// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/broker/match/match.proto" (package "bpt.broker.match", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Void } from "../../base/base";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { MainMatchType } from "../../match/common/v1/common";
import { PauseConf } from "../../match/common/v1/common";
import { BlindStructure } from "../../match/common/v1/common";
import { MatchListType } from "../../match/common/v1/common";
import { Transaction } from "../../match/matchlink/v1/matchlink";
import { UserStatus } from "../../match/common/v1/common";
import { Desk } from "../../match/common/v1/common";
import { ChargeEvent } from "../../match/common/v1/common";
import { AssetItem } from "../../common/common_define";
import { SimpleAsset } from "../../common/common_define";
import { MatchContext } from "../../match/common/v1/common";
import { MatchType } from "../../match/common/v1/common";
import { Match } from "../../match/common/v1/common";
import { MatchConf } from "../../match/common/v1/common";
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchStart
 */
export interface MsgMatchStart {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 1;
     */
    conf?: MatchConf;
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match mttMatch = 2;
     */
    mttMatch?: Match;
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match sngMatch = 3;
     */
    sngMatch?: Match;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: string matchKey = 5;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchResult
 */
export interface MsgMatchResult {
    /**
     * @generated from protobuf field: repeated string successTS = 6;
     */
    successTS: string[]; // 赛中处理成功的交易
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 7;
     */
    match?: Match;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchContext matchPoolContext = 8;
     */
    matchPoolContext?: MatchContext;
    /**
     * @generated from protobuf field: bool isMatchOver = 9;
     */
    isMatchOver: boolean;
    /**
     * @generated from protobuf field: int64 finalTableUserNum = 10;
     */
    finalTableUserNum: number; // 进入FT用户
    /**
     * @generated from protobuf field: bool isHeadUp = 12;
     */
    isHeadUp: boolean; // 是否触发二人对决
    /**
     * 创建者的手续费，手续费计算规则
     * 见文档 https://boyaagame.feishu.cn/wiki/AFIAwt0RUiV0pIkxKNRcdsNOnDc?fromScene=spaceOverview
     *
     * @generated from protobuf field: repeated bpt.common.SimpleAsset creatorServiceFee = 13;
     */
    creatorServiceFee: SimpleAsset[];
    /**
     * @generated from protobuf field: int32 placePaid = 14;
     */
    placePaid: number; // 获奖人数(奖圈人数)
    /**
     * 是否matchhub中的MatchOver发送的
     *
     * @generated from protobuf field: bool isMatchhubMatchOverSend = 15;
     */
    isMatchhubMatchOverSend: boolean;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgUserMatchResult
 */
export interface MsgUserMatchResult {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: string matchName = 3;
     */
    matchName: string;
    /**
     * @generated from protobuf field: string preMatchKey = 4;
     */
    preMatchKey: string;
    /**
     * @generated from protobuf field: string matchKey = 5;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 startTime = 6;
     */
    startTime: number; // 比赛开始时间 时间戳 例如：1551791375
    /**
     * @generated from protobuf field: int64 endTime = 7;
     */
    endTime: number;
    /**
     * @generated from protobuf field: int64 rank = 8;
     */
    rank: number;
    /**
     * 得分
     *
     * @generated from protobuf field: int64 score = 9;
     */
    score: number;
    /**
     * 奖励
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem prizes = 10;
     */
    prizes: AssetItem[];
    /**
     * 报名时间
     *
     * @generated from protobuf field: int64 enterAt = 11;
     */
    enterAt: number;
    /**
     * 报名费
     *
     * @generated from protobuf field: bpt.common.AssetItem enterFee = 12;
     */
    enterFee?: AssetItem;
    /**
     * @generated from protobuf field: int64 matchId = 16;
     */
    matchId: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 20;
     */
    match?: Match;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgLuckyWinner
 */
export interface MsgLuckyWinner {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 获取中奖励（加倍后）
     *
     * @generated from protobuf field: bpt.common.AssetItem prize = 2;
     */
    prize?: AssetItem;
    /**
     * 倍数
     *
     * @generated from protobuf field: uint32 multipler = 3;
     */
    multipler: number;
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string tableKey = 5;
     */
    tableKey: string;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgUserMatchCharges
 */
export interface MsgUserMatchCharges {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.broker.match.MsgCharge msgCharge = 2;
     */
    msgCharge: MsgCharge[];
}
/**
 * @generated from protobuf message bpt.broker.match.MsgCharge
 */
export interface MsgCharge {
    /**
     * @generated from protobuf field: bpt.match.common.v1.ChargeEvent event = 1;
     */
    event: ChargeEvent;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint64 chips = 3;
     */
    chips: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchReachBubble
 */
export interface MsgMatchReachBubble {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchReachFinalTable
 */
export interface MsgMatchReachFinalTable {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 finalTableID = 2;
     */
    finalTableID: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgRankChange
 */
export interface MsgRankChange {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.broker.match.UserRankInfo rankInfos = 2;
     */
    rankInfos: UserRankInfo[];
}
/**
 * @generated from protobuf message bpt.broker.match.MsgDesksSnapshot
 */
export interface MsgDesksSnapshot {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Desk desks = 2;
     */
    desks: Desk[];
}
/**
 * @generated from protobuf message bpt.broker.match.MsgReEntryExhausted
 */
export interface MsgReEntryExhausted {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.broker.match.UserRankInfo
 */
export interface UserRankInfo {
    /**
     * @generated from protobuf field: int32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint64 chips = 3;
     */
    chips: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.UserStatus status = 4;
     */
    status: UserStatus;
    /**
     * 从比赛开始到出局经过的时间(单位：秒)，0表示未出局
     *
     * @generated from protobuf field: uint32 elapsedTime = 5;
     */
    elapsedTime: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchListCreatedCheckDeferred
 */
export interface MsgMatchListCreatedCheckDeferred {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 startAt = 2;
     */
    startAt: number;
    /**
     * @generated from protobuf field: int64 createdTime = 3;
     */
    createdTime: number;
    /**
     * @generated from protobuf field: int64 retry = 4;
     */
    retry: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MailInfo
 */
export interface MailInfo {
    /**
     * @generated from protobuf field: string reason = 1;
     */
    reason: string;
    /**
     * @generated from protobuf field: bool isForce = 2;
     */
    isForce: boolean;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchDeferredEvent
 */
export interface MsgMatchDeferredEvent {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.broker.match.MatchEvent event = 2;
     */
    event: MatchEvent;
    /**
     * @generated from protobuf field: int64 createdTime = 3;
     */
    createdTime: number;
    /**
     * @generated from protobuf field: int64 retry = 4;
     */
    retry: number;
    /**
     * @generated from protobuf field: bpt.broker.match.MailInfo mailInfo = 5;
     */
    mailInfo?: MailInfo;
    /**
     * @generated from protobuf field: int64 pauseEndAt = 7;
     */
    pauseEndAt: number; // 暂停比赛的截止时间
    /**
     * @generated from protobuf field: int64 pauseBeginAt = 8;
     */
    pauseBeginAt: number; // 暂停开始的时间
    /**
     * @generated from protobuf field: int64 execAt = 6;
     */
    execAt: number; // 事件执行时间
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchhubTransaction
 */
export interface MsgMatchhubTransaction {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
    /**
     * @generated from protobuf field: bool isMock = 2;
     */
    isMock: boolean;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchReportStageUserEvent
 */
export interface MsgMatchReportStageUserEvent {
    /**
     * @generated from protobuf field: bpt.broker.match.MatchUserStageEvent event = 1;
     */
    event: MatchUserStageEvent;
    /**
     * @generated from protobuf field: repeated bpt.broker.match.StageUser stageUsers = 2;
     */
    stageUsers: StageUser[];
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 4;
     */
    matchListType: MatchListType;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchPause
 */
export interface MsgMatchPause {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 pauseBeginAt = 2;
     */
    pauseBeginAt: number;
    /**
     * @generated from protobuf field: int64 pauseEndAt = 3;
     */
    pauseEndAt: number;
    /**
     * @generated from protobuf field: int64 registerDeadline = 4;
     */
    registerDeadline: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchResume
 */
export interface MsgMatchResume {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 resumeAt = 2;
     */
    resumeAt: number;
    /**
     * @generated from protobuf field: int64 registerDeadline = 3;
     */
    registerDeadline: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgBreakTime
 */
export interface MsgBreakTime {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 breakTimeStartAt = 2;
     */
    breakTimeStartAt: number; // 休息开始时间
    /**
     * @generated from protobuf field: int64 breakTimeStopAt = 3;
     */
    breakTimeStopAt: number; // 休息结束时间
    /**
     * @generated from protobuf field: uint32 blindLevel = 4;
     */
    blindLevel: number; // 当前盲注级别
    /**
     * @generated from protobuf field: bpt.match.common.v1.BlindStructure blindStructure = 5;
     */
    blindStructure?: BlindStructure;
    /**
     * @generated from protobuf field: bpt.match.common.v1.PauseConf pauseConf = 6;
     */
    pauseConf?: PauseConf;
}
/**
 * @generated from protobuf message bpt.broker.match.StageUser
 */
export interface StageUser {
    /**
     * @generated from protobuf field: uint32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 Uid = 2 [json_name = "Uid"];
     */
    Uid: number;
    /**
     * @generated from protobuf field: uint64 Chips = 3 [json_name = "Chips"];
     */
    Chips: number;
}
/**
 * @generated from protobuf message bpt.broker.match.DeferRewardNoticeTask
 */
export interface DeferRewardNoticeTask {
    /**
     * @generated from protobuf field: int64 matchID = 1;
     */
    matchID: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgUserRegister
 */
export interface MsgUserRegister {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string; // 对应 eagle tournament_id
    /**
     * @generated from protobuf field: string matchName = 3;
     */
    matchName: string; // 取多语言的en  对应eagle tournament_name
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset basePool = 4;
     */
    basePool: SimpleAsset[]; // eagle 上报 100000MTT,0.01BTC
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset buyInAssets = 5;
     */
    buyInAssets: SimpleAsset[]; // 报名费用
    /**
     * @generated from protobuf field: int32 maxReentry = 6;
     */
    maxReentry: number;
    /**
     * @generated from protobuf field: bpt.common.SimpleAsset reEntryCost = 7;
     */
    reEntryCost?: SimpleAsset; // 单次reentry的成本
    /**
     * @generated from protobuf field: int32 addonCnt = 8;
     */
    addonCnt: number; // 用户报名时比赛addon 的次数
    /**
     * @generated from protobuf field: bpt.common.SimpleAsset addOnCost = 9;
     */
    addOnCost?: SimpleAsset; // 单次addon的成本
    /**
     * 初始筹码
     *
     * @generated from protobuf field: uint64 startingStack = 10;
     */
    startingStack: number;
    /**
     * 最小玩家数量
     *
     * @generated from protobuf field: int32 minPlayer = 11;
     */
    minPlayer: number;
    /**
     * 最大玩家数量
     *
     * @generated from protobuf field: int32 maxPlayer = 12;
     */
    maxPlayer: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchType mainMatchType = 13;
     */
    mainMatchType: MainMatchType; // 比赛类型
    /**
     * @generated from protobuf field: int64 startAt = 14;
     */
    startAt: number; // 比赛开始时间
    /**
     * @generated from protobuf field: string channelName = 15;
     */
    channelName: string; // 渠道名称
    /**
     * 比赛类型
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 16;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: bool lateReg = 17;
     */
    lateReg: boolean; // 是否延迟报名
}
/**
 * @generated from protobuf message bpt.broker.match.MsgUserUnRegister
 */
export interface MsgUserUnRegister {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string; // 对应 eagle tournament_id
    /**
     * @generated from protobuf field: string matchName = 3;
     */
    matchName: string; // 取多语言的en  对应eagle tournament_name
    /**
     * @generated from protobuf field: int32 maxReentry = 6;
     */
    maxReentry: number;
    /**
     * @generated from protobuf field: bpt.common.SimpleAsset reEntryCost = 7;
     */
    reEntryCost?: SimpleAsset; // 单次reentry的成本
    /**
     * @generated from protobuf field: bpt.common.SimpleAsset addOnCost = 9;
     */
    addOnCost?: SimpleAsset; // 单次addon的成本
    /**
     * 初始筹码
     *
     * @generated from protobuf field: uint64 startingStack = 10;
     */
    startingStack: number;
    /**
     * 最小玩家数量
     *
     * @generated from protobuf field: int32 minPlayer = 11;
     */
    minPlayer: number;
    /**
     * 最大玩家数量
     *
     * @generated from protobuf field: int32 maxPlayer = 12;
     */
    maxPlayer: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset basePool = 4;
     */
    basePool: SimpleAsset[]; // eagle 上报 100000MTT,0.01BTC
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset buyInAssets = 5;
     */
    buyInAssets: SimpleAsset[]; // 报名费用
    /**
     * @generated from protobuf field: int32 addonCnt = 8;
     */
    addonCnt: number; // 用户报名时比赛addon 的次数
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchType mainMatchType = 13;
     */
    mainMatchType: MainMatchType; // 比赛类型
    /**
     * @generated from protobuf field: int64 startAt = 14;
     */
    startAt: number; // 比赛开始时间
    /**
     * @generated from protobuf field: string channelName = 15;
     */
    channelName: string; // 渠道名称
    /**
     * 比赛类型
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 16;
     */
    matchType: MatchType;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgUserEliminate
 */
export interface MsgUserEliminate {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string; // 对应 eagle tournament_id
    /**
     * @generated from protobuf field: string matchName = 3;
     */
    matchName: string; // 取多语言的en  对应eagle tournament_name
    /**
     * 比赛类型
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * 用户轮次
     *
     * @generated from protobuf field: int32 rounds = 5;
     */
    rounds: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalPrizePool = 6;
     */
    totalPrizePool: SimpleAsset[]; // 总奖池
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset basePool = 7;
     */
    basePool: SimpleAsset[]; // 保底奖池
    /**
     * @generated from protobuf field: int64 durationInSeconds = 8;
     */
    durationInSeconds: number; // 比赛的持续时间
    /**
     * @generated from protobuf field: int32 reEntryCnt = 9;
     */
    reEntryCnt: number; // 用户重入次数
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalReEntryCost = 10;
     */
    totalReEntryCost: SimpleAsset[];
    /**
     * @generated from protobuf field: int32 reBuyCnt = 11;
     */
    reBuyCnt: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalReBuyCost = 12;
     */
    totalReBuyCost: SimpleAsset[];
    /**
     * @generated from protobuf field: int32 addOnCnt = 13;
     */
    addOnCnt: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalAddOnCost = 14;
     */
    totalAddOnCost: SimpleAsset[];
}
/**
 * @generated from protobuf message bpt.broker.match.MsgUserGameOver
 */
export interface MsgUserGameOver {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string; // 对应 eagle tournament_id
    /**
     * @generated from protobuf field: string matchName = 3;
     */
    matchName: string; // 取多语言的en  对应eagle tournament_name
    /**
     * 比赛类型
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * 用户轮次
     *
     * @generated from protobuf field: int32 rounds = 5;
     */
    rounds: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalPrizePool = 6;
     */
    totalPrizePool: SimpleAsset[]; // 总奖池
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset basePool = 7;
     */
    basePool: SimpleAsset[]; // 保底奖池
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset userPrizes = 8;
     */
    userPrizes: SimpleAsset[];
    /**
     * @generated from protobuf field: uint32 rank = 9;
     */
    rank: number;
    /**
     * @generated from protobuf field: uint64 remainChips = 10;
     */
    remainChips: number;
    /**
     * @generated from protobuf field: int64 durationInSeconds = 11;
     */
    durationInSeconds: number; // 比赛的持续时间
    /**
     * @generated from protobuf field: int32 reEntryCnt = 12;
     */
    reEntryCnt: number; // 用户重入次数
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalReEntryCost = 13;
     */
    totalReEntryCost: SimpleAsset[];
    /**
     * @generated from protobuf field: int32 reBuyCnt = 14;
     */
    reBuyCnt: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalReBuyCost = 15;
     */
    totalReBuyCost: SimpleAsset[];
    /**
     * @generated from protobuf field: int32 addOnCnt = 16;
     */
    addOnCnt: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset totalAddOnCost = 17;
     */
    totalAddOnCost: SimpleAsset[];
}
/**
 * @generated from protobuf message bpt.broker.match.SettlementUser
 */
export interface SettlementUser {
    /**
     * @generated from protobuf field: int64 userID = 1;
     */
    userID: number;
    /**
     * @generated from protobuf field: uint32 rank = 2;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 chips = 3;
     */
    chips: number;
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchRegisterDeadline
 */
export interface MsgMatchRegisterDeadline {
    /**
     * @generated from protobuf field: int64 tick = 1;
     */
    tick: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.broker.match.SettlementUser settlementUserList = 3;
     */
    settlementUserList: SettlementUser[];
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchUserSettlement
 */
export interface MsgMatchUserSettlement {
    /**
     * @generated from protobuf field: int64 tick = 1;
     */
    tick: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.broker.match.SettlementUser settlementUserList = 3;
     */
    settlementUserList: SettlementUser[];
}
/**
 * @generated from protobuf message bpt.broker.match.MsgMatchOver
 */
export interface MsgMatchOver {
    /**
     * @generated from protobuf field: int64 tick = 1;
     */
    tick: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.broker.match.MsgMatchResult matchResult = 3;
     */
    matchResult?: MsgMatchResult;
}
/**
 * @generated from protobuf enum bpt.broker.match.MatchEvent
 */
export enum MatchEvent {
    /**
     * @generated from protobuf enum value: MatchEventAboutStart = 0;
     */
    MatchEventAboutStart = 0,
    /**
     * @generated from protobuf enum value: MatchEventStartMatch = 1;
     */
    MatchEventStartMatch = 1,
    /**
     * @generated from protobuf enum value: MatchEventCountDown = 2;
     */
    MatchEventCountDown = 2,
    /**
     * @generated from protobuf enum value: MatchEventMultipler = 3;
     */
    MatchEventMultipler = 3,
    /**
     * 用户主动取消
     *
     * @generated from protobuf enum value: MatchEventOwnerCancelMail = 4;
     */
    MatchEventOwnerCancelMail = 4,
    /**
     * @generated from protobuf enum value: MatchEventResumeMatch = 5;
     */
    MatchEventResumeMatch = 5,
    /**
     * 自动上链
     *
     * @generated from protobuf enum value: MatchEventAutoOnChain = 6;
     */
    MatchEventAutoOnChain = 6
}
/**
 * @generated from protobuf enum bpt.broker.match.MatchUserStageEvent
 */
export enum MatchUserStageEvent {
    /**
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * 泡沫男孩
     *
     * @generated from protobuf enum value: BubbleUser = 1;
     */
    BubbleUser = 1,
    /**
     * 进入钱圈的用户
     *
     * @generated from protobuf enum value: EnterPrizeUsers = 2;
     */
    EnterPrizeUsers = 2,
    /**
     * 进入final table的用户
     *
     * @generated from protobuf enum value: FinalTableUsers = 3;
     */
    FinalTableUsers = 3,
    /**
     * 第三名用户
     *
     * @generated from protobuf enum value: ThirdPlaceUser = 4;
     */
    ThirdPlaceUser = 4,
    /**
     * 二人对决用户
     *
     * @generated from protobuf enum value: HeadsUpUsers = 5;
     */
    HeadsUpUsers = 5,
    /**
     * 冠军用户
     *
     * @generated from protobuf enum value: ChampionUser = 6;
     */
    ChampionUser = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchStart$Type extends MessageType<MsgMatchStart> {
    constructor() {
        super("bpt.broker.match.MsgMatchStart", [
            { no: 1, name: "conf", kind: "message", T: () => MatchConf },
            { no: 2, name: "mttMatch", kind: "message", T: () => Match },
            { no: 3, name: "sngMatch", kind: "message", T: () => Match },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 5, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchStart>): MsgMatchStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchType = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgMatchStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchStart): MsgMatchStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchConf conf */ 1:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                case /* bpt.match.common.v1.Match mttMatch */ 2:
                    message.mttMatch = Match.internalBinaryRead(reader, reader.uint32(), options, message.mttMatch);
                    break;
                case /* bpt.match.common.v1.Match sngMatch */ 3:
                    message.sngMatch = Match.internalBinaryRead(reader, reader.uint32(), options, message.sngMatch);
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* string matchKey */ 5:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchConf conf = 1; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.Match mttMatch = 2; */
        if (message.mttMatch)
            Match.internalBinaryWrite(message.mttMatch, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.Match sngMatch = 3; */
        if (message.sngMatch)
            Match.internalBinaryWrite(message.sngMatch, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* string matchKey = 5; */
        if (message.matchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchStart
 */
export const MsgMatchStart = new MsgMatchStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchResult$Type extends MessageType<MsgMatchResult> {
    constructor() {
        super("bpt.broker.match.MsgMatchResult", [
            { no: 6, name: "successTS", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "match", kind: "message", T: () => Match },
            { no: 8, name: "matchPoolContext", kind: "message", T: () => MatchContext },
            { no: 9, name: "isMatchOver", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "finalTableUserNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "isHeadUp", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "creatorServiceFee", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 14, name: "placePaid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "isMatchhubMatchOverSend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchResult>): MsgMatchResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.successTS = [];
        message.isMatchOver = false;
        message.finalTableUserNum = 0;
        message.isHeadUp = false;
        message.creatorServiceFee = [];
        message.placePaid = 0;
        message.isMatchhubMatchOverSend = false;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchResult): MsgMatchResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string successTS */ 6:
                    message.successTS.push(reader.string());
                    break;
                case /* bpt.match.common.v1.Match match */ 7:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                case /* bpt.match.common.v1.MatchContext matchPoolContext */ 8:
                    message.matchPoolContext = MatchContext.internalBinaryRead(reader, reader.uint32(), options, message.matchPoolContext);
                    break;
                case /* bool isMatchOver */ 9:
                    message.isMatchOver = reader.bool();
                    break;
                case /* int64 finalTableUserNum */ 10:
                    message.finalTableUserNum = reader.int64().toNumber();
                    break;
                case /* bool isHeadUp */ 12:
                    message.isHeadUp = reader.bool();
                    break;
                case /* repeated bpt.common.SimpleAsset creatorServiceFee */ 13:
                    message.creatorServiceFee.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 placePaid */ 14:
                    message.placePaid = reader.int32();
                    break;
                case /* bool isMatchhubMatchOverSend */ 15:
                    message.isMatchhubMatchOverSend = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string successTS = 6; */
        for (let i = 0; i < message.successTS.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.successTS[i]);
        /* bpt.match.common.v1.Match match = 7; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchContext matchPoolContext = 8; */
        if (message.matchPoolContext)
            MatchContext.internalBinaryWrite(message.matchPoolContext, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool isMatchOver = 9; */
        if (message.isMatchOver !== false)
            writer.tag(9, WireType.Varint).bool(message.isMatchOver);
        /* int64 finalTableUserNum = 10; */
        if (message.finalTableUserNum !== 0)
            writer.tag(10, WireType.Varint).int64(message.finalTableUserNum);
        /* bool isHeadUp = 12; */
        if (message.isHeadUp !== false)
            writer.tag(12, WireType.Varint).bool(message.isHeadUp);
        /* repeated bpt.common.SimpleAsset creatorServiceFee = 13; */
        for (let i = 0; i < message.creatorServiceFee.length; i++)
            SimpleAsset.internalBinaryWrite(message.creatorServiceFee[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* int32 placePaid = 14; */
        if (message.placePaid !== 0)
            writer.tag(14, WireType.Varint).int32(message.placePaid);
        /* bool isMatchhubMatchOverSend = 15; */
        if (message.isMatchhubMatchOverSend !== false)
            writer.tag(15, WireType.Varint).bool(message.isMatchhubMatchOverSend);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchResult
 */
export const MsgMatchResult = new MsgMatchResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserMatchResult$Type extends MessageType<MsgUserMatchResult> {
    constructor() {
        super("bpt.broker.match.MsgUserMatchResult", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 3, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "preMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "startTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "endTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "rank", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "score", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 11, name: "enterAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "enterFee", kind: "message", T: () => AssetItem },
            { no: 16, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 20, name: "match", kind: "message", T: () => Match }
        ]);
    }
    create(value?: PartialMessage<MsgUserMatchResult>): MsgUserMatchResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchType = 0;
        message.matchName = "";
        message.preMatchKey = "";
        message.matchKey = "";
        message.startTime = 0;
        message.endTime = 0;
        message.rank = 0;
        message.score = 0;
        message.prizes = [];
        message.enterAt = 0;
        message.matchId = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserMatchResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserMatchResult): MsgUserMatchResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                case /* string matchName */ 3:
                    message.matchName = reader.string();
                    break;
                case /* string preMatchKey */ 4:
                    message.preMatchKey = reader.string();
                    break;
                case /* string matchKey */ 5:
                    message.matchKey = reader.string();
                    break;
                case /* int64 startTime */ 6:
                    message.startTime = reader.int64().toNumber();
                    break;
                case /* int64 endTime */ 7:
                    message.endTime = reader.int64().toNumber();
                    break;
                case /* int64 rank */ 8:
                    message.rank = reader.int64().toNumber();
                    break;
                case /* int64 score */ 9:
                    message.score = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizes */ 10:
                    message.prizes.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 enterAt */ 11:
                    message.enterAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.AssetItem enterFee */ 12:
                    message.enterFee = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.enterFee);
                    break;
                case /* int64 matchId */ 16:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.Match match */ 20:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserMatchResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        /* string matchName = 3; */
        if (message.matchName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchName);
        /* string preMatchKey = 4; */
        if (message.preMatchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.preMatchKey);
        /* string matchKey = 5; */
        if (message.matchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchKey);
        /* int64 startTime = 6; */
        if (message.startTime !== 0)
            writer.tag(6, WireType.Varint).int64(message.startTime);
        /* int64 endTime = 7; */
        if (message.endTime !== 0)
            writer.tag(7, WireType.Varint).int64(message.endTime);
        /* int64 rank = 8; */
        if (message.rank !== 0)
            writer.tag(8, WireType.Varint).int64(message.rank);
        /* int64 score = 9; */
        if (message.score !== 0)
            writer.tag(9, WireType.Varint).int64(message.score);
        /* repeated bpt.common.AssetItem prizes = 10; */
        for (let i = 0; i < message.prizes.length; i++)
            AssetItem.internalBinaryWrite(message.prizes[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int64 enterAt = 11; */
        if (message.enterAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.enterAt);
        /* bpt.common.AssetItem enterFee = 12; */
        if (message.enterFee)
            AssetItem.internalBinaryWrite(message.enterFee, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* int64 matchId = 16; */
        if (message.matchId !== 0)
            writer.tag(16, WireType.Varint).int64(message.matchId);
        /* bpt.match.common.v1.Match match = 20; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgUserMatchResult
 */
export const MsgUserMatchResult = new MsgUserMatchResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgLuckyWinner$Type extends MessageType<MsgLuckyWinner> {
    constructor() {
        super("bpt.broker.match.MsgLuckyWinner", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "prize", kind: "message", T: () => AssetItem },
            { no: 3, name: "multipler", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgLuckyWinner>): MsgLuckyWinner {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.multipler = 0;
        message.matchKey = "";
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgLuckyWinner>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgLuckyWinner): MsgLuckyWinner {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.common.AssetItem prize */ 2:
                    message.prize = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.prize);
                    break;
                case /* uint32 multipler */ 3:
                    message.multipler = reader.uint32();
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                case /* string tableKey */ 5:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgLuckyWinner, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.common.AssetItem prize = 2; */
        if (message.prize)
            AssetItem.internalBinaryWrite(message.prize, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* uint32 multipler = 3; */
        if (message.multipler !== 0)
            writer.tag(3, WireType.Varint).uint32(message.multipler);
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        /* string tableKey = 5; */
        if (message.tableKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgLuckyWinner
 */
export const MsgLuckyWinner = new MsgLuckyWinner$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserMatchCharges$Type extends MessageType<MsgUserMatchCharges> {
    constructor() {
        super("bpt.broker.match.MsgUserMatchCharges", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "msgCharge", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MsgCharge }
        ]);
    }
    create(value?: PartialMessage<MsgUserMatchCharges>): MsgUserMatchCharges {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.msgCharge = [];
        if (value !== undefined)
            reflectionMergePartial<MsgUserMatchCharges>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserMatchCharges): MsgUserMatchCharges {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.broker.match.MsgCharge msgCharge */ 2:
                    message.msgCharge.push(MsgCharge.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserMatchCharges, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.broker.match.MsgCharge msgCharge = 2; */
        for (let i = 0; i < message.msgCharge.length; i++)
            MsgCharge.internalBinaryWrite(message.msgCharge[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgUserMatchCharges
 */
export const MsgUserMatchCharges = new MsgUserMatchCharges$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgCharge$Type extends MessageType<MsgCharge> {
    constructor() {
        super("bpt.broker.match.MsgCharge", [
            { no: 1, name: "event", kind: "enum", T: () => ["bpt.match.common.v1.ChargeEvent", ChargeEvent] },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgCharge>): MsgCharge {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = 0;
        message.uid = 0;
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgCharge>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgCharge): MsgCharge {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.ChargeEvent event */ 1:
                    message.event = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 chips */ 3:
                    message.chips = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgCharge, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.ChargeEvent event = 1; */
        if (message.event !== 0)
            writer.tag(1, WireType.Varint).int32(message.event);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* uint64 chips = 3; */
        if (message.chips !== 0)
            writer.tag(3, WireType.Varint).uint64(message.chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgCharge
 */
export const MsgCharge = new MsgCharge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchReachBubble$Type extends MessageType<MsgMatchReachBubble> {
    constructor() {
        super("bpt.broker.match.MsgMatchReachBubble", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchReachBubble>): MsgMatchReachBubble {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgMatchReachBubble>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchReachBubble): MsgMatchReachBubble {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchReachBubble, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchReachBubble
 */
export const MsgMatchReachBubble = new MsgMatchReachBubble$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchReachFinalTable$Type extends MessageType<MsgMatchReachFinalTable> {
    constructor() {
        super("bpt.broker.match.MsgMatchReachFinalTable", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "finalTableID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchReachFinalTable>): MsgMatchReachFinalTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.finalTableID = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchReachFinalTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchReachFinalTable): MsgMatchReachFinalTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 finalTableID */ 2:
                    message.finalTableID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchReachFinalTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 finalTableID = 2; */
        if (message.finalTableID !== 0)
            writer.tag(2, WireType.Varint).int64(message.finalTableID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchReachFinalTable
 */
export const MsgMatchReachFinalTable = new MsgMatchReachFinalTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgRankChange$Type extends MessageType<MsgRankChange> {
    constructor() {
        super("bpt.broker.match.MsgRankChange", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rankInfos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserRankInfo }
        ]);
    }
    create(value?: PartialMessage<MsgRankChange>): MsgRankChange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.rankInfos = [];
        if (value !== undefined)
            reflectionMergePartial<MsgRankChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgRankChange): MsgRankChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.broker.match.UserRankInfo rankInfos */ 2:
                    message.rankInfos.push(UserRankInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgRankChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.broker.match.UserRankInfo rankInfos = 2; */
        for (let i = 0; i < message.rankInfos.length; i++)
            UserRankInfo.internalBinaryWrite(message.rankInfos[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgRankChange
 */
export const MsgRankChange = new MsgRankChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgDesksSnapshot$Type extends MessageType<MsgDesksSnapshot> {
    constructor() {
        super("bpt.broker.match.MsgDesksSnapshot", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Desk }
        ]);
    }
    create(value?: PartialMessage<MsgDesksSnapshot>): MsgDesksSnapshot {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.desks = [];
        if (value !== undefined)
            reflectionMergePartial<MsgDesksSnapshot>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgDesksSnapshot): MsgDesksSnapshot {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.match.common.v1.Desk desks */ 2:
                    message.desks.push(Desk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgDesksSnapshot, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.match.common.v1.Desk desks = 2; */
        for (let i = 0; i < message.desks.length; i++)
            Desk.internalBinaryWrite(message.desks[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgDesksSnapshot
 */
export const MsgDesksSnapshot = new MsgDesksSnapshot$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgReEntryExhausted$Type extends MessageType<MsgReEntryExhausted> {
    constructor() {
        super("bpt.broker.match.MsgReEntryExhausted", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgReEntryExhausted>): MsgReEntryExhausted {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<MsgReEntryExhausted>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgReEntryExhausted): MsgReEntryExhausted {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgReEntryExhausted, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgReEntryExhausted
 */
export const MsgReEntryExhausted = new MsgReEntryExhausted$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRankInfo$Type extends MessageType<UserRankInfo> {
    constructor() {
        super("bpt.broker.match.UserRankInfo", [
            { no: 1, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "status", kind: "enum", T: () => ["bpt.match.common.v1.UserStatus", UserStatus] },
            { no: 5, name: "elapsedTime", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<UserRankInfo>): UserRankInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.uid = 0;
        message.chips = 0;
        message.status = 0;
        message.elapsedTime = 0;
        if (value !== undefined)
            reflectionMergePartial<UserRankInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRankInfo): UserRankInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rank */ 1:
                    message.rank = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 chips */ 3:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* bpt.match.common.v1.UserStatus status */ 4:
                    message.status = reader.int32();
                    break;
                case /* uint32 elapsedTime */ 5:
                    message.elapsedTime = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRankInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).int32(message.rank);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* uint64 chips = 3; */
        if (message.chips !== 0)
            writer.tag(3, WireType.Varint).uint64(message.chips);
        /* bpt.match.common.v1.UserStatus status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).int32(message.status);
        /* uint32 elapsedTime = 5; */
        if (message.elapsedTime !== 0)
            writer.tag(5, WireType.Varint).uint32(message.elapsedTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.UserRankInfo
 */
export const UserRankInfo = new UserRankInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchListCreatedCheckDeferred$Type extends MessageType<MsgMatchListCreatedCheckDeferred> {
    constructor() {
        super("bpt.broker.match.MsgMatchListCreatedCheckDeferred", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "createdTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "retry", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchListCreatedCheckDeferred>): MsgMatchListCreatedCheckDeferred {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.startAt = 0;
        message.createdTime = 0;
        message.retry = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchListCreatedCheckDeferred>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchListCreatedCheckDeferred): MsgMatchListCreatedCheckDeferred {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 startAt */ 2:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* int64 createdTime */ 3:
                    message.createdTime = reader.int64().toNumber();
                    break;
                case /* int64 retry */ 4:
                    message.retry = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchListCreatedCheckDeferred, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 startAt = 2; */
        if (message.startAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.startAt);
        /* int64 createdTime = 3; */
        if (message.createdTime !== 0)
            writer.tag(3, WireType.Varint).int64(message.createdTime);
        /* int64 retry = 4; */
        if (message.retry !== 0)
            writer.tag(4, WireType.Varint).int64(message.retry);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchListCreatedCheckDeferred
 */
export const MsgMatchListCreatedCheckDeferred = new MsgMatchListCreatedCheckDeferred$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MailInfo$Type extends MessageType<MailInfo> {
    constructor() {
        super("bpt.broker.match.MailInfo", [
            { no: 1, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "isForce", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MailInfo>): MailInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.reason = "";
        message.isForce = false;
        if (value !== undefined)
            reflectionMergePartial<MailInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MailInfo): MailInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string reason */ 1:
                    message.reason = reader.string();
                    break;
                case /* bool isForce */ 2:
                    message.isForce = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MailInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string reason = 1; */
        if (message.reason !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.reason);
        /* bool isForce = 2; */
        if (message.isForce !== false)
            writer.tag(2, WireType.Varint).bool(message.isForce);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MailInfo
 */
export const MailInfo = new MailInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchDeferredEvent$Type extends MessageType<MsgMatchDeferredEvent> {
    constructor() {
        super("bpt.broker.match.MsgMatchDeferredEvent", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "event", kind: "enum", T: () => ["bpt.broker.match.MatchEvent", MatchEvent] },
            { no: 3, name: "createdTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "retry", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "mailInfo", kind: "message", T: () => MailInfo },
            { no: 7, name: "pauseEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "pauseBeginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "execAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchDeferredEvent>): MsgMatchDeferredEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.event = 0;
        message.createdTime = 0;
        message.retry = 0;
        message.pauseEndAt = 0;
        message.pauseBeginAt = 0;
        message.execAt = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchDeferredEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchDeferredEvent): MsgMatchDeferredEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.broker.match.MatchEvent event */ 2:
                    message.event = reader.int32();
                    break;
                case /* int64 createdTime */ 3:
                    message.createdTime = reader.int64().toNumber();
                    break;
                case /* int64 retry */ 4:
                    message.retry = reader.int64().toNumber();
                    break;
                case /* bpt.broker.match.MailInfo mailInfo */ 5:
                    message.mailInfo = MailInfo.internalBinaryRead(reader, reader.uint32(), options, message.mailInfo);
                    break;
                case /* int64 pauseEndAt */ 7:
                    message.pauseEndAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseBeginAt */ 8:
                    message.pauseBeginAt = reader.int64().toNumber();
                    break;
                case /* int64 execAt */ 6:
                    message.execAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchDeferredEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.broker.match.MatchEvent event = 2; */
        if (message.event !== 0)
            writer.tag(2, WireType.Varint).int32(message.event);
        /* int64 createdTime = 3; */
        if (message.createdTime !== 0)
            writer.tag(3, WireType.Varint).int64(message.createdTime);
        /* int64 retry = 4; */
        if (message.retry !== 0)
            writer.tag(4, WireType.Varint).int64(message.retry);
        /* bpt.broker.match.MailInfo mailInfo = 5; */
        if (message.mailInfo)
            MailInfo.internalBinaryWrite(message.mailInfo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int64 pauseEndAt = 7; */
        if (message.pauseEndAt !== 0)
            writer.tag(7, WireType.Varint).int64(message.pauseEndAt);
        /* int64 pauseBeginAt = 8; */
        if (message.pauseBeginAt !== 0)
            writer.tag(8, WireType.Varint).int64(message.pauseBeginAt);
        /* int64 execAt = 6; */
        if (message.execAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.execAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchDeferredEvent
 */
export const MsgMatchDeferredEvent = new MsgMatchDeferredEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchhubTransaction$Type extends MessageType<MsgMatchhubTransaction> {
    constructor() {
        super("bpt.broker.match.MsgMatchhubTransaction", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction },
            { no: 2, name: "isMock", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchhubTransaction>): MsgMatchhubTransaction {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isMock = false;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchhubTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchhubTransaction): MsgMatchhubTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                case /* bool isMock */ 2:
                    message.isMock = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchhubTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool isMock = 2; */
        if (message.isMock !== false)
            writer.tag(2, WireType.Varint).bool(message.isMock);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchhubTransaction
 */
export const MsgMatchhubTransaction = new MsgMatchhubTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchReportStageUserEvent$Type extends MessageType<MsgMatchReportStageUserEvent> {
    constructor() {
        super("bpt.broker.match.MsgMatchReportStageUserEvent", [
            { no: 1, name: "event", kind: "enum", T: () => ["bpt.broker.match.MatchUserStageEvent", MatchUserStageEvent] },
            { no: 2, name: "stageUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StageUser },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] }
        ]);
    }
    create(value?: PartialMessage<MsgMatchReportStageUserEvent>): MsgMatchReportStageUserEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = 0;
        message.stageUsers = [];
        message.matchKey = "";
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchReportStageUserEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchReportStageUserEvent): MsgMatchReportStageUserEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.broker.match.MatchUserStageEvent event */ 1:
                    message.event = reader.int32();
                    break;
                case /* repeated bpt.broker.match.StageUser stageUsers */ 2:
                    message.stageUsers.push(StageUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 4:
                    message.matchListType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchReportStageUserEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.broker.match.MatchUserStageEvent event = 1; */
        if (message.event !== 0)
            writer.tag(1, WireType.Varint).int32(message.event);
        /* repeated bpt.broker.match.StageUser stageUsers = 2; */
        for (let i = 0; i < message.stageUsers.length; i++)
            StageUser.internalBinaryWrite(message.stageUsers[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchListType matchListType = 4; */
        if (message.matchListType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchListType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchReportStageUserEvent
 */
export const MsgMatchReportStageUserEvent = new MsgMatchReportStageUserEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchPause$Type extends MessageType<MsgMatchPause> {
    constructor() {
        super("bpt.broker.match.MsgMatchPause", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pauseBeginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pauseEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchPause>): MsgMatchPause {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.pauseBeginAt = 0;
        message.pauseEndAt = 0;
        message.registerDeadline = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchPause>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchPause): MsgMatchPause {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 pauseBeginAt */ 2:
                    message.pauseBeginAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseEndAt */ 3:
                    message.pauseEndAt = reader.int64().toNumber();
                    break;
                case /* int64 registerDeadline */ 4:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchPause, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 pauseBeginAt = 2; */
        if (message.pauseBeginAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.pauseBeginAt);
        /* int64 pauseEndAt = 3; */
        if (message.pauseEndAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.pauseEndAt);
        /* int64 registerDeadline = 4; */
        if (message.registerDeadline !== 0)
            writer.tag(4, WireType.Varint).int64(message.registerDeadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchPause
 */
export const MsgMatchPause = new MsgMatchPause$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchResume$Type extends MessageType<MsgMatchResume> {
    constructor() {
        super("bpt.broker.match.MsgMatchResume", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "resumeAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchResume>): MsgMatchResume {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.resumeAt = 0;
        message.registerDeadline = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchResume>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchResume): MsgMatchResume {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 resumeAt */ 2:
                    message.resumeAt = reader.int64().toNumber();
                    break;
                case /* int64 registerDeadline */ 3:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchResume, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 resumeAt = 2; */
        if (message.resumeAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.resumeAt);
        /* int64 registerDeadline = 3; */
        if (message.registerDeadline !== 0)
            writer.tag(3, WireType.Varint).int64(message.registerDeadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchResume
 */
export const MsgMatchResume = new MsgMatchResume$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgBreakTime$Type extends MessageType<MsgBreakTime> {
    constructor() {
        super("bpt.broker.match.MsgBreakTime", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "breakTimeStartAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "breakTimeStopAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "blindStructure", kind: "message", T: () => BlindStructure },
            { no: 6, name: "pauseConf", kind: "message", T: () => PauseConf }
        ]);
    }
    create(value?: PartialMessage<MsgBreakTime>): MsgBreakTime {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.breakTimeStartAt = 0;
        message.breakTimeStopAt = 0;
        message.blindLevel = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgBreakTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgBreakTime): MsgBreakTime {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 breakTimeStartAt */ 2:
                    message.breakTimeStartAt = reader.int64().toNumber();
                    break;
                case /* int64 breakTimeStopAt */ 3:
                    message.breakTimeStopAt = reader.int64().toNumber();
                    break;
                case /* uint32 blindLevel */ 4:
                    message.blindLevel = reader.uint32();
                    break;
                case /* bpt.match.common.v1.BlindStructure blindStructure */ 5:
                    message.blindStructure = BlindStructure.internalBinaryRead(reader, reader.uint32(), options, message.blindStructure);
                    break;
                case /* bpt.match.common.v1.PauseConf pauseConf */ 6:
                    message.pauseConf = PauseConf.internalBinaryRead(reader, reader.uint32(), options, message.pauseConf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgBreakTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 breakTimeStartAt = 2; */
        if (message.breakTimeStartAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.breakTimeStartAt);
        /* int64 breakTimeStopAt = 3; */
        if (message.breakTimeStopAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.breakTimeStopAt);
        /* uint32 blindLevel = 4; */
        if (message.blindLevel !== 0)
            writer.tag(4, WireType.Varint).uint32(message.blindLevel);
        /* bpt.match.common.v1.BlindStructure blindStructure = 5; */
        if (message.blindStructure)
            BlindStructure.internalBinaryWrite(message.blindStructure, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.PauseConf pauseConf = 6; */
        if (message.pauseConf)
            PauseConf.internalBinaryWrite(message.pauseConf, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgBreakTime
 */
export const MsgBreakTime = new MsgBreakTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StageUser$Type extends MessageType<StageUser> {
    constructor() {
        super("bpt.broker.match.StageUser", [
            { no: 1, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "Uid", kind: "scalar", localName: "Uid", jsonName: "Uid", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Chips", kind: "scalar", localName: "Chips", jsonName: "Chips", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<StageUser>): StageUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.Uid = 0;
        message.Chips = 0;
        if (value !== undefined)
            reflectionMergePartial<StageUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StageUser): StageUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 rank */ 1:
                    message.rank = reader.uint32();
                    break;
                case /* int64 Uid = 2 [json_name = "Uid"];*/ 2:
                    message.Uid = reader.int64().toNumber();
                    break;
                case /* uint64 Chips = 3 [json_name = "Chips"];*/ 3:
                    message.Chips = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StageUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).uint32(message.rank);
        /* int64 Uid = 2 [json_name = "Uid"]; */
        if (message.Uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.Uid);
        /* uint64 Chips = 3 [json_name = "Chips"]; */
        if (message.Chips !== 0)
            writer.tag(3, WireType.Varint).uint64(message.Chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.StageUser
 */
export const StageUser = new StageUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeferRewardNoticeTask$Type extends MessageType<DeferRewardNoticeTask> {
    constructor() {
        super("bpt.broker.match.DeferRewardNoticeTask", [
            { no: 1, name: "matchID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeferRewardNoticeTask>): DeferRewardNoticeTask {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchID = 0;
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<DeferRewardNoticeTask>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeferRewardNoticeTask): DeferRewardNoticeTask {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchID */ 1:
                    message.matchID = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeferRewardNoticeTask, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchID = 1; */
        if (message.matchID !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchID);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.DeferRewardNoticeTask
 */
export const DeferRewardNoticeTask = new DeferRewardNoticeTask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserRegister$Type extends MessageType<MsgUserRegister> {
    constructor() {
        super("bpt.broker.match.MsgUserRegister", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "basePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 5, name: "buyInAssets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 6, name: "maxReentry", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "reEntryCost", kind: "message", T: () => SimpleAsset },
            { no: 8, name: "addonCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "addOnCost", kind: "message", T: () => SimpleAsset },
            { no: 10, name: "startingStack", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "minPlayer", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "maxPlayer", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "mainMatchType", kind: "enum", T: () => ["bpt.match.common.v1.MainMatchType", MainMatchType] },
            { no: 14, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "channelName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 17, name: "lateReg", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserRegister>): MsgUserRegister {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.matchName = "";
        message.basePool = [];
        message.buyInAssets = [];
        message.maxReentry = 0;
        message.addonCnt = 0;
        message.startingStack = 0;
        message.minPlayer = 0;
        message.maxPlayer = 0;
        message.mainMatchType = 0;
        message.startAt = 0;
        message.channelName = "";
        message.matchType = 0;
        message.lateReg = false;
        if (value !== undefined)
            reflectionMergePartial<MsgUserRegister>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserRegister): MsgUserRegister {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string matchName */ 3:
                    message.matchName = reader.string();
                    break;
                case /* repeated bpt.common.SimpleAsset basePool */ 4:
                    message.basePool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset buyInAssets */ 5:
                    message.buyInAssets.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 maxReentry */ 6:
                    message.maxReentry = reader.int32();
                    break;
                case /* bpt.common.SimpleAsset reEntryCost */ 7:
                    message.reEntryCost = SimpleAsset.internalBinaryRead(reader, reader.uint32(), options, message.reEntryCost);
                    break;
                case /* int32 addonCnt */ 8:
                    message.addonCnt = reader.int32();
                    break;
                case /* bpt.common.SimpleAsset addOnCost */ 9:
                    message.addOnCost = SimpleAsset.internalBinaryRead(reader, reader.uint32(), options, message.addOnCost);
                    break;
                case /* uint64 startingStack */ 10:
                    message.startingStack = reader.uint64().toNumber();
                    break;
                case /* int32 minPlayer */ 11:
                    message.minPlayer = reader.int32();
                    break;
                case /* int32 maxPlayer */ 12:
                    message.maxPlayer = reader.int32();
                    break;
                case /* bpt.match.common.v1.MainMatchType mainMatchType */ 13:
                    message.mainMatchType = reader.int32();
                    break;
                case /* int64 startAt */ 14:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* string channelName */ 15:
                    message.channelName = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 16:
                    message.matchType = reader.int32();
                    break;
                case /* bool lateReg */ 17:
                    message.lateReg = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserRegister, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string matchName = 3; */
        if (message.matchName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchName);
        /* repeated bpt.common.SimpleAsset basePool = 4; */
        for (let i = 0; i < message.basePool.length; i++)
            SimpleAsset.internalBinaryWrite(message.basePool[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset buyInAssets = 5; */
        for (let i = 0; i < message.buyInAssets.length; i++)
            SimpleAsset.internalBinaryWrite(message.buyInAssets[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 maxReentry = 6; */
        if (message.maxReentry !== 0)
            writer.tag(6, WireType.Varint).int32(message.maxReentry);
        /* bpt.common.SimpleAsset reEntryCost = 7; */
        if (message.reEntryCost)
            SimpleAsset.internalBinaryWrite(message.reEntryCost, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 addonCnt = 8; */
        if (message.addonCnt !== 0)
            writer.tag(8, WireType.Varint).int32(message.addonCnt);
        /* bpt.common.SimpleAsset addOnCost = 9; */
        if (message.addOnCost)
            SimpleAsset.internalBinaryWrite(message.addOnCost, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* uint64 startingStack = 10; */
        if (message.startingStack !== 0)
            writer.tag(10, WireType.Varint).uint64(message.startingStack);
        /* int32 minPlayer = 11; */
        if (message.minPlayer !== 0)
            writer.tag(11, WireType.Varint).int32(message.minPlayer);
        /* int32 maxPlayer = 12; */
        if (message.maxPlayer !== 0)
            writer.tag(12, WireType.Varint).int32(message.maxPlayer);
        /* bpt.match.common.v1.MainMatchType mainMatchType = 13; */
        if (message.mainMatchType !== 0)
            writer.tag(13, WireType.Varint).int32(message.mainMatchType);
        /* int64 startAt = 14; */
        if (message.startAt !== 0)
            writer.tag(14, WireType.Varint).int64(message.startAt);
        /* string channelName = 15; */
        if (message.channelName !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.channelName);
        /* bpt.match.common.v1.MatchType matchType = 16; */
        if (message.matchType !== 0)
            writer.tag(16, WireType.Varint).int32(message.matchType);
        /* bool lateReg = 17; */
        if (message.lateReg !== false)
            writer.tag(17, WireType.Varint).bool(message.lateReg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgUserRegister
 */
export const MsgUserRegister = new MsgUserRegister$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserUnRegister$Type extends MessageType<MsgUserUnRegister> {
    constructor() {
        super("bpt.broker.match.MsgUserUnRegister", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "maxReentry", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "reEntryCost", kind: "message", T: () => SimpleAsset },
            { no: 9, name: "addOnCost", kind: "message", T: () => SimpleAsset },
            { no: 10, name: "startingStack", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "minPlayer", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "maxPlayer", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "basePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 5, name: "buyInAssets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 8, name: "addonCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "mainMatchType", kind: "enum", T: () => ["bpt.match.common.v1.MainMatchType", MainMatchType] },
            { no: 14, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "channelName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] }
        ]);
    }
    create(value?: PartialMessage<MsgUserUnRegister>): MsgUserUnRegister {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.matchName = "";
        message.maxReentry = 0;
        message.startingStack = 0;
        message.minPlayer = 0;
        message.maxPlayer = 0;
        message.basePool = [];
        message.buyInAssets = [];
        message.addonCnt = 0;
        message.mainMatchType = 0;
        message.startAt = 0;
        message.channelName = "";
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserUnRegister>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserUnRegister): MsgUserUnRegister {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string matchName */ 3:
                    message.matchName = reader.string();
                    break;
                case /* int32 maxReentry */ 6:
                    message.maxReentry = reader.int32();
                    break;
                case /* bpt.common.SimpleAsset reEntryCost */ 7:
                    message.reEntryCost = SimpleAsset.internalBinaryRead(reader, reader.uint32(), options, message.reEntryCost);
                    break;
                case /* bpt.common.SimpleAsset addOnCost */ 9:
                    message.addOnCost = SimpleAsset.internalBinaryRead(reader, reader.uint32(), options, message.addOnCost);
                    break;
                case /* uint64 startingStack */ 10:
                    message.startingStack = reader.uint64().toNumber();
                    break;
                case /* int32 minPlayer */ 11:
                    message.minPlayer = reader.int32();
                    break;
                case /* int32 maxPlayer */ 12:
                    message.maxPlayer = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset basePool */ 4:
                    message.basePool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset buyInAssets */ 5:
                    message.buyInAssets.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 addonCnt */ 8:
                    message.addonCnt = reader.int32();
                    break;
                case /* bpt.match.common.v1.MainMatchType mainMatchType */ 13:
                    message.mainMatchType = reader.int32();
                    break;
                case /* int64 startAt */ 14:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* string channelName */ 15:
                    message.channelName = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 16:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserUnRegister, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string matchName = 3; */
        if (message.matchName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchName);
        /* int32 maxReentry = 6; */
        if (message.maxReentry !== 0)
            writer.tag(6, WireType.Varint).int32(message.maxReentry);
        /* bpt.common.SimpleAsset reEntryCost = 7; */
        if (message.reEntryCost)
            SimpleAsset.internalBinaryWrite(message.reEntryCost, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SimpleAsset addOnCost = 9; */
        if (message.addOnCost)
            SimpleAsset.internalBinaryWrite(message.addOnCost, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* uint64 startingStack = 10; */
        if (message.startingStack !== 0)
            writer.tag(10, WireType.Varint).uint64(message.startingStack);
        /* int32 minPlayer = 11; */
        if (message.minPlayer !== 0)
            writer.tag(11, WireType.Varint).int32(message.minPlayer);
        /* int32 maxPlayer = 12; */
        if (message.maxPlayer !== 0)
            writer.tag(12, WireType.Varint).int32(message.maxPlayer);
        /* repeated bpt.common.SimpleAsset basePool = 4; */
        for (let i = 0; i < message.basePool.length; i++)
            SimpleAsset.internalBinaryWrite(message.basePool[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset buyInAssets = 5; */
        for (let i = 0; i < message.buyInAssets.length; i++)
            SimpleAsset.internalBinaryWrite(message.buyInAssets[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 addonCnt = 8; */
        if (message.addonCnt !== 0)
            writer.tag(8, WireType.Varint).int32(message.addonCnt);
        /* bpt.match.common.v1.MainMatchType mainMatchType = 13; */
        if (message.mainMatchType !== 0)
            writer.tag(13, WireType.Varint).int32(message.mainMatchType);
        /* int64 startAt = 14; */
        if (message.startAt !== 0)
            writer.tag(14, WireType.Varint).int64(message.startAt);
        /* string channelName = 15; */
        if (message.channelName !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.channelName);
        /* bpt.match.common.v1.MatchType matchType = 16; */
        if (message.matchType !== 0)
            writer.tag(16, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgUserUnRegister
 */
export const MsgUserUnRegister = new MsgUserUnRegister$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserEliminate$Type extends MessageType<MsgUserEliminate> {
    constructor() {
        super("bpt.broker.match.MsgUserEliminate", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 5, name: "rounds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "totalPrizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 7, name: "basePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 8, name: "durationInSeconds", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "reEntryCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "totalReEntryCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 11, name: "reBuyCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "totalReBuyCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 13, name: "addOnCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "totalAddOnCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset }
        ]);
    }
    create(value?: PartialMessage<MsgUserEliminate>): MsgUserEliminate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.matchName = "";
        message.matchType = 0;
        message.rounds = 0;
        message.totalPrizePool = [];
        message.basePool = [];
        message.durationInSeconds = 0;
        message.reEntryCnt = 0;
        message.totalReEntryCost = [];
        message.reBuyCnt = 0;
        message.totalReBuyCost = [];
        message.addOnCnt = 0;
        message.totalAddOnCost = [];
        if (value !== undefined)
            reflectionMergePartial<MsgUserEliminate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserEliminate): MsgUserEliminate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string matchName */ 3:
                    message.matchName = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* int32 rounds */ 5:
                    message.rounds = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalPrizePool */ 6:
                    message.totalPrizePool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset basePool */ 7:
                    message.basePool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 durationInSeconds */ 8:
                    message.durationInSeconds = reader.int64().toNumber();
                    break;
                case /* int32 reEntryCnt */ 9:
                    message.reEntryCnt = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalReEntryCost */ 10:
                    message.totalReEntryCost.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 reBuyCnt */ 11:
                    message.reBuyCnt = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalReBuyCost */ 12:
                    message.totalReBuyCost.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 addOnCnt */ 13:
                    message.addOnCnt = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalAddOnCost */ 14:
                    message.totalAddOnCost.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserEliminate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string matchName = 3; */
        if (message.matchName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchName);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* int32 rounds = 5; */
        if (message.rounds !== 0)
            writer.tag(5, WireType.Varint).int32(message.rounds);
        /* repeated bpt.common.SimpleAsset totalPrizePool = 6; */
        for (let i = 0; i < message.totalPrizePool.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalPrizePool[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset basePool = 7; */
        for (let i = 0; i < message.basePool.length; i++)
            SimpleAsset.internalBinaryWrite(message.basePool[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int64 durationInSeconds = 8; */
        if (message.durationInSeconds !== 0)
            writer.tag(8, WireType.Varint).int64(message.durationInSeconds);
        /* int32 reEntryCnt = 9; */
        if (message.reEntryCnt !== 0)
            writer.tag(9, WireType.Varint).int32(message.reEntryCnt);
        /* repeated bpt.common.SimpleAsset totalReEntryCost = 10; */
        for (let i = 0; i < message.totalReEntryCost.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalReEntryCost[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int32 reBuyCnt = 11; */
        if (message.reBuyCnt !== 0)
            writer.tag(11, WireType.Varint).int32(message.reBuyCnt);
        /* repeated bpt.common.SimpleAsset totalReBuyCost = 12; */
        for (let i = 0; i < message.totalReBuyCost.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalReBuyCost[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* int32 addOnCnt = 13; */
        if (message.addOnCnt !== 0)
            writer.tag(13, WireType.Varint).int32(message.addOnCnt);
        /* repeated bpt.common.SimpleAsset totalAddOnCost = 14; */
        for (let i = 0; i < message.totalAddOnCost.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalAddOnCost[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgUserEliminate
 */
export const MsgUserEliminate = new MsgUserEliminate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserGameOver$Type extends MessageType<MsgUserGameOver> {
    constructor() {
        super("bpt.broker.match.MsgUserGameOver", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 5, name: "rounds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "totalPrizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 7, name: "basePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 8, name: "userPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 9, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "remainChips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "durationInSeconds", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "reEntryCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "totalReEntryCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 14, name: "reBuyCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "totalReBuyCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 16, name: "addOnCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "totalAddOnCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset }
        ]);
    }
    create(value?: PartialMessage<MsgUserGameOver>): MsgUserGameOver {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.matchName = "";
        message.matchType = 0;
        message.rounds = 0;
        message.totalPrizePool = [];
        message.basePool = [];
        message.userPrizes = [];
        message.rank = 0;
        message.remainChips = 0;
        message.durationInSeconds = 0;
        message.reEntryCnt = 0;
        message.totalReEntryCost = [];
        message.reBuyCnt = 0;
        message.totalReBuyCost = [];
        message.addOnCnt = 0;
        message.totalAddOnCost = [];
        if (value !== undefined)
            reflectionMergePartial<MsgUserGameOver>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserGameOver): MsgUserGameOver {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string matchName */ 3:
                    message.matchName = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* int32 rounds */ 5:
                    message.rounds = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalPrizePool */ 6:
                    message.totalPrizePool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset basePool */ 7:
                    message.basePool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset userPrizes */ 8:
                    message.userPrizes.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 rank */ 9:
                    message.rank = reader.uint32();
                    break;
                case /* uint64 remainChips */ 10:
                    message.remainChips = reader.uint64().toNumber();
                    break;
                case /* int64 durationInSeconds */ 11:
                    message.durationInSeconds = reader.int64().toNumber();
                    break;
                case /* int32 reEntryCnt */ 12:
                    message.reEntryCnt = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalReEntryCost */ 13:
                    message.totalReEntryCost.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 reBuyCnt */ 14:
                    message.reBuyCnt = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalReBuyCost */ 15:
                    message.totalReBuyCost.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 addOnCnt */ 16:
                    message.addOnCnt = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset totalAddOnCost */ 17:
                    message.totalAddOnCost.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserGameOver, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string matchName = 3; */
        if (message.matchName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchName);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* int32 rounds = 5; */
        if (message.rounds !== 0)
            writer.tag(5, WireType.Varint).int32(message.rounds);
        /* repeated bpt.common.SimpleAsset totalPrizePool = 6; */
        for (let i = 0; i < message.totalPrizePool.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalPrizePool[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset basePool = 7; */
        for (let i = 0; i < message.basePool.length; i++)
            SimpleAsset.internalBinaryWrite(message.basePool[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset userPrizes = 8; */
        for (let i = 0; i < message.userPrizes.length; i++)
            SimpleAsset.internalBinaryWrite(message.userPrizes[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* uint32 rank = 9; */
        if (message.rank !== 0)
            writer.tag(9, WireType.Varint).uint32(message.rank);
        /* uint64 remainChips = 10; */
        if (message.remainChips !== 0)
            writer.tag(10, WireType.Varint).uint64(message.remainChips);
        /* int64 durationInSeconds = 11; */
        if (message.durationInSeconds !== 0)
            writer.tag(11, WireType.Varint).int64(message.durationInSeconds);
        /* int32 reEntryCnt = 12; */
        if (message.reEntryCnt !== 0)
            writer.tag(12, WireType.Varint).int32(message.reEntryCnt);
        /* repeated bpt.common.SimpleAsset totalReEntryCost = 13; */
        for (let i = 0; i < message.totalReEntryCost.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalReEntryCost[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* int32 reBuyCnt = 14; */
        if (message.reBuyCnt !== 0)
            writer.tag(14, WireType.Varint).int32(message.reBuyCnt);
        /* repeated bpt.common.SimpleAsset totalReBuyCost = 15; */
        for (let i = 0; i < message.totalReBuyCost.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalReBuyCost[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* int32 addOnCnt = 16; */
        if (message.addOnCnt !== 0)
            writer.tag(16, WireType.Varint).int32(message.addOnCnt);
        /* repeated bpt.common.SimpleAsset totalAddOnCost = 17; */
        for (let i = 0; i < message.totalAddOnCost.length; i++)
            SimpleAsset.internalBinaryWrite(message.totalAddOnCost[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgUserGameOver
 */
export const MsgUserGameOver = new MsgUserGameOver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SettlementUser$Type extends MessageType<SettlementUser> {
    constructor() {
        super("bpt.broker.match.SettlementUser", [
            { no: 1, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "chips", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SettlementUser>): SettlementUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userID = 0;
        message.rank = 0;
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<SettlementUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SettlementUser): SettlementUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 userID */ 1:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* uint32 rank */ 2:
                    message.rank = reader.uint32();
                    break;
                case /* int64 chips */ 3:
                    message.chips = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SettlementUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 userID = 1; */
        if (message.userID !== 0)
            writer.tag(1, WireType.Varint).int64(message.userID);
        /* uint32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).uint32(message.rank);
        /* int64 chips = 3; */
        if (message.chips !== 0)
            writer.tag(3, WireType.Varint).int64(message.chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.SettlementUser
 */
export const SettlementUser = new SettlementUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchRegisterDeadline$Type extends MessageType<MsgMatchRegisterDeadline> {
    constructor() {
        super("bpt.broker.match.MsgMatchRegisterDeadline", [
            { no: 1, name: "tick", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "settlementUserList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettlementUser }
        ]);
    }
    create(value?: PartialMessage<MsgMatchRegisterDeadline>): MsgMatchRegisterDeadline {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tick = 0;
        message.matchKey = "";
        message.settlementUserList = [];
        if (value !== undefined)
            reflectionMergePartial<MsgMatchRegisterDeadline>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchRegisterDeadline): MsgMatchRegisterDeadline {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 tick */ 1:
                    message.tick = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.broker.match.SettlementUser settlementUserList */ 3:
                    message.settlementUserList.push(SettlementUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchRegisterDeadline, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 tick = 1; */
        if (message.tick !== 0)
            writer.tag(1, WireType.Varint).int64(message.tick);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.broker.match.SettlementUser settlementUserList = 3; */
        for (let i = 0; i < message.settlementUserList.length; i++)
            SettlementUser.internalBinaryWrite(message.settlementUserList[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchRegisterDeadline
 */
export const MsgMatchRegisterDeadline = new MsgMatchRegisterDeadline$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchUserSettlement$Type extends MessageType<MsgMatchUserSettlement> {
    constructor() {
        super("bpt.broker.match.MsgMatchUserSettlement", [
            { no: 1, name: "tick", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "settlementUserList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SettlementUser }
        ]);
    }
    create(value?: PartialMessage<MsgMatchUserSettlement>): MsgMatchUserSettlement {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tick = 0;
        message.matchKey = "";
        message.settlementUserList = [];
        if (value !== undefined)
            reflectionMergePartial<MsgMatchUserSettlement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchUserSettlement): MsgMatchUserSettlement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 tick */ 1:
                    message.tick = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.broker.match.SettlementUser settlementUserList */ 3:
                    message.settlementUserList.push(SettlementUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchUserSettlement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 tick = 1; */
        if (message.tick !== 0)
            writer.tag(1, WireType.Varint).int64(message.tick);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.broker.match.SettlementUser settlementUserList = 3; */
        for (let i = 0; i < message.settlementUserList.length; i++)
            SettlementUser.internalBinaryWrite(message.settlementUserList[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchUserSettlement
 */
export const MsgMatchUserSettlement = new MsgMatchUserSettlement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchOver$Type extends MessageType<MsgMatchOver> {
    constructor() {
        super("bpt.broker.match.MsgMatchOver", [
            { no: 1, name: "tick", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchResult", kind: "message", T: () => MsgMatchResult }
        ]);
    }
    create(value?: PartialMessage<MsgMatchOver>): MsgMatchOver {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tick = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgMatchOver>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchOver): MsgMatchOver {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 tick */ 1:
                    message.tick = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.broker.match.MsgMatchResult matchResult */ 3:
                    message.matchResult = MsgMatchResult.internalBinaryRead(reader, reader.uint32(), options, message.matchResult);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchOver, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 tick = 1; */
        if (message.tick !== 0)
            writer.tag(1, WireType.Varint).int64(message.tick);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.broker.match.MsgMatchResult matchResult = 3; */
        if (message.matchResult)
            MsgMatchResult.internalBinaryWrite(message.matchResult, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.broker.match.MsgMatchOver
 */
export const MsgMatchOver = new MsgMatchOver$Type();
/**
 * @generated ServiceType for protobuf service bpt.broker.match.BPTMatchBroker
 */
export const BPTMatchBroker = new ServiceType("bpt.broker.match.BPTMatchBroker", [
    { name: "MatchStart", options: {}, I: MsgMatchStart, O: Void },
    { name: "MatchResult", options: {}, I: MsgMatchResult, O: Void },
    { name: "UserMatchResult", options: {}, I: MsgUserMatchResult, O: Void },
    { name: "LuckyWinner", options: {}, I: MsgLuckyWinner, O: Void },
    { name: "UserMatchCharge", options: {}, I: MsgUserMatchCharges, O: Void },
    { name: "MatchRegisterDeadline", options: {}, I: MsgMatchRegisterDeadline, O: Void },
    { name: "MatchUserSettlement", options: {}, I: MsgMatchUserSettlement, O: Void },
    { name: "MatchOver", options: {}, I: MsgMatchOver, O: Void },
    { name: "MatchReachBubble", options: {}, I: MsgMatchReachBubble, O: Void },
    { name: "MatchReachFinalTable", options: {}, I: MsgMatchReachFinalTable, O: Void },
    { name: "RankChange", options: {}, I: MsgRankChange, O: Void },
    { name: "DesksSnapshot", options: {}, I: MsgDesksSnapshot, O: Void },
    { name: "ReEntryExhausted", options: {}, I: MsgReEntryExhausted, O: Void },
    { name: "MatchListCreatedCheckDeferred", options: {}, I: MsgMatchListCreatedCheckDeferred, O: Void },
    { name: "MatchDeferredEvent", options: {}, I: MsgMatchDeferredEvent, O: Void },
    { name: "MatchhubTransaction", options: {}, I: MsgMatchhubTransaction, O: Void },
    { name: "MatchReportStageUserEvent", options: {}, I: MsgMatchReportStageUserEvent, O: Void },
    { name: "MatchAllOver", options: {}, I: MsgMatchResult, O: Void },
    { name: "MatchCancel", options: {}, I: MsgMatchResult, O: Void }
]);
