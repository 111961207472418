// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "gaw/asset/wallet/v1/token.proto" (package "gaw.asset.wallet.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ChainID } from "../../../base/base";
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Token
 */
export interface Token {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.TokenType type = 2;
     */
    type: TokenType;
    /**
     * @generated from protobuf field: string contract = 3;
     */
    contract: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string symbol = 5;
     */
    symbol: string;
    /**
     * @generated from protobuf field: int32 decimals = 6;
     */
    decimals: number;
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.Unit
 */
export enum Unit {
    /**
     * 1
     *
     * @generated from protobuf enum value: Wei = 0;
     */
    Wei = 0,
    /**
     * 1e9
     *
     * @generated from protobuf enum value: Gwei = 1;
     */
    Gwei = 1,
    /**
     * 1e18
     *
     * @generated from protobuf enum value: Ehter = 2;
     */
    Ehter = 2
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.TokenType
 */
export enum TokenType {
    /**
     * @generated from protobuf enum value: TokenTypeNative = 0;
     */
    TokenTypeNative = 0,
    /**
     * @generated from protobuf enum value: TokenTypeERC20 = 1;
     */
    TokenTypeERC20 = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class Token$Type extends MessageType<Token> {
    constructor() {
        super("gaw.asset.wallet.v1.Token", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "type", kind: "enum", T: () => ["gaw.asset.wallet.v1.TokenType", TokenType] },
            { no: 3, name: "contract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "decimals", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Token>): Token {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.type = 0;
        message.contract = "";
        message.name = "";
        message.symbol = "";
        message.decimals = 0;
        if (value !== undefined)
            reflectionMergePartial<Token>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Token): Token {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* gaw.asset.wallet.v1.TokenType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* string contract */ 3:
                    message.contract = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string symbol */ 5:
                    message.symbol = reader.string();
                    break;
                case /* int32 decimals */ 6:
                    message.decimals = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Token, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* gaw.asset.wallet.v1.TokenType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* string contract = 3; */
        if (message.contract !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contract);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string symbol = 5; */
        if (message.symbol !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.symbol);
        /* int32 decimals = 6; */
        if (message.decimals !== 0)
            writer.tag(6, WireType.Varint).int32(message.decimals);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Token
 */
export const Token = new Token$Type();
