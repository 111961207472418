// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/userinfo/v1/userinfo.proto" (package "bpt.hall.userinfo.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Userinfo } from "./userinfo";
import type { UpdateUserDataResp } from "./userinfo";
import type { UpdateUserDataReq } from "./userinfo";
import type { FuzzySearchClubUsersResp } from "./userinfo";
import type { FuzzySearchClubUsersReq } from "./userinfo";
import type { UpdateClubIdResp } from "./userinfo";
import type { UpdateClubIdReq } from "./userinfo";
import type { AddNoticeResp } from "./userinfo";
import type { AddNoticeReq } from "./userinfo";
import type { InitUserResp } from "./userinfo";
import type { InitUserReq } from "./userinfo";
import type { GetTableViewStatusReq } from "./userinfo";
import type { GetWaitBigBlindStatusResp } from "./userinfo";
import type { GetWaitBigBlindStatusReq } from "./userinfo";
import type { BatchGetUserShowdownSwitchResp } from "./userinfo";
import type { BatchGetUserShowdownSwitchReq } from "./userinfo";
import type { GlobalSearchUsersResp } from "./userinfo";
import type { GlobalSearchUsersReq } from "./userinfo";
import type { BatchGetUserVoiceConfigResp } from "./userinfo";
import type { BatchGetUserVoiceConfigReq } from "./userinfo";
import type { SaveUserGameConfigReq } from "./userinfo";
import type { GetUserGameConfigResp } from "./userinfo";
import type { CloseNoticeResp } from "./userinfo";
import type { CloseNoticeReq } from "./userinfo";
import type { ListNoticeResp } from "./userinfo";
import type { ListNoticeReq } from "./userinfo";
import type { WalletSearchUsersResp } from "./userinfo";
import type { WalletSearchUsersReq } from "./userinfo";
import type { FuzzySearchUsersResp } from "./userinfo";
import type { FuzzySearchUsersReq } from "./userinfo";
import type { UpdateTagResp } from "./userinfo";
import type { UpdateTagReq } from "./userinfo";
import type { ListTagResp } from "./userinfo";
import type { ListTagReq } from "./userinfo";
import type { DeleteTagReq } from "./userinfo";
import type { BatchGetTagResp } from "./userinfo";
import type { BatchGetTagReq } from "./userinfo";
import type { GetTagResp } from "./userinfo";
import type { GetTagReq } from "./userinfo";
import type { SaveTagResp } from "./userinfo";
import type { SaveTagReq } from "./userinfo";
import type { ListSocialAccountResp } from "./userinfo";
import type { ListSocialAccountReq } from "./userinfo";
import type { GetUserIntegratedInfoResp } from "./userinfo";
import type { GetUserIntegratedInfoReq } from "./userinfo";
import type { ListImagesFromAlbumResp } from "./userinfo";
import type { ListImagesFromAlbumReq } from "./userinfo";
import type { DeleteImagesFromAlbumReq } from "./userinfo";
import type { SaveImagesToAlbumResp } from "./userinfo";
import type { SaveImagesToAlbumReq } from "./userinfo";
import type { GetTableViewStatusResp } from "./userinfo";
import type { SetUserTableVoiceReq } from "./userinfo";
import type { SetTableViewStatusReq } from "./userinfo";
import type { ListUserChampionsResp } from "./userinfo";
import type { RerunStRankResp } from "./userinfo";
import type { RerunStRankReq } from "./userinfo";
import type { GetRankAndTop1TimesResp } from "./userinfo";
import type { GetRankAndTotalWinResp } from "./userinfo";
import type { GetUserRankingResp } from "./userinfo";
import type { GetUserRankingReq } from "./userinfo";
import type { ListUserPrizeRankingsResp } from "./userinfo";
import type { ListUserPrizeRankingsReq } from "./userinfo";
import type { ListUserPrizeRanksResp } from "./userinfo";
import type { ListUserPrizeRanksReq } from "./userinfo";
import type { TopBonusListResp } from "./userinfo";
import type { TopChampionListResp } from "./userinfo";
import type { PageListInvitationCodeResp } from "./userinfo";
import type { PageListInvitationCodeReq } from "./userinfo";
import type { LoginOutResp } from "./userinfo";
import type { LoginOutReq } from "./userinfo";
import type { GetSTSResp } from "./userinfo";
import type { GetSTSReq } from "./userinfo";
import type { RefreshTokenResp } from "./userinfo";
import type { RefreshTokenReq } from "./userinfo";
import type { LoginByPlatformResp } from "./userinfo";
import type { LoginByPlatformReq } from "./userinfo";
import type { LoginByExchangeResp } from "./userinfo";
import type { LoginByExchangeReq } from "./userinfo";
import type { GetAutoBuyInStatusResp } from "./userinfo";
import type { GetAutoBuyInStatusReq } from "./userinfo";
import type { SetWaitBigBlindStatusReq } from "./userinfo";
import type { SetAutoBuyInStatusReq } from "./userinfo";
import type { UpdateUserReq } from "./userinfo";
import type { BatchGetSimpleUserResp } from "./userinfo";
import type { BatchGetSimpleUserReq } from "./userinfo";
import type { BatchGetUserResp } from "./userinfo";
import type { BatchGetUserReq } from "./userinfo";
import type { BatchGetUserStyleStatResp } from "./userinfo";
import type { BatchGetUserStyleStatReq } from "./userinfo";
import type { GetUserStyleStatResp } from "./userinfo";
import type { GetUserStyleStatReq } from "./userinfo";
import type { Void } from "../../../base/base";
import type { GetUserOpsInfoResp } from "./userinfo";
import type { GetUserOpsInfoReq } from "./userinfo";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetUserResp } from "./userinfo";
import type { GetUserReq } from "./userinfo";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service bpt.hall.userinfo.v1.Userinfo
 */
export interface IUserinfoClient {
    /**
     * ------------------对外接口 Start------------------
     *  查询用户信息
     *
     * @generated from protobuf rpc: GetUser(bpt.hall.userinfo.v1.GetUserReq) returns (bpt.hall.userinfo.v1.GetUserResp);
     */
    getUser(input: GetUserReq, options?: RpcOptions): UnaryCall<GetUserReq, GetUserResp>;
    /**
     * @generated from protobuf rpc: GetUserOpsInfo(bpt.hall.userinfo.v1.GetUserOpsInfoReq) returns (bpt.hall.userinfo.v1.GetUserOpsInfoResp);
     */
    getUserOpsInfo(input: GetUserOpsInfoReq, options?: RpcOptions): UnaryCall<GetUserOpsInfoReq, GetUserOpsInfoResp>;
    /**
     *  查询用户自己的信息
     *
     * @generated from protobuf rpc: GetMySelf(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetUserResp);
     */
    getMySelf(input: Void, options?: RpcOptions): UnaryCall<Void, GetUserResp>;
    /**
     *  查询用户风格数据
     *
     * @generated from protobuf rpc: GetUserStyleStat(bpt.hall.userinfo.v1.GetUserStyleStatReq) returns (bpt.hall.userinfo.v1.GetUserStyleStatResp);
     */
    getUserStyleStat(input: GetUserStyleStatReq, options?: RpcOptions): UnaryCall<GetUserStyleStatReq, GetUserStyleStatResp>;
    /**
     *  批量查询用户风格数据
     *
     * @generated from protobuf rpc: BatchGetUserStyleStat(bpt.hall.userinfo.v1.BatchGetUserStyleStatReq) returns (bpt.hall.userinfo.v1.BatchGetUserStyleStatResp);
     */
    batchGetUserStyleStat(input: BatchGetUserStyleStatReq, options?: RpcOptions): UnaryCall<BatchGetUserStyleStatReq, BatchGetUserStyleStatResp>;
    /**
     *  批量获取用户信息
     *
     * @generated from protobuf rpc: BatchGetUser(bpt.hall.userinfo.v1.BatchGetUserReq) returns (bpt.hall.userinfo.v1.BatchGetUserResp);
     */
    batchGetUser(input: BatchGetUserReq, options?: RpcOptions): UnaryCall<BatchGetUserReq, BatchGetUserResp>;
    /**
     *  批量获取用户简略信息
     *
     * @generated from protobuf rpc: BatchGetSimpleUser(bpt.hall.userinfo.v1.BatchGetSimpleUserReq) returns (bpt.hall.userinfo.v1.BatchGetSimpleUserResp);
     */
    batchGetSimpleUser(input: BatchGetSimpleUserReq, options?: RpcOptions): UnaryCall<BatchGetSimpleUserReq, BatchGetSimpleUserResp>;
    /**
     * 修改用户信息
     *
     * @generated from protobuf rpc: UpdateUser(bpt.hall.userinfo.v1.UpdateUserReq) returns (bpt.hall.userinfo.v1.GetUserResp);
     */
    updateUser(input: UpdateUserReq, options?: RpcOptions): UnaryCall<UpdateUserReq, GetUserResp>;
    // 游戏设定相关 

    /**
     * 设置自动买入状态 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetAutoBuyInStatus(bpt.hall.userinfo.v1.SetAutoBuyInStatusReq) returns (bpt.base.Void);
     */
    setAutoBuyInStatus(input: SetAutoBuyInStatusReq, options?: RpcOptions): UnaryCall<SetAutoBuyInStatusReq, Void>;
    /**
     * 设定等待大盲状态 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetWaitBigBlindStatus(bpt.hall.userinfo.v1.SetWaitBigBlindStatusReq) returns (bpt.base.Void);
     */
    setWaitBigBlindStatus(input: SetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<SetWaitBigBlindStatusReq, Void>;
    /**
     * 获取自动买入状态 过时,请使用GetGameConfig
     *
     * @generated from protobuf rpc: GetAutoBuyInStatus(bpt.hall.userinfo.v1.GetAutoBuyInStatusReq) returns (bpt.hall.userinfo.v1.GetAutoBuyInStatusResp);
     */
    getAutoBuyInStatus(input: GetAutoBuyInStatusReq, options?: RpcOptions): UnaryCall<GetAutoBuyInStatusReq, GetAutoBuyInStatusResp>;
    /**
     * 登录
     *
     * @generated from protobuf rpc: LoginByExchange(bpt.hall.userinfo.v1.LoginByExchangeReq) returns (bpt.hall.userinfo.v1.LoginByExchangeResp);
     */
    loginByExchange(input: LoginByExchangeReq, options?: RpcOptions): UnaryCall<LoginByExchangeReq, LoginByExchangeResp>;
    /**
     * 登录
     *
     * @generated from protobuf rpc: LoginByPlatform(bpt.hall.userinfo.v1.LoginByPlatformReq) returns (bpt.hall.userinfo.v1.LoginByPlatformResp);
     */
    loginByPlatform(input: LoginByPlatformReq, options?: RpcOptions): UnaryCall<LoginByPlatformReq, LoginByPlatformResp>;
    /**
     * 刷新token
     *
     * @generated from protobuf rpc: RefreshToken(bpt.hall.userinfo.v1.RefreshTokenReq) returns (bpt.hall.userinfo.v1.RefreshTokenResp);
     */
    refreshToken(input: RefreshTokenReq, options?: RpcOptions): UnaryCall<RefreshTokenReq, RefreshTokenResp>;
    /**
     * oss
     *
     * @generated from protobuf rpc: GetSTS(bpt.hall.userinfo.v1.GetSTSReq) returns (bpt.hall.userinfo.v1.GetSTSResp);
     */
    getSTS(input: GetSTSReq, options?: RpcOptions): UnaryCall<GetSTSReq, GetSTSResp>;
    /**
     * 登出
     *
     * @generated from protobuf rpc: LoginOut(bpt.hall.userinfo.v1.LoginOutReq) returns (bpt.hall.userinfo.v1.LoginOutResp);
     */
    loginOut(input: LoginOutReq, options?: RpcOptions): UnaryCall<LoginOutReq, LoginOutResp>;
    /**
     * @generated from protobuf rpc: PageListInvitationCode(bpt.hall.userinfo.v1.PageListInvitationCodeReq) returns (bpt.hall.userinfo.v1.PageListInvitationCodeResp);
     */
    pageListInvitationCode(input: PageListInvitationCodeReq, options?: RpcOptions): UnaryCall<PageListInvitationCodeReq, PageListInvitationCodeResp>;
    // 排行榜相关

    /**
     * 冠军榜
     *
     * @generated from protobuf rpc: TopChampionList(bpt.base.Void) returns (bpt.hall.userinfo.v1.TopChampionListResp);
     */
    topChampionList(input: Void, options?: RpcOptions): UnaryCall<Void, TopChampionListResp>;
    /**
     * 奖金榜
     *
     * @generated from protobuf rpc: TopBonusList(bpt.base.Void) returns (bpt.hall.userinfo.v1.TopBonusListResp);
     */
    topBonusList(input: Void, options?: RpcOptions): UnaryCall<Void, TopBonusListResp>;
    /**
     * 日周赛 首页使用
     *
     * @generated from protobuf rpc: ListUserPrizeRanks(bpt.hall.userinfo.v1.ListUserPrizeRanksReq) returns (bpt.hall.userinfo.v1.ListUserPrizeRanksResp);
     */
    listUserPrizeRanks(input: ListUserPrizeRanksReq, options?: RpcOptions): UnaryCall<ListUserPrizeRanksReq, ListUserPrizeRanksResp>;
    /**
     * 日周赛 排行榜
     *
     * @generated from protobuf rpc: ListUserPrizeRankings(bpt.hall.userinfo.v1.ListUserPrizeRankingsReq) returns (bpt.hall.userinfo.v1.ListUserPrizeRankingsResp);
     */
    listUserPrizeRankings(input: ListUserPrizeRankingsReq, options?: RpcOptions): UnaryCall<ListUserPrizeRankingsReq, ListUserPrizeRankingsResp>;
    /**
     * 日周赛用户个人排名和奖金
     *
     * @generated from protobuf rpc: GetUserRanking(bpt.hall.userinfo.v1.GetUserRankingReq) returns (bpt.hall.userinfo.v1.GetUserRankingResp);
     */
    getUserRanking(input: GetUserRankingReq, options?: RpcOptions): UnaryCall<GetUserRankingReq, GetUserRankingResp>;
    /**
     * 生成日周赛榜单
     *
     * @generated from protobuf rpc: GenerateUserPrizeRanks(bpt.base.Void) returns (bpt.base.Void);
     */
    generateUserPrizeRanks(input: Void, options?: RpcOptions): UnaryCall<Void, Void>;
    /**
     * 用户个人排名和累计赢得的奖励
     *
     * @generated from protobuf rpc: GetRankAndTotalWin(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetRankAndTotalWinResp);
     */
    getRankAndTotalWin(input: Void, options?: RpcOptions): UnaryCall<Void, GetRankAndTotalWinResp>;
    /**
     * 用户个人排名和累计top1次数
     *
     * @generated from protobuf rpc: GetRankAndTop1Times(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetRankAndTop1TimesResp);
     */
    getRankAndTop1Times(input: Void, options?: RpcOptions): UnaryCall<Void, GetRankAndTop1TimesResp>;
    /**
     * 某次统计数据失败，调用这个接口重新统计
     *
     * @generated from protobuf rpc: RerunStRank(bpt.hall.userinfo.v1.RerunStRankReq) returns (bpt.hall.userinfo.v1.RerunStRankResp);
     */
    rerunStRank(input: RerunStRankReq, options?: RpcOptions): UnaryCall<RerunStRankReq, RerunStRankResp>;
    /**
     * @generated from protobuf rpc: ListUserChampions(bpt.base.Void) returns (bpt.hall.userinfo.v1.ListUserChampionsResp);
     */
    listUserChampions(input: Void, options?: RpcOptions): UnaryCall<Void, ListUserChampionsResp>;
    /**
     * 设置多桌列表开启状态 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetTableViewStatus(bpt.hall.userinfo.v1.SetTableViewStatusReq) returns (bpt.base.Void);
     */
    setTableViewStatus(input: SetTableViewStatusReq, options?: RpcOptions): UnaryCall<SetTableViewStatusReq, Void>;
    /**
     * 设置用户游戏语音包 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetUserTableVoice(bpt.hall.userinfo.v1.SetUserTableVoiceReq) returns (bpt.base.Void);
     */
    setUserTableVoice(input: SetUserTableVoiceReq, options?: RpcOptions): UnaryCall<SetUserTableVoiceReq, Void>;
    /**
     * 获取多桌列表开启状态 过时,请使用GetGameConfig
     *
     * @generated from protobuf rpc: GetTableViewStatus(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetTableViewStatusResp);
     */
    getTableViewStatus(input: Void, options?: RpcOptions): UnaryCall<Void, GetTableViewStatusResp>;
    /**
     * 保存图片到相册
     *
     * @generated from protobuf rpc: SaveImagesToAlbum(bpt.hall.userinfo.v1.SaveImagesToAlbumReq) returns (bpt.hall.userinfo.v1.SaveImagesToAlbumResp);
     */
    saveImagesToAlbum(input: SaveImagesToAlbumReq, options?: RpcOptions): UnaryCall<SaveImagesToAlbumReq, SaveImagesToAlbumResp>;
    /**
     * 从相册删除图片
     *
     * @generated from protobuf rpc: DeleteImagesFromAlbum(bpt.hall.userinfo.v1.DeleteImagesFromAlbumReq) returns (bpt.base.Void);
     */
    deleteImagesFromAlbum(input: DeleteImagesFromAlbumReq, options?: RpcOptions): UnaryCall<DeleteImagesFromAlbumReq, Void>;
    /**
     * 获取相册图片
     *
     * @generated from protobuf rpc: ListImagesFromAlbum(bpt.hall.userinfo.v1.ListImagesFromAlbumReq) returns (bpt.hall.userinfo.v1.ListImagesFromAlbumResp);
     */
    listImagesFromAlbum(input: ListImagesFromAlbumReq, options?: RpcOptions): UnaryCall<ListImagesFromAlbumReq, ListImagesFromAlbumResp>;
    /**
     * 获取用户的综合数据，包含基本信息，生涯，社交关系等
     *
     * @generated from protobuf rpc: GetUserIntegratedInfo(bpt.hall.userinfo.v1.GetUserIntegratedInfoReq) returns (bpt.hall.userinfo.v1.GetUserIntegratedInfoResp);
     */
    getUserIntegratedInfo(input: GetUserIntegratedInfoReq, options?: RpcOptions): UnaryCall<GetUserIntegratedInfoReq, GetUserIntegratedInfoResp>;
    /**
     * 获取绑定信息平台，例如推特，fb等
     *
     * @generated from protobuf rpc: ListSocialAccount(bpt.hall.userinfo.v1.ListSocialAccountReq) returns (bpt.hall.userinfo.v1.ListSocialAccountResp);
     */
    listSocialAccount(input: ListSocialAccountReq, options?: RpcOptions): UnaryCall<ListSocialAccountReq, ListSocialAccountResp>;
    /**
     * 保存标签
     *
     * @generated from protobuf rpc: SaveTag(bpt.hall.userinfo.v1.SaveTagReq) returns (bpt.hall.userinfo.v1.SaveTagResp);
     */
    saveTag(input: SaveTagReq, options?: RpcOptions): UnaryCall<SaveTagReq, SaveTagResp>;
    /**
     * 获取标签
     *
     * @generated from protobuf rpc: GetTag(bpt.hall.userinfo.v1.GetTagReq) returns (bpt.hall.userinfo.v1.GetTagResp);
     */
    getTag(input: GetTagReq, options?: RpcOptions): UnaryCall<GetTagReq, GetTagResp>;
    /**
     * 批量获取标签
     *
     * @generated from protobuf rpc: BatchGetTag(bpt.hall.userinfo.v1.BatchGetTagReq) returns (bpt.hall.userinfo.v1.BatchGetTagResp);
     */
    batchGetTag(input: BatchGetTagReq, options?: RpcOptions): UnaryCall<BatchGetTagReq, BatchGetTagResp>;
    /**
     * 删除标签
     *
     * @generated from protobuf rpc: DeleteTag(bpt.hall.userinfo.v1.DeleteTagReq) returns (bpt.base.Void);
     */
    deleteTag(input: DeleteTagReq, options?: RpcOptions): UnaryCall<DeleteTagReq, Void>;
    /**
     * 分页获取标签
     *
     * @generated from protobuf rpc: ListTag(bpt.hall.userinfo.v1.ListTagReq) returns (bpt.hall.userinfo.v1.ListTagResp);
     */
    listTag(input: ListTagReq, options?: RpcOptions): UnaryCall<ListTagReq, ListTagResp>;
    /**
     * 更新标签
     *
     * @generated from protobuf rpc: UpdateTag(bpt.hall.userinfo.v1.UpdateTagReq) returns (bpt.hall.userinfo.v1.UpdateTagResp);
     */
    updateTag(input: UpdateTagReq, options?: RpcOptions): UnaryCall<UpdateTagReq, UpdateTagResp>;
    /**
     *  模糊搜索用户
     *
     * @generated from protobuf rpc: FuzzySearchUsers(bpt.hall.userinfo.v1.FuzzySearchUsersReq) returns (bpt.hall.userinfo.v1.FuzzySearchUsersResp);
     */
    fuzzySearchUsers(input: FuzzySearchUsersReq, options?: RpcOptions): UnaryCall<FuzzySearchUsersReq, FuzzySearchUsersResp>;
    /**
     *  模糊搜索用户(包含钱包)
     *
     * @generated from protobuf rpc: WalletSearchUsers(bpt.hall.userinfo.v1.WalletSearchUsersReq) returns (bpt.hall.userinfo.v1.WalletSearchUsersResp);
     */
    walletSearchUsers(input: WalletSearchUsersReq, options?: RpcOptions): UnaryCall<WalletSearchUsersReq, WalletSearchUsersResp>;
    /**
     * 获取首页通知
     *
     * @generated from protobuf rpc: ListNotice(bpt.hall.userinfo.v1.ListNoticeReq) returns (bpt.hall.userinfo.v1.ListNoticeResp);
     */
    listNotice(input: ListNoticeReq, options?: RpcOptions): UnaryCall<ListNoticeReq, ListNoticeResp>;
    /**
     * 关闭首页通知
     *
     * @generated from protobuf rpc: CloseNotice(bpt.hall.userinfo.v1.CloseNoticeReq) returns (bpt.hall.userinfo.v1.CloseNoticeResp);
     */
    closeNotice(input: CloseNoticeReq, options?: RpcOptions): UnaryCall<CloseNoticeReq, CloseNoticeResp>;
    /**
     * 获取游戏配置
     *
     * @generated from protobuf rpc: GetUserGameConfig(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetUserGameConfigResp);
     */
    getUserGameConfig(input: Void, options?: RpcOptions): UnaryCall<Void, GetUserGameConfigResp>;
    /**
     * 保存游戏配置
     *
     * @generated from protobuf rpc: SaveUserGameConfig(bpt.hall.userinfo.v1.SaveUserGameConfigReq) returns (bpt.base.Void);
     */
    saveUserGameConfig(input: SaveUserGameConfigReq, options?: RpcOptions): UnaryCall<SaveUserGameConfigReq, Void>;
    /**
     * 批量获取用户语音包资源配置
     *
     * @generated from protobuf rpc: BatchGetUserVoiceConfig(bpt.hall.userinfo.v1.BatchGetUserVoiceConfigReq) returns (bpt.hall.userinfo.v1.BatchGetUserVoiceConfigResp);
     */
    batchGetUserVoiceConfig(input: BatchGetUserVoiceConfigReq, options?: RpcOptions): UnaryCall<BatchGetUserVoiceConfigReq, BatchGetUserVoiceConfigResp>;
    /**
     * @generated from protobuf rpc: GlobalSearchUsers(bpt.hall.userinfo.v1.GlobalSearchUsersReq) returns (bpt.hall.userinfo.v1.GlobalSearchUsersResp);
     */
    globalSearchUsers(input: GlobalSearchUsersReq, options?: RpcOptions): UnaryCall<GlobalSearchUsersReq, GlobalSearchUsersResp>;
    /**
     * 批量获取玩家结算亮牌开关
     *
     * ------------------对外接口 End------------------
     *
     * @generated from protobuf rpc: BatchGetUserShowdownSwitch(bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchReq) returns (bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchResp);
     */
    batchGetUserShowdownSwitch(input: BatchGetUserShowdownSwitchReq, options?: RpcOptions): UnaryCall<BatchGetUserShowdownSwitchReq, BatchGetUserShowdownSwitchResp>;
    // ------------------内部接口 Start------------------

    /**
     * 获取等待大盲状态(内部调用）
     *
     * @generated from protobuf rpc: GetWaitBigBlindStatus(bpt.hall.userinfo.v1.GetWaitBigBlindStatusReq) returns (bpt.hall.userinfo.v1.GetWaitBigBlindStatusResp);
     */
    getWaitBigBlindStatus(input: GetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<GetWaitBigBlindStatusReq, GetWaitBigBlindStatusResp>;
    /**
     * 获取等待大盲状态（内部调用）
     *
     * @generated from protobuf rpc: GetWaitBigBlindStatusInternal(bpt.hall.userinfo.v1.GetWaitBigBlindStatusReq) returns (bpt.hall.userinfo.v1.GetWaitBigBlindStatusResp);
     */
    getWaitBigBlindStatusInternal(input: GetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<GetWaitBigBlindStatusReq, GetWaitBigBlindStatusResp>;
    /**
     * 设定等待大盲状态 (内部调用）
     *
     * @generated from protobuf rpc: SetWaitBigBlindStatusInternal(bpt.hall.userinfo.v1.SetWaitBigBlindStatusReq) returns (bpt.base.Void);
     */
    setWaitBigBlindStatusInternal(input: SetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<SetWaitBigBlindStatusReq, Void>;
    /**
     * 获取自动买入状态(内部调用）
     *
     * @generated from protobuf rpc: GetAutoBuyInStatusInternal(bpt.hall.userinfo.v1.GetAutoBuyInStatusReq) returns (bpt.hall.userinfo.v1.GetAutoBuyInStatusResp);
     */
    getAutoBuyInStatusInternal(input: GetAutoBuyInStatusReq, options?: RpcOptions): UnaryCall<GetAutoBuyInStatusReq, GetAutoBuyInStatusResp>;
    /**
     * @generated from protobuf rpc: GetTableViewStatusInternal(bpt.hall.userinfo.v1.GetTableViewStatusReq) returns (bpt.hall.userinfo.v1.GetTableViewStatusResp);
     */
    getTableViewStatusInternal(input: GetTableViewStatusReq, options?: RpcOptions): UnaryCall<GetTableViewStatusReq, GetTableViewStatusResp>;
    /**
     * @generated from protobuf rpc: InitUser(bpt.hall.userinfo.v1.InitUserReq) returns (bpt.hall.userinfo.v1.InitUserResp);
     */
    initUser(input: InitUserReq, options?: RpcOptions): UnaryCall<InitUserReq, InitUserResp>;
    /**
     * 添加首页通知
     *
     * @generated from protobuf rpc: AddNotice(bpt.hall.userinfo.v1.AddNoticeReq) returns (bpt.hall.userinfo.v1.AddNoticeResp);
     */
    addNotice(input: AddNoticeReq, options?: RpcOptions): UnaryCall<AddNoticeReq, AddNoticeResp>;
    /**
     * 更新标签
     *
     * @generated from protobuf rpc: UpdateClubId(bpt.hall.userinfo.v1.UpdateClubIdReq) returns (bpt.hall.userinfo.v1.UpdateClubIdResp);
     */
    updateClubId(input: UpdateClubIdReq, options?: RpcOptions): UnaryCall<UpdateClubIdReq, UpdateClubIdResp>;
    /**
     * @generated from protobuf rpc: FuzzySearchClubUsers(bpt.hall.userinfo.v1.FuzzySearchClubUsersReq) returns (bpt.hall.userinfo.v1.FuzzySearchClubUsersResp);
     */
    fuzzySearchClubUsers(input: FuzzySearchClubUsersReq, options?: RpcOptions): UnaryCall<FuzzySearchClubUsersReq, FuzzySearchClubUsersResp>;
    /**
     * 更新标签
     *
     * @generated from protobuf rpc: UpdateUserData(bpt.hall.userinfo.v1.UpdateUserDataReq) returns (bpt.hall.userinfo.v1.UpdateUserDataResp);
     */
    updateUserData(input: UpdateUserDataReq, options?: RpcOptions): UnaryCall<UpdateUserDataReq, UpdateUserDataResp>;
    /**
     * @generated from protobuf rpc: SyncUserToEs(bpt.base.Void) returns (bpt.base.Void);
     */
    syncUserToEs(input: Void, options?: RpcOptions): UnaryCall<Void, Void>;
}
/**
 * @generated from protobuf service bpt.hall.userinfo.v1.Userinfo
 */
export class UserinfoClient implements IUserinfoClient, ServiceInfo {
    typeName = Userinfo.typeName;
    methods = Userinfo.methods;
    options = Userinfo.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * ------------------对外接口 Start------------------
     *  查询用户信息
     *
     * @generated from protobuf rpc: GetUser(bpt.hall.userinfo.v1.GetUserReq) returns (bpt.hall.userinfo.v1.GetUserResp);
     */
    getUser(input: GetUserReq, options?: RpcOptions): UnaryCall<GetUserReq, GetUserResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserReq, GetUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetUserOpsInfo(bpt.hall.userinfo.v1.GetUserOpsInfoReq) returns (bpt.hall.userinfo.v1.GetUserOpsInfoResp);
     */
    getUserOpsInfo(input: GetUserOpsInfoReq, options?: RpcOptions): UnaryCall<GetUserOpsInfoReq, GetUserOpsInfoResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserOpsInfoReq, GetUserOpsInfoResp>("unary", this._transport, method, opt, input);
    }
    /**
     *  查询用户自己的信息
     *
     * @generated from protobuf rpc: GetMySelf(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetUserResp);
     */
    getMySelf(input: Void, options?: RpcOptions): UnaryCall<Void, GetUserResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     *  查询用户风格数据
     *
     * @generated from protobuf rpc: GetUserStyleStat(bpt.hall.userinfo.v1.GetUserStyleStatReq) returns (bpt.hall.userinfo.v1.GetUserStyleStatResp);
     */
    getUserStyleStat(input: GetUserStyleStatReq, options?: RpcOptions): UnaryCall<GetUserStyleStatReq, GetUserStyleStatResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserStyleStatReq, GetUserStyleStatResp>("unary", this._transport, method, opt, input);
    }
    /**
     *  批量查询用户风格数据
     *
     * @generated from protobuf rpc: BatchGetUserStyleStat(bpt.hall.userinfo.v1.BatchGetUserStyleStatReq) returns (bpt.hall.userinfo.v1.BatchGetUserStyleStatResp);
     */
    batchGetUserStyleStat(input: BatchGetUserStyleStatReq, options?: RpcOptions): UnaryCall<BatchGetUserStyleStatReq, BatchGetUserStyleStatResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserStyleStatReq, BatchGetUserStyleStatResp>("unary", this._transport, method, opt, input);
    }
    /**
     *  批量获取用户信息
     *
     * @generated from protobuf rpc: BatchGetUser(bpt.hall.userinfo.v1.BatchGetUserReq) returns (bpt.hall.userinfo.v1.BatchGetUserResp);
     */
    batchGetUser(input: BatchGetUserReq, options?: RpcOptions): UnaryCall<BatchGetUserReq, BatchGetUserResp> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserReq, BatchGetUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     *  批量获取用户简略信息
     *
     * @generated from protobuf rpc: BatchGetSimpleUser(bpt.hall.userinfo.v1.BatchGetSimpleUserReq) returns (bpt.hall.userinfo.v1.BatchGetSimpleUserResp);
     */
    batchGetSimpleUser(input: BatchGetSimpleUserReq, options?: RpcOptions): UnaryCall<BatchGetSimpleUserReq, BatchGetSimpleUserResp> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetSimpleUserReq, BatchGetSimpleUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 修改用户信息
     *
     * @generated from protobuf rpc: UpdateUser(bpt.hall.userinfo.v1.UpdateUserReq) returns (bpt.hall.userinfo.v1.GetUserResp);
     */
    updateUser(input: UpdateUserReq, options?: RpcOptions): UnaryCall<UpdateUserReq, GetUserResp> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateUserReq, GetUserResp>("unary", this._transport, method, opt, input);
    }
    // 游戏设定相关 

    /**
     * 设置自动买入状态 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetAutoBuyInStatus(bpt.hall.userinfo.v1.SetAutoBuyInStatusReq) returns (bpt.base.Void);
     */
    setAutoBuyInStatus(input: SetAutoBuyInStatusReq, options?: RpcOptions): UnaryCall<SetAutoBuyInStatusReq, Void> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetAutoBuyInStatusReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 设定等待大盲状态 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetWaitBigBlindStatus(bpt.hall.userinfo.v1.SetWaitBigBlindStatusReq) returns (bpt.base.Void);
     */
    setWaitBigBlindStatus(input: SetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<SetWaitBigBlindStatusReq, Void> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetWaitBigBlindStatusReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取自动买入状态 过时,请使用GetGameConfig
     *
     * @generated from protobuf rpc: GetAutoBuyInStatus(bpt.hall.userinfo.v1.GetAutoBuyInStatusReq) returns (bpt.hall.userinfo.v1.GetAutoBuyInStatusResp);
     */
    getAutoBuyInStatus(input: GetAutoBuyInStatusReq, options?: RpcOptions): UnaryCall<GetAutoBuyInStatusReq, GetAutoBuyInStatusResp> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAutoBuyInStatusReq, GetAutoBuyInStatusResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 登录
     *
     * @generated from protobuf rpc: LoginByExchange(bpt.hall.userinfo.v1.LoginByExchangeReq) returns (bpt.hall.userinfo.v1.LoginByExchangeResp);
     */
    loginByExchange(input: LoginByExchangeReq, options?: RpcOptions): UnaryCall<LoginByExchangeReq, LoginByExchangeResp> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<LoginByExchangeReq, LoginByExchangeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 登录
     *
     * @generated from protobuf rpc: LoginByPlatform(bpt.hall.userinfo.v1.LoginByPlatformReq) returns (bpt.hall.userinfo.v1.LoginByPlatformResp);
     */
    loginByPlatform(input: LoginByPlatformReq, options?: RpcOptions): UnaryCall<LoginByPlatformReq, LoginByPlatformResp> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<LoginByPlatformReq, LoginByPlatformResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 刷新token
     *
     * @generated from protobuf rpc: RefreshToken(bpt.hall.userinfo.v1.RefreshTokenReq) returns (bpt.hall.userinfo.v1.RefreshTokenResp);
     */
    refreshToken(input: RefreshTokenReq, options?: RpcOptions): UnaryCall<RefreshTokenReq, RefreshTokenResp> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<RefreshTokenReq, RefreshTokenResp>("unary", this._transport, method, opt, input);
    }
    /**
     * oss
     *
     * @generated from protobuf rpc: GetSTS(bpt.hall.userinfo.v1.GetSTSReq) returns (bpt.hall.userinfo.v1.GetSTSResp);
     */
    getSTS(input: GetSTSReq, options?: RpcOptions): UnaryCall<GetSTSReq, GetSTSResp> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSTSReq, GetSTSResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 登出
     *
     * @generated from protobuf rpc: LoginOut(bpt.hall.userinfo.v1.LoginOutReq) returns (bpt.hall.userinfo.v1.LoginOutResp);
     */
    loginOut(input: LoginOutReq, options?: RpcOptions): UnaryCall<LoginOutReq, LoginOutResp> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<LoginOutReq, LoginOutResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PageListInvitationCode(bpt.hall.userinfo.v1.PageListInvitationCodeReq) returns (bpt.hall.userinfo.v1.PageListInvitationCodeResp);
     */
    pageListInvitationCode(input: PageListInvitationCodeReq, options?: RpcOptions): UnaryCall<PageListInvitationCodeReq, PageListInvitationCodeResp> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<PageListInvitationCodeReq, PageListInvitationCodeResp>("unary", this._transport, method, opt, input);
    }
    // 排行榜相关

    /**
     * 冠军榜
     *
     * @generated from protobuf rpc: TopChampionList(bpt.base.Void) returns (bpt.hall.userinfo.v1.TopChampionListResp);
     */
    topChampionList(input: Void, options?: RpcOptions): UnaryCall<Void, TopChampionListResp> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, TopChampionListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 奖金榜
     *
     * @generated from protobuf rpc: TopBonusList(bpt.base.Void) returns (bpt.hall.userinfo.v1.TopBonusListResp);
     */
    topBonusList(input: Void, options?: RpcOptions): UnaryCall<Void, TopBonusListResp> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, TopBonusListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 日周赛 首页使用
     *
     * @generated from protobuf rpc: ListUserPrizeRanks(bpt.hall.userinfo.v1.ListUserPrizeRanksReq) returns (bpt.hall.userinfo.v1.ListUserPrizeRanksResp);
     */
    listUserPrizeRanks(input: ListUserPrizeRanksReq, options?: RpcOptions): UnaryCall<ListUserPrizeRanksReq, ListUserPrizeRanksResp> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserPrizeRanksReq, ListUserPrizeRanksResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 日周赛 排行榜
     *
     * @generated from protobuf rpc: ListUserPrizeRankings(bpt.hall.userinfo.v1.ListUserPrizeRankingsReq) returns (bpt.hall.userinfo.v1.ListUserPrizeRankingsResp);
     */
    listUserPrizeRankings(input: ListUserPrizeRankingsReq, options?: RpcOptions): UnaryCall<ListUserPrizeRankingsReq, ListUserPrizeRankingsResp> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserPrizeRankingsReq, ListUserPrizeRankingsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 日周赛用户个人排名和奖金
     *
     * @generated from protobuf rpc: GetUserRanking(bpt.hall.userinfo.v1.GetUserRankingReq) returns (bpt.hall.userinfo.v1.GetUserRankingResp);
     */
    getUserRanking(input: GetUserRankingReq, options?: RpcOptions): UnaryCall<GetUserRankingReq, GetUserRankingResp> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserRankingReq, GetUserRankingResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 生成日周赛榜单
     *
     * @generated from protobuf rpc: GenerateUserPrizeRanks(bpt.base.Void) returns (bpt.base.Void);
     */
    generateUserPrizeRanks(input: Void, options?: RpcOptions): UnaryCall<Void, Void> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 用户个人排名和累计赢得的奖励
     *
     * @generated from protobuf rpc: GetRankAndTotalWin(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetRankAndTotalWinResp);
     */
    getRankAndTotalWin(input: Void, options?: RpcOptions): UnaryCall<Void, GetRankAndTotalWinResp> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetRankAndTotalWinResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 用户个人排名和累计top1次数
     *
     * @generated from protobuf rpc: GetRankAndTop1Times(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetRankAndTop1TimesResp);
     */
    getRankAndTop1Times(input: Void, options?: RpcOptions): UnaryCall<Void, GetRankAndTop1TimesResp> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetRankAndTop1TimesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 某次统计数据失败，调用这个接口重新统计
     *
     * @generated from protobuf rpc: RerunStRank(bpt.hall.userinfo.v1.RerunStRankReq) returns (bpt.hall.userinfo.v1.RerunStRankResp);
     */
    rerunStRank(input: RerunStRankReq, options?: RpcOptions): UnaryCall<RerunStRankReq, RerunStRankResp> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<RerunStRankReq, RerunStRankResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListUserChampions(bpt.base.Void) returns (bpt.hall.userinfo.v1.ListUserChampionsResp);
     */
    listUserChampions(input: Void, options?: RpcOptions): UnaryCall<Void, ListUserChampionsResp> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, ListUserChampionsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 设置多桌列表开启状态 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetTableViewStatus(bpt.hall.userinfo.v1.SetTableViewStatusReq) returns (bpt.base.Void);
     */
    setTableViewStatus(input: SetTableViewStatusReq, options?: RpcOptions): UnaryCall<SetTableViewStatusReq, Void> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetTableViewStatusReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 设置用户游戏语音包 过时,请使用SaveGameConfig
     *
     * @generated from protobuf rpc: SetUserTableVoice(bpt.hall.userinfo.v1.SetUserTableVoiceReq) returns (bpt.base.Void);
     */
    setUserTableVoice(input: SetUserTableVoiceReq, options?: RpcOptions): UnaryCall<SetUserTableVoiceReq, Void> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetUserTableVoiceReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取多桌列表开启状态 过时,请使用GetGameConfig
     *
     * @generated from protobuf rpc: GetTableViewStatus(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetTableViewStatusResp);
     */
    getTableViewStatus(input: Void, options?: RpcOptions): UnaryCall<Void, GetTableViewStatusResp> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetTableViewStatusResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存图片到相册
     *
     * @generated from protobuf rpc: SaveImagesToAlbum(bpt.hall.userinfo.v1.SaveImagesToAlbumReq) returns (bpt.hall.userinfo.v1.SaveImagesToAlbumResp);
     */
    saveImagesToAlbum(input: SaveImagesToAlbumReq, options?: RpcOptions): UnaryCall<SaveImagesToAlbumReq, SaveImagesToAlbumResp> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveImagesToAlbumReq, SaveImagesToAlbumResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 从相册删除图片
     *
     * @generated from protobuf rpc: DeleteImagesFromAlbum(bpt.hall.userinfo.v1.DeleteImagesFromAlbumReq) returns (bpt.base.Void);
     */
    deleteImagesFromAlbum(input: DeleteImagesFromAlbumReq, options?: RpcOptions): UnaryCall<DeleteImagesFromAlbumReq, Void> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteImagesFromAlbumReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取相册图片
     *
     * @generated from protobuf rpc: ListImagesFromAlbum(bpt.hall.userinfo.v1.ListImagesFromAlbumReq) returns (bpt.hall.userinfo.v1.ListImagesFromAlbumResp);
     */
    listImagesFromAlbum(input: ListImagesFromAlbumReq, options?: RpcOptions): UnaryCall<ListImagesFromAlbumReq, ListImagesFromAlbumResp> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListImagesFromAlbumReq, ListImagesFromAlbumResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取用户的综合数据，包含基本信息，生涯，社交关系等
     *
     * @generated from protobuf rpc: GetUserIntegratedInfo(bpt.hall.userinfo.v1.GetUserIntegratedInfoReq) returns (bpt.hall.userinfo.v1.GetUserIntegratedInfoResp);
     */
    getUserIntegratedInfo(input: GetUserIntegratedInfoReq, options?: RpcOptions): UnaryCall<GetUserIntegratedInfoReq, GetUserIntegratedInfoResp> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserIntegratedInfoReq, GetUserIntegratedInfoResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取绑定信息平台，例如推特，fb等
     *
     * @generated from protobuf rpc: ListSocialAccount(bpt.hall.userinfo.v1.ListSocialAccountReq) returns (bpt.hall.userinfo.v1.ListSocialAccountResp);
     */
    listSocialAccount(input: ListSocialAccountReq, options?: RpcOptions): UnaryCall<ListSocialAccountReq, ListSocialAccountResp> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListSocialAccountReq, ListSocialAccountResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存标签
     *
     * @generated from protobuf rpc: SaveTag(bpt.hall.userinfo.v1.SaveTagReq) returns (bpt.hall.userinfo.v1.SaveTagResp);
     */
    saveTag(input: SaveTagReq, options?: RpcOptions): UnaryCall<SaveTagReq, SaveTagResp> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveTagReq, SaveTagResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取标签
     *
     * @generated from protobuf rpc: GetTag(bpt.hall.userinfo.v1.GetTagReq) returns (bpt.hall.userinfo.v1.GetTagResp);
     */
    getTag(input: GetTagReq, options?: RpcOptions): UnaryCall<GetTagReq, GetTagResp> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTagReq, GetTagResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取标签
     *
     * @generated from protobuf rpc: BatchGetTag(bpt.hall.userinfo.v1.BatchGetTagReq) returns (bpt.hall.userinfo.v1.BatchGetTagResp);
     */
    batchGetTag(input: BatchGetTagReq, options?: RpcOptions): UnaryCall<BatchGetTagReq, BatchGetTagResp> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetTagReq, BatchGetTagResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除标签
     *
     * @generated from protobuf rpc: DeleteTag(bpt.hall.userinfo.v1.DeleteTagReq) returns (bpt.base.Void);
     */
    deleteTag(input: DeleteTagReq, options?: RpcOptions): UnaryCall<DeleteTagReq, Void> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteTagReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 分页获取标签
     *
     * @generated from protobuf rpc: ListTag(bpt.hall.userinfo.v1.ListTagReq) returns (bpt.hall.userinfo.v1.ListTagResp);
     */
    listTag(input: ListTagReq, options?: RpcOptions): UnaryCall<ListTagReq, ListTagResp> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListTagReq, ListTagResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 更新标签
     *
     * @generated from protobuf rpc: UpdateTag(bpt.hall.userinfo.v1.UpdateTagReq) returns (bpt.hall.userinfo.v1.UpdateTagResp);
     */
    updateTag(input: UpdateTagReq, options?: RpcOptions): UnaryCall<UpdateTagReq, UpdateTagResp> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateTagReq, UpdateTagResp>("unary", this._transport, method, opt, input);
    }
    /**
     *  模糊搜索用户
     *
     * @generated from protobuf rpc: FuzzySearchUsers(bpt.hall.userinfo.v1.FuzzySearchUsersReq) returns (bpt.hall.userinfo.v1.FuzzySearchUsersResp);
     */
    fuzzySearchUsers(input: FuzzySearchUsersReq, options?: RpcOptions): UnaryCall<FuzzySearchUsersReq, FuzzySearchUsersResp> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<FuzzySearchUsersReq, FuzzySearchUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     *  模糊搜索用户(包含钱包)
     *
     * @generated from protobuf rpc: WalletSearchUsers(bpt.hall.userinfo.v1.WalletSearchUsersReq) returns (bpt.hall.userinfo.v1.WalletSearchUsersResp);
     */
    walletSearchUsers(input: WalletSearchUsersReq, options?: RpcOptions): UnaryCall<WalletSearchUsersReq, WalletSearchUsersResp> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<WalletSearchUsersReq, WalletSearchUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取首页通知
     *
     * @generated from protobuf rpc: ListNotice(bpt.hall.userinfo.v1.ListNoticeReq) returns (bpt.hall.userinfo.v1.ListNoticeResp);
     */
    listNotice(input: ListNoticeReq, options?: RpcOptions): UnaryCall<ListNoticeReq, ListNoticeResp> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListNoticeReq, ListNoticeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 关闭首页通知
     *
     * @generated from protobuf rpc: CloseNotice(bpt.hall.userinfo.v1.CloseNoticeReq) returns (bpt.hall.userinfo.v1.CloseNoticeResp);
     */
    closeNotice(input: CloseNoticeReq, options?: RpcOptions): UnaryCall<CloseNoticeReq, CloseNoticeResp> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<CloseNoticeReq, CloseNoticeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取游戏配置
     *
     * @generated from protobuf rpc: GetUserGameConfig(bpt.base.Void) returns (bpt.hall.userinfo.v1.GetUserGameConfigResp);
     */
    getUserGameConfig(input: Void, options?: RpcOptions): UnaryCall<Void, GetUserGameConfigResp> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetUserGameConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存游戏配置
     *
     * @generated from protobuf rpc: SaveUserGameConfig(bpt.hall.userinfo.v1.SaveUserGameConfigReq) returns (bpt.base.Void);
     */
    saveUserGameConfig(input: SaveUserGameConfigReq, options?: RpcOptions): UnaryCall<SaveUserGameConfigReq, Void> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveUserGameConfigReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取用户语音包资源配置
     *
     * @generated from protobuf rpc: BatchGetUserVoiceConfig(bpt.hall.userinfo.v1.BatchGetUserVoiceConfigReq) returns (bpt.hall.userinfo.v1.BatchGetUserVoiceConfigResp);
     */
    batchGetUserVoiceConfig(input: BatchGetUserVoiceConfigReq, options?: RpcOptions): UnaryCall<BatchGetUserVoiceConfigReq, BatchGetUserVoiceConfigResp> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserVoiceConfigReq, BatchGetUserVoiceConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GlobalSearchUsers(bpt.hall.userinfo.v1.GlobalSearchUsersReq) returns (bpt.hall.userinfo.v1.GlobalSearchUsersResp);
     */
    globalSearchUsers(input: GlobalSearchUsersReq, options?: RpcOptions): UnaryCall<GlobalSearchUsersReq, GlobalSearchUsersResp> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<GlobalSearchUsersReq, GlobalSearchUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取玩家结算亮牌开关
     *
     * ------------------对外接口 End------------------
     *
     * @generated from protobuf rpc: BatchGetUserShowdownSwitch(bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchReq) returns (bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchResp);
     */
    batchGetUserShowdownSwitch(input: BatchGetUserShowdownSwitchReq, options?: RpcOptions): UnaryCall<BatchGetUserShowdownSwitchReq, BatchGetUserShowdownSwitchResp> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserShowdownSwitchReq, BatchGetUserShowdownSwitchResp>("unary", this._transport, method, opt, input);
    }
    // ------------------内部接口 Start------------------

    /**
     * 获取等待大盲状态(内部调用）
     *
     * @generated from protobuf rpc: GetWaitBigBlindStatus(bpt.hall.userinfo.v1.GetWaitBigBlindStatusReq) returns (bpt.hall.userinfo.v1.GetWaitBigBlindStatusResp);
     */
    getWaitBigBlindStatus(input: GetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<GetWaitBigBlindStatusReq, GetWaitBigBlindStatusResp> {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWaitBigBlindStatusReq, GetWaitBigBlindStatusResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取等待大盲状态（内部调用）
     *
     * @generated from protobuf rpc: GetWaitBigBlindStatusInternal(bpt.hall.userinfo.v1.GetWaitBigBlindStatusReq) returns (bpt.hall.userinfo.v1.GetWaitBigBlindStatusResp);
     */
    getWaitBigBlindStatusInternal(input: GetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<GetWaitBigBlindStatusReq, GetWaitBigBlindStatusResp> {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetWaitBigBlindStatusReq, GetWaitBigBlindStatusResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 设定等待大盲状态 (内部调用）
     *
     * @generated from protobuf rpc: SetWaitBigBlindStatusInternal(bpt.hall.userinfo.v1.SetWaitBigBlindStatusReq) returns (bpt.base.Void);
     */
    setWaitBigBlindStatusInternal(input: SetWaitBigBlindStatusReq, options?: RpcOptions): UnaryCall<SetWaitBigBlindStatusReq, Void> {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept<SetWaitBigBlindStatusReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取自动买入状态(内部调用）
     *
     * @generated from protobuf rpc: GetAutoBuyInStatusInternal(bpt.hall.userinfo.v1.GetAutoBuyInStatusReq) returns (bpt.hall.userinfo.v1.GetAutoBuyInStatusResp);
     */
    getAutoBuyInStatusInternal(input: GetAutoBuyInStatusReq, options?: RpcOptions): UnaryCall<GetAutoBuyInStatusReq, GetAutoBuyInStatusResp> {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetAutoBuyInStatusReq, GetAutoBuyInStatusResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetTableViewStatusInternal(bpt.hall.userinfo.v1.GetTableViewStatusReq) returns (bpt.hall.userinfo.v1.GetTableViewStatusResp);
     */
    getTableViewStatusInternal(input: GetTableViewStatusReq, options?: RpcOptions): UnaryCall<GetTableViewStatusReq, GetTableViewStatusResp> {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetTableViewStatusReq, GetTableViewStatusResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: InitUser(bpt.hall.userinfo.v1.InitUserReq) returns (bpt.hall.userinfo.v1.InitUserResp);
     */
    initUser(input: InitUserReq, options?: RpcOptions): UnaryCall<InitUserReq, InitUserResp> {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept<InitUserReq, InitUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 添加首页通知
     *
     * @generated from protobuf rpc: AddNotice(bpt.hall.userinfo.v1.AddNoticeReq) returns (bpt.hall.userinfo.v1.AddNoticeResp);
     */
    addNotice(input: AddNoticeReq, options?: RpcOptions): UnaryCall<AddNoticeReq, AddNoticeResp> {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddNoticeReq, AddNoticeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 更新标签
     *
     * @generated from protobuf rpc: UpdateClubId(bpt.hall.userinfo.v1.UpdateClubIdReq) returns (bpt.hall.userinfo.v1.UpdateClubIdResp);
     */
    updateClubId(input: UpdateClubIdReq, options?: RpcOptions): UnaryCall<UpdateClubIdReq, UpdateClubIdResp> {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateClubIdReq, UpdateClubIdResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FuzzySearchClubUsers(bpt.hall.userinfo.v1.FuzzySearchClubUsersReq) returns (bpt.hall.userinfo.v1.FuzzySearchClubUsersResp);
     */
    fuzzySearchClubUsers(input: FuzzySearchClubUsersReq, options?: RpcOptions): UnaryCall<FuzzySearchClubUsersReq, FuzzySearchClubUsersResp> {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept<FuzzySearchClubUsersReq, FuzzySearchClubUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 更新标签
     *
     * @generated from protobuf rpc: UpdateUserData(bpt.hall.userinfo.v1.UpdateUserDataReq) returns (bpt.hall.userinfo.v1.UpdateUserDataResp);
     */
    updateUserData(input: UpdateUserDataReq, options?: RpcOptions): UnaryCall<UpdateUserDataReq, UpdateUserDataResp> {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateUserDataReq, UpdateUserDataResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SyncUserToEs(bpt.base.Void) returns (bpt.base.Void);
     */
    syncUserToEs(input: Void, options?: RpcOptions): UnaryCall<Void, Void> {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, Void>("unary", this._transport, method, opt, input);
    }
}
