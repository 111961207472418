// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/common/code/code.proto" (package "bpt.common.code", syntax proto3)
// tslint:disable
// @ts-nocheck
// 状态码
// 100：（继续） 请求者应当继续提出请求。 服务器返回此代码表示已收到请求的第一部分，正在等待其余部分。
// 101：（切换协议） 请求者已要求服务器切换协议，服务器已确认并准备切换。
// 102： 由WebDAV（RFC 2518）扩展的状态码，代表处理将被继续执行。

// 200：（成功） 服务器已成功处理了请求。 通常，这表示服务器提供了请求的网页。
// 201：（已创建） 请求成功并且服务器创建了新的资源。(用于有更新操作的返回，如update,delete)
// 202：（已接受） 服务器已接受请求，但尚未处理。(用于后台异步处理)
// 203：（非授权信息） 服务器已成功处理了请求，但返回的信息可能来自另一来源。
// 204：（无内容） 服务器成功处理了请求，但没有返回任何内容。(返回是base.Void的)
// 205：（重置内容） 服务器成功处理了请求，但没有返回任何内容。
// 206：（部分内容） 服务器成功处理了部分 GET 请求。
// 208：（已经报告）一个DAV的绑定成员被前一个请求枚举，并且没有被再一次包括。
// 226：（IM Used）服务器已经满足了请求所要的资源，并且响应是一个或者多个实例操作应用于当前实例的结果。（重复请求，幂等处理）

// 300：（多种选择） 针对请求，服务器可执行多种操作。 服务器可根据请求者 (user agent) 选择一项操作，或提供操作列表供请求者选择。
// 301：（永久移动） 请求的网页已永久移动到新位置。 服务器返回此响应（对 GET 或 HEAD 请求的响应）时，会自动将请求者转到新位置。
// 302：（临时移动） 服务器目前从不同位置的网页响应请求，但请求者应继续使用原有位置来进行以后的请求。
// 303：（查看其他位置） 请求者应当对不同的位置使用单独的 GET 请求来检索响应时，服务器返回此代码。
// 304：（未修改） 自从上次请求后，请求的网页未修改过。 服务器返回此响应时，不会返回网页内容。
// 305：（使用代理） 请求者只能使用代理访问请求的网页。 如果服务器返回此响应，还表示请求者应使用代理。
// 307：（临时重定向） 服务器目前从不同位置的网页响应请求，但请求者应继续使用原有位置来进行以后的请求。
// 308：（永久转移）这个请求和以后的请求都应该被另一个URI地址重新发送。307、308和302、301有相同的表现，但是不允许HTTP方法改变。例如，请求表单到一个永久转移的资源将会继续顺利地执行。(合服，分服)

// 400:  (错误请求) 服务器不理解请求的语法。
// 401： (未授权) 请求要求身份验证。 对于需要登录的网页，服务器可能返回此响应。
// 402： 该状态码是为了将来可能的需求而预留的。
// 403：（禁止) 服务器拒绝请求。
// 404：（未找到) 服务器找不到请求的网页。(数据库找不到记录，比赛不存在等)
// 405：（方法禁用) 禁用请求中指定的方法。(见restful规范)
// 406：（不接受) 无法使用请求的内容特性响应请求的网页。
// 407：（需要代理授权) 此状态代码与 401（未授权）类似，但指定请求者应当授权使用代理。
// 408：（请求超时) 服务器等候请求时发生超时。（time out）
// 409：（冲突) 服务器在完成请求时发生冲突。 服务器必须在响应中包含有关冲突的信息。
// 410：（已删除) 如果请求的资源已永久删除，服务器就会返回此响应。
// 411：（需要有效长度) 服务器不接受不含有效内容长度标头字段的请求。
// 412：（未满足前提条件) 服务器未满足请求者在请求中设置的其中一个前提条件。
// 413：（请求实体过大) 服务器无法处理请求，因为请求实体过大，超出服务器的处理能力。
// 414：（请求的 URI 过长) 请求的 URI（通常为网址）过长，服务器无法处理。
// 415：（不支持的媒体类型) 请求的格式不受请求页面的支持。
// 416：（请求范围不符合要求) 如果页面无法提供请求的范围，则服务器会返回此状态代码。(非法分页参数)
// 417：（未满足期望值) 服务器未满足"期望"请求标头字段的要求。
// 418：（我是一个茶壶）这个代码是在1998年作为传统的IETF April Fools‘ jokes被定义的在RFC2324，超文本咖啡罐控制协议，但是并没有被实际的HTTP服务器实现。RFC指定了这个代码应该是由茶罐返回给速溶咖啡。
// 419：（认证超时）并不是HTTP标注的一部分，419认证超时表示以前的有效证明已经失效了。同时也被用于401未认证的替代选择为了从其它被拒绝访问的已认证客户端中指定服务器的资源。
// 420：（方法失效）不是HTTP的标准，但是被Spring定义在HTTP状态类中当方法失时使用。这个状态码已经不推荐在Spring中使用。
// 420：（提高你的耐心）也不是HTTP标准的一部分，但是被版本1的Twitter搜索和趋势APi返回当客户端的速率被限制的时候。其它的服务提供商可能会使用429太多的请求响应码来代替。
// 421： 从当前客户端所在的IP地址到服务器的连接数超过了服务器许可的最大范围。通常，这里的IP地址指的是从服务器上看到的客户端地址（比如用户的网关或者代理服务器地址）。在这种情况下，连接数的计算可能涉及到不止一个终端用户。
// 422： 请求格式正确，但是由于含有语义错误，无法响应。（RFC 4918 WebDAV）
// 423： Locked当前资源被锁定。（RFC 4918 WebDAV）
// 424： 由于之前的某个请求发生的错误，导致当前请求失败，例如 PROPPATCH。（RFC 4918 WebDAV）
// 425： 在WebDav Advanced Collections 草案中定义，但是未出现在《WebDAV 顺序集协议》（RFC 3658）中。
// 426： 客户端应当切换到TLS/1.0。（RFC 2817）
// 428： (需要前置条件)原始服务器需要有条件的请求。当客户端GET一个资源的状态的时候，同时又PUT回给服务器，与此同时第三方修改状态到服务器上的时候，为了避免丢失更新的问题发生将会导致冲突。
// 429：（过多请求）用户已经发送了太多的请求在指定的时间里。用于限制速率。
// 431：（请求头部字段太大）服务器由于一个单独的请求头部字段或者是全部的字段太大而不愿意处理请求。
// 440：（登陆超时（微软））一个微软的扩展，意味着你的会话已经超时。
// 444：（无响应）被使用在Nginx的日志中表明服务器没有返回信息给客户端并且关闭了连接（在威慑恶意软件的时候比较有用）。
// 449：（重试（微软））一个微软的扩展。请求应该在执行适当的动作之后被重试。
// 450：（被Windows家长控制阻塞（微软））一个微软的扩展。这个错误是当Windows家长控制打开并且阻塞指定网页的访问的时候被指定。
// 451：（由于法律原因而无效（因特网草稿））被定义在因特网草稿“一个新的HTTP状态码用于法律限制的资源”。被用于当资源的访问由于法律原因被禁止的时候。例如检查制度或者是政府强制要求禁止访问。一个例子是1953年dystopian的小说Fahrenheit 451就是一个非法的资源。
// 451：（重定向（微软））被用在Exchange ActiveSync中如果一个更有效的服务器能够被使用或者是服务器不能访问用户的邮箱。客户端会假定重新执行HTTP自动发现协议去寻找更适合的服务器。
// 494：（请求头太大（Nginx））Nginx内置代码和431类似，但是是被更早地引入在版本0.9.4（在2011年1月21日）。
// 495：（证书错误（Nginx））Nginx内置的代码，当使用SSL客户端证书的时候错误会出现为了在日志错误中区分它和4XX和一个错误页面的重定向。。
// 496：（没有证书（Nginx））Nginx内置的代码，当客户端不能提供证书在日志中分辨4XX和一个错误页面的重定向。
// 497：（HTTP到HTTPS（Nginx））Nginx内置的代码，被用于原始的HTTP的请求发送给HTTPS端口去分辨4XX在日志中和一个错误页面的重定向。
// 498：（令牌超时或失效（Esri））由ArcGIS for Server返回。这个代码意味着令牌的超时或者是失效。
// 499：（客户端关闭请求（Nginx））被用在Nginx日志去表明一个连接已经被客户端关闭当服务器仍然正在处理它的请求，是的服务器无法返货状态码。
// 499：（需要令牌（Esri））由ArcGIS for Server返回。意味着需要一个令牌（如果没有令牌被提交）。

/**
 * 500：（服务器内部错误） 服务器遇到错误，无法完成请求。
 * 501：（尚未实施） 服务器不具备完成请求的功能。 例如，服务器无法识别请求方法时可能会返回此代码。
 * 502：（错误网关） 服务器作为网关或代理，从上游服务器收到无效响应。
 * 503：（服务不可用） 服务器目前无法使用（由于超载或停机维护）。 通常，这只是暂时状态。
 * 504：（网关超时） 服务器作为网关或代理，但是没有及时从上游服务器收到请求。
 * 505：（HTTP 版本不受支持） 服务器不支持请求中所用的 HTTP 协议版本。
 * 506： 由《透明内容协商协议》（RFC 2295）扩展，代表服务器存在内部配置错误：被请求的协商变元资源被配置为在透明内容协商中使用自己，因此在一个协商处理中不是一个合适的重点。
 * 507： 服务器无法存储完成请求所必须的内容。这个状况被认为是临时的。WebDAV (RFC 4918)
 * 509： 服务器达到带宽限制。这不是一个官方的状态码，但是仍被广泛使用。(限流)
 * 510： 获取资源所需要的策略并没有没满足。（RFC 2774）。
 * 508：（发现环路）服务器发现了一个无限的循环档处理请求的时候。
 * 511：（需要网络授权）客户端需要授权去火的网络的访问权限。一般用于代理交互中被用来进行网络的访问控制。
 * 520：（未知错误）这个状态码也没有被指定在任何RFC中，并且只会被一些服务器返回，例如微软的Azure和CloudFlare服务器:”520错误。本质上是一个捕获全部的响应当原始服务器返回一些未知的或者一些不能被忍受或者被解释的(协议违反或者空响应)”。
 * 598：（网络读取超时异常(未知)）这个状态码也没有在任何RFC中指定，但是被用在微软的HTTP代理中去标注一个网络读取超时在一个客户端之前的代理的后面。
 * 599：（网络连接超时异常(未知)）这个状态码也没有在任何RFC中指定，但是被用在微软的HTTP代理中去标注一个网络连接超时在一个客户端之前的代理的后面。
 *    ————————————————
 *    版权声明：本文为博主原创文章，遵循 CC 4.0 BY-SA 版权协议，转载请附上原文出处链接和本声明。
 *    原文链接：https://blog.csdn.net/weixin_63149034/article/details/126365189
 *
 * @generated from protobuf enum bpt.common.code.ErrorCode
 */
export enum ErrorCode {
    /**
     * 基础错误
     *
     * @generated from protobuf enum value: CodeOK = 0;
     */
    CodeOK = 0,
    /**
     * @generated from protobuf enum value: NoContent = 15;
     */
    NoContent = 15,
    /**
     * 参数非法，参数错误，解码失败等等，，业务
     *
     * @generated from protobuf enum value: BadRequest = 3;
     */
    BadRequest = 3,
    /**
     * 未授权，平台
     *
     * @generated from protobuf enum value: Unauthorized = 16;
     */
    Unauthorized = 16,
    /**
     * 方法不被允许，平台
     *
     * @generated from protobuf enum value: MethodNotAllowed = 7;
     */
    MethodNotAllowed = 7,
    /**
     * 内部错误（编解码失败），平台
     *
     * @generated from protobuf enum value: Internal = 13;
     */
    Internal = 13,
    /**
     * 未知，业务
     *
     * @generated from protobuf enum value: Unknown = 2;
     */
    Unknown = 2,
    /**
     * 我们的
     * 请求超时，平台
     *
     * @generated from protobuf enum value: RequestTimeout = 50;
     */
    RequestTimeout = 50,
    /**
     * 路由报错
     *
     * @generated from protobuf enum value: RouterException = 51;
     */
    RouterException = 51,
    /**
     * 重定向服务
     *
     * @generated from protobuf enum value: StatusFound = 52;
     */
    StatusFound = 52,
    /**
     * 资源不足，业务
     *
     * @generated from protobuf enum value: InsufficientResources = 53;
     */
    InsufficientResources = 53,
    /**
     * 流通道关闭
     *
     * 404
     *
     * @generated from protobuf enum value: EOF = 54;
     */
    EOF = 54,
    /**
     * 异步返回（忽略）
     *
     * 无
     *
     * @generated from protobuf enum value: AsyncReturn = 55;
     */
    AsyncReturn = 55,
    /**
     * 内部服务错误：数据库/缓存访问错误，平台
     *
     * 500
     *
     * @generated from protobuf enum value: InternalServerError = 56;
     */
    InternalServerError = 56,
    /**
     * 服务未找到，平台
     *
     * 503
     *
     * @generated from protobuf enum value: ServiceNotFound = 57;
     */
    ServiceNotFound = 57,
    /**
     * 方法未找到，平台
     *
     * 404
     *
     * @generated from protobuf enum value: MethodNotFound = 58;
     */
    MethodNotFound = 58,
    /**
     * 网路异常，平台
     *
     * 500
     *
     * @generated from protobuf enum value: NetworkException = 59;
     */
    NetworkException = 59,
    /**
     * 服务不可达，或过载，平台
     *
     * 503
     *
     * @generated from protobuf enum value: ServiceUnavailable = 60;
     */
    ServiceUnavailable = 60,
    /**
     * 队列资源未找到，平台
     *
     * 404
     *
     * @generated from protobuf enum value: QueueNotFound = 61;
     */
    QueueNotFound = 61,
    /**
     * 公共状态码最大值（忽略）
     *
     * @generated from protobuf enum value: Max = 1000;
     */
    Max = 1000,
    /**
     * 通用业务错误码 2000-2999
     *
     * @generated from protobuf enum value: CodeNotLogin = 2000;
     */
    CodeNotLogin = 2000,
    /**
     * 如重试时，已经处理的业务，幂等控制重复id
     *
     * @generated from protobuf enum value: CodeRepeated = 2001;
     */
    CodeRepeated = 2001,
    /**
     * conf 错误码3000-3999
     *
     * @generated from protobuf enum value: CodeConfNotExist = 3000;
     */
    CodeConfNotExist = 3000,
    /**
     * @generated from protobuf enum value: CodeInvalidConf = 3001;
     */
    CodeInvalidConf = 3001,
    /**
     * userInfo
     *
     * @generated from protobuf enum value: CodeUserUpdateResultNicknameDuplicate = 3101;
     */
    CodeUserUpdateResultNicknameDuplicate = 3101,
    /**
     * @generated from protobuf enum value: CodeUserUpdateResultNicknameChanceLimit = 3102;
     */
    CodeUserUpdateResultNicknameChanceLimit = 3102,
    /**
     * @generated from protobuf enum value: CodeUserUpdateResultNicknameInvalid = 3103;
     */
    CodeUserUpdateResultNicknameInvalid = 3103,
    /**
     * @generated from protobuf enum value: CodeUserUpdateResultNicknameLenInvalid = 3104;
     */
    CodeUserUpdateResultNicknameLenInvalid = 3104,
    /**
     * @generated from protobuf enum value: CodeUserUpdateResultNicknameDisableUpdate = 3105;
     */
    CodeUserUpdateResultNicknameDisableUpdate = 3105,
    /**
     * @generated from protobuf enum value: CodeUserNicknameNoUpdateAllowed = 3106;
     */
    CodeUserNicknameNoUpdateAllowed = 3106,
    /**
     * match 错误码4000-4999
     *
     * @generated from protobuf enum value: CodeMatchNotExist = 4000;
     */
    CodeMatchNotExist = 4000,
    /**
     * @generated from protobuf enum value: CodeAssetNotEnough = 4001;
     */
    CodeAssetNotEnough = 4001,
    /**
     * @generated from protobuf enum value: CodeDuplicatedRegistration = 4002;
     */
    CodeDuplicatedRegistration = 4002,
    /**
     * @generated from protobuf enum value: CodeMatchStarted = 4003;
     */
    CodeMatchStarted = 4003,
    /**
     * @generated from protobuf enum value: CodeMatchFinish = 4004;
     */
    CodeMatchFinish = 4004,
    /**
     * 超过延迟注册时间
     *
     * @generated from protobuf enum value: CodeLateRegisterExpired = 4005;
     */
    CodeLateRegisterExpired = 4005,
    /**
     * 已经在同一场次的其他比赛中
     *
     * @generated from protobuf enum value: CodeSameSessionMatchExist = 4006;
     */
    CodeSameSessionMatchExist = 4006,
    /**
     * @generated from protobuf enum value: CodeMatchUserFull = 4007;
     */
    CodeMatchUserFull = 4007,
    /**
     * @generated from protobuf enum value: CodeConfigNotEnable = 4008;
     */
    CodeConfigNotEnable = 4008,
    /**
     * @generated from protobuf enum value: CodeNotRegister = 4009;
     */
    CodeNotRegister = 4009,
    /**
     * @generated from protobuf enum value: CodeReachMaxRebuy = 4010;
     */
    CodeReachMaxRebuy = 4010,
    /**
     * 某些操作在finaltable时候不能操作，如reentry,addon等
     *
     * @generated from protobuf enum value: CodeMatchInFinal = 4011;
     */
    CodeMatchInFinal = 4011,
    /**
     * @generated from protobuf enum value: CodeBlindLevelMisMatch = 4012;
     */
    CodeBlindLevelMisMatch = 4012,
    /**
     * @generated from protobuf enum value: CodeTableSeatOccupied = 4013;
     */
    CodeTableSeatOccupied = 4013,
    /**
     * @generated from protobuf enum value: CodeTableSeatNotExist = 4014;
     */
    CodeTableSeatNotExist = 4014,
    /**
     * @generated from protobuf enum value: CodeTableNotFindUser = 4015;
     */
    CodeTableNotFindUser = 4015,
    /**
     * 操作如ReBuy,AddOn,ReEntry,延迟报名等操作，需要在比赛开始后才合法，如比赛未开始，会返回该错误
     *
     * @generated from protobuf enum value: CodeMatchNotStart = 4016;
     */
    CodeMatchNotStart = 4016,
    /**
     * 某些操作在进入泡沫圈后操作是非法的，如rebuy,addon,reentry
     * 泡沫圈：2桌以上，人数=奖圈人数+1
     *
     * @generated from protobuf enum value: CodeBubbleTime = 4017;
     */
    CodeBubbleTime = 4017,
    /**
     * 进入奖圈操作非法，如rebuy,addon,reentry,延迟报名
     *
     * @generated from protobuf enum value: CodeEnterPrize = 4018;
     */
    CodeEnterPrize = 4018,
    /**
     * 如AddOn,ReEntry,ReBuy等业务会有次数限制，如果次数到达限制报该错误
     *
     * @generated from protobuf enum value: CodeExceedLimit = 4019;
     */
    CodeExceedLimit = 4019,
    /**
     * 不在业务允许的时间的非法操作，如复活需要在15秒钟操作，rebuy在前几个盲注等级操作,未到首次展示时间的报名
     *
     * @generated from protobuf enum value: CodeNotInValidTimeRange = 4020;
     */
    CodeNotInValidTimeRange = 4020,
    /**
     * 如rebuy,需要限制筹码小于等于初始筹码，超过初始筹码，操作非法
     * reentry 要求复活时筹码为0，不为0操作非法
     *
     * @generated from protobuf enum value: CodeChipsNotMatch = 4021;
     */
    CodeChipsNotMatch = 4021,
    /**
     * 如复活用户，在超时，最后一桌等情况，不再满足条件，被取消资格
     *
     * @generated from protobuf enum value: CodeCanceled = 4022;
     */
    CodeCanceled = 4022,
    /**
     * 需要申请审核，报名时尚未审核通过返回此错误
     *
     * @generated from protobuf enum value: CodeMatchNeedApply = 4024;
     */
    CodeMatchNeedApply = 4024,
    /**
     * 当前的交易进行中，完成当前付款前不能付款
     *
     * @generated from protobuf enum value: CodeTransactionOnGoing = 4025;
     */
    CodeTransactionOnGoing = 4025,
    /**
     * 用户未出局,如用户reentry，但是未出局，不能reentry
     *
     * @generated from protobuf enum value: CodeNotOut = 4026;
     */
    CodeNotOut = 4026,
    /**
     * 桌子找不到，可能是非法的参数，也可能桌子已销毁
     *
     * @generated from protobuf enum value: CodeTableNotExist = 4027;
     */
    CodeTableNotExist = 4027,
    /**
     * 比赛已取消
     *
     * @generated from protobuf enum value: CodeMatchCanceled = 4028;
     */
    CodeMatchCanceled = 4028,
    /**
     * @generated from protobuf enum value: CodeMatchStopped = 4029;
     */
    CodeMatchStopped = 4029,
    /**
     * @generated from protobuf enum value: CodeMatchApplyRejected = 4030;
     */
    CodeMatchApplyRejected = 4030,
    /**
     * 权限不足，某些操作限定创建人执行，如后台上线比赛，需要本人操作
     *
     * @generated from protobuf enum value: CodeNotOwner = 4031;
     */
    CodeNotOwner = 4031,
    /**
     * 人数不足开赛失败
     *
     * @generated from protobuf enum value: CodeUserNotEnough = 4032;
     */
    CodeUserNotEnough = 4032,
    /**
     * 非法的门票，如要求A门票报名传入b门票
     *
     * @generated from protobuf enum value: CodeInvalidTicket = 4033;
     */
    CodeInvalidTicket = 4033,
    /**
     * 超过门票的有效期
     *
     * @generated from protobuf enum value: CodeTicketExpired = 4034;
     */
    CodeTicketExpired = 4034,
    /**
     * 用户已出局
     *
     * @generated from protobuf enum value: CodeUserIsOut = 4035;
     */
    CodeUserIsOut = 4035,
    /**
     * 不可操作的状体啊，如暂停比赛，但是比赛已结束，状态不正确
     *
     * @generated from protobuf enum value: CodeInvalidMatchStatus = 4036;
     */
    CodeInvalidMatchStatus = 4036,
    /**
     * 用户未获奖
     *
     * @generated from protobuf enum value: CodeNotPrizeUser = 4037;
     */
    CodeNotPrizeUser = 4037,
    /**
     * 填写表单不合法
     *
     * @generated from protobuf enum value: CodeInvalidForm = 4038;
     */
    CodeInvalidForm = 4038,
    /**
     * 不满足加入条件_VIP等级不够
     *
     * @generated from protobuf enum value: CodeInvalidRegisterNotEnoughVIPLeavel = 4039;
     */
    CodeInvalidRegisterNotEnoughVIPLeavel = 4039,
    /**
     * 不满足加入条件_身份认证等级不够
     *
     * @generated from protobuf enum value: CodeInvalidRegisterNotIdentityLevel = 4040;
     */
    CodeInvalidRegisterNotIdentityLevel = 4040,
    /**
     * bpt.match.mttmach
     *
     * 交易已处理
     *
     * @generated from protobuf enum value: CodeTxProcessed = 4041;
     */
    CodeTxProcessed = 4041,
    /**
     * 交易无效
     *
     * @generated from protobuf enum value: CodeTxInvalid = 4042;
     */
    CodeTxInvalid = 4042,
    /**
     * 比赛已结束
     *
     * @generated from protobuf enum value: CodeMatchOver = 4043;
     */
    CodeMatchOver = 4043,
    /**
     * 比赛繁忙
     *
     * @generated from protobuf enum value: CodeMatchBusy = 4044;
     */
    CodeMatchBusy = 4044,
    /**
     * 用户不在报名俱乐部列表
     *
     * @generated from protobuf enum value: CodeUserNotInClub = 4045;
     */
    CodeUserNotInClub = 4045,
    /**
     * 油价
     *
     * @generated from protobuf enum value: CodeNoPaymentInfo = 5001;
     */
    CodeNoPaymentInfo = 5001,
    /**
     * @generated from protobuf enum value: CodeInvalidMail = 5002;
     */
    CodeInvalidMail = 5002,
    /**
     * cashmatch code
     * 达到上限
     *
     * @generated from protobuf enum value: CodeReBuyLimit = 6001;
     */
    CodeReBuyLimit = 6001,
    /**
     * 金币不足
     *
     * @generated from protobuf enum value: CodeNoMoney = 6002;
     */
    CodeNoMoney = 6002,
    /**
     * 已经在座位上
     *
     * @generated from protobuf enum value: CodeAlreadyInSeat = 6003;
     */
    CodeAlreadyInSeat = 6003,
    /**
     * 8001-8999 运营后台
     *
     * 已发奖/已领奖
     *
     * @generated from protobuf enum value: CodeAlreadyReward = 8001;
     */
    CodeAlreadyReward = 8001,
    /**
     * 当前无可进入牌桌
     *
     * @generated from protobuf enum value: CodeCurrentNoAvailableTable = 1000002;
     */
    CodeCurrentNoAvailableTable = 1000002,
    /**
     * 未到领取时间
     *
     * @generated from protobuf enum value: CodeNotReachReceiveTime = 1000001;
     */
    CodeNotReachReceiveTime = 1000001,
    /**
     * 找不到对应桌子
     *
     * @generated from protobuf enum value: CODE_TABLE_NOT_FOUND = 483329;
     */
    CODE_TABLE_NOT_FOUND = 483329,
    /**
     * 找不到对应玩家
     *
     * @generated from protobuf enum value: CODE_USER_NOT_FOUND = 483330;
     */
    CODE_USER_NOT_FOUND = 483330,
    /**
     * 找不到牌局记录
     *
     * @generated from protobuf enum value: CODE_GAME_LOG_NOT_FOUND = 483331;
     */
    CODE_GAME_LOG_NOT_FOUND = 483331,
    /**
     * 找不到游戏回放
     *
     * @generated from protobuf enum value: CODE_GAME_REPLAY_NOT_FOUND = 483332;
     */
    CODE_GAME_REPLAY_NOT_FOUND = 483332,
    /**
     * 围观桌子已经结束
     *
     * @generated from protobuf enum value: CODE_OBSERVE_TABLE_END = 483333;
     */
    CODE_OBSERVE_TABLE_END = 483333,
    /**
     * 桌子已存在
     *
     * @generated from protobuf enum value: CODE_TABLE_ALREADY_START = 483334;
     */
    CODE_TABLE_ALREADY_START = 483334,
    /**
     * 桌子已存在
     *
     * @generated from protobuf enum value: CODE_TABLE_OVERFLOW = 483335;
     */
    CODE_TABLE_OVERFLOW = 483335,
    /**
     * 桌子上找不到玩家
     *
     * @generated from protobuf enum value: CODE_TABLE_NOT_FOUND_USER = 483336;
     */
    CODE_TABLE_NOT_FOUND_USER = 483336,
    /**
     * 没有足够的道具数量
     *
     * @generated from protobuf enum value: CodeNoEnoughPropNum = 700673;
     */
    CodeNoEnoughPropNum = 700673,
    /**
     * 列表桌子已满
     *
     * @generated from protobuf enum value: CodeTableViewReachLimit = 700674;
     */
    CodeTableViewReachLimit = 700674,
    /**
     * 列表桌子已存在
     *
     * @generated from protobuf enum value: CodeTableViewExist = 700675;
     */
    CodeTableViewExist = 700675,
    /**
     * 用户不在操作中
     *
     * @generated from protobuf enum value: CodeUserNotOperating = 700676;
     */
    CodeUserNotOperating = 700676,
    /**
     * 用户时间卡不足
     *
     * @generated from protobuf enum value: CodeUserTimeCardNotEnough = 700677;
     */
    CodeUserTimeCardNotEnough = 700677,
    /**
     * mail 错误码8000-8999
     *
     * @generated from protobuf enum value: CodeCreateMailBusinessIdExist = 8000;
     */
    CodeCreateMailBusinessIdExist = 8000,
    /**
     * 道具商城 错误码9000-9999
     * 签名未找到
     *
     * 价格错误
     *
     * @generated from protobuf enum value: PricesError = 9000;
     */
    PricesError = 9000,
    /**
     * 道具不存在
     *
     * @generated from protobuf enum value: PropNotFound = 9001;
     */
    PropNotFound = 9001,
    /**
     * 币种不存在
     *
     * @generated from protobuf enum value: CoinNotFound = 9002;
     */
    CoinNotFound = 9002,
    /**
     * 商品已存在
     *
     * @generated from protobuf enum value: ProductExist = 9003;
     */
    ProductExist = 9003,
    /**
     * 商品ID错误
     *
     * @generated from protobuf enum value: PrimitiveObjectID = 9004;
     */
    PrimitiveObjectID = 9004,
    /**
     * 商品上架失败
     *
     * @generated from protobuf enum value: OnShelvesFail = 9005;
     */
    OnShelvesFail = 9005,
    /**
     * 商品下架失败
     *
     * @generated from protobuf enum value: OffShelvesFail = 9006;
     */
    OffShelvesFail = 9006,
    /**
     * 商品删除失败
     *
     * @generated from protobuf enum value: DelProductFail = 9007;
     */
    DelProductFail = 9007,
    /**
     * 商品置顶失败
     *
     * @generated from protobuf enum value: SetPinFail = 9008;
     */
    SetPinFail = 9008,
    /**
     * 商品取消置顶失败
     *
     * @generated from protobuf enum value: UnsetPinFail = 9009;
     */
    UnsetPinFail = 9009,
    /**
     * 无效道具类型
     *
     * @generated from protobuf enum value: PropTypeInvalid = 9010;
     */
    PropTypeInvalid = 9010,
    /**
     * Club俱乐部
     *
     * 俱乐部名已存在
     *
     * @generated from protobuf enum value: ClubNameExistError = 10001;
     */
    ClubNameExistError = 10001,
    /**
     * 加入俱乐部
     *
     * @generated from protobuf enum value: ClubMemberAddError = 10002;
     */
    ClubMemberAddError = 10002,
    /**
     * 设置成员身份错误
     *
     * @generated from protobuf enum value: ClubMemberUpdateError = 10003;
     */
    ClubMemberUpdateError = 10003,
    /**
     * 加入条件不满足
     *
     * @generated from protobuf enum value: ClubMemberJoinError = 10004;
     */
    ClubMemberJoinError = 10004,
    /**
     * 俱乐部头像为空
     *
     * @generated from protobuf enum value: ClubLogoEmptyError = 10006;
     */
    ClubLogoEmptyError = 10006,
    /**
     * 成员管理无权限
     *
     * @generated from protobuf enum value: ClubUpdateRightError = 10007;
     */
    ClubUpdateRightError = 10007
}
