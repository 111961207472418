// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/match/matchhub/v1/matchhub.proto" (package "bpt.match.matchhub.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { MatchHubService } from "./matchhub";
import type { GetRecommendMatchResp } from "./matchhub";
import type { GetMatchFeaturesResp } from "./matchhub";
import type { GetMatchFeaturesReq } from "./matchhub";
import type { ListMatchFeaturesResp } from "./matchhub";
import type { DeleteMatchNoticeConfigReq } from "./matchhub";
import type { SaveMatchNoticeConfigResp } from "./matchhub";
import type { SaveMatchNoticeConfigReq } from "./matchhub";
import type { GetMatchNoticeConfigResp } from "./matchhub";
import type { GetMatchNoticeConfigReq } from "./matchhub";
import type { ListMatchNoticeConfigResp } from "./matchhub";
import type { ListMatchNoticeConfigReq } from "./matchhub";
import type { GetMatchNoticeResp } from "./matchhub";
import type { GetMatchNoticeReq } from "./matchhub";
import type { GetMatchPrizeNumResp } from "./matchhub";
import type { GetMatchPrizeNumReq } from "./matchhub";
import type { MsgMatchOver } from "../../../broker/match/match";
import type { MsgMatchUserSettlement } from "../../../broker/match/match";
import type { MsgMatchRegisterDeadline } from "../../../broker/match/match";
import type { GetEarlyBirdConfigInternalResp } from "./matchhub";
import type { GetEarlyBirdConfigInternalReq } from "./matchhub";
import type { AuditAwardClaimFormReq } from "./matchhub";
import type { ListAwardClaimFormResp } from "./matchhub";
import type { ListAwardClaimFormReq } from "./matchhub";
import type { SubmitAwardClaimFormReq } from "./matchhub";
import type { RestoreMatchTablesResp } from "../../mttmatch/v1/mttmatch";
import type { RestoreMatchTablesReq } from "../../mttmatch/v1/mttmatch";
import type { AdminRegisterReq } from "./matchhub";
import type { MarkReadCompletedAwardMatchReq } from "./matchhub";
import type { UsePropShuffleCardCallBackResp } from "./matchhub";
import type { UsePropShuffleCardCallBackReq } from "./matchhub";
import type { UsePropResp } from "./matchhub";
import type { UsePropReq } from "./matchhub";
import type { GetReEntryInfoResp } from "./matchhub";
import type { GetReEntryInfoReq } from "./matchhub";
import type { GetRegisterInfoResp } from "./matchhub";
import type { GetRegisterInfoReq } from "./matchhub";
import type { MarkAwardResp } from "./matchhub";
import type { MarkAwardReq } from "./matchhub";
import type { ListUserRecordByCmsResp } from "./matchhub";
import type { ListUserRecordByCmsReq } from "./matchhub";
import type { ListDispatchContractResp } from "./matchhub";
import type { ListDispatchContractReq } from "./matchhub";
import type { FormatAndValidateTierPrizeResp } from "./matchhub";
import type { FormatAndValidateTierPrizeReq } from "./matchhub";
import type { ListMatchPrizeAndBubbleUserResp } from "./matchhub";
import type { ListMatchPrizeAndBubbleUserReq } from "./matchhub";
import type { ListPrizePoolResp } from "./matchhub";
import type { ListPrizePoolReq } from "./matchhub";
import type { BatchGetMatchNameAndStartAtResp } from "./matchhub";
import type { BatchGetMatchNameAndStartAtReq } from "./matchhub";
import type { GetMatchNameAndStartAtResp } from "./matchhub";
import type { GetMatchNameAndStartAtReq } from "./matchhub";
import type { GetDayOneMaxChipsResp } from "./matchhub";
import type { GetDayOneMaxChipsReq } from "./matchhub";
import type { ListSubMatchesResp } from "./matchhub";
import type { ListSubMatchesReq } from "./matchhub";
import type { GetMyTableResp } from "../../mttmatch/v1/mttmatch";
import type { GetMyTableReq } from "../../mttmatch/v1/mttmatch";
import type { ObserveTableOnBubbleResp } from "../../mttmatch/v1/mttmatch";
import type { ObserveTableOnBubbleReq } from "../../mttmatch/v1/mttmatch";
import type { ObserveTableResp } from "../../mttmatch/v1/mttmatch";
import type { ObserveTableReq } from "../../mttmatch/v1/mttmatch";
import type { RemoveTableViewReq } from "./matchhub";
import type { BatchAddTableViewReq } from "./matchhub";
import type { AddTableViewReq } from "./matchhub";
import type { ListTableViewsResp } from "./matchhub";
import type { BatchGetUserResp } from "../../mttmatch/v1/mttmatch";
import type { BatchGetUserReq } from "../../mttmatch/v1/mttmatch";
import type { ListDesksResp } from "../../mttmatch/v1/mttmatch";
import type { ListDesksReq } from "../../mttmatch/v1/mttmatch";
import type { ListRankResp } from "../../mttmatch/v1/mttmatch";
import type { ListRankReq } from "../../mttmatch/v1/mttmatch";
import type { MatchOverViewResp } from "../../mttmatch/v1/mttmatch";
import type { MatchOverViewReq } from "../../mttmatch/v1/mttmatch";
import type { BatchGetUserCanEnterResp } from "../../mttmatch/v1/mttmatch";
import type { BatchGetUserCanEnterReq } from "../../mttmatch/v1/mttmatch";
import type { BatchGetCanReEnterResp } from "../../mttmatch/v1/mttmatch";
import type { BatchGetCanReEnterReq } from "../../mttmatch/v1/mttmatch";
import type { AddOnResp } from "../../mttmatch/v1/mttmatch";
import type { AddOnReq } from "../../mttmatch/v1/mttmatch";
import type { CancelAddOnResp } from "../../mttmatch/v1/mttmatch";
import type { CancelAddOnReq } from "../../mttmatch/v1/mttmatch";
import type { CancelReBuyResp } from "../../mttmatch/v1/mttmatch";
import type { CancelReBuyReq } from "../../mttmatch/v1/mttmatch";
import type { ReEntryResp } from "../../mttmatch/v1/mttmatch";
import type { ReEntryReq } from "../../mttmatch/v1/mttmatch";
import type { ReBuyResp } from "../../mttmatch/v1/mttmatch";
import type { ReBuyReq } from "../../mttmatch/v1/mttmatch";
import type { AnimationFinishResp } from "../../mttmatch/v1/mttmatch";
import type { AnimationFinishReq } from "../../mttmatch/v1/mttmatch";
import type { UpdatePoolResp } from "./matchhub";
import type { UpdatePoolReq } from "./matchhub";
import type { GetChipInfoResp } from "./matchhub";
import type { GetChipInfoReq } from "./matchhub";
import type { GetMyMatchNumResp } from "./matchhub";
import type { GetMyMatchNumReq } from "./matchhub";
import type { GetMttMatchFinalRankingResp } from "./matchhub";
import type { GetMttMatchFinalRankingReq } from "./matchhub";
import type { GetMatchMoneyCircleUsersResp } from "./matchhub";
import type { GetMatchMoneyCircleUsersReq } from "./matchhub";
import type { GetMatchSchedulesResp } from "./matchhub";
import type { GetMatchSchedulesReq } from "./matchhub";
import type { GetIndexRankingResp } from "./matchhub";
import type { GetIndexRankingReq } from "./matchhub";
import type { GetMatchPrizeUsersResp } from "./matchhub";
import type { GetMatchPrizeUsersReq } from "./matchhub";
import type { GetMatchReportDetailResp } from "./matchhub";
import type { GetMatchReportDetailReq } from "./matchhub";
import type { GetMyMatchRecordResp } from "./matchhub";
import type { GetMyMatchRecordReq } from "./matchhub";
import type { GetUserObservableMatchResp } from "./matchhub";
import type { GetUserObservableMatchReq } from "./matchhub";
import type { GetHomeMyMatchListResp } from "./matchhub";
import type { GetHomeMyMatchListReq } from "./matchhub";
import type { GetListMyMttMatchResp } from "./matchhub";
import type { GetListMyMatchResp } from "./matchhub";
import type { GetListMyMatchReq } from "./matchhub";
import type { ListHistoryClubMatchResp } from "./matchhub";
import type { ListHistoryClubMatchReq } from "./matchhub";
import type { ListHistoryMatchResp } from "./matchhub";
import type { ListHistoryMatchReq } from "./matchhub";
import type { ListLatestMatchResp } from "./matchhub";
import type { ListLatestMatchReq } from "./matchhub";
import type { ListClubMatchItemResp } from "./matchhub";
import type { ListClubMatchItemReq } from "./matchhub";
import type { ListMatchItemResp } from "./matchhub";
import type { ListMatchItemReq } from "./matchhub";
import type { ListSidebarMatchResp } from "./matchhub";
import type { ListSidebarMatchReq } from "./matchhub";
import type { ListMttMatchResp } from "./matchhub";
import type { ListMttMatchReq } from "./matchhub";
import type { ListMatchResp } from "./matchhub";
import type { ListMatchReq } from "./matchhub";
import type { UpdateListUsersReq } from "./matchhub";
import type { UpdateListStartReq } from "./matchhub";
import type { RemoveMatchByMatchKeyReq } from "./matchhub";
import type { RemoveMatchByConfIdReq } from "./matchhub";
import type { RemoveMatchReq } from "./matchhub";
import type { CreateMatchReq } from "./matchhub";
import type { ChangeDeskResp } from "./matchhub";
import type { ChangeDeskReq } from "./matchhub";
import type { QuickStartResp } from "./matchhub";
import type { QuickStartReq } from "./matchhub";
import type { DeleteMatchTagReq } from "./matchhub";
import type { SaveMatchTagReq } from "./matchhub";
import type { ListMatchTagResp } from "./matchhub";
import type { ListMatchTagReq } from "./matchhub";
import type { FreeAddChipsResp } from "./matchhub";
import type { FreeAddChipsReq } from "./matchhub";
import type { GenPayCredentialResp } from "./matchhub";
import type { GenPayCredentialReq } from "./matchhub";
import type { BatchGetMatchStateAndUserStateResp } from "./matchhub";
import type { BatchGetMatchStateAndUserStateReq } from "./matchhub";
import type { ListMyMatchResp } from "./matchhub";
import type { ListMyMatchReq } from "./matchhub";
import type { JoinMatchResp } from "./matchhub";
import type { JoinMatchReq } from "./matchhub";
import type { GetMatchBriefResp } from "./matchhub";
import type { GetMatchBriefReq } from "./matchhub";
import type { GetMatchResp } from "./matchhub";
import type { GetMatchReq } from "./matchhub";
import type { GetUserResp } from "./matchhub";
import type { GetUserReq } from "./matchhub";
import type { ListJoinedUsersResp } from "./matchhub";
import type { ListJoinedUsersReq } from "./matchhub";
import type { ListMatchBlindOptionsResp } from "./matchhub";
import type { ListMatchBlindOptionsReq } from "./matchhub";
import type { ListMultiMatchTablesResp } from "./matchhub";
import type { ListMultiMatchTablesReq } from "./matchhub";
import type { ListRegisterUsersResp } from "./matchhub";
import type { ListRegisterUsersReq } from "./matchhub";
import type { ListMatchUsersResp } from "./matchhub";
import type { ListMatchUsersReq } from "./matchhub";
import type { ListMatchTablesResp } from "./matchhub";
import type { ListMatchTablesReq } from "./matchhub";
import type { Transaction } from "../../matchlink/v1/matchlink";
import type { UpdateUserManageStatusReq } from "./matchhub";
import type { BatchGetUserManageResp } from "./matchhub";
import type { BatchGetUserManageReq } from "./matchhub";
import type { UserUnRegisterMatchResp } from "./matchhub";
import type { UserUnRegisterMatchReq } from "./matchhub";
import type { UserRegisterMatchResp } from "./matchhub";
import type { UserRegisterMatchReq } from "./matchhub";
import type { ListUserManageResp } from "./matchhub";
import type { ListUserManageReq } from "./matchhub";
import type { AuditUserManageResp } from "./matchhub";
import type { AuditUserManageReq } from "./matchhub";
import type { UserApplyMatchResp } from "./matchhub";
import type { UserApplyMatchReq } from "./matchhub";
import type { ForceCancelMatchResp } from "./matchhub";
import type { ForceCancelMatchReq } from "./matchhub";
import type { CancelMatchByUserResp } from "./matchhub";
import type { CancelMatchByUserReq } from "./matchhub";
import type { UpdateMatchRecordConfReq } from "./matchhub";
import type { UpdateMatchRecordStateResp } from "./matchhub";
import type { UpdateMatchRecordStateReq } from "./matchhub";
import type { GetMatchRecordResp } from "./matchhub";
import type { GetMatchRecordReq } from "./matchhub";
import type { BatchGetRunningMatchResp } from "./matchhub";
import type { BatchGetRunningMatchReq } from "./matchhub";
import type { ListMatchRecordResp } from "./matchhub";
import type { ListMatchRecordReq } from "./matchhub";
import type { DeleteMatchConfigTemplateV2Req } from "./matchhub";
import type { ListMatchConfigTemplateV2Resp } from "./matchhub";
import type { ListMatchConfigTemplateV2Req } from "./matchhub";
import type { GetMatchConfigTemplateV2Resp } from "./matchhub";
import type { GetMatchConfigTemplateV2Req } from "./matchhub";
import type { SaveMatchConfigTemplateV2Resp } from "./matchhub";
import type { SaveMatchConfigTemplateV2Req } from "./matchhub";
import type { ListMatchConfigOperationLogResp } from "./matchhub";
import type { ListMatchConfigOperationLogReq } from "./matchhub";
import type { DeleteMatchConfigTemplateResp } from "./matchhub";
import type { DeleteMatchConfigTemplateReq } from "./matchhub";
import type { ListMatchConfigTemplateResp } from "./matchhub";
import type { ListMatchConfigTemplateReq } from "./matchhub";
import type { GetMatchConfigTemplateResp } from "./matchhub";
import type { GetMatchConfigTemplateReq } from "./matchhub";
import type { SaveMatchConfigTemplateResp } from "./matchhub";
import type { SaveMatchConfigTemplateReq } from "./matchhub";
import type { ListMatchConfigResp } from "./matchhub";
import type { ListMatchConfigReq } from "./matchhub";
import type { DeleteMatchConfigResp } from "./matchhub";
import type { DeleteMatchConfigReq } from "./matchhub";
import type { DuplicateMatchConfigResp } from "./matchhub";
import type { DuplicateMatchConfigReq } from "./matchhub";
import type { GetMatchConfigResp } from "./matchhub";
import type { GetMatchConfigReq } from "./matchhub";
import type { SaveMatchConfigAllResp } from "./matchhub";
import type { SaveMatchConfigAllReq } from "./matchhub";
import type { SaveMatchConfigRewardResp } from "./matchhub";
import type { SaveMatchConfigRewardReq } from "./matchhub";
import type { SaveMatchConfigBlindResp } from "./matchhub";
import type { SaveMatchConfigBlindReq } from "./matchhub";
import type { SaveMatchConfigBaseResp } from "./matchhub";
import type { SaveMatchConfigBaseReq } from "./matchhub";
import type { CreateMatchConfigResp } from "./matchhub";
import type { CreateMatchConfigReq } from "./matchhub";
import type { ResumeMatchReq } from "./matchhub";
import type { PauseMatchReq } from "./matchhub";
import type { AdminFetchUserPrizeRewardResp } from "./matchhub";
import type { AdminFetchUserPrizeRewardReq } from "./matchhub";
import type { AdminSendRewardResp } from "./matchhub";
import type { AdminSendRewardReq } from "./matchhub";
import type { OfflineMatchConfigResp } from "./matchhub";
import type { OfflineMatchConfigReq } from "./matchhub";
import type { OnlineMatchConfigResp } from "./matchhub";
import type { OnlineMatchConfigReq } from "./matchhub";
import type { GetSeatMatchLiteInfoResp } from "./matchhub";
import type { GetSeatMatchLiteInfoReq } from "./matchhub";
import type { GetSimpleMatchResp } from "./matchhub";
import type { GetSimpleMatchReq } from "./matchhub";
import type { DeleteMatchConfTmplReq } from "./matchhub";
import type { ListMatchConfTmplResp } from "./matchhub";
import type { ListMatchConfTmplReq } from "./matchhub";
import type { GetMatchConfTmplResp } from "./matchhub";
import type { GetMatchConfTmplReq } from "./matchhub";
import type { SaveMatchConfTmplResp } from "./matchhub";
import type { SaveMatchConfTmplReq } from "./matchhub";
import type { GetConfByIdsResp } from "./matchhub";
import type { GetConfByIdsReq } from "./matchhub";
import type { ListMatchConfResp } from "./matchhub";
import type { ListMatchConfReq } from "./matchhub";
import type { ListMatchConfByKeyResp } from "./matchhub";
import type { ListMatchConfByKeyReq } from "./matchhub";
import type { BatchGetMatchConfResp } from "./matchhub";
import type { BatchGetMatchConfReq } from "./matchhub";
import type { GetMatchConfResp } from "./matchhub";
import type { GetMatchConfReq } from "./matchhub";
import type { UpdateMatchConfStateResp } from "./matchhub";
import type { UpdateMatchConfStateReq } from "./matchhub";
import type { DeleteMatchConfResp } from "./matchhub";
import type { DeleteMatchConfReq } from "./matchhub";
import type { ReuseMatchConfResp } from "./matchhub";
import type { ReuseMatchConfReq } from "./matchhub";
import type { SaveMatchConfResp } from "./matchhub";
import type { SaveMatchConfReq } from "./matchhub";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { Void } from "../../../base/base";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service bpt.match.matchhub.v1.MatchHubService
 */
export interface IMatchHubServiceClient {
    /**
     * @generated from protobuf rpc: Ping(bpt.base.Void) returns (bpt.base.Void);
     */
    ping(input: Void, options?: RpcOptions): UnaryCall<Void, Void>;
    /**
     * ===================比赛配置管理=======================
     * Deprecated: use SaveMatchConfigAll
     *
     * @generated from protobuf rpc: SaveMatchConf(bpt.match.matchhub.v1.SaveMatchConfReq) returns (bpt.match.matchhub.v1.SaveMatchConfResp);
     */
    saveMatchConf(input: SaveMatchConfReq, options?: RpcOptions): UnaryCall<SaveMatchConfReq, SaveMatchConfResp>;
    /**
     * Deprecated: use DuplicateMatchConfig
     *
     * @generated from protobuf rpc: ReuseMatchConf(bpt.match.matchhub.v1.ReuseMatchConfReq) returns (bpt.match.matchhub.v1.ReuseMatchConfResp);
     */
    reuseMatchConf(input: ReuseMatchConfReq, options?: RpcOptions): UnaryCall<ReuseMatchConfReq, ReuseMatchConfResp>;
    /**
     * Deprecated: use DeleteMatchConfig
     *
     * @generated from protobuf rpc: DeleteMatchConf(bpt.match.matchhub.v1.DeleteMatchConfReq) returns (bpt.match.matchhub.v1.DeleteMatchConfResp);
     */
    deleteMatchConf(input: DeleteMatchConfReq, options?: RpcOptions): UnaryCall<DeleteMatchConfReq, DeleteMatchConfResp>;
    /**
     * Deprecated: use OnlineMatchConfig
     * StatePause 暂停
     * StateStop 下线
     * StatePending 赛事创建者暂停
     *
     * @generated from protobuf rpc: UpdateMatchConfState(bpt.match.matchhub.v1.UpdateMatchConfStateReq) returns (bpt.match.matchhub.v1.UpdateMatchConfStateResp);
     */
    updateMatchConfState(input: UpdateMatchConfStateReq, options?: RpcOptions): UnaryCall<UpdateMatchConfStateReq, UpdateMatchConfStateResp>;
    /**
     * Deprecated: use GetMatchConfig
     *
     * @generated from protobuf rpc: GetMatchConf(bpt.match.matchhub.v1.GetMatchConfReq) returns (bpt.match.matchhub.v1.GetMatchConfResp);
     */
    getMatchConf(input: GetMatchConfReq, options?: RpcOptions): UnaryCall<GetMatchConfReq, GetMatchConfResp>;
    /**
     * Deprecated:ListMatchConfig
     *
     * deprecated
     *
     * @generated from protobuf rpc: BatchGetMatchConf(bpt.match.matchhub.v1.BatchGetMatchConfReq) returns (bpt.match.matchhub.v1.BatchGetMatchConfResp);
     */
    batchGetMatchConf(input: BatchGetMatchConfReq, options?: RpcOptions): UnaryCall<BatchGetMatchConfReq, BatchGetMatchConfResp>;
    /**
     * @generated from protobuf rpc: ListMatchConfByKey(bpt.match.matchhub.v1.ListMatchConfByKeyReq) returns (bpt.match.matchhub.v1.ListMatchConfByKeyResp);
     */
    listMatchConfByKey(input: ListMatchConfByKeyReq, options?: RpcOptions): UnaryCall<ListMatchConfByKeyReq, ListMatchConfByKeyResp>;
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: ListMatchConf(bpt.match.matchhub.v1.ListMatchConfReq) returns (bpt.match.matchhub.v1.ListMatchConfResp);
     */
    listMatchConf(input: ListMatchConfReq, options?: RpcOptions): UnaryCall<ListMatchConfReq, ListMatchConfResp>;
    /**
     * @generated from protobuf rpc: GetConfByIds(bpt.match.matchhub.v1.GetConfByIdsReq) returns (bpt.match.matchhub.v1.GetConfByIdsResp);
     */
    getConfByIds(input: GetConfByIdsReq, options?: RpcOptions): UnaryCall<GetConfByIdsReq, GetConfByIdsResp>;
    /**
     * Deprecated:SaveMatchConfigTemplate
     *
     * @generated from protobuf rpc: SaveMatchConfTmpl(bpt.match.matchhub.v1.SaveMatchConfTmplReq) returns (bpt.match.matchhub.v1.SaveMatchConfTmplResp);
     */
    saveMatchConfTmpl(input: SaveMatchConfTmplReq, options?: RpcOptions): UnaryCall<SaveMatchConfTmplReq, SaveMatchConfTmplResp>;
    /**
     * Deprecated:GetMatchConfigTemplate
     *
     * @generated from protobuf rpc: GetMatchConfTmpl(bpt.match.matchhub.v1.GetMatchConfTmplReq) returns (bpt.match.matchhub.v1.GetMatchConfTmplResp);
     */
    getMatchConfTmpl(input: GetMatchConfTmplReq, options?: RpcOptions): UnaryCall<GetMatchConfTmplReq, GetMatchConfTmplResp>;
    /**
     * Deprecated:ListMatchConfigTemplate
     *
     * @generated from protobuf rpc: ListMatchConfTmpl(bpt.match.matchhub.v1.ListMatchConfTmplReq) returns (bpt.match.matchhub.v1.ListMatchConfTmplResp);
     */
    listMatchConfTmpl(input: ListMatchConfTmplReq, options?: RpcOptions): UnaryCall<ListMatchConfTmplReq, ListMatchConfTmplResp>;
    /**
     * Deprecated:DeleteMatchConfigTemplate
     *
     * @generated from protobuf rpc: DeleteMatchConfTmpl(bpt.match.matchhub.v1.DeleteMatchConfTmplReq) returns (bpt.base.Void);
     */
    deleteMatchConfTmpl(input: DeleteMatchConfTmplReq, options?: RpcOptions): UnaryCall<DeleteMatchConfTmplReq, Void>;
    /**
     * 比赛简要在简要信息(名字、开始时间)
     *
     * @generated from protobuf rpc: GetSimpleMatch(bpt.match.matchhub.v1.GetSimpleMatchReq) returns (bpt.match.matchhub.v1.GetSimpleMatchResp);
     */
    getSimpleMatch(input: GetSimpleMatchReq, options?: RpcOptions): UnaryCall<GetSimpleMatchReq, GetSimpleMatchResp>;
    /**
     * 查询当前比赛的GetSeatMatchLiteInfo
     *
     * @generated from protobuf rpc: GetSeatMatchLiteInfo(bpt.match.matchhub.v1.GetSeatMatchLiteInfoReq) returns (bpt.match.matchhub.v1.GetSeatMatchLiteInfoResp);
     */
    getSeatMatchLiteInfo(input: GetSeatMatchLiteInfoReq, options?: RpcOptions): UnaryCall<GetSeatMatchLiteInfoReq, GetSeatMatchLiteInfoResp>;
    /**
     * ===================新后台比赛配置相关接口 Start=======================
     * 上线赛事
     *
     * @generated from protobuf rpc: OnlineMatchConfig(bpt.match.matchhub.v1.OnlineMatchConfigReq) returns (bpt.match.matchhub.v1.OnlineMatchConfigResp);
     */
    onlineMatchConfig(input: OnlineMatchConfigReq, options?: RpcOptions): UnaryCall<OnlineMatchConfigReq, OnlineMatchConfigResp>;
    /**
     * 下线赛事
     *
     * @generated from protobuf rpc: OfflineMatchConfig(bpt.match.matchhub.v1.OfflineMatchConfigReq) returns (bpt.match.matchhub.v1.OfflineMatchConfigResp);
     */
    offlineMatchConfig(input: OfflineMatchConfigReq, options?: RpcOptions): UnaryCall<OfflineMatchConfigReq, OfflineMatchConfigResp>;
    /**
     * 后台发送奖励
     *
     * @generated from protobuf rpc: AdminSendReward(bpt.match.matchhub.v1.AdminSendRewardReq) returns (bpt.match.matchhub.v1.AdminSendRewardResp);
     */
    adminSendReward(input: AdminSendRewardReq, options?: RpcOptions): UnaryCall<AdminSendRewardReq, AdminSendRewardResp>;
    /**
     * 后台获取当前比赛的所有用户奖励信息
     *
     * @generated from protobuf rpc: AdminFetchUserPrizeReward(bpt.match.matchhub.v1.AdminFetchUserPrizeRewardReq) returns (bpt.match.matchhub.v1.AdminFetchUserPrizeRewardResp);
     */
    adminFetchUserPrizeReward(input: AdminFetchUserPrizeRewardReq, options?: RpcOptions): UnaryCall<AdminFetchUserPrizeRewardReq, AdminFetchUserPrizeRewardResp>;
    /**
     * 暂停比赛
     *
     * @generated from protobuf rpc: PauseMatch(bpt.match.matchhub.v1.PauseMatchReq) returns (bpt.base.Void);
     */
    pauseMatch(input: PauseMatchReq, options?: RpcOptions): UnaryCall<PauseMatchReq, Void>;
    /**
     * 继续比赛
     *
     * @generated from protobuf rpc: ResumeMatch(bpt.match.matchhub.v1.ResumeMatchReq) returns (bpt.base.Void);
     */
    resumeMatch(input: ResumeMatchReq, options?: RpcOptions): UnaryCall<ResumeMatchReq, Void>;
    /**
     * CreateMatchConfig
     *
     * @generated from protobuf rpc: CreateMatchConfig(bpt.match.matchhub.v1.CreateMatchConfigReq) returns (bpt.match.matchhub.v1.CreateMatchConfigResp);
     */
    createMatchConfig(input: CreateMatchConfigReq, options?: RpcOptions): UnaryCall<CreateMatchConfigReq, CreateMatchConfigResp>;
    /**
     * 保存基本信息
     *
     * @generated from protobuf rpc: SaveMatchConfigBase(bpt.match.matchhub.v1.SaveMatchConfigBaseReq) returns (bpt.match.matchhub.v1.SaveMatchConfigBaseResp);
     */
    saveMatchConfigBase(input: SaveMatchConfigBaseReq, options?: RpcOptions): UnaryCall<SaveMatchConfigBaseReq, SaveMatchConfigBaseResp>;
    /**
     * 保存盲注信息
     *
     * @generated from protobuf rpc: SaveMatchConfigBlind(bpt.match.matchhub.v1.SaveMatchConfigBlindReq) returns (bpt.match.matchhub.v1.SaveMatchConfigBlindResp);
     */
    saveMatchConfigBlind(input: SaveMatchConfigBlindReq, options?: RpcOptions): UnaryCall<SaveMatchConfigBlindReq, SaveMatchConfigBlindResp>;
    /**
     * 保存奖励信息
     *
     * @generated from protobuf rpc: SaveMatchConfigReward(bpt.match.matchhub.v1.SaveMatchConfigRewardReq) returns (bpt.match.matchhub.v1.SaveMatchConfigRewardResp);
     */
    saveMatchConfigReward(input: SaveMatchConfigRewardReq, options?: RpcOptions): UnaryCall<SaveMatchConfigRewardReq, SaveMatchConfigRewardResp>;
    /**
     * 聚合保存所有配置信息
     *
     * @generated from protobuf rpc: SaveMatchConfigAll(bpt.match.matchhub.v1.SaveMatchConfigAllReq) returns (bpt.match.matchhub.v1.SaveMatchConfigAllResp);
     */
    saveMatchConfigAll(input: SaveMatchConfigAllReq, options?: RpcOptions): UnaryCall<SaveMatchConfigAllReq, SaveMatchConfigAllResp>;
    /**
     * 获取赛事配置所有信息
     *
     * @generated from protobuf rpc: GetMatchConfig(bpt.match.matchhub.v1.GetMatchConfigReq) returns (bpt.match.matchhub.v1.GetMatchConfigResp);
     */
    getMatchConfig(input: GetMatchConfigReq, options?: RpcOptions): UnaryCall<GetMatchConfigReq, GetMatchConfigResp>;
    /**
     * 复制
     *
     * @generated from protobuf rpc: DuplicateMatchConfig(bpt.match.matchhub.v1.DuplicateMatchConfigReq) returns (bpt.match.matchhub.v1.DuplicateMatchConfigResp);
     */
    duplicateMatchConfig(input: DuplicateMatchConfigReq, options?: RpcOptions): UnaryCall<DuplicateMatchConfigReq, DuplicateMatchConfigResp>;
    /**
     * 删除配置
     *
     * @generated from protobuf rpc: DeleteMatchConfig(bpt.match.matchhub.v1.DeleteMatchConfigReq) returns (bpt.match.matchhub.v1.DeleteMatchConfigResp);
     */
    deleteMatchConfig(input: DeleteMatchConfigReq, options?: RpcOptions): UnaryCall<DeleteMatchConfigReq, DeleteMatchConfigResp>;
    /**
     * 比赛配置记录列表
     *
     * @generated from protobuf rpc: ListMatchConfig(bpt.match.matchhub.v1.ListMatchConfigReq) returns (bpt.match.matchhub.v1.ListMatchConfigResp);
     */
    listMatchConfig(input: ListMatchConfigReq, options?: RpcOptions): UnaryCall<ListMatchConfigReq, ListMatchConfigResp>;
    /**
     * 模版保存(旧版)
     *
     * @generated from protobuf rpc: SaveMatchConfigTemplate(bpt.match.matchhub.v1.SaveMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.SaveMatchConfigTemplateResp);
     */
    saveMatchConfigTemplate(input: SaveMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<SaveMatchConfigTemplateReq, SaveMatchConfigTemplateResp>;
    /**
     * 获取模版(旧版)
     *
     * @generated from protobuf rpc: GetMatchConfigTemplate(bpt.match.matchhub.v1.GetMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.GetMatchConfigTemplateResp);
     */
    getMatchConfigTemplate(input: GetMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<GetMatchConfigTemplateReq, GetMatchConfigTemplateResp>;
    /**
     * 模版列表(旧版)
     *
     * @generated from protobuf rpc: ListMatchConfigTemplate(bpt.match.matchhub.v1.ListMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.ListMatchConfigTemplateResp);
     */
    listMatchConfigTemplate(input: ListMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<ListMatchConfigTemplateReq, ListMatchConfigTemplateResp>;
    /**
     * 删除模版(旧版)
     *
     * @generated from protobuf rpc: DeleteMatchConfigTemplate(bpt.match.matchhub.v1.DeleteMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.DeleteMatchConfigTemplateResp);
     */
    deleteMatchConfigTemplate(input: DeleteMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<DeleteMatchConfigTemplateReq, DeleteMatchConfigTemplateResp>;
    /**
     * 查询配置操作日志列表
     *
     * @generated from protobuf rpc: ListMatchConfigOperationLog(bpt.match.matchhub.v1.ListMatchConfigOperationLogReq) returns (bpt.match.matchhub.v1.ListMatchConfigOperationLogResp);
     */
    listMatchConfigOperationLog(input: ListMatchConfigOperationLogReq, options?: RpcOptions): UnaryCall<ListMatchConfigOperationLogReq, ListMatchConfigOperationLogResp>;
    /**
     * 模版保存
     *
     * @generated from protobuf rpc: SaveMatchConfigTemplateV2(bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Req) returns (bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Resp);
     */
    saveMatchConfigTemplateV2(input: SaveMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<SaveMatchConfigTemplateV2Req, SaveMatchConfigTemplateV2Resp>;
    /**
     * 获取模版
     *
     * @generated from protobuf rpc: GetMatchConfigTemplateV2(bpt.match.matchhub.v1.GetMatchConfigTemplateV2Req) returns (bpt.match.matchhub.v1.GetMatchConfigTemplateV2Resp);
     */
    getMatchConfigTemplateV2(input: GetMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<GetMatchConfigTemplateV2Req, GetMatchConfigTemplateV2Resp>;
    /**
     * 模版列表
     *
     * @generated from protobuf rpc: ListMatchConfigTemplateV2(bpt.match.matchhub.v1.ListMatchConfigTemplateV2Req) returns (bpt.match.matchhub.v1.ListMatchConfigTemplateV2Resp);
     */
    listMatchConfigTemplateV2(input: ListMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<ListMatchConfigTemplateV2Req, ListMatchConfigTemplateV2Resp>;
    /**
     * 删除模版
     *
     * ===================新后台比赛配置相关接口 End=======================
     *
     * @generated from protobuf rpc: DeleteMatchConfigTemplateV2(bpt.match.matchhub.v1.DeleteMatchConfigTemplateV2Req) returns (bpt.base.Void);
     */
    deleteMatchConfigTemplateV2(input: DeleteMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<DeleteMatchConfigTemplateV2Req, Void>;
    /**
     * ===================配置下的比赛列表信息=======================
     * 鉴权
     *
     * @generated from protobuf rpc: ListMatchRecord(bpt.match.matchhub.v1.ListMatchRecordReq) returns (bpt.match.matchhub.v1.ListMatchRecordResp);
     */
    listMatchRecord(input: ListMatchRecordReq, options?: RpcOptions): UnaryCall<ListMatchRecordReq, ListMatchRecordResp>;
    /**
     * 批量获取运行中的赛事，内部使用
     *
     * @generated from protobuf rpc: BatchGetRunningMatch(bpt.match.matchhub.v1.BatchGetRunningMatchReq) returns (bpt.match.matchhub.v1.BatchGetRunningMatchResp);
     */
    batchGetRunningMatch(input: BatchGetRunningMatchReq, options?: RpcOptions): UnaryCall<BatchGetRunningMatchReq, BatchGetRunningMatchResp>;
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: GetMatchRecord(bpt.match.matchhub.v1.GetMatchRecordReq) returns (bpt.match.matchhub.v1.GetMatchRecordResp);
     */
    getMatchRecord(input: GetMatchRecordReq, options?: RpcOptions): UnaryCall<GetMatchRecordReq, GetMatchRecordResp>;
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: UpdateMatchRecordState(bpt.match.matchhub.v1.UpdateMatchRecordStateReq) returns (bpt.match.matchhub.v1.UpdateMatchRecordStateResp);
     */
    updateMatchRecordState(input: UpdateMatchRecordStateReq, options?: RpcOptions): UnaryCall<UpdateMatchRecordStateReq, UpdateMatchRecordStateResp>;
    /**
     * @generated from protobuf rpc: UpdateMatchRecordConf(bpt.match.matchhub.v1.UpdateMatchRecordConfReq) returns (bpt.base.Void);
     */
    updateMatchRecordConf(input: UpdateMatchRecordConfReq, options?: RpcOptions): UnaryCall<UpdateMatchRecordConfReq, Void>;
    /**
     * @generated from protobuf rpc: CancelMatchByUser(bpt.match.matchhub.v1.CancelMatchByUserReq) returns (bpt.match.matchhub.v1.CancelMatchByUserResp);
     */
    cancelMatchByUser(input: CancelMatchByUserReq, options?: RpcOptions): UnaryCall<CancelMatchByUserReq, CancelMatchByUserResp>;
    /**
     * deprecated 强制取消是在比赛开始的情况下调用，应该由后端判断
     *
     * @generated from protobuf rpc: ForceCancelMatch(bpt.match.matchhub.v1.ForceCancelMatchReq) returns (bpt.match.matchhub.v1.ForceCancelMatchResp);
     */
    forceCancelMatch(input: ForceCancelMatchReq, options?: RpcOptions): UnaryCall<ForceCancelMatchReq, ForceCancelMatchResp>;
    /**
     * ===================用户加入比赛审核管理=======================
     * 鉴权
     *
     * @generated from protobuf rpc: UserApplyMatch(bpt.match.matchhub.v1.UserApplyMatchReq) returns (bpt.match.matchhub.v1.UserApplyMatchResp);
     */
    userApplyMatch(input: UserApplyMatchReq, options?: RpcOptions): UnaryCall<UserApplyMatchReq, UserApplyMatchResp>;
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: AuditUserManage(bpt.match.matchhub.v1.AuditUserManageReq) returns (bpt.match.matchhub.v1.AuditUserManageResp);
     */
    auditUserManage(input: AuditUserManageReq, options?: RpcOptions): UnaryCall<AuditUserManageReq, AuditUserManageResp>;
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: ListUserManage(bpt.match.matchhub.v1.ListUserManageReq) returns (bpt.match.matchhub.v1.ListUserManageResp);
     */
    listUserManage(input: ListUserManageReq, options?: RpcOptions): UnaryCall<ListUserManageReq, ListUserManageResp>;
    /**
     * 报名
     * 鉴权
     * CodeExceedLimit 超过报名次数限制
     * CodeNotLogin 未登陆
     * CodeUserNotInClub 不在俱乐部
     * CodeMatchUserFull 报名用户已满
     * CodeNotInValidTimeRange 不在报名时间
     * CodeLateRegisterExpired 超过延时报名时间
     * CodeDuplicatedRegistration 重复报名
     * CodeMatchCanceled 比赛已取消
     *
     * @generated from protobuf rpc: UserRegisterMatch(bpt.match.matchhub.v1.UserRegisterMatchReq) returns (bpt.match.matchhub.v1.UserRegisterMatchResp);
     */
    userRegisterMatch(input: UserRegisterMatchReq, options?: RpcOptions): UnaryCall<UserRegisterMatchReq, UserRegisterMatchResp>;
    /**
     * 取消报名
     * 鉴权
     *
     * @generated from protobuf rpc: UserUnRegisterMatch(bpt.match.matchhub.v1.UserUnRegisterMatchReq) returns (bpt.match.matchhub.v1.UserUnRegisterMatchResp);
     */
    userUnRegisterMatch(input: UserUnRegisterMatchReq, options?: RpcOptions): UnaryCall<UserUnRegisterMatchReq, UserUnRegisterMatchResp>;
    /**
     * 批量获取UserManage
     * 鉴权
     *
     * @generated from protobuf rpc: BatchGetUserManage(bpt.match.matchhub.v1.BatchGetUserManageReq) returns (bpt.match.matchhub.v1.BatchGetUserManageResp);
     */
    batchGetUserManage(input: BatchGetUserManageReq, options?: RpcOptions): UnaryCall<BatchGetUserManageReq, BatchGetUserManageResp>;
    /**
     * 标记用户状态
     *
     * @generated from protobuf rpc: UpdateUserManageStatus(bpt.match.matchhub.v1.UpdateUserManageStatusReq) returns (bpt.base.Void);
     */
    updateUserManageStatus(input: UpdateUserManageStatusReq, options?: RpcOptions): UnaryCall<UpdateUserManageStatusReq, Void>;
    /**
     * ===================matchlink api 上链相关=======================
     *  matchlink上链回调
     *
     * @generated from protobuf rpc: LinkTransactionCallBack(bpt.match.matchlink.v1.Transaction) returns (bpt.base.Void);
     */
    linkTransactionCallBack(input: Transaction, options?: RpcOptions): UnaryCall<Transaction, Void>;
    /**
     * ===================赛前、赛事面板==========================
     * 赛事面板桌子数据
     *
     * @generated from protobuf rpc: ListMatchTables(bpt.match.matchhub.v1.ListMatchTablesReq) returns (bpt.match.matchhub.v1.ListMatchTablesResp);
     */
    listMatchTables(input: ListMatchTablesReq, options?: RpcOptions): UnaryCall<ListMatchTablesReq, ListMatchTablesResp>;
    /**
     * 赛事面板用户数据
     *
     * @generated from protobuf rpc: ListMatchUsers(bpt.match.matchhub.v1.ListMatchUsersReq) returns (bpt.match.matchhub.v1.ListMatchUsersResp);
     */
    listMatchUsers(input: ListMatchUsersReq, options?: RpcOptions): UnaryCall<ListMatchUsersReq, ListMatchUsersResp>;
    /**
     * 赛前用户报名列表
     *
     * @generated from protobuf rpc: ListRegisterUsers(bpt.match.matchhub.v1.ListRegisterUsersReq) returns (bpt.match.matchhub.v1.ListRegisterUsersResp);
     */
    listRegisterUsers(input: ListRegisterUsersReq, options?: RpcOptions): UnaryCall<ListRegisterUsersReq, ListRegisterUsersResp>;
    /**
     * 获取多场赛事桌子列表
     *
     * @generated from protobuf rpc: ListMultiMatchTables(bpt.match.matchhub.v1.ListMultiMatchTablesReq) returns (bpt.match.matchhub.v1.ListMultiMatchTablesResp);
     */
    listMultiMatchTables(input: ListMultiMatchTablesReq, options?: RpcOptions): UnaryCall<ListMultiMatchTablesReq, ListMultiMatchTablesResp>;
    /**
     * 获取该类型赛事可选的盲注组合
     *
     * @generated from protobuf rpc: ListMatchBlindOptions(bpt.match.matchhub.v1.ListMatchBlindOptionsReq) returns (bpt.match.matchhub.v1.ListMatchBlindOptionsResp);
     */
    listMatchBlindOptions(input: ListMatchBlindOptionsReq, options?: RpcOptions): UnaryCall<ListMatchBlindOptionsReq, ListMatchBlindOptionsResp>;
    /**
     * 赛前等待用户列表
     *
     * @generated from protobuf rpc: ListJoinedUsers(bpt.match.matchhub.v1.ListJoinedUsersReq) returns (bpt.match.matchhub.v1.ListJoinedUsersResp);
     */
    listJoinedUsers(input: ListJoinedUsersReq, options?: RpcOptions): UnaryCall<ListJoinedUsersReq, ListJoinedUsersResp>;
    /**
     * 赛事面板用户数据
     *
     * @generated from protobuf rpc: GetUser(bpt.match.matchhub.v1.GetUserReq) returns (bpt.match.matchhub.v1.GetUserResp);
     */
    getUser(input: GetUserReq, options?: RpcOptions): UnaryCall<GetUserReq, GetUserResp>;
    /**
     * 报名成功的用户获取房间信息
     * 房间详细信息
     *
     * @generated from protobuf rpc: GetMatch(bpt.match.matchhub.v1.GetMatchReq) returns (bpt.match.matchhub.v1.GetMatchResp);
     */
    getMatch(input: GetMatchReq, options?: RpcOptions): UnaryCall<GetMatchReq, GetMatchResp>;
    /**
     * @generated from protobuf rpc: GetMatchBrief(bpt.match.matchhub.v1.GetMatchBriefReq) returns (bpt.match.matchhub.v1.GetMatchBriefResp);
     */
    getMatchBrief(input: GetMatchBriefReq, options?: RpcOptions): UnaryCall<GetMatchBriefReq, GetMatchBriefResp>;
    /**
     * 加入比赛
     *
     * @generated from protobuf rpc: JoinMatch(bpt.match.matchhub.v1.JoinMatchReq) returns (bpt.match.matchhub.v1.JoinMatchResp);
     */
    joinMatch(input: JoinMatchReq, options?: RpcOptions): UnaryCall<JoinMatchReq, JoinMatchResp>;
    /**
     * 获取我的报名所有赛事
     * 鉴权
     *
     * @generated from protobuf rpc: ListMyMatch(bpt.match.matchhub.v1.ListMyMatchReq) returns (bpt.match.matchhub.v1.ListMyMatchResp);
     */
    listMyMatch(input: ListMyMatchReq, options?: RpcOptions): UnaryCall<ListMyMatchReq, ListMyMatchResp>;
    /**
     * 根据传入的matchKey批量获取比赛状态和用户在这场比赛中的状态
     *
     * @generated from protobuf rpc: BatchGetMatchStateAndUserState(bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateReq) returns (bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateResp);
     */
    batchGetMatchStateAndUserState(input: BatchGetMatchStateAndUserStateReq, options?: RpcOptions): UnaryCall<BatchGetMatchStateAndUserStateReq, BatchGetMatchStateAndUserStateResp>;
    /**
     * 邮件中含有退款领奖信息，生成凭证，如果没有，则返回错误5001
     * 鉴权
     *
     * @generated from protobuf rpc: GenPayCredential(bpt.match.matchhub.v1.GenPayCredentialReq) returns (bpt.match.matchhub.v1.GenPayCredentialResp);
     */
    genPayCredential(input: GenPayCredentialReq, options?: RpcOptions): UnaryCall<GenPayCredentialReq, GenPayCredentialResp>;
    /**
     * 免费加筹码(认证用户)
     *
     * @generated from protobuf rpc: FreeAddChips(bpt.match.matchhub.v1.FreeAddChipsReq) returns (bpt.match.matchhub.v1.FreeAddChipsResp);
     */
    freeAddChips(input: FreeAddChipsReq, options?: RpcOptions): UnaryCall<FreeAddChipsReq, FreeAddChipsResp>;
    // ===================赛前推送==========================

    /**
     * ===================比赛房间等级分类==========================
     * 鉴权
     *
     * @generated from protobuf rpc: ListMatchTag(bpt.match.matchhub.v1.ListMatchTagReq) returns (bpt.match.matchhub.v1.ListMatchTagResp);
     */
    listMatchTag(input: ListMatchTagReq, options?: RpcOptions): UnaryCall<ListMatchTagReq, ListMatchTagResp>;
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: SaveMatchTag(bpt.match.matchhub.v1.SaveMatchTagReq) returns (bpt.base.Void);
     */
    saveMatchTag(input: SaveMatchTagReq, options?: RpcOptions): UnaryCall<SaveMatchTagReq, Void>;
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: DeleteMatchTag(bpt.match.matchhub.v1.DeleteMatchTagReq) returns (bpt.base.Void);
     */
    deleteMatchTag(input: DeleteMatchTagReq, options?: RpcOptions): UnaryCall<DeleteMatchTagReq, Void>;
    /**
     * ====================现金桌开始=========================
     * 快速开始
     * 鉴权
     *
     * @generated from protobuf rpc: QuickStart(bpt.match.matchhub.v1.QuickStartReq) returns (bpt.match.matchhub.v1.QuickStartResp);
     */
    quickStart(input: QuickStartReq, options?: RpcOptions): UnaryCall<QuickStartReq, QuickStartResp>;
    /**
     * 换桌
     * 鉴权
     *
     * @generated from protobuf rpc: ChangeDesk(bpt.match.matchhub.v1.ChangeDeskReq) returns (bpt.match.matchhub.v1.ChangeDeskResp);
     */
    changeDesk(input: ChangeDeskReq, options?: RpcOptions): UnaryCall<ChangeDeskReq, ChangeDeskResp>;
    // ====================现金桌结束=========================

    // ===================Match List Start=======================

    /**
     * 创建赛前
     *
     * @generated from protobuf rpc: CreateMatch(bpt.match.matchhub.v1.CreateMatchReq) returns (bpt.base.Void);
     */
    createMatch(input: CreateMatchReq, options?: RpcOptions): UnaryCall<CreateMatchReq, Void>;
    /**
     * 删除赛前
     *
     * @generated from protobuf rpc: RemoveMatch(bpt.match.matchhub.v1.RemoveMatchReq) returns (bpt.base.Void);
     */
    removeMatch(input: RemoveMatchReq, options?: RpcOptions): UnaryCall<RemoveMatchReq, Void>;
    /**
     * 删除赛事列表按confid维度
     * deprecated 不再维护
     *
     * @generated from protobuf rpc: RemoveMatchByConfId(bpt.match.matchhub.v1.RemoveMatchByConfIdReq) returns (bpt.base.Void);
     */
    removeMatchByConfId(input: RemoveMatchByConfIdReq, options?: RpcOptions): UnaryCall<RemoveMatchByConfIdReq, Void>;
    /**
     * 删除赛事列表按matchkey维度
     *
     * @generated from protobuf rpc: RemoveMatchByMatchKey(bpt.match.matchhub.v1.RemoveMatchByMatchKeyReq) returns (bpt.base.Void);
     */
    removeMatchByMatchKey(input: RemoveMatchByMatchKeyReq, options?: RpcOptions): UnaryCall<RemoveMatchByMatchKeyReq, Void>;
    /**
     * 清理不在列表展示的服务
     *
     * @generated from protobuf rpc: ClearShowExpiredList(bpt.base.Void) returns (bpt.base.Void);
     */
    clearShowExpiredList(input: Void, options?: RpcOptions): UnaryCall<Void, Void>;
    /**
     * 开赛成功更新赛事列表状态
     *
     * @generated from protobuf rpc: UpdateListStart(bpt.match.matchhub.v1.UpdateListStartReq) returns (bpt.base.Void);
     */
    updateListStart(input: UpdateListStartReq, options?: RpcOptions): UnaryCall<UpdateListStartReq, Void>;
    /**
     * 报名更新赛事列表选手数据，这里只适用与单桌如SNG、spingo等
     *
     * @generated from protobuf rpc: UpdateListUsers(bpt.match.matchhub.v1.UpdateListUsersReq) returns (bpt.base.Void);
     */
    updateListUsers(input: UpdateListUsersReq, options?: RpcOptions): UnaryCall<UpdateListUsersReq, Void>;
    /**
     * @generated from protobuf rpc: ListMatch(bpt.match.matchhub.v1.ListMatchReq) returns (bpt.match.matchhub.v1.ListMatchResp);
     */
    listMatch(input: ListMatchReq, options?: RpcOptions): UnaryCall<ListMatchReq, ListMatchResp>;
    /**
     * 获取mtt赛事列表
     *
     * @generated from protobuf rpc: ListMttMatch(bpt.match.matchhub.v1.ListMttMatchReq) returns (bpt.match.matchhub.v1.ListMttMatchResp);
     */
    listMttMatch(input: ListMttMatchReq, options?: RpcOptions): UnaryCall<ListMttMatchReq, ListMttMatchResp>;
    /**
     * 获取侧边栏比赛列表
     *
     * @generated from protobuf rpc: ListSidebarMatch(bpt.match.matchhub.v1.ListSidebarMatchReq) returns (bpt.match.matchhub.v1.ListSidebarMatchResp);
     */
    listSidebarMatch(input: ListSidebarMatchReq, options?: RpcOptions): UnaryCall<ListSidebarMatchReq, ListSidebarMatchResp>;
    /**
     * 获取比赛列表
     *
     * @generated from protobuf rpc: ListMatchItem(bpt.match.matchhub.v1.ListMatchItemReq) returns (bpt.match.matchhub.v1.ListMatchItemResp);
     */
    listMatchItem(input: ListMatchItemReq, options?: RpcOptions): UnaryCall<ListMatchItemReq, ListMatchItemResp>;
    /**
     * 获取俱乐部比赛列表
     *
     * @generated from protobuf rpc: ListClubMatchItem(bpt.match.matchhub.v1.ListClubMatchItemReq) returns (bpt.match.matchhub.v1.ListClubMatchItemResp);
     */
    listClubMatchItem(input: ListClubMatchItemReq, options?: RpcOptions): UnaryCall<ListClubMatchItemReq, ListClubMatchItemResp>;
    /**
     * 获取日赛周赛，月赛最新一场的比赛
     *
     * @generated from protobuf rpc: ListLatestMatch(bpt.match.matchhub.v1.ListLatestMatchReq) returns (bpt.match.matchhub.v1.ListLatestMatchResp);
     */
    listLatestMatch(input: ListLatestMatchReq, options?: RpcOptions): UnaryCall<ListLatestMatchReq, ListLatestMatchResp>;
    /**
     * 查询历史比赛列表
     *
     * @generated from protobuf rpc: ListHistoryMatch(bpt.match.matchhub.v1.ListHistoryMatchReq) returns (bpt.match.matchhub.v1.ListHistoryMatchResp);
     */
    listHistoryMatch(input: ListHistoryMatchReq, options?: RpcOptions): UnaryCall<ListHistoryMatchReq, ListHistoryMatchResp>;
    /**
     * 查询俱乐部历史比赛列表
     *
     * @generated from protobuf rpc: ListHistoryClubMatch(bpt.match.matchhub.v1.ListHistoryClubMatchReq) returns (bpt.match.matchhub.v1.ListHistoryClubMatchResp);
     */
    listHistoryClubMatch(input: ListHistoryClubMatchReq, options?: RpcOptions): UnaryCall<ListHistoryClubMatchReq, ListHistoryClubMatchResp>;
    /**
     * 返回已报名和在游戏中的比赛（不包括已获奖和出局的）;(原ListMyMatch，和matchhub冲突改名)
     * 鉴权
     *
     * @generated from protobuf rpc: GetListMyMatch(bpt.match.matchhub.v1.GetListMyMatchReq) returns (bpt.match.matchhub.v1.GetListMyMatchResp);
     */
    getListMyMatch(input: GetListMyMatchReq, options?: RpcOptions): UnaryCall<GetListMyMatchReq, GetListMyMatchResp>;
    /**
     * @generated from protobuf rpc: GetListMyMttMatch(bpt.base.Void) returns (bpt.match.matchhub.v1.GetListMyMttMatchResp);
     */
    getListMyMttMatch(input: Void, options?: RpcOptions): UnaryCall<Void, GetListMyMttMatchResp>;
    /**
     * 首页获取我的比赛列表(展示当前玩家已报名且未结束的全部比赛，包含已被淘汰但未完成最终结算的比赛；)
     * 鉴权
     *
     * @generated from protobuf rpc: GetHomeMyMatchList(bpt.match.matchhub.v1.GetHomeMyMatchListReq) returns (bpt.match.matchhub.v1.GetHomeMyMatchListResp);
     */
    getHomeMyMatchList(input: GetHomeMyMatchListReq, options?: RpcOptions): UnaryCall<GetHomeMyMatchListReq, GetHomeMyMatchListResp>;
    /**
     * 获取用户可围观的比赛
     *
     * @generated from protobuf rpc: GetUserObservableMatch(bpt.match.matchhub.v1.GetUserObservableMatchReq) returns (bpt.match.matchhub.v1.GetUserObservableMatchResp);
     */
    getUserObservableMatch(input: GetUserObservableMatchReq, options?: RpcOptions): UnaryCall<GetUserObservableMatchReq, GetUserObservableMatchResp>;
    /**
     * 鉴权
     * 获取我的比赛记录
     *
     * @generated from protobuf rpc: GetMyMatchRecord(bpt.match.matchhub.v1.GetMyMatchRecordReq) returns (bpt.match.matchhub.v1.GetMyMatchRecordResp);
     */
    getMyMatchRecord(input: GetMyMatchRecordReq, options?: RpcOptions): UnaryCall<GetMyMatchRecordReq, GetMyMatchRecordResp>;
    /**
     * 获取赛报详情
     *
     * @generated from protobuf rpc: GetMatchReportDetail(bpt.match.matchhub.v1.GetMatchReportDetailReq) returns (bpt.match.matchhub.v1.GetMatchReportDetailResp);
     */
    getMatchReportDetail(input: GetMatchReportDetailReq, options?: RpcOptions): UnaryCall<GetMatchReportDetailReq, GetMatchReportDetailResp>;
    /**
     * @generated from protobuf rpc: GetMatchPrizeUsers(bpt.match.matchhub.v1.GetMatchPrizeUsersReq) returns (bpt.match.matchhub.v1.GetMatchPrizeUsersResp);
     */
    getMatchPrizeUsers(input: GetMatchPrizeUsersReq, options?: RpcOptions): UnaryCall<GetMatchPrizeUsersReq, GetMatchPrizeUsersResp>;
    /**
     * 首页排行榜
     *
     * @generated from protobuf rpc: GetIndexRanking(bpt.match.matchhub.v1.GetIndexRankingReq) returns (bpt.match.matchhub.v1.GetIndexRankingResp);
     */
    getIndexRanking(input: GetIndexRankingReq, options?: RpcOptions): UnaryCall<GetIndexRankingReq, GetIndexRankingResp>;
    /**
     * @generated from protobuf rpc: GetMatchSchedules(bpt.match.matchhub.v1.GetMatchSchedulesReq) returns (bpt.match.matchhub.v1.GetMatchSchedulesResp);
     */
    getMatchSchedules(input: GetMatchSchedulesReq, options?: RpcOptions): UnaryCall<GetMatchSchedulesReq, GetMatchSchedulesResp>;
    /**
     * 获取mtt赛事中进入钱圈的用户,作废，使用GetMatchPrizeUsers
     *
     * @generated from protobuf rpc: GetMatchMoneyCircleUsers(bpt.match.matchhub.v1.GetMatchMoneyCircleUsersReq) returns (bpt.match.matchhub.v1.GetMatchMoneyCircleUsersResp);
     */
    getMatchMoneyCircleUsers(input: GetMatchMoneyCircleUsersReq, options?: RpcOptions): UnaryCall<GetMatchMoneyCircleUsersReq, GetMatchMoneyCircleUsersResp>;
    /**
     * @generated from protobuf rpc: GetMttMatchFinalRanking(bpt.match.matchhub.v1.GetMttMatchFinalRankingReq) returns (bpt.match.matchhub.v1.GetMttMatchFinalRankingResp);
     */
    getMttMatchFinalRanking(input: GetMttMatchFinalRankingReq, options?: RpcOptions): UnaryCall<GetMttMatchFinalRankingReq, GetMttMatchFinalRankingResp>;
    /**
     * 返回已报名和在游戏中的比赛（不包括已获奖和出局的）数量
     * 鉴权
     *
     * @generated from protobuf rpc: GetMyMatchNum(bpt.match.matchhub.v1.GetMyMatchNumReq) returns (bpt.match.matchhub.v1.GetMyMatchNumResp);
     */
    getMyMatchNum(input: GetMyMatchNumReq, options?: RpcOptions): UnaryCall<GetMyMatchNumReq, GetMyMatchNumResp>;
    /**
     * 获取筹码信息
     *
     * @generated from protobuf rpc: GetChipInfo(bpt.match.matchhub.v1.GetChipInfoReq) returns (bpt.match.matchhub.v1.GetChipInfoResp);
     */
    getChipInfo(input: GetChipInfoReq, options?: RpcOptions): UnaryCall<GetChipInfoReq, GetChipInfoResp>;
    /**
     * @generated from protobuf rpc: UpdatePool(bpt.match.matchhub.v1.UpdatePoolReq) returns (bpt.match.matchhub.v1.UpdatePoolResp);
     */
    updatePool(input: UpdatePoolReq, options?: RpcOptions): UnaryCall<UpdatePoolReq, UpdatePoolResp>;
    // ===================MatchList End=======================

    /**
     * ===============mtt===============
     *
     * @generated from protobuf rpc: AnimationFinish(bpt.match.mttmatch.v1.AnimationFinishReq) returns (bpt.match.mttmatch.v1.AnimationFinishResp);
     */
    animationFinish(input: AnimationFinishReq, options?: RpcOptions): UnaryCall<AnimationFinishReq, AnimationFinishResp>;
    /**
     * @generated from protobuf rpc: ReBuy(bpt.match.mttmatch.v1.ReBuyReq) returns (bpt.match.mttmatch.v1.ReBuyResp);
     */
    reBuy(input: ReBuyReq, options?: RpcOptions): UnaryCall<ReBuyReq, ReBuyResp>;
    /**
     * @generated from protobuf rpc: ReEntry(bpt.match.mttmatch.v1.ReEntryReq) returns (bpt.match.mttmatch.v1.ReEntryResp);
     */
    reEntry(input: ReEntryReq, options?: RpcOptions): UnaryCall<ReEntryReq, ReEntryResp>;
    /**
     * @generated from protobuf rpc: CancelReBuy(bpt.match.mttmatch.v1.CancelReBuyReq) returns (bpt.match.mttmatch.v1.CancelReBuyResp);
     */
    cancelReBuy(input: CancelReBuyReq, options?: RpcOptions): UnaryCall<CancelReBuyReq, CancelReBuyResp>;
    /**
     * @generated from protobuf rpc: CancelAddOn(bpt.match.mttmatch.v1.CancelAddOnReq) returns (bpt.match.mttmatch.v1.CancelAddOnResp);
     */
    cancelAddOn(input: CancelAddOnReq, options?: RpcOptions): UnaryCall<CancelAddOnReq, CancelAddOnResp>;
    /**
     * @generated from protobuf rpc: AddOn(bpt.match.mttmatch.v1.AddOnReq) returns (bpt.match.mttmatch.v1.AddOnResp);
     */
    addOn(input: AddOnReq, options?: RpcOptions): UnaryCall<AddOnReq, AddOnResp>;
    /**
     * 获取用户是否可以重入
     * 未淘汰的时候返回false
     *
     * @generated from protobuf rpc: BatchGetCanReEnter(bpt.match.mttmatch.v1.BatchGetCanReEnterReq) returns (bpt.match.mttmatch.v1.BatchGetCanReEnterResp);
     */
    batchGetCanReEnter(input: BatchGetCanReEnterReq, options?: RpcOptions): UnaryCall<BatchGetCanReEnterReq, BatchGetCanReEnterResp>;
    /**
     * 用户未淘汰未淘汰，返回true,用户淘汰，且能重入，返回true,否则返回false
     *
     * @generated from protobuf rpc: BatchGetUserCanEnter(bpt.match.mttmatch.v1.BatchGetUserCanEnterReq) returns (bpt.match.mttmatch.v1.BatchGetUserCanEnterResp);
     */
    batchGetUserCanEnter(input: BatchGetUserCanEnterReq, options?: RpcOptions): UnaryCall<BatchGetUserCanEnterReq, BatchGetUserCanEnterResp>;
    /**
     * @generated from protobuf rpc: MatchOverView(bpt.match.mttmatch.v1.MatchOverViewReq) returns (bpt.match.mttmatch.v1.MatchOverViewResp);
     */
    matchOverView(input: MatchOverViewReq, options?: RpcOptions): UnaryCall<MatchOverViewReq, MatchOverViewResp>;
    /**
     * @generated from protobuf rpc: ListRank(bpt.match.mttmatch.v1.ListRankReq) returns (bpt.match.mttmatch.v1.ListRankResp);
     */
    listRank(input: ListRankReq, options?: RpcOptions): UnaryCall<ListRankReq, ListRankResp>;
    /**
     * @generated from protobuf rpc: ListDesks(bpt.match.mttmatch.v1.ListDesksReq) returns (bpt.match.mttmatch.v1.ListDesksResp);
     */
    listDesks(input: ListDesksReq, options?: RpcOptions): UnaryCall<ListDesksReq, ListDesksResp>;
    /**
     * 批量获取比赛中某些用户
     *
     * @generated from protobuf rpc: BatchGetUser(bpt.match.mttmatch.v1.BatchGetUserReq) returns (bpt.match.mttmatch.v1.BatchGetUserResp);
     */
    batchGetUser(input: BatchGetUserReq, options?: RpcOptions): UnaryCall<BatchGetUserReq, BatchGetUserResp>;
    /**
     * =================================多桌列表==================================
     * 获取用户多桌列表
     *
     * @generated from protobuf rpc: ListTableViews(bpt.base.Void) returns (bpt.match.matchhub.v1.ListTableViewsResp);
     */
    listTableViews(input: Void, options?: RpcOptions): UnaryCall<Void, ListTableViewsResp>;
    /**
     * 增加桌子
     *
     * @generated from protobuf rpc: AddTableView(bpt.match.matchhub.v1.AddTableViewReq) returns (bpt.base.Void);
     */
    addTableView(input: AddTableViewReq, options?: RpcOptions): UnaryCall<AddTableViewReq, Void>;
    /**
     * @generated from protobuf rpc: BatchAddTableView(bpt.match.matchhub.v1.BatchAddTableViewReq) returns (bpt.base.Void);
     */
    batchAddTableView(input: BatchAddTableViewReq, options?: RpcOptions): UnaryCall<BatchAddTableViewReq, Void>;
    /**
     * 删除桌子
     *
     * @generated from protobuf rpc: RemoveTableView(bpt.match.matchhub.v1.RemoveTableViewReq) returns (bpt.base.Void);
     */
    removeTableView(input: RemoveTableViewReq, options?: RpcOptions): UnaryCall<RemoveTableViewReq, Void>;
    /**
     * 围观桌子，传入preferredTabkeKey优先分配，如不能分配，返回一个可围观桌子，需要登录
     *
     * @generated from protobuf rpc: ObserveTable(bpt.match.mttmatch.v1.ObserveTableReq) returns (bpt.match.mttmatch.v1.ObserveTableResp);
     */
    observeTable(input: ObserveTableReq, options?: RpcOptions): UnaryCall<ObserveTableReq, ObserveTableResp>;
    /**
     * 泡沫时围观桌子
     *
     * @generated from protobuf rpc: ObserveTableOnBubble(bpt.match.mttmatch.v1.ObserveTableOnBubbleReq) returns (bpt.match.mttmatch.v1.ObserveTableOnBubbleResp);
     */
    observeTableOnBubble(input: ObserveTableOnBubbleReq, options?: RpcOptions): UnaryCall<ObserveTableOnBubbleReq, ObserveTableOnBubbleResp>;
    /**
     * 获取玩家(已报名玩家)的桌子(在打的，和围观的)
     * 未报名或者未参赛的用户，返回再打桌子和围观桌子都为空
     *
     * @generated from protobuf rpc: GetMyTable(bpt.match.mttmatch.v1.GetMyTableReq) returns (bpt.match.mttmatch.v1.GetMyTableResp);
     */
    getMyTable(input: GetMyTableReq, options?: RpcOptions): UnaryCall<GetMyTableReq, GetMyTableResp>;
    /**
     * 获取多日赛多日赛程的比赛名称和用户晋级信息
     *
     * @generated from protobuf rpc: ListSubMatches(bpt.match.matchhub.v1.ListSubMatchesReq) returns (bpt.match.matchhub.v1.ListSubMatchesResp);
     */
    listSubMatches(input: ListSubMatchesReq, options?: RpcOptions): UnaryCall<ListSubMatchesReq, ListSubMatchesResp>;
    /**
     * 多日赛查询之前比赛的最高筹码，传入day1的matchkey，返回这场比赛之前的最大筹码，需要和当前比赛的筹码对比，获取最大筹码
     * 如果这是第一场，返回0
     *
     * @generated from protobuf rpc: GetDayOneMaxChips(bpt.match.matchhub.v1.GetDayOneMaxChipsReq) returns (bpt.match.matchhub.v1.GetDayOneMaxChipsResp);
     */
    getDayOneMaxChips(input: GetDayOneMaxChipsReq, options?: RpcOptions): UnaryCall<GetDayOneMaxChipsReq, GetDayOneMaxChipsResp>;
    /**
     * @generated from protobuf rpc: GetMatchNameAndStartAt(bpt.match.matchhub.v1.GetMatchNameAndStartAtReq) returns (bpt.match.matchhub.v1.GetMatchNameAndStartAtResp);
     */
    getMatchNameAndStartAt(input: GetMatchNameAndStartAtReq, options?: RpcOptions): UnaryCall<GetMatchNameAndStartAtReq, GetMatchNameAndStartAtResp>;
    /**
     * @generated from protobuf rpc: BatchGetMatchNameAndStartAt(bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtReq) returns (bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtResp);
     */
    batchGetMatchNameAndStartAt(input: BatchGetMatchNameAndStartAtReq, options?: RpcOptions): UnaryCall<BatchGetMatchNameAndStartAtReq, BatchGetMatchNameAndStartAtResp>;
    /**
     * 获取奖池
     *
     * @generated from protobuf rpc: ListPrizePool(bpt.match.matchhub.v1.ListPrizePoolReq) returns (bpt.match.matchhub.v1.ListPrizePoolResp);
     */
    listPrizePool(input: ListPrizePoolReq, options?: RpcOptions): UnaryCall<ListPrizePoolReq, ListPrizePoolResp>;
    /**
     * @generated from protobuf rpc: ListMatchPrizeAndBubbleUser(bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserReq) returns (bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserResp);
     */
    listMatchPrizeAndBubbleUser(input: ListMatchPrizeAndBubbleUserReq, options?: RpcOptions): UnaryCall<ListMatchPrizeAndBubbleUserReq, ListMatchPrizeAndBubbleUserResp>;
    /**
     * @generated from protobuf rpc: FormatAndValidateTierPrize(bpt.match.matchhub.v1.FormatAndValidateTierPrizeReq) returns (bpt.match.matchhub.v1.FormatAndValidateTierPrizeResp);
     */
    formatAndValidateTierPrize(input: FormatAndValidateTierPrizeReq, options?: RpcOptions): UnaryCall<FormatAndValidateTierPrizeReq, FormatAndValidateTierPrizeResp>;
    /**
     * 获取赛事可用的合约
     *
     * @generated from protobuf rpc: ListDispatchContract(bpt.match.matchhub.v1.ListDispatchContractReq) returns (bpt.match.matchhub.v1.ListDispatchContractResp);
     */
    listDispatchContract(input: ListDispatchContractReq, options?: RpcOptions): UnaryCall<ListDispatchContractReq, ListDispatchContractResp>;
    /**
     * 后台获取用户记录
     *
     * @generated from protobuf rpc: ListUserRecordByCms(bpt.match.matchhub.v1.ListUserRecordByCmsReq) returns (bpt.match.matchhub.v1.ListUserRecordByCmsResp);
     */
    listUserRecordByCms(input: ListUserRecordByCmsReq, options?: RpcOptions): UnaryCall<ListUserRecordByCmsReq, ListUserRecordByCmsResp>;
    /**
     * 后台手动发奖
     *
     * @generated from protobuf rpc: MarkAward(bpt.match.matchhub.v1.MarkAwardReq) returns (bpt.match.matchhub.v1.MarkAwardResp);
     */
    markAward(input: MarkAwardReq, options?: RpcOptions): UnaryCall<MarkAwardReq, MarkAwardResp>;
    /**
     * @generated from protobuf rpc: GetRegisterInfo(bpt.match.matchhub.v1.GetRegisterInfoReq) returns (bpt.match.matchhub.v1.GetRegisterInfoResp);
     */
    getRegisterInfo(input: GetRegisterInfoReq, options?: RpcOptions): UnaryCall<GetRegisterInfoReq, GetRegisterInfoResp>;
    /**
     * @generated from protobuf rpc: GetReEntryInfo(bpt.match.matchhub.v1.GetReEntryInfoReq) returns (bpt.match.matchhub.v1.GetReEntryInfoResp);
     */
    getReEntryInfo(input: GetReEntryInfoReq, options?: RpcOptions): UnaryCall<GetReEntryInfoReq, GetReEntryInfoResp>;
    /**
     * ===================牌桌道具使用回调=======================
     *
     * @generated from protobuf rpc: UseProp(bpt.match.matchhub.v1.UsePropReq) returns (bpt.match.matchhub.v1.UsePropResp);
     */
    useProp(input: UsePropReq, options?: RpcOptions): UnaryCall<UsePropReq, UsePropResp>;
    /**
     * 使用道具-洗牌卡CallBack
     *
     * @generated from protobuf rpc: UsePropShuffleCardCallBack(bpt.match.matchhub.v1.UsePropShuffleCardCallBackReq) returns (bpt.match.matchhub.v1.UsePropShuffleCardCallBackResp);
     */
    usePropShuffleCardCallBack(input: UsePropShuffleCardCallBackReq, options?: RpcOptions): UnaryCall<UsePropShuffleCardCallBackReq, UsePropShuffleCardCallBackResp>;
    /**
     * 标记已读收到奖励的比赛，在用户点击时触发
     *
     * @generated from protobuf rpc: MarkReadCompletedAwardMatch(bpt.match.matchhub.v1.MarkReadCompletedAwardMatchReq) returns (bpt.base.Void);
     */
    markReadCompletedAwardMatch(input: MarkReadCompletedAwardMatchReq, options?: RpcOptions): UnaryCall<MarkReadCompletedAwardMatchReq, Void>;
    /**
     * 管理员补签
     *
     * @generated from protobuf rpc: AdminRegister(bpt.match.matchhub.v1.AdminRegisterReq) returns (bpt.base.Void);
     */
    adminRegister(input: AdminRegisterReq, options?: RpcOptions): UnaryCall<AdminRegisterReq, Void>;
    /**
     * 恢复异常桌子
     *
     * @generated from protobuf rpc: RestoreMatchTables(bpt.match.mttmatch.v1.RestoreMatchTablesReq) returns (bpt.match.mttmatch.v1.RestoreMatchTablesResp);
     */
    restoreMatchTables(input: RestoreMatchTablesReq, options?: RpcOptions): UnaryCall<RestoreMatchTablesReq, RestoreMatchTablesResp>;
    /**
     * 用户未获奖返回 CodeNotPrizeUser
     * 表单不合法返回CodeInvalidForm（没有填写必填信息，漏项）
     * 提交表单成功会更新用户领奖状态为已领取
     *
     * @generated from protobuf rpc: SubmitAwardClaimForm(bpt.match.matchhub.v1.SubmitAwardClaimFormReq) returns (bpt.base.Void);
     */
    submitAwardClaimForm(input: SubmitAwardClaimFormReq, options?: RpcOptions): UnaryCall<SubmitAwardClaimFormReq, Void>;
    /**
     * 拉取提交的领奖表单
     *
     * @generated from protobuf rpc: ListAwardClaimForm(bpt.match.matchhub.v1.ListAwardClaimFormReq) returns (bpt.match.matchhub.v1.ListAwardClaimFormResp);
     */
    listAwardClaimForm(input: ListAwardClaimFormReq, options?: RpcOptions): UnaryCall<ListAwardClaimFormReq, ListAwardClaimFormResp>;
    /**
     * 审核操作
     *
     * @generated from protobuf rpc: AuditAwardClaimForm(bpt.match.matchhub.v1.AuditAwardClaimFormReq) returns (bpt.base.Void);
     */
    auditAwardClaimForm(input: AuditAwardClaimFormReq, options?: RpcOptions): UnaryCall<AuditAwardClaimFormReq, Void>;
    /**
     * ------------------内部接口 Start-----------------------------------
     * 获取早鸟配置
     *
     * @generated from protobuf rpc: GetEarlyBirdConfigInternal(bpt.match.matchhub.v1.GetEarlyBirdConfigInternalReq) returns (bpt.match.matchhub.v1.GetEarlyBirdConfigInternalResp);
     */
    getEarlyBirdConfigInternal(input: GetEarlyBirdConfigInternalReq, options?: RpcOptions): UnaryCall<GetEarlyBirdConfigInternalReq, GetEarlyBirdConfigInternalResp>;
    // ------------------内部接口 End-------------------------------------

    /**
     * ---------------------MTT Match 调用接口 Start---------------------
     * 赛事进入延迟报名截止时间
     *
     * @generated from protobuf rpc: MttMatchRegisterDeadline(bpt.broker.match.MsgMatchRegisterDeadline) returns (bpt.base.Void);
     */
    mttMatchRegisterDeadline(input: MsgMatchRegisterDeadline, options?: RpcOptions): UnaryCall<MsgMatchRegisterDeadline, Void>;
    /**
     * 比赛结算消息
     *
     * @generated from protobuf rpc: MttMatchUserSettlement(bpt.broker.match.MsgMatchUserSettlement) returns (bpt.base.Void);
     */
    mttMatchUserSettlement(input: MsgMatchUserSettlement, options?: RpcOptions): UnaryCall<MsgMatchUserSettlement, Void>;
    /**
     * 比赛结束
     *
     * @generated from protobuf rpc: MttMatchOver(bpt.broker.match.MsgMatchOver) returns (bpt.base.Void);
     */
    mttMatchOver(input: MsgMatchOver, options?: RpcOptions): UnaryCall<MsgMatchOver, Void>;
    /**
     * 获取当前比赛的奖圈(如果是晋级赛，会返回SeatNum)
     *
     * ---------------------MTT Match 调用接口 End---------------------
     *
     * @generated from protobuf rpc: GetMatchPrizeNum(bpt.match.matchhub.v1.GetMatchPrizeNumReq) returns (bpt.match.matchhub.v1.GetMatchPrizeNumResp);
     */
    getMatchPrizeNum(input: GetMatchPrizeNumReq, options?: RpcOptions): UnaryCall<GetMatchPrizeNumReq, GetMatchPrizeNumResp>;
    /**
     * --------------------- 赛前预告相关接口 Start---------------------
     * client获取赛前预告
     *
     * @generated from protobuf rpc: GetMatchNotice(bpt.match.matchhub.v1.GetMatchNoticeReq) returns (bpt.match.matchhub.v1.GetMatchNoticeResp);
     */
    getMatchNotice(input: GetMatchNoticeReq, options?: RpcOptions): UnaryCall<GetMatchNoticeReq, GetMatchNoticeResp>;
    /**
     * 获取赛前预告配置列表
     *
     * @generated from protobuf rpc: ListMatchNoticeConfig(bpt.match.matchhub.v1.ListMatchNoticeConfigReq) returns (bpt.match.matchhub.v1.ListMatchNoticeConfigResp);
     */
    listMatchNoticeConfig(input: ListMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<ListMatchNoticeConfigReq, ListMatchNoticeConfigResp>;
    /**
     * 获取赛前预告配置
     *
     * @generated from protobuf rpc: GetMatchNoticeConfig(bpt.match.matchhub.v1.GetMatchNoticeConfigReq) returns (bpt.match.matchhub.v1.GetMatchNoticeConfigResp);
     */
    getMatchNoticeConfig(input: GetMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<GetMatchNoticeConfigReq, GetMatchNoticeConfigResp>;
    /**
     * 保存赛前预告配置
     *
     * @generated from protobuf rpc: SaveMatchNoticeConfig(bpt.match.matchhub.v1.SaveMatchNoticeConfigReq) returns (bpt.match.matchhub.v1.SaveMatchNoticeConfigResp);
     */
    saveMatchNoticeConfig(input: SaveMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<SaveMatchNoticeConfigReq, SaveMatchNoticeConfigResp>;
    /**
     * 删除赛前预告配置
     *
     * @generated from protobuf rpc: DeleteMatchNoticeConfig(bpt.match.matchhub.v1.DeleteMatchNoticeConfigReq) returns (bpt.base.Void);
     */
    deleteMatchNoticeConfig(input: DeleteMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<DeleteMatchNoticeConfigReq, Void>;
    /**
     * ===================比赛配置管理=======================
     * 列举在使用的比赛类型
     *
     * @generated from protobuf rpc: ListMatchFeatures(bpt.base.Void) returns (bpt.match.matchhub.v1.ListMatchFeaturesResp);
     */
    listMatchFeatures(input: Void, options?: RpcOptions): UnaryCall<Void, ListMatchFeaturesResp>;
    /**
     * 获取比赛的相关特性开关
     *
     * @generated from protobuf rpc: GetMatchFeatures(bpt.match.matchhub.v1.GetMatchFeaturesReq) returns (bpt.match.matchhub.v1.GetMatchFeaturesResp);
     */
    getMatchFeatures(input: GetMatchFeaturesReq, options?: RpcOptions): UnaryCall<GetMatchFeaturesReq, GetMatchFeaturesResp>;
    /**
     * 社区获取推荐的比赛
     * 优先返回能进入的比赛
     *
     * @generated from protobuf rpc: GetRecommendMatch(bpt.base.Void) returns (bpt.match.matchhub.v1.GetRecommendMatchResp);
     */
    getRecommendMatch(input: Void, options?: RpcOptions): UnaryCall<Void, GetRecommendMatchResp>;
}
/**
 * @generated from protobuf service bpt.match.matchhub.v1.MatchHubService
 */
export class MatchHubServiceClient implements IMatchHubServiceClient, ServiceInfo {
    typeName = MatchHubService.typeName;
    methods = MatchHubService.methods;
    options = MatchHubService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: Ping(bpt.base.Void) returns (bpt.base.Void);
     */
    ping(input: Void, options?: RpcOptions): UnaryCall<Void, Void> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================比赛配置管理=======================
     * Deprecated: use SaveMatchConfigAll
     *
     * @generated from protobuf rpc: SaveMatchConf(bpt.match.matchhub.v1.SaveMatchConfReq) returns (bpt.match.matchhub.v1.SaveMatchConfResp);
     */
    saveMatchConf(input: SaveMatchConfReq, options?: RpcOptions): UnaryCall<SaveMatchConfReq, SaveMatchConfResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfReq, SaveMatchConfResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated: use DuplicateMatchConfig
     *
     * @generated from protobuf rpc: ReuseMatchConf(bpt.match.matchhub.v1.ReuseMatchConfReq) returns (bpt.match.matchhub.v1.ReuseMatchConfResp);
     */
    reuseMatchConf(input: ReuseMatchConfReq, options?: RpcOptions): UnaryCall<ReuseMatchConfReq, ReuseMatchConfResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReuseMatchConfReq, ReuseMatchConfResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated: use DeleteMatchConfig
     *
     * @generated from protobuf rpc: DeleteMatchConf(bpt.match.matchhub.v1.DeleteMatchConfReq) returns (bpt.match.matchhub.v1.DeleteMatchConfResp);
     */
    deleteMatchConf(input: DeleteMatchConfReq, options?: RpcOptions): UnaryCall<DeleteMatchConfReq, DeleteMatchConfResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMatchConfReq, DeleteMatchConfResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated: use OnlineMatchConfig
     * StatePause 暂停
     * StateStop 下线
     * StatePending 赛事创建者暂停
     *
     * @generated from protobuf rpc: UpdateMatchConfState(bpt.match.matchhub.v1.UpdateMatchConfStateReq) returns (bpt.match.matchhub.v1.UpdateMatchConfStateResp);
     */
    updateMatchConfState(input: UpdateMatchConfStateReq, options?: RpcOptions): UnaryCall<UpdateMatchConfStateReq, UpdateMatchConfStateResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateMatchConfStateReq, UpdateMatchConfStateResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated: use GetMatchConfig
     *
     * @generated from protobuf rpc: GetMatchConf(bpt.match.matchhub.v1.GetMatchConfReq) returns (bpt.match.matchhub.v1.GetMatchConfResp);
     */
    getMatchConf(input: GetMatchConfReq, options?: RpcOptions): UnaryCall<GetMatchConfReq, GetMatchConfResp> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchConfReq, GetMatchConfResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated:ListMatchConfig
     *
     * deprecated
     *
     * @generated from protobuf rpc: BatchGetMatchConf(bpt.match.matchhub.v1.BatchGetMatchConfReq) returns (bpt.match.matchhub.v1.BatchGetMatchConfResp);
     */
    batchGetMatchConf(input: BatchGetMatchConfReq, options?: RpcOptions): UnaryCall<BatchGetMatchConfReq, BatchGetMatchConfResp> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetMatchConfReq, BatchGetMatchConfResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMatchConfByKey(bpt.match.matchhub.v1.ListMatchConfByKeyReq) returns (bpt.match.matchhub.v1.ListMatchConfByKeyResp);
     */
    listMatchConfByKey(input: ListMatchConfByKeyReq, options?: RpcOptions): UnaryCall<ListMatchConfByKeyReq, ListMatchConfByKeyResp> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchConfByKeyReq, ListMatchConfByKeyResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: ListMatchConf(bpt.match.matchhub.v1.ListMatchConfReq) returns (bpt.match.matchhub.v1.ListMatchConfResp);
     */
    listMatchConf(input: ListMatchConfReq, options?: RpcOptions): UnaryCall<ListMatchConfReq, ListMatchConfResp> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchConfReq, ListMatchConfResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetConfByIds(bpt.match.matchhub.v1.GetConfByIdsReq) returns (bpt.match.matchhub.v1.GetConfByIdsResp);
     */
    getConfByIds(input: GetConfByIdsReq, options?: RpcOptions): UnaryCall<GetConfByIdsReq, GetConfByIdsResp> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetConfByIdsReq, GetConfByIdsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated:SaveMatchConfigTemplate
     *
     * @generated from protobuf rpc: SaveMatchConfTmpl(bpt.match.matchhub.v1.SaveMatchConfTmplReq) returns (bpt.match.matchhub.v1.SaveMatchConfTmplResp);
     */
    saveMatchConfTmpl(input: SaveMatchConfTmplReq, options?: RpcOptions): UnaryCall<SaveMatchConfTmplReq, SaveMatchConfTmplResp> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfTmplReq, SaveMatchConfTmplResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated:GetMatchConfigTemplate
     *
     * @generated from protobuf rpc: GetMatchConfTmpl(bpt.match.matchhub.v1.GetMatchConfTmplReq) returns (bpt.match.matchhub.v1.GetMatchConfTmplResp);
     */
    getMatchConfTmpl(input: GetMatchConfTmplReq, options?: RpcOptions): UnaryCall<GetMatchConfTmplReq, GetMatchConfTmplResp> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchConfTmplReq, GetMatchConfTmplResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated:ListMatchConfigTemplate
     *
     * @generated from protobuf rpc: ListMatchConfTmpl(bpt.match.matchhub.v1.ListMatchConfTmplReq) returns (bpt.match.matchhub.v1.ListMatchConfTmplResp);
     */
    listMatchConfTmpl(input: ListMatchConfTmplReq, options?: RpcOptions): UnaryCall<ListMatchConfTmplReq, ListMatchConfTmplResp> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchConfTmplReq, ListMatchConfTmplResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Deprecated:DeleteMatchConfigTemplate
     *
     * @generated from protobuf rpc: DeleteMatchConfTmpl(bpt.match.matchhub.v1.DeleteMatchConfTmplReq) returns (bpt.base.Void);
     */
    deleteMatchConfTmpl(input: DeleteMatchConfTmplReq, options?: RpcOptions): UnaryCall<DeleteMatchConfTmplReq, Void> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMatchConfTmplReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 比赛简要在简要信息(名字、开始时间)
     *
     * @generated from protobuf rpc: GetSimpleMatch(bpt.match.matchhub.v1.GetSimpleMatchReq) returns (bpt.match.matchhub.v1.GetSimpleMatchResp);
     */
    getSimpleMatch(input: GetSimpleMatchReq, options?: RpcOptions): UnaryCall<GetSimpleMatchReq, GetSimpleMatchResp> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSimpleMatchReq, GetSimpleMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询当前比赛的GetSeatMatchLiteInfo
     *
     * @generated from protobuf rpc: GetSeatMatchLiteInfo(bpt.match.matchhub.v1.GetSeatMatchLiteInfoReq) returns (bpt.match.matchhub.v1.GetSeatMatchLiteInfoResp);
     */
    getSeatMatchLiteInfo(input: GetSeatMatchLiteInfoReq, options?: RpcOptions): UnaryCall<GetSeatMatchLiteInfoReq, GetSeatMatchLiteInfoResp> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetSeatMatchLiteInfoReq, GetSeatMatchLiteInfoResp>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================新后台比赛配置相关接口 Start=======================
     * 上线赛事
     *
     * @generated from protobuf rpc: OnlineMatchConfig(bpt.match.matchhub.v1.OnlineMatchConfigReq) returns (bpt.match.matchhub.v1.OnlineMatchConfigResp);
     */
    onlineMatchConfig(input: OnlineMatchConfigReq, options?: RpcOptions): UnaryCall<OnlineMatchConfigReq, OnlineMatchConfigResp> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<OnlineMatchConfigReq, OnlineMatchConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 下线赛事
     *
     * @generated from protobuf rpc: OfflineMatchConfig(bpt.match.matchhub.v1.OfflineMatchConfigReq) returns (bpt.match.matchhub.v1.OfflineMatchConfigResp);
     */
    offlineMatchConfig(input: OfflineMatchConfigReq, options?: RpcOptions): UnaryCall<OfflineMatchConfigReq, OfflineMatchConfigResp> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<OfflineMatchConfigReq, OfflineMatchConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 后台发送奖励
     *
     * @generated from protobuf rpc: AdminSendReward(bpt.match.matchhub.v1.AdminSendRewardReq) returns (bpt.match.matchhub.v1.AdminSendRewardResp);
     */
    adminSendReward(input: AdminSendRewardReq, options?: RpcOptions): UnaryCall<AdminSendRewardReq, AdminSendRewardResp> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<AdminSendRewardReq, AdminSendRewardResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 后台获取当前比赛的所有用户奖励信息
     *
     * @generated from protobuf rpc: AdminFetchUserPrizeReward(bpt.match.matchhub.v1.AdminFetchUserPrizeRewardReq) returns (bpt.match.matchhub.v1.AdminFetchUserPrizeRewardResp);
     */
    adminFetchUserPrizeReward(input: AdminFetchUserPrizeRewardReq, options?: RpcOptions): UnaryCall<AdminFetchUserPrizeRewardReq, AdminFetchUserPrizeRewardResp> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<AdminFetchUserPrizeRewardReq, AdminFetchUserPrizeRewardResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 暂停比赛
     *
     * @generated from protobuf rpc: PauseMatch(bpt.match.matchhub.v1.PauseMatchReq) returns (bpt.base.Void);
     */
    pauseMatch(input: PauseMatchReq, options?: RpcOptions): UnaryCall<PauseMatchReq, Void> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<PauseMatchReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 继续比赛
     *
     * @generated from protobuf rpc: ResumeMatch(bpt.match.matchhub.v1.ResumeMatchReq) returns (bpt.base.Void);
     */
    resumeMatch(input: ResumeMatchReq, options?: RpcOptions): UnaryCall<ResumeMatchReq, Void> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResumeMatchReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * CreateMatchConfig
     *
     * @generated from protobuf rpc: CreateMatchConfig(bpt.match.matchhub.v1.CreateMatchConfigReq) returns (bpt.match.matchhub.v1.CreateMatchConfigResp);
     */
    createMatchConfig(input: CreateMatchConfigReq, options?: RpcOptions): UnaryCall<CreateMatchConfigReq, CreateMatchConfigResp> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateMatchConfigReq, CreateMatchConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存基本信息
     *
     * @generated from protobuf rpc: SaveMatchConfigBase(bpt.match.matchhub.v1.SaveMatchConfigBaseReq) returns (bpt.match.matchhub.v1.SaveMatchConfigBaseResp);
     */
    saveMatchConfigBase(input: SaveMatchConfigBaseReq, options?: RpcOptions): UnaryCall<SaveMatchConfigBaseReq, SaveMatchConfigBaseResp> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfigBaseReq, SaveMatchConfigBaseResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存盲注信息
     *
     * @generated from protobuf rpc: SaveMatchConfigBlind(bpt.match.matchhub.v1.SaveMatchConfigBlindReq) returns (bpt.match.matchhub.v1.SaveMatchConfigBlindResp);
     */
    saveMatchConfigBlind(input: SaveMatchConfigBlindReq, options?: RpcOptions): UnaryCall<SaveMatchConfigBlindReq, SaveMatchConfigBlindResp> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfigBlindReq, SaveMatchConfigBlindResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存奖励信息
     *
     * @generated from protobuf rpc: SaveMatchConfigReward(bpt.match.matchhub.v1.SaveMatchConfigRewardReq) returns (bpt.match.matchhub.v1.SaveMatchConfigRewardResp);
     */
    saveMatchConfigReward(input: SaveMatchConfigRewardReq, options?: RpcOptions): UnaryCall<SaveMatchConfigRewardReq, SaveMatchConfigRewardResp> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfigRewardReq, SaveMatchConfigRewardResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 聚合保存所有配置信息
     *
     * @generated from protobuf rpc: SaveMatchConfigAll(bpt.match.matchhub.v1.SaveMatchConfigAllReq) returns (bpt.match.matchhub.v1.SaveMatchConfigAllResp);
     */
    saveMatchConfigAll(input: SaveMatchConfigAllReq, options?: RpcOptions): UnaryCall<SaveMatchConfigAllReq, SaveMatchConfigAllResp> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfigAllReq, SaveMatchConfigAllResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取赛事配置所有信息
     *
     * @generated from protobuf rpc: GetMatchConfig(bpt.match.matchhub.v1.GetMatchConfigReq) returns (bpt.match.matchhub.v1.GetMatchConfigResp);
     */
    getMatchConfig(input: GetMatchConfigReq, options?: RpcOptions): UnaryCall<GetMatchConfigReq, GetMatchConfigResp> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchConfigReq, GetMatchConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 复制
     *
     * @generated from protobuf rpc: DuplicateMatchConfig(bpt.match.matchhub.v1.DuplicateMatchConfigReq) returns (bpt.match.matchhub.v1.DuplicateMatchConfigResp);
     */
    duplicateMatchConfig(input: DuplicateMatchConfigReq, options?: RpcOptions): UnaryCall<DuplicateMatchConfigReq, DuplicateMatchConfigResp> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<DuplicateMatchConfigReq, DuplicateMatchConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除配置
     *
     * @generated from protobuf rpc: DeleteMatchConfig(bpt.match.matchhub.v1.DeleteMatchConfigReq) returns (bpt.match.matchhub.v1.DeleteMatchConfigResp);
     */
    deleteMatchConfig(input: DeleteMatchConfigReq, options?: RpcOptions): UnaryCall<DeleteMatchConfigReq, DeleteMatchConfigResp> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMatchConfigReq, DeleteMatchConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 比赛配置记录列表
     *
     * @generated from protobuf rpc: ListMatchConfig(bpt.match.matchhub.v1.ListMatchConfigReq) returns (bpt.match.matchhub.v1.ListMatchConfigResp);
     */
    listMatchConfig(input: ListMatchConfigReq, options?: RpcOptions): UnaryCall<ListMatchConfigReq, ListMatchConfigResp> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchConfigReq, ListMatchConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 模版保存(旧版)
     *
     * @generated from protobuf rpc: SaveMatchConfigTemplate(bpt.match.matchhub.v1.SaveMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.SaveMatchConfigTemplateResp);
     */
    saveMatchConfigTemplate(input: SaveMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<SaveMatchConfigTemplateReq, SaveMatchConfigTemplateResp> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfigTemplateReq, SaveMatchConfigTemplateResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取模版(旧版)
     *
     * @generated from protobuf rpc: GetMatchConfigTemplate(bpt.match.matchhub.v1.GetMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.GetMatchConfigTemplateResp);
     */
    getMatchConfigTemplate(input: GetMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<GetMatchConfigTemplateReq, GetMatchConfigTemplateResp> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchConfigTemplateReq, GetMatchConfigTemplateResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 模版列表(旧版)
     *
     * @generated from protobuf rpc: ListMatchConfigTemplate(bpt.match.matchhub.v1.ListMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.ListMatchConfigTemplateResp);
     */
    listMatchConfigTemplate(input: ListMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<ListMatchConfigTemplateReq, ListMatchConfigTemplateResp> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchConfigTemplateReq, ListMatchConfigTemplateResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除模版(旧版)
     *
     * @generated from protobuf rpc: DeleteMatchConfigTemplate(bpt.match.matchhub.v1.DeleteMatchConfigTemplateReq) returns (bpt.match.matchhub.v1.DeleteMatchConfigTemplateResp);
     */
    deleteMatchConfigTemplate(input: DeleteMatchConfigTemplateReq, options?: RpcOptions): UnaryCall<DeleteMatchConfigTemplateReq, DeleteMatchConfigTemplateResp> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMatchConfigTemplateReq, DeleteMatchConfigTemplateResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询配置操作日志列表
     *
     * @generated from protobuf rpc: ListMatchConfigOperationLog(bpt.match.matchhub.v1.ListMatchConfigOperationLogReq) returns (bpt.match.matchhub.v1.ListMatchConfigOperationLogResp);
     */
    listMatchConfigOperationLog(input: ListMatchConfigOperationLogReq, options?: RpcOptions): UnaryCall<ListMatchConfigOperationLogReq, ListMatchConfigOperationLogResp> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchConfigOperationLogReq, ListMatchConfigOperationLogResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 模版保存
     *
     * @generated from protobuf rpc: SaveMatchConfigTemplateV2(bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Req) returns (bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Resp);
     */
    saveMatchConfigTemplateV2(input: SaveMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<SaveMatchConfigTemplateV2Req, SaveMatchConfigTemplateV2Resp> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchConfigTemplateV2Req, SaveMatchConfigTemplateV2Resp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取模版
     *
     * @generated from protobuf rpc: GetMatchConfigTemplateV2(bpt.match.matchhub.v1.GetMatchConfigTemplateV2Req) returns (bpt.match.matchhub.v1.GetMatchConfigTemplateV2Resp);
     */
    getMatchConfigTemplateV2(input: GetMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<GetMatchConfigTemplateV2Req, GetMatchConfigTemplateV2Resp> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchConfigTemplateV2Req, GetMatchConfigTemplateV2Resp>("unary", this._transport, method, opt, input);
    }
    /**
     * 模版列表
     *
     * @generated from protobuf rpc: ListMatchConfigTemplateV2(bpt.match.matchhub.v1.ListMatchConfigTemplateV2Req) returns (bpt.match.matchhub.v1.ListMatchConfigTemplateV2Resp);
     */
    listMatchConfigTemplateV2(input: ListMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<ListMatchConfigTemplateV2Req, ListMatchConfigTemplateV2Resp> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchConfigTemplateV2Req, ListMatchConfigTemplateV2Resp>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除模版
     *
     * ===================新后台比赛配置相关接口 End=======================
     *
     * @generated from protobuf rpc: DeleteMatchConfigTemplateV2(bpt.match.matchhub.v1.DeleteMatchConfigTemplateV2Req) returns (bpt.base.Void);
     */
    deleteMatchConfigTemplateV2(input: DeleteMatchConfigTemplateV2Req, options?: RpcOptions): UnaryCall<DeleteMatchConfigTemplateV2Req, Void> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMatchConfigTemplateV2Req, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================配置下的比赛列表信息=======================
     * 鉴权
     *
     * @generated from protobuf rpc: ListMatchRecord(bpt.match.matchhub.v1.ListMatchRecordReq) returns (bpt.match.matchhub.v1.ListMatchRecordResp);
     */
    listMatchRecord(input: ListMatchRecordReq, options?: RpcOptions): UnaryCall<ListMatchRecordReq, ListMatchRecordResp> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchRecordReq, ListMatchRecordResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取运行中的赛事，内部使用
     *
     * @generated from protobuf rpc: BatchGetRunningMatch(bpt.match.matchhub.v1.BatchGetRunningMatchReq) returns (bpt.match.matchhub.v1.BatchGetRunningMatchResp);
     */
    batchGetRunningMatch(input: BatchGetRunningMatchReq, options?: RpcOptions): UnaryCall<BatchGetRunningMatchReq, BatchGetRunningMatchResp> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetRunningMatchReq, BatchGetRunningMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: GetMatchRecord(bpt.match.matchhub.v1.GetMatchRecordReq) returns (bpt.match.matchhub.v1.GetMatchRecordResp);
     */
    getMatchRecord(input: GetMatchRecordReq, options?: RpcOptions): UnaryCall<GetMatchRecordReq, GetMatchRecordResp> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchRecordReq, GetMatchRecordResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: UpdateMatchRecordState(bpt.match.matchhub.v1.UpdateMatchRecordStateReq) returns (bpt.match.matchhub.v1.UpdateMatchRecordStateResp);
     */
    updateMatchRecordState(input: UpdateMatchRecordStateReq, options?: RpcOptions): UnaryCall<UpdateMatchRecordStateReq, UpdateMatchRecordStateResp> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateMatchRecordStateReq, UpdateMatchRecordStateResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMatchRecordConf(bpt.match.matchhub.v1.UpdateMatchRecordConfReq) returns (bpt.base.Void);
     */
    updateMatchRecordConf(input: UpdateMatchRecordConfReq, options?: RpcOptions): UnaryCall<UpdateMatchRecordConfReq, Void> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateMatchRecordConfReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CancelMatchByUser(bpt.match.matchhub.v1.CancelMatchByUserReq) returns (bpt.match.matchhub.v1.CancelMatchByUserResp);
     */
    cancelMatchByUser(input: CancelMatchByUserReq, options?: RpcOptions): UnaryCall<CancelMatchByUserReq, CancelMatchByUserResp> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<CancelMatchByUserReq, CancelMatchByUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     * deprecated 强制取消是在比赛开始的情况下调用，应该由后端判断
     *
     * @generated from protobuf rpc: ForceCancelMatch(bpt.match.matchhub.v1.ForceCancelMatchReq) returns (bpt.match.matchhub.v1.ForceCancelMatchResp);
     */
    forceCancelMatch(input: ForceCancelMatchReq, options?: RpcOptions): UnaryCall<ForceCancelMatchReq, ForceCancelMatchResp> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<ForceCancelMatchReq, ForceCancelMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================用户加入比赛审核管理=======================
     * 鉴权
     *
     * @generated from protobuf rpc: UserApplyMatch(bpt.match.matchhub.v1.UserApplyMatchReq) returns (bpt.match.matchhub.v1.UserApplyMatchResp);
     */
    userApplyMatch(input: UserApplyMatchReq, options?: RpcOptions): UnaryCall<UserApplyMatchReq, UserApplyMatchResp> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserApplyMatchReq, UserApplyMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: AuditUserManage(bpt.match.matchhub.v1.AuditUserManageReq) returns (bpt.match.matchhub.v1.AuditUserManageResp);
     */
    auditUserManage(input: AuditUserManageReq, options?: RpcOptions): UnaryCall<AuditUserManageReq, AuditUserManageResp> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuditUserManageReq, AuditUserManageResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: ListUserManage(bpt.match.matchhub.v1.ListUserManageReq) returns (bpt.match.matchhub.v1.ListUserManageResp);
     */
    listUserManage(input: ListUserManageReq, options?: RpcOptions): UnaryCall<ListUserManageReq, ListUserManageResp> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserManageReq, ListUserManageResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 报名
     * 鉴权
     * CodeExceedLimit 超过报名次数限制
     * CodeNotLogin 未登陆
     * CodeUserNotInClub 不在俱乐部
     * CodeMatchUserFull 报名用户已满
     * CodeNotInValidTimeRange 不在报名时间
     * CodeLateRegisterExpired 超过延时报名时间
     * CodeDuplicatedRegistration 重复报名
     * CodeMatchCanceled 比赛已取消
     *
     * @generated from protobuf rpc: UserRegisterMatch(bpt.match.matchhub.v1.UserRegisterMatchReq) returns (bpt.match.matchhub.v1.UserRegisterMatchResp);
     */
    userRegisterMatch(input: UserRegisterMatchReq, options?: RpcOptions): UnaryCall<UserRegisterMatchReq, UserRegisterMatchResp> {
        const method = this.methods[50], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserRegisterMatchReq, UserRegisterMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 取消报名
     * 鉴权
     *
     * @generated from protobuf rpc: UserUnRegisterMatch(bpt.match.matchhub.v1.UserUnRegisterMatchReq) returns (bpt.match.matchhub.v1.UserUnRegisterMatchResp);
     */
    userUnRegisterMatch(input: UserUnRegisterMatchReq, options?: RpcOptions): UnaryCall<UserUnRegisterMatchReq, UserUnRegisterMatchResp> {
        const method = this.methods[51], opt = this._transport.mergeOptions(options);
        return stackIntercept<UserUnRegisterMatchReq, UserUnRegisterMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取UserManage
     * 鉴权
     *
     * @generated from protobuf rpc: BatchGetUserManage(bpt.match.matchhub.v1.BatchGetUserManageReq) returns (bpt.match.matchhub.v1.BatchGetUserManageResp);
     */
    batchGetUserManage(input: BatchGetUserManageReq, options?: RpcOptions): UnaryCall<BatchGetUserManageReq, BatchGetUserManageResp> {
        const method = this.methods[52], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserManageReq, BatchGetUserManageResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 标记用户状态
     *
     * @generated from protobuf rpc: UpdateUserManageStatus(bpt.match.matchhub.v1.UpdateUserManageStatusReq) returns (bpt.base.Void);
     */
    updateUserManageStatus(input: UpdateUserManageStatusReq, options?: RpcOptions): UnaryCall<UpdateUserManageStatusReq, Void> {
        const method = this.methods[53], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateUserManageStatusReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================matchlink api 上链相关=======================
     *  matchlink上链回调
     *
     * @generated from protobuf rpc: LinkTransactionCallBack(bpt.match.matchlink.v1.Transaction) returns (bpt.base.Void);
     */
    linkTransactionCallBack(input: Transaction, options?: RpcOptions): UnaryCall<Transaction, Void> {
        const method = this.methods[54], opt = this._transport.mergeOptions(options);
        return stackIntercept<Transaction, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================赛前、赛事面板==========================
     * 赛事面板桌子数据
     *
     * @generated from protobuf rpc: ListMatchTables(bpt.match.matchhub.v1.ListMatchTablesReq) returns (bpt.match.matchhub.v1.ListMatchTablesResp);
     */
    listMatchTables(input: ListMatchTablesReq, options?: RpcOptions): UnaryCall<ListMatchTablesReq, ListMatchTablesResp> {
        const method = this.methods[55], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchTablesReq, ListMatchTablesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 赛事面板用户数据
     *
     * @generated from protobuf rpc: ListMatchUsers(bpt.match.matchhub.v1.ListMatchUsersReq) returns (bpt.match.matchhub.v1.ListMatchUsersResp);
     */
    listMatchUsers(input: ListMatchUsersReq, options?: RpcOptions): UnaryCall<ListMatchUsersReq, ListMatchUsersResp> {
        const method = this.methods[56], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchUsersReq, ListMatchUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 赛前用户报名列表
     *
     * @generated from protobuf rpc: ListRegisterUsers(bpt.match.matchhub.v1.ListRegisterUsersReq) returns (bpt.match.matchhub.v1.ListRegisterUsersResp);
     */
    listRegisterUsers(input: ListRegisterUsersReq, options?: RpcOptions): UnaryCall<ListRegisterUsersReq, ListRegisterUsersResp> {
        const method = this.methods[57], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListRegisterUsersReq, ListRegisterUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取多场赛事桌子列表
     *
     * @generated from protobuf rpc: ListMultiMatchTables(bpt.match.matchhub.v1.ListMultiMatchTablesReq) returns (bpt.match.matchhub.v1.ListMultiMatchTablesResp);
     */
    listMultiMatchTables(input: ListMultiMatchTablesReq, options?: RpcOptions): UnaryCall<ListMultiMatchTablesReq, ListMultiMatchTablesResp> {
        const method = this.methods[58], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMultiMatchTablesReq, ListMultiMatchTablesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取该类型赛事可选的盲注组合
     *
     * @generated from protobuf rpc: ListMatchBlindOptions(bpt.match.matchhub.v1.ListMatchBlindOptionsReq) returns (bpt.match.matchhub.v1.ListMatchBlindOptionsResp);
     */
    listMatchBlindOptions(input: ListMatchBlindOptionsReq, options?: RpcOptions): UnaryCall<ListMatchBlindOptionsReq, ListMatchBlindOptionsResp> {
        const method = this.methods[59], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchBlindOptionsReq, ListMatchBlindOptionsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 赛前等待用户列表
     *
     * @generated from protobuf rpc: ListJoinedUsers(bpt.match.matchhub.v1.ListJoinedUsersReq) returns (bpt.match.matchhub.v1.ListJoinedUsersResp);
     */
    listJoinedUsers(input: ListJoinedUsersReq, options?: RpcOptions): UnaryCall<ListJoinedUsersReq, ListJoinedUsersResp> {
        const method = this.methods[60], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListJoinedUsersReq, ListJoinedUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 赛事面板用户数据
     *
     * @generated from protobuf rpc: GetUser(bpt.match.matchhub.v1.GetUserReq) returns (bpt.match.matchhub.v1.GetUserResp);
     */
    getUser(input: GetUserReq, options?: RpcOptions): UnaryCall<GetUserReq, GetUserResp> {
        const method = this.methods[61], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserReq, GetUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 报名成功的用户获取房间信息
     * 房间详细信息
     *
     * @generated from protobuf rpc: GetMatch(bpt.match.matchhub.v1.GetMatchReq) returns (bpt.match.matchhub.v1.GetMatchResp);
     */
    getMatch(input: GetMatchReq, options?: RpcOptions): UnaryCall<GetMatchReq, GetMatchResp> {
        const method = this.methods[62], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchReq, GetMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMatchBrief(bpt.match.matchhub.v1.GetMatchBriefReq) returns (bpt.match.matchhub.v1.GetMatchBriefResp);
     */
    getMatchBrief(input: GetMatchBriefReq, options?: RpcOptions): UnaryCall<GetMatchBriefReq, GetMatchBriefResp> {
        const method = this.methods[63], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchBriefReq, GetMatchBriefResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 加入比赛
     *
     * @generated from protobuf rpc: JoinMatch(bpt.match.matchhub.v1.JoinMatchReq) returns (bpt.match.matchhub.v1.JoinMatchResp);
     */
    joinMatch(input: JoinMatchReq, options?: RpcOptions): UnaryCall<JoinMatchReq, JoinMatchResp> {
        const method = this.methods[64], opt = this._transport.mergeOptions(options);
        return stackIntercept<JoinMatchReq, JoinMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取我的报名所有赛事
     * 鉴权
     *
     * @generated from protobuf rpc: ListMyMatch(bpt.match.matchhub.v1.ListMyMatchReq) returns (bpt.match.matchhub.v1.ListMyMatchResp);
     */
    listMyMatch(input: ListMyMatchReq, options?: RpcOptions): UnaryCall<ListMyMatchReq, ListMyMatchResp> {
        const method = this.methods[65], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMyMatchReq, ListMyMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 根据传入的matchKey批量获取比赛状态和用户在这场比赛中的状态
     *
     * @generated from protobuf rpc: BatchGetMatchStateAndUserState(bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateReq) returns (bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateResp);
     */
    batchGetMatchStateAndUserState(input: BatchGetMatchStateAndUserStateReq, options?: RpcOptions): UnaryCall<BatchGetMatchStateAndUserStateReq, BatchGetMatchStateAndUserStateResp> {
        const method = this.methods[66], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetMatchStateAndUserStateReq, BatchGetMatchStateAndUserStateResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 邮件中含有退款领奖信息，生成凭证，如果没有，则返回错误5001
     * 鉴权
     *
     * @generated from protobuf rpc: GenPayCredential(bpt.match.matchhub.v1.GenPayCredentialReq) returns (bpt.match.matchhub.v1.GenPayCredentialResp);
     */
    genPayCredential(input: GenPayCredentialReq, options?: RpcOptions): UnaryCall<GenPayCredentialReq, GenPayCredentialResp> {
        const method = this.methods[67], opt = this._transport.mergeOptions(options);
        return stackIntercept<GenPayCredentialReq, GenPayCredentialResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 免费加筹码(认证用户)
     *
     * @generated from protobuf rpc: FreeAddChips(bpt.match.matchhub.v1.FreeAddChipsReq) returns (bpt.match.matchhub.v1.FreeAddChipsResp);
     */
    freeAddChips(input: FreeAddChipsReq, options?: RpcOptions): UnaryCall<FreeAddChipsReq, FreeAddChipsResp> {
        const method = this.methods[68], opt = this._transport.mergeOptions(options);
        return stackIntercept<FreeAddChipsReq, FreeAddChipsResp>("unary", this._transport, method, opt, input);
    }
    // ===================赛前推送==========================

    /**
     * ===================比赛房间等级分类==========================
     * 鉴权
     *
     * @generated from protobuf rpc: ListMatchTag(bpt.match.matchhub.v1.ListMatchTagReq) returns (bpt.match.matchhub.v1.ListMatchTagResp);
     */
    listMatchTag(input: ListMatchTagReq, options?: RpcOptions): UnaryCall<ListMatchTagReq, ListMatchTagResp> {
        const method = this.methods[69], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchTagReq, ListMatchTagResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: SaveMatchTag(bpt.match.matchhub.v1.SaveMatchTagReq) returns (bpt.base.Void);
     */
    saveMatchTag(input: SaveMatchTagReq, options?: RpcOptions): UnaryCall<SaveMatchTagReq, Void> {
        const method = this.methods[70], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchTagReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     *
     * @generated from protobuf rpc: DeleteMatchTag(bpt.match.matchhub.v1.DeleteMatchTagReq) returns (bpt.base.Void);
     */
    deleteMatchTag(input: DeleteMatchTagReq, options?: RpcOptions): UnaryCall<DeleteMatchTagReq, Void> {
        const method = this.methods[71], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMatchTagReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * ====================现金桌开始=========================
     * 快速开始
     * 鉴权
     *
     * @generated from protobuf rpc: QuickStart(bpt.match.matchhub.v1.QuickStartReq) returns (bpt.match.matchhub.v1.QuickStartResp);
     */
    quickStart(input: QuickStartReq, options?: RpcOptions): UnaryCall<QuickStartReq, QuickStartResp> {
        const method = this.methods[72], opt = this._transport.mergeOptions(options);
        return stackIntercept<QuickStartReq, QuickStartResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 换桌
     * 鉴权
     *
     * @generated from protobuf rpc: ChangeDesk(bpt.match.matchhub.v1.ChangeDeskReq) returns (bpt.match.matchhub.v1.ChangeDeskResp);
     */
    changeDesk(input: ChangeDeskReq, options?: RpcOptions): UnaryCall<ChangeDeskReq, ChangeDeskResp> {
        const method = this.methods[73], opt = this._transport.mergeOptions(options);
        return stackIntercept<ChangeDeskReq, ChangeDeskResp>("unary", this._transport, method, opt, input);
    }
    // ====================现金桌结束=========================

    // ===================Match List Start=======================

    /**
     * 创建赛前
     *
     * @generated from protobuf rpc: CreateMatch(bpt.match.matchhub.v1.CreateMatchReq) returns (bpt.base.Void);
     */
    createMatch(input: CreateMatchReq, options?: RpcOptions): UnaryCall<CreateMatchReq, Void> {
        const method = this.methods[74], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateMatchReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除赛前
     *
     * @generated from protobuf rpc: RemoveMatch(bpt.match.matchhub.v1.RemoveMatchReq) returns (bpt.base.Void);
     */
    removeMatch(input: RemoveMatchReq, options?: RpcOptions): UnaryCall<RemoveMatchReq, Void> {
        const method = this.methods[75], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveMatchReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除赛事列表按confid维度
     * deprecated 不再维护
     *
     * @generated from protobuf rpc: RemoveMatchByConfId(bpt.match.matchhub.v1.RemoveMatchByConfIdReq) returns (bpt.base.Void);
     */
    removeMatchByConfId(input: RemoveMatchByConfIdReq, options?: RpcOptions): UnaryCall<RemoveMatchByConfIdReq, Void> {
        const method = this.methods[76], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveMatchByConfIdReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除赛事列表按matchkey维度
     *
     * @generated from protobuf rpc: RemoveMatchByMatchKey(bpt.match.matchhub.v1.RemoveMatchByMatchKeyReq) returns (bpt.base.Void);
     */
    removeMatchByMatchKey(input: RemoveMatchByMatchKeyReq, options?: RpcOptions): UnaryCall<RemoveMatchByMatchKeyReq, Void> {
        const method = this.methods[77], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveMatchByMatchKeyReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 清理不在列表展示的服务
     *
     * @generated from protobuf rpc: ClearShowExpiredList(bpt.base.Void) returns (bpt.base.Void);
     */
    clearShowExpiredList(input: Void, options?: RpcOptions): UnaryCall<Void, Void> {
        const method = this.methods[78], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 开赛成功更新赛事列表状态
     *
     * @generated from protobuf rpc: UpdateListStart(bpt.match.matchhub.v1.UpdateListStartReq) returns (bpt.base.Void);
     */
    updateListStart(input: UpdateListStartReq, options?: RpcOptions): UnaryCall<UpdateListStartReq, Void> {
        const method = this.methods[79], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateListStartReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 报名更新赛事列表选手数据，这里只适用与单桌如SNG、spingo等
     *
     * @generated from protobuf rpc: UpdateListUsers(bpt.match.matchhub.v1.UpdateListUsersReq) returns (bpt.base.Void);
     */
    updateListUsers(input: UpdateListUsersReq, options?: RpcOptions): UnaryCall<UpdateListUsersReq, Void> {
        const method = this.methods[80], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateListUsersReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMatch(bpt.match.matchhub.v1.ListMatchReq) returns (bpt.match.matchhub.v1.ListMatchResp);
     */
    listMatch(input: ListMatchReq, options?: RpcOptions): UnaryCall<ListMatchReq, ListMatchResp> {
        const method = this.methods[81], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchReq, ListMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取mtt赛事列表
     *
     * @generated from protobuf rpc: ListMttMatch(bpt.match.matchhub.v1.ListMttMatchReq) returns (bpt.match.matchhub.v1.ListMttMatchResp);
     */
    listMttMatch(input: ListMttMatchReq, options?: RpcOptions): UnaryCall<ListMttMatchReq, ListMttMatchResp> {
        const method = this.methods[82], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMttMatchReq, ListMttMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取侧边栏比赛列表
     *
     * @generated from protobuf rpc: ListSidebarMatch(bpt.match.matchhub.v1.ListSidebarMatchReq) returns (bpt.match.matchhub.v1.ListSidebarMatchResp);
     */
    listSidebarMatch(input: ListSidebarMatchReq, options?: RpcOptions): UnaryCall<ListSidebarMatchReq, ListSidebarMatchResp> {
        const method = this.methods[83], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListSidebarMatchReq, ListSidebarMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取比赛列表
     *
     * @generated from protobuf rpc: ListMatchItem(bpt.match.matchhub.v1.ListMatchItemReq) returns (bpt.match.matchhub.v1.ListMatchItemResp);
     */
    listMatchItem(input: ListMatchItemReq, options?: RpcOptions): UnaryCall<ListMatchItemReq, ListMatchItemResp> {
        const method = this.methods[84], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchItemReq, ListMatchItemResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取俱乐部比赛列表
     *
     * @generated from protobuf rpc: ListClubMatchItem(bpt.match.matchhub.v1.ListClubMatchItemReq) returns (bpt.match.matchhub.v1.ListClubMatchItemResp);
     */
    listClubMatchItem(input: ListClubMatchItemReq, options?: RpcOptions): UnaryCall<ListClubMatchItemReq, ListClubMatchItemResp> {
        const method = this.methods[85], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListClubMatchItemReq, ListClubMatchItemResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取日赛周赛，月赛最新一场的比赛
     *
     * @generated from protobuf rpc: ListLatestMatch(bpt.match.matchhub.v1.ListLatestMatchReq) returns (bpt.match.matchhub.v1.ListLatestMatchResp);
     */
    listLatestMatch(input: ListLatestMatchReq, options?: RpcOptions): UnaryCall<ListLatestMatchReq, ListLatestMatchResp> {
        const method = this.methods[86], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListLatestMatchReq, ListLatestMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询历史比赛列表
     *
     * @generated from protobuf rpc: ListHistoryMatch(bpt.match.matchhub.v1.ListHistoryMatchReq) returns (bpt.match.matchhub.v1.ListHistoryMatchResp);
     */
    listHistoryMatch(input: ListHistoryMatchReq, options?: RpcOptions): UnaryCall<ListHistoryMatchReq, ListHistoryMatchResp> {
        const method = this.methods[87], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListHistoryMatchReq, ListHistoryMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询俱乐部历史比赛列表
     *
     * @generated from protobuf rpc: ListHistoryClubMatch(bpt.match.matchhub.v1.ListHistoryClubMatchReq) returns (bpt.match.matchhub.v1.ListHistoryClubMatchResp);
     */
    listHistoryClubMatch(input: ListHistoryClubMatchReq, options?: RpcOptions): UnaryCall<ListHistoryClubMatchReq, ListHistoryClubMatchResp> {
        const method = this.methods[88], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListHistoryClubMatchReq, ListHistoryClubMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 返回已报名和在游戏中的比赛（不包括已获奖和出局的）;(原ListMyMatch，和matchhub冲突改名)
     * 鉴权
     *
     * @generated from protobuf rpc: GetListMyMatch(bpt.match.matchhub.v1.GetListMyMatchReq) returns (bpt.match.matchhub.v1.GetListMyMatchResp);
     */
    getListMyMatch(input: GetListMyMatchReq, options?: RpcOptions): UnaryCall<GetListMyMatchReq, GetListMyMatchResp> {
        const method = this.methods[89], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetListMyMatchReq, GetListMyMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetListMyMttMatch(bpt.base.Void) returns (bpt.match.matchhub.v1.GetListMyMttMatchResp);
     */
    getListMyMttMatch(input: Void, options?: RpcOptions): UnaryCall<Void, GetListMyMttMatchResp> {
        const method = this.methods[90], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetListMyMttMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 首页获取我的比赛列表(展示当前玩家已报名且未结束的全部比赛，包含已被淘汰但未完成最终结算的比赛；)
     * 鉴权
     *
     * @generated from protobuf rpc: GetHomeMyMatchList(bpt.match.matchhub.v1.GetHomeMyMatchListReq) returns (bpt.match.matchhub.v1.GetHomeMyMatchListResp);
     */
    getHomeMyMatchList(input: GetHomeMyMatchListReq, options?: RpcOptions): UnaryCall<GetHomeMyMatchListReq, GetHomeMyMatchListResp> {
        const method = this.methods[91], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetHomeMyMatchListReq, GetHomeMyMatchListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取用户可围观的比赛
     *
     * @generated from protobuf rpc: GetUserObservableMatch(bpt.match.matchhub.v1.GetUserObservableMatchReq) returns (bpt.match.matchhub.v1.GetUserObservableMatchResp);
     */
    getUserObservableMatch(input: GetUserObservableMatchReq, options?: RpcOptions): UnaryCall<GetUserObservableMatchReq, GetUserObservableMatchResp> {
        const method = this.methods[92], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserObservableMatchReq, GetUserObservableMatchResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 鉴权
     * 获取我的比赛记录
     *
     * @generated from protobuf rpc: GetMyMatchRecord(bpt.match.matchhub.v1.GetMyMatchRecordReq) returns (bpt.match.matchhub.v1.GetMyMatchRecordResp);
     */
    getMyMatchRecord(input: GetMyMatchRecordReq, options?: RpcOptions): UnaryCall<GetMyMatchRecordReq, GetMyMatchRecordResp> {
        const method = this.methods[93], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMyMatchRecordReq, GetMyMatchRecordResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取赛报详情
     *
     * @generated from protobuf rpc: GetMatchReportDetail(bpt.match.matchhub.v1.GetMatchReportDetailReq) returns (bpt.match.matchhub.v1.GetMatchReportDetailResp);
     */
    getMatchReportDetail(input: GetMatchReportDetailReq, options?: RpcOptions): UnaryCall<GetMatchReportDetailReq, GetMatchReportDetailResp> {
        const method = this.methods[94], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchReportDetailReq, GetMatchReportDetailResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMatchPrizeUsers(bpt.match.matchhub.v1.GetMatchPrizeUsersReq) returns (bpt.match.matchhub.v1.GetMatchPrizeUsersResp);
     */
    getMatchPrizeUsers(input: GetMatchPrizeUsersReq, options?: RpcOptions): UnaryCall<GetMatchPrizeUsersReq, GetMatchPrizeUsersResp> {
        const method = this.methods[95], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchPrizeUsersReq, GetMatchPrizeUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 首页排行榜
     *
     * @generated from protobuf rpc: GetIndexRanking(bpt.match.matchhub.v1.GetIndexRankingReq) returns (bpt.match.matchhub.v1.GetIndexRankingResp);
     */
    getIndexRanking(input: GetIndexRankingReq, options?: RpcOptions): UnaryCall<GetIndexRankingReq, GetIndexRankingResp> {
        const method = this.methods[96], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetIndexRankingReq, GetIndexRankingResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMatchSchedules(bpt.match.matchhub.v1.GetMatchSchedulesReq) returns (bpt.match.matchhub.v1.GetMatchSchedulesResp);
     */
    getMatchSchedules(input: GetMatchSchedulesReq, options?: RpcOptions): UnaryCall<GetMatchSchedulesReq, GetMatchSchedulesResp> {
        const method = this.methods[97], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchSchedulesReq, GetMatchSchedulesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取mtt赛事中进入钱圈的用户,作废，使用GetMatchPrizeUsers
     *
     * @generated from protobuf rpc: GetMatchMoneyCircleUsers(bpt.match.matchhub.v1.GetMatchMoneyCircleUsersReq) returns (bpt.match.matchhub.v1.GetMatchMoneyCircleUsersResp);
     */
    getMatchMoneyCircleUsers(input: GetMatchMoneyCircleUsersReq, options?: RpcOptions): UnaryCall<GetMatchMoneyCircleUsersReq, GetMatchMoneyCircleUsersResp> {
        const method = this.methods[98], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchMoneyCircleUsersReq, GetMatchMoneyCircleUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMttMatchFinalRanking(bpt.match.matchhub.v1.GetMttMatchFinalRankingReq) returns (bpt.match.matchhub.v1.GetMttMatchFinalRankingResp);
     */
    getMttMatchFinalRanking(input: GetMttMatchFinalRankingReq, options?: RpcOptions): UnaryCall<GetMttMatchFinalRankingReq, GetMttMatchFinalRankingResp> {
        const method = this.methods[99], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMttMatchFinalRankingReq, GetMttMatchFinalRankingResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 返回已报名和在游戏中的比赛（不包括已获奖和出局的）数量
     * 鉴权
     *
     * @generated from protobuf rpc: GetMyMatchNum(bpt.match.matchhub.v1.GetMyMatchNumReq) returns (bpt.match.matchhub.v1.GetMyMatchNumResp);
     */
    getMyMatchNum(input: GetMyMatchNumReq, options?: RpcOptions): UnaryCall<GetMyMatchNumReq, GetMyMatchNumResp> {
        const method = this.methods[100], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMyMatchNumReq, GetMyMatchNumResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取筹码信息
     *
     * @generated from protobuf rpc: GetChipInfo(bpt.match.matchhub.v1.GetChipInfoReq) returns (bpt.match.matchhub.v1.GetChipInfoResp);
     */
    getChipInfo(input: GetChipInfoReq, options?: RpcOptions): UnaryCall<GetChipInfoReq, GetChipInfoResp> {
        const method = this.methods[101], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetChipInfoReq, GetChipInfoResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdatePool(bpt.match.matchhub.v1.UpdatePoolReq) returns (bpt.match.matchhub.v1.UpdatePoolResp);
     */
    updatePool(input: UpdatePoolReq, options?: RpcOptions): UnaryCall<UpdatePoolReq, UpdatePoolResp> {
        const method = this.methods[102], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdatePoolReq, UpdatePoolResp>("unary", this._transport, method, opt, input);
    }
    // ===================MatchList End=======================

    /**
     * ===============mtt===============
     *
     * @generated from protobuf rpc: AnimationFinish(bpt.match.mttmatch.v1.AnimationFinishReq) returns (bpt.match.mttmatch.v1.AnimationFinishResp);
     */
    animationFinish(input: AnimationFinishReq, options?: RpcOptions): UnaryCall<AnimationFinishReq, AnimationFinishResp> {
        const method = this.methods[103], opt = this._transport.mergeOptions(options);
        return stackIntercept<AnimationFinishReq, AnimationFinishResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReBuy(bpt.match.mttmatch.v1.ReBuyReq) returns (bpt.match.mttmatch.v1.ReBuyResp);
     */
    reBuy(input: ReBuyReq, options?: RpcOptions): UnaryCall<ReBuyReq, ReBuyResp> {
        const method = this.methods[104], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReBuyReq, ReBuyResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReEntry(bpt.match.mttmatch.v1.ReEntryReq) returns (bpt.match.mttmatch.v1.ReEntryResp);
     */
    reEntry(input: ReEntryReq, options?: RpcOptions): UnaryCall<ReEntryReq, ReEntryResp> {
        const method = this.methods[105], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReEntryReq, ReEntryResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CancelReBuy(bpt.match.mttmatch.v1.CancelReBuyReq) returns (bpt.match.mttmatch.v1.CancelReBuyResp);
     */
    cancelReBuy(input: CancelReBuyReq, options?: RpcOptions): UnaryCall<CancelReBuyReq, CancelReBuyResp> {
        const method = this.methods[106], opt = this._transport.mergeOptions(options);
        return stackIntercept<CancelReBuyReq, CancelReBuyResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: CancelAddOn(bpt.match.mttmatch.v1.CancelAddOnReq) returns (bpt.match.mttmatch.v1.CancelAddOnResp);
     */
    cancelAddOn(input: CancelAddOnReq, options?: RpcOptions): UnaryCall<CancelAddOnReq, CancelAddOnResp> {
        const method = this.methods[107], opt = this._transport.mergeOptions(options);
        return stackIntercept<CancelAddOnReq, CancelAddOnResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: AddOn(bpt.match.mttmatch.v1.AddOnReq) returns (bpt.match.mttmatch.v1.AddOnResp);
     */
    addOn(input: AddOnReq, options?: RpcOptions): UnaryCall<AddOnReq, AddOnResp> {
        const method = this.methods[108], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddOnReq, AddOnResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取用户是否可以重入
     * 未淘汰的时候返回false
     *
     * @generated from protobuf rpc: BatchGetCanReEnter(bpt.match.mttmatch.v1.BatchGetCanReEnterReq) returns (bpt.match.mttmatch.v1.BatchGetCanReEnterResp);
     */
    batchGetCanReEnter(input: BatchGetCanReEnterReq, options?: RpcOptions): UnaryCall<BatchGetCanReEnterReq, BatchGetCanReEnterResp> {
        const method = this.methods[109], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetCanReEnterReq, BatchGetCanReEnterResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 用户未淘汰未淘汰，返回true,用户淘汰，且能重入，返回true,否则返回false
     *
     * @generated from protobuf rpc: BatchGetUserCanEnter(bpt.match.mttmatch.v1.BatchGetUserCanEnterReq) returns (bpt.match.mttmatch.v1.BatchGetUserCanEnterResp);
     */
    batchGetUserCanEnter(input: BatchGetUserCanEnterReq, options?: RpcOptions): UnaryCall<BatchGetUserCanEnterReq, BatchGetUserCanEnterResp> {
        const method = this.methods[110], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserCanEnterReq, BatchGetUserCanEnterResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MatchOverView(bpt.match.mttmatch.v1.MatchOverViewReq) returns (bpt.match.mttmatch.v1.MatchOverViewResp);
     */
    matchOverView(input: MatchOverViewReq, options?: RpcOptions): UnaryCall<MatchOverViewReq, MatchOverViewResp> {
        const method = this.methods[111], opt = this._transport.mergeOptions(options);
        return stackIntercept<MatchOverViewReq, MatchOverViewResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListRank(bpt.match.mttmatch.v1.ListRankReq) returns (bpt.match.mttmatch.v1.ListRankResp);
     */
    listRank(input: ListRankReq, options?: RpcOptions): UnaryCall<ListRankReq, ListRankResp> {
        const method = this.methods[112], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListRankReq, ListRankResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListDesks(bpt.match.mttmatch.v1.ListDesksReq) returns (bpt.match.mttmatch.v1.ListDesksResp);
     */
    listDesks(input: ListDesksReq, options?: RpcOptions): UnaryCall<ListDesksReq, ListDesksResp> {
        const method = this.methods[113], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListDesksReq, ListDesksResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取比赛中某些用户
     *
     * @generated from protobuf rpc: BatchGetUser(bpt.match.mttmatch.v1.BatchGetUserReq) returns (bpt.match.mttmatch.v1.BatchGetUserResp);
     */
    batchGetUser(input: BatchGetUserReq, options?: RpcOptions): UnaryCall<BatchGetUserReq, BatchGetUserResp> {
        const method = this.methods[114], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserReq, BatchGetUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     * =================================多桌列表==================================
     * 获取用户多桌列表
     *
     * @generated from protobuf rpc: ListTableViews(bpt.base.Void) returns (bpt.match.matchhub.v1.ListTableViewsResp);
     */
    listTableViews(input: Void, options?: RpcOptions): UnaryCall<Void, ListTableViewsResp> {
        const method = this.methods[115], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, ListTableViewsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 增加桌子
     *
     * @generated from protobuf rpc: AddTableView(bpt.match.matchhub.v1.AddTableViewReq) returns (bpt.base.Void);
     */
    addTableView(input: AddTableViewReq, options?: RpcOptions): UnaryCall<AddTableViewReq, Void> {
        const method = this.methods[116], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddTableViewReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchAddTableView(bpt.match.matchhub.v1.BatchAddTableViewReq) returns (bpt.base.Void);
     */
    batchAddTableView(input: BatchAddTableViewReq, options?: RpcOptions): UnaryCall<BatchAddTableViewReq, Void> {
        const method = this.methods[117], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchAddTableViewReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除桌子
     *
     * @generated from protobuf rpc: RemoveTableView(bpt.match.matchhub.v1.RemoveTableViewReq) returns (bpt.base.Void);
     */
    removeTableView(input: RemoveTableViewReq, options?: RpcOptions): UnaryCall<RemoveTableViewReq, Void> {
        const method = this.methods[118], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveTableViewReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 围观桌子，传入preferredTabkeKey优先分配，如不能分配，返回一个可围观桌子，需要登录
     *
     * @generated from protobuf rpc: ObserveTable(bpt.match.mttmatch.v1.ObserveTableReq) returns (bpt.match.mttmatch.v1.ObserveTableResp);
     */
    observeTable(input: ObserveTableReq, options?: RpcOptions): UnaryCall<ObserveTableReq, ObserveTableResp> {
        const method = this.methods[119], opt = this._transport.mergeOptions(options);
        return stackIntercept<ObserveTableReq, ObserveTableResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 泡沫时围观桌子
     *
     * @generated from protobuf rpc: ObserveTableOnBubble(bpt.match.mttmatch.v1.ObserveTableOnBubbleReq) returns (bpt.match.mttmatch.v1.ObserveTableOnBubbleResp);
     */
    observeTableOnBubble(input: ObserveTableOnBubbleReq, options?: RpcOptions): UnaryCall<ObserveTableOnBubbleReq, ObserveTableOnBubbleResp> {
        const method = this.methods[120], opt = this._transport.mergeOptions(options);
        return stackIntercept<ObserveTableOnBubbleReq, ObserveTableOnBubbleResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取玩家(已报名玩家)的桌子(在打的，和围观的)
     * 未报名或者未参赛的用户，返回再打桌子和围观桌子都为空
     *
     * @generated from protobuf rpc: GetMyTable(bpt.match.mttmatch.v1.GetMyTableReq) returns (bpt.match.mttmatch.v1.GetMyTableResp);
     */
    getMyTable(input: GetMyTableReq, options?: RpcOptions): UnaryCall<GetMyTableReq, GetMyTableResp> {
        const method = this.methods[121], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMyTableReq, GetMyTableResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取多日赛多日赛程的比赛名称和用户晋级信息
     *
     * @generated from protobuf rpc: ListSubMatches(bpt.match.matchhub.v1.ListSubMatchesReq) returns (bpt.match.matchhub.v1.ListSubMatchesResp);
     */
    listSubMatches(input: ListSubMatchesReq, options?: RpcOptions): UnaryCall<ListSubMatchesReq, ListSubMatchesResp> {
        const method = this.methods[122], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListSubMatchesReq, ListSubMatchesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 多日赛查询之前比赛的最高筹码，传入day1的matchkey，返回这场比赛之前的最大筹码，需要和当前比赛的筹码对比，获取最大筹码
     * 如果这是第一场，返回0
     *
     * @generated from protobuf rpc: GetDayOneMaxChips(bpt.match.matchhub.v1.GetDayOneMaxChipsReq) returns (bpt.match.matchhub.v1.GetDayOneMaxChipsResp);
     */
    getDayOneMaxChips(input: GetDayOneMaxChipsReq, options?: RpcOptions): UnaryCall<GetDayOneMaxChipsReq, GetDayOneMaxChipsResp> {
        const method = this.methods[123], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDayOneMaxChipsReq, GetDayOneMaxChipsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetMatchNameAndStartAt(bpt.match.matchhub.v1.GetMatchNameAndStartAtReq) returns (bpt.match.matchhub.v1.GetMatchNameAndStartAtResp);
     */
    getMatchNameAndStartAt(input: GetMatchNameAndStartAtReq, options?: RpcOptions): UnaryCall<GetMatchNameAndStartAtReq, GetMatchNameAndStartAtResp> {
        const method = this.methods[124], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchNameAndStartAtReq, GetMatchNameAndStartAtResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetMatchNameAndStartAt(bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtReq) returns (bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtResp);
     */
    batchGetMatchNameAndStartAt(input: BatchGetMatchNameAndStartAtReq, options?: RpcOptions): UnaryCall<BatchGetMatchNameAndStartAtReq, BatchGetMatchNameAndStartAtResp> {
        const method = this.methods[125], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetMatchNameAndStartAtReq, BatchGetMatchNameAndStartAtResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取奖池
     *
     * @generated from protobuf rpc: ListPrizePool(bpt.match.matchhub.v1.ListPrizePoolReq) returns (bpt.match.matchhub.v1.ListPrizePoolResp);
     */
    listPrizePool(input: ListPrizePoolReq, options?: RpcOptions): UnaryCall<ListPrizePoolReq, ListPrizePoolResp> {
        const method = this.methods[126], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListPrizePoolReq, ListPrizePoolResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMatchPrizeAndBubbleUser(bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserReq) returns (bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserResp);
     */
    listMatchPrizeAndBubbleUser(input: ListMatchPrizeAndBubbleUserReq, options?: RpcOptions): UnaryCall<ListMatchPrizeAndBubbleUserReq, ListMatchPrizeAndBubbleUserResp> {
        const method = this.methods[127], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchPrizeAndBubbleUserReq, ListMatchPrizeAndBubbleUserResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: FormatAndValidateTierPrize(bpt.match.matchhub.v1.FormatAndValidateTierPrizeReq) returns (bpt.match.matchhub.v1.FormatAndValidateTierPrizeResp);
     */
    formatAndValidateTierPrize(input: FormatAndValidateTierPrizeReq, options?: RpcOptions): UnaryCall<FormatAndValidateTierPrizeReq, FormatAndValidateTierPrizeResp> {
        const method = this.methods[128], opt = this._transport.mergeOptions(options);
        return stackIntercept<FormatAndValidateTierPrizeReq, FormatAndValidateTierPrizeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取赛事可用的合约
     *
     * @generated from protobuf rpc: ListDispatchContract(bpt.match.matchhub.v1.ListDispatchContractReq) returns (bpt.match.matchhub.v1.ListDispatchContractResp);
     */
    listDispatchContract(input: ListDispatchContractReq, options?: RpcOptions): UnaryCall<ListDispatchContractReq, ListDispatchContractResp> {
        const method = this.methods[129], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListDispatchContractReq, ListDispatchContractResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 后台获取用户记录
     *
     * @generated from protobuf rpc: ListUserRecordByCms(bpt.match.matchhub.v1.ListUserRecordByCmsReq) returns (bpt.match.matchhub.v1.ListUserRecordByCmsResp);
     */
    listUserRecordByCms(input: ListUserRecordByCmsReq, options?: RpcOptions): UnaryCall<ListUserRecordByCmsReq, ListUserRecordByCmsResp> {
        const method = this.methods[130], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserRecordByCmsReq, ListUserRecordByCmsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 后台手动发奖
     *
     * @generated from protobuf rpc: MarkAward(bpt.match.matchhub.v1.MarkAwardReq) returns (bpt.match.matchhub.v1.MarkAwardResp);
     */
    markAward(input: MarkAwardReq, options?: RpcOptions): UnaryCall<MarkAwardReq, MarkAwardResp> {
        const method = this.methods[131], opt = this._transport.mergeOptions(options);
        return stackIntercept<MarkAwardReq, MarkAwardResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetRegisterInfo(bpt.match.matchhub.v1.GetRegisterInfoReq) returns (bpt.match.matchhub.v1.GetRegisterInfoResp);
     */
    getRegisterInfo(input: GetRegisterInfoReq, options?: RpcOptions): UnaryCall<GetRegisterInfoReq, GetRegisterInfoResp> {
        const method = this.methods[132], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetRegisterInfoReq, GetRegisterInfoResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetReEntryInfo(bpt.match.matchhub.v1.GetReEntryInfoReq) returns (bpt.match.matchhub.v1.GetReEntryInfoResp);
     */
    getReEntryInfo(input: GetReEntryInfoReq, options?: RpcOptions): UnaryCall<GetReEntryInfoReq, GetReEntryInfoResp> {
        const method = this.methods[133], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetReEntryInfoReq, GetReEntryInfoResp>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================牌桌道具使用回调=======================
     *
     * @generated from protobuf rpc: UseProp(bpt.match.matchhub.v1.UsePropReq) returns (bpt.match.matchhub.v1.UsePropResp);
     */
    useProp(input: UsePropReq, options?: RpcOptions): UnaryCall<UsePropReq, UsePropResp> {
        const method = this.methods[134], opt = this._transport.mergeOptions(options);
        return stackIntercept<UsePropReq, UsePropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 使用道具-洗牌卡CallBack
     *
     * @generated from protobuf rpc: UsePropShuffleCardCallBack(bpt.match.matchhub.v1.UsePropShuffleCardCallBackReq) returns (bpt.match.matchhub.v1.UsePropShuffleCardCallBackResp);
     */
    usePropShuffleCardCallBack(input: UsePropShuffleCardCallBackReq, options?: RpcOptions): UnaryCall<UsePropShuffleCardCallBackReq, UsePropShuffleCardCallBackResp> {
        const method = this.methods[135], opt = this._transport.mergeOptions(options);
        return stackIntercept<UsePropShuffleCardCallBackReq, UsePropShuffleCardCallBackResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 标记已读收到奖励的比赛，在用户点击时触发
     *
     * @generated from protobuf rpc: MarkReadCompletedAwardMatch(bpt.match.matchhub.v1.MarkReadCompletedAwardMatchReq) returns (bpt.base.Void);
     */
    markReadCompletedAwardMatch(input: MarkReadCompletedAwardMatchReq, options?: RpcOptions): UnaryCall<MarkReadCompletedAwardMatchReq, Void> {
        const method = this.methods[136], opt = this._transport.mergeOptions(options);
        return stackIntercept<MarkReadCompletedAwardMatchReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 管理员补签
     *
     * @generated from protobuf rpc: AdminRegister(bpt.match.matchhub.v1.AdminRegisterReq) returns (bpt.base.Void);
     */
    adminRegister(input: AdminRegisterReq, options?: RpcOptions): UnaryCall<AdminRegisterReq, Void> {
        const method = this.methods[137], opt = this._transport.mergeOptions(options);
        return stackIntercept<AdminRegisterReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 恢复异常桌子
     *
     * @generated from protobuf rpc: RestoreMatchTables(bpt.match.mttmatch.v1.RestoreMatchTablesReq) returns (bpt.match.mttmatch.v1.RestoreMatchTablesResp);
     */
    restoreMatchTables(input: RestoreMatchTablesReq, options?: RpcOptions): UnaryCall<RestoreMatchTablesReq, RestoreMatchTablesResp> {
        const method = this.methods[138], opt = this._transport.mergeOptions(options);
        return stackIntercept<RestoreMatchTablesReq, RestoreMatchTablesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 用户未获奖返回 CodeNotPrizeUser
     * 表单不合法返回CodeInvalidForm（没有填写必填信息，漏项）
     * 提交表单成功会更新用户领奖状态为已领取
     *
     * @generated from protobuf rpc: SubmitAwardClaimForm(bpt.match.matchhub.v1.SubmitAwardClaimFormReq) returns (bpt.base.Void);
     */
    submitAwardClaimForm(input: SubmitAwardClaimFormReq, options?: RpcOptions): UnaryCall<SubmitAwardClaimFormReq, Void> {
        const method = this.methods[139], opt = this._transport.mergeOptions(options);
        return stackIntercept<SubmitAwardClaimFormReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 拉取提交的领奖表单
     *
     * @generated from protobuf rpc: ListAwardClaimForm(bpt.match.matchhub.v1.ListAwardClaimFormReq) returns (bpt.match.matchhub.v1.ListAwardClaimFormResp);
     */
    listAwardClaimForm(input: ListAwardClaimFormReq, options?: RpcOptions): UnaryCall<ListAwardClaimFormReq, ListAwardClaimFormResp> {
        const method = this.methods[140], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListAwardClaimFormReq, ListAwardClaimFormResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 审核操作
     *
     * @generated from protobuf rpc: AuditAwardClaimForm(bpt.match.matchhub.v1.AuditAwardClaimFormReq) returns (bpt.base.Void);
     */
    auditAwardClaimForm(input: AuditAwardClaimFormReq, options?: RpcOptions): UnaryCall<AuditAwardClaimFormReq, Void> {
        const method = this.methods[141], opt = this._transport.mergeOptions(options);
        return stackIntercept<AuditAwardClaimFormReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * ------------------内部接口 Start-----------------------------------
     * 获取早鸟配置
     *
     * @generated from protobuf rpc: GetEarlyBirdConfigInternal(bpt.match.matchhub.v1.GetEarlyBirdConfigInternalReq) returns (bpt.match.matchhub.v1.GetEarlyBirdConfigInternalResp);
     */
    getEarlyBirdConfigInternal(input: GetEarlyBirdConfigInternalReq, options?: RpcOptions): UnaryCall<GetEarlyBirdConfigInternalReq, GetEarlyBirdConfigInternalResp> {
        const method = this.methods[142], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetEarlyBirdConfigInternalReq, GetEarlyBirdConfigInternalResp>("unary", this._transport, method, opt, input);
    }
    // ------------------内部接口 End-------------------------------------

    /**
     * ---------------------MTT Match 调用接口 Start---------------------
     * 赛事进入延迟报名截止时间
     *
     * @generated from protobuf rpc: MttMatchRegisterDeadline(bpt.broker.match.MsgMatchRegisterDeadline) returns (bpt.base.Void);
     */
    mttMatchRegisterDeadline(input: MsgMatchRegisterDeadline, options?: RpcOptions): UnaryCall<MsgMatchRegisterDeadline, Void> {
        const method = this.methods[143], opt = this._transport.mergeOptions(options);
        return stackIntercept<MsgMatchRegisterDeadline, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 比赛结算消息
     *
     * @generated from protobuf rpc: MttMatchUserSettlement(bpt.broker.match.MsgMatchUserSettlement) returns (bpt.base.Void);
     */
    mttMatchUserSettlement(input: MsgMatchUserSettlement, options?: RpcOptions): UnaryCall<MsgMatchUserSettlement, Void> {
        const method = this.methods[144], opt = this._transport.mergeOptions(options);
        return stackIntercept<MsgMatchUserSettlement, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 比赛结束
     *
     * @generated from protobuf rpc: MttMatchOver(bpt.broker.match.MsgMatchOver) returns (bpt.base.Void);
     */
    mttMatchOver(input: MsgMatchOver, options?: RpcOptions): UnaryCall<MsgMatchOver, Void> {
        const method = this.methods[145], opt = this._transport.mergeOptions(options);
        return stackIntercept<MsgMatchOver, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取当前比赛的奖圈(如果是晋级赛，会返回SeatNum)
     *
     * ---------------------MTT Match 调用接口 End---------------------
     *
     * @generated from protobuf rpc: GetMatchPrizeNum(bpt.match.matchhub.v1.GetMatchPrizeNumReq) returns (bpt.match.matchhub.v1.GetMatchPrizeNumResp);
     */
    getMatchPrizeNum(input: GetMatchPrizeNumReq, options?: RpcOptions): UnaryCall<GetMatchPrizeNumReq, GetMatchPrizeNumResp> {
        const method = this.methods[146], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchPrizeNumReq, GetMatchPrizeNumResp>("unary", this._transport, method, opt, input);
    }
    /**
     * --------------------- 赛前预告相关接口 Start---------------------
     * client获取赛前预告
     *
     * @generated from protobuf rpc: GetMatchNotice(bpt.match.matchhub.v1.GetMatchNoticeReq) returns (bpt.match.matchhub.v1.GetMatchNoticeResp);
     */
    getMatchNotice(input: GetMatchNoticeReq, options?: RpcOptions): UnaryCall<GetMatchNoticeReq, GetMatchNoticeResp> {
        const method = this.methods[147], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchNoticeReq, GetMatchNoticeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取赛前预告配置列表
     *
     * @generated from protobuf rpc: ListMatchNoticeConfig(bpt.match.matchhub.v1.ListMatchNoticeConfigReq) returns (bpt.match.matchhub.v1.ListMatchNoticeConfigResp);
     */
    listMatchNoticeConfig(input: ListMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<ListMatchNoticeConfigReq, ListMatchNoticeConfigResp> {
        const method = this.methods[148], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMatchNoticeConfigReq, ListMatchNoticeConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取赛前预告配置
     *
     * @generated from protobuf rpc: GetMatchNoticeConfig(bpt.match.matchhub.v1.GetMatchNoticeConfigReq) returns (bpt.match.matchhub.v1.GetMatchNoticeConfigResp);
     */
    getMatchNoticeConfig(input: GetMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<GetMatchNoticeConfigReq, GetMatchNoticeConfigResp> {
        const method = this.methods[149], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchNoticeConfigReq, GetMatchNoticeConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存赛前预告配置
     *
     * @generated from protobuf rpc: SaveMatchNoticeConfig(bpt.match.matchhub.v1.SaveMatchNoticeConfigReq) returns (bpt.match.matchhub.v1.SaveMatchNoticeConfigResp);
     */
    saveMatchNoticeConfig(input: SaveMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<SaveMatchNoticeConfigReq, SaveMatchNoticeConfigResp> {
        const method = this.methods[150], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveMatchNoticeConfigReq, SaveMatchNoticeConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除赛前预告配置
     *
     * @generated from protobuf rpc: DeleteMatchNoticeConfig(bpt.match.matchhub.v1.DeleteMatchNoticeConfigReq) returns (bpt.base.Void);
     */
    deleteMatchNoticeConfig(input: DeleteMatchNoticeConfigReq, options?: RpcOptions): UnaryCall<DeleteMatchNoticeConfigReq, Void> {
        const method = this.methods[151], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMatchNoticeConfigReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * ===================比赛配置管理=======================
     * 列举在使用的比赛类型
     *
     * @generated from protobuf rpc: ListMatchFeatures(bpt.base.Void) returns (bpt.match.matchhub.v1.ListMatchFeaturesResp);
     */
    listMatchFeatures(input: Void, options?: RpcOptions): UnaryCall<Void, ListMatchFeaturesResp> {
        const method = this.methods[152], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, ListMatchFeaturesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取比赛的相关特性开关
     *
     * @generated from protobuf rpc: GetMatchFeatures(bpt.match.matchhub.v1.GetMatchFeaturesReq) returns (bpt.match.matchhub.v1.GetMatchFeaturesResp);
     */
    getMatchFeatures(input: GetMatchFeaturesReq, options?: RpcOptions): UnaryCall<GetMatchFeaturesReq, GetMatchFeaturesResp> {
        const method = this.methods[153], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetMatchFeaturesReq, GetMatchFeaturesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 社区获取推荐的比赛
     * 优先返回能进入的比赛
     *
     * @generated from protobuf rpc: GetRecommendMatch(bpt.base.Void) returns (bpt.match.matchhub.v1.GetRecommendMatchResp);
     */
    getRecommendMatch(input: Void, options?: RpcOptions): UnaryCall<Void, GetRecommendMatchResp> {
        const method = this.methods[154], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetRecommendMatchResp>("unary", this._transport, method, opt, input);
    }
}
