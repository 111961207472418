import { GrpcWebFetchTransport } from '@mtt/grpc-core/src/grpcClient';

import {
  BatchGetUserResp,
  GetUserResp,
} from '@/grpc/client/bpt/hall/userinfo/v1/userinfo';
import { UserinfoClient } from '@/grpc/client/bpt/hall/userinfo/v1/userinfo.client';
import { getApiPrefix } from '@/utils/api-prefix';

const createNoAuthTransport = () => {
  return new GrpcWebFetchTransport({
    format: 'binary',
    baseUrl: `${getApiPrefix()}/userinfo_noauth/`,
  });
};
class HallUserInfoMgr {
  private noAuthClient: UserinfoClient;

  constructor() {
    this.noAuthClient = new UserinfoClient(createNoAuthTransport());
  }

  /**
   * 获取用户信息
   */
  async getUser(uid: number): Promise<GetUserResp> {
    return await this.noAuthClient.getUser({ uid }).response;
  }

  /** 批量获取用户信息 */
  async batchGetUser(uids: number[]): Promise<BatchGetUserResp> {
    return await this.noAuthClient.batchGetUser({ uids }).response;
  }
}

export const hallUserInfoMgr = new HallUserInfoMgr();
