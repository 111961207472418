// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/social/relation/v1/relation.proto" (package "bpt.social.relation.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Void } from "../../../base/base";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.social.relation.v1.UserMark
 */
export interface UserMark {
    /**
     * @generated from protobuf field: int64 userID = 1;
     */
    userID: number;
    /**
     * @generated from protobuf field: bpt.social.relation.v1.Mark mark = 2;
     */
    mark?: Mark;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.Mark
 */
export interface Mark {
    /**
     * 别名
     *
     * @generated from protobuf field: string alias = 2;
     */
    alias: string;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.SaveMarkReq
 */
export interface SaveMarkReq {
    /**
     * @generated from protobuf field: int64 dstID = 1;
     */
    dstID: number;
    /**
     * @generated from protobuf field: bpt.social.relation.v1.Mark mark = 2;
     */
    mark?: Mark;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.SaveMarkResp
 */
export interface SaveMarkResp {
    /**
     * @generated from protobuf field: int64 seq = 1;
     */
    seq: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.GetMarkReq
 */
export interface GetMarkReq {
    /**
     * @generated from protobuf field: int64 dstID = 1;
     */
    dstID: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.GetMarkResp
 */
export interface GetMarkResp {
    /**
     * @generated from protobuf field: bpt.social.relation.v1.Mark mark = 1;
     */
    mark?: Mark;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.SyncMarkReq
 */
export interface SyncMarkReq {
    /**
     * @generated from protobuf field: int64 seq = 1;
     */
    seq: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.SyncMarkResp
 */
export interface SyncMarkResp {
    /**
     * @generated from protobuf field: repeated bpt.social.relation.v1.UserMark list = 1;
     */
    list: UserMark[];
    /**
     * @generated from protobuf field: int64 seq = 2;
     */
    seq: number;
}
/**
 * **  Follow **
 *
 * @generated from protobuf message bpt.social.relation.v1.FollowReq
 */
export interface FollowReq {
    /**
     * 对方 uid
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * **  UnFollow **
 *
 * @generated from protobuf message bpt.social.relation.v1.UnFollowReq
 */
export interface UnFollowReq {
    /**
     * 对方 uid
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * **  UnFollow **
 *
 * @generated from protobuf message bpt.social.relation.v1.UnFriendReq
 */
export interface UnFriendReq {
    /**
     * 对方 uid
     *
     * @generated from protobuf field: repeated int64 dstIDList = 1;
     */
    dstIDList: number[];
}
/**
 * **  Black **
 *
 * @generated from protobuf message bpt.social.relation.v1.BlackReq
 */
export interface BlackReq {
    /**
     * 对方 uid
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * **  UnBlack **
 *
 * @generated from protobuf message bpt.social.relation.v1.UnBlackReq
 */
export interface UnBlackReq {
    /**
     * 对方 uid
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.Relate
 */
export interface Relate {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.social.relation.v1.RelateType relateType = 2;
     */
    relateType: RelateType;
    /**
     * @generated from protobuf field: int64 relateAt = 3;
     */
    relateAt: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.PageListReq
 */
export interface PageListReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.social.relation.v1.RelateType relateType = 2;
     */
    relateType: RelateType;
    /**
     * @generated from protobuf field: int64 offset = 3;
     */
    offset: number; // >=0
    /**
     * @generated from protobuf field: int64 limit = 4;
     */
    limit: number; // 最多50个,大于0
}
/**
 * @generated from protobuf message bpt.social.relation.v1.PageListResp
 */
export interface PageListResp {
    /**
     * @generated from protobuf field: int64 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: repeated bpt.social.relation.v1.Relate list = 2;
     */
    list: Relate[];
}
/**
 * @generated from protobuf message bpt.social.relation.v1.CountReq
 */
export interface CountReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.CountResp
 */
export interface CountResp {
    /**
     * 关注数
     *
     * @generated from protobuf field: int64 followNum = 1;
     */
    followNum: number;
    /**
     * 粉丝数
     *
     * @generated from protobuf field: int64 fansNum = 2;
     */
    fansNum: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.GetReq
 */
export interface GetReq {
    /**
     * @generated from protobuf field: repeated int64 userIDList = 1;
     */
    userIDList: number[];
}
/**
 * @generated from protobuf message bpt.social.relation.v1.GetResp
 */
export interface GetResp {
    /**
     * @generated from protobuf field: repeated bpt.social.relation.v1.Relate list = 1;
     */
    list: Relate[]; // 没数据返回表示没关系
}
/**
 * ** SyncChange **
 *
 * @generated from protobuf message bpt.social.relation.v1.SyncID
 */
export interface SyncID {
    /**
     * @generated from protobuf field: int32 Date = 1 [json_name = "Date"];
     */
    Date: number;
    /**
     * @generated from protobuf field: int32 Incr = 2 [json_name = "Incr"];
     */
    Incr: number;
}
/**
 * ** SyncChange **
 *
 * @generated from protobuf message bpt.social.relation.v1.SyncReq
 */
export interface SyncReq {
    /**
     * @generated from protobuf field: string hash = 1;
     */
    hash: string;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.SyncResp
 */
export interface SyncResp {
    /**
     * @generated from protobuf field: repeated int64 list = 1;
     */
    list: number[];
    /**
     * @generated from protobuf field: string hash = 2;
     */
    hash: string;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.ListFriendReq
 */
export interface ListFriendReq {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: number;
    /**
     * @generated from protobuf field: int64 limit = 2;
     */
    limit: number;
}
/**
 * @generated from protobuf message bpt.social.relation.v1.ListFriendResp
 */
export interface ListFriendResp {
    /**
     * @generated from protobuf field: int64 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[];
}
/**
 * ** List **
 *
 * @generated from protobuf enum bpt.social.relation.v1.RelateType
 */
export enum RelateType {
    /**
     * @generated from protobuf enum value: UnknownType = 0;
     */
    UnknownType = 0,
    /**
     * @generated from protobuf enum value: Fans = 1;
     */
    Fans = 1,
    /**
     * @generated from protobuf enum value: Follow = 2;
     */
    Follow = 2,
    /**
     * @generated from protobuf enum value: Black = 3;
     */
    Black = 3,
    /**
     * @generated from protobuf enum value: Blacked = 4;
     */
    Blacked = 4,
    /**
     * @generated from protobuf enum value: Friend = 5;
     */
    Friend = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class UserMark$Type extends MessageType<UserMark> {
    constructor() {
        super("bpt.social.relation.v1.UserMark", [
            { no: 1, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "mark", kind: "message", T: () => Mark }
        ]);
    }
    create(value?: PartialMessage<UserMark>): UserMark {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<UserMark>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserMark): UserMark {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 userID */ 1:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* bpt.social.relation.v1.Mark mark */ 2:
                    message.mark = Mark.internalBinaryRead(reader, reader.uint32(), options, message.mark);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserMark, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 userID = 1; */
        if (message.userID !== 0)
            writer.tag(1, WireType.Varint).int64(message.userID);
        /* bpt.social.relation.v1.Mark mark = 2; */
        if (message.mark)
            Mark.internalBinaryWrite(message.mark, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.UserMark
 */
export const UserMark = new UserMark$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Mark$Type extends MessageType<Mark> {
    constructor() {
        super("bpt.social.relation.v1.Mark", [
            { no: 2, name: "alias", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Mark>): Mark {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.alias = "";
        if (value !== undefined)
            reflectionMergePartial<Mark>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Mark): Mark {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string alias */ 2:
                    message.alias = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Mark, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string alias = 2; */
        if (message.alias !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.alias);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.Mark
 */
export const Mark = new Mark$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMarkReq$Type extends MessageType<SaveMarkReq> {
    constructor() {
        super("bpt.social.relation.v1.SaveMarkReq", [
            { no: 1, name: "dstID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "mark", kind: "message", T: () => Mark }
        ]);
    }
    create(value?: PartialMessage<SaveMarkReq>): SaveMarkReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dstID = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveMarkReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMarkReq): SaveMarkReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 dstID */ 1:
                    message.dstID = reader.int64().toNumber();
                    break;
                case /* bpt.social.relation.v1.Mark mark */ 2:
                    message.mark = Mark.internalBinaryRead(reader, reader.uint32(), options, message.mark);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMarkReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 dstID = 1; */
        if (message.dstID !== 0)
            writer.tag(1, WireType.Varint).int64(message.dstID);
        /* bpt.social.relation.v1.Mark mark = 2; */
        if (message.mark)
            Mark.internalBinaryWrite(message.mark, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.SaveMarkReq
 */
export const SaveMarkReq = new SaveMarkReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMarkResp$Type extends MessageType<SaveMarkResp> {
    constructor() {
        super("bpt.social.relation.v1.SaveMarkResp", [
            { no: 1, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SaveMarkResp>): SaveMarkResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.seq = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveMarkResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMarkResp): SaveMarkResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 seq */ 1:
                    message.seq = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMarkResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 seq = 1; */
        if (message.seq !== 0)
            writer.tag(1, WireType.Varint).int64(message.seq);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.SaveMarkResp
 */
export const SaveMarkResp = new SaveMarkResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMarkReq$Type extends MessageType<GetMarkReq> {
    constructor() {
        super("bpt.social.relation.v1.GetMarkReq", [
            { no: 1, name: "dstID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMarkReq>): GetMarkReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dstID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMarkReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMarkReq): GetMarkReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 dstID */ 1:
                    message.dstID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMarkReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 dstID = 1; */
        if (message.dstID !== 0)
            writer.tag(1, WireType.Varint).int64(message.dstID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.GetMarkReq
 */
export const GetMarkReq = new GetMarkReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMarkResp$Type extends MessageType<GetMarkResp> {
    constructor() {
        super("bpt.social.relation.v1.GetMarkResp", [
            { no: 1, name: "mark", kind: "message", T: () => Mark }
        ]);
    }
    create(value?: PartialMessage<GetMarkResp>): GetMarkResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMarkResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMarkResp): GetMarkResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.social.relation.v1.Mark mark */ 1:
                    message.mark = Mark.internalBinaryRead(reader, reader.uint32(), options, message.mark);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMarkResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.social.relation.v1.Mark mark = 1; */
        if (message.mark)
            Mark.internalBinaryWrite(message.mark, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.GetMarkResp
 */
export const GetMarkResp = new GetMarkResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncMarkReq$Type extends MessageType<SyncMarkReq> {
    constructor() {
        super("bpt.social.relation.v1.SyncMarkReq", [
            { no: 1, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SyncMarkReq>): SyncMarkReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.seq = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<SyncMarkReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncMarkReq): SyncMarkReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 seq */ 1:
                    message.seq = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncMarkReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 seq = 1; */
        if (message.seq !== 0)
            writer.tag(1, WireType.Varint).int64(message.seq);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.SyncMarkReq
 */
export const SyncMarkReq = new SyncMarkReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncMarkResp$Type extends MessageType<SyncMarkResp> {
    constructor() {
        super("bpt.social.relation.v1.SyncMarkResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserMark },
            { no: 2, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SyncMarkResp>): SyncMarkResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.seq = 0;
        if (value !== undefined)
            reflectionMergePartial<SyncMarkResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncMarkResp): SyncMarkResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.social.relation.v1.UserMark list */ 1:
                    message.list.push(UserMark.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 seq */ 2:
                    message.seq = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncMarkResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.social.relation.v1.UserMark list = 1; */
        for (let i = 0; i < message.list.length; i++)
            UserMark.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 seq = 2; */
        if (message.seq !== 0)
            writer.tag(2, WireType.Varint).int64(message.seq);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.SyncMarkResp
 */
export const SyncMarkResp = new SyncMarkResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FollowReq$Type extends MessageType<FollowReq> {
    constructor() {
        super("bpt.social.relation.v1.FollowReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<FollowReq>): FollowReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<FollowReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FollowReq): FollowReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FollowReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.FollowReq
 */
export const FollowReq = new FollowReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnFollowReq$Type extends MessageType<UnFollowReq> {
    constructor() {
        super("bpt.social.relation.v1.UnFollowReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UnFollowReq>): UnFollowReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<UnFollowReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnFollowReq): UnFollowReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnFollowReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.UnFollowReq
 */
export const UnFollowReq = new UnFollowReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnFriendReq$Type extends MessageType<UnFriendReq> {
    constructor() {
        super("bpt.social.relation.v1.UnFriendReq", [
            { no: 1, name: "dstIDList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UnFriendReq>): UnFriendReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dstIDList = [];
        if (value !== undefined)
            reflectionMergePartial<UnFriendReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnFriendReq): UnFriendReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 dstIDList */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.dstIDList.push(reader.int64().toNumber());
                    else
                        message.dstIDList.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnFriendReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 dstIDList = 1; */
        if (message.dstIDList.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.dstIDList.length; i++)
                writer.int64(message.dstIDList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.UnFriendReq
 */
export const UnFriendReq = new UnFriendReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlackReq$Type extends MessageType<BlackReq> {
    constructor() {
        super("bpt.social.relation.v1.BlackReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BlackReq>): BlackReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<BlackReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlackReq): BlackReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlackReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.BlackReq
 */
export const BlackReq = new BlackReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnBlackReq$Type extends MessageType<UnBlackReq> {
    constructor() {
        super("bpt.social.relation.v1.UnBlackReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UnBlackReq>): UnBlackReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<UnBlackReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnBlackReq): UnBlackReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnBlackReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.UnBlackReq
 */
export const UnBlackReq = new UnBlackReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Relate$Type extends MessageType<Relate> {
    constructor() {
        super("bpt.social.relation.v1.Relate", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "relateType", kind: "enum", T: () => ["bpt.social.relation.v1.RelateType", RelateType] },
            { no: 3, name: "relateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Relate>): Relate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.relateType = 0;
        message.relateAt = 0;
        if (value !== undefined)
            reflectionMergePartial<Relate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Relate): Relate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.social.relation.v1.RelateType relateType */ 2:
                    message.relateType = reader.int32();
                    break;
                case /* int64 relateAt */ 3:
                    message.relateAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Relate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.social.relation.v1.RelateType relateType = 2; */
        if (message.relateType !== 0)
            writer.tag(2, WireType.Varint).int32(message.relateType);
        /* int64 relateAt = 3; */
        if (message.relateAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.relateAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.Relate
 */
export const Relate = new Relate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageListReq$Type extends MessageType<PageListReq> {
    constructor() {
        super("bpt.social.relation.v1.PageListReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "relateType", kind: "enum", T: () => ["bpt.social.relation.v1.RelateType", RelateType] },
            { no: 3, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageListReq>): PageListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.relateType = 0;
        message.offset = 0;
        message.limit = 0;
        if (value !== undefined)
            reflectionMergePartial<PageListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageListReq): PageListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.social.relation.v1.RelateType relateType */ 2:
                    message.relateType = reader.int32();
                    break;
                case /* int64 offset */ 3:
                    message.offset = reader.int64().toNumber();
                    break;
                case /* int64 limit */ 4:
                    message.limit = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.social.relation.v1.RelateType relateType = 2; */
        if (message.relateType !== 0)
            writer.tag(2, WireType.Varint).int32(message.relateType);
        /* int64 offset = 3; */
        if (message.offset !== 0)
            writer.tag(3, WireType.Varint).int64(message.offset);
        /* int64 limit = 4; */
        if (message.limit !== 0)
            writer.tag(4, WireType.Varint).int64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.PageListReq
 */
export const PageListReq = new PageListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageListResp$Type extends MessageType<PageListResp> {
    constructor() {
        super("bpt.social.relation.v1.PageListResp", [
            { no: 1, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Relate }
        ]);
    }
    create(value?: PartialMessage<PageListResp>): PageListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<PageListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageListResp): PageListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 total */ 1:
                    message.total = reader.int64().toNumber();
                    break;
                case /* repeated bpt.social.relation.v1.Relate list */ 2:
                    message.list.push(Relate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int64(message.total);
        /* repeated bpt.social.relation.v1.Relate list = 2; */
        for (let i = 0; i < message.list.length; i++)
            Relate.internalBinaryWrite(message.list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.PageListResp
 */
export const PageListResp = new PageListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountReq$Type extends MessageType<CountReq> {
    constructor() {
        super("bpt.social.relation.v1.CountReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CountReq>): CountReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<CountReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountReq): CountReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.CountReq
 */
export const CountReq = new CountReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountResp$Type extends MessageType<CountResp> {
    constructor() {
        super("bpt.social.relation.v1.CountResp", [
            { no: 1, name: "followNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "fansNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CountResp>): CountResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.followNum = 0;
        message.fansNum = 0;
        if (value !== undefined)
            reflectionMergePartial<CountResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountResp): CountResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 followNum */ 1:
                    message.followNum = reader.int64().toNumber();
                    break;
                case /* int64 fansNum */ 2:
                    message.fansNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 followNum = 1; */
        if (message.followNum !== 0)
            writer.tag(1, WireType.Varint).int64(message.followNum);
        /* int64 fansNum = 2; */
        if (message.fansNum !== 0)
            writer.tag(2, WireType.Varint).int64(message.fansNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.CountResp
 */
export const CountResp = new CountResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReq$Type extends MessageType<GetReq> {
    constructor() {
        super("bpt.social.relation.v1.GetReq", [
            { no: 1, name: "userIDList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetReq>): GetReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userIDList = [];
        if (value !== undefined)
            reflectionMergePartial<GetReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReq): GetReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 userIDList */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.userIDList.push(reader.int64().toNumber());
                    else
                        message.userIDList.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 userIDList = 1; */
        if (message.userIDList.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.userIDList.length; i++)
                writer.int64(message.userIDList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.GetReq
 */
export const GetReq = new GetReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResp$Type extends MessageType<GetResp> {
    constructor() {
        super("bpt.social.relation.v1.GetResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Relate }
        ]);
    }
    create(value?: PartialMessage<GetResp>): GetResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<GetResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResp): GetResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.social.relation.v1.Relate list */ 1:
                    message.list.push(Relate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.social.relation.v1.Relate list = 1; */
        for (let i = 0; i < message.list.length; i++)
            Relate.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.GetResp
 */
export const GetResp = new GetResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncID$Type extends MessageType<SyncID> {
    constructor() {
        super("bpt.social.relation.v1.SyncID", [
            { no: 1, name: "Date", kind: "scalar", localName: "Date", jsonName: "Date", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "Incr", kind: "scalar", localName: "Incr", jsonName: "Incr", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SyncID>): SyncID {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Date = 0;
        message.Incr = 0;
        if (value !== undefined)
            reflectionMergePartial<SyncID>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncID): SyncID {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 Date = 1 [json_name = "Date"];*/ 1:
                    message.Date = reader.int32();
                    break;
                case /* int32 Incr = 2 [json_name = "Incr"];*/ 2:
                    message.Incr = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncID, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 Date = 1 [json_name = "Date"]; */
        if (message.Date !== 0)
            writer.tag(1, WireType.Varint).int32(message.Date);
        /* int32 Incr = 2 [json_name = "Incr"]; */
        if (message.Incr !== 0)
            writer.tag(2, WireType.Varint).int32(message.Incr);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.SyncID
 */
export const SyncID = new SyncID$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncReq$Type extends MessageType<SyncReq> {
    constructor() {
        super("bpt.social.relation.v1.SyncReq", [
            { no: 1, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncReq>): SyncReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hash = "";
        if (value !== undefined)
            reflectionMergePartial<SyncReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncReq): SyncReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string hash */ 1:
                    message.hash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string hash = 1; */
        if (message.hash !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.hash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.SyncReq
 */
export const SyncReq = new SyncReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncResp$Type extends MessageType<SyncResp> {
    constructor() {
        super("bpt.social.relation.v1.SyncResp", [
            { no: 1, name: "list", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncResp>): SyncResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.hash = "";
        if (value !== undefined)
            reflectionMergePartial<SyncResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncResp): SyncResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 list */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.list.push(reader.int64().toNumber());
                    else
                        message.list.push(reader.int64().toNumber());
                    break;
                case /* string hash */ 2:
                    message.hash = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 list = 1; */
        if (message.list.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.list.length; i++)
                writer.int64(message.list[i]);
            writer.join();
        }
        /* string hash = 2; */
        if (message.hash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.hash);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.SyncResp
 */
export const SyncResp = new SyncResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFriendReq$Type extends MessageType<ListFriendReq> {
    constructor() {
        super("bpt.social.relation.v1.ListFriendReq", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListFriendReq>): ListFriendReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offset = 0;
        message.limit = 0;
        if (value !== undefined)
            reflectionMergePartial<ListFriendReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFriendReq): ListFriendReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toNumber();
                    break;
                case /* int64 limit */ 2:
                    message.limit = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFriendReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* int64 limit = 2; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.ListFriendReq
 */
export const ListFriendReq = new ListFriendReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListFriendResp$Type extends MessageType<ListFriendResp> {
    constructor() {
        super("bpt.social.relation.v1.ListFriendResp", [
            { no: 1, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListFriendResp>): ListFriendResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<ListFriendResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListFriendResp): ListFriendResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 total */ 1:
                    message.total = reader.int64().toNumber();
                    break;
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListFriendResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int64(message.total);
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.social.relation.v1.ListFriendResp
 */
export const ListFriendResp = new ListFriendResp$Type();
/**
 * @generated ServiceType for protobuf service bpt.social.relation.v1.Relation
 */
export const Relation = new ServiceType("bpt.social.relation.v1.Relation", [
    { name: "Follow", options: {}, I: FollowReq, O: Void },
    { name: "UnFollow", options: {}, I: UnFollowReq, O: Void },
    { name: "PageList", options: {}, I: PageListReq, O: PageListResp },
    { name: "Count", options: {}, I: CountReq, O: CountResp },
    { name: "Get", options: {}, I: GetReq, O: GetResp },
    { name: "ListFriend", options: {}, I: ListFriendReq, O: ListFriendResp },
    { name: "Sync", options: {}, I: SyncReq, O: SyncResp },
    { name: "SyncMark", options: {}, I: SyncMarkReq, O: SyncMarkResp },
    { name: "SaveMark", options: {}, I: SaveMarkReq, O: SaveMarkResp }
]);
