export default {
  header: { getStart: 'Get Started' },
  banner: {
    title: 'Earn free Bitcoin weekly',
    description: 'Exciting WEB3 competition platform',
    launch: 'Launch MTT Sports',
    launchUrl: 'https://sports.mtt.xyz/oauth2/login',
  },

  club: {
    title: 'Build Your MTT Sports Club',
    description:
      'There will be many MTT Sports teams in the future, allowing offline communities to host their own competitions and manage their fan club.',
  },
  experience: {
    title: 'Fantastic Interactions',
    description:
      'You can shop for a variety of items and express your feelings by sharing virtual gifts and props. These interactions make gameplay more interesting and fun for players, improving communication and interaction.',
  },

  global: {
    title: 'Global MTT Sports Players Social Circle',
    description:
      'This is where star players and competition enthusiasts from all over the world gather. You can make many good friends on MTT Sports.',
  },
  rankingSystem: {
    title: 'Worldwide Ranking System',
    description:
      'Achieve a worldwide ranking in MTT by participating in events and following your own career advancement path. Progress in the events will enhance your ranking and help you become a professional player.',
  },
  token: {
    title: 'MTT Tokens',
    description:
      '<b>MTT Tokens is the native cryptocurrency of the MTT Network.</b><br/>Participating in competitions at MTT Sports can earn you <b>MTT Tokens</b>, which in turn can yield exceptionally high returns.<br/><b>MTT Tokens</b> can also be used to pay for MTT Sports platform consumption and transaction fees on the MTT Network;',
  },

  tournaments: {
    title: 'MTT Tournament',
    description:
      'MTT Sports offers a wide variety of events. We provide not only regular daily, weekly, and monthly competitions but also host a series of high-reward events for your participation.',
  },

  footer: {
    aboutUs: 'About Us',
    mttPocker: 'MTT SPORTS',
    desclimer:
      '[MTT SPORTS (mtt.xyz)] is owned and operated by [MTT SPORTS Limited]. Registration number: [000030846], registered address: [9 Barrack, Belize City, Belize]. Contact us at [officials@mtt.xyz]. [MTT SPORTS (mtt.xyz)] is licensed and regulated by the Government of the Autonomous island of Anjouan, Union of Comoros, and operates under License No. [ALSI-152312001-FI3]. [MTT SPORTS (mtt.xyz)] has passed all regulatory compliance and is legally authorized to conduct gaming operations for games of chance and E-Sports.',
    information: 'Information',
    aml: '• AML',
    amlLink: 'https://cdn-web.mtt.xyz/static/AML.pdf',
    selfExclusion: '• Self-exclusion policy',
    selfExclusionLink:
      'https://cdn-web.mtt.xyz/static/Self.Exclusion.Policy.pdf',
    community: 'Community',
    commitment: '• Commitment to Preventing Underage Gambling',
    commitmentLink:
      'https://cdn-web.mtt.xyz/static/Commitment.to.Preventing.Underage.Gambling.pdf',
    kyc: '• KYC Policy-Anjouan',
    kycLink: 'https://cdn-web.mtt.xyz/static/KYC.Policy.Anjouan.pdf',
    tnc: '• Terms and Conditions-Anjouan',
    tncLink: 'https://cdn-web.mtt.xyz/static/Terms.and.Conditions.Anjouan.pdf',
    responsible: '• Responsible Gaming-Anjouan',
    responsibleLink:
      'https://cdn-web.mtt.xyz/static/Responsible.Gaming.Anjouan.pdf',
    phone: 'Registrant Phone: +852.52654226',
    street: 'Registrant Street: 9 Barrack, Belize City,Belize',
    email: 'Registrant Email: officials@mtt.xyz',
    communiteTips: '( Follow us to keep up to date with our latest news )',
    tgLink: 'https://t.me/+cdbWvezq6PljODU1',
    xLink: 'https://x.com/mtt_sports',
    discordLink: 'https://discord.com/invite/fgCUFg43gE',
    support: 'Support',
  },
};
