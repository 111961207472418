// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/userinfo/v1/userinfo.proto" (package "bpt.hall.userinfo.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Void } from "../../../base/base";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Int32Value } from "../../../../google/protobuf/wrappers";
import { SimpleAsset } from "../../../common/common_define";
import { NoticeType } from "../../common/common";
import { Relate } from "../../../social/relation/v1/relation";
import { CompetencyRating } from "../../common/common";
import { AssetItem } from "../../../common/common_define";
import { I18n } from "../../../common/common_lang";
import { MatchListType } from "../../../match/common/v1/common";
import { FieldMask } from "../../../base/base";
import { Gender as Gender$ } from "../../../common/common_define";
import { SwitchState } from "../../../common/common_define";
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchReq
 */
export interface BatchGetUserShowdownSwitchReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UserShowdownSwitch
 */
export interface UserShowdownSwitch {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 2;
     */
    state: SwitchState;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchResp
 */
export interface BatchGetUserShowdownSwitchResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserShowdownSwitch userShowdownSwitchs = 1;
     */
    userShowdownSwitchs: UserShowdownSwitch[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserVoiceConfigReq
 */
export interface BatchGetUserVoiceConfigReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UserVoiceConfig
 */
export interface UserVoiceConfig {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 voiceId = 2;
     */
    voiceId: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserVoiceConfigResp
 */
export interface BatchGetUserVoiceConfigResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserVoiceConfig userVoiceConfigs = 1;
     */
    userVoiceConfigs: UserVoiceConfig[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserGameConfigResp
 */
export interface GetUserGameConfigResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserGameConfig gameConfig = 1;
     */
    gameConfig?: UserGameConfig;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SaveUserGameConfigReq
 */
export interface SaveUserGameConfigReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserGameConfig gameConfig = 1;
     */
    gameConfig?: UserGameConfig;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginOutReq
 */
export interface LoginOutReq {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginOutResp
 */
export interface LoginOutResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.RefreshTokenReq
 */
export interface RefreshTokenReq {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.RefreshTokenResp
 */
export interface RefreshTokenResp {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: int64 tokenExpiredAt = 2;
     */
    tokenExpiredAt: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GenerateInvitationCodeReq
 */
export interface GenerateInvitationCodeReq {
    /**
     * @generated from protobuf field: int32 quantity = 1;
     */
    quantity: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GenerateInvitationCodeResp
 */
export interface GenerateInvitationCodeResp {
    /**
     * @generated from protobuf field: int64 createTime = 1;
     */
    createTime: number;
    /**
     * @generated from protobuf field: repeated string codes = 2;
     */
    codes: string[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.VerifyInvitationCodeReq
 */
export interface VerifyInvitationCodeReq {
    /**
     * @generated from protobuf field: string inviteCode = 1;
     */
    inviteCode: string;
    /**
     * @generated from protobuf field: string deviceId = 2;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.VerifyInvitationCodeResp
 */
export interface VerifyInvitationCodeResp {
    /**
     * @generated from protobuf field: int64 useTime = 1;
     */
    useTime: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.PageListInvitationCodeReq
 */
export interface PageListInvitationCodeReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.InvitationCode
 */
export interface InvitationCode {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: int64 createdAt = 2;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 usedAt = 3;
     */
    usedAt: number;
    /**
     * @generated from protobuf field: int64 usedAccountID = 4;
     */
    usedAccountID: number;
    /**
     * @generated from protobuf field: int64 ownerAccountID = 5;
     */
    ownerAccountID: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.PageListInvitationCodeResp
 */
export interface PageListInvitationCodeResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.InvitationCode invitationCodeList = 1;
     */
    invitationCodeList: InvitationCode[];
    /**
     * @generated from protobuf field: int64 usedTotal = 3;
     */
    usedTotal: number;
    /**
     * @generated from protobuf field: int64 unusedTotal = 4;
     */
    unusedTotal: number;
}
/**
 * oss sts 临时授权
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.Credentials
 */
export interface Credentials {
    /**
     * @generated from protobuf field: string securityToken = 1;
     */
    securityToken: string;
    /**
     * @generated from protobuf field: string accessKeyID = 2;
     */
    accessKeyID: string;
    /**
     * @generated from protobuf field: string accessKeySecret = 3;
     */
    accessKeySecret: string;
    /**
     * @generated from protobuf field: int64 expire = 4;
     */
    expire: number;
    /**
     * @generated from protobuf field: string region = 5;
     */
    region: string;
    /**
     * @generated from protobuf field: string bucket = 6;
     */
    bucket: string;
    /**
     * @generated from protobuf field: string dir = 7;
     */
    dir: string;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Host host = 8;
     */
    host?: Host;
}
/**
 * oss host
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.Host
 */
export interface Host {
    /**
     * @generated from protobuf field: string protocol = 1;
     */
    protocol: string;
    /**
     * @generated from protobuf field: string URL = 2 [json_name = "URL"];
     */
    URL: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetSTSReq
 */
export interface GetSTSReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.GetSTSReq.Platform platform = 1;
     */
    platform: GetSTSReq_Platform;
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.GetSTSReq.Platform
 */
export enum GetSTSReq_Platform {
    /**
     * @generated from protobuf enum value: PlatformUnknown = 0;
     */
    PlatformUnknown = 0,
    /**
     * @generated from protobuf enum value: PlatformAli = 1;
     */
    PlatformAli = 1,
    /**
     * @generated from protobuf enum value: PlatformHuawei = 4;
     */
    PlatformHuawei = 4
}
/**
 * oss sts 临时授权响应
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetSTSResp
 */
export interface GetSTSResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Platform platform = 1;
     */
    platform: Platform;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Credentials credentials = 2;
     */
    credentials?: Credentials;
    /**
     * @generated from protobuf field: bool isOpenAudit = 3;
     */
    isOpenAudit: boolean;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.VipInfo
 */
export interface VipInfo {
    /**
     * level 0 为非会员
     *
     * @generated from protobuf field: int32 level = 1;
     */
    level: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.Identity
 */
export interface Identity {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Identity.IdentityLevel level = 1;
     */
    level: Identity_IdentityLevel;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.Identity.IdentityType types = 2;
     */
    types: Identity_IdentityType[];
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.Identity.IdentityType
 */
export enum Identity_IdentityType {
    /**
     * 0 无认证, 不会有这个，要占位, 数组为空就是未认证
     *
     * @generated from protobuf enum value: IdentityTypeUnVerify = 0;
     */
    IdentityTypeUnVerify = 0,
    /**
     * 1 Twitter
     *
     * @generated from protobuf enum value: IdentityTypeTwitter = 1;
     */
    IdentityTypeTwitter = 1,
    /**
     * 2 人脸
     *
     * @generated from protobuf enum value: IdentityTypeFace = 2;
     */
    IdentityTypeFace = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.Identity.IdentityLevel
 */
export enum Identity_IdentityLevel {
    /**
     * 0 无认证
     *
     * @generated from protobuf enum value: IdentityLevelUnVerify = 0;
     */
    IdentityLevelUnVerify = 0,
    /**
     * Blue 通过Twitter的社交验证
     *
     * @generated from protobuf enum value: IdentityLevelBlue = 1;
     */
    IdentityLevelBlue = 1,
    /**
     * Green 通过人脸的活体验证
     *
     * @generated from protobuf enum value: IdentityLevelGreen = 2;
     */
    IdentityLevelGreen = 2,
    /**
     * Golden 通过Twitter 和 人脸活体认证
     *
     * @generated from protobuf enum value: IdentityLevelGolden = 3;
     */
    IdentityLevelGolden = 3
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.User
 */
export interface User {
    /**
     * 用户 ID
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 昵称
     *
     * @generated from protobuf field: string nickname = 3;
     */
    nickname: string;
    /**
     * 性别
     *
     * @generated from protobuf field: bpt.common.Gender gender = 4;
     */
    gender: Gender$;
    /**
     * 头像
     *
     * @generated from protobuf field: string avatar = 6;
     */
    avatar: string;
    /**
     * 角色类型
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.RoleType roleType = 9;
     */
    roleType: RoleType;
    /**
     * 国籍/区域
     *
     * @generated from protobuf field: string region = 10;
     */
    region: string;
    /**
     * 新手状态
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.NoviceStatus noviceStatus = 11;
     */
    noviceStatus: NoviceStatus;
    /**
     * 封禁至 -1为永久
     *
     * @generated from protobuf field: int64 bannedUntil = 13;
     */
    bannedUntil: number;
    /**
     * 注册时间
     *
     * @generated from protobuf field: int64 createAt = 14;
     */
    createAt: number;
    /**
     * 更新时间
     *
     * @generated from protobuf field: int64 updateAt = 15;
     */
    updateAt: number;
    /**
     * 注销时间
     *
     * @generated from protobuf field: int64 deactivateAt = 16;
     */
    deactivateAt: number;
    /**
     * 新手状态结束时间
     *
     * @generated from protobuf field: int64 noviceExpireAt = 17;
     */
    noviceExpireAt: number;
    /**
     * 禁言至 -1为永久
     *
     * @generated from protobuf field: int64 mutedUntil = 18;
     */
    mutedUntil: number;
    /**
     * 个性签名
     *
     * @generated from protobuf field: string signature = 20;
     */
    signature: string;
    /**
     * 弃用,旧版游戏配置
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.GameConfig gameConfig = 21;
     */
    gameConfig?: GameConfig;
    /**
     * @generated from protobuf field: string pubId = 22;
     */
    pubId: string;
    /**
     * @generated from protobuf field: int64 accountId = 23;
     */
    accountId: number;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PassportList passportInfo = 24;
     */
    passportInfo?: PassportList;
    /**
     * @generated from protobuf field: string avatarBlurHash = 25;
     */
    avatarBlurHash: string; // 毛头像
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.VipInfo vipInfo = 26;
     */
    vipInfo?: VipInfo; // vip信息
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Tag tag = 27;
     */
    tag?: Tag;
    /**
     * 新版游戏配置
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserGameConfig userGameConfig = 28;
     */
    userGameConfig?: UserGameConfig;
    /**
     * 身份标识
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.Identity identity = 29;
     */
    identity?: Identity;
    /**
     * 昵称修改次数
     *
     * @generated from protobuf field: uint32 nickUpnum = 30;
     */
    nickUpnum: number;
    /**
     * 昵称修改时间
     *
     * @generated from protobuf field: int64 ninckUpdateAt = 31;
     */
    ninckUpdateAt: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.PassportList
 */
export interface PassportList {
    /**
     * @generated from protobuf field: int32 type = 1;
     */
    type: number;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
    /**
     * @generated from protobuf field: string relateID = 3;
     */
    relateID: string;
}
/**
 * 用户游戏配置
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UserGameConfig
 */
export interface UserGameConfig {
    /**
     * 全局游戏配置
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.GlobalGameConfig gameConfig = 1;
     */
    gameConfig?: GlobalGameConfig;
    /**
     * 牌桌主题配置
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.TableThemeConfig tableThemeConfig = 2;
     */
    tableThemeConfig?: TableThemeConfig;
    /**
     * 练习场配置
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.PracticeConfig practiceConfig = 3;
     */
    practiceConfig?: PracticeConfig;
}
/**
 * 旧版
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GameConfig
 */
export interface GameConfig {
    /**
     * 自动买入状态
     *
     * @generated from protobuf field: bpt.common.SwitchState autoBuyInState = 1;
     */
    autoBuyInState: SwitchState;
    /**
     * 等待大盲状态
     *
     * @generated from protobuf field: bpt.common.SwitchState waitBigBlindState = 2;
     */
    waitBigBlindState: SwitchState;
    /**
     * 多桌列表开启状态
     *
     * @generated from protobuf field: bpt.common.SwitchState tableViewListState = 3;
     */
    tableViewListState: SwitchState;
    /**
     * 语音资源ID,为0默认
     *
     * @generated from protobuf field: int64 voiceId = 4;
     */
    voiceId: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GlobalGameConfig
 */
export interface GlobalGameConfig {
    /**
     * 多桌列表开启状态
     *
     * @generated from protobuf field: bpt.common.SwitchState tableViewListState = 1;
     */
    tableViewListState: SwitchState;
    /**
     * 礼物特效开关
     *
     * @generated from protobuf field: bpt.common.SwitchState giftEffectState = 2;
     */
    giftEffectState: SwitchState;
    /**
     * 互动道具特效开关
     *
     * @generated from protobuf field: bpt.common.SwitchState interactivePropsEffectState = 3;
     */
    interactivePropsEffectState: SwitchState;
    /**
     * 筹码展示模式切换开关
     *
     * @generated from protobuf field: bpt.common.SwitchState chipDisplayModeState = 4;
     */
    chipDisplayModeState: SwitchState;
    /**
     * 围观聊天开关
     *
     * @generated from protobuf field: bpt.common.SwitchState spectatorsChatState = 5;
     */
    spectatorsChatState: SwitchState;
    /**
     * 震动提示开关
     *
     * @generated from protobuf field: bpt.common.SwitchState vibrationState = 6;
     */
    vibrationState: SwitchState;
    /**
     * 背景音乐开关
     *
     * @generated from protobuf field: bpt.common.SwitchState bgmState = 7;
     */
    bgmState: SwitchState;
    /**
     * 健康监控开关(Fps,延迟等指标)
     *
     * @generated from protobuf field: bpt.common.SwitchState healthMonitorState = 8;
     */
    healthMonitorState: SwitchState;
    /**
     * 音效开关
     *
     * @generated from protobuf field: bpt.common.SwitchState soundEffectState = 9;
     */
    soundEffectState: SwitchState;
    /**
     * 语音包音效开关
     *
     * @generated from protobuf field: bpt.common.SwitchState voiceEffectState = 10;
     */
    voiceEffectState: SwitchState;
    /**
     * 结算亮牌开关(关了不亮牌)
     *
     * @generated from protobuf field: bpt.common.SwitchState showdownShowCardState = 11;
     */
    showdownShowCardState: SwitchState;
    /**
     * 默认语音包
     *
     * @generated from protobuf field: bpt.common.SwitchState defaultVoiceState = 12;
     */
    defaultVoiceState: SwitchState;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.TableThemeConfig
 */
export interface TableThemeConfig {
    /**
     * 语音资源ID,为0默认
     *
     * @generated from protobuf field: int64 voiceId = 1;
     */
    voiceId: number;
    /**
     * 卡片资源ID,为0默认
     *
     * @generated from protobuf field: int64 cardId = 2;
     */
    cardId: number;
    /**
     * 牌桌背景资源ID,为0默认
     *
     * @generated from protobuf field: int64 tableBgId = 3;
     */
    tableBgId: number;
    /**
     * 按钮主题资源ID,为0默认
     *
     * @generated from protobuf field: int64 buttonId = 4;
     */
    buttonId: number;
    /**
     * 卡片背面资源ID,为0默认
     *
     * @generated from protobuf field: int64 cardBackId = 5;
     */
    cardBackId: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.PracticeConfig
 */
export interface PracticeConfig {
    /**
     * 自动买入状态
     *
     * @generated from protobuf field: bpt.common.SwitchState autoBuyInState = 1;
     */
    autoBuyInState: SwitchState;
    /**
     * 等待大盲状态
     *
     * @generated from protobuf field: bpt.common.SwitchState waitBigBlindState = 2;
     */
    waitBigBlindState: SwitchState;
}
// message IdCardInfo {
//   int64             uid      = 1;  // 用户 ID
//   string            idNo     = 2;  // 身份证号
//   string            name     = 3;  // 真实姓名
//   IdCardStatus      status   = 4;  // 身份证认证状态
//   string            pi       = 5;  // 中宣部生成的身份识别码
//   bpt.common.Gender gender   = 6;  // 性别
//   int64             birthday = 7;  // 生日
// 
//   int64 updateAt = 15;  // 更新时间
// }

/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UserStyleStat
 */
export interface UserStyleStat {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 下列Rate命名的整型百分比保留三位，即1000代表1%
     * 激进度(AF)
     *
     * @generated from protobuf field: int64 AFRate = 2 [json_name = "AFRate"];
     */
    AFRate: number;
    /**
     * 入池率（VPIP）
     *
     * @generated from protobuf field: int64 VPIPRate = 3 [json_name = "VPIPRate"];
     */
    VPIPRate: number;
    /**
     * 翻牌前加注率(PFR)
     *
     * @generated from protobuf field: int64 PFRRate = 4 [json_name = "PFRRate"];
     */
    PFRRate: number;
    /**
     * 再加注率(3BET)
     *
     * @generated from protobuf field: int64 ThreeBETRate = 5 [json_name = "ThreeBETRate"];
     */
    ThreeBETRate: number;
    /**
     * 翻牌前/flop/turn/river弃牌率
     *
     * @generated from protobuf field: int64 PreFlopFoldRate = 6 [json_name = "PreFlopFoldRate"];
     */
    PreFlopFoldRate: number;
    /**
     * @generated from protobuf field: int64 FlopFoldRate = 7 [json_name = "FlopFoldRate"];
     */
    FlopFoldRate: number;
    /**
     * @generated from protobuf field: int64 TurnFoldRate = 8 [json_name = "TurnFoldRate"];
     */
    TurnFoldRate: number;
    /**
     * @generated from protobuf field: int64 RiverFoldRate = 9 [json_name = "RiverFoldRate"];
     */
    RiverFoldRate: number;
    /**
     *  进入奖圈概率
     *  int64 InTheMoneyRate = 10;
     * 进入奖圈后没有拿到特殊名次的次数
     *
     * @generated from protobuf field: int64 PrizePoolCnt = 11 [json_name = "PrizePoolCnt"];
     */
    PrizePoolCnt: number;
    /**
     * 进入奖圈后得到第三名的次数
     *
     * @generated from protobuf field: int64 ThirdPlaceCnt = 12 [json_name = "ThirdPlaceCnt"];
     */
    ThirdPlaceCnt: number;
    /**
     * 进入奖圈后得到第二名的次数
     *
     * @generated from protobuf field: int64 SecondPlaceCnt = 13 [json_name = "SecondPlaceCnt"];
     */
    SecondPlaceCnt: number;
    /**
     * 进入奖圈后得到第一名的次数
     *
     * @generated from protobuf field: int64 FirstPlaceCnt = 14 [json_name = "FirstPlaceCnt"];
     */
    FirstPlaceCnt: number;
    /**
     * 最佳手牌
     *
     * @generated from protobuf field: repeated uint32 BestHand = 15 [json_name = "BestHand"];
     */
    BestHand: number[];
    /**
     * 进入奖圈场次
     *
     * @generated from protobuf field: int64 InTheMoneyCnt = 16 [json_name = "InTheMoneyCnt"];
     */
    InTheMoneyCnt: number;
    /**
     * 参加比赛总场次
     *
     * @generated from protobuf field: int64 JoinMatchCnt = 17 [json_name = "JoinMatchCnt"];
     */
    JoinMatchCnt: number;
    /**
     * @deprecated赢得奖金总额(MTT,精确到整数位)
     *
     * @generated from protobuf field: int64 TotalPrizeMTT = 18 [json_name = "TotalPrizeMTT"];
     */
    TotalPrizeMTT: number;
    /**
     * 进入ft的场次
     *
     * @generated from protobuf field: int64 EnterFinalTableCnt = 19 [json_name = "EnterFinalTableCnt"];
     */
    EnterFinalTableCnt: number;
    /**
     * 手牌数
     *
     * @generated from protobuf field: int64 HoleCardsCnt = 20 [json_name = "HoleCardsCnt"];
     */
    HoleCardsCnt: number;
    /**
     * 赢得奖金总额(MTT,精确到整数位)
     *
     * @generated from protobuf field: string PrizeMTTCnt = 21 [json_name = "PrizeMTTCnt"];
     */
    PrizeMTTCnt: string;
    /**
     * 获得点赞数
     *
     * @generated from protobuf field: int64 receivedLikeCnt = 22;
     */
    receivedLikeCnt: number;
}
/**
 * ** GetUserStyleStat **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserStyleStatReq
 */
export interface GetUserStyleStatReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserStyleStatResp
 */
export interface GetUserStyleStatResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserStyleStat stat = 1;
     */
    stat?: UserStyleStat;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserStyleStatReq
 */
export interface BatchGetUserStyleStatReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserStyleStatResp
 */
export interface BatchGetUserStyleStatResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserStyleStat statList = 1;
     */
    statList: UserStyleStat[];
}
/**
 * 分页请求
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.PageReq
 */
export interface PageReq {
    /**
     * @generated from protobuf field: int64 index = 1;
     */
    index: number; // 页码
    /**
     * @generated from protobuf field: int64 size = 2;
     */
    size: number; // 页大小
}
/**
 * 分页响应
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.PageResp
 */
export interface PageResp {
    /**
     * @generated from protobuf field: int64 index = 1;
     */
    index: number; // 页码
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number; // 总数
}
/**
 * ** CreateUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.RegistryExtInfo
 */
export interface RegistryExtInfo {
    /**
     * @generated from protobuf field: string guid = 1;
     */
    guid: string; // 设备唯一标识
    /**
     * DeviceType	deviceType	    = 2 [(gogoproto.nullable) = false]; //
     * 设备类型
     *
     * @generated from protobuf field: string deviceMode = 3;
     */
    deviceMode: string; // 客户品牌型号
    /**
     * @generated from protobuf field: int64 clientTime = 4;
     */
    clientTime: number; // 客户端时间
    /**
     * @generated from protobuf field: string clientVersion = 5;
     */
    clientVersion: string; // 客户端版本
    /**
     * @generated from protobuf field: float latitude = 6;
     */
    latitude: number; // 纬度
    /**
     * @generated from protobuf field: float longitude = 7;
     */
    longitude: number; // 经度
    /**
     * @generated from protobuf field: string language = 8;
     */
    language: string; // 使用语言
    /**
     * @generated from protobuf field: string os = 9;
     */
    os: string; // 操作系统
    /**
     * @generated from protobuf field: string channel = 10;
     */
    channel: string; // 登录渠道信息
}
/**
 * ** CreateUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.CreateUserReq
 */
export interface CreateUserReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.RegistryExtInfo extInfo = 2;
     */
    extInfo?: RegistryExtInfo;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.CreateUserResp
 */
export interface CreateUserResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.User user = 1;
     */
    user?: User;
}
/**
 * **  GetOrCreateUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.InitUserReq
 */
export interface InitUserReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.InitUserResp
 */
export interface InitUserResp {
}
/**
 * ** GetUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserReq
 */
export interface GetUserReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserResp
 */
export interface GetUserResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.User user = 1;
     */
    user?: User;
}
/**
 * ** BatchGetUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserReq
 */
export interface BatchGetUserReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetUserResp
 */
export interface BatchGetUserResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.User users = 1;
     */
    users: User[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetSimpleUserReq
 */
export interface BatchGetSimpleUserReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetSimpleUserResp
 */
export interface BatchGetSimpleUserResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.SimpleUser users = 1;
     */
    users: SimpleUser[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SimpleUser
 */
export interface SimpleUser {
    /**
     * 用户 ID
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 昵称
     *
     * @generated from protobuf field: string nickname = 2;
     */
    nickname: string;
    /**
     * 性别
     *
     * @generated from protobuf field: bpt.common.Gender gender = 3;
     */
    gender: Gender$;
    /**
     * 头像
     *
     * @generated from protobuf field: string avatar = 4;
     */
    avatar: string;
    /**
     * 个性签名
     *
     * @generated from protobuf field: string signature = 5;
     */
    signature: string;
    /**
     * @generated from protobuf field: string pubId = 6;
     */
    pubId: string;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.VipInfo vipInfo = 7;
     */
    vipInfo?: VipInfo;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Tag tag = 8;
     */
    tag?: Tag;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SearchSimpleUser
 */
export interface SearchSimpleUser {
    /**
     * 用户 ID
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 昵称
     *
     * @generated from protobuf field: string nickname = 2;
     */
    nickname: string;
    /**
     * 性别
     *
     * @generated from protobuf field: bpt.common.Gender gender = 3;
     */
    gender: Gender$;
    /**
     * 头像
     *
     * @generated from protobuf field: string avatar = 4;
     */
    avatar: string;
    /**
     * 个性签名
     *
     * @generated from protobuf field: string signature = 5;
     */
    signature: string;
    /**
     * @generated from protobuf field: string pubId = 6;
     */
    pubId: string;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.VipInfo vipInfo = 7;
     */
    vipInfo?: VipInfo;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Tag tag = 8;
     */
    tag?: Tag;
    /**
     * 钱包地址
     *
     * @generated from protobuf field: string address = 9;
     */
    address: string;
    /**
     * 转账次数
     *
     * @generated from protobuf field: int32 transTimes = 10;
     */
    transTimes: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SearchUsersReq
 */
export interface SearchUsersReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageReq page = 1;
     */
    page?: PageReq;
    /**
     * @generated from protobuf field: string nickname = 2;
     */
    nickname: string; // 昵称
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.GenderFilter gender = 3;
     */
    gender: GenderFilter;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.IdCardStatusFilter idCardStatus = 4;
     */
    idCardStatus: IdCardStatusFilter;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.RoleTypeFilter roleType = 5;
     */
    roleType: RoleTypeFilter;
    /**
     * @generated from protobuf field: int64 uid = 6;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 phoneNumber = 7;
     */
    phoneNumber: number;
    /**
     * @generated from protobuf field: int64 createAfter = 14;
     */
    createAfter: number;
    /**
     * @generated from protobuf field: int64 createBefore = 15;
     */
    createBefore: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SearchUsersResp
 */
export interface SearchUsersResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageResp page = 1;
     */
    page?: PageResp;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.User users = 2;
     */
    users: User[];
}
/**
 * **  FuzzySearchUsers **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.FuzzySearchUsersReq
 */
export interface FuzzySearchUsersReq {
    /**
     * page size 最大30
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageReq page = 1;
     */
    page?: PageReq;
    /**
     * 关键字。 pub_id
     *
     * @generated from protobuf field: string keyword = 2;
     */
    keyword: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.FuzzySearchUsersResp
 */
export interface FuzzySearchUsersResp {
    /**
     * PageResp      page  = 1 [(gogoproto.nullable) = false];
     *
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.SimpleUser users = 2;
     */
    users: SimpleUser[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.FuzzySearchClubUsersReq
 */
export interface FuzzySearchClubUsersReq {
    /**
     * @generated from protobuf field: uint32 clubId = 1;
     */
    clubId: number;
    /**
     * page size 最大30
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageReq page = 2;
     */
    page?: PageReq;
    /**
     * 关键字。 pub_id
     *
     * @generated from protobuf field: string keyword = 3;
     */
    keyword: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.FuzzySearchClubUsersResp
 */
export interface FuzzySearchClubUsersResp {
    /**
     * PageResp      page  = 1 [(gogoproto.nullable) = false];
     *
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.SimpleUser users = 2;
     */
    users: SimpleUser[];
}
/**
 * **  WalletSearchUsers **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.WalletSearchUsersReq
 */
export interface WalletSearchUsersReq {
    /**
     * page size 最大30
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageReq page = 1;
     */
    page?: PageReq;
    /**
     * 关键字。 pub_id
     *
     * @generated from protobuf field: string keyword = 2;
     */
    keyword: string;
    /**
     * 是否需要钱包信息
     *
     * @generated from protobuf field: bool isWallet = 3;
     */
    isWallet: boolean;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.WalletSearchUsersResp
 */
export interface WalletSearchUsersResp {
    /**
     * PageResp      page  = 1 [(gogoproto.nullable) = false];
     *
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.SearchSimpleUser users = 2;
     */
    users: SearchSimpleUser[];
}
/**
 * **  VerifyIdCard **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.VerifyIdCardReq
 */
export interface VerifyIdCardReq {
    /**
     * int64	uid	    = 1 ; // 用户 uid, 需要和 ctx 中的 uid 匹配
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string; // 姓名
    /**
     * @generated from protobuf field: string idNo = 3;
     */
    idNo: string; // 身份证号
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.VerifyIdCardResp
 */
export interface VerifyIdCardResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.IdCardVerifyResult code = 1;
     */
    code: IdCardVerifyResult;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.IdCardStatus status = 2;
     */
    status: IdCardStatus;
    /**
     * @generated from protobuf field: int32 retryLeft = 3;
     */
    retryLeft: number; // TODO 剩余重试次数
}
/**
 * **  GetUserIdCardInfo **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserIdCardInfoReq
 */
export interface GetUserIdCardInfoReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // 用户 uid
}
// message GetUserIdCardInfoResp {
//   IdCardInfo info = 1 [(gogoproto.nullable) = false];
// }

/**
 * **  GetUserIdCardInfosByIdNo **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserIdCardInfosByIdNoReq
 */
export interface GetUserIdCardInfosByIdNoReq {
    /**
     * @generated from protobuf field: string idNo = 1;
     */
    idNo: string;
}
/**
 * **  RemoveUserIdCardInfo **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardInfoReq
 */
export interface RemoveUserIdCardInfoReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardInfoResp
 */
export interface RemoveUserIdCardInfoResp {
}
/**
 * **  RemoveUserIdCardRetryLimit **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardRetryLimitReq
 */
export interface RemoveUserIdCardRetryLimitReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardRetryLimitResp
 */
export interface RemoveUserIdCardRetryLimitResp {
}
/**
 * **  UpdateUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserReq
 */
export interface UpdateUserReq {
    /**
     * 昵称
     *
     * @generated from protobuf field: string nickname = 1;
     */
    nickname: string;
    /**
     * 性别
     *
     * @generated from protobuf field: bpt.common.Gender gender = 2;
     */
    gender: Gender$;
    /**
     * 头像
     *
     * @generated from protobuf field: string avatar = 3;
     */
    avatar: string;
    /**
     * 个性签名
     *
     * @generated from protobuf field: string signature = 4;
     */
    signature: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserResp
 */
export interface UpdateUserResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserUpdateResult code = 1;
     */
    code: UserUpdateResult;
}
/**
 * **  UpdateUserBySelf **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserBySelfReq
 */
export interface UpdateUserBySelfReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: bpt.base.FieldMask fieldMask = 2;
     */
    fieldMask?: FieldMask; // 不能更新 uid, roleType 以及 registryAt updateAt
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserBySelfResp
 */
export interface UpdateUserBySelfResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserUpdateResult code = 1;
     */
    code: UserUpdateResult;
}
/**
 * **  GetNicknameUpdatePermission **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetNicknameUpdatePermissionReq
 */
export interface GetNicknameUpdatePermissionReq {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetNicknameUpdatePermissionResp
 */
export interface GetNicknameUpdatePermissionResp {
    /**
     * 是否可以免费更新昵称
     *
     * @generated from protobuf field: bool freeUpdateNickname = 1;
     */
    freeUpdateNickname: boolean;
}
/**
 * **  GetRandUid **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetRandUidReq
 */
export interface GetRandUidReq {
    /**
     * @generated from protobuf field: int64 num = 1;
     */
    num: number;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.RoleTypeFilter roleType = 2;
     */
    roleType: RoleTypeFilter;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetRandUidResp
 */
export interface GetRandUidResp {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
}
/**
 * **  CountUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.CountUserReq
 */
export interface CountUserReq {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.CountUserResp
 */
export interface CountUserResp {
    /**
     * @generated from protobuf field: int64 count = 1;
     */
    count: number;
}
/**
 * **  UploadCustomAvatar **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UploadCustomAvatarReq
 */
export interface UploadCustomAvatarReq {
    /**
     * 自定义头像地址，传空则删除
     *
     * @generated from protobuf field: string customAvatarURL = 1;
     */
    customAvatarURL: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UploadCustomAvatarResp
 */
export interface UploadCustomAvatarResp {
}
/**
 * **  GetCustomAvatar **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetCustomAvatarReq
 */
export interface GetCustomAvatarReq {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetCustomAvatarResp
 */
export interface GetCustomAvatarResp {
    /**
     * @generated from protobuf field: string customAvatarURL = 1;
     */
    customAvatarURL: string;
}
// **  BanUser **

/**
 * 封号邮件
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.BanUserMail
 */
export interface BanUserMail {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BanUserReq
 */
export interface BanUserReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[]; // 用户id数组
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.BanReason reason = 2;
     */
    reason: BanReason;
    /**
     * @generated from protobuf field: int64 banTimeSec = 3;
     */
    banTimeSec: number; // 封禁时长(秒) -1 为永久
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string; // 备注  封号描述
    /**
     * @generated from protobuf field: repeated string evidenceURLs = 6;
     */
    evidenceURLs: string[]; // 证据附件链接 最多五个
    /**
     * @generated from protobuf field: string manipulator = 7;
     */
    manipulator: string; // 操作人
    /**
     * @generated from protobuf field: bool forceOffline = 8;
     */
    forceOffline: boolean; // 是否立即踢下线
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.BanUserMail mail = 9;
     */
    mail?: BanUserMail; // 封号邮件
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BanUserResp
 */
export interface BanUserResp {
}
/**
 * **  UnbanUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UnbanUserReq
 */
export interface UnbanUserReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[]; // 用户id数组
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UnbanReason reason = 3;
     */
    reason: UnbanReason; // 解封原因
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string; // 备注  解封描述
    /**
     * @generated from protobuf field: string manipulator = 5;
     */
    manipulator: string; // 操作人
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UnbanUserResp
 */
export interface UnbanUserResp {
}
/**
 * UserBanLog 封禁日志
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UserBanLog
 */
export interface UserBanLog {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number; // 日志id
    /**
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[]; // 用户id
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.BanStatus banStatus = 4;
     */
    banStatus: BanStatus; // 操作类型
    /**
     * @generated from protobuf field: int64 banTimeSec = 5;
     */
    banTimeSec: number; // 封禁时长(秒)  -1 为永久
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.BanReason lockReason = 6;
     */
    lockReason: BanReason; // 封号原因
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UnbanReason unlockReason = 7;
     */
    unlockReason: UnbanReason; // 解封原因
    /**
     * @generated from protobuf field: string description = 8;
     */
    description: string; // 备注  描述
    /**
     * @generated from protobuf field: repeated string evidenceURLs = 9;
     */
    evidenceURLs: string[]; // 证据附件链接 最多五个
    /**
     * @generated from protobuf field: string manipulator = 10;
     */
    manipulator: string; // 操作人
    /**
     * @generated from protobuf field: int64 updateAt = 11;
     */
    updateAt: number; // 操作时间
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.BanUserMail mail = 12;
     */
    mail?: BanUserMail; // 邮件
}
/**
 * **  BanLogs **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetBanLogsReq
 */
export interface GetBanLogsReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageReq page = 1;
     */
    page?: PageReq;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number; // 根据uid筛选
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetBanLogsResp
 */
export interface GetBanLogsResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageResp page = 1;
     */
    page?: PageResp; // 分页信息
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserBanLog logs = 2;
     */
    logs: UserBanLog[];
}
/**
 * **  GetUserBanInfo **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserBanInfoReq
 */
export interface GetUserBanInfoReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserBanInfoResp
 */
export interface GetUserBanInfoResp {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 原因
     *
     * @generated from protobuf field: string reason = 2;
     */
    reason: string; // deprecated
    /**
     * 起始时间
     *
     * @generated from protobuf field: int64 startAt = 3;
     */
    startAt: number;
    /**
     * 封号结束UNIX时间 -1 永久
     *
     * @generated from protobuf field: int64 endAt = 4;
     */
    endAt: number;
    /**
     * 封号原因
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.BanReason banReason = 5;
     */
    banReason: BanReason;
    /**
     * 备注  描述
     *
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ShippingAddr
 */
export interface ShippingAddr {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
    /**
     * @generated from protobuf field: int64 UID = 2 [json_name = "UID"];
     */
    UID: number;
    /**
     * @generated from protobuf field: string receiver = 3;
     */
    receiver: string; // 收件人信息
    /**
     * @generated from protobuf field: string contactNumber = 4;
     */
    contactNumber: string; // 收件人联系方式
    /**
     * @generated from protobuf field: string province = 5;
     */
    province: string; // 省份
    /**
     * @generated from protobuf field: string city = 6;
     */
    city: string; // 城市
    /**
     * @generated from protobuf field: string region = 7;
     */
    region: string; // 区域
    /**
     * @generated from protobuf field: string addr = 8;
     */
    addr: string; // 详细地址
    /**
     * @generated from protobuf field: int32 default = 9;
     */
    default: number; // 0 不是默认，1默认
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetShippingAddrReq
 */
export interface GetShippingAddrReq {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string; // 			int64 			UID = 2;
    // //ID 为空，UID不为零时返回玩家默认地址
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetShippingAddrResp
 */
export interface GetShippingAddrResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.ShippingAddr info = 2;
     */
    info?: ShippingAddr;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListShippingAddrReq
 */
export interface ListShippingAddrReq {
    /**
     * 		int64 			UID 			= 1;
     *
     * @generated from protobuf field: int32 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListShippingAddrResp
 */
export interface ListShippingAddrResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.ShippingAddr infos = 2;
     */
    infos: ShippingAddr[];
    /**
     * @generated from protobuf field: int32 total = 3;
     */
    total: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.CreateShippingAddrReq
 */
export interface CreateShippingAddrReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.ShippingAddr info = 1;
     */
    info?: ShippingAddr;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.CreateShippingAddrResp
 */
export interface CreateShippingAddrResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.ShippingAddr info = 2;
     */
    info?: ShippingAddr;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateShippingAddrReq
 */
export interface UpdateShippingAddrReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.ShippingAddr info = 1;
     */
    info?: ShippingAddr;
    /**
     * @generated from protobuf field: bpt.base.FieldMask updateMask = 2;
     */
    updateMask?: FieldMask;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateShippingAddrResp
 */
export interface UpdateShippingAddrResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.ShippingAddr info = 2;
     */
    info?: ShippingAddr;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeleteShippingAddrReq
 */
export interface DeleteShippingAddrReq {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeleteShippingAddrResp
 */
export interface DeleteShippingAddrResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetShippingAddrByUIDReq
 */
export interface GetShippingAddrByUIDReq {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
    /**
     * @generated from protobuf field: int64 UID = 2 [json_name = "UID"];
     */
    UID: number; // ID  为空，UID不为零时返回玩家默认地址
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetShippingAddrByUIDResp
 */
export interface GetShippingAddrByUIDResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.ShippingAddr info = 2;
     */
    info?: ShippingAddr;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListShippingAddrByUIDReq
 */
export interface ListShippingAddrByUIDReq {
    /**
     * @generated from protobuf field: int64 UID = 1 [json_name = "UID"];
     */
    UID: number;
    /**
     * @generated from protobuf field: int32 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListShippingAddrByUIDResp
 */
export interface ListShippingAddrByUIDResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.ShippingAddr infos = 2;
     */
    infos: ShippingAddr[];
    /**
     * @generated from protobuf field: int32 total = 3;
     */
    total: number;
}
// **  DeactivateUser **

/**
 * int64  uid     = 1;  从 ctx 取
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.DeactivateUserReq
 */
export interface DeactivateUserReq {
    /**
     * 姓名
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * 身份证号
     *
     * @generated from protobuf field: string idNo = 3;
     */
    idNo: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeactivateUserResp
 */
export interface DeactivateUserResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Code code = 1;
     */
    code: Code;
}
/**
 * **  DeactivateUserByAdmin **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.DeactivateUserByAdminReq
 */
export interface DeactivateUserByAdminReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string operator = 2;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeactivateUserByAdminResp
 */
export interface DeactivateUserByAdminResp {
}
/**
 * **  GetLatestUserDeactivateLog **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.PassportRecord
 */
export interface PassportRecord {
    /**
     * @generated from protobuf field: string phone = 1;
     */
    phone: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeactivateLog
 */
export interface DeactivateLog {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.User user = 2;
     */
    user?: User;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PassportRecord passport = 3;
     */
    passport?: PassportRecord;
    /**
     * @generated from protobuf field: string operator = 4;
     */
    operator: string;
    /**
     * @generated from protobuf field: int64 deactivateAt = 5;
     */
    deactivateAt: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetLatestUserDeactivateLogReq
 */
export interface GetLatestUserDeactivateLogReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetLatestUserDeactivateLogResp
 */
export interface GetLatestUserDeactivateLogResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.DeactivateStatus status = 2;
     */
    status: DeactivateStatus;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.DeactivateLog log = 3;
     */
    log?: DeactivateLog;
}
/**
 * **  ResetNickname **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.ResetNicknameReq
 */
export interface ResetNicknameReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string operator = 15;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ResetNicknameResp
 */
export interface ResetNicknameResp {
}
/**
 * **  ResetAvatar **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.ResetAvatarReq
 */
export interface ResetAvatarReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string operator = 15;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ResetAvatarResp
 */
export interface ResetAvatarResp {
}
/**
 * **  ResetNicknameBySensitiveTask **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.ResetNicknameBySensitiveTaskReq
 */
export interface ResetNicknameBySensitiveTaskReq {
    /**
     * 是否重置
     *
     * @generated from protobuf field: bool resetNickname = 1;
     */
    resetNickname: boolean;
    /**
     * uid
     *
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * 要重置的昵称
     *
     * @generated from protobuf field: string nickname = 3;
     */
    nickname: string;
    /**
     * 命中的敏感词
     *
     * @generated from protobuf field: string sensitiveWord = 4;
     */
    sensitiveWord: string;
    /**
     * 敏感词库版本
     *
     * @generated from protobuf field: string sensitiveVersion = 14;
     */
    sensitiveVersion: string;
    /**
     * 重置检测触发来源
     *
     * @generated from protobuf field: string source = 15;
     */
    source: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ResetNicknameBySensitiveTaskResp
 */
export interface ResetNicknameBySensitiveTaskResp {
}
/**
 * **  GetUserInfoConfig **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.AvatarConfig
 */
export interface AvatarConfig {
    /**
     * @generated from protobuf field: string defaultAvatar = 1;
     */
    defaultAvatar: string;
    /**
     * @generated from protobuf field: repeated string avatarList = 2;
     */
    avatarList: string[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UserInfoConfig
 */
export interface UserInfoConfig {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.AvatarConfig avatar = 2;
     */
    avatar?: AvatarConfig;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserInfoConfigReq
 */
export interface GetUserInfoConfigReq {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserInfoConfigResp
 */
export interface GetUserInfoConfigResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserInfoConfig config = 2;
     */
    config?: UserInfoConfig;
}
// **  UpdateUserInfoConfig **

/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserInfoConfigReq
 */
export interface UpdateUserInfoConfigReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UserInfoConfig config = 1;
     */
    config?: UserInfoConfig;
    /**
     * @generated from protobuf field: string operator = 15;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserInfoConfigResp
 */
export interface UpdateUserInfoConfigResp {
}
/**
 * **  ValidateNickname **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.ValidateNicknameReq
 */
export interface ValidateNicknameReq {
    /**
     * @generated from protobuf field: string nickname = 1;
     */
    nickname: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ValidateNicknameResp
 */
export interface ValidateNicknameResp {
}
// **  MuteUser **

/**
 * 禁言邮件
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.MuteUserMail
 */
export interface MuteUserMail {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.MuteUserReq
 */
export interface MuteUserReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[]; // 用户id数组
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.MuteReason reason = 2;
     */
    reason: MuteReason;
    /**
     * @generated from protobuf field: int64 muteTimeSec = 3;
     */
    muteTimeSec: number; // 禁言时长(秒) -1 为永久
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string; // 备注  禁言描述
    /**
     * @generated from protobuf field: repeated string evidenceURLs = 6;
     */
    evidenceURLs: string[]; // 证据附件链接 最多五个
    /**
     * @generated from protobuf field: string manipulator = 7;
     */
    manipulator: string; // 操作人
    /**
     * @generated from protobuf field: bool forceOffline = 8;
     */
    forceOffline: boolean; // 是否立即踢下线
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.MuteUserMail mail = 9;
     */
    mail?: MuteUserMail; // 禁言邮件
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.MuteUserResp
 */
export interface MuteUserResp {
}
/**
 * **  UnmuteUser **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UnmuteUserReq
 */
export interface UnmuteUserReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[]; // 用户id数组
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UnmuteReason reason = 3;
     */
    reason: UnmuteReason; // 解封原因
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string; // 备注  解封描述
    /**
     * @generated from protobuf field: string manipulator = 5;
     */
    manipulator: string; // 操作人
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UnmuteUserResp
 */
export interface UnmuteUserResp {
}
/**
 * UserMuteLog 禁言日志
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UserMuteLog
 */
export interface UserMuteLog {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number; // 日志id
    /**
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[]; // 用户id
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.MuteStatus muteStatus = 4;
     */
    muteStatus: MuteStatus; // 操作类型
    /**
     * @generated from protobuf field: int64 muteTimeSec = 5;
     */
    muteTimeSec: number; // 禁言时长(秒)  -1 为永久
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.MuteReason muteReason = 6;
     */
    muteReason: MuteReason; // 禁言原因
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UnmuteReason unmuteReason = 7;
     */
    unmuteReason: UnmuteReason; // 解封原因
    /**
     * @generated from protobuf field: string description = 8;
     */
    description: string; // 备注  描述
    /**
     * @generated from protobuf field: repeated string evidenceURLs = 9;
     */
    evidenceURLs: string[]; // 证据附件链接 最多五个
    /**
     * @generated from protobuf field: string manipulator = 10;
     */
    manipulator: string; // 操作人
    /**
     * @generated from protobuf field: int64 updateAt = 11;
     */
    updateAt: number; // 操作时间
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.MuteUserMail mail = 12;
     */
    mail?: MuteUserMail; // 邮件
}
/**
 * **  MuteLogs **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetMuteLogsReq
 */
export interface GetMuteLogsReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageReq page = 1;
     */
    page?: PageReq;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number; // 根据uid筛选
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetMuteLogsResp
 */
export interface GetMuteLogsResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageResp page = 1;
     */
    page?: PageResp; // 分页信息
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserMuteLog logs = 2;
     */
    logs: UserMuteLog[];
}
/**
 * **  GetUserMuteInfo **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserMuteInfoReq
 */
export interface GetUserMuteInfoReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserMuteInfoResp
 */
export interface GetUserMuteInfoResp {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 原因
     *
     * @generated from protobuf field: string reason = 2;
     */
    reason: string; // deprecated
    /**
     * 起始时间
     *
     * @generated from protobuf field: int64 startAt = 3;
     */
    startAt: number;
    /**
     * 禁言结束UNIX时间 -1 永久
     *
     * @generated from protobuf field: int64 endAt = 4;
     */
    endAt: number;
    /**
     * 禁言原因
     *
     * @generated from protobuf field: bpt.hall.userinfo.v1.MuteReason muteReason = 5;
     */
    muteReason: MuteReason;
    /**
     * 备注  描述
     *
     * @generated from protobuf field: string description = 6;
     */
    description: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.NicknameItem
 */
export interface NicknameItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bpt.common.Gender gender = 2;
     */
    gender: Gender$;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.NicknameItemType type = 3;
     */
    type: NicknameItemType;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string operator = 13;
     */
    operator: string;
    /**
     * @generated from protobuf field: int64 createdAt = 14;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 updatedAt = 15;
     */
    updatedAt: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UploadNicknameItemsReq
 */
export interface UploadNicknameItemsReq {
    /**
     * @generated from protobuf field: bpt.common.Gender gender = 2;
     */
    gender: Gender$;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.NicknameItemType type = 3;
     */
    type: NicknameItemType;
    /**
     * @generated from protobuf field: repeated string names = 4;
     */
    names: string[];
    /**
     * @generated from protobuf field: string operator = 15;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UploadNicknameItemsResp
 */
export interface UploadNicknameItemsResp {
}
/**
 * **  UpdateNicknameItem **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateNicknameItemReq
 */
export interface UpdateNicknameItemReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // 只允许修改名称数据，其他两个类型不能改
    /**
     * @generated from protobuf field: string operator = 15;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateNicknameItemResp
 */
export interface UpdateNicknameItemResp {
}
/**
 * **  ListNicknameItems **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.ListNicknameItemsReq
 */
export interface ListNicknameItemsReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PageReq page = 1;
     */
    page?: PageReq;
    /**
     * @generated from protobuf field: bpt.common.Gender gender = 2;
     */
    gender: Gender$;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.NicknameItemType type = 3;
     */
    type: NicknameItemType;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListNicknameItemsResp
 */
export interface ListNicknameItemsResp {
    /**
     * @generated from protobuf field: int64 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.NicknameItem items = 3;
     */
    items: NicknameItem[];
}
/**
 * **  GetRandNickname **
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.GetRandNicknameReq
 */
export interface GetRandNicknameReq {
    /**
     * @generated from protobuf field: bpt.common.Gender gender = 2;
     */
    gender: Gender$;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetRandNicknameResp
 */
export interface GetRandNicknameResp {
    /**
     * @generated from protobuf field: string nickname = 2;
     */
    nickname: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SetAutoBuyInStatusReq
 */
export interface SetAutoBuyInStatusReq {
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 1;
     */
    state: SwitchState;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SetWaitBigBlindStatusReq
 */
export interface SetWaitBigBlindStatusReq {
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 1;
     */
    state: SwitchState;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetAutoBuyInStatusReq
 */
export interface GetAutoBuyInStatusReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetAutoBuyInStatusResp
 */
export interface GetAutoBuyInStatusResp {
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 1;
     */
    state: SwitchState;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetWaitBigBlindStatusReq
 */
export interface GetWaitBigBlindStatusReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetWaitBigBlindStatusResp
 */
export interface GetWaitBigBlindStatusResp {
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 1;
     */
    state: SwitchState;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SetTableViewStatusReq
 */
export interface SetTableViewStatusReq {
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 1;
     */
    state: SwitchState;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SetUserTableVoiceReq
 */
export interface SetUserTableVoiceReq {
    /**
     * @generated from protobuf field: int64 voiceId = 1;
     */
    voiceId: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetTableViewStatusReq
 */
export interface GetTableViewStatusReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetTableViewStatusResp
 */
export interface GetTableViewStatusResp {
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 1;
     */
    state: SwitchState;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginByPlatformReq
 */
export interface LoginByPlatformReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.LoginReqCommon common = 1;
     */
    common?: LoginReqCommon;
    /**
     * @generated from protobuf field: string oauthCode = 3;
     */
    oauthCode: string;
    /**
     * @generated from protobuf field: string accessToken = 4;
     */
    accessToken: string;
    /**
     * 扩展参数
     *
     * @generated from protobuf field: string param = 5;
     */
    param: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginReqCommon
 */
export interface LoginReqCommon {
    /**
     * @generated from protobuf field: string guid = 1;
     */
    guid: string; // 设备唯一标识
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.DeviceType deviceType = 2;
     */
    deviceType: DeviceType; // 设备类型
    /**
     * @generated from protobuf field: string deviceMode = 3;
     */
    deviceMode: string; // 客户品牌型号
    /**
     * @generated from protobuf field: int64 clientTime = 4;
     */
    clientTime: number; // 客户端时间，UNIX_TIMESTAMP 秒级，如果与服务器差异太大则不允许登录
    /**
     * @generated from protobuf field: string clientVersion = 5;
     */
    clientVersion: string; // 客户端版本
    /**
     * @generated from protobuf field: float latitude = 6;
     */
    latitude: number; // 纬度
    /**
     * @generated from protobuf field: float longitude = 7;
     */
    longitude: number; // 经度
    /**
     * @generated from protobuf field: string language = 8;
     */
    language: string; // 使用语言
    /**
     * @generated from protobuf field: string os = 9;
     */
    os: string; // 操作系统
    /**
     * @generated from protobuf field: string channel = 10;
     */
    channel: string; // 登录渠道信息
    /**
     * MB,WB
     *
     * @generated from protobuf field: string source = 11;
     */
    source: string; // Token Tag
    /**
     * @generated from protobuf field: bool isSimulator = 12;
     */
    isSimulator: boolean; // 是否是模拟器
    /**
     * @generated from protobuf field: bool autoRegister = 13;
     */
    autoRegister: boolean; // 是否自动注册
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginByPlatformResp
 */
export interface LoginByPlatformResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.LoginRespCommon common = 1;
     */
    common?: LoginRespCommon;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PlatformUser user = 2;
     */
    user?: PlatformUser;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginByExchangeReq
 */
export interface LoginByExchangeReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.LoginReqCommon common = 1;
     */
    common?: LoginReqCommon;
    /**
     * @generated from protobuf field: string accessToken = 2;
     */
    accessToken: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginByExchangeResp
 */
export interface LoginByExchangeResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.LoginRespCommon common = 1;
     */
    common?: LoginRespCommon;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.PlatformUser
 */
export interface PlatformUser {
    /**
     * @generated from protobuf field: string openID = 1;
     */
    openID: string;
    /**
     * @generated from protobuf field: string nickname = 2;
     */
    nickname: string;
    /**
     * @generated from protobuf field: string headImgURL = 3;
     */
    headImgURL: string;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Gender gender = 4;
     */
    gender: Gender;
    /**
     * @generated from protobuf field: string pubID = 5;
     */
    pubID: string;
    /**
     * @generated from protobuf field: int64 AccountID = 6 [json_name = "AccountID"];
     */
    AccountID: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginRespCommon
 */
export interface LoginRespCommon {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.LoginRespCommon.TokenType type = 1;
     */
    type: LoginRespCommon_TokenType;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: string token = 4;
     */
    token: string;
    /**
     * @generated from protobuf field: int64 tokenExpiredAt = 5;
     */
    tokenExpiredAt: number;
    /**
     * @generated from protobuf field: bool isRegister = 7;
     */
    isRegister: boolean;
    /**
     * @generated from protobuf field: int64 lastLoginAt = 9;
     */
    lastLoginAt: number;
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.LoginRespCommon.TokenType
 */
export enum LoginRespCommon_TokenType {
    /**
     * @generated from protobuf enum value: TokenTypeUnknown = 0;
     */
    TokenTypeUnknown = 0,
    /**
     * 可以访问Token
     *
     * @generated from protobuf enum value: TokenTypeAccess = 1;
     */
    TokenTypeAccess = 1,
    /**
     * 已认证
     *
     * @generated from protobuf enum value: TokenTypePassportVerified = 2;
     */
    TokenTypePassportVerified = 2
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.TopChampionListResp
 */
export interface TopChampionListResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.ChampionElem users = 1;
     */
    users: ChampionElem[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.TopChampionListV2Req
 */
export interface TopChampionListV2Req {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ChampionElem
 */
export interface ChampionElem {
    /**
     * @generated from protobuf field: int32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int32 times = 3;
     */
    times: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.TopBonusListResp
 */
export interface TopBonusListResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.BonusElem users = 1;
     */
    users: BonusElem[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BonusElem
 */
export interface BonusElem {
    /**
     * @generated from protobuf field: int32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: double totalWinning = 3;
     */
    totalWinning: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.TopBonusListV2Req
 */
export interface TopBonusListV2Req {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetRankAndTotalWinResp
 */
export interface GetRankAndTotalWinResp {
    /**
     * @generated from protobuf field: int32 rankRange = 1;
     */
    rankRange: number; // 系统只排前1～rankRange
    /**
     * @generated from protobuf field: int32 rank = 2;
     */
    rank: number; // 用户排名，0代表用户的排名超出rankRange
    /**
     * @generated from protobuf field: double totalWin = 3;
     */
    totalWin: number; // 单位为USDT
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserRankingResp
 */
export interface GetUserRankingResp {
    /**
     * @generated from protobuf field: int32 rankRange = 1;
     */
    rankRange: number; // 系统只排前1～rankRange
    /**
     * @generated from protobuf field: int32 rank = 2;
     */
    rank: number; // 用户排名，0代表用户的排名超出rankRange
    /**
     * @generated from protobuf field: double totalWin = 3;
     */
    totalWin: number; // 单位为MTT
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetRankAndTop1TimesResp
 */
export interface GetRankAndTop1TimesResp {
    /**
     * @generated from protobuf field: int32 rankRange = 1;
     */
    rankRange: number; // 系统只排前1～rankRange
    /**
     * @generated from protobuf field: int32 rank = 2;
     */
    rank: number; // 用户排名，0代表用户的排名超出rankRange
    /**
     * @generated from protobuf field: int32 times = 3;
     */
    times: number; // top1次数
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.RerunStRankReq
 */
export interface RerunStRankReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 2;
     */
    matchListType: MatchListType;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.RerunStRankResp
 */
export interface RerunStRankResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetMatchReportListReq
 */
export interface GetMatchReportListReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetMatchReportListResp
 */
export interface GetMatchReportListResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UserPrizeRank
 */
export interface UserPrizeRank {
    /**
     * @generated from protobuf field: int32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 3;
     */
    matchListType: MatchListType;
    /**
     * @generated from protobuf field: double total = 4;
     */
    total: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UserChampion
 */
export interface UserChampion {
    /**
     * @generated from protobuf field: int32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.User user = 3;
     */
    user?: User;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 4 [json_name = "i18n"];
     */
    i18n?: I18n;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizes = 5;
     */
    prizes: AssetItem[];
    /**
     * @generated from protobuf field: string matchKey = 6;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListUserPrizeRanksReq
 */
export interface ListUserPrizeRanksReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 1;
     */
    matchListType: MatchListType;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListUserPrizeRanksResp
 */
export interface ListUserPrizeRanksResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserPrizeRank userPrizeRanks = 1;
     */
    userPrizeRanks: UserPrizeRank[];
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 3 [json_name = "i18n"];
     */
    i18n?: I18n;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListUserPrizeRankingsReq
 */
export interface ListUserPrizeRankingsReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 1;
     */
    matchListType: MatchListType;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserRankingReq
 */
export interface GetUserRankingReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 1;
     */
    matchListType: MatchListType;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListUserPrizeRankingsResp
 */
export interface ListUserPrizeRankingsResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserPrizeRank userPrizeRanks = 1;
     */
    userPrizeRanks: UserPrizeRank[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListUserChampionsResp
 */
export interface ListUserChampionsResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.UserChampion userChampions = 1;
     */
    userChampions: UserChampion[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SaveImagesToAlbumReq
 */
export interface SaveImagesToAlbumReq {
    /**
     * @generated from protobuf field: repeated string imagePaths = 1;
     */
    imagePaths: string[]; // 已传图片+len(imagePaths) <=9,不然调用不成功
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SaveImagesToAlbumResp
 */
export interface SaveImagesToAlbumResp {
    /**
     * @generated from protobuf field: repeated string imageIds = 1;
     */
    imageIds: string[]; // 图片的id
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeleteImagesFromAlbumReq
 */
export interface DeleteImagesFromAlbumReq {
    /**
     * @generated from protobuf field: repeated string imageIds = 1;
     */
    imageIds: string[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListImagesFromAlbumReq
 */
export interface ListImagesFromAlbumReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // 可以是自己，也可以是别人
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListImagesFromAlbumResp
 */
export interface ListImagesFromAlbumResp {
    /**
     * @generated from protobuf field: int64 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.AlbumImage albumImages = 2;
     */
    albumImages: AlbumImage[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.AlbumImage
 */
export interface AlbumImage {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserIntegratedInfoReq
 */
export interface GetUserIntegratedInfoReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserIntegratedInfoResp
 */
export interface GetUserIntegratedInfoResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.SimpleUser user = 1;
     */
    user?: SimpleUser;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.AlbumImage images = 2;
     */
    images: AlbumImage[];
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.CareerData careerData = 3;
     */
    careerData?: CareerData;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.SocialData socialData = 4;
     */
    socialData?: SocialData;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Tag tag = 5;
     */
    tag?: Tag;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PointRating rating = 6;
     */
    rating?: PointRating;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.PointRating
 */
export interface PointRating {
    /**
     * @generated from protobuf field: int32 worldRank = 1;
     */
    worldRank: number;
    /**
     * @generated from protobuf field: int32 worldRankPoints = 2;
     */
    worldRankPoints: number;
    /**
     * @generated from protobuf field: bpt.hall.common.CompetencyRating competencyRating = 3;
     */
    competencyRating?: CompetencyRating;
    /**
     * @generated from protobuf field: int32 totalPoints = 4;
     */
    totalPoints: number; // 历史总积分
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.CareerData
 */
export interface CareerData {
    /**
     * @generated from protobuf field: int64 firstPlaceCnt = 1;
     */
    firstPlaceCnt: number;
    /**
     * @generated from protobuf field: int64 joinMatchCnt = 2;
     */
    joinMatchCnt: number;
    /**
     * @generated from protobuf field: int64 totalPrizeMTT = 3;
     */
    totalPrizeMTT: number;
    /**
     * @generated from protobuf field: string prizeMTTCnt = 4;
     */
    prizeMTTCnt: string;
    /**
     * @generated from protobuf field: int64 receivedLikeCnt = 5;
     */
    receivedLikeCnt: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SocialData
 */
export interface SocialData {
    /**
     * @generated from protobuf field: int64 fansNum = 1;
     */
    fansNum: number;
    /**
     * @generated from protobuf field: int64 followNum = 2;
     */
    followNum: number;
    /**
     * @generated from protobuf field: bpt.social.relation.v1.Relate relate = 3;
     */
    relate?: Relate;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListSocialAccountReq
 */
export interface ListSocialAccountReq {
    /**
     * @generated from protobuf field: int64 accountId = 1;
     */
    accountId: number;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.PassportType passportType = 2;
     */
    passportType: PassportType;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListSocialAccountResp
 */
export interface ListSocialAccountResp {
    /**
     * @generated from protobuf field: string username = 1;
     */
    username: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SaveTagReq
 */
export interface SaveTagReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Tag tag = 1;
     */
    tag?: Tag;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.SaveTagResp
 */
export interface SaveTagResp {
    /**
     * @generated from protobuf field: string tagId = 1;
     */
    tagId: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetTagReq
 */
export interface GetTagReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetTagResp
 */
export interface GetTagResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Tag tag = 1;
     */
    tag?: Tag;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetTagReq
 */
export interface BatchGetTagReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.BatchGetTagResp
 */
export interface BatchGetTagResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.Tag tags = 1;
     */
    tags: Tag[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeleteTagReq
 */
export interface DeleteTagReq {
    /**
     * @generated from protobuf field: repeated string tagIds = 1;
     */
    tagIds: string[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.DeleteTagResp
 */
export interface DeleteTagResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListTagReq
 */
export interface ListTagReq {
    /**
     * @generated from protobuf field: int64 skip = 1;
     */
    skip: number;
    /**
     * @generated from protobuf field: int64 limit = 2;
     */
    limit: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListTagResp
 */
export interface ListTagResp {
    /**
     * @generated from protobuf field: int64 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.Tag tags = 2;
     */
    tags: Tag[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateTagReq
 */
export interface UpdateTagReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bool commentUpdate = 3;
     */
    commentUpdate: boolean;
    /**
     * @generated from protobuf field: string comment = 4;
     */
    comment: string;
    /**
     * @generated from protobuf field: bool tagUpdate = 5;
     */
    tagUpdate: boolean;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.TagType tagTypes = 6;
     */
    tagTypes: TagType[];
    /**
     * @generated from protobuf field: bool colorUpdate = 7;
     */
    colorUpdate: boolean;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.ColourType colourTypes = 8;
     */
    colourTypes: ColourType[];
    /**
     * @generated from protobuf field: bool markUpdate = 9;
     */
    markUpdate: boolean;
    /**
     * @generated from protobuf field: string mark = 10;
     */
    mark: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateTagResp
 */
export interface UpdateTagResp {
    /**
     * @generated from protobuf field: string tagId = 1;
     */
    tagId: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.Tag
 */
export interface Tag {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string tagId = 2;
     */
    tagId: string;
    /**
     * @generated from protobuf field: string comment = 3;
     */
    comment: string;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.TagType tagTypes = 4;
     */
    tagTypes: TagType[];
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.ColourType colourTypes = 5;
     */
    colourTypes: ColourType[];
    /**
     * @generated from protobuf field: int64 updateAt = 6;
     */
    updateAt: number;
    /**
     * @generated from protobuf field: string mark = 7;
     */
    mark: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.Notice
 */
export interface Notice {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string noticeId = 2;
     */
    noticeId: string;
    /**
     * @generated from protobuf field: bpt.hall.common.NoticeType noticeType = 3;
     */
    noticeType: NoticeType;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.HonorNotice HonorNotice = 4 [json_name = "HonorNotice"];
     */
    HonorNotice?: HonorNotice;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.RatingNotice RatingNotice = 5 [json_name = "RatingNotice"];
     */
    RatingNotice?: RatingNotice;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.RewardNotice rewardNotice = 7;
     */
    rewardNotice?: RewardNotice;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.NonChainRewardNotice nonChainRewardNotice = 9;
     */
    nonChainRewardNotice?: NonChainRewardNotice;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.TransferNotice transferNotice = 8;
     */
    transferNotice?: TransferNotice;
    /**
     * @generated from protobuf field: int64 createTime = 6;
     */
    createTime: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.HonorNotice
 */
export interface HonorNotice {
    /**
     * 取值来源:bpt/ccds/ccds.proto/HonorTopic
     *
     * @generated from protobuf field: int32 topic = 1;
     */
    topic: number;
    /**
     * 用户达成时间
     *
     * @generated from protobuf field: int64 achieveAt = 2;
     */
    achieveAt: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.RatingNotice
 */
export interface RatingNotice {
    /**
     * @generated from protobuf field: bpt.hall.common.CompetencyRating competencyRating = 1;
     */
    competencyRating?: CompetencyRating;
    /**
     * @generated from protobuf field: int32 addPoints = 2;
     */
    addPoints: number;
}
/**
 * 链上资产的发奖通知
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.RewardNotice
 */
export interface RewardNotice {
    /**
     * @generated from protobuf field: bpt.common.I18n matchName = 1;
     */
    matchName?: I18n; // 多语言
    /**
     * @generated from protobuf field: int32 rank = 2;
     */
    rank: number;
    /**
     * 展示多个奖品，相同类型不合并，返回数量可能超过3个
     * 需求要求显示3个，超过3个需要前端处理
     *
     * @generated from protobuf field: repeated bpt.common.SimpleAsset prizes = 3;
     */
    prizes: SimpleAsset[];
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int32 joinedNum = 5;
     */
    joinedNum: number; // 参赛用户
}
/**
 * 非链上资产的发奖通知
 *
 * @generated from protobuf message bpt.hall.userinfo.v1.NonChainRewardNotice
 */
export interface NonChainRewardNotice {
    /**
     * @generated from protobuf field: bpt.common.I18n matchName = 1;
     */
    matchName?: I18n; // 多语言
    /**
     * @generated from protobuf field: int32 rank = 2;
     */
    rank: number;
    /**
     * 展示多个奖品，相同类型不合并，返回数量可能超过3个
     * 需求要求显示3个，超过3个需要前端处理
     *
     * @generated from protobuf field: repeated bpt.common.SimpleAsset prizes = 3;
     */
    prizes: SimpleAsset[];
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int32 joinedNum = 5;
     */
    joinedNum: number; // 参赛用户
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.TransferNotice
 */
export interface TransferNotice {
    /**
     * @generated from protobuf field: string fromUserName = 1;
     */
    fromUserName: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.AddNoticeReq
 */
export interface AddNoticeReq {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Notice notice = 1;
     */
    notice?: Notice;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.AddNoticeResp
 */
export interface AddNoticeResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListNoticeReq
 */
export interface ListNoticeReq {
    /**
     * @generated from protobuf field: int64 offset = 1;
     */
    offset: number;
    /**
     * @generated from protobuf field: int64 limit = 2;
     */
    limit: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.ListNoticeResp
 */
export interface ListNoticeResp {
    /**
     * @generated from protobuf field: int64 total = 1;
     */
    total: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.Notice notices = 2;
     */
    notices: Notice[];
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.CloseNoticeReq
 */
export interface CloseNoticeReq {
    /**
     * @generated from protobuf field: string noticeId = 1;
     */
    noticeId: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.CloseNoticeResp
 */
export interface CloseNoticeResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateClubIdReq
 */
export interface UpdateClubIdReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint32 clubId = 2;
     */
    clubId: number;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.UpdateType updateType = 3;
     */
    updateType: UpdateType;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateClubIdResp
 */
export interface UpdateClubIdResp {
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserOpsInfoReq
 */
export interface GetUserOpsInfoReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.Location
 */
export interface Location {
    /**
     * @generated from protobuf field: string countryCode = 1;
     */
    countryCode: string;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.LoginLog
 */
export interface LoginLog {
    /**
     * @generated from protobuf field: int64 loginAt = 1;
     */
    loginAt: number;
    /**
     * @generated from protobuf field: string ip = 2;
     */
    ip: string;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.Location location = 3;
     */
    location?: Location;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GetUserOpsInfoResp
 */
export interface GetUserOpsInfoResp {
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.LoginLog lastLoginLog = 1;
     */
    lastLoginLog?: LoginLog;
    /**
     * @generated from protobuf field: string fromInviteCode = 2;
     */
    fromInviteCode: string;
    /**
     * @generated from protobuf field: int64 registerAt = 3;
     */
    registerAt: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GlobalSearchUser
 */
export interface GlobalSearchUser {
    /**
     * 用户 ID
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 昵称
     *
     * @generated from protobuf field: string nickname = 2;
     */
    nickname: string;
    /**
     * 性别
     *
     * @generated from protobuf field: bpt.common.Gender gender = 3;
     */
    gender: Gender$;
    /**
     * 头像
     *
     * @generated from protobuf field: string avatar = 4;
     */
    avatar: string;
    /**
     * 个性签名
     *
     * @generated from protobuf field: string signature = 5;
     */
    signature: string;
    /**
     * @generated from protobuf field: string pubId = 6;
     */
    pubId: string;
    /**
     * @generated from protobuf field: string mark = 7;
     */
    mark: string;
    /**
     * 钱包地址
     *
     * @generated from protobuf field: string address = 9;
     */
    address: string;
    /**
     * 转账次数
     *
     * @generated from protobuf field: int32 transTimes = 10;
     */
    transTimes: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GlobalSearchUsersReq
 */
export interface GlobalSearchUsersReq {
    /**
     * @generated from protobuf field: string keyword = 1;
     */
    keyword: string;
    /**
     * @generated from protobuf field: bpt.hall.userinfo.v1.SearchType searchType = 2;
     */
    searchType: SearchType;
    /**
     * @generated from protobuf field: uint32 clubId = 3;
     */
    clubId: number; // 非俱乐部搜索传0
    /**
     * @generated from protobuf field: uint32 page = 4;
     */
    page: number;
    /**
     * @generated from protobuf field: uint32 pageSize = 5;
     */
    pageSize: number; // 最大支持30
    /**
     * @generated from protobuf field: bool isWallet = 6;
     */
    isWallet: boolean; // 是否需要钱包信息
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.GlobalSearchUsersResp
 */
export interface GlobalSearchUsersResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.userinfo.v1.GlobalSearchUser users = 1;
     */
    users: GlobalSearchUser[];
    /**
     * @generated from protobuf field: uint32 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserDataReq
 */
export interface UpdateUserDataReq {
    /**
     * @generated from protobuf field: int64 userID = 1;
     */
    userID: number;
    /**
     * @generated from protobuf field: google.protobuf.Int32Value roleType = 2;
     */
    roleType?: Int32Value;
}
/**
 * @generated from protobuf message bpt.hall.userinfo.v1.UpdateUserDataResp
 */
export interface UpdateUserDataResp {
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.Code
 */
export enum Code {
    /**
     * @generated from protobuf enum value: CodeOK = 0;
     */
    CodeOK = 0,
    /**
     * @generated from protobuf enum value: CodeNicknameDuplicate = 3001;
     */
    CodeNicknameDuplicate = 3001,
    /**
     * @generated from protobuf enum value: CodeNicknameChanceLimit = 3002;
     */
    CodeNicknameChanceLimit = 3002,
    /**
     * @generated from protobuf enum value: CodeNicknameInvalid = 3003;
     */
    CodeNicknameInvalid = 3003,
    /**
     * @generated from protobuf enum value: CodeNicknameLenInvalid = 3004;
     */
    CodeNicknameLenInvalid = 3004,
    /**
     * 身份证信息与用户不匹配
     *
     * @generated from protobuf enum value: CodeIdCardInfoNotMatchUser = 4001;
     */
    CodeIdCardInfoNotMatchUser = 4001,
    /**
     * 已注销
     *
     * @generated from protobuf enum value: CodeDeactivated = 4002;
     */
    CodeDeactivated = 4002,
    /**
     * 注销频率限制
     *
     * @generated from protobuf enum value: CodeDeactivatedTimesLimit = 4003;
     */
    CodeDeactivatedTimesLimit = 4003,
    /**
     * 统计表中没有查到用户，说明用户没参加过锦标赛，今天参加了，结果明天才会出来
     *
     * @generated from protobuf enum value: CodeUserHasNoRanking = 5000;
     */
    CodeUserHasNoRanking = 5000,
    /**
     * 邀请码
     * 1.设备已经用过邀请码；
     *
     * @generated from protobuf enum value: CodeDeviceIdFound = 6000;
     */
    CodeDeviceIdFound = 6000,
    /**
     * 2.notfound的邀请码；
     *
     * @generated from protobuf enum value: CodeInviteCodeNotFound = 6001;
     */
    CodeInviteCodeNotFound = 6001,
    /**
     * 3邀请码已经使用过
     *
     * @generated from protobuf enum value: CodeInviteCodeHasUsed = 6002;
     */
    CodeInviteCodeHasUsed = 6002
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.RoleType
 */
export enum RoleType {
    /**
     * 普通用户
     *
     * @generated from protobuf enum value: RoleTypeRegular = 0;
     */
    RoleTypeRegular = 0,
    /**
     * 机器人
     *
     * @generated from protobuf enum value: RoleTypeRobot = 1;
     */
    RoleTypeRobot = 1,
    /**
     * @generated from protobuf enum value: RoleTypeTester = 2;
     */
    RoleTypeTester = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.NoviceStatus
 */
export enum NoviceStatus {
    /**
     * 普通用户
     *
     * @generated from protobuf enum value: NoviceStatusNone = 0;
     */
    NoviceStatusNone = 0,
    /**
     * 新手
     *
     * @generated from protobuf enum value: NoviceStatusNovice = 1;
     */
    NoviceStatusNovice = 1
}
/**
 * oss 平台
 *
 * @generated from protobuf enum bpt.hall.userinfo.v1.Platform
 */
export enum Platform {
    /**
     * @generated from protobuf enum value: PlatformUnknown = 0;
     */
    PlatformUnknown = 0,
    /**
     * @generated from protobuf enum value: PlatformAli = 1;
     */
    PlatformAli = 1,
    /**
     * @generated from protobuf enum value: PlatformTencent = 2;
     */
    PlatformTencent = 2,
    /**
     * @generated from protobuf enum value: PlatformMinio = 3;
     */
    PlatformMinio = 3
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.IdCardStatus
 */
export enum IdCardStatus {
    /**
     * 未认证
     *
     * @generated from protobuf enum value: IdCardUnAuth = 0;
     */
    IdCardUnAuth = 0,
    /**
     * 认证中 根据中宣部要求，认证中的用户与通过认证的用户权限相同
     *
     * @generated from protobuf enum value: IdCardAuthing = 1;
     */
    IdCardAuthing = 1,
    /**
     * 认证成功
     *
     * @generated from protobuf enum value: IdCardAuthSuccess = 2;
     */
    IdCardAuthSuccess = 2,
    /**
     * 认证失败
     *
     * @generated from protobuf enum value: IdCardAuthFail = 3;
     */
    IdCardAuthFail = 3
}
/**
 * /***  SearchUsers ***\/
 *
 * @generated from protobuf enum bpt.hall.userinfo.v1.GenderFilter
 */
export enum GenderFilter {
    /**
     * @generated from protobuf enum value: GenderFilterAll = 0;
     */
    GenderFilterAll = 0,
    /**
     * @generated from protobuf enum value: GenderFilterUnknow = 1;
     */
    GenderFilterUnknow = 1,
    /**
     * @generated from protobuf enum value: GenderFilterMale = 2;
     */
    GenderFilterMale = 2,
    /**
     * @generated from protobuf enum value: GenderFilterFemale = 3;
     */
    GenderFilterFemale = 3
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.IdCardStatusFilter
 */
export enum IdCardStatusFilter {
    /**
     * @generated from protobuf enum value: IdCardStatusFilterAll = 0;
     */
    IdCardStatusFilterAll = 0,
    /**
     * 未认证
     *
     * @generated from protobuf enum value: IdCardStatusFilterUnAuth = 1;
     */
    IdCardStatusFilterUnAuth = 1,
    /**
     * 认证中 根据中宣部要求，认证中的用户与通过认证的用户权限相同
     *
     * @generated from protobuf enum value: IdCardStatusFilterAuthing = 2;
     */
    IdCardStatusFilterAuthing = 2,
    /**
     * 认证成功
     *
     * @generated from protobuf enum value: IdCardStatusFilterSuccess = 3;
     */
    IdCardStatusFilterSuccess = 3,
    /**
     * 认证失败
     *
     * @generated from protobuf enum value: IdCardStatusFilterFail = 4;
     */
    IdCardStatusFilterFail = 4,
    /**
     * @generated from protobuf enum value: IdCardStatusFilterAuthingAndSuc = 5;
     */
    IdCardStatusFilterAuthingAndSuc = 5,
    /**
     * @generated from protobuf enum value: IdCardStatusFilterUnAuthAnFail = 6;
     */
    IdCardStatusFilterUnAuthAnFail = 6
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.RoleTypeFilter
 */
export enum RoleTypeFilter {
    /**
     * @generated from protobuf enum value: RoleTypeFilterAll = 0;
     */
    RoleTypeFilterAll = 0,
    /**
     * 普通用户
     *
     * @generated from protobuf enum value: RoleTypeFilterRegular = 1;
     */
    RoleTypeFilterRegular = 1,
    /**
     * 机器人
     *
     * @generated from protobuf enum value: RoleTypeFilterRobot = 2;
     */
    RoleTypeFilterRobot = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.IdCardVerifyResult
 */
export enum IdCardVerifyResult {
    /**
     * 成功
     *
     * @generated from protobuf enum value: IdCardVerifyResultOK = 0;
     */
    IdCardVerifyResultOK = 0,
    /**
     * 失败
     *
     * @generated from protobuf enum value: IdCardVerifyResultFail = 2001;
     */
    IdCardVerifyResultFail = 2001,
    /**
     * 身份证和姓名不匹配
     *
     * @generated from protobuf enum value: IdCardVerifyResultNotMatch = 2002;
     */
    IdCardVerifyResultNotMatch = 2002,
    /**
     * 身份证号无效
     *
     * @generated from protobuf enum value: IdCardVerifyResultInvalid = 2003;
     */
    IdCardVerifyResultInvalid = 2003,
    /**
     * IdCardVerifyResultTimesLimit        = 2004; // 认证次数限制
     *
     * 错误重试次数达到上限
     *
     * @generated from protobuf enum value: IdCardVerifyResultRetryLimit = 2005;
     */
    IdCardVerifyResultRetryLimit = 2005,
    /**
     * 绑定账号数量达到上限
     *
     * @generated from protobuf enum value: IdCardVerifyResultBindAccountLimit = 2006;
     */
    IdCardVerifyResultBindAccountLimit = 2006
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.UserUpdateResult
 */
export enum UserUpdateResult {
    /**
     * @generated from protobuf enum value: UserUpdateResultOK = 0;
     */
    UserUpdateResultOK = 0
}
/**
 * Ban 封号和解封原因
 *
 * @generated from protobuf enum bpt.hall.userinfo.v1.BanReason
 */
export enum BanReason {
    /**
     * @generated from protobuf enum value: BanReasonOther = 0;
     */
    BanReasonOther = 0,
    /**
     * 牌局作弊
     *
     * @generated from protobuf enum value: BanReasonCardCheat = 1;
     */
    BanReasonCardCheat = 1,
    /**
     * 比赛作弊
     *
     * @generated from protobuf enum value: BanReasonMatchCheat = 2;
     */
    BanReasonMatchCheat = 2,
    /**
     * 非法收售钻石
     *
     * @generated from protobuf enum value: BanReasonIllegalDealDiamond = 3;
     */
    BanReasonIllegalDealDiamond = 3,
    /**
     * 违规昵称 头像
     *
     * @generated from protobuf enum value: BanReasonViolateNameAvatar = 4;
     */
    BanReasonViolateNameAvatar = 4,
    /**
     * 账号被盗
     *
     * @generated from protobuf enum value: BanReasonAccountHacked = 5;
     */
    BanReasonAccountHacked = 5,
    /**
     * 发布违规内容
     *
     * @generated from protobuf enum value: BanReasonViolateContent = 6;
     */
    BanReasonViolateContent = 6
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.UnbanReason
 */
export enum UnbanReason {
    /**
     * @generated from protobuf enum value: UnbanReasonOther = 0;
     */
    UnbanReasonOther = 0,
    /**
     * 误操作
     *
     * @generated from protobuf enum value: UnbanReasonMistake = 1;
     */
    UnbanReasonMistake = 1,
    /**
     * 已警告玩家
     *
     * @generated from protobuf enum value: UnbanReasonWarned = 2;
     */
    UnbanReasonWarned = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.BanStatus
 */
export enum BanStatus {
    /**
     * @generated from protobuf enum value: BanStatusUnknown = 0;
     */
    BanStatusUnknown = 0,
    /**
     * 封禁
     *
     * @generated from protobuf enum value: BanStatusBan = 1;
     */
    BanStatusBan = 1,
    /**
     * 解封
     *
     * @generated from protobuf enum value: BanStatusUnBan = 2;
     */
    BanStatusUnBan = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.DeactivateStatus
 */
export enum DeactivateStatus {
    /**
     * 未注销
     *
     * @generated from protobuf enum value: DeactivateStatusNone = 0;
     */
    DeactivateStatusNone = 0,
    /**
     * 注销完成
     *
     * @generated from protobuf enum value: DeactivateStatusFinished = 1;
     */
    DeactivateStatusFinished = 1
}
/**
 * Mute 禁言和解封原因
 *
 * @generated from protobuf enum bpt.hall.userinfo.v1.MuteReason
 */
export enum MuteReason {
    /**
     * 其他
     *
     * @generated from protobuf enum value: MuteReasonOther = 0;
     */
    MuteReasonOther = 0,
    /**
     * 涉政/爆恐
     *
     * @generated from protobuf enum value: MuteReasonPolitical = 1;
     */
    MuteReasonPolitical = 1,
    /**
     * 色情/广告
     *
     * @generated from protobuf enum value: MuteReasonPornOrAd = 2;
     */
    MuteReasonPornOrAd = 2,
    /**
     * 辱骂/低俗
     *
     * @generated from protobuf enum value: MuteReasonInsult = 3;
     */
    MuteReasonInsult = 3
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.UnmuteReason
 */
export enum UnmuteReason {
    /**
     * @generated from protobuf enum value: UnmuteReasonOther = 0;
     */
    UnmuteReasonOther = 0,
    /**
     * 误操作
     *
     * @generated from protobuf enum value: UnmuteReasonMistake = 1;
     */
    UnmuteReasonMistake = 1,
    /**
     * 已警告玩家
     *
     * @generated from protobuf enum value: UnmuteReasonWarned = 2;
     */
    UnmuteReasonWarned = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.MuteStatus
 */
export enum MuteStatus {
    /**
     * @generated from protobuf enum value: MuteStatusUnknown = 0;
     */
    MuteStatusUnknown = 0,
    /**
     * 禁言
     *
     * @generated from protobuf enum value: MuteStatusMute = 1;
     */
    MuteStatusMute = 1,
    /**
     * 解封
     *
     * @generated from protobuf enum value: MuteStatusUnMute = 2;
     */
    MuteStatusUnMute = 2
}
/**
 * **  UploadNicknameItems **
 *
 * @generated from protobuf enum bpt.hall.userinfo.v1.NicknameItemType
 */
export enum NicknameItemType {
    /**
     * @generated from protobuf enum value: NicknameItemTypeUnknown = 0;
     */
    NicknameItemTypeUnknown = 0,
    /**
     * 姓氏
     *
     * @generated from protobuf enum value: NicknameItemTypeFamilyName = 1;
     */
    NicknameItemTypeFamilyName = 1,
    /**
     * 名字
     *
     * @generated from protobuf enum value: NicknameItemTypeGivenName = 2;
     */
    NicknameItemTypeGivenName = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.PlatformType
 */
export enum PlatformType {
    /**
     * @generated from protobuf enum value: PlatformTypeUnknown = 0;
     */
    PlatformTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: PlatformTypeWeixin = 1;
     */
    PlatformTypeWeixin = 1,
    /**
     * @generated from protobuf enum value: PlatformTypeApple = 2;
     */
    PlatformTypeApple = 2,
    /**
     * @generated from protobuf enum value: PlatformTypeOppo = 3;
     */
    PlatformTypeOppo = 3,
    /**
     * @generated from protobuf enum value: PlatformTypeVivo = 4;
     */
    PlatformTypeVivo = 4,
    /**
     * @generated from protobuf enum value: PlatformTypeHuawei = 5;
     */
    PlatformTypeHuawei = 5,
    /**
     * @generated from protobuf enum value: PlatformTypeLine = 9;
     */
    PlatformTypeLine = 9,
    /**
     * @generated from protobuf enum value: PlatformTypeFacebook = 10;
     */
    PlatformTypeFacebook = 10,
    /**
     * @generated from protobuf enum value: PlatformTypeGoogle = 11;
     */
    PlatformTypeGoogle = 11,
    /**
     * @generated from protobuf enum value: PlatformTypeGAW = 12;
     */
    PlatformTypeGAW = 12,
    /**
     * Mob 平台，不知道放哪好，先卡在这个位置吧
     *
     * @generated from protobuf enum value: PlatformTypeMob = 1000;
     */
    PlatformTypeMob = 1000
}
/**
 * 设备类型
 *
 * @generated from protobuf enum bpt.hall.userinfo.v1.DeviceType
 */
export enum DeviceType {
    /**
     * Windows
     *
     * @generated from protobuf enum value: DeviceTypeWin = 0;
     */
    DeviceTypeWin = 0,
    /**
     * iOS
     *
     * @generated from protobuf enum value: DeviceTypeIOS = 1;
     */
    DeviceTypeIOS = 1,
    /**
     * 安卓
     *
     * @generated from protobuf enum value: DeviceTypeAndroid = 2;
     */
    DeviceTypeAndroid = 2,
    /**
     * 网页
     *
     * @generated from protobuf enum value: DeviceTypeWeb = 3;
     */
    DeviceTypeWeb = 3,
    /**
     * Mac
     *
     * @generated from protobuf enum value: DeviceTypeMac = 4;
     */
    DeviceTypeMac = 4,
    /**
     * 模拟器
     *
     * @generated from protobuf enum value: DeviceTypeSimulator = 5;
     */
    DeviceTypeSimulator = 5,
    /**
     * PWA
     *
     * @generated from protobuf enum value: DeviceTypePWA = 6;
     */
    DeviceTypePWA = 6
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.Gender
 */
export enum Gender {
    /**
     * @generated from protobuf enum value: GenderUnknown = 0;
     */
    GenderUnknown = 0,
    /**
     * @generated from protobuf enum value: GenderMale = 1;
     */
    GenderMale = 1,
    /**
     * @generated from protobuf enum value: GenderFemale = 2;
     */
    GenderFemale = 2,
    /**
     * @generated from protobuf enum value: GenderNonbinary = 3;
     */
    GenderNonbinary = 3
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.PassportType
 */
export enum PassportType {
    /**
     * @generated from protobuf enum value: PassportTypeUnknown = 0;
     */
    PassportTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: PassportTypeTwitter = 10000013;
     */
    PassportTypeTwitter = 10000013
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.TagType
 */
export enum TagType {
    /**
     * @generated from protobuf enum value: TagTypeUnknown = 0;
     */
    TagTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: TagTypeFish = 1;
     */
    TagTypeFish = 1,
    /**
     * @generated from protobuf enum value: TagTypeShark = 2;
     */
    TagTypeShark = 2,
    /**
     * @generated from protobuf enum value: TagTypeWhale = 3;
     */
    TagTypeWhale = 3,
    /**
     * @generated from protobuf enum value: TagTypeBluffer = 4;
     */
    TagTypeBluffer = 4,
    /**
     * @generated from protobuf enum value: TagTypeRock = 5;
     */
    TagTypeRock = 5,
    /**
     * @generated from protobuf enum value: TagTypeDonkey = 6;
     */
    TagTypeDonkey = 6,
    /**
     * @generated from protobuf enum value: TagTypeCallingStation = 7;
     */
    TagTypeCallingStation = 7,
    /**
     * @generated from protobuf enum value: TagWolf = 8;
     */
    TagWolf = 8
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.ColourType
 */
export enum ColourType {
    /**
     * @generated from protobuf enum value: ColourTypeUnknown = 0;
     */
    ColourTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: ColourTypeRed = 1;
     */
    ColourTypeRed = 1,
    /**
     * @generated from protobuf enum value: ColourTypePink = 2;
     */
    ColourTypePink = 2,
    /**
     * @generated from protobuf enum value: ColourTypePurple = 3;
     */
    ColourTypePurple = 3,
    /**
     * @generated from protobuf enum value: ColourTypeBlue = 4;
     */
    ColourTypeBlue = 4,
    /**
     * @generated from protobuf enum value: ColourTypeGreen = 5;
     */
    ColourTypeGreen = 5,
    /**
     * @generated from protobuf enum value: ColourTypeBrown = 6;
     */
    ColourTypeBrown = 6,
    /**
     * @generated from protobuf enum value: ColourTypeYellow = 7;
     */
    ColourTypeYellow = 7
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.UpdateType
 */
export enum UpdateType {
    /**
     * @generated from protobuf enum value: UPDATE_TYPE_UNKONW = 0;
     */
    UPDATE_TYPE_UNKONW = 0,
    /**
     * 加入
     *
     * @generated from protobuf enum value: UPDATE_TYPE_JOIN = 1;
     */
    UPDATE_TYPE_JOIN = 1,
    /**
     * 离开
     *
     * @generated from protobuf enum value: UPDATE_TYPE_EXIT = 2;
     */
    UPDATE_TYPE_EXIT = 2
}
/**
 * @generated from protobuf enum bpt.hall.userinfo.v1.SearchType
 */
export enum SearchType {
    /**
     * @generated from protobuf enum value: SEARCH_TYPE_UNKONW = 0;
     */
    SEARCH_TYPE_UNKONW = 0,
    /**
     * 昵称搜索
     *
     * @generated from protobuf enum value: SEARCH_TYPE_NICKNAME = 1;
     */
    SEARCH_TYPE_NICKNAME = 1,
    /**
     * 备注
     *
     * @generated from protobuf enum value: SEARCH_TYPE_MARK = 2;
     */
    SEARCH_TYPE_MARK = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserShowdownSwitchReq$Type extends MessageType<BatchGetUserShowdownSwitchReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserShowdownSwitchReq>): BatchGetUserShowdownSwitchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserShowdownSwitchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserShowdownSwitchReq): BatchGetUserShowdownSwitchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserShowdownSwitchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchReq
 */
export const BatchGetUserShowdownSwitchReq = new BatchGetUserShowdownSwitchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserShowdownSwitch$Type extends MessageType<UserShowdownSwitch> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserShowdownSwitch", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<UserShowdownSwitch>): UserShowdownSwitch {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<UserShowdownSwitch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserShowdownSwitch): UserShowdownSwitch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.common.SwitchState state */ 2:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserShowdownSwitch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.common.SwitchState state = 2; */
        if (message.state !== 0)
            writer.tag(2, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserShowdownSwitch
 */
export const UserShowdownSwitch = new UserShowdownSwitch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserShowdownSwitchResp$Type extends MessageType<BatchGetUserShowdownSwitchResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchResp", [
            { no: 1, name: "userShowdownSwitchs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserShowdownSwitch }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserShowdownSwitchResp>): BatchGetUserShowdownSwitchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userShowdownSwitchs = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserShowdownSwitchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserShowdownSwitchResp): BatchGetUserShowdownSwitchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.UserShowdownSwitch userShowdownSwitchs */ 1:
                    message.userShowdownSwitchs.push(UserShowdownSwitch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserShowdownSwitchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.UserShowdownSwitch userShowdownSwitchs = 1; */
        for (let i = 0; i < message.userShowdownSwitchs.length; i++)
            UserShowdownSwitch.internalBinaryWrite(message.userShowdownSwitchs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserShowdownSwitchResp
 */
export const BatchGetUserShowdownSwitchResp = new BatchGetUserShowdownSwitchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserVoiceConfigReq$Type extends MessageType<BatchGetUserVoiceConfigReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserVoiceConfigReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserVoiceConfigReq>): BatchGetUserVoiceConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserVoiceConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserVoiceConfigReq): BatchGetUserVoiceConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserVoiceConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserVoiceConfigReq
 */
export const BatchGetUserVoiceConfigReq = new BatchGetUserVoiceConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserVoiceConfig$Type extends MessageType<UserVoiceConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserVoiceConfig", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "voiceId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UserVoiceConfig>): UserVoiceConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.voiceId = 0;
        if (value !== undefined)
            reflectionMergePartial<UserVoiceConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserVoiceConfig): UserVoiceConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 voiceId */ 2:
                    message.voiceId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserVoiceConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 voiceId = 2; */
        if (message.voiceId !== 0)
            writer.tag(2, WireType.Varint).int64(message.voiceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserVoiceConfig
 */
export const UserVoiceConfig = new UserVoiceConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserVoiceConfigResp$Type extends MessageType<BatchGetUserVoiceConfigResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserVoiceConfigResp", [
            { no: 1, name: "userVoiceConfigs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserVoiceConfig }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserVoiceConfigResp>): BatchGetUserVoiceConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userVoiceConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserVoiceConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserVoiceConfigResp): BatchGetUserVoiceConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.UserVoiceConfig userVoiceConfigs */ 1:
                    message.userVoiceConfigs.push(UserVoiceConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserVoiceConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.UserVoiceConfig userVoiceConfigs = 1; */
        for (let i = 0; i < message.userVoiceConfigs.length; i++)
            UserVoiceConfig.internalBinaryWrite(message.userVoiceConfigs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserVoiceConfigResp
 */
export const BatchGetUserVoiceConfigResp = new BatchGetUserVoiceConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserGameConfigResp$Type extends MessageType<GetUserGameConfigResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserGameConfigResp", [
            { no: 1, name: "gameConfig", kind: "message", T: () => UserGameConfig }
        ]);
    }
    create(value?: PartialMessage<GetUserGameConfigResp>): GetUserGameConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserGameConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserGameConfigResp): GetUserGameConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.UserGameConfig gameConfig */ 1:
                    message.gameConfig = UserGameConfig.internalBinaryRead(reader, reader.uint32(), options, message.gameConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserGameConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.UserGameConfig gameConfig = 1; */
        if (message.gameConfig)
            UserGameConfig.internalBinaryWrite(message.gameConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserGameConfigResp
 */
export const GetUserGameConfigResp = new GetUserGameConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveUserGameConfigReq$Type extends MessageType<SaveUserGameConfigReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SaveUserGameConfigReq", [
            { no: 1, name: "gameConfig", kind: "message", T: () => UserGameConfig }
        ]);
    }
    create(value?: PartialMessage<SaveUserGameConfigReq>): SaveUserGameConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveUserGameConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveUserGameConfigReq): SaveUserGameConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.UserGameConfig gameConfig */ 1:
                    message.gameConfig = UserGameConfig.internalBinaryRead(reader, reader.uint32(), options, message.gameConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveUserGameConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.UserGameConfig gameConfig = 1; */
        if (message.gameConfig)
            UserGameConfig.internalBinaryWrite(message.gameConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SaveUserGameConfigReq
 */
export const SaveUserGameConfigReq = new SaveUserGameConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginOutReq$Type extends MessageType<LoginOutReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginOutReq", []);
    }
    create(value?: PartialMessage<LoginOutReq>): LoginOutReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LoginOutReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginOutReq): LoginOutReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LoginOutReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginOutReq
 */
export const LoginOutReq = new LoginOutReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginOutResp$Type extends MessageType<LoginOutResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginOutResp", []);
    }
    create(value?: PartialMessage<LoginOutResp>): LoginOutResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LoginOutResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginOutResp): LoginOutResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LoginOutResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginOutResp
 */
export const LoginOutResp = new LoginOutResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshTokenReq$Type extends MessageType<RefreshTokenReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.RefreshTokenReq", []);
    }
    create(value?: PartialMessage<RefreshTokenReq>): RefreshTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RefreshTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshTokenReq): RefreshTokenReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RefreshTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RefreshTokenReq
 */
export const RefreshTokenReq = new RefreshTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshTokenResp$Type extends MessageType<RefreshTokenResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.RefreshTokenResp", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tokenExpiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshTokenResp>): RefreshTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        message.tokenExpiredAt = 0;
        if (value !== undefined)
            reflectionMergePartial<RefreshTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshTokenResp): RefreshTokenResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* int64 tokenExpiredAt */ 2:
                    message.tokenExpiredAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* int64 tokenExpiredAt = 2; */
        if (message.tokenExpiredAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.tokenExpiredAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RefreshTokenResp
 */
export const RefreshTokenResp = new RefreshTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateInvitationCodeReq$Type extends MessageType<GenerateInvitationCodeReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GenerateInvitationCodeReq", [
            { no: 1, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateInvitationCodeReq>): GenerateInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.quantity = 0;
        if (value !== undefined)
            reflectionMergePartial<GenerateInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateInvitationCodeReq): GenerateInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 quantity */ 1:
                    message.quantity = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 quantity = 1; */
        if (message.quantity !== 0)
            writer.tag(1, WireType.Varint).int32(message.quantity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GenerateInvitationCodeReq
 */
export const GenerateInvitationCodeReq = new GenerateInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateInvitationCodeResp$Type extends MessageType<GenerateInvitationCodeResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GenerateInvitationCodeResp", [
            { no: 1, name: "createTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "codes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateInvitationCodeResp>): GenerateInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.createTime = 0;
        message.codes = [];
        if (value !== undefined)
            reflectionMergePartial<GenerateInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateInvitationCodeResp): GenerateInvitationCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 createTime */ 1:
                    message.createTime = reader.int64().toNumber();
                    break;
                case /* repeated string codes */ 2:
                    message.codes.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 createTime = 1; */
        if (message.createTime !== 0)
            writer.tag(1, WireType.Varint).int64(message.createTime);
        /* repeated string codes = 2; */
        for (let i = 0; i < message.codes.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.codes[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GenerateInvitationCodeResp
 */
export const GenerateInvitationCodeResp = new GenerateInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyInvitationCodeReq$Type extends MessageType<VerifyInvitationCodeReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.VerifyInvitationCodeReq", [
            { no: 1, name: "inviteCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyInvitationCodeReq>): VerifyInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.inviteCode = "";
        message.deviceId = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyInvitationCodeReq): VerifyInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string inviteCode */ 1:
                    message.inviteCode = reader.string();
                    break;
                case /* string deviceId */ 2:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string inviteCode = 1; */
        if (message.inviteCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.inviteCode);
        /* string deviceId = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.VerifyInvitationCodeReq
 */
export const VerifyInvitationCodeReq = new VerifyInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyInvitationCodeResp$Type extends MessageType<VerifyInvitationCodeResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.VerifyInvitationCodeResp", [
            { no: 1, name: "useTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyInvitationCodeResp>): VerifyInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.useTime = 0;
        if (value !== undefined)
            reflectionMergePartial<VerifyInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyInvitationCodeResp): VerifyInvitationCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 useTime */ 1:
                    message.useTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 useTime = 1; */
        if (message.useTime !== 0)
            writer.tag(1, WireType.Varint).int64(message.useTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.VerifyInvitationCodeResp
 */
export const VerifyInvitationCodeResp = new VerifyInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageListInvitationCodeReq$Type extends MessageType<PageListInvitationCodeReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.PageListInvitationCodeReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageListInvitationCodeReq>): PageListInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<PageListInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageListInvitationCodeReq): PageListInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageListInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PageListInvitationCodeReq
 */
export const PageListInvitationCodeReq = new PageListInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvitationCode$Type extends MessageType<InvitationCode> {
    constructor() {
        super("bpt.hall.userinfo.v1.InvitationCode", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "usedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "usedAccountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ownerAccountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InvitationCode>): InvitationCode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        message.createdAt = 0;
        message.usedAt = 0;
        message.usedAccountID = 0;
        message.ownerAccountID = 0;
        if (value !== undefined)
            reflectionMergePartial<InvitationCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvitationCode): InvitationCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int64 createdAt */ 2:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 usedAt */ 3:
                    message.usedAt = reader.int64().toNumber();
                    break;
                case /* int64 usedAccountID */ 4:
                    message.usedAccountID = reader.int64().toNumber();
                    break;
                case /* int64 ownerAccountID */ 5:
                    message.ownerAccountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvitationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int64 createdAt = 2; */
        if (message.createdAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.createdAt);
        /* int64 usedAt = 3; */
        if (message.usedAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.usedAt);
        /* int64 usedAccountID = 4; */
        if (message.usedAccountID !== 0)
            writer.tag(4, WireType.Varint).int64(message.usedAccountID);
        /* int64 ownerAccountID = 5; */
        if (message.ownerAccountID !== 0)
            writer.tag(5, WireType.Varint).int64(message.ownerAccountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.InvitationCode
 */
export const InvitationCode = new InvitationCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageListInvitationCodeResp$Type extends MessageType<PageListInvitationCodeResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.PageListInvitationCodeResp", [
            { no: 1, name: "invitationCodeList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvitationCode },
            { no: 3, name: "usedTotal", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "unusedTotal", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageListInvitationCodeResp>): PageListInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.invitationCodeList = [];
        message.usedTotal = 0;
        message.unusedTotal = 0;
        if (value !== undefined)
            reflectionMergePartial<PageListInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageListInvitationCodeResp): PageListInvitationCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.InvitationCode invitationCodeList */ 1:
                    message.invitationCodeList.push(InvitationCode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 usedTotal */ 3:
                    message.usedTotal = reader.int64().toNumber();
                    break;
                case /* int64 unusedTotal */ 4:
                    message.unusedTotal = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageListInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.InvitationCode invitationCodeList = 1; */
        for (let i = 0; i < message.invitationCodeList.length; i++)
            InvitationCode.internalBinaryWrite(message.invitationCodeList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 usedTotal = 3; */
        if (message.usedTotal !== 0)
            writer.tag(3, WireType.Varint).int64(message.usedTotal);
        /* int64 unusedTotal = 4; */
        if (message.unusedTotal !== 0)
            writer.tag(4, WireType.Varint).int64(message.unusedTotal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PageListInvitationCodeResp
 */
export const PageListInvitationCodeResp = new PageListInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Credentials$Type extends MessageType<Credentials> {
    constructor() {
        super("bpt.hall.userinfo.v1.Credentials", [
            { no: 1, name: "securityToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accessKeyID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "accessKeySecret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expire", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "bucket", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "dir", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "host", kind: "message", T: () => Host }
        ]);
    }
    create(value?: PartialMessage<Credentials>): Credentials {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.securityToken = "";
        message.accessKeyID = "";
        message.accessKeySecret = "";
        message.expire = 0;
        message.region = "";
        message.bucket = "";
        message.dir = "";
        if (value !== undefined)
            reflectionMergePartial<Credentials>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Credentials): Credentials {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string securityToken */ 1:
                    message.securityToken = reader.string();
                    break;
                case /* string accessKeyID */ 2:
                    message.accessKeyID = reader.string();
                    break;
                case /* string accessKeySecret */ 3:
                    message.accessKeySecret = reader.string();
                    break;
                case /* int64 expire */ 4:
                    message.expire = reader.int64().toNumber();
                    break;
                case /* string region */ 5:
                    message.region = reader.string();
                    break;
                case /* string bucket */ 6:
                    message.bucket = reader.string();
                    break;
                case /* string dir */ 7:
                    message.dir = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.Host host */ 8:
                    message.host = Host.internalBinaryRead(reader, reader.uint32(), options, message.host);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Credentials, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string securityToken = 1; */
        if (message.securityToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.securityToken);
        /* string accessKeyID = 2; */
        if (message.accessKeyID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessKeyID);
        /* string accessKeySecret = 3; */
        if (message.accessKeySecret !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accessKeySecret);
        /* int64 expire = 4; */
        if (message.expire !== 0)
            writer.tag(4, WireType.Varint).int64(message.expire);
        /* string region = 5; */
        if (message.region !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.region);
        /* string bucket = 6; */
        if (message.bucket !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.bucket);
        /* string dir = 7; */
        if (message.dir !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.dir);
        /* bpt.hall.userinfo.v1.Host host = 8; */
        if (message.host)
            Host.internalBinaryWrite(message.host, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.Credentials
 */
export const Credentials = new Credentials$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Host$Type extends MessageType<Host> {
    constructor() {
        super("bpt.hall.userinfo.v1.Host", [
            { no: 1, name: "protocol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "URL", kind: "scalar", localName: "URL", jsonName: "URL", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Host>): Host {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.protocol = "";
        message.URL = "";
        if (value !== undefined)
            reflectionMergePartial<Host>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Host): Host {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string protocol */ 1:
                    message.protocol = reader.string();
                    break;
                case /* string URL = 2 [json_name = "URL"];*/ 2:
                    message.URL = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Host, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string protocol = 1; */
        if (message.protocol !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.protocol);
        /* string URL = 2 [json_name = "URL"]; */
        if (message.URL !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.URL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.Host
 */
export const Host = new Host$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSTSReq$Type extends MessageType<GetSTSReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetSTSReq", [
            { no: 1, name: "platform", kind: "enum", T: () => ["bpt.hall.userinfo.v1.GetSTSReq.Platform", GetSTSReq_Platform] }
        ]);
    }
    create(value?: PartialMessage<GetSTSReq>): GetSTSReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.platform = 0;
        if (value !== undefined)
            reflectionMergePartial<GetSTSReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSTSReq): GetSTSReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.GetSTSReq.Platform platform */ 1:
                    message.platform = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSTSReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.GetSTSReq.Platform platform = 1; */
        if (message.platform !== 0)
            writer.tag(1, WireType.Varint).int32(message.platform);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetSTSReq
 */
export const GetSTSReq = new GetSTSReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSTSResp$Type extends MessageType<GetSTSResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetSTSResp", [
            { no: 1, name: "platform", kind: "enum", T: () => ["bpt.hall.userinfo.v1.Platform", Platform] },
            { no: 2, name: "credentials", kind: "message", T: () => Credentials },
            { no: 3, name: "isOpenAudit", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetSTSResp>): GetSTSResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.platform = 0;
        message.isOpenAudit = false;
        if (value !== undefined)
            reflectionMergePartial<GetSTSResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSTSResp): GetSTSResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.Platform platform */ 1:
                    message.platform = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.Credentials credentials */ 2:
                    message.credentials = Credentials.internalBinaryRead(reader, reader.uint32(), options, message.credentials);
                    break;
                case /* bool isOpenAudit */ 3:
                    message.isOpenAudit = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSTSResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.Platform platform = 1; */
        if (message.platform !== 0)
            writer.tag(1, WireType.Varint).int32(message.platform);
        /* bpt.hall.userinfo.v1.Credentials credentials = 2; */
        if (message.credentials)
            Credentials.internalBinaryWrite(message.credentials, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool isOpenAudit = 3; */
        if (message.isOpenAudit !== false)
            writer.tag(3, WireType.Varint).bool(message.isOpenAudit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetSTSResp
 */
export const GetSTSResp = new GetSTSResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VipInfo$Type extends MessageType<VipInfo> {
    constructor() {
        super("bpt.hall.userinfo.v1.VipInfo", [
            { no: 1, name: "level", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<VipInfo>): VipInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.level = 0;
        if (value !== undefined)
            reflectionMergePartial<VipInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VipInfo): VipInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 level */ 1:
                    message.level = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VipInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).int32(message.level);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.VipInfo
 */
export const VipInfo = new VipInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Identity$Type extends MessageType<Identity> {
    constructor() {
        super("bpt.hall.userinfo.v1.Identity", [
            { no: 1, name: "level", kind: "enum", T: () => ["bpt.hall.userinfo.v1.Identity.IdentityLevel", Identity_IdentityLevel] },
            { no: 2, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.hall.userinfo.v1.Identity.IdentityType", Identity_IdentityType] }
        ]);
    }
    create(value?: PartialMessage<Identity>): Identity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.level = 0;
        message.types = [];
        if (value !== undefined)
            reflectionMergePartial<Identity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Identity): Identity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.Identity.IdentityLevel level */ 1:
                    message.level = reader.int32();
                    break;
                case /* repeated bpt.hall.userinfo.v1.Identity.IdentityType types */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Identity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.Identity.IdentityLevel level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).int32(message.level);
        /* repeated bpt.hall.userinfo.v1.Identity.IdentityType types = 2; */
        if (message.types.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.Identity
 */
export const Identity = new Identity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("bpt.hall.userinfo.v1.User", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 6, name: "avatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "roleType", kind: "enum", T: () => ["bpt.hall.userinfo.v1.RoleType", RoleType] },
            { no: 10, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "noviceStatus", kind: "enum", T: () => ["bpt.hall.userinfo.v1.NoviceStatus", NoviceStatus] },
            { no: 13, name: "bannedUntil", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "createAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "deactivateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "noviceExpireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "mutedUntil", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 20, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "gameConfig", kind: "message", T: () => GameConfig },
            { no: 22, name: "pubId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "accountId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 24, name: "passportInfo", kind: "message", T: () => PassportList },
            { no: 25, name: "avatarBlurHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "vipInfo", kind: "message", T: () => VipInfo },
            { no: 27, name: "tag", kind: "message", T: () => Tag },
            { no: 28, name: "userGameConfig", kind: "message", T: () => UserGameConfig },
            { no: 29, name: "identity", kind: "message", T: () => Identity },
            { no: 30, name: "nickUpnum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 31, name: "ninckUpdateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.nickname = "";
        message.gender = 0;
        message.avatar = "";
        message.roleType = 0;
        message.region = "";
        message.noviceStatus = 0;
        message.bannedUntil = 0;
        message.createAt = 0;
        message.updateAt = 0;
        message.deactivateAt = 0;
        message.noviceExpireAt = 0;
        message.mutedUntil = 0;
        message.signature = "";
        message.pubId = "";
        message.accountId = 0;
        message.avatarBlurHash = "";
        message.nickUpnum = 0;
        message.ninckUpdateAt = 0;
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string nickname */ 3:
                    message.nickname = reader.string();
                    break;
                case /* bpt.common.Gender gender */ 4:
                    message.gender = reader.int32();
                    break;
                case /* string avatar */ 6:
                    message.avatar = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.RoleType roleType */ 9:
                    message.roleType = reader.int32();
                    break;
                case /* string region */ 10:
                    message.region = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.NoviceStatus noviceStatus */ 11:
                    message.noviceStatus = reader.int32();
                    break;
                case /* int64 bannedUntil */ 13:
                    message.bannedUntil = reader.int64().toNumber();
                    break;
                case /* int64 createAt */ 14:
                    message.createAt = reader.int64().toNumber();
                    break;
                case /* int64 updateAt */ 15:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* int64 deactivateAt */ 16:
                    message.deactivateAt = reader.int64().toNumber();
                    break;
                case /* int64 noviceExpireAt */ 17:
                    message.noviceExpireAt = reader.int64().toNumber();
                    break;
                case /* int64 mutedUntil */ 18:
                    message.mutedUntil = reader.int64().toNumber();
                    break;
                case /* string signature */ 20:
                    message.signature = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.GameConfig gameConfig */ 21:
                    message.gameConfig = GameConfig.internalBinaryRead(reader, reader.uint32(), options, message.gameConfig);
                    break;
                case /* string pubId */ 22:
                    message.pubId = reader.string();
                    break;
                case /* int64 accountId */ 23:
                    message.accountId = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.PassportList passportInfo */ 24:
                    message.passportInfo = PassportList.internalBinaryRead(reader, reader.uint32(), options, message.passportInfo);
                    break;
                case /* string avatarBlurHash */ 25:
                    message.avatarBlurHash = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.VipInfo vipInfo */ 26:
                    message.vipInfo = VipInfo.internalBinaryRead(reader, reader.uint32(), options, message.vipInfo);
                    break;
                case /* bpt.hall.userinfo.v1.Tag tag */ 27:
                    message.tag = Tag.internalBinaryRead(reader, reader.uint32(), options, message.tag);
                    break;
                case /* bpt.hall.userinfo.v1.UserGameConfig userGameConfig */ 28:
                    message.userGameConfig = UserGameConfig.internalBinaryRead(reader, reader.uint32(), options, message.userGameConfig);
                    break;
                case /* bpt.hall.userinfo.v1.Identity identity */ 29:
                    message.identity = Identity.internalBinaryRead(reader, reader.uint32(), options, message.identity);
                    break;
                case /* uint32 nickUpnum */ 30:
                    message.nickUpnum = reader.uint32();
                    break;
                case /* int64 ninckUpdateAt */ 31:
                    message.ninckUpdateAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string nickname = 3; */
        if (message.nickname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.nickname);
        /* bpt.common.Gender gender = 4; */
        if (message.gender !== 0)
            writer.tag(4, WireType.Varint).int32(message.gender);
        /* string avatar = 6; */
        if (message.avatar !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.avatar);
        /* bpt.hall.userinfo.v1.RoleType roleType = 9; */
        if (message.roleType !== 0)
            writer.tag(9, WireType.Varint).int32(message.roleType);
        /* string region = 10; */
        if (message.region !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.region);
        /* bpt.hall.userinfo.v1.NoviceStatus noviceStatus = 11; */
        if (message.noviceStatus !== 0)
            writer.tag(11, WireType.Varint).int32(message.noviceStatus);
        /* int64 bannedUntil = 13; */
        if (message.bannedUntil !== 0)
            writer.tag(13, WireType.Varint).int64(message.bannedUntil);
        /* int64 createAt = 14; */
        if (message.createAt !== 0)
            writer.tag(14, WireType.Varint).int64(message.createAt);
        /* int64 updateAt = 15; */
        if (message.updateAt !== 0)
            writer.tag(15, WireType.Varint).int64(message.updateAt);
        /* int64 deactivateAt = 16; */
        if (message.deactivateAt !== 0)
            writer.tag(16, WireType.Varint).int64(message.deactivateAt);
        /* int64 noviceExpireAt = 17; */
        if (message.noviceExpireAt !== 0)
            writer.tag(17, WireType.Varint).int64(message.noviceExpireAt);
        /* int64 mutedUntil = 18; */
        if (message.mutedUntil !== 0)
            writer.tag(18, WireType.Varint).int64(message.mutedUntil);
        /* string signature = 20; */
        if (message.signature !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.signature);
        /* bpt.hall.userinfo.v1.GameConfig gameConfig = 21; */
        if (message.gameConfig)
            GameConfig.internalBinaryWrite(message.gameConfig, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* string pubId = 22; */
        if (message.pubId !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.pubId);
        /* int64 accountId = 23; */
        if (message.accountId !== 0)
            writer.tag(23, WireType.Varint).int64(message.accountId);
        /* bpt.hall.userinfo.v1.PassportList passportInfo = 24; */
        if (message.passportInfo)
            PassportList.internalBinaryWrite(message.passportInfo, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* string avatarBlurHash = 25; */
        if (message.avatarBlurHash !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.avatarBlurHash);
        /* bpt.hall.userinfo.v1.VipInfo vipInfo = 26; */
        if (message.vipInfo)
            VipInfo.internalBinaryWrite(message.vipInfo, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.Tag tag = 27; */
        if (message.tag)
            Tag.internalBinaryWrite(message.tag, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.UserGameConfig userGameConfig = 28; */
        if (message.userGameConfig)
            UserGameConfig.internalBinaryWrite(message.userGameConfig, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.Identity identity = 29; */
        if (message.identity)
            Identity.internalBinaryWrite(message.identity, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* uint32 nickUpnum = 30; */
        if (message.nickUpnum !== 0)
            writer.tag(30, WireType.Varint).uint32(message.nickUpnum);
        /* int64 ninckUpdateAt = 31; */
        if (message.ninckUpdateAt !== 0)
            writer.tag(31, WireType.Varint).int64(message.ninckUpdateAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassportList$Type extends MessageType<PassportList> {
    constructor() {
        super("bpt.hall.userinfo.v1.PassportList", [
            { no: 1, name: "type", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "relateID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PassportList>): PassportList {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.id = "";
        message.relateID = "";
        if (value !== undefined)
            reflectionMergePartial<PassportList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassportList): PassportList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* string relateID */ 3:
                    message.relateID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassportList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* string relateID = 3; */
        if (message.relateID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.relateID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PassportList
 */
export const PassportList = new PassportList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserGameConfig$Type extends MessageType<UserGameConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserGameConfig", [
            { no: 1, name: "gameConfig", kind: "message", T: () => GlobalGameConfig },
            { no: 2, name: "tableThemeConfig", kind: "message", T: () => TableThemeConfig },
            { no: 3, name: "practiceConfig", kind: "message", T: () => PracticeConfig }
        ]);
    }
    create(value?: PartialMessage<UserGameConfig>): UserGameConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserGameConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserGameConfig): UserGameConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.GlobalGameConfig gameConfig */ 1:
                    message.gameConfig = GlobalGameConfig.internalBinaryRead(reader, reader.uint32(), options, message.gameConfig);
                    break;
                case /* bpt.hall.userinfo.v1.TableThemeConfig tableThemeConfig */ 2:
                    message.tableThemeConfig = TableThemeConfig.internalBinaryRead(reader, reader.uint32(), options, message.tableThemeConfig);
                    break;
                case /* bpt.hall.userinfo.v1.PracticeConfig practiceConfig */ 3:
                    message.practiceConfig = PracticeConfig.internalBinaryRead(reader, reader.uint32(), options, message.practiceConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserGameConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.GlobalGameConfig gameConfig = 1; */
        if (message.gameConfig)
            GlobalGameConfig.internalBinaryWrite(message.gameConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.TableThemeConfig tableThemeConfig = 2; */
        if (message.tableThemeConfig)
            TableThemeConfig.internalBinaryWrite(message.tableThemeConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.PracticeConfig practiceConfig = 3; */
        if (message.practiceConfig)
            PracticeConfig.internalBinaryWrite(message.practiceConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserGameConfig
 */
export const UserGameConfig = new UserGameConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameConfig$Type extends MessageType<GameConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.GameConfig", [
            { no: 1, name: "autoBuyInState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "waitBigBlindState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 3, name: "tableViewListState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "voiceId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GameConfig>): GameConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.autoBuyInState = 0;
        message.waitBigBlindState = 0;
        message.tableViewListState = 0;
        message.voiceId = 0;
        if (value !== undefined)
            reflectionMergePartial<GameConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GameConfig): GameConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState autoBuyInState */ 1:
                    message.autoBuyInState = reader.int32();
                    break;
                case /* bpt.common.SwitchState waitBigBlindState */ 2:
                    message.waitBigBlindState = reader.int32();
                    break;
                case /* bpt.common.SwitchState tableViewListState */ 3:
                    message.tableViewListState = reader.int32();
                    break;
                case /* int64 voiceId */ 4:
                    message.voiceId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GameConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState autoBuyInState = 1; */
        if (message.autoBuyInState !== 0)
            writer.tag(1, WireType.Varint).int32(message.autoBuyInState);
        /* bpt.common.SwitchState waitBigBlindState = 2; */
        if (message.waitBigBlindState !== 0)
            writer.tag(2, WireType.Varint).int32(message.waitBigBlindState);
        /* bpt.common.SwitchState tableViewListState = 3; */
        if (message.tableViewListState !== 0)
            writer.tag(3, WireType.Varint).int32(message.tableViewListState);
        /* int64 voiceId = 4; */
        if (message.voiceId !== 0)
            writer.tag(4, WireType.Varint).int64(message.voiceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GameConfig
 */
export const GameConfig = new GameConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalGameConfig$Type extends MessageType<GlobalGameConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.GlobalGameConfig", [
            { no: 1, name: "tableViewListState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "giftEffectState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 3, name: "interactivePropsEffectState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "chipDisplayModeState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "spectatorsChatState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 6, name: "vibrationState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 7, name: "bgmState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 8, name: "healthMonitorState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 9, name: "soundEffectState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 10, name: "voiceEffectState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 11, name: "showdownShowCardState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 12, name: "defaultVoiceState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<GlobalGameConfig>): GlobalGameConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableViewListState = 0;
        message.giftEffectState = 0;
        message.interactivePropsEffectState = 0;
        message.chipDisplayModeState = 0;
        message.spectatorsChatState = 0;
        message.vibrationState = 0;
        message.bgmState = 0;
        message.healthMonitorState = 0;
        message.soundEffectState = 0;
        message.voiceEffectState = 0;
        message.showdownShowCardState = 0;
        message.defaultVoiceState = 0;
        if (value !== undefined)
            reflectionMergePartial<GlobalGameConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GlobalGameConfig): GlobalGameConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState tableViewListState */ 1:
                    message.tableViewListState = reader.int32();
                    break;
                case /* bpt.common.SwitchState giftEffectState */ 2:
                    message.giftEffectState = reader.int32();
                    break;
                case /* bpt.common.SwitchState interactivePropsEffectState */ 3:
                    message.interactivePropsEffectState = reader.int32();
                    break;
                case /* bpt.common.SwitchState chipDisplayModeState */ 4:
                    message.chipDisplayModeState = reader.int32();
                    break;
                case /* bpt.common.SwitchState spectatorsChatState */ 5:
                    message.spectatorsChatState = reader.int32();
                    break;
                case /* bpt.common.SwitchState vibrationState */ 6:
                    message.vibrationState = reader.int32();
                    break;
                case /* bpt.common.SwitchState bgmState */ 7:
                    message.bgmState = reader.int32();
                    break;
                case /* bpt.common.SwitchState healthMonitorState */ 8:
                    message.healthMonitorState = reader.int32();
                    break;
                case /* bpt.common.SwitchState soundEffectState */ 9:
                    message.soundEffectState = reader.int32();
                    break;
                case /* bpt.common.SwitchState voiceEffectState */ 10:
                    message.voiceEffectState = reader.int32();
                    break;
                case /* bpt.common.SwitchState showdownShowCardState */ 11:
                    message.showdownShowCardState = reader.int32();
                    break;
                case /* bpt.common.SwitchState defaultVoiceState */ 12:
                    message.defaultVoiceState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GlobalGameConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState tableViewListState = 1; */
        if (message.tableViewListState !== 0)
            writer.tag(1, WireType.Varint).int32(message.tableViewListState);
        /* bpt.common.SwitchState giftEffectState = 2; */
        if (message.giftEffectState !== 0)
            writer.tag(2, WireType.Varint).int32(message.giftEffectState);
        /* bpt.common.SwitchState interactivePropsEffectState = 3; */
        if (message.interactivePropsEffectState !== 0)
            writer.tag(3, WireType.Varint).int32(message.interactivePropsEffectState);
        /* bpt.common.SwitchState chipDisplayModeState = 4; */
        if (message.chipDisplayModeState !== 0)
            writer.tag(4, WireType.Varint).int32(message.chipDisplayModeState);
        /* bpt.common.SwitchState spectatorsChatState = 5; */
        if (message.spectatorsChatState !== 0)
            writer.tag(5, WireType.Varint).int32(message.spectatorsChatState);
        /* bpt.common.SwitchState vibrationState = 6; */
        if (message.vibrationState !== 0)
            writer.tag(6, WireType.Varint).int32(message.vibrationState);
        /* bpt.common.SwitchState bgmState = 7; */
        if (message.bgmState !== 0)
            writer.tag(7, WireType.Varint).int32(message.bgmState);
        /* bpt.common.SwitchState healthMonitorState = 8; */
        if (message.healthMonitorState !== 0)
            writer.tag(8, WireType.Varint).int32(message.healthMonitorState);
        /* bpt.common.SwitchState soundEffectState = 9; */
        if (message.soundEffectState !== 0)
            writer.tag(9, WireType.Varint).int32(message.soundEffectState);
        /* bpt.common.SwitchState voiceEffectState = 10; */
        if (message.voiceEffectState !== 0)
            writer.tag(10, WireType.Varint).int32(message.voiceEffectState);
        /* bpt.common.SwitchState showdownShowCardState = 11; */
        if (message.showdownShowCardState !== 0)
            writer.tag(11, WireType.Varint).int32(message.showdownShowCardState);
        /* bpt.common.SwitchState defaultVoiceState = 12; */
        if (message.defaultVoiceState !== 0)
            writer.tag(12, WireType.Varint).int32(message.defaultVoiceState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GlobalGameConfig
 */
export const GlobalGameConfig = new GlobalGameConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableThemeConfig$Type extends MessageType<TableThemeConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.TableThemeConfig", [
            { no: 1, name: "voiceId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "cardId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "tableBgId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "buttonId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "cardBackId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TableThemeConfig>): TableThemeConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.voiceId = 0;
        message.cardId = 0;
        message.tableBgId = 0;
        message.buttonId = 0;
        message.cardBackId = 0;
        if (value !== undefined)
            reflectionMergePartial<TableThemeConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableThemeConfig): TableThemeConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 voiceId */ 1:
                    message.voiceId = reader.int64().toNumber();
                    break;
                case /* int64 cardId */ 2:
                    message.cardId = reader.int64().toNumber();
                    break;
                case /* int64 tableBgId */ 3:
                    message.tableBgId = reader.int64().toNumber();
                    break;
                case /* int64 buttonId */ 4:
                    message.buttonId = reader.int64().toNumber();
                    break;
                case /* int64 cardBackId */ 5:
                    message.cardBackId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableThemeConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 voiceId = 1; */
        if (message.voiceId !== 0)
            writer.tag(1, WireType.Varint).int64(message.voiceId);
        /* int64 cardId = 2; */
        if (message.cardId !== 0)
            writer.tag(2, WireType.Varint).int64(message.cardId);
        /* int64 tableBgId = 3; */
        if (message.tableBgId !== 0)
            writer.tag(3, WireType.Varint).int64(message.tableBgId);
        /* int64 buttonId = 4; */
        if (message.buttonId !== 0)
            writer.tag(4, WireType.Varint).int64(message.buttonId);
        /* int64 cardBackId = 5; */
        if (message.cardBackId !== 0)
            writer.tag(5, WireType.Varint).int64(message.cardBackId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.TableThemeConfig
 */
export const TableThemeConfig = new TableThemeConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PracticeConfig$Type extends MessageType<PracticeConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.PracticeConfig", [
            { no: 1, name: "autoBuyInState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "waitBigBlindState", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<PracticeConfig>): PracticeConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.autoBuyInState = 0;
        message.waitBigBlindState = 0;
        if (value !== undefined)
            reflectionMergePartial<PracticeConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PracticeConfig): PracticeConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState autoBuyInState */ 1:
                    message.autoBuyInState = reader.int32();
                    break;
                case /* bpt.common.SwitchState waitBigBlindState */ 2:
                    message.waitBigBlindState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PracticeConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState autoBuyInState = 1; */
        if (message.autoBuyInState !== 0)
            writer.tag(1, WireType.Varint).int32(message.autoBuyInState);
        /* bpt.common.SwitchState waitBigBlindState = 2; */
        if (message.waitBigBlindState !== 0)
            writer.tag(2, WireType.Varint).int32(message.waitBigBlindState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PracticeConfig
 */
export const PracticeConfig = new PracticeConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserStyleStat$Type extends MessageType<UserStyleStat> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserStyleStat", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "AFRate", kind: "scalar", localName: "AFRate", jsonName: "AFRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "VPIPRate", kind: "scalar", localName: "VPIPRate", jsonName: "VPIPRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "PFRRate", kind: "scalar", localName: "PFRRate", jsonName: "PFRRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ThreeBETRate", kind: "scalar", localName: "ThreeBETRate", jsonName: "ThreeBETRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "PreFlopFoldRate", kind: "scalar", localName: "PreFlopFoldRate", jsonName: "PreFlopFoldRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "FlopFoldRate", kind: "scalar", localName: "FlopFoldRate", jsonName: "FlopFoldRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "TurnFoldRate", kind: "scalar", localName: "TurnFoldRate", jsonName: "TurnFoldRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "RiverFoldRate", kind: "scalar", localName: "RiverFoldRate", jsonName: "RiverFoldRate", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "PrizePoolCnt", kind: "scalar", localName: "PrizePoolCnt", jsonName: "PrizePoolCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "ThirdPlaceCnt", kind: "scalar", localName: "ThirdPlaceCnt", jsonName: "ThirdPlaceCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "SecondPlaceCnt", kind: "scalar", localName: "SecondPlaceCnt", jsonName: "SecondPlaceCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "FirstPlaceCnt", kind: "scalar", localName: "FirstPlaceCnt", jsonName: "FirstPlaceCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "BestHand", kind: "scalar", localName: "BestHand", jsonName: "BestHand", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 16, name: "InTheMoneyCnt", kind: "scalar", localName: "InTheMoneyCnt", jsonName: "InTheMoneyCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "JoinMatchCnt", kind: "scalar", localName: "JoinMatchCnt", jsonName: "JoinMatchCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "TotalPrizeMTT", kind: "scalar", localName: "TotalPrizeMTT", jsonName: "TotalPrizeMTT", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 19, name: "EnterFinalTableCnt", kind: "scalar", localName: "EnterFinalTableCnt", jsonName: "EnterFinalTableCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 20, name: "HoleCardsCnt", kind: "scalar", localName: "HoleCardsCnt", jsonName: "HoleCardsCnt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 21, name: "PrizeMTTCnt", kind: "scalar", localName: "PrizeMTTCnt", jsonName: "PrizeMTTCnt", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "receivedLikeCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UserStyleStat>): UserStyleStat {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.AFRate = 0;
        message.VPIPRate = 0;
        message.PFRRate = 0;
        message.ThreeBETRate = 0;
        message.PreFlopFoldRate = 0;
        message.FlopFoldRate = 0;
        message.TurnFoldRate = 0;
        message.RiverFoldRate = 0;
        message.PrizePoolCnt = 0;
        message.ThirdPlaceCnt = 0;
        message.SecondPlaceCnt = 0;
        message.FirstPlaceCnt = 0;
        message.BestHand = [];
        message.InTheMoneyCnt = 0;
        message.JoinMatchCnt = 0;
        message.TotalPrizeMTT = 0;
        message.EnterFinalTableCnt = 0;
        message.HoleCardsCnt = 0;
        message.PrizeMTTCnt = "";
        message.receivedLikeCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<UserStyleStat>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserStyleStat): UserStyleStat {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 AFRate = 2 [json_name = "AFRate"];*/ 2:
                    message.AFRate = reader.int64().toNumber();
                    break;
                case /* int64 VPIPRate = 3 [json_name = "VPIPRate"];*/ 3:
                    message.VPIPRate = reader.int64().toNumber();
                    break;
                case /* int64 PFRRate = 4 [json_name = "PFRRate"];*/ 4:
                    message.PFRRate = reader.int64().toNumber();
                    break;
                case /* int64 ThreeBETRate = 5 [json_name = "ThreeBETRate"];*/ 5:
                    message.ThreeBETRate = reader.int64().toNumber();
                    break;
                case /* int64 PreFlopFoldRate = 6 [json_name = "PreFlopFoldRate"];*/ 6:
                    message.PreFlopFoldRate = reader.int64().toNumber();
                    break;
                case /* int64 FlopFoldRate = 7 [json_name = "FlopFoldRate"];*/ 7:
                    message.FlopFoldRate = reader.int64().toNumber();
                    break;
                case /* int64 TurnFoldRate = 8 [json_name = "TurnFoldRate"];*/ 8:
                    message.TurnFoldRate = reader.int64().toNumber();
                    break;
                case /* int64 RiverFoldRate = 9 [json_name = "RiverFoldRate"];*/ 9:
                    message.RiverFoldRate = reader.int64().toNumber();
                    break;
                case /* int64 PrizePoolCnt = 11 [json_name = "PrizePoolCnt"];*/ 11:
                    message.PrizePoolCnt = reader.int64().toNumber();
                    break;
                case /* int64 ThirdPlaceCnt = 12 [json_name = "ThirdPlaceCnt"];*/ 12:
                    message.ThirdPlaceCnt = reader.int64().toNumber();
                    break;
                case /* int64 SecondPlaceCnt = 13 [json_name = "SecondPlaceCnt"];*/ 13:
                    message.SecondPlaceCnt = reader.int64().toNumber();
                    break;
                case /* int64 FirstPlaceCnt = 14 [json_name = "FirstPlaceCnt"];*/ 14:
                    message.FirstPlaceCnt = reader.int64().toNumber();
                    break;
                case /* repeated uint32 BestHand = 15 [json_name = "BestHand"];*/ 15:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.BestHand.push(reader.uint32());
                    else
                        message.BestHand.push(reader.uint32());
                    break;
                case /* int64 InTheMoneyCnt = 16 [json_name = "InTheMoneyCnt"];*/ 16:
                    message.InTheMoneyCnt = reader.int64().toNumber();
                    break;
                case /* int64 JoinMatchCnt = 17 [json_name = "JoinMatchCnt"];*/ 17:
                    message.JoinMatchCnt = reader.int64().toNumber();
                    break;
                case /* int64 TotalPrizeMTT = 18 [json_name = "TotalPrizeMTT"];*/ 18:
                    message.TotalPrizeMTT = reader.int64().toNumber();
                    break;
                case /* int64 EnterFinalTableCnt = 19 [json_name = "EnterFinalTableCnt"];*/ 19:
                    message.EnterFinalTableCnt = reader.int64().toNumber();
                    break;
                case /* int64 HoleCardsCnt = 20 [json_name = "HoleCardsCnt"];*/ 20:
                    message.HoleCardsCnt = reader.int64().toNumber();
                    break;
                case /* string PrizeMTTCnt = 21 [json_name = "PrizeMTTCnt"];*/ 21:
                    message.PrizeMTTCnt = reader.string();
                    break;
                case /* int64 receivedLikeCnt */ 22:
                    message.receivedLikeCnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserStyleStat, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 AFRate = 2 [json_name = "AFRate"]; */
        if (message.AFRate !== 0)
            writer.tag(2, WireType.Varint).int64(message.AFRate);
        /* int64 VPIPRate = 3 [json_name = "VPIPRate"]; */
        if (message.VPIPRate !== 0)
            writer.tag(3, WireType.Varint).int64(message.VPIPRate);
        /* int64 PFRRate = 4 [json_name = "PFRRate"]; */
        if (message.PFRRate !== 0)
            writer.tag(4, WireType.Varint).int64(message.PFRRate);
        /* int64 ThreeBETRate = 5 [json_name = "ThreeBETRate"]; */
        if (message.ThreeBETRate !== 0)
            writer.tag(5, WireType.Varint).int64(message.ThreeBETRate);
        /* int64 PreFlopFoldRate = 6 [json_name = "PreFlopFoldRate"]; */
        if (message.PreFlopFoldRate !== 0)
            writer.tag(6, WireType.Varint).int64(message.PreFlopFoldRate);
        /* int64 FlopFoldRate = 7 [json_name = "FlopFoldRate"]; */
        if (message.FlopFoldRate !== 0)
            writer.tag(7, WireType.Varint).int64(message.FlopFoldRate);
        /* int64 TurnFoldRate = 8 [json_name = "TurnFoldRate"]; */
        if (message.TurnFoldRate !== 0)
            writer.tag(8, WireType.Varint).int64(message.TurnFoldRate);
        /* int64 RiverFoldRate = 9 [json_name = "RiverFoldRate"]; */
        if (message.RiverFoldRate !== 0)
            writer.tag(9, WireType.Varint).int64(message.RiverFoldRate);
        /* int64 PrizePoolCnt = 11 [json_name = "PrizePoolCnt"]; */
        if (message.PrizePoolCnt !== 0)
            writer.tag(11, WireType.Varint).int64(message.PrizePoolCnt);
        /* int64 ThirdPlaceCnt = 12 [json_name = "ThirdPlaceCnt"]; */
        if (message.ThirdPlaceCnt !== 0)
            writer.tag(12, WireType.Varint).int64(message.ThirdPlaceCnt);
        /* int64 SecondPlaceCnt = 13 [json_name = "SecondPlaceCnt"]; */
        if (message.SecondPlaceCnt !== 0)
            writer.tag(13, WireType.Varint).int64(message.SecondPlaceCnt);
        /* int64 FirstPlaceCnt = 14 [json_name = "FirstPlaceCnt"]; */
        if (message.FirstPlaceCnt !== 0)
            writer.tag(14, WireType.Varint).int64(message.FirstPlaceCnt);
        /* repeated uint32 BestHand = 15 [json_name = "BestHand"]; */
        if (message.BestHand.length) {
            writer.tag(15, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.BestHand.length; i++)
                writer.uint32(message.BestHand[i]);
            writer.join();
        }
        /* int64 InTheMoneyCnt = 16 [json_name = "InTheMoneyCnt"]; */
        if (message.InTheMoneyCnt !== 0)
            writer.tag(16, WireType.Varint).int64(message.InTheMoneyCnt);
        /* int64 JoinMatchCnt = 17 [json_name = "JoinMatchCnt"]; */
        if (message.JoinMatchCnt !== 0)
            writer.tag(17, WireType.Varint).int64(message.JoinMatchCnt);
        /* int64 TotalPrizeMTT = 18 [json_name = "TotalPrizeMTT"]; */
        if (message.TotalPrizeMTT !== 0)
            writer.tag(18, WireType.Varint).int64(message.TotalPrizeMTT);
        /* int64 EnterFinalTableCnt = 19 [json_name = "EnterFinalTableCnt"]; */
        if (message.EnterFinalTableCnt !== 0)
            writer.tag(19, WireType.Varint).int64(message.EnterFinalTableCnt);
        /* int64 HoleCardsCnt = 20 [json_name = "HoleCardsCnt"]; */
        if (message.HoleCardsCnt !== 0)
            writer.tag(20, WireType.Varint).int64(message.HoleCardsCnt);
        /* string PrizeMTTCnt = 21 [json_name = "PrizeMTTCnt"]; */
        if (message.PrizeMTTCnt !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.PrizeMTTCnt);
        /* int64 receivedLikeCnt = 22; */
        if (message.receivedLikeCnt !== 0)
            writer.tag(22, WireType.Varint).int64(message.receivedLikeCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserStyleStat
 */
export const UserStyleStat = new UserStyleStat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStyleStatReq$Type extends MessageType<GetUserStyleStatReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserStyleStatReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserStyleStatReq>): GetUserStyleStatReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserStyleStatReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserStyleStatReq): GetUserStyleStatReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserStyleStatReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserStyleStatReq
 */
export const GetUserStyleStatReq = new GetUserStyleStatReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserStyleStatResp$Type extends MessageType<GetUserStyleStatResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserStyleStatResp", [
            { no: 1, name: "stat", kind: "message", T: () => UserStyleStat }
        ]);
    }
    create(value?: PartialMessage<GetUserStyleStatResp>): GetUserStyleStatResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserStyleStatResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserStyleStatResp): GetUserStyleStatResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.UserStyleStat stat */ 1:
                    message.stat = UserStyleStat.internalBinaryRead(reader, reader.uint32(), options, message.stat);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserStyleStatResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.UserStyleStat stat = 1; */
        if (message.stat)
            UserStyleStat.internalBinaryWrite(message.stat, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserStyleStatResp
 */
export const GetUserStyleStatResp = new GetUserStyleStatResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserStyleStatReq$Type extends MessageType<BatchGetUserStyleStatReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserStyleStatReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserStyleStatReq>): BatchGetUserStyleStatReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserStyleStatReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserStyleStatReq): BatchGetUserStyleStatReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserStyleStatReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserStyleStatReq
 */
export const BatchGetUserStyleStatReq = new BatchGetUserStyleStatReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserStyleStatResp$Type extends MessageType<BatchGetUserStyleStatResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserStyleStatResp", [
            { no: 1, name: "statList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserStyleStat }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserStyleStatResp>): BatchGetUserStyleStatResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.statList = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserStyleStatResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserStyleStatResp): BatchGetUserStyleStatResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.UserStyleStat statList */ 1:
                    message.statList.push(UserStyleStat.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserStyleStatResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.UserStyleStat statList = 1; */
        for (let i = 0; i < message.statList.length; i++)
            UserStyleStat.internalBinaryWrite(message.statList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserStyleStatResp
 */
export const BatchGetUserStyleStatResp = new BatchGetUserStyleStatResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageReq$Type extends MessageType<PageReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.PageReq", [
            { no: 1, name: "index", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "size", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageReq>): PageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.index = 0;
        message.size = 0;
        if (value !== undefined)
            reflectionMergePartial<PageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageReq): PageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 index */ 1:
                    message.index = reader.int64().toNumber();
                    break;
                case /* int64 size */ 2:
                    message.size = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 index = 1; */
        if (message.index !== 0)
            writer.tag(1, WireType.Varint).int64(message.index);
        /* int64 size = 2; */
        if (message.size !== 0)
            writer.tag(2, WireType.Varint).int64(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PageReq
 */
export const PageReq = new PageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageResp$Type extends MessageType<PageResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.PageResp", [
            { no: 1, name: "index", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageResp>): PageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.index = 0;
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<PageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageResp): PageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 index */ 1:
                    message.index = reader.int64().toNumber();
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 index = 1; */
        if (message.index !== 0)
            writer.tag(1, WireType.Varint).int64(message.index);
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PageResp
 */
export const PageResp = new PageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegistryExtInfo$Type extends MessageType<RegistryExtInfo> {
    constructor() {
        super("bpt.hall.userinfo.v1.RegistryExtInfo", [
            { no: 1, name: "guid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deviceMode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "clientTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "clientVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "os", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegistryExtInfo>): RegistryExtInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.guid = "";
        message.deviceMode = "";
        message.clientTime = 0;
        message.clientVersion = "";
        message.latitude = 0;
        message.longitude = 0;
        message.language = "";
        message.os = "";
        message.channel = "";
        if (value !== undefined)
            reflectionMergePartial<RegistryExtInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegistryExtInfo): RegistryExtInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string guid */ 1:
                    message.guid = reader.string();
                    break;
                case /* string deviceMode */ 3:
                    message.deviceMode = reader.string();
                    break;
                case /* int64 clientTime */ 4:
                    message.clientTime = reader.int64().toNumber();
                    break;
                case /* string clientVersion */ 5:
                    message.clientVersion = reader.string();
                    break;
                case /* float latitude */ 6:
                    message.latitude = reader.float();
                    break;
                case /* float longitude */ 7:
                    message.longitude = reader.float();
                    break;
                case /* string language */ 8:
                    message.language = reader.string();
                    break;
                case /* string os */ 9:
                    message.os = reader.string();
                    break;
                case /* string channel */ 10:
                    message.channel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegistryExtInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string guid = 1; */
        if (message.guid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.guid);
        /* string deviceMode = 3; */
        if (message.deviceMode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceMode);
        /* int64 clientTime = 4; */
        if (message.clientTime !== 0)
            writer.tag(4, WireType.Varint).int64(message.clientTime);
        /* string clientVersion = 5; */
        if (message.clientVersion !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.clientVersion);
        /* float latitude = 6; */
        if (message.latitude !== 0)
            writer.tag(6, WireType.Bit32).float(message.latitude);
        /* float longitude = 7; */
        if (message.longitude !== 0)
            writer.tag(7, WireType.Bit32).float(message.longitude);
        /* string language = 8; */
        if (message.language !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.language);
        /* string os = 9; */
        if (message.os !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.os);
        /* string channel = 10; */
        if (message.channel !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.channel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RegistryExtInfo
 */
export const RegistryExtInfo = new RegistryExtInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateUserReq$Type extends MessageType<CreateUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.CreateUserReq", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "extInfo", kind: "message", T: () => RegistryExtInfo }
        ]);
    }
    create(value?: PartialMessage<CreateUserReq>): CreateUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateUserReq): CreateUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* bpt.hall.userinfo.v1.RegistryExtInfo extInfo */ 2:
                    message.extInfo = RegistryExtInfo.internalBinaryRead(reader, reader.uint32(), options, message.extInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.RegistryExtInfo extInfo = 2; */
        if (message.extInfo)
            RegistryExtInfo.internalBinaryWrite(message.extInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CreateUserReq
 */
export const CreateUserReq = new CreateUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateUserResp$Type extends MessageType<CreateUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.CreateUserResp", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<CreateUserResp>): CreateUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateUserResp): CreateUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CreateUserResp
 */
export const CreateUserResp = new CreateUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitUserReq$Type extends MessageType<InitUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.InitUserReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InitUserReq>): InitUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<InitUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitUserReq): InitUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.InitUserReq
 */
export const InitUserReq = new InitUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitUserResp$Type extends MessageType<InitUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.InitUserResp", []);
    }
    create(value?: PartialMessage<InitUserResp>): InitUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<InitUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitUserResp): InitUserResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InitUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.InitUserResp
 */
export const InitUserResp = new InitUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserReq$Type extends MessageType<GetUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserReq>): GetUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserReq): GetUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserReq
 */
export const GetUserReq = new GetUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserResp$Type extends MessageType<GetUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserResp", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetUserResp>): GetUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserResp): GetUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserResp
 */
export const GetUserResp = new GetUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserReq$Type extends MessageType<BatchGetUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserReq>): BatchGetUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserReq): BatchGetUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserReq
 */
export const BatchGetUserReq = new BatchGetUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserResp$Type extends MessageType<BatchGetUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetUserResp", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserResp>): BatchGetUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserResp): BatchGetUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetUserResp
 */
export const BatchGetUserResp = new BatchGetUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetSimpleUserReq$Type extends MessageType<BatchGetSimpleUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetSimpleUserReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetSimpleUserReq>): BatchGetSimpleUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetSimpleUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetSimpleUserReq): BatchGetSimpleUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetSimpleUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetSimpleUserReq
 */
export const BatchGetSimpleUserReq = new BatchGetSimpleUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetSimpleUserResp$Type extends MessageType<BatchGetSimpleUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetSimpleUserResp", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleUser }
        ]);
    }
    create(value?: PartialMessage<BatchGetSimpleUserResp>): BatchGetSimpleUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetSimpleUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetSimpleUserResp): BatchGetSimpleUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.SimpleUser users */ 1:
                    message.users.push(SimpleUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetSimpleUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.SimpleUser users = 1; */
        for (let i = 0; i < message.users.length; i++)
            SimpleUser.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetSimpleUserResp
 */
export const BatchGetSimpleUserResp = new BatchGetSimpleUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SimpleUser$Type extends MessageType<SimpleUser> {
    constructor() {
        super("bpt.hall.userinfo.v1.SimpleUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 4, name: "avatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pubId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "vipInfo", kind: "message", T: () => VipInfo },
            { no: 8, name: "tag", kind: "message", T: () => Tag }
        ]);
    }
    create(value?: PartialMessage<SimpleUser>): SimpleUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.nickname = "";
        message.gender = 0;
        message.avatar = "";
        message.signature = "";
        message.pubId = "";
        if (value !== undefined)
            reflectionMergePartial<SimpleUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SimpleUser): SimpleUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string nickname */ 2:
                    message.nickname = reader.string();
                    break;
                case /* bpt.common.Gender gender */ 3:
                    message.gender = reader.int32();
                    break;
                case /* string avatar */ 4:
                    message.avatar = reader.string();
                    break;
                case /* string signature */ 5:
                    message.signature = reader.string();
                    break;
                case /* string pubId */ 6:
                    message.pubId = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.VipInfo vipInfo */ 7:
                    message.vipInfo = VipInfo.internalBinaryRead(reader, reader.uint32(), options, message.vipInfo);
                    break;
                case /* bpt.hall.userinfo.v1.Tag tag */ 8:
                    message.tag = Tag.internalBinaryRead(reader, reader.uint32(), options, message.tag);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SimpleUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string nickname = 2; */
        if (message.nickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nickname);
        /* bpt.common.Gender gender = 3; */
        if (message.gender !== 0)
            writer.tag(3, WireType.Varint).int32(message.gender);
        /* string avatar = 4; */
        if (message.avatar !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.avatar);
        /* string signature = 5; */
        if (message.signature !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.signature);
        /* string pubId = 6; */
        if (message.pubId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.pubId);
        /* bpt.hall.userinfo.v1.VipInfo vipInfo = 7; */
        if (message.vipInfo)
            VipInfo.internalBinaryWrite(message.vipInfo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.Tag tag = 8; */
        if (message.tag)
            Tag.internalBinaryWrite(message.tag, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SimpleUser
 */
export const SimpleUser = new SimpleUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchSimpleUser$Type extends MessageType<SearchSimpleUser> {
    constructor() {
        super("bpt.hall.userinfo.v1.SearchSimpleUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 4, name: "avatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pubId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "vipInfo", kind: "message", T: () => VipInfo },
            { no: 8, name: "tag", kind: "message", T: () => Tag },
            { no: 9, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "transTimes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SearchSimpleUser>): SearchSimpleUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.nickname = "";
        message.gender = 0;
        message.avatar = "";
        message.signature = "";
        message.pubId = "";
        message.address = "";
        message.transTimes = 0;
        if (value !== undefined)
            reflectionMergePartial<SearchSimpleUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchSimpleUser): SearchSimpleUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string nickname */ 2:
                    message.nickname = reader.string();
                    break;
                case /* bpt.common.Gender gender */ 3:
                    message.gender = reader.int32();
                    break;
                case /* string avatar */ 4:
                    message.avatar = reader.string();
                    break;
                case /* string signature */ 5:
                    message.signature = reader.string();
                    break;
                case /* string pubId */ 6:
                    message.pubId = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.VipInfo vipInfo */ 7:
                    message.vipInfo = VipInfo.internalBinaryRead(reader, reader.uint32(), options, message.vipInfo);
                    break;
                case /* bpt.hall.userinfo.v1.Tag tag */ 8:
                    message.tag = Tag.internalBinaryRead(reader, reader.uint32(), options, message.tag);
                    break;
                case /* string address */ 9:
                    message.address = reader.string();
                    break;
                case /* int32 transTimes */ 10:
                    message.transTimes = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchSimpleUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string nickname = 2; */
        if (message.nickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nickname);
        /* bpt.common.Gender gender = 3; */
        if (message.gender !== 0)
            writer.tag(3, WireType.Varint).int32(message.gender);
        /* string avatar = 4; */
        if (message.avatar !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.avatar);
        /* string signature = 5; */
        if (message.signature !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.signature);
        /* string pubId = 6; */
        if (message.pubId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.pubId);
        /* bpt.hall.userinfo.v1.VipInfo vipInfo = 7; */
        if (message.vipInfo)
            VipInfo.internalBinaryWrite(message.vipInfo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.Tag tag = 8; */
        if (message.tag)
            Tag.internalBinaryWrite(message.tag, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string address = 9; */
        if (message.address !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.address);
        /* int32 transTimes = 10; */
        if (message.transTimes !== 0)
            writer.tag(10, WireType.Varint).int32(message.transTimes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SearchSimpleUser
 */
export const SearchSimpleUser = new SearchSimpleUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchUsersReq$Type extends MessageType<SearchUsersReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SearchUsersReq", [
            { no: 1, name: "page", kind: "message", T: () => PageReq },
            { no: 2, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "gender", kind: "enum", T: () => ["bpt.hall.userinfo.v1.GenderFilter", GenderFilter] },
            { no: 4, name: "idCardStatus", kind: "enum", T: () => ["bpt.hall.userinfo.v1.IdCardStatusFilter", IdCardStatusFilter] },
            { no: 5, name: "roleType", kind: "enum", T: () => ["bpt.hall.userinfo.v1.RoleTypeFilter", RoleTypeFilter] },
            { no: 6, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "phoneNumber", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "createAfter", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "createBefore", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SearchUsersReq>): SearchUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nickname = "";
        message.gender = 0;
        message.idCardStatus = 0;
        message.roleType = 0;
        message.uid = 0;
        message.phoneNumber = 0;
        message.createAfter = 0;
        message.createBefore = 0;
        if (value !== undefined)
            reflectionMergePartial<SearchUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchUsersReq): SearchUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageReq page */ 1:
                    message.page = PageReq.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* string nickname */ 2:
                    message.nickname = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.GenderFilter gender */ 3:
                    message.gender = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.IdCardStatusFilter idCardStatus */ 4:
                    message.idCardStatus = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.RoleTypeFilter roleType */ 5:
                    message.roleType = reader.int32();
                    break;
                case /* int64 uid */ 6:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 phoneNumber */ 7:
                    message.phoneNumber = reader.int64().toNumber();
                    break;
                case /* int64 createAfter */ 14:
                    message.createAfter = reader.int64().toNumber();
                    break;
                case /* int64 createBefore */ 15:
                    message.createBefore = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageReq page = 1; */
        if (message.page)
            PageReq.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string nickname = 2; */
        if (message.nickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nickname);
        /* bpt.hall.userinfo.v1.GenderFilter gender = 3; */
        if (message.gender !== 0)
            writer.tag(3, WireType.Varint).int32(message.gender);
        /* bpt.hall.userinfo.v1.IdCardStatusFilter idCardStatus = 4; */
        if (message.idCardStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.idCardStatus);
        /* bpt.hall.userinfo.v1.RoleTypeFilter roleType = 5; */
        if (message.roleType !== 0)
            writer.tag(5, WireType.Varint).int32(message.roleType);
        /* int64 uid = 6; */
        if (message.uid !== 0)
            writer.tag(6, WireType.Varint).int64(message.uid);
        /* int64 phoneNumber = 7; */
        if (message.phoneNumber !== 0)
            writer.tag(7, WireType.Varint).int64(message.phoneNumber);
        /* int64 createAfter = 14; */
        if (message.createAfter !== 0)
            writer.tag(14, WireType.Varint).int64(message.createAfter);
        /* int64 createBefore = 15; */
        if (message.createBefore !== 0)
            writer.tag(15, WireType.Varint).int64(message.createBefore);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SearchUsersReq
 */
export const SearchUsersReq = new SearchUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SearchUsersResp$Type extends MessageType<SearchUsersResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.SearchUsersResp", [
            { no: 1, name: "page", kind: "message", T: () => PageResp },
            { no: 2, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<SearchUsersResp>): SearchUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<SearchUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SearchUsersResp): SearchUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageResp page */ 1:
                    message.page = PageResp.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* repeated bpt.hall.userinfo.v1.User users */ 2:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SearchUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageResp page = 1; */
        if (message.page)
            PageResp.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.hall.userinfo.v1.User users = 2; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SearchUsersResp
 */
export const SearchUsersResp = new SearchUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FuzzySearchUsersReq$Type extends MessageType<FuzzySearchUsersReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.FuzzySearchUsersReq", [
            { no: 1, name: "page", kind: "message", T: () => PageReq },
            { no: 2, name: "keyword", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FuzzySearchUsersReq>): FuzzySearchUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.keyword = "";
        if (value !== undefined)
            reflectionMergePartial<FuzzySearchUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FuzzySearchUsersReq): FuzzySearchUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageReq page */ 1:
                    message.page = PageReq.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* string keyword */ 2:
                    message.keyword = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FuzzySearchUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageReq page = 1; */
        if (message.page)
            PageReq.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string keyword = 2; */
        if (message.keyword !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.keyword);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.FuzzySearchUsersReq
 */
export const FuzzySearchUsersReq = new FuzzySearchUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FuzzySearchUsersResp$Type extends MessageType<FuzzySearchUsersResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.FuzzySearchUsersResp", [
            { no: 2, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleUser }
        ]);
    }
    create(value?: PartialMessage<FuzzySearchUsersResp>): FuzzySearchUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<FuzzySearchUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FuzzySearchUsersResp): FuzzySearchUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.SimpleUser users */ 2:
                    message.users.push(SimpleUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FuzzySearchUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.SimpleUser users = 2; */
        for (let i = 0; i < message.users.length; i++)
            SimpleUser.internalBinaryWrite(message.users[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.FuzzySearchUsersResp
 */
export const FuzzySearchUsersResp = new FuzzySearchUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FuzzySearchClubUsersReq$Type extends MessageType<FuzzySearchClubUsersReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.FuzzySearchClubUsersReq", [
            { no: 1, name: "clubId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "page", kind: "message", T: () => PageReq },
            { no: 3, name: "keyword", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FuzzySearchClubUsersReq>): FuzzySearchClubUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.clubId = 0;
        message.keyword = "";
        if (value !== undefined)
            reflectionMergePartial<FuzzySearchClubUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FuzzySearchClubUsersReq): FuzzySearchClubUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 clubId */ 1:
                    message.clubId = reader.uint32();
                    break;
                case /* bpt.hall.userinfo.v1.PageReq page */ 2:
                    message.page = PageReq.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* string keyword */ 3:
                    message.keyword = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FuzzySearchClubUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 clubId = 1; */
        if (message.clubId !== 0)
            writer.tag(1, WireType.Varint).uint32(message.clubId);
        /* bpt.hall.userinfo.v1.PageReq page = 2; */
        if (message.page)
            PageReq.internalBinaryWrite(message.page, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string keyword = 3; */
        if (message.keyword !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.keyword);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.FuzzySearchClubUsersReq
 */
export const FuzzySearchClubUsersReq = new FuzzySearchClubUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FuzzySearchClubUsersResp$Type extends MessageType<FuzzySearchClubUsersResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.FuzzySearchClubUsersResp", [
            { no: 2, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleUser }
        ]);
    }
    create(value?: PartialMessage<FuzzySearchClubUsersResp>): FuzzySearchClubUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<FuzzySearchClubUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FuzzySearchClubUsersResp): FuzzySearchClubUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.SimpleUser users */ 2:
                    message.users.push(SimpleUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FuzzySearchClubUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.SimpleUser users = 2; */
        for (let i = 0; i < message.users.length; i++)
            SimpleUser.internalBinaryWrite(message.users[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.FuzzySearchClubUsersResp
 */
export const FuzzySearchClubUsersResp = new FuzzySearchClubUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WalletSearchUsersReq$Type extends MessageType<WalletSearchUsersReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.WalletSearchUsersReq", [
            { no: 1, name: "page", kind: "message", T: () => PageReq },
            { no: 2, name: "keyword", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isWallet", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<WalletSearchUsersReq>): WalletSearchUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.keyword = "";
        message.isWallet = false;
        if (value !== undefined)
            reflectionMergePartial<WalletSearchUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WalletSearchUsersReq): WalletSearchUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageReq page */ 1:
                    message.page = PageReq.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* string keyword */ 2:
                    message.keyword = reader.string();
                    break;
                case /* bool isWallet */ 3:
                    message.isWallet = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WalletSearchUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageReq page = 1; */
        if (message.page)
            PageReq.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string keyword = 2; */
        if (message.keyword !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.keyword);
        /* bool isWallet = 3; */
        if (message.isWallet !== false)
            writer.tag(3, WireType.Varint).bool(message.isWallet);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.WalletSearchUsersReq
 */
export const WalletSearchUsersReq = new WalletSearchUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WalletSearchUsersResp$Type extends MessageType<WalletSearchUsersResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.WalletSearchUsersResp", [
            { no: 2, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SearchSimpleUser }
        ]);
    }
    create(value?: PartialMessage<WalletSearchUsersResp>): WalletSearchUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<WalletSearchUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WalletSearchUsersResp): WalletSearchUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.SearchSimpleUser users */ 2:
                    message.users.push(SearchSimpleUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WalletSearchUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.SearchSimpleUser users = 2; */
        for (let i = 0; i < message.users.length; i++)
            SearchSimpleUser.internalBinaryWrite(message.users[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.WalletSearchUsersResp
 */
export const WalletSearchUsersResp = new WalletSearchUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyIdCardReq$Type extends MessageType<VerifyIdCardReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.VerifyIdCardReq", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "idNo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyIdCardReq>): VerifyIdCardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.idNo = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyIdCardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyIdCardReq): VerifyIdCardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string idNo */ 3:
                    message.idNo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyIdCardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string idNo = 3; */
        if (message.idNo !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.VerifyIdCardReq
 */
export const VerifyIdCardReq = new VerifyIdCardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyIdCardResp$Type extends MessageType<VerifyIdCardResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.VerifyIdCardResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.hall.userinfo.v1.IdCardVerifyResult", IdCardVerifyResult] },
            { no: 2, name: "status", kind: "enum", T: () => ["bpt.hall.userinfo.v1.IdCardStatus", IdCardStatus] },
            { no: 3, name: "retryLeft", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyIdCardResp>): VerifyIdCardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.status = 0;
        message.retryLeft = 0;
        if (value !== undefined)
            reflectionMergePartial<VerifyIdCardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyIdCardResp): VerifyIdCardResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.IdCardVerifyResult code */ 1:
                    message.code = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.IdCardStatus status */ 2:
                    message.status = reader.int32();
                    break;
                case /* int32 retryLeft */ 3:
                    message.retryLeft = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyIdCardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.IdCardVerifyResult code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* bpt.hall.userinfo.v1.IdCardStatus status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* int32 retryLeft = 3; */
        if (message.retryLeft !== 0)
            writer.tag(3, WireType.Varint).int32(message.retryLeft);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.VerifyIdCardResp
 */
export const VerifyIdCardResp = new VerifyIdCardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserIdCardInfoReq$Type extends MessageType<GetUserIdCardInfoReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserIdCardInfoReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserIdCardInfoReq>): GetUserIdCardInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserIdCardInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserIdCardInfoReq): GetUserIdCardInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserIdCardInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserIdCardInfoReq
 */
export const GetUserIdCardInfoReq = new GetUserIdCardInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserIdCardInfosByIdNoReq$Type extends MessageType<GetUserIdCardInfosByIdNoReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserIdCardInfosByIdNoReq", [
            { no: 1, name: "idNo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserIdCardInfosByIdNoReq>): GetUserIdCardInfosByIdNoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.idNo = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserIdCardInfosByIdNoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserIdCardInfosByIdNoReq): GetUserIdCardInfosByIdNoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string idNo */ 1:
                    message.idNo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserIdCardInfosByIdNoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string idNo = 1; */
        if (message.idNo !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserIdCardInfosByIdNoReq
 */
export const GetUserIdCardInfosByIdNoReq = new GetUserIdCardInfosByIdNoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserIdCardInfoReq$Type extends MessageType<RemoveUserIdCardInfoReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.RemoveUserIdCardInfoReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveUserIdCardInfoReq>): RemoveUserIdCardInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<RemoveUserIdCardInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserIdCardInfoReq): RemoveUserIdCardInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveUserIdCardInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardInfoReq
 */
export const RemoveUserIdCardInfoReq = new RemoveUserIdCardInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserIdCardInfoResp$Type extends MessageType<RemoveUserIdCardInfoResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.RemoveUserIdCardInfoResp", []);
    }
    create(value?: PartialMessage<RemoveUserIdCardInfoResp>): RemoveUserIdCardInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RemoveUserIdCardInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserIdCardInfoResp): RemoveUserIdCardInfoResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveUserIdCardInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardInfoResp
 */
export const RemoveUserIdCardInfoResp = new RemoveUserIdCardInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserIdCardRetryLimitReq$Type extends MessageType<RemoveUserIdCardRetryLimitReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.RemoveUserIdCardRetryLimitReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveUserIdCardRetryLimitReq>): RemoveUserIdCardRetryLimitReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<RemoveUserIdCardRetryLimitReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserIdCardRetryLimitReq): RemoveUserIdCardRetryLimitReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveUserIdCardRetryLimitReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardRetryLimitReq
 */
export const RemoveUserIdCardRetryLimitReq = new RemoveUserIdCardRetryLimitReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveUserIdCardRetryLimitResp$Type extends MessageType<RemoveUserIdCardRetryLimitResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.RemoveUserIdCardRetryLimitResp", []);
    }
    create(value?: PartialMessage<RemoveUserIdCardRetryLimitResp>): RemoveUserIdCardRetryLimitResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RemoveUserIdCardRetryLimitResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveUserIdCardRetryLimitResp): RemoveUserIdCardRetryLimitResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveUserIdCardRetryLimitResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RemoveUserIdCardRetryLimitResp
 */
export const RemoveUserIdCardRetryLimitResp = new RemoveUserIdCardRetryLimitResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserReq$Type extends MessageType<UpdateUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserReq", [
            { no: 1, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 3, name: "avatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateUserReq>): UpdateUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nickname = "";
        message.gender = 0;
        message.avatar = "";
        message.signature = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserReq): UpdateUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string nickname */ 1:
                    message.nickname = reader.string();
                    break;
                case /* bpt.common.Gender gender */ 2:
                    message.gender = reader.int32();
                    break;
                case /* string avatar */ 3:
                    message.avatar = reader.string();
                    break;
                case /* string signature */ 4:
                    message.signature = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string nickname = 1; */
        if (message.nickname !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nickname);
        /* bpt.common.Gender gender = 2; */
        if (message.gender !== 0)
            writer.tag(2, WireType.Varint).int32(message.gender);
        /* string avatar = 3; */
        if (message.avatar !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.avatar);
        /* string signature = 4; */
        if (message.signature !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.signature);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserReq
 */
export const UpdateUserReq = new UpdateUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserResp$Type extends MessageType<UpdateUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.hall.userinfo.v1.UserUpdateResult", UserUpdateResult] }
        ]);
    }
    create(value?: PartialMessage<UpdateUserResp>): UpdateUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserResp): UpdateUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.UserUpdateResult code */ 1:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.UserUpdateResult code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserResp
 */
export const UpdateUserResp = new UpdateUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserBySelfReq$Type extends MessageType<UpdateUserBySelfReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserBySelfReq", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 2, name: "fieldMask", kind: "message", T: () => FieldMask }
        ]);
    }
    create(value?: PartialMessage<UpdateUserBySelfReq>): UpdateUserBySelfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateUserBySelfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserBySelfReq): UpdateUserBySelfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* bpt.base.FieldMask fieldMask */ 2:
                    message.fieldMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.fieldMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserBySelfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.base.FieldMask fieldMask = 2; */
        if (message.fieldMask)
            FieldMask.internalBinaryWrite(message.fieldMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserBySelfReq
 */
export const UpdateUserBySelfReq = new UpdateUserBySelfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserBySelfResp$Type extends MessageType<UpdateUserBySelfResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserBySelfResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.hall.userinfo.v1.UserUpdateResult", UserUpdateResult] }
        ]);
    }
    create(value?: PartialMessage<UpdateUserBySelfResp>): UpdateUserBySelfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateUserBySelfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserBySelfResp): UpdateUserBySelfResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.UserUpdateResult code */ 1:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserBySelfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.UserUpdateResult code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserBySelfResp
 */
export const UpdateUserBySelfResp = new UpdateUserBySelfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNicknameUpdatePermissionReq$Type extends MessageType<GetNicknameUpdatePermissionReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetNicknameUpdatePermissionReq", []);
    }
    create(value?: PartialMessage<GetNicknameUpdatePermissionReq>): GetNicknameUpdatePermissionReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNicknameUpdatePermissionReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNicknameUpdatePermissionReq): GetNicknameUpdatePermissionReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetNicknameUpdatePermissionReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetNicknameUpdatePermissionReq
 */
export const GetNicknameUpdatePermissionReq = new GetNicknameUpdatePermissionReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNicknameUpdatePermissionResp$Type extends MessageType<GetNicknameUpdatePermissionResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetNicknameUpdatePermissionResp", [
            { no: 1, name: "freeUpdateNickname", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetNicknameUpdatePermissionResp>): GetNicknameUpdatePermissionResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.freeUpdateNickname = false;
        if (value !== undefined)
            reflectionMergePartial<GetNicknameUpdatePermissionResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNicknameUpdatePermissionResp): GetNicknameUpdatePermissionResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool freeUpdateNickname */ 1:
                    message.freeUpdateNickname = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNicknameUpdatePermissionResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool freeUpdateNickname = 1; */
        if (message.freeUpdateNickname !== false)
            writer.tag(1, WireType.Varint).bool(message.freeUpdateNickname);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetNicknameUpdatePermissionResp
 */
export const GetNicknameUpdatePermissionResp = new GetNicknameUpdatePermissionResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRandUidReq$Type extends MessageType<GetRandUidReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetRandUidReq", [
            { no: 1, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "roleType", kind: "enum", T: () => ["bpt.hall.userinfo.v1.RoleTypeFilter", RoleTypeFilter] }
        ]);
    }
    create(value?: PartialMessage<GetRandUidReq>): GetRandUidReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.num = 0;
        message.roleType = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRandUidReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRandUidReq): GetRandUidReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 num */ 1:
                    message.num = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.RoleTypeFilter roleType */ 2:
                    message.roleType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRandUidReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 num = 1; */
        if (message.num !== 0)
            writer.tag(1, WireType.Varint).int64(message.num);
        /* bpt.hall.userinfo.v1.RoleTypeFilter roleType = 2; */
        if (message.roleType !== 0)
            writer.tag(2, WireType.Varint).int32(message.roleType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetRandUidReq
 */
export const GetRandUidReq = new GetRandUidReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRandUidResp$Type extends MessageType<GetRandUidResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetRandUidResp", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetRandUidResp>): GetRandUidResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<GetRandUidResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRandUidResp): GetRandUidResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRandUidResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetRandUidResp
 */
export const GetRandUidResp = new GetRandUidResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountUserReq$Type extends MessageType<CountUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.CountUserReq", []);
    }
    create(value?: PartialMessage<CountUserReq>): CountUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CountUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountUserReq): CountUserReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CountUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CountUserReq
 */
export const CountUserReq = new CountUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountUserResp$Type extends MessageType<CountUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.CountUserResp", [
            { no: 1, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CountUserResp>): CountUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<CountUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountUserResp): CountUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 count */ 1:
                    message.count = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CountUserResp
 */
export const CountUserResp = new CountUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadCustomAvatarReq$Type extends MessageType<UploadCustomAvatarReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UploadCustomAvatarReq", [
            { no: 1, name: "customAvatarURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UploadCustomAvatarReq>): UploadCustomAvatarReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customAvatarURL = "";
        if (value !== undefined)
            reflectionMergePartial<UploadCustomAvatarReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadCustomAvatarReq): UploadCustomAvatarReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customAvatarURL */ 1:
                    message.customAvatarURL = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadCustomAvatarReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customAvatarURL = 1; */
        if (message.customAvatarURL !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customAvatarURL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UploadCustomAvatarReq
 */
export const UploadCustomAvatarReq = new UploadCustomAvatarReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadCustomAvatarResp$Type extends MessageType<UploadCustomAvatarResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UploadCustomAvatarResp", []);
    }
    create(value?: PartialMessage<UploadCustomAvatarResp>): UploadCustomAvatarResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UploadCustomAvatarResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadCustomAvatarResp): UploadCustomAvatarResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UploadCustomAvatarResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UploadCustomAvatarResp
 */
export const UploadCustomAvatarResp = new UploadCustomAvatarResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomAvatarReq$Type extends MessageType<GetCustomAvatarReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetCustomAvatarReq", []);
    }
    create(value?: PartialMessage<GetCustomAvatarReq>): GetCustomAvatarReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetCustomAvatarReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomAvatarReq): GetCustomAvatarReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetCustomAvatarReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetCustomAvatarReq
 */
export const GetCustomAvatarReq = new GetCustomAvatarReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomAvatarResp$Type extends MessageType<GetCustomAvatarResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetCustomAvatarResp", [
            { no: 1, name: "customAvatarURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomAvatarResp>): GetCustomAvatarResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.customAvatarURL = "";
        if (value !== undefined)
            reflectionMergePartial<GetCustomAvatarResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomAvatarResp): GetCustomAvatarResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string customAvatarURL */ 1:
                    message.customAvatarURL = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomAvatarResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string customAvatarURL = 1; */
        if (message.customAvatarURL !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.customAvatarURL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetCustomAvatarResp
 */
export const GetCustomAvatarResp = new GetCustomAvatarResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BanUserMail$Type extends MessageType<BanUserMail> {
    constructor() {
        super("bpt.hall.userinfo.v1.BanUserMail", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BanUserMail>): BanUserMail {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.content = "";
        if (value !== undefined)
            reflectionMergePartial<BanUserMail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BanUserMail): BanUserMail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BanUserMail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BanUserMail
 */
export const BanUserMail = new BanUserMail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BanUserReq$Type extends MessageType<BanUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.BanUserReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "reason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.BanReason", BanReason] },
            { no: 3, name: "banTimeSec", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "evidenceURLs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "manipulator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "forceOffline", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "mail", kind: "message", T: () => BanUserMail }
        ]);
    }
    create(value?: PartialMessage<BanUserReq>): BanUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.reason = 0;
        message.banTimeSec = 0;
        message.description = "";
        message.evidenceURLs = [];
        message.manipulator = "";
        message.forceOffline = false;
        if (value !== undefined)
            reflectionMergePartial<BanUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BanUserReq): BanUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* bpt.hall.userinfo.v1.BanReason reason */ 2:
                    message.reason = reader.int32();
                    break;
                case /* int64 banTimeSec */ 3:
                    message.banTimeSec = reader.int64().toNumber();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* repeated string evidenceURLs */ 6:
                    message.evidenceURLs.push(reader.string());
                    break;
                case /* string manipulator */ 7:
                    message.manipulator = reader.string();
                    break;
                case /* bool forceOffline */ 8:
                    message.forceOffline = reader.bool();
                    break;
                case /* bpt.hall.userinfo.v1.BanUserMail mail */ 9:
                    message.mail = BanUserMail.internalBinaryRead(reader, reader.uint32(), options, message.mail);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BanUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* bpt.hall.userinfo.v1.BanReason reason = 2; */
        if (message.reason !== 0)
            writer.tag(2, WireType.Varint).int32(message.reason);
        /* int64 banTimeSec = 3; */
        if (message.banTimeSec !== 0)
            writer.tag(3, WireType.Varint).int64(message.banTimeSec);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* repeated string evidenceURLs = 6; */
        for (let i = 0; i < message.evidenceURLs.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.evidenceURLs[i]);
        /* string manipulator = 7; */
        if (message.manipulator !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.manipulator);
        /* bool forceOffline = 8; */
        if (message.forceOffline !== false)
            writer.tag(8, WireType.Varint).bool(message.forceOffline);
        /* bpt.hall.userinfo.v1.BanUserMail mail = 9; */
        if (message.mail)
            BanUserMail.internalBinaryWrite(message.mail, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BanUserReq
 */
export const BanUserReq = new BanUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BanUserResp$Type extends MessageType<BanUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.BanUserResp", []);
    }
    create(value?: PartialMessage<BanUserResp>): BanUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<BanUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BanUserResp): BanUserResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BanUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BanUserResp
 */
export const BanUserResp = new BanUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnbanUserReq$Type extends MessageType<UnbanUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UnbanUserReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "reason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.UnbanReason", UnbanReason] },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "manipulator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UnbanUserReq>): UnbanUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.reason = 0;
        message.description = "";
        message.manipulator = "";
        if (value !== undefined)
            reflectionMergePartial<UnbanUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnbanUserReq): UnbanUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* bpt.hall.userinfo.v1.UnbanReason reason */ 3:
                    message.reason = reader.int32();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string manipulator */ 5:
                    message.manipulator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnbanUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* bpt.hall.userinfo.v1.UnbanReason reason = 3; */
        if (message.reason !== 0)
            writer.tag(3, WireType.Varint).int32(message.reason);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string manipulator = 5; */
        if (message.manipulator !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.manipulator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UnbanUserReq
 */
export const UnbanUserReq = new UnbanUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnbanUserResp$Type extends MessageType<UnbanUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UnbanUserResp", []);
    }
    create(value?: PartialMessage<UnbanUserResp>): UnbanUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UnbanUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnbanUserResp): UnbanUserResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UnbanUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UnbanUserResp
 */
export const UnbanUserResp = new UnbanUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserBanLog$Type extends MessageType<UserBanLog> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserBanLog", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "banStatus", kind: "enum", T: () => ["bpt.hall.userinfo.v1.BanStatus", BanStatus] },
            { no: 5, name: "banTimeSec", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "lockReason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.BanReason", BanReason] },
            { no: 7, name: "unlockReason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.UnbanReason", UnbanReason] },
            { no: 8, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "evidenceURLs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "manipulator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "mail", kind: "message", T: () => BanUserMail }
        ]);
    }
    create(value?: PartialMessage<UserBanLog>): UserBanLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.uids = [];
        message.banStatus = 0;
        message.banTimeSec = 0;
        message.lockReason = 0;
        message.unlockReason = 0;
        message.description = "";
        message.evidenceURLs = [];
        message.manipulator = "";
        message.updateAt = 0;
        if (value !== undefined)
            reflectionMergePartial<UserBanLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserBanLog): UserBanLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* bpt.hall.userinfo.v1.BanStatus banStatus */ 4:
                    message.banStatus = reader.int32();
                    break;
                case /* int64 banTimeSec */ 5:
                    message.banTimeSec = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.BanReason lockReason */ 6:
                    message.lockReason = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.UnbanReason unlockReason */ 7:
                    message.unlockReason = reader.int32();
                    break;
                case /* string description */ 8:
                    message.description = reader.string();
                    break;
                case /* repeated string evidenceURLs */ 9:
                    message.evidenceURLs.push(reader.string());
                    break;
                case /* string manipulator */ 10:
                    message.manipulator = reader.string();
                    break;
                case /* int64 updateAt */ 11:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.BanUserMail mail */ 12:
                    message.mail = BanUserMail.internalBinaryRead(reader, reader.uint32(), options, message.mail);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserBanLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* bpt.hall.userinfo.v1.BanStatus banStatus = 4; */
        if (message.banStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.banStatus);
        /* int64 banTimeSec = 5; */
        if (message.banTimeSec !== 0)
            writer.tag(5, WireType.Varint).int64(message.banTimeSec);
        /* bpt.hall.userinfo.v1.BanReason lockReason = 6; */
        if (message.lockReason !== 0)
            writer.tag(6, WireType.Varint).int32(message.lockReason);
        /* bpt.hall.userinfo.v1.UnbanReason unlockReason = 7; */
        if (message.unlockReason !== 0)
            writer.tag(7, WireType.Varint).int32(message.unlockReason);
        /* string description = 8; */
        if (message.description !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.description);
        /* repeated string evidenceURLs = 9; */
        for (let i = 0; i < message.evidenceURLs.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.evidenceURLs[i]);
        /* string manipulator = 10; */
        if (message.manipulator !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.manipulator);
        /* int64 updateAt = 11; */
        if (message.updateAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.updateAt);
        /* bpt.hall.userinfo.v1.BanUserMail mail = 12; */
        if (message.mail)
            BanUserMail.internalBinaryWrite(message.mail, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserBanLog
 */
export const UserBanLog = new UserBanLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBanLogsReq$Type extends MessageType<GetBanLogsReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetBanLogsReq", [
            { no: 1, name: "page", kind: "message", T: () => PageReq },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetBanLogsReq>): GetBanLogsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetBanLogsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBanLogsReq): GetBanLogsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageReq page */ 1:
                    message.page = PageReq.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBanLogsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageReq page = 1; */
        if (message.page)
            PageReq.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetBanLogsReq
 */
export const GetBanLogsReq = new GetBanLogsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBanLogsResp$Type extends MessageType<GetBanLogsResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetBanLogsResp", [
            { no: 1, name: "page", kind: "message", T: () => PageResp },
            { no: 2, name: "logs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserBanLog }
        ]);
    }
    create(value?: PartialMessage<GetBanLogsResp>): GetBanLogsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.logs = [];
        if (value !== undefined)
            reflectionMergePartial<GetBanLogsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBanLogsResp): GetBanLogsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageResp page */ 1:
                    message.page = PageResp.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* repeated bpt.hall.userinfo.v1.UserBanLog logs */ 2:
                    message.logs.push(UserBanLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBanLogsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageResp page = 1; */
        if (message.page)
            PageResp.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.hall.userinfo.v1.UserBanLog logs = 2; */
        for (let i = 0; i < message.logs.length; i++)
            UserBanLog.internalBinaryWrite(message.logs[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetBanLogsResp
 */
export const GetBanLogsResp = new GetBanLogsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserBanInfoReq$Type extends MessageType<GetUserBanInfoReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserBanInfoReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserBanInfoReq>): GetUserBanInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserBanInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserBanInfoReq): GetUserBanInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserBanInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserBanInfoReq
 */
export const GetUserBanInfoReq = new GetUserBanInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserBanInfoResp$Type extends MessageType<GetUserBanInfoResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserBanInfoResp", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "banReason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.BanReason", BanReason] },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserBanInfoResp>): GetUserBanInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.reason = "";
        message.startAt = 0;
        message.endAt = 0;
        message.banReason = 0;
        message.description = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserBanInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserBanInfoResp): GetUserBanInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string reason */ 2:
                    message.reason = reader.string();
                    break;
                case /* int64 startAt */ 3:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 4:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.BanReason banReason */ 5:
                    message.banReason = reader.int32();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserBanInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string reason = 2; */
        if (message.reason !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.reason);
        /* int64 startAt = 3; */
        if (message.startAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.startAt);
        /* int64 endAt = 4; */
        if (message.endAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.endAt);
        /* bpt.hall.userinfo.v1.BanReason banReason = 5; */
        if (message.banReason !== 0)
            writer.tag(5, WireType.Varint).int32(message.banReason);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserBanInfoResp
 */
export const GetUserBanInfoResp = new GetUserBanInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShippingAddr$Type extends MessageType<ShippingAddr> {
    constructor() {
        super("bpt.hall.userinfo.v1.ShippingAddr", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UID", kind: "scalar", localName: "UID", jsonName: "UID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "receiver", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "contactNumber", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "province", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "city", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "region", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "addr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "default", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ShippingAddr>): ShippingAddr {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.UID = 0;
        message.receiver = "";
        message.contactNumber = "";
        message.province = "";
        message.city = "";
        message.region = "";
        message.addr = "";
        message.default = 0;
        if (value !== undefined)
            reflectionMergePartial<ShippingAddr>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShippingAddr): ShippingAddr {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                case /* int64 UID = 2 [json_name = "UID"];*/ 2:
                    message.UID = reader.int64().toNumber();
                    break;
                case /* string receiver */ 3:
                    message.receiver = reader.string();
                    break;
                case /* string contactNumber */ 4:
                    message.contactNumber = reader.string();
                    break;
                case /* string province */ 5:
                    message.province = reader.string();
                    break;
                case /* string city */ 6:
                    message.city = reader.string();
                    break;
                case /* string region */ 7:
                    message.region = reader.string();
                    break;
                case /* string addr */ 8:
                    message.addr = reader.string();
                    break;
                case /* int32 default */ 9:
                    message.default = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShippingAddr, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        /* int64 UID = 2 [json_name = "UID"]; */
        if (message.UID !== 0)
            writer.tag(2, WireType.Varint).int64(message.UID);
        /* string receiver = 3; */
        if (message.receiver !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.receiver);
        /* string contactNumber = 4; */
        if (message.contactNumber !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.contactNumber);
        /* string province = 5; */
        if (message.province !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.province);
        /* string city = 6; */
        if (message.city !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.city);
        /* string region = 7; */
        if (message.region !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.region);
        /* string addr = 8; */
        if (message.addr !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.addr);
        /* int32 default = 9; */
        if (message.default !== 0)
            writer.tag(9, WireType.Varint).int32(message.default);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ShippingAddr
 */
export const ShippingAddr = new ShippingAddr$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetShippingAddrReq$Type extends MessageType<GetShippingAddrReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetShippingAddrReq", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetShippingAddrReq>): GetShippingAddrReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        if (value !== undefined)
            reflectionMergePartial<GetShippingAddrReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetShippingAddrReq): GetShippingAddrReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetShippingAddrReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetShippingAddrReq
 */
export const GetShippingAddrReq = new GetShippingAddrReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetShippingAddrResp$Type extends MessageType<GetShippingAddrResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetShippingAddrResp", [
            { no: 2, name: "info", kind: "message", T: () => ShippingAddr }
        ]);
    }
    create(value?: PartialMessage<GetShippingAddrResp>): GetShippingAddrResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetShippingAddrResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetShippingAddrResp): GetShippingAddrResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.ShippingAddr info */ 2:
                    message.info = ShippingAddr.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetShippingAddrResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.ShippingAddr info = 2; */
        if (message.info)
            ShippingAddr.internalBinaryWrite(message.info, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetShippingAddrResp
 */
export const GetShippingAddrResp = new GetShippingAddrResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListShippingAddrReq$Type extends MessageType<ListShippingAddrReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListShippingAddrReq", [
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListShippingAddrReq>): ListShippingAddrReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListShippingAddrReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListShippingAddrReq): ListShippingAddrReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListShippingAddrReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListShippingAddrReq
 */
export const ListShippingAddrReq = new ListShippingAddrReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListShippingAddrResp$Type extends MessageType<ListShippingAddrResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListShippingAddrResp", [
            { no: 2, name: "infos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ShippingAddr },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListShippingAddrResp>): ListShippingAddrResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.infos = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<ListShippingAddrResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListShippingAddrResp): ListShippingAddrResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.ShippingAddr infos */ 2:
                    message.infos.push(ShippingAddr.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListShippingAddrResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.ShippingAddr infos = 2; */
        for (let i = 0; i < message.infos.length; i++)
            ShippingAddr.internalBinaryWrite(message.infos[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListShippingAddrResp
 */
export const ListShippingAddrResp = new ListShippingAddrResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateShippingAddrReq$Type extends MessageType<CreateShippingAddrReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.CreateShippingAddrReq", [
            { no: 1, name: "info", kind: "message", T: () => ShippingAddr }
        ]);
    }
    create(value?: PartialMessage<CreateShippingAddrReq>): CreateShippingAddrReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateShippingAddrReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateShippingAddrReq): CreateShippingAddrReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.ShippingAddr info */ 1:
                    message.info = ShippingAddr.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateShippingAddrReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.ShippingAddr info = 1; */
        if (message.info)
            ShippingAddr.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CreateShippingAddrReq
 */
export const CreateShippingAddrReq = new CreateShippingAddrReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateShippingAddrResp$Type extends MessageType<CreateShippingAddrResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.CreateShippingAddrResp", [
            { no: 2, name: "info", kind: "message", T: () => ShippingAddr }
        ]);
    }
    create(value?: PartialMessage<CreateShippingAddrResp>): CreateShippingAddrResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateShippingAddrResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateShippingAddrResp): CreateShippingAddrResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.ShippingAddr info */ 2:
                    message.info = ShippingAddr.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateShippingAddrResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.ShippingAddr info = 2; */
        if (message.info)
            ShippingAddr.internalBinaryWrite(message.info, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CreateShippingAddrResp
 */
export const CreateShippingAddrResp = new CreateShippingAddrResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateShippingAddrReq$Type extends MessageType<UpdateShippingAddrReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateShippingAddrReq", [
            { no: 1, name: "info", kind: "message", T: () => ShippingAddr },
            { no: 2, name: "updateMask", kind: "message", T: () => FieldMask }
        ]);
    }
    create(value?: PartialMessage<UpdateShippingAddrReq>): UpdateShippingAddrReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateShippingAddrReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateShippingAddrReq): UpdateShippingAddrReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.ShippingAddr info */ 1:
                    message.info = ShippingAddr.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                case /* bpt.base.FieldMask updateMask */ 2:
                    message.updateMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.updateMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateShippingAddrReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.ShippingAddr info = 1; */
        if (message.info)
            ShippingAddr.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.base.FieldMask updateMask = 2; */
        if (message.updateMask)
            FieldMask.internalBinaryWrite(message.updateMask, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateShippingAddrReq
 */
export const UpdateShippingAddrReq = new UpdateShippingAddrReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateShippingAddrResp$Type extends MessageType<UpdateShippingAddrResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateShippingAddrResp", [
            { no: 2, name: "info", kind: "message", T: () => ShippingAddr }
        ]);
    }
    create(value?: PartialMessage<UpdateShippingAddrResp>): UpdateShippingAddrResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateShippingAddrResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateShippingAddrResp): UpdateShippingAddrResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.ShippingAddr info */ 2:
                    message.info = ShippingAddr.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateShippingAddrResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.ShippingAddr info = 2; */
        if (message.info)
            ShippingAddr.internalBinaryWrite(message.info, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateShippingAddrResp
 */
export const UpdateShippingAddrResp = new UpdateShippingAddrResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteShippingAddrReq$Type extends MessageType<DeleteShippingAddrReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeleteShippingAddrReq", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteShippingAddrReq>): DeleteShippingAddrReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteShippingAddrReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteShippingAddrReq): DeleteShippingAddrReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteShippingAddrReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeleteShippingAddrReq
 */
export const DeleteShippingAddrReq = new DeleteShippingAddrReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteShippingAddrResp$Type extends MessageType<DeleteShippingAddrResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeleteShippingAddrResp", []);
    }
    create(value?: PartialMessage<DeleteShippingAddrResp>): DeleteShippingAddrResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteShippingAddrResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteShippingAddrResp): DeleteShippingAddrResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteShippingAddrResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeleteShippingAddrResp
 */
export const DeleteShippingAddrResp = new DeleteShippingAddrResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetShippingAddrByUIDReq$Type extends MessageType<GetShippingAddrByUIDReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetShippingAddrByUIDReq", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "UID", kind: "scalar", localName: "UID", jsonName: "UID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetShippingAddrByUIDReq>): GetShippingAddrByUIDReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.UID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetShippingAddrByUIDReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetShippingAddrByUIDReq): GetShippingAddrByUIDReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                case /* int64 UID = 2 [json_name = "UID"];*/ 2:
                    message.UID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetShippingAddrByUIDReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        /* int64 UID = 2 [json_name = "UID"]; */
        if (message.UID !== 0)
            writer.tag(2, WireType.Varint).int64(message.UID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetShippingAddrByUIDReq
 */
export const GetShippingAddrByUIDReq = new GetShippingAddrByUIDReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetShippingAddrByUIDResp$Type extends MessageType<GetShippingAddrByUIDResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetShippingAddrByUIDResp", [
            { no: 2, name: "info", kind: "message", T: () => ShippingAddr }
        ]);
    }
    create(value?: PartialMessage<GetShippingAddrByUIDResp>): GetShippingAddrByUIDResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetShippingAddrByUIDResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetShippingAddrByUIDResp): GetShippingAddrByUIDResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.ShippingAddr info */ 2:
                    message.info = ShippingAddr.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetShippingAddrByUIDResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.ShippingAddr info = 2; */
        if (message.info)
            ShippingAddr.internalBinaryWrite(message.info, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetShippingAddrByUIDResp
 */
export const GetShippingAddrByUIDResp = new GetShippingAddrByUIDResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListShippingAddrByUIDReq$Type extends MessageType<ListShippingAddrByUIDReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListShippingAddrByUIDReq", [
            { no: 1, name: "UID", kind: "scalar", localName: "UID", jsonName: "UID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListShippingAddrByUIDReq>): ListShippingAddrByUIDReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.UID = 0;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListShippingAddrByUIDReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListShippingAddrByUIDReq): ListShippingAddrByUIDReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 UID = 1 [json_name = "UID"];*/ 1:
                    message.UID = reader.int64().toNumber();
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListShippingAddrByUIDReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 UID = 1 [json_name = "UID"]; */
        if (message.UID !== 0)
            writer.tag(1, WireType.Varint).int64(message.UID);
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListShippingAddrByUIDReq
 */
export const ListShippingAddrByUIDReq = new ListShippingAddrByUIDReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListShippingAddrByUIDResp$Type extends MessageType<ListShippingAddrByUIDResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListShippingAddrByUIDResp", [
            { no: 2, name: "infos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ShippingAddr },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListShippingAddrByUIDResp>): ListShippingAddrByUIDResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.infos = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<ListShippingAddrByUIDResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListShippingAddrByUIDResp): ListShippingAddrByUIDResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.ShippingAddr infos */ 2:
                    message.infos.push(ShippingAddr.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListShippingAddrByUIDResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.ShippingAddr infos = 2; */
        for (let i = 0; i < message.infos.length; i++)
            ShippingAddr.internalBinaryWrite(message.infos[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListShippingAddrByUIDResp
 */
export const ListShippingAddrByUIDResp = new ListShippingAddrByUIDResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateUserReq$Type extends MessageType<DeactivateUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeactivateUserReq", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "idNo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeactivateUserReq>): DeactivateUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.idNo = "";
        if (value !== undefined)
            reflectionMergePartial<DeactivateUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateUserReq): DeactivateUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string idNo */ 3:
                    message.idNo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string idNo = 3; */
        if (message.idNo !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeactivateUserReq
 */
export const DeactivateUserReq = new DeactivateUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateUserResp$Type extends MessageType<DeactivateUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeactivateUserResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.hall.userinfo.v1.Code", Code] }
        ]);
    }
    create(value?: PartialMessage<DeactivateUserResp>): DeactivateUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<DeactivateUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateUserResp): DeactivateUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.Code code */ 1:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.Code code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeactivateUserResp
 */
export const DeactivateUserResp = new DeactivateUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateUserByAdminReq$Type extends MessageType<DeactivateUserByAdminReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeactivateUserByAdminReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeactivateUserByAdminReq>): DeactivateUserByAdminReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<DeactivateUserByAdminReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateUserByAdminReq): DeactivateUserByAdminReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string operator */ 2:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateUserByAdminReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string operator = 2; */
        if (message.operator !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeactivateUserByAdminReq
 */
export const DeactivateUserByAdminReq = new DeactivateUserByAdminReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateUserByAdminResp$Type extends MessageType<DeactivateUserByAdminResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeactivateUserByAdminResp", []);
    }
    create(value?: PartialMessage<DeactivateUserByAdminResp>): DeactivateUserByAdminResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeactivateUserByAdminResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateUserByAdminResp): DeactivateUserByAdminResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeactivateUserByAdminResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeactivateUserByAdminResp
 */
export const DeactivateUserByAdminResp = new DeactivateUserByAdminResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassportRecord$Type extends MessageType<PassportRecord> {
    constructor() {
        super("bpt.hall.userinfo.v1.PassportRecord", [
            { no: 1, name: "phone", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PassportRecord>): PassportRecord {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.phone = "";
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<PassportRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassportRecord): PassportRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string phone */ 1:
                    message.phone = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassportRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string phone = 1; */
        if (message.phone !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.phone);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PassportRecord
 */
export const PassportRecord = new PassportRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeactivateLog$Type extends MessageType<DeactivateLog> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeactivateLog", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "user", kind: "message", T: () => User },
            { no: 3, name: "passport", kind: "message", T: () => PassportRecord },
            { no: 4, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "deactivateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeactivateLog>): DeactivateLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.operator = "";
        message.deactivateAt = 0;
        if (value !== undefined)
            reflectionMergePartial<DeactivateLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeactivateLog): DeactivateLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.User user */ 2:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* bpt.hall.userinfo.v1.PassportRecord passport */ 3:
                    message.passport = PassportRecord.internalBinaryRead(reader, reader.uint32(), options, message.passport);
                    break;
                case /* string operator */ 4:
                    message.operator = reader.string();
                    break;
                case /* int64 deactivateAt */ 5:
                    message.deactivateAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeactivateLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.hall.userinfo.v1.User user = 2; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.PassportRecord passport = 3; */
        if (message.passport)
            PassportRecord.internalBinaryWrite(message.passport, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string operator = 4; */
        if (message.operator !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.operator);
        /* int64 deactivateAt = 5; */
        if (message.deactivateAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.deactivateAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeactivateLog
 */
export const DeactivateLog = new DeactivateLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLatestUserDeactivateLogReq$Type extends MessageType<GetLatestUserDeactivateLogReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetLatestUserDeactivateLogReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetLatestUserDeactivateLogReq>): GetLatestUserDeactivateLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetLatestUserDeactivateLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLatestUserDeactivateLogReq): GetLatestUserDeactivateLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLatestUserDeactivateLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetLatestUserDeactivateLogReq
 */
export const GetLatestUserDeactivateLogReq = new GetLatestUserDeactivateLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLatestUserDeactivateLogResp$Type extends MessageType<GetLatestUserDeactivateLogResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetLatestUserDeactivateLogResp", [
            { no: 2, name: "status", kind: "enum", T: () => ["bpt.hall.userinfo.v1.DeactivateStatus", DeactivateStatus] },
            { no: 3, name: "log", kind: "message", T: () => DeactivateLog }
        ]);
    }
    create(value?: PartialMessage<GetLatestUserDeactivateLogResp>): GetLatestUserDeactivateLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<GetLatestUserDeactivateLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLatestUserDeactivateLogResp): GetLatestUserDeactivateLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.DeactivateStatus status */ 2:
                    message.status = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.DeactivateLog log */ 3:
                    message.log = DeactivateLog.internalBinaryRead(reader, reader.uint32(), options, message.log);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLatestUserDeactivateLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.DeactivateStatus status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* bpt.hall.userinfo.v1.DeactivateLog log = 3; */
        if (message.log)
            DeactivateLog.internalBinaryWrite(message.log, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetLatestUserDeactivateLogResp
 */
export const GetLatestUserDeactivateLogResp = new GetLatestUserDeactivateLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetNicknameReq$Type extends MessageType<ResetNicknameReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ResetNicknameReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetNicknameReq>): ResetNicknameReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<ResetNicknameReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetNicknameReq): ResetNicknameReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string operator */ 15:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetNicknameReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string operator = 15; */
        if (message.operator !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ResetNicknameReq
 */
export const ResetNicknameReq = new ResetNicknameReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetNicknameResp$Type extends MessageType<ResetNicknameResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ResetNicknameResp", []);
    }
    create(value?: PartialMessage<ResetNicknameResp>): ResetNicknameResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ResetNicknameResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetNicknameResp): ResetNicknameResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResetNicknameResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ResetNicknameResp
 */
export const ResetNicknameResp = new ResetNicknameResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetAvatarReq$Type extends MessageType<ResetAvatarReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ResetAvatarReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetAvatarReq>): ResetAvatarReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<ResetAvatarReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetAvatarReq): ResetAvatarReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string operator */ 15:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetAvatarReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string operator = 15; */
        if (message.operator !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ResetAvatarReq
 */
export const ResetAvatarReq = new ResetAvatarReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetAvatarResp$Type extends MessageType<ResetAvatarResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ResetAvatarResp", []);
    }
    create(value?: PartialMessage<ResetAvatarResp>): ResetAvatarResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ResetAvatarResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetAvatarResp): ResetAvatarResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResetAvatarResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ResetAvatarResp
 */
export const ResetAvatarResp = new ResetAvatarResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetNicknameBySensitiveTaskReq$Type extends MessageType<ResetNicknameBySensitiveTaskReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ResetNicknameBySensitiveTaskReq", [
            { no: 1, name: "resetNickname", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sensitiveWord", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "sensitiveVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetNicknameBySensitiveTaskReq>): ResetNicknameBySensitiveTaskReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.resetNickname = false;
        message.uid = 0;
        message.nickname = "";
        message.sensitiveWord = "";
        message.sensitiveVersion = "";
        message.source = "";
        if (value !== undefined)
            reflectionMergePartial<ResetNicknameBySensitiveTaskReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetNicknameBySensitiveTaskReq): ResetNicknameBySensitiveTaskReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool resetNickname */ 1:
                    message.resetNickname = reader.bool();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string nickname */ 3:
                    message.nickname = reader.string();
                    break;
                case /* string sensitiveWord */ 4:
                    message.sensitiveWord = reader.string();
                    break;
                case /* string sensitiveVersion */ 14:
                    message.sensitiveVersion = reader.string();
                    break;
                case /* string source */ 15:
                    message.source = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetNicknameBySensitiveTaskReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool resetNickname = 1; */
        if (message.resetNickname !== false)
            writer.tag(1, WireType.Varint).bool(message.resetNickname);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* string nickname = 3; */
        if (message.nickname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.nickname);
        /* string sensitiveWord = 4; */
        if (message.sensitiveWord !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.sensitiveWord);
        /* string sensitiveVersion = 14; */
        if (message.sensitiveVersion !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.sensitiveVersion);
        /* string source = 15; */
        if (message.source !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.source);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ResetNicknameBySensitiveTaskReq
 */
export const ResetNicknameBySensitiveTaskReq = new ResetNicknameBySensitiveTaskReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetNicknameBySensitiveTaskResp$Type extends MessageType<ResetNicknameBySensitiveTaskResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ResetNicknameBySensitiveTaskResp", []);
    }
    create(value?: PartialMessage<ResetNicknameBySensitiveTaskResp>): ResetNicknameBySensitiveTaskResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ResetNicknameBySensitiveTaskResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetNicknameBySensitiveTaskResp): ResetNicknameBySensitiveTaskResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResetNicknameBySensitiveTaskResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ResetNicknameBySensitiveTaskResp
 */
export const ResetNicknameBySensitiveTaskResp = new ResetNicknameBySensitiveTaskResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AvatarConfig$Type extends MessageType<AvatarConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.AvatarConfig", [
            { no: 1, name: "defaultAvatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "avatarList", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AvatarConfig>): AvatarConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.defaultAvatar = "";
        message.avatarList = [];
        if (value !== undefined)
            reflectionMergePartial<AvatarConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AvatarConfig): AvatarConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string defaultAvatar */ 1:
                    message.defaultAvatar = reader.string();
                    break;
                case /* repeated string avatarList */ 2:
                    message.avatarList.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AvatarConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string defaultAvatar = 1; */
        if (message.defaultAvatar !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.defaultAvatar);
        /* repeated string avatarList = 2; */
        for (let i = 0; i < message.avatarList.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.avatarList[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.AvatarConfig
 */
export const AvatarConfig = new AvatarConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfoConfig$Type extends MessageType<UserInfoConfig> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserInfoConfig", [
            { no: 2, name: "avatar", kind: "message", T: () => AvatarConfig }
        ]);
    }
    create(value?: PartialMessage<UserInfoConfig>): UserInfoConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserInfoConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInfoConfig): UserInfoConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.AvatarConfig avatar */ 2:
                    message.avatar = AvatarConfig.internalBinaryRead(reader, reader.uint32(), options, message.avatar);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserInfoConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.AvatarConfig avatar = 2; */
        if (message.avatar)
            AvatarConfig.internalBinaryWrite(message.avatar, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserInfoConfig
 */
export const UserInfoConfig = new UserInfoConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserInfoConfigReq$Type extends MessageType<GetUserInfoConfigReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserInfoConfigReq", []);
    }
    create(value?: PartialMessage<GetUserInfoConfigReq>): GetUserInfoConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserInfoConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserInfoConfigReq): GetUserInfoConfigReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetUserInfoConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserInfoConfigReq
 */
export const GetUserInfoConfigReq = new GetUserInfoConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserInfoConfigResp$Type extends MessageType<GetUserInfoConfigResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserInfoConfigResp", [
            { no: 2, name: "config", kind: "message", T: () => UserInfoConfig }
        ]);
    }
    create(value?: PartialMessage<GetUserInfoConfigResp>): GetUserInfoConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserInfoConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserInfoConfigResp): GetUserInfoConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.UserInfoConfig config */ 2:
                    message.config = UserInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserInfoConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.UserInfoConfig config = 2; */
        if (message.config)
            UserInfoConfig.internalBinaryWrite(message.config, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserInfoConfigResp
 */
export const GetUserInfoConfigResp = new GetUserInfoConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserInfoConfigReq$Type extends MessageType<UpdateUserInfoConfigReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserInfoConfigReq", [
            { no: 1, name: "config", kind: "message", T: () => UserInfoConfig },
            { no: 15, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateUserInfoConfigReq>): UpdateUserInfoConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateUserInfoConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserInfoConfigReq): UpdateUserInfoConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.UserInfoConfig config */ 1:
                    message.config = UserInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                case /* string operator */ 15:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserInfoConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.UserInfoConfig config = 1; */
        if (message.config)
            UserInfoConfig.internalBinaryWrite(message.config, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string operator = 15; */
        if (message.operator !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserInfoConfigReq
 */
export const UpdateUserInfoConfigReq = new UpdateUserInfoConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserInfoConfigResp$Type extends MessageType<UpdateUserInfoConfigResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserInfoConfigResp", []);
    }
    create(value?: PartialMessage<UpdateUserInfoConfigResp>): UpdateUserInfoConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateUserInfoConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserInfoConfigResp): UpdateUserInfoConfigResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateUserInfoConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserInfoConfigResp
 */
export const UpdateUserInfoConfigResp = new UpdateUserInfoConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateNicknameReq$Type extends MessageType<ValidateNicknameReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ValidateNicknameReq", [
            { no: 1, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ValidateNicknameReq>): ValidateNicknameReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nickname = "";
        if (value !== undefined)
            reflectionMergePartial<ValidateNicknameReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateNicknameReq): ValidateNicknameReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string nickname */ 1:
                    message.nickname = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ValidateNicknameReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string nickname = 1; */
        if (message.nickname !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nickname);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ValidateNicknameReq
 */
export const ValidateNicknameReq = new ValidateNicknameReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ValidateNicknameResp$Type extends MessageType<ValidateNicknameResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ValidateNicknameResp", []);
    }
    create(value?: PartialMessage<ValidateNicknameResp>): ValidateNicknameResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ValidateNicknameResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ValidateNicknameResp): ValidateNicknameResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ValidateNicknameResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ValidateNicknameResp
 */
export const ValidateNicknameResp = new ValidateNicknameResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MuteUserMail$Type extends MessageType<MuteUserMail> {
    constructor() {
        super("bpt.hall.userinfo.v1.MuteUserMail", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MuteUserMail>): MuteUserMail {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.content = "";
        if (value !== undefined)
            reflectionMergePartial<MuteUserMail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MuteUserMail): MuteUserMail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MuteUserMail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.MuteUserMail
 */
export const MuteUserMail = new MuteUserMail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MuteUserReq$Type extends MessageType<MuteUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.MuteUserReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "reason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.MuteReason", MuteReason] },
            { no: 3, name: "muteTimeSec", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "evidenceURLs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "manipulator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "forceOffline", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "mail", kind: "message", T: () => MuteUserMail }
        ]);
    }
    create(value?: PartialMessage<MuteUserReq>): MuteUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.reason = 0;
        message.muteTimeSec = 0;
        message.description = "";
        message.evidenceURLs = [];
        message.manipulator = "";
        message.forceOffline = false;
        if (value !== undefined)
            reflectionMergePartial<MuteUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MuteUserReq): MuteUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* bpt.hall.userinfo.v1.MuteReason reason */ 2:
                    message.reason = reader.int32();
                    break;
                case /* int64 muteTimeSec */ 3:
                    message.muteTimeSec = reader.int64().toNumber();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* repeated string evidenceURLs */ 6:
                    message.evidenceURLs.push(reader.string());
                    break;
                case /* string manipulator */ 7:
                    message.manipulator = reader.string();
                    break;
                case /* bool forceOffline */ 8:
                    message.forceOffline = reader.bool();
                    break;
                case /* bpt.hall.userinfo.v1.MuteUserMail mail */ 9:
                    message.mail = MuteUserMail.internalBinaryRead(reader, reader.uint32(), options, message.mail);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MuteUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* bpt.hall.userinfo.v1.MuteReason reason = 2; */
        if (message.reason !== 0)
            writer.tag(2, WireType.Varint).int32(message.reason);
        /* int64 muteTimeSec = 3; */
        if (message.muteTimeSec !== 0)
            writer.tag(3, WireType.Varint).int64(message.muteTimeSec);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* repeated string evidenceURLs = 6; */
        for (let i = 0; i < message.evidenceURLs.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.evidenceURLs[i]);
        /* string manipulator = 7; */
        if (message.manipulator !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.manipulator);
        /* bool forceOffline = 8; */
        if (message.forceOffline !== false)
            writer.tag(8, WireType.Varint).bool(message.forceOffline);
        /* bpt.hall.userinfo.v1.MuteUserMail mail = 9; */
        if (message.mail)
            MuteUserMail.internalBinaryWrite(message.mail, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.MuteUserReq
 */
export const MuteUserReq = new MuteUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MuteUserResp$Type extends MessageType<MuteUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.MuteUserResp", []);
    }
    create(value?: PartialMessage<MuteUserResp>): MuteUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MuteUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MuteUserResp): MuteUserResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MuteUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.MuteUserResp
 */
export const MuteUserResp = new MuteUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnmuteUserReq$Type extends MessageType<UnmuteUserReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UnmuteUserReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "reason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.UnmuteReason", UnmuteReason] },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "manipulator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UnmuteUserReq>): UnmuteUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.reason = 0;
        message.description = "";
        message.manipulator = "";
        if (value !== undefined)
            reflectionMergePartial<UnmuteUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnmuteUserReq): UnmuteUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* bpt.hall.userinfo.v1.UnmuteReason reason */ 3:
                    message.reason = reader.int32();
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* string manipulator */ 5:
                    message.manipulator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnmuteUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* bpt.hall.userinfo.v1.UnmuteReason reason = 3; */
        if (message.reason !== 0)
            writer.tag(3, WireType.Varint).int32(message.reason);
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* string manipulator = 5; */
        if (message.manipulator !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.manipulator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UnmuteUserReq
 */
export const UnmuteUserReq = new UnmuteUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnmuteUserResp$Type extends MessageType<UnmuteUserResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UnmuteUserResp", []);
    }
    create(value?: PartialMessage<UnmuteUserResp>): UnmuteUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UnmuteUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnmuteUserResp): UnmuteUserResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UnmuteUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UnmuteUserResp
 */
export const UnmuteUserResp = new UnmuteUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserMuteLog$Type extends MessageType<UserMuteLog> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserMuteLog", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "muteStatus", kind: "enum", T: () => ["bpt.hall.userinfo.v1.MuteStatus", MuteStatus] },
            { no: 5, name: "muteTimeSec", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "muteReason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.MuteReason", MuteReason] },
            { no: 7, name: "unmuteReason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.UnmuteReason", UnmuteReason] },
            { no: 8, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "evidenceURLs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "manipulator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "mail", kind: "message", T: () => MuteUserMail }
        ]);
    }
    create(value?: PartialMessage<UserMuteLog>): UserMuteLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.uids = [];
        message.muteStatus = 0;
        message.muteTimeSec = 0;
        message.muteReason = 0;
        message.unmuteReason = 0;
        message.description = "";
        message.evidenceURLs = [];
        message.manipulator = "";
        message.updateAt = 0;
        if (value !== undefined)
            reflectionMergePartial<UserMuteLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserMuteLog): UserMuteLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* bpt.hall.userinfo.v1.MuteStatus muteStatus */ 4:
                    message.muteStatus = reader.int32();
                    break;
                case /* int64 muteTimeSec */ 5:
                    message.muteTimeSec = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.MuteReason muteReason */ 6:
                    message.muteReason = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.UnmuteReason unmuteReason */ 7:
                    message.unmuteReason = reader.int32();
                    break;
                case /* string description */ 8:
                    message.description = reader.string();
                    break;
                case /* repeated string evidenceURLs */ 9:
                    message.evidenceURLs.push(reader.string());
                    break;
                case /* string manipulator */ 10:
                    message.manipulator = reader.string();
                    break;
                case /* int64 updateAt */ 11:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.MuteUserMail mail */ 12:
                    message.mail = MuteUserMail.internalBinaryRead(reader, reader.uint32(), options, message.mail);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserMuteLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* bpt.hall.userinfo.v1.MuteStatus muteStatus = 4; */
        if (message.muteStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.muteStatus);
        /* int64 muteTimeSec = 5; */
        if (message.muteTimeSec !== 0)
            writer.tag(5, WireType.Varint).int64(message.muteTimeSec);
        /* bpt.hall.userinfo.v1.MuteReason muteReason = 6; */
        if (message.muteReason !== 0)
            writer.tag(6, WireType.Varint).int32(message.muteReason);
        /* bpt.hall.userinfo.v1.UnmuteReason unmuteReason = 7; */
        if (message.unmuteReason !== 0)
            writer.tag(7, WireType.Varint).int32(message.unmuteReason);
        /* string description = 8; */
        if (message.description !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.description);
        /* repeated string evidenceURLs = 9; */
        for (let i = 0; i < message.evidenceURLs.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.evidenceURLs[i]);
        /* string manipulator = 10; */
        if (message.manipulator !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.manipulator);
        /* int64 updateAt = 11; */
        if (message.updateAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.updateAt);
        /* bpt.hall.userinfo.v1.MuteUserMail mail = 12; */
        if (message.mail)
            MuteUserMail.internalBinaryWrite(message.mail, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserMuteLog
 */
export const UserMuteLog = new UserMuteLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMuteLogsReq$Type extends MessageType<GetMuteLogsReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetMuteLogsReq", [
            { no: 1, name: "page", kind: "message", T: () => PageReq },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMuteLogsReq>): GetMuteLogsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMuteLogsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMuteLogsReq): GetMuteLogsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageReq page */ 1:
                    message.page = PageReq.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMuteLogsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageReq page = 1; */
        if (message.page)
            PageReq.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetMuteLogsReq
 */
export const GetMuteLogsReq = new GetMuteLogsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMuteLogsResp$Type extends MessageType<GetMuteLogsResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetMuteLogsResp", [
            { no: 1, name: "page", kind: "message", T: () => PageResp },
            { no: 2, name: "logs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserMuteLog }
        ]);
    }
    create(value?: PartialMessage<GetMuteLogsResp>): GetMuteLogsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.logs = [];
        if (value !== undefined)
            reflectionMergePartial<GetMuteLogsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMuteLogsResp): GetMuteLogsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageResp page */ 1:
                    message.page = PageResp.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* repeated bpt.hall.userinfo.v1.UserMuteLog logs */ 2:
                    message.logs.push(UserMuteLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMuteLogsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageResp page = 1; */
        if (message.page)
            PageResp.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.hall.userinfo.v1.UserMuteLog logs = 2; */
        for (let i = 0; i < message.logs.length; i++)
            UserMuteLog.internalBinaryWrite(message.logs[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetMuteLogsResp
 */
export const GetMuteLogsResp = new GetMuteLogsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserMuteInfoReq$Type extends MessageType<GetUserMuteInfoReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserMuteInfoReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserMuteInfoReq>): GetUserMuteInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserMuteInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserMuteInfoReq): GetUserMuteInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserMuteInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserMuteInfoReq
 */
export const GetUserMuteInfoReq = new GetUserMuteInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserMuteInfoResp$Type extends MessageType<GetUserMuteInfoResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserMuteInfoResp", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "muteReason", kind: "enum", T: () => ["bpt.hall.userinfo.v1.MuteReason", MuteReason] },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserMuteInfoResp>): GetUserMuteInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.reason = "";
        message.startAt = 0;
        message.endAt = 0;
        message.muteReason = 0;
        message.description = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserMuteInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserMuteInfoResp): GetUserMuteInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string reason */ 2:
                    message.reason = reader.string();
                    break;
                case /* int64 startAt */ 3:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 4:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.MuteReason muteReason */ 5:
                    message.muteReason = reader.int32();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserMuteInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string reason = 2; */
        if (message.reason !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.reason);
        /* int64 startAt = 3; */
        if (message.startAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.startAt);
        /* int64 endAt = 4; */
        if (message.endAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.endAt);
        /* bpt.hall.userinfo.v1.MuteReason muteReason = 5; */
        if (message.muteReason !== 0)
            writer.tag(5, WireType.Varint).int32(message.muteReason);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserMuteInfoResp
 */
export const GetUserMuteInfoResp = new GetUserMuteInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NicknameItem$Type extends MessageType<NicknameItem> {
    constructor() {
        super("bpt.hall.userinfo.v1.NicknameItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.hall.userinfo.v1.NicknameItemType", NicknameItemType] },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "updatedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<NicknameItem>): NicknameItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.gender = 0;
        message.type = 0;
        message.name = "";
        message.operator = "";
        message.createdAt = 0;
        message.updatedAt = 0;
        if (value !== undefined)
            reflectionMergePartial<NicknameItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NicknameItem): NicknameItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bpt.common.Gender gender */ 2:
                    message.gender = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.NicknameItemType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string operator */ 13:
                    message.operator = reader.string();
                    break;
                case /* int64 createdAt */ 14:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 updatedAt */ 15:
                    message.updatedAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NicknameItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bpt.common.Gender gender = 2; */
        if (message.gender !== 0)
            writer.tag(2, WireType.Varint).int32(message.gender);
        /* bpt.hall.userinfo.v1.NicknameItemType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string operator = 13; */
        if (message.operator !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.operator);
        /* int64 createdAt = 14; */
        if (message.createdAt !== 0)
            writer.tag(14, WireType.Varint).int64(message.createdAt);
        /* int64 updatedAt = 15; */
        if (message.updatedAt !== 0)
            writer.tag(15, WireType.Varint).int64(message.updatedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.NicknameItem
 */
export const NicknameItem = new NicknameItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadNicknameItemsReq$Type extends MessageType<UploadNicknameItemsReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UploadNicknameItemsReq", [
            { no: 2, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.hall.userinfo.v1.NicknameItemType", NicknameItemType] },
            { no: 4, name: "names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UploadNicknameItemsReq>): UploadNicknameItemsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.gender = 0;
        message.type = 0;
        message.names = [];
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<UploadNicknameItemsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadNicknameItemsReq): UploadNicknameItemsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.Gender gender */ 2:
                    message.gender = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.NicknameItemType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* repeated string names */ 4:
                    message.names.push(reader.string());
                    break;
                case /* string operator */ 15:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadNicknameItemsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.Gender gender = 2; */
        if (message.gender !== 0)
            writer.tag(2, WireType.Varint).int32(message.gender);
        /* bpt.hall.userinfo.v1.NicknameItemType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* repeated string names = 4; */
        for (let i = 0; i < message.names.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.names[i]);
        /* string operator = 15; */
        if (message.operator !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UploadNicknameItemsReq
 */
export const UploadNicknameItemsReq = new UploadNicknameItemsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadNicknameItemsResp$Type extends MessageType<UploadNicknameItemsResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UploadNicknameItemsResp", []);
    }
    create(value?: PartialMessage<UploadNicknameItemsResp>): UploadNicknameItemsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UploadNicknameItemsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadNicknameItemsResp): UploadNicknameItemsResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UploadNicknameItemsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UploadNicknameItemsResp
 */
export const UploadNicknameItemsResp = new UploadNicknameItemsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateNicknameItemReq$Type extends MessageType<UpdateNicknameItemReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateNicknameItemReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateNicknameItemReq>): UpdateNicknameItemReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateNicknameItemReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateNicknameItemReq): UpdateNicknameItemReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string operator */ 15:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateNicknameItemReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string operator = 15; */
        if (message.operator !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateNicknameItemReq
 */
export const UpdateNicknameItemReq = new UpdateNicknameItemReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateNicknameItemResp$Type extends MessageType<UpdateNicknameItemResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateNicknameItemResp", []);
    }
    create(value?: PartialMessage<UpdateNicknameItemResp>): UpdateNicknameItemResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateNicknameItemResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateNicknameItemResp): UpdateNicknameItemResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateNicknameItemResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateNicknameItemResp
 */
export const UpdateNicknameItemResp = new UpdateNicknameItemResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNicknameItemsReq$Type extends MessageType<ListNicknameItemsReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListNicknameItemsReq", [
            { no: 1, name: "page", kind: "message", T: () => PageReq },
            { no: 2, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.hall.userinfo.v1.NicknameItemType", NicknameItemType] },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListNicknameItemsReq>): ListNicknameItemsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.gender = 0;
        message.type = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<ListNicknameItemsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNicknameItemsReq): ListNicknameItemsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.PageReq page */ 1:
                    message.page = PageReq.internalBinaryRead(reader, reader.uint32(), options, message.page);
                    break;
                case /* bpt.common.Gender gender */ 2:
                    message.gender = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.NicknameItemType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNicknameItemsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.PageReq page = 1; */
        if (message.page)
            PageReq.internalBinaryWrite(message.page, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.Gender gender = 2; */
        if (message.gender !== 0)
            writer.tag(2, WireType.Varint).int32(message.gender);
        /* bpt.hall.userinfo.v1.NicknameItemType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListNicknameItemsReq
 */
export const ListNicknameItemsReq = new ListNicknameItemsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNicknameItemsResp$Type extends MessageType<ListNicknameItemsResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListNicknameItemsResp", [
            { no: 1, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NicknameItem }
        ]);
    }
    create(value?: PartialMessage<ListNicknameItemsResp>): ListNicknameItemsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListNicknameItemsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNicknameItemsResp): ListNicknameItemsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 total */ 1:
                    message.total = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.userinfo.v1.NicknameItem items */ 3:
                    message.items.push(NicknameItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNicknameItemsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int64(message.total);
        /* repeated bpt.hall.userinfo.v1.NicknameItem items = 3; */
        for (let i = 0; i < message.items.length; i++)
            NicknameItem.internalBinaryWrite(message.items[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListNicknameItemsResp
 */
export const ListNicknameItemsResp = new ListNicknameItemsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRandNicknameReq$Type extends MessageType<GetRandNicknameReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetRandNicknameReq", [
            { no: 2, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] }
        ]);
    }
    create(value?: PartialMessage<GetRandNicknameReq>): GetRandNicknameReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.gender = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRandNicknameReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRandNicknameReq): GetRandNicknameReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.Gender gender */ 2:
                    message.gender = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRandNicknameReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.Gender gender = 2; */
        if (message.gender !== 0)
            writer.tag(2, WireType.Varint).int32(message.gender);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetRandNicknameReq
 */
export const GetRandNicknameReq = new GetRandNicknameReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRandNicknameResp$Type extends MessageType<GetRandNicknameResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetRandNicknameResp", [
            { no: 2, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRandNicknameResp>): GetRandNicknameResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nickname = "";
        if (value !== undefined)
            reflectionMergePartial<GetRandNicknameResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRandNicknameResp): GetRandNicknameResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string nickname */ 2:
                    message.nickname = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRandNicknameResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string nickname = 2; */
        if (message.nickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nickname);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetRandNicknameResp
 */
export const GetRandNicknameResp = new GetRandNicknameResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAutoBuyInStatusReq$Type extends MessageType<SetAutoBuyInStatusReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SetAutoBuyInStatusReq", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<SetAutoBuyInStatusReq>): SetAutoBuyInStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<SetAutoBuyInStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetAutoBuyInStatusReq): SetAutoBuyInStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState state */ 1:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetAutoBuyInStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SetAutoBuyInStatusReq
 */
export const SetAutoBuyInStatusReq = new SetAutoBuyInStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetWaitBigBlindStatusReq$Type extends MessageType<SetWaitBigBlindStatusReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SetWaitBigBlindStatusReq", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SetWaitBigBlindStatusReq>): SetWaitBigBlindStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<SetWaitBigBlindStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetWaitBigBlindStatusReq): SetWaitBigBlindStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState state */ 1:
                    message.state = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetWaitBigBlindStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SetWaitBigBlindStatusReq
 */
export const SetWaitBigBlindStatusReq = new SetWaitBigBlindStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAutoBuyInStatusReq$Type extends MessageType<GetAutoBuyInStatusReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetAutoBuyInStatusReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetAutoBuyInStatusReq>): GetAutoBuyInStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAutoBuyInStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAutoBuyInStatusReq): GetAutoBuyInStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAutoBuyInStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetAutoBuyInStatusReq
 */
export const GetAutoBuyInStatusReq = new GetAutoBuyInStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAutoBuyInStatusResp$Type extends MessageType<GetAutoBuyInStatusResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetAutoBuyInStatusResp", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<GetAutoBuyInStatusResp>): GetAutoBuyInStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAutoBuyInStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAutoBuyInStatusResp): GetAutoBuyInStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState state */ 1:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAutoBuyInStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetAutoBuyInStatusResp
 */
export const GetAutoBuyInStatusResp = new GetAutoBuyInStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWaitBigBlindStatusReq$Type extends MessageType<GetWaitBigBlindStatusReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetWaitBigBlindStatusReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetWaitBigBlindStatusReq>): GetWaitBigBlindStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWaitBigBlindStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWaitBigBlindStatusReq): GetWaitBigBlindStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWaitBigBlindStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetWaitBigBlindStatusReq
 */
export const GetWaitBigBlindStatusReq = new GetWaitBigBlindStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWaitBigBlindStatusResp$Type extends MessageType<GetWaitBigBlindStatusResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetWaitBigBlindStatusResp", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<GetWaitBigBlindStatusResp>): GetWaitBigBlindStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWaitBigBlindStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWaitBigBlindStatusResp): GetWaitBigBlindStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState state */ 1:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWaitBigBlindStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetWaitBigBlindStatusResp
 */
export const GetWaitBigBlindStatusResp = new GetWaitBigBlindStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTableViewStatusReq$Type extends MessageType<SetTableViewStatusReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SetTableViewStatusReq", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<SetTableViewStatusReq>): SetTableViewStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<SetTableViewStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetTableViewStatusReq): SetTableViewStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState state */ 1:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetTableViewStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SetTableViewStatusReq
 */
export const SetTableViewStatusReq = new SetTableViewStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetUserTableVoiceReq$Type extends MessageType<SetUserTableVoiceReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SetUserTableVoiceReq", [
            { no: 1, name: "voiceId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SetUserTableVoiceReq>): SetUserTableVoiceReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.voiceId = 0;
        if (value !== undefined)
            reflectionMergePartial<SetUserTableVoiceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetUserTableVoiceReq): SetUserTableVoiceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 voiceId */ 1:
                    message.voiceId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetUserTableVoiceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 voiceId = 1; */
        if (message.voiceId !== 0)
            writer.tag(1, WireType.Varint).int64(message.voiceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SetUserTableVoiceReq
 */
export const SetUserTableVoiceReq = new SetUserTableVoiceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTableViewStatusReq$Type extends MessageType<GetTableViewStatusReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetTableViewStatusReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetTableViewStatusReq>): GetTableViewStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTableViewStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTableViewStatusReq): GetTableViewStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTableViewStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetTableViewStatusReq
 */
export const GetTableViewStatusReq = new GetTableViewStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTableViewStatusResp$Type extends MessageType<GetTableViewStatusResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetTableViewStatusResp", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<GetTableViewStatusResp>): GetTableViewStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTableViewStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTableViewStatusResp): GetTableViewStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState state */ 1:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTableViewStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetTableViewStatusResp
 */
export const GetTableViewStatusResp = new GetTableViewStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginByPlatformReq$Type extends MessageType<LoginByPlatformReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginByPlatformReq", [
            { no: 1, name: "common", kind: "message", T: () => LoginReqCommon },
            { no: 3, name: "oauthCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "param", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoginByPlatformReq>): LoginByPlatformReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.oauthCode = "";
        message.accessToken = "";
        message.param = "";
        if (value !== undefined)
            reflectionMergePartial<LoginByPlatformReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginByPlatformReq): LoginByPlatformReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.LoginReqCommon common */ 1:
                    message.common = LoginReqCommon.internalBinaryRead(reader, reader.uint32(), options, message.common);
                    break;
                case /* string oauthCode */ 3:
                    message.oauthCode = reader.string();
                    break;
                case /* string accessToken */ 4:
                    message.accessToken = reader.string();
                    break;
                case /* string param */ 5:
                    message.param = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginByPlatformReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.LoginReqCommon common = 1; */
        if (message.common)
            LoginReqCommon.internalBinaryWrite(message.common, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string oauthCode = 3; */
        if (message.oauthCode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.oauthCode);
        /* string accessToken = 4; */
        if (message.accessToken !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accessToken);
        /* string param = 5; */
        if (message.param !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.param);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginByPlatformReq
 */
export const LoginByPlatformReq = new LoginByPlatformReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginReqCommon$Type extends MessageType<LoginReqCommon> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginReqCommon", [
            { no: 1, name: "guid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceType", kind: "enum", T: () => ["bpt.hall.userinfo.v1.DeviceType", DeviceType] },
            { no: 3, name: "deviceMode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "clientTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "clientVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 7, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 8, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "os", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "source", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "isSimulator", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "autoRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<LoginReqCommon>): LoginReqCommon {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.guid = "";
        message.deviceType = 0;
        message.deviceMode = "";
        message.clientTime = 0;
        message.clientVersion = "";
        message.latitude = 0;
        message.longitude = 0;
        message.language = "";
        message.os = "";
        message.channel = "";
        message.source = "";
        message.isSimulator = false;
        message.autoRegister = false;
        if (value !== undefined)
            reflectionMergePartial<LoginReqCommon>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginReqCommon): LoginReqCommon {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string guid */ 1:
                    message.guid = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.DeviceType deviceType */ 2:
                    message.deviceType = reader.int32();
                    break;
                case /* string deviceMode */ 3:
                    message.deviceMode = reader.string();
                    break;
                case /* int64 clientTime */ 4:
                    message.clientTime = reader.int64().toNumber();
                    break;
                case /* string clientVersion */ 5:
                    message.clientVersion = reader.string();
                    break;
                case /* float latitude */ 6:
                    message.latitude = reader.float();
                    break;
                case /* float longitude */ 7:
                    message.longitude = reader.float();
                    break;
                case /* string language */ 8:
                    message.language = reader.string();
                    break;
                case /* string os */ 9:
                    message.os = reader.string();
                    break;
                case /* string channel */ 10:
                    message.channel = reader.string();
                    break;
                case /* string source */ 11:
                    message.source = reader.string();
                    break;
                case /* bool isSimulator */ 12:
                    message.isSimulator = reader.bool();
                    break;
                case /* bool autoRegister */ 13:
                    message.autoRegister = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginReqCommon, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string guid = 1; */
        if (message.guid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.guid);
        /* bpt.hall.userinfo.v1.DeviceType deviceType = 2; */
        if (message.deviceType !== 0)
            writer.tag(2, WireType.Varint).int32(message.deviceType);
        /* string deviceMode = 3; */
        if (message.deviceMode !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.deviceMode);
        /* int64 clientTime = 4; */
        if (message.clientTime !== 0)
            writer.tag(4, WireType.Varint).int64(message.clientTime);
        /* string clientVersion = 5; */
        if (message.clientVersion !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.clientVersion);
        /* float latitude = 6; */
        if (message.latitude !== 0)
            writer.tag(6, WireType.Bit32).float(message.latitude);
        /* float longitude = 7; */
        if (message.longitude !== 0)
            writer.tag(7, WireType.Bit32).float(message.longitude);
        /* string language = 8; */
        if (message.language !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.language);
        /* string os = 9; */
        if (message.os !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.os);
        /* string channel = 10; */
        if (message.channel !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.channel);
        /* string source = 11; */
        if (message.source !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.source);
        /* bool isSimulator = 12; */
        if (message.isSimulator !== false)
            writer.tag(12, WireType.Varint).bool(message.isSimulator);
        /* bool autoRegister = 13; */
        if (message.autoRegister !== false)
            writer.tag(13, WireType.Varint).bool(message.autoRegister);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginReqCommon
 */
export const LoginReqCommon = new LoginReqCommon$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginByPlatformResp$Type extends MessageType<LoginByPlatformResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginByPlatformResp", [
            { no: 1, name: "common", kind: "message", T: () => LoginRespCommon },
            { no: 2, name: "user", kind: "message", T: () => PlatformUser }
        ]);
    }
    create(value?: PartialMessage<LoginByPlatformResp>): LoginByPlatformResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LoginByPlatformResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginByPlatformResp): LoginByPlatformResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.LoginRespCommon common */ 1:
                    message.common = LoginRespCommon.internalBinaryRead(reader, reader.uint32(), options, message.common);
                    break;
                case /* bpt.hall.userinfo.v1.PlatformUser user */ 2:
                    message.user = PlatformUser.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginByPlatformResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.LoginRespCommon common = 1; */
        if (message.common)
            LoginRespCommon.internalBinaryWrite(message.common, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.PlatformUser user = 2; */
        if (message.user)
            PlatformUser.internalBinaryWrite(message.user, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginByPlatformResp
 */
export const LoginByPlatformResp = new LoginByPlatformResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginByExchangeReq$Type extends MessageType<LoginByExchangeReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginByExchangeReq", [
            { no: 1, name: "common", kind: "message", T: () => LoginReqCommon },
            { no: 2, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoginByExchangeReq>): LoginByExchangeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessToken = "";
        if (value !== undefined)
            reflectionMergePartial<LoginByExchangeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginByExchangeReq): LoginByExchangeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.LoginReqCommon common */ 1:
                    message.common = LoginReqCommon.internalBinaryRead(reader, reader.uint32(), options, message.common);
                    break;
                case /* string accessToken */ 2:
                    message.accessToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginByExchangeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.LoginReqCommon common = 1; */
        if (message.common)
            LoginReqCommon.internalBinaryWrite(message.common, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string accessToken = 2; */
        if (message.accessToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accessToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginByExchangeReq
 */
export const LoginByExchangeReq = new LoginByExchangeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginByExchangeResp$Type extends MessageType<LoginByExchangeResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginByExchangeResp", [
            { no: 1, name: "common", kind: "message", T: () => LoginRespCommon }
        ]);
    }
    create(value?: PartialMessage<LoginByExchangeResp>): LoginByExchangeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LoginByExchangeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginByExchangeResp): LoginByExchangeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.LoginRespCommon common */ 1:
                    message.common = LoginRespCommon.internalBinaryRead(reader, reader.uint32(), options, message.common);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginByExchangeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.LoginRespCommon common = 1; */
        if (message.common)
            LoginRespCommon.internalBinaryWrite(message.common, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginByExchangeResp
 */
export const LoginByExchangeResp = new LoginByExchangeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformUser$Type extends MessageType<PlatformUser> {
    constructor() {
        super("bpt.hall.userinfo.v1.PlatformUser", [
            { no: 1, name: "openID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "headImgURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "gender", kind: "enum", T: () => ["bpt.hall.userinfo.v1.Gender", Gender] },
            { no: 5, name: "pubID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "AccountID", kind: "scalar", localName: "AccountID", jsonName: "AccountID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PlatformUser>): PlatformUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.openID = "";
        message.nickname = "";
        message.headImgURL = "";
        message.gender = 0;
        message.pubID = "";
        message.AccountID = 0;
        if (value !== undefined)
            reflectionMergePartial<PlatformUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlatformUser): PlatformUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string openID */ 1:
                    message.openID = reader.string();
                    break;
                case /* string nickname */ 2:
                    message.nickname = reader.string();
                    break;
                case /* string headImgURL */ 3:
                    message.headImgURL = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.Gender gender */ 4:
                    message.gender = reader.int32();
                    break;
                case /* string pubID */ 5:
                    message.pubID = reader.string();
                    break;
                case /* int64 AccountID = 6 [json_name = "AccountID"];*/ 6:
                    message.AccountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlatformUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string openID = 1; */
        if (message.openID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.openID);
        /* string nickname = 2; */
        if (message.nickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nickname);
        /* string headImgURL = 3; */
        if (message.headImgURL !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.headImgURL);
        /* bpt.hall.userinfo.v1.Gender gender = 4; */
        if (message.gender !== 0)
            writer.tag(4, WireType.Varint).int32(message.gender);
        /* string pubID = 5; */
        if (message.pubID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pubID);
        /* int64 AccountID = 6 [json_name = "AccountID"]; */
        if (message.AccountID !== 0)
            writer.tag(6, WireType.Varint).int64(message.AccountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PlatformUser
 */
export const PlatformUser = new PlatformUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginRespCommon$Type extends MessageType<LoginRespCommon> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginRespCommon", [
            { no: 1, name: "type", kind: "enum", T: () => ["bpt.hall.userinfo.v1.LoginRespCommon.TokenType", LoginRespCommon_TokenType] },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tokenExpiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "lastLoginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<LoginRespCommon>): LoginRespCommon {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.uid = 0;
        message.token = "";
        message.tokenExpiredAt = 0;
        message.isRegister = false;
        message.lastLoginAt = 0;
        if (value !== undefined)
            reflectionMergePartial<LoginRespCommon>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginRespCommon): LoginRespCommon {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.LoginRespCommon.TokenType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string token */ 4:
                    message.token = reader.string();
                    break;
                case /* int64 tokenExpiredAt */ 5:
                    message.tokenExpiredAt = reader.int64().toNumber();
                    break;
                case /* bool isRegister */ 7:
                    message.isRegister = reader.bool();
                    break;
                case /* int64 lastLoginAt */ 9:
                    message.lastLoginAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginRespCommon, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.LoginRespCommon.TokenType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* string token = 4; */
        if (message.token !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.token);
        /* int64 tokenExpiredAt = 5; */
        if (message.tokenExpiredAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.tokenExpiredAt);
        /* bool isRegister = 7; */
        if (message.isRegister !== false)
            writer.tag(7, WireType.Varint).bool(message.isRegister);
        /* int64 lastLoginAt = 9; */
        if (message.lastLoginAt !== 0)
            writer.tag(9, WireType.Varint).int64(message.lastLoginAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginRespCommon
 */
export const LoginRespCommon = new LoginRespCommon$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopChampionListResp$Type extends MessageType<TopChampionListResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.TopChampionListResp", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChampionElem }
        ]);
    }
    create(value?: PartialMessage<TopChampionListResp>): TopChampionListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<TopChampionListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TopChampionListResp): TopChampionListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.ChampionElem users */ 1:
                    message.users.push(ChampionElem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TopChampionListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.ChampionElem users = 1; */
        for (let i = 0; i < message.users.length; i++)
            ChampionElem.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.TopChampionListResp
 */
export const TopChampionListResp = new TopChampionListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopChampionListV2Req$Type extends MessageType<TopChampionListV2Req> {
    constructor() {
        super("bpt.hall.userinfo.v1.TopChampionListV2Req", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TopChampionListV2Req>): TopChampionListV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<TopChampionListV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TopChampionListV2Req): TopChampionListV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TopChampionListV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.TopChampionListV2Req
 */
export const TopChampionListV2Req = new TopChampionListV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChampionElem$Type extends MessageType<ChampionElem> {
    constructor() {
        super("bpt.hall.userinfo.v1.ChampionElem", [
            { no: 1, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "times", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ChampionElem>): ChampionElem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.uid = 0;
        message.times = 0;
        if (value !== undefined)
            reflectionMergePartial<ChampionElem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChampionElem): ChampionElem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rank */ 1:
                    message.rank = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int32 times */ 3:
                    message.times = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChampionElem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).int32(message.rank);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int32 times = 3; */
        if (message.times !== 0)
            writer.tag(3, WireType.Varint).int32(message.times);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ChampionElem
 */
export const ChampionElem = new ChampionElem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopBonusListResp$Type extends MessageType<TopBonusListResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.TopBonusListResp", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BonusElem }
        ]);
    }
    create(value?: PartialMessage<TopBonusListResp>): TopBonusListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<TopBonusListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TopBonusListResp): TopBonusListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.BonusElem users */ 1:
                    message.users.push(BonusElem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TopBonusListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.BonusElem users = 1; */
        for (let i = 0; i < message.users.length; i++)
            BonusElem.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.TopBonusListResp
 */
export const TopBonusListResp = new TopBonusListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusElem$Type extends MessageType<BonusElem> {
    constructor() {
        super("bpt.hall.userinfo.v1.BonusElem", [
            { no: 1, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "totalWinning", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<BonusElem>): BonusElem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.uid = 0;
        message.totalWinning = 0;
        if (value !== undefined)
            reflectionMergePartial<BonusElem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BonusElem): BonusElem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rank */ 1:
                    message.rank = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* double totalWinning */ 3:
                    message.totalWinning = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BonusElem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).int32(message.rank);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* double totalWinning = 3; */
        if (message.totalWinning !== 0)
            writer.tag(3, WireType.Bit64).double(message.totalWinning);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BonusElem
 */
export const BonusElem = new BonusElem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TopBonusListV2Req$Type extends MessageType<TopBonusListV2Req> {
    constructor() {
        super("bpt.hall.userinfo.v1.TopBonusListV2Req", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TopBonusListV2Req>): TopBonusListV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<TopBonusListV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TopBonusListV2Req): TopBonusListV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TopBonusListV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.TopBonusListV2Req
 */
export const TopBonusListV2Req = new TopBonusListV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRankAndTotalWinResp$Type extends MessageType<GetRankAndTotalWinResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetRankAndTotalWinResp", [
            { no: 1, name: "rankRange", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "totalWin", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<GetRankAndTotalWinResp>): GetRankAndTotalWinResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rankRange = 0;
        message.rank = 0;
        message.totalWin = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRankAndTotalWinResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRankAndTotalWinResp): GetRankAndTotalWinResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rankRange */ 1:
                    message.rankRange = reader.int32();
                    break;
                case /* int32 rank */ 2:
                    message.rank = reader.int32();
                    break;
                case /* double totalWin */ 3:
                    message.totalWin = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRankAndTotalWinResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rankRange = 1; */
        if (message.rankRange !== 0)
            writer.tag(1, WireType.Varint).int32(message.rankRange);
        /* int32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).int32(message.rank);
        /* double totalWin = 3; */
        if (message.totalWin !== 0)
            writer.tag(3, WireType.Bit64).double(message.totalWin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetRankAndTotalWinResp
 */
export const GetRankAndTotalWinResp = new GetRankAndTotalWinResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserRankingResp$Type extends MessageType<GetUserRankingResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserRankingResp", [
            { no: 1, name: "rankRange", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "totalWin", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserRankingResp>): GetUserRankingResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rankRange = 0;
        message.rank = 0;
        message.totalWin = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserRankingResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserRankingResp): GetUserRankingResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rankRange */ 1:
                    message.rankRange = reader.int32();
                    break;
                case /* int32 rank */ 2:
                    message.rank = reader.int32();
                    break;
                case /* double totalWin */ 3:
                    message.totalWin = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserRankingResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rankRange = 1; */
        if (message.rankRange !== 0)
            writer.tag(1, WireType.Varint).int32(message.rankRange);
        /* int32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).int32(message.rank);
        /* double totalWin = 3; */
        if (message.totalWin !== 0)
            writer.tag(3, WireType.Bit64).double(message.totalWin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserRankingResp
 */
export const GetUserRankingResp = new GetUserRankingResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRankAndTop1TimesResp$Type extends MessageType<GetRankAndTop1TimesResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetRankAndTop1TimesResp", [
            { no: 1, name: "rankRange", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "times", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetRankAndTop1TimesResp>): GetRankAndTop1TimesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rankRange = 0;
        message.rank = 0;
        message.times = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRankAndTop1TimesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRankAndTop1TimesResp): GetRankAndTop1TimesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rankRange */ 1:
                    message.rankRange = reader.int32();
                    break;
                case /* int32 rank */ 2:
                    message.rank = reader.int32();
                    break;
                case /* int32 times */ 3:
                    message.times = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRankAndTop1TimesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rankRange = 1; */
        if (message.rankRange !== 0)
            writer.tag(1, WireType.Varint).int32(message.rankRange);
        /* int32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).int32(message.rank);
        /* int32 times = 3; */
        if (message.times !== 0)
            writer.tag(3, WireType.Varint).int32(message.times);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetRankAndTop1TimesResp
 */
export const GetRankAndTop1TimesResp = new GetRankAndTop1TimesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RerunStRankReq$Type extends MessageType<RerunStRankReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.RerunStRankReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] }
        ]);
    }
    create(value?: PartialMessage<RerunStRankReq>): RerunStRankReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<RerunStRankReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RerunStRankReq): RerunStRankReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 2:
                    message.matchListType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RerunStRankReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchListType matchListType = 2; */
        if (message.matchListType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchListType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RerunStRankReq
 */
export const RerunStRankReq = new RerunStRankReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RerunStRankResp$Type extends MessageType<RerunStRankResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.RerunStRankResp", []);
    }
    create(value?: PartialMessage<RerunStRankResp>): RerunStRankResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RerunStRankResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RerunStRankResp): RerunStRankResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RerunStRankResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RerunStRankResp
 */
export const RerunStRankResp = new RerunStRankResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchReportListReq$Type extends MessageType<GetMatchReportListReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetMatchReportListReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchReportListReq>): GetMatchReportListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchReportListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchReportListReq): GetMatchReportListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchReportListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetMatchReportListReq
 */
export const GetMatchReportListReq = new GetMatchReportListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchReportListResp$Type extends MessageType<GetMatchReportListResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetMatchReportListResp", []);
    }
    create(value?: PartialMessage<GetMatchReportListResp>): GetMatchReportListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchReportListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchReportListResp): GetMatchReportListResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetMatchReportListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetMatchReportListResp
 */
export const GetMatchReportListResp = new GetMatchReportListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPrizeRank$Type extends MessageType<UserPrizeRank> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserPrizeRank", [
            { no: 1, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 4, name: "total", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<UserPrizeRank>): UserPrizeRank {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.uid = 0;
        message.matchListType = 0;
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<UserPrizeRank>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPrizeRank): UserPrizeRank {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rank */ 1:
                    message.rank = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 3:
                    message.matchListType = reader.int32();
                    break;
                case /* double total */ 4:
                    message.total = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPrizeRank, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).int32(message.rank);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* bpt.match.common.v1.MatchListType matchListType = 3; */
        if (message.matchListType !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchListType);
        /* double total = 4; */
        if (message.total !== 0)
            writer.tag(4, WireType.Bit64).double(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserPrizeRank
 */
export const UserPrizeRank = new UserPrizeRank$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserChampion$Type extends MessageType<UserChampion> {
    constructor() {
        super("bpt.hall.userinfo.v1.UserChampion", [
            { no: 1, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "user", kind: "message", T: () => User },
            { no: 4, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 5, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 6, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserChampion>): UserChampion {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.uid = 0;
        message.prizes = [];
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<UserChampion>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserChampion): UserChampion {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rank */ 1:
                    message.rank = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.User user */ 3:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* bpt.common.I18n i18n = 4 [json_name = "i18n"];*/ 4:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* repeated bpt.common.AssetItem prizes */ 5:
                    message.prizes.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 6:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserChampion, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).int32(message.rank);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* bpt.hall.userinfo.v1.User user = 3; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.I18n i18n = 4 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem prizes = 5; */
        for (let i = 0; i < message.prizes.length; i++)
            AssetItem.internalBinaryWrite(message.prizes[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 6; */
        if (message.matchKey !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UserChampion
 */
export const UserChampion = new UserChampion$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPrizeRanksReq$Type extends MessageType<ListUserPrizeRanksReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListUserPrizeRanksReq", [
            { no: 1, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] }
        ]);
    }
    create(value?: PartialMessage<ListUserPrizeRanksReq>): ListUserPrizeRanksReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPrizeRanksReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPrizeRanksReq): ListUserPrizeRanksReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchListType matchListType */ 1:
                    message.matchListType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPrizeRanksReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchListType matchListType = 1; */
        if (message.matchListType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchListType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListUserPrizeRanksReq
 */
export const ListUserPrizeRanksReq = new ListUserPrizeRanksReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPrizeRanksResp$Type extends MessageType<ListUserPrizeRanksResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListUserPrizeRanksResp", [
            { no: 1, name: "userPrizeRanks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPrizeRank },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n }
        ]);
    }
    create(value?: PartialMessage<ListUserPrizeRanksResp>): ListUserPrizeRanksResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userPrizeRanks = [];
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ListUserPrizeRanksResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPrizeRanksResp): ListUserPrizeRanksResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.UserPrizeRank userPrizeRanks */ 1:
                    message.userPrizeRanks.push(UserPrizeRank.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.common.I18n i18n = 3 [json_name = "i18n"];*/ 3:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPrizeRanksResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.UserPrizeRank userPrizeRanks = 1; */
        for (let i = 0; i < message.userPrizeRanks.length; i++)
            UserPrizeRank.internalBinaryWrite(message.userPrizeRanks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.common.I18n i18n = 3 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListUserPrizeRanksResp
 */
export const ListUserPrizeRanksResp = new ListUserPrizeRanksResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPrizeRankingsReq$Type extends MessageType<ListUserPrizeRankingsReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListUserPrizeRankingsReq", [
            { no: 1, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] }
        ]);
    }
    create(value?: PartialMessage<ListUserPrizeRankingsReq>): ListUserPrizeRankingsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPrizeRankingsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPrizeRankingsReq): ListUserPrizeRankingsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchListType matchListType */ 1:
                    message.matchListType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPrizeRankingsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchListType matchListType = 1; */
        if (message.matchListType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchListType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListUserPrizeRankingsReq
 */
export const ListUserPrizeRankingsReq = new ListUserPrizeRankingsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserRankingReq$Type extends MessageType<GetUserRankingReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserRankingReq", [
            { no: 1, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] }
        ]);
    }
    create(value?: PartialMessage<GetUserRankingReq>): GetUserRankingReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserRankingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserRankingReq): GetUserRankingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchListType matchListType */ 1:
                    message.matchListType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserRankingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchListType matchListType = 1; */
        if (message.matchListType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchListType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserRankingReq
 */
export const GetUserRankingReq = new GetUserRankingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPrizeRankingsResp$Type extends MessageType<ListUserPrizeRankingsResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListUserPrizeRankingsResp", [
            { no: 1, name: "userPrizeRanks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPrizeRank }
        ]);
    }
    create(value?: PartialMessage<ListUserPrizeRankingsResp>): ListUserPrizeRankingsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userPrizeRanks = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserPrizeRankingsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPrizeRankingsResp): ListUserPrizeRankingsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.UserPrizeRank userPrizeRanks */ 1:
                    message.userPrizeRanks.push(UserPrizeRank.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPrizeRankingsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.UserPrizeRank userPrizeRanks = 1; */
        for (let i = 0; i < message.userPrizeRanks.length; i++)
            UserPrizeRank.internalBinaryWrite(message.userPrizeRanks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListUserPrizeRankingsResp
 */
export const ListUserPrizeRankingsResp = new ListUserPrizeRankingsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserChampionsResp$Type extends MessageType<ListUserChampionsResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListUserChampionsResp", [
            { no: 1, name: "userChampions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserChampion }
        ]);
    }
    create(value?: PartialMessage<ListUserChampionsResp>): ListUserChampionsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userChampions = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserChampionsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserChampionsResp): ListUserChampionsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.UserChampion userChampions */ 1:
                    message.userChampions.push(UserChampion.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserChampionsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.UserChampion userChampions = 1; */
        for (let i = 0; i < message.userChampions.length; i++)
            UserChampion.internalBinaryWrite(message.userChampions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListUserChampionsResp
 */
export const ListUserChampionsResp = new ListUserChampionsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveImagesToAlbumReq$Type extends MessageType<SaveImagesToAlbumReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SaveImagesToAlbumReq", [
            { no: 1, name: "imagePaths", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveImagesToAlbumReq>): SaveImagesToAlbumReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imagePaths = [];
        if (value !== undefined)
            reflectionMergePartial<SaveImagesToAlbumReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveImagesToAlbumReq): SaveImagesToAlbumReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string imagePaths */ 1:
                    message.imagePaths.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveImagesToAlbumReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string imagePaths = 1; */
        for (let i = 0; i < message.imagePaths.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.imagePaths[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SaveImagesToAlbumReq
 */
export const SaveImagesToAlbumReq = new SaveImagesToAlbumReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveImagesToAlbumResp$Type extends MessageType<SaveImagesToAlbumResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.SaveImagesToAlbumResp", [
            { no: 1, name: "imageIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveImagesToAlbumResp>): SaveImagesToAlbumResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imageIds = [];
        if (value !== undefined)
            reflectionMergePartial<SaveImagesToAlbumResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveImagesToAlbumResp): SaveImagesToAlbumResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string imageIds */ 1:
                    message.imageIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveImagesToAlbumResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string imageIds = 1; */
        for (let i = 0; i < message.imageIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.imageIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SaveImagesToAlbumResp
 */
export const SaveImagesToAlbumResp = new SaveImagesToAlbumResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteImagesFromAlbumReq$Type extends MessageType<DeleteImagesFromAlbumReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeleteImagesFromAlbumReq", [
            { no: 1, name: "imageIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteImagesFromAlbumReq>): DeleteImagesFromAlbumReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.imageIds = [];
        if (value !== undefined)
            reflectionMergePartial<DeleteImagesFromAlbumReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteImagesFromAlbumReq): DeleteImagesFromAlbumReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string imageIds */ 1:
                    message.imageIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteImagesFromAlbumReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string imageIds = 1; */
        for (let i = 0; i < message.imageIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.imageIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeleteImagesFromAlbumReq
 */
export const DeleteImagesFromAlbumReq = new DeleteImagesFromAlbumReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListImagesFromAlbumReq$Type extends MessageType<ListImagesFromAlbumReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListImagesFromAlbumReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListImagesFromAlbumReq>): ListImagesFromAlbumReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListImagesFromAlbumReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListImagesFromAlbumReq): ListImagesFromAlbumReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListImagesFromAlbumReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListImagesFromAlbumReq
 */
export const ListImagesFromAlbumReq = new ListImagesFromAlbumReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListImagesFromAlbumResp$Type extends MessageType<ListImagesFromAlbumResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListImagesFromAlbumResp", [
            { no: 1, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "albumImages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AlbumImage }
        ]);
    }
    create(value?: PartialMessage<ListImagesFromAlbumResp>): ListImagesFromAlbumResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        message.albumImages = [];
        if (value !== undefined)
            reflectionMergePartial<ListImagesFromAlbumResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListImagesFromAlbumResp): ListImagesFromAlbumResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 total */ 1:
                    message.total = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.userinfo.v1.AlbumImage albumImages */ 2:
                    message.albumImages.push(AlbumImage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListImagesFromAlbumResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int64(message.total);
        /* repeated bpt.hall.userinfo.v1.AlbumImage albumImages = 2; */
        for (let i = 0; i < message.albumImages.length; i++)
            AlbumImage.internalBinaryWrite(message.albumImages[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListImagesFromAlbumResp
 */
export const ListImagesFromAlbumResp = new ListImagesFromAlbumResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AlbumImage$Type extends MessageType<AlbumImage> {
    constructor() {
        super("bpt.hall.userinfo.v1.AlbumImage", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AlbumImage>): AlbumImage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<AlbumImage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AlbumImage): AlbumImage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AlbumImage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.AlbumImage
 */
export const AlbumImage = new AlbumImage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserIntegratedInfoReq$Type extends MessageType<GetUserIntegratedInfoReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserIntegratedInfoReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserIntegratedInfoReq>): GetUserIntegratedInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserIntegratedInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserIntegratedInfoReq): GetUserIntegratedInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserIntegratedInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserIntegratedInfoReq
 */
export const GetUserIntegratedInfoReq = new GetUserIntegratedInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserIntegratedInfoResp$Type extends MessageType<GetUserIntegratedInfoResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserIntegratedInfoResp", [
            { no: 1, name: "user", kind: "message", T: () => SimpleUser },
            { no: 2, name: "images", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AlbumImage },
            { no: 3, name: "careerData", kind: "message", T: () => CareerData },
            { no: 4, name: "socialData", kind: "message", T: () => SocialData },
            { no: 5, name: "tag", kind: "message", T: () => Tag },
            { no: 6, name: "rating", kind: "message", T: () => PointRating }
        ]);
    }
    create(value?: PartialMessage<GetUserIntegratedInfoResp>): GetUserIntegratedInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.images = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserIntegratedInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserIntegratedInfoResp): GetUserIntegratedInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.SimpleUser user */ 1:
                    message.user = SimpleUser.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* repeated bpt.hall.userinfo.v1.AlbumImage images */ 2:
                    message.images.push(AlbumImage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.hall.userinfo.v1.CareerData careerData */ 3:
                    message.careerData = CareerData.internalBinaryRead(reader, reader.uint32(), options, message.careerData);
                    break;
                case /* bpt.hall.userinfo.v1.SocialData socialData */ 4:
                    message.socialData = SocialData.internalBinaryRead(reader, reader.uint32(), options, message.socialData);
                    break;
                case /* bpt.hall.userinfo.v1.Tag tag */ 5:
                    message.tag = Tag.internalBinaryRead(reader, reader.uint32(), options, message.tag);
                    break;
                case /* bpt.hall.userinfo.v1.PointRating rating */ 6:
                    message.rating = PointRating.internalBinaryRead(reader, reader.uint32(), options, message.rating);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserIntegratedInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.SimpleUser user = 1; */
        if (message.user)
            SimpleUser.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.hall.userinfo.v1.AlbumImage images = 2; */
        for (let i = 0; i < message.images.length; i++)
            AlbumImage.internalBinaryWrite(message.images[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.CareerData careerData = 3; */
        if (message.careerData)
            CareerData.internalBinaryWrite(message.careerData, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.SocialData socialData = 4; */
        if (message.socialData)
            SocialData.internalBinaryWrite(message.socialData, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.Tag tag = 5; */
        if (message.tag)
            Tag.internalBinaryWrite(message.tag, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.PointRating rating = 6; */
        if (message.rating)
            PointRating.internalBinaryWrite(message.rating, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserIntegratedInfoResp
 */
export const GetUserIntegratedInfoResp = new GetUserIntegratedInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PointRating$Type extends MessageType<PointRating> {
    constructor() {
        super("bpt.hall.userinfo.v1.PointRating", [
            { no: 1, name: "worldRank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "worldRankPoints", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "competencyRating", kind: "message", T: () => CompetencyRating },
            { no: 4, name: "totalPoints", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PointRating>): PointRating {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.worldRank = 0;
        message.worldRankPoints = 0;
        message.totalPoints = 0;
        if (value !== undefined)
            reflectionMergePartial<PointRating>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PointRating): PointRating {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 worldRank */ 1:
                    message.worldRank = reader.int32();
                    break;
                case /* int32 worldRankPoints */ 2:
                    message.worldRankPoints = reader.int32();
                    break;
                case /* bpt.hall.common.CompetencyRating competencyRating */ 3:
                    message.competencyRating = CompetencyRating.internalBinaryRead(reader, reader.uint32(), options, message.competencyRating);
                    break;
                case /* int32 totalPoints */ 4:
                    message.totalPoints = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PointRating, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 worldRank = 1; */
        if (message.worldRank !== 0)
            writer.tag(1, WireType.Varint).int32(message.worldRank);
        /* int32 worldRankPoints = 2; */
        if (message.worldRankPoints !== 0)
            writer.tag(2, WireType.Varint).int32(message.worldRankPoints);
        /* bpt.hall.common.CompetencyRating competencyRating = 3; */
        if (message.competencyRating)
            CompetencyRating.internalBinaryWrite(message.competencyRating, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalPoints = 4; */
        if (message.totalPoints !== 0)
            writer.tag(4, WireType.Varint).int32(message.totalPoints);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.PointRating
 */
export const PointRating = new PointRating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CareerData$Type extends MessageType<CareerData> {
    constructor() {
        super("bpt.hall.userinfo.v1.CareerData", [
            { no: 1, name: "firstPlaceCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "joinMatchCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "totalPrizeMTT", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "prizeMTTCnt", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "receivedLikeCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CareerData>): CareerData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.firstPlaceCnt = 0;
        message.joinMatchCnt = 0;
        message.totalPrizeMTT = 0;
        message.prizeMTTCnt = "";
        message.receivedLikeCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<CareerData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CareerData): CareerData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 firstPlaceCnt */ 1:
                    message.firstPlaceCnt = reader.int64().toNumber();
                    break;
                case /* int64 joinMatchCnt */ 2:
                    message.joinMatchCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalPrizeMTT */ 3:
                    message.totalPrizeMTT = reader.int64().toNumber();
                    break;
                case /* string prizeMTTCnt */ 4:
                    message.prizeMTTCnt = reader.string();
                    break;
                case /* int64 receivedLikeCnt */ 5:
                    message.receivedLikeCnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CareerData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 firstPlaceCnt = 1; */
        if (message.firstPlaceCnt !== 0)
            writer.tag(1, WireType.Varint).int64(message.firstPlaceCnt);
        /* int64 joinMatchCnt = 2; */
        if (message.joinMatchCnt !== 0)
            writer.tag(2, WireType.Varint).int64(message.joinMatchCnt);
        /* int64 totalPrizeMTT = 3; */
        if (message.totalPrizeMTT !== 0)
            writer.tag(3, WireType.Varint).int64(message.totalPrizeMTT);
        /* string prizeMTTCnt = 4; */
        if (message.prizeMTTCnt !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.prizeMTTCnt);
        /* int64 receivedLikeCnt = 5; */
        if (message.receivedLikeCnt !== 0)
            writer.tag(5, WireType.Varint).int64(message.receivedLikeCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CareerData
 */
export const CareerData = new CareerData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SocialData$Type extends MessageType<SocialData> {
    constructor() {
        super("bpt.hall.userinfo.v1.SocialData", [
            { no: 1, name: "fansNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "followNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "relate", kind: "message", T: () => Relate }
        ]);
    }
    create(value?: PartialMessage<SocialData>): SocialData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fansNum = 0;
        message.followNum = 0;
        if (value !== undefined)
            reflectionMergePartial<SocialData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SocialData): SocialData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 fansNum */ 1:
                    message.fansNum = reader.int64().toNumber();
                    break;
                case /* int64 followNum */ 2:
                    message.followNum = reader.int64().toNumber();
                    break;
                case /* bpt.social.relation.v1.Relate relate */ 3:
                    message.relate = Relate.internalBinaryRead(reader, reader.uint32(), options, message.relate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SocialData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 fansNum = 1; */
        if (message.fansNum !== 0)
            writer.tag(1, WireType.Varint).int64(message.fansNum);
        /* int64 followNum = 2; */
        if (message.followNum !== 0)
            writer.tag(2, WireType.Varint).int64(message.followNum);
        /* bpt.social.relation.v1.Relate relate = 3; */
        if (message.relate)
            Relate.internalBinaryWrite(message.relate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SocialData
 */
export const SocialData = new SocialData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSocialAccountReq$Type extends MessageType<ListSocialAccountReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListSocialAccountReq", [
            { no: 1, name: "accountId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "passportType", kind: "enum", T: () => ["bpt.hall.userinfo.v1.PassportType", PassportType] }
        ]);
    }
    create(value?: PartialMessage<ListSocialAccountReq>): ListSocialAccountReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountId = 0;
        message.passportType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListSocialAccountReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSocialAccountReq): ListSocialAccountReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountId */ 1:
                    message.accountId = reader.int64().toNumber();
                    break;
                case /* bpt.hall.userinfo.v1.PassportType passportType */ 2:
                    message.passportType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSocialAccountReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountId = 1; */
        if (message.accountId !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountId);
        /* bpt.hall.userinfo.v1.PassportType passportType = 2; */
        if (message.passportType !== 0)
            writer.tag(2, WireType.Varint).int32(message.passportType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListSocialAccountReq
 */
export const ListSocialAccountReq = new ListSocialAccountReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSocialAccountResp$Type extends MessageType<ListSocialAccountResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListSocialAccountResp", [
            { no: 1, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListSocialAccountResp>): ListSocialAccountResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.username = "";
        if (value !== undefined)
            reflectionMergePartial<ListSocialAccountResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSocialAccountResp): ListSocialAccountResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string username */ 1:
                    message.username = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSocialAccountResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string username = 1; */
        if (message.username !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.username);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListSocialAccountResp
 */
export const ListSocialAccountResp = new ListSocialAccountResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTagReq$Type extends MessageType<SaveTagReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.SaveTagReq", [
            { no: 1, name: "tag", kind: "message", T: () => Tag }
        ]);
    }
    create(value?: PartialMessage<SaveTagReq>): SaveTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTagReq): SaveTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.Tag tag */ 1:
                    message.tag = Tag.internalBinaryRead(reader, reader.uint32(), options, message.tag);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.Tag tag = 1; */
        if (message.tag)
            Tag.internalBinaryWrite(message.tag, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SaveTagReq
 */
export const SaveTagReq = new SaveTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTagResp$Type extends MessageType<SaveTagResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.SaveTagResp", [
            { no: 1, name: "tagId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveTagResp>): SaveTagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tagId = "";
        if (value !== undefined)
            reflectionMergePartial<SaveTagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTagResp): SaveTagResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tagId */ 1:
                    message.tagId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tagId = 1; */
        if (message.tagId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tagId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.SaveTagResp
 */
export const SaveTagResp = new SaveTagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTagReq$Type extends MessageType<GetTagReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetTagReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetTagReq>): GetTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTagReq): GetTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetTagReq
 */
export const GetTagReq = new GetTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTagResp$Type extends MessageType<GetTagResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetTagResp", [
            { no: 1, name: "tag", kind: "message", T: () => Tag }
        ]);
    }
    create(value?: PartialMessage<GetTagResp>): GetTagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetTagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTagResp): GetTagResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.Tag tag */ 1:
                    message.tag = Tag.internalBinaryRead(reader, reader.uint32(), options, message.tag);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.Tag tag = 1; */
        if (message.tag)
            Tag.internalBinaryWrite(message.tag, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetTagResp
 */
export const GetTagResp = new GetTagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetTagReq$Type extends MessageType<BatchGetTagReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetTagReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetTagReq>): BatchGetTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetTagReq): BatchGetTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetTagReq
 */
export const BatchGetTagReq = new BatchGetTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetTagResp$Type extends MessageType<BatchGetTagResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.BatchGetTagResp", [
            { no: 1, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tag }
        ]);
    }
    create(value?: PartialMessage<BatchGetTagResp>): BatchGetTagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tags = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetTagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetTagResp): BatchGetTagResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.Tag tags */ 1:
                    message.tags.push(Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetTagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.Tag tags = 1; */
        for (let i = 0; i < message.tags.length; i++)
            Tag.internalBinaryWrite(message.tags[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.BatchGetTagResp
 */
export const BatchGetTagResp = new BatchGetTagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTagReq$Type extends MessageType<DeleteTagReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeleteTagReq", [
            { no: 1, name: "tagIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteTagReq>): DeleteTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tagIds = [];
        if (value !== undefined)
            reflectionMergePartial<DeleteTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTagReq): DeleteTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string tagIds */ 1:
                    message.tagIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string tagIds = 1; */
        for (let i = 0; i < message.tagIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.tagIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeleteTagReq
 */
export const DeleteTagReq = new DeleteTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteTagResp$Type extends MessageType<DeleteTagResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.DeleteTagResp", []);
    }
    create(value?: PartialMessage<DeleteTagResp>): DeleteTagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteTagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteTagResp): DeleteTagResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteTagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.DeleteTagResp
 */
export const DeleteTagResp = new DeleteTagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTagReq$Type extends MessageType<ListTagReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListTagReq", [
            { no: 1, name: "skip", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListTagReq>): ListTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.skip = 0;
        message.limit = 0;
        if (value !== undefined)
            reflectionMergePartial<ListTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTagReq): ListTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 skip */ 1:
                    message.skip = reader.int64().toNumber();
                    break;
                case /* int64 limit */ 2:
                    message.limit = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 skip = 1; */
        if (message.skip !== 0)
            writer.tag(1, WireType.Varint).int64(message.skip);
        /* int64 limit = 2; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListTagReq
 */
export const ListTagReq = new ListTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTagResp$Type extends MessageType<ListTagResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListTagResp", [
            { no: 1, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tag }
        ]);
    }
    create(value?: PartialMessage<ListTagResp>): ListTagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        message.tags = [];
        if (value !== undefined)
            reflectionMergePartial<ListTagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTagResp): ListTagResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 total */ 1:
                    message.total = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.userinfo.v1.Tag tags */ 2:
                    message.tags.push(Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int64(message.total);
        /* repeated bpt.hall.userinfo.v1.Tag tags = 2; */
        for (let i = 0; i < message.tags.length; i++)
            Tag.internalBinaryWrite(message.tags[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListTagResp
 */
export const ListTagResp = new ListTagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTagReq$Type extends MessageType<UpdateTagReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateTagReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "commentUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tagUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "tagTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.hall.userinfo.v1.TagType", TagType] },
            { no: 7, name: "colorUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "colourTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.hall.userinfo.v1.ColourType", ColourType] },
            { no: 9, name: "markUpdate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "mark", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateTagReq>): UpdateTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.commentUpdate = false;
        message.comment = "";
        message.tagUpdate = false;
        message.tagTypes = [];
        message.colorUpdate = false;
        message.colourTypes = [];
        message.markUpdate = false;
        message.mark = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateTagReq): UpdateTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bool commentUpdate */ 3:
                    message.commentUpdate = reader.bool();
                    break;
                case /* string comment */ 4:
                    message.comment = reader.string();
                    break;
                case /* bool tagUpdate */ 5:
                    message.tagUpdate = reader.bool();
                    break;
                case /* repeated bpt.hall.userinfo.v1.TagType tagTypes */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.tagTypes.push(reader.int32());
                    else
                        message.tagTypes.push(reader.int32());
                    break;
                case /* bool colorUpdate */ 7:
                    message.colorUpdate = reader.bool();
                    break;
                case /* repeated bpt.hall.userinfo.v1.ColourType colourTypes */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.colourTypes.push(reader.int32());
                    else
                        message.colourTypes.push(reader.int32());
                    break;
                case /* bool markUpdate */ 9:
                    message.markUpdate = reader.bool();
                    break;
                case /* string mark */ 10:
                    message.mark = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bool commentUpdate = 3; */
        if (message.commentUpdate !== false)
            writer.tag(3, WireType.Varint).bool(message.commentUpdate);
        /* string comment = 4; */
        if (message.comment !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.comment);
        /* bool tagUpdate = 5; */
        if (message.tagUpdate !== false)
            writer.tag(5, WireType.Varint).bool(message.tagUpdate);
        /* repeated bpt.hall.userinfo.v1.TagType tagTypes = 6; */
        if (message.tagTypes.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.tagTypes.length; i++)
                writer.int32(message.tagTypes[i]);
            writer.join();
        }
        /* bool colorUpdate = 7; */
        if (message.colorUpdate !== false)
            writer.tag(7, WireType.Varint).bool(message.colorUpdate);
        /* repeated bpt.hall.userinfo.v1.ColourType colourTypes = 8; */
        if (message.colourTypes.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.colourTypes.length; i++)
                writer.int32(message.colourTypes[i]);
            writer.join();
        }
        /* bool markUpdate = 9; */
        if (message.markUpdate !== false)
            writer.tag(9, WireType.Varint).bool(message.markUpdate);
        /* string mark = 10; */
        if (message.mark !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.mark);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateTagReq
 */
export const UpdateTagReq = new UpdateTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateTagResp$Type extends MessageType<UpdateTagResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateTagResp", [
            { no: 1, name: "tagId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateTagResp>): UpdateTagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tagId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateTagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateTagResp): UpdateTagResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tagId */ 1:
                    message.tagId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateTagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tagId = 1; */
        if (message.tagId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tagId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateTagResp
 */
export const UpdateTagResp = new UpdateTagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Tag$Type extends MessageType<Tag> {
    constructor() {
        super("bpt.hall.userinfo.v1.Tag", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tagId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tagTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.hall.userinfo.v1.TagType", TagType] },
            { no: 5, name: "colourTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.hall.userinfo.v1.ColourType", ColourType] },
            { no: 6, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "mark", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Tag>): Tag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.tagId = "";
        message.comment = "";
        message.tagTypes = [];
        message.colourTypes = [];
        message.updateAt = 0;
        message.mark = "";
        if (value !== undefined)
            reflectionMergePartial<Tag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tag): Tag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string tagId */ 2:
                    message.tagId = reader.string();
                    break;
                case /* string comment */ 3:
                    message.comment = reader.string();
                    break;
                case /* repeated bpt.hall.userinfo.v1.TagType tagTypes */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.tagTypes.push(reader.int32());
                    else
                        message.tagTypes.push(reader.int32());
                    break;
                case /* repeated bpt.hall.userinfo.v1.ColourType colourTypes */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.colourTypes.push(reader.int32());
                    else
                        message.colourTypes.push(reader.int32());
                    break;
                case /* int64 updateAt */ 6:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* string mark */ 7:
                    message.mark = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string tagId = 2; */
        if (message.tagId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tagId);
        /* string comment = 3; */
        if (message.comment !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.comment);
        /* repeated bpt.hall.userinfo.v1.TagType tagTypes = 4; */
        if (message.tagTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.tagTypes.length; i++)
                writer.int32(message.tagTypes[i]);
            writer.join();
        }
        /* repeated bpt.hall.userinfo.v1.ColourType colourTypes = 5; */
        if (message.colourTypes.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.colourTypes.length; i++)
                writer.int32(message.colourTypes[i]);
            writer.join();
        }
        /* int64 updateAt = 6; */
        if (message.updateAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.updateAt);
        /* string mark = 7; */
        if (message.mark !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mark);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.Tag
 */
export const Tag = new Tag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Notice$Type extends MessageType<Notice> {
    constructor() {
        super("bpt.hall.userinfo.v1.Notice", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "noticeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "noticeType", kind: "enum", T: () => ["bpt.hall.common.NoticeType", NoticeType] },
            { no: 4, name: "HonorNotice", kind: "message", localName: "HonorNotice", jsonName: "HonorNotice", T: () => HonorNotice },
            { no: 5, name: "RatingNotice", kind: "message", localName: "RatingNotice", jsonName: "RatingNotice", T: () => RatingNotice },
            { no: 7, name: "rewardNotice", kind: "message", T: () => RewardNotice },
            { no: 9, name: "nonChainRewardNotice", kind: "message", T: () => NonChainRewardNotice },
            { no: 8, name: "transferNotice", kind: "message", T: () => TransferNotice },
            { no: 6, name: "createTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Notice>): Notice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.noticeId = "";
        message.noticeType = 0;
        message.createTime = 0;
        if (value !== undefined)
            reflectionMergePartial<Notice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Notice): Notice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string noticeId */ 2:
                    message.noticeId = reader.string();
                    break;
                case /* bpt.hall.common.NoticeType noticeType */ 3:
                    message.noticeType = reader.int32();
                    break;
                case /* bpt.hall.userinfo.v1.HonorNotice HonorNotice = 4 [json_name = "HonorNotice"];*/ 4:
                    message.HonorNotice = HonorNotice.internalBinaryRead(reader, reader.uint32(), options, message.HonorNotice);
                    break;
                case /* bpt.hall.userinfo.v1.RatingNotice RatingNotice = 5 [json_name = "RatingNotice"];*/ 5:
                    message.RatingNotice = RatingNotice.internalBinaryRead(reader, reader.uint32(), options, message.RatingNotice);
                    break;
                case /* bpt.hall.userinfo.v1.RewardNotice rewardNotice */ 7:
                    message.rewardNotice = RewardNotice.internalBinaryRead(reader, reader.uint32(), options, message.rewardNotice);
                    break;
                case /* bpt.hall.userinfo.v1.NonChainRewardNotice nonChainRewardNotice */ 9:
                    message.nonChainRewardNotice = NonChainRewardNotice.internalBinaryRead(reader, reader.uint32(), options, message.nonChainRewardNotice);
                    break;
                case /* bpt.hall.userinfo.v1.TransferNotice transferNotice */ 8:
                    message.transferNotice = TransferNotice.internalBinaryRead(reader, reader.uint32(), options, message.transferNotice);
                    break;
                case /* int64 createTime */ 6:
                    message.createTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Notice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string noticeId = 2; */
        if (message.noticeId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.noticeId);
        /* bpt.hall.common.NoticeType noticeType = 3; */
        if (message.noticeType !== 0)
            writer.tag(3, WireType.Varint).int32(message.noticeType);
        /* bpt.hall.userinfo.v1.HonorNotice HonorNotice = 4 [json_name = "HonorNotice"]; */
        if (message.HonorNotice)
            HonorNotice.internalBinaryWrite(message.HonorNotice, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.RatingNotice RatingNotice = 5 [json_name = "RatingNotice"]; */
        if (message.RatingNotice)
            RatingNotice.internalBinaryWrite(message.RatingNotice, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.RewardNotice rewardNotice = 7; */
        if (message.rewardNotice)
            RewardNotice.internalBinaryWrite(message.rewardNotice, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.NonChainRewardNotice nonChainRewardNotice = 9; */
        if (message.nonChainRewardNotice)
            NonChainRewardNotice.internalBinaryWrite(message.nonChainRewardNotice, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.userinfo.v1.TransferNotice transferNotice = 8; */
        if (message.transferNotice)
            TransferNotice.internalBinaryWrite(message.transferNotice, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int64 createTime = 6; */
        if (message.createTime !== 0)
            writer.tag(6, WireType.Varint).int64(message.createTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.Notice
 */
export const Notice = new Notice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HonorNotice$Type extends MessageType<HonorNotice> {
    constructor() {
        super("bpt.hall.userinfo.v1.HonorNotice", [
            { no: 1, name: "topic", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "achieveAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<HonorNotice>): HonorNotice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.topic = 0;
        message.achieveAt = 0;
        if (value !== undefined)
            reflectionMergePartial<HonorNotice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HonorNotice): HonorNotice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 topic */ 1:
                    message.topic = reader.int32();
                    break;
                case /* int64 achieveAt */ 2:
                    message.achieveAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HonorNotice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 topic = 1; */
        if (message.topic !== 0)
            writer.tag(1, WireType.Varint).int32(message.topic);
        /* int64 achieveAt = 2; */
        if (message.achieveAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.achieveAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.HonorNotice
 */
export const HonorNotice = new HonorNotice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RatingNotice$Type extends MessageType<RatingNotice> {
    constructor() {
        super("bpt.hall.userinfo.v1.RatingNotice", [
            { no: 1, name: "competencyRating", kind: "message", T: () => CompetencyRating },
            { no: 2, name: "addPoints", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<RatingNotice>): RatingNotice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.addPoints = 0;
        if (value !== undefined)
            reflectionMergePartial<RatingNotice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RatingNotice): RatingNotice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.common.CompetencyRating competencyRating */ 1:
                    message.competencyRating = CompetencyRating.internalBinaryRead(reader, reader.uint32(), options, message.competencyRating);
                    break;
                case /* int32 addPoints */ 2:
                    message.addPoints = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RatingNotice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.common.CompetencyRating competencyRating = 1; */
        if (message.competencyRating)
            CompetencyRating.internalBinaryWrite(message.competencyRating, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 addPoints = 2; */
        if (message.addPoints !== 0)
            writer.tag(2, WireType.Varint).int32(message.addPoints);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RatingNotice
 */
export const RatingNotice = new RatingNotice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RewardNotice$Type extends MessageType<RewardNotice> {
    constructor() {
        super("bpt.hall.userinfo.v1.RewardNotice", [
            { no: 1, name: "matchName", kind: "message", T: () => I18n },
            { no: 2, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "joinedNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<RewardNotice>): RewardNotice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.prizes = [];
        message.matchKey = "";
        message.joinedNum = 0;
        if (value !== undefined)
            reflectionMergePartial<RewardNotice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RewardNotice): RewardNotice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.I18n matchName */ 1:
                    message.matchName = I18n.internalBinaryRead(reader, reader.uint32(), options, message.matchName);
                    break;
                case /* int32 rank */ 2:
                    message.rank = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset prizes */ 3:
                    message.prizes.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                case /* int32 joinedNum */ 5:
                    message.joinedNum = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RewardNotice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.I18n matchName = 1; */
        if (message.matchName)
            I18n.internalBinaryWrite(message.matchName, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).int32(message.rank);
        /* repeated bpt.common.SimpleAsset prizes = 3; */
        for (let i = 0; i < message.prizes.length; i++)
            SimpleAsset.internalBinaryWrite(message.prizes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        /* int32 joinedNum = 5; */
        if (message.joinedNum !== 0)
            writer.tag(5, WireType.Varint).int32(message.joinedNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.RewardNotice
 */
export const RewardNotice = new RewardNotice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NonChainRewardNotice$Type extends MessageType<NonChainRewardNotice> {
    constructor() {
        super("bpt.hall.userinfo.v1.NonChainRewardNotice", [
            { no: 1, name: "matchName", kind: "message", T: () => I18n },
            { no: 2, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "joinedNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<NonChainRewardNotice>): NonChainRewardNotice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.prizes = [];
        message.matchKey = "";
        message.joinedNum = 0;
        if (value !== undefined)
            reflectionMergePartial<NonChainRewardNotice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NonChainRewardNotice): NonChainRewardNotice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.I18n matchName */ 1:
                    message.matchName = I18n.internalBinaryRead(reader, reader.uint32(), options, message.matchName);
                    break;
                case /* int32 rank */ 2:
                    message.rank = reader.int32();
                    break;
                case /* repeated bpt.common.SimpleAsset prizes */ 3:
                    message.prizes.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                case /* int32 joinedNum */ 5:
                    message.joinedNum = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NonChainRewardNotice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.I18n matchName = 1; */
        if (message.matchName)
            I18n.internalBinaryWrite(message.matchName, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).int32(message.rank);
        /* repeated bpt.common.SimpleAsset prizes = 3; */
        for (let i = 0; i < message.prizes.length; i++)
            SimpleAsset.internalBinaryWrite(message.prizes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        /* int32 joinedNum = 5; */
        if (message.joinedNum !== 0)
            writer.tag(5, WireType.Varint).int32(message.joinedNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.NonChainRewardNotice
 */
export const NonChainRewardNotice = new NonChainRewardNotice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferNotice$Type extends MessageType<TransferNotice> {
    constructor() {
        super("bpt.hall.userinfo.v1.TransferNotice", [
            { no: 1, name: "fromUserName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TransferNotice>): TransferNotice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fromUserName = "";
        if (value !== undefined)
            reflectionMergePartial<TransferNotice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransferNotice): TransferNotice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fromUserName */ 1:
                    message.fromUserName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransferNotice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fromUserName = 1; */
        if (message.fromUserName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fromUserName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.TransferNotice
 */
export const TransferNotice = new TransferNotice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddNoticeReq$Type extends MessageType<AddNoticeReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.AddNoticeReq", [
            { no: 1, name: "notice", kind: "message", T: () => Notice }
        ]);
    }
    create(value?: PartialMessage<AddNoticeReq>): AddNoticeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AddNoticeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddNoticeReq): AddNoticeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.Notice notice */ 1:
                    message.notice = Notice.internalBinaryRead(reader, reader.uint32(), options, message.notice);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddNoticeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.Notice notice = 1; */
        if (message.notice)
            Notice.internalBinaryWrite(message.notice, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.AddNoticeReq
 */
export const AddNoticeReq = new AddNoticeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddNoticeResp$Type extends MessageType<AddNoticeResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.AddNoticeResp", []);
    }
    create(value?: PartialMessage<AddNoticeResp>): AddNoticeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AddNoticeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddNoticeResp): AddNoticeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddNoticeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.AddNoticeResp
 */
export const AddNoticeResp = new AddNoticeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNoticeReq$Type extends MessageType<ListNoticeReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListNoticeReq", [
            { no: 1, name: "offset", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "limit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListNoticeReq>): ListNoticeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offset = 0;
        message.limit = 0;
        if (value !== undefined)
            reflectionMergePartial<ListNoticeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNoticeReq): ListNoticeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 offset */ 1:
                    message.offset = reader.int64().toNumber();
                    break;
                case /* int64 limit */ 2:
                    message.limit = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNoticeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 offset = 1; */
        if (message.offset !== 0)
            writer.tag(1, WireType.Varint).int64(message.offset);
        /* int64 limit = 2; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int64(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListNoticeReq
 */
export const ListNoticeReq = new ListNoticeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNoticeResp$Type extends MessageType<ListNoticeResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.ListNoticeResp", [
            { no: 1, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "notices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Notice }
        ]);
    }
    create(value?: PartialMessage<ListNoticeResp>): ListNoticeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.total = 0;
        message.notices = [];
        if (value !== undefined)
            reflectionMergePartial<ListNoticeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNoticeResp): ListNoticeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 total */ 1:
                    message.total = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.userinfo.v1.Notice notices */ 2:
                    message.notices.push(Notice.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNoticeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 total = 1; */
        if (message.total !== 0)
            writer.tag(1, WireType.Varint).int64(message.total);
        /* repeated bpt.hall.userinfo.v1.Notice notices = 2; */
        for (let i = 0; i < message.notices.length; i++)
            Notice.internalBinaryWrite(message.notices[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.ListNoticeResp
 */
export const ListNoticeResp = new ListNoticeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseNoticeReq$Type extends MessageType<CloseNoticeReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.CloseNoticeReq", [
            { no: 1, name: "noticeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CloseNoticeReq>): CloseNoticeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.noticeId = "";
        if (value !== undefined)
            reflectionMergePartial<CloseNoticeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseNoticeReq): CloseNoticeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string noticeId */ 1:
                    message.noticeId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CloseNoticeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string noticeId = 1; */
        if (message.noticeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.noticeId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CloseNoticeReq
 */
export const CloseNoticeReq = new CloseNoticeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CloseNoticeResp$Type extends MessageType<CloseNoticeResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.CloseNoticeResp", []);
    }
    create(value?: PartialMessage<CloseNoticeResp>): CloseNoticeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CloseNoticeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CloseNoticeResp): CloseNoticeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CloseNoticeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.CloseNoticeResp
 */
export const CloseNoticeResp = new CloseNoticeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClubIdReq$Type extends MessageType<UpdateClubIdReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateClubIdReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "clubId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "updateType", kind: "enum", T: () => ["bpt.hall.userinfo.v1.UpdateType", UpdateType] }
        ]);
    }
    create(value?: PartialMessage<UpdateClubIdReq>): UpdateClubIdReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.clubId = 0;
        message.updateType = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateClubIdReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClubIdReq): UpdateClubIdReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint32 clubId */ 2:
                    message.clubId = reader.uint32();
                    break;
                case /* bpt.hall.userinfo.v1.UpdateType updateType */ 3:
                    message.updateType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateClubIdReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint32 clubId = 2; */
        if (message.clubId !== 0)
            writer.tag(2, WireType.Varint).uint32(message.clubId);
        /* bpt.hall.userinfo.v1.UpdateType updateType = 3; */
        if (message.updateType !== 0)
            writer.tag(3, WireType.Varint).int32(message.updateType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateClubIdReq
 */
export const UpdateClubIdReq = new UpdateClubIdReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateClubIdResp$Type extends MessageType<UpdateClubIdResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateClubIdResp", []);
    }
    create(value?: PartialMessage<UpdateClubIdResp>): UpdateClubIdResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateClubIdResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateClubIdResp): UpdateClubIdResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateClubIdResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateClubIdResp
 */
export const UpdateClubIdResp = new UpdateClubIdResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserOpsInfoReq$Type extends MessageType<GetUserOpsInfoReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserOpsInfoReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserOpsInfoReq>): GetUserOpsInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserOpsInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserOpsInfoReq): GetUserOpsInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserOpsInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserOpsInfoReq
 */
export const GetUserOpsInfoReq = new GetUserOpsInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("bpt.hall.userinfo.v1.Location", [
            { no: 1, name: "countryCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.countryCode = "";
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string countryCode */ 1:
                    message.countryCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string countryCode = 1; */
        if (message.countryCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.countryCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginLog$Type extends MessageType<LoginLog> {
    constructor() {
        super("bpt.hall.userinfo.v1.LoginLog", [
            { no: 1, name: "loginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location", kind: "message", T: () => Location }
        ]);
    }
    create(value?: PartialMessage<LoginLog>): LoginLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.loginAt = 0;
        message.ip = "";
        if (value !== undefined)
            reflectionMergePartial<LoginLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginLog): LoginLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 loginAt */ 1:
                    message.loginAt = reader.int64().toNumber();
                    break;
                case /* string ip */ 2:
                    message.ip = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.Location location */ 3:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 loginAt = 1; */
        if (message.loginAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.loginAt);
        /* string ip = 2; */
        if (message.ip !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ip);
        /* bpt.hall.userinfo.v1.Location location = 3; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.LoginLog
 */
export const LoginLog = new LoginLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserOpsInfoResp$Type extends MessageType<GetUserOpsInfoResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GetUserOpsInfoResp", [
            { no: 1, name: "lastLoginLog", kind: "message", T: () => LoginLog },
            { no: 2, name: "fromInviteCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "registerAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserOpsInfoResp>): GetUserOpsInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fromInviteCode = "";
        message.registerAt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserOpsInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserOpsInfoResp): GetUserOpsInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.userinfo.v1.LoginLog lastLoginLog */ 1:
                    message.lastLoginLog = LoginLog.internalBinaryRead(reader, reader.uint32(), options, message.lastLoginLog);
                    break;
                case /* string fromInviteCode */ 2:
                    message.fromInviteCode = reader.string();
                    break;
                case /* int64 registerAt */ 3:
                    message.registerAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserOpsInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.userinfo.v1.LoginLog lastLoginLog = 1; */
        if (message.lastLoginLog)
            LoginLog.internalBinaryWrite(message.lastLoginLog, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string fromInviteCode = 2; */
        if (message.fromInviteCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fromInviteCode);
        /* int64 registerAt = 3; */
        if (message.registerAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.registerAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GetUserOpsInfoResp
 */
export const GetUserOpsInfoResp = new GetUserOpsInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalSearchUser$Type extends MessageType<GlobalSearchUser> {
    constructor() {
        super("bpt.hall.userinfo.v1.GlobalSearchUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender$] },
            { no: 4, name: "avatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pubId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "mark", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "transTimes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GlobalSearchUser>): GlobalSearchUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.nickname = "";
        message.gender = 0;
        message.avatar = "";
        message.signature = "";
        message.pubId = "";
        message.mark = "";
        message.address = "";
        message.transTimes = 0;
        if (value !== undefined)
            reflectionMergePartial<GlobalSearchUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GlobalSearchUser): GlobalSearchUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string nickname */ 2:
                    message.nickname = reader.string();
                    break;
                case /* bpt.common.Gender gender */ 3:
                    message.gender = reader.int32();
                    break;
                case /* string avatar */ 4:
                    message.avatar = reader.string();
                    break;
                case /* string signature */ 5:
                    message.signature = reader.string();
                    break;
                case /* string pubId */ 6:
                    message.pubId = reader.string();
                    break;
                case /* string mark */ 7:
                    message.mark = reader.string();
                    break;
                case /* string address */ 9:
                    message.address = reader.string();
                    break;
                case /* int32 transTimes */ 10:
                    message.transTimes = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GlobalSearchUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string nickname = 2; */
        if (message.nickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nickname);
        /* bpt.common.Gender gender = 3; */
        if (message.gender !== 0)
            writer.tag(3, WireType.Varint).int32(message.gender);
        /* string avatar = 4; */
        if (message.avatar !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.avatar);
        /* string signature = 5; */
        if (message.signature !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.signature);
        /* string pubId = 6; */
        if (message.pubId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.pubId);
        /* string mark = 7; */
        if (message.mark !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mark);
        /* string address = 9; */
        if (message.address !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.address);
        /* int32 transTimes = 10; */
        if (message.transTimes !== 0)
            writer.tag(10, WireType.Varint).int32(message.transTimes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GlobalSearchUser
 */
export const GlobalSearchUser = new GlobalSearchUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalSearchUsersReq$Type extends MessageType<GlobalSearchUsersReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.GlobalSearchUsersReq", [
            { no: 1, name: "keyword", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "searchType", kind: "enum", T: () => ["bpt.hall.userinfo.v1.SearchType", SearchType] },
            { no: 3, name: "clubId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "page", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "pageSize", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "isWallet", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GlobalSearchUsersReq>): GlobalSearchUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.keyword = "";
        message.searchType = 0;
        message.clubId = 0;
        message.page = 0;
        message.pageSize = 0;
        message.isWallet = false;
        if (value !== undefined)
            reflectionMergePartial<GlobalSearchUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GlobalSearchUsersReq): GlobalSearchUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string keyword */ 1:
                    message.keyword = reader.string();
                    break;
                case /* bpt.hall.userinfo.v1.SearchType searchType */ 2:
                    message.searchType = reader.int32();
                    break;
                case /* uint32 clubId */ 3:
                    message.clubId = reader.uint32();
                    break;
                case /* uint32 page */ 4:
                    message.page = reader.uint32();
                    break;
                case /* uint32 pageSize */ 5:
                    message.pageSize = reader.uint32();
                    break;
                case /* bool isWallet */ 6:
                    message.isWallet = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GlobalSearchUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string keyword = 1; */
        if (message.keyword !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.keyword);
        /* bpt.hall.userinfo.v1.SearchType searchType = 2; */
        if (message.searchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.searchType);
        /* uint32 clubId = 3; */
        if (message.clubId !== 0)
            writer.tag(3, WireType.Varint).uint32(message.clubId);
        /* uint32 page = 4; */
        if (message.page !== 0)
            writer.tag(4, WireType.Varint).uint32(message.page);
        /* uint32 pageSize = 5; */
        if (message.pageSize !== 0)
            writer.tag(5, WireType.Varint).uint32(message.pageSize);
        /* bool isWallet = 6; */
        if (message.isWallet !== false)
            writer.tag(6, WireType.Varint).bool(message.isWallet);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GlobalSearchUsersReq
 */
export const GlobalSearchUsersReq = new GlobalSearchUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GlobalSearchUsersResp$Type extends MessageType<GlobalSearchUsersResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.GlobalSearchUsersResp", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GlobalSearchUser },
            { no: 2, name: "total", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<GlobalSearchUsersResp>): GlobalSearchUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<GlobalSearchUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GlobalSearchUsersResp): GlobalSearchUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.userinfo.v1.GlobalSearchUser users */ 1:
                    message.users.push(GlobalSearchUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 total */ 2:
                    message.total = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GlobalSearchUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.userinfo.v1.GlobalSearchUser users = 1; */
        for (let i = 0; i < message.users.length; i++)
            GlobalSearchUser.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint32 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).uint32(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.GlobalSearchUsersResp
 */
export const GlobalSearchUsersResp = new GlobalSearchUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserDataReq$Type extends MessageType<UpdateUserDataReq> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserDataReq", [
            { no: 1, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "roleType", kind: "message", T: () => Int32Value }
        ]);
    }
    create(value?: PartialMessage<UpdateUserDataReq>): UpdateUserDataReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateUserDataReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserDataReq): UpdateUserDataReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 userID */ 1:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* google.protobuf.Int32Value roleType */ 2:
                    message.roleType = Int32Value.internalBinaryRead(reader, reader.uint32(), options, message.roleType);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserDataReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 userID = 1; */
        if (message.userID !== 0)
            writer.tag(1, WireType.Varint).int64(message.userID);
        /* google.protobuf.Int32Value roleType = 2; */
        if (message.roleType)
            Int32Value.internalBinaryWrite(message.roleType, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserDataReq
 */
export const UpdateUserDataReq = new UpdateUserDataReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserDataResp$Type extends MessageType<UpdateUserDataResp> {
    constructor() {
        super("bpt.hall.userinfo.v1.UpdateUserDataResp", []);
    }
    create(value?: PartialMessage<UpdateUserDataResp>): UpdateUserDataResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateUserDataResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserDataResp): UpdateUserDataResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateUserDataResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.userinfo.v1.UpdateUserDataResp
 */
export const UpdateUserDataResp = new UpdateUserDataResp$Type();
/**
 * @generated ServiceType for protobuf service bpt.hall.userinfo.v1.Userinfo
 */
export const Userinfo = new ServiceType("bpt.hall.userinfo.v1.Userinfo", [
    { name: "GetUser", options: {}, I: GetUserReq, O: GetUserResp },
    { name: "GetUserOpsInfo", options: {}, I: GetUserOpsInfoReq, O: GetUserOpsInfoResp },
    { name: "GetMySelf", options: {}, I: Void, O: GetUserResp },
    { name: "GetUserStyleStat", options: {}, I: GetUserStyleStatReq, O: GetUserStyleStatResp },
    { name: "BatchGetUserStyleStat", options: {}, I: BatchGetUserStyleStatReq, O: BatchGetUserStyleStatResp },
    { name: "BatchGetUser", options: {}, I: BatchGetUserReq, O: BatchGetUserResp },
    { name: "BatchGetSimpleUser", options: {}, I: BatchGetSimpleUserReq, O: BatchGetSimpleUserResp },
    { name: "UpdateUser", options: {}, I: UpdateUserReq, O: GetUserResp },
    { name: "SetAutoBuyInStatus", options: {}, I: SetAutoBuyInStatusReq, O: Void },
    { name: "SetWaitBigBlindStatus", options: {}, I: SetWaitBigBlindStatusReq, O: Void },
    { name: "GetAutoBuyInStatus", options: {}, I: GetAutoBuyInStatusReq, O: GetAutoBuyInStatusResp },
    { name: "LoginByExchange", options: {}, I: LoginByExchangeReq, O: LoginByExchangeResp },
    { name: "LoginByPlatform", options: {}, I: LoginByPlatformReq, O: LoginByPlatformResp },
    { name: "RefreshToken", options: {}, I: RefreshTokenReq, O: RefreshTokenResp },
    { name: "GetSTS", options: {}, I: GetSTSReq, O: GetSTSResp },
    { name: "LoginOut", options: {}, I: LoginOutReq, O: LoginOutResp },
    { name: "PageListInvitationCode", options: {}, I: PageListInvitationCodeReq, O: PageListInvitationCodeResp },
    { name: "TopChampionList", options: {}, I: Void, O: TopChampionListResp },
    { name: "TopBonusList", options: {}, I: Void, O: TopBonusListResp },
    { name: "ListUserPrizeRanks", options: {}, I: ListUserPrizeRanksReq, O: ListUserPrizeRanksResp },
    { name: "ListUserPrizeRankings", options: {}, I: ListUserPrizeRankingsReq, O: ListUserPrizeRankingsResp },
    { name: "GetUserRanking", options: {}, I: GetUserRankingReq, O: GetUserRankingResp },
    { name: "GenerateUserPrizeRanks", options: {}, I: Void, O: Void },
    { name: "GetRankAndTotalWin", options: {}, I: Void, O: GetRankAndTotalWinResp },
    { name: "GetRankAndTop1Times", options: {}, I: Void, O: GetRankAndTop1TimesResp },
    { name: "RerunStRank", options: {}, I: RerunStRankReq, O: RerunStRankResp },
    { name: "ListUserChampions", options: {}, I: Void, O: ListUserChampionsResp },
    { name: "SetTableViewStatus", options: {}, I: SetTableViewStatusReq, O: Void },
    { name: "SetUserTableVoice", options: {}, I: SetUserTableVoiceReq, O: Void },
    { name: "GetTableViewStatus", options: {}, I: Void, O: GetTableViewStatusResp },
    { name: "SaveImagesToAlbum", options: {}, I: SaveImagesToAlbumReq, O: SaveImagesToAlbumResp },
    { name: "DeleteImagesFromAlbum", options: {}, I: DeleteImagesFromAlbumReq, O: Void },
    { name: "ListImagesFromAlbum", options: {}, I: ListImagesFromAlbumReq, O: ListImagesFromAlbumResp },
    { name: "GetUserIntegratedInfo", options: {}, I: GetUserIntegratedInfoReq, O: GetUserIntegratedInfoResp },
    { name: "ListSocialAccount", options: {}, I: ListSocialAccountReq, O: ListSocialAccountResp },
    { name: "SaveTag", options: {}, I: SaveTagReq, O: SaveTagResp },
    { name: "GetTag", options: {}, I: GetTagReq, O: GetTagResp },
    { name: "BatchGetTag", options: {}, I: BatchGetTagReq, O: BatchGetTagResp },
    { name: "DeleteTag", options: {}, I: DeleteTagReq, O: Void },
    { name: "ListTag", options: {}, I: ListTagReq, O: ListTagResp },
    { name: "UpdateTag", options: {}, I: UpdateTagReq, O: UpdateTagResp },
    { name: "FuzzySearchUsers", options: {}, I: FuzzySearchUsersReq, O: FuzzySearchUsersResp },
    { name: "WalletSearchUsers", options: {}, I: WalletSearchUsersReq, O: WalletSearchUsersResp },
    { name: "ListNotice", options: {}, I: ListNoticeReq, O: ListNoticeResp },
    { name: "CloseNotice", options: {}, I: CloseNoticeReq, O: CloseNoticeResp },
    { name: "GetUserGameConfig", options: {}, I: Void, O: GetUserGameConfigResp },
    { name: "SaveUserGameConfig", options: {}, I: SaveUserGameConfigReq, O: Void },
    { name: "BatchGetUserVoiceConfig", options: {}, I: BatchGetUserVoiceConfigReq, O: BatchGetUserVoiceConfigResp },
    { name: "GlobalSearchUsers", options: {}, I: GlobalSearchUsersReq, O: GlobalSearchUsersResp },
    { name: "BatchGetUserShowdownSwitch", options: {}, I: BatchGetUserShowdownSwitchReq, O: BatchGetUserShowdownSwitchResp },
    { name: "GetWaitBigBlindStatus", options: { "blocker.exportclient": 0 }, I: GetWaitBigBlindStatusReq, O: GetWaitBigBlindStatusResp },
    { name: "GetWaitBigBlindStatusInternal", options: { "blocker.exportclient": 0 }, I: GetWaitBigBlindStatusReq, O: GetWaitBigBlindStatusResp },
    { name: "SetWaitBigBlindStatusInternal", options: { "blocker.exportclient": 0 }, I: SetWaitBigBlindStatusReq, O: Void },
    { name: "GetAutoBuyInStatusInternal", options: { "blocker.exportclient": 0 }, I: GetAutoBuyInStatusReq, O: GetAutoBuyInStatusResp },
    { name: "GetTableViewStatusInternal", options: { "blocker.exportclient": 0 }, I: GetTableViewStatusReq, O: GetTableViewStatusResp },
    { name: "InitUser", options: { "blocker.exportclient": 0 }, I: InitUserReq, O: InitUserResp },
    { name: "AddNotice", options: { "blocker.exportclient": 0 }, I: AddNoticeReq, O: AddNoticeResp },
    { name: "UpdateClubId", options: { "blocker.exportclient": 0 }, I: UpdateClubIdReq, O: UpdateClubIdResp },
    { name: "FuzzySearchClubUsers", options: { "blocker.exportclient": 0 }, I: FuzzySearchClubUsersReq, O: FuzzySearchClubUsersResp },
    { name: "UpdateUserData", options: { "blocker.exportclient": 0 }, I: UpdateUserDataReq, O: UpdateUserDataResp },
    { name: "SyncUserToEs", options: { "blocker.exportclient": 0 }, I: Void, O: Void }
]);
