// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/common/common.proto" (package "bpt.hall.common", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.hall.common.CompetencyRating
 */
export interface CompetencyRating {
    /**
     * @generated from protobuf field: bpt.hall.common.RatingType ratingType = 1;
     */
    ratingType: RatingType; // 评级
    /**
     * @generated from protobuf field: int32 level = 2;
     */
    level: number; // 小段位的等级，用于展示星星的数量
    /**
     * @generated from protobuf field: int32 points = 3;
     */
    points: number; // 当前等级的积分
    /**
     * @generated from protobuf field: int64 completeTime = 4;
     */
    completeTime: number; // 完成时间，0代表未完成
}
/**
 * @generated from protobuf message bpt.hall.common.CompetencyRatingStandard
 */
export interface CompetencyRatingStandard {
    /**
     * @generated from protobuf field: bpt.hall.common.RatingType ratingType = 1;
     */
    ratingType: RatingType;
    /**
     * @generated from protobuf field: int32 level = 2;
     */
    level: number;
    /**
     * @generated from protobuf field: int32 points = 3;
     */
    points: number;
    /**
     * @generated from protobuf field: bpt.hall.common.Threshold threshold = 4;
     */
    threshold?: Threshold;
}
/**
 * @generated from protobuf message bpt.hall.common.Threshold
 */
export interface Threshold {
    /**
     * @generated from protobuf field: bpt.hall.common.ThresholdType thresholdType = 1;
     */
    thresholdType: ThresholdType;
    /**
     * @generated from protobuf field: int32 times = 2;
     */
    times: number;
}
/**
 * @generated from protobuf enum bpt.hall.common.NoticeType
 */
export enum NoticeType {
    /**
     * @generated from protobuf enum value: NoticeTypeUnknown = 0;
     */
    NoticeTypeUnknown = 0,
    /**
     * 积分
     *
     * @generated from protobuf enum value: NoticeTypeGrowth = 1;
     */
    NoticeTypeGrowth = 1,
    /**
     * 成就
     *
     * @generated from protobuf enum value: NoticeTypeHonor = 2;
     */
    NoticeTypeHonor = 2,
    /**
     * 发奖
     *
     * @generated from protobuf enum value: NoticeTypeReward = 3;
     */
    NoticeTypeReward = 3,
    /**
     * 非链发奖
     *
     * @generated from protobuf enum value: NoticeTypeNonChainReward = 5;
     */
    NoticeTypeNonChainReward = 5,
    /**
     * 转账交易
     *
     * @generated from protobuf enum value: NoticeTypeTransfer = 4;
     */
    NoticeTypeTransfer = 4
}
/**
 * @generated from protobuf enum bpt.hall.common.RatingType
 */
export enum RatingType {
    /**
     * @generated from protobuf enum value: RatingTypeUnknown = 0;
     */
    RatingTypeUnknown = 0,
    /**
     * 新手
     *
     * @generated from protobuf enum value: RatingTypeNewbie = 1;
     */
    RatingTypeNewbie = 1,
    /**
     * 业余玩家
     *
     * @generated from protobuf enum value: RatingTypeAmateur = 2;
     */
    RatingTypeAmateur = 2,
    /**
     * 初级
     *
     * @generated from protobuf enum value: RatingTypePrimary = 3;
     */
    RatingTypePrimary = 3,
    /**
     * 中级
     *
     * @generated from protobuf enum value: RatingTypeIntermediate = 4;
     */
    RatingTypeIntermediate = 4,
    /**
     * 高级
     *
     * @generated from protobuf enum value: RatingTypeAdvanced = 5;
     */
    RatingTypeAdvanced = 5,
    /**
     * 职业
     *
     * @generated from protobuf enum value: RatingTypeProfessional = 6;
     */
    RatingTypeProfessional = 6
}
/**
 * @generated from protobuf enum bpt.hall.common.ThresholdType
 */
export enum ThresholdType {
    /**
     * @generated from protobuf enum value: ThresholdTypeUnknown = 0;
     */
    ThresholdTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: ThresholdTypeDailyChampionFt = 1;
     */
    ThresholdTypeDailyChampionFt = 1,
    /**
     * @generated from protobuf enum value: ThresholdTypeWeeklyChampionFt = 2;
     */
    ThresholdTypeWeeklyChampionFt = 2,
    /**
     * @generated from protobuf enum value: ThresholdTypeMonthlyChampionFt = 3;
     */
    ThresholdTypeMonthlyChampionFt = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class CompetencyRating$Type extends MessageType<CompetencyRating> {
    constructor() {
        super("bpt.hall.common.CompetencyRating", [
            { no: 1, name: "ratingType", kind: "enum", T: () => ["bpt.hall.common.RatingType", RatingType] },
            { no: 2, name: "level", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "points", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "completeTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CompetencyRating>): CompetencyRating {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ratingType = 0;
        message.level = 0;
        message.points = 0;
        message.completeTime = 0;
        if (value !== undefined)
            reflectionMergePartial<CompetencyRating>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetencyRating): CompetencyRating {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.common.RatingType ratingType */ 1:
                    message.ratingType = reader.int32();
                    break;
                case /* int32 level */ 2:
                    message.level = reader.int32();
                    break;
                case /* int32 points */ 3:
                    message.points = reader.int32();
                    break;
                case /* int64 completeTime */ 4:
                    message.completeTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetencyRating, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.common.RatingType ratingType = 1; */
        if (message.ratingType !== 0)
            writer.tag(1, WireType.Varint).int32(message.ratingType);
        /* int32 level = 2; */
        if (message.level !== 0)
            writer.tag(2, WireType.Varint).int32(message.level);
        /* int32 points = 3; */
        if (message.points !== 0)
            writer.tag(3, WireType.Varint).int32(message.points);
        /* int64 completeTime = 4; */
        if (message.completeTime !== 0)
            writer.tag(4, WireType.Varint).int64(message.completeTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.CompetencyRating
 */
export const CompetencyRating = new CompetencyRating$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompetencyRatingStandard$Type extends MessageType<CompetencyRatingStandard> {
    constructor() {
        super("bpt.hall.common.CompetencyRatingStandard", [
            { no: 1, name: "ratingType", kind: "enum", T: () => ["bpt.hall.common.RatingType", RatingType] },
            { no: 2, name: "level", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "points", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "threshold", kind: "message", T: () => Threshold }
        ]);
    }
    create(value?: PartialMessage<CompetencyRatingStandard>): CompetencyRatingStandard {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ratingType = 0;
        message.level = 0;
        message.points = 0;
        if (value !== undefined)
            reflectionMergePartial<CompetencyRatingStandard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompetencyRatingStandard): CompetencyRatingStandard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.common.RatingType ratingType */ 1:
                    message.ratingType = reader.int32();
                    break;
                case /* int32 level */ 2:
                    message.level = reader.int32();
                    break;
                case /* int32 points */ 3:
                    message.points = reader.int32();
                    break;
                case /* bpt.hall.common.Threshold threshold */ 4:
                    message.threshold = Threshold.internalBinaryRead(reader, reader.uint32(), options, message.threshold);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompetencyRatingStandard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.common.RatingType ratingType = 1; */
        if (message.ratingType !== 0)
            writer.tag(1, WireType.Varint).int32(message.ratingType);
        /* int32 level = 2; */
        if (message.level !== 0)
            writer.tag(2, WireType.Varint).int32(message.level);
        /* int32 points = 3; */
        if (message.points !== 0)
            writer.tag(3, WireType.Varint).int32(message.points);
        /* bpt.hall.common.Threshold threshold = 4; */
        if (message.threshold)
            Threshold.internalBinaryWrite(message.threshold, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.CompetencyRatingStandard
 */
export const CompetencyRatingStandard = new CompetencyRatingStandard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Threshold$Type extends MessageType<Threshold> {
    constructor() {
        super("bpt.hall.common.Threshold", [
            { no: 1, name: "thresholdType", kind: "enum", T: () => ["bpt.hall.common.ThresholdType", ThresholdType] },
            { no: 2, name: "times", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Threshold>): Threshold {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.thresholdType = 0;
        message.times = 0;
        if (value !== undefined)
            reflectionMergePartial<Threshold>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Threshold): Threshold {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.common.ThresholdType thresholdType */ 1:
                    message.thresholdType = reader.int32();
                    break;
                case /* int32 times */ 2:
                    message.times = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Threshold, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.common.ThresholdType thresholdType = 1; */
        if (message.thresholdType !== 0)
            writer.tag(1, WireType.Varint).int32(message.thresholdType);
        /* int32 times = 2; */
        if (message.times !== 0)
            writer.tag(2, WireType.Varint).int32(message.times);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.Threshold
 */
export const Threshold = new Threshold$Type();
