// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/mtt-website/src/models/header';
import model_2 from '/mtt-website/src/models/ranking';

export const models = {
model_1: { namespace: 'header', model: model_1 },
model_2: { namespace: 'ranking', model: model_2 },
} as const
