// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "mp/base/account.proto" (package "mp.base", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message mp.base.Device
 */
export interface Device {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string; // chrome/safari/firefox/...
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string; // 1.0.0
    /**
     * @generated from protobuf field: string platform = 3;
     */
    platform: string; // win32/win64/mac/iphone/android
}
/**
 * @generated from protobuf message mp.base.LoginParam
 */
export interface LoginParam {
    /**
     * @generated from protobuf field: string ip = 1;
     */
    ip: string;
    /**
     * @generated from protobuf field: mp.base.Device device = 2;
     */
    device?: Device;
    /**
     * * @name 设备唯一标识
     *
     * @generated from protobuf field: string guid = 5;
     */
    guid: string;
    /**
     * * @name 客户端时间，UNIX_TIMESTAMP 秒级，如果与服务器差异太大则不允许登录
     *
     * @generated from protobuf field: int64 clientTime = 8;
     */
    clientTime: number;
    /**
     * * @name 客户端版本
     *
     * @generated from protobuf field: string clientVersion = 9;
     */
    clientVersion: string;
    /**
     * * @name 纬度
     *
     * @generated from protobuf field: float latitude = 10;
     */
    latitude: number;
    /**
     * * @name 经度
     *
     * @generated from protobuf field: float longitude = 11;
     */
    longitude: number;
    /**
     * * @name 使用语言
     *
     * @generated from protobuf field: string language = 12;
     */
    language: string;
    /**
     * * @name 登录渠道信息
     *
     * @generated from protobuf field: string channel = 14;
     */
    channel: string;
    /**
     * @generated from protobuf field: string tag = 16;
     */
    tag: string;
    /**
     * @generated from protobuf field: repeated int64 relateUserList = 17;
     */
    relateUserList: number[]; // 关联用户
    /**
     * @generated from protobuf field: string referer = 18;
     */
    referer: string; // 引用
}
/**
 * @generated from protobuf enum mp.base.PassportType
 */
export enum PassportType {
    /**
     * @generated from protobuf enum value: PassportTypeUnknow = 0;
     */
    PassportTypeUnknow = 0,
    /**
     * * Guid
     *
     * @generated from protobuf enum value: PassportTypeGuid = 1;
     */
    PassportTypeGuid = 1,
    /**
     * * 手机
     *
     * @generated from protobuf enum value: PassportTypePhone = 2;
     */
    PassportTypePhone = 2,
    /**
     * * 邮箱
     *
     * @generated from protobuf enum value: PassportTypeEmail = 3;
     */
    PassportTypeEmail = 3,
    /**
     * * App
     *
     * @generated from protobuf enum value: PassportTypeApp = 4;
     */
    PassportTypeApp = 4,
    /**
     * * PubID
     *
     * @generated from protobuf enum value: PassportTypePubID = 5;
     */
    PassportTypePubID = 5,
    /**
     * eth swie wallet
     *
     * @generated from protobuf enum value: PassportTypeWalletETH = 6;
     */
    PassportTypeWalletETH = 6,
    /**
     * * 平台认证Begin
     *
     * @generated from protobuf enum value: PassportTypePlatformBegin = 10000000;
     */
    PassportTypePlatformBegin = 10000000,
    /**
     * * 微信
     *
     * @generated from protobuf enum value: PassportTypePlatformWechat = 10000001;
     */
    PassportTypePlatformWechat = 10000001,
    /**
     * * 苹果
     *
     * @generated from protobuf enum value: PassportTypePlatformApple = 10000002;
     */
    PassportTypePlatformApple = 10000002,
    /**
     * * oppo
     *
     * @generated from protobuf enum value: PassportTypePlatformOppo = 10000003;
     */
    PassportTypePlatformOppo = 10000003,
    /**
     * * vivo
     *
     * @generated from protobuf enum value: PassportTypePlatformVivo = 10000004;
     */
    PassportTypePlatformVivo = 10000004,
    /**
     * * 华为
     *
     * @generated from protobuf enum value: PassportTypePlatformHuawei = 10000005;
     */
    PassportTypePlatformHuawei = 10000005,
    /**
     * * 小米
     *
     * @generated from protobuf enum value: PassportTypePlatformXiaomi = 10000006;
     */
    PassportTypePlatformXiaomi = 10000006,
    /**
     * * 魅族
     *
     * @generated from protobuf enum value: PassportTypePlatformMeizu = 10000007;
     */
    PassportTypePlatformMeizu = 10000007,
    /**
     * * 应用宝
     *
     * @generated from protobuf enum value: PassportTypePlatformYingyongbao = 10000008;
     */
    PassportTypePlatformYingyongbao = 10000008,
    /**
     * * Line
     *
     * @generated from protobuf enum value: PassportTypePlatformLine = 10000009;
     */
    PassportTypePlatformLine = 10000009,
    /**
     * * FB
     *
     * @generated from protobuf enum value: PassportTypePlatformFacebook = 10000010;
     */
    PassportTypePlatformFacebook = 10000010,
    /**
     * * Google
     *
     * @generated from protobuf enum value: PassportTypePlatformGoogle = 10000011;
     */
    PassportTypePlatformGoogle = 10000011,
    /**
     * * GAW
     *
     * @generated from protobuf enum value: PassportTypePlatformGAW = 10000012;
     */
    PassportTypePlatformGAW = 10000012,
    /**
     * * Twitter
     *
     * @generated from protobuf enum value: PassportTypePlatformTwitter = 10000013;
     */
    PassportTypePlatformTwitter = 10000013,
    /**
     * * BPT
     *
     * @generated from protobuf enum value: PassportTypePlatformBPT = 10000014;
     */
    PassportTypePlatformBPT = 10000014,
    /**
     * * 平台认证End
     *
     * @generated from protobuf enum value: PassportTypePlatformEnd = 19999999;
     */
    PassportTypePlatformEnd = 19999999
}
/**
 * @generated from protobuf enum mp.base.PassportAuthType
 */
export enum PassportAuthType {
    /**
     * @generated from protobuf enum value: PassportAuthTypeUnknown = 0;
     */
    PassportAuthTypeUnknown = 0,
    /**
     * 验证码
     *
     * @generated from protobuf enum value: PassportAuthTypeCaptcha = 1;
     */
    PassportAuthTypeCaptcha = 1,
    /**
     * 第三方平台
     *
     * @generated from protobuf enum value: PassportAuthTypePlatform = 2;
     */
    PassportAuthTypePlatform = 2,
    /**
     * 密码
     *
     * @generated from protobuf enum value: PassportAuthTypePassword = 3;
     */
    PassportAuthTypePassword = 3,
    /**
     * 私钥
     *
     * @generated from protobuf enum value: PassportAuthTypeSignature = 4;
     */
    PassportAuthTypeSignature = 4,
    /**
     * opt验证器
     *
     * @generated from protobuf enum value: PassportAuthTypeOtpCode = 5;
     */
    PassportAuthTypeOtpCode = 5,
    /**
     * 通行密钥
     *
     * @generated from protobuf enum value: PassportAuthTypePassKey = 6;
     */
    PassportAuthTypePassKey = 6
}
/**
 * @generated from protobuf enum mp.base.LoginType
 */
export enum LoginType {
    /**
     * * Guid
     *
     * @generated from protobuf enum value: LoginTypeGuid = 0;
     */
    LoginTypeGuid = 0,
    /**
     * * 验证码
     *
     * @generated from protobuf enum value: LoginTypeCaptcha = 1;
     */
    LoginTypeCaptcha = 1,
    /**
     * * 密码
     *
     * @generated from protobuf enum value: LoginTypePassword = 2;
     */
    LoginTypePassword = 2,
    /**
     * * 授权
     *
     * @generated from protobuf enum value: LoginTypeCertificate = 3;
     */
    LoginTypeCertificate = 3,
    /**
     * * 平台
     *
     * @generated from protobuf enum value: LoginTypePlatform = 4;
     */
    LoginTypePlatform = 4,
    /**
     * * Mob
     *
     * @generated from protobuf enum value: LoginTypeIdentify = 5;
     */
    LoginTypeIdentify = 5,
    /**
     * * web3 钱包登录
     *
     * @generated from protobuf enum value: LoginTypeWallet = 6;
     */
    LoginTypeWallet = 6,
    /**
     * * App 登录
     *
     * @generated from protobuf enum value: LoginTypeApp = 7;
     */
    LoginTypeApp = 7
}
/**
 * 性别
 *
 * @generated from protobuf enum mp.base.Gender
 */
export enum Gender {
    /**
     * @generated from protobuf enum value: GenderUnknown = 0;
     */
    GenderUnknown = 0,
    /**
     * * 男性
     *
     * @generated from protobuf enum value: GenderMale = 1;
     */
    GenderMale = 1,
    /**
     * * 女性
     *
     * @generated from protobuf enum value: GenderFemale = 2;
     */
    GenderFemale = 2,
    /**
     * @generated from protobuf enum value: GenderNonbinary = 3;
     */
    GenderNonbinary = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Device$Type extends MessageType<Device> {
    constructor() {
        super("mp.base.Device", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "platform", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Device>): Device {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.version = "";
        message.platform = "";
        if (value !== undefined)
            reflectionMergePartial<Device>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Device): Device {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* string platform */ 3:
                    message.platform = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Device, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* string platform = 3; */
        if (message.platform !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.platform);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.base.Device
 */
export const Device = new Device$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginParam$Type extends MessageType<LoginParam> {
    constructor() {
        super("mp.base.LoginParam", [
            { no: 1, name: "ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "device", kind: "message", T: () => Device },
            { no: 5, name: "guid", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "clientTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "clientVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "latitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 11, name: "longitude", kind: "scalar", T: 2 /*ScalarType.FLOAT*/ },
            { no: 12, name: "language", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "relateUserList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "referer", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoginParam>): LoginParam {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ip = "";
        message.guid = "";
        message.clientTime = 0;
        message.clientVersion = "";
        message.latitude = 0;
        message.longitude = 0;
        message.language = "";
        message.channel = "";
        message.tag = "";
        message.relateUserList = [];
        message.referer = "";
        if (value !== undefined)
            reflectionMergePartial<LoginParam>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginParam): LoginParam {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ip */ 1:
                    message.ip = reader.string();
                    break;
                case /* mp.base.Device device */ 2:
                    message.device = Device.internalBinaryRead(reader, reader.uint32(), options, message.device);
                    break;
                case /* string guid */ 5:
                    message.guid = reader.string();
                    break;
                case /* int64 clientTime */ 8:
                    message.clientTime = reader.int64().toNumber();
                    break;
                case /* string clientVersion */ 9:
                    message.clientVersion = reader.string();
                    break;
                case /* float latitude */ 10:
                    message.latitude = reader.float();
                    break;
                case /* float longitude */ 11:
                    message.longitude = reader.float();
                    break;
                case /* string language */ 12:
                    message.language = reader.string();
                    break;
                case /* string channel */ 14:
                    message.channel = reader.string();
                    break;
                case /* string tag */ 16:
                    message.tag = reader.string();
                    break;
                case /* repeated int64 relateUserList */ 17:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.relateUserList.push(reader.int64().toNumber());
                    else
                        message.relateUserList.push(reader.int64().toNumber());
                    break;
                case /* string referer */ 18:
                    message.referer = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginParam, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ip = 1; */
        if (message.ip !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ip);
        /* mp.base.Device device = 2; */
        if (message.device)
            Device.internalBinaryWrite(message.device, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string guid = 5; */
        if (message.guid !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.guid);
        /* int64 clientTime = 8; */
        if (message.clientTime !== 0)
            writer.tag(8, WireType.Varint).int64(message.clientTime);
        /* string clientVersion = 9; */
        if (message.clientVersion !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.clientVersion);
        /* float latitude = 10; */
        if (message.latitude !== 0)
            writer.tag(10, WireType.Bit32).float(message.latitude);
        /* float longitude = 11; */
        if (message.longitude !== 0)
            writer.tag(11, WireType.Bit32).float(message.longitude);
        /* string language = 12; */
        if (message.language !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.language);
        /* string channel = 14; */
        if (message.channel !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.channel);
        /* string tag = 16; */
        if (message.tag !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.tag);
        /* repeated int64 relateUserList = 17; */
        if (message.relateUserList.length) {
            writer.tag(17, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.relateUserList.length; i++)
                writer.int64(message.relateUserList[i]);
            writer.join();
        }
        /* string referer = 18; */
        if (message.referer !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.referer);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.base.LoginParam
 */
export const LoginParam = new LoginParam$Type();
