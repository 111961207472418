// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "mp/user/account/v1/account.proto" (package "mp.user.account.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Device } from "../../../base/account";
import { PassportAuthType } from "../../../base/account";
import { PassportType } from "../../../base/account";
import { LoginParam } from "../../../base/account";
/**
 * @generated from protobuf message mp.user.account.v1.CaptchaStatus
 */
export interface CaptchaStatus {
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaType type = 1;
     */
    type: CaptchaType;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: int64 expiredAt = 3;
     */
    expiredAt: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.CaptchaRiskStatus
 */
export interface CaptchaRiskStatus {
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaType type = 1;
     */
    type: CaptchaType;
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaRiskType riskType = 2;
     */
    riskType: CaptchaRiskType;
    /**
     * @generated from protobuf field: int32 val = 3;
     */
    val: number;
    /**
     * @generated from protobuf field: int64 ttl = 4;
     */
    ttl: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetCaptchaStatusReq
 */
export interface GetCaptchaStatusReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf oneof: entity
     */
    entity: {
        oneofKind: "phone";
        /**
         * @generated from protobuf field: mp.user.account.v1.Phone phone = 2;
         */
        phone: Phone;
    } | {
        oneofKind: "email";
        /**
         * @generated from protobuf field: string email = 3;
         */
        email: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message mp.user.account.v1.GetCaptchaStatusResp
 */
export interface GetCaptchaStatusResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.CaptchaRiskStatus statusList = 1;
     */
    statusList: CaptchaRiskStatus[];
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.CaptchaStatus list = 2;
     */
    list: CaptchaStatus[];
}
/**
 * @generated from protobuf message mp.user.account.v1.ResetCaptchaRiskReq
 */
export interface ResetCaptchaRiskReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaType type = 2;
     */
    type: CaptchaType;
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaRiskType riskType = 3;
     */
    riskType: CaptchaRiskType;
    /**
     * @generated from protobuf oneof: entity
     */
    entity: {
        oneofKind: "phone";
        /**
         * @generated from protobuf field: mp.user.account.v1.Phone phone = 4;
         */
        phone: Phone;
    } | {
        oneofKind: "email";
        /**
         * @generated from protobuf field: string email = 5;
         */
        email: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message mp.user.account.v1.ResetCaptchaRiskResp
 */
export interface ResetCaptchaRiskResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.LogoutReq
 */
export interface LogoutReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
    /**
     * @generated from protobuf field: int64 fdID = 3;
     */
    fdID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.LogoutResp
 */
export interface LogoutResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.LoginReq
 */
export interface LoginReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: mp.base.LoginParam param = 2;
     */
    param?: LoginParam;
    /**
     * @generated from protobuf field: bool autoRegister = 3;
     */
    autoRegister: boolean;
    /**
     * @generated from protobuf field: mp.user.account.v1.LoginData loginData = 4;
     */
    loginData?: LoginData;
}
/**
 * @generated from protobuf message mp.user.account.v1.SignupReq
 */
export interface SignupReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: mp.base.LoginParam param = 2;
     */
    param?: LoginParam;
    /**
     * @generated from protobuf field: string pubID = 3;
     */
    pubID: string;
    /**
     * @generated from protobuf field: string authToken = 5;
     */
    authToken: string;
    /**
     * @generated from protobuf field: string invitationCode = 7;
     */
    invitationCode: string;
    /**
     * @generated from protobuf field: string password = 8;
     */
    password: string;
}
// message AuthVerify {
// 	oneof data {
// 		// passport 登陆
// 		PassportAuthReq passportAuth = 1;
// 		// @deprecated 使用 verifyAuthToken
// 		string 			authToken 	 = 2;
// 		VerifyAuthToken verifyAuthToken = 3;
// 	}
// }

/**
 * @generated from protobuf message mp.user.account.v1.SignupResult
 */
export interface SignupResult {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: int64 playerID = 2;
     */
    playerID: number;
    /**
     * @generated from protobuf field: string accessToken = 3;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: int64 expiredAt = 4;
     */
    expiredAt: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.Passport passport = 5;
     */
    passport?: Passport;
    /**
     * @generated from protobuf field: string pubID = 6;
     */
    pubID: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.SignupResp
 */
export interface SignupResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.SignupResult result = 1;
     */
    result?: SignupResult;
}
/**
 * @generated from protobuf message mp.user.account.v1.LoginData
 */
export interface LoginData {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "authToken";
        /**
         * passport 登陆
         *
         * @generated from protobuf field: string authToken = 1;
         */
        authToken: string;
    } | {
        oneofKind: "accessToken";
        /**
         * 授权信息登陆
         *
         * @generated from protobuf field: string accessToken = 2;
         */
        accessToken: string;
    } | {
        oneofKind: "qrCode";
        /**
         * 二维码扫码登陆
         *
         * @generated from protobuf field: string qrCode = 3;
         */
        qrCode: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message mp.user.account.v1.Phone
 */
export interface Phone {
    /**
     * @generated from protobuf field: string number = 1;
     */
    number: string; // 手机号
    /**
     * @generated from protobuf field: uint32 zone = 2;
     */
    zone: number; // 手机地区号
}
/**
 * @generated from protobuf message mp.user.account.v1.GuidLogin
 */
export interface GuidLogin {
    /**
     * @generated from protobuf field: string guid = 1;
     */
    guid: string; // 终端唯一设备号
}
/**
 * @generated from protobuf message mp.user.account.v1.PlatformAuth
 */
export interface PlatformAuth {
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformType platformType = 1;
     */
    platformType: PlatformType;
    /**
     * Oauth code 方式
     *
     * @generated from protobuf field: string authCode = 2;
     */
    authCode: string;
    /**
     * JWT token 方式
     *
     * @generated from protobuf field: string identityToken = 3;
     */
    identityToken: string;
    /**
     * ACCESS TOKEN 方式登陆
     *
     * @generated from protobuf field: string openID = 8;
     */
    openID: string;
    /**
     * @generated from protobuf field: string accessToken = 9;
     */
    accessToken: string;
    /**
     * @generated from protobuf field: string accessSecrect = 6;
     */
    accessSecrect: string;
    /**
     * @generated from protobuf field: string param = 4;
     */
    param: string;
    /**
     * 指定appID, channel
     *
     * @generated from protobuf field: int32 appID = 5;
     */
    appID: number;
    /**
     * @generated from protobuf field: string channel = 10;
     */
    channel: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.AppCode
 */
export interface AppCode {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.Certificate
 */
export interface Certificate {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string; // 登录凭证
    /**
     * @generated from protobuf field: int64 expiredAt = 2;
     */
    expiredAt: number; // token 过期时间戳
    /**
     * @generated from protobuf field: mp.user.account.v1.Certificate.TokenType type = 3;
     */
    type: Certificate_TokenType;
}
/**
 * @generated from protobuf enum mp.user.account.v1.Certificate.TokenType
 */
export enum Certificate_TokenType {
    /**
     * @generated from protobuf enum value: TokenTypeUnknown = 0;
     */
    TokenTypeUnknown = 0,
    /**
     * 可以访问Token
     *
     * @generated from protobuf enum value: TokenTypeAccess = 1;
     */
    TokenTypeAccess = 1,
    /**
     * 已认证
     *
     * @generated from protobuf enum value: TokenTypePassportVerified = 2;
     */
    TokenTypePassportVerified = 2
}
/**
 * @generated from protobuf message mp.user.account.v1.LoginResult
 */
export interface LoginResult {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: int64 playerID = 2;
     */
    playerID: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.Certificate certificate = 3;
     */
    certificate?: Certificate;
    /**
     * @generated from protobuf field: bool isRegister = 4;
     */
    isRegister: boolean;
    /**
     * @generated from protobuf field: string passportID = 5;
     */
    passportID: string;
    /**
     * @generated from protobuf field: mp.base.PassportType passportType = 6;
     */
    passportType: PassportType;
    /**
     * @generated from protobuf field: string pubID = 7;
     */
    pubID: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.LoginResp
 */
export interface LoginResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.LoginResult result = 1;
     */
    result?: LoginResult;
}
/**
 * @generated from protobuf message mp.user.account.v1.OTPAuth
 */
export interface OTPAuth {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.AuthPassport
 */
export interface AuthPassport {
    /**
     * @generated from protobuf field: mp.base.PassportType type = 1;
     */
    type: PassportType;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.PassKeyCredential
 */
export interface PassKeyCredential {
    /**
     * @generated from protobuf field: string data = 1;
     */
    data: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.PassportAuthReq
 */
export interface PassportAuthReq {
    /**
     * @generated from protobuf field: mp.user.account.v1.AuthPassport passport = 1;
     */
    passport?: AuthPassport;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
    /**
     * @generated from protobuf oneof: verifyParam
     */
    verifyParam: {
        oneofKind: "captcha";
        /**
         * 验证码
         *
         * @generated from protobuf field: mp.user.account.v1.CaptchaEntity captcha = 5;
         */
        captcha: CaptchaEntity;
    } | {
        oneofKind: "platformAuth";
        /**
         * 平台授权
         *
         * @generated from protobuf field: mp.user.account.v1.PlatformAuth platformAuth = 6;
         */
        platformAuth: PlatformAuth;
    } | {
        oneofKind: "password";
        /**
         * 密码
         *
         * @generated from protobuf field: string password = 7;
         */
        password: string;
    } | {
        oneofKind: "signature";
        /**
         * 签名
         *
         * @generated from protobuf field: string signature = 9;
         */
        signature: string;
    } | {
        oneofKind: "otpAuth";
        /**
         * otp code
         *
         * @generated from protobuf field: mp.user.account.v1.OTPAuth otpAuth = 10;
         */
        otpAuth: OTPAuth;
    } | {
        oneofKind: "passKeyCredential";
        /**
         * @generated from protobuf field: mp.user.account.v1.PassKeyCredential passKeyCredential = 12;
         */
        passKeyCredential: PassKeyCredential;
    } | {
        oneofKind: "payPassword";
        /**
         * 密码
         *
         * @generated from protobuf field: string payPassword = 13;
         */
        payPassword: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message mp.user.account.v1.PassportAuthResp
 */
export interface PassportAuthResp {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: int64 expiredTime = 2;
     */
    expiredTime: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.Account account = 3;
     */
    account?: Account;
    /**
     * @generated from protobuf field: mp.user.account.v1.Passport passport = 4;
     */
    passport?: Passport;
}
/**
 * @generated from protobuf message mp.user.account.v1.PubIDCaptchaEntity
 */
export interface PubIDCaptchaEntity {
    /**
     * @generated from protobuf field: string pubID = 1;
     */
    pubID: string;
    /**
     * @generated from protobuf field: mp.base.PassportType passportType = 2;
     */
    passportType: PassportType;
}
/**
 *  ----  captcha
 *
 * @generated from protobuf message mp.user.account.v1.SendCaptchaReq
 */
export interface SendCaptchaReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaType captchaType = 2;
     */
    captchaType: CaptchaType; // 验证码类型
    /**
     * @generated from protobuf field: string lang = 10;
     */
    lang: string; // 语言
    /**
     * @generated from protobuf oneof: entity
     */
    entity: {
        oneofKind: "phone";
        /**
         * @generated from protobuf field: mp.user.account.v1.Phone phone = 3;
         */
        phone: Phone;
    } | {
        oneofKind: "email";
        /**
         * @generated from protobuf field: string email = 4;
         */
        email: string;
    } | {
        oneofKind: "pubIDCaptcha";
        /**
         * @generated from protobuf field: mp.user.account.v1.PubIDCaptchaEntity pubIDCaptcha = 6;
         */
        pubIDCaptcha: PubIDCaptchaEntity;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message mp.user.account.v1.SendCaptchaResp
 */
export interface SendCaptchaResp {
    /**
     * @generated from protobuf field: int64 expiredTime = 2;
     */
    expiredTime: number; // 验证码过期时间
}
/**
 * @generated from protobuf message mp.user.account.v1.VerifyCaptchaReq
 */
export interface VerifyCaptchaReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaType captchaType = 2;
     */
    captchaType: CaptchaType; // 验证码类型
    /**
     * @generated from protobuf field: string captcha = 3;
     */
    captcha: string; // 验证码
    /**
     * @generated from protobuf oneof: entity
     */
    entity: {
        oneofKind: "phone";
        /**
         * @generated from protobuf field: mp.user.account.v1.Phone phone = 5;
         */
        phone: Phone;
    } | {
        oneofKind: "email";
        /**
         * @generated from protobuf field: string email = 6;
         */
        email: string;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message mp.user.account.v1.VerifyCaptchaResp
 */
export interface VerifyCaptchaResp {
    /**
     * @generated from protobuf field: int64 expiredTime = 2;
     */
    expiredTime: number; // 验证码过期时间
}
/**
 * ----  bind account type
 *
 * @generated from protobuf message mp.user.account.v1.BindReq
 */
export interface BindReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string verifyAuthToken = 2;
     */
    verifyAuthToken: string;
    /**
     * @generated from protobuf field: string bindAuthToken = 3;
     */
    bindAuthToken: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.BindResp
 */
export interface BindResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.Passport passport = 1;
     */
    passport?: Passport;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * ----  unbind account type
 *
 * @generated from protobuf message mp.user.account.v1.UnbindReq
 */
export interface UnbindReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string verifyAuthToken = 2;
     */
    verifyAuthToken: string;
    /**
     * 解绑
     *
     * @generated from protobuf field: mp.user.account.v1.Passport unbindPassport = 3;
     */
    unbindPassport?: Passport;
}
/**
 * @generated from protobuf message mp.user.account.v1.UnbindResp
 */
export interface UnbindResp {
}
/**
 * ---- charnge account type
 *
 * @generated from protobuf message mp.user.account.v1.ChangeReq
 */
export interface ChangeReq {
    /**
     * @generated from protobuf field: string verifyAuthToken = 1;
     */
    verifyAuthToken: string;
    /**
     * @generated from protobuf field: string changeAuthToken = 2;
     */
    changeAuthToken: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.ChangeResp
 */
export interface ChangeResp {
}
/**
 * ---- set account password
 *
 * @generated from protobuf message mp.user.account.v1.PhoneCaptcha
 */
export interface PhoneCaptcha {
    /**
     * @generated from protobuf field: mp.user.account.v1.Phone phone = 1;
     */
    phone?: Phone; // 手机号
    /**
     * @generated from protobuf field: string captcha = 2;
     */
    captcha: string; // 验证码
}
/**
 * @generated from protobuf message mp.user.account.v1.CaptchaEntity
 */
export interface CaptchaEntity {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: mp.user.account.v1.CaptchaType captchaType = 2;
     */
    captchaType: CaptchaType;
    /**
     * 没指定就默认，指定就走指定 passport
     *
     * @generated from protobuf field: mp.base.PassportType passportType = 3;
     */
    passportType: PassportType;
}
/**
 * @generated from protobuf message mp.user.account.v1.SetPasswordReq
 */
export interface SetPasswordReq {
    /**
     * @generated from protobuf field: string verifyAuthToken = 1;
     */
    verifyAuthToken: string;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.SetPasswordResp
 */
export interface SetPasswordResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.Profile
 */
export interface Profile {
    /**
     * @generated from protobuf field: string nickname = 1;
     */
    nickname: string;
    /**
     * @generated from protobuf field: string avatar = 2;
     */
    avatar: string;
    /**
     * @generated from protobuf field: string username = 3;
     */
    username: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.Passport
 */
export interface Passport {
    /**
     * @generated from protobuf field: mp.base.PassportType type = 1;
     */
    type: PassportType;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
    /**
     * @generated from protobuf field: string relateID = 3;
     */
    relateID: string;
    /**
     * @generated from protobuf field: mp.user.account.v1.Profile profile = 4;
     */
    profile?: Profile;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetReq
 */
export interface GetReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetResp
 */
export interface GetResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.Account account = 1;
     */
    account?: Account;
}
/**
 * @generated from protobuf message mp.user.account.v1.Account
 */
export interface Account {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: bool existPassword = 2;
     */
    existPassword: boolean;
    /**
     * @generated from protobuf field: string pubID = 3;
     */
    pubID: string;
    /**
     * @generated from protobuf field: bool existOTP = 4;
     */
    existOTP: boolean;
    /**
     * @generated from protobuf field: bool existPayPassword = 5;
     */
    existPayPassword: boolean;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassportReq
 */
export interface GetPassportReq {
    /**
     * @generated from protobuf field: string passportID = 1;
     */
    passportID: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassportResp
 */
export interface GetPassportResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.Passport Passport = 1 [json_name = "Passport"];
     */
    Passport?: Passport;
    /**
     * @generated from protobuf field: mp.user.account.v1.Account account = 2;
     */
    account?: Account;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPassportReq
 */
export interface ListPassportReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: mp.base.PassportType type = 2;
     */
    type: PassportType;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPassportResp
 */
export interface ListPassportResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.Passport passportList = 1;
     */
    passportList: Passport[];
    /**
     * @generated from protobuf field: mp.user.account.v1.Account account = 2;
     */
    account?: Account;
}
/**
 * @generated from protobuf message mp.user.account.v1.Platform
 */
export interface Platform {
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformType type = 1;
     */
    type: PlatformType;
    /**
     * @generated from protobuf field: string id = 2;
     */
    id: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.IDCard
 */
export interface IDCard {
    /**
     * @generated from protobuf field: string cardNo = 1;
     */
    cardNo: string; // 身份证号
    /**
     * @generated from protobuf field: string cardName = 2;
     */
    cardName: string; // 真实姓名
    /**
     * @generated from protobuf field: mp.user.account.v1.IDCardStatus status = 3;
     */
    status: IDCardStatus; // 身份证认证状态
    /**
     * @generated from protobuf field: int64 updateAt = 4;
     */
    updateAt: number; // 更新时间
}
/**
 * **  VerifyIDCard **
 *
 * @generated from protobuf message mp.user.account.v1.VerifyIDCardReq
 */
export interface VerifyIDCardReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string idCardName = 3;
     */
    idCardName: string; // 姓名
    /**
     * @generated from protobuf field: string idCardNo = 4;
     */
    idCardNo: string; // 身份证号
}
/**
 * @generated from protobuf message mp.user.account.v1.VerifyIDCardResp
 */
export interface VerifyIDCardResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.IDCardStatus status = 1;
     */
    status: IDCardStatus;
    /**
     * @generated from protobuf field: int32 retryRemain = 2;
     */
    retryRemain: number;
}
/**
 * **  GetUserIDCard **
 *
 * @generated from protobuf message mp.user.account.v1.GetIDCardReq
 */
export interface GetIDCardReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetIDCardResp
 */
export interface GetIDCardResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.IDCard info = 1;
     */
    info?: IDCard;
}
/**
 * **  GetIDCardByNo **
 *
 * @generated from protobuf message mp.user.account.v1.GetIDCardByNoReq
 */
export interface GetIDCardByNoReq {
    /**
     * @generated from protobuf field: string idCardNo = 1;
     */
    idCardNo: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetIDCardByNoResp
 */
export interface GetIDCardByNoResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.IDCard info = 1;
     */
    info?: IDCard;
}
/**
 * **  RemoveUserIDCard **
 *
 * @generated from protobuf message mp.user.account.v1.RemoveIDCardReq
 */
export interface RemoveIDCardReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.RemoveIDCardResp
 */
export interface RemoveIDCardResp {
}
/**
 * **  ResetAccountIDCardRetryLimit **
 *
 * @generated from protobuf message mp.user.account.v1.ResetIDCardRetryLimitReq
 */
export interface ResetIDCardRetryLimitReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ResetIDCardRetryLimitResp
 */
export interface ResetIDCardRetryLimitResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.PhoneAccount
 */
export interface PhoneAccount {
    /**
     * @generated from protobuf field: string number = 1;
     */
    number: string; // 手机号
    /**
     * @generated from protobuf field: uint32 zone = 2;
     */
    zone: number; // 手机地区号
    /**
     * @generated from protobuf field: int64 accountID = 3;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListReq
 */
export interface ListReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.Phone phoneList = 2;
     */
    phoneList: Phone[];
}
/**
 * @generated from protobuf message mp.user.account.v1.ListResp
 */
export interface ListResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.PhoneAccount phoneAccountList = 2;
     */
    phoneAccountList: PhoneAccount[];
}
/**
 * @generated from protobuf message mp.user.account.v1.AccessTokenClaims
 */
export interface AccessTokenClaims {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 playerID = 2;
     */
    playerID: number;
    /**
     * @generated from protobuf field: int64 accountID = 3;
     */
    accountID: number;
    /**
     * @generated from protobuf field: int32 random = 4;
     */
    random: number;
    /**
     * @generated from protobuf field: string tag = 5;
     */
    tag: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.PassportVerifiedTokenClaims
 */
export interface PassportVerifiedTokenClaims {
    /**
     * @generated from protobuf field: string passportID = 1;
     */
    passportID: string;
    /**
     * @generated from protobuf field: mp.base.PassportType passportType = 2;
     */
    passportType: PassportType;
    /**
     * @generated from protobuf field: int64 accountID = 3;
     */
    accountID: number;
    /**
     * @generated from protobuf field: int32 random = 4;
     */
    random: number;
    /**
     * @generated from protobuf field: mp.base.PassportAuthType authType = 5;
     */
    authType: PassportAuthType;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetCertificationReq
 */
export interface GetCertificationReq {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetCertificationResp
 */
export interface GetCertificationResp {
    /**
     * @generated from protobuf field: int64 expiredAt = 3;
     */
    expiredAt: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.AccessTokenClaims claims = 4;
     */
    claims?: AccessTokenClaims;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassportTokenReq
 */
export interface GetPassportTokenReq {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: bool checkTTL = 2;
     */
    checkTTL: boolean;
    /**
     * @generated from protobuf field: bool checkAuthChange = 3;
     */
    checkAuthChange: boolean;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassportTokenResp
 */
export interface GetPassportTokenResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.PassportVerifiedTokenClaims claims = 1;
     */
    claims?: PassportVerifiedTokenClaims;
    /**
     * @generated from protobuf field: int64 authChangeAt = 2;
     */
    authChangeAt: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.RefreshCertificationReq
 */
export interface RefreshCertificationReq {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.RefreshCertificationResp
 */
export interface RefreshCertificationResp {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: int64 expiredAt = 2;
     */
    expiredAt: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.AccessTokenClaims claims = 3;
     */
    claims?: AccessTokenClaims;
}
/**
 * @generated from protobuf message mp.user.account.v1.CertificateLogin
 */
export interface CertificateLogin {
    /**
     * @generated from protobuf field: string accessToken = 1;
     */
    accessToken: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.CreateWalletSignatureMsgReq
 */
export interface CreateWalletSignatureMsgReq {
    /**
     * @generated from protobuf field: string publicAddress = 2;
     */
    publicAddress: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.CreateWalletSignatureMsgResp
 */
export interface CreateWalletSignatureMsgResp {
    /**
     * @generated from protobuf field: string msg = 1;
     */
    msg: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.GenerateQRCodeReq
 */
export interface GenerateQRCodeReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 playerID = 2;
     */
    playerID: number;
    /**
     * @generated from protobuf field: string data = 3;
     */
    data: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.GenerateQRCodeResp
 */
export interface GenerateQRCodeResp {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int64 expiredAt = 2;
     */
    expiredAt: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.CheckQRCodeReq
 */
export interface CheckQRCodeReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.CheckQRCodeResp
 */
export interface CheckQRCodeResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.QRCodeStatus status = 1;
     */
    status: QRCodeStatus;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: int32 authAppID = 3;
     */
    authAppID: number;
    /**
     * @generated from protobuf field: int64 authPlayerID = 4;
     */
    authPlayerID: number;
    /**
     * @generated from protobuf field: int32 srcAppID = 5;
     */
    srcAppID: number;
    /**
     * @generated from protobuf field: int64 srcPlayerID = 6;
     */
    srcPlayerID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ScanQRCodeReq
 */
export interface ScanQRCodeReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.ScanQRCodeResp
 */
export interface ScanQRCodeResp {
    /**
     * @generated from protobuf field: string data = 1;
     */
    data: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.ConfirmQRCodeReq
 */
export interface ConfirmQRCodeReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int32 appID = 2;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 playerID = 3;
     */
    playerID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ConfirmQRCodeResp
 */
export interface ConfirmQRCodeResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.CancelQRCodeReq
 */
export interface CancelQRCodeReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.CancelQRCodeResp
 */
export interface CancelQRCodeResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.CancelReq
 */
export interface CancelReq {
    /**
     * @generated from protobuf field: string pubID = 1;
     */
    pubID: string;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.CancelResp
 */
export interface CancelResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.InvitationCode
 */
export interface InvitationCode {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: int64 createdAt = 2;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 ownerAccountID = 3;
     */
    ownerAccountID: number;
    /**
     * @generated from protobuf field: int64 invitationCount = 4;
     */
    invitationCount: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.InvitationRecord
 */
export interface InvitationRecord {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: int64 createdAt = 2;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 ownerAccountID = 3;
     */
    ownerAccountID: number;
    /**
     * @generated from protobuf field: int64 usedAccountID = 4;
     */
    usedAccountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.CreateInvitationCodeReq
 */
export interface CreateInvitationCodeReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.CreateInvitationCodeResp
 */
export interface CreateInvitationCodeResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.GetInvitationCodeReq
 */
export interface GetInvitationCodeReq {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetInvitationCodeResp
 */
export interface GetInvitationCodeResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.InvitationCode invitationCode = 1;
     */
    invitationCode?: InvitationCode;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetAccountInvitationCodeReq
 */
export interface GetAccountInvitationCodeReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetAccountInvitationCodeResp
 */
export interface GetAccountInvitationCodeResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.InvitationCode invitationCode = 1;
     */
    invitationCode?: InvitationCode;
    /**
     * @generated from protobuf field: int64 invitationCount = 2;
     */
    invitationCount: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.TwitterUser
 */
export interface TwitterUser {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string screenName = 2;
     */
    screenName: string;
    /**
     * @generated from protobuf field: string location = 3;
     */
    location: string;
    /**
     * @generated from protobuf field: bool verified = 4;
     */
    verified: boolean;
    /**
     * @generated from protobuf field: int32 followersCount = 5;
     */
    followersCount: number;
    /**
     * @generated from protobuf field: int32 friendsCount = 6;
     */
    friendsCount: number;
    /**
     * @generated from protobuf field: int32 listedCount = 7;
     */
    listedCount: number;
    /**
     * @generated from protobuf field: int32 favouritesCount = 8;
     */
    favouritesCount: number;
    /**
     * @generated from protobuf field: int32 statusesCount = 9;
     */
    statusesCount: number;
    /**
     * @generated from protobuf field: int64 userCreatedAt = 10;
     */
    userCreatedAt: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.Location
 */
export interface Location {
    /**
     * @generated from protobuf field: string countryCode = 1;
     */
    countryCode: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.DeviceLoginLog
 */
export interface DeviceLoginLog {
    /**
     * @generated from protobuf field: mp.base.Device device = 1;
     */
    device?: Device; // 设备
    /**
     * @generated from protobuf field: int64 loginAt = 2;
     */
    loginAt: number;
    /**
     * @generated from protobuf field: string ip = 3;
     */
    ip: string;
    /**
     * @generated from protobuf field: mp.user.account.v1.Location location = 4;
     */
    location?: Location;
    /**
     * @generated from protobuf field: int64 count = 5;
     */
    count: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListDeviceLoginLogReq
 */
export interface ListDeviceLoginLogReq {
    /**
     * @generated from protobuf field: mp.base.Device device = 1;
     */
    device?: Device;
    /**
     * @generated from protobuf field: int32 appID = 2;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 accountID = 3;
     */
    accountID: number;
    /**
     * @generated from protobuf field: int64 page = 4;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 5;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListDeviceLoginLogResp
 */
export interface ListDeviceLoginLogResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.DeviceLoginLog deviceLoginLogList = 1;
     */
    deviceLoginLogList: DeviceLoginLog[];
}
/**
 * @generated from protobuf message mp.user.account.v1.ListDeviceLastLoginLogReq
 */
export interface ListDeviceLastLoginLogReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListDeviceLastLoginLogResp
 */
export interface ListDeviceLastLoginLogResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.DeviceLoginLog deviceLoginLogList = 1;
     */
    deviceLoginLogList: DeviceLoginLog[];
}
/**
 * @generated from protobuf message mp.user.account.v1.InvitationCodeApply
 */
export interface InvitationCodeApply {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.InvitationCodeApplyStatus status = 3;
     */
    status: InvitationCodeApplyStatus;
    /**
     * @generated from protobuf field: int32 num = 4;
     */
    num: number;
    /**
     * @generated from protobuf field: int64 createdAt = 5;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 operatedAt = 6;
     */
    operatedAt: number;
    /**
     * @generated from protobuf field: string operator = 7;
     */
    operator: string;
    /**
     * @generated from protobuf field: int32 reason = 8;
     */
    reason: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ApplyInvitationCodeReq
 */
export interface ApplyInvitationCodeReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: bool check = 2;
     */
    check: boolean;
}
/**
 * @generated from protobuf message mp.user.account.v1.ApplyInvitationCodeResp
 */
export interface ApplyInvitationCodeResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.PassInvitationCodeApplyReq
 */
export interface PassInvitationCodeApplyReq {
    /**
     * @generated from protobuf field: string applyID = 1;
     */
    applyID: string;
    /**
     * @generated from protobuf field: int64 num = 2;
     */
    num: number;
    /**
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.PassInvitationCodeApplyResp
 */
export interface PassInvitationCodeApplyResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.RejectInvitationCodeApplyReq
 */
export interface RejectInvitationCodeApplyReq {
    /**
     * @generated from protobuf field: string applyID = 1;
     */
    applyID: string;
    /**
     * @generated from protobuf field: int32 reason = 2;
     */
    reason: number;
    /**
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.RejectInvitationCodeApplyResp
 */
export interface RejectInvitationCodeApplyResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.ListInvitationCodeApplyReq
 */
export interface ListInvitationCodeApplyReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: bool isApplying = 4;
     */
    isApplying: boolean;
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListInvitationCodeApplyResp
 */
export interface ListInvitationCodeApplyResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.InvitationCodeApply applyList = 1;
     */
    applyList: InvitationCodeApply[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetInvitationCodeApplyReq
 */
export interface GetInvitationCodeApplyReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetInvitationCodeApplyResp
 */
export interface GetInvitationCodeApplyResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.InvitationCodeApply apply = 1;
     */
    apply?: InvitationCodeApply;
}
/**
 * @generated from protobuf message mp.user.account.v1.VerifyAuthToken
 */
export interface VerifyAuthToken {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: repeated mp.base.PassportAuthType allowAuthTypeList = 2;
     */
    allowAuthTypeList: PassportAuthType[];
    /**
     * @generated from protobuf field: repeated mp.base.PassportType allowPassportTypeList = 3;
     */
    allowPassportTypeList: PassportType[];
    /**
     * @generated from protobuf field: repeated mp.base.PassportAuthType denyAuthTypeList = 4;
     */
    denyAuthTypeList: PassportAuthType[];
    /**
     * @generated from protobuf field: repeated mp.base.PassportType denyPassportTypeList = 5;
     */
    denyPassportTypeList: PassportType[];
}
/**
 * @generated from protobuf message mp.user.account.v1.Enable2FAReq
 */
export interface Enable2FAReq {
    /**
     * @generated from protobuf field: mp.user.account.v1.VerifyAuthToken authToken = 1;
     */
    authToken?: VerifyAuthToken;
    /**
     * @generated from protobuf field: mp.user.account.v1.VerifyAuthToken newFactorAuthToken = 2;
     */
    newFactorAuthToken?: VerifyAuthToken;
}
/**
 * @generated from protobuf message mp.user.account.v1.Enable2FAResp
 */
export interface Enable2FAResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.Disable2FAReq
 */
export interface Disable2FAReq {
    /**
     * @generated from protobuf field: mp.user.account.v1.VerifyAuthToken authToken = 1;
     */
    authToken?: VerifyAuthToken;
    /**
     * @generated from protobuf field: mp.user.account.v1.VerifyAuthToken factorAuthToken = 2;
     */
    factorAuthToken?: VerifyAuthToken;
}
/**
 * @generated from protobuf message mp.user.account.v1.Disable2FAResp
 */
export interface Disable2FAResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.Verify2FAReq
 */
export interface Verify2FAReq {
    /**
     * @generated from protobuf field: string authToken = 1;
     */
    authToken: string;
    /**
     * @generated from protobuf field: string factorAuthToken = 2;
     */
    factorAuthToken: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.Verify2FAResp
 */
export interface Verify2FAResp {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.Change2FAReq
 */
export interface Change2FAReq {
    /**
     * @generated from protobuf field: mp.user.account.v1.VerifyAuthToken authToken = 1;
     */
    authToken?: VerifyAuthToken;
    /**
     * @generated from protobuf field: mp.user.account.v1.VerifyAuthToken oldFactorAuthToken = 2;
     */
    oldFactorAuthToken?: VerifyAuthToken;
    /**
     * @generated from protobuf field: mp.user.account.v1.VerifyAuthToken newFactorAuthToken = 3;
     */
    newFactorAuthToken?: VerifyAuthToken;
}
/**
 * @generated from protobuf message mp.user.account.v1.Change2FAResp
 */
export interface Change2FAResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.FactorPassport
 */
export interface FactorPassport {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: mp.base.PassportType type = 2;
     */
    type: PassportType;
    /**
     * @generated from protobuf field: bool isEnable = 3;
     */
    isEnable: boolean;
    /**
     * @generated from protobuf field: mp.base.PassportAuthType authType = 4;
     */
    authType: PassportAuthType;
}
/**
 * @generated from protobuf message mp.user.account.v1.Get2FAReq
 */
export interface Get2FAReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.Get2FAResp
 */
export interface Get2FAResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.FactorPassport factorPassport = 1;
     */
    factorPassport?: FactorPassport;
}
/**
 * @generated from protobuf message mp.user.account.v1.GenerateOTPSecretReq
 */
export interface GenerateOTPSecretReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GenerateOTPSecretResp
 */
export interface GenerateOTPSecretResp {
    /**
     * @generated from protobuf field: string secret = 1;
     */
    secret: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.ChangeOTPReq
 */
export interface ChangeOTPReq {
    /**
     * @generated from protobuf field: string authToken = 1;
     */
    authToken: string;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.ChangeOTPResp
 */
export interface ChangeOTPResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.DelOTPReq
 */
export interface DelOTPReq {
    /**
     * @generated from protobuf field: string authToken = 1;
     */
    authToken: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.DelOTPResp
 */
export interface DelOTPResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.PlatformEntity
 */
export interface PlatformEntity {
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformEntity.Status status = 1;
     */
    status: PlatformEntity_Status;
    /**
     * @generated from protobuf oneof: entity
     */
    entity: {
        oneofKind: "twitter";
        /**
         * @generated from protobuf field: mp.user.account.v1.PlatformEntity.Twitter twitter = 3;
         */
        twitter: PlatformEntity_Twitter;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message mp.user.account.v1.PlatformEntity.Twitter
 */
export interface PlatformEntity_Twitter {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string username = 3;
     */
    username: string;
    /**
     * @generated from protobuf field: string profileImageURL = 4;
     */
    profileImageURL: string;
    /**
     * @generated from protobuf field: int32 flowersCount = 5;
     */
    flowersCount: number;
    /**
     * @generated from protobuf field: int32 followingCount = 6;
     */
    followingCount: number;
    /**
     * @generated from protobuf field: int32 tweetCount = 7;
     */
    tweetCount: number;
    /**
     * @generated from protobuf field: int32 listedCount = 8;
     */
    listedCount: number;
    /**
     * @generated from protobuf field: bool verified = 9;
     */
    verified: boolean;
    /**
     * @generated from protobuf field: int64 createdAt = 10;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 lastUpdateAt = 11;
     */
    lastUpdateAt: number;
    /**
     * @generated from protobuf field: repeated string connectionStatus = 12;
     */
    connectionStatus: string[];
}
/**
 * @generated from protobuf enum mp.user.account.v1.PlatformEntity.Status
 */
export enum PlatformEntity_Status {
    /**
     * @generated from protobuf enum value: StatusUnknown = 0;
     */
    StatusUnknown = 0,
    /**
     * 等待审核
     *
     * @generated from protobuf enum value: StatusWaiting = 1;
     */
    StatusWaiting = 1,
    /**
     * 通过验证
     *
     * @generated from protobuf enum value: StatusVerified = 2;
     */
    StatusVerified = 2,
    /**
     * 拒绝
     *
     * @generated from protobuf enum value: StatusReject = 3;
     */
    StatusReject = 3
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPlatformEntityReq
 */
export interface ListPlatformEntityReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformEntity.Status status = 3;
     */
    status: PlatformEntity_Status;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPlatformEntityResp
 */
export interface ListPlatformEntityResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.PlatformEntity entityList = 1;
     */
    entityList: PlatformEntity[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.SetPlatformEntityReq
 */
export interface SetPlatformEntityReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformType type = 2;
     */
    type: PlatformType;
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformEntity.Status status = 3;
     */
    status: PlatformEntity_Status;
    /**
     * @generated from protobuf field: int64 accountID = 4;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.SetPlatformEntityResp
 */
export interface SetPlatformEntityResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.PlatformEntityStatus
 */
export interface PlatformEntityStatus {
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformType type = 1;
     */
    type: PlatformType;
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformEntity.Status status = 2;
     */
    status: PlatformEntity_Status;
    /**
     * @generated from protobuf field: int64 accountID = 3;
     */
    accountID: number;
    /**
     * @generated from protobuf field: repeated string connectionStatus = 4;
     */
    connectionStatus: string[];
}
/**
 * @generated from protobuf message mp.user.account.v1.ListAccountPlatformEntityStatusReq
 */
export interface ListAccountPlatformEntityStatusReq {
    /**
     * @generated from protobuf field: repeated int64 accountIDList = 1;
     */
    accountIDList: number[];
}
/**
 * @generated from protobuf message mp.user.account.v1.ListAccountPlatformEntityStatusResp
 */
export interface ListAccountPlatformEntityStatusResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.PlatformEntityStatus statusList = 1;
     */
    statusList: PlatformEntityStatus[];
}
/**
 * @generated from protobuf message mp.user.account.v1.SetAccountPlatformEntityStatusReq
 */
export interface SetAccountPlatformEntityStatusReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformEntity.Status status = 2;
     */
    status: PlatformEntity_Status;
}
/**
 * @generated from protobuf message mp.user.account.v1.SetAccountPlatformEntityStatusResp
 */
export interface SetAccountPlatformEntityStatusResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPlatformEntityStatusReq
 */
export interface ListPlatformEntityStatusReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPlatformEntityStatusResp
 */
export interface ListPlatformEntityStatusResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.PlatformEntityStatus statusList = 1;
     */
    statusList: PlatformEntityStatus[];
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPlatformEntityStatusReq
 */
export interface GetPlatformEntityStatusReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPlatformEntityStatusResp
 */
export interface GetPlatformEntityStatusResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.PlatformEntityStatus status = 1;
     */
    status?: PlatformEntityStatus;
}
/**
 * @generated from protobuf message mp.user.account.v1.RegisterPassKeyBeginReq
 */
export interface RegisterPassKeyBeginReq {
    /**
     * @generated from protobuf field: string authToken = 1;
     */
    authToken: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.RegisterPassKeyBeginResp
 */
export interface RegisterPassKeyBeginResp {
    /**
     * @generated from protobuf field: string challenge = 1;
     */
    challenge: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.RegisterPassKeyFinishReq
 */
export interface RegisterPassKeyFinishReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string data = 2;
     */
    data: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.RegisterPassKeyFinishResp
 */
export interface RegisterPassKeyFinishResp {
    /**
     * @generated from protobuf field: string credID = 1;
     */
    credID: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.AuthByPassKeyBeginReq
 */
export interface AuthByPassKeyBeginReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string credID = 2;
     */
    credID: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.AuthByPassKeyBeginResp
 */
export interface AuthByPassKeyBeginResp {
    /**
     * @generated from protobuf field: string challenge = 1;
     */
    challenge: string;
    /**
     * @generated from protobuf field: repeated string credIDList = 2;
     */
    credIDList: string[];
}
/**
 * @generated from protobuf message mp.user.account.v1.AuthByPassKeyFinishReq
 */
export interface AuthByPassKeyFinishReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string data = 2;
     */
    data: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.AuthByPassKeyFinishResp
 */
export interface AuthByPassKeyFinishResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.PassKey
 */
export interface PassKey {
    /**
     * string id = 1;
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int64 createdAt = 3;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: string credID = 4;
     */
    credID: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPassKeyReq
 */
export interface ListPassKeyReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.ListPassKeyResp
 */
export interface ListPassKeyResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.PassKey passKeyList = 1;
     */
    passKeyList: PassKey[];
}
/**
 * @generated from protobuf message mp.user.account.v1.DelPassKeyReq
 */
export interface DelPassKeyReq {
    /**
     * @generated from protobuf field: string credID = 1;
     */
    credID: string;
    /**
     * @generated from protobuf field: string authToken = 2;
     */
    authToken: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.DelPassKeyResp
 */
export interface DelPassKeyResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.UpdatePassKeyReq
 */
export interface UpdatePassKeyReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string credID = 2;
     */
    credID: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.UpdatePassKeyResp
 */
export interface UpdatePassKeyResp {
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassKeyReq
 */
export interface GetPassKeyReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
    /**
     * @generated from protobuf field: string credID = 2;
     */
    credID: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassKeyResp
 */
export interface GetPassKeyResp {
    /**
     * @generated from protobuf field: mp.user.account.v1.PassKey passKey = 1;
     */
    passKey?: PassKey;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassportAuthReq
 */
export interface GetPassportAuthReq {
    /**
     * @generated from protobuf field: int64 accountID = 1;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.GetPassportAuthResp
 */
export interface GetPassportAuthResp {
    /**
     * @generated from protobuf field: int64 changeAt = 1;
     */
    changeAt: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.PageAccountInvitationCodeReq
 */
export interface PageAccountInvitationCodeReq {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
    /**
     * @generated from protobuf field: int64 page = 3;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 4;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.PageAccountInvitationCodeResp
 */
export interface PageAccountInvitationCodeResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.InvitationCode list = 1;
     */
    list: InvitationCode[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.PageAccountInvitationRecordReq
 */
export interface PageAccountInvitationRecordReq {
    /**
     * @generated from protobuf field: int64 ownerAccountID = 1;
     */
    ownerAccountID: number;
    /**
     * @generated from protobuf field: int64 usedAccountID = 2;
     */
    usedAccountID: number;
    /**
     * @generated from protobuf field: string code = 7;
     */
    code: string;
    /**
     * @generated from protobuf field: int64 startAt = 3;
     */
    startAt: number;
    /**
     * @generated from protobuf field: int64 endAt = 4;
     */
    endAt: number;
    /**
     * @generated from protobuf field: int64 page = 5;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 6;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.PageAccountInvitationRecordResp
 */
export interface PageAccountInvitationRecordResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.InvitationRecord list = 1;
     */
    list: InvitationRecord[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.PassportTypeCheck
 */
export interface PassportTypeCheck {
    /**
     * @generated from protobuf field: mp.base.PassportType passportType = 1;
     */
    passportType: PassportType;
    /**
     * @generated from protobuf field: bool exist = 2;
     */
    exist: boolean;
}
/**
 * @generated from protobuf message mp.user.account.v1.CheckPassportTypeReq
 */
export interface CheckPassportTypeReq {
    /**
     * @generated from protobuf field: repeated mp.base.PassportType list = 1;
     */
    list: PassportType[];
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.CheckPassportTypeResp
 */
export interface CheckPassportTypeResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.PassportTypeCheck list = 1;
     */
    list: PassportTypeCheck[];
}
/**
 * @generated from protobuf message mp.user.account.v1.AuthTypeCheck
 */
export interface AuthTypeCheck {
    /**
     * @generated from protobuf field: mp.base.PassportAuthType authType = 1;
     */
    authType: PassportAuthType;
    /**
     * @generated from protobuf field: bool exist = 2;
     */
    exist: boolean;
}
/**
 * @generated from protobuf message mp.user.account.v1.CheckAuthTypeReq
 */
export interface CheckAuthTypeReq {
    /**
     * @generated from protobuf field: repeated mp.base.PassportAuthType list = 1;
     */
    list: PassportAuthType[];
    /**
     * @generated from protobuf field: int64 accountID = 2;
     */
    accountID: number;
}
/**
 * @generated from protobuf message mp.user.account.v1.CheckAuthTypeResp
 */
export interface CheckAuthTypeResp {
    /**
     * @generated from protobuf field: repeated mp.user.account.v1.AuthTypeCheck list = 1;
     */
    list: AuthTypeCheck[];
}
/**
 * @generated from protobuf message mp.user.account.v1.SetPayPasswordReq
 */
export interface SetPayPasswordReq {
    /**
     * @generated from protobuf field: string verifyAuthToken = 1;
     */
    verifyAuthToken: string;
    /**
     * @generated from protobuf field: string password = 3;
     */
    password: string;
}
/**
 * @generated from protobuf message mp.user.account.v1.SetPayPasswordResp
 */
export interface SetPayPasswordResp {
}
/**
 * 验证码类型
 *
 * @generated from protobuf enum mp.user.account.v1.CaptchaType
 */
export enum CaptchaType {
    /**
     * 登录验证码
     *
     * @generated from protobuf enum value: CaptchaTypeLogin = 0;
     */
    CaptchaTypeLogin = 0,
    /**
     * 绑定手机验证码
     *
     * @generated from protobuf enum value: CaptchaTypeBind = 1;
     */
    CaptchaTypeBind = 1,
    /**
     * 换绑手机验证码
     *
     * @generated from protobuf enum value: CaptchaTypeChangeBind = 2;
     */
    CaptchaTypeChangeBind = 2,
    /**
     * 设置密码
     *
     * @generated from protobuf enum value: CaptchaTypeSetPassword = 3;
     */
    CaptchaTypeSetPassword = 3,
    /**
     * 验证
     *
     * @generated from protobuf enum value: CaptchaTypeVerify = 4;
     */
    CaptchaTypeVerify = 4
}
/**
 * @generated from protobuf enum mp.user.account.v1.CaptchaRiskType
 */
export enum CaptchaRiskType {
    /**
     * @generated from protobuf enum value: CaptchaRiskTypeUnknown = 0;
     */
    CaptchaRiskTypeUnknown = 0,
    /**
     * 重试次数
     *
     * @generated from protobuf enum value: CaptchaRiskControlTypeTry = 1;
     */
    CaptchaRiskControlTypeTry = 1,
    /**
     * 生成次数
     *
     * @generated from protobuf enum value: CaptchaRiskControlTypeGenerate = 2;
     */
    CaptchaRiskControlTypeGenerate = 2
}
/**
 * @generated from protobuf enum mp.user.account.v1.DeviceType
 */
export enum DeviceType {
    /**
     * * Windows
     *
     * @generated from protobuf enum value: DeviceTypeWin = 0;
     */
    DeviceTypeWin = 0,
    /**
     * * iOS
     *
     * @generated from protobuf enum value: DeviceTypeIOS = 1;
     */
    DeviceTypeIOS = 1,
    /**
     * * 安卓
     *
     * @generated from protobuf enum value: DeviceTypeAndroid = 2;
     */
    DeviceTypeAndroid = 2,
    /**
     * * 网页
     *
     * @generated from protobuf enum value: DeviceTypeWeb = 3;
     */
    DeviceTypeWeb = 3,
    /**
     * * Mac
     *
     * @generated from protobuf enum value: DeviceTypeMac = 4;
     */
    DeviceTypeMac = 4,
    /**
     * * 模拟器
     *
     * @generated from protobuf enum value: DeviceTypeSimulator = 5;
     */
    DeviceTypeSimulator = 5
}
/**
 * @generated from protobuf enum mp.user.account.v1.PlatformType
 */
export enum PlatformType {
    /**
     * @generated from protobuf enum value: PlatformTypeUnknown = 0;
     */
    PlatformTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: PlatformTypeWeixin = 1;
     */
    PlatformTypeWeixin = 1,
    /**
     * @generated from protobuf enum value: PlatformTypeApple = 2;
     */
    PlatformTypeApple = 2,
    /**
     * @generated from protobuf enum value: PlatformTypeOppo = 3;
     */
    PlatformTypeOppo = 3,
    /**
     * @generated from protobuf enum value: PlatformTypeVivo = 4;
     */
    PlatformTypeVivo = 4,
    /**
     * @generated from protobuf enum value: PlatformTypeHuawei = 5;
     */
    PlatformTypeHuawei = 5,
    /**
     * @generated from protobuf enum value: PlatformTypeLine = 9;
     */
    PlatformTypeLine = 9,
    /**
     * @generated from protobuf enum value: PlatformTypeFacebook = 10;
     */
    PlatformTypeFacebook = 10,
    /**
     * @generated from protobuf enum value: PlatformTypeGoogle = 11;
     */
    PlatformTypeGoogle = 11,
    /**
     * @generated from protobuf enum value: PlatformTypeGAW = 12;
     */
    PlatformTypeGAW = 12,
    /**
     * @generated from protobuf enum value: PlatformTypeTwitter = 13;
     */
    PlatformTypeTwitter = 13,
    /**
     * @generated from protobuf enum value: PlatformTypeBPT = 14;
     */
    PlatformTypeBPT = 14,
    /**
     * Mob 平台，不知道放哪好，先卡在这个位置吧
     *
     * @generated from protobuf enum value: PlatformTypeMob = 1000;
     */
    PlatformTypeMob = 1000
}
/**
 * @generated from protobuf enum mp.user.account.v1.IDCardStatus
 */
export enum IDCardStatus {
    /**
     * * 未认证
     *
     * @generated from protobuf enum value: IDCardStatusUnVerify = 0;
     */
    IDCardStatusUnVerify = 0,
    /**
     * * 认证中
     *
     * 根据中宣部要求，认证中的用户与通过认证的用户权限相同
     *
     * @generated from protobuf enum value: IDCardStatusVerifying = 1;
     */
    IDCardStatusVerifying = 1,
    /**
     * * 认证成功
     *
     * @generated from protobuf enum value: IDCardStatusVerifySuccess = 2;
     */
    IDCardStatusVerifySuccess = 2,
    /**
     * * 认证失败
     *
     * @generated from protobuf enum value: IDCardStatusVerifyFail = 3;
     */
    IDCardStatusVerifyFail = 3
}
/**
 * @generated from protobuf enum mp.user.account.v1.QRCodeStatus
 */
export enum QRCodeStatus {
    /**
     * 未扫描
     *
     * @generated from protobuf enum value: QRCodeStatusUnknown = 0;
     */
    QRCodeStatusUnknown = 0,
    /**
     * 已扫描，等待用户确认
     *
     * @generated from protobuf enum value: QRCodeStatusScanned = 1;
     */
    QRCodeStatusScanned = 1,
    /**
     * 已扫描，用户同意授权
     *
     * @generated from protobuf enum value: QRCodeStatusSuccess = 2;
     */
    QRCodeStatusSuccess = 2,
    /**
     * 取消授权
     *
     * @generated from protobuf enum value: QRCodeStatusCancel = 3;
     */
    QRCodeStatusCancel = 3,
    /**
     * 过期
     *
     * @generated from protobuf enum value: QRCodeStatusExpired = 4;
     */
    QRCodeStatusExpired = 4
}
/**
 * @generated from protobuf enum mp.user.account.v1.WaitStatus
 */
export enum WaitStatus {
    /**
     * @generated from protobuf enum value: WaitStatusUnknown = 0;
     */
    WaitStatusUnknown = 0,
    /**
     * @generated from protobuf enum value: WaitStatusWait = 1;
     */
    WaitStatusWait = 1,
    /**
     * @generated from protobuf enum value: WaitStatusPass = 2;
     */
    WaitStatusPass = 2,
    /**
     * @generated from protobuf enum value: WaitStatusCancel = 3;
     */
    WaitStatusCancel = 3
}
/**
 * @generated from protobuf enum mp.user.account.v1.InvitationCodeApplyStatus
 */
export enum InvitationCodeApplyStatus {
    /**
     * @generated from protobuf enum value: InvitationCodeApplyStatusUnknown = 0;
     */
    InvitationCodeApplyStatusUnknown = 0,
    /**
     * @generated from protobuf enum value: InvitationCodeApplyStatusApplying = 1;
     */
    InvitationCodeApplyStatusApplying = 1,
    /**
     * @generated from protobuf enum value: InvitationCodeApplyStatusSuccess = 2;
     */
    InvitationCodeApplyStatusSuccess = 2,
    /**
     * @generated from protobuf enum value: InvitationCodeApplyStatusReject = 3;
     */
    InvitationCodeApplyStatusReject = 3,
    /**
     * @generated from protobuf enum value: InvitationCodeApplyStatusExhaust = 4;
     */
    InvitationCodeApplyStatusExhaust = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class CaptchaStatus$Type extends MessageType<CaptchaStatus> {
    constructor() {
        super("mp.user.account.v1.CaptchaStatus", [
            { no: 1, name: "type", kind: "enum", T: () => ["mp.user.account.v1.CaptchaType", CaptchaType] },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CaptchaStatus>): CaptchaStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.code = "";
        message.expiredAt = 0;
        if (value !== undefined)
            reflectionMergePartial<CaptchaStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaptchaStatus): CaptchaStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.CaptchaType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* int64 expiredAt */ 3:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaptchaStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.CaptchaType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* int64 expiredAt = 3; */
        if (message.expiredAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.expiredAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CaptchaStatus
 */
export const CaptchaStatus = new CaptchaStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaptchaRiskStatus$Type extends MessageType<CaptchaRiskStatus> {
    constructor() {
        super("mp.user.account.v1.CaptchaRiskStatus", [
            { no: 1, name: "type", kind: "enum", T: () => ["mp.user.account.v1.CaptchaType", CaptchaType] },
            { no: 2, name: "riskType", kind: "enum", T: () => ["mp.user.account.v1.CaptchaRiskType", CaptchaRiskType] },
            { no: 3, name: "val", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "ttl", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CaptchaRiskStatus>): CaptchaRiskStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.riskType = 0;
        message.val = 0;
        message.ttl = 0;
        if (value !== undefined)
            reflectionMergePartial<CaptchaRiskStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaptchaRiskStatus): CaptchaRiskStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.CaptchaType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* mp.user.account.v1.CaptchaRiskType riskType */ 2:
                    message.riskType = reader.int32();
                    break;
                case /* int32 val */ 3:
                    message.val = reader.int32();
                    break;
                case /* int64 ttl */ 4:
                    message.ttl = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaptchaRiskStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.CaptchaType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* mp.user.account.v1.CaptchaRiskType riskType = 2; */
        if (message.riskType !== 0)
            writer.tag(2, WireType.Varint).int32(message.riskType);
        /* int32 val = 3; */
        if (message.val !== 0)
            writer.tag(3, WireType.Varint).int32(message.val);
        /* int64 ttl = 4; */
        if (message.ttl !== 0)
            writer.tag(4, WireType.Varint).int64(message.ttl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CaptchaRiskStatus
 */
export const CaptchaRiskStatus = new CaptchaRiskStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCaptchaStatusReq$Type extends MessageType<GetCaptchaStatusReq> {
    constructor() {
        super("mp.user.account.v1.GetCaptchaStatusReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "phone", kind: "message", oneof: "entity", T: () => Phone },
            { no: 3, name: "email", kind: "scalar", oneof: "entity", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCaptchaStatusReq>): GetCaptchaStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.entity = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<GetCaptchaStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCaptchaStatusReq): GetCaptchaStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* mp.user.account.v1.Phone phone */ 2:
                    message.entity = {
                        oneofKind: "phone",
                        phone: Phone.internalBinaryRead(reader, reader.uint32(), options, (message.entity as any).phone)
                    };
                    break;
                case /* string email */ 3:
                    message.entity = {
                        oneofKind: "email",
                        email: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCaptchaStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* mp.user.account.v1.Phone phone = 2; */
        if (message.entity.oneofKind === "phone")
            Phone.internalBinaryWrite(message.entity.phone, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string email = 3; */
        if (message.entity.oneofKind === "email")
            writer.tag(3, WireType.LengthDelimited).string(message.entity.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetCaptchaStatusReq
 */
export const GetCaptchaStatusReq = new GetCaptchaStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCaptchaStatusResp$Type extends MessageType<GetCaptchaStatusResp> {
    constructor() {
        super("mp.user.account.v1.GetCaptchaStatusResp", [
            { no: 1, name: "statusList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CaptchaRiskStatus },
            { no: 2, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CaptchaStatus }
        ]);
    }
    create(value?: PartialMessage<GetCaptchaStatusResp>): GetCaptchaStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.statusList = [];
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<GetCaptchaStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCaptchaStatusResp): GetCaptchaStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.CaptchaRiskStatus statusList */ 1:
                    message.statusList.push(CaptchaRiskStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated mp.user.account.v1.CaptchaStatus list */ 2:
                    message.list.push(CaptchaStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCaptchaStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.CaptchaRiskStatus statusList = 1; */
        for (let i = 0; i < message.statusList.length; i++)
            CaptchaRiskStatus.internalBinaryWrite(message.statusList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated mp.user.account.v1.CaptchaStatus list = 2; */
        for (let i = 0; i < message.list.length; i++)
            CaptchaStatus.internalBinaryWrite(message.list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetCaptchaStatusResp
 */
export const GetCaptchaStatusResp = new GetCaptchaStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetCaptchaRiskReq$Type extends MessageType<ResetCaptchaRiskReq> {
    constructor() {
        super("mp.user.account.v1.ResetCaptchaRiskReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["mp.user.account.v1.CaptchaType", CaptchaType] },
            { no: 3, name: "riskType", kind: "enum", T: () => ["mp.user.account.v1.CaptchaRiskType", CaptchaRiskType] },
            { no: 4, name: "phone", kind: "message", oneof: "entity", T: () => Phone },
            { no: 5, name: "email", kind: "scalar", oneof: "entity", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetCaptchaRiskReq>): ResetCaptchaRiskReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.type = 0;
        message.riskType = 0;
        message.entity = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<ResetCaptchaRiskReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetCaptchaRiskReq): ResetCaptchaRiskReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* mp.user.account.v1.CaptchaType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* mp.user.account.v1.CaptchaRiskType riskType */ 3:
                    message.riskType = reader.int32();
                    break;
                case /* mp.user.account.v1.Phone phone */ 4:
                    message.entity = {
                        oneofKind: "phone",
                        phone: Phone.internalBinaryRead(reader, reader.uint32(), options, (message.entity as any).phone)
                    };
                    break;
                case /* string email */ 5:
                    message.entity = {
                        oneofKind: "email",
                        email: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetCaptchaRiskReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* mp.user.account.v1.CaptchaType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* mp.user.account.v1.CaptchaRiskType riskType = 3; */
        if (message.riskType !== 0)
            writer.tag(3, WireType.Varint).int32(message.riskType);
        /* mp.user.account.v1.Phone phone = 4; */
        if (message.entity.oneofKind === "phone")
            Phone.internalBinaryWrite(message.entity.phone, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string email = 5; */
        if (message.entity.oneofKind === "email")
            writer.tag(5, WireType.LengthDelimited).string(message.entity.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ResetCaptchaRiskReq
 */
export const ResetCaptchaRiskReq = new ResetCaptchaRiskReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetCaptchaRiskResp$Type extends MessageType<ResetCaptchaRiskResp> {
    constructor() {
        super("mp.user.account.v1.ResetCaptchaRiskResp", []);
    }
    create(value?: PartialMessage<ResetCaptchaRiskResp>): ResetCaptchaRiskResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ResetCaptchaRiskResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetCaptchaRiskResp): ResetCaptchaRiskResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResetCaptchaRiskResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ResetCaptchaRiskResp
 */
export const ResetCaptchaRiskResp = new ResetCaptchaRiskResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoutReq$Type extends MessageType<LogoutReq> {
    constructor() {
        super("mp.user.account.v1.LogoutReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "fdID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<LogoutReq>): LogoutReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.accountID = 0;
        message.fdID = 0;
        if (value !== undefined)
            reflectionMergePartial<LogoutReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoutReq): LogoutReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* int64 fdID */ 3:
                    message.fdID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogoutReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        /* int64 fdID = 3; */
        if (message.fdID !== 0)
            writer.tag(3, WireType.Varint).int64(message.fdID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.LogoutReq
 */
export const LogoutReq = new LogoutReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogoutResp$Type extends MessageType<LogoutResp> {
    constructor() {
        super("mp.user.account.v1.LogoutResp", []);
    }
    create(value?: PartialMessage<LogoutResp>): LogoutResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LogoutResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogoutResp): LogoutResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: LogoutResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.LogoutResp
 */
export const LogoutResp = new LogoutResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginReq$Type extends MessageType<LoginReq> {
    constructor() {
        super("mp.user.account.v1.LoginReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "param", kind: "message", T: () => LoginParam },
            { no: 3, name: "autoRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "loginData", kind: "message", T: () => LoginData }
        ]);
    }
    create(value?: PartialMessage<LoginReq>): LoginReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.autoRegister = false;
        if (value !== undefined)
            reflectionMergePartial<LoginReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginReq): LoginReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* mp.base.LoginParam param */ 2:
                    message.param = LoginParam.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                case /* bool autoRegister */ 3:
                    message.autoRegister = reader.bool();
                    break;
                case /* mp.user.account.v1.LoginData loginData */ 4:
                    message.loginData = LoginData.internalBinaryRead(reader, reader.uint32(), options, message.loginData);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* mp.base.LoginParam param = 2; */
        if (message.param)
            LoginParam.internalBinaryWrite(message.param, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool autoRegister = 3; */
        if (message.autoRegister !== false)
            writer.tag(3, WireType.Varint).bool(message.autoRegister);
        /* mp.user.account.v1.LoginData loginData = 4; */
        if (message.loginData)
            LoginData.internalBinaryWrite(message.loginData, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.LoginReq
 */
export const LoginReq = new LoginReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignupReq$Type extends MessageType<SignupReq> {
    constructor() {
        super("mp.user.account.v1.SignupReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "param", kind: "message", T: () => LoginParam },
            { no: 3, name: "pubID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "authToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "invitationCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SignupReq>): SignupReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.pubID = "";
        message.authToken = "";
        message.invitationCode = "";
        message.password = "";
        if (value !== undefined)
            reflectionMergePartial<SignupReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignupReq): SignupReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* mp.base.LoginParam param */ 2:
                    message.param = LoginParam.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                case /* string pubID */ 3:
                    message.pubID = reader.string();
                    break;
                case /* string authToken */ 5:
                    message.authToken = reader.string();
                    break;
                case /* string invitationCode */ 7:
                    message.invitationCode = reader.string();
                    break;
                case /* string password */ 8:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignupReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* mp.base.LoginParam param = 2; */
        if (message.param)
            LoginParam.internalBinaryWrite(message.param, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string pubID = 3; */
        if (message.pubID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pubID);
        /* string authToken = 5; */
        if (message.authToken !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.authToken);
        /* string invitationCode = 7; */
        if (message.invitationCode !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.invitationCode);
        /* string password = 8; */
        if (message.password !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SignupReq
 */
export const SignupReq = new SignupReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignupResult$Type extends MessageType<SignupResult> {
    constructor() {
        super("mp.user.account.v1.SignupResult", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "playerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "passport", kind: "message", T: () => Passport },
            { no: 6, name: "pubID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SignupResult>): SignupResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.playerID = 0;
        message.accessToken = "";
        message.expiredAt = 0;
        message.pubID = "";
        if (value !== undefined)
            reflectionMergePartial<SignupResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignupResult): SignupResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* int64 playerID */ 2:
                    message.playerID = reader.int64().toNumber();
                    break;
                case /* string accessToken */ 3:
                    message.accessToken = reader.string();
                    break;
                case /* int64 expiredAt */ 4:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.Passport passport */ 5:
                    message.passport = Passport.internalBinaryRead(reader, reader.uint32(), options, message.passport);
                    break;
                case /* string pubID */ 6:
                    message.pubID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignupResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* int64 playerID = 2; */
        if (message.playerID !== 0)
            writer.tag(2, WireType.Varint).int64(message.playerID);
        /* string accessToken = 3; */
        if (message.accessToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accessToken);
        /* int64 expiredAt = 4; */
        if (message.expiredAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.expiredAt);
        /* mp.user.account.v1.Passport passport = 5; */
        if (message.passport)
            Passport.internalBinaryWrite(message.passport, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string pubID = 6; */
        if (message.pubID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.pubID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SignupResult
 */
export const SignupResult = new SignupResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SignupResp$Type extends MessageType<SignupResp> {
    constructor() {
        super("mp.user.account.v1.SignupResp", [
            { no: 1, name: "result", kind: "message", T: () => SignupResult }
        ]);
    }
    create(value?: PartialMessage<SignupResp>): SignupResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SignupResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SignupResp): SignupResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.SignupResult result */ 1:
                    message.result = SignupResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SignupResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.SignupResult result = 1; */
        if (message.result)
            SignupResult.internalBinaryWrite(message.result, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SignupResp
 */
export const SignupResp = new SignupResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginData$Type extends MessageType<LoginData> {
    constructor() {
        super("mp.user.account.v1.LoginData", [
            { no: 1, name: "authToken", kind: "scalar", oneof: "data", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accessToken", kind: "scalar", oneof: "data", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "qrCode", kind: "scalar", oneof: "data", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoginData>): LoginData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<LoginData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginData): LoginData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string authToken */ 1:
                    message.data = {
                        oneofKind: "authToken",
                        authToken: reader.string()
                    };
                    break;
                case /* string accessToken */ 2:
                    message.data = {
                        oneofKind: "accessToken",
                        accessToken: reader.string()
                    };
                    break;
                case /* string qrCode */ 3:
                    message.data = {
                        oneofKind: "qrCode",
                        qrCode: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string authToken = 1; */
        if (message.data.oneofKind === "authToken")
            writer.tag(1, WireType.LengthDelimited).string(message.data.authToken);
        /* string accessToken = 2; */
        if (message.data.oneofKind === "accessToken")
            writer.tag(2, WireType.LengthDelimited).string(message.data.accessToken);
        /* string qrCode = 3; */
        if (message.data.oneofKind === "qrCode")
            writer.tag(3, WireType.LengthDelimited).string(message.data.qrCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.LoginData
 */
export const LoginData = new LoginData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Phone$Type extends MessageType<Phone> {
    constructor() {
        super("mp.user.account.v1.Phone", [
            { no: 1, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "zone", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Phone>): Phone {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = "";
        message.zone = 0;
        if (value !== undefined)
            reflectionMergePartial<Phone>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Phone): Phone {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string number */ 1:
                    message.number = reader.string();
                    break;
                case /* uint32 zone */ 2:
                    message.zone = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Phone, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string number = 1; */
        if (message.number !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.number);
        /* uint32 zone = 2; */
        if (message.zone !== 0)
            writer.tag(2, WireType.Varint).uint32(message.zone);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Phone
 */
export const Phone = new Phone$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GuidLogin$Type extends MessageType<GuidLogin> {
    constructor() {
        super("mp.user.account.v1.GuidLogin", [
            { no: 1, name: "guid", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GuidLogin>): GuidLogin {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.guid = "";
        if (value !== undefined)
            reflectionMergePartial<GuidLogin>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GuidLogin): GuidLogin {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string guid */ 1:
                    message.guid = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GuidLogin, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string guid = 1; */
        if (message.guid !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.guid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GuidLogin
 */
export const GuidLogin = new GuidLogin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformAuth$Type extends MessageType<PlatformAuth> {
    constructor() {
        super("mp.user.account.v1.PlatformAuth", [
            { no: 1, name: "platformType", kind: "enum", T: () => ["mp.user.account.v1.PlatformType", PlatformType] },
            { no: 2, name: "authCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "identityToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "openID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "accessSecrect", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "param", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PlatformAuth>): PlatformAuth {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.platformType = 0;
        message.authCode = "";
        message.identityToken = "";
        message.openID = "";
        message.accessToken = "";
        message.accessSecrect = "";
        message.param = "";
        message.appID = 0;
        message.channel = "";
        if (value !== undefined)
            reflectionMergePartial<PlatformAuth>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlatformAuth): PlatformAuth {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.PlatformType platformType */ 1:
                    message.platformType = reader.int32();
                    break;
                case /* string authCode */ 2:
                    message.authCode = reader.string();
                    break;
                case /* string identityToken */ 3:
                    message.identityToken = reader.string();
                    break;
                case /* string openID */ 8:
                    message.openID = reader.string();
                    break;
                case /* string accessToken */ 9:
                    message.accessToken = reader.string();
                    break;
                case /* string accessSecrect */ 6:
                    message.accessSecrect = reader.string();
                    break;
                case /* string param */ 4:
                    message.param = reader.string();
                    break;
                case /* int32 appID */ 5:
                    message.appID = reader.int32();
                    break;
                case /* string channel */ 10:
                    message.channel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlatformAuth, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.PlatformType platformType = 1; */
        if (message.platformType !== 0)
            writer.tag(1, WireType.Varint).int32(message.platformType);
        /* string authCode = 2; */
        if (message.authCode !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.authCode);
        /* string identityToken = 3; */
        if (message.identityToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.identityToken);
        /* string openID = 8; */
        if (message.openID !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.openID);
        /* string accessToken = 9; */
        if (message.accessToken !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.accessToken);
        /* string accessSecrect = 6; */
        if (message.accessSecrect !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.accessSecrect);
        /* string param = 4; */
        if (message.param !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.param);
        /* int32 appID = 5; */
        if (message.appID !== 0)
            writer.tag(5, WireType.Varint).int32(message.appID);
        /* string channel = 10; */
        if (message.channel !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.channel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PlatformAuth
 */
export const PlatformAuth = new PlatformAuth$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppCode$Type extends MessageType<AppCode> {
    constructor() {
        super("mp.user.account.v1.AppCode", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AppCode>): AppCode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        if (value !== undefined)
            reflectionMergePartial<AppCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppCode): AppCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AppCode
 */
export const AppCode = new AppCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Certificate$Type extends MessageType<Certificate> {
    constructor() {
        super("mp.user.account.v1.Certificate", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["mp.user.account.v1.Certificate.TokenType", Certificate_TokenType] }
        ]);
    }
    create(value?: PartialMessage<Certificate>): Certificate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        message.expiredAt = 0;
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<Certificate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Certificate): Certificate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* int64 expiredAt */ 2:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.Certificate.TokenType type */ 3:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Certificate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* int64 expiredAt = 2; */
        if (message.expiredAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredAt);
        /* mp.user.account.v1.Certificate.TokenType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Certificate
 */
export const Certificate = new Certificate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginResult$Type extends MessageType<LoginResult> {
    constructor() {
        super("mp.user.account.v1.LoginResult", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "playerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "certificate", kind: "message", T: () => Certificate },
            { no: 4, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "passportID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "passportType", kind: "enum", T: () => ["mp.base.PassportType", PassportType] },
            { no: 7, name: "pubID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LoginResult>): LoginResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.playerID = 0;
        message.isRegister = false;
        message.passportID = "";
        message.passportType = 0;
        message.pubID = "";
        if (value !== undefined)
            reflectionMergePartial<LoginResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginResult): LoginResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* int64 playerID */ 2:
                    message.playerID = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.Certificate certificate */ 3:
                    message.certificate = Certificate.internalBinaryRead(reader, reader.uint32(), options, message.certificate);
                    break;
                case /* bool isRegister */ 4:
                    message.isRegister = reader.bool();
                    break;
                case /* string passportID */ 5:
                    message.passportID = reader.string();
                    break;
                case /* mp.base.PassportType passportType */ 6:
                    message.passportType = reader.int32();
                    break;
                case /* string pubID */ 7:
                    message.pubID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* int64 playerID = 2; */
        if (message.playerID !== 0)
            writer.tag(2, WireType.Varint).int64(message.playerID);
        /* mp.user.account.v1.Certificate certificate = 3; */
        if (message.certificate)
            Certificate.internalBinaryWrite(message.certificate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bool isRegister = 4; */
        if (message.isRegister !== false)
            writer.tag(4, WireType.Varint).bool(message.isRegister);
        /* string passportID = 5; */
        if (message.passportID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.passportID);
        /* mp.base.PassportType passportType = 6; */
        if (message.passportType !== 0)
            writer.tag(6, WireType.Varint).int32(message.passportType);
        /* string pubID = 7; */
        if (message.pubID !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.pubID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.LoginResult
 */
export const LoginResult = new LoginResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LoginResp$Type extends MessageType<LoginResp> {
    constructor() {
        super("mp.user.account.v1.LoginResp", [
            { no: 1, name: "result", kind: "message", T: () => LoginResult }
        ]);
    }
    create(value?: PartialMessage<LoginResp>): LoginResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LoginResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LoginResp): LoginResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.LoginResult result */ 1:
                    message.result = LoginResult.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LoginResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.LoginResult result = 1; */
        if (message.result)
            LoginResult.internalBinaryWrite(message.result, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.LoginResp
 */
export const LoginResp = new LoginResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OTPAuth$Type extends MessageType<OTPAuth> {
    constructor() {
        super("mp.user.account.v1.OTPAuth", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OTPAuth>): OTPAuth {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        if (value !== undefined)
            reflectionMergePartial<OTPAuth>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OTPAuth): OTPAuth {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OTPAuth, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.OTPAuth
 */
export const OTPAuth = new OTPAuth$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthPassport$Type extends MessageType<AuthPassport> {
    constructor() {
        super("mp.user.account.v1.AuthPassport", [
            { no: 1, name: "type", kind: "enum", T: () => ["mp.base.PassportType", PassportType] },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthPassport>): AuthPassport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<AuthPassport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthPassport): AuthPassport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.base.PassportType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthPassport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.base.PassportType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AuthPassport
 */
export const AuthPassport = new AuthPassport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassKeyCredential$Type extends MessageType<PassKeyCredential> {
    constructor() {
        super("mp.user.account.v1.PassKeyCredential", [
            { no: 1, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PassKeyCredential>): PassKeyCredential {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<PassKeyCredential>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassKeyCredential): PassKeyCredential {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string data */ 1:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassKeyCredential, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string data = 1; */
        if (message.data !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassKeyCredential
 */
export const PassKeyCredential = new PassKeyCredential$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassportAuthReq$Type extends MessageType<PassportAuthReq> {
    constructor() {
        super("mp.user.account.v1.PassportAuthReq", [
            { no: 1, name: "passport", kind: "message", T: () => AuthPassport },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "captcha", kind: "message", oneof: "verifyParam", T: () => CaptchaEntity },
            { no: 6, name: "platformAuth", kind: "message", oneof: "verifyParam", T: () => PlatformAuth },
            { no: 7, name: "password", kind: "scalar", oneof: "verifyParam", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "signature", kind: "scalar", oneof: "verifyParam", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "otpAuth", kind: "message", oneof: "verifyParam", T: () => OTPAuth },
            { no: 12, name: "passKeyCredential", kind: "message", oneof: "verifyParam", T: () => PassKeyCredential },
            { no: 13, name: "payPassword", kind: "scalar", oneof: "verifyParam", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PassportAuthReq>): PassportAuthReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.verifyParam = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<PassportAuthReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassportAuthReq): PassportAuthReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.AuthPassport passport */ 1:
                    message.passport = AuthPassport.internalBinaryRead(reader, reader.uint32(), options, message.passport);
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.CaptchaEntity captcha */ 5:
                    message.verifyParam = {
                        oneofKind: "captcha",
                        captcha: CaptchaEntity.internalBinaryRead(reader, reader.uint32(), options, (message.verifyParam as any).captcha)
                    };
                    break;
                case /* mp.user.account.v1.PlatformAuth platformAuth */ 6:
                    message.verifyParam = {
                        oneofKind: "platformAuth",
                        platformAuth: PlatformAuth.internalBinaryRead(reader, reader.uint32(), options, (message.verifyParam as any).platformAuth)
                    };
                    break;
                case /* string password */ 7:
                    message.verifyParam = {
                        oneofKind: "password",
                        password: reader.string()
                    };
                    break;
                case /* string signature */ 9:
                    message.verifyParam = {
                        oneofKind: "signature",
                        signature: reader.string()
                    };
                    break;
                case /* mp.user.account.v1.OTPAuth otpAuth */ 10:
                    message.verifyParam = {
                        oneofKind: "otpAuth",
                        otpAuth: OTPAuth.internalBinaryRead(reader, reader.uint32(), options, (message.verifyParam as any).otpAuth)
                    };
                    break;
                case /* mp.user.account.v1.PassKeyCredential passKeyCredential */ 12:
                    message.verifyParam = {
                        oneofKind: "passKeyCredential",
                        passKeyCredential: PassKeyCredential.internalBinaryRead(reader, reader.uint32(), options, (message.verifyParam as any).passKeyCredential)
                    };
                    break;
                case /* string payPassword */ 13:
                    message.verifyParam = {
                        oneofKind: "payPassword",
                        payPassword: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassportAuthReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.AuthPassport passport = 1; */
        if (message.passport)
            AuthPassport.internalBinaryWrite(message.passport, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        /* mp.user.account.v1.CaptchaEntity captcha = 5; */
        if (message.verifyParam.oneofKind === "captcha")
            CaptchaEntity.internalBinaryWrite(message.verifyParam.captcha, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.PlatformAuth platformAuth = 6; */
        if (message.verifyParam.oneofKind === "platformAuth")
            PlatformAuth.internalBinaryWrite(message.verifyParam.platformAuth, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string password = 7; */
        if (message.verifyParam.oneofKind === "password")
            writer.tag(7, WireType.LengthDelimited).string(message.verifyParam.password);
        /* string signature = 9; */
        if (message.verifyParam.oneofKind === "signature")
            writer.tag(9, WireType.LengthDelimited).string(message.verifyParam.signature);
        /* mp.user.account.v1.OTPAuth otpAuth = 10; */
        if (message.verifyParam.oneofKind === "otpAuth")
            OTPAuth.internalBinaryWrite(message.verifyParam.otpAuth, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.PassKeyCredential passKeyCredential = 12; */
        if (message.verifyParam.oneofKind === "passKeyCredential")
            PassKeyCredential.internalBinaryWrite(message.verifyParam.passKeyCredential, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string payPassword = 13; */
        if (message.verifyParam.oneofKind === "payPassword")
            writer.tag(13, WireType.LengthDelimited).string(message.verifyParam.payPassword);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassportAuthReq
 */
export const PassportAuthReq = new PassportAuthReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassportAuthResp$Type extends MessageType<PassportAuthResp> {
    constructor() {
        super("mp.user.account.v1.PassportAuthResp", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expiredTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "account", kind: "message", T: () => Account },
            { no: 4, name: "passport", kind: "message", T: () => Passport }
        ]);
    }
    create(value?: PartialMessage<PassportAuthResp>): PassportAuthResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        message.expiredTime = 0;
        if (value !== undefined)
            reflectionMergePartial<PassportAuthResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassportAuthResp): PassportAuthResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* int64 expiredTime */ 2:
                    message.expiredTime = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.Account account */ 3:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                case /* mp.user.account.v1.Passport passport */ 4:
                    message.passport = Passport.internalBinaryRead(reader, reader.uint32(), options, message.passport);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassportAuthResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* int64 expiredTime = 2; */
        if (message.expiredTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredTime);
        /* mp.user.account.v1.Account account = 3; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.Passport passport = 4; */
        if (message.passport)
            Passport.internalBinaryWrite(message.passport, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassportAuthResp
 */
export const PassportAuthResp = new PassportAuthResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PubIDCaptchaEntity$Type extends MessageType<PubIDCaptchaEntity> {
    constructor() {
        super("mp.user.account.v1.PubIDCaptchaEntity", [
            { no: 1, name: "pubID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "passportType", kind: "enum", T: () => ["mp.base.PassportType", PassportType] }
        ]);
    }
    create(value?: PartialMessage<PubIDCaptchaEntity>): PubIDCaptchaEntity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pubID = "";
        message.passportType = 0;
        if (value !== undefined)
            reflectionMergePartial<PubIDCaptchaEntity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PubIDCaptchaEntity): PubIDCaptchaEntity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string pubID */ 1:
                    message.pubID = reader.string();
                    break;
                case /* mp.base.PassportType passportType */ 2:
                    message.passportType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PubIDCaptchaEntity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string pubID = 1; */
        if (message.pubID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pubID);
        /* mp.base.PassportType passportType = 2; */
        if (message.passportType !== 0)
            writer.tag(2, WireType.Varint).int32(message.passportType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PubIDCaptchaEntity
 */
export const PubIDCaptchaEntity = new PubIDCaptchaEntity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendCaptchaReq$Type extends MessageType<SendCaptchaReq> {
    constructor() {
        super("mp.user.account.v1.SendCaptchaReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "captchaType", kind: "enum", T: () => ["mp.user.account.v1.CaptchaType", CaptchaType] },
            { no: 10, name: "lang", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phone", kind: "message", oneof: "entity", T: () => Phone },
            { no: 4, name: "email", kind: "scalar", oneof: "entity", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "pubIDCaptcha", kind: "message", oneof: "entity", T: () => PubIDCaptchaEntity }
        ]);
    }
    create(value?: PartialMessage<SendCaptchaReq>): SendCaptchaReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.captchaType = 0;
        message.lang = "";
        message.entity = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<SendCaptchaReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendCaptchaReq): SendCaptchaReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* mp.user.account.v1.CaptchaType captchaType */ 2:
                    message.captchaType = reader.int32();
                    break;
                case /* string lang */ 10:
                    message.lang = reader.string();
                    break;
                case /* mp.user.account.v1.Phone phone */ 3:
                    message.entity = {
                        oneofKind: "phone",
                        phone: Phone.internalBinaryRead(reader, reader.uint32(), options, (message.entity as any).phone)
                    };
                    break;
                case /* string email */ 4:
                    message.entity = {
                        oneofKind: "email",
                        email: reader.string()
                    };
                    break;
                case /* mp.user.account.v1.PubIDCaptchaEntity pubIDCaptcha */ 6:
                    message.entity = {
                        oneofKind: "pubIDCaptcha",
                        pubIDCaptcha: PubIDCaptchaEntity.internalBinaryRead(reader, reader.uint32(), options, (message.entity as any).pubIDCaptcha)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendCaptchaReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* mp.user.account.v1.CaptchaType captchaType = 2; */
        if (message.captchaType !== 0)
            writer.tag(2, WireType.Varint).int32(message.captchaType);
        /* string lang = 10; */
        if (message.lang !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.lang);
        /* mp.user.account.v1.Phone phone = 3; */
        if (message.entity.oneofKind === "phone")
            Phone.internalBinaryWrite(message.entity.phone, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string email = 4; */
        if (message.entity.oneofKind === "email")
            writer.tag(4, WireType.LengthDelimited).string(message.entity.email);
        /* mp.user.account.v1.PubIDCaptchaEntity pubIDCaptcha = 6; */
        if (message.entity.oneofKind === "pubIDCaptcha")
            PubIDCaptchaEntity.internalBinaryWrite(message.entity.pubIDCaptcha, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SendCaptchaReq
 */
export const SendCaptchaReq = new SendCaptchaReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendCaptchaResp$Type extends MessageType<SendCaptchaResp> {
    constructor() {
        super("mp.user.account.v1.SendCaptchaResp", [
            { no: 2, name: "expiredTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SendCaptchaResp>): SendCaptchaResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.expiredTime = 0;
        if (value !== undefined)
            reflectionMergePartial<SendCaptchaResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendCaptchaResp): SendCaptchaResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 expiredTime */ 2:
                    message.expiredTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendCaptchaResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 expiredTime = 2; */
        if (message.expiredTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SendCaptchaResp
 */
export const SendCaptchaResp = new SendCaptchaResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyCaptchaReq$Type extends MessageType<VerifyCaptchaReq> {
    constructor() {
        super("mp.user.account.v1.VerifyCaptchaReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "captchaType", kind: "enum", T: () => ["mp.user.account.v1.CaptchaType", CaptchaType] },
            { no: 3, name: "captcha", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "phone", kind: "message", oneof: "entity", T: () => Phone },
            { no: 6, name: "email", kind: "scalar", oneof: "entity", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyCaptchaReq>): VerifyCaptchaReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.captchaType = 0;
        message.captcha = "";
        message.entity = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<VerifyCaptchaReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyCaptchaReq): VerifyCaptchaReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* mp.user.account.v1.CaptchaType captchaType */ 2:
                    message.captchaType = reader.int32();
                    break;
                case /* string captcha */ 3:
                    message.captcha = reader.string();
                    break;
                case /* mp.user.account.v1.Phone phone */ 5:
                    message.entity = {
                        oneofKind: "phone",
                        phone: Phone.internalBinaryRead(reader, reader.uint32(), options, (message.entity as any).phone)
                    };
                    break;
                case /* string email */ 6:
                    message.entity = {
                        oneofKind: "email",
                        email: reader.string()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyCaptchaReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* mp.user.account.v1.CaptchaType captchaType = 2; */
        if (message.captchaType !== 0)
            writer.tag(2, WireType.Varint).int32(message.captchaType);
        /* string captcha = 3; */
        if (message.captcha !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.captcha);
        /* mp.user.account.v1.Phone phone = 5; */
        if (message.entity.oneofKind === "phone")
            Phone.internalBinaryWrite(message.entity.phone, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string email = 6; */
        if (message.entity.oneofKind === "email")
            writer.tag(6, WireType.LengthDelimited).string(message.entity.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.VerifyCaptchaReq
 */
export const VerifyCaptchaReq = new VerifyCaptchaReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyCaptchaResp$Type extends MessageType<VerifyCaptchaResp> {
    constructor() {
        super("mp.user.account.v1.VerifyCaptchaResp", [
            { no: 2, name: "expiredTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyCaptchaResp>): VerifyCaptchaResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.expiredTime = 0;
        if (value !== undefined)
            reflectionMergePartial<VerifyCaptchaResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyCaptchaResp): VerifyCaptchaResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 expiredTime */ 2:
                    message.expiredTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyCaptchaResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 expiredTime = 2; */
        if (message.expiredTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.VerifyCaptchaResp
 */
export const VerifyCaptchaResp = new VerifyCaptchaResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BindReq$Type extends MessageType<BindReq> {
    constructor() {
        super("mp.user.account.v1.BindReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "verifyAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bindAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BindReq>): BindReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.verifyAuthToken = "";
        message.bindAuthToken = "";
        if (value !== undefined)
            reflectionMergePartial<BindReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BindReq): BindReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string verifyAuthToken */ 2:
                    message.verifyAuthToken = reader.string();
                    break;
                case /* string bindAuthToken */ 3:
                    message.bindAuthToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BindReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string verifyAuthToken = 2; */
        if (message.verifyAuthToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.verifyAuthToken);
        /* string bindAuthToken = 3; */
        if (message.bindAuthToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.bindAuthToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.BindReq
 */
export const BindReq = new BindReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BindResp$Type extends MessageType<BindResp> {
    constructor() {
        super("mp.user.account.v1.BindResp", [
            { no: 1, name: "passport", kind: "message", T: () => Passport },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BindResp>): BindResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<BindResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BindResp): BindResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.Passport passport */ 1:
                    message.passport = Passport.internalBinaryRead(reader, reader.uint32(), options, message.passport);
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BindResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.Passport passport = 1; */
        if (message.passport)
            Passport.internalBinaryWrite(message.passport, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.BindResp
 */
export const BindResp = new BindResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnbindReq$Type extends MessageType<UnbindReq> {
    constructor() {
        super("mp.user.account.v1.UnbindReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "verifyAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "unbindPassport", kind: "message", T: () => Passport }
        ]);
    }
    create(value?: PartialMessage<UnbindReq>): UnbindReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.verifyAuthToken = "";
        if (value !== undefined)
            reflectionMergePartial<UnbindReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnbindReq): UnbindReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string verifyAuthToken */ 2:
                    message.verifyAuthToken = reader.string();
                    break;
                case /* mp.user.account.v1.Passport unbindPassport */ 3:
                    message.unbindPassport = Passport.internalBinaryRead(reader, reader.uint32(), options, message.unbindPassport);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnbindReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string verifyAuthToken = 2; */
        if (message.verifyAuthToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.verifyAuthToken);
        /* mp.user.account.v1.Passport unbindPassport = 3; */
        if (message.unbindPassport)
            Passport.internalBinaryWrite(message.unbindPassport, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.UnbindReq
 */
export const UnbindReq = new UnbindReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnbindResp$Type extends MessageType<UnbindResp> {
    constructor() {
        super("mp.user.account.v1.UnbindResp", []);
    }
    create(value?: PartialMessage<UnbindResp>): UnbindResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UnbindResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnbindResp): UnbindResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UnbindResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.UnbindResp
 */
export const UnbindResp = new UnbindResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeReq$Type extends MessageType<ChangeReq> {
    constructor() {
        super("mp.user.account.v1.ChangeReq", [
            { no: 1, name: "verifyAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "changeAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChangeReq>): ChangeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.verifyAuthToken = "";
        message.changeAuthToken = "";
        if (value !== undefined)
            reflectionMergePartial<ChangeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeReq): ChangeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string verifyAuthToken */ 1:
                    message.verifyAuthToken = reader.string();
                    break;
                case /* string changeAuthToken */ 2:
                    message.changeAuthToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChangeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string verifyAuthToken = 1; */
        if (message.verifyAuthToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.verifyAuthToken);
        /* string changeAuthToken = 2; */
        if (message.changeAuthToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.changeAuthToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ChangeReq
 */
export const ChangeReq = new ChangeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeResp$Type extends MessageType<ChangeResp> {
    constructor() {
        super("mp.user.account.v1.ChangeResp", []);
    }
    create(value?: PartialMessage<ChangeResp>): ChangeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ChangeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeResp): ChangeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ChangeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ChangeResp
 */
export const ChangeResp = new ChangeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhoneCaptcha$Type extends MessageType<PhoneCaptcha> {
    constructor() {
        super("mp.user.account.v1.PhoneCaptcha", [
            { no: 1, name: "phone", kind: "message", T: () => Phone },
            { no: 2, name: "captcha", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PhoneCaptcha>): PhoneCaptcha {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.captcha = "";
        if (value !== undefined)
            reflectionMergePartial<PhoneCaptcha>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhoneCaptcha): PhoneCaptcha {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.Phone phone */ 1:
                    message.phone = Phone.internalBinaryRead(reader, reader.uint32(), options, message.phone);
                    break;
                case /* string captcha */ 2:
                    message.captcha = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhoneCaptcha, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.Phone phone = 1; */
        if (message.phone)
            Phone.internalBinaryWrite(message.phone, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string captcha = 2; */
        if (message.captcha !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.captcha);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PhoneCaptcha
 */
export const PhoneCaptcha = new PhoneCaptcha$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CaptchaEntity$Type extends MessageType<CaptchaEntity> {
    constructor() {
        super("mp.user.account.v1.CaptchaEntity", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "captchaType", kind: "enum", T: () => ["mp.user.account.v1.CaptchaType", CaptchaType] },
            { no: 3, name: "passportType", kind: "enum", T: () => ["mp.base.PassportType", PassportType] }
        ]);
    }
    create(value?: PartialMessage<CaptchaEntity>): CaptchaEntity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        message.captchaType = 0;
        message.passportType = 0;
        if (value !== undefined)
            reflectionMergePartial<CaptchaEntity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CaptchaEntity): CaptchaEntity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* mp.user.account.v1.CaptchaType captchaType */ 2:
                    message.captchaType = reader.int32();
                    break;
                case /* mp.base.PassportType passportType */ 3:
                    message.passportType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CaptchaEntity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* mp.user.account.v1.CaptchaType captchaType = 2; */
        if (message.captchaType !== 0)
            writer.tag(2, WireType.Varint).int32(message.captchaType);
        /* mp.base.PassportType passportType = 3; */
        if (message.passportType !== 0)
            writer.tag(3, WireType.Varint).int32(message.passportType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CaptchaEntity
 */
export const CaptchaEntity = new CaptchaEntity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetPasswordReq$Type extends MessageType<SetPasswordReq> {
    constructor() {
        super("mp.user.account.v1.SetPasswordReq", [
            { no: 1, name: "verifyAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetPasswordReq>): SetPasswordReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.verifyAuthToken = "";
        message.password = "";
        if (value !== undefined)
            reflectionMergePartial<SetPasswordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetPasswordReq): SetPasswordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string verifyAuthToken */ 1:
                    message.verifyAuthToken = reader.string();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetPasswordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string verifyAuthToken = 1; */
        if (message.verifyAuthToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.verifyAuthToken);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetPasswordReq
 */
export const SetPasswordReq = new SetPasswordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetPasswordResp$Type extends MessageType<SetPasswordResp> {
    constructor() {
        super("mp.user.account.v1.SetPasswordResp", []);
    }
    create(value?: PartialMessage<SetPasswordResp>): SetPasswordResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetPasswordResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetPasswordResp): SetPasswordResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetPasswordResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetPasswordResp
 */
export const SetPasswordResp = new SetPasswordResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Profile$Type extends MessageType<Profile> {
    constructor() {
        super("mp.user.account.v1.Profile", [
            { no: 1, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "avatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Profile>): Profile {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nickname = "";
        message.avatar = "";
        message.username = "";
        if (value !== undefined)
            reflectionMergePartial<Profile>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Profile): Profile {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string nickname */ 1:
                    message.nickname = reader.string();
                    break;
                case /* string avatar */ 2:
                    message.avatar = reader.string();
                    break;
                case /* string username */ 3:
                    message.username = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Profile, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string nickname = 1; */
        if (message.nickname !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nickname);
        /* string avatar = 2; */
        if (message.avatar !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.avatar);
        /* string username = 3; */
        if (message.username !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.username);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Profile
 */
export const Profile = new Profile$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Passport$Type extends MessageType<Passport> {
    constructor() {
        super("mp.user.account.v1.Passport", [
            { no: 1, name: "type", kind: "enum", T: () => ["mp.base.PassportType", PassportType] },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "relateID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "profile", kind: "message", T: () => Profile }
        ]);
    }
    create(value?: PartialMessage<Passport>): Passport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.id = "";
        message.relateID = "";
        if (value !== undefined)
            reflectionMergePartial<Passport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Passport): Passport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.base.PassportType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                case /* string relateID */ 3:
                    message.relateID = reader.string();
                    break;
                case /* mp.user.account.v1.Profile profile */ 4:
                    message.profile = Profile.internalBinaryRead(reader, reader.uint32(), options, message.profile);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Passport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.base.PassportType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        /* string relateID = 3; */
        if (message.relateID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.relateID);
        /* mp.user.account.v1.Profile profile = 4; */
        if (message.profile)
            Profile.internalBinaryWrite(message.profile, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Passport
 */
export const Passport = new Passport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReq$Type extends MessageType<GetReq> {
    constructor() {
        super("mp.user.account.v1.GetReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetReq>): GetReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReq): GetReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetReq
 */
export const GetReq = new GetReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResp$Type extends MessageType<GetResp> {
    constructor() {
        super("mp.user.account.v1.GetResp", [
            { no: 1, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<GetResp>): GetResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResp): GetResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.Account account */ 1:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.Account account = 1; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetResp
 */
export const GetResp = new GetResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Account$Type extends MessageType<Account> {
    constructor() {
        super("mp.user.account.v1.Account", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "existPassword", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "pubID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "existOTP", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "existPayPassword", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<Account>): Account {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.existPassword = false;
        message.pubID = "";
        message.existOTP = false;
        message.existPayPassword = false;
        if (value !== undefined)
            reflectionMergePartial<Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Account): Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* bool existPassword */ 2:
                    message.existPassword = reader.bool();
                    break;
                case /* string pubID */ 3:
                    message.pubID = reader.string();
                    break;
                case /* bool existOTP */ 4:
                    message.existOTP = reader.bool();
                    break;
                case /* bool existPayPassword */ 5:
                    message.existPayPassword = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* bool existPassword = 2; */
        if (message.existPassword !== false)
            writer.tag(2, WireType.Varint).bool(message.existPassword);
        /* string pubID = 3; */
        if (message.pubID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pubID);
        /* bool existOTP = 4; */
        if (message.existOTP !== false)
            writer.tag(4, WireType.Varint).bool(message.existOTP);
        /* bool existPayPassword = 5; */
        if (message.existPayPassword !== false)
            writer.tag(5, WireType.Varint).bool(message.existPayPassword);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Account
 */
export const Account = new Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassportReq$Type extends MessageType<GetPassportReq> {
    constructor() {
        super("mp.user.account.v1.GetPassportReq", [
            { no: 1, name: "passportID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPassportReq>): GetPassportReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.passportID = "";
        if (value !== undefined)
            reflectionMergePartial<GetPassportReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassportReq): GetPassportReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string passportID */ 1:
                    message.passportID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassportReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string passportID = 1; */
        if (message.passportID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.passportID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassportReq
 */
export const GetPassportReq = new GetPassportReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassportResp$Type extends MessageType<GetPassportResp> {
    constructor() {
        super("mp.user.account.v1.GetPassportResp", [
            { no: 1, name: "Passport", kind: "message", localName: "Passport", jsonName: "Passport", T: () => Passport },
            { no: 2, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<GetPassportResp>): GetPassportResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetPassportResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassportResp): GetPassportResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.Passport Passport = 1 [json_name = "Passport"];*/ 1:
                    message.Passport = Passport.internalBinaryRead(reader, reader.uint32(), options, message.Passport);
                    break;
                case /* mp.user.account.v1.Account account */ 2:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassportResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.Passport Passport = 1 [json_name = "Passport"]; */
        if (message.Passport)
            Passport.internalBinaryWrite(message.Passport, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.Account account = 2; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassportResp
 */
export const GetPassportResp = new GetPassportResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPassportReq$Type extends MessageType<ListPassportReq> {
    constructor() {
        super("mp.user.account.v1.ListPassportReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["mp.base.PassportType", PassportType] }
        ]);
    }
    create(value?: PartialMessage<ListPassportReq>): ListPassportReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<ListPassportReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPassportReq): ListPassportReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* mp.base.PassportType type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPassportReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* mp.base.PassportType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPassportReq
 */
export const ListPassportReq = new ListPassportReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPassportResp$Type extends MessageType<ListPassportResp> {
    constructor() {
        super("mp.user.account.v1.ListPassportResp", [
            { no: 1, name: "passportList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Passport },
            { no: 2, name: "account", kind: "message", T: () => Account }
        ]);
    }
    create(value?: PartialMessage<ListPassportResp>): ListPassportResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.passportList = [];
        if (value !== undefined)
            reflectionMergePartial<ListPassportResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPassportResp): ListPassportResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.Passport passportList */ 1:
                    message.passportList.push(Passport.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* mp.user.account.v1.Account account */ 2:
                    message.account = Account.internalBinaryRead(reader, reader.uint32(), options, message.account);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPassportResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.Passport passportList = 1; */
        for (let i = 0; i < message.passportList.length; i++)
            Passport.internalBinaryWrite(message.passportList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.Account account = 2; */
        if (message.account)
            Account.internalBinaryWrite(message.account, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPassportResp
 */
export const ListPassportResp = new ListPassportResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Platform$Type extends MessageType<Platform> {
    constructor() {
        super("mp.user.account.v1.Platform", [
            { no: 1, name: "type", kind: "enum", T: () => ["mp.user.account.v1.PlatformType", PlatformType] },
            { no: 2, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Platform>): Platform {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<Platform>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Platform): Platform {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.PlatformType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string id */ 2:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Platform, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.PlatformType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string id = 2; */
        if (message.id !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Platform
 */
export const Platform = new Platform$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IDCard$Type extends MessageType<IDCard> {
    constructor() {
        super("mp.user.account.v1.IDCard", [
            { no: 1, name: "cardNo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cardName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["mp.user.account.v1.IDCardStatus", IDCardStatus] },
            { no: 4, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<IDCard>): IDCard {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cardNo = "";
        message.cardName = "";
        message.status = 0;
        message.updateAt = 0;
        if (value !== undefined)
            reflectionMergePartial<IDCard>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IDCard): IDCard {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cardNo */ 1:
                    message.cardNo = reader.string();
                    break;
                case /* string cardName */ 2:
                    message.cardName = reader.string();
                    break;
                case /* mp.user.account.v1.IDCardStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* int64 updateAt */ 4:
                    message.updateAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IDCard, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cardNo = 1; */
        if (message.cardNo !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cardNo);
        /* string cardName = 2; */
        if (message.cardName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cardName);
        /* mp.user.account.v1.IDCardStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* int64 updateAt = 4; */
        if (message.updateAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.updateAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.IDCard
 */
export const IDCard = new IDCard$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyIDCardReq$Type extends MessageType<VerifyIDCardReq> {
    constructor() {
        super("mp.user.account.v1.VerifyIDCardReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "idCardName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "idCardNo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyIDCardReq>): VerifyIDCardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.accountID = 0;
        message.idCardName = "";
        message.idCardNo = "";
        if (value !== undefined)
            reflectionMergePartial<VerifyIDCardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyIDCardReq): VerifyIDCardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string idCardName */ 3:
                    message.idCardName = reader.string();
                    break;
                case /* string idCardNo */ 4:
                    message.idCardNo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyIDCardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        /* string idCardName = 3; */
        if (message.idCardName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.idCardName);
        /* string idCardNo = 4; */
        if (message.idCardNo !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.idCardNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.VerifyIDCardReq
 */
export const VerifyIDCardReq = new VerifyIDCardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyIDCardResp$Type extends MessageType<VerifyIDCardResp> {
    constructor() {
        super("mp.user.account.v1.VerifyIDCardResp", [
            { no: 1, name: "status", kind: "enum", T: () => ["mp.user.account.v1.IDCardStatus", IDCardStatus] },
            { no: 2, name: "retryRemain", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<VerifyIDCardResp>): VerifyIDCardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.retryRemain = 0;
        if (value !== undefined)
            reflectionMergePartial<VerifyIDCardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyIDCardResp): VerifyIDCardResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.IDCardStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* int32 retryRemain */ 2:
                    message.retryRemain = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyIDCardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.IDCardStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* int32 retryRemain = 2; */
        if (message.retryRemain !== 0)
            writer.tag(2, WireType.Varint).int32(message.retryRemain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.VerifyIDCardResp
 */
export const VerifyIDCardResp = new VerifyIDCardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIDCardReq$Type extends MessageType<GetIDCardReq> {
    constructor() {
        super("mp.user.account.v1.GetIDCardReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetIDCardReq>): GetIDCardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetIDCardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIDCardReq): GetIDCardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIDCardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetIDCardReq
 */
export const GetIDCardReq = new GetIDCardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIDCardResp$Type extends MessageType<GetIDCardResp> {
    constructor() {
        super("mp.user.account.v1.GetIDCardResp", [
            { no: 1, name: "info", kind: "message", T: () => IDCard }
        ]);
    }
    create(value?: PartialMessage<GetIDCardResp>): GetIDCardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetIDCardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIDCardResp): GetIDCardResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.IDCard info */ 1:
                    message.info = IDCard.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIDCardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.IDCard info = 1; */
        if (message.info)
            IDCard.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetIDCardResp
 */
export const GetIDCardResp = new GetIDCardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIDCardByNoReq$Type extends MessageType<GetIDCardByNoReq> {
    constructor() {
        super("mp.user.account.v1.GetIDCardByNoReq", [
            { no: 1, name: "idCardNo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetIDCardByNoReq>): GetIDCardByNoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.idCardNo = "";
        if (value !== undefined)
            reflectionMergePartial<GetIDCardByNoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIDCardByNoReq): GetIDCardByNoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string idCardNo */ 1:
                    message.idCardNo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIDCardByNoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string idCardNo = 1; */
        if (message.idCardNo !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.idCardNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetIDCardByNoReq
 */
export const GetIDCardByNoReq = new GetIDCardByNoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIDCardByNoResp$Type extends MessageType<GetIDCardByNoResp> {
    constructor() {
        super("mp.user.account.v1.GetIDCardByNoResp", [
            { no: 1, name: "info", kind: "message", T: () => IDCard }
        ]);
    }
    create(value?: PartialMessage<GetIDCardByNoResp>): GetIDCardByNoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetIDCardByNoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIDCardByNoResp): GetIDCardByNoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.IDCard info */ 1:
                    message.info = IDCard.internalBinaryRead(reader, reader.uint32(), options, message.info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIDCardByNoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.IDCard info = 1; */
        if (message.info)
            IDCard.internalBinaryWrite(message.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetIDCardByNoResp
 */
export const GetIDCardByNoResp = new GetIDCardByNoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveIDCardReq$Type extends MessageType<RemoveIDCardReq> {
    constructor() {
        super("mp.user.account.v1.RemoveIDCardReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveIDCardReq>): RemoveIDCardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<RemoveIDCardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveIDCardReq): RemoveIDCardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveIDCardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RemoveIDCardReq
 */
export const RemoveIDCardReq = new RemoveIDCardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveIDCardResp$Type extends MessageType<RemoveIDCardResp> {
    constructor() {
        super("mp.user.account.v1.RemoveIDCardResp", []);
    }
    create(value?: PartialMessage<RemoveIDCardResp>): RemoveIDCardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RemoveIDCardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveIDCardResp): RemoveIDCardResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveIDCardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RemoveIDCardResp
 */
export const RemoveIDCardResp = new RemoveIDCardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetIDCardRetryLimitReq$Type extends MessageType<ResetIDCardRetryLimitReq> {
    constructor() {
        super("mp.user.account.v1.ResetIDCardRetryLimitReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ResetIDCardRetryLimitReq>): ResetIDCardRetryLimitReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<ResetIDCardRetryLimitReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetIDCardRetryLimitReq): ResetIDCardRetryLimitReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetIDCardRetryLimitReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ResetIDCardRetryLimitReq
 */
export const ResetIDCardRetryLimitReq = new ResetIDCardRetryLimitReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetIDCardRetryLimitResp$Type extends MessageType<ResetIDCardRetryLimitResp> {
    constructor() {
        super("mp.user.account.v1.ResetIDCardRetryLimitResp", []);
    }
    create(value?: PartialMessage<ResetIDCardRetryLimitResp>): ResetIDCardRetryLimitResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ResetIDCardRetryLimitResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetIDCardRetryLimitResp): ResetIDCardRetryLimitResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResetIDCardRetryLimitResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ResetIDCardRetryLimitResp
 */
export const ResetIDCardRetryLimitResp = new ResetIDCardRetryLimitResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhoneAccount$Type extends MessageType<PhoneAccount> {
    constructor() {
        super("mp.user.account.v1.PhoneAccount", [
            { no: 1, name: "number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "zone", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PhoneAccount>): PhoneAccount {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = "";
        message.zone = 0;
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<PhoneAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhoneAccount): PhoneAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string number */ 1:
                    message.number = reader.string();
                    break;
                case /* uint32 zone */ 2:
                    message.zone = reader.uint32();
                    break;
                case /* int64 accountID */ 3:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhoneAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string number = 1; */
        if (message.number !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.number);
        /* uint32 zone = 2; */
        if (message.zone !== 0)
            writer.tag(2, WireType.Varint).uint32(message.zone);
        /* int64 accountID = 3; */
        if (message.accountID !== 0)
            writer.tag(3, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PhoneAccount
 */
export const PhoneAccount = new PhoneAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListReq$Type extends MessageType<ListReq> {
    constructor() {
        super("mp.user.account.v1.ListReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "phoneList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Phone }
        ]);
    }
    create(value?: PartialMessage<ListReq>): ListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.phoneList = [];
        if (value !== undefined)
            reflectionMergePartial<ListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListReq): ListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* repeated mp.user.account.v1.Phone phoneList */ 2:
                    message.phoneList.push(Phone.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* repeated mp.user.account.v1.Phone phoneList = 2; */
        for (let i = 0; i < message.phoneList.length; i++)
            Phone.internalBinaryWrite(message.phoneList[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListReq
 */
export const ListReq = new ListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListResp$Type extends MessageType<ListResp> {
    constructor() {
        super("mp.user.account.v1.ListResp", [
            { no: 2, name: "phoneAccountList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PhoneAccount }
        ]);
    }
    create(value?: PartialMessage<ListResp>): ListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.phoneAccountList = [];
        if (value !== undefined)
            reflectionMergePartial<ListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListResp): ListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.PhoneAccount phoneAccountList */ 2:
                    message.phoneAccountList.push(PhoneAccount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.PhoneAccount phoneAccountList = 2; */
        for (let i = 0; i < message.phoneAccountList.length; i++)
            PhoneAccount.internalBinaryWrite(message.phoneAccountList[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListResp
 */
export const ListResp = new ListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccessTokenClaims$Type extends MessageType<AccessTokenClaims> {
    constructor() {
        super("mp.user.account.v1.AccessTokenClaims", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "playerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "random", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AccessTokenClaims>): AccessTokenClaims {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.playerID = 0;
        message.accountID = 0;
        message.random = 0;
        message.tag = "";
        if (value !== undefined)
            reflectionMergePartial<AccessTokenClaims>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccessTokenClaims): AccessTokenClaims {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 playerID */ 2:
                    message.playerID = reader.int64().toNumber();
                    break;
                case /* int64 accountID */ 3:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* int32 random */ 4:
                    message.random = reader.int32();
                    break;
                case /* string tag */ 5:
                    message.tag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccessTokenClaims, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 playerID = 2; */
        if (message.playerID !== 0)
            writer.tag(2, WireType.Varint).int64(message.playerID);
        /* int64 accountID = 3; */
        if (message.accountID !== 0)
            writer.tag(3, WireType.Varint).int64(message.accountID);
        /* int32 random = 4; */
        if (message.random !== 0)
            writer.tag(4, WireType.Varint).int32(message.random);
        /* string tag = 5; */
        if (message.tag !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AccessTokenClaims
 */
export const AccessTokenClaims = new AccessTokenClaims$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassportVerifiedTokenClaims$Type extends MessageType<PassportVerifiedTokenClaims> {
    constructor() {
        super("mp.user.account.v1.PassportVerifiedTokenClaims", [
            { no: 1, name: "passportID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "passportType", kind: "enum", T: () => ["mp.base.PassportType", PassportType] },
            { no: 3, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "random", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "authType", kind: "enum", T: () => ["mp.base.PassportAuthType", PassportAuthType] }
        ]);
    }
    create(value?: PartialMessage<PassportVerifiedTokenClaims>): PassportVerifiedTokenClaims {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.passportID = "";
        message.passportType = 0;
        message.accountID = 0;
        message.random = 0;
        message.authType = 0;
        if (value !== undefined)
            reflectionMergePartial<PassportVerifiedTokenClaims>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassportVerifiedTokenClaims): PassportVerifiedTokenClaims {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string passportID */ 1:
                    message.passportID = reader.string();
                    break;
                case /* mp.base.PassportType passportType */ 2:
                    message.passportType = reader.int32();
                    break;
                case /* int64 accountID */ 3:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* int32 random */ 4:
                    message.random = reader.int32();
                    break;
                case /* mp.base.PassportAuthType authType */ 5:
                    message.authType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassportVerifiedTokenClaims, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string passportID = 1; */
        if (message.passportID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.passportID);
        /* mp.base.PassportType passportType = 2; */
        if (message.passportType !== 0)
            writer.tag(2, WireType.Varint).int32(message.passportType);
        /* int64 accountID = 3; */
        if (message.accountID !== 0)
            writer.tag(3, WireType.Varint).int64(message.accountID);
        /* int32 random = 4; */
        if (message.random !== 0)
            writer.tag(4, WireType.Varint).int32(message.random);
        /* mp.base.PassportAuthType authType = 5; */
        if (message.authType !== 0)
            writer.tag(5, WireType.Varint).int32(message.authType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassportVerifiedTokenClaims
 */
export const PassportVerifiedTokenClaims = new PassportVerifiedTokenClaims$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCertificationReq$Type extends MessageType<GetCertificationReq> {
    constructor() {
        super("mp.user.account.v1.GetCertificationReq", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCertificationReq>): GetCertificationReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<GetCertificationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCertificationReq): GetCertificationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCertificationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetCertificationReq
 */
export const GetCertificationReq = new GetCertificationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCertificationResp$Type extends MessageType<GetCertificationResp> {
    constructor() {
        super("mp.user.account.v1.GetCertificationResp", [
            { no: 3, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "claims", kind: "message", T: () => AccessTokenClaims }
        ]);
    }
    create(value?: PartialMessage<GetCertificationResp>): GetCertificationResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.expiredAt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetCertificationResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCertificationResp): GetCertificationResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 expiredAt */ 3:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.AccessTokenClaims claims */ 4:
                    message.claims = AccessTokenClaims.internalBinaryRead(reader, reader.uint32(), options, message.claims);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCertificationResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 expiredAt = 3; */
        if (message.expiredAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.expiredAt);
        /* mp.user.account.v1.AccessTokenClaims claims = 4; */
        if (message.claims)
            AccessTokenClaims.internalBinaryWrite(message.claims, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetCertificationResp
 */
export const GetCertificationResp = new GetCertificationResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassportTokenReq$Type extends MessageType<GetPassportTokenReq> {
    constructor() {
        super("mp.user.account.v1.GetPassportTokenReq", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "checkTTL", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "checkAuthChange", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetPassportTokenReq>): GetPassportTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        message.checkTTL = false;
        message.checkAuthChange = false;
        if (value !== undefined)
            reflectionMergePartial<GetPassportTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassportTokenReq): GetPassportTokenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* bool checkTTL */ 2:
                    message.checkTTL = reader.bool();
                    break;
                case /* bool checkAuthChange */ 3:
                    message.checkAuthChange = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassportTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* bool checkTTL = 2; */
        if (message.checkTTL !== false)
            writer.tag(2, WireType.Varint).bool(message.checkTTL);
        /* bool checkAuthChange = 3; */
        if (message.checkAuthChange !== false)
            writer.tag(3, WireType.Varint).bool(message.checkAuthChange);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassportTokenReq
 */
export const GetPassportTokenReq = new GetPassportTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassportTokenResp$Type extends MessageType<GetPassportTokenResp> {
    constructor() {
        super("mp.user.account.v1.GetPassportTokenResp", [
            { no: 1, name: "claims", kind: "message", T: () => PassportVerifiedTokenClaims },
            { no: 2, name: "authChangeAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetPassportTokenResp>): GetPassportTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.authChangeAt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPassportTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassportTokenResp): GetPassportTokenResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.PassportVerifiedTokenClaims claims */ 1:
                    message.claims = PassportVerifiedTokenClaims.internalBinaryRead(reader, reader.uint32(), options, message.claims);
                    break;
                case /* int64 authChangeAt */ 2:
                    message.authChangeAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassportTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.PassportVerifiedTokenClaims claims = 1; */
        if (message.claims)
            PassportVerifiedTokenClaims.internalBinaryWrite(message.claims, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 authChangeAt = 2; */
        if (message.authChangeAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.authChangeAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassportTokenResp
 */
export const GetPassportTokenResp = new GetPassportTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshCertificationReq$Type extends MessageType<RefreshCertificationReq> {
    constructor() {
        super("mp.user.account.v1.RefreshCertificationReq", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshCertificationReq>): RefreshCertificationReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<RefreshCertificationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshCertificationReq): RefreshCertificationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshCertificationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RefreshCertificationReq
 */
export const RefreshCertificationReq = new RefreshCertificationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshCertificationResp$Type extends MessageType<RefreshCertificationResp> {
    constructor() {
        super("mp.user.account.v1.RefreshCertificationResp", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "claims", kind: "message", T: () => AccessTokenClaims }
        ]);
    }
    create(value?: PartialMessage<RefreshCertificationResp>): RefreshCertificationResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        message.expiredAt = 0;
        if (value !== undefined)
            reflectionMergePartial<RefreshCertificationResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshCertificationResp): RefreshCertificationResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* int64 expiredAt */ 2:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.AccessTokenClaims claims */ 3:
                    message.claims = AccessTokenClaims.internalBinaryRead(reader, reader.uint32(), options, message.claims);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshCertificationResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* int64 expiredAt = 2; */
        if (message.expiredAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredAt);
        /* mp.user.account.v1.AccessTokenClaims claims = 3; */
        if (message.claims)
            AccessTokenClaims.internalBinaryWrite(message.claims, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RefreshCertificationResp
 */
export const RefreshCertificationResp = new RefreshCertificationResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CertificateLogin$Type extends MessageType<CertificateLogin> {
    constructor() {
        super("mp.user.account.v1.CertificateLogin", [
            { no: 1, name: "accessToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CertificateLogin>): CertificateLogin {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accessToken = "";
        if (value !== undefined)
            reflectionMergePartial<CertificateLogin>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CertificateLogin): CertificateLogin {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string accessToken */ 1:
                    message.accessToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CertificateLogin, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string accessToken = 1; */
        if (message.accessToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.accessToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CertificateLogin
 */
export const CertificateLogin = new CertificateLogin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWalletSignatureMsgReq$Type extends MessageType<CreateWalletSignatureMsgReq> {
    constructor() {
        super("mp.user.account.v1.CreateWalletSignatureMsgReq", [
            { no: 2, name: "publicAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateWalletSignatureMsgReq>): CreateWalletSignatureMsgReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.publicAddress = "";
        if (value !== undefined)
            reflectionMergePartial<CreateWalletSignatureMsgReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWalletSignatureMsgReq): CreateWalletSignatureMsgReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string publicAddress */ 2:
                    message.publicAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateWalletSignatureMsgReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string publicAddress = 2; */
        if (message.publicAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.publicAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CreateWalletSignatureMsgReq
 */
export const CreateWalletSignatureMsgReq = new CreateWalletSignatureMsgReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWalletSignatureMsgResp$Type extends MessageType<CreateWalletSignatureMsgResp> {
    constructor() {
        super("mp.user.account.v1.CreateWalletSignatureMsgResp", [
            { no: 1, name: "msg", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateWalletSignatureMsgResp>): CreateWalletSignatureMsgResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.msg = "";
        if (value !== undefined)
            reflectionMergePartial<CreateWalletSignatureMsgResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWalletSignatureMsgResp): CreateWalletSignatureMsgResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string msg */ 1:
                    message.msg = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateWalletSignatureMsgResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string msg = 1; */
        if (message.msg !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.msg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CreateWalletSignatureMsgResp
 */
export const CreateWalletSignatureMsgResp = new CreateWalletSignatureMsgResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateQRCodeReq$Type extends MessageType<GenerateQRCodeReq> {
    constructor() {
        super("mp.user.account.v1.GenerateQRCodeReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "playerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateQRCodeReq>): GenerateQRCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.playerID = 0;
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<GenerateQRCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateQRCodeReq): GenerateQRCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 playerID */ 2:
                    message.playerID = reader.int64().toNumber();
                    break;
                case /* string data */ 3:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateQRCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 playerID = 2; */
        if (message.playerID !== 0)
            writer.tag(2, WireType.Varint).int64(message.playerID);
        /* string data = 3; */
        if (message.data !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GenerateQRCodeReq
 */
export const GenerateQRCodeReq = new GenerateQRCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateQRCodeResp$Type extends MessageType<GenerateQRCodeResp> {
    constructor() {
        super("mp.user.account.v1.GenerateQRCodeResp", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateQRCodeResp>): GenerateQRCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.expiredAt = 0;
        if (value !== undefined)
            reflectionMergePartial<GenerateQRCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateQRCodeResp): GenerateQRCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int64 expiredAt */ 2:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateQRCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int64 expiredAt = 2; */
        if (message.expiredAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GenerateQRCodeResp
 */
export const GenerateQRCodeResp = new GenerateQRCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckQRCodeReq$Type extends MessageType<CheckQRCodeReq> {
    constructor() {
        super("mp.user.account.v1.CheckQRCodeReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckQRCodeReq>): CheckQRCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<CheckQRCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckQRCodeReq): CheckQRCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckQRCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CheckQRCodeReq
 */
export const CheckQRCodeReq = new CheckQRCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckQRCodeResp$Type extends MessageType<CheckQRCodeResp> {
    constructor() {
        super("mp.user.account.v1.CheckQRCodeResp", [
            { no: 1, name: "status", kind: "enum", T: () => ["mp.user.account.v1.QRCodeStatus", QRCodeStatus] },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "authAppID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "authPlayerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "srcAppID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "srcPlayerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CheckQRCodeResp>): CheckQRCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.code = "";
        message.authAppID = 0;
        message.authPlayerID = 0;
        message.srcAppID = 0;
        message.srcPlayerID = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckQRCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckQRCodeResp): CheckQRCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.QRCodeStatus status */ 1:
                    message.status = reader.int32();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* int32 authAppID */ 3:
                    message.authAppID = reader.int32();
                    break;
                case /* int64 authPlayerID */ 4:
                    message.authPlayerID = reader.int64().toNumber();
                    break;
                case /* int32 srcAppID */ 5:
                    message.srcAppID = reader.int32();
                    break;
                case /* int64 srcPlayerID */ 6:
                    message.srcPlayerID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckQRCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.QRCodeStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* int32 authAppID = 3; */
        if (message.authAppID !== 0)
            writer.tag(3, WireType.Varint).int32(message.authAppID);
        /* int64 authPlayerID = 4; */
        if (message.authPlayerID !== 0)
            writer.tag(4, WireType.Varint).int64(message.authPlayerID);
        /* int32 srcAppID = 5; */
        if (message.srcAppID !== 0)
            writer.tag(5, WireType.Varint).int32(message.srcAppID);
        /* int64 srcPlayerID = 6; */
        if (message.srcPlayerID !== 0)
            writer.tag(6, WireType.Varint).int64(message.srcPlayerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CheckQRCodeResp
 */
export const CheckQRCodeResp = new CheckQRCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScanQRCodeReq$Type extends MessageType<ScanQRCodeReq> {
    constructor() {
        super("mp.user.account.v1.ScanQRCodeReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScanQRCodeReq>): ScanQRCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<ScanQRCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScanQRCodeReq): ScanQRCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScanQRCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ScanQRCodeReq
 */
export const ScanQRCodeReq = new ScanQRCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScanQRCodeResp$Type extends MessageType<ScanQRCodeResp> {
    constructor() {
        super("mp.user.account.v1.ScanQRCodeResp", [
            { no: 1, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ScanQRCodeResp>): ScanQRCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<ScanQRCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScanQRCodeResp): ScanQRCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string data */ 1:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScanQRCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string data = 1; */
        if (message.data !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ScanQRCodeResp
 */
export const ScanQRCodeResp = new ScanQRCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmQRCodeReq$Type extends MessageType<ConfirmQRCodeReq> {
    constructor() {
        super("mp.user.account.v1.ConfirmQRCodeReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "playerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ConfirmQRCodeReq>): ConfirmQRCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.appID = 0;
        message.playerID = 0;
        if (value !== undefined)
            reflectionMergePartial<ConfirmQRCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfirmQRCodeReq): ConfirmQRCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int32 appID */ 2:
                    message.appID = reader.int32();
                    break;
                case /* int64 playerID */ 3:
                    message.playerID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfirmQRCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 appID = 2; */
        if (message.appID !== 0)
            writer.tag(2, WireType.Varint).int32(message.appID);
        /* int64 playerID = 3; */
        if (message.playerID !== 0)
            writer.tag(3, WireType.Varint).int64(message.playerID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ConfirmQRCodeReq
 */
export const ConfirmQRCodeReq = new ConfirmQRCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmQRCodeResp$Type extends MessageType<ConfirmQRCodeResp> {
    constructor() {
        super("mp.user.account.v1.ConfirmQRCodeResp", []);
    }
    create(value?: PartialMessage<ConfirmQRCodeResp>): ConfirmQRCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ConfirmQRCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfirmQRCodeResp): ConfirmQRCodeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ConfirmQRCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ConfirmQRCodeResp
 */
export const ConfirmQRCodeResp = new ConfirmQRCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelQRCodeReq$Type extends MessageType<CancelQRCodeReq> {
    constructor() {
        super("mp.user.account.v1.CancelQRCodeReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelQRCodeReq>): CancelQRCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<CancelQRCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelQRCodeReq): CancelQRCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelQRCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CancelQRCodeReq
 */
export const CancelQRCodeReq = new CancelQRCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelQRCodeResp$Type extends MessageType<CancelQRCodeResp> {
    constructor() {
        super("mp.user.account.v1.CancelQRCodeResp", []);
    }
    create(value?: PartialMessage<CancelQRCodeResp>): CancelQRCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CancelQRCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelQRCodeResp): CancelQRCodeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CancelQRCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CancelQRCodeResp
 */
export const CancelQRCodeResp = new CancelQRCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelReq$Type extends MessageType<CancelReq> {
    constructor() {
        super("mp.user.account.v1.CancelReq", [
            { no: 1, name: "pubID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CancelReq>): CancelReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pubID = "";
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<CancelReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelReq): CancelReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string pubID */ 1:
                    message.pubID = reader.string();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string pubID = 1; */
        if (message.pubID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pubID);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CancelReq
 */
export const CancelReq = new CancelReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelResp$Type extends MessageType<CancelResp> {
    constructor() {
        super("mp.user.account.v1.CancelResp", []);
    }
    create(value?: PartialMessage<CancelResp>): CancelResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CancelResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelResp): CancelResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CancelResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CancelResp
 */
export const CancelResp = new CancelResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvitationCode$Type extends MessageType<InvitationCode> {
    constructor() {
        super("mp.user.account.v1.InvitationCode", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "ownerAccountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "invitationCount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InvitationCode>): InvitationCode {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        message.createdAt = 0;
        message.ownerAccountID = 0;
        message.invitationCount = 0;
        if (value !== undefined)
            reflectionMergePartial<InvitationCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvitationCode): InvitationCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int64 createdAt */ 2:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 ownerAccountID */ 3:
                    message.ownerAccountID = reader.int64().toNumber();
                    break;
                case /* int64 invitationCount */ 4:
                    message.invitationCount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvitationCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int64 createdAt = 2; */
        if (message.createdAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.createdAt);
        /* int64 ownerAccountID = 3; */
        if (message.ownerAccountID !== 0)
            writer.tag(3, WireType.Varint).int64(message.ownerAccountID);
        /* int64 invitationCount = 4; */
        if (message.invitationCount !== 0)
            writer.tag(4, WireType.Varint).int64(message.invitationCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.InvitationCode
 */
export const InvitationCode = new InvitationCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvitationRecord$Type extends MessageType<InvitationRecord> {
    constructor() {
        super("mp.user.account.v1.InvitationRecord", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "ownerAccountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "usedAccountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InvitationRecord>): InvitationRecord {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        message.createdAt = 0;
        message.ownerAccountID = 0;
        message.usedAccountID = 0;
        if (value !== undefined)
            reflectionMergePartial<InvitationRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvitationRecord): InvitationRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int64 createdAt */ 2:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 ownerAccountID */ 3:
                    message.ownerAccountID = reader.int64().toNumber();
                    break;
                case /* int64 usedAccountID */ 4:
                    message.usedAccountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvitationRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int64 createdAt = 2; */
        if (message.createdAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.createdAt);
        /* int64 ownerAccountID = 3; */
        if (message.ownerAccountID !== 0)
            writer.tag(3, WireType.Varint).int64(message.ownerAccountID);
        /* int64 usedAccountID = 4; */
        if (message.usedAccountID !== 0)
            writer.tag(4, WireType.Varint).int64(message.usedAccountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.InvitationRecord
 */
export const InvitationRecord = new InvitationRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateInvitationCodeReq$Type extends MessageType<CreateInvitationCodeReq> {
    constructor() {
        super("mp.user.account.v1.CreateInvitationCodeReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateInvitationCodeReq>): CreateInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.code = "";
        if (value !== undefined)
            reflectionMergePartial<CreateInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateInvitationCodeReq): CreateInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CreateInvitationCodeReq
 */
export const CreateInvitationCodeReq = new CreateInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateInvitationCodeResp$Type extends MessageType<CreateInvitationCodeResp> {
    constructor() {
        super("mp.user.account.v1.CreateInvitationCodeResp", []);
    }
    create(value?: PartialMessage<CreateInvitationCodeResp>): CreateInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateInvitationCodeResp): CreateInvitationCodeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CreateInvitationCodeResp
 */
export const CreateInvitationCodeResp = new CreateInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvitationCodeReq$Type extends MessageType<GetInvitationCodeReq> {
    constructor() {
        super("mp.user.account.v1.GetInvitationCodeReq", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetInvitationCodeReq>): GetInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        if (value !== undefined)
            reflectionMergePartial<GetInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvitationCodeReq): GetInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetInvitationCodeReq
 */
export const GetInvitationCodeReq = new GetInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvitationCodeResp$Type extends MessageType<GetInvitationCodeResp> {
    constructor() {
        super("mp.user.account.v1.GetInvitationCodeResp", [
            { no: 1, name: "invitationCode", kind: "message", T: () => InvitationCode }
        ]);
    }
    create(value?: PartialMessage<GetInvitationCodeResp>): GetInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvitationCodeResp): GetInvitationCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.InvitationCode invitationCode */ 1:
                    message.invitationCode = InvitationCode.internalBinaryRead(reader, reader.uint32(), options, message.invitationCode);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.InvitationCode invitationCode = 1; */
        if (message.invitationCode)
            InvitationCode.internalBinaryWrite(message.invitationCode, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetInvitationCodeResp
 */
export const GetInvitationCodeResp = new GetInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountInvitationCodeReq$Type extends MessageType<GetAccountInvitationCodeReq> {
    constructor() {
        super("mp.user.account.v1.GetAccountInvitationCodeReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountInvitationCodeReq>): GetAccountInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAccountInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountInvitationCodeReq): GetAccountInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetAccountInvitationCodeReq
 */
export const GetAccountInvitationCodeReq = new GetAccountInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountInvitationCodeResp$Type extends MessageType<GetAccountInvitationCodeResp> {
    constructor() {
        super("mp.user.account.v1.GetAccountInvitationCodeResp", [
            { no: 1, name: "invitationCode", kind: "message", T: () => InvitationCode },
            { no: 2, name: "invitationCount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountInvitationCodeResp>): GetAccountInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.invitationCount = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAccountInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountInvitationCodeResp): GetAccountInvitationCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.InvitationCode invitationCode */ 1:
                    message.invitationCode = InvitationCode.internalBinaryRead(reader, reader.uint32(), options, message.invitationCode);
                    break;
                case /* int64 invitationCount */ 2:
                    message.invitationCount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.InvitationCode invitationCode = 1; */
        if (message.invitationCode)
            InvitationCode.internalBinaryWrite(message.invitationCode, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 invitationCount = 2; */
        if (message.invitationCount !== 0)
            writer.tag(2, WireType.Varint).int64(message.invitationCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetAccountInvitationCodeResp
 */
export const GetAccountInvitationCodeResp = new GetAccountInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TwitterUser$Type extends MessageType<TwitterUser> {
    constructor() {
        super("mp.user.account.v1.TwitterUser", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "screenName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "location", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "followersCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "friendsCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "listedCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "favouritesCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "statusesCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "userCreatedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TwitterUser>): TwitterUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.screenName = "";
        message.location = "";
        message.verified = false;
        message.followersCount = 0;
        message.friendsCount = 0;
        message.listedCount = 0;
        message.favouritesCount = 0;
        message.statusesCount = 0;
        message.userCreatedAt = 0;
        if (value !== undefined)
            reflectionMergePartial<TwitterUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TwitterUser): TwitterUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string screenName */ 2:
                    message.screenName = reader.string();
                    break;
                case /* string location */ 3:
                    message.location = reader.string();
                    break;
                case /* bool verified */ 4:
                    message.verified = reader.bool();
                    break;
                case /* int32 followersCount */ 5:
                    message.followersCount = reader.int32();
                    break;
                case /* int32 friendsCount */ 6:
                    message.friendsCount = reader.int32();
                    break;
                case /* int32 listedCount */ 7:
                    message.listedCount = reader.int32();
                    break;
                case /* int32 favouritesCount */ 8:
                    message.favouritesCount = reader.int32();
                    break;
                case /* int32 statusesCount */ 9:
                    message.statusesCount = reader.int32();
                    break;
                case /* int64 userCreatedAt */ 10:
                    message.userCreatedAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TwitterUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string screenName = 2; */
        if (message.screenName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.screenName);
        /* string location = 3; */
        if (message.location !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.location);
        /* bool verified = 4; */
        if (message.verified !== false)
            writer.tag(4, WireType.Varint).bool(message.verified);
        /* int32 followersCount = 5; */
        if (message.followersCount !== 0)
            writer.tag(5, WireType.Varint).int32(message.followersCount);
        /* int32 friendsCount = 6; */
        if (message.friendsCount !== 0)
            writer.tag(6, WireType.Varint).int32(message.friendsCount);
        /* int32 listedCount = 7; */
        if (message.listedCount !== 0)
            writer.tag(7, WireType.Varint).int32(message.listedCount);
        /* int32 favouritesCount = 8; */
        if (message.favouritesCount !== 0)
            writer.tag(8, WireType.Varint).int32(message.favouritesCount);
        /* int32 statusesCount = 9; */
        if (message.statusesCount !== 0)
            writer.tag(9, WireType.Varint).int32(message.statusesCount);
        /* int64 userCreatedAt = 10; */
        if (message.userCreatedAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.userCreatedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.TwitterUser
 */
export const TwitterUser = new TwitterUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Location$Type extends MessageType<Location> {
    constructor() {
        super("mp.user.account.v1.Location", [
            { no: 1, name: "countryCode", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Location>): Location {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.countryCode = "";
        if (value !== undefined)
            reflectionMergePartial<Location>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Location): Location {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string countryCode */ 1:
                    message.countryCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Location, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string countryCode = 1; */
        if (message.countryCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.countryCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Location
 */
export const Location = new Location$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeviceLoginLog$Type extends MessageType<DeviceLoginLog> {
    constructor() {
        super("mp.user.account.v1.DeviceLoginLog", [
            { no: 1, name: "device", kind: "message", T: () => Device },
            { no: 2, name: "loginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "ip", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "location", kind: "message", T: () => Location },
            { no: 5, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeviceLoginLog>): DeviceLoginLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.loginAt = 0;
        message.ip = "";
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<DeviceLoginLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeviceLoginLog): DeviceLoginLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.base.Device device */ 1:
                    message.device = Device.internalBinaryRead(reader, reader.uint32(), options, message.device);
                    break;
                case /* int64 loginAt */ 2:
                    message.loginAt = reader.int64().toNumber();
                    break;
                case /* string ip */ 3:
                    message.ip = reader.string();
                    break;
                case /* mp.user.account.v1.Location location */ 4:
                    message.location = Location.internalBinaryRead(reader, reader.uint32(), options, message.location);
                    break;
                case /* int64 count */ 5:
                    message.count = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeviceLoginLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.base.Device device = 1; */
        if (message.device)
            Device.internalBinaryWrite(message.device, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 loginAt = 2; */
        if (message.loginAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.loginAt);
        /* string ip = 3; */
        if (message.ip !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.ip);
        /* mp.user.account.v1.Location location = 4; */
        if (message.location)
            Location.internalBinaryWrite(message.location, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int64 count = 5; */
        if (message.count !== 0)
            writer.tag(5, WireType.Varint).int64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.DeviceLoginLog
 */
export const DeviceLoginLog = new DeviceLoginLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDeviceLoginLogReq$Type extends MessageType<ListDeviceLoginLogReq> {
    constructor() {
        super("mp.user.account.v1.ListDeviceLoginLogReq", [
            { no: 1, name: "device", kind: "message", T: () => Device },
            { no: 2, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListDeviceLoginLogReq>): ListDeviceLoginLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.accountID = 0;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListDeviceLoginLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDeviceLoginLogReq): ListDeviceLoginLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.base.Device device */ 1:
                    message.device = Device.internalBinaryRead(reader, reader.uint32(), options, message.device);
                    break;
                case /* int32 appID */ 2:
                    message.appID = reader.int32();
                    break;
                case /* int64 accountID */ 3:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* int64 page */ 4:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 5:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDeviceLoginLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.base.Device device = 1; */
        if (message.device)
            Device.internalBinaryWrite(message.device, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 appID = 2; */
        if (message.appID !== 0)
            writer.tag(2, WireType.Varint).int32(message.appID);
        /* int64 accountID = 3; */
        if (message.accountID !== 0)
            writer.tag(3, WireType.Varint).int64(message.accountID);
        /* int64 page = 4; */
        if (message.page !== 0)
            writer.tag(4, WireType.Varint).int64(message.page);
        /* int64 pageSize = 5; */
        if (message.pageSize !== 0)
            writer.tag(5, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListDeviceLoginLogReq
 */
export const ListDeviceLoginLogReq = new ListDeviceLoginLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDeviceLoginLogResp$Type extends MessageType<ListDeviceLoginLogResp> {
    constructor() {
        super("mp.user.account.v1.ListDeviceLoginLogResp", [
            { no: 1, name: "deviceLoginLogList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeviceLoginLog }
        ]);
    }
    create(value?: PartialMessage<ListDeviceLoginLogResp>): ListDeviceLoginLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deviceLoginLogList = [];
        if (value !== undefined)
            reflectionMergePartial<ListDeviceLoginLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDeviceLoginLogResp): ListDeviceLoginLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.DeviceLoginLog deviceLoginLogList */ 1:
                    message.deviceLoginLogList.push(DeviceLoginLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDeviceLoginLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.DeviceLoginLog deviceLoginLogList = 1; */
        for (let i = 0; i < message.deviceLoginLogList.length; i++)
            DeviceLoginLog.internalBinaryWrite(message.deviceLoginLogList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListDeviceLoginLogResp
 */
export const ListDeviceLoginLogResp = new ListDeviceLoginLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDeviceLastLoginLogReq$Type extends MessageType<ListDeviceLastLoginLogReq> {
    constructor() {
        super("mp.user.account.v1.ListDeviceLastLoginLogReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListDeviceLastLoginLogReq>): ListDeviceLastLoginLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListDeviceLastLoginLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDeviceLastLoginLogReq): ListDeviceLastLoginLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDeviceLastLoginLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListDeviceLastLoginLogReq
 */
export const ListDeviceLastLoginLogReq = new ListDeviceLastLoginLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDeviceLastLoginLogResp$Type extends MessageType<ListDeviceLastLoginLogResp> {
    constructor() {
        super("mp.user.account.v1.ListDeviceLastLoginLogResp", [
            { no: 1, name: "deviceLoginLogList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeviceLoginLog }
        ]);
    }
    create(value?: PartialMessage<ListDeviceLastLoginLogResp>): ListDeviceLastLoginLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deviceLoginLogList = [];
        if (value !== undefined)
            reflectionMergePartial<ListDeviceLastLoginLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDeviceLastLoginLogResp): ListDeviceLastLoginLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.DeviceLoginLog deviceLoginLogList */ 1:
                    message.deviceLoginLogList.push(DeviceLoginLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDeviceLastLoginLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.DeviceLoginLog deviceLoginLogList = 1; */
        for (let i = 0; i < message.deviceLoginLogList.length; i++)
            DeviceLoginLog.internalBinaryWrite(message.deviceLoginLogList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListDeviceLastLoginLogResp
 */
export const ListDeviceLastLoginLogResp = new ListDeviceLastLoginLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvitationCodeApply$Type extends MessageType<InvitationCodeApply> {
    constructor() {
        super("mp.user.account.v1.InvitationCodeApply", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["mp.user.account.v1.InvitationCodeApplyStatus", InvitationCodeApplyStatus] },
            { no: 4, name: "num", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "operatedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "reason", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<InvitationCodeApply>): InvitationCodeApply {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.accountID = 0;
        message.status = 0;
        message.num = 0;
        message.createdAt = 0;
        message.operatedAt = 0;
        message.operator = "";
        message.reason = 0;
        if (value !== undefined)
            reflectionMergePartial<InvitationCodeApply>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvitationCodeApply): InvitationCodeApply {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.InvitationCodeApplyStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* int32 num */ 4:
                    message.num = reader.int32();
                    break;
                case /* int64 createdAt */ 5:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 operatedAt */ 6:
                    message.operatedAt = reader.int64().toNumber();
                    break;
                case /* string operator */ 7:
                    message.operator = reader.string();
                    break;
                case /* int32 reason */ 8:
                    message.reason = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvitationCodeApply, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        /* mp.user.account.v1.InvitationCodeApplyStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* int32 num = 4; */
        if (message.num !== 0)
            writer.tag(4, WireType.Varint).int32(message.num);
        /* int64 createdAt = 5; */
        if (message.createdAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.createdAt);
        /* int64 operatedAt = 6; */
        if (message.operatedAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.operatedAt);
        /* string operator = 7; */
        if (message.operator !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.operator);
        /* int32 reason = 8; */
        if (message.reason !== 0)
            writer.tag(8, WireType.Varint).int32(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.InvitationCodeApply
 */
export const InvitationCodeApply = new InvitationCodeApply$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyInvitationCodeReq$Type extends MessageType<ApplyInvitationCodeReq> {
    constructor() {
        super("mp.user.account.v1.ApplyInvitationCodeReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "check", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ApplyInvitationCodeReq>): ApplyInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.check = false;
        if (value !== undefined)
            reflectionMergePartial<ApplyInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyInvitationCodeReq): ApplyInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* bool check */ 2:
                    message.check = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* bool check = 2; */
        if (message.check !== false)
            writer.tag(2, WireType.Varint).bool(message.check);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ApplyInvitationCodeReq
 */
export const ApplyInvitationCodeReq = new ApplyInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyInvitationCodeResp$Type extends MessageType<ApplyInvitationCodeResp> {
    constructor() {
        super("mp.user.account.v1.ApplyInvitationCodeResp", []);
    }
    create(value?: PartialMessage<ApplyInvitationCodeResp>): ApplyInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ApplyInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyInvitationCodeResp): ApplyInvitationCodeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ApplyInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ApplyInvitationCodeResp
 */
export const ApplyInvitationCodeResp = new ApplyInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassInvitationCodeApplyReq$Type extends MessageType<PassInvitationCodeApplyReq> {
    constructor() {
        super("mp.user.account.v1.PassInvitationCodeApplyReq", [
            { no: 1, name: "applyID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PassInvitationCodeApplyReq>): PassInvitationCodeApplyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.applyID = "";
        message.num = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<PassInvitationCodeApplyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassInvitationCodeApplyReq): PassInvitationCodeApplyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string applyID */ 1:
                    message.applyID = reader.string();
                    break;
                case /* int64 num */ 2:
                    message.num = reader.int64().toNumber();
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassInvitationCodeApplyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string applyID = 1; */
        if (message.applyID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.applyID);
        /* int64 num = 2; */
        if (message.num !== 0)
            writer.tag(2, WireType.Varint).int64(message.num);
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassInvitationCodeApplyReq
 */
export const PassInvitationCodeApplyReq = new PassInvitationCodeApplyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassInvitationCodeApplyResp$Type extends MessageType<PassInvitationCodeApplyResp> {
    constructor() {
        super("mp.user.account.v1.PassInvitationCodeApplyResp", []);
    }
    create(value?: PartialMessage<PassInvitationCodeApplyResp>): PassInvitationCodeApplyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<PassInvitationCodeApplyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassInvitationCodeApplyResp): PassInvitationCodeApplyResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PassInvitationCodeApplyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassInvitationCodeApplyResp
 */
export const PassInvitationCodeApplyResp = new PassInvitationCodeApplyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RejectInvitationCodeApplyReq$Type extends MessageType<RejectInvitationCodeApplyReq> {
    constructor() {
        super("mp.user.account.v1.RejectInvitationCodeApplyReq", [
            { no: 1, name: "applyID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reason", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RejectInvitationCodeApplyReq>): RejectInvitationCodeApplyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.applyID = "";
        message.reason = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<RejectInvitationCodeApplyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RejectInvitationCodeApplyReq): RejectInvitationCodeApplyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string applyID */ 1:
                    message.applyID = reader.string();
                    break;
                case /* int32 reason */ 2:
                    message.reason = reader.int32();
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RejectInvitationCodeApplyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string applyID = 1; */
        if (message.applyID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.applyID);
        /* int32 reason = 2; */
        if (message.reason !== 0)
            writer.tag(2, WireType.Varint).int32(message.reason);
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RejectInvitationCodeApplyReq
 */
export const RejectInvitationCodeApplyReq = new RejectInvitationCodeApplyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RejectInvitationCodeApplyResp$Type extends MessageType<RejectInvitationCodeApplyResp> {
    constructor() {
        super("mp.user.account.v1.RejectInvitationCodeApplyResp", []);
    }
    create(value?: PartialMessage<RejectInvitationCodeApplyResp>): RejectInvitationCodeApplyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RejectInvitationCodeApplyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RejectInvitationCodeApplyResp): RejectInvitationCodeApplyResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RejectInvitationCodeApplyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RejectInvitationCodeApplyResp
 */
export const RejectInvitationCodeApplyResp = new RejectInvitationCodeApplyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInvitationCodeApplyReq$Type extends MessageType<ListInvitationCodeApplyReq> {
    constructor() {
        super("mp.user.account.v1.ListInvitationCodeApplyReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "isApplying", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListInvitationCodeApplyReq>): ListInvitationCodeApplyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.isApplying = false;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListInvitationCodeApplyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInvitationCodeApplyReq): ListInvitationCodeApplyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* bool isApplying */ 4:
                    message.isApplying = reader.bool();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListInvitationCodeApplyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* bool isApplying = 4; */
        if (message.isApplying !== false)
            writer.tag(4, WireType.Varint).bool(message.isApplying);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListInvitationCodeApplyReq
 */
export const ListInvitationCodeApplyReq = new ListInvitationCodeApplyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListInvitationCodeApplyResp$Type extends MessageType<ListInvitationCodeApplyResp> {
    constructor() {
        super("mp.user.account.v1.ListInvitationCodeApplyResp", [
            { no: 1, name: "applyList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvitationCodeApply },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListInvitationCodeApplyResp>): ListInvitationCodeApplyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.applyList = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<ListInvitationCodeApplyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListInvitationCodeApplyResp): ListInvitationCodeApplyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.InvitationCodeApply applyList */ 1:
                    message.applyList.push(InvitationCodeApply.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListInvitationCodeApplyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.InvitationCodeApply applyList = 1; */
        for (let i = 0; i < message.applyList.length; i++)
            InvitationCodeApply.internalBinaryWrite(message.applyList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListInvitationCodeApplyResp
 */
export const ListInvitationCodeApplyResp = new ListInvitationCodeApplyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvitationCodeApplyReq$Type extends MessageType<GetInvitationCodeApplyReq> {
    constructor() {
        super("mp.user.account.v1.GetInvitationCodeApplyReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetInvitationCodeApplyReq>): GetInvitationCodeApplyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetInvitationCodeApplyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvitationCodeApplyReq): GetInvitationCodeApplyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvitationCodeApplyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetInvitationCodeApplyReq
 */
export const GetInvitationCodeApplyReq = new GetInvitationCodeApplyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetInvitationCodeApplyResp$Type extends MessageType<GetInvitationCodeApplyResp> {
    constructor() {
        super("mp.user.account.v1.GetInvitationCodeApplyResp", [
            { no: 1, name: "apply", kind: "message", T: () => InvitationCodeApply }
        ]);
    }
    create(value?: PartialMessage<GetInvitationCodeApplyResp>): GetInvitationCodeApplyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetInvitationCodeApplyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetInvitationCodeApplyResp): GetInvitationCodeApplyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.InvitationCodeApply apply */ 1:
                    message.apply = InvitationCodeApply.internalBinaryRead(reader, reader.uint32(), options, message.apply);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetInvitationCodeApplyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.InvitationCodeApply apply = 1; */
        if (message.apply)
            InvitationCodeApply.internalBinaryWrite(message.apply, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetInvitationCodeApplyResp
 */
export const GetInvitationCodeApplyResp = new GetInvitationCodeApplyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyAuthToken$Type extends MessageType<VerifyAuthToken> {
    constructor() {
        super("mp.user.account.v1.VerifyAuthToken", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "allowAuthTypeList", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["mp.base.PassportAuthType", PassportAuthType] },
            { no: 3, name: "allowPassportTypeList", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["mp.base.PassportType", PassportType] },
            { no: 4, name: "denyAuthTypeList", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["mp.base.PassportAuthType", PassportAuthType] },
            { no: 5, name: "denyPassportTypeList", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["mp.base.PassportType", PassportType] }
        ]);
    }
    create(value?: PartialMessage<VerifyAuthToken>): VerifyAuthToken {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        message.allowAuthTypeList = [];
        message.allowPassportTypeList = [];
        message.denyAuthTypeList = [];
        message.denyPassportTypeList = [];
        if (value !== undefined)
            reflectionMergePartial<VerifyAuthToken>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyAuthToken): VerifyAuthToken {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* repeated mp.base.PassportAuthType allowAuthTypeList */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.allowAuthTypeList.push(reader.int32());
                    else
                        message.allowAuthTypeList.push(reader.int32());
                    break;
                case /* repeated mp.base.PassportType allowPassportTypeList */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.allowPassportTypeList.push(reader.int32());
                    else
                        message.allowPassportTypeList.push(reader.int32());
                    break;
                case /* repeated mp.base.PassportAuthType denyAuthTypeList */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.denyAuthTypeList.push(reader.int32());
                    else
                        message.denyAuthTypeList.push(reader.int32());
                    break;
                case /* repeated mp.base.PassportType denyPassportTypeList */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.denyPassportTypeList.push(reader.int32());
                    else
                        message.denyPassportTypeList.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyAuthToken, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* repeated mp.base.PassportAuthType allowAuthTypeList = 2; */
        if (message.allowAuthTypeList.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.allowAuthTypeList.length; i++)
                writer.int32(message.allowAuthTypeList[i]);
            writer.join();
        }
        /* repeated mp.base.PassportType allowPassportTypeList = 3; */
        if (message.allowPassportTypeList.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.allowPassportTypeList.length; i++)
                writer.int32(message.allowPassportTypeList[i]);
            writer.join();
        }
        /* repeated mp.base.PassportAuthType denyAuthTypeList = 4; */
        if (message.denyAuthTypeList.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.denyAuthTypeList.length; i++)
                writer.int32(message.denyAuthTypeList[i]);
            writer.join();
        }
        /* repeated mp.base.PassportType denyPassportTypeList = 5; */
        if (message.denyPassportTypeList.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.denyPassportTypeList.length; i++)
                writer.int32(message.denyPassportTypeList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.VerifyAuthToken
 */
export const VerifyAuthToken = new VerifyAuthToken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Enable2FAReq$Type extends MessageType<Enable2FAReq> {
    constructor() {
        super("mp.user.account.v1.Enable2FAReq", [
            { no: 1, name: "authToken", kind: "message", T: () => VerifyAuthToken },
            { no: 2, name: "newFactorAuthToken", kind: "message", T: () => VerifyAuthToken }
        ]);
    }
    create(value?: PartialMessage<Enable2FAReq>): Enable2FAReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Enable2FAReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Enable2FAReq): Enable2FAReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.VerifyAuthToken authToken */ 1:
                    message.authToken = VerifyAuthToken.internalBinaryRead(reader, reader.uint32(), options, message.authToken);
                    break;
                case /* mp.user.account.v1.VerifyAuthToken newFactorAuthToken */ 2:
                    message.newFactorAuthToken = VerifyAuthToken.internalBinaryRead(reader, reader.uint32(), options, message.newFactorAuthToken);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Enable2FAReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.VerifyAuthToken authToken = 1; */
        if (message.authToken)
            VerifyAuthToken.internalBinaryWrite(message.authToken, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.VerifyAuthToken newFactorAuthToken = 2; */
        if (message.newFactorAuthToken)
            VerifyAuthToken.internalBinaryWrite(message.newFactorAuthToken, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Enable2FAReq
 */
export const Enable2FAReq = new Enable2FAReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Enable2FAResp$Type extends MessageType<Enable2FAResp> {
    constructor() {
        super("mp.user.account.v1.Enable2FAResp", []);
    }
    create(value?: PartialMessage<Enable2FAResp>): Enable2FAResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Enable2FAResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Enable2FAResp): Enable2FAResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Enable2FAResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Enable2FAResp
 */
export const Enable2FAResp = new Enable2FAResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Disable2FAReq$Type extends MessageType<Disable2FAReq> {
    constructor() {
        super("mp.user.account.v1.Disable2FAReq", [
            { no: 1, name: "authToken", kind: "message", T: () => VerifyAuthToken },
            { no: 2, name: "factorAuthToken", kind: "message", T: () => VerifyAuthToken }
        ]);
    }
    create(value?: PartialMessage<Disable2FAReq>): Disable2FAReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Disable2FAReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Disable2FAReq): Disable2FAReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.VerifyAuthToken authToken */ 1:
                    message.authToken = VerifyAuthToken.internalBinaryRead(reader, reader.uint32(), options, message.authToken);
                    break;
                case /* mp.user.account.v1.VerifyAuthToken factorAuthToken */ 2:
                    message.factorAuthToken = VerifyAuthToken.internalBinaryRead(reader, reader.uint32(), options, message.factorAuthToken);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Disable2FAReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.VerifyAuthToken authToken = 1; */
        if (message.authToken)
            VerifyAuthToken.internalBinaryWrite(message.authToken, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.VerifyAuthToken factorAuthToken = 2; */
        if (message.factorAuthToken)
            VerifyAuthToken.internalBinaryWrite(message.factorAuthToken, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Disable2FAReq
 */
export const Disable2FAReq = new Disable2FAReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Disable2FAResp$Type extends MessageType<Disable2FAResp> {
    constructor() {
        super("mp.user.account.v1.Disable2FAResp", []);
    }
    create(value?: PartialMessage<Disable2FAResp>): Disable2FAResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Disable2FAResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Disable2FAResp): Disable2FAResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Disable2FAResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Disable2FAResp
 */
export const Disable2FAResp = new Disable2FAResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Verify2FAReq$Type extends MessageType<Verify2FAReq> {
    constructor() {
        super("mp.user.account.v1.Verify2FAReq", [
            { no: 1, name: "authToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "factorAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Verify2FAReq>): Verify2FAReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.authToken = "";
        message.factorAuthToken = "";
        if (value !== undefined)
            reflectionMergePartial<Verify2FAReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Verify2FAReq): Verify2FAReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string authToken */ 1:
                    message.authToken = reader.string();
                    break;
                case /* string factorAuthToken */ 2:
                    message.factorAuthToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Verify2FAReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string authToken = 1; */
        if (message.authToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.authToken);
        /* string factorAuthToken = 2; */
        if (message.factorAuthToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.factorAuthToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Verify2FAReq
 */
export const Verify2FAReq = new Verify2FAReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Verify2FAResp$Type extends MessageType<Verify2FAResp> {
    constructor() {
        super("mp.user.account.v1.Verify2FAResp", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Verify2FAResp>): Verify2FAResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<Verify2FAResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Verify2FAResp): Verify2FAResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Verify2FAResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Verify2FAResp
 */
export const Verify2FAResp = new Verify2FAResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Change2FAReq$Type extends MessageType<Change2FAReq> {
    constructor() {
        super("mp.user.account.v1.Change2FAReq", [
            { no: 1, name: "authToken", kind: "message", T: () => VerifyAuthToken },
            { no: 2, name: "oldFactorAuthToken", kind: "message", T: () => VerifyAuthToken },
            { no: 3, name: "newFactorAuthToken", kind: "message", T: () => VerifyAuthToken }
        ]);
    }
    create(value?: PartialMessage<Change2FAReq>): Change2FAReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Change2FAReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Change2FAReq): Change2FAReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.VerifyAuthToken authToken */ 1:
                    message.authToken = VerifyAuthToken.internalBinaryRead(reader, reader.uint32(), options, message.authToken);
                    break;
                case /* mp.user.account.v1.VerifyAuthToken oldFactorAuthToken */ 2:
                    message.oldFactorAuthToken = VerifyAuthToken.internalBinaryRead(reader, reader.uint32(), options, message.oldFactorAuthToken);
                    break;
                case /* mp.user.account.v1.VerifyAuthToken newFactorAuthToken */ 3:
                    message.newFactorAuthToken = VerifyAuthToken.internalBinaryRead(reader, reader.uint32(), options, message.newFactorAuthToken);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Change2FAReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.VerifyAuthToken authToken = 1; */
        if (message.authToken)
            VerifyAuthToken.internalBinaryWrite(message.authToken, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.VerifyAuthToken oldFactorAuthToken = 2; */
        if (message.oldFactorAuthToken)
            VerifyAuthToken.internalBinaryWrite(message.oldFactorAuthToken, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* mp.user.account.v1.VerifyAuthToken newFactorAuthToken = 3; */
        if (message.newFactorAuthToken)
            VerifyAuthToken.internalBinaryWrite(message.newFactorAuthToken, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Change2FAReq
 */
export const Change2FAReq = new Change2FAReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Change2FAResp$Type extends MessageType<Change2FAResp> {
    constructor() {
        super("mp.user.account.v1.Change2FAResp", []);
    }
    create(value?: PartialMessage<Change2FAResp>): Change2FAResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Change2FAResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Change2FAResp): Change2FAResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Change2FAResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Change2FAResp
 */
export const Change2FAResp = new Change2FAResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FactorPassport$Type extends MessageType<FactorPassport> {
    constructor() {
        super("mp.user.account.v1.FactorPassport", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["mp.base.PassportType", PassportType] },
            { no: 3, name: "isEnable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "authType", kind: "enum", T: () => ["mp.base.PassportAuthType", PassportAuthType] }
        ]);
    }
    create(value?: PartialMessage<FactorPassport>): FactorPassport {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.type = 0;
        message.isEnable = false;
        message.authType = 0;
        if (value !== undefined)
            reflectionMergePartial<FactorPassport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FactorPassport): FactorPassport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* mp.base.PassportType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* bool isEnable */ 3:
                    message.isEnable = reader.bool();
                    break;
                case /* mp.base.PassportAuthType authType */ 4:
                    message.authType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FactorPassport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* mp.base.PassportType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* bool isEnable = 3; */
        if (message.isEnable !== false)
            writer.tag(3, WireType.Varint).bool(message.isEnable);
        /* mp.base.PassportAuthType authType = 4; */
        if (message.authType !== 0)
            writer.tag(4, WireType.Varint).int32(message.authType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.FactorPassport
 */
export const FactorPassport = new FactorPassport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Get2FAReq$Type extends MessageType<Get2FAReq> {
    constructor() {
        super("mp.user.account.v1.Get2FAReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Get2FAReq>): Get2FAReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<Get2FAReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Get2FAReq): Get2FAReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Get2FAReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Get2FAReq
 */
export const Get2FAReq = new Get2FAReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Get2FAResp$Type extends MessageType<Get2FAResp> {
    constructor() {
        super("mp.user.account.v1.Get2FAResp", [
            { no: 1, name: "factorPassport", kind: "message", T: () => FactorPassport }
        ]);
    }
    create(value?: PartialMessage<Get2FAResp>): Get2FAResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Get2FAResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Get2FAResp): Get2FAResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.FactorPassport factorPassport */ 1:
                    message.factorPassport = FactorPassport.internalBinaryRead(reader, reader.uint32(), options, message.factorPassport);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Get2FAResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.FactorPassport factorPassport = 1; */
        if (message.factorPassport)
            FactorPassport.internalBinaryWrite(message.factorPassport, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.Get2FAResp
 */
export const Get2FAResp = new Get2FAResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateOTPSecretReq$Type extends MessageType<GenerateOTPSecretReq> {
    constructor() {
        super("mp.user.account.v1.GenerateOTPSecretReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateOTPSecretReq>): GenerateOTPSecretReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<GenerateOTPSecretReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateOTPSecretReq): GenerateOTPSecretReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateOTPSecretReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GenerateOTPSecretReq
 */
export const GenerateOTPSecretReq = new GenerateOTPSecretReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenerateOTPSecretResp$Type extends MessageType<GenerateOTPSecretResp> {
    constructor() {
        super("mp.user.account.v1.GenerateOTPSecretResp", [
            { no: 1, name: "secret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GenerateOTPSecretResp>): GenerateOTPSecretResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.secret = "";
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<GenerateOTPSecretResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenerateOTPSecretResp): GenerateOTPSecretResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string secret */ 1:
                    message.secret = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenerateOTPSecretResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string secret = 1; */
        if (message.secret !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.secret);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GenerateOTPSecretResp
 */
export const GenerateOTPSecretResp = new GenerateOTPSecretResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeOTPReq$Type extends MessageType<ChangeOTPReq> {
    constructor() {
        super("mp.user.account.v1.ChangeOTPReq", [
            { no: 1, name: "authToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChangeOTPReq>): ChangeOTPReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.authToken = "";
        message.code = "";
        if (value !== undefined)
            reflectionMergePartial<ChangeOTPReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeOTPReq): ChangeOTPReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string authToken */ 1:
                    message.authToken = reader.string();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChangeOTPReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string authToken = 1; */
        if (message.authToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.authToken);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ChangeOTPReq
 */
export const ChangeOTPReq = new ChangeOTPReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeOTPResp$Type extends MessageType<ChangeOTPResp> {
    constructor() {
        super("mp.user.account.v1.ChangeOTPResp", []);
    }
    create(value?: PartialMessage<ChangeOTPResp>): ChangeOTPResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ChangeOTPResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeOTPResp): ChangeOTPResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ChangeOTPResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ChangeOTPResp
 */
export const ChangeOTPResp = new ChangeOTPResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelOTPReq$Type extends MessageType<DelOTPReq> {
    constructor() {
        super("mp.user.account.v1.DelOTPReq", [
            { no: 1, name: "authToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DelOTPReq>): DelOTPReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.authToken = "";
        if (value !== undefined)
            reflectionMergePartial<DelOTPReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelOTPReq): DelOTPReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string authToken */ 1:
                    message.authToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelOTPReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string authToken = 1; */
        if (message.authToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.authToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.DelOTPReq
 */
export const DelOTPReq = new DelOTPReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelOTPResp$Type extends MessageType<DelOTPResp> {
    constructor() {
        super("mp.user.account.v1.DelOTPResp", []);
    }
    create(value?: PartialMessage<DelOTPResp>): DelOTPResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DelOTPResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelOTPResp): DelOTPResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DelOTPResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.DelOTPResp
 */
export const DelOTPResp = new DelOTPResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformEntity$Type extends MessageType<PlatformEntity> {
    constructor() {
        super("mp.user.account.v1.PlatformEntity", [
            { no: 1, name: "status", kind: "enum", T: () => ["mp.user.account.v1.PlatformEntity.Status", PlatformEntity_Status] },
            { no: 3, name: "twitter", kind: "message", oneof: "entity", T: () => PlatformEntity_Twitter }
        ]);
    }
    create(value?: PartialMessage<PlatformEntity>): PlatformEntity {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.status = 0;
        message.entity = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<PlatformEntity>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlatformEntity): PlatformEntity {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.PlatformEntity.Status status */ 1:
                    message.status = reader.int32();
                    break;
                case /* mp.user.account.v1.PlatformEntity.Twitter twitter */ 3:
                    message.entity = {
                        oneofKind: "twitter",
                        twitter: PlatformEntity_Twitter.internalBinaryRead(reader, reader.uint32(), options, (message.entity as any).twitter)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlatformEntity, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.PlatformEntity.Status status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        /* mp.user.account.v1.PlatformEntity.Twitter twitter = 3; */
        if (message.entity.oneofKind === "twitter")
            PlatformEntity_Twitter.internalBinaryWrite(message.entity.twitter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PlatformEntity
 */
export const PlatformEntity = new PlatformEntity$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformEntity_Twitter$Type extends MessageType<PlatformEntity_Twitter> {
    constructor() {
        super("mp.user.account.v1.PlatformEntity.Twitter", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "profileImageURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "flowersCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "followingCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "tweetCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "listedCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "verified", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "lastUpdateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "connectionStatus", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PlatformEntity_Twitter>): PlatformEntity_Twitter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.username = "";
        message.profileImageURL = "";
        message.flowersCount = 0;
        message.followingCount = 0;
        message.tweetCount = 0;
        message.listedCount = 0;
        message.verified = false;
        message.createdAt = 0;
        message.lastUpdateAt = 0;
        message.connectionStatus = [];
        if (value !== undefined)
            reflectionMergePartial<PlatformEntity_Twitter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlatformEntity_Twitter): PlatformEntity_Twitter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string username */ 3:
                    message.username = reader.string();
                    break;
                case /* string profileImageURL */ 4:
                    message.profileImageURL = reader.string();
                    break;
                case /* int32 flowersCount */ 5:
                    message.flowersCount = reader.int32();
                    break;
                case /* int32 followingCount */ 6:
                    message.followingCount = reader.int32();
                    break;
                case /* int32 tweetCount */ 7:
                    message.tweetCount = reader.int32();
                    break;
                case /* int32 listedCount */ 8:
                    message.listedCount = reader.int32();
                    break;
                case /* bool verified */ 9:
                    message.verified = reader.bool();
                    break;
                case /* int64 createdAt */ 10:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 lastUpdateAt */ 11:
                    message.lastUpdateAt = reader.int64().toNumber();
                    break;
                case /* repeated string connectionStatus */ 12:
                    message.connectionStatus.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlatformEntity_Twitter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string username = 3; */
        if (message.username !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.username);
        /* string profileImageURL = 4; */
        if (message.profileImageURL !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.profileImageURL);
        /* int32 flowersCount = 5; */
        if (message.flowersCount !== 0)
            writer.tag(5, WireType.Varint).int32(message.flowersCount);
        /* int32 followingCount = 6; */
        if (message.followingCount !== 0)
            writer.tag(6, WireType.Varint).int32(message.followingCount);
        /* int32 tweetCount = 7; */
        if (message.tweetCount !== 0)
            writer.tag(7, WireType.Varint).int32(message.tweetCount);
        /* int32 listedCount = 8; */
        if (message.listedCount !== 0)
            writer.tag(8, WireType.Varint).int32(message.listedCount);
        /* bool verified = 9; */
        if (message.verified !== false)
            writer.tag(9, WireType.Varint).bool(message.verified);
        /* int64 createdAt = 10; */
        if (message.createdAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.createdAt);
        /* int64 lastUpdateAt = 11; */
        if (message.lastUpdateAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.lastUpdateAt);
        /* repeated string connectionStatus = 12; */
        for (let i = 0; i < message.connectionStatus.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.connectionStatus[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PlatformEntity.Twitter
 */
export const PlatformEntity_Twitter = new PlatformEntity_Twitter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPlatformEntityReq$Type extends MessageType<ListPlatformEntityReq> {
    constructor() {
        super("mp.user.account.v1.ListPlatformEntityReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["mp.user.account.v1.PlatformEntity.Status", PlatformEntity_Status] }
        ]);
    }
    create(value?: PartialMessage<ListPlatformEntityReq>): ListPlatformEntityReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<ListPlatformEntityReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPlatformEntityReq): ListPlatformEntityReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.PlatformEntity.Status status */ 3:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPlatformEntityReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* mp.user.account.v1.PlatformEntity.Status status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPlatformEntityReq
 */
export const ListPlatformEntityReq = new ListPlatformEntityReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPlatformEntityResp$Type extends MessageType<ListPlatformEntityResp> {
    constructor() {
        super("mp.user.account.v1.ListPlatformEntityResp", [
            { no: 1, name: "entityList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PlatformEntity },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListPlatformEntityResp>): ListPlatformEntityResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entityList = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<ListPlatformEntityResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPlatformEntityResp): ListPlatformEntityResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.PlatformEntity entityList */ 1:
                    message.entityList.push(PlatformEntity.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPlatformEntityResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.PlatformEntity entityList = 1; */
        for (let i = 0; i < message.entityList.length; i++)
            PlatformEntity.internalBinaryWrite(message.entityList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPlatformEntityResp
 */
export const ListPlatformEntityResp = new ListPlatformEntityResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetPlatformEntityReq$Type extends MessageType<SetPlatformEntityReq> {
    constructor() {
        super("mp.user.account.v1.SetPlatformEntityReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["mp.user.account.v1.PlatformType", PlatformType] },
            { no: 3, name: "status", kind: "enum", T: () => ["mp.user.account.v1.PlatformEntity.Status", PlatformEntity_Status] },
            { no: 4, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SetPlatformEntityReq>): SetPlatformEntityReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.type = 0;
        message.status = 0;
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<SetPlatformEntityReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetPlatformEntityReq): SetPlatformEntityReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* mp.user.account.v1.PlatformType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* mp.user.account.v1.PlatformEntity.Status status */ 3:
                    message.status = reader.int32();
                    break;
                case /* int64 accountID */ 4:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetPlatformEntityReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* mp.user.account.v1.PlatformType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* mp.user.account.v1.PlatformEntity.Status status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* int64 accountID = 4; */
        if (message.accountID !== 0)
            writer.tag(4, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetPlatformEntityReq
 */
export const SetPlatformEntityReq = new SetPlatformEntityReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetPlatformEntityResp$Type extends MessageType<SetPlatformEntityResp> {
    constructor() {
        super("mp.user.account.v1.SetPlatformEntityResp", []);
    }
    create(value?: PartialMessage<SetPlatformEntityResp>): SetPlatformEntityResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetPlatformEntityResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetPlatformEntityResp): SetPlatformEntityResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetPlatformEntityResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetPlatformEntityResp
 */
export const SetPlatformEntityResp = new SetPlatformEntityResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformEntityStatus$Type extends MessageType<PlatformEntityStatus> {
    constructor() {
        super("mp.user.account.v1.PlatformEntityStatus", [
            { no: 1, name: "type", kind: "enum", T: () => ["mp.user.account.v1.PlatformType", PlatformType] },
            { no: 2, name: "status", kind: "enum", T: () => ["mp.user.account.v1.PlatformEntity.Status", PlatformEntity_Status] },
            { no: 3, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "connectionStatus", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PlatformEntityStatus>): PlatformEntityStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.status = 0;
        message.accountID = 0;
        message.connectionStatus = [];
        if (value !== undefined)
            reflectionMergePartial<PlatformEntityStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlatformEntityStatus): PlatformEntityStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.PlatformType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* mp.user.account.v1.PlatformEntity.Status status */ 2:
                    message.status = reader.int32();
                    break;
                case /* int64 accountID */ 3:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* repeated string connectionStatus */ 4:
                    message.connectionStatus.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PlatformEntityStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.PlatformType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* mp.user.account.v1.PlatformEntity.Status status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* int64 accountID = 3; */
        if (message.accountID !== 0)
            writer.tag(3, WireType.Varint).int64(message.accountID);
        /* repeated string connectionStatus = 4; */
        for (let i = 0; i < message.connectionStatus.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.connectionStatus[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PlatformEntityStatus
 */
export const PlatformEntityStatus = new PlatformEntityStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAccountPlatformEntityStatusReq$Type extends MessageType<ListAccountPlatformEntityStatusReq> {
    constructor() {
        super("mp.user.account.v1.ListAccountPlatformEntityStatusReq", [
            { no: 1, name: "accountIDList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListAccountPlatformEntityStatusReq>): ListAccountPlatformEntityStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountIDList = [];
        if (value !== undefined)
            reflectionMergePartial<ListAccountPlatformEntityStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAccountPlatformEntityStatusReq): ListAccountPlatformEntityStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 accountIDList */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.accountIDList.push(reader.int64().toNumber());
                    else
                        message.accountIDList.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAccountPlatformEntityStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 accountIDList = 1; */
        if (message.accountIDList.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.accountIDList.length; i++)
                writer.int64(message.accountIDList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListAccountPlatformEntityStatusReq
 */
export const ListAccountPlatformEntityStatusReq = new ListAccountPlatformEntityStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAccountPlatformEntityStatusResp$Type extends MessageType<ListAccountPlatformEntityStatusResp> {
    constructor() {
        super("mp.user.account.v1.ListAccountPlatformEntityStatusResp", [
            { no: 1, name: "statusList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PlatformEntityStatus }
        ]);
    }
    create(value?: PartialMessage<ListAccountPlatformEntityStatusResp>): ListAccountPlatformEntityStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.statusList = [];
        if (value !== undefined)
            reflectionMergePartial<ListAccountPlatformEntityStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAccountPlatformEntityStatusResp): ListAccountPlatformEntityStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.PlatformEntityStatus statusList */ 1:
                    message.statusList.push(PlatformEntityStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAccountPlatformEntityStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.PlatformEntityStatus statusList = 1; */
        for (let i = 0; i < message.statusList.length; i++)
            PlatformEntityStatus.internalBinaryWrite(message.statusList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListAccountPlatformEntityStatusResp
 */
export const ListAccountPlatformEntityStatusResp = new ListAccountPlatformEntityStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAccountPlatformEntityStatusReq$Type extends MessageType<SetAccountPlatformEntityStatusReq> {
    constructor() {
        super("mp.user.account.v1.SetAccountPlatformEntityStatusReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "status", kind: "enum", T: () => ["mp.user.account.v1.PlatformEntity.Status", PlatformEntity_Status] }
        ]);
    }
    create(value?: PartialMessage<SetAccountPlatformEntityStatusReq>): SetAccountPlatformEntityStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<SetAccountPlatformEntityStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetAccountPlatformEntityStatusReq): SetAccountPlatformEntityStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* mp.user.account.v1.PlatformEntity.Status status */ 2:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetAccountPlatformEntityStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* mp.user.account.v1.PlatformEntity.Status status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetAccountPlatformEntityStatusReq
 */
export const SetAccountPlatformEntityStatusReq = new SetAccountPlatformEntityStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetAccountPlatformEntityStatusResp$Type extends MessageType<SetAccountPlatformEntityStatusResp> {
    constructor() {
        super("mp.user.account.v1.SetAccountPlatformEntityStatusResp", []);
    }
    create(value?: PartialMessage<SetAccountPlatformEntityStatusResp>): SetAccountPlatformEntityStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetAccountPlatformEntityStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetAccountPlatformEntityStatusResp): SetAccountPlatformEntityStatusResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetAccountPlatformEntityStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetAccountPlatformEntityStatusResp
 */
export const SetAccountPlatformEntityStatusResp = new SetAccountPlatformEntityStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPlatformEntityStatusReq$Type extends MessageType<ListPlatformEntityStatusReq> {
    constructor() {
        super("mp.user.account.v1.ListPlatformEntityStatusReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListPlatformEntityStatusReq>): ListPlatformEntityStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListPlatformEntityStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPlatformEntityStatusReq): ListPlatformEntityStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPlatformEntityStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPlatformEntityStatusReq
 */
export const ListPlatformEntityStatusReq = new ListPlatformEntityStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPlatformEntityStatusResp$Type extends MessageType<ListPlatformEntityStatusResp> {
    constructor() {
        super("mp.user.account.v1.ListPlatformEntityStatusResp", [
            { no: 1, name: "statusList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PlatformEntityStatus }
        ]);
    }
    create(value?: PartialMessage<ListPlatformEntityStatusResp>): ListPlatformEntityStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.statusList = [];
        if (value !== undefined)
            reflectionMergePartial<ListPlatformEntityStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPlatformEntityStatusResp): ListPlatformEntityStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.PlatformEntityStatus statusList */ 1:
                    message.statusList.push(PlatformEntityStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPlatformEntityStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.PlatformEntityStatus statusList = 1; */
        for (let i = 0; i < message.statusList.length; i++)
            PlatformEntityStatus.internalBinaryWrite(message.statusList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPlatformEntityStatusResp
 */
export const ListPlatformEntityStatusResp = new ListPlatformEntityStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlatformEntityStatusReq$Type extends MessageType<GetPlatformEntityStatusReq> {
    constructor() {
        super("mp.user.account.v1.GetPlatformEntityStatusReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetPlatformEntityStatusReq>): GetPlatformEntityStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPlatformEntityStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlatformEntityStatusReq): GetPlatformEntityStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlatformEntityStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPlatformEntityStatusReq
 */
export const GetPlatformEntityStatusReq = new GetPlatformEntityStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlatformEntityStatusResp$Type extends MessageType<GetPlatformEntityStatusResp> {
    constructor() {
        super("mp.user.account.v1.GetPlatformEntityStatusResp", [
            { no: 1, name: "status", kind: "message", T: () => PlatformEntityStatus }
        ]);
    }
    create(value?: PartialMessage<GetPlatformEntityStatusResp>): GetPlatformEntityStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetPlatformEntityStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlatformEntityStatusResp): GetPlatformEntityStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.PlatformEntityStatus status */ 1:
                    message.status = PlatformEntityStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlatformEntityStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.PlatformEntityStatus status = 1; */
        if (message.status)
            PlatformEntityStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPlatformEntityStatusResp
 */
export const GetPlatformEntityStatusResp = new GetPlatformEntityStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterPassKeyBeginReq$Type extends MessageType<RegisterPassKeyBeginReq> {
    constructor() {
        super("mp.user.account.v1.RegisterPassKeyBeginReq", [
            { no: 1, name: "authToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterPassKeyBeginReq>): RegisterPassKeyBeginReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.authToken = "";
        if (value !== undefined)
            reflectionMergePartial<RegisterPassKeyBeginReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterPassKeyBeginReq): RegisterPassKeyBeginReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string authToken */ 1:
                    message.authToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterPassKeyBeginReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string authToken = 1; */
        if (message.authToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.authToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RegisterPassKeyBeginReq
 */
export const RegisterPassKeyBeginReq = new RegisterPassKeyBeginReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterPassKeyBeginResp$Type extends MessageType<RegisterPassKeyBeginResp> {
    constructor() {
        super("mp.user.account.v1.RegisterPassKeyBeginResp", [
            { no: 1, name: "challenge", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterPassKeyBeginResp>): RegisterPassKeyBeginResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.challenge = "";
        if (value !== undefined)
            reflectionMergePartial<RegisterPassKeyBeginResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterPassKeyBeginResp): RegisterPassKeyBeginResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string challenge */ 1:
                    message.challenge = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterPassKeyBeginResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string challenge = 1; */
        if (message.challenge !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.challenge);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RegisterPassKeyBeginResp
 */
export const RegisterPassKeyBeginResp = new RegisterPassKeyBeginResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterPassKeyFinishReq$Type extends MessageType<RegisterPassKeyFinishReq> {
    constructor() {
        super("mp.user.account.v1.RegisterPassKeyFinishReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterPassKeyFinishReq>): RegisterPassKeyFinishReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.data = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<RegisterPassKeyFinishReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterPassKeyFinishReq): RegisterPassKeyFinishReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string data */ 2:
                    message.data = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterPassKeyFinishReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string data = 2; */
        if (message.data !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.data);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RegisterPassKeyFinishReq
 */
export const RegisterPassKeyFinishReq = new RegisterPassKeyFinishReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterPassKeyFinishResp$Type extends MessageType<RegisterPassKeyFinishResp> {
    constructor() {
        super("mp.user.account.v1.RegisterPassKeyFinishResp", [
            { no: 1, name: "credID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterPassKeyFinishResp>): RegisterPassKeyFinishResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credID = "";
        if (value !== undefined)
            reflectionMergePartial<RegisterPassKeyFinishResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterPassKeyFinishResp): RegisterPassKeyFinishResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string credID */ 1:
                    message.credID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterPassKeyFinishResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string credID = 1; */
        if (message.credID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.credID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.RegisterPassKeyFinishResp
 */
export const RegisterPassKeyFinishResp = new RegisterPassKeyFinishResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthByPassKeyBeginReq$Type extends MessageType<AuthByPassKeyBeginReq> {
    constructor() {
        super("mp.user.account.v1.AuthByPassKeyBeginReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "credID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthByPassKeyBeginReq>): AuthByPassKeyBeginReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.credID = "";
        if (value !== undefined)
            reflectionMergePartial<AuthByPassKeyBeginReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthByPassKeyBeginReq): AuthByPassKeyBeginReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string credID */ 2:
                    message.credID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthByPassKeyBeginReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string credID = 2; */
        if (message.credID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.credID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AuthByPassKeyBeginReq
 */
export const AuthByPassKeyBeginReq = new AuthByPassKeyBeginReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthByPassKeyBeginResp$Type extends MessageType<AuthByPassKeyBeginResp> {
    constructor() {
        super("mp.user.account.v1.AuthByPassKeyBeginResp", [
            { no: 1, name: "challenge", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "credIDList", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthByPassKeyBeginResp>): AuthByPassKeyBeginResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.challenge = "";
        message.credIDList = [];
        if (value !== undefined)
            reflectionMergePartial<AuthByPassKeyBeginResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthByPassKeyBeginResp): AuthByPassKeyBeginResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string challenge */ 1:
                    message.challenge = reader.string();
                    break;
                case /* repeated string credIDList */ 2:
                    message.credIDList.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthByPassKeyBeginResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string challenge = 1; */
        if (message.challenge !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.challenge);
        /* repeated string credIDList = 2; */
        for (let i = 0; i < message.credIDList.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.credIDList[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AuthByPassKeyBeginResp
 */
export const AuthByPassKeyBeginResp = new AuthByPassKeyBeginResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthByPassKeyFinishReq$Type extends MessageType<AuthByPassKeyFinishReq> {
    constructor() {
        super("mp.user.account.v1.AuthByPassKeyFinishReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuthByPassKeyFinishReq>): AuthByPassKeyFinishReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<AuthByPassKeyFinishReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthByPassKeyFinishReq): AuthByPassKeyFinishReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string data */ 2:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthByPassKeyFinishReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string data = 2; */
        if (message.data !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AuthByPassKeyFinishReq
 */
export const AuthByPassKeyFinishReq = new AuthByPassKeyFinishReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthByPassKeyFinishResp$Type extends MessageType<AuthByPassKeyFinishResp> {
    constructor() {
        super("mp.user.account.v1.AuthByPassKeyFinishResp", []);
    }
    create(value?: PartialMessage<AuthByPassKeyFinishResp>): AuthByPassKeyFinishResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AuthByPassKeyFinishResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthByPassKeyFinishResp): AuthByPassKeyFinishResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AuthByPassKeyFinishResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AuthByPassKeyFinishResp
 */
export const AuthByPassKeyFinishResp = new AuthByPassKeyFinishResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassKey$Type extends MessageType<PassKey> {
    constructor() {
        super("mp.user.account.v1.PassKey", [
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "credID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PassKey>): PassKey {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.createdAt = 0;
        message.credID = "";
        if (value !== undefined)
            reflectionMergePartial<PassKey>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassKey): PassKey {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int64 createdAt */ 3:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* string credID */ 4:
                    message.credID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassKey, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int64 createdAt = 3; */
        if (message.createdAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.createdAt);
        /* string credID = 4; */
        if (message.credID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.credID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassKey
 */
export const PassKey = new PassKey$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPassKeyReq$Type extends MessageType<ListPassKeyReq> {
    constructor() {
        super("mp.user.account.v1.ListPassKeyReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListPassKeyReq>): ListPassKeyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListPassKeyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPassKeyReq): ListPassKeyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPassKeyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPassKeyReq
 */
export const ListPassKeyReq = new ListPassKeyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPassKeyResp$Type extends MessageType<ListPassKeyResp> {
    constructor() {
        super("mp.user.account.v1.ListPassKeyResp", [
            { no: 1, name: "passKeyList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PassKey }
        ]);
    }
    create(value?: PartialMessage<ListPassKeyResp>): ListPassKeyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.passKeyList = [];
        if (value !== undefined)
            reflectionMergePartial<ListPassKeyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPassKeyResp): ListPassKeyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.PassKey passKeyList */ 1:
                    message.passKeyList.push(PassKey.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPassKeyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.PassKey passKeyList = 1; */
        for (let i = 0; i < message.passKeyList.length; i++)
            PassKey.internalBinaryWrite(message.passKeyList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.ListPassKeyResp
 */
export const ListPassKeyResp = new ListPassKeyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelPassKeyReq$Type extends MessageType<DelPassKeyReq> {
    constructor() {
        super("mp.user.account.v1.DelPassKeyReq", [
            { no: 1, name: "credID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "authToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DelPassKeyReq>): DelPassKeyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credID = "";
        message.authToken = "";
        if (value !== undefined)
            reflectionMergePartial<DelPassKeyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelPassKeyReq): DelPassKeyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string credID */ 1:
                    message.credID = reader.string();
                    break;
                case /* string authToken */ 2:
                    message.authToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelPassKeyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string credID = 1; */
        if (message.credID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.credID);
        /* string authToken = 2; */
        if (message.authToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.authToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.DelPassKeyReq
 */
export const DelPassKeyReq = new DelPassKeyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelPassKeyResp$Type extends MessageType<DelPassKeyResp> {
    constructor() {
        super("mp.user.account.v1.DelPassKeyResp", []);
    }
    create(value?: PartialMessage<DelPassKeyResp>): DelPassKeyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DelPassKeyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelPassKeyResp): DelPassKeyResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DelPassKeyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.DelPassKeyResp
 */
export const DelPassKeyResp = new DelPassKeyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePassKeyReq$Type extends MessageType<UpdatePassKeyReq> {
    constructor() {
        super("mp.user.account.v1.UpdatePassKeyReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "credID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdatePassKeyReq>): UpdatePassKeyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.credID = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<UpdatePassKeyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePassKeyReq): UpdatePassKeyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string credID */ 2:
                    message.credID = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePassKeyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string credID = 2; */
        if (message.credID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.credID);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.UpdatePassKeyReq
 */
export const UpdatePassKeyReq = new UpdatePassKeyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePassKeyResp$Type extends MessageType<UpdatePassKeyResp> {
    constructor() {
        super("mp.user.account.v1.UpdatePassKeyResp", []);
    }
    create(value?: PartialMessage<UpdatePassKeyResp>): UpdatePassKeyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdatePassKeyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePassKeyResp): UpdatePassKeyResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdatePassKeyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.UpdatePassKeyResp
 */
export const UpdatePassKeyResp = new UpdatePassKeyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassKeyReq$Type extends MessageType<GetPassKeyReq> {
    constructor() {
        super("mp.user.account.v1.GetPassKeyReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "credID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPassKeyReq>): GetPassKeyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        message.credID = "";
        if (value !== undefined)
            reflectionMergePartial<GetPassKeyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassKeyReq): GetPassKeyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* string credID */ 2:
                    message.credID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassKeyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        /* string credID = 2; */
        if (message.credID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.credID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassKeyReq
 */
export const GetPassKeyReq = new GetPassKeyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassKeyResp$Type extends MessageType<GetPassKeyResp> {
    constructor() {
        super("mp.user.account.v1.GetPassKeyResp", [
            { no: 1, name: "passKey", kind: "message", T: () => PassKey }
        ]);
    }
    create(value?: PartialMessage<GetPassKeyResp>): GetPassKeyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetPassKeyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassKeyResp): GetPassKeyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.user.account.v1.PassKey passKey */ 1:
                    message.passKey = PassKey.internalBinaryRead(reader, reader.uint32(), options, message.passKey);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassKeyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.user.account.v1.PassKey passKey = 1; */
        if (message.passKey)
            PassKey.internalBinaryWrite(message.passKey, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassKeyResp
 */
export const GetPassKeyResp = new GetPassKeyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassportAuthReq$Type extends MessageType<GetPassportAuthReq> {
    constructor() {
        super("mp.user.account.v1.GetPassportAuthReq", [
            { no: 1, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetPassportAuthReq>): GetPassportAuthReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPassportAuthReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassportAuthReq): GetPassportAuthReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 accountID */ 1:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassportAuthReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 accountID = 1; */
        if (message.accountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassportAuthReq
 */
export const GetPassportAuthReq = new GetPassportAuthReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPassportAuthResp$Type extends MessageType<GetPassportAuthResp> {
    constructor() {
        super("mp.user.account.v1.GetPassportAuthResp", [
            { no: 1, name: "changeAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetPassportAuthResp>): GetPassportAuthResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.changeAt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPassportAuthResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPassportAuthResp): GetPassportAuthResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 changeAt */ 1:
                    message.changeAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPassportAuthResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 changeAt = 1; */
        if (message.changeAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.changeAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.GetPassportAuthResp
 */
export const GetPassportAuthResp = new GetPassportAuthResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageAccountInvitationCodeReq$Type extends MessageType<PageAccountInvitationCodeReq> {
    constructor() {
        super("mp.user.account.v1.PageAccountInvitationCodeReq", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageAccountInvitationCodeReq>): PageAccountInvitationCodeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = "";
        message.accountID = 0;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<PageAccountInvitationCodeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageAccountInvitationCodeReq): PageAccountInvitationCodeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                case /* int64 page */ 3:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 4:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageAccountInvitationCodeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        /* int64 page = 3; */
        if (message.page !== 0)
            writer.tag(3, WireType.Varint).int64(message.page);
        /* int64 pageSize = 4; */
        if (message.pageSize !== 0)
            writer.tag(4, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PageAccountInvitationCodeReq
 */
export const PageAccountInvitationCodeReq = new PageAccountInvitationCodeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageAccountInvitationCodeResp$Type extends MessageType<PageAccountInvitationCodeResp> {
    constructor() {
        super("mp.user.account.v1.PageAccountInvitationCodeResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvitationCode },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageAccountInvitationCodeResp>): PageAccountInvitationCodeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<PageAccountInvitationCodeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageAccountInvitationCodeResp): PageAccountInvitationCodeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.InvitationCode list */ 1:
                    message.list.push(InvitationCode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageAccountInvitationCodeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.InvitationCode list = 1; */
        for (let i = 0; i < message.list.length; i++)
            InvitationCode.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PageAccountInvitationCodeResp
 */
export const PageAccountInvitationCodeResp = new PageAccountInvitationCodeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageAccountInvitationRecordReq$Type extends MessageType<PageAccountInvitationRecordReq> {
    constructor() {
        super("mp.user.account.v1.PageAccountInvitationRecordReq", [
            { no: 1, name: "ownerAccountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "usedAccountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageAccountInvitationRecordReq>): PageAccountInvitationRecordReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ownerAccountID = 0;
        message.usedAccountID = 0;
        message.code = "";
        message.startAt = 0;
        message.endAt = 0;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<PageAccountInvitationRecordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageAccountInvitationRecordReq): PageAccountInvitationRecordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ownerAccountID */ 1:
                    message.ownerAccountID = reader.int64().toNumber();
                    break;
                case /* int64 usedAccountID */ 2:
                    message.usedAccountID = reader.int64().toNumber();
                    break;
                case /* string code */ 7:
                    message.code = reader.string();
                    break;
                case /* int64 startAt */ 3:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 4:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* int64 page */ 5:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 6:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageAccountInvitationRecordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ownerAccountID = 1; */
        if (message.ownerAccountID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ownerAccountID);
        /* int64 usedAccountID = 2; */
        if (message.usedAccountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.usedAccountID);
        /* string code = 7; */
        if (message.code !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.code);
        /* int64 startAt = 3; */
        if (message.startAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.startAt);
        /* int64 endAt = 4; */
        if (message.endAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.endAt);
        /* int64 page = 5; */
        if (message.page !== 0)
            writer.tag(5, WireType.Varint).int64(message.page);
        /* int64 pageSize = 6; */
        if (message.pageSize !== 0)
            writer.tag(6, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PageAccountInvitationRecordReq
 */
export const PageAccountInvitationRecordReq = new PageAccountInvitationRecordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PageAccountInvitationRecordResp$Type extends MessageType<PageAccountInvitationRecordResp> {
    constructor() {
        super("mp.user.account.v1.PageAccountInvitationRecordResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => InvitationRecord },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PageAccountInvitationRecordResp>): PageAccountInvitationRecordResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<PageAccountInvitationRecordResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PageAccountInvitationRecordResp): PageAccountInvitationRecordResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.InvitationRecord list */ 1:
                    message.list.push(InvitationRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PageAccountInvitationRecordResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.InvitationRecord list = 1; */
        for (let i = 0; i < message.list.length; i++)
            InvitationRecord.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PageAccountInvitationRecordResp
 */
export const PageAccountInvitationRecordResp = new PageAccountInvitationRecordResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PassportTypeCheck$Type extends MessageType<PassportTypeCheck> {
    constructor() {
        super("mp.user.account.v1.PassportTypeCheck", [
            { no: 1, name: "passportType", kind: "enum", T: () => ["mp.base.PassportType", PassportType] },
            { no: 2, name: "exist", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PassportTypeCheck>): PassportTypeCheck {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.passportType = 0;
        message.exist = false;
        if (value !== undefined)
            reflectionMergePartial<PassportTypeCheck>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PassportTypeCheck): PassportTypeCheck {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.base.PassportType passportType */ 1:
                    message.passportType = reader.int32();
                    break;
                case /* bool exist */ 2:
                    message.exist = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PassportTypeCheck, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.base.PassportType passportType = 1; */
        if (message.passportType !== 0)
            writer.tag(1, WireType.Varint).int32(message.passportType);
        /* bool exist = 2; */
        if (message.exist !== false)
            writer.tag(2, WireType.Varint).bool(message.exist);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.PassportTypeCheck
 */
export const PassportTypeCheck = new PassportTypeCheck$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckPassportTypeReq$Type extends MessageType<CheckPassportTypeReq> {
    constructor() {
        super("mp.user.account.v1.CheckPassportTypeReq", [
            { no: 1, name: "list", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["mp.base.PassportType", PassportType] },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CheckPassportTypeReq>): CheckPassportTypeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckPassportTypeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckPassportTypeReq): CheckPassportTypeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.base.PassportType list */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.list.push(reader.int32());
                    else
                        message.list.push(reader.int32());
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckPassportTypeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.base.PassportType list = 1; */
        if (message.list.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.list.length; i++)
                writer.int32(message.list[i]);
            writer.join();
        }
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CheckPassportTypeReq
 */
export const CheckPassportTypeReq = new CheckPassportTypeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckPassportTypeResp$Type extends MessageType<CheckPassportTypeResp> {
    constructor() {
        super("mp.user.account.v1.CheckPassportTypeResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PassportTypeCheck }
        ]);
    }
    create(value?: PartialMessage<CheckPassportTypeResp>): CheckPassportTypeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<CheckPassportTypeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckPassportTypeResp): CheckPassportTypeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.PassportTypeCheck list */ 1:
                    message.list.push(PassportTypeCheck.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckPassportTypeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.PassportTypeCheck list = 1; */
        for (let i = 0; i < message.list.length; i++)
            PassportTypeCheck.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CheckPassportTypeResp
 */
export const CheckPassportTypeResp = new CheckPassportTypeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuthTypeCheck$Type extends MessageType<AuthTypeCheck> {
    constructor() {
        super("mp.user.account.v1.AuthTypeCheck", [
            { no: 1, name: "authType", kind: "enum", T: () => ["mp.base.PassportAuthType", PassportAuthType] },
            { no: 2, name: "exist", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AuthTypeCheck>): AuthTypeCheck {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.authType = 0;
        message.exist = false;
        if (value !== undefined)
            reflectionMergePartial<AuthTypeCheck>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuthTypeCheck): AuthTypeCheck {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* mp.base.PassportAuthType authType */ 1:
                    message.authType = reader.int32();
                    break;
                case /* bool exist */ 2:
                    message.exist = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuthTypeCheck, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* mp.base.PassportAuthType authType = 1; */
        if (message.authType !== 0)
            writer.tag(1, WireType.Varint).int32(message.authType);
        /* bool exist = 2; */
        if (message.exist !== false)
            writer.tag(2, WireType.Varint).bool(message.exist);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.AuthTypeCheck
 */
export const AuthTypeCheck = new AuthTypeCheck$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthTypeReq$Type extends MessageType<CheckAuthTypeReq> {
    constructor() {
        super("mp.user.account.v1.CheckAuthTypeReq", [
            { no: 1, name: "list", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["mp.base.PassportAuthType", PassportAuthType] },
            { no: 2, name: "accountID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CheckAuthTypeReq>): CheckAuthTypeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.accountID = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckAuthTypeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthTypeReq): CheckAuthTypeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.base.PassportAuthType list */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.list.push(reader.int32());
                    else
                        message.list.push(reader.int32());
                    break;
                case /* int64 accountID */ 2:
                    message.accountID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckAuthTypeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.base.PassportAuthType list = 1; */
        if (message.list.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.list.length; i++)
                writer.int32(message.list[i]);
            writer.join();
        }
        /* int64 accountID = 2; */
        if (message.accountID !== 0)
            writer.tag(2, WireType.Varint).int64(message.accountID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CheckAuthTypeReq
 */
export const CheckAuthTypeReq = new CheckAuthTypeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAuthTypeResp$Type extends MessageType<CheckAuthTypeResp> {
    constructor() {
        super("mp.user.account.v1.CheckAuthTypeResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AuthTypeCheck }
        ]);
    }
    create(value?: PartialMessage<CheckAuthTypeResp>): CheckAuthTypeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<CheckAuthTypeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAuthTypeResp): CheckAuthTypeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated mp.user.account.v1.AuthTypeCheck list */ 1:
                    message.list.push(AuthTypeCheck.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckAuthTypeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated mp.user.account.v1.AuthTypeCheck list = 1; */
        for (let i = 0; i < message.list.length; i++)
            AuthTypeCheck.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.CheckAuthTypeResp
 */
export const CheckAuthTypeResp = new CheckAuthTypeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetPayPasswordReq$Type extends MessageType<SetPayPasswordReq> {
    constructor() {
        super("mp.user.account.v1.SetPayPasswordReq", [
            { no: 1, name: "verifyAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetPayPasswordReq>): SetPayPasswordReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.verifyAuthToken = "";
        message.password = "";
        if (value !== undefined)
            reflectionMergePartial<SetPayPasswordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetPayPasswordReq): SetPayPasswordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string verifyAuthToken */ 1:
                    message.verifyAuthToken = reader.string();
                    break;
                case /* string password */ 3:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetPayPasswordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string verifyAuthToken = 1; */
        if (message.verifyAuthToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.verifyAuthToken);
        /* string password = 3; */
        if (message.password !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetPayPasswordReq
 */
export const SetPayPasswordReq = new SetPayPasswordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetPayPasswordResp$Type extends MessageType<SetPayPasswordResp> {
    constructor() {
        super("mp.user.account.v1.SetPayPasswordResp", []);
    }
    create(value?: PartialMessage<SetPayPasswordResp>): SetPayPasswordResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetPayPasswordResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetPayPasswordResp): SetPayPasswordResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetPayPasswordResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message mp.user.account.v1.SetPayPasswordResp
 */
export const SetPayPasswordResp = new SetPayPasswordResp$Type();
/**
 * @generated ServiceType for protobuf service mp.user.account.v1.AccountService
 */
export const AccountService = new ServiceType("mp.user.account.v1.AccountService", [
    { name: "Login", options: { "blocker.exportclient": 0 }, I: LoginReq, O: LoginResp },
    { name: "Signup", options: { "blocker.exportclient": 0 }, I: SignupReq, O: SignupResp },
    { name: "Bind", options: { "blocker.exportclient": 0 }, I: BindReq, O: BindResp },
    { name: "Unbind", options: { "blocker.exportclient": 0 }, I: UnbindReq, O: UnbindResp },
    { name: "Change", options: { "blocker.exportclient": 0 }, I: ChangeReq, O: ChangeResp },
    { name: "SetPassword", options: { "blocker.exportclient": 0 }, I: SetPasswordReq, O: SetPasswordResp },
    { name: "SetPayPassword", options: { "blocker.exportclient": 0 }, I: SetPayPasswordReq, O: SetPayPasswordResp },
    { name: "SendCaptcha", options: { "blocker.exportclient": 0 }, I: SendCaptchaReq, O: SendCaptchaResp },
    { name: "PassportAuth", options: { "blocker.exportclient": 0 }, I: PassportAuthReq, O: PassportAuthResp },
    { name: "CheckAuthType", options: { "blocker.exportclient": 0 }, I: CheckAuthTypeReq, O: CheckAuthTypeResp },
    { name: "GetPassportToken", options: { "blocker.exportclient": 0 }, I: GetPassportTokenReq, O: GetPassportTokenResp },
    { name: "GetPassport", options: { "blocker.exportclient": 0 }, I: GetPassportReq, O: GetPassportResp },
    { name: "GetPassportAuth", options: { "blocker.exportclient": 0 }, I: GetPassportAuthReq, O: GetPassportAuthResp },
    { name: "ListPassport", options: { "blocker.exportclient": 0 }, I: ListPassportReq, O: ListPassportResp },
    { name: "CheckPassportType", options: { "blocker.exportclient": 0 }, I: CheckPassportTypeReq, O: CheckPassportTypeResp },
    { name: "GenerateQRCode", options: { "blocker.exportclient": 0 }, I: GenerateQRCodeReq, O: GenerateQRCodeResp },
    { name: "CheckQRCode", options: { "blocker.exportclient": 0 }, I: CheckQRCodeReq, O: CheckQRCodeResp },
    { name: "ScanQRCode", options: { "blocker.exportclient": 0 }, I: ScanQRCodeReq, O: ScanQRCodeResp },
    { name: "ConfirmQRCode", options: { "blocker.exportclient": 0 }, I: ConfirmQRCodeReq, O: ConfirmQRCodeResp },
    { name: "CancelQRCode", options: { "blocker.exportclient": 0 }, I: CancelQRCodeReq, O: CancelQRCodeResp },
    { name: "GetCertification", options: { "blocker.exportclient": 0 }, I: GetCertificationReq, O: GetCertificationResp },
    { name: "RefreshCertification", options: { "blocker.exportclient": 0 }, I: RefreshCertificationReq, O: RefreshCertificationResp },
    { name: "Get", options: { "blocker.exportclient": 0 }, I: GetReq, O: GetResp },
    { name: "List", options: { "blocker.exportclient": 0 }, I: ListReq, O: ListResp },
    { name: "VerifyCaptcha", options: { "blocker.exportclient": 0 }, I: VerifyCaptchaReq, O: VerifyCaptchaResp },
    { name: "GetCaptchaStatus", options: { "blocker.exportclient": 0 }, I: GetCaptchaStatusReq, O: GetCaptchaStatusResp },
    { name: "ResetCaptchaRisk", options: { "blocker.exportclient": 0 }, I: ResetCaptchaRiskReq, O: ResetCaptchaRiskResp },
    { name: "Cancel", options: { "blocker.exportclient": 0 }, I: CancelReq, O: CancelResp },
    { name: "CreateWalletSignatureMsg", options: { "blocker.exportclient": 0 }, I: CreateWalletSignatureMsgReq, O: CreateWalletSignatureMsgResp },
    { name: "CreateInvitationCode", options: { "blocker.exportclient": 0 }, I: CreateInvitationCodeReq, O: CreateInvitationCodeResp },
    { name: "GetAccountInvitationCode", options: { "blocker.exportclient": 0 }, I: GetAccountInvitationCodeReq, O: GetAccountInvitationCodeResp },
    { name: "GetInvitationCode", options: { "blocker.exportclient": 0 }, I: GetInvitationCodeReq, O: GetInvitationCodeResp },
    { name: "PageAccountInvitationCode", options: { "blocker.exportclient": 0 }, I: PageAccountInvitationCodeReq, O: PageAccountInvitationCodeResp },
    { name: "PageAccountInvitationRecord", options: { "blocker.exportclient": 0 }, I: PageAccountInvitationRecordReq, O: PageAccountInvitationRecordResp },
    { name: "Enable2FA", options: { "blocker.exportclient": 0 }, I: Enable2FAReq, O: Enable2FAResp },
    { name: "Disable2FA", options: { "blocker.exportclient": 0 }, I: Disable2FAReq, O: Disable2FAResp },
    { name: "Change2FA", options: { "blocker.exportclient": 0 }, I: Change2FAReq, O: Change2FAResp },
    { name: "Get2FA", options: { "blocker.exportclient": 0 }, I: Get2FAReq, O: Get2FAResp },
    { name: "Verify2FA", options: { "blocker.exportclient": 0 }, I: Verify2FAReq, O: Verify2FAResp },
    { name: "GenerateOTPSecret", options: { "blocker.exportclient": 0 }, I: GenerateOTPSecretReq, O: GenerateOTPSecretResp },
    { name: "ChangeOTP", options: { "blocker.exportclient": 0 }, I: ChangeOTPReq, O: ChangeOTPResp },
    { name: "DelOTP", options: { "blocker.exportclient": 0 }, I: DelOTPReq, O: DelOTPResp },
    { name: "ListDeviceLoginLog", options: { "blocker.exportclient": 0 }, I: ListDeviceLoginLogReq, O: ListDeviceLoginLogResp },
    { name: "ListDeviceLastLoginLog", options: { "blocker.exportclient": 0 }, I: ListDeviceLastLoginLogReq, O: ListDeviceLastLoginLogResp },
    { name: "ListPlatformEntity", options: { "blocker.exportclient": 0 }, I: ListPlatformEntityReq, O: ListPlatformEntityResp },
    { name: "SetPlatformEntity", options: { "blocker.exportclient": 0 }, I: SetPlatformEntityReq, O: SetPlatformEntityResp },
    { name: "GetPlatformEntityStatus", options: { "blocker.exportclient": 0 }, I: GetPlatformEntityStatusReq, O: GetPlatformEntityStatusResp },
    { name: "ListAccountPlatformEntityStatus", options: { "blocker.exportclient": 0 }, I: ListAccountPlatformEntityStatusReq, O: ListAccountPlatformEntityStatusResp },
    { name: "SetAccountPlatformEntityStatus", options: { "blocker.exportclient": 0 }, I: SetAccountPlatformEntityStatusReq, O: SetAccountPlatformEntityStatusResp },
    { name: "ListPlatformEntityStatus", options: { "blocker.exportclient": 0 }, I: ListPlatformEntityStatusReq, O: ListPlatformEntityStatusResp },
    { name: "RegisterPassKeyBegin", options: { "blocker.exportclient": 0 }, I: RegisterPassKeyBeginReq, O: RegisterPassKeyBeginResp },
    { name: "RegisterPassKeyFinish", options: { "blocker.exportclient": 0 }, I: RegisterPassKeyFinishReq, O: RegisterPassKeyFinishResp },
    { name: "AuthByPassKeyBegin", options: { "blocker.exportclient": 0 }, I: AuthByPassKeyBeginReq, O: AuthByPassKeyBeginResp },
    { name: "AuthByPassKeyFinish", options: { "blocker.exportclient": 0 }, I: AuthByPassKeyFinishReq, O: AuthByPassKeyFinishResp },
    { name: "ListPassKey", options: { "blocker.exportclient": 0 }, I: ListPassKeyReq, O: ListPassKeyResp },
    { name: "DelPassKey", options: { "blocker.exportclient": 0 }, I: DelPassKeyReq, O: DelPassKeyResp },
    { name: "UpdatePassKey", options: { "blocker.exportclient": 0 }, I: UpdatePassKeyReq, O: UpdatePassKeyResp },
    { name: "GetPassKey", options: { "blocker.exportclient": 0 }, I: GetPassKeyReq, O: GetPassKeyResp }
]);
