// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "gaw/asset/wallet/v1/wallet_session.proto" (package "gaw.asset.wallet.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message gaw.asset.wallet.v1.SessionError
 */
export interface SessionError {
    /**
     * @generated from protobuf field: int64 code = 1;
     */
    code: number;
    /**
     * @generated from protobuf field: string message = 2;
     */
    message: string;
    /**
     * @generated from protobuf field: string data = 3;
     */
    data: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.SessionResponseBody
 */
export interface SessionResponseBody {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * string jsonrpc = 2;
     *
     * @generated from protobuf field: gaw.asset.wallet.v1.SessionError error = 3;
     */
    error?: SessionError;
    /**
     * @generated from protobuf field: string result = 4;
     */
    result: string; // 或许是不同类型？ 交易返回的是transaction_hash
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.SessionResponseBody.TransactionResult resultList = 5;
     */
    resultList: SessionResponseBody_TransactionResult[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.SessionResponseBody.TransactionResult
 */
export interface SessionResponseBody_TransactionResult {
    /**
     * @generated from protobuf field: string tag = 1;
     */
    tag: string;
    /**
     * @generated from protobuf field: string hash = 2;
     */
    hash: string;
    /**
     * @generated from protobuf field: string from = 5;
     */
    from: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.SessionError error = 3;
     */
    error?: SessionError;
    /**
     * @generated from protobuf field: string result = 4;
     */
    result: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.SessionResponse
 */
export interface SessionResponse {
    /**
     * 区分不同请求
     *
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * topic 区分不同的连接
     *
     * @generated from protobuf field: string topic = 2;
     */
    topic: string;
    /**
     * json 格式，上面的 ResponseBody 对应 wallet connect 的 JsonRpcResponse 结构
     *
     * @generated from protobuf field: string responseJson = 5;
     */
    responseJson: string;
}
// 下面定义给跟 gaw 通信用

/**
 * @generated from protobuf message gaw.asset.wallet.v1.SendEthTransactionParams
 */
export interface SendEthTransactionParams {
    /**
     * @generated from protobuf field: string from = 1;
     */
    from: string;
    /**
     * @generated from protobuf field: string to = 2;
     */
    to: string;
    /**
     * @generated from protobuf field: string data = 3;
     */
    data: string;
    /**
     * @generated from protobuf field: string value = 4;
     */
    value: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Request
 */
export interface Request {
    /**
     * @generated from protobuf field: string method = 1;
     */
    method: string;
    /**
     * 不建议使用
     *
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.SendEthTransactionParams params = 2;
     */
    params: SendEthTransactionParams[];
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.Request.TransactionParam transactionParamList = 3;
     */
    transactionParamList: Request_TransactionParam[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Request.TransactionParam
 */
export interface Request_TransactionParam {
    /**
     * @generated from protobuf field: string tag = 1;
     */
    tag: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.SendEthTransactionParams params = 2;
     */
    params?: SendEthTransactionParams;
    /**
     * @generated from protobuf field: string metadata = 3;
     */
    metadata: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Params
 */
export interface Params {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Request request = 1;
     */
    request?: Request;
    /**
     * @generated from protobuf field: string chainId = 2;
     */
    chainId: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Verified
 */
export interface Verified {
    /**
     * @generated from protobuf field: string verifyUrl = 1;
     */
    verifyUrl: string;
    /**
     * @generated from protobuf field: string validation = 2;
     */
    validation: string;
    /**
     * @generated from protobuf field: string origin = 3;
     */
    origin: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.VerifyContext
 */
export interface VerifyContext {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Verified verified = 1;
     */
    verified?: Verified;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.EventArguments
 */
export interface EventArguments {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string topic = 2;
     */
    topic: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Params params = 3;
     */
    params?: Params;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.VerifyContext verifyContext = 4;
     */
    verifyContext?: VerifyContext;
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.SessionErrorType
 */
export enum SessionErrorType {
    /**
     * @generated from protobuf enum value: OK = 0;
     */
    OK = 0,
    /**
     * 10 (Internal)
     *
     * @generated from protobuf enum value: NON_CONFORMING_NAMESPACES = 9;
     */
    NON_CONFORMING_NAMESPACES = 9,
    /**
     * 1000 (Internal)
     *
     * @generated from protobuf enum value: MISSING_OR_INVALID = 1000;
     */
    MISSING_OR_INVALID = 1000,
    /**
     * @generated from protobuf enum value: MISSING_RESPONSE = 1001;
     */
    MISSING_RESPONSE = 1001,
    /**
     * @generated from protobuf enum value: MISSING_DECRYPT_PARAMS = 1002;
     */
    MISSING_DECRYPT_PARAMS = 1002,
    /**
     * @generated from protobuf enum value: INVALID_UPDATE_REQUEST = 1003;
     */
    INVALID_UPDATE_REQUEST = 1003,
    /**
     * @generated from protobuf enum value: INVALID_UPGRADE_REQUEST = 1004;
     */
    INVALID_UPGRADE_REQUEST = 1004,
    /**
     * @generated from protobuf enum value: INVALID_EXTEND_REQUEST = 1005;
     */
    INVALID_EXTEND_REQUEST = 1005,
    /**
     * @generated from protobuf enum value: INVALID_STORAGE_KEY_NAME = 1020;
     */
    INVALID_STORAGE_KEY_NAME = 1020,
    /**
     * @generated from protobuf enum value: RECORD_ALREADY_EXISTS = 1100;
     */
    RECORD_ALREADY_EXISTS = 1100,
    /**
     * @generated from protobuf enum value: RESTORE_WILL_OVERRIDE = 1200;
     */
    RESTORE_WILL_OVERRIDE = 1200,
    /**
     * @generated from protobuf enum value: NO_MATCHING_ID = 1300;
     */
    NO_MATCHING_ID = 1300,
    /**
     * @generated from protobuf enum value: NO_MATCHING_TOPIC = 1301;
     */
    NO_MATCHING_TOPIC = 1301,
    /**
     * @generated from protobuf enum value: NO_MATCHING_RESPONSE = 1302;
     */
    NO_MATCHING_RESPONSE = 1302,
    /**
     * @generated from protobuf enum value: NO_MATCHING_KEY = 1303;
     */
    NO_MATCHING_KEY = 1303,
    /**
     * @generated from protobuf enum value: UNKNOWN_JSONRPC_METHOD = 1400;
     */
    UNKNOWN_JSONRPC_METHOD = 1400,
    /**
     * @generated from protobuf enum value: MISMATCHED_TOPIC = 1500;
     */
    MISMATCHED_TOPIC = 1500,
    /**
     * @generated from protobuf enum value: MISMATCHED_ACCOUNTS = 1501;
     */
    MISMATCHED_ACCOUNTS = 1501,
    /**
     * @generated from protobuf enum value: SETTLED = 1600;
     */
    SETTLED = 1600,
    /**
     * @generated from protobuf enum value: NOT_APPROVED = 1601;
     */
    NOT_APPROVED = 1601,
    /**
     * @generated from protobuf enum value: PROPOSAL_RESPONDED = 1602;
     */
    PROPOSAL_RESPONDED = 1602,
    /**
     * @generated from protobuf enum value: RESPONSE_ACKNOWLEDGED = 1603;
     */
    RESPONSE_ACKNOWLEDGED = 1603,
    /**
     * @generated from protobuf enum value: EXPIRED = 1604;
     */
    EXPIRED = 1604,
    /**
     * @generated from protobuf enum value: DELETED = 1605;
     */
    DELETED = 1605,
    /**
     * @generated from protobuf enum value: RESUBSCRIBED = 1606;
     */
    RESUBSCRIBED = 1606,
    /**
     * @generated from protobuf enum value: NOT_INITIALIZED = 1607;
     */
    NOT_INITIALIZED = 1607,
    /**
     * 2000 (Timeout)
     *
     * @generated from protobuf enum value: SETTLE_TIMEOUT = 2000;
     */
    SETTLE_TIMEOUT = 2000,
    /**
     * @generated from protobuf enum value: JSONRPC_REQUEST_TIMEOUT = 2001;
     */
    JSONRPC_REQUEST_TIMEOUT = 2001,
    /**
     * 3000 (Unauthorized)
     *
     * @generated from protobuf enum value: UNAUTHORIZED_TARGET_CHAIN = 3000;
     */
    UNAUTHORIZED_TARGET_CHAIN = 3000,
    /**
     * @generated from protobuf enum value: UNAUTHORIZED_JSON_RPC_METHOD = 3001;
     */
    UNAUTHORIZED_JSON_RPC_METHOD = 3001,
    /**
     * @generated from protobuf enum value: UNAUTHORIZED_NOTIFICATION_TYPE = 3002;
     */
    UNAUTHORIZED_NOTIFICATION_TYPE = 3002,
    /**
     * @generated from protobuf enum value: UNAUTHORIZED_UPDATE_REQUEST = 3003;
     */
    UNAUTHORIZED_UPDATE_REQUEST = 3003,
    /**
     * @generated from protobuf enum value: UNAUTHORIZED_UPGRADE_REQUEST = 3004;
     */
    UNAUTHORIZED_UPGRADE_REQUEST = 3004,
    /**
     * @generated from protobuf enum value: UNAUTHORIZED_EXTEND_REQUEST = 3005;
     */
    UNAUTHORIZED_EXTEND_REQUEST = 3005,
    /**
     * @generated from protobuf enum value: UNAUTHORIZED_MATCHING_CONTROLLER = 3100;
     */
    UNAUTHORIZED_MATCHING_CONTROLLER = 3100,
    /**
     * @generated from protobuf enum value: UNAUTHORIZED_METHOD = 3101;
     */
    UNAUTHORIZED_METHOD = 3101,
    /**
     * 4000 (EIP-1193)
     *
     * @generated from protobuf enum value: JSONRPC_REQUEST_METHOD_REJECTED = 4001;
     */
    JSONRPC_REQUEST_METHOD_REJECTED = 4001,
    /**
     * @generated from protobuf enum value: JSONRPC_REQUEST_METHOD_UNAUTHORIZED = 4100;
     */
    JSONRPC_REQUEST_METHOD_UNAUTHORIZED = 4100,
    /**
     * @generated from protobuf enum value: JSONRPC_REQUEST_METHOD_UNSUPPORTED = 4200;
     */
    JSONRPC_REQUEST_METHOD_UNSUPPORTED = 4200,
    /**
     * @generated from protobuf enum value: DISCONNECTED_ALL_CHAINS = 4900;
     */
    DISCONNECTED_ALL_CHAINS = 4900,
    /**
     * @generated from protobuf enum value: DISCONNECTED_TARGET_CHAIN = 4901;
     */
    DISCONNECTED_TARGET_CHAIN = 4901,
    /**
     * 5000 (CAIP-25)
     *
     * @generated from protobuf enum value: DISAPPROVED_CHAINS = 5000;
     */
    DISAPPROVED_CHAINS = 5000,
    /**
     * @generated from protobuf enum value: DISAPPROVED_JSONRPC = 5001;
     */
    DISAPPROVED_JSONRPC = 5001,
    /**
     * @generated from protobuf enum value: DISAPPROVED_NOTIFICATION = 5002;
     */
    DISAPPROVED_NOTIFICATION = 5002,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_CHAINS = 5100;
     */
    UNSUPPORTED_CHAINS = 5100,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_JSONRPC = 5101;
     */
    UNSUPPORTED_JSONRPC = 5101,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_NOTIFICATION = 5102;
     */
    UNSUPPORTED_NOTIFICATION = 5102,
    /**
     * @generated from protobuf enum value: UNSUPPORTED_ACCOUNTS = 5103;
     */
    UNSUPPORTED_ACCOUNTS = 5103,
    /**
     * @generated from protobuf enum value: USER_DISCONNECTED = 5900;
     */
    USER_DISCONNECTED = 5900,
    /**
     * 9000 (Unknown)
     *
     * @generated from protobuf enum value: UNKNOWN = 9000;
     */
    UNKNOWN = 9000
}
// @generated message type with reflection information, may provide speed optimized methods
class SessionError$Type extends MessageType<SessionError> {
    constructor() {
        super("gaw.asset.wallet.v1.SessionError", [
            { no: 1, name: "code", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SessionError>): SessionError {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.message = "";
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<SessionError>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionError): SessionError {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 code */ 1:
                    message.code = reader.int64().toNumber();
                    break;
                case /* string message */ 2:
                    message.message = reader.string();
                    break;
                case /* string data */ 3:
                    message.data = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionError, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int64(message.code);
        /* string message = 2; */
        if (message.message !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.message);
        /* string data = 3; */
        if (message.data !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SessionError
 */
export const SessionError = new SessionError$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionResponseBody$Type extends MessageType<SessionResponseBody> {
    constructor() {
        super("gaw.asset.wallet.v1.SessionResponseBody", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "error", kind: "message", T: () => SessionError },
            { no: 4, name: "result", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "resultList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SessionResponseBody_TransactionResult }
        ]);
    }
    create(value?: PartialMessage<SessionResponseBody>): SessionResponseBody {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.result = "";
        message.resultList = [];
        if (value !== undefined)
            reflectionMergePartial<SessionResponseBody>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionResponseBody): SessionResponseBody {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* gaw.asset.wallet.v1.SessionError error */ 3:
                    message.error = SessionError.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                case /* string result */ 4:
                    message.result = reader.string();
                    break;
                case /* repeated gaw.asset.wallet.v1.SessionResponseBody.TransactionResult resultList */ 5:
                    message.resultList.push(SessionResponseBody_TransactionResult.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionResponseBody, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* gaw.asset.wallet.v1.SessionError error = 3; */
        if (message.error)
            SessionError.internalBinaryWrite(message.error, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string result = 4; */
        if (message.result !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.result);
        /* repeated gaw.asset.wallet.v1.SessionResponseBody.TransactionResult resultList = 5; */
        for (let i = 0; i < message.resultList.length; i++)
            SessionResponseBody_TransactionResult.internalBinaryWrite(message.resultList[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SessionResponseBody
 */
export const SessionResponseBody = new SessionResponseBody$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionResponseBody_TransactionResult$Type extends MessageType<SessionResponseBody_TransactionResult> {
    constructor() {
        super("gaw.asset.wallet.v1.SessionResponseBody.TransactionResult", [
            { no: 1, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "error", kind: "message", T: () => SessionError },
            { no: 4, name: "result", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SessionResponseBody_TransactionResult>): SessionResponseBody_TransactionResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tag = "";
        message.hash = "";
        message.from = "";
        message.result = "";
        if (value !== undefined)
            reflectionMergePartial<SessionResponseBody_TransactionResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionResponseBody_TransactionResult): SessionResponseBody_TransactionResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tag */ 1:
                    message.tag = reader.string();
                    break;
                case /* string hash */ 2:
                    message.hash = reader.string();
                    break;
                case /* string from */ 5:
                    message.from = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.SessionError error */ 3:
                    message.error = SessionError.internalBinaryRead(reader, reader.uint32(), options, message.error);
                    break;
                case /* string result */ 4:
                    message.result = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionResponseBody_TransactionResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tag = 1; */
        if (message.tag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tag);
        /* string hash = 2; */
        if (message.hash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.hash);
        /* string from = 5; */
        if (message.from !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.from);
        /* gaw.asset.wallet.v1.SessionError error = 3; */
        if (message.error)
            SessionError.internalBinaryWrite(message.error, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string result = 4; */
        if (message.result !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.result);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SessionResponseBody.TransactionResult
 */
export const SessionResponseBody_TransactionResult = new SessionResponseBody_TransactionResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SessionResponse$Type extends MessageType<SessionResponse> {
    constructor() {
        super("gaw.asset.wallet.v1.SessionResponse", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "topic", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "responseJson", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SessionResponse>): SessionResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.topic = "";
        message.responseJson = "";
        if (value !== undefined)
            reflectionMergePartial<SessionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SessionResponse): SessionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string topic */ 2:
                    message.topic = reader.string();
                    break;
                case /* string responseJson */ 5:
                    message.responseJson = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SessionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string topic = 2; */
        if (message.topic !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.topic);
        /* string responseJson = 5; */
        if (message.responseJson !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.responseJson);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SessionResponse
 */
export const SessionResponse = new SessionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendEthTransactionParams$Type extends MessageType<SendEthTransactionParams> {
    constructor() {
        super("gaw.asset.wallet.v1.SendEthTransactionParams", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendEthTransactionParams>): SendEthTransactionParams {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.from = "";
        message.to = "";
        message.data = "";
        message.value = "";
        if (value !== undefined)
            reflectionMergePartial<SendEthTransactionParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendEthTransactionParams): SendEthTransactionParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                case /* string data */ 3:
                    message.data = reader.string();
                    break;
                case /* string value */ 4:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendEthTransactionParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        /* string data = 3; */
        if (message.data !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.data);
        /* string value = 4; */
        if (message.value !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SendEthTransactionParams
 */
export const SendEthTransactionParams = new SendEthTransactionParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Request$Type extends MessageType<Request> {
    constructor() {
        super("gaw.asset.wallet.v1.Request", [
            { no: 1, name: "method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "params", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SendEthTransactionParams },
            { no: 3, name: "transactionParamList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Request_TransactionParam }
        ]);
    }
    create(value?: PartialMessage<Request>): Request {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.method = "";
        message.params = [];
        message.transactionParamList = [];
        if (value !== undefined)
            reflectionMergePartial<Request>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Request): Request {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string method */ 1:
                    message.method = reader.string();
                    break;
                case /* repeated gaw.asset.wallet.v1.SendEthTransactionParams params */ 2:
                    message.params.push(SendEthTransactionParams.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated gaw.asset.wallet.v1.Request.TransactionParam transactionParamList */ 3:
                    message.transactionParamList.push(Request_TransactionParam.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Request, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string method = 1; */
        if (message.method !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.method);
        /* repeated gaw.asset.wallet.v1.SendEthTransactionParams params = 2; */
        for (let i = 0; i < message.params.length; i++)
            SendEthTransactionParams.internalBinaryWrite(message.params[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated gaw.asset.wallet.v1.Request.TransactionParam transactionParamList = 3; */
        for (let i = 0; i < message.transactionParamList.length; i++)
            Request_TransactionParam.internalBinaryWrite(message.transactionParamList[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Request
 */
export const Request = new Request$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Request_TransactionParam$Type extends MessageType<Request_TransactionParam> {
    constructor() {
        super("gaw.asset.wallet.v1.Request.TransactionParam", [
            { no: 1, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "params", kind: "message", T: () => SendEthTransactionParams },
            { no: 3, name: "metadata", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Request_TransactionParam>): Request_TransactionParam {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tag = "";
        message.metadata = "";
        if (value !== undefined)
            reflectionMergePartial<Request_TransactionParam>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Request_TransactionParam): Request_TransactionParam {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tag */ 1:
                    message.tag = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.SendEthTransactionParams params */ 2:
                    message.params = SendEthTransactionParams.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                case /* string metadata */ 3:
                    message.metadata = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Request_TransactionParam, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tag = 1; */
        if (message.tag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tag);
        /* gaw.asset.wallet.v1.SendEthTransactionParams params = 2; */
        if (message.params)
            SendEthTransactionParams.internalBinaryWrite(message.params, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string metadata = 3; */
        if (message.metadata !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.metadata);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Request.TransactionParam
 */
export const Request_TransactionParam = new Request_TransactionParam$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Params$Type extends MessageType<Params> {
    constructor() {
        super("gaw.asset.wallet.v1.Params", [
            { no: 1, name: "request", kind: "message", T: () => Request },
            { no: 2, name: "chainId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Params>): Params {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainId = "";
        if (value !== undefined)
            reflectionMergePartial<Params>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Params): Params {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Request request */ 1:
                    message.request = Request.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                case /* string chainId */ 2:
                    message.chainId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Params, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Request request = 1; */
        if (message.request)
            Request.internalBinaryWrite(message.request, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string chainId = 2; */
        if (message.chainId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.chainId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Params
 */
export const Params = new Params$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Verified$Type extends MessageType<Verified> {
    constructor() {
        super("gaw.asset.wallet.v1.Verified", [
            { no: 1, name: "verifyUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "validation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Verified>): Verified {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.verifyUrl = "";
        message.validation = "";
        message.origin = "";
        if (value !== undefined)
            reflectionMergePartial<Verified>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Verified): Verified {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string verifyUrl */ 1:
                    message.verifyUrl = reader.string();
                    break;
                case /* string validation */ 2:
                    message.validation = reader.string();
                    break;
                case /* string origin */ 3:
                    message.origin = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Verified, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string verifyUrl = 1; */
        if (message.verifyUrl !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.verifyUrl);
        /* string validation = 2; */
        if (message.validation !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.validation);
        /* string origin = 3; */
        if (message.origin !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.origin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Verified
 */
export const Verified = new Verified$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VerifyContext$Type extends MessageType<VerifyContext> {
    constructor() {
        super("gaw.asset.wallet.v1.VerifyContext", [
            { no: 1, name: "verified", kind: "message", T: () => Verified }
        ]);
    }
    create(value?: PartialMessage<VerifyContext>): VerifyContext {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<VerifyContext>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VerifyContext): VerifyContext {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Verified verified */ 1:
                    message.verified = Verified.internalBinaryRead(reader, reader.uint32(), options, message.verified);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VerifyContext, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Verified verified = 1; */
        if (message.verified)
            Verified.internalBinaryWrite(message.verified, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.VerifyContext
 */
export const VerifyContext = new VerifyContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventArguments$Type extends MessageType<EventArguments> {
    constructor() {
        super("gaw.asset.wallet.v1.EventArguments", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "topic", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "params", kind: "message", T: () => Params },
            { no: 4, name: "verifyContext", kind: "message", T: () => VerifyContext }
        ]);
    }
    create(value?: PartialMessage<EventArguments>): EventArguments {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.topic = "";
        if (value !== undefined)
            reflectionMergePartial<EventArguments>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventArguments): EventArguments {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string topic */ 2:
                    message.topic = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.Params params */ 3:
                    message.params = Params.internalBinaryRead(reader, reader.uint32(), options, message.params);
                    break;
                case /* gaw.asset.wallet.v1.VerifyContext verifyContext */ 4:
                    message.verifyContext = VerifyContext.internalBinaryRead(reader, reader.uint32(), options, message.verifyContext);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventArguments, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string topic = 2; */
        if (message.topic !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.topic);
        /* gaw.asset.wallet.v1.Params params = 3; */
        if (message.params)
            Params.internalBinaryWrite(message.params, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.VerifyContext verifyContext = 4; */
        if (message.verifyContext)
            VerifyContext.internalBinaryWrite(message.verifyContext, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.EventArguments
 */
export const EventArguments = new EventArguments$Type();
