import { isNaN, isNil, isNumber } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

export function formatNumWithUnit(num: number) {
  if (!isNumber(num) || isNaN(num)) {
    return '';
  }

  let str = '';
  switch (true) {
    case num >= 1000000000000000:
      let pValue = num / 1000000000000000;
      if (num < 100000000000000000) {
        pValue = Math.floor(pValue * 100) / 100;
        str = pValue.toString() + 'P';
      } else {
        pValue = Math.floor(pValue * 10) / 10;
        str = pValue.toString() + 'P';
      }
      break;
    case num >= 1000000000000:
      let tValue = num / 1000000000000;
      if (num < 100000000000000) {
        tValue = Math.floor(tValue * 100) / 100;
        str = tValue.toString() + 'T';
      } else {
        tValue = Math.floor(tValue * 10) / 10;
        str = tValue.toString() + 'T';
      }
      break;
    case num >= 1000000000:
      let bValue = num / 1000000000;
      if (num < 100000000000) {
        bValue = Math.floor(bValue * 100) / 100;
        str = bValue.toString() + 'B';
      } else {
        bValue = Math.floor(bValue * 10) / 10;
        str = bValue.toString() + 'B';
      }
      break;
    case num >= 1000000:
      let mValue = num / 1000000;
      if (num < 100000000) {
        mValue = Math.floor(mValue * 100) / 100;
        str = mValue.toString() + 'M';
      } else {
        mValue = Math.floor(mValue * 10) / 10;
        str = mValue.toString() + 'M';
      }
      break;
    case num >= 1000:
      let kValue = num / 1000;
      if (num < 100000) {
        kValue = Math.floor(kValue * 100) / 100;
        str = kValue.toString() + 'K';
      } else {
        kValue = Math.floor(kValue * 10) / 10;
        str = kValue.toString() + 'K';
      }
      break;
    case num >= 0:
      str = num.toString();
      break;
    default:
      str = '-' + formatNumWithUnit(Math.abs(num));
      break;
  }
  return str;
}

/**
 * 格式化货币/金钱
 * @param num 需要转换千分位的数字
 * @param decimals 小数点 位数（默认：0，不保留小数点）
 * @returns
 */
export const formatNumWithComma = (num: number, decimals = 0) => {
  if (isNil(num) || isNaN(num)) return num;
  const options = {
    style: 'decimal',
    maximumFractionDigits: decimals,
  } as Intl.NumberFormatOptions;
  return num.toLocaleString('en-US', options);
};

/**
 * 格式化数字 (适用于筹码。若金钱、货币可能有问题)
 * @description 小于1000的不做任何处理，小于100000的添加千分位，大于100000的转换单位(P/T/B/M/K)
 * @param chip 需要转换 筹码(注意：筹码是正整数，是没有小数点的。货币可能带有小数点)
 * @returns
 */
export const formatChipNum = (
  chip: number | undefined | string,
  decimals = 0,
) => {
  if (isNil(chip) || isNaN(chip)) return '';
  chip = Number(chip);
  const negative = chip < 0;
  const absolute = Math.abs(chip);
  let str = '';
  if (absolute < 1000) {
    str = absolute.toString();
    if (/e-(\d+)/.test(str)) {
      str = str.replace(/([\d\.]+)e-(\d+)/, (_, $1, $2) => {
        return '0.' + '0'.repeat(Number($2) - 1) + $1.replace(/\./, '');
      });
    }
  } else if (absolute < 100000) {
    str = formatNumWithComma(absolute, decimals) as string;
  } else {
    str = formatNumWithUnit(absolute);
  }
  if (negative) {
    str = '-' + str;
  }

  if (absolute < 100000) {
    str = str.substring(0, 11);
    while (/\./.test(str) && /[0\.]$/.test(str)) {
      str = str.substring(0, str.length - 1);
    }
  }
  return str;
};

/**
 * 格式化货币/金钱
 * @param num 金额/钱（例子：33 / 30.1 / 3621 ）
 * @returns 返回带千分位的金额字符串 （例子: 33 / 30.1 / 3,621 ）
 */
export const formatCoinNum = (num: number) => {
  return num.toLocaleString();
};

export const byteBufferToUTF8String = (arr: Uint8Array) => {
  if (!arr) {
    return '';
  }
  let UTF = '';
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] == null) {
      break;
    }
    const one = arr[i].toString(2);
    const v = one.match(/^1+?(?=0)/);
    if (v && one.length == 8) {
      const bytesLength = v[0].length;
      let store = arr[i].toString(2).slice(7 - bytesLength);

      for (let st = 1; st < bytesLength; st++) {
        store += arr[st + i].toString(2).slice(2);
      }
      UTF += String.fromCharCode(parseInt(store, 2));
      i += bytesLength - 1;
    } else {
      UTF += String.fromCharCode(arr[i]);
    }
  }
  return UTF;
};

/** 钱包地址脱敏 */
export const desensitizeWalletPwd = (pwd: string, len: number = 6) => {
  if (!pwd) return '';
  const reg = new RegExp(`\(\\w{${len}}\)\\w*\(\\w{${len}}\)`);
  return pwd.replace(reg, '$1...$2');
};

/**
 * 获取允许设置长度的字符串
 * @param str 需要检测的字符串
 * @param allowLength 允许长度的字符串
 * @param chineseCharLen 一个中文字符实际长度（默认2个字符长度）
 */
export const getAllowLengthStr = (
  textStr: string,
  allowLength: number,
  chineseCharLen: number = 2,
) => {
  const str = textStr || '';
  let len = 0;
  for (let index = 0; index < str.length; index++) {
    const char = str.charAt(index);
    if (char.match(/[^\x00-\xff]/gi) != null) {
      len += chineseCharLen;
    } else {
      len += 1;
    }
    if (len > allowLength) {
      return str.slice(0, index);
    } else if (len === allowLength) {
      return str.slice(0, index + 1);
    }
  }
  return str;
};

/**
 * 获取字符串的字节数
 * @param str 字符串
 * @param chineseCharLen 一个汉字占用多少字节，默认一个汉字2占两字节
 */
export const getStrLength = (
  textStr: any,
  chineseCharLen: number = 2,
): number => {
  const str = textStr || '';
  let len = 0;
  for (let index = 0; index < str.length; index++) {
    const char = str.charAt(index);
    if (char.match(/[^\x00-\xff]/gi) != null) {
      len += chineseCharLen;
    } else {
      len += 1;
    }
  }
  return len;
};

/**
 * 获取数字指定小数位数的字符串
 * @param num 数字
 * @param decimal 小数位数
 */
export function formatNumberWithSuffix(num: number, decimalPlaces: number = 1) {
  if (isNil(num) || isNaN(num)) return '';

  const suffixes = ['', 'K', 'M', 'B', 'T', 'P'];
  let suffixIndex = 0;
  while (num >= 1000 && suffixIndex < suffixes.length - 1) {
    num /= 1000;
    suffixIndex++;
  }
  const multiplier = Math.pow(10, decimalPlaces);
  num = Math.floor(num * multiplier) / multiplier;

  return num + suffixes[suffixIndex];
}

/**
 * 根据筹码和大小盲计算BB数量
 * @param bigBlind 数字
 * @param chip 小数位数
 * @param isSeparator 是否需要分隔符
 */
export const countChipBB = (
  chip: number | undefined | null,
  bigBlind: number | undefined | null,
) => {
  if (isNil(bigBlind) || isNaN(bigBlind) || isNil(chip) || isNaN(chip)) {
    return '-';
  }

  return formatNumberWithSuffix(chip / bigBlind, 1) + ' BB';
};

/**
 * 获取数字的百分比，保留指定的小数位数（不四舍五入）
 * @param {number} number - 数字
 * @param {number} decimalPlaces - 保留的小数位数
 * @returns {string} - 百分比形式的字符串
 */
export function formatPercentage(number: number, decimalPlaces: number) {
  const percentage = number * 100;
  const factor = Math.pow(10, decimalPlaces);
  const truncatedPercentage = Math.floor(percentage * factor) / factor;

  // 转换为字符串并查找小数点位置
  let percentageStr = truncatedPercentage.toString();
  const dotIndex = percentageStr.indexOf('.');

  if (dotIndex !== -1) {
    // 去除小数点后的多余零
    while (percentageStr.endsWith('0')) {
      percentageStr = percentageStr.slice(0, -1);
    }
    // 如果小数点在末尾，也去掉它
    if (percentageStr.endsWith('.')) {
      percentageStr = percentageStr.slice(0, -1);
    }
  }

  return percentageStr + '%';
}

/**
 * 给某个css添加前缀，用于react组件
 * @param {number} property - 属性名
 * @param {number} value - 属性值
 */
export function setPrefixedStyle(property: string = '', value: string = '') {
  const prefixes = ['Webkit', 'Moz', 'Ms', 'O'];
  // 设置标准样式
  const style: { [key: string]: string } = {
    [property]: value,
  };

  // 遍历前缀数组，设置带前缀的样式
  prefixes.forEach((prefix) => {
    style[
      `${prefix}${property.slice(0, 1).toLocaleUpperCase()}${property.slice(1)}`
    ] = value;
  });

  return style;
}

/**
 * 生成唯一的ID
 * @returns
 */
export const createUnique = () => {
  return uuidv4();
};
