// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/common/common_define.proto" (package "bpt.common", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.common.Range
 */
export interface Range {
    /**
     * @generated from protobuf field: int64 start = 1;
     */
    start: number;
    /**
     * @generated from protobuf field: int64 end = 2;
     */
    end: number;
}
/**
 * @generated from protobuf message bpt.common.Metadata
 */
export interface Metadata {
    /**
     * @generated from protobuf field: string key = 1;
     */
    key: string; // 键
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string; // 值
    /**
     * @generated from protobuf field: string desc = 3;
     */
    desc: string; // 描述
}
/**
 * notice： 时间戳 9999999999 为永久时间
 *
 * @generated from protobuf message bpt.common.ExpireData
 */
export interface ExpireData {
    /**
     * @generated from protobuf field: bpt.common.ExpireType type = 1;
     */
    type: ExpireType; // [(syncproto.checksum) = true];  // 过期类型
    /**
     * @generated from protobuf field: int64 value = 2;
     */
    value: number; // [(syncproto.checksum) = true];  // 值
}
/**
 * deprecated
 *
 * @generated from protobuf message bpt.common.PropItem
 */
export interface PropItem {
    /**
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number; // 道具ID
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // 道具名字
    /**
     * @generated from protobuf field: string img = 3;
     */
    img: string; // 底图
    /**
     * @generated from protobuf field: int32 num = 4;
     */
    num: number; // 数量
    /**
     * @generated from protobuf field: int64 typeID = 5;
     */
    typeID: number;
    /**
     * @generated from protobuf field: bpt.common.ExpireData expire = 6;
     */
    expire?: ExpireData; // 过期数据
    /**
     * @generated from protobuf field: string desc = 7;
     */
    desc: string; // 描述
    /**
     * @generated from protobuf field: bpt.common.ItemType itemType = 8;
     */
    itemType: ItemType; // 元素类型
    /**
     * @generated from protobuf field: string icon = 9;
     */
    icon: string; // icon
}
/**
 * @generated from protobuf message bpt.common.VIPLevelReward
 */
export interface VIPLevelReward {
    /**
     * @generated from protobuf field: int32 level = 1;
     */
    level: number; // 等级
    /**
     * @generated from protobuf field: int32 multiple = 2;
     */
    multiple: number; // 倍数
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string; // 等级名称
}
/**
 * SysMailBody 系统邮件
 *
 * @generated from protobuf message bpt.common.SysMailBody
 */
export interface SysMailBody {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string; // [(syncproto.checksum) = true];  // 邮件title
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string; // [(syncproto.checksum) = true];  // 邮件内容
    /**
     * @generated from protobuf field: bpt.common.ExpireData expire = 3;
     */
    expire?: ExpireData; // ; //[(gogoproto.nullable) = false, (syncproto.checksum) = true];
    /**
     * @generated from protobuf field: string reason = 4;
     */
    reason: string; // [(syncproto.checksum) = true];  //原因
    /**
     * @generated from protobuf field: int32 templateType = 5;
     */
    templateType: number; // [(syncproto.checksum) = true];  //模板类型(CMS 用于区分模板)
}
/**
 * AwardAccept 奖励领取
 *
 * @generated from protobuf message bpt.common.AwardAcceptConf
 */
export interface AwardAcceptConf {
    /**
     * @generated from protobuf field: bpt.common.AwardAcceptType awardAcceptType = 1;
     */
    awardAcceptType: AwardAcceptType; // [(syncproto.checksum) = true];  // 手动/自动
    /**
     * @generated from protobuf field: bpt.common.AwardAcceptDest awardAcceptDest = 2;
     */
    awardAcceptDest: AwardAcceptDest; // [(syncproto.checksum) = true];                          // 背包/邮件
    /**
     * @generated from protobuf field: bpt.common.SysMailBody sysMailBody = 5;
     */
    sysMailBody?: SysMailBody; // [(syncproto.checksum) = true];  // 邮件内容
    /**
     * 领取策略
     *
     * @generated from protobuf field: bpt.common.AwardAcceptStrategy awardAcceptStrategy = 6;
     */
    awardAcceptStrategy: AwardAcceptStrategy; // [(syncproto.checksum) = true];
    /**
     * 可选数量
     *
     * @generated from protobuf field: int64 selectNum = 7;
     */
    selectNum: number; // [(syncproto.checksum) = true];
}
/**
 * 货币
 *
 * @generated from protobuf message bpt.common.Money
 */
export interface Money {
    /**
     * 货币价值
     *
     * @generated from protobuf field: int64 amount = 1;
     */
    amount: number;
    /**
     * 货币单位 ”x" eg. CNY(元)
     *
     * @generated from protobuf field: string currency = 2;
     */
    currency: string;
    /**
     * 当前amount 精度；1000 表示3精度， 100 表示2位精度，
     * 例如：amount:100 currency: CNY decimal 100 表示一元，精度2位，可以表示到分
     *
     * @generated from protobuf field: int64 decimal = 3;
     */
    decimal: number;
}
/**
 * 资产兑换汇率
 *
 * @generated from protobuf message bpt.common.AssetExchangeRate
 */
export interface AssetExchangeRate {
    /**
     * 兑换数量
     *
     * @generated from protobuf field: int64 amount = 1;
     */
    amount: number;
    /**
     * 货币价值
     *
     * @generated from protobuf field: bpt.common.Money money = 2;
     */
    money?: Money; // [(gogoproto.nullable) = false];
}
/**
 * @generated from protobuf message bpt.common.Prop
 */
export interface Prop {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int64 amount = 2;
     */
    amount: number;
    /**
     * @generated from protobuf field: bpt.common.PropType type = 3;
     */
    type: PropType;
    /**
     * @generated from protobuf field: string img = 4;
     */
    img: string;
    /**
     * @generated from protobuf field: string icon = 5;
     */
    icon: string;
    /**
     * @generated from protobuf field: string desc = 6;
     */
    desc: string;
    /**
     * @generated from protobuf field: bpt.common.ExpireData expire = 7;
     */
    expire?: ExpireData; // [(gogoproto.nullable) = false];
}
/**
 * @generated from protobuf message bpt.common.AssetItem
 */
export interface AssetItem {
    /**
     * 资产ID
     *
     * @generated from protobuf field: int64 id = 1;
     */
    id: number; // [(syncproto.checksum) = true];
    /**
     * 资产额度
     *
     * @generated from protobuf field: int64 amount = 2;
     */
    amount: number; // [(syncproto.checksum) = true];
    /**
     * @generated from protobuf field: string img = 4;
     */
    img: string;
    /**
     * @generated from protobuf field: string icon = 5;
     */
    icon: string;
    /**
     * @generated from protobuf field: string desc = 6;
     */
    desc: string;
    /**
     * 过期时间
     *
     * @generated from protobuf field: bpt.common.ExpireData expire = 7;
     */
    expire?: ExpireData; // [(gogoproto.nullable) = false, (syncproto.checksum) = true];
    /**
     * @generated from protobuf field: string name = 8;
     */
    name: string;
    /**
     * 资产类型
     *
     * @generated from protobuf field: bpt.common.AssetType type = 9;
     */
    type: AssetType; // [(syncproto.checksum) = true];
    /**
     * 资产额外元数据信息，
     * 键值"sub_type"代表资产细分类型枚举，代表道具的propType，装扮的dressPart
     * 资产类型为道具且sub_type为5000（gaw货币）时，键值"decimals"，"amount"有对应值，代表GAW钱包货币的精度和数值
     *
     * @generated from protobuf field: map<string, string> meta = 10;
     */
    meta: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string rawAmount = 11;
     */
    rawAmount: string; // [(syncproto.checksum) = true];
}
/**
 * @generated from protobuf message bpt.common.SimpleAsset
 */
export interface SimpleAsset {
    /**
     * 资产ID
     *
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string rawAmount = 2;
     */
    rawAmount: string;
}
/**
 * deprecated
 *
 * @generated from protobuf message bpt.common.Asset
 */
export interface Asset {
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem props = 1;
     */
    props: AssetItem[]; // [(gogoproto.nullable) = false];
    /**
     * @generated from protobuf field: bpt.common.AssetItem diamond = 2;
     */
    diamond?: AssetItem; // [(gogoproto.nullable) = false];
    /**
     * @generated from protobuf field: bpt.common.AssetItem coin = 3;
     */
    coin?: AssetItem; // [(gogoproto.nullable) = false];
    /**
     * @generated from protobuf field: bpt.common.AssetItem mung = 4;
     */
    mung?: AssetItem; // [(gogoproto.nullable) = false];
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem gift = 5;
     */
    gift: AssetItem[]; // [(gogoproto.nullable) = false];
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem dress = 6;
     */
    dress: AssetItem[]; // [(gogoproto.nullable) = false];
    /**
     * @generated from protobuf field: bpt.common.AssetItem honourPoint = 7;
     */
    honourPoint?: AssetItem; // [(gogoproto.nullable) = false];
}
/**
 * /////// 公共CMS key-value 配置定义//////////
 *
 * @generated from protobuf message bpt.common.Parameter
 */
export interface Parameter {
    /**
     * 配置的int value
     *
     * @generated from protobuf field: int64 intVal = 1;
     */
    intVal: number; // [(syncproto.checksum) = true];
    /**
     * 配值的string
     *
     * @generated from protobuf field: string strVal = 2;
     */
    strVal: string; // [(syncproto.checksum) = true];
    /**
     * 配置的bool 开关
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 3;
     */
    switch: SwitchState; // [(syncproto.checksum) = true];
    /**
     * 配置的资产
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem assetItems = 4;
     */
    assetItems: AssetItem[]; // [(syncproto.checksum) = true];
}
/**
 * AppConfig 服务cms key-value
 *
 * @generated from protobuf message bpt.common.AppConfig
 */
export interface AppConfig {
    /**
     * 配置key
     *
     * @generated from protobuf field: string key = 1;
     */
    key: string; // [(syncproto.checksum) = true];
    /**
     * 配置描述
     *
     * @generated from protobuf field: string desc = 2;
     */
    desc: string; // [(syncproto.checksum) = true];
    /**
     * 配置开关
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 3;
     */
    switch: SwitchState; // [(syncproto.checksum) = true];
    /**
     * 配值参数
     *
     * @generated from protobuf field: bpt.common.Parameter param = 4;
     */
    param?: Parameter; // [(syncproto.checksum) = true];
    /**
     * 操作人
     *
     * @generated from protobuf field: string operator = 5;
     */
    operator: string; // [(syncproto.checksum) = true];
    /**
     * 更新时间
     *
     * @generated from protobuf field: int64 updateAt = 6;
     */
    updateAt: number; // [(syncproto.checksum) = true];
}
/**
 * @generated from protobuf enum bpt.common.State
 */
export enum State {
    /**
     * @generated from protobuf enum value: StateUnknown = 0;
     */
    StateUnknown = 0,
    /**
     * 上线
     *
     * @generated from protobuf enum value: StateOnline = 1;
     */
    StateOnline = 1,
    /**
     * 下线
     *
     * @generated from protobuf enum value: StateOffline = 2;
     */
    StateOffline = 2,
    /**
     * 草稿
     *
     * @generated from protobuf enum value: StatePending = 3;
     */
    StatePending = 3
}
/**
 * @generated from protobuf enum bpt.common.OS
 */
export enum OS {
    /**
     * @generated from protobuf enum value: OSUnknown = 0;
     */
    OSUnknown = 0,
    /**
     * @generated from protobuf enum value: OSiOS = 1;
     */
    OSiOS = 1,
    /**
     * @generated from protobuf enum value: OSAndroid = 2;
     */
    OSAndroid = 2,
    /**
     * @generated from protobuf enum value: OSWin32 = 3;
     */
    OSWin32 = 3
}
/**
 * @generated from protobuf enum bpt.common.SysModules
 */
export enum SysModules {
    /**
     * @generated from protobuf enum value: SysModules_Unknown = 0;
     */
    SysModules_Unknown = 0,
    /**
     * @generated from protobuf enum value: SysModules_Prop = 1;
     */
    SysModules_Prop = 1,
    /**
     * @generated from protobuf enum value: SysModules_VIP = 2;
     */
    SysModules_VIP = 2
}
/**
 * @generated from protobuf enum bpt.common.ExpireType
 */
export enum ExpireType {
    /**
     * 未知
     *
     * @generated from protobuf enum value: ExpireTypeUnknown = 0;
     */
    ExpireTypeUnknown = 0,
    /**
     * 时间段（秒）
     *
     * @generated from protobuf enum value: ExpireTypeDuration = 1;
     */
    ExpireTypeDuration = 1,
    /**
     * 时间点
     *
     * @generated from protobuf enum value: ExpireTypeTime = 2;
     */
    ExpireTypeTime = 2
}
/**
 * deprecated
 *
 * @generated from protobuf enum bpt.common.ItemType
 */
export enum ItemType {
    /**
     * 未知
     *
     * @generated from protobuf enum value: ItemTypeUnknown = 0;
     */
    ItemTypeUnknown = 0,
    /**
     * 道具
     *
     * @generated from protobuf enum value: ItemTypeProp = 1;
     */
    ItemTypeProp = 1,
    /**
     * 绿豆
     *
     * @generated from protobuf enum value: ItemTypeMung = 2;
     */
    ItemTypeMung = 2,
    /**
     * 金币
     *
     * @generated from protobuf enum value: ItemTypeCoin = 3;
     */
    ItemTypeCoin = 3
}
/**
 * 变更标记， 加或减
 *
 * @generated from protobuf enum bpt.common.Sign
 */
export enum Sign {
    /**
     * @generated from protobuf enum value: SignUnknown = 0;
     */
    SignUnknown = 0,
    /**
     * 流入（增加）
     *
     * ; //[(kitproto.bigdata_tag) = "增加"];
     *
     * @generated from protobuf enum value: SignCredit = 1;
     */
    SignCredit = 1,
    /**
     * 流出 (减少)
     *
     * [(kitproto.bigdata_tag) = "减少"];
     *
     * @generated from protobuf enum value: SignDebit = 2;
     */
    SignDebit = 2
}
/**
 * 来源方式
 * 1 ~ 99 增加方式
 * 100 ~ 扣减方式
 *
 * @generated from protobuf enum bpt.common.Way
 */
export enum Way {
    /**
     * 增加方式
     *
     * ; //[(kitproto.bigdata_tag) = "未知"];
     *
     * @generated from protobuf enum value: WayNone = 0;
     */
    WayNone = 0,
    /**
     * 系统发放
     *
     * [(kitproto.bigdata_tag) = "系统发放"];
     *
     * @generated from protobuf enum value: WayIncSYS = 1;
     */
    WayIncSYS = 1,
    /**
     * 后台发放               （系统扣除）
     *
     * [(kitproto.bigdata_tag) = "后台发放"];
     *
     * @generated from protobuf enum value: WayIncCMS = 2;
     */
    WayIncCMS = 2,
    /**
     * sng比赛发放
     *
     * [(kitproto.bigdata_tag) = "sng比赛发放"];
     *
     * @generated from protobuf enum value: WayIncSngMatching = 3;
     */
    WayIncSngMatching = 3,
    /**
     * mtt比赛发放
     *
     * [(kitproto.bigdata_tag) = "mtt比赛发放"];
     *
     * @generated from protobuf enum value: WayIncMttMatching = 4;
     */
    WayIncMttMatching = 4,
    /**
     * 任务发放
     *
     * [(kitproto.bigdata_tag) = "任务发放"];
     *
     * @generated from protobuf enum value: WayIncTaskReward = 5;
     */
    WayIncTaskReward = 5,
    /**
     * 活动发放
     *
     * [(kitproto.bigdata_tag) = "活动发放"];
     *
     * @generated from protobuf enum value: WayIncActivity = 6;
     */
    WayIncActivity = 6,
    /**
     * 链上交易(增加)
     *
     * @generated from protobuf enum value: WayIncTxOnChain = 7;
     */
    WayIncTxOnChain = 7,
    /**
     * 跨链桥（增加）
     *
     * @generated from protobuf enum value: WayIncBridge = 8;
     */
    WayIncBridge = 8,
    /**
     * /////////////////////////扣减渠道////////////////////////////////////
     * 扣减方式
     * 用户使用道具
     *
     * [(kitproto.bigdata_tag) = "用户使用"];
     *
     * @generated from protobuf enum value: WayDecUse = 100;
     */
    WayDecUse = 100,
    /**
     * 后台扣除               （系统扣除）
     *
     * [(kitproto.bigdata_tag) = "后台扣除"];
     *
     * @generated from protobuf enum value: WayDecCMS = 101;
     */
    WayDecCMS = 101,
    /**
     * 过期
     *
     * [(kitproto.bigdata_tag) = "过期"];
     *
     * @generated from protobuf enum value: WayDecExpire = 102;
     */
    WayDecExpire = 102,
    /**
     * 用户清理
     *
     * [(kitproto.bigdata_tag) = "用户清理"];
     *
     * @generated from protobuf enum value: WayDecCleanUp = 103;
     */
    WayDecCleanUp = 103,
    /**
     * 链上交易(减少)
     *
     * @generated from protobuf enum value: WayDecTxOnChain = 104;
     */
    WayDecTxOnChain = 104,
    /**
     * 跨链桥（减少）
     *
     * @generated from protobuf enum value: WayDecBridge = 105;
     */
    WayDecBridge = 105
}
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * AwardAcceptType 奖励领取方式（玩家）
 *
 * @generated from protobuf enum bpt.common.AwardAcceptType
 */
export enum AwardAcceptType {
    /**
     * 未知类型
     *
     * @generated from protobuf enum value: AwardAcceptTypeUnknown = 0;
     */
    AwardAcceptTypeUnknown = 0,
    /**
     * 手动领取
     *
     * @generated from protobuf enum value: AwardAcceptTypeManualAccept = 1;
     */
    AwardAcceptTypeManualAccept = 1,
    /**
     * 自动领取
     *
     * @generated from protobuf enum value: AwardAcceptTypeAutoAccept = 2;
     */
    AwardAcceptTypeAutoAccept = 2
}
/**
 * AwardAcceptDest 奖励发放目的地
 *
 * @generated from protobuf enum bpt.common.AwardAcceptDest
 */
export enum AwardAcceptDest {
    /**
     * 未知类型
     *
     * @generated from protobuf enum value: AwardAcceptDestUnknown = 0;
     */
    AwardAcceptDestUnknown = 0,
    /**
     * 系统邮件
     *
     * @generated from protobuf enum value: AwardAcceptDestSysMail = 1;
     */
    AwardAcceptDestSysMail = 1,
    /**
     * 用户背包
     *
     * @generated from protobuf enum value: AwardAcceptDestUserBag = 2;
     */
    AwardAcceptDestUserBag = 2
}
/**
 * @generated from protobuf enum bpt.common.AwardAcceptStrategy
 */
export enum AwardAcceptStrategy {
    /**
     * 直接领取
     *
     * @generated from protobuf enum value: AwardAwardAcceptStrategyDefault = 0;
     */
    AwardAwardAcceptStrategyDefault = 0,
    /**
     * 选择奖励
     *
     * @generated from protobuf enum value: AwardAwardAcceptStrategySelect = 1;
     */
    AwardAwardAcceptStrategySelect = 1
}
/**
 * @generated from protobuf enum bpt.common.AwardType
 */
export enum AwardType {
    /**
     * @generated from protobuf enum value: AwardTypeUnknown = 0;
     */
    AwardTypeUnknown = 0,
    /**
     * 固定奖励
     *
     * @generated from protobuf enum value: AwardTypeFixed = 1;
     */
    AwardTypeFixed = 1,
    /**
     * 随机奖励
     *
     * @generated from protobuf enum value: AwardTypeRandom = 2;
     */
    AwardTypeRandom = 2
}
/**
 * @generated from protobuf enum bpt.common.VIPRewardType
 */
export enum VIPRewardType {
    /**
     * @generated from protobuf enum value: VIPRewardTypeUnknown = 0;
     */
    VIPRewardTypeUnknown = 0,
    /**
     * 关闭
     *
     * @generated from protobuf enum value: VIPRewardTypeClose = 1;
     */
    VIPRewardTypeClose = 1,
    /**
     * 双倍
     *
     * @generated from protobuf enum value: VIPRewardTypeDouble = 2;
     */
    VIPRewardTypeDouble = 2,
    /**
     * 按等级
     *
     * @generated from protobuf enum value: VIPRewardTypeLevel = 3;
     */
    VIPRewardTypeLevel = 3
}
/**
 * 用户组;这里用于区分请求资源的用户是哪个组，针对资源可见性
 *
 * @generated from protobuf enum bpt.common.UserGroupType
 */
export enum UserGroupType {
    /**
     * 未知
     *
     * @generated from protobuf enum value: UserGroupTypeUnknown = 0;
     */
    UserGroupTypeUnknown = 0,
    /**
     * 正常用户
     *
     * @generated from protobuf enum value: UserGroupTypeNormal = 1;
     */
    UserGroupTypeNormal = 1,
    /**
     * 屏蔽用户组
     *
     * @generated from protobuf enum value: UserGroupTypeBlock = 2;
     */
    UserGroupTypeBlock = 2
}
// bgo\component\pay.proto
// enum PMode {
// PModeUnknown   = 0;
// GooglePay      = 12;   // 谷歌支付
// ApplePay       = 99;   // 苹果支付
// AliPayApp      = 265;  // 支付宝支付 APP 端
// AliPayWap      = 26;   // 支付宝支付 手机网页端
// WxPayApp       = 431;  // 微信支付   APP 端
// WxPayH5        = 898;  // 微信支付   H5
// WxPayJsApi     = 520;  // 微信支付   公众号 JSAPI
// WxPayMPJsApi   = 899;  // 微信支付   小程序 JSAPI
// VIVOPAY        = 889;  // VIVO 支付
// OPPOPAY        = 215;  // OPPO 支付
// ApplePayPeriod = 913;  // 苹果支付  周期订阅
// AliPayPeriod   = 914;  // 支付宝支付 周期订阅
// HuaWeiPay      = 892;  // 华为支付
// }

/**
 * 支付渠道需要和bgo\component\pay.proto 中的支付配置枚举保持一致
 *
 * @generated from protobuf enum bpt.common.PayChannel
 */
export enum PayChannel {
    /**
     * [(kitproto.bigdata_tag) = "未知支付方式"];
     *
     * @generated from protobuf enum value: PayChannelUnknown = 0;
     */
    PayChannelUnknown = 0,
    /**
     * 谷歌支付
     *
     * [(kitproto.bigdata_tag) = "谷歌支付"];
     *
     * @generated from protobuf enum value: PayChannelGooglePay = 12;
     */
    PayChannelGooglePay = 12,
    /**
     * 支付宝支付 手机网页端
     *
     * [(kitproto.bigdata_tag) = "支付宝支付 手机网页端"];
     *
     * @generated from protobuf enum value: PayChannelAliPayWap = 26;
     */
    PayChannelAliPayWap = 26,
    /**
     * 苹果普通支付
     *
     * [(kitproto.bigdata_tag) = "苹果支付"];
     *
     * @generated from protobuf enum value: PayChannelApple = 99;
     */
    PayChannelApple = 99,
    /**
     * OPPO 支付
     *
     * [(kitproto.bigdata_tag) = "OPPO支付"];
     *
     * @generated from protobuf enum value: PayChannelOPPOPAY = 215;
     */
    PayChannelOPPOPAY = 215,
    /**
     * 支付宝普通支付
     *
     * [(kitproto.bigdata_tag) = "支付宝支付"];
     *
     * @generated from protobuf enum value: PayChannelAliPay = 265;
     */
    PayChannelAliPay = 265,
    /**
     * 微信支付
     *
     * [(kitproto.bigdata_tag) = "微信支付"];
     *
     * @generated from protobuf enum value: PayChannelWeChat = 431;
     */
    PayChannelWeChat = 431,
    /**
     * 微信支付   公众号 JSAPI
     *
     * [(kitproto.bigdata_tag) = "微信支付公众号JSAPI"];
     *
     * @generated from protobuf enum value: PayChannelWxPayJsApi = 520;
     */
    PayChannelWxPayJsApi = 520,
    /**
     * VIVO 支付
     *
     * [(kitproto.bigdata_tag) = "VIVO支付"];
     *
     * @generated from protobuf enum value: PayChannelVIVOPAY = 889;
     */
    PayChannelVIVOPAY = 889,
    /**
     * 华为支付
     *
     * [(kitproto.bigdata_tag) = "华为支付"];
     *
     * @generated from protobuf enum value: PayChannelHuaWeiPay = 892;
     */
    PayChannelHuaWeiPay = 892,
    /**
     * 微信支付   H5
     *
     * [(kitproto.bigdata_tag) = "微信支付H5"];
     *
     * @generated from protobuf enum value: PayChannelWxPayH5 = 898;
     */
    PayChannelWxPayH5 = 898,
    /**
     * 微信支付   小程序 JSAPI
     *
     * [(kitproto.bigdata_tag) = "微信支付小程序JSAPI"];
     *
     * @generated from protobuf enum value: PayChannelWxPayMPJsApi = 899;
     */
    PayChannelWxPayMPJsApi = 899,
    /**
     * 苹果连续支付 周期订阅
     *
     * [(kitproto.bigdata_tag) = "苹果连续支付周期订阅"];
     *
     * @generated from protobuf enum value: PayChannelApplePeriod = 913;
     */
    PayChannelApplePeriod = 913,
    /**
     * 支付宝连续支付 周期订阅
     *
     * [(kitproto.bigdata_tag) = "支付宝连续支付周期订阅"];
     *
     * @generated from protobuf enum value: PayChannelAliPayPeriod = 914;
     */
    PayChannelAliPayPeriod = 914,
    /**
     * 阿里支付宝支付 APP 端
     *
     * [(kitproto.bigdata_tag) = "阿里支付宝支付 APP 端"];
     *
     * @generated from protobuf enum value: PayCHannelAliPayApp = 932;
     */
    PayCHannelAliPayApp = 932
}
/**
 * 道具分类类型
 *
 * @generated from protobuf enum bpt.common.PropCategory
 */
export enum PropCategory {
    /**
     * @generated from protobuf enum value: PropCategoryUnknown = 0;
     */
    PropCategoryUnknown = 0,
    /**
     * 货币（暂时）
     *
     * @generated from protobuf enum value: PropCategoryCoin = 1000;
     */
    PropCategoryCoin = 1000,
    /**
     * 兑换券
     *
     * @generated from protobuf enum value: PropCategoryVoucher = 2000;
     */
    PropCategoryVoucher = 2000,
    /**
     * 正常
     *
     * @generated from protobuf enum value: PropCategoryNormal = 3000;
     */
    PropCategoryNormal = 3000,
    /**
     * 门票
     *
     * @generated from protobuf enum value: PropCategoryTicket = 4000;
     */
    PropCategoryTicket = 4000,
    /**
     * 虚拟货币
     *
     * @generated from protobuf enum value: PropCategoryVirtualCoin = 5000;
     */
    PropCategoryVirtualCoin = 5000,
    /**
     * Nft道具
     *
     * @generated from protobuf enum value: PropCategoryNFT = 6000;
     */
    PropCategoryNFT = 6000
}
/**
 * 道具功能类型
 *
 * @generated from protobuf enum bpt.common.PropType
 */
export enum PropType {
    /**
     * @generated from protobuf enum value: PropTypeUnknown = 0;
     */
    PropTypeUnknown = 0,
    /**
     * 金币
     *
     * @generated from protobuf enum value: PropTypeCoin = 1000;
     */
    PropTypeCoin = 1000,
    /**
     * 兑换券
     *
     * @generated from protobuf enum value: PropTypeVoucher = 2000;
     */
    PropTypeVoucher = 2000,
    /**
     * 通用
     *
     * @generated from protobuf enum value: PropTypeNormal = 3000;
     */
    PropTypeNormal = 3000,
    /**
     * 通用-互动
     *
     * @generated from protobuf enum value: PropTypeInteractive = 3001;
     */
    PropTypeInteractive = 3001,
    /**
     * 通用-礼物
     *
     * @generated from protobuf enum value: PropTypeGift = 3002;
     */
    PropTypeGift = 3002,
    /**
     * 通用-语音包
     *
     * @generated from protobuf enum value: PropTypeVoice = 3003;
     */
    PropTypeVoice = 3003,
    /**
     * 通用-RNG洗牌卡
     *
     * @generated from protobuf enum value: PropTypeRNGShuffleCard = 3004;
     */
    PropTypeRNGShuffleCard = 3004,
    /**
     * 门票
     *
     * @generated from protobuf enum value: PropTypeTicket = 4000;
     */
    PropTypeTicket = 4000,
    /**
     * 虚拟货币｜GAW货币
     *
     * @generated from protobuf enum value: PropTypeGAWCoin = 5000;
     */
    PropTypeGAWCoin = 5000,
    /**
     * Nft道具-冠军
     *
     * @generated from protobuf enum value: PropTypeNFTChampion = 6000;
     */
    PropTypeNFTChampion = 6000,
    /**
     * Nft道具-进奖圈
     *
     * @generated from protobuf enum value: PropTypeNFTAward = 6001;
     */
    PropTypeNFTAward = 6001,
    /**
     * Nft道具-FinalTable
     *
     * @generated from protobuf enum value: PropTypeNFTFinalTable = 6002;
     */
    PropTypeNFTFinalTable = 6002,
    /**
     * Nft道具-泡沫
     *
     * @generated from protobuf enum value: PropTypeNFTBubble = 6003;
     */
    PropTypeNFTBubble = 6003,
    /**
     * Nft道具-亚军
     *
     * @generated from protobuf enum value: PropTypeNFTSecond = 6004;
     */
    PropTypeNFTSecond = 6004,
    /**
     * Nft道具-季军
     *
     * @generated from protobuf enum value: PropTypeNFTThird = 6005;
     */
    PropTypeNFTThird = 6005
}
/**
 * @generated from protobuf enum bpt.common.AssetType
 */
export enum AssetType {
    /**
     * [(kitproto.bigdata_tag) = "未知资产类型"];  //
     *
     * @generated from protobuf enum value: AssetTypeUnknown = 0;
     */
    AssetTypeUnknown = 0,
    /**
     * 免费资产
     *
     * [(kitproto.bigdata_tag) = "免费资产"];
     *
     * @generated from protobuf enum value: AssetTypeFree = 1;
     */
    AssetTypeFree = 1,
    /**
     * 道具资产
     *
     * [(kitproto.bigdata_tag) = "道具资产",(kitproto.service_tag) = "bpt.hall.prop.v1"];
     *
     * @generated from protobuf enum value: AssetTypeProp = 2;
     */
    AssetTypeProp = 2
}
/**
 * RoleType liveroom 服务使用
 *
 * @generated from protobuf enum bpt.common.RoleType
 */
export enum RoleType {
    /**
     * 未知
     *
     * @generated from protobuf enum value: RoleTypeUnknown = 0;
     */
    RoleTypeUnknown = 0,
    /**
     * 主持人
     *
     * @generated from protobuf enum value: RoleTypeHost = 1;
     */
    RoleTypeHost = 1,
    /**
     * 嘉宾(可以发言)
     *
     * @generated from protobuf enum value: RoleTypeGuest = 2;
     */
    RoleTypeGuest = 2,
    /**
     * 观众
     *
     * @generated from protobuf enum value: RoleTypeViewer = 3;
     */
    RoleTypeViewer = 3,
    /**
     * 玩家
     *
     * @generated from protobuf enum value: RoleTypePlayer = 4;
     */
    RoleTypePlayer = 4,
    /**
     * 助理(可以管理嘉宾)
     *
     * @generated from protobuf enum value: RoleTypeAssistant = 5;
     */
    RoleTypeAssistant = 5
}
/**
 * 胜平负
 *
 * @generated from protobuf enum bpt.common.WinningResult
 */
export enum WinningResult {
    /**
     * @generated from protobuf enum value: WinningResult_None = 0;
     */
    WinningResult_None = 0,
    /**
     * @generated from protobuf enum value: WinningResult_Win = 1;
     */
    WinningResult_Win = 1,
    /**
     * @generated from protobuf enum value: WinningResult_Lose = 2;
     */
    WinningResult_Lose = 2,
    /**
     * @generated from protobuf enum value: WinningResult_Draw = 3;
     */
    WinningResult_Draw = 3
}
/**
 * 性别
 *
 * @generated from protobuf enum bpt.common.Gender
 */
export enum Gender {
    /**
     * @generated from protobuf enum value: GenderUnknown = 0;
     */
    GenderUnknown = 0,
    /**
     * 男性
     *
     * @generated from protobuf enum value: GenderMale = 1;
     */
    GenderMale = 1,
    /**
     * 女性
     *
     * @generated from protobuf enum value: GenderFemale = 2;
     */
    GenderFemale = 2
}
/**
 * @generated from protobuf enum bpt.common.SwitchState
 */
export enum SwitchState {
    /**
     * 未知
     *
     * @generated from protobuf enum value: SwitchStateUnknown = 0;
     */
    SwitchStateUnknown = 0,
    /**
     * 打开
     *
     * @generated from protobuf enum value: SwitchStateOn = 1;
     */
    SwitchStateOn = 1,
    /**
     * 关闭
     *
     * @generated from protobuf enum value: SwitchStateOff = 2;
     */
    SwitchStateOff = 2
}
/**
 * 比较运算符
 *
 * @generated from protobuf enum bpt.common.ComparisonOperator
 */
export enum ComparisonOperator {
    /**
     * 未知
     *
     * @generated from protobuf enum value: ComparisonUnknown = 0;
     */
    ComparisonUnknown = 0,
    /**
     * 大于
     *
     * @generated from protobuf enum value: ComparisonGT = 1;
     */
    ComparisonGT = 1,
    /**
     * 大于等于
     *
     * @generated from protobuf enum value: ComparisonGTE = 2;
     */
    ComparisonGTE = 2,
    /**
     * 小于
     *
     * @generated from protobuf enum value: ComparisonLT = 3;
     */
    ComparisonLT = 3,
    /**
     * 小于等于
     *
     * @generated from protobuf enum value: ComparisonLTE = 4;
     */
    ComparisonLTE = 4,
    /**
     * 等于
     *
     * @generated from protobuf enum value: ComparisonEqual = 5;
     */
    ComparisonEqual = 5
}
/**
 * 用户标识（资产流水数据中使用，运营需要标识该用户的类型）
 *
 * @generated from protobuf enum bpt.common.UserRobotFlag
 */
export enum UserRobotFlag {
    /**
     * @generated from protobuf enum value: UserRobotFlagUnknown = 0;
     */
    UserRobotFlagUnknown = 0,
    /**
     * 正常玩家
     *
     * @generated from protobuf enum value: UserRobotFlagPlayer = 1;
     */
    UserRobotFlagPlayer = 1,
    /**
     * 机器人
     *
     * @generated from protobuf enum value: UserRobotFlagRobot = 2;
     */
    UserRobotFlagRobot = 2,
    /**
     * 白名单用户
     *
     * @generated from protobuf enum value: UserRobotFlagWhite = 3;
     */
    UserRobotFlagWhite = 3
}
/**
 * 发放的方式
 *
 * @generated from protobuf enum bpt.common.GrantType
 */
export enum GrantType {
    /**
     * 直接发放
     *
     * @generated from protobuf enum value: GrantTypeDirectly = 0;
     */
    GrantTypeDirectly = 0,
    /**
     * 通过邮件发放
     *
     * @generated from protobuf enum value: GrantTypeByMail = 1;
     */
    GrantTypeByMail = 1,
    /**
     * 通过商城购买
     *
     * @generated from protobuf enum value: GrantTypeByMallBuy = 2;
     */
    GrantTypeByMallBuy = 2
}
/**
 * @generated from protobuf enum bpt.common.MailSceneMetaType
 */
export enum MailSceneMetaType {
    /**
     * @generated from protobuf enum value: MailSceneMetaTypeUnknown = 0;
     */
    MailSceneMetaTypeUnknown = 0,
    /**
     * /////////SubTypeNeedGawPay//////////
     * 上链gaw比赛相关邮件
     * 比赛领奖（调用matchhub服务ClaimPrize）
     *
     * @generated from protobuf enum value: MailSceneMetaTypeMatchReward = 1;
     */
    MailSceneMetaTypeMatchReward = 1,
    /**
     * 比赛报名退款（调用matchhub服务UserUnRegisterMatch）
     *
     * @generated from protobuf enum value: MailSceneMetaTypeRegisterRefund = 2;
     */
    MailSceneMetaTypeRegisterRefund = 2,
    /**
     * 现金桌卖筹码 (调用cashmatch服务WithDrawChips接口)
     *
     * @generated from protobuf enum value: MailSceneMetaTypeWithDrawChips = 3;
     */
    MailSceneMetaTypeWithDrawChips = 3,
    /**
     * 开赛失败赛事方退赛费（调用matchhub服务CancelMatchByUser）
     *
     * @generated from protobuf enum value: MailSceneMetaTypeUserCancelMatch = 4;
     */
    MailSceneMetaTypeUserCancelMatch = 4,
    /**
     * addon/rebuy/reentry失败退钱
     *
     * @generated from protobuf enum value: MailSceneMetaTypeWithDrawReBuyAddOnReEntry = 5;
     */
    MailSceneMetaTypeWithDrawReBuyAddOnReEntry = 5,
    /**
     * @generated from protobuf enum value: MailSceneMetaTypeOwnerCancelMatch = 6;
     */
    MailSceneMetaTypeOwnerCancelMatch = 6,
    /**
     * @generated from protobuf enum value: MailSceneMetaTypeOwnerForceCancelMatch = 7;
     */
    MailSceneMetaTypeOwnerForceCancelMatch = 7,
    /**
     * 人数不足
     *
     * @generated from protobuf enum value: MailSceneMetaTypeNotEnoughUserCancelMatch = 8;
     */
    MailSceneMetaTypeNotEnoughUserCancelMatch = 8
}
// @generated message type with reflection information, may provide speed optimized methods
class Range$Type extends MessageType<Range> {
    constructor() {
        super("bpt.common.Range", [
            { no: 1, name: "start", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "end", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Range>): Range {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.start = 0;
        message.end = 0;
        if (value !== undefined)
            reflectionMergePartial<Range>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Range): Range {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 start */ 1:
                    message.start = reader.int64().toNumber();
                    break;
                case /* int64 end */ 2:
                    message.end = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Range, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 start = 1; */
        if (message.start !== 0)
            writer.tag(1, WireType.Varint).int64(message.start);
        /* int64 end = 2; */
        if (message.end !== 0)
            writer.tag(2, WireType.Varint).int64(message.end);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.Range
 */
export const Range = new Range$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Metadata$Type extends MessageType<Metadata> {
    constructor() {
        super("bpt.common.Metadata", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Metadata>): Metadata {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.key = "";
        message.value = "";
        message.desc = "";
        if (value !== undefined)
            reflectionMergePartial<Metadata>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Metadata): Metadata {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                case /* string desc */ 3:
                    message.desc = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Metadata, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* string desc = 3; */
        if (message.desc !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.desc);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.Metadata
 */
export const Metadata = new Metadata$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpireData$Type extends MessageType<ExpireData> {
    constructor() {
        super("bpt.common.ExpireData", [
            { no: 1, name: "type", kind: "enum", T: () => ["bpt.common.ExpireType", ExpireType] },
            { no: 2, name: "value", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ExpireData>): ExpireData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.value = 0;
        if (value !== undefined)
            reflectionMergePartial<ExpireData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExpireData): ExpireData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.ExpireType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* int64 value */ 2:
                    message.value = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExpireData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.ExpireType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* int64 value = 2; */
        if (message.value !== 0)
            writer.tag(2, WireType.Varint).int64(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.ExpireData
 */
export const ExpireData = new ExpireData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PropItem$Type extends MessageType<PropItem> {
    constructor() {
        super("bpt.common.PropItem", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "img", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "num", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "typeID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "expire", kind: "message", T: () => ExpireData },
            { no: 7, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "itemType", kind: "enum", T: () => ["bpt.common.ItemType", ItemType] },
            { no: 9, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PropItem>): PropItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.name = "";
        message.img = "";
        message.num = 0;
        message.typeID = 0;
        message.desc = "";
        message.itemType = 0;
        message.icon = "";
        if (value !== undefined)
            reflectionMergePartial<PropItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PropItem): PropItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string img */ 3:
                    message.img = reader.string();
                    break;
                case /* int32 num */ 4:
                    message.num = reader.int32();
                    break;
                case /* int64 typeID */ 5:
                    message.typeID = reader.int64().toNumber();
                    break;
                case /* bpt.common.ExpireData expire */ 6:
                    message.expire = ExpireData.internalBinaryRead(reader, reader.uint32(), options, message.expire);
                    break;
                case /* string desc */ 7:
                    message.desc = reader.string();
                    break;
                case /* bpt.common.ItemType itemType */ 8:
                    message.itemType = reader.int32();
                    break;
                case /* string icon */ 9:
                    message.icon = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PropItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string img = 3; */
        if (message.img !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.img);
        /* int32 num = 4; */
        if (message.num !== 0)
            writer.tag(4, WireType.Varint).int32(message.num);
        /* int64 typeID = 5; */
        if (message.typeID !== 0)
            writer.tag(5, WireType.Varint).int64(message.typeID);
        /* bpt.common.ExpireData expire = 6; */
        if (message.expire)
            ExpireData.internalBinaryWrite(message.expire, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* string desc = 7; */
        if (message.desc !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.desc);
        /* bpt.common.ItemType itemType = 8; */
        if (message.itemType !== 0)
            writer.tag(8, WireType.Varint).int32(message.itemType);
        /* string icon = 9; */
        if (message.icon !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.icon);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.PropItem
 */
export const PropItem = new PropItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VIPLevelReward$Type extends MessageType<VIPLevelReward> {
    constructor() {
        super("bpt.common.VIPLevelReward", [
            { no: 1, name: "level", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "multiple", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VIPLevelReward>): VIPLevelReward {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.level = 0;
        message.multiple = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<VIPLevelReward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VIPLevelReward): VIPLevelReward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 level */ 1:
                    message.level = reader.int32();
                    break;
                case /* int32 multiple */ 2:
                    message.multiple = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VIPLevelReward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).int32(message.level);
        /* int32 multiple = 2; */
        if (message.multiple !== 0)
            writer.tag(2, WireType.Varint).int32(message.multiple);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.VIPLevelReward
 */
export const VIPLevelReward = new VIPLevelReward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SysMailBody$Type extends MessageType<SysMailBody> {
    constructor() {
        super("bpt.common.SysMailBody", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "expire", kind: "message", T: () => ExpireData },
            { no: 4, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "templateType", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SysMailBody>): SysMailBody {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.content = "";
        message.reason = "";
        message.templateType = 0;
        if (value !== undefined)
            reflectionMergePartial<SysMailBody>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SysMailBody): SysMailBody {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                case /* bpt.common.ExpireData expire */ 3:
                    message.expire = ExpireData.internalBinaryRead(reader, reader.uint32(), options, message.expire);
                    break;
                case /* string reason */ 4:
                    message.reason = reader.string();
                    break;
                case /* int32 templateType */ 5:
                    message.templateType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SysMailBody, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        /* bpt.common.ExpireData expire = 3; */
        if (message.expire)
            ExpireData.internalBinaryWrite(message.expire, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string reason = 4; */
        if (message.reason !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.reason);
        /* int32 templateType = 5; */
        if (message.templateType !== 0)
            writer.tag(5, WireType.Varint).int32(message.templateType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.SysMailBody
 */
export const SysMailBody = new SysMailBody$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AwardAcceptConf$Type extends MessageType<AwardAcceptConf> {
    constructor() {
        super("bpt.common.AwardAcceptConf", [
            { no: 1, name: "awardAcceptType", kind: "enum", T: () => ["bpt.common.AwardAcceptType", AwardAcceptType] },
            { no: 2, name: "awardAcceptDest", kind: "enum", T: () => ["bpt.common.AwardAcceptDest", AwardAcceptDest] },
            { no: 5, name: "sysMailBody", kind: "message", T: () => SysMailBody },
            { no: 6, name: "awardAcceptStrategy", kind: "enum", T: () => ["bpt.common.AwardAcceptStrategy", AwardAcceptStrategy] },
            { no: 7, name: "selectNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AwardAcceptConf>): AwardAcceptConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.awardAcceptType = 0;
        message.awardAcceptDest = 0;
        message.awardAcceptStrategy = 0;
        message.selectNum = 0;
        if (value !== undefined)
            reflectionMergePartial<AwardAcceptConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AwardAcceptConf): AwardAcceptConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.AwardAcceptType awardAcceptType */ 1:
                    message.awardAcceptType = reader.int32();
                    break;
                case /* bpt.common.AwardAcceptDest awardAcceptDest */ 2:
                    message.awardAcceptDest = reader.int32();
                    break;
                case /* bpt.common.SysMailBody sysMailBody */ 5:
                    message.sysMailBody = SysMailBody.internalBinaryRead(reader, reader.uint32(), options, message.sysMailBody);
                    break;
                case /* bpt.common.AwardAcceptStrategy awardAcceptStrategy */ 6:
                    message.awardAcceptStrategy = reader.int32();
                    break;
                case /* int64 selectNum */ 7:
                    message.selectNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AwardAcceptConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.AwardAcceptType awardAcceptType = 1; */
        if (message.awardAcceptType !== 0)
            writer.tag(1, WireType.Varint).int32(message.awardAcceptType);
        /* bpt.common.AwardAcceptDest awardAcceptDest = 2; */
        if (message.awardAcceptDest !== 0)
            writer.tag(2, WireType.Varint).int32(message.awardAcceptDest);
        /* bpt.common.SysMailBody sysMailBody = 5; */
        if (message.sysMailBody)
            SysMailBody.internalBinaryWrite(message.sysMailBody, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AwardAcceptStrategy awardAcceptStrategy = 6; */
        if (message.awardAcceptStrategy !== 0)
            writer.tag(6, WireType.Varint).int32(message.awardAcceptStrategy);
        /* int64 selectNum = 7; */
        if (message.selectNum !== 0)
            writer.tag(7, WireType.Varint).int64(message.selectNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.AwardAcceptConf
 */
export const AwardAcceptConf = new AwardAcceptConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Money$Type extends MessageType<Money> {
    constructor() {
        super("bpt.common.Money", [
            { no: 1, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "decimal", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Money>): Money {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amount = 0;
        message.currency = "";
        message.decimal = 0;
        if (value !== undefined)
            reflectionMergePartial<Money>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Money): Money {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 amount */ 1:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* string currency */ 2:
                    message.currency = reader.string();
                    break;
                case /* int64 decimal */ 3:
                    message.decimal = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Money, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 amount = 1; */
        if (message.amount !== 0)
            writer.tag(1, WireType.Varint).int64(message.amount);
        /* string currency = 2; */
        if (message.currency !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.currency);
        /* int64 decimal = 3; */
        if (message.decimal !== 0)
            writer.tag(3, WireType.Varint).int64(message.decimal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.Money
 */
export const Money = new Money$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetExchangeRate$Type extends MessageType<AssetExchangeRate> {
    constructor() {
        super("bpt.common.AssetExchangeRate", [
            { no: 1, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "money", kind: "message", T: () => Money }
        ]);
    }
    create(value?: PartialMessage<AssetExchangeRate>): AssetExchangeRate {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amount = 0;
        if (value !== undefined)
            reflectionMergePartial<AssetExchangeRate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetExchangeRate): AssetExchangeRate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 amount */ 1:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* bpt.common.Money money */ 2:
                    message.money = Money.internalBinaryRead(reader, reader.uint32(), options, message.money);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssetExchangeRate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 amount = 1; */
        if (message.amount !== 0)
            writer.tag(1, WireType.Varint).int64(message.amount);
        /* bpt.common.Money money = 2; */
        if (message.money)
            Money.internalBinaryWrite(message.money, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.AssetExchangeRate
 */
export const AssetExchangeRate = new AssetExchangeRate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Prop$Type extends MessageType<Prop> {
    constructor() {
        super("bpt.common.Prop", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] },
            { no: 4, name: "img", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "expire", kind: "message", T: () => ExpireData }
        ]);
    }
    create(value?: PartialMessage<Prop>): Prop {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.amount = 0;
        message.type = 0;
        message.img = "";
        message.icon = "";
        message.desc = "";
        if (value !== undefined)
            reflectionMergePartial<Prop>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Prop): Prop {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* int64 amount */ 2:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string img */ 4:
                    message.img = reader.string();
                    break;
                case /* string icon */ 5:
                    message.icon = reader.string();
                    break;
                case /* string desc */ 6:
                    message.desc = reader.string();
                    break;
                case /* bpt.common.ExpireData expire */ 7:
                    message.expire = ExpireData.internalBinaryRead(reader, reader.uint32(), options, message.expire);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Prop, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Varint).int64(message.amount);
        /* bpt.common.PropType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string img = 4; */
        if (message.img !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.img);
        /* string icon = 5; */
        if (message.icon !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.icon);
        /* string desc = 6; */
        if (message.desc !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.desc);
        /* bpt.common.ExpireData expire = 7; */
        if (message.expire)
            ExpireData.internalBinaryWrite(message.expire, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.Prop
 */
export const Prop = new Prop$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetItem$Type extends MessageType<AssetItem> {
    constructor() {
        super("bpt.common.AssetItem", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "img", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "expire", kind: "message", T: () => ExpireData },
            { no: 8, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "type", kind: "enum", T: () => ["bpt.common.AssetType", AssetType] },
            { no: 10, name: "meta", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 11, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AssetItem>): AssetItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.amount = 0;
        message.img = "";
        message.icon = "";
        message.desc = "";
        message.name = "";
        message.type = 0;
        message.meta = {};
        message.rawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<AssetItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetItem): AssetItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* int64 amount */ 2:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* string img */ 4:
                    message.img = reader.string();
                    break;
                case /* string icon */ 5:
                    message.icon = reader.string();
                    break;
                case /* string desc */ 6:
                    message.desc = reader.string();
                    break;
                case /* bpt.common.ExpireData expire */ 7:
                    message.expire = ExpireData.internalBinaryRead(reader, reader.uint32(), options, message.expire);
                    break;
                case /* string name */ 8:
                    message.name = reader.string();
                    break;
                case /* bpt.common.AssetType type */ 9:
                    message.type = reader.int32();
                    break;
                case /* map<string, string> meta */ 10:
                    this.binaryReadMap10(message.meta, reader, options);
                    break;
                case /* string rawAmount */ 11:
                    message.rawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap10(map: AssetItem["meta"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AssetItem["meta"] | undefined, val: AssetItem["meta"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.AssetItem.meta");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: AssetItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Varint).int64(message.amount);
        /* string img = 4; */
        if (message.img !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.img);
        /* string icon = 5; */
        if (message.icon !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.icon);
        /* string desc = 6; */
        if (message.desc !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.desc);
        /* bpt.common.ExpireData expire = 7; */
        if (message.expire)
            ExpireData.internalBinaryWrite(message.expire, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string name = 8; */
        if (message.name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.name);
        /* bpt.common.AssetType type = 9; */
        if (message.type !== 0)
            writer.tag(9, WireType.Varint).int32(message.type);
        /* map<string, string> meta = 10; */
        for (let k of globalThis.Object.keys(message.meta))
            writer.tag(10, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.meta[k]).join();
        /* string rawAmount = 11; */
        if (message.rawAmount !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.rawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.AssetItem
 */
export const AssetItem = new AssetItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SimpleAsset$Type extends MessageType<SimpleAsset> {
    constructor() {
        super("bpt.common.SimpleAsset", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SimpleAsset>): SimpleAsset {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.rawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<SimpleAsset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SimpleAsset): SimpleAsset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string rawAmount */ 2:
                    message.rawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SimpleAsset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string rawAmount = 2; */
        if (message.rawAmount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.SimpleAsset
 */
export const SimpleAsset = new SimpleAsset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Asset$Type extends MessageType<Asset> {
    constructor() {
        super("bpt.common.Asset", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 2, name: "diamond", kind: "message", T: () => AssetItem },
            { no: 3, name: "coin", kind: "message", T: () => AssetItem },
            { no: 4, name: "mung", kind: "message", T: () => AssetItem },
            { no: 5, name: "gift", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 6, name: "dress", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 7, name: "honourPoint", kind: "message", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<Asset>): Asset {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        message.gift = [];
        message.dress = [];
        if (value !== undefined)
            reflectionMergePartial<Asset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Asset): Asset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem props */ 1:
                    message.props.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.AssetItem diamond */ 2:
                    message.diamond = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.diamond);
                    break;
                case /* bpt.common.AssetItem coin */ 3:
                    message.coin = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.coin);
                    break;
                case /* bpt.common.AssetItem mung */ 4:
                    message.mung = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.mung);
                    break;
                case /* repeated bpt.common.AssetItem gift */ 5:
                    message.gift.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem dress */ 6:
                    message.dress.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.AssetItem honourPoint */ 7:
                    message.honourPoint = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.honourPoint);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Asset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem props = 1; */
        for (let i = 0; i < message.props.length; i++)
            AssetItem.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem diamond = 2; */
        if (message.diamond)
            AssetItem.internalBinaryWrite(message.diamond, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem coin = 3; */
        if (message.coin)
            AssetItem.internalBinaryWrite(message.coin, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem mung = 4; */
        if (message.mung)
            AssetItem.internalBinaryWrite(message.mung, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem gift = 5; */
        for (let i = 0; i < message.gift.length; i++)
            AssetItem.internalBinaryWrite(message.gift[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem dress = 6; */
        for (let i = 0; i < message.dress.length; i++)
            AssetItem.internalBinaryWrite(message.dress[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem honourPoint = 7; */
        if (message.honourPoint)
            AssetItem.internalBinaryWrite(message.honourPoint, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.Asset
 */
export const Asset = new Asset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Parameter$Type extends MessageType<Parameter> {
    constructor() {
        super("bpt.common.Parameter", [
            { no: 1, name: "intVal", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "strVal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "assetItems", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<Parameter>): Parameter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.intVal = 0;
        message.strVal = "";
        message.switch = 0;
        message.assetItems = [];
        if (value !== undefined)
            reflectionMergePartial<Parameter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Parameter): Parameter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 intVal */ 1:
                    message.intVal = reader.int64().toNumber();
                    break;
                case /* string strVal */ 2:
                    message.strVal = reader.string();
                    break;
                case /* bpt.common.SwitchState switch */ 3:
                    message.switch = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem assetItems */ 4:
                    message.assetItems.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Parameter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 intVal = 1; */
        if (message.intVal !== 0)
            writer.tag(1, WireType.Varint).int64(message.intVal);
        /* string strVal = 2; */
        if (message.strVal !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.strVal);
        /* bpt.common.SwitchState switch = 3; */
        if (message.switch !== 0)
            writer.tag(3, WireType.Varint).int32(message.switch);
        /* repeated bpt.common.AssetItem assetItems = 4; */
        for (let i = 0; i < message.assetItems.length; i++)
            AssetItem.internalBinaryWrite(message.assetItems[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.Parameter
 */
export const Parameter = new Parameter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppConfig$Type extends MessageType<AppConfig> {
    constructor() {
        super("bpt.common.AppConfig", [
            { no: 1, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "param", kind: "message", T: () => Parameter },
            { no: 5, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AppConfig>): AppConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.key = "";
        message.desc = "";
        message.switch = 0;
        message.operator = "";
        message.updateAt = 0;
        if (value !== undefined)
            reflectionMergePartial<AppConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppConfig): AppConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string key */ 1:
                    message.key = reader.string();
                    break;
                case /* string desc */ 2:
                    message.desc = reader.string();
                    break;
                case /* bpt.common.SwitchState switch */ 3:
                    message.switch = reader.int32();
                    break;
                case /* bpt.common.Parameter param */ 4:
                    message.param = Parameter.internalBinaryRead(reader, reader.uint32(), options, message.param);
                    break;
                case /* string operator */ 5:
                    message.operator = reader.string();
                    break;
                case /* int64 updateAt */ 6:
                    message.updateAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string key = 1; */
        if (message.key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.key);
        /* string desc = 2; */
        if (message.desc !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.desc);
        /* bpt.common.SwitchState switch = 3; */
        if (message.switch !== 0)
            writer.tag(3, WireType.Varint).int32(message.switch);
        /* bpt.common.Parameter param = 4; */
        if (message.param)
            Parameter.internalBinaryWrite(message.param, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string operator = 5; */
        if (message.operator !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.operator);
        /* int64 updateAt = 6; */
        if (message.updateAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.updateAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.AppConfig
 */
export const AppConfig = new AppConfig$Type();
