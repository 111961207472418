// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/common/common.proto" (package "bpt.common", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.common.HandleTableExceptionReq
 */
export interface HandleTableExceptionReq {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
}
/**
 * @generated from protobuf message bpt.common.HandleTableResultReq
 */
export interface HandleTableResultReq {
    /**
     * @generated from protobuf field: bpt.common.GameResult gameResult = 1;
     */
    gameResult?: GameResult;
    /**
     * 桌子资源释放延迟
     *
     * @generated from protobuf field: int64 releaseDelay = 2;
     */
    releaseDelay: number;
}
/**
 * @generated from protobuf message bpt.common.HandleRoundResultReq
 */
export interface HandleRoundResultReq {
}
/**
 * @generated from protobuf message bpt.common.HandleTableInfoSyncReq
 */
export interface HandleTableInfoSyncReq {
}
/**
 * @generated from protobuf message bpt.common.GameResult
 */
export interface GameResult {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     * int64                  tid         = 2;
     *
     * @generated from protobuf field: repeated bpt.common.GameResultItem items = 4;
     */
    items: GameResultItem[];
    /**
     * 子游戏桌子透传结算信息
     *
     * @generated from protobuf field: bytes tableGameData = 5;
     */
    tableGameData: Uint8Array;
}
/**
 * @generated from protobuf message bpt.common.GameResultItem
 */
export interface GameResultItem {
    /**
     * 用户id
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 角色(与配置保持一致)
     *
     * @generated from protobuf field: int64 role = 2;
     */
    role: number;
    /**
     * 倍率
     *
     * @generated from protobuf field: int64 scoreRatio = 3;
     */
    scoreRatio: number;
    /**
     * 比赛结果（与配置保持一致）
     *
     * @generated from protobuf field: bpt.common.GameResultType gameResult = 4;
     */
    gameResult: GameResultType;
    /**
     * 比赛时间
     *
     * @generated from protobuf field: int64 playTime = 5;
     */
    playTime: number;
    /**
     * 步时是否用完
     *
     * @generated from protobuf field: bool isTimeOut = 6;
     */
    isTimeOut: boolean;
    /**
     * 总步数 (操作数 如出牌次数)
     *
     * @generated from protobuf field: int64 allStep = 7;
     */
    allStep: number;
    /**
     * 总托管手牌次数
     *
     * @generated from protobuf field: int64 handCnt = 8;
     */
    handCnt: number;
    /**
     * 是否高光
     *
     * @generated from protobuf field: bool isHighlight = 9;
     */
    isHighlight: boolean;
    /**
     * 主动托管次数
     *
     * @generated from protobuf field: int64 entrustCnt = 10;
     */
    entrustCnt: number;
    /**
     * 被动托管次数
     *
     * @generated from protobuf field: int64 entrustedCnt = 11;
     */
    entrustedCnt: number;
    /**
     * 托管结束状态  1.主动托管结束，2.被动托管结束，3.正常结束
     *
     * @generated from protobuf field: int64 endEntrustStatus = 12;
     */
    endEntrustStatus: number;
    /**
     * 总操作耗时（秒）
     *
     * @generated from protobuf field: int64 allStepCost = 13;
     */
    allStepCost: number;
    /**
     * 扩展数据 用户角色 “role”
     *
     * @generated from protobuf field: map<string, string> metas = 14;
     */
    metas: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message bpt.common.GameEnd
 */
export interface GameEnd {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
}
/**
 * @generated from protobuf message bpt.common.HandleTableEndReq
 */
export interface HandleTableEndReq {
    /**
     * @generated from protobuf field: bpt.common.GameEnd gameEnd = 1;
     */
    gameEnd?: GameEnd;
}
// message BubbleDealCardReq{
//  // 是否有玩家allin
//  bool hasAllIn =3;
// }
// 
// message BubbleDealCardResp{
// }

/**
 * @generated from protobuf message bpt.common.EliminateReq
 */
export interface EliminateReq {
    /**
     * @deprecated淘汰用户id
     *
     * @generated from protobuf field: int64 foldUid = 1;
     */
    foldUid: number;
    /**
     * 淘汰用户id
     *
     * @generated from protobuf field: repeated int64 foldUids = 2;
     */
    foldUids: number[];
}
/**
 * @generated from protobuf message bpt.common.FoldReq
 */
export interface FoldReq {
    /**
     * 弃牌用户id
     *
     * @generated from protobuf field: int64 foldUid = 3;
     */
    foldUid: number;
    /**
     * 筹码
     *
     * @generated from protobuf field: int64 chips = 4;
     */
    chips: number;
    /**
     * 留坐离桌状态
     *
     * @generated from protobuf field: int32 managedState = 5;
     */
    managedState: number;
    /**
     * @generated from protobuf field: int64 seq = 6;
     */
    seq: number;
    /**
     * 动画时间
     *
     * @generated from protobuf field: int64 animTime = 7;
     */
    animTime: number;
    /**
     * 时间牌数量
     *
     * @generated from protobuf field: int32 timeCardNum = 8;
     */
    timeCardNum: number;
    /**
     * 洗牌卡使用状态
     *
     * @generated from protobuf field: bool useShuffleCard = 9;
     */
    useShuffleCard: boolean;
}
/**
 * @generated from protobuf message bpt.common.FoldResp
 */
export interface FoldResp {
}
/**
 * @generated from protobuf message bpt.common.ManageReq
 */
export interface ManageReq {
    /**
     * 用户id
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 留坐离桌状态
     *
     * @generated from protobuf field: int32 managedState = 2;
     */
    managedState: number;
    /**
     * @generated from protobuf field: int64 seq = 6;
     */
    seq: number;
}
/**
 * @generated from protobuf message bpt.common.TableOpReq
 */
export interface TableOpReq {
    /**
     *  // 比赛id
     *  int64 matchID =1;
     * tableKey
     *
     * @generated from protobuf field: string tKey = 2;
     */
    tKey: string;
    /**
     * 操作类型
     *
     * @generated from protobuf field: bpt.common.TableOpType opType = 3;
     */
    opType: TableOpType;
    /**
     * @generated from protobuf field: bytes req = 4;
     */
    req: Uint8Array;
    /**
     * matchKey
     *
     * @generated from protobuf field: string matchKey = 5;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 seq = 6;
     */
    seq: number;
}
/**
 * @generated from protobuf message bpt.common.TableOpResp
 */
export interface TableOpResp {
    /**
     * 操作类型
     *
     * @generated from protobuf field: bpt.common.TableOpType opType = 1;
     */
    opType: TableOpType;
    /**
     * @generated from protobuf field: string resp = 2;
     */
    resp: string;
}
/**
 * @generated from protobuf message bpt.common.LogicConfig
 */
export interface LogicConfig {
    /**
     * 超时次数(达到阈值桌子切用户到托管)
     *
     * @generated from protobuf field: int32 timeOutCnt = 1;
     */
    timeOutCnt: number;
    /**
     * 下注时间(秒)
     *
     * @generated from protobuf field: int32 playTime = 2;
     */
    playTime: number;
    /**
     * 台费(mini项目用)
     *
     * @generated from protobuf field: uint64 entryFee = 3;
     */
    entryFee: number;
    /**
     * 时间牌效力时长(秒)
     *
     * @generated from protobuf field: int64 timeCardDuration = 4;
     */
    timeCardDuration: number;
}
/**
 * @generated from protobuf message bpt.common.BlindConfig
 */
export interface BlindConfig {
    /**
     * 本次涨盲小盲
     *
     * @generated from protobuf field: uint64 smallBlind = 1;
     */
    smallBlind: number;
    /**
     * 本次涨盲大盲
     *
     * @generated from protobuf field: uint64 bigBlind = 2;
     */
    bigBlind: number;
    /**
     * 下一次涨盲间隔(秒)
     *
     * @generated from protobuf field: int64 raiseBlindInterval = 3;
     */
    raiseBlindInterval: number;
    /**
     * 最小筹码颗粒度
     *
     * @generated from protobuf field: uint64 minChipParticle = 4;
     */
    minChipParticle: number;
    /**
     * 前注
     *
     * @generated from protobuf field: uint64 ante = 5;
     */
    ante: number;
    /**
     * 盲注等级
     *
     * @generated from protobuf field: int32 blindLevel = 6;
     */
    blindLevel: number;
    /**
     * @generated from protobuf field: bpt.common.AnteType anteType = 7;
     */
    anteType: AnteType;
}
/**
 * eventID:1003
 * 运行桌子打一手牌
 *
 * @generated from protobuf message bpt.common.MsgRunTable
 */
export interface MsgRunTable {
    /**
     * @generated from protobuf field: bpt.common.LogicConfig logicConfig = 1;
     */
    logicConfig?: LogicConfig;
    /**
     * @generated from protobuf field: bpt.common.BlindConfig blindConfig = 2;
     */
    blindConfig?: BlindConfig;
    /**
     * @generated from protobuf field: repeated bpt.common.UserInfo users = 3;
     */
    users: UserInfo[];
}
/**
 * eventID:1004
 * 同步用户信息
 *
 * @generated from protobuf message bpt.common.MsgSyncUserData
 */
export interface MsgSyncUserData {
    /**
     * @generated from protobuf field: repeated bpt.common.UserInfo users = 1;
     */
    users: UserInfo[];
}
/**
 * eventID:1003
 * 批量开桌
 *
 * @generated from protobuf message bpt.common.MsgBatchRunTable
 */
export interface MsgBatchRunTable {
    /**
     * @generated from protobuf field: bpt.common.LogicConfig logicConfig = 1;
     */
    logicConfig?: LogicConfig;
    /**
     * @generated from protobuf field: bpt.common.BlindConfig blindConfig = 2;
     */
    blindConfig?: BlindConfig;
}
/**
 * @generated from protobuf message bpt.common.UserInfo
 */
export interface UserInfo {
    /**
     * 用户ID
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 比赛key
     *
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * 是否已经离开
     *
     * @generated from protobuf field: bool isLeave = 3;
     */
    isLeave: boolean;
    /**
     * 渠道appID
     *
     * @generated from protobuf field: string appID = 4;
     */
    appID: string;
    /**
     * 牌局保护局数,默认0没有保护光环（新手、活跃玩家局数）
     *
     * @generated from protobuf field: int32 rookieNum = 5;
     */
    rookieNum: number;
    /**
     * 渠道app版本号
     *
     * @generated from protobuf field: string appVersion = 6;
     */
    appVersion: string;
    /**
     * 上下文metadata
     *
     * @generated from protobuf field: map<string, string> metadata = 7;
     */
    metadata: {
        [key: string]: string;
    };
    /**
     * 队伍id
     *
     * @generated from protobuf field: string matchTeam = 8;
     */
    matchTeam: string;
    /**
     * 最小牌力值
     *
     * @generated from protobuf field: int32 minCardScore = 9;
     */
    minCardScore: number;
    /**
     * 优先级
     *
     * @generated from protobuf field: int32 priorities = 10;
     */
    priorities: number;
    /**
     * 筹码,mtt需要用户自带筹码进桌
     *
     * @generated from protobuf field: uint64 chip = 11;
     */
    chip: number;
    /**
     * 托管状态
     *
     * @generated from protobuf field: int32 managedState = 12;
     */
    managedState: number;
    /**
     * 座位号
     *
     * @generated from protobuf field: int32 seat = 13;
     */
    seat: number;
    /**
     * 等待入局
     *
     * @generated from protobuf field: bool waitForPlay = 14;
     */
    waitForPlay: boolean;
    /**
     * 是否加注中
     *
     * @generated from protobuf field: bool isAddOn = 15;
     */
    isAddOn: boolean;
    /**
     * 是否重购中
     *
     * @generated from protobuf field: bool isReBuy = 16;
     */
    isReBuy: boolean;
    /**
     * 时间牌余量
     *
     * @generated from protobuf field: int32 timeCardNum = 17;
     */
    timeCardNum: number;
    /**
     * 洗牌信息
     *
     * @generated from protobuf field: bpt.common.ShuffleInfo shuffleInfo = 18;
     */
    shuffleInfo?: ShuffleInfo;
}
/**
 * @generated from protobuf message bpt.common.ShuffleInfo
 */
export interface ShuffleInfo {
    /**
     * 洗牌卡使用状态
     *
     * @generated from protobuf field: bool useShuffleCard = 1;
     */
    useShuffleCard: boolean;
    /**
     * 输入的洗牌文本
     *
     * @generated from protobuf field: string inputText = 2;
     */
    inputText: string;
}
// //////////////////////////////////////call back////////////////////////////////////

/**
 * @generated from protobuf message bpt.common.ClearManagedUserCbReq
 */
export interface ClearManagedUserCbReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string tableKey = 2;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.common.TableInfoReq
 */
export interface TableInfoReq {
    /**
     * @generated from protobuf field: bpt.common.MsgTableInfo tableInfo = 1;
     */
    tableInfo?: MsgTableInfo;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 seq = 3;
     */
    seq: number;
}
/**
 * @generated from protobuf message bpt.common.TableInfoResp
 */
export interface TableInfoResp {
}
/**
 * @generated from protobuf message bpt.common.RoundResultReq
 */
export interface RoundResultReq {
    /**
     * @generated from protobuf field: bpt.common.MsgRoundResult roundResult = 1;
     */
    roundResult?: MsgRoundResult;
    /**
     * tableKey
     *
     * @generated from protobuf field: string tKey = 2;
     */
    tKey: string;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 seq = 4;
     */
    seq: number;
}
/**
 * @generated from protobuf message bpt.common.RoundResultResp
 */
export interface RoundResultResp {
}
/**
 * @generated from protobuf message bpt.common.RoundEndReq
 */
export interface RoundEndReq {
    /**
     * @generated from protobuf field: string tKey = 1;
     */
    tKey: string;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 seq = 3;
     */
    seq: number;
    /**
     * @generated from protobuf field: bpt.common.MsgRoundResult roundResult = 4;
     */
    roundResult?: MsgRoundResult;
}
/**
 * @generated from protobuf message bpt.common.RoundEndResp
 */
export interface RoundEndResp {
}
/**
 * @generated from protobuf message bpt.common.UserAllInStat
 */
export interface UserAllInStat {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint64 allInChip = 2;
     */
    allInChip: number;
}
/**
 * @generated from protobuf message bpt.common.RoleInfo
 */
export interface RoleInfo {
    /**
     * /////////////// Poker Star 房间规则 ///////////////
     * 庄家、大盲注、小盲注、枪口的座位号
     *
     * @generated from protobuf field: int32 DealerSeat = 1 [json_name = "DealerSeat"];
     */
    DealerSeat: number; // 庄家
    /**
     * @generated from protobuf field: int32 BBSeat = 2 [json_name = "BBSeat"];
     */
    BBSeat: number; // 大盲注
    /**
     * @generated from protobuf field: int32 SBSeat = 3 [json_name = "SBSeat"];
     */
    SBSeat: number; // 小盲注
    /**
     * @generated from protobuf field: int32 UTGSeat = 4 [json_name = "UTGSeat"];
     */
    UTGSeat: number; // 枪口位
    /**
     * 牌局回放时使用
     *
     * @generated from protobuf field: int32 UTG2Seat = 5 [json_name = "UTG2Seat"];
     */
    UTG2Seat: number; // 枪口位+1
    /**
     * @generated from protobuf field: int32 MPSeat = 6 [json_name = "MPSeat"];
     */
    MPSeat: number; // 中间位置
    /**
     * @generated from protobuf field: int32 MP2Seat = 7 [json_name = "MP2Seat"];
     */
    MP2Seat: number; // 中间位置+1
    /**
     * @generated from protobuf field: int32 MP3Seat = 8 [json_name = "MP3Seat"];
     */
    MP3Seat: number; // 中间位置+2
    /**
     * @generated from protobuf field: int32 COSeat = 9 [json_name = "COSeat"];
     */
    COSeat: number; // 关口位
    /**
     * /////////////// GG Poker 房间规则 ///////////////
     *
     * @generated from protobuf field: int32 RecordBTN = 10 [json_name = "RecordBTN"];
     */
    RecordBTN: number; // 庄家按钮
    /**
     * @generated from protobuf field: int32 RecordSB = 11 [json_name = "RecordSB"];
     */
    RecordSB: number; // 小盲注
    /**
     * @generated from protobuf field: int32 RecordBB = 12 [json_name = "RecordBB"];
     */
    RecordBB: number; // 大盲注
    /**
     * @generated from protobuf field: int32 RecordUTG = 13 [json_name = "RecordUTG"];
     */
    RecordUTG: number; // 枪口位
    /**
     * @generated from protobuf field: int32 RecordUTG2 = 14 [json_name = "RecordUTG2"];
     */
    RecordUTG2: number; // 枪口位+1
    /**
     * @generated from protobuf field: int32 RecordUTG3 = 15 [json_name = "RecordUTG3"];
     */
    RecordUTG3: number; // 枪口位+2
    /**
     * @generated from protobuf field: int32 RecordLJ = 16 [json_name = "RecordLJ"];
     */
    RecordLJ: number; // Lojack，中间位置
    /**
     * @generated from protobuf field: int32 RecordHJ = 17 [json_name = "RecordHJ"];
     */
    RecordHJ: number; // Hijack，中间位置+1
    /**
     * @generated from protobuf field: int32 RecordCO = 18 [json_name = "RecordCO"];
     */
    RecordCO: number; // 关口位
}
/**
 * @generated from protobuf message bpt.common.MsgUserInfo
 */
export interface MsgUserInfo {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 小于0表示围观
     *
     * @generated from protobuf field: int32 seat = 2;
     */
    seat: number;
    /**
     * 是否托管(留坐离桌)
     *
     * @generated from protobuf field: bool isManaged = 3;
     */
    isManaged: boolean;
    /**
     * 剩余筹码
     *
     * @generated from protobuf field: uint64 chip = 13;
     */
    chip: number;
    /**
     * 超时次数
     *
     * @generated from protobuf field: int32 timeOutCnt = 4;
     */
    timeOutCnt: number;
    /**
     * 时间牌余量
     *
     * @generated from protobuf field: int32 timeCardNum = 5;
     */
    timeCardNum: number;
}
/**
 * @generated from protobuf message bpt.common.MsgTableInfo
 */
export interface MsgTableInfo {
    /**
     * @generated from protobuf field: string tKey = 2;
     */
    tKey: string;
    /**
     * @generated from protobuf field: repeated bpt.common.MsgUserInfo users = 3;
     */
    users: MsgUserInfo[]; // 用户信息
    /**
     * @generated from protobuf field: repeated bpt.common.MsgUserInfo roundUsers = 4;
     */
    roundUsers: MsgUserInfo[]; // 入局用户
    /**
     * 牌局阶段
     *
     * @generated from protobuf field: bpt.common.RoundStage roundStage = 10;
     */
    roundStage: RoundStage;
    /**
     * 庄家、大小盲、枪手信息座位号
     *
     * @generated from protobuf field: bpt.common.RoleInfo roleInfo = 18;
     */
    roleInfo?: RoleInfo;
    /**
     * 该局用户all in的筹码信息
     *
     * @generated from protobuf field: repeated bpt.common.UserAllInStat userAllInStats = 21;
     */
    userAllInStats: UserAllInStat[];
    /**
     * @generated from protobuf field: bpt.common.TableState tableState = 22;
     */
    tableState: TableState;
}
/**
 * @generated from protobuf message bpt.common.MsgRoundResult
 */
export interface MsgRoundResult {
    /**
     * @generated from protobuf field: repeated bpt.common.MsgUserResult results = 1;
     */
    results: MsgUserResult[];
    /**
     * 本轮牌局公共牌
     *
     * @generated from protobuf field: repeated uint32 cards = 3;
     */
    cards: number[];
    /**
     * 动画时间(毫秒)
     *
     * @generated from protobuf field: int64 animationTime = 4;
     */
    animationTime: number;
}
/**
 * @generated from protobuf message bpt.common.MsgUserResult
 */
export interface MsgUserResult {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 牌型类型
     *
     * @generated from protobuf field: bpt.common.CT cardType = 7;
     */
    cardType: CT;
    /**
     * 剩余筹码,剩余筹码=0即为输掉游戏
     *
     * @generated from protobuf field: uint64 chip = 3;
     */
    chip: number;
    /**
     * 用户留坐离桌状态
     *
     * @generated from protobuf field: int32 managedState = 9;
     */
    managedState: number;
    /**
     * 是否赢得本轮牌局
     *
     * @generated from protobuf field: bool winRound = 10;
     */
    winRound: boolean;
    /**
     * 本局变动的筹码数量
     *
     * @generated from protobuf field: uint64 changeChip = 11;
     */
    changeChip: number;
    /**
     * 剩余时间牌
     *
     * @generated from protobuf field: int32 timeCardNum = 12;
     */
    timeCardNum: number;
    /**
     * 由这个用户本局淘汰掉的玩家
     *
     * @generated from protobuf field: repeated int64 eliminatedUsers = 13;
     */
    eliminatedUsers: number[];
    /**
     * 洗牌卡使用状态
     *
     * @generated from protobuf field: bool useShuffleCard = 14;
     */
    useShuffleCard: boolean;
}
/**
 * 桌子状态变更回调
 *
 * @generated from protobuf message bpt.common.MsgTableStateChange
 */
export interface MsgTableStateChange {
    /**
     * @generated from protobuf field: string tKey = 1;
     */
    tKey: string;
    /**
     * @generated from protobuf field: bpt.common.TableState tableState = 2;
     */
    tableState: TableState;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.common.TableStateChangeResp
 */
export interface TableStateChangeResp {
}
/**
 * @generated from protobuf message bpt.common.I18nField
 */
export interface I18nField {
    /**
     * @generated from protobuf field: map<string, string> entries = 1;
     */
    entries: {
        [key: string]: string;
    }; // key是语言，value是对应翻译
}
/**
 * @generated from protobuf message bpt.common.DataField
 */
export interface DataField {
    /**
     * @generated from protobuf field: bpt.common.FieldType fieldType = 1;
     */
    fieldType: FieldType;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string; // FieldType=0时，就是直接渲染的数据
    /**
     * @generated from protobuf field: string template = 3;
     */
    template: string; // FieldType=1时有值，值为对应的key,如TablePaymentOperation
    /**
     * @generated from protobuf field: map<string, bpt.common.DataField> data = 4;
     */
    data: {
        [key: string]: DataField;
    }; // FieldType=1时有值，为对应模板的模板数据
    /**
     * @generated from protobuf field: bpt.common.I18nField i8nField = 6 [json_name = "i8nField"];
     */
    i8nField?: I18nField; // 先选语言,从对应的语言找出对应value的值
}
/**
 * @generated from protobuf message bpt.common.TemplateContent
 */
export interface TemplateContent {
    /**
     * @generated from protobuf field: string template = 1;
     */
    template: string; // 邮件模板，对应翻译文件的key
    /**
     * @generated from protobuf field: map<string, bpt.common.DataField> data = 2;
     */
    data: {
        [key: string]: DataField;
    }; // 如对应模板没有字段，这里为空
}
/**
 * @generated from protobuf message bpt.common.PingMatchReq
 */
export interface PingMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.common.PingMatchResp
 */
export interface PingMatchResp {
}
/**
 * @generated from protobuf message bpt.common.TableView
 */
export interface TableView {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     *    string roomId = 2;
     *
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int32 matchType = 4;
     */
    matchType: number;
    /**
     * @generated from protobuf field: bpt.common.TableViewChangeReason reason = 5;
     */
    reason: TableViewChangeReason;
}
/**
 * @generated from protobuf enum bpt.common.GameResultType
 */
export enum GameResultType {
    /**
     * @generated from protobuf enum value: GameResultTypeUnknown = 0;
     */
    GameResultTypeUnknown = 0,
    /**
     * 赢
     *
     * @generated from protobuf enum value: GameResultTypeWin = 1;
     */
    GameResultTypeWin = 1,
    /**
     * 输
     *
     * @generated from protobuf enum value: GameResultTypeLoss = 2;
     */
    GameResultTypeLoss = 2,
    /**
     * 平
     *
     * @generated from protobuf enum value: GameResultTypeDraw = 3;
     */
    GameResultTypeDraw = 3
}
/**
 * @generated from protobuf enum bpt.common.TableOpType
 */
export enum TableOpType {
    /**
     * @generated from protobuf enum value: TABLE_OP_TYPE_UNSPECIFIED = 0;
     */
    TABLE_OP_TYPE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: TABLE_OP_TYPE_FOLD = 1;
     */
    TABLE_OP_TYPE_FOLD = 1,
    /**
     * @generated from protobuf enum value: TABLE_OP_TYPE_MANAGE = 2;
     */
    TABLE_OP_TYPE_MANAGE = 2,
    /**
     * @generated from protobuf enum value: TABLE_OP_TYPE_ELIMINATE = 3;
     */
    TABLE_OP_TYPE_ELIMINATE = 3
}
/**
 * @generated from protobuf enum bpt.common.OpCode
 */
export enum OpCode {
    /**
     * @generated from protobuf enum value: OP_CODE_UNKNOWN = 0;
     */
    OP_CODE_UNKNOWN = 0,
    /**
     * 下注,本轮到自己前尚无玩家下注时可用
     *
     * @generated from protobuf enum value: OP_CODE_BET = 1;
     */
    OP_CODE_BET = 1,
    /**
     * 跟注
     *
     * @generated from protobuf enum value: OP_CODE_CALL = 2;
     */
    OP_CODE_CALL = 2,
    /**
     * 弃牌
     *
     * @generated from protobuf enum value: OP_CODE_FOLD = 3;
     */
    OP_CODE_FOLD = 3,
    /**
     * 过牌,本轮到自己前尚无玩家下注时可用
     *
     * @generated from protobuf enum value: OP_CODE_CHECK = 4;
     */
    OP_CODE_CHECK = 4,
    /**
     * 加注
     *
     * @generated from protobuf enum value: OP_CODE_RAISE = 5;
     */
    OP_CODE_RAISE = 5,
    /**
     * 梭哈
     *
     * @generated from protobuf enum value: OP_CODE_ALL_IN = 6;
     */
    OP_CODE_ALL_IN = 6,
    /**
     * 盲注
     *
     * @generated from protobuf enum value: OP_CODE_BLIND = 7;
     */
    OP_CODE_BLIND = 7,
    /**
     * 前注
     *
     * @generated from protobuf enum value: OP_CODE_ANTE = 8;
     */
    OP_CODE_ANTE = 8
}
/**
 * @generated from protobuf enum bpt.common.AnteType
 */
export enum AnteType {
    /**
     * 标准前注，每个玩家交前注
     *
     * @generated from protobuf enum value: AnteTypeStandard = 0;
     */
    AnteTypeStandard = 0,
    /**
     * 大盲前注，仅由大盲交前注
     *
     * @generated from protobuf enum value: AnteTypeBigBlind = 1;
     */
    AnteTypeBigBlind = 1,
    /**
     * 庄家前注，由庄家交前注
     *
     * @generated from protobuf enum value: AnteTypeDealer = 2;
     */
    AnteTypeDealer = 2
}
/**
 * (除round外按顺序往下)
 *  牌局流程:pre_flop(选庄)->ante(交前注、盲注、发牌)->call(pre_flop)flop->call(flop)->turn->call(turn)->river->call(river)->showdown
 *
 * @generated from protobuf enum bpt.common.RoundStage
 */
export enum RoundStage {
    /**
     * @generated from protobuf enum value: ROUND_STAGE_UNKNOWN = 0;
     */
    ROUND_STAGE_UNKNOWN = 0,
    /**
     * 由庄家起手发私牌，顺时针发放，每人两张
     *
     * @generated from protobuf enum value: ROUND_STAGE_PRE_FLOP = 1;
     */
    ROUND_STAGE_PRE_FLOP = 1,
    /**
     * 发放三张公共牌
     *
     * @generated from protobuf enum value: ROUND_STAGE_FLOP = 2;
     */
    ROUND_STAGE_FLOP = 2,
    /**
     * 叫/押注阶段
     *
     * @generated from protobuf enum value: ROUND_STAGE_CALL = 3;
     */
    ROUND_STAGE_CALL = 3,
    /**
     * 发放第四张公共牌
     *
     * @generated from protobuf enum value: ROUND_STAGE_TURN = 4;
     */
    ROUND_STAGE_TURN = 4,
    /**
     * 发放第五张公共牌
     *
     * @generated from protobuf enum value: ROUND_STAGE_RIVER = 5;
     */
    ROUND_STAGE_RIVER = 5,
    /**
     * 剩余玩家比牌、结算
     *
     * @generated from protobuf enum value: ROUND_STAGE_SHOWDOWN = 6;
     */
    ROUND_STAGE_SHOWDOWN = 6,
    /**
     * 前注
     *
     * @generated from protobuf enum value: ROUND_STAGE_ANTE = 11;
     */
    ROUND_STAGE_ANTE = 11,
    /**
     * 叫注圈 用于区分叫注轮
     *
     * @generated from protobuf enum value: ROUND_STAGE_PRE_FLOP_ROUND = 7;
     */
    ROUND_STAGE_PRE_FLOP_ROUND = 7,
    /**
     * @generated from protobuf enum value: ROUND_STAGE_FLOP_ROUND = 8;
     */
    ROUND_STAGE_FLOP_ROUND = 8,
    /**
     * @generated from protobuf enum value: ROUND_STAGE_TURN_ROUND = 9;
     */
    ROUND_STAGE_TURN_ROUND = 9,
    /**
     * @generated from protobuf enum value: ROUND_STAGE_RIVER_ROUND = 10;
     */
    ROUND_STAGE_RIVER_ROUND = 10
}
/**
 * @generated from protobuf enum bpt.common.TableState
 */
export enum TableState {
    /**
     * @generated from protobuf enum value: TABLE_STATE_UNKNOWN = 0;
     */
    TABLE_STATE_UNKNOWN = 0,
    /**
     * 就绪
     *
     * @generated from protobuf enum value: TABLE_STATE_READY = 1;
     */
    TABLE_STATE_READY = 1,
    /**
     * 在打
     *
     * @generated from protobuf enum value: TABLE_STATE_PLAYING = 2;
     */
    TABLE_STATE_PLAYING = 2,
    /**
     * 异常
     *
     * @generated from protobuf enum value: TABLE_STATE_PANIC = 3;
     */
    TABLE_STATE_PANIC = 3,
    /**
     * 释放
     *
     * @generated from protobuf enum value: TABLE_STATE_FREE = 4;
     */
    TABLE_STATE_FREE = 4
}
/**
 * @generated from protobuf enum bpt.common.CT
 */
export enum CT {
    /**
     * @generated from protobuf enum value: CT_UNKNOWN = 0;
     */
    CT_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: CT_HIGH_CARD = 1;
     */
    CT_HIGH_CARD = 1,
    /**
     * @generated from protobuf enum value: CT_PAIR = 2;
     */
    CT_PAIR = 2,
    /**
     * @generated from protobuf enum value: CT_TWO_PAIRS = 3;
     */
    CT_TWO_PAIRS = 3,
    /**
     * @generated from protobuf enum value: CT_THREE_OF_A_KIND = 4;
     */
    CT_THREE_OF_A_KIND = 4,
    /**
     * CT_STRAIGHT 顺子
     *
     * @generated from protobuf enum value: CT_STRAIGHT = 5;
     */
    CT_STRAIGHT = 5,
    /**
     * CT_FLUSH 同花
     *
     * @generated from protobuf enum value: CT_FLUSH = 6;
     */
    CT_FLUSH = 6,
    /**
     * CT_FULL_HOUSE 葫芦
     *
     * @generated from protobuf enum value: CT_FULL_HOUSE = 7;
     */
    CT_FULL_HOUSE = 7,
    /**
     * @generated from protobuf enum value: CT_FOUR_OF_A_KIND = 8;
     */
    CT_FOUR_OF_A_KIND = 8,
    /**
     * CT_STRAIGHT_FLUSH 同花顺
     *
     * @generated from protobuf enum value: CT_STRAIGHT_FLUSH = 9;
     */
    CT_STRAIGHT_FLUSH = 9,
    /**
     * 皇家同花顺
     *
     * @generated from protobuf enum value: CT_ROYAL_FLUSH = 10;
     */
    CT_ROYAL_FLUSH = 10
}
// ///////////////////////// dispatchorder的操作类型枚举///////////////////

/**
 * @generated from protobuf enum bpt.common.DeliveryServiceType
 */
export enum DeliveryServiceType {
    /**
     * @generated from protobuf enum value: DeliveryServiceTypeUnknown = 0;
     */
    DeliveryServiceTypeUnknown = 0,
    /**
     * sng比赛
     *
     * [(kitproto.service_tag) = "bpt.match.match.v1"];
     *
     * @generated from protobuf enum value: DeliveryServiceTypeMatch = 1;
     */
    DeliveryServiceTypeMatch = 1,
    /**
     * mtt比赛
     *
     * [(kitproto.service_tag) = "bpt.match.mttmatch.v1"];
     *
     * @generated from protobuf enum value: DeliveryServiceTypeMttMatch = 2;
     */
    DeliveryServiceTypeMttMatch = 2
}
/**
 * @generated from protobuf enum bpt.common.SceneType
 */
export enum SceneType {
    /**
     * 1-255为比赛相关场景占位
     *
     * @generated from protobuf enum value: SceneTypeUnknown = 0;
     */
    SceneTypeUnknown = 0,
    /**
     * 报名
     *
     * @generated from protobuf enum value: SceneTypeRegister = 1;
     */
    SceneTypeRegister = 1,
    /**
     * 延时报名
     *
     * @generated from protobuf enum value: SceneTypeDelayRegister = 2;
     */
    SceneTypeDelayRegister = 2,
    /**
     * ReBuy
     *
     * @generated from protobuf enum value: SceneTypeReBuy = 3;
     */
    SceneTypeReBuy = 3,
    /**
     * ReEntry
     *
     * @generated from protobuf enum value: SceneTypeReEntry = 4;
     */
    SceneTypeReEntry = 4,
    /**
     * AddOn
     *
     * @generated from protobuf enum value: SceneTypeAddOn = 5;
     */
    SceneTypeAddOn = 5,
    /**
     * 取消报名
     *
     * @generated from protobuf enum value: SceneTypeUnRegister = 6;
     */
    SceneTypeUnRegister = 6,
    /**
     * 现金桌买完筹码进桌
     *
     * @generated from protobuf enum value: SceneTypeCashJoinTable = 7;
     */
    SceneTypeCashJoinTable = 7,
    /**
     * 现金桌买筹码
     *
     * 256-511为...
     *
     * @generated from protobuf enum value: SceneTypeCashBuyIn = 8;
     */
    SceneTypeCashBuyIn = 8
}
/**
 * 多语言模板相关
 *
 * @generated from protobuf enum bpt.common.FieldType
 */
export enum FieldType {
    /**
     * 值类型，直接填充数据
     *
     * @generated from protobuf enum value: FieldValue = 0;
     */
    FieldValue = 0,
    /**
     * 模板类型，该字段是一个模板，需要渲染后再填充到邮件
     *
     * @generated from protobuf enum value: FieldTemplate = 1;
     */
    FieldTemplate = 1,
    /**
     * 多语言类型，需要根据value给的值从I18n中获取对应语言的翻译
     *
     * @generated from protobuf enum value: FieldI18nField = 2;
     */
    FieldI18nField = 2
}
/**
 * @generated from protobuf enum bpt.common.TableViewChangeType
 */
export enum TableViewChangeType {
    /**
     * @generated from protobuf enum value: TABLE_VIEW_CHANGE_TYPE_UNKNOWN = 0;
     */
    TABLE_VIEW_CHANGE_TYPE_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: TABLE_VIEW_CHANGE_TYPE_ADD = 1;
     */
    TABLE_VIEW_CHANGE_TYPE_ADD = 1,
    /**
     * @generated from protobuf enum value: TABLE_VIEW_CHANGE_TYPE_REMOVE = 2;
     */
    TABLE_VIEW_CHANGE_TYPE_REMOVE = 2
}
/**
 * @generated from protobuf enum bpt.common.TableViewChangeReason
 */
export enum TableViewChangeReason {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * 前端主动操作
     *
     * @generated from protobuf enum value: FRONT_OPERATION = 1;
     */
    FRONT_OPERATION = 1,
    /**
     * 比赛开始，服务的主动推送
     *
     * @generated from protobuf enum value: MATCH_START = 2;
     */
    MATCH_START = 2,
    /**
     * 比赛结束，服务的主动推送
     *
     * @generated from protobuf enum value: MATCH_END = 3;
     */
    MATCH_END = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class HandleTableExceptionReq$Type extends MessageType<HandleTableExceptionReq> {
    constructor() {
        super("bpt.common.HandleTableExceptionReq", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<HandleTableExceptionReq>): HandleTableExceptionReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<HandleTableExceptionReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandleTableExceptionReq): HandleTableExceptionReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HandleTableExceptionReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.HandleTableExceptionReq
 */
export const HandleTableExceptionReq = new HandleTableExceptionReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandleTableResultReq$Type extends MessageType<HandleTableResultReq> {
    constructor() {
        super("bpt.common.HandleTableResultReq", [
            { no: 1, name: "gameResult", kind: "message", T: () => GameResult },
            { no: 2, name: "releaseDelay", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<HandleTableResultReq>): HandleTableResultReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.releaseDelay = 0;
        if (value !== undefined)
            reflectionMergePartial<HandleTableResultReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandleTableResultReq): HandleTableResultReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.GameResult gameResult */ 1:
                    message.gameResult = GameResult.internalBinaryRead(reader, reader.uint32(), options, message.gameResult);
                    break;
                case /* int64 releaseDelay */ 2:
                    message.releaseDelay = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HandleTableResultReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.GameResult gameResult = 1; */
        if (message.gameResult)
            GameResult.internalBinaryWrite(message.gameResult, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 releaseDelay = 2; */
        if (message.releaseDelay !== 0)
            writer.tag(2, WireType.Varint).int64(message.releaseDelay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.HandleTableResultReq
 */
export const HandleTableResultReq = new HandleTableResultReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandleRoundResultReq$Type extends MessageType<HandleRoundResultReq> {
    constructor() {
        super("bpt.common.HandleRoundResultReq", []);
    }
    create(value?: PartialMessage<HandleRoundResultReq>): HandleRoundResultReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<HandleRoundResultReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandleRoundResultReq): HandleRoundResultReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: HandleRoundResultReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.HandleRoundResultReq
 */
export const HandleRoundResultReq = new HandleRoundResultReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandleTableInfoSyncReq$Type extends MessageType<HandleTableInfoSyncReq> {
    constructor() {
        super("bpt.common.HandleTableInfoSyncReq", []);
    }
    create(value?: PartialMessage<HandleTableInfoSyncReq>): HandleTableInfoSyncReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<HandleTableInfoSyncReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandleTableInfoSyncReq): HandleTableInfoSyncReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: HandleTableInfoSyncReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.HandleTableInfoSyncReq
 */
export const HandleTableInfoSyncReq = new HandleTableInfoSyncReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameResult$Type extends MessageType<GameResult> {
    constructor() {
        super("bpt.common.GameResult", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GameResultItem },
            { no: 5, name: "tableGameData", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GameResult>): GameResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.items = [];
        message.tableGameData = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<GameResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GameResult): GameResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* repeated bpt.common.GameResultItem items */ 4:
                    message.items.push(GameResultItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bytes tableGameData */ 5:
                    message.tableGameData = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GameResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* repeated bpt.common.GameResultItem items = 4; */
        for (let i = 0; i < message.items.length; i++)
            GameResultItem.internalBinaryWrite(message.items[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bytes tableGameData = 5; */
        if (message.tableGameData.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.tableGameData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.GameResult
 */
export const GameResult = new GameResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameResultItem$Type extends MessageType<GameResultItem> {
    constructor() {
        super("bpt.common.GameResultItem", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "role", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "scoreRatio", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "gameResult", kind: "enum", T: () => ["bpt.common.GameResultType", GameResultType] },
            { no: 5, name: "playTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "isTimeOut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "allStep", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "handCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "isHighlight", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "entrustCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "entrustedCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "endEntrustStatus", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "allStepCost", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "metas", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<GameResultItem>): GameResultItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.role = 0;
        message.scoreRatio = 0;
        message.gameResult = 0;
        message.playTime = 0;
        message.isTimeOut = false;
        message.allStep = 0;
        message.handCnt = 0;
        message.isHighlight = false;
        message.entrustCnt = 0;
        message.entrustedCnt = 0;
        message.endEntrustStatus = 0;
        message.allStepCost = 0;
        message.metas = {};
        if (value !== undefined)
            reflectionMergePartial<GameResultItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GameResultItem): GameResultItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 role */ 2:
                    message.role = reader.int64().toNumber();
                    break;
                case /* int64 scoreRatio */ 3:
                    message.scoreRatio = reader.int64().toNumber();
                    break;
                case /* bpt.common.GameResultType gameResult */ 4:
                    message.gameResult = reader.int32();
                    break;
                case /* int64 playTime */ 5:
                    message.playTime = reader.int64().toNumber();
                    break;
                case /* bool isTimeOut */ 6:
                    message.isTimeOut = reader.bool();
                    break;
                case /* int64 allStep */ 7:
                    message.allStep = reader.int64().toNumber();
                    break;
                case /* int64 handCnt */ 8:
                    message.handCnt = reader.int64().toNumber();
                    break;
                case /* bool isHighlight */ 9:
                    message.isHighlight = reader.bool();
                    break;
                case /* int64 entrustCnt */ 10:
                    message.entrustCnt = reader.int64().toNumber();
                    break;
                case /* int64 entrustedCnt */ 11:
                    message.entrustedCnt = reader.int64().toNumber();
                    break;
                case /* int64 endEntrustStatus */ 12:
                    message.endEntrustStatus = reader.int64().toNumber();
                    break;
                case /* int64 allStepCost */ 13:
                    message.allStepCost = reader.int64().toNumber();
                    break;
                case /* map<string, string> metas */ 14:
                    this.binaryReadMap14(message.metas, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap14(map: GameResultItem["metas"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GameResultItem["metas"] | undefined, val: GameResultItem["metas"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.GameResultItem.metas");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: GameResultItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 role = 2; */
        if (message.role !== 0)
            writer.tag(2, WireType.Varint).int64(message.role);
        /* int64 scoreRatio = 3; */
        if (message.scoreRatio !== 0)
            writer.tag(3, WireType.Varint).int64(message.scoreRatio);
        /* bpt.common.GameResultType gameResult = 4; */
        if (message.gameResult !== 0)
            writer.tag(4, WireType.Varint).int32(message.gameResult);
        /* int64 playTime = 5; */
        if (message.playTime !== 0)
            writer.tag(5, WireType.Varint).int64(message.playTime);
        /* bool isTimeOut = 6; */
        if (message.isTimeOut !== false)
            writer.tag(6, WireType.Varint).bool(message.isTimeOut);
        /* int64 allStep = 7; */
        if (message.allStep !== 0)
            writer.tag(7, WireType.Varint).int64(message.allStep);
        /* int64 handCnt = 8; */
        if (message.handCnt !== 0)
            writer.tag(8, WireType.Varint).int64(message.handCnt);
        /* bool isHighlight = 9; */
        if (message.isHighlight !== false)
            writer.tag(9, WireType.Varint).bool(message.isHighlight);
        /* int64 entrustCnt = 10; */
        if (message.entrustCnt !== 0)
            writer.tag(10, WireType.Varint).int64(message.entrustCnt);
        /* int64 entrustedCnt = 11; */
        if (message.entrustedCnt !== 0)
            writer.tag(11, WireType.Varint).int64(message.entrustedCnt);
        /* int64 endEntrustStatus = 12; */
        if (message.endEntrustStatus !== 0)
            writer.tag(12, WireType.Varint).int64(message.endEntrustStatus);
        /* int64 allStepCost = 13; */
        if (message.allStepCost !== 0)
            writer.tag(13, WireType.Varint).int64(message.allStepCost);
        /* map<string, string> metas = 14; */
        for (let k of globalThis.Object.keys(message.metas))
            writer.tag(14, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metas[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.GameResultItem
 */
export const GameResultItem = new GameResultItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameEnd$Type extends MessageType<GameEnd> {
    constructor() {
        super("bpt.common.GameEnd", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GameEnd>): GameEnd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<GameEnd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GameEnd): GameEnd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GameEnd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.GameEnd
 */
export const GameEnd = new GameEnd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HandleTableEndReq$Type extends MessageType<HandleTableEndReq> {
    constructor() {
        super("bpt.common.HandleTableEndReq", [
            { no: 1, name: "gameEnd", kind: "message", T: () => GameEnd }
        ]);
    }
    create(value?: PartialMessage<HandleTableEndReq>): HandleTableEndReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<HandleTableEndReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HandleTableEndReq): HandleTableEndReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.GameEnd gameEnd */ 1:
                    message.gameEnd = GameEnd.internalBinaryRead(reader, reader.uint32(), options, message.gameEnd);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HandleTableEndReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.GameEnd gameEnd = 1; */
        if (message.gameEnd)
            GameEnd.internalBinaryWrite(message.gameEnd, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.HandleTableEndReq
 */
export const HandleTableEndReq = new HandleTableEndReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EliminateReq$Type extends MessageType<EliminateReq> {
    constructor() {
        super("bpt.common.EliminateReq", [
            { no: 1, name: "foldUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "foldUids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<EliminateReq>): EliminateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.foldUid = 0;
        message.foldUids = [];
        if (value !== undefined)
            reflectionMergePartial<EliminateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EliminateReq): EliminateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 foldUid */ 1:
                    message.foldUid = reader.int64().toNumber();
                    break;
                case /* repeated int64 foldUids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.foldUids.push(reader.int64().toNumber());
                    else
                        message.foldUids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EliminateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 foldUid = 1; */
        if (message.foldUid !== 0)
            writer.tag(1, WireType.Varint).int64(message.foldUid);
        /* repeated int64 foldUids = 2; */
        if (message.foldUids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.foldUids.length; i++)
                writer.int64(message.foldUids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.EliminateReq
 */
export const EliminateReq = new EliminateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FoldReq$Type extends MessageType<FoldReq> {
    constructor() {
        super("bpt.common.FoldReq", [
            { no: 3, name: "foldUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "chips", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "managedState", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "animTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "useShuffleCard", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FoldReq>): FoldReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.foldUid = 0;
        message.chips = 0;
        message.managedState = 0;
        message.seq = 0;
        message.animTime = 0;
        message.timeCardNum = 0;
        message.useShuffleCard = false;
        if (value !== undefined)
            reflectionMergePartial<FoldReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FoldReq): FoldReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 foldUid */ 3:
                    message.foldUid = reader.int64().toNumber();
                    break;
                case /* int64 chips */ 4:
                    message.chips = reader.int64().toNumber();
                    break;
                case /* int32 managedState */ 5:
                    message.managedState = reader.int32();
                    break;
                case /* int64 seq */ 6:
                    message.seq = reader.int64().toNumber();
                    break;
                case /* int64 animTime */ 7:
                    message.animTime = reader.int64().toNumber();
                    break;
                case /* int32 timeCardNum */ 8:
                    message.timeCardNum = reader.int32();
                    break;
                case /* bool useShuffleCard */ 9:
                    message.useShuffleCard = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FoldReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 foldUid = 3; */
        if (message.foldUid !== 0)
            writer.tag(3, WireType.Varint).int64(message.foldUid);
        /* int64 chips = 4; */
        if (message.chips !== 0)
            writer.tag(4, WireType.Varint).int64(message.chips);
        /* int32 managedState = 5; */
        if (message.managedState !== 0)
            writer.tag(5, WireType.Varint).int32(message.managedState);
        /* int64 seq = 6; */
        if (message.seq !== 0)
            writer.tag(6, WireType.Varint).int64(message.seq);
        /* int64 animTime = 7; */
        if (message.animTime !== 0)
            writer.tag(7, WireType.Varint).int64(message.animTime);
        /* int32 timeCardNum = 8; */
        if (message.timeCardNum !== 0)
            writer.tag(8, WireType.Varint).int32(message.timeCardNum);
        /* bool useShuffleCard = 9; */
        if (message.useShuffleCard !== false)
            writer.tag(9, WireType.Varint).bool(message.useShuffleCard);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.FoldReq
 */
export const FoldReq = new FoldReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FoldResp$Type extends MessageType<FoldResp> {
    constructor() {
        super("bpt.common.FoldResp", []);
    }
    create(value?: PartialMessage<FoldResp>): FoldResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<FoldResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FoldResp): FoldResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FoldResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.FoldResp
 */
export const FoldResp = new FoldResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManageReq$Type extends MessageType<ManageReq> {
    constructor() {
        super("bpt.common.ManageReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "managedState", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ManageReq>): ManageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.managedState = 0;
        message.seq = 0;
        if (value !== undefined)
            reflectionMergePartial<ManageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManageReq): ManageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int32 managedState */ 2:
                    message.managedState = reader.int32();
                    break;
                case /* int64 seq */ 6:
                    message.seq = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ManageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int32 managedState = 2; */
        if (message.managedState !== 0)
            writer.tag(2, WireType.Varint).int32(message.managedState);
        /* int64 seq = 6; */
        if (message.seq !== 0)
            writer.tag(6, WireType.Varint).int64(message.seq);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.ManageReq
 */
export const ManageReq = new ManageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableOpReq$Type extends MessageType<TableOpReq> {
    constructor() {
        super("bpt.common.TableOpReq", [
            { no: 2, name: "tKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "opType", kind: "enum", T: () => ["bpt.common.TableOpType", TableOpType] },
            { no: 4, name: "req", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 5, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TableOpReq>): TableOpReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tKey = "";
        message.opType = 0;
        message.req = new Uint8Array(0);
        message.matchKey = "";
        message.seq = 0;
        if (value !== undefined)
            reflectionMergePartial<TableOpReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableOpReq): TableOpReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tKey */ 2:
                    message.tKey = reader.string();
                    break;
                case /* bpt.common.TableOpType opType */ 3:
                    message.opType = reader.int32();
                    break;
                case /* bytes req */ 4:
                    message.req = reader.bytes();
                    break;
                case /* string matchKey */ 5:
                    message.matchKey = reader.string();
                    break;
                case /* int64 seq */ 6:
                    message.seq = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableOpReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tKey = 2; */
        if (message.tKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tKey);
        /* bpt.common.TableOpType opType = 3; */
        if (message.opType !== 0)
            writer.tag(3, WireType.Varint).int32(message.opType);
        /* bytes req = 4; */
        if (message.req.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.req);
        /* string matchKey = 5; */
        if (message.matchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchKey);
        /* int64 seq = 6; */
        if (message.seq !== 0)
            writer.tag(6, WireType.Varint).int64(message.seq);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TableOpReq
 */
export const TableOpReq = new TableOpReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableOpResp$Type extends MessageType<TableOpResp> {
    constructor() {
        super("bpt.common.TableOpResp", [
            { no: 1, name: "opType", kind: "enum", T: () => ["bpt.common.TableOpType", TableOpType] },
            { no: 2, name: "resp", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TableOpResp>): TableOpResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.opType = 0;
        message.resp = "";
        if (value !== undefined)
            reflectionMergePartial<TableOpResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableOpResp): TableOpResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.TableOpType opType */ 1:
                    message.opType = reader.int32();
                    break;
                case /* string resp */ 2:
                    message.resp = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableOpResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.TableOpType opType = 1; */
        if (message.opType !== 0)
            writer.tag(1, WireType.Varint).int32(message.opType);
        /* string resp = 2; */
        if (message.resp !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.resp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TableOpResp
 */
export const TableOpResp = new TableOpResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LogicConfig$Type extends MessageType<LogicConfig> {
    constructor() {
        super("bpt.common.LogicConfig", [
            { no: 1, name: "timeOutCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "playTime", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "entryFee", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "timeCardDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<LogicConfig>): LogicConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.timeOutCnt = 0;
        message.playTime = 0;
        message.entryFee = 0;
        message.timeCardDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<LogicConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LogicConfig): LogicConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 timeOutCnt */ 1:
                    message.timeOutCnt = reader.int32();
                    break;
                case /* int32 playTime */ 2:
                    message.playTime = reader.int32();
                    break;
                case /* uint64 entryFee */ 3:
                    message.entryFee = reader.uint64().toNumber();
                    break;
                case /* int64 timeCardDuration */ 4:
                    message.timeCardDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LogicConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 timeOutCnt = 1; */
        if (message.timeOutCnt !== 0)
            writer.tag(1, WireType.Varint).int32(message.timeOutCnt);
        /* int32 playTime = 2; */
        if (message.playTime !== 0)
            writer.tag(2, WireType.Varint).int32(message.playTime);
        /* uint64 entryFee = 3; */
        if (message.entryFee !== 0)
            writer.tag(3, WireType.Varint).uint64(message.entryFee);
        /* int64 timeCardDuration = 4; */
        if (message.timeCardDuration !== 0)
            writer.tag(4, WireType.Varint).int64(message.timeCardDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.LogicConfig
 */
export const LogicConfig = new LogicConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlindConfig$Type extends MessageType<BlindConfig> {
    constructor() {
        super("bpt.common.BlindConfig", [
            { no: 1, name: "smallBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "bigBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "raiseBlindInterval", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "minChipParticle", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "ante", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "blindLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "anteType", kind: "enum", T: () => ["bpt.common.AnteType", AnteType] }
        ]);
    }
    create(value?: PartialMessage<BlindConfig>): BlindConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.smallBlind = 0;
        message.bigBlind = 0;
        message.raiseBlindInterval = 0;
        message.minChipParticle = 0;
        message.ante = 0;
        message.blindLevel = 0;
        message.anteType = 0;
        if (value !== undefined)
            reflectionMergePartial<BlindConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlindConfig): BlindConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 smallBlind */ 1:
                    message.smallBlind = reader.uint64().toNumber();
                    break;
                case /* uint64 bigBlind */ 2:
                    message.bigBlind = reader.uint64().toNumber();
                    break;
                case /* int64 raiseBlindInterval */ 3:
                    message.raiseBlindInterval = reader.int64().toNumber();
                    break;
                case /* uint64 minChipParticle */ 4:
                    message.minChipParticle = reader.uint64().toNumber();
                    break;
                case /* uint64 ante */ 5:
                    message.ante = reader.uint64().toNumber();
                    break;
                case /* int32 blindLevel */ 6:
                    message.blindLevel = reader.int32();
                    break;
                case /* bpt.common.AnteType anteType */ 7:
                    message.anteType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlindConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 smallBlind = 1; */
        if (message.smallBlind !== 0)
            writer.tag(1, WireType.Varint).uint64(message.smallBlind);
        /* uint64 bigBlind = 2; */
        if (message.bigBlind !== 0)
            writer.tag(2, WireType.Varint).uint64(message.bigBlind);
        /* int64 raiseBlindInterval = 3; */
        if (message.raiseBlindInterval !== 0)
            writer.tag(3, WireType.Varint).int64(message.raiseBlindInterval);
        /* uint64 minChipParticle = 4; */
        if (message.minChipParticle !== 0)
            writer.tag(4, WireType.Varint).uint64(message.minChipParticle);
        /* uint64 ante = 5; */
        if (message.ante !== 0)
            writer.tag(5, WireType.Varint).uint64(message.ante);
        /* int32 blindLevel = 6; */
        if (message.blindLevel !== 0)
            writer.tag(6, WireType.Varint).int32(message.blindLevel);
        /* bpt.common.AnteType anteType = 7; */
        if (message.anteType !== 0)
            writer.tag(7, WireType.Varint).int32(message.anteType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.BlindConfig
 */
export const BlindConfig = new BlindConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgRunTable$Type extends MessageType<MsgRunTable> {
    constructor() {
        super("bpt.common.MsgRunTable", [
            { no: 1, name: "logicConfig", kind: "message", T: () => LogicConfig },
            { no: 2, name: "blindConfig", kind: "message", T: () => BlindConfig },
            { no: 3, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserInfo }
        ]);
    }
    create(value?: PartialMessage<MsgRunTable>): MsgRunTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<MsgRunTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgRunTable): MsgRunTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.LogicConfig logicConfig */ 1:
                    message.logicConfig = LogicConfig.internalBinaryRead(reader, reader.uint32(), options, message.logicConfig);
                    break;
                case /* bpt.common.BlindConfig blindConfig */ 2:
                    message.blindConfig = BlindConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindConfig);
                    break;
                case /* repeated bpt.common.UserInfo users */ 3:
                    message.users.push(UserInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgRunTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.LogicConfig logicConfig = 1; */
        if (message.logicConfig)
            LogicConfig.internalBinaryWrite(message.logicConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.BlindConfig blindConfig = 2; */
        if (message.blindConfig)
            BlindConfig.internalBinaryWrite(message.blindConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.UserInfo users = 3; */
        for (let i = 0; i < message.users.length; i++)
            UserInfo.internalBinaryWrite(message.users[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgRunTable
 */
export const MsgRunTable = new MsgRunTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgSyncUserData$Type extends MessageType<MsgSyncUserData> {
    constructor() {
        super("bpt.common.MsgSyncUserData", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserInfo }
        ]);
    }
    create(value?: PartialMessage<MsgSyncUserData>): MsgSyncUserData {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<MsgSyncUserData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgSyncUserData): MsgSyncUserData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.UserInfo users */ 1:
                    message.users.push(UserInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgSyncUserData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.UserInfo users = 1; */
        for (let i = 0; i < message.users.length; i++)
            UserInfo.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgSyncUserData
 */
export const MsgSyncUserData = new MsgSyncUserData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgBatchRunTable$Type extends MessageType<MsgBatchRunTable> {
    constructor() {
        super("bpt.common.MsgBatchRunTable", [
            { no: 1, name: "logicConfig", kind: "message", T: () => LogicConfig },
            { no: 2, name: "blindConfig", kind: "message", T: () => BlindConfig }
        ]);
    }
    create(value?: PartialMessage<MsgBatchRunTable>): MsgBatchRunTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgBatchRunTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgBatchRunTable): MsgBatchRunTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.LogicConfig logicConfig */ 1:
                    message.logicConfig = LogicConfig.internalBinaryRead(reader, reader.uint32(), options, message.logicConfig);
                    break;
                case /* bpt.common.BlindConfig blindConfig */ 2:
                    message.blindConfig = BlindConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgBatchRunTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.LogicConfig logicConfig = 1; */
        if (message.logicConfig)
            LogicConfig.internalBinaryWrite(message.logicConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.BlindConfig blindConfig = 2; */
        if (message.blindConfig)
            BlindConfig.internalBinaryWrite(message.blindConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgBatchRunTable
 */
export const MsgBatchRunTable = new MsgBatchRunTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfo$Type extends MessageType<UserInfo> {
    constructor() {
        super("bpt.common.UserInfo", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "isLeave", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "appID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "rookieNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "appVersion", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 8, name: "matchTeam", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "minCardScore", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "priorities", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "chip", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "managedState", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "seat", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "waitForPlay", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "isAddOn", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "isReBuy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "shuffleInfo", kind: "message", T: () => ShuffleInfo }
        ]);
    }
    create(value?: PartialMessage<UserInfo>): UserInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.isLeave = false;
        message.appID = "";
        message.rookieNum = 0;
        message.appVersion = "";
        message.metadata = {};
        message.matchTeam = "";
        message.minCardScore = 0;
        message.priorities = 0;
        message.chip = 0;
        message.managedState = 0;
        message.seat = 0;
        message.waitForPlay = false;
        message.isAddOn = false;
        message.isReBuy = false;
        message.timeCardNum = 0;
        if (value !== undefined)
            reflectionMergePartial<UserInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInfo): UserInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bool isLeave */ 3:
                    message.isLeave = reader.bool();
                    break;
                case /* string appID */ 4:
                    message.appID = reader.string();
                    break;
                case /* int32 rookieNum */ 5:
                    message.rookieNum = reader.int32();
                    break;
                case /* string appVersion */ 6:
                    message.appVersion = reader.string();
                    break;
                case /* map<string, string> metadata */ 7:
                    this.binaryReadMap7(message.metadata, reader, options);
                    break;
                case /* string matchTeam */ 8:
                    message.matchTeam = reader.string();
                    break;
                case /* int32 minCardScore */ 9:
                    message.minCardScore = reader.int32();
                    break;
                case /* int32 priorities */ 10:
                    message.priorities = reader.int32();
                    break;
                case /* uint64 chip */ 11:
                    message.chip = reader.uint64().toNumber();
                    break;
                case /* int32 managedState */ 12:
                    message.managedState = reader.int32();
                    break;
                case /* int32 seat */ 13:
                    message.seat = reader.int32();
                    break;
                case /* bool waitForPlay */ 14:
                    message.waitForPlay = reader.bool();
                    break;
                case /* bool isAddOn */ 15:
                    message.isAddOn = reader.bool();
                    break;
                case /* bool isReBuy */ 16:
                    message.isReBuy = reader.bool();
                    break;
                case /* int32 timeCardNum */ 17:
                    message.timeCardNum = reader.int32();
                    break;
                case /* bpt.common.ShuffleInfo shuffleInfo */ 18:
                    message.shuffleInfo = ShuffleInfo.internalBinaryRead(reader, reader.uint32(), options, message.shuffleInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: UserInfo["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof UserInfo["metadata"] | undefined, val: UserInfo["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.UserInfo.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: UserInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bool isLeave = 3; */
        if (message.isLeave !== false)
            writer.tag(3, WireType.Varint).bool(message.isLeave);
        /* string appID = 4; */
        if (message.appID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.appID);
        /* int32 rookieNum = 5; */
        if (message.rookieNum !== 0)
            writer.tag(5, WireType.Varint).int32(message.rookieNum);
        /* string appVersion = 6; */
        if (message.appVersion !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.appVersion);
        /* map<string, string> metadata = 7; */
        for (let k of globalThis.Object.keys(message.metadata))
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        /* string matchTeam = 8; */
        if (message.matchTeam !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.matchTeam);
        /* int32 minCardScore = 9; */
        if (message.minCardScore !== 0)
            writer.tag(9, WireType.Varint).int32(message.minCardScore);
        /* int32 priorities = 10; */
        if (message.priorities !== 0)
            writer.tag(10, WireType.Varint).int32(message.priorities);
        /* uint64 chip = 11; */
        if (message.chip !== 0)
            writer.tag(11, WireType.Varint).uint64(message.chip);
        /* int32 managedState = 12; */
        if (message.managedState !== 0)
            writer.tag(12, WireType.Varint).int32(message.managedState);
        /* int32 seat = 13; */
        if (message.seat !== 0)
            writer.tag(13, WireType.Varint).int32(message.seat);
        /* bool waitForPlay = 14; */
        if (message.waitForPlay !== false)
            writer.tag(14, WireType.Varint).bool(message.waitForPlay);
        /* bool isAddOn = 15; */
        if (message.isAddOn !== false)
            writer.tag(15, WireType.Varint).bool(message.isAddOn);
        /* bool isReBuy = 16; */
        if (message.isReBuy !== false)
            writer.tag(16, WireType.Varint).bool(message.isReBuy);
        /* int32 timeCardNum = 17; */
        if (message.timeCardNum !== 0)
            writer.tag(17, WireType.Varint).int32(message.timeCardNum);
        /* bpt.common.ShuffleInfo shuffleInfo = 18; */
        if (message.shuffleInfo)
            ShuffleInfo.internalBinaryWrite(message.shuffleInfo, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.UserInfo
 */
export const UserInfo = new UserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShuffleInfo$Type extends MessageType<ShuffleInfo> {
    constructor() {
        super("bpt.common.ShuffleInfo", [
            { no: 1, name: "useShuffleCard", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "inputText", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ShuffleInfo>): ShuffleInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.useShuffleCard = false;
        message.inputText = "";
        if (value !== undefined)
            reflectionMergePartial<ShuffleInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShuffleInfo): ShuffleInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool useShuffleCard */ 1:
                    message.useShuffleCard = reader.bool();
                    break;
                case /* string inputText */ 2:
                    message.inputText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShuffleInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool useShuffleCard = 1; */
        if (message.useShuffleCard !== false)
            writer.tag(1, WireType.Varint).bool(message.useShuffleCard);
        /* string inputText = 2; */
        if (message.inputText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.inputText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.ShuffleInfo
 */
export const ShuffleInfo = new ShuffleInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClearManagedUserCbReq$Type extends MessageType<ClearManagedUserCbReq> {
    constructor() {
        super("bpt.common.ClearManagedUserCbReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ClearManagedUserCbReq>): ClearManagedUserCbReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.tableKey = "";
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<ClearManagedUserCbReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClearManagedUserCbReq): ClearManagedUserCbReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string tableKey */ 2:
                    message.tableKey = reader.string();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClearManagedUserCbReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string tableKey = 2; */
        if (message.tableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tableKey);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.ClearManagedUserCbReq
 */
export const ClearManagedUserCbReq = new ClearManagedUserCbReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableInfoReq$Type extends MessageType<TableInfoReq> {
    constructor() {
        super("bpt.common.TableInfoReq", [
            { no: 1, name: "tableInfo", kind: "message", T: () => MsgTableInfo },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TableInfoReq>): TableInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.seq = 0;
        if (value !== undefined)
            reflectionMergePartial<TableInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableInfoReq): TableInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.MsgTableInfo tableInfo */ 1:
                    message.tableInfo = MsgTableInfo.internalBinaryRead(reader, reader.uint32(), options, message.tableInfo);
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* int64 seq */ 3:
                    message.seq = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.MsgTableInfo tableInfo = 1; */
        if (message.tableInfo)
            MsgTableInfo.internalBinaryWrite(message.tableInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* int64 seq = 3; */
        if (message.seq !== 0)
            writer.tag(3, WireType.Varint).int64(message.seq);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TableInfoReq
 */
export const TableInfoReq = new TableInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableInfoResp$Type extends MessageType<TableInfoResp> {
    constructor() {
        super("bpt.common.TableInfoResp", []);
    }
    create(value?: PartialMessage<TableInfoResp>): TableInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<TableInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableInfoResp): TableInfoResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TableInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TableInfoResp
 */
export const TableInfoResp = new TableInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoundResultReq$Type extends MessageType<RoundResultReq> {
    constructor() {
        super("bpt.common.RoundResultReq", [
            { no: 1, name: "roundResult", kind: "message", T: () => MsgRoundResult },
            { no: 2, name: "tKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RoundResultReq>): RoundResultReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tKey = "";
        message.matchKey = "";
        message.seq = 0;
        if (value !== undefined)
            reflectionMergePartial<RoundResultReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoundResultReq): RoundResultReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.MsgRoundResult roundResult */ 1:
                    message.roundResult = MsgRoundResult.internalBinaryRead(reader, reader.uint32(), options, message.roundResult);
                    break;
                case /* string tKey */ 2:
                    message.tKey = reader.string();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* int64 seq */ 4:
                    message.seq = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoundResultReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.MsgRoundResult roundResult = 1; */
        if (message.roundResult)
            MsgRoundResult.internalBinaryWrite(message.roundResult, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string tKey = 2; */
        if (message.tKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tKey);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* int64 seq = 4; */
        if (message.seq !== 0)
            writer.tag(4, WireType.Varint).int64(message.seq);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.RoundResultReq
 */
export const RoundResultReq = new RoundResultReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoundResultResp$Type extends MessageType<RoundResultResp> {
    constructor() {
        super("bpt.common.RoundResultResp", []);
    }
    create(value?: PartialMessage<RoundResultResp>): RoundResultResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RoundResultResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoundResultResp): RoundResultResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RoundResultResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.RoundResultResp
 */
export const RoundResultResp = new RoundResultResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoundEndReq$Type extends MessageType<RoundEndReq> {
    constructor() {
        super("bpt.common.RoundEndReq", [
            { no: 1, name: "tKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "seq", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "roundResult", kind: "message", T: () => MsgRoundResult }
        ]);
    }
    create(value?: PartialMessage<RoundEndReq>): RoundEndReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tKey = "";
        message.matchKey = "";
        message.seq = 0;
        if (value !== undefined)
            reflectionMergePartial<RoundEndReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoundEndReq): RoundEndReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tKey */ 1:
                    message.tKey = reader.string();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* int64 seq */ 3:
                    message.seq = reader.int64().toNumber();
                    break;
                case /* bpt.common.MsgRoundResult roundResult */ 4:
                    message.roundResult = MsgRoundResult.internalBinaryRead(reader, reader.uint32(), options, message.roundResult);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoundEndReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tKey = 1; */
        if (message.tKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tKey);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* int64 seq = 3; */
        if (message.seq !== 0)
            writer.tag(3, WireType.Varint).int64(message.seq);
        /* bpt.common.MsgRoundResult roundResult = 4; */
        if (message.roundResult)
            MsgRoundResult.internalBinaryWrite(message.roundResult, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.RoundEndReq
 */
export const RoundEndReq = new RoundEndReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoundEndResp$Type extends MessageType<RoundEndResp> {
    constructor() {
        super("bpt.common.RoundEndResp", []);
    }
    create(value?: PartialMessage<RoundEndResp>): RoundEndResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RoundEndResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoundEndResp): RoundEndResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RoundEndResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.RoundEndResp
 */
export const RoundEndResp = new RoundEndResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserAllInStat$Type extends MessageType<UserAllInStat> {
    constructor() {
        super("bpt.common.UserAllInStat", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "allInChip", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UserAllInStat>): UserAllInStat {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.allInChip = 0;
        if (value !== undefined)
            reflectionMergePartial<UserAllInStat>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserAllInStat): UserAllInStat {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 allInChip */ 2:
                    message.allInChip = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserAllInStat, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint64 allInChip = 2; */
        if (message.allInChip !== 0)
            writer.tag(2, WireType.Varint).uint64(message.allInChip);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.UserAllInStat
 */
export const UserAllInStat = new UserAllInStat$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RoleInfo$Type extends MessageType<RoleInfo> {
    constructor() {
        super("bpt.common.RoleInfo", [
            { no: 1, name: "DealerSeat", kind: "scalar", localName: "DealerSeat", jsonName: "DealerSeat", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "BBSeat", kind: "scalar", localName: "BBSeat", jsonName: "BBSeat", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "SBSeat", kind: "scalar", localName: "SBSeat", jsonName: "SBSeat", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "UTGSeat", kind: "scalar", localName: "UTGSeat", jsonName: "UTGSeat", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "UTG2Seat", kind: "scalar", localName: "UTG2Seat", jsonName: "UTG2Seat", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "MPSeat", kind: "scalar", localName: "MPSeat", jsonName: "MPSeat", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "MP2Seat", kind: "scalar", localName: "MP2Seat", jsonName: "MP2Seat", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "MP3Seat", kind: "scalar", localName: "MP3Seat", jsonName: "MP3Seat", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "COSeat", kind: "scalar", localName: "COSeat", jsonName: "COSeat", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "RecordBTN", kind: "scalar", localName: "RecordBTN", jsonName: "RecordBTN", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "RecordSB", kind: "scalar", localName: "RecordSB", jsonName: "RecordSB", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "RecordBB", kind: "scalar", localName: "RecordBB", jsonName: "RecordBB", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "RecordUTG", kind: "scalar", localName: "RecordUTG", jsonName: "RecordUTG", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "RecordUTG2", kind: "scalar", localName: "RecordUTG2", jsonName: "RecordUTG2", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "RecordUTG3", kind: "scalar", localName: "RecordUTG3", jsonName: "RecordUTG3", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "RecordLJ", kind: "scalar", localName: "RecordLJ", jsonName: "RecordLJ", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "RecordHJ", kind: "scalar", localName: "RecordHJ", jsonName: "RecordHJ", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "RecordCO", kind: "scalar", localName: "RecordCO", jsonName: "RecordCO", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<RoleInfo>): RoleInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.DealerSeat = 0;
        message.BBSeat = 0;
        message.SBSeat = 0;
        message.UTGSeat = 0;
        message.UTG2Seat = 0;
        message.MPSeat = 0;
        message.MP2Seat = 0;
        message.MP3Seat = 0;
        message.COSeat = 0;
        message.RecordBTN = 0;
        message.RecordSB = 0;
        message.RecordBB = 0;
        message.RecordUTG = 0;
        message.RecordUTG2 = 0;
        message.RecordUTG3 = 0;
        message.RecordLJ = 0;
        message.RecordHJ = 0;
        message.RecordCO = 0;
        if (value !== undefined)
            reflectionMergePartial<RoleInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RoleInfo): RoleInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 DealerSeat = 1 [json_name = "DealerSeat"];*/ 1:
                    message.DealerSeat = reader.int32();
                    break;
                case /* int32 BBSeat = 2 [json_name = "BBSeat"];*/ 2:
                    message.BBSeat = reader.int32();
                    break;
                case /* int32 SBSeat = 3 [json_name = "SBSeat"];*/ 3:
                    message.SBSeat = reader.int32();
                    break;
                case /* int32 UTGSeat = 4 [json_name = "UTGSeat"];*/ 4:
                    message.UTGSeat = reader.int32();
                    break;
                case /* int32 UTG2Seat = 5 [json_name = "UTG2Seat"];*/ 5:
                    message.UTG2Seat = reader.int32();
                    break;
                case /* int32 MPSeat = 6 [json_name = "MPSeat"];*/ 6:
                    message.MPSeat = reader.int32();
                    break;
                case /* int32 MP2Seat = 7 [json_name = "MP2Seat"];*/ 7:
                    message.MP2Seat = reader.int32();
                    break;
                case /* int32 MP3Seat = 8 [json_name = "MP3Seat"];*/ 8:
                    message.MP3Seat = reader.int32();
                    break;
                case /* int32 COSeat = 9 [json_name = "COSeat"];*/ 9:
                    message.COSeat = reader.int32();
                    break;
                case /* int32 RecordBTN = 10 [json_name = "RecordBTN"];*/ 10:
                    message.RecordBTN = reader.int32();
                    break;
                case /* int32 RecordSB = 11 [json_name = "RecordSB"];*/ 11:
                    message.RecordSB = reader.int32();
                    break;
                case /* int32 RecordBB = 12 [json_name = "RecordBB"];*/ 12:
                    message.RecordBB = reader.int32();
                    break;
                case /* int32 RecordUTG = 13 [json_name = "RecordUTG"];*/ 13:
                    message.RecordUTG = reader.int32();
                    break;
                case /* int32 RecordUTG2 = 14 [json_name = "RecordUTG2"];*/ 14:
                    message.RecordUTG2 = reader.int32();
                    break;
                case /* int32 RecordUTG3 = 15 [json_name = "RecordUTG3"];*/ 15:
                    message.RecordUTG3 = reader.int32();
                    break;
                case /* int32 RecordLJ = 16 [json_name = "RecordLJ"];*/ 16:
                    message.RecordLJ = reader.int32();
                    break;
                case /* int32 RecordHJ = 17 [json_name = "RecordHJ"];*/ 17:
                    message.RecordHJ = reader.int32();
                    break;
                case /* int32 RecordCO = 18 [json_name = "RecordCO"];*/ 18:
                    message.RecordCO = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RoleInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 DealerSeat = 1 [json_name = "DealerSeat"]; */
        if (message.DealerSeat !== 0)
            writer.tag(1, WireType.Varint).int32(message.DealerSeat);
        /* int32 BBSeat = 2 [json_name = "BBSeat"]; */
        if (message.BBSeat !== 0)
            writer.tag(2, WireType.Varint).int32(message.BBSeat);
        /* int32 SBSeat = 3 [json_name = "SBSeat"]; */
        if (message.SBSeat !== 0)
            writer.tag(3, WireType.Varint).int32(message.SBSeat);
        /* int32 UTGSeat = 4 [json_name = "UTGSeat"]; */
        if (message.UTGSeat !== 0)
            writer.tag(4, WireType.Varint).int32(message.UTGSeat);
        /* int32 UTG2Seat = 5 [json_name = "UTG2Seat"]; */
        if (message.UTG2Seat !== 0)
            writer.tag(5, WireType.Varint).int32(message.UTG2Seat);
        /* int32 MPSeat = 6 [json_name = "MPSeat"]; */
        if (message.MPSeat !== 0)
            writer.tag(6, WireType.Varint).int32(message.MPSeat);
        /* int32 MP2Seat = 7 [json_name = "MP2Seat"]; */
        if (message.MP2Seat !== 0)
            writer.tag(7, WireType.Varint).int32(message.MP2Seat);
        /* int32 MP3Seat = 8 [json_name = "MP3Seat"]; */
        if (message.MP3Seat !== 0)
            writer.tag(8, WireType.Varint).int32(message.MP3Seat);
        /* int32 COSeat = 9 [json_name = "COSeat"]; */
        if (message.COSeat !== 0)
            writer.tag(9, WireType.Varint).int32(message.COSeat);
        /* int32 RecordBTN = 10 [json_name = "RecordBTN"]; */
        if (message.RecordBTN !== 0)
            writer.tag(10, WireType.Varint).int32(message.RecordBTN);
        /* int32 RecordSB = 11 [json_name = "RecordSB"]; */
        if (message.RecordSB !== 0)
            writer.tag(11, WireType.Varint).int32(message.RecordSB);
        /* int32 RecordBB = 12 [json_name = "RecordBB"]; */
        if (message.RecordBB !== 0)
            writer.tag(12, WireType.Varint).int32(message.RecordBB);
        /* int32 RecordUTG = 13 [json_name = "RecordUTG"]; */
        if (message.RecordUTG !== 0)
            writer.tag(13, WireType.Varint).int32(message.RecordUTG);
        /* int32 RecordUTG2 = 14 [json_name = "RecordUTG2"]; */
        if (message.RecordUTG2 !== 0)
            writer.tag(14, WireType.Varint).int32(message.RecordUTG2);
        /* int32 RecordUTG3 = 15 [json_name = "RecordUTG3"]; */
        if (message.RecordUTG3 !== 0)
            writer.tag(15, WireType.Varint).int32(message.RecordUTG3);
        /* int32 RecordLJ = 16 [json_name = "RecordLJ"]; */
        if (message.RecordLJ !== 0)
            writer.tag(16, WireType.Varint).int32(message.RecordLJ);
        /* int32 RecordHJ = 17 [json_name = "RecordHJ"]; */
        if (message.RecordHJ !== 0)
            writer.tag(17, WireType.Varint).int32(message.RecordHJ);
        /* int32 RecordCO = 18 [json_name = "RecordCO"]; */
        if (message.RecordCO !== 0)
            writer.tag(18, WireType.Varint).int32(message.RecordCO);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.RoleInfo
 */
export const RoleInfo = new RoleInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserInfo$Type extends MessageType<MsgUserInfo> {
    constructor() {
        super("bpt.common.MsgUserInfo", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "seat", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "isManaged", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "chip", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "timeOutCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserInfo>): MsgUserInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.seat = 0;
        message.isManaged = false;
        message.chip = 0;
        message.timeOutCnt = 0;
        message.timeCardNum = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserInfo): MsgUserInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int32 seat */ 2:
                    message.seat = reader.int32();
                    break;
                case /* bool isManaged */ 3:
                    message.isManaged = reader.bool();
                    break;
                case /* uint64 chip */ 13:
                    message.chip = reader.uint64().toNumber();
                    break;
                case /* int32 timeOutCnt */ 4:
                    message.timeOutCnt = reader.int32();
                    break;
                case /* int32 timeCardNum */ 5:
                    message.timeCardNum = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int32 seat = 2; */
        if (message.seat !== 0)
            writer.tag(2, WireType.Varint).int32(message.seat);
        /* bool isManaged = 3; */
        if (message.isManaged !== false)
            writer.tag(3, WireType.Varint).bool(message.isManaged);
        /* uint64 chip = 13; */
        if (message.chip !== 0)
            writer.tag(13, WireType.Varint).uint64(message.chip);
        /* int32 timeOutCnt = 4; */
        if (message.timeOutCnt !== 0)
            writer.tag(4, WireType.Varint).int32(message.timeOutCnt);
        /* int32 timeCardNum = 5; */
        if (message.timeCardNum !== 0)
            writer.tag(5, WireType.Varint).int32(message.timeCardNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgUserInfo
 */
export const MsgUserInfo = new MsgUserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgTableInfo$Type extends MessageType<MsgTableInfo> {
    constructor() {
        super("bpt.common.MsgTableInfo", [
            { no: 2, name: "tKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MsgUserInfo },
            { no: 4, name: "roundUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MsgUserInfo },
            { no: 10, name: "roundStage", kind: "enum", T: () => ["bpt.common.RoundStage", RoundStage] },
            { no: 18, name: "roleInfo", kind: "message", T: () => RoleInfo },
            { no: 21, name: "userAllInStats", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserAllInStat },
            { no: 22, name: "tableState", kind: "enum", T: () => ["bpt.common.TableState", TableState] }
        ]);
    }
    create(value?: PartialMessage<MsgTableInfo>): MsgTableInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tKey = "";
        message.users = [];
        message.roundUsers = [];
        message.roundStage = 0;
        message.userAllInStats = [];
        message.tableState = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgTableInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgTableInfo): MsgTableInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tKey */ 2:
                    message.tKey = reader.string();
                    break;
                case /* repeated bpt.common.MsgUserInfo users */ 3:
                    message.users.push(MsgUserInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.MsgUserInfo roundUsers */ 4:
                    message.roundUsers.push(MsgUserInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.RoundStage roundStage */ 10:
                    message.roundStage = reader.int32();
                    break;
                case /* bpt.common.RoleInfo roleInfo */ 18:
                    message.roleInfo = RoleInfo.internalBinaryRead(reader, reader.uint32(), options, message.roleInfo);
                    break;
                case /* repeated bpt.common.UserAllInStat userAllInStats */ 21:
                    message.userAllInStats.push(UserAllInStat.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.TableState tableState */ 22:
                    message.tableState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgTableInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tKey = 2; */
        if (message.tKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tKey);
        /* repeated bpt.common.MsgUserInfo users = 3; */
        for (let i = 0; i < message.users.length; i++)
            MsgUserInfo.internalBinaryWrite(message.users[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.MsgUserInfo roundUsers = 4; */
        for (let i = 0; i < message.roundUsers.length; i++)
            MsgUserInfo.internalBinaryWrite(message.roundUsers[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.RoundStage roundStage = 10; */
        if (message.roundStage !== 0)
            writer.tag(10, WireType.Varint).int32(message.roundStage);
        /* bpt.common.RoleInfo roleInfo = 18; */
        if (message.roleInfo)
            RoleInfo.internalBinaryWrite(message.roleInfo, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.UserAllInStat userAllInStats = 21; */
        for (let i = 0; i < message.userAllInStats.length; i++)
            UserAllInStat.internalBinaryWrite(message.userAllInStats[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.TableState tableState = 22; */
        if (message.tableState !== 0)
            writer.tag(22, WireType.Varint).int32(message.tableState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgTableInfo
 */
export const MsgTableInfo = new MsgTableInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgRoundResult$Type extends MessageType<MsgRoundResult> {
    constructor() {
        super("bpt.common.MsgRoundResult", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MsgUserResult },
            { no: 3, name: "cards", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "animationTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgRoundResult>): MsgRoundResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.results = [];
        message.cards = [];
        message.animationTime = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgRoundResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgRoundResult): MsgRoundResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.MsgUserResult results */ 1:
                    message.results.push(MsgUserResult.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated uint32 cards */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.cards.push(reader.uint32());
                    else
                        message.cards.push(reader.uint32());
                    break;
                case /* int64 animationTime */ 4:
                    message.animationTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgRoundResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.MsgUserResult results = 1; */
        for (let i = 0; i < message.results.length; i++)
            MsgUserResult.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated uint32 cards = 3; */
        if (message.cards.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.cards.length; i++)
                writer.uint32(message.cards[i]);
            writer.join();
        }
        /* int64 animationTime = 4; */
        if (message.animationTime !== 0)
            writer.tag(4, WireType.Varint).int64(message.animationTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgRoundResult
 */
export const MsgRoundResult = new MsgRoundResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserResult$Type extends MessageType<MsgUserResult> {
    constructor() {
        super("bpt.common.MsgUserResult", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "cardType", kind: "enum", T: () => ["bpt.common.CT", CT] },
            { no: 3, name: "chip", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "managedState", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "winRound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "changeChip", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "eliminatedUsers", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "useShuffleCard", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserResult>): MsgUserResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.cardType = 0;
        message.chip = 0;
        message.managedState = 0;
        message.winRound = false;
        message.changeChip = 0;
        message.timeCardNum = 0;
        message.eliminatedUsers = [];
        message.useShuffleCard = false;
        if (value !== undefined)
            reflectionMergePartial<MsgUserResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserResult): MsgUserResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.common.CT cardType */ 7:
                    message.cardType = reader.int32();
                    break;
                case /* uint64 chip */ 3:
                    message.chip = reader.uint64().toNumber();
                    break;
                case /* int32 managedState */ 9:
                    message.managedState = reader.int32();
                    break;
                case /* bool winRound */ 10:
                    message.winRound = reader.bool();
                    break;
                case /* uint64 changeChip */ 11:
                    message.changeChip = reader.uint64().toNumber();
                    break;
                case /* int32 timeCardNum */ 12:
                    message.timeCardNum = reader.int32();
                    break;
                case /* repeated int64 eliminatedUsers */ 13:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.eliminatedUsers.push(reader.int64().toNumber());
                    else
                        message.eliminatedUsers.push(reader.int64().toNumber());
                    break;
                case /* bool useShuffleCard */ 14:
                    message.useShuffleCard = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.common.CT cardType = 7; */
        if (message.cardType !== 0)
            writer.tag(7, WireType.Varint).int32(message.cardType);
        /* uint64 chip = 3; */
        if (message.chip !== 0)
            writer.tag(3, WireType.Varint).uint64(message.chip);
        /* int32 managedState = 9; */
        if (message.managedState !== 0)
            writer.tag(9, WireType.Varint).int32(message.managedState);
        /* bool winRound = 10; */
        if (message.winRound !== false)
            writer.tag(10, WireType.Varint).bool(message.winRound);
        /* uint64 changeChip = 11; */
        if (message.changeChip !== 0)
            writer.tag(11, WireType.Varint).uint64(message.changeChip);
        /* int32 timeCardNum = 12; */
        if (message.timeCardNum !== 0)
            writer.tag(12, WireType.Varint).int32(message.timeCardNum);
        /* repeated int64 eliminatedUsers = 13; */
        if (message.eliminatedUsers.length) {
            writer.tag(13, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.eliminatedUsers.length; i++)
                writer.int64(message.eliminatedUsers[i]);
            writer.join();
        }
        /* bool useShuffleCard = 14; */
        if (message.useShuffleCard !== false)
            writer.tag(14, WireType.Varint).bool(message.useShuffleCard);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgUserResult
 */
export const MsgUserResult = new MsgUserResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgTableStateChange$Type extends MessageType<MsgTableStateChange> {
    constructor() {
        super("bpt.common.MsgTableStateChange", [
            { no: 1, name: "tKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableState", kind: "enum", T: () => ["bpt.common.TableState", TableState] },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgTableStateChange>): MsgTableStateChange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tKey = "";
        message.tableState = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgTableStateChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgTableStateChange): MsgTableStateChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tKey */ 1:
                    message.tKey = reader.string();
                    break;
                case /* bpt.common.TableState tableState */ 2:
                    message.tableState = reader.int32();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgTableStateChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tKey = 1; */
        if (message.tKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tKey);
        /* bpt.common.TableState tableState = 2; */
        if (message.tableState !== 0)
            writer.tag(2, WireType.Varint).int32(message.tableState);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.MsgTableStateChange
 */
export const MsgTableStateChange = new MsgTableStateChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableStateChangeResp$Type extends MessageType<TableStateChangeResp> {
    constructor() {
        super("bpt.common.TableStateChangeResp", []);
    }
    create(value?: PartialMessage<TableStateChangeResp>): TableStateChangeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<TableStateChangeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableStateChangeResp): TableStateChangeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TableStateChangeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TableStateChangeResp
 */
export const TableStateChangeResp = new TableStateChangeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class I18nField$Type extends MessageType<I18nField> {
    constructor() {
        super("bpt.common.I18nField", [
            { no: 1, name: "entries", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<I18nField>): I18nField {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entries = {};
        if (value !== undefined)
            reflectionMergePartial<I18nField>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: I18nField): I18nField {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> entries */ 1:
                    this.binaryReadMap1(message.entries, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: I18nField["entries"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof I18nField["entries"] | undefined, val: I18nField["entries"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.I18nField.entries");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: I18nField, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> entries = 1; */
        for (let k of globalThis.Object.keys(message.entries))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.entries[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.I18nField
 */
export const I18nField = new I18nField$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DataField$Type extends MessageType<DataField> {
    constructor() {
        super("bpt.common.DataField", [
            { no: 1, name: "fieldType", kind: "enum", T: () => ["bpt.common.FieldType", FieldType] },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "template", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => DataField } },
            { no: 6, name: "i8nField", kind: "message", localName: "i8nField", jsonName: "i8nField", T: () => I18nField }
        ]);
    }
    create(value?: PartialMessage<DataField>): DataField {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fieldType = 0;
        message.value = "";
        message.template = "";
        message.data = {};
        if (value !== undefined)
            reflectionMergePartial<DataField>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DataField): DataField {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.FieldType fieldType */ 1:
                    message.fieldType = reader.int32();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                case /* string template */ 3:
                    message.template = reader.string();
                    break;
                case /* map<string, bpt.common.DataField> data */ 4:
                    this.binaryReadMap4(message.data, reader, options);
                    break;
                case /* bpt.common.I18nField i8nField = 6 [json_name = "i8nField"];*/ 6:
                    message.i8nField = I18nField.internalBinaryRead(reader, reader.uint32(), options, message.i8nField);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: DataField["data"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DataField["data"] | undefined, val: DataField["data"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = DataField.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.DataField.data");
            }
        }
        map[key ?? ""] = val ?? DataField.create();
    }
    internalBinaryWrite(message: DataField, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.FieldType fieldType = 1; */
        if (message.fieldType !== 0)
            writer.tag(1, WireType.Varint).int32(message.fieldType);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        /* string template = 3; */
        if (message.template !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.template);
        /* map<string, bpt.common.DataField> data = 4; */
        for (let k of globalThis.Object.keys(message.data)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            DataField.internalBinaryWrite(message.data[k], writer, options);
            writer.join().join();
        }
        /* bpt.common.I18nField i8nField = 6 [json_name = "i8nField"]; */
        if (message.i8nField)
            I18nField.internalBinaryWrite(message.i8nField, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.DataField
 */
export const DataField = new DataField$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TemplateContent$Type extends MessageType<TemplateContent> {
    constructor() {
        super("bpt.common.TemplateContent", [
            { no: 1, name: "template", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "data", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => DataField } }
        ]);
    }
    create(value?: PartialMessage<TemplateContent>): TemplateContent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.template = "";
        message.data = {};
        if (value !== undefined)
            reflectionMergePartial<TemplateContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TemplateContent): TemplateContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string template */ 1:
                    message.template = reader.string();
                    break;
                case /* map<string, bpt.common.DataField> data */ 2:
                    this.binaryReadMap2(message.data, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: TemplateContent["data"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof TemplateContent["data"] | undefined, val: TemplateContent["data"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = DataField.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.TemplateContent.data");
            }
        }
        map[key ?? ""] = val ?? DataField.create();
    }
    internalBinaryWrite(message: TemplateContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string template = 1; */
        if (message.template !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.template);
        /* map<string, bpt.common.DataField> data = 2; */
        for (let k of globalThis.Object.keys(message.data)) {
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            DataField.internalBinaryWrite(message.data[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TemplateContent
 */
export const TemplateContent = new TemplateContent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PingMatchReq$Type extends MessageType<PingMatchReq> {
    constructor() {
        super("bpt.common.PingMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PingMatchReq>): PingMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<PingMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PingMatchReq): PingMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PingMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.PingMatchReq
 */
export const PingMatchReq = new PingMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PingMatchResp$Type extends MessageType<PingMatchResp> {
    constructor() {
        super("bpt.common.PingMatchResp", []);
    }
    create(value?: PartialMessage<PingMatchResp>): PingMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<PingMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PingMatchResp): PingMatchResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PingMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.PingMatchResp
 */
export const PingMatchResp = new PingMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableView$Type extends MessageType<TableView> {
    constructor() {
        super("bpt.common.TableView", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "reason", kind: "enum", T: () => ["bpt.common.TableViewChangeReason", TableViewChangeReason] }
        ]);
    }
    create(value?: PartialMessage<TableView>): TableView {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.matchKey = "";
        message.matchType = 0;
        message.reason = 0;
        if (value !== undefined)
            reflectionMergePartial<TableView>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableView): TableView {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* int32 matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.common.TableViewChangeReason reason */ 5:
                    message.reason = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableView, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* int32 matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* bpt.common.TableViewChangeReason reason = 5; */
        if (message.reason !== 0)
            writer.tag(5, WireType.Varint).int32(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TableView
 */
export const TableView = new TableView$Type();
