// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "gaw/base/base.proto" (package "gaw.base", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message gaw.base.Void
 */
export interface Void {
}
/**
 * @generated from protobuf enum gaw.base.ChainID
 */
export enum ChainID {
    /**
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * Ethereum Mainnet
     *
     * @generated from protobuf enum value: EthereumMainnet = 1;
     */
    EthereumMainnet = 1,
    /**
     * Ethereum Testnet Goerli
     *
     * @deprecated
     * @generated from protobuf enum value: Goerli = 5 [deprecated = true];
     */
    Goerli = 5,
    /**
     * Polygon Mainnet
     *
     * @generated from protobuf enum value: PolygonMainnet = 137;
     */
    PolygonMainnet = 137,
    /**
     * opBNB Mainnet
     *
     * @generated from protobuf enum value: OpBNBMainnet = 204;
     */
    OpBNBMainnet = 204,
    /**
     * @generated from protobuf enum value: MttTestnet = 6110;
     */
    MttTestnet = 6110,
    /**
     * 链升级6118 => 6880
     *
     * @generated from protobuf enum value: MttMainnet = 6880;
     */
    MttMainnet = 6880,
    /**
     * Polygon Testnet Mumbai
     *
     * @generated from protobuf enum value: Mumbai = 80001;
     */
    Mumbai = 80001,
    /**
     * Ethereum Testnet Sepolia
     *
     * @generated from protobuf enum value: Sepolia = 11155111;
     */
    Sepolia = 11155111,
    /**
     * 做兼容 不要用
     *
     * @deprecated
     * @generated from protobuf enum value: ReddioTestnet = 799999998 [deprecated = true];
     */
    ReddioTestnet = 799999998,
    /**
     * 做兼容 不要用
     *
     * @deprecated
     * @generated from protobuf enum value: ReddioMainnet = 799999999 [deprecated = true];
     */
    ReddioMainnet = 799999999
}
/**
 * @generated from protobuf enum gaw.base.TransferType
 */
export enum TransferType {
    /**
     * @generated from protobuf enum value: TransferTypeNative = 0;
     */
    TransferTypeNative = 0,
    /**
     * @generated from protobuf enum value: TransferTypeErc20 = 1;
     */
    TransferTypeErc20 = 1,
    /**
     * @generated from protobuf enum value: TransferTypeErc721 = 2;
     */
    TransferTypeErc721 = 2,
    /**
     * @generated from protobuf enum value: TransferTypeErc1155 = 3;
     */
    TransferTypeErc1155 = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Void$Type extends MessageType<Void> {
    constructor() {
        super("gaw.base.Void", []);
    }
    create(value?: PartialMessage<Void>): Void {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Void>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Void): Void {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Void, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.base.Void
 */
export const Void = new Void$Type();
