// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/common/common_web3.proto" (package "bpt.common", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Unit } from "../../gaw/asset/wallet/v1/token";
import { ChainID } from "../../gaw/base/base";
/**
 * bpt 统一展示token结构
 * readableBalance 计算公式 readableBalance=amount*unit/10^decimals
 *
 * @generated from protobuf message bpt.common.Token
 */
export interface Token {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: bpt.common.TokenType tokenType = 2;
     */
    tokenType: TokenType;
    /**
     * @generated from protobuf field: string contactAddr = 3;
     */
    contactAddr: string; // 如erc20等的合约地址，如有，主币没有
    /**
     * 对应assetItem 的icon
     *
     * @generated from protobuf field: string icon = 4;
     */
    icon: string;
    /**
     * 小数位数
     *
     * @generated from protobuf field: int32 decimals = 5;
     */
    decimals: number; // token 属性 decimal
    /**
     * @generated from protobuf field: string walletAddr = 6;
     */
    walletAddr: string; // 钱包地址，如有
    /**
     * @generated from protobuf field: int64 amount = 7;
     */
    amount: number; // token最小单位的数量,未来可能更改，可能超过int64上限
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Unit unit = 8;
     */
    unit: Unit;
    /**
     * 可读余额 （前端直接显示这个就好）
     *
     * @generated from protobuf field: string readableBalance = 9;
     */
    readableBalance: string;
    /**
     * @generated from protobuf field: string rawAmount = 10;
     */
    rawAmount: string;
}
/**
 * @generated from protobuf message bpt.common.TransactionMeta
 */
export interface TransactionMeta {
    /**
     * @generated from protobuf field: bpt.common.Operation opType = 1;
     */
    opType: Operation; // 操作类型
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string; // 标题
    /**
     * @generated from protobuf field: string rawAmount = 4;
     */
    rawAmount: string;
    /**
     * @generated from protobuf field: int64 itemID = 5;
     */
    itemID: number; // 对应assetItemID
    /**
     * @generated from protobuf field: bpt.common.RefundType refundType = 6;
     */
    refundType: RefundType; // Operation 为 refund时的子类型
    /**
     * @generated from protobuf field: uint32 rank = 7;
     */
    rank: number; // 领奖时，排名信息
}
/**
 * @generated from protobuf message bpt.common.TicketInfo
 */
export interface TicketInfo {
    /**
     * @generated from protobuf field: string tokenId = 1;
     */
    tokenId: string;
    /**
     * @generated from protobuf field: string price = 2;
     */
    price: string;
    /**
     * @generated from protobuf field: int64 deadline = 3;
     */
    deadline: number;
}
/**
 * @generated from protobuf message bpt.common.TxOnChainDetail
 */
export interface TxOnChainDetail {
    /**
     * @generated from protobuf field: string txHash = 1;
     */
    txHash: string;
    /**
     * @generated from protobuf field: string fromAddr = 2;
     */
    fromAddr: string;
    /**
     * @generated from protobuf field: string toAddr = 3;
     */
    toAddr: string;
    /**
     * @generated from protobuf field: string contractAddr = 4;
     */
    contractAddr: string;
    /**
     * @generated from protobuf field: bpt.common.TicketInfo ticketInfo = 13;
     */
    ticketInfo?: TicketInfo;
    /**
     * @generated from protobuf field: string rawAmount = 5;
     */
    rawAmount: string;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 6;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: uint64 timestamp = 7;
     */
    timestamp: number;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainType txType = 8;
     */
    txType: TxOnChainType;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainStatus status = 9;
     */
    status: TxOnChainStatus;
    /**
     * @generated from protobuf field: string matchKey = 10;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string method = 11;
     */
    method: string;
    /**
     * @generated from protobuf field: uint32 taskId = 12;
     */
    taskId: number;
    /**
     * @generated from protobuf field: string productId = 14;
     */
    productId: string;
    /**
     * @generated from protobuf field: int64 propId = 15;
     */
    propId: number;
    /**
     * @generated from protobuf field: string currentBalance = 16;
     */
    currentBalance: string;
}
/**
 * @generated from protobuf enum bpt.common.TokenType
 */
export enum TokenType {
    /**
     * 原生代币
     *
     * @generated from protobuf enum value: TokenTypeNative = 0;
     */
    TokenTypeNative = 0,
    /**
     * ERC20
     *
     * @generated from protobuf enum value: TokenTypeERC20 = 1;
     */
    TokenTypeERC20 = 1,
    /**
     * ERC721
     *
     * @generated from protobuf enum value: TokenTypeERC721 = 2;
     */
    TokenTypeERC721 = 2,
    /**
     * ERC1155
     *
     * @generated from protobuf enum value: TokenTypeERC1155 = 3;
     */
    TokenTypeERC1155 = 3,
    /**
     * 用于测试，不上链的币
     *
     * @generated from protobuf enum value: TokenTypeTest = 1000;
     */
    TokenTypeTest = 1000
}
/**
 * @generated from protobuf enum bpt.common.Operation
 */
export enum Operation {
    /**
     * 领奖
     *
     * @generated from protobuf enum value: ClaimAward = 0;
     */
    ClaimAward = 0,
    /**
     * 退款
     *
     * @generated from protobuf enum value: Refund = 1;
     */
    Refund = 1
}
/**
 * @generated from protobuf enum bpt.common.RefundType
 */
export enum RefundType {
    /**
     * rebuy
     *
     * @generated from protobuf enum value: RefundTypeReBuy = 0;
     */
    RefundTypeReBuy = 0,
    /**
     * addon
     *
     * @generated from protobuf enum value: RefundTypeAddOn = 1;
     */
    RefundTypeAddOn = 1,
    /**
     * reentry
     *
     * @generated from protobuf enum value: RefundTypeReEntry = 2;
     */
    RefundTypeReEntry = 2,
    /**
     * 报名（包含延时报名）
     *
     * @generated from protobuf enum value: RefundTypeRegister = 3;
     */
    RefundTypeRegister = 3,
    /**
     * 比赛取消
     *
     * @generated from protobuf enum value: RefundTypeCancelMatch = 4;
     */
    RefundTypeCancelMatch = 4
}
/**
 * 链上交易记录类型
 *
 * @generated from protobuf enum bpt.common.TxOnChainType
 */
export enum TxOnChainType {
    /**
     * 全部
     *
     * @generated from protobuf enum value: TxOnChainNormal = 0;
     */
    TxOnChainNormal = 0,
    /**
     * 赛事报名
     *
     * @generated from protobuf enum value: TxOnChainRegister = 1;
     */
    TxOnChainRegister = 1,
    /**
     * AddOn
     *
     * @generated from protobuf enum value: TxOnChainAddOn = 2;
     */
    TxOnChainAddOn = 2,
    /**
     * ReEntry
     *
     * @generated from protobuf enum value: TxOnChainReEntry = 3;
     */
    TxOnChainReEntry = 3,
    /**
     * ReBuy
     *
     * @generated from protobuf enum value: TxOnChainReBuy = 4;
     */
    TxOnChainReBuy = 4,
    /**
     * 领取奖励
     *
     * @generated from protobuf enum value: TxOnChainClaimPrize = 5;
     */
    TxOnChainClaimPrize = 5,
    /**
     * 退款
     *
     * @generated from protobuf enum value: TxOnChainRefund = 6;
     */
    TxOnChainRefund = 6,
    /**
     * 接收
     *
     * @generated from protobuf enum value: TxOnChainDeposit = 7;
     */
    TxOnChainDeposit = 7,
    /**
     * 发送
     *
     * @generated from protobuf enum value: TxOnChainSend = 8;
     */
    TxOnChainSend = 8,
    /**
     * 任务
     *
     * @generated from protobuf enum value: TxOnChainTask = 9;
     */
    TxOnChainTask = 9,
    /**
     * 商城购买
     *
     * @generated from protobuf enum value: TxOnChainMallBuy = 10;
     */
    TxOnChainMallBuy = 10,
    /**
     * 售卖(商城礼物...)
     *
     * @generated from protobuf enum value: TxOnChainSell = 11;
     */
    TxOnChainSell = 11,
    /**
     * 交易手续费Gas
     *
     * @generated from protobuf enum value: TxOnChainTransactionFee = 12;
     */
    TxOnChainTransactionFee = 12,
    /**
     * 抽奖
     *
     * @generated from protobuf enum value: TxOnChainRaffle = 13;
     */
    TxOnChainRaffle = 13,
    /**
     * vip质押：用于金额扣款
     *
     * @generated from protobuf enum value: TxOnChainCoinStake = 14;
     */
    TxOnChainCoinStake = 14,
    /**
     * vip质押：用于获取nft
     *
     * @generated from protobuf enum value: TxOnChainNftStake = 15;
     */
    TxOnChainNftStake = 15,
    /**
     * vip赎回：用于金额赎回
     *
     * @generated from protobuf enum value: TxOnChainCoinRedeem = 16;
     */
    TxOnChainCoinRedeem = 16,
    /**
     * vip赎回：用于nft划款
     *
     * @generated from protobuf enum value: TxOnChainNftRedeem = 17;
     */
    TxOnChainNftRedeem = 17,
    /**
     * vip转让：用于nft转让
     *
     * @generated from protobuf enum value: TxOnChainNftTransfer = 18;
     */
    TxOnChainNftTransfer = 18,
    /**
     * 跨链桥
     *
     * @generated from protobuf enum value: TxOnChainBridge = 19;
     */
    TxOnChainBridge = 19
}
/**
 * 链上交易记录状态
 *
 * @generated from protobuf enum bpt.common.TxOnChainStatus
 */
export enum TxOnChainStatus {
    /**
     * 全部
     *
     * @generated from protobuf enum value: TxOnChainStatusNormal = 0;
     */
    TxOnChainStatusNormal = 0,
    /**
     * @generated from protobuf enum value: TxOnChainStatusFailed = 1;
     */
    TxOnChainStatusFailed = 1,
    /**
     * @generated from protobuf enum value: TxOnChainStatusSucceed = 2;
     */
    TxOnChainStatusSucceed = 2,
    /**
     * @generated from protobuf enum value: TxOnChainStatusPending = 3;
     */
    TxOnChainStatusPending = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Token$Type extends MessageType<Token> {
    constructor() {
        super("bpt.common.Token", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "tokenType", kind: "enum", T: () => ["bpt.common.TokenType", TokenType] },
            { no: 3, name: "contactAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "decimals", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "walletAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "unit", kind: "enum", T: () => ["gaw.asset.wallet.v1.Unit", Unit] },
            { no: 9, name: "readableBalance", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Token>): Token {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.tokenType = 0;
        message.contactAddr = "";
        message.icon = "";
        message.decimals = 0;
        message.walletAddr = "";
        message.amount = 0;
        message.unit = 0;
        message.readableBalance = "";
        message.rawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<Token>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Token): Token {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* bpt.common.TokenType tokenType */ 2:
                    message.tokenType = reader.int32();
                    break;
                case /* string contactAddr */ 3:
                    message.contactAddr = reader.string();
                    break;
                case /* string icon */ 4:
                    message.icon = reader.string();
                    break;
                case /* int32 decimals */ 5:
                    message.decimals = reader.int32();
                    break;
                case /* string walletAddr */ 6:
                    message.walletAddr = reader.string();
                    break;
                case /* int64 amount */ 7:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* gaw.asset.wallet.v1.Unit unit */ 8:
                    message.unit = reader.int32();
                    break;
                case /* string readableBalance */ 9:
                    message.readableBalance = reader.string();
                    break;
                case /* string rawAmount */ 10:
                    message.rawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Token, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* bpt.common.TokenType tokenType = 2; */
        if (message.tokenType !== 0)
            writer.tag(2, WireType.Varint).int32(message.tokenType);
        /* string contactAddr = 3; */
        if (message.contactAddr !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contactAddr);
        /* string icon = 4; */
        if (message.icon !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.icon);
        /* int32 decimals = 5; */
        if (message.decimals !== 0)
            writer.tag(5, WireType.Varint).int32(message.decimals);
        /* string walletAddr = 6; */
        if (message.walletAddr !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.walletAddr);
        /* int64 amount = 7; */
        if (message.amount !== 0)
            writer.tag(7, WireType.Varint).int64(message.amount);
        /* gaw.asset.wallet.v1.Unit unit = 8; */
        if (message.unit !== 0)
            writer.tag(8, WireType.Varint).int32(message.unit);
        /* string readableBalance = 9; */
        if (message.readableBalance !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.readableBalance);
        /* string rawAmount = 10; */
        if (message.rawAmount !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.rawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.Token
 */
export const Token = new Token$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionMeta$Type extends MessageType<TransactionMeta> {
    constructor() {
        super("bpt.common.TransactionMeta", [
            { no: 1, name: "opType", kind: "enum", T: () => ["bpt.common.Operation", Operation] },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "itemID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "refundType", kind: "enum", T: () => ["bpt.common.RefundType", RefundType] },
            { no: 7, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<TransactionMeta>): TransactionMeta {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.opType = 0;
        message.matchKey = "";
        message.title = "";
        message.rawAmount = "";
        message.itemID = 0;
        message.refundType = 0;
        message.rank = 0;
        if (value !== undefined)
            reflectionMergePartial<TransactionMeta>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransactionMeta): TransactionMeta {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.Operation opType */ 1:
                    message.opType = reader.int32();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string rawAmount */ 4:
                    message.rawAmount = reader.string();
                    break;
                case /* int64 itemID */ 5:
                    message.itemID = reader.int64().toNumber();
                    break;
                case /* bpt.common.RefundType refundType */ 6:
                    message.refundType = reader.int32();
                    break;
                case /* uint32 rank */ 7:
                    message.rank = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransactionMeta, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.Operation opType = 1; */
        if (message.opType !== 0)
            writer.tag(1, WireType.Varint).int32(message.opType);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string rawAmount = 4; */
        if (message.rawAmount !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.rawAmount);
        /* int64 itemID = 5; */
        if (message.itemID !== 0)
            writer.tag(5, WireType.Varint).int64(message.itemID);
        /* bpt.common.RefundType refundType = 6; */
        if (message.refundType !== 0)
            writer.tag(6, WireType.Varint).int32(message.refundType);
        /* uint32 rank = 7; */
        if (message.rank !== 0)
            writer.tag(7, WireType.Varint).uint32(message.rank);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TransactionMeta
 */
export const TransactionMeta = new TransactionMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TicketInfo$Type extends MessageType<TicketInfo> {
    constructor() {
        super("bpt.common.TicketInfo", [
            { no: 1, name: "tokenId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "price", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TicketInfo>): TicketInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenId = "";
        message.price = "";
        message.deadline = 0;
        if (value !== undefined)
            reflectionMergePartial<TicketInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TicketInfo): TicketInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tokenId */ 1:
                    message.tokenId = reader.string();
                    break;
                case /* string price */ 2:
                    message.price = reader.string();
                    break;
                case /* int64 deadline */ 3:
                    message.deadline = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TicketInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tokenId = 1; */
        if (message.tokenId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tokenId);
        /* string price = 2; */
        if (message.price !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.price);
        /* int64 deadline = 3; */
        if (message.deadline !== 0)
            writer.tag(3, WireType.Varint).int64(message.deadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TicketInfo
 */
export const TicketInfo = new TicketInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TxOnChainDetail$Type extends MessageType<TxOnChainDetail> {
    constructor() {
        super("bpt.common.TxOnChainDetail", [
            { no: 1, name: "txHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "fromAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "toAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "contractAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "ticketInfo", kind: "message", T: () => TicketInfo },
            { no: 5, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 7, name: "timestamp", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "txType", kind: "enum", T: () => ["bpt.common.TxOnChainType", TxOnChainType] },
            { no: 9, name: "status", kind: "enum", T: () => ["bpt.common.TxOnChainStatus", TxOnChainStatus] },
            { no: 10, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "method", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "taskId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "productId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "currentBalance", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TxOnChainDetail>): TxOnChainDetail {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.txHash = "";
        message.fromAddr = "";
        message.toAddr = "";
        message.contractAddr = "";
        message.rawAmount = "";
        message.chainID = 0;
        message.timestamp = 0;
        message.txType = 0;
        message.status = 0;
        message.matchKey = "";
        message.method = "";
        message.taskId = 0;
        message.productId = "";
        message.propId = 0;
        message.currentBalance = "";
        if (value !== undefined)
            reflectionMergePartial<TxOnChainDetail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TxOnChainDetail): TxOnChainDetail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string txHash */ 1:
                    message.txHash = reader.string();
                    break;
                case /* string fromAddr */ 2:
                    message.fromAddr = reader.string();
                    break;
                case /* string toAddr */ 3:
                    message.toAddr = reader.string();
                    break;
                case /* string contractAddr */ 4:
                    message.contractAddr = reader.string();
                    break;
                case /* bpt.common.TicketInfo ticketInfo */ 13:
                    message.ticketInfo = TicketInfo.internalBinaryRead(reader, reader.uint32(), options, message.ticketInfo);
                    break;
                case /* string rawAmount */ 5:
                    message.rawAmount = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 6:
                    message.chainID = reader.int32();
                    break;
                case /* uint64 timestamp */ 7:
                    message.timestamp = reader.uint64().toNumber();
                    break;
                case /* bpt.common.TxOnChainType txType */ 8:
                    message.txType = reader.int32();
                    break;
                case /* bpt.common.TxOnChainStatus status */ 9:
                    message.status = reader.int32();
                    break;
                case /* string matchKey */ 10:
                    message.matchKey = reader.string();
                    break;
                case /* string method */ 11:
                    message.method = reader.string();
                    break;
                case /* uint32 taskId */ 12:
                    message.taskId = reader.uint32();
                    break;
                case /* string productId */ 14:
                    message.productId = reader.string();
                    break;
                case /* int64 propId */ 15:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* string currentBalance */ 16:
                    message.currentBalance = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TxOnChainDetail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string txHash = 1; */
        if (message.txHash !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.txHash);
        /* string fromAddr = 2; */
        if (message.fromAddr !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.fromAddr);
        /* string toAddr = 3; */
        if (message.toAddr !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.toAddr);
        /* string contractAddr = 4; */
        if (message.contractAddr !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.contractAddr);
        /* bpt.common.TicketInfo ticketInfo = 13; */
        if (message.ticketInfo)
            TicketInfo.internalBinaryWrite(message.ticketInfo, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string rawAmount = 5; */
        if (message.rawAmount !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.rawAmount);
        /* gaw.base.ChainID chainID = 6; */
        if (message.chainID !== 0)
            writer.tag(6, WireType.Varint).int32(message.chainID);
        /* uint64 timestamp = 7; */
        if (message.timestamp !== 0)
            writer.tag(7, WireType.Varint).uint64(message.timestamp);
        /* bpt.common.TxOnChainType txType = 8; */
        if (message.txType !== 0)
            writer.tag(8, WireType.Varint).int32(message.txType);
        /* bpt.common.TxOnChainStatus status = 9; */
        if (message.status !== 0)
            writer.tag(9, WireType.Varint).int32(message.status);
        /* string matchKey = 10; */
        if (message.matchKey !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.matchKey);
        /* string method = 11; */
        if (message.method !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.method);
        /* uint32 taskId = 12; */
        if (message.taskId !== 0)
            writer.tag(12, WireType.Varint).uint32(message.taskId);
        /* string productId = 14; */
        if (message.productId !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.productId);
        /* int64 propId = 15; */
        if (message.propId !== 0)
            writer.tag(15, WireType.Varint).int64(message.propId);
        /* string currentBalance = 16; */
        if (message.currentBalance !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.currentBalance);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.TxOnChainDetail
 */
export const TxOnChainDetail = new TxOnChainDetail$Type();
