// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/prop/v1/prop.proto" (package "bpt.hall.prop.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PropService } from "./prop";
import type { ListUserTxOnChainLogInternalResp } from "./prop";
import type { ListUserTxOnChainLogInternalReq } from "./prop";
import type { FilterOffChainPropResp } from "./prop";
import type { FilterOffChainPropReq } from "./prop";
import type { BatchGetPropMTTPriceInternalResp } from "./prop";
import type { BatchGetPropMTTPriceInternalReq } from "./prop";
import type { BatchGetPropPriceInternalResp } from "./prop";
import type { BatchGetPropPriceInternalReq } from "./prop";
import type { AdminSendPropResp } from "./prop";
import type { AdminSendPropReq } from "./prop";
import type { GetPropInternalResp } from "./prop";
import type { GetPropInternalReq } from "./prop";
import type { ListUserPropNftInternalResp } from "./prop";
import type { ListUserPropNftInternalReq } from "./prop";
import type { UpdateUserTxOnChainLogResp } from "./prop";
import type { UpdateUserTxOnChainLogReq } from "./prop";
import type { SaveDailyTrainingCoinConfigReq } from "./prop";
import type { GetDailyTrainingCoinConfigResp } from "./prop";
import type { ListPropTypeResp } from "./prop";
import type { ListPropTypeReq } from "./prop";
import type { ListPropCategoryResp } from "./prop";
import type { SellDelPropReq } from "./prop";
import type { UnFreezePropReq } from "./prop";
import type { BaseResp } from "./prop";
import type { FreezePropReq } from "./prop";
import type { ListSimplyPropResp } from "./prop";
import type { ListSimplyPropReq } from "./prop";
import type { BatchGetResourceResp } from "../../../base/base";
import type { BatchGetResourceReq } from "../../../base/base";
import type { CheckUserExpiredPropCallbackReq } from "./prop";
import type { ListUserPropLogResp } from "./prop";
import type { ListUserPropLogReq } from "./prop";
import type { CMSListUserResResp } from "./prop";
import type { CMSListUserResReq } from "./prop";
import type { RefundUserPropResp } from "./prop";
import type { RefundUserPropReq } from "./prop";
import type { FillAssetsResp } from "../../common/assets";
import type { FillAssetsReq } from "../../common/assets";
import type { IncUserAssetsByMallBuyResp } from "./prop";
import type { IncUserAssetsByMallBuyReq } from "./prop";
import type { DecUserOnChainAssetsResp } from "./prop";
import type { DecUserOnChainAssetsReq } from "./prop";
import type { IncUserOnChainAssetsResp } from "./prop";
import type { IncUserOnChainAssetsReq } from "./prop";
import type { DecUserAssetsResp } from "../../common/assets";
import type { DecUserAssetsReq } from "../../common/assets";
import type { IncUserAssetsResp } from "../../common/assets";
import type { IncUserAssetsReq } from "../../common/assets";
import type { GetStatsResp } from "./prop";
import type { GetStatsReq } from "./prop";
import type { SavePropResp } from "./prop";
import type { SavePropReq } from "./prop";
import type { IncUserSendOnChainAssetsTxLogResp } from "./prop";
import type { IncUserSendOnChainAssetsTxLogReq } from "./prop";
import type { UsePropOnSelfResp } from "./prop";
import type { UsePropOnSelfReq } from "./prop";
import type { BatchGetUserPropNumResp } from "./prop";
import type { BatchGetUserPropNumReq } from "./prop";
import type { GetUserPropNumResp } from "./prop";
import type { GetUserPropNumReq } from "./prop";
import type { ListUserPropActivityLogResp } from "./prop";
import type { ListUserPropActivityLogReq } from "./prop";
import type { SendGiftResp } from "./prop";
import type { SendGiftReq } from "./prop";
import type { UseInteractivePropResp } from "./prop";
import type { UseInteractivePropReq } from "./prop";
import type { GetUserSelfUsablePropsOnTableResp } from "./prop";
import type { GetUserSelfUsablePropsOnTableReq } from "./prop";
import type { GetUserPropOnTableResp } from "./prop";
import type { GetUserPropOnTableReq } from "./prop";
import type { GetUserPropBagResp } from "./prop";
import type { GetUserPropBagReq } from "./prop";
import type { ListUserPropNFTResp } from "./prop";
import type { ListUserPropNFTReq } from "./prop";
import type { ListUserTxOnChainLogResp } from "./prop";
import type { ListUserTxOnChainLogReq } from "./prop";
import type { GetUserDailyTrainingCoinInfoResp } from "./prop";
import type { GetUserDailyTrainingCoinInfoReq } from "./prop";
import type { GetDailyTrainingCoinResp } from "./prop";
import type { GetDailyTrainingCoinReq } from "./prop";
import type { GetUserCoinResp } from "./prop";
import type { GetUserCoinReq } from "./prop";
import type { BatchGetUserPropResp } from "./prop";
import type { BatchGetUserPropReq } from "./prop";
import type { GetUserPropGawBalanceListResp } from "./prop";
import type { GetUserPropGawBalanceListReq } from "./prop";
import type { GetPropGawResp } from "./prop";
import type { Void } from "../../../base/base";
import type { ListPropResp } from "./prop";
import type { ListPropReq } from "./prop";
import type { BatchGetPropResp } from "./prop";
import type { BatchGetPropReq } from "./prop";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { GetPropResp } from "./prop";
import type { GetPropReq } from "./prop";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service bpt.hall.prop.v1.PropService
 */
export interface IPropServiceClient {
    /**
     * ------------------对外接口 Start------------------
     * GetProp: 获取道具(后台有使用)
     *
     * @generated from protobuf rpc: GetProp(bpt.hall.prop.v1.GetPropReq) returns (bpt.hall.prop.v1.GetPropResp);
     */
    getProp(input: GetPropReq, options?: RpcOptions): UnaryCall<GetPropReq, GetPropResp>;
    /**
     * BatchGetProp: 批量获取道具
     *
     * @generated from protobuf rpc: BatchGetProp(bpt.hall.prop.v1.BatchGetPropReq) returns (bpt.hall.prop.v1.BatchGetPropResp);
     */
    batchGetProp(input: BatchGetPropReq, options?: RpcOptions): UnaryCall<BatchGetPropReq, BatchGetPropResp>;
    /**
     * ListProp: 列举道具（目前支持按功能类型、名字模糊匹配、ID查找）(后台有使用)
     *
     * @generated from protobuf rpc: ListProp(bpt.hall.prop.v1.ListPropReq) returns (bpt.hall.prop.v1.ListPropResp);
     */
    listProp(input: ListPropReq, options?: RpcOptions): UnaryCall<ListPropReq, ListPropResp>;
    /**
     * @generated from protobuf rpc: GetPropGaw(bpt.base.Void) returns (bpt.hall.prop.v1.GetPropGawResp);
     */
    getPropGaw(input: Void, options?: RpcOptions): UnaryCall<Void, GetPropGawResp>;
    /**
     * 获取用户的gaw资产信息
     *
     * @generated from protobuf rpc: GetUserPropGawBalanceList(bpt.hall.prop.v1.GetUserPropGawBalanceListReq) returns (bpt.hall.prop.v1.GetUserPropGawBalanceListResp);
     */
    getUserPropGawBalanceList(input: GetUserPropGawBalanceListReq, options?: RpcOptions): UnaryCall<GetUserPropGawBalanceListReq, GetUserPropGawBalanceListResp>;
    /**
     * @generated from protobuf rpc: BatchGetUserProp(bpt.hall.prop.v1.BatchGetUserPropReq) returns (bpt.hall.prop.v1.BatchGetUserPropResp);
     */
    batchGetUserProp(input: BatchGetUserPropReq, options?: RpcOptions): UnaryCall<BatchGetUserPropReq, BatchGetUserPropResp>;
    /**
     * ==============金币（暂时用于计数）=================
     *
     * @generated from protobuf rpc: GetUserCoin(bpt.hall.prop.v1.GetUserCoinReq) returns (bpt.hall.prop.v1.GetUserCoinResp);
     */
    getUserCoin(input: GetUserCoinReq, options?: RpcOptions): UnaryCall<GetUserCoinReq, GetUserCoinResp>;
    /**
     * ==============其它===============================
     * 领取免费训练币
     *
     * @generated from protobuf rpc: GetDailyTrainingCoin(bpt.hall.prop.v1.GetDailyTrainingCoinReq) returns (bpt.hall.prop.v1.GetDailyTrainingCoinResp);
     */
    getDailyTrainingCoin(input: GetDailyTrainingCoinReq, options?: RpcOptions): UnaryCall<GetDailyTrainingCoinReq, GetDailyTrainingCoinResp>;
    /**
     * 查询用户免费训练币信息
     *
     * @generated from protobuf rpc: GetUserDailyTrainingCoinInfo(bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoReq) returns (bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoResp);
     */
    getUserDailyTrainingCoinInfo(input: GetUserDailyTrainingCoinInfoReq, options?: RpcOptions): UnaryCall<GetUserDailyTrainingCoinInfoReq, GetUserDailyTrainingCoinInfoResp>;
    /**
     * 查询链上交易流水信息
     *
     * @generated from protobuf rpc: ListUserTxOnChainLog(bpt.hall.prop.v1.ListUserTxOnChainLogReq) returns (bpt.hall.prop.v1.ListUserTxOnChainLogResp);
     */
    listUserTxOnChainLog(input: ListUserTxOnChainLogReq, options?: RpcOptions): UnaryCall<ListUserTxOnChainLogReq, ListUserTxOnChainLogResp>;
    /**
     * 查询用户NFT
     *
     * @generated from protobuf rpc: ListUserPropNFT(bpt.hall.prop.v1.ListUserPropNFTReq) returns (bpt.hall.prop.v1.ListUserPropNFTResp);
     */
    listUserPropNFT(input: ListUserPropNFTReq, options?: RpcOptions): UnaryCall<ListUserPropNFTReq, ListUserPropNFTResp>;
    /**
     * 获取用户道具背包
     *
     * @generated from protobuf rpc: GetUserPropBag(bpt.hall.prop.v1.GetUserPropBagReq) returns (bpt.hall.prop.v1.GetUserPropBagResp);
     */
    getUserPropBag(input: GetUserPropBagReq, options?: RpcOptions): UnaryCall<GetUserPropBagReq, GetUserPropBagResp>;
    /**
     * 获取用户在桌子上的道具列表-对别人使用的道具列表
     *
     * @generated from protobuf rpc: GetUserPropOnTable(bpt.hall.prop.v1.GetUserPropOnTableReq) returns (bpt.hall.prop.v1.GetUserPropOnTableResp);
     */
    getUserPropOnTable(input: GetUserPropOnTableReq, options?: RpcOptions): UnaryCall<GetUserPropOnTableReq, GetUserPropOnTableResp>;
    /**
     * 获取用户在桌子上只能对自己使用的道具列表
     *
     * @generated from protobuf rpc: GetUserSelfUsablePropsOnTable(bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableReq) returns (bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableResp);
     */
    getUserSelfUsablePropsOnTable(input: GetUserSelfUsablePropsOnTableReq, options?: RpcOptions): UnaryCall<GetUserSelfUsablePropsOnTableReq, GetUserSelfUsablePropsOnTableResp>;
    /**
     * 使用互动道具
     *
     * @generated from protobuf rpc: UseInteractiveProp(bpt.hall.prop.v1.UseInteractivePropReq) returns (bpt.hall.prop.v1.UseInteractivePropResp);
     */
    useInteractiveProp(input: UseInteractivePropReq, options?: RpcOptions): UnaryCall<UseInteractivePropReq, UseInteractivePropResp>;
    /**
     * 赠送礼物
     *
     * @generated from protobuf rpc: SendGift(bpt.hall.prop.v1.SendGiftReq) returns (bpt.hall.prop.v1.SendGiftResp);
     */
    sendGift(input: SendGiftReq, options?: RpcOptions): UnaryCall<SendGiftReq, SendGiftResp>;
    /**
     * 查询用户道具使用记录
     *
     * @generated from protobuf rpc: ListUserPropActivityLog(bpt.hall.prop.v1.ListUserPropActivityLogReq) returns (bpt.hall.prop.v1.ListUserPropActivityLogResp);
     */
    listUserPropActivityLog(input: ListUserPropActivityLogReq, options?: RpcOptions): UnaryCall<ListUserPropActivityLogReq, ListUserPropActivityLogResp>;
    /**
     * 当前用户道具数量
     *
     * @generated from protobuf rpc: GetUserPropNum(bpt.hall.prop.v1.GetUserPropNumReq) returns (bpt.hall.prop.v1.GetUserPropNumResp);
     */
    getUserPropNum(input: GetUserPropNumReq, options?: RpcOptions): UnaryCall<GetUserPropNumReq, GetUserPropNumResp>;
    /**
     * 批量当前用户道具数量
     *
     * @generated from protobuf rpc: BatchGetUserPropNum(bpt.hall.prop.v1.BatchGetUserPropNumReq) returns (bpt.hall.prop.v1.BatchGetUserPropNumResp);
     */
    batchGetUserPropNum(input: BatchGetUserPropNumReq, options?: RpcOptions): UnaryCall<BatchGetUserPropNumReq, BatchGetUserPropNumResp>;
    /**
     * 对自己使用道具
     *
     * @generated from protobuf rpc: UsePropOnSelf(bpt.hall.prop.v1.UsePropOnSelfReq) returns (bpt.hall.prop.v1.UsePropOnSelfResp);
     */
    usePropOnSelf(input: UsePropOnSelfReq, options?: RpcOptions): UnaryCall<UsePropOnSelfReq, UsePropOnSelfResp>;
    /**
     * 新增用户Send链上资产交易中记录
     *
     * @generated from protobuf rpc: IncUserSendOnChainAssetsTxLog(bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogReq) returns (bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogResp);
     */
    incUserSendOnChainAssetsTxLog(input: IncUserSendOnChainAssetsTxLogReq, options?: RpcOptions): UnaryCall<IncUserSendOnChainAssetsTxLogReq, IncUserSendOnChainAssetsTxLogResp>;
    // ------------------对外接口 End------------------

    /**
     * ------------------内部接口 Start------------------
     * 保存道具(后台有使用)
     *
     * @generated from protobuf rpc: SaveProp(bpt.hall.prop.v1.SavePropReq) returns (bpt.hall.prop.v1.SavePropResp);
     */
    saveProp(input: SavePropReq, options?: RpcOptions): UnaryCall<SavePropReq, SavePropResp>;
    /**
     * 获取道具统计信息
     *
     * @generated from protobuf rpc: GetStats(bpt.hall.prop.v1.GetStatsReq) returns (bpt.hall.prop.v1.GetStatsResp);
     */
    getStats(input: GetStatsReq, options?: RpcOptions): UnaryCall<GetStatsReq, GetStatsResp>;
    /**
     * 增加用户资产(公共库应用的)
     *
     * @generated from protobuf rpc: IncUserAssets(bpt.hall.common.IncUserAssetsReq) returns (bpt.hall.common.IncUserAssetsResp);
     */
    incUserAssets(input: IncUserAssetsReq, options?: RpcOptions): UnaryCall<IncUserAssetsReq, IncUserAssetsResp>;
    /**
     * 减少用户资产(公共库应用的)
     *
     * @generated from protobuf rpc: DecUserAssets(bpt.hall.common.DecUserAssetsReq) returns (bpt.hall.common.DecUserAssetsResp);
     */
    decUserAssets(input: DecUserAssetsReq, options?: RpcOptions): UnaryCall<DecUserAssetsReq, DecUserAssetsResp>;
    /**
     * 增加用户链上资产
     *
     * @generated from protobuf rpc: IncUserOnChainAssets(bpt.hall.prop.v1.IncUserOnChainAssetsReq) returns (bpt.hall.prop.v1.IncUserOnChainAssetsResp);
     */
    incUserOnChainAssets(input: IncUserOnChainAssetsReq, options?: RpcOptions): UnaryCall<IncUserOnChainAssetsReq, IncUserOnChainAssetsResp>;
    /**
     * 减少用户链上资产
     *
     * @generated from protobuf rpc: DecUserOnChainAssets(bpt.hall.prop.v1.DecUserOnChainAssetsReq) returns (bpt.hall.prop.v1.DecUserOnChainAssetsResp);
     */
    decUserOnChainAssets(input: DecUserOnChainAssetsReq, options?: RpcOptions): UnaryCall<DecUserOnChainAssetsReq, DecUserOnChainAssetsResp>;
    /**
     * IncUserAssetsByMallBuy: 增加用户资产通过商城购买
     *
     * @generated from protobuf rpc: IncUserAssetsByMallBuy(bpt.hall.prop.v1.IncUserAssetsByMallBuyReq) returns (bpt.hall.prop.v1.IncUserAssetsByMallBuyResp);
     */
    incUserAssetsByMallBuy(input: IncUserAssetsByMallBuyReq, options?: RpcOptions): UnaryCall<IncUserAssetsByMallBuyReq, IncUserAssetsByMallBuyResp>;
    /**
     * 填充资产信息(公共库应用的)
     *
     * @generated from protobuf rpc: FillAssets(bpt.hall.common.FillAssetsReq) returns (bpt.hall.common.FillAssetsResp);
     */
    fillAssets(input: FillAssetsReq, options?: RpcOptions): UnaryCall<FillAssetsReq, FillAssetsResp>;
    /**
     * @generated from protobuf rpc: RefundUserProp(bpt.hall.prop.v1.RefundUserPropReq) returns (bpt.hall.prop.v1.RefundUserPropResp);
     */
    refundUserProp(input: RefundUserPropReq, options?: RpcOptions): UnaryCall<RefundUserPropReq, RefundUserPropResp>;
    /**
     * 列举用户道具和礼包
     *
     * @generated from protobuf rpc: CMSListUserRes(bpt.hall.prop.v1.CMSListUserResReq) returns (bpt.hall.prop.v1.CMSListUserResResp);
     */
    cMSListUserRes(input: CMSListUserResReq, options?: RpcOptions): UnaryCall<CMSListUserResReq, CMSListUserResResp>;
    /**
     * 列举道具流水
     *
     * @generated from protobuf rpc: ListUserPropLog(bpt.hall.prop.v1.ListUserPropLogReq) returns (bpt.hall.prop.v1.ListUserPropLogResp);
     */
    listUserPropLog(input: ListUserPropLogReq, options?: RpcOptions): UnaryCall<ListUserPropLogReq, ListUserPropLogResp>;
    /**
     * timedJob
     *
     * @generated from protobuf rpc: CheckUserExpiredPropCallback(bpt.hall.prop.v1.CheckUserExpiredPropCallbackReq) returns (bpt.base.Void);
     */
    checkUserExpiredPropCallback(input: CheckUserExpiredPropCallbackReq, options?: RpcOptions): UnaryCall<CheckUserExpiredPropCallbackReq, Void>;
    /**
     * 同步领域
     * 批量获取道具（同步功能使用）
     *
     * @generated from protobuf rpc: BatchGetPropForSync(bpt.base.BatchGetResourceReq) returns (bpt.base.BatchGetResourceResp);
     */
    batchGetPropForSync(input: BatchGetResourceReq, options?: RpcOptions): UnaryCall<BatchGetResourceReq, BatchGetResourceResp>;
    /**
     * ListSimplyProp: 获取列表
     *
     * @generated from protobuf rpc: ListSimplyProp(bpt.hall.prop.v1.ListSimplyPropReq) returns (bpt.hall.prop.v1.ListSimplyPropResp);
     */
    listSimplyProp(input: ListSimplyPropReq, options?: RpcOptions): UnaryCall<ListSimplyPropReq, ListSimplyPropResp>;
    /**
     * 冻结用户道具
     *
     * @generated from protobuf rpc: FreezeProp(bpt.hall.prop.v1.FreezePropReq) returns (bpt.hall.prop.v1.BaseResp);
     */
    freezeProp(input: FreezePropReq, options?: RpcOptions): UnaryCall<FreezePropReq, BaseResp>;
    /**
     * 解冻用户道具
     *
     * @generated from protobuf rpc: UnFreezeProp(bpt.hall.prop.v1.UnFreezePropReq) returns (bpt.hall.prop.v1.BaseResp);
     */
    unFreezeProp(input: UnFreezePropReq, options?: RpcOptions): UnaryCall<UnFreezePropReq, BaseResp>;
    /**
     * 删除出售道具
     *
     * @generated from protobuf rpc: SellDelProp(bpt.hall.prop.v1.SellDelPropReq) returns (bpt.hall.prop.v1.BaseResp);
     */
    sellDelProp(input: SellDelPropReq, options?: RpcOptions): UnaryCall<SellDelPropReq, BaseResp>;
    /**
     * =============分类，功能类型========================
     * 获取道具分类列表
     *
     * @generated from protobuf rpc: ListPropCategory(bpt.base.Void) returns (bpt.hall.prop.v1.ListPropCategoryResp);
     */
    listPropCategory(input: Void, options?: RpcOptions): UnaryCall<Void, ListPropCategoryResp>;
    /**
     * 获取道具功能类型列表
     *
     * @generated from protobuf rpc: ListPropType(bpt.hall.prop.v1.ListPropTypeReq) returns (bpt.hall.prop.v1.ListPropTypeResp);
     */
    listPropType(input: ListPropTypeReq, options?: RpcOptions): UnaryCall<ListPropTypeReq, ListPropTypeResp>;
    /**
     * 配置每日免费训练币配置
     *
     * @generated from protobuf rpc: GetDailyTrainingCoinConfig(bpt.base.Void) returns (bpt.hall.prop.v1.GetDailyTrainingCoinConfigResp);
     */
    getDailyTrainingCoinConfig(input: Void, options?: RpcOptions): UnaryCall<Void, GetDailyTrainingCoinConfigResp>;
    /**
     * 保存每日免费训练币配置
     *
     * @generated from protobuf rpc: SaveDailyTrainingCoinConfig(bpt.hall.prop.v1.SaveDailyTrainingCoinConfigReq) returns (bpt.base.Void);
     */
    saveDailyTrainingCoinConfig(input: SaveDailyTrainingCoinConfigReq, options?: RpcOptions): UnaryCall<SaveDailyTrainingCoinConfigReq, Void>;
    /**
     * 链上交易流水信息更新
     *
     * @generated from protobuf rpc: UpdateUserTxOnChainLog(bpt.hall.prop.v1.UpdateUserTxOnChainLogReq) returns (bpt.hall.prop.v1.UpdateUserTxOnChainLogResp);
     */
    updateUserTxOnChainLog(input: UpdateUserTxOnChainLogReq, options?: RpcOptions): UnaryCall<UpdateUserTxOnChainLogReq, UpdateUserTxOnChainLogResp>;
    /**
     * 内部查询用户NFT
     *
     * @generated from protobuf rpc: ListUserPropNftInternal(bpt.hall.prop.v1.ListUserPropNftInternalReq) returns (bpt.hall.prop.v1.ListUserPropNftInternalResp);
     */
    listUserPropNftInternal(input: ListUserPropNftInternalReq, options?: RpcOptions): UnaryCall<ListUserPropNftInternalReq, ListUserPropNftInternalResp>;
    /**
     * GetPropInternal: 内部使用获取道具接口
     *
     * @generated from protobuf rpc: GetPropInternal(bpt.hall.prop.v1.GetPropInternalReq) returns (bpt.hall.prop.v1.GetPropInternalResp);
     */
    getPropInternal(input: GetPropInternalReq, options?: RpcOptions): UnaryCall<GetPropInternalReq, GetPropInternalResp>;
    /**
     * 后台系统发放道具
     *
     * @generated from protobuf rpc: AdminSendProp(bpt.hall.prop.v1.AdminSendPropReq) returns (bpt.hall.prop.v1.AdminSendPropResp);
     */
    adminSendProp(input: AdminSendPropReq, options?: RpcOptions): UnaryCall<AdminSendPropReq, AdminSendPropResp>;
    /**
     * 批量获取当前道具的价值(linkedErc20PropId=3 MTT)
     *
     * @generated from protobuf rpc: BatchGetPropPriceInternal(bpt.hall.prop.v1.BatchGetPropPriceInternalReq) returns (bpt.hall.prop.v1.BatchGetPropPriceInternalResp);
     */
    batchGetPropPriceInternal(input: BatchGetPropPriceInternalReq, options?: RpcOptions): UnaryCall<BatchGetPropPriceInternalReq, BatchGetPropPriceInternalResp>;
    /**
     * 批量获取当前道具的MTT价值
     *
     * @generated from protobuf rpc: BatchGetPropMTTPriceInternal(bpt.hall.prop.v1.BatchGetPropMTTPriceInternalReq) returns (bpt.hall.prop.v1.BatchGetPropMTTPriceInternalResp);
     */
    batchGetPropMTTPriceInternal(input: BatchGetPropMTTPriceInternalReq, options?: RpcOptions): UnaryCall<BatchGetPropMTTPriceInternalReq, BatchGetPropMTTPriceInternalResp>;
    /**
     * 传入propid,过滤链上的资产，仅返回链上的
     *
     * @generated from protobuf rpc: FilterOffChainProp(bpt.hall.prop.v1.FilterOffChainPropReq) returns (bpt.hall.prop.v1.FilterOffChainPropResp);
     */
    filterOffChainProp(input: FilterOffChainPropReq, options?: RpcOptions): UnaryCall<FilterOffChainPropReq, FilterOffChainPropResp>;
    /**
     * 查询链上交易流水信息(内部)
     *
     * @generated from protobuf rpc: ListUserTxOnChainLogInternal(bpt.hall.prop.v1.ListUserTxOnChainLogInternalReq) returns (bpt.hall.prop.v1.ListUserTxOnChainLogInternalResp);
     */
    listUserTxOnChainLogInternal(input: ListUserTxOnChainLogInternalReq, options?: RpcOptions): UnaryCall<ListUserTxOnChainLogInternalReq, ListUserTxOnChainLogInternalResp>;
}
/**
 * @generated from protobuf service bpt.hall.prop.v1.PropService
 */
export class PropServiceClient implements IPropServiceClient, ServiceInfo {
    typeName = PropService.typeName;
    methods = PropService.methods;
    options = PropService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * ------------------对外接口 Start------------------
     * GetProp: 获取道具(后台有使用)
     *
     * @generated from protobuf rpc: GetProp(bpt.hall.prop.v1.GetPropReq) returns (bpt.hall.prop.v1.GetPropResp);
     */
    getProp(input: GetPropReq, options?: RpcOptions): UnaryCall<GetPropReq, GetPropResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPropReq, GetPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * BatchGetProp: 批量获取道具
     *
     * @generated from protobuf rpc: BatchGetProp(bpt.hall.prop.v1.BatchGetPropReq) returns (bpt.hall.prop.v1.BatchGetPropResp);
     */
    batchGetProp(input: BatchGetPropReq, options?: RpcOptions): UnaryCall<BatchGetPropReq, BatchGetPropResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetPropReq, BatchGetPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * ListProp: 列举道具（目前支持按功能类型、名字模糊匹配、ID查找）(后台有使用)
     *
     * @generated from protobuf rpc: ListProp(bpt.hall.prop.v1.ListPropReq) returns (bpt.hall.prop.v1.ListPropResp);
     */
    listProp(input: ListPropReq, options?: RpcOptions): UnaryCall<ListPropReq, ListPropResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListPropReq, ListPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetPropGaw(bpt.base.Void) returns (bpt.hall.prop.v1.GetPropGawResp);
     */
    getPropGaw(input: Void, options?: RpcOptions): UnaryCall<Void, GetPropGawResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetPropGawResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取用户的gaw资产信息
     *
     * @generated from protobuf rpc: GetUserPropGawBalanceList(bpt.hall.prop.v1.GetUserPropGawBalanceListReq) returns (bpt.hall.prop.v1.GetUserPropGawBalanceListResp);
     */
    getUserPropGawBalanceList(input: GetUserPropGawBalanceListReq, options?: RpcOptions): UnaryCall<GetUserPropGawBalanceListReq, GetUserPropGawBalanceListResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserPropGawBalanceListReq, GetUserPropGawBalanceListResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BatchGetUserProp(bpt.hall.prop.v1.BatchGetUserPropReq) returns (bpt.hall.prop.v1.BatchGetUserPropResp);
     */
    batchGetUserProp(input: BatchGetUserPropReq, options?: RpcOptions): UnaryCall<BatchGetUserPropReq, BatchGetUserPropResp> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserPropReq, BatchGetUserPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * ==============金币（暂时用于计数）=================
     *
     * @generated from protobuf rpc: GetUserCoin(bpt.hall.prop.v1.GetUserCoinReq) returns (bpt.hall.prop.v1.GetUserCoinResp);
     */
    getUserCoin(input: GetUserCoinReq, options?: RpcOptions): UnaryCall<GetUserCoinReq, GetUserCoinResp> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserCoinReq, GetUserCoinResp>("unary", this._transport, method, opt, input);
    }
    /**
     * ==============其它===============================
     * 领取免费训练币
     *
     * @generated from protobuf rpc: GetDailyTrainingCoin(bpt.hall.prop.v1.GetDailyTrainingCoinReq) returns (bpt.hall.prop.v1.GetDailyTrainingCoinResp);
     */
    getDailyTrainingCoin(input: GetDailyTrainingCoinReq, options?: RpcOptions): UnaryCall<GetDailyTrainingCoinReq, GetDailyTrainingCoinResp> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetDailyTrainingCoinReq, GetDailyTrainingCoinResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询用户免费训练币信息
     *
     * @generated from protobuf rpc: GetUserDailyTrainingCoinInfo(bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoReq) returns (bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoResp);
     */
    getUserDailyTrainingCoinInfo(input: GetUserDailyTrainingCoinInfoReq, options?: RpcOptions): UnaryCall<GetUserDailyTrainingCoinInfoReq, GetUserDailyTrainingCoinInfoResp> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserDailyTrainingCoinInfoReq, GetUserDailyTrainingCoinInfoResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询链上交易流水信息
     *
     * @generated from protobuf rpc: ListUserTxOnChainLog(bpt.hall.prop.v1.ListUserTxOnChainLogReq) returns (bpt.hall.prop.v1.ListUserTxOnChainLogResp);
     */
    listUserTxOnChainLog(input: ListUserTxOnChainLogReq, options?: RpcOptions): UnaryCall<ListUserTxOnChainLogReq, ListUserTxOnChainLogResp> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserTxOnChainLogReq, ListUserTxOnChainLogResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询用户NFT
     *
     * @generated from protobuf rpc: ListUserPropNFT(bpt.hall.prop.v1.ListUserPropNFTReq) returns (bpt.hall.prop.v1.ListUserPropNFTResp);
     */
    listUserPropNFT(input: ListUserPropNFTReq, options?: RpcOptions): UnaryCall<ListUserPropNFTReq, ListUserPropNFTResp> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserPropNFTReq, ListUserPropNFTResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取用户道具背包
     *
     * @generated from protobuf rpc: GetUserPropBag(bpt.hall.prop.v1.GetUserPropBagReq) returns (bpt.hall.prop.v1.GetUserPropBagResp);
     */
    getUserPropBag(input: GetUserPropBagReq, options?: RpcOptions): UnaryCall<GetUserPropBagReq, GetUserPropBagResp> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserPropBagReq, GetUserPropBagResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取用户在桌子上的道具列表-对别人使用的道具列表
     *
     * @generated from protobuf rpc: GetUserPropOnTable(bpt.hall.prop.v1.GetUserPropOnTableReq) returns (bpt.hall.prop.v1.GetUserPropOnTableResp);
     */
    getUserPropOnTable(input: GetUserPropOnTableReq, options?: RpcOptions): UnaryCall<GetUserPropOnTableReq, GetUserPropOnTableResp> {
        const method = this.methods[12], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserPropOnTableReq, GetUserPropOnTableResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取用户在桌子上只能对自己使用的道具列表
     *
     * @generated from protobuf rpc: GetUserSelfUsablePropsOnTable(bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableReq) returns (bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableResp);
     */
    getUserSelfUsablePropsOnTable(input: GetUserSelfUsablePropsOnTableReq, options?: RpcOptions): UnaryCall<GetUserSelfUsablePropsOnTableReq, GetUserSelfUsablePropsOnTableResp> {
        const method = this.methods[13], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserSelfUsablePropsOnTableReq, GetUserSelfUsablePropsOnTableResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 使用互动道具
     *
     * @generated from protobuf rpc: UseInteractiveProp(bpt.hall.prop.v1.UseInteractivePropReq) returns (bpt.hall.prop.v1.UseInteractivePropResp);
     */
    useInteractiveProp(input: UseInteractivePropReq, options?: RpcOptions): UnaryCall<UseInteractivePropReq, UseInteractivePropResp> {
        const method = this.methods[14], opt = this._transport.mergeOptions(options);
        return stackIntercept<UseInteractivePropReq, UseInteractivePropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 赠送礼物
     *
     * @generated from protobuf rpc: SendGift(bpt.hall.prop.v1.SendGiftReq) returns (bpt.hall.prop.v1.SendGiftResp);
     */
    sendGift(input: SendGiftReq, options?: RpcOptions): UnaryCall<SendGiftReq, SendGiftResp> {
        const method = this.methods[15], opt = this._transport.mergeOptions(options);
        return stackIntercept<SendGiftReq, SendGiftResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询用户道具使用记录
     *
     * @generated from protobuf rpc: ListUserPropActivityLog(bpt.hall.prop.v1.ListUserPropActivityLogReq) returns (bpt.hall.prop.v1.ListUserPropActivityLogResp);
     */
    listUserPropActivityLog(input: ListUserPropActivityLogReq, options?: RpcOptions): UnaryCall<ListUserPropActivityLogReq, ListUserPropActivityLogResp> {
        const method = this.methods[16], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserPropActivityLogReq, ListUserPropActivityLogResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 当前用户道具数量
     *
     * @generated from protobuf rpc: GetUserPropNum(bpt.hall.prop.v1.GetUserPropNumReq) returns (bpt.hall.prop.v1.GetUserPropNumResp);
     */
    getUserPropNum(input: GetUserPropNumReq, options?: RpcOptions): UnaryCall<GetUserPropNumReq, GetUserPropNumResp> {
        const method = this.methods[17], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetUserPropNumReq, GetUserPropNumResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量当前用户道具数量
     *
     * @generated from protobuf rpc: BatchGetUserPropNum(bpt.hall.prop.v1.BatchGetUserPropNumReq) returns (bpt.hall.prop.v1.BatchGetUserPropNumResp);
     */
    batchGetUserPropNum(input: BatchGetUserPropNumReq, options?: RpcOptions): UnaryCall<BatchGetUserPropNumReq, BatchGetUserPropNumResp> {
        const method = this.methods[18], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetUserPropNumReq, BatchGetUserPropNumResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 对自己使用道具
     *
     * @generated from protobuf rpc: UsePropOnSelf(bpt.hall.prop.v1.UsePropOnSelfReq) returns (bpt.hall.prop.v1.UsePropOnSelfResp);
     */
    usePropOnSelf(input: UsePropOnSelfReq, options?: RpcOptions): UnaryCall<UsePropOnSelfReq, UsePropOnSelfResp> {
        const method = this.methods[19], opt = this._transport.mergeOptions(options);
        return stackIntercept<UsePropOnSelfReq, UsePropOnSelfResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 新增用户Send链上资产交易中记录
     *
     * @generated from protobuf rpc: IncUserSendOnChainAssetsTxLog(bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogReq) returns (bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogResp);
     */
    incUserSendOnChainAssetsTxLog(input: IncUserSendOnChainAssetsTxLogReq, options?: RpcOptions): UnaryCall<IncUserSendOnChainAssetsTxLogReq, IncUserSendOnChainAssetsTxLogResp> {
        const method = this.methods[20], opt = this._transport.mergeOptions(options);
        return stackIntercept<IncUserSendOnChainAssetsTxLogReq, IncUserSendOnChainAssetsTxLogResp>("unary", this._transport, method, opt, input);
    }
    // ------------------对外接口 End------------------

    /**
     * ------------------内部接口 Start------------------
     * 保存道具(后台有使用)
     *
     * @generated from protobuf rpc: SaveProp(bpt.hall.prop.v1.SavePropReq) returns (bpt.hall.prop.v1.SavePropResp);
     */
    saveProp(input: SavePropReq, options?: RpcOptions): UnaryCall<SavePropReq, SavePropResp> {
        const method = this.methods[21], opt = this._transport.mergeOptions(options);
        return stackIntercept<SavePropReq, SavePropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取道具统计信息
     *
     * @generated from protobuf rpc: GetStats(bpt.hall.prop.v1.GetStatsReq) returns (bpt.hall.prop.v1.GetStatsResp);
     */
    getStats(input: GetStatsReq, options?: RpcOptions): UnaryCall<GetStatsReq, GetStatsResp> {
        const method = this.methods[22], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetStatsReq, GetStatsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 增加用户资产(公共库应用的)
     *
     * @generated from protobuf rpc: IncUserAssets(bpt.hall.common.IncUserAssetsReq) returns (bpt.hall.common.IncUserAssetsResp);
     */
    incUserAssets(input: IncUserAssetsReq, options?: RpcOptions): UnaryCall<IncUserAssetsReq, IncUserAssetsResp> {
        const method = this.methods[23], opt = this._transport.mergeOptions(options);
        return stackIntercept<IncUserAssetsReq, IncUserAssetsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 减少用户资产(公共库应用的)
     *
     * @generated from protobuf rpc: DecUserAssets(bpt.hall.common.DecUserAssetsReq) returns (bpt.hall.common.DecUserAssetsResp);
     */
    decUserAssets(input: DecUserAssetsReq, options?: RpcOptions): UnaryCall<DecUserAssetsReq, DecUserAssetsResp> {
        const method = this.methods[24], opt = this._transport.mergeOptions(options);
        return stackIntercept<DecUserAssetsReq, DecUserAssetsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 增加用户链上资产
     *
     * @generated from protobuf rpc: IncUserOnChainAssets(bpt.hall.prop.v1.IncUserOnChainAssetsReq) returns (bpt.hall.prop.v1.IncUserOnChainAssetsResp);
     */
    incUserOnChainAssets(input: IncUserOnChainAssetsReq, options?: RpcOptions): UnaryCall<IncUserOnChainAssetsReq, IncUserOnChainAssetsResp> {
        const method = this.methods[25], opt = this._transport.mergeOptions(options);
        return stackIntercept<IncUserOnChainAssetsReq, IncUserOnChainAssetsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 减少用户链上资产
     *
     * @generated from protobuf rpc: DecUserOnChainAssets(bpt.hall.prop.v1.DecUserOnChainAssetsReq) returns (bpt.hall.prop.v1.DecUserOnChainAssetsResp);
     */
    decUserOnChainAssets(input: DecUserOnChainAssetsReq, options?: RpcOptions): UnaryCall<DecUserOnChainAssetsReq, DecUserOnChainAssetsResp> {
        const method = this.methods[26], opt = this._transport.mergeOptions(options);
        return stackIntercept<DecUserOnChainAssetsReq, DecUserOnChainAssetsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * IncUserAssetsByMallBuy: 增加用户资产通过商城购买
     *
     * @generated from protobuf rpc: IncUserAssetsByMallBuy(bpt.hall.prop.v1.IncUserAssetsByMallBuyReq) returns (bpt.hall.prop.v1.IncUserAssetsByMallBuyResp);
     */
    incUserAssetsByMallBuy(input: IncUserAssetsByMallBuyReq, options?: RpcOptions): UnaryCall<IncUserAssetsByMallBuyReq, IncUserAssetsByMallBuyResp> {
        const method = this.methods[27], opt = this._transport.mergeOptions(options);
        return stackIntercept<IncUserAssetsByMallBuyReq, IncUserAssetsByMallBuyResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 填充资产信息(公共库应用的)
     *
     * @generated from protobuf rpc: FillAssets(bpt.hall.common.FillAssetsReq) returns (bpt.hall.common.FillAssetsResp);
     */
    fillAssets(input: FillAssetsReq, options?: RpcOptions): UnaryCall<FillAssetsReq, FillAssetsResp> {
        const method = this.methods[28], opt = this._transport.mergeOptions(options);
        return stackIntercept<FillAssetsReq, FillAssetsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RefundUserProp(bpt.hall.prop.v1.RefundUserPropReq) returns (bpt.hall.prop.v1.RefundUserPropResp);
     */
    refundUserProp(input: RefundUserPropReq, options?: RpcOptions): UnaryCall<RefundUserPropReq, RefundUserPropResp> {
        const method = this.methods[29], opt = this._transport.mergeOptions(options);
        return stackIntercept<RefundUserPropReq, RefundUserPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 列举用户道具和礼包
     *
     * @generated from protobuf rpc: CMSListUserRes(bpt.hall.prop.v1.CMSListUserResReq) returns (bpt.hall.prop.v1.CMSListUserResResp);
     */
    cMSListUserRes(input: CMSListUserResReq, options?: RpcOptions): UnaryCall<CMSListUserResReq, CMSListUserResResp> {
        const method = this.methods[30], opt = this._transport.mergeOptions(options);
        return stackIntercept<CMSListUserResReq, CMSListUserResResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 列举道具流水
     *
     * @generated from protobuf rpc: ListUserPropLog(bpt.hall.prop.v1.ListUserPropLogReq) returns (bpt.hall.prop.v1.ListUserPropLogResp);
     */
    listUserPropLog(input: ListUserPropLogReq, options?: RpcOptions): UnaryCall<ListUserPropLogReq, ListUserPropLogResp> {
        const method = this.methods[31], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserPropLogReq, ListUserPropLogResp>("unary", this._transport, method, opt, input);
    }
    /**
     * timedJob
     *
     * @generated from protobuf rpc: CheckUserExpiredPropCallback(bpt.hall.prop.v1.CheckUserExpiredPropCallbackReq) returns (bpt.base.Void);
     */
    checkUserExpiredPropCallback(input: CheckUserExpiredPropCallbackReq, options?: RpcOptions): UnaryCall<CheckUserExpiredPropCallbackReq, Void> {
        const method = this.methods[32], opt = this._transport.mergeOptions(options);
        return stackIntercept<CheckUserExpiredPropCallbackReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 同步领域
     * 批量获取道具（同步功能使用）
     *
     * @generated from protobuf rpc: BatchGetPropForSync(bpt.base.BatchGetResourceReq) returns (bpt.base.BatchGetResourceResp);
     */
    batchGetPropForSync(input: BatchGetResourceReq, options?: RpcOptions): UnaryCall<BatchGetResourceReq, BatchGetResourceResp> {
        const method = this.methods[33], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetResourceReq, BatchGetResourceResp>("unary", this._transport, method, opt, input);
    }
    /**
     * ListSimplyProp: 获取列表
     *
     * @generated from protobuf rpc: ListSimplyProp(bpt.hall.prop.v1.ListSimplyPropReq) returns (bpt.hall.prop.v1.ListSimplyPropResp);
     */
    listSimplyProp(input: ListSimplyPropReq, options?: RpcOptions): UnaryCall<ListSimplyPropReq, ListSimplyPropResp> {
        const method = this.methods[34], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListSimplyPropReq, ListSimplyPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 冻结用户道具
     *
     * @generated from protobuf rpc: FreezeProp(bpt.hall.prop.v1.FreezePropReq) returns (bpt.hall.prop.v1.BaseResp);
     */
    freezeProp(input: FreezePropReq, options?: RpcOptions): UnaryCall<FreezePropReq, BaseResp> {
        const method = this.methods[35], opt = this._transport.mergeOptions(options);
        return stackIntercept<FreezePropReq, BaseResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 解冻用户道具
     *
     * @generated from protobuf rpc: UnFreezeProp(bpt.hall.prop.v1.UnFreezePropReq) returns (bpt.hall.prop.v1.BaseResp);
     */
    unFreezeProp(input: UnFreezePropReq, options?: RpcOptions): UnaryCall<UnFreezePropReq, BaseResp> {
        const method = this.methods[36], opt = this._transport.mergeOptions(options);
        return stackIntercept<UnFreezePropReq, BaseResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除出售道具
     *
     * @generated from protobuf rpc: SellDelProp(bpt.hall.prop.v1.SellDelPropReq) returns (bpt.hall.prop.v1.BaseResp);
     */
    sellDelProp(input: SellDelPropReq, options?: RpcOptions): UnaryCall<SellDelPropReq, BaseResp> {
        const method = this.methods[37], opt = this._transport.mergeOptions(options);
        return stackIntercept<SellDelPropReq, BaseResp>("unary", this._transport, method, opt, input);
    }
    /**
     * =============分类，功能类型========================
     * 获取道具分类列表
     *
     * @generated from protobuf rpc: ListPropCategory(bpt.base.Void) returns (bpt.hall.prop.v1.ListPropCategoryResp);
     */
    listPropCategory(input: Void, options?: RpcOptions): UnaryCall<Void, ListPropCategoryResp> {
        const method = this.methods[38], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, ListPropCategoryResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取道具功能类型列表
     *
     * @generated from protobuf rpc: ListPropType(bpt.hall.prop.v1.ListPropTypeReq) returns (bpt.hall.prop.v1.ListPropTypeResp);
     */
    listPropType(input: ListPropTypeReq, options?: RpcOptions): UnaryCall<ListPropTypeReq, ListPropTypeResp> {
        const method = this.methods[39], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListPropTypeReq, ListPropTypeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 配置每日免费训练币配置
     *
     * @generated from protobuf rpc: GetDailyTrainingCoinConfig(bpt.base.Void) returns (bpt.hall.prop.v1.GetDailyTrainingCoinConfigResp);
     */
    getDailyTrainingCoinConfig(input: Void, options?: RpcOptions): UnaryCall<Void, GetDailyTrainingCoinConfigResp> {
        const method = this.methods[40], opt = this._transport.mergeOptions(options);
        return stackIntercept<Void, GetDailyTrainingCoinConfigResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 保存每日免费训练币配置
     *
     * @generated from protobuf rpc: SaveDailyTrainingCoinConfig(bpt.hall.prop.v1.SaveDailyTrainingCoinConfigReq) returns (bpt.base.Void);
     */
    saveDailyTrainingCoinConfig(input: SaveDailyTrainingCoinConfigReq, options?: RpcOptions): UnaryCall<SaveDailyTrainingCoinConfigReq, Void> {
        const method = this.methods[41], opt = this._transport.mergeOptions(options);
        return stackIntercept<SaveDailyTrainingCoinConfigReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 链上交易流水信息更新
     *
     * @generated from protobuf rpc: UpdateUserTxOnChainLog(bpt.hall.prop.v1.UpdateUserTxOnChainLogReq) returns (bpt.hall.prop.v1.UpdateUserTxOnChainLogResp);
     */
    updateUserTxOnChainLog(input: UpdateUserTxOnChainLogReq, options?: RpcOptions): UnaryCall<UpdateUserTxOnChainLogReq, UpdateUserTxOnChainLogResp> {
        const method = this.methods[42], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateUserTxOnChainLogReq, UpdateUserTxOnChainLogResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 内部查询用户NFT
     *
     * @generated from protobuf rpc: ListUserPropNftInternal(bpt.hall.prop.v1.ListUserPropNftInternalReq) returns (bpt.hall.prop.v1.ListUserPropNftInternalResp);
     */
    listUserPropNftInternal(input: ListUserPropNftInternalReq, options?: RpcOptions): UnaryCall<ListUserPropNftInternalReq, ListUserPropNftInternalResp> {
        const method = this.methods[43], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserPropNftInternalReq, ListUserPropNftInternalResp>("unary", this._transport, method, opt, input);
    }
    /**
     * GetPropInternal: 内部使用获取道具接口
     *
     * @generated from protobuf rpc: GetPropInternal(bpt.hall.prop.v1.GetPropInternalReq) returns (bpt.hall.prop.v1.GetPropInternalResp);
     */
    getPropInternal(input: GetPropInternalReq, options?: RpcOptions): UnaryCall<GetPropInternalReq, GetPropInternalResp> {
        const method = this.methods[44], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetPropInternalReq, GetPropInternalResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 后台系统发放道具
     *
     * @generated from protobuf rpc: AdminSendProp(bpt.hall.prop.v1.AdminSendPropReq) returns (bpt.hall.prop.v1.AdminSendPropResp);
     */
    adminSendProp(input: AdminSendPropReq, options?: RpcOptions): UnaryCall<AdminSendPropReq, AdminSendPropResp> {
        const method = this.methods[45], opt = this._transport.mergeOptions(options);
        return stackIntercept<AdminSendPropReq, AdminSendPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取当前道具的价值(linkedErc20PropId=3 MTT)
     *
     * @generated from protobuf rpc: BatchGetPropPriceInternal(bpt.hall.prop.v1.BatchGetPropPriceInternalReq) returns (bpt.hall.prop.v1.BatchGetPropPriceInternalResp);
     */
    batchGetPropPriceInternal(input: BatchGetPropPriceInternalReq, options?: RpcOptions): UnaryCall<BatchGetPropPriceInternalReq, BatchGetPropPriceInternalResp> {
        const method = this.methods[46], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetPropPriceInternalReq, BatchGetPropPriceInternalResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 批量获取当前道具的MTT价值
     *
     * @generated from protobuf rpc: BatchGetPropMTTPriceInternal(bpt.hall.prop.v1.BatchGetPropMTTPriceInternalReq) returns (bpt.hall.prop.v1.BatchGetPropMTTPriceInternalResp);
     */
    batchGetPropMTTPriceInternal(input: BatchGetPropMTTPriceInternalReq, options?: RpcOptions): UnaryCall<BatchGetPropMTTPriceInternalReq, BatchGetPropMTTPriceInternalResp> {
        const method = this.methods[47], opt = this._transport.mergeOptions(options);
        return stackIntercept<BatchGetPropMTTPriceInternalReq, BatchGetPropMTTPriceInternalResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 传入propid,过滤链上的资产，仅返回链上的
     *
     * @generated from protobuf rpc: FilterOffChainProp(bpt.hall.prop.v1.FilterOffChainPropReq) returns (bpt.hall.prop.v1.FilterOffChainPropResp);
     */
    filterOffChainProp(input: FilterOffChainPropReq, options?: RpcOptions): UnaryCall<FilterOffChainPropReq, FilterOffChainPropResp> {
        const method = this.methods[48], opt = this._transport.mergeOptions(options);
        return stackIntercept<FilterOffChainPropReq, FilterOffChainPropResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 查询链上交易流水信息(内部)
     *
     * @generated from protobuf rpc: ListUserTxOnChainLogInternal(bpt.hall.prop.v1.ListUserTxOnChainLogInternalReq) returns (bpt.hall.prop.v1.ListUserTxOnChainLogInternalResp);
     */
    listUserTxOnChainLogInternal(input: ListUserTxOnChainLogInternalReq, options?: RpcOptions): UnaryCall<ListUserTxOnChainLogInternalReq, ListUserTxOnChainLogInternalResp> {
        const method = this.methods[49], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUserTxOnChainLogInternalReq, ListUserTxOnChainLogInternalResp>("unary", this._transport, method, opt, input);
    }
}
