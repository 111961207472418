// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/match/common/v1/common.proto" (package "bpt.match.common.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ChainID } from "../../../../gaw/base/base";
import { I18nField } from "../../../common/common";
import { AnteType } from "../../../common/common";
import { I18n } from "../../../common/common_lang";
import { SceneType } from "../../../common/common";
import { SimpleAsset } from "../../../common/common_define";
import { SwitchState } from "../../../common/common_define";
import { AssetItem } from "../../../common/common_define";
// message BlindInfo {
//  uint32 level =1; //涨盲的盲注等级
//  int64  levelEnd =2;  // 盲注等级结束时间
//  int64 breakEnd = 3;  // 如果有休息时间，休息时间结束秒级时间戳,如果没有休息时间，传0
// }

/**
 * @generated from protobuf message bpt.match.common.v1.Match
 */
export interface Match {
    /**
     * @generated from protobuf field: int64 matchID = 1;
     */
    matchID: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus state = 3;
     */
    state: MatchStatus;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.User users = 4;
     */
    users: User[]; // 所有用户
    /**
     * @generated from protobuf field: int64 confID = 5;
     */
    confID: number;
    /**
     * @generated from protobuf field: bool isRegister = 6;
     */
    isRegister: boolean; // 当前用户是否已报名
    /**
     * @generated from protobuf field: int64 confLastUpdate = 7;
     */
    confLastUpdate: number;
    /**
     * @generated from protobuf field: string chatGroupId = 8;
     */
    chatGroupId: string;
    /**
     * @generated from protobuf field: int64 startedAt = 9;
     */
    startedAt: number; // 比赛开始时间戳，单位秒
    /**
     * @generated from protobuf field: int64 endAt = 10;
     */
    endAt: number;
    /**
     * @generated from protobuf field: uint32 blindLevel = 11;
     */
    blindLevel: number; // deprecated 使用blindinfo的level
    /**
     * @generated from protobuf field: bool isBubbleTime = 12;
     */
    isBubbleTime: boolean;
    /**
     * @generated from protobuf field: bool isDelayRegister = 13;
     */
    isDelayRegister: boolean; // 是否能够延迟报名
    /**
     * @generated from protobuf field: int64 registerDeadline = 26;
     */
    registerDeadline: number; // 延迟报名时间
    /**
     * @generated from protobuf field: int64 waitDelayRegisterDeadline = 42;
     */
    waitDelayRegisterDeadline: number; // 剩下1人时，等待延时用户进场时间
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 14;
     */
    prizePool: AssetItem[]; // 总奖池(动态，包含重入和重购后增加的奖池)
    /**
     * @generated from protobuf field: string matchKey = 15;
     */
    matchKey: string; // 全局唯一标记
    /**
     * @generated from protobuf field: int64 finalTableID = 16;
     */
    finalTableID: number;
    /**
     * @generated from protobuf field: uint32 multipler = 17;
     */
    multipler: number; // spingo奖励倍数
    /**
     * @generated from protobuf field: bool isBreakTime = 18;
     */
    isBreakTime: boolean; // deprecated 是否在休息时间,使用blindInfo的hasbreaktime 和时间范围判断
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 19;
     */
    conf?: MatchConf;
    /**
     * @generated from protobuf field: int64 userCnt = 21;
     */
    userCnt: number; // 报名用户
    /**
     * @generated from protobuf field: int64 srvID = 22;
     */
    srvID: number; // 服务id
    /**
     * @generated from protobuf field: uint32 myRank = 23;
     */
    myRank: number; // 我的排名
    /**
     * @generated from protobuf field: uint32 avgChip = 24;
     */
    avgChip: number; // 平均筹码
    /**
     * @generated from protobuf field: uint32 smallestStack = 50;
     */
    smallestStack: number; // 最小选手分数
    /**
     * @generated from protobuf field: uint32 largestStack = 51;
     */
    largestStack: number; // 最小选手分数
    /**
     * @generated from protobuf field: uint32 playingNum = 25;
     */
    playingNum: number; // 当前在玩用户（未淘汰）
    /**
     * @generated from protobuf field: bool isPrizeRound = 27;
     */
    isPrizeRound: boolean; // 是否钱圈
    /**
     * @generated from protobuf field: bool isAbleEnter = 28;
     */
    isAbleEnter: boolean; // 是否能报名，（钱圈，泡沫圈，过了延时报名时间,finaltable,开桌只有一桌，人满 都不能进入）
    /**
     * @generated from protobuf field: bpt.match.common.v1.MyTable myTable = 29;
     */
    myTable?: MyTable; // 我的桌子
    /**
     * @generated from protobuf field: int64 breakTimeStopAt = 31;
     */
    breakTimeStopAt: number;
    /**
     * @generated from protobuf field: uint32 rollBackBlindLevel = 32;
     */
    rollBackBlindLevel: number; // 发生回滚时的盲注等级，不发生值为0
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchInfo mainMatchInfo = 39;
     */
    mainMatchInfo?: MainMatchInfo;
    /**
     * @generated from protobuf field: uint32 startBlindLevel = 40;
     */
    startBlindLevel: number; // 开始盲注界别，对于多阶段比赛，开始盲注级别可能不为1
    /**
     * @generated from protobuf field: int64 registrationAt = 41;
     */
    registrationAt: number; // 比赛报名时间戳，单位秒
    /**
     * @generated from protobuf field: string lastPhasedMatchKey = 43;
     */
    lastPhasedMatchKey: string; // 多日赛最后一场比赛MatchKey
    /**
     * @generated from protobuf field: int64 pauseEndAt = 44;
     */
    pauseEndAt: number; // 当值为0时，未暂停，>0时，为暂停的截止时间
    /**
     * @generated from protobuf field: int64 pauseBeginAt = 45;
     */
    pauseBeginAt: number; // 暂停开始的时间
    /**
     * 当前已经暂停的总时长（单位：秒）不包括当前暂停（从pauseEndAt到now()）
     *
     * @generated from protobuf field: int32 elapsedPauseSeconds = 46;
     */
    elapsedPauseSeconds: number;
    /**
     * 目标席位赛目标比赛Key
     *
     * @generated from protobuf field: string seatMatchTargetMatchKey = 47;
     */
    seatMatchTargetMatchKey: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.MatchFeatureConfig
 */
export interface MatchFeatureConfig {
    /**
     * 首页显示冠军，默认开启
     *
     * @generated from protobuf field: bpt.common.SwitchState enableHomeChampionDisplay = 1;
     */
    enableHomeChampionDisplay: SwitchState;
    /**
     * 首页显示赛报，默认开启
     *
     * @generated from protobuf field: bpt.common.SwitchState enableHomeMatchReportDisplay = 2;
     */
    enableHomeMatchReportDisplay: SwitchState;
    /**
     * 解锁成就积分，默认开启
     *
     * @generated from protobuf field: bpt.common.SwitchState enableAchievementUnlock = 3;
     */
    enableAchievementUnlock: SwitchState;
    /**
     * 使用认证特权，默认开启
     *
     * @generated from protobuf field: bpt.common.SwitchState enablePrivilegeUse = 4;
     */
    enablePrivilegeUse: SwitchState;
    /**
     * 是否在首页比赛列表展示，默认展示
     *
     * @generated from protobuf field: bpt.common.SwitchState enableHomeListDisplay = 5;
     */
    enableHomeListDisplay: SwitchState;
    /**
     * 俱乐部是否统计奖金和手续费，默认统计
     *
     * @generated from protobuf field: bpt.common.SwitchState enableClubStatistics = 6;
     */
    enableClubStatistics: SwitchState;
    /**
     * 在列表中的保留时间
     *
     * @generated from protobuf field: int64 keepDurationInSeconds = 7;
     */
    keepDurationInSeconds: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.MatchFront
 */
export interface MatchFront {
    /**
     * @generated from protobuf field: int64 matchID = 1;
     */
    matchID: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus state = 3;
     */
    state: MatchStatus;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.User users = 4;
     */
    users: User[]; // 所有用户
    /**
     * @generated from protobuf field: int64 confID = 5;
     */
    confID: number;
    /**
     * @generated from protobuf field: bool isRegister = 6;
     */
    isRegister: boolean; // 当前用户是否已报名
    /**
     * @generated from protobuf field: int64 confLastUpdate = 7;
     */
    confLastUpdate: number;
    /**
     * @generated from protobuf field: string chatGroupId = 8;
     */
    chatGroupId: string;
    /**
     * @generated from protobuf field: int64 startedAt = 9;
     */
    startedAt: number; // 比赛开始时间戳，单位秒
    /**
     * @generated from protobuf field: int64 endAt = 10;
     */
    endAt: number;
    /**
     * @generated from protobuf field: uint32 blindLevel = 11;
     */
    blindLevel: number; // deprecated 使用blindinfo的level
    /**
     * @generated from protobuf field: bool isBubbleTime = 12;
     */
    isBubbleTime: boolean;
    /**
     * @generated from protobuf field: bool isDelayRegister = 13;
     */
    isDelayRegister: boolean; // 是否能够延迟报名
    /**
     * @generated from protobuf field: repeated int64 propIds = 14;
     */
    propIds: number[]; // 总奖池里物品的id
    /**
     * @generated from protobuf field: string matchKey = 15;
     */
    matchKey: string; // 全局唯一标记
    /**
     * @generated from protobuf field: int64 finalTableID = 16;
     */
    finalTableID: number;
    /**
     * @generated from protobuf field: uint32 multipler = 17;
     */
    multipler: number; // spingo奖励倍数
    /**
     * @generated from protobuf field: bool isBreakTime = 18;
     */
    isBreakTime: boolean; // deprecated 是否在休息时间,使用blindInfo的hasbreaktime 和时间范围判断
    /**
     * @generated from protobuf field: int64 userCnt = 21;
     */
    userCnt: number; // 报名用户
    /**
     * @generated from protobuf field: int64 srvID = 22;
     */
    srvID: number; // 服务id
    /**
     * @generated from protobuf field: uint32 myRank = 23;
     */
    myRank: number; // 我的排名
    /**
     * @generated from protobuf field: uint32 avgChip = 24;
     */
    avgChip: number; // 平均筹码
    /**
     * @generated from protobuf field: uint32 playingNum = 25;
     */
    playingNum: number; // 当前在玩用户（未淘汰）
    /**
     * @generated from protobuf field: int64 registerDeadline = 26;
     */
    registerDeadline: number; // 延迟报名时间
    /**
     * @generated from protobuf field: bool isPrizeRound = 27;
     */
    isPrizeRound: boolean; // 是否钱圈
    /**
     * @generated from protobuf field: bool isAbleEnter = 28;
     */
    isAbleEnter: boolean; // 是否能报名，（钱圈，泡沫圈，过了延时报名时间,finaltable,开桌只有一桌，人满 都不能进入）
    /**
     * @generated from protobuf field: bpt.match.common.v1.MyTable myTable = 29;
     */
    myTable?: MyTable; // 我的桌子
    /**
     * @generated from protobuf field: int64 breakTimeStopAt = 31;
     */
    breakTimeStopAt: number;
    /**
     * @generated from protobuf field: uint32 rollBackBlindLevel = 32;
     */
    rollBackBlindLevel: number; // 发生回滚时的盲注等级，不发生值为0
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchInfo mainMatchInfo = 39;
     */
    mainMatchInfo?: MainMatchInfo;
    /**
     * @generated from protobuf field: uint32 startBlindLevel = 40;
     */
    startBlindLevel: number;
    /**
     * @generated from protobuf field: int64 registrationAt = 41;
     */
    registrationAt: number; // 比赛报名时间戳，单位秒
    /**
     * @generated from protobuf field: string lastPhasedMatchKey = 43;
     */
    lastPhasedMatchKey: string; // 多日赛最后一场比赛MatchKey
    /**
     * @generated from protobuf field: int64 waitDelayRegisterDeadline = 44;
     */
    waitDelayRegisterDeadline: number; // 剩下1人时，等待延时用户进场时间
    /**
     * @generated from protobuf field: int64 joinedCnt = 45;
     */
    joinedCnt: number; // 参赛用户
    /**
     * @generated from protobuf field: bool isJoin = 46;
     */
    isJoin: boolean; // 是否已参赛
    /**
     * @generated from protobuf field: int64 pauseEndAt = 47;
     */
    pauseEndAt: number; // 当值为0时，未暂停，>0时，为暂停的截止时间
    /**
     * @generated from protobuf field: int64 pauseBeginAt = 48;
     */
    pauseBeginAt: number; // 暂停开始的时间
    /**
     * 当前已经暂停的总时长（单位：秒）不包括当前暂停（从pauseEndAt到now()）
     *
     * @generated from protobuf field: int32 elapsedPauseSeconds = 49;
     */
    elapsedPauseSeconds: number;
    /**
     * @generated from protobuf field: uint32 smallestStack = 50;
     */
    smallestStack: number; // 最小选手分数
    /**
     * @generated from protobuf field: uint32 largestStack = 51;
     */
    largestStack: number; // 最小选手分数
    /**
     * @generated from protobuf field: uint32 clubID = 52;
     */
    clubID: number; // 俱乐部ID
    /**
     * @generated from protobuf field: bpt.match.common.v1.CancelReason cancelReason = 53;
     */
    cancelReason: CancelReason; // 取消原因
    /**
     * 目标席位赛目标比赛Key
     *
     * @generated from protobuf field: string seatMatchTargetMatchKey = 54;
     */
    seatMatchTargetMatchKey: string;
}
/**
 * 上一场比赛结算的奖池数据，带到这一场累计
 * 如上一场结算时，10分报名，本场20人报名，结算时，报名人数为30
 *
 * @generated from protobuf message bpt.match.common.v1.MatchContext
 */
export interface MatchContext {
    /**
     * @generated from protobuf field: int64 totalBuyInEnterCnt = 1;
     */
    totalBuyInEnterCnt: number; // 总使用buyIn报名人数
    /**
     * @generated from protobuf field: int64 totalTicketEnterCnt = 5;
     */
    totalTicketEnterCnt: number; // 总的使用门票报名的次数，乘以配置的单次需要门票数量，得到总票数
    /**
     * @generated from protobuf field: int64 totalIdentityRightEnterCnt = 11;
     */
    totalIdentityRightEnterCnt: number; // 使用身份特权报名
    /**
     * @generated from protobuf field: int64 totalAddOnCnt = 2;
     */
    totalAddOnCnt: number; // 总手数
    /**
     * @generated from protobuf field: int64 totalReBuyCnt = 3;
     */
    totalReBuyCnt: number; // reBuy次数
    /**
     * @generated from protobuf field: int64 totalBuyInReEntryCnt = 4;
     */
    totalBuyInReEntryCnt: number; // 使用buyin reEntry次数
    /**
     * @generated from protobuf field: int64 totalTicketReEntryCnt = 6;
     */
    totalTicketReEntryCnt: number; // 总的使用门票reentry的次数，乘以配置的单次需要门票数量，得到总票数
    /**
     * @generated from protobuf field: int64 totalIdentityRightReEntryCnt = 12;
     */
    totalIdentityRightReEntryCnt: number; // 使用身份特权重入
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset registerPool = 7;
     */
    registerPool: SimpleAsset[]; // 当前报名累计的奖池，不算手续费
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset addOnPool = 8;
     */
    addOnPool: SimpleAsset[]; // 当前addon累计的奖池，不算手续费
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset reBuyPool = 9;
     */
    reBuyPool: SimpleAsset[]; // 当前rebuy累计的奖池，不算手续费
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset reEntryPool = 10;
     */
    reEntryPool: SimpleAsset[]; // 当前reentry累计的奖池，不算手续费
    /**
     * @generated from protobuf field: int64 totalDeduplicatedEnterCnt = 13;
     */
    totalDeduplicatedEnterCnt: number; // 去重后的报名总人数
}
/**
 * @generated from protobuf message bpt.match.common.v1.MainMatchInfo
 */
export interface MainMatchInfo {
    /**
     * @generated from protobuf field: bool isMainMatch = 1;
     */
    isMainMatch: boolean; // 是否主比赛，有些赛制，如多日赛等赛制，有多个子比赛
    /**
     * @generated from protobuf field: string mainMatchKey = 2;
     */
    mainMatchKey: string;
    /**
     * 如 1,2,3 表示day1,day2,day3
     *
     * @generated from protobuf field: int32 phase = 3;
     */
    phase: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchType mainMatchType = 4;
     */
    mainMatchType: MainMatchType;
    /**
     * 多天赛制中最后一场的比赛matchkey
     *
     * @generated from protobuf field: string finalDayMatchKey = 5;
     */
    finalDayMatchKey: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.StageUser
 */
export interface StageUser {
    /**
     * @generated from protobuf field: uint32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 Uid = 2 [json_name = "Uid"];
     */
    Uid: number;
    /**
     * @generated from protobuf field: uint64 Chips = 3 [json_name = "Chips"];
     */
    Chips: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.DeskUser
 */
export interface DeskUser {
    /**
     * 用户信息
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 筹码
     *
     * @generated from protobuf field: uint64 chips = 2;
     */
    chips: number;
    /**
     * @generated from protobuf field: uint32 seatNo = 3;
     */
    seatNo: number;
    /**
     * 留座离桌
     *
     * @generated from protobuf field: bool isManaged = 4;
     */
    isManaged: boolean;
}
/**
 * @generated from protobuf message bpt.match.common.v1.MyTable
 */
export interface MyTable {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 tableServerID = 2;
     */
    tableServerID: number;
    /**
     * 比赛桌子状态
     *
     * @generated from protobuf field: bpt.match.common.v1.DeskStatus deskStatus = 3;
     */
    deskStatus: DeskStatus;
}
/**
 * @generated from protobuf message bpt.match.common.v1.Desk
 */
export interface Desk {
    /**
     * deprecated
     *
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[]; // deprecated
    /**
     * @generated from protobuf field: int64 deskID = 2;
     */
    deskID: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.DeskStatus status = 3;
     */
    status: DeskStatus;
    /**
     * @generated from protobuf field: string tableKey = 4;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 tableServerID = 5;
     */
    tableServerID: number;
    /**
     * @generated from protobuf field: uint64 smStack = 6;
     */
    smStack: number;
    /**
     * @generated from protobuf field: uint64 lgStack = 7;
     */
    lgStack: number;
    /**
     * 当前盲注等级
     *
     * @generated from protobuf field: uint32 blindLevel = 8;
     */
    blindLevel: number;
    /**
     * 加入的桌子是否finaltable,true表示加入桌子为finaltable
     *
     * @generated from protobuf field: bool isFinalTable = 9;
     */
    isFinalTable: boolean;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.DeskUser deskUsers = 10;
     */
    deskUsers: DeskUser[];
    /**
     * @generated from protobuf field: string matchKey = 11;
     */
    matchKey: string;
    /**
     * 当前桌大小盲
     *
     * @generated from protobuf field: uint64 smallBlind = 12;
     */
    smallBlind: number;
    /**
     * @generated from protobuf field: uint64 bigBlind = 13;
     */
    bigBlind: number;
    /**
     * 现金桌buy-in信息
     * 携带限制下限
     *
     * @generated from protobuf field: uint64 LowerLimitChips = 14 [json_name = "LowerLimitChips"];
     */
    LowerLimitChips: number;
    /**
     * 携带限制上限
     *
     * @generated from protobuf field: uint64 UpperLimitChips = 15 [json_name = "UpperLimitChips"];
     */
    UpperLimitChips: number;
    /**
     * 默认值为9
     *
     * @generated from protobuf field: uint32 playerPerTable = 16;
     */
    playerPerTable: number;
    /**
     * @generated from protobuf field: uint64 ante = 17;
     */
    ante: number;
    /**
     * 桌子列表视图映射id
     *
     * @generated from protobuf field: uint64 deskViewID = 18;
     */
    deskViewID: number;
    /**
     * 庄家位置
     *
     * @generated from protobuf field: uint32 dealerSeatNo = 19;
     */
    dealerSeatNo: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.User
 */
export interface User {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint32 rank = 2;
     */
    rank: number;
    /**
     * @generated from protobuf field: uint32 seatNo = 3;
     */
    seatNo: number;
    /**
     *  int64 deskID=4;
     *
     * @generated from protobuf field: uint64 chips = 5;
     */
    chips: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.UserStatus status = 6;
     */
    status: UserStatus;
    /**
     * 报名时间戳，单位秒
     *
     * @generated from protobuf field: int64 RegisterAt = 7 [json_name = "RegisterAt"];
     */
    RegisterAt: number;
    /**
     * 淘汰时间戳，单位秒
     *
     * @generated from protobuf field: int64 userOutAt = 8;
     */
    userOutAt: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prize = 9;
     */
    prize: AssetItem[];
    /**
     * rebuy 总次数
     *
     * @generated from protobuf field: uint32 reBuyCnt = 10;
     */
    reBuyCnt: number;
    /**
     * addon 总次数
     *
     * @generated from protobuf field: uint32 addOnCnt = 11;
     */
    addOnCnt: number;
    /**
     * reentry 总次数(兼容旧的，reEntryCnt-reEntryByTicketCnt-reEntryByIdentity就是用token重入)
     *
     * @generated from protobuf field: uint32 reEntryCnt = 12;
     */
    reEntryCnt: number;
    /**
     * 用ticket reentry 总次数
     *
     * @generated from protobuf field: uint32 reEntryByTicketCnt = 18;
     */
    reEntryByTicketCnt: number;
    /**
     * @generated from protobuf field: uint32 reEntryByIdentity = 21;
     */
    reEntryByIdentity: number;
    /**
     * 思考时间截止时间
     * 如addOn,rebuy,有一个思考时间截止时间，如果超过思考时间，将从牌局移除
     *
     * @generated from protobuf field: int64 reBuyAddOnDeadLine = 13;
     */
    reBuyAddOnDeadLine: number;
    /**
     * @generated from protobuf field: string tableKey = 14;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 tableSrvID = 15;
     */
    tableSrvID: number;
    /**
     * 发放数量
     *
     * @generated from protobuf field: int32 timeCardNum = 16;
     */
    timeCardNum: number;
    /**
     * @generated from protobuf field: bpt.common.AssetItem matchBuyIn = 17;
     */
    matchBuyIn?: AssetItem; // 比赛報名費
    /**
     * @generated from protobuf field: repeated int64 eliminatedUsers = 19;
     */
    eliminatedUsers: number[]; // 由这个用户本局淘汰掉的玩家
    /**
     * @generated from protobuf field: bool earlyBird = 20;
     */
    earlyBird: boolean; // 是否具备早鸟资格
    /**
     * @generated from protobuf field: bpt.match.common.v1.StageResult stageResult = 22;
     */
    stageResult: StageResult; // 晋级结果
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem reBuyCost = 23;
     */
    reBuyCost: AssetItem[]; // rebuy花费
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem addOnCost = 24;
     */
    addOnCost: AssetItem[]; // addon花费
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem reEntryCost = 25;
     */
    reEntryCost: AssetItem[]; // reentry花费
}
/**
 * @generated from protobuf message bpt.match.common.v1.RegisterInfo
 */
export interface RegisterInfo {
    /**
     * @generated from protobuf field: bpt.match.common.v1.RegisterWay registerWay = 1;
     */
    registerWay: RegisterWay; //  string ticketRawAmount = 2;
}
/**
 * @generated from protobuf message bpt.match.common.v1.RegisterUser
 */
export interface RegisterUser {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 registerAt = 2;
     */
    registerAt: number; // 用于排名
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: int64 chips = 5;
     */
    chips: number; // 用户初始筹码，一般赛事是初始筹码，多日赛事是day1最高筹码
    /**
     * @generated from protobuf field: int64 reEnterCnt = 6;
     */
    reEnterCnt: number;
    /**
     * @generated from protobuf field: int64 reEnterByTicketCnt = 11;
     */
    reEnterByTicketCnt: number;
    /**
     * @generated from protobuf field: int64 reBuyCnt = 7;
     */
    reBuyCnt: number;
    /**
     * @generated from protobuf field: int64 addOnCnt = 8;
     */
    addOnCnt: number;
    /**
     * 时间卡数量
     *
     * @generated from protobuf field: int32 timeCardNum = 9;
     */
    timeCardNum: number;
    /**
     * 报名信息
     *
     * @generated from protobuf field: bpt.match.common.v1.RegisterInfo registerInfo = 10;
     */
    registerInfo?: RegisterInfo;
    /**
     * @generated from protobuf field: bpt.match.common.v1.JoinStatus joinStatus = 12;
     */
    joinStatus: JoinStatus;
    /**
     * @generated from protobuf field: int32 advanceOrder = 13;
     */
    advanceOrder: number; // 晋级顺序，越早晋级名次越高
}
/**
 * @generated from protobuf message bpt.match.common.v1.MsgPaymentResult
 */
export interface MsgPaymentResult {
    /**
     * @generated from protobuf field: string txID = 1;
     */
    txID: string;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.common.SceneType scene = 3;
     */
    scene: SceneType;
    /**
     * 付款流程是否成功，
     *
     * @generated from protobuf field: bool isSuccess = 4;
     */
    isSuccess: boolean;
    /**
     * 如果付款失败，返回错误信息
     *
     * @generated from protobuf field: string errMsg = 5;
     */
    errMsg: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.MsgTxResult
 */
export interface MsgTxResult {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.common.SceneType scene = 2;
     */
    scene: SceneType;
    /**
     * @generated from protobuf field: bool isSuccess = 3;
     */
    isSuccess: boolean; // 是否成功
    /**
     * @generated from protobuf field: string chainTxHash = 4;
     */
    chainTxHash: string; // hash
    /**
     * @generated from protobuf field: int64 serverTS = 5;
     */
    serverTS: number; // 服务器时间戳，秒级
}
/**
 * @generated from protobuf message bpt.match.common.v1.MsgReconnect
 */
export interface MsgReconnect {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 srvID = 2;
     */
    srvID: number;
}
// ///////////////////////////以下是配置部分/////////////////////////////////

/**
 * 后台用，配置
 *
 * @generated from protobuf message bpt.match.common.v1.MatchConf
 */
export interface MatchConf {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * 比赛名称
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * 规则说明
     *
     * @generated from protobuf field: string rule = 3;
     */
    rule: string;
    /**
     * 赛事类型
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * 基础信息
     *
     * @generated from protobuf field: bpt.match.common.v1.BaseConf baseConf = 5;
     */
    baseConf?: BaseConf;
    /**
     * 阶段配置
     *
     * @generated from protobuf field: bpt.match.common.v1.StageConf stageConf = 6;
     */
    stageConf?: StageConf;
    /**
     * 比赛控制
     *
     * @generated from protobuf field: bpt.match.common.v1.ParticipateConf participateConf = 7;
     */
    participateConf?: ParticipateConf;
    /**
     * 比赛时间
     *
     * @generated from protobuf field: bpt.match.common.v1.ScheduleConfig schedule = 8;
     */
    schedule?: ScheduleConfig;
    /**
     * 报名信息
     *
     * @generated from protobuf field: bpt.match.common.v1.EntryConf entryConf = 9;
     */
    entryConf?: EntryConf;
    /**
     * 动态奖励配置
     *
     * @generated from protobuf field: bpt.match.common.v1.DynamicPrizeConf dynamicPrizeConf = 10;
     */
    dynamicPrizeConf?: DynamicPrizeConf;
    /**
     * 配置状态
     *
     * @generated from protobuf field: bpt.match.common.v1.State state = 11;
     */
    state: State;
    /**
     * 最后更新时间时间戳（单位：秒）
     *
     * @generated from protobuf field: int64 lastUpdate = 12;
     */
    lastUpdate: number;
    /**
     * 固定奖励配置
     *
     * @generated from protobuf field: bpt.match.common.v1.FixedPrizeConf fixedPrizeConf = 13;
     */
    fixedPrizeConf?: FixedPrizeConf;
    /**
     * @generated from protobuf field: string operator = 14;
     */
    operator: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.SpinAndGoPrizeConf spinAndGoPrizeConf = 15;
     */
    spinAndGoPrizeConf?: SpinAndGoPrizeConf;
    /**
     * 现金桌配置
     *
     * @generated from protobuf field: bpt.match.common.v1.CashConf cashConf = 16;
     */
    cashConf?: CashConf;
    /**
     * 配置审核状态
     *
     * @generated from protobuf field: bpt.match.common.v1.ReviewState reviewState = 17;
     */
    reviewState: ReviewState;
    /**
     * 动态或静态奖池，Mtt动态奖池去prizeconf,固定奖池取sngprizeconf
     *
     * @generated from protobuf field: bpt.match.common.v1.PrizeType prizeType = 18;
     */
    prizeType: PrizeType;
    /**
     * 赛事方uid
     *
     * @generated from protobuf field: int64 matchOwner = 19;
     */
    matchOwner: number;
    /**
     * 同是满人或者不是满人的房间的排序
     *
     * @generated from protobuf field: int32 showPriority = 20;
     */
    showPriority: number;
    /**
     * 所属TAG(beginner/advanced/expert)
     *
     * @generated from protobuf field: string tag = 21;
     */
    tag: string;
    /**
     * 自动发送奖励
     *
     * @generated from protobuf field: bpt.common.SwitchState autoSendPrize = 22;
     */
    autoSendPrize: SwitchState;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 23 [json_name = "i18n"];
     */
    i18n?: I18n;
    /**
     * 并行奖励配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ParallelPrizeConf parallelPrizeConf = 24;
     */
    parallelPrizeConf?: ParallelPrizeConf;
    /**
     * 开发用，指定host:port格式（如 172.20.153.134:9000 ），将会在制定机器开始比赛
     *
     * @generated from protobuf field: string endPoint = 25;
     */
    endPoint: string;
    /**
     * 赛前cancelMatchTime内，不能取消比赛，不能取消报名，必须字段，时间必须>=30s，单位：秒
     *
     * @generated from protobuf field: int64 cancelMatchTime = 26;
     */
    cancelMatchTime: number;
    /**
     * 赛报是否上报到资讯
     *
     * @generated from protobuf field: bpt.common.SwitchState reportToNews = 27;
     */
    reportToNews: SwitchState;
    /**
     * 版本配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ServiceConf serviceConf = 28;
     */
    serviceConf?: ServiceConf;
    /**
     * 锦标赛类型（排行榜）
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 29;
     */
    matchListType: MatchListType;
    /**
     *  repeated PrizeConfItem prizeConf =28;
     *
     * @generated from protobuf field: bpt.match.common.v1.EarlyBird earlyBird = 30;
     */
    earlyBird?: EarlyBird;
    /**
     * 子比赛配置
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.SubMatchConf subMatchConfs = 31;
     */
    subMatchConfs: SubMatchConf[];
    /**
     * 比赛MatchFeatureConfig
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig = 32;
     */
    matchFeatureConfig?: MatchFeatureConfig;
    /**
     * 俱乐部ID
     *
     * @generated from protobuf field: uint32 clubId = 33;
     */
    clubId: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.PasswordConf
 */
export interface PasswordConf {
    /**
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * @generated from protobuf field: string password = 2;
     */
    password: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.EnterReviewConf
 */
export interface EnterReviewConf {
    /**
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
}
/**
 * @generated from protobuf message bpt.match.common.v1.TimeSlot
 */
export interface TimeSlot {
    /**
     * @generated from protobuf field: int64 showTime = 1;
     */
    showTime: number;
    /**
     * @generated from protobuf field: int64 matchStartTime = 2;
     */
    matchStartTime: number;
}
/**
 * 基础信息
 *
 * @generated from protobuf message bpt.match.common.v1.BaseConf
 */
export interface BaseConf {
    /**
     * @generated from protobuf field: bpt.match.common.v1.BetWay betWay = 1;
     */
    betWay: BetWay;
    /**
     * 初始筹码
     *
     * @generated from protobuf field: uint64 startingStack = 2;
     */
    startingStack: number;
    /**
     * @generated from protobuf field: uint32 minPlayerNum = 3;
     */
    minPlayerNum: number;
    /**
     * maxPlayerNum=0 表示无人数限制
     *
     * @generated from protobuf field: uint32 maxPlayerNum = 4;
     */
    maxPlayerNum: number;
    /**
     * 每桌人数
     * 默认值为9
     *
     * @generated from protobuf field: uint32 playerPerTable = 5;
     */
    playerPerTable: number;
    /**
     * pc端规格列表图片
     *
     * @generated from protobuf field: string showImg = 6;
     */
    showImg: string;
    /**
     * pc端规格详情图片
     *
     * @generated from protobuf field: string showImgMobile = 7;
     */
    showImgMobile: string;
    /**
     * 移动端规格列表图片
     *
     * @generated from protobuf field: string mobileShowImgList = 8;
     */
    mobileShowImgList: string;
    /**
     * 移动端规格详情图片
     *
     * @generated from protobuf field: string mobileShowImgDetail = 9;
     */
    mobileShowImgDetail: string;
    /**
     * pc端规格列表图片
     *
     * @generated from protobuf field: string showImgList = 10;
     */
    showImgList: string; // 列表
    /**
     * pc端规格详情图片
     *
     * @generated from protobuf field: string showImgDetail = 11;
     */
    showImgDetail: string; // 详情
}
/**
 * 服务信息
 *
 * @generated from protobuf message bpt.match.common.v1.ServiceConf
 */
export interface ServiceConf {
    /**
     * 比赛服务版本
     *
     * @generated from protobuf field: int64 matchVersion = 1;
     */
    matchVersion: number;
    /**
     * 桌子服务版本
     *
     * @generated from protobuf field: int64 tableVersion = 2;
     */
    tableVersion: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.EarlyBird
 */
export interface EarlyBird {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState isOpen = 1;
     */
    isOpen: SwitchState;
    /**
     * @generated from protobuf field: bpt.common.SwitchState isBroadcast = 2;
     */
    isBroadcast: SwitchState; // 是否广播大奖
    /**
     * @generated from protobuf field: int64 getDuration = 3;
     */
    getDuration: number; // 早鸟资格(距离开赛前秒数)
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.EarlyBirdAward award = 4;
     */
    award: EarlyBirdAward[]; // 前3个奖项
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.EarlyBirdAvgAward avgAward = 5;
     */
    avgAward: EarlyBirdAvgAward[]; // 第四个，按人数均分
}
/**
 * @generated from protobuf message bpt.match.common.v1.EarlyBirdAward
 */
export interface EarlyBirdAward {
    /**
     * @generated from protobuf field: int32 count = 1;
     */
    count: number; // 个数
    /**
     * @generated from protobuf field: bpt.common.AssetItem award = 3;
     */
    award?: AssetItem;
}
/**
 * @generated from protobuf message bpt.match.common.v1.EarlyBirdAvgAward
 */
export interface EarlyBirdAvgAward {
    /**
     * @generated from protobuf field: int32 count = 1;
     */
    count: number; // 个数
    /**
     * Deprecated: use upLimitAssetItem
     *
     * @generated from protobuf field: int32 upLimit = 2;
     */
    upLimit: number; // 个人上限
    /**
     * @generated from protobuf field: bpt.common.AssetItem award = 3;
     */
    award?: AssetItem; // 奖项
    /**
     * 个人上限
     *
     * @generated from protobuf field: bpt.common.AssetItem upLimitAssetItem = 4;
     */
    upLimitAssetItem?: AssetItem;
}
/**
 * @generated from protobuf message bpt.match.common.v1.StageConf
 */
export interface StageConf {
    /**
     * 盲注结构
     *
     * @generated from protobuf field: bpt.match.common.v1.BlindStructure blindStructure = 1;
     */
    blindStructure?: BlindStructure;
    /**
     * 重购
     *
     * @generated from protobuf field: bpt.match.common.v1.RebuyConf reBuy = 2;
     */
    reBuy?: RebuyConf;
    /**
     * 加注
     *
     * @generated from protobuf field: bpt.match.common.v1.AddOnOption addOn = 3;
     */
    addOn?: AddOnOption;
    /**
     * 重入
     *
     * @generated from protobuf field: bpt.match.common.v1.ReentryConf reEntry = 4;
     */
    reEntry?: ReentryConf;
    /**
     * 暂停休息时间
     *
     * @generated from protobuf field: bpt.match.common.v1.PauseConf pauseConf = 5;
     */
    pauseConf?: PauseConf;
    /**
     * @generated from protobuf field: bpt.match.common.v1.BubbleConf bubbleConf = 6;
     */
    bubbleConf?: BubbleConf;
    /**
     * @generated from protobuf field: bpt.match.common.v1.SpecTableConf specTableConf = 7;
     */
    specTableConf?: SpecTableConf;
    /**
     * @generated from protobuf field: bpt.match.common.v1.TimeCardConf timeCardConf = 8;
     */
    timeCardConf?: TimeCardConf;
}
/**
 * reference https://www.pokerstrategy.com/strategy/live-poker/how-blinds-increase-structure/
 *
 * @generated from protobuf message bpt.match.common.v1.BlindStructure
 */
export interface BlindStructure {
    /**
     * Level必须从1开始
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.BlindLevel levels = 1;
     */
    levels: BlindLevel[];
    /**
     * @generated from protobuf field: bpt.common.AnteType anteType = 2;
     */
    anteType: AnteType;
}
/**
 * @generated from protobuf message bpt.match.common.v1.SubMatchConf
 */
export interface SubMatchConf {
    /**
     * @generated from protobuf field: int64 subMatchConfID = 1;
     */
    subMatchConfID: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.StageConf stageConf = 2;
     */
    stageConf?: StageConf;
    /**
     * @generated from protobuf field: bpt.common.SwitchState sameWithDayOneA = 4;
     */
    sameWithDayOneA: SwitchState;
    /**
     * 初始筹码
     *
     * @generated from protobuf field: uint64 startingStack = 5;
     */
    startingStack: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.DelayPlay delayPlay = 6;
     */
    delayPlay?: DelayPlay;
    /**
     * @generated from protobuf field: bpt.common.SimpleAsset subMatchBuyIn = 7;
     */
    subMatchBuyIn?: SimpleAsset;
}
/**
 * @generated from protobuf message bpt.match.common.v1.BlindLevel
 */
export interface BlindLevel {
    /**
     * @generated from protobuf field: uint32 level = 1;
     */
    level: number;
    /**
     * @generated from protobuf field: uint64 ante = 2;
     */
    ante: number; // 前注： 每一局除了大小盲注强制每人都要下的钱
    /**
     * @generated from protobuf field: uint64 smallBlind = 3;
     */
    smallBlind: number;
    /**
     * @generated from protobuf field: uint64 bigBlind = 4;
     */
    bigBlind: number;
    /**
     * 涨盲时间
     *
     * @generated from protobuf field: uint32 roundLength = 5;
     */
    roundLength: number;
    /**
     * 最小筹码颗粒度
     *
     * @generated from protobuf field: uint64 minChipParticle = 6;
     */
    minChipParticle: number;
    /**
     * 时间卡配置-新增
     *
     * @generated from protobuf field: bpt.match.common.v1.BlindLevelTimeCardConfig timeCardConfig = 7;
     */
    timeCardConfig?: BlindLevelTimeCardConfig;
    /**
     * 盲注tags(展示使用)-新增
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.BlindLevelTagType blindLevelTags = 8;
     */
    blindLevelTags: BlindLevelTagType[];
}
/**
 * 盲注时间卡配置
 *
 * @generated from protobuf message bpt.match.common.v1.BlindLevelTimeCardConfig
 */
export interface BlindLevelTimeCardConfig {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 单个时间银行卡延长的时间(单位：秒)
     *
     * @generated from protobuf field: int64 durationInSecond = 2;
     */
    durationInSecond: number;
    /**
     * 发放数量
     *
     * @generated from protobuf field: int32 timeCardNum = 3;
     */
    timeCardNum: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.RebuyConf
 */
export interface RebuyConf {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 盲注阶段， 包含本值,盲注的前几个阶段
     *
     * @generated from protobuf field: uint32 blindLevel = 2;
     */
    blindLevel: number;
    /**
     *  次数限制， 0为不限制次数
     *
     * @generated from protobuf field: uint32 maxRebuy = 3;
     */
    maxRebuy: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.Exchange exchange = 4;
     */
    exchange?: Exchange;
    /**
     * 用户筹码为0时，通知用户rebuy,用户操作的时间(单位：秒)，规定时间未操作，出局
     *
     * @generated from protobuf field: int64 timeout = 5;
     */
    timeout: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.Exchange
 */
export interface Exchange {
    /**
     * 购买费用
     *
     * @generated from protobuf field: bpt.common.AssetItem pay = 1;
     */
    pay?: AssetItem;
    /**
     * 获得筹码
     *
     * @generated from protobuf field: uint64 chips = 2;
     */
    chips: number;
}
/**
 * AddOn is an option of rebuy,only available if rebuy is on
 *
 * @generated from protobuf message bpt.match.common.v1.AddOnOption
 */
export interface AddOnOption {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 盲注阶段， 包含本值,从某个盲注等级开始
     *
     * @generated from protobuf field: uint32 blindLevel = 2;
     */
    blindLevel: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.Exchange exchange = 3;
     */
    exchange?: Exchange;
    /**
     * 持续时间 单位：秒
     *
     * @generated from protobuf field: int64 durSecond = 4;
     */
    durSecond: number;
    /**
     * 用户筹码为0时，通知用户addon,用户操作的时间(单位：秒)，规定时间未操作，出局
     *
     * @generated from protobuf field: int64 timeout = 5;
     */
    timeout: number;
    /**
     * 一次请求最多买多少手
     *
     * @generated from protobuf field: int64 countMax = 6;
     */
    countMax: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.ReentryConf
 */
export interface ReentryConf {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 最大重入次数，0表示不限次数
     *
     * @generated from protobuf field: uint32 maxReentry = 2;
     */
    maxReentry: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.Exchange exchange = 3;
     */
    exchange?: Exchange;
    /**
     * @generated from protobuf field: uint32 blindLevel = 4;
     */
    blindLevel: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.PauseConf
 */
export interface PauseConf {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 盲注等级映射的休息时间
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.PauseBlind pauseBlind = 4;
     */
    pauseBlind: PauseBlind[];
}
/**
 * @generated from protobuf message bpt.match.common.v1.PauseBlind
 */
export interface PauseBlind {
    /**
     * 盲注等级
     *
     * @generated from protobuf field: uint32 level = 1;
     */
    level: number;
    /**
     * 休息时间,(单位：分钟)
     *
     * @generated from protobuf field: uint32 break = 2;
     */
    break: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.BubbleConf
 */
export interface BubbleConf {
    /**
     * 是否并列排名，默认为false,并列排名时，同时出局的并列排名平分奖金
     *
     * @generated from protobuf field: bpt.common.SwitchState isParallel = 1;
     */
    isParallel: SwitchState;
}
/**
 * @generated from protobuf message bpt.match.common.v1.SpecTableConf
 */
export interface SpecTableConf {
    /**
     * 奖圈保底人数
     *
     * @generated from protobuf field: uint32 minAwardUserNum = 8;
     */
    minAwardUserNum: number;
    /**
     * finalTable人数
     *
     * @generated from protobuf field: uint32 finalTableUserNum = 9;
     */
    finalTableUserNum: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.TimeCardLevelGrant
 */
export interface TimeCardLevelGrant {
    /**
     * 盲注等级
     *
     * @generated from protobuf field: uint32 level = 1;
     */
    level: number;
    /**
     * 发放数量
     *
     * @generated from protobuf field: int32 timeCardNum = 2;
     */
    timeCardNum: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.TimeCardConf
 */
export interface TimeCardConf {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 单个时间银行卡延长的时间(单位：秒)
     *
     * @generated from protobuf field: int64 durationInSecond = 2;
     */
    durationInSecond: number;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.TimeCardLevelGrant timeCardLevelGrants = 3;
     */
    timeCardLevelGrants: TimeCardLevelGrant[];
}
/**
 * 比赛控制
 *
 * @generated from protobuf message bpt.match.common.v1.ParticipateConf
 */
export interface ParticipateConf {
    /**
     * 围观配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ObserverConf observerConf = 1;
     */
    observerConf?: ObserverConf;
    /**
     * 机器人配置
     *
     * @generated from protobuf field: bpt.match.common.v1.RobotConf robotConf = 2;
     */
    robotConf?: RobotConf;
    /**
     * 延迟入场配置
     *
     * @generated from protobuf field: bpt.match.common.v1.DelayPlay delayPlay = 3;
     */
    delayPlay?: DelayPlay;
    /**
     * 密码配置
     *
     * @generated from protobuf field: bpt.match.common.v1.PasswordConf passwordConf = 16;
     */
    passwordConf?: PasswordConf;
    /**
     * 入赛审核配置
     *
     * @generated from protobuf field: bpt.match.common.v1.EnterReviewConf enterReviewConf = 17;
     */
    enterReviewConf?: EnterReviewConf;
}
/**
 * @generated from protobuf message bpt.match.common.v1.ObserverConf
 */
export interface ObserverConf {
    /**
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 默认实时围观
     *
     * @generated from protobuf field: bpt.match.common.v1.ObserveMode observeMode = 2;
     */
    observeMode: ObserveMode;
    /**
     * 默认单人围观
     *
     * @generated from protobuf field: bpt.match.common.v1.ObserveView viewMode = 3;
     */
    viewMode: ObserveView;
    /**
     * 手牌是否可见
     *
     * @generated from protobuf field: bool isVisible = 4;
     */
    isVisible: boolean;
}
/**
 * @generated from protobuf message bpt.match.common.v1.RobotConf
 */
export interface RobotConf {
    /**
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 机器人加入模式
     *
     * @generated from protobuf field: bpt.match.common.v1.RobotEnterMode mode = 2;
     */
    mode: RobotEnterMode;
    /**
     * 机器人最多加入数量
     *
     * @generated from protobuf field: int64 maxNum = 3;
     */
    maxNum: number;
    /**
     * 机器人进入等待时间
     *
     * @generated from protobuf field: bpt.match.common.v1.SectionRange enterWait = 4;
     */
    enterWait?: SectionRange;
    /**
     * 添加时间间隔
     *
     * @generated from protobuf field: bpt.match.common.v1.SectionRange addInterval = 5;
     */
    addInterval?: SectionRange;
    /**
     * 单次加入机器人数量
     *
     * @generated from protobuf field: bpt.match.common.v1.SectionRange addNum = 6;
     */
    addNum?: SectionRange;
}
/**
 * @generated from protobuf message bpt.match.common.v1.SectionRange
 */
export interface SectionRange {
    /**
     * @generated from protobuf field: uint64 start = 1;
     */
    start: number;
    /**
     * @generated from protobuf field: uint64 end = 2;
     */
    end: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.DelayPlay
 */
export interface DelayPlay {
    /**
     * 是否开启
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     *  // 延时时间 单位分钟
     *  int64 delayDuration = 2; //deprecated 新实现
     * 盲注阶段， 包含本值，延迟报名时间截止到配置的盲注等级，
     * 如盲注等级后有休息时间，则包含休息时间
     *
     * @generated from protobuf field: uint32 blindLevel = 3;
     */
    blindLevel: number;
    /**
     *  bpt.common.SwitchState earlyEnd =4; // deprecated 该功能放弃
     *
     * @generated from protobuf field: int64 delayDurationInSec = 5;
     */
    delayDurationInSec: number; // 冗余字段（后台不配置），保存时计算延时报名多少秒
}
/**
 * @generated from protobuf message bpt.match.common.v1.ScheduleConfig
 */
export interface ScheduleConfig {
    /**
     * 比赛开始日期结束日期
     *
     * @generated from protobuf field: bpt.match.common.v1.DateSpan dateSpan = 1;
     */
    dateSpan?: DateSpan;
    /**
     * 时间段
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.TimeSpan timeSpans = 2;
     */
    timeSpans: TimeSpan[];
    /**
     * @generated from protobuf field: string firstDisplayTime = 3;
     */
    firstDisplayTime: string; // 首场比赛提前显示时间(2019-01-24 9:30:00)
    /**
     * 循环类型
     *
     * @generated from protobuf field: bpt.match.common.v1.SchedulerType type = 4;
     */
    type: SchedulerType;
    /**
     * @generated from protobuf field: string matchStart = 5;
     */
    matchStart: string; // 比赛开始时间，对于单天单场和单场适用 对于sng 到时间是展示，对于mtt,上线即展示，到时间开赛
    /**
     * 周期类型
     *
     * @generated from protobuf field: bpt.match.common.v1.PeriodType periodType = 6;
     */
    periodType: PeriodType;
    /**
     * @generated from protobuf field: bpt.match.common.v1.PhasedConfig phasedConfig = 7;
     */
    phasedConfig?: PhasedConfig; // 多日赛事配置
    /**
     * @generated from protobuf field: int64 registrationDuration = 8;
     */
    registrationDuration: number; // 距离开赛多久可以报名
}
/**
 *  time
 * 日期配置
 *
 * @generated from protobuf message bpt.match.common.v1.DateSpan
 */
export interface DateSpan {
    /**
     * @generated from protobuf field: string start = 1;
     */
    start: string; // 开始日期
    /**
     * @generated from protobuf field: string end = 2;
     */
    end: string; // 结束日期
    /**
     * @generated from protobuf field: int32 intervalDay = 3;
     */
    intervalDay: number; // 间隔天数,单天单场和单天多长适用
}
/**
 * 时间配置
 *
 * @generated from protobuf message bpt.match.common.v1.TimeSpan
 */
export interface TimeSpan {
    /**
     * @generated from protobuf field: string start = 1;
     */
    start: string; // 一天的开始时间
    /**
     * @generated from protobuf field: string end = 2;
     */
    end: string; // 一天的结束时间
    /**
     * 单位秒
     *
     * @generated from protobuf field: int64 intervalDuration = 3;
     */
    intervalDuration: number; // 循坏赛时间间隔
}
/**
 * 参考以下链接Phased Tournaments部分 https://www.pokerstars.com/poker/tournaments/types/
 *
 * @generated from protobuf message bpt.match.common.v1.PhaseEntry
 */
export interface PhaseEntry {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * 如 1,2,3 表示day1,day2,day3
     *
     * @generated from protobuf field: int32 phase = 2;
     */
    phase: number;
    /**
     * SwitchStateOn表示这一阶段开启多场
     *
     * @generated from protobuf field: bpt.common.SwitchState multiEntry = 3;
     */
    multiEntry: SwitchState;
    /**
     * @generated from protobuf field: int64 startAt = 4;
     */
    startAt: number; // 开赛时间，时间戳
    /**
     * @generated from protobuf field: bpt.match.common.v1.GameOverType gameOverType = 5;
     */
    gameOverType: GameOverType;
    /**
     * @generated from protobuf field: uint32 gameOverBlindLevel = 6;
     */
    gameOverBlindLevel: number; // 结束的盲注级别
    /**
     * @generated from protobuf field: uint32 minPlayerNum = 8;
     */
    minPlayerNum: number;
    /**
     * @generated from protobuf field: uint32 maxPlayerNum = 9;
     */
    maxPlayerNum: number;
    /**
     * @generated from protobuf field: string name = 10;
     */
    name: string; // 展示名字，如Day1A
    /**
     * 对于GameOverType类型为GameOverTypePercentage 的子比赛，达到结束的百分比
     * 所有此类型的子比赛结束的值均一致
     *
     * @generated from protobuf field: uint32 gameOverPercentage = 11;
     */
    gameOverPercentage: number;
    /**
     * 比赛最低晋级人数
     *
     * @generated from protobuf field: uint32 minAdvancePlayerNum = 12;
     */
    minAdvancePlayerNum: number;
    /**
     * 比赛最大晋级人数
     *
     * @generated from protobuf field: uint32 maxAdvancePlayerNum = 13;
     */
    maxAdvancePlayerNum: number;
    /**
     * 是否继承筹码
     *
     * @generated from protobuf field: bpt.common.SwitchState inheritStack = 14;
     */
    inheritStack: SwitchState;
}
/**
 * @generated from protobuf message bpt.match.common.v1.PhaseRule
 */
export interface PhaseRule {
    /**
     * 如 1,2,3 表示day1,day2,day3
     *
     * @generated from protobuf field: int32 phase = 1;
     */
    phase: number;
    /**
     * @generated from protobuf field: bpt.common.I18nField ruleDesc = 2;
     */
    ruleDesc?: I18nField;
}
/**
 * 多天赛事配置，对应SchedulerTypePhased
 *
 * @generated from protobuf message bpt.match.common.v1.PhasedConfig
 */
export interface PhasedConfig {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.PhaseEntry phases = 1;
     */
    phases: PhaseEntry[];
    /**
     * Deprecated: use PhaseEntry.gameOverPercentage
     *
     * @generated from protobuf field: uint32 gameOverPercentage = 2;
     */
    gameOverPercentage: number; // deprecated 每一场单独配置
    /**
     * 各个阶段的
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.PhaseRule phaseRules = 3;
     */
    phaseRules: PhaseRule[];
}
/**
 *  ERC-1155的门票 数量可为uint256
 *
 * @generated from protobuf message bpt.match.common.v1.Ticket
 */
export interface Ticket {
    /**
     * @generated from protobuf field: string contractAddress = 1;
     */
    contractAddress: string; // 合约地址
    /**
     * @generated from protobuf field: string tokenID = 2;
     */
    tokenID: string;
    /**
     * @generated from protobuf field: string rawAmount = 3;
     */
    rawAmount: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.EntryConf
 */
export interface EntryConf {
    /**
     * @generated from protobuf field: bpt.match.common.v1.EntryNodeConfig node = 2;
     */
    node?: EntryNodeConfig;
    /**
     * @generated from protobuf field: bpt.match.common.v1.ChainConf chainConf = 3;
     */
    chainConf?: ChainConf;
    /**
     * @generated from protobuf field: bpt.match.common.v1.TicketsConf ticketsConf = 4;
     */
    ticketsConf?: TicketsConf;
    /**
     * 限制Club(为0表示不限)
     *
     * @generated from protobuf field: repeated uint32 clubIds = 5;
     */
    clubIds: number[];
}
/**
 * @generated from protobuf message bpt.match.common.v1.ChainConf
 */
export interface ChainConf {
    /**
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 链类型
     *
     * @generated from protobuf field: gaw.base.ChainID chainID = 2;
     */
    chainID: ChainID;
    /**
     * 链请求回调容忍时间
     *
     * @generated from protobuf field: int64 waitTimeForChain = 3;
     */
    waitTimeForChain: number;
    /**
     * 是不是业务业务代报名（业务代报名）
     *
     * @generated from protobuf field: bpt.common.SwitchState autoApply = 4;
     */
    autoApply: SwitchState; // true 业务自动代报名
    /**
     * 比赛合约地址
     *
     * @generated from protobuf field: string dispatchContract = 5;
     */
    dispatchContract: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.EntryNodeConfig
 */
export interface EntryNodeConfig {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.EntryNodeType type = 2;
     */
    type: EntryNodeType;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.EntryNodeConfig nodes = 3;
     */
    nodes: EntryNodeConfig[];
    /**
     * 新增报名费
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem buyIn = 4;
     */
    buyIn: AssetItem[];
    /**
     * 阈值
     *
     * @generated from protobuf field: int64 threshold = 11;
     */
    threshold: number;
    /**
     * ">=" "<"
     *
     * @generated from protobuf field: string sign = 12;
     */
    sign: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.TicketsConf
 */
export interface TicketsConf {
    /**
     * 是否启用门票报名，默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 门票物品id
     *
     * @generated from protobuf field: int64 propId = 2;
     */
    propId: number;
    /**
     * 报名所需门票数
     *
     * @generated from protobuf field: int32 registerTicketCnt = 3;
     */
    registerTicketCnt: number;
    /**
     * 重入门票配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ReentryTicketsConf reentryTicketsConf = 4;
     */
    reentryTicketsConf?: ReentryTicketsConf;
    /**
     * @generated from protobuf field: int64 exchangePropID = 5;
     */
    exchangePropID: number;
    /**
     * @generated from protobuf field: string exchangePropRawAmount = 6;
     */
    exchangePropRawAmount: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.ReentryTicketsConf
 */
export interface ReentryTicketsConf {
    /**
     * reentry是否可以使用门票
     *
     * @generated from protobuf field: bpt.common.SwitchState reentrySwitch = 4;
     */
    reentrySwitch: SwitchState;
    /**
     * reentry所需门票数
     *
     * @generated from protobuf field: int32 reentryTicketCnt = 5;
     */
    reentryTicketCnt: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.DynamicPrizeConf
 */
export interface DynamicPrizeConf {
    /**
     * 基础奖池
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem basePool = 1;
     */
    basePool: AssetItem[];
    /**
     * 阶梯奖池
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.TierPrize tierPrizes = 4;
     */
    tierPrizes: TierPrize[];
    /**
     * 奖圈比例人数,取本阶梯奖池的奖圈比例, 默认值为100%
     *
     * @generated from protobuf field: uint32 prizePercentage = 5;
     */
    prizePercentage: number;
}
/**
 * 并列奖池保底人数外额外名额
 *
 * @generated from protobuf message bpt.match.common.v1.ExtraPlaces
 */
export interface ExtraPlaces {
    /**
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState; // 是否开启额外名额
    /**
     * 对应ParallelPrizeConf rewardAsset 的rawAmount 的单位，每增加一席位需要增加的奖池金额
     *
     * @generated from protobuf field: string rawAmountPerPlace = 2;
     */
    rawAmountPerPlace: string;
}
/**
 * @generated from protobuf message bpt.match.common.v1.ParallelPrizeConf
 */
export interface ParallelPrizeConf {
    /**
     * @generated from protobuf field: uint32 minAwardUserNum = 1;
     */
    minAwardUserNum: number;
    /**
     * @generated from protobuf field: bpt.common.AssetItem rewardAsset = 2;
     */
    rewardAsset?: AssetItem;
    /**
     * @generated from protobuf field: bpt.match.common.v1.ExtraPlaces extraPlace = 3;
     */
    extraPlace?: ExtraPlaces;
}
// message NftConf{
//  bpt.common.SwitchState switch = 1;
// }

/**
 * 含本数
 *
 * @generated from protobuf message bpt.match.common.v1.TierPrize
 */
export interface TierPrize {
    /**
     * 人数范围开始
     *
     * @generated from protobuf field: uint32 start = 1;
     */
    start: number;
    /**
     * 人数范围结束
     *
     * @generated from protobuf field: uint32 end = 2;
     */
    end: number;
    /**
     * 奖圈奖励
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.RankPrize rankPrizes = 3;
     */
    rankPrizes: RankPrize[];
}
/**
 * @generated from protobuf message bpt.match.common.v1.RankPrize
 */
export interface RankPrize {
    /**
     * 奖励范围，开始和结束的排名
     *
     * @generated from protobuf field: bpt.match.common.v1.SectionRange rankRange = 4;
     */
    rankRange?: SectionRange;
    /**
     * 万分数，保留百分位小数点后两位
     *
     * @generated from protobuf field: uint32 tenThousandShare = 5;
     */
    tenThousandShare: number;
    /**
     * fixPrize
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem assets = 6;
     */
    assets: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.common.v1.ConfInfoExt
 */
export interface ConfInfoExt {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number; // 配置id
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // 比赛名
    /**
     * @generated from protobuf field: string showImg = 3;
     */
    showImg: string; // 展示图片
    /**
     * @generated from protobuf field: string showMobileImg = 4;
     */
    showMobileImg: string; // 手机版本的展示图片
    /**
     * 移动端规格列表图片
     *
     * @generated from protobuf field: string mobileShowImgList = 11;
     */
    mobileShowImgList: string;
    /**
     * 移动端规格详情图片
     *
     * @generated from protobuf field: string mobileShowImgDetail = 12;
     */
    mobileShowImgDetail: string;
    /**
     * pc端规格列表图片
     *
     * @generated from protobuf field: string showImgList = 13;
     */
    showImgList: string; // 列表
    /**
     * pc端规格详情图片
     *
     * @generated from protobuf field: string showImgDetail = 14;
     */
    showImgDetail: string; // 详情
    /**
     * 默认值为9
     *
     * @generated from protobuf field: uint32 playerPerTable = 5;
     */
    playerPerTable: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.EntryConf entryConf = 6;
     */
    entryConf?: EntryConf;
    /**
     * @generated from protobuf field: bpt.match.common.v1.CashConf cashConf = 7;
     */
    cashConf?: CashConf;
    /**
     * @generated from protobuf field: bpt.match.common.v1.StageConf stageConf = 8;
     */
    stageConf?: StageConf;
    /**
     * @generated from protobuf field: int64 playerNum = 9;
     */
    playerNum: number; // 在玩用户 就是坐在桌子上面的用户（桌子上有座位的 不是围观 也是不是游离状态的）
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 10 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言，主要是name的
}
/**
 * 固定奖励
 *
 * @generated from protobuf message bpt.match.common.v1.FixedPrizeConf
 */
export interface FixedPrizeConf {
    /**
     * 排名奖励
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.RangePrize rangePrizes = 1;
     */
    rangePrizes: RangePrize[];
}
/**
 * 排名奖励
 *
 * @generated from protobuf message bpt.match.common.v1.RangePrize
 */
export interface RangePrize {
    /**
     * 开始名次，含本数
     *
     * @generated from protobuf field: uint32 startRank = 1;
     */
    startRank: number;
    /**
     * 结束名次，含本数
     *
     * @generated from protobuf field: uint32 endRank = 2;
     */
    endRank: number;
    /**
     * 奖品
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem assets = 3;
     */
    assets: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.common.v1.SpinAndGoPrizeConf
 */
export interface SpinAndGoPrizeConf {
    /**
     * @generated from protobuf field: bpt.common.AssetItem rewardAsset = 1;
     */
    rewardAsset?: AssetItem;
    /**
     * @generated from protobuf field: bpt.match.common.v1.ProbabilitySheet probabilitySheet = 2;
     */
    probabilitySheet?: ProbabilitySheet;
}
/**
 * 奖励倍数表
 *
 * @generated from protobuf message bpt.match.common.v1.ProbabilitySheet
 */
export interface ProbabilitySheet {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.ProbabilityItem items = 1;
     */
    items: ProbabilityItem[];
}
/**
 * @generated from protobuf message bpt.match.common.v1.ProbabilityItem
 */
export interface ProbabilityItem {
    /**
     * @generated from protobuf field: uint32 probability = 1;
     */
    probability: number; // 奖励概率,百万分位数，数值1 表示百万分之一
    /**
     * @generated from protobuf field: uint32 multipler = 2;
     */
    multipler: number; // 奖励倍数
}
/**
 * @generated from protobuf message bpt.match.common.v1.CashConf
 */
export interface CashConf {
    /**
     * 携带限制下限
     *
     * @generated from protobuf field: uint64 LowerLimitChips = 1 [json_name = "LowerLimitChips"];
     */
    LowerLimitChips: number;
    /**
     * 携带限制上限
     *
     * @generated from protobuf field: uint64 UpperLimitChips = 2 [json_name = "UpperLimitChips"];
     */
    UpperLimitChips: number;
    /**
     * 场次子类型
     *
     * @generated from protobuf field: bpt.match.common.v1.SubMatchType subMatchType = 3;
     */
    subMatchType: SubMatchType;
    /**
     * 兑换，货币数量（包括代币和我们自己的数据库资产金币）
     *
     * @generated from protobuf field: bpt.common.AssetItem token = 4;
     */
    token?: AssetItem;
    /**
     * 兑换，筹码数
     *
     * @generated from protobuf field: uint64 chips = 5;
     */
    chips: number;
    /**
     * 一共创建多少个桌子(创建桌子上限)
     *
     * @generated from protobuf field: uint32 tableNum = 6;
     */
    tableNum: number;
    /**
     * 最少创建多少个桌子(创建桌子下限)
     *
     * @generated from protobuf field: uint32 minTableNum = 7;
     */
    minTableNum: number;
    /**
     * 创建桌子模式
     *
     * @generated from protobuf field: bpt.match.common.v1.CreateTableMode createTableMode = 8;
     */
    createTableMode: CreateTableMode;
    /**
     * 预留空桌子数
     *
     * @generated from protobuf field: uint32 reserveTableNum = 9;
     */
    reserveTableNum: number;
    /**
     * 台费
     *
     * @generated from protobuf field: uint64 entryFee = 10;
     */
    entryFee: number;
}
/**
 * @generated from protobuf message bpt.match.common.v1.CashMatchConf
 */
export interface CashMatchConf {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * 比赛名称
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * 规则说明
     *
     * @generated from protobuf field: string rule = 3;
     */
    rule: string;
    /**
     * 每桌人数
     * 默认值为9
     *
     * @generated from protobuf field: uint32 playerPerTable = 4;
     */
    playerPerTable: number;
    /**
     * 盲注
     *
     * @generated from protobuf field: bpt.match.common.v1.BlindLevel level = 5;
     */
    level?: BlindLevel;
    /**
     * 前注类型
     *
     * @generated from protobuf field: bpt.common.AnteType anteType = 6;
     */
    anteType: AnteType;
    /**
     * 配置状态
     *
     * @generated from protobuf field: bpt.match.common.v1.State state = 7;
     */
    state: State;
    /**
     * 最后更新时间时间戳（单位：秒）
     *
     * @generated from protobuf field: int64 lastUpdate = 8;
     */
    lastUpdate: number;
    /**
     * 最后更新操作者
     *
     * @generated from protobuf field: string operator = 9;
     */
    operator: string;
    /**
     * 现金桌配置
     *
     * @generated from protobuf field: bpt.match.common.v1.CashConf cashConf = 10;
     */
    cashConf?: CashConf;
    /**
     * 同盲注级别下展示的优先级
     *
     * @generated from protobuf field: int32 showPriority = 11;
     */
    showPriority: number;
    /**
     * 所属TAG(beginner/advanced/expert)
     *
     * @generated from protobuf field: string tag = 12;
     */
    tag: string;
    /**
     * 版本配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ServiceConf serviceConf = 13;
     */
    serviceConf?: ServiceConf;
    /**
     * 创建者
     *
     * @generated from protobuf field: int64 matchOwner = 14;
     */
    matchOwner: number;
    /**
     * @generated from protobuf field: int64 id = 15;
     */
    id: number;
}
/**
 * @generated from protobuf enum bpt.match.common.v1.MatchStatus
 */
export enum MatchStatus {
    /**
     * @generated from protobuf enum value: MatchStatusUnknown = 0;
     */
    MatchStatusUnknown = 0,
    /**
     * 未开始
     *
     * @generated from protobuf enum value: MatchStatusInit = 1;
     */
    MatchStatusInit = 1,
    /**
     * 运行中
     *
     * @generated from protobuf enum value: MatchStatusRunning = 2;
     */
    MatchStatusRunning = 2,
    /**
     * 结束
     *
     * @generated from protobuf enum value: MatchStatusOver = 3;
     */
    MatchStatusOver = 3,
    /**
     * 异常结束
     *
     * @generated from protobuf enum value: MatchStatusAbort = 4;
     */
    MatchStatusAbort = 4,
    /**
     * 比赛暂停
     *
     * @generated from protobuf enum value: MatchStatusPause = 5;
     */
    MatchStatusPause = 5
}
/**
 * @generated from protobuf enum bpt.match.common.v1.MainMatchType
 */
export enum MainMatchType {
    /**
     * 常规赛，单场打完结束
     *
     * @generated from protobuf enum value: MainMatchRegular = 0;
     */
    MainMatchRegular = 0,
    /**
     * 多日赛，比赛分阶段进行，第一阶段有多场，用户带第一阶段最大筹码晋级
     *
     * @generated from protobuf enum value: MainMatchPhasedTournament = 1;
     */
    MainMatchPhasedTournament = 1
}
/**
 * @generated from protobuf enum bpt.match.common.v1.DeskStatus
 */
export enum DeskStatus {
    /**
     * @generated from protobuf enum value: DeskStatusInit = 0;
     */
    DeskStatusInit = 0,
    /**
     * @generated from protobuf enum value: DeskStatusPlaying = 1;
     */
    DeskStatusPlaying = 1,
    /**
     * @generated from protobuf enum value: DeskStatusDestroy = 2;
     */
    DeskStatusDestroy = 2,
    /**
     * 等待配桌，当结算后，桌子不能马上下一轮时，状态变更DeskStatusWaiting
     *
     * @generated from protobuf enum value: DeskStatusWaiting = 3;
     */
    DeskStatusWaiting = 3,
    /**
     * 桌子一轮结束后变为ready
     *
     * @generated from protobuf enum value: DeskStatusReady = 4;
     */
    DeskStatusReady = 4,
    /**
     * 一轮结算中
     *
     * @generated from protobuf enum value: DeskStatusRoundResult = 5;
     */
    DeskStatusRoundResult = 5,
    /**
     * 一轮结算完成
     *
     * @generated from protobuf enum value: DeskStatusRoundEnd = 6;
     */
    DeskStatusRoundEnd = 6
}
/**
 * @generated from protobuf enum bpt.match.common.v1.UserStatus
 */
export enum UserStatus {
    /**
     * @generated from protobuf enum value: UserStatusUnknown = 0;
     */
    UserStatusUnknown = 0,
    /**
     * 已加入
     *
     * @generated from protobuf enum value: UserStatusEntry = 1;
     */
    UserStatusEntry = 1,
    /**
     * 正在游戏中
     *
     * @generated from protobuf enum value: UserStatusPlaying = 2;
     */
    UserStatusPlaying = 2,
    /**
     * 淘汰/出局/结束
     *
     * @generated from protobuf enum value: UserStatusOver = 3;
     */
    UserStatusOver = 3,
    /**
     *  // 等待复活
     *  UserStatusWaitReEntry =4;  // deprecated 使用rankRange
     * 等待ReBuy
     *
     * @generated from protobuf enum value: UserStatusWaitReBuy = 5;
     */
    UserStatusWaitReBuy = 5,
    /**
     * 等待AddOn
     *
     * @generated from protobuf enum value: UserStatusWaitAddOn = 6;
     */
    UserStatusWaitAddOn = 6,
    /**
     * 用户筹码为0后，不addon,rebuy的短暂状态，下次调度将会置为over,并离开桌子
     * 该状态只会转为UserStatusOver状态
     * 该状态会等待用户播放完动画再调离
     *
     * @generated from protobuf enum value: UserStatusOnLeave = 7;
     */
    UserStatusOnLeave = 7,
    /**
     * 用户reentry或是延时入场，用户暂时不能入座等待的中间状态，
     * 该状态只会转为UserStatusPlaying
     *
     * @generated from protobuf enum value: UserStatusOnJoinPlay = 8;
     */
    UserStatusOnJoinPlay = 8,
    /**
     * 未参赛
     *
     * @generated from protobuf enum value: UserStatusNotJoined = 9;
     */
    UserStatusNotJoined = 9
}
/**
 * @generated from protobuf enum bpt.match.common.v1.ChargeEvent
 */
export enum ChargeEvent {
    /**
     * @generated from protobuf enum value: ChargeEventUnSpecified = 0;
     */
    ChargeEventUnSpecified = 0,
    /**
     * @generated from protobuf enum value: ChargeEventReBuy = 1;
     */
    ChargeEventReBuy = 1,
    /**
     * @generated from protobuf enum value: ChargeEventAddOn = 2;
     */
    ChargeEventAddOn = 2,
    /**
     * @generated from protobuf enum value: ChargeEventReEntry = 3;
     */
    ChargeEventReEntry = 3
}
/**
 * @generated from protobuf enum bpt.match.common.v1.ReviewState
 */
export enum ReviewState {
    /**
     * @generated from protobuf enum value: ReviewStateUnknown = 0;
     */
    ReviewStateUnknown = 0,
    /**
     * @generated from protobuf enum value: ReviewStateDefault = 1;
     */
    ReviewStateDefault = 1,
    /**
     * @generated from protobuf enum value: ReviewStatePassed = 2;
     */
    ReviewStatePassed = 2,
    /**
     * @generated from protobuf enum value: ReviewStateRejected = 3;
     */
    ReviewStateRejected = 3
}
/**
 * @generated from protobuf enum bpt.match.common.v1.PrizeType
 */
export enum PrizeType {
    /**
     * @generated from protobuf enum value: PrizeTypeUnknown = 0;
     */
    PrizeTypeUnknown = 0,
    /**
     * 动态奖池
     *
     * @generated from protobuf enum value: PrizeTypeDynamic = 1;
     */
    PrizeTypeDynamic = 1,
    /**
     * 固定奖池
     *
     * @generated from protobuf enum value: PrizeTypeFixed = 2;
     */
    PrizeTypeFixed = 2,
    /**
     * 并列奖池
     *
     * @generated from protobuf enum value: PrizeTypeParallel = 3;
     */
    PrizeTypeParallel = 3,
    /**
     * 动态归一化奖池
     * 找出第一个可以给所有玩家发奖的阶梯奖池，按权重（TenThousandShare）归一化
     * 对应配置同样是DynamicPrizeConf
     *
     * @generated from protobuf enum value: PrizeTypeDynamicNormalize = 4;
     */
    PrizeTypeDynamicNormalize = 4
}
/**
 * 赛事类型
 *
 * @generated from protobuf enum bpt.match.common.v1.MatchType
 */
export enum MatchType {
    /**
     * @generated from protobuf enum value: MatchTypeUnknown = 0;
     */
    MatchTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: MatchTypeSNG = 1;
     */
    MatchTypeSNG = 1,
    /**
     * @generated from protobuf enum value: MatchTypeMTT = 2;
     */
    MatchTypeMTT = 2,
    /**
     * @generated from protobuf enum value: MatchTypeSpinAndGo = 3;
     */
    MatchTypeSpinAndGo = 3,
    /**
     * @generated from protobuf enum value: MatchTypeCash = 4;
     */
    MatchTypeCash = 4
}
/**
 * @generated from protobuf enum bpt.match.common.v1.CancelReason
 */
export enum CancelReason {
    /**
     * @generated from protobuf enum value: CancelReasonUnspecified = 0;
     */
    CancelReasonUnspecified = 0,
    /**
     * 人数不足
     *
     * @generated from protobuf enum value: CancelReasonInsufficient = 1;
     */
    CancelReasonInsufficient = 1,
    /**
     * 服务错误
     *
     * @generated from protobuf enum value: CancelReasonServerError = 2;
     */
    CancelReasonServerError = 2,
    /**
     * 举办方取消
     *
     * @generated from protobuf enum value: CancelReasonCancelByCreator = 3;
     */
    CancelReasonCancelByCreator = 3
}
/**
 * 锦标赛展示类型,比赛分类
 * 和具体玩法无关的运营分类，和标签类似
 *
 * @generated from protobuf enum bpt.match.common.v1.MatchListType
 */
export enum MatchListType {
    /**
     * @generated from protobuf enum value: MatchListTypeUnknown = 0;
     */
    MatchListTypeUnknown = 0,
    /**
     * 日赛
     *
     * @generated from protobuf enum value: MatchListTypeDaily = 1;
     */
    MatchListTypeDaily = 1,
    /**
     * 周赛
     *
     * @generated from protobuf enum value: MatchListTypeWeekly = 2;
     */
    MatchListTypeWeekly = 2,
    /**
     * 月赛
     *
     * @generated from protobuf enum value: MatchListTypeMonthly = 3;
     */
    MatchListTypeMonthly = 3,
    /**
     * 测试赛（避免测试比赛覆盖日榜周榜）
     *
     * @generated from protobuf enum value: MatchListTypeTest = 4;
     */
    MatchListTypeTest = 4,
    /**
     * 娱乐赛
     *
     * @generated from protobuf enum value: MatchListTypeEntertain = 5;
     */
    MatchListTypeEntertain = 5,
    /**
     * 特别赛（豪客赛）
     *
     * @generated from protobuf enum value: MatchListTypeHighRoller = 6;
     */
    MatchListTypeHighRoller = 6
}
/**
 * @generated from protobuf enum bpt.match.common.v1.BetWay
 */
export enum BetWay {
    /**
     * 无限下注
     *
     * @generated from protobuf enum value: BetWayUnlimited = 0;
     */
    BetWayUnlimited = 0
}
/**
 * @generated from protobuf enum bpt.match.common.v1.BlindLevelTagType
 */
export enum BlindLevelTagType {
    /**
     * @generated from protobuf enum value: BlindLevelTagTypeUnknown = 0;
     */
    BlindLevelTagTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: BlindLevelTagType_RebuyConf = 1;
     */
    BlindLevelTagType_RebuyConf = 1,
    /**
     * @generated from protobuf enum value: BlindLevelTagType_AddOn = 2;
     */
    BlindLevelTagType_AddOn = 2,
    /**
     * @generated from protobuf enum value: BlindLevelTagType_Reentry = 3;
     */
    BlindLevelTagType_Reentry = 3
}
/**
 * @generated from protobuf enum bpt.match.common.v1.ObserveMode
 */
export enum ObserveMode {
    /**
     * 实时围观
     *
     * @generated from protobuf enum value: ObserveModeRealTime = 0;
     */
    ObserveModeRealTime = 0,
    /**
     * 延时围观
     *
     * @generated from protobuf enum value: ObserveModeDelay = 1;
     */
    ObserveModeDelay = 1
}
/**
 * 围观模式
 *
 * @generated from protobuf enum bpt.match.common.v1.ObserveView
 */
export enum ObserveView {
    /**
     * 单人围观 仅看一个玩家手牌
     *
     * @generated from protobuf enum value: ObserveViewSignalPlayer = 0;
     */
    ObserveViewSignalPlayer = 0,
    /**
     * 全局围观 上帝视角
     *
     * @generated from protobuf enum value: ObserveViewEveryPlayer = 1;
     */
    ObserveViewEveryPlayer = 1
}
/**
 * @generated from protobuf enum bpt.match.common.v1.RobotEnterMode
 */
export enum RobotEnterMode {
    /**
     * 固定人数
     *
     * @generated from protobuf enum value: RobotEnterModeFixedNum = 0;
     */
    RobotEnterModeFixedNum = 0,
    /**
     * 最低开赛人数/报名人数
     *
     * @generated from protobuf enum value: RobotEnterModeFillMinUserNum = 1;
     */
    RobotEnterModeFillMinUserNum = 1
}
/**
 * @generated from protobuf enum bpt.match.common.v1.GameOverType
 */
export enum GameOverType {
    /**
     * 盲注等级结束
     *
     * @generated from protobuf enum value: GameOverTypeBlindLevel = 0;
     */
    GameOverTypeBlindLevel = 0,
    /**
     * 盲注结束或者达到剩余人数
     *
     * @generated from protobuf enum value: GameOverTypeBlindLevelOrReachFinalTable = 2;
     */
    GameOverTypeBlindLevelOrReachFinalTable = 2,
    /**
     * 按照奖金类型达到结束，如动态奖池，打到1人，并列奖池达到奖池人数
     *
     * @generated from protobuf enum value: GameOverTypeMatchOver = 3;
     */
    GameOverTypeMatchOver = 3,
    /**
     * 按人数比例的奖圈
     *
     * @generated from protobuf enum value: GameOverTypePercentage = 4;
     */
    GameOverTypePercentage = 4,
    /**
     * 按奖圈人数结束(延迟结束后确认)
     *
     * @generated from protobuf enum value: GameOverTypePrizeNum = 5;
     */
    GameOverTypePrizeNum = 5
}
/**
 * 周期类型
 *
 * @generated from protobuf enum bpt.match.common.v1.SchedulerType
 */
export enum SchedulerType {
    /**
     * @generated from protobuf enum value: SchedulerTypeUnknown = 0;
     */
    SchedulerTypeUnknown = 0,
    /**
     * 一次
     *
     * @generated from protobuf enum value: SchedulerTypeOnce = 1;
     */
    SchedulerTypeOnce = 1,
    /**
     * 每日一次
     *
     * @generated from protobuf enum value: SchedulerTypeDailyOnce = 2;
     */
    SchedulerTypeDailyOnce = 2,
    /**
     * 每日循环
     *
     * @generated from protobuf enum value: SchedulerTypeCycle = 3;
     */
    SchedulerTypeCycle = 3,
    /**
     * 期末循环
     *
     * @generated from protobuf enum value: SchedulerTypePeriodEnd = 4;
     */
    SchedulerTypePeriodEnd = 4,
    /**
     * 多天赛事(phased tournament/Flight tournaments)
     *
     * 对应多天赛的配置是 PhasedConfig
     *
     * @generated from protobuf enum value: SchedulerTypePhased = 5;
     */
    SchedulerTypePhased = 5
}
/**
 * @generated from protobuf enum bpt.match.common.v1.PeriodType
 */
export enum PeriodType {
    /**
     * @generated from protobuf enum value: PeriodTypeUnknown = 0;
     */
    PeriodTypeUnknown = 0,
    /**
     * 周末
     *
     * @generated from protobuf enum value: PeriodTypeWeek = 1;
     */
    PeriodTypeWeek = 1,
    /**
     * 月末
     *
     * @generated from protobuf enum value: PeriodTypeMonth = 2;
     */
    PeriodTypeMonth = 2,
    /**
     * 季末
     *
     * @generated from protobuf enum value: PeriodTypeQuarter = 3;
     */
    PeriodTypeQuarter = 3
}
/**
 * @generated from protobuf enum bpt.match.common.v1.RegisterWay
 */
export enum RegisterWay {
    /**
     * ERC20报名
     *
     * @generated from protobuf enum value: RegisterWayToken = 0;
     */
    RegisterWayToken = 0,
    /**
     * 门票报名
     *
     * @generated from protobuf enum value: RegisterWayTicket = 1;
     */
    RegisterWayTicket = 1,
    /**
     * 晋级
     *
     * @generated from protobuf enum value: RegisterWayAdvance = 2;
     */
    RegisterWayAdvance = 2,
    /**
     * @generated from protobuf enum value: RegisterWayUnspecified = 3;
     */
    RegisterWayUnspecified = 3,
    /**
     * 身份特权
     *
     * @generated from protobuf enum value: RegisterWayIdentityRight = 4;
     */
    RegisterWayIdentityRight = 4
}
/**
 * @generated from protobuf enum bpt.match.common.v1.EntryNodeType
 */
export enum EntryNodeType {
    /**
     * @generated from protobuf enum value: EntryNodeTypeFree = 0;
     */
    EntryNodeTypeFree = 0,
    /**
     * @generated from protobuf enum value: EntryNodeTypeAsset = 1;
     */
    EntryNodeTypeAsset = 1,
    /**
     * @generated from protobuf enum value: EntryNodeTypeVip = 2;
     */
    EntryNodeTypeVip = 2,
    /**
     * @generated from protobuf enum value: EntryNodeTypeAnd = 100;
     */
    EntryNodeTypeAnd = 100,
    /**
     * @generated from protobuf enum value: EntryNodeTypeOr = 101;
     */
    EntryNodeTypeOr = 101,
    /**
     * @generated from protobuf enum value: EntryNodeTypeSelect = 102;
     */
    EntryNodeTypeSelect = 102
}
/**
 * 配置状态
 *
 * @generated from protobuf enum bpt.match.common.v1.State
 */
export enum State {
    /**
     * @generated from protobuf enum value: StateUnknown = 0;
     */
    StateUnknown = 0,
    /**
     * @generated from protobuf enum value: StateInit = 1;
     */
    StateInit = 1,
    /**
     * @generated from protobuf enum value: StateRunning = 2;
     */
    StateRunning = 2,
    /**
     * 中场休息
     *
     * @generated from protobuf enum value: StatePause = 3;
     */
    StatePause = 3,
    /**
     * @generated from protobuf enum value: StateStop = 4;
     */
    StateStop = 4,
    /**
     * 被赛事创建者暂停
     *
     * @generated from protobuf enum value: StatePending = 5;
     */
    StatePending = 5,
    /**
     * 等待上链回调时的赛事创建中
     *
     * @generated from protobuf enum value: StateCreating = 6;
     */
    StateCreating = 6
}
/**
 * 创建桌子模式
 *
 * @generated from protobuf enum bpt.match.common.v1.CreateTableMode
 */
export enum CreateTableMode {
    /**
     * @generated from protobuf enum value: CreateTableModeUnknown = 0;
     */
    CreateTableModeUnknown = 0,
    /**
     * 固定桌子数, 直接创建tableNum个桌子
     *
     * @generated from protobuf enum value: CreateTableModeFix = 1;
     */
    CreateTableModeFix = 1,
    /**
     * 动态桌子数, 创建minTableNum个桌子, 当桌子数不足时, 动态创建桌子, 直到tableNum个桌子
     *
     * @generated from protobuf enum value: CreateTableModeDynamic = 2;
     */
    CreateTableModeDynamic = 2
}
/**
 * @generated from protobuf enum bpt.match.common.v1.SubMatchType
 */
export enum SubMatchType {
    /**
     * @generated from protobuf enum value: SubMatchTypeUnknown = 0;
     */
    SubMatchTypeUnknown = 0,
    /**
     * 新手场
     *
     * @generated from protobuf enum value: SubMatchTypeRookie = 1;
     */
    SubMatchTypeRookie = 1,
    /**
     * 初级场
     *
     * @generated from protobuf enum value: SubMatchTypePrimary = 2;
     */
    SubMatchTypePrimary = 2,
    /**
     * 中级场
     *
     * @generated from protobuf enum value: SubMatchTypeIntermediate = 3;
     */
    SubMatchTypeIntermediate = 3,
    /**
     * 高级场
     *
     * @generated from protobuf enum value: SubMatchTypeSenior = 4;
     */
    SubMatchTypeSenior = 4,
    /**
     * 大师场
     *
     * @generated from protobuf enum value: SubMatchTypeMaster = 5;
     */
    SubMatchTypeMaster = 5,
    /**
     * 专家场
     *
     * @generated from protobuf enum value: SubMatchTypeExpert = 6;
     */
    SubMatchTypeExpert = 6,
    /**
     * 巅峰场
     *
     * @generated from protobuf enum value: SubMatchTypePeak = 7;
     */
    SubMatchTypePeak = 7,
    /**
     * 王者场
     *
     * @generated from protobuf enum value: SubMatchTypeKing = 8;
     */
    SubMatchTypeKing = 8,
    /**
     * 追加场次1
     *
     * @generated from protobuf enum value: SubMatchTypeBackupOne = 9;
     */
    SubMatchTypeBackupOne = 9,
    /**
     * 追加场次2
     *
     * @generated from protobuf enum value: SubMatchTypeBackupTwo = 10;
     */
    SubMatchTypeBackupTwo = 10,
    /**
     * 追加场次3
     *
     * @generated from protobuf enum value: SubMatchTypeBackupThree = 11;
     */
    SubMatchTypeBackupThree = 11,
    /**
     * 追加场次4
     *
     * @generated from protobuf enum value: SubMatchTypeBackupFour = 12;
     */
    SubMatchTypeBackupFour = 12,
    /**
     * 追加场次5
     *
     * @generated from protobuf enum value: SubMatchTypeBackupFive = 13;
     */
    SubMatchTypeBackupFive = 13,
    /**
     * 追加场次6
     *
     * @generated from protobuf enum value: SubMatchTypeBackupSix = 14;
     */
    SubMatchTypeBackupSix = 14,
    /**
     * 追加场次7
     *
     * @generated from protobuf enum value: SubMatchTypeBackupSeven = 15;
     */
    SubMatchTypeBackupSeven = 15,
    /**
     * 追加场次8
     *
     * @generated from protobuf enum value: SubMatchTypeBackupEight = 16;
     */
    SubMatchTypeBackupEight = 16
}
/**
 * @generated from protobuf enum bpt.match.common.v1.MatchAbiType
 */
export enum MatchAbiType {
    /**
     * @generated from protobuf enum value: MatchAbiTypeUnknown = 0;
     */
    MatchAbiTypeUnknown = 0,
    /**
     * 单天赛事
     *
     * @generated from protobuf enum value: MatchAbiTypeSingleDay = 1;
     */
    MatchAbiTypeSingleDay = 1,
    /**
     * 多天赛事
     *
     * @generated from protobuf enum value: MatchAbiTypeMultiDay = 2;
     */
    MatchAbiTypeMultiDay = 2,
    /**
     * MerkleTree
     *
     * @generated from protobuf enum value: MatchAbiTypeMerkleTree = 3;
     */
    MatchAbiTypeMerkleTree = 3,
    /**
     * 门票赛
     *
     * @generated from protobuf enum value: MatchAbiTypeTicket = 4;
     */
    MatchAbiTypeTicket = 4,
    /**
     * 多奖励单天
     *
     * @generated from protobuf enum value: MatchAbiTypeMultiPrize = 5;
     */
    MatchAbiTypeMultiPrize = 5,
    /**
     * 批量退款
     *
     * @generated from protobuf enum value: MatchAbiTypeBatchRefund = 6;
     */
    MatchAbiTypeBatchRefund = 6,
    /**
     * 多聚合多天
     *
     * @generated from protobuf enum value: MatchAbiTypePolymeric = 7;
     */
    MatchAbiTypePolymeric = 7,
    /**
     * 模块化
     *
     * @generated from protobuf enum value: MatchAbiTypeUnits = 8;
     */
    MatchAbiTypeUnits = 8
}
/**
 * @generated from protobuf enum bpt.match.common.v1.EventAbiType
 */
export enum EventAbiType {
    /**
     * @generated from protobuf enum value: EventAbiTypeUnknown = 0;
     */
    EventAbiTypeUnknown = 0,
    /**
     * 单一
     *
     * @generated from protobuf enum value: EventAbiTypeSingle = 1;
     */
    EventAbiTypeSingle = 1,
    /**
     * 组合
     *
     * @generated from protobuf enum value: EventAbiTypeCombination = 2;
     */
    EventAbiTypeCombination = 2,
    /**
     * 多奖励
     *
     * @generated from protobuf enum value: EventAbiTypeMultiPrize = 3;
     */
    EventAbiTypeMultiPrize = 3,
    /**
     * 批量退款
     *
     * @generated from protobuf enum value: EventAbiTypeBatchRefund = 4;
     */
    EventAbiTypeBatchRefund = 4,
    /**
     * 多聚合
     *
     * @generated from protobuf enum value: EventAbiTypePolymeric = 5;
     */
    EventAbiTypePolymeric = 5,
    /**
     * 模块化
     *
     * @generated from protobuf enum value: EventAbiTypeUnits = 6;
     */
    EventAbiTypeUnits = 6
}
/**
 * @generated from protobuf enum bpt.match.common.v1.StageType
 */
export enum StageType {
    /**
     * 淘汰赛，出局即结束
     *
     * @generated from protobuf enum value: StageTypeKnockOut = 0;
     */
    StageTypeKnockOut = 0,
    /**
     * 小组赛 排名靠前的晋级
     *
     * @generated from protobuf enum value: StageTypeGroup = 1;
     */
    StageTypeGroup = 1
}
/**
 * @generated from protobuf enum bpt.match.common.v1.StageResult
 */
export enum StageResult {
    /**
     * 比赛结果未决
     *
     * @generated from protobuf enum value: StageResultPending = 0;
     */
    StageResultPending = 0,
    /**
     * 比赛晋级
     *
     * @generated from protobuf enum value: StageResultAdvance = 1;
     */
    StageResultAdvance = 1,
    /**
     * 比赛淘汰
     *
     * @generated from protobuf enum value: StageResultKnockOut = 2;
     */
    StageResultKnockOut = 2,
    /**
     * 初始状态
     *
     * @generated from protobuf enum value: StageResultInit = 3;
     */
    StageResultInit = 3
}
/**
 * @generated from protobuf enum bpt.match.common.v1.JoinStatus
 */
export enum JoinStatus {
    /**
     * @generated from protobuf enum value: JoinStatusUnknown = 0;
     */
    JoinStatusUnknown = 0,
    /**
     * 已参赛
     *
     * @generated from protobuf enum value: JoinStatusJoined = 1;
     */
    JoinStatusJoined = 1,
    /**
     * 未参赛
     *
     * @generated from protobuf enum value: JoinStatusNotJoined = 2;
     */
    JoinStatusNotJoined = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class Match$Type extends MessageType<Match> {
    constructor() {
        super("bpt.match.common.v1.Match", [
            { no: 1, name: "matchID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 4, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 5, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "confLastUpdate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "chatGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "isBubbleTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "isDelayRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 42, name: "waitDelayRegisterDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 15, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "finalTableID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "multipler", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 18, name: "isBreakTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "conf", kind: "message", T: () => MatchConf },
            { no: 21, name: "userCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 22, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 23, name: "myRank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 24, name: "avgChip", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 50, name: "smallestStack", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 51, name: "largestStack", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 25, name: "playingNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 27, name: "isPrizeRound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 28, name: "isAbleEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 29, name: "myTable", kind: "message", T: () => MyTable },
            { no: 31, name: "breakTimeStopAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 32, name: "rollBackBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 39, name: "mainMatchInfo", kind: "message", T: () => MainMatchInfo },
            { no: 40, name: "startBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 41, name: "registrationAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 43, name: "lastPhasedMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "pauseEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 45, name: "pauseBeginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 46, name: "elapsedPauseSeconds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 47, name: "seatMatchTargetMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Match>): Match {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchID = 0;
        message.state = 0;
        message.users = [];
        message.confID = 0;
        message.isRegister = false;
        message.confLastUpdate = 0;
        message.chatGroupId = "";
        message.startedAt = 0;
        message.endAt = 0;
        message.blindLevel = 0;
        message.isBubbleTime = false;
        message.isDelayRegister = false;
        message.registerDeadline = 0;
        message.waitDelayRegisterDeadline = 0;
        message.prizePool = [];
        message.matchKey = "";
        message.finalTableID = 0;
        message.multipler = 0;
        message.isBreakTime = false;
        message.userCnt = 0;
        message.srvID = 0;
        message.myRank = 0;
        message.avgChip = 0;
        message.smallestStack = 0;
        message.largestStack = 0;
        message.playingNum = 0;
        message.isPrizeRound = false;
        message.isAbleEnter = false;
        message.breakTimeStopAt = 0;
        message.rollBackBlindLevel = 0;
        message.startBlindLevel = 0;
        message.registrationAt = 0;
        message.lastPhasedMatchKey = "";
        message.pauseEndAt = 0;
        message.pauseBeginAt = 0;
        message.elapsedPauseSeconds = 0;
        message.seatMatchTargetMatchKey = "";
        if (value !== undefined)
            reflectionMergePartial<Match>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Match): Match {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchID */ 1:
                    message.matchID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchStatus state */ 3:
                    message.state = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.User users */ 4:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 confID */ 5:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* bool isRegister */ 6:
                    message.isRegister = reader.bool();
                    break;
                case /* int64 confLastUpdate */ 7:
                    message.confLastUpdate = reader.int64().toNumber();
                    break;
                case /* string chatGroupId */ 8:
                    message.chatGroupId = reader.string();
                    break;
                case /* int64 startedAt */ 9:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 10:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* uint32 blindLevel */ 11:
                    message.blindLevel = reader.uint32();
                    break;
                case /* bool isBubbleTime */ 12:
                    message.isBubbleTime = reader.bool();
                    break;
                case /* bool isDelayRegister */ 13:
                    message.isDelayRegister = reader.bool();
                    break;
                case /* int64 registerDeadline */ 26:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* int64 waitDelayRegisterDeadline */ 42:
                    message.waitDelayRegisterDeadline = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 14:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 15:
                    message.matchKey = reader.string();
                    break;
                case /* int64 finalTableID */ 16:
                    message.finalTableID = reader.int64().toNumber();
                    break;
                case /* uint32 multipler */ 17:
                    message.multipler = reader.uint32();
                    break;
                case /* bool isBreakTime */ 18:
                    message.isBreakTime = reader.bool();
                    break;
                case /* bpt.match.common.v1.MatchConf conf */ 19:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                case /* int64 userCnt */ 21:
                    message.userCnt = reader.int64().toNumber();
                    break;
                case /* int64 srvID */ 22:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* uint32 myRank */ 23:
                    message.myRank = reader.uint32();
                    break;
                case /* uint32 avgChip */ 24:
                    message.avgChip = reader.uint32();
                    break;
                case /* uint32 smallestStack */ 50:
                    message.smallestStack = reader.uint32();
                    break;
                case /* uint32 largestStack */ 51:
                    message.largestStack = reader.uint32();
                    break;
                case /* uint32 playingNum */ 25:
                    message.playingNum = reader.uint32();
                    break;
                case /* bool isPrizeRound */ 27:
                    message.isPrizeRound = reader.bool();
                    break;
                case /* bool isAbleEnter */ 28:
                    message.isAbleEnter = reader.bool();
                    break;
                case /* bpt.match.common.v1.MyTable myTable */ 29:
                    message.myTable = MyTable.internalBinaryRead(reader, reader.uint32(), options, message.myTable);
                    break;
                case /* int64 breakTimeStopAt */ 31:
                    message.breakTimeStopAt = reader.int64().toNumber();
                    break;
                case /* uint32 rollBackBlindLevel */ 32:
                    message.rollBackBlindLevel = reader.uint32();
                    break;
                case /* bpt.match.common.v1.MainMatchInfo mainMatchInfo */ 39:
                    message.mainMatchInfo = MainMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.mainMatchInfo);
                    break;
                case /* uint32 startBlindLevel */ 40:
                    message.startBlindLevel = reader.uint32();
                    break;
                case /* int64 registrationAt */ 41:
                    message.registrationAt = reader.int64().toNumber();
                    break;
                case /* string lastPhasedMatchKey */ 43:
                    message.lastPhasedMatchKey = reader.string();
                    break;
                case /* int64 pauseEndAt */ 44:
                    message.pauseEndAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseBeginAt */ 45:
                    message.pauseBeginAt = reader.int64().toNumber();
                    break;
                case /* int32 elapsedPauseSeconds */ 46:
                    message.elapsedPauseSeconds = reader.int32();
                    break;
                case /* string seatMatchTargetMatchKey */ 47:
                    message.seatMatchTargetMatchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Match, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchID = 1; */
        if (message.matchID !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchID);
        /* bpt.match.common.v1.MatchStatus state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        /* repeated bpt.match.common.v1.User users = 4; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int64 confID = 5; */
        if (message.confID !== 0)
            writer.tag(5, WireType.Varint).int64(message.confID);
        /* bool isRegister = 6; */
        if (message.isRegister !== false)
            writer.tag(6, WireType.Varint).bool(message.isRegister);
        /* int64 confLastUpdate = 7; */
        if (message.confLastUpdate !== 0)
            writer.tag(7, WireType.Varint).int64(message.confLastUpdate);
        /* string chatGroupId = 8; */
        if (message.chatGroupId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.chatGroupId);
        /* int64 startedAt = 9; */
        if (message.startedAt !== 0)
            writer.tag(9, WireType.Varint).int64(message.startedAt);
        /* int64 endAt = 10; */
        if (message.endAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.endAt);
        /* uint32 blindLevel = 11; */
        if (message.blindLevel !== 0)
            writer.tag(11, WireType.Varint).uint32(message.blindLevel);
        /* bool isBubbleTime = 12; */
        if (message.isBubbleTime !== false)
            writer.tag(12, WireType.Varint).bool(message.isBubbleTime);
        /* bool isDelayRegister = 13; */
        if (message.isDelayRegister !== false)
            writer.tag(13, WireType.Varint).bool(message.isDelayRegister);
        /* int64 registerDeadline = 26; */
        if (message.registerDeadline !== 0)
            writer.tag(26, WireType.Varint).int64(message.registerDeadline);
        /* int64 waitDelayRegisterDeadline = 42; */
        if (message.waitDelayRegisterDeadline !== 0)
            writer.tag(42, WireType.Varint).int64(message.waitDelayRegisterDeadline);
        /* repeated bpt.common.AssetItem prizePool = 14; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 15; */
        if (message.matchKey !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.matchKey);
        /* int64 finalTableID = 16; */
        if (message.finalTableID !== 0)
            writer.tag(16, WireType.Varint).int64(message.finalTableID);
        /* uint32 multipler = 17; */
        if (message.multipler !== 0)
            writer.tag(17, WireType.Varint).uint32(message.multipler);
        /* bool isBreakTime = 18; */
        if (message.isBreakTime !== false)
            writer.tag(18, WireType.Varint).bool(message.isBreakTime);
        /* bpt.match.common.v1.MatchConf conf = 19; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(19, WireType.LengthDelimited).fork(), options).join();
        /* int64 userCnt = 21; */
        if (message.userCnt !== 0)
            writer.tag(21, WireType.Varint).int64(message.userCnt);
        /* int64 srvID = 22; */
        if (message.srvID !== 0)
            writer.tag(22, WireType.Varint).int64(message.srvID);
        /* uint32 myRank = 23; */
        if (message.myRank !== 0)
            writer.tag(23, WireType.Varint).uint32(message.myRank);
        /* uint32 avgChip = 24; */
        if (message.avgChip !== 0)
            writer.tag(24, WireType.Varint).uint32(message.avgChip);
        /* uint32 smallestStack = 50; */
        if (message.smallestStack !== 0)
            writer.tag(50, WireType.Varint).uint32(message.smallestStack);
        /* uint32 largestStack = 51; */
        if (message.largestStack !== 0)
            writer.tag(51, WireType.Varint).uint32(message.largestStack);
        /* uint32 playingNum = 25; */
        if (message.playingNum !== 0)
            writer.tag(25, WireType.Varint).uint32(message.playingNum);
        /* bool isPrizeRound = 27; */
        if (message.isPrizeRound !== false)
            writer.tag(27, WireType.Varint).bool(message.isPrizeRound);
        /* bool isAbleEnter = 28; */
        if (message.isAbleEnter !== false)
            writer.tag(28, WireType.Varint).bool(message.isAbleEnter);
        /* bpt.match.common.v1.MyTable myTable = 29; */
        if (message.myTable)
            MyTable.internalBinaryWrite(message.myTable, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* int64 breakTimeStopAt = 31; */
        if (message.breakTimeStopAt !== 0)
            writer.tag(31, WireType.Varint).int64(message.breakTimeStopAt);
        /* uint32 rollBackBlindLevel = 32; */
        if (message.rollBackBlindLevel !== 0)
            writer.tag(32, WireType.Varint).uint32(message.rollBackBlindLevel);
        /* bpt.match.common.v1.MainMatchInfo mainMatchInfo = 39; */
        if (message.mainMatchInfo)
            MainMatchInfo.internalBinaryWrite(message.mainMatchInfo, writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* uint32 startBlindLevel = 40; */
        if (message.startBlindLevel !== 0)
            writer.tag(40, WireType.Varint).uint32(message.startBlindLevel);
        /* int64 registrationAt = 41; */
        if (message.registrationAt !== 0)
            writer.tag(41, WireType.Varint).int64(message.registrationAt);
        /* string lastPhasedMatchKey = 43; */
        if (message.lastPhasedMatchKey !== "")
            writer.tag(43, WireType.LengthDelimited).string(message.lastPhasedMatchKey);
        /* int64 pauseEndAt = 44; */
        if (message.pauseEndAt !== 0)
            writer.tag(44, WireType.Varint).int64(message.pauseEndAt);
        /* int64 pauseBeginAt = 45; */
        if (message.pauseBeginAt !== 0)
            writer.tag(45, WireType.Varint).int64(message.pauseBeginAt);
        /* int32 elapsedPauseSeconds = 46; */
        if (message.elapsedPauseSeconds !== 0)
            writer.tag(46, WireType.Varint).int32(message.elapsedPauseSeconds);
        /* string seatMatchTargetMatchKey = 47; */
        if (message.seatMatchTargetMatchKey !== "")
            writer.tag(47, WireType.LengthDelimited).string(message.seatMatchTargetMatchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.Match
 */
export const Match = new Match$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchFeatureConfig$Type extends MessageType<MatchFeatureConfig> {
    constructor() {
        super("bpt.match.common.v1.MatchFeatureConfig", [
            { no: 1, name: "enableHomeChampionDisplay", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "enableHomeMatchReportDisplay", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 3, name: "enableAchievementUnlock", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "enablePrivilegeUse", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "enableHomeListDisplay", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 6, name: "enableClubStatistics", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 7, name: "keepDurationInSeconds", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MatchFeatureConfig>): MatchFeatureConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.enableHomeChampionDisplay = 0;
        message.enableHomeMatchReportDisplay = 0;
        message.enableAchievementUnlock = 0;
        message.enablePrivilegeUse = 0;
        message.enableHomeListDisplay = 0;
        message.enableClubStatistics = 0;
        message.keepDurationInSeconds = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchFeatureConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchFeatureConfig): MatchFeatureConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState enableHomeChampionDisplay */ 1:
                    message.enableHomeChampionDisplay = reader.int32();
                    break;
                case /* bpt.common.SwitchState enableHomeMatchReportDisplay */ 2:
                    message.enableHomeMatchReportDisplay = reader.int32();
                    break;
                case /* bpt.common.SwitchState enableAchievementUnlock */ 3:
                    message.enableAchievementUnlock = reader.int32();
                    break;
                case /* bpt.common.SwitchState enablePrivilegeUse */ 4:
                    message.enablePrivilegeUse = reader.int32();
                    break;
                case /* bpt.common.SwitchState enableHomeListDisplay */ 5:
                    message.enableHomeListDisplay = reader.int32();
                    break;
                case /* bpt.common.SwitchState enableClubStatistics */ 6:
                    message.enableClubStatistics = reader.int32();
                    break;
                case /* int64 keepDurationInSeconds */ 7:
                    message.keepDurationInSeconds = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchFeatureConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState enableHomeChampionDisplay = 1; */
        if (message.enableHomeChampionDisplay !== 0)
            writer.tag(1, WireType.Varint).int32(message.enableHomeChampionDisplay);
        /* bpt.common.SwitchState enableHomeMatchReportDisplay = 2; */
        if (message.enableHomeMatchReportDisplay !== 0)
            writer.tag(2, WireType.Varint).int32(message.enableHomeMatchReportDisplay);
        /* bpt.common.SwitchState enableAchievementUnlock = 3; */
        if (message.enableAchievementUnlock !== 0)
            writer.tag(3, WireType.Varint).int32(message.enableAchievementUnlock);
        /* bpt.common.SwitchState enablePrivilegeUse = 4; */
        if (message.enablePrivilegeUse !== 0)
            writer.tag(4, WireType.Varint).int32(message.enablePrivilegeUse);
        /* bpt.common.SwitchState enableHomeListDisplay = 5; */
        if (message.enableHomeListDisplay !== 0)
            writer.tag(5, WireType.Varint).int32(message.enableHomeListDisplay);
        /* bpt.common.SwitchState enableClubStatistics = 6; */
        if (message.enableClubStatistics !== 0)
            writer.tag(6, WireType.Varint).int32(message.enableClubStatistics);
        /* int64 keepDurationInSeconds = 7; */
        if (message.keepDurationInSeconds !== 0)
            writer.tag(7, WireType.Varint).int64(message.keepDurationInSeconds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MatchFeatureConfig
 */
export const MatchFeatureConfig = new MatchFeatureConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchFront$Type extends MessageType<MatchFront> {
    constructor() {
        super("bpt.match.common.v1.MatchFront", [
            { no: 1, name: "matchID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 4, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 5, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "confLastUpdate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "chatGroupId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "isBubbleTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "isDelayRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "propIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "finalTableID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "multipler", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 18, name: "isBreakTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "userCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 22, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 23, name: "myRank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 24, name: "avgChip", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 25, name: "playingNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 26, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 27, name: "isPrizeRound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 28, name: "isAbleEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 29, name: "myTable", kind: "message", T: () => MyTable },
            { no: 31, name: "breakTimeStopAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 32, name: "rollBackBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 39, name: "mainMatchInfo", kind: "message", T: () => MainMatchInfo },
            { no: 40, name: "startBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 41, name: "registrationAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 43, name: "lastPhasedMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "waitDelayRegisterDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 45, name: "joinedCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 46, name: "isJoin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 47, name: "pauseEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 48, name: "pauseBeginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 49, name: "elapsedPauseSeconds", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 50, name: "smallestStack", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 51, name: "largestStack", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 52, name: "clubID", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 53, name: "cancelReason", kind: "enum", T: () => ["bpt.match.common.v1.CancelReason", CancelReason] },
            { no: 54, name: "seatMatchTargetMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchFront>): MatchFront {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchID = 0;
        message.state = 0;
        message.users = [];
        message.confID = 0;
        message.isRegister = false;
        message.confLastUpdate = 0;
        message.chatGroupId = "";
        message.startedAt = 0;
        message.endAt = 0;
        message.blindLevel = 0;
        message.isBubbleTime = false;
        message.isDelayRegister = false;
        message.propIds = [];
        message.matchKey = "";
        message.finalTableID = 0;
        message.multipler = 0;
        message.isBreakTime = false;
        message.userCnt = 0;
        message.srvID = 0;
        message.myRank = 0;
        message.avgChip = 0;
        message.playingNum = 0;
        message.registerDeadline = 0;
        message.isPrizeRound = false;
        message.isAbleEnter = false;
        message.breakTimeStopAt = 0;
        message.rollBackBlindLevel = 0;
        message.startBlindLevel = 0;
        message.registrationAt = 0;
        message.lastPhasedMatchKey = "";
        message.waitDelayRegisterDeadline = 0;
        message.joinedCnt = 0;
        message.isJoin = false;
        message.pauseEndAt = 0;
        message.pauseBeginAt = 0;
        message.elapsedPauseSeconds = 0;
        message.smallestStack = 0;
        message.largestStack = 0;
        message.clubID = 0;
        message.cancelReason = 0;
        message.seatMatchTargetMatchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MatchFront>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchFront): MatchFront {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchID */ 1:
                    message.matchID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchStatus state */ 3:
                    message.state = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.User users */ 4:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 confID */ 5:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* bool isRegister */ 6:
                    message.isRegister = reader.bool();
                    break;
                case /* int64 confLastUpdate */ 7:
                    message.confLastUpdate = reader.int64().toNumber();
                    break;
                case /* string chatGroupId */ 8:
                    message.chatGroupId = reader.string();
                    break;
                case /* int64 startedAt */ 9:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 10:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* uint32 blindLevel */ 11:
                    message.blindLevel = reader.uint32();
                    break;
                case /* bool isBubbleTime */ 12:
                    message.isBubbleTime = reader.bool();
                    break;
                case /* bool isDelayRegister */ 13:
                    message.isDelayRegister = reader.bool();
                    break;
                case /* repeated int64 propIds */ 14:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propIds.push(reader.int64().toNumber());
                    else
                        message.propIds.push(reader.int64().toNumber());
                    break;
                case /* string matchKey */ 15:
                    message.matchKey = reader.string();
                    break;
                case /* int64 finalTableID */ 16:
                    message.finalTableID = reader.int64().toNumber();
                    break;
                case /* uint32 multipler */ 17:
                    message.multipler = reader.uint32();
                    break;
                case /* bool isBreakTime */ 18:
                    message.isBreakTime = reader.bool();
                    break;
                case /* int64 userCnt */ 21:
                    message.userCnt = reader.int64().toNumber();
                    break;
                case /* int64 srvID */ 22:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* uint32 myRank */ 23:
                    message.myRank = reader.uint32();
                    break;
                case /* uint32 avgChip */ 24:
                    message.avgChip = reader.uint32();
                    break;
                case /* uint32 playingNum */ 25:
                    message.playingNum = reader.uint32();
                    break;
                case /* int64 registerDeadline */ 26:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* bool isPrizeRound */ 27:
                    message.isPrizeRound = reader.bool();
                    break;
                case /* bool isAbleEnter */ 28:
                    message.isAbleEnter = reader.bool();
                    break;
                case /* bpt.match.common.v1.MyTable myTable */ 29:
                    message.myTable = MyTable.internalBinaryRead(reader, reader.uint32(), options, message.myTable);
                    break;
                case /* int64 breakTimeStopAt */ 31:
                    message.breakTimeStopAt = reader.int64().toNumber();
                    break;
                case /* uint32 rollBackBlindLevel */ 32:
                    message.rollBackBlindLevel = reader.uint32();
                    break;
                case /* bpt.match.common.v1.MainMatchInfo mainMatchInfo */ 39:
                    message.mainMatchInfo = MainMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.mainMatchInfo);
                    break;
                case /* uint32 startBlindLevel */ 40:
                    message.startBlindLevel = reader.uint32();
                    break;
                case /* int64 registrationAt */ 41:
                    message.registrationAt = reader.int64().toNumber();
                    break;
                case /* string lastPhasedMatchKey */ 43:
                    message.lastPhasedMatchKey = reader.string();
                    break;
                case /* int64 waitDelayRegisterDeadline */ 44:
                    message.waitDelayRegisterDeadline = reader.int64().toNumber();
                    break;
                case /* int64 joinedCnt */ 45:
                    message.joinedCnt = reader.int64().toNumber();
                    break;
                case /* bool isJoin */ 46:
                    message.isJoin = reader.bool();
                    break;
                case /* int64 pauseEndAt */ 47:
                    message.pauseEndAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseBeginAt */ 48:
                    message.pauseBeginAt = reader.int64().toNumber();
                    break;
                case /* int32 elapsedPauseSeconds */ 49:
                    message.elapsedPauseSeconds = reader.int32();
                    break;
                case /* uint32 smallestStack */ 50:
                    message.smallestStack = reader.uint32();
                    break;
                case /* uint32 largestStack */ 51:
                    message.largestStack = reader.uint32();
                    break;
                case /* uint32 clubID */ 52:
                    message.clubID = reader.uint32();
                    break;
                case /* bpt.match.common.v1.CancelReason cancelReason */ 53:
                    message.cancelReason = reader.int32();
                    break;
                case /* string seatMatchTargetMatchKey */ 54:
                    message.seatMatchTargetMatchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchFront, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchID = 1; */
        if (message.matchID !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchID);
        /* bpt.match.common.v1.MatchStatus state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        /* repeated bpt.match.common.v1.User users = 4; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int64 confID = 5; */
        if (message.confID !== 0)
            writer.tag(5, WireType.Varint).int64(message.confID);
        /* bool isRegister = 6; */
        if (message.isRegister !== false)
            writer.tag(6, WireType.Varint).bool(message.isRegister);
        /* int64 confLastUpdate = 7; */
        if (message.confLastUpdate !== 0)
            writer.tag(7, WireType.Varint).int64(message.confLastUpdate);
        /* string chatGroupId = 8; */
        if (message.chatGroupId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.chatGroupId);
        /* int64 startedAt = 9; */
        if (message.startedAt !== 0)
            writer.tag(9, WireType.Varint).int64(message.startedAt);
        /* int64 endAt = 10; */
        if (message.endAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.endAt);
        /* uint32 blindLevel = 11; */
        if (message.blindLevel !== 0)
            writer.tag(11, WireType.Varint).uint32(message.blindLevel);
        /* bool isBubbleTime = 12; */
        if (message.isBubbleTime !== false)
            writer.tag(12, WireType.Varint).bool(message.isBubbleTime);
        /* bool isDelayRegister = 13; */
        if (message.isDelayRegister !== false)
            writer.tag(13, WireType.Varint).bool(message.isDelayRegister);
        /* repeated int64 propIds = 14; */
        if (message.propIds.length) {
            writer.tag(14, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propIds.length; i++)
                writer.int64(message.propIds[i]);
            writer.join();
        }
        /* string matchKey = 15; */
        if (message.matchKey !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.matchKey);
        /* int64 finalTableID = 16; */
        if (message.finalTableID !== 0)
            writer.tag(16, WireType.Varint).int64(message.finalTableID);
        /* uint32 multipler = 17; */
        if (message.multipler !== 0)
            writer.tag(17, WireType.Varint).uint32(message.multipler);
        /* bool isBreakTime = 18; */
        if (message.isBreakTime !== false)
            writer.tag(18, WireType.Varint).bool(message.isBreakTime);
        /* int64 userCnt = 21; */
        if (message.userCnt !== 0)
            writer.tag(21, WireType.Varint).int64(message.userCnt);
        /* int64 srvID = 22; */
        if (message.srvID !== 0)
            writer.tag(22, WireType.Varint).int64(message.srvID);
        /* uint32 myRank = 23; */
        if (message.myRank !== 0)
            writer.tag(23, WireType.Varint).uint32(message.myRank);
        /* uint32 avgChip = 24; */
        if (message.avgChip !== 0)
            writer.tag(24, WireType.Varint).uint32(message.avgChip);
        /* uint32 playingNum = 25; */
        if (message.playingNum !== 0)
            writer.tag(25, WireType.Varint).uint32(message.playingNum);
        /* int64 registerDeadline = 26; */
        if (message.registerDeadline !== 0)
            writer.tag(26, WireType.Varint).int64(message.registerDeadline);
        /* bool isPrizeRound = 27; */
        if (message.isPrizeRound !== false)
            writer.tag(27, WireType.Varint).bool(message.isPrizeRound);
        /* bool isAbleEnter = 28; */
        if (message.isAbleEnter !== false)
            writer.tag(28, WireType.Varint).bool(message.isAbleEnter);
        /* bpt.match.common.v1.MyTable myTable = 29; */
        if (message.myTable)
            MyTable.internalBinaryWrite(message.myTable, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* int64 breakTimeStopAt = 31; */
        if (message.breakTimeStopAt !== 0)
            writer.tag(31, WireType.Varint).int64(message.breakTimeStopAt);
        /* uint32 rollBackBlindLevel = 32; */
        if (message.rollBackBlindLevel !== 0)
            writer.tag(32, WireType.Varint).uint32(message.rollBackBlindLevel);
        /* bpt.match.common.v1.MainMatchInfo mainMatchInfo = 39; */
        if (message.mainMatchInfo)
            MainMatchInfo.internalBinaryWrite(message.mainMatchInfo, writer.tag(39, WireType.LengthDelimited).fork(), options).join();
        /* uint32 startBlindLevel = 40; */
        if (message.startBlindLevel !== 0)
            writer.tag(40, WireType.Varint).uint32(message.startBlindLevel);
        /* int64 registrationAt = 41; */
        if (message.registrationAt !== 0)
            writer.tag(41, WireType.Varint).int64(message.registrationAt);
        /* string lastPhasedMatchKey = 43; */
        if (message.lastPhasedMatchKey !== "")
            writer.tag(43, WireType.LengthDelimited).string(message.lastPhasedMatchKey);
        /* int64 waitDelayRegisterDeadline = 44; */
        if (message.waitDelayRegisterDeadline !== 0)
            writer.tag(44, WireType.Varint).int64(message.waitDelayRegisterDeadline);
        /* int64 joinedCnt = 45; */
        if (message.joinedCnt !== 0)
            writer.tag(45, WireType.Varint).int64(message.joinedCnt);
        /* bool isJoin = 46; */
        if (message.isJoin !== false)
            writer.tag(46, WireType.Varint).bool(message.isJoin);
        /* int64 pauseEndAt = 47; */
        if (message.pauseEndAt !== 0)
            writer.tag(47, WireType.Varint).int64(message.pauseEndAt);
        /* int64 pauseBeginAt = 48; */
        if (message.pauseBeginAt !== 0)
            writer.tag(48, WireType.Varint).int64(message.pauseBeginAt);
        /* int32 elapsedPauseSeconds = 49; */
        if (message.elapsedPauseSeconds !== 0)
            writer.tag(49, WireType.Varint).int32(message.elapsedPauseSeconds);
        /* uint32 smallestStack = 50; */
        if (message.smallestStack !== 0)
            writer.tag(50, WireType.Varint).uint32(message.smallestStack);
        /* uint32 largestStack = 51; */
        if (message.largestStack !== 0)
            writer.tag(51, WireType.Varint).uint32(message.largestStack);
        /* uint32 clubID = 52; */
        if (message.clubID !== 0)
            writer.tag(52, WireType.Varint).uint32(message.clubID);
        /* bpt.match.common.v1.CancelReason cancelReason = 53; */
        if (message.cancelReason !== 0)
            writer.tag(53, WireType.Varint).int32(message.cancelReason);
        /* string seatMatchTargetMatchKey = 54; */
        if (message.seatMatchTargetMatchKey !== "")
            writer.tag(54, WireType.LengthDelimited).string(message.seatMatchTargetMatchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MatchFront
 */
export const MatchFront = new MatchFront$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchContext$Type extends MessageType<MatchContext> {
    constructor() {
        super("bpt.match.common.v1.MatchContext", [
            { no: 1, name: "totalBuyInEnterCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "totalTicketEnterCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "totalIdentityRightEnterCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "totalAddOnCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "totalReBuyCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "totalBuyInReEntryCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "totalTicketReEntryCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "totalIdentityRightReEntryCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "registerPool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 8, name: "addOnPool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 9, name: "reBuyPool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 10, name: "reEntryPool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 13, name: "totalDeduplicatedEnterCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MatchContext>): MatchContext {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalBuyInEnterCnt = 0;
        message.totalTicketEnterCnt = 0;
        message.totalIdentityRightEnterCnt = 0;
        message.totalAddOnCnt = 0;
        message.totalReBuyCnt = 0;
        message.totalBuyInReEntryCnt = 0;
        message.totalTicketReEntryCnt = 0;
        message.totalIdentityRightReEntryCnt = 0;
        message.registerPool = [];
        message.addOnPool = [];
        message.reBuyPool = [];
        message.reEntryPool = [];
        message.totalDeduplicatedEnterCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchContext>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchContext): MatchContext {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalBuyInEnterCnt */ 1:
                    message.totalBuyInEnterCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalTicketEnterCnt */ 5:
                    message.totalTicketEnterCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalIdentityRightEnterCnt */ 11:
                    message.totalIdentityRightEnterCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalAddOnCnt */ 2:
                    message.totalAddOnCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalReBuyCnt */ 3:
                    message.totalReBuyCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalBuyInReEntryCnt */ 4:
                    message.totalBuyInReEntryCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalTicketReEntryCnt */ 6:
                    message.totalTicketReEntryCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalIdentityRightReEntryCnt */ 12:
                    message.totalIdentityRightReEntryCnt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.SimpleAsset registerPool */ 7:
                    message.registerPool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset addOnPool */ 8:
                    message.addOnPool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset reBuyPool */ 9:
                    message.reBuyPool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset reEntryPool */ 10:
                    message.reEntryPool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalDeduplicatedEnterCnt */ 13:
                    message.totalDeduplicatedEnterCnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchContext, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalBuyInEnterCnt = 1; */
        if (message.totalBuyInEnterCnt !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalBuyInEnterCnt);
        /* int64 totalTicketEnterCnt = 5; */
        if (message.totalTicketEnterCnt !== 0)
            writer.tag(5, WireType.Varint).int64(message.totalTicketEnterCnt);
        /* int64 totalIdentityRightEnterCnt = 11; */
        if (message.totalIdentityRightEnterCnt !== 0)
            writer.tag(11, WireType.Varint).int64(message.totalIdentityRightEnterCnt);
        /* int64 totalAddOnCnt = 2; */
        if (message.totalAddOnCnt !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalAddOnCnt);
        /* int64 totalReBuyCnt = 3; */
        if (message.totalReBuyCnt !== 0)
            writer.tag(3, WireType.Varint).int64(message.totalReBuyCnt);
        /* int64 totalBuyInReEntryCnt = 4; */
        if (message.totalBuyInReEntryCnt !== 0)
            writer.tag(4, WireType.Varint).int64(message.totalBuyInReEntryCnt);
        /* int64 totalTicketReEntryCnt = 6; */
        if (message.totalTicketReEntryCnt !== 0)
            writer.tag(6, WireType.Varint).int64(message.totalTicketReEntryCnt);
        /* int64 totalIdentityRightReEntryCnt = 12; */
        if (message.totalIdentityRightReEntryCnt !== 0)
            writer.tag(12, WireType.Varint).int64(message.totalIdentityRightReEntryCnt);
        /* repeated bpt.common.SimpleAsset registerPool = 7; */
        for (let i = 0; i < message.registerPool.length; i++)
            SimpleAsset.internalBinaryWrite(message.registerPool[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset addOnPool = 8; */
        for (let i = 0; i < message.addOnPool.length; i++)
            SimpleAsset.internalBinaryWrite(message.addOnPool[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset reBuyPool = 9; */
        for (let i = 0; i < message.reBuyPool.length; i++)
            SimpleAsset.internalBinaryWrite(message.reBuyPool[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset reEntryPool = 10; */
        for (let i = 0; i < message.reEntryPool.length; i++)
            SimpleAsset.internalBinaryWrite(message.reEntryPool[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalDeduplicatedEnterCnt = 13; */
        if (message.totalDeduplicatedEnterCnt !== 0)
            writer.tag(13, WireType.Varint).int64(message.totalDeduplicatedEnterCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MatchContext
 */
export const MatchContext = new MatchContext$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MainMatchInfo$Type extends MessageType<MainMatchInfo> {
    constructor() {
        super("bpt.match.common.v1.MainMatchInfo", [
            { no: 1, name: "isMainMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "mainMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "phase", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "mainMatchType", kind: "enum", T: () => ["bpt.match.common.v1.MainMatchType", MainMatchType] },
            { no: 5, name: "finalDayMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MainMatchInfo>): MainMatchInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isMainMatch = false;
        message.mainMatchKey = "";
        message.phase = 0;
        message.mainMatchType = 0;
        message.finalDayMatchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MainMatchInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MainMatchInfo): MainMatchInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isMainMatch */ 1:
                    message.isMainMatch = reader.bool();
                    break;
                case /* string mainMatchKey */ 2:
                    message.mainMatchKey = reader.string();
                    break;
                case /* int32 phase */ 3:
                    message.phase = reader.int32();
                    break;
                case /* bpt.match.common.v1.MainMatchType mainMatchType */ 4:
                    message.mainMatchType = reader.int32();
                    break;
                case /* string finalDayMatchKey */ 5:
                    message.finalDayMatchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MainMatchInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isMainMatch = 1; */
        if (message.isMainMatch !== false)
            writer.tag(1, WireType.Varint).bool(message.isMainMatch);
        /* string mainMatchKey = 2; */
        if (message.mainMatchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.mainMatchKey);
        /* int32 phase = 3; */
        if (message.phase !== 0)
            writer.tag(3, WireType.Varint).int32(message.phase);
        /* bpt.match.common.v1.MainMatchType mainMatchType = 4; */
        if (message.mainMatchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.mainMatchType);
        /* string finalDayMatchKey = 5; */
        if (message.finalDayMatchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.finalDayMatchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MainMatchInfo
 */
export const MainMatchInfo = new MainMatchInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StageUser$Type extends MessageType<StageUser> {
    constructor() {
        super("bpt.match.common.v1.StageUser", [
            { no: 1, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "Uid", kind: "scalar", localName: "Uid", jsonName: "Uid", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Chips", kind: "scalar", localName: "Chips", jsonName: "Chips", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<StageUser>): StageUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.Uid = 0;
        message.Chips = 0;
        if (value !== undefined)
            reflectionMergePartial<StageUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StageUser): StageUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 rank */ 1:
                    message.rank = reader.uint32();
                    break;
                case /* int64 Uid = 2 [json_name = "Uid"];*/ 2:
                    message.Uid = reader.int64().toNumber();
                    break;
                case /* uint64 Chips = 3 [json_name = "Chips"];*/ 3:
                    message.Chips = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StageUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).uint32(message.rank);
        /* int64 Uid = 2 [json_name = "Uid"]; */
        if (message.Uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.Uid);
        /* uint64 Chips = 3 [json_name = "Chips"]; */
        if (message.Chips !== 0)
            writer.tag(3, WireType.Varint).uint64(message.Chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.StageUser
 */
export const StageUser = new StageUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskUser$Type extends MessageType<DeskUser> {
    constructor() {
        super("bpt.match.common.v1.DeskUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "seatNo", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "isManaged", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DeskUser>): DeskUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.chips = 0;
        message.seatNo = 0;
        message.isManaged = false;
        if (value !== undefined)
            reflectionMergePartial<DeskUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskUser): DeskUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 chips */ 2:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* uint32 seatNo */ 3:
                    message.seatNo = reader.uint32();
                    break;
                case /* bool isManaged */ 4:
                    message.isManaged = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint64 chips = 2; */
        if (message.chips !== 0)
            writer.tag(2, WireType.Varint).uint64(message.chips);
        /* uint32 seatNo = 3; */
        if (message.seatNo !== 0)
            writer.tag(3, WireType.Varint).uint32(message.seatNo);
        /* bool isManaged = 4; */
        if (message.isManaged !== false)
            writer.tag(4, WireType.Varint).bool(message.isManaged);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.DeskUser
 */
export const DeskUser = new DeskUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MyTable$Type extends MessageType<MyTable> {
    constructor() {
        super("bpt.match.common.v1.MyTable", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableServerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "deskStatus", kind: "enum", T: () => ["bpt.match.common.v1.DeskStatus", DeskStatus] }
        ]);
    }
    create(value?: PartialMessage<MyTable>): MyTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.tableServerID = 0;
        message.deskStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<MyTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MyTable): MyTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* int64 tableServerID */ 2:
                    message.tableServerID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.DeskStatus deskStatus */ 3:
                    message.deskStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MyTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* int64 tableServerID = 2; */
        if (message.tableServerID !== 0)
            writer.tag(2, WireType.Varint).int64(message.tableServerID);
        /* bpt.match.common.v1.DeskStatus deskStatus = 3; */
        if (message.deskStatus !== 0)
            writer.tag(3, WireType.Varint).int32(message.deskStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MyTable
 */
export const MyTable = new MyTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Desk$Type extends MessageType<Desk> {
    constructor() {
        super("bpt.match.common.v1.Desk", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "deskID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["bpt.match.common.v1.DeskStatus", DeskStatus] },
            { no: 4, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tableServerID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "smStack", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "lgStack", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "isFinalTable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "deskUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeskUser },
            { no: 11, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "smallBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "bigBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "LowerLimitChips", kind: "scalar", localName: "LowerLimitChips", jsonName: "LowerLimitChips", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "UpperLimitChips", kind: "scalar", localName: "UpperLimitChips", jsonName: "UpperLimitChips", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "playerPerTable", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 17, name: "ante", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "deskViewID", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 19, name: "dealerSeatNo", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Desk>): Desk {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.deskID = 0;
        message.status = 0;
        message.tableKey = "";
        message.tableServerID = 0;
        message.smStack = 0;
        message.lgStack = 0;
        message.blindLevel = 0;
        message.isFinalTable = false;
        message.deskUsers = [];
        message.matchKey = "";
        message.smallBlind = 0;
        message.bigBlind = 0;
        message.LowerLimitChips = 0;
        message.UpperLimitChips = 0;
        message.playerPerTable = 0;
        message.ante = 0;
        message.deskViewID = 0;
        message.dealerSeatNo = 0;
        if (value !== undefined)
            reflectionMergePartial<Desk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Desk): Desk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* int64 deskID */ 2:
                    message.deskID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.DeskStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* string tableKey */ 4:
                    message.tableKey = reader.string();
                    break;
                case /* int64 tableServerID */ 5:
                    message.tableServerID = reader.int64().toNumber();
                    break;
                case /* uint64 smStack */ 6:
                    message.smStack = reader.uint64().toNumber();
                    break;
                case /* uint64 lgStack */ 7:
                    message.lgStack = reader.uint64().toNumber();
                    break;
                case /* uint32 blindLevel */ 8:
                    message.blindLevel = reader.uint32();
                    break;
                case /* bool isFinalTable */ 9:
                    message.isFinalTable = reader.bool();
                    break;
                case /* repeated bpt.match.common.v1.DeskUser deskUsers */ 10:
                    message.deskUsers.push(DeskUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 11:
                    message.matchKey = reader.string();
                    break;
                case /* uint64 smallBlind */ 12:
                    message.smallBlind = reader.uint64().toNumber();
                    break;
                case /* uint64 bigBlind */ 13:
                    message.bigBlind = reader.uint64().toNumber();
                    break;
                case /* uint64 LowerLimitChips = 14 [json_name = "LowerLimitChips"];*/ 14:
                    message.LowerLimitChips = reader.uint64().toNumber();
                    break;
                case /* uint64 UpperLimitChips = 15 [json_name = "UpperLimitChips"];*/ 15:
                    message.UpperLimitChips = reader.uint64().toNumber();
                    break;
                case /* uint32 playerPerTable */ 16:
                    message.playerPerTable = reader.uint32();
                    break;
                case /* uint64 ante */ 17:
                    message.ante = reader.uint64().toNumber();
                    break;
                case /* uint64 deskViewID */ 18:
                    message.deskViewID = reader.uint64().toNumber();
                    break;
                case /* uint32 dealerSeatNo */ 19:
                    message.dealerSeatNo = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Desk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* int64 deskID = 2; */
        if (message.deskID !== 0)
            writer.tag(2, WireType.Varint).int64(message.deskID);
        /* bpt.match.common.v1.DeskStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* string tableKey = 4; */
        if (message.tableKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tableKey);
        /* int64 tableServerID = 5; */
        if (message.tableServerID !== 0)
            writer.tag(5, WireType.Varint).int64(message.tableServerID);
        /* uint64 smStack = 6; */
        if (message.smStack !== 0)
            writer.tag(6, WireType.Varint).uint64(message.smStack);
        /* uint64 lgStack = 7; */
        if (message.lgStack !== 0)
            writer.tag(7, WireType.Varint).uint64(message.lgStack);
        /* uint32 blindLevel = 8; */
        if (message.blindLevel !== 0)
            writer.tag(8, WireType.Varint).uint32(message.blindLevel);
        /* bool isFinalTable = 9; */
        if (message.isFinalTable !== false)
            writer.tag(9, WireType.Varint).bool(message.isFinalTable);
        /* repeated bpt.match.common.v1.DeskUser deskUsers = 10; */
        for (let i = 0; i < message.deskUsers.length; i++)
            DeskUser.internalBinaryWrite(message.deskUsers[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 11; */
        if (message.matchKey !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.matchKey);
        /* uint64 smallBlind = 12; */
        if (message.smallBlind !== 0)
            writer.tag(12, WireType.Varint).uint64(message.smallBlind);
        /* uint64 bigBlind = 13; */
        if (message.bigBlind !== 0)
            writer.tag(13, WireType.Varint).uint64(message.bigBlind);
        /* uint64 LowerLimitChips = 14 [json_name = "LowerLimitChips"]; */
        if (message.LowerLimitChips !== 0)
            writer.tag(14, WireType.Varint).uint64(message.LowerLimitChips);
        /* uint64 UpperLimitChips = 15 [json_name = "UpperLimitChips"]; */
        if (message.UpperLimitChips !== 0)
            writer.tag(15, WireType.Varint).uint64(message.UpperLimitChips);
        /* uint32 playerPerTable = 16; */
        if (message.playerPerTable !== 0)
            writer.tag(16, WireType.Varint).uint32(message.playerPerTable);
        /* uint64 ante = 17; */
        if (message.ante !== 0)
            writer.tag(17, WireType.Varint).uint64(message.ante);
        /* uint64 deskViewID = 18; */
        if (message.deskViewID !== 0)
            writer.tag(18, WireType.Varint).uint64(message.deskViewID);
        /* uint32 dealerSeatNo = 19; */
        if (message.dealerSeatNo !== 0)
            writer.tag(19, WireType.Varint).uint32(message.dealerSeatNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.Desk
 */
export const Desk = new Desk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("bpt.match.common.v1.User", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "seatNo", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "status", kind: "enum", T: () => ["bpt.match.common.v1.UserStatus", UserStatus] },
            { no: 7, name: "RegisterAt", kind: "scalar", localName: "RegisterAt", jsonName: "RegisterAt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "userOutAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "prize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 10, name: "reBuyCnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "addOnCnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "reEntryCnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 18, name: "reEntryByTicketCnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 21, name: "reEntryByIdentity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "reBuyAddOnDeadLine", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "tableSrvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "matchBuyIn", kind: "message", T: () => AssetItem },
            { no: 19, name: "eliminatedUsers", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 20, name: "earlyBird", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "stageResult", kind: "enum", T: () => ["bpt.match.common.v1.StageResult", StageResult] },
            { no: 23, name: "reBuyCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 24, name: "addOnCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 25, name: "reEntryCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.rank = 0;
        message.seatNo = 0;
        message.chips = 0;
        message.status = 0;
        message.RegisterAt = 0;
        message.userOutAt = 0;
        message.prize = [];
        message.reBuyCnt = 0;
        message.addOnCnt = 0;
        message.reEntryCnt = 0;
        message.reEntryByTicketCnt = 0;
        message.reEntryByIdentity = 0;
        message.reBuyAddOnDeadLine = 0;
        message.tableKey = "";
        message.tableSrvID = 0;
        message.timeCardNum = 0;
        message.eliminatedUsers = [];
        message.earlyBird = false;
        message.stageResult = 0;
        message.reBuyCost = [];
        message.addOnCost = [];
        message.reEntryCost = [];
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint32 rank */ 2:
                    message.rank = reader.uint32();
                    break;
                case /* uint32 seatNo */ 3:
                    message.seatNo = reader.uint32();
                    break;
                case /* uint64 chips */ 5:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* bpt.match.common.v1.UserStatus status */ 6:
                    message.status = reader.int32();
                    break;
                case /* int64 RegisterAt = 7 [json_name = "RegisterAt"];*/ 7:
                    message.RegisterAt = reader.int64().toNumber();
                    break;
                case /* int64 userOutAt */ 8:
                    message.userOutAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prize */ 9:
                    message.prize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 reBuyCnt */ 10:
                    message.reBuyCnt = reader.uint32();
                    break;
                case /* uint32 addOnCnt */ 11:
                    message.addOnCnt = reader.uint32();
                    break;
                case /* uint32 reEntryCnt */ 12:
                    message.reEntryCnt = reader.uint32();
                    break;
                case /* uint32 reEntryByTicketCnt */ 18:
                    message.reEntryByTicketCnt = reader.uint32();
                    break;
                case /* uint32 reEntryByIdentity */ 21:
                    message.reEntryByIdentity = reader.uint32();
                    break;
                case /* int64 reBuyAddOnDeadLine */ 13:
                    message.reBuyAddOnDeadLine = reader.int64().toNumber();
                    break;
                case /* string tableKey */ 14:
                    message.tableKey = reader.string();
                    break;
                case /* int64 tableSrvID */ 15:
                    message.tableSrvID = reader.int64().toNumber();
                    break;
                case /* int32 timeCardNum */ 16:
                    message.timeCardNum = reader.int32();
                    break;
                case /* bpt.common.AssetItem matchBuyIn */ 17:
                    message.matchBuyIn = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.matchBuyIn);
                    break;
                case /* repeated int64 eliminatedUsers */ 19:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.eliminatedUsers.push(reader.int64().toNumber());
                    else
                        message.eliminatedUsers.push(reader.int64().toNumber());
                    break;
                case /* bool earlyBird */ 20:
                    message.earlyBird = reader.bool();
                    break;
                case /* bpt.match.common.v1.StageResult stageResult */ 22:
                    message.stageResult = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem reBuyCost */ 23:
                    message.reBuyCost.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem addOnCost */ 24:
                    message.addOnCost.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem reEntryCost */ 25:
                    message.reEntryCost.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).uint32(message.rank);
        /* uint32 seatNo = 3; */
        if (message.seatNo !== 0)
            writer.tag(3, WireType.Varint).uint32(message.seatNo);
        /* uint64 chips = 5; */
        if (message.chips !== 0)
            writer.tag(5, WireType.Varint).uint64(message.chips);
        /* bpt.match.common.v1.UserStatus status = 6; */
        if (message.status !== 0)
            writer.tag(6, WireType.Varint).int32(message.status);
        /* int64 RegisterAt = 7 [json_name = "RegisterAt"]; */
        if (message.RegisterAt !== 0)
            writer.tag(7, WireType.Varint).int64(message.RegisterAt);
        /* int64 userOutAt = 8; */
        if (message.userOutAt !== 0)
            writer.tag(8, WireType.Varint).int64(message.userOutAt);
        /* repeated bpt.common.AssetItem prize = 9; */
        for (let i = 0; i < message.prize.length; i++)
            AssetItem.internalBinaryWrite(message.prize[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* uint32 reBuyCnt = 10; */
        if (message.reBuyCnt !== 0)
            writer.tag(10, WireType.Varint).uint32(message.reBuyCnt);
        /* uint32 addOnCnt = 11; */
        if (message.addOnCnt !== 0)
            writer.tag(11, WireType.Varint).uint32(message.addOnCnt);
        /* uint32 reEntryCnt = 12; */
        if (message.reEntryCnt !== 0)
            writer.tag(12, WireType.Varint).uint32(message.reEntryCnt);
        /* uint32 reEntryByTicketCnt = 18; */
        if (message.reEntryByTicketCnt !== 0)
            writer.tag(18, WireType.Varint).uint32(message.reEntryByTicketCnt);
        /* uint32 reEntryByIdentity = 21; */
        if (message.reEntryByIdentity !== 0)
            writer.tag(21, WireType.Varint).uint32(message.reEntryByIdentity);
        /* int64 reBuyAddOnDeadLine = 13; */
        if (message.reBuyAddOnDeadLine !== 0)
            writer.tag(13, WireType.Varint).int64(message.reBuyAddOnDeadLine);
        /* string tableKey = 14; */
        if (message.tableKey !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.tableKey);
        /* int64 tableSrvID = 15; */
        if (message.tableSrvID !== 0)
            writer.tag(15, WireType.Varint).int64(message.tableSrvID);
        /* int32 timeCardNum = 16; */
        if (message.timeCardNum !== 0)
            writer.tag(16, WireType.Varint).int32(message.timeCardNum);
        /* bpt.common.AssetItem matchBuyIn = 17; */
        if (message.matchBuyIn)
            AssetItem.internalBinaryWrite(message.matchBuyIn, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated int64 eliminatedUsers = 19; */
        if (message.eliminatedUsers.length) {
            writer.tag(19, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.eliminatedUsers.length; i++)
                writer.int64(message.eliminatedUsers[i]);
            writer.join();
        }
        /* bool earlyBird = 20; */
        if (message.earlyBird !== false)
            writer.tag(20, WireType.Varint).bool(message.earlyBird);
        /* bpt.match.common.v1.StageResult stageResult = 22; */
        if (message.stageResult !== 0)
            writer.tag(22, WireType.Varint).int32(message.stageResult);
        /* repeated bpt.common.AssetItem reBuyCost = 23; */
        for (let i = 0; i < message.reBuyCost.length; i++)
            AssetItem.internalBinaryWrite(message.reBuyCost[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem addOnCost = 24; */
        for (let i = 0; i < message.addOnCost.length; i++)
            AssetItem.internalBinaryWrite(message.addOnCost[i], writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem reEntryCost = 25; */
        for (let i = 0; i < message.reEntryCost.length; i++)
            AssetItem.internalBinaryWrite(message.reEntryCost[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterInfo$Type extends MessageType<RegisterInfo> {
    constructor() {
        super("bpt.match.common.v1.RegisterInfo", [
            { no: 1, name: "registerWay", kind: "enum", T: () => ["bpt.match.common.v1.RegisterWay", RegisterWay] }
        ]);
    }
    create(value?: PartialMessage<RegisterInfo>): RegisterInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.registerWay = 0;
        if (value !== undefined)
            reflectionMergePartial<RegisterInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterInfo): RegisterInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.RegisterWay registerWay */ 1:
                    message.registerWay = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.RegisterWay registerWay = 1; */
        if (message.registerWay !== 0)
            writer.tag(1, WireType.Varint).int32(message.registerWay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.RegisterInfo
 */
export const RegisterInfo = new RegisterInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterUser$Type extends MessageType<RegisterUser> {
    constructor() {
        super("bpt.match.common.v1.RegisterUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "registerAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "chips", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "reEnterCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "reEnterByTicketCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "reBuyCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "addOnCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "registerInfo", kind: "message", T: () => RegisterInfo },
            { no: 12, name: "joinStatus", kind: "enum", T: () => ["bpt.match.common.v1.JoinStatus", JoinStatus] },
            { no: 13, name: "advanceOrder", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterUser>): RegisterUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.registerAt = 0;
        message.name = "";
        message.chips = 0;
        message.reEnterCnt = 0;
        message.reEnterByTicketCnt = 0;
        message.reBuyCnt = 0;
        message.addOnCnt = 0;
        message.timeCardNum = 0;
        message.joinStatus = 0;
        message.advanceOrder = 0;
        if (value !== undefined)
            reflectionMergePartial<RegisterUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterUser): RegisterUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 registerAt */ 2:
                    message.registerAt = reader.int64().toNumber();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* int64 chips */ 5:
                    message.chips = reader.int64().toNumber();
                    break;
                case /* int64 reEnterCnt */ 6:
                    message.reEnterCnt = reader.int64().toNumber();
                    break;
                case /* int64 reEnterByTicketCnt */ 11:
                    message.reEnterByTicketCnt = reader.int64().toNumber();
                    break;
                case /* int64 reBuyCnt */ 7:
                    message.reBuyCnt = reader.int64().toNumber();
                    break;
                case /* int64 addOnCnt */ 8:
                    message.addOnCnt = reader.int64().toNumber();
                    break;
                case /* int32 timeCardNum */ 9:
                    message.timeCardNum = reader.int32();
                    break;
                case /* bpt.match.common.v1.RegisterInfo registerInfo */ 10:
                    message.registerInfo = RegisterInfo.internalBinaryRead(reader, reader.uint32(), options, message.registerInfo);
                    break;
                case /* bpt.match.common.v1.JoinStatus joinStatus */ 12:
                    message.joinStatus = reader.int32();
                    break;
                case /* int32 advanceOrder */ 13:
                    message.advanceOrder = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 registerAt = 2; */
        if (message.registerAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.registerAt);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* int64 chips = 5; */
        if (message.chips !== 0)
            writer.tag(5, WireType.Varint).int64(message.chips);
        /* int64 reEnterCnt = 6; */
        if (message.reEnterCnt !== 0)
            writer.tag(6, WireType.Varint).int64(message.reEnterCnt);
        /* int64 reEnterByTicketCnt = 11; */
        if (message.reEnterByTicketCnt !== 0)
            writer.tag(11, WireType.Varint).int64(message.reEnterByTicketCnt);
        /* int64 reBuyCnt = 7; */
        if (message.reBuyCnt !== 0)
            writer.tag(7, WireType.Varint).int64(message.reBuyCnt);
        /* int64 addOnCnt = 8; */
        if (message.addOnCnt !== 0)
            writer.tag(8, WireType.Varint).int64(message.addOnCnt);
        /* int32 timeCardNum = 9; */
        if (message.timeCardNum !== 0)
            writer.tag(9, WireType.Varint).int32(message.timeCardNum);
        /* bpt.match.common.v1.RegisterInfo registerInfo = 10; */
        if (message.registerInfo)
            RegisterInfo.internalBinaryWrite(message.registerInfo, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.JoinStatus joinStatus = 12; */
        if (message.joinStatus !== 0)
            writer.tag(12, WireType.Varint).int32(message.joinStatus);
        /* int32 advanceOrder = 13; */
        if (message.advanceOrder !== 0)
            writer.tag(13, WireType.Varint).int32(message.advanceOrder);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.RegisterUser
 */
export const RegisterUser = new RegisterUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgPaymentResult$Type extends MessageType<MsgPaymentResult> {
    constructor() {
        super("bpt.match.common.v1.MsgPaymentResult", [
            { no: 1, name: "txID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "scene", kind: "enum", T: () => ["bpt.common.SceneType", SceneType] },
            { no: 4, name: "isSuccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "errMsg", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgPaymentResult>): MsgPaymentResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.txID = "";
        message.matchKey = "";
        message.scene = 0;
        message.isSuccess = false;
        message.errMsg = "";
        if (value !== undefined)
            reflectionMergePartial<MsgPaymentResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgPaymentResult): MsgPaymentResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string txID */ 1:
                    message.txID = reader.string();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.common.SceneType scene */ 3:
                    message.scene = reader.int32();
                    break;
                case /* bool isSuccess */ 4:
                    message.isSuccess = reader.bool();
                    break;
                case /* string errMsg */ 5:
                    message.errMsg = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgPaymentResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string txID = 1; */
        if (message.txID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.txID);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.common.SceneType scene = 3; */
        if (message.scene !== 0)
            writer.tag(3, WireType.Varint).int32(message.scene);
        /* bool isSuccess = 4; */
        if (message.isSuccess !== false)
            writer.tag(4, WireType.Varint).bool(message.isSuccess);
        /* string errMsg = 5; */
        if (message.errMsg !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.errMsg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MsgPaymentResult
 */
export const MsgPaymentResult = new MsgPaymentResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgTxResult$Type extends MessageType<MsgTxResult> {
    constructor() {
        super("bpt.match.common.v1.MsgTxResult", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scene", kind: "enum", T: () => ["bpt.common.SceneType", SceneType] },
            { no: 3, name: "isSuccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "chainTxHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "serverTS", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgTxResult>): MsgTxResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.scene = 0;
        message.isSuccess = false;
        message.chainTxHash = "";
        message.serverTS = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgTxResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgTxResult): MsgTxResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.common.SceneType scene */ 2:
                    message.scene = reader.int32();
                    break;
                case /* bool isSuccess */ 3:
                    message.isSuccess = reader.bool();
                    break;
                case /* string chainTxHash */ 4:
                    message.chainTxHash = reader.string();
                    break;
                case /* int64 serverTS */ 5:
                    message.serverTS = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgTxResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.common.SceneType scene = 2; */
        if (message.scene !== 0)
            writer.tag(2, WireType.Varint).int32(message.scene);
        /* bool isSuccess = 3; */
        if (message.isSuccess !== false)
            writer.tag(3, WireType.Varint).bool(message.isSuccess);
        /* string chainTxHash = 4; */
        if (message.chainTxHash !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.chainTxHash);
        /* int64 serverTS = 5; */
        if (message.serverTS !== 0)
            writer.tag(5, WireType.Varint).int64(message.serverTS);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MsgTxResult
 */
export const MsgTxResult = new MsgTxResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgReconnect$Type extends MessageType<MsgReconnect> {
    constructor() {
        super("bpt.match.common.v1.MsgReconnect", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgReconnect>): MsgReconnect {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.srvID = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgReconnect>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgReconnect): MsgReconnect {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 srvID */ 2:
                    message.srvID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgReconnect, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 srvID = 2; */
        if (message.srvID !== 0)
            writer.tag(2, WireType.Varint).int64(message.srvID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MsgReconnect
 */
export const MsgReconnect = new MsgReconnect$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchConf$Type extends MessageType<MatchConf> {
    constructor() {
        super("bpt.match.common.v1.MatchConf", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rule", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 5, name: "baseConf", kind: "message", T: () => BaseConf },
            { no: 6, name: "stageConf", kind: "message", T: () => StageConf },
            { no: 7, name: "participateConf", kind: "message", T: () => ParticipateConf },
            { no: 8, name: "schedule", kind: "message", T: () => ScheduleConfig },
            { no: 9, name: "entryConf", kind: "message", T: () => EntryConf },
            { no: 10, name: "dynamicPrizeConf", kind: "message", T: () => DynamicPrizeConf },
            { no: 11, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.State", State] },
            { no: 12, name: "lastUpdate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "fixedPrizeConf", kind: "message", T: () => FixedPrizeConf },
            { no: 14, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "spinAndGoPrizeConf", kind: "message", T: () => SpinAndGoPrizeConf },
            { no: 16, name: "cashConf", kind: "message", T: () => CashConf },
            { no: 17, name: "reviewState", kind: "enum", T: () => ["bpt.match.common.v1.ReviewState", ReviewState] },
            { no: 18, name: "prizeType", kind: "enum", T: () => ["bpt.match.common.v1.PrizeType", PrizeType] },
            { no: 19, name: "matchOwner", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 20, name: "showPriority", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "autoSendPrize", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 23, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 24, name: "parallelPrizeConf", kind: "message", T: () => ParallelPrizeConf },
            { no: 25, name: "endPoint", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 26, name: "cancelMatchTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 27, name: "reportToNews", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 28, name: "serviceConf", kind: "message", T: () => ServiceConf },
            { no: 29, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 30, name: "earlyBird", kind: "message", T: () => EarlyBird },
            { no: 31, name: "subMatchConfs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatchConf },
            { no: 32, name: "matchFeatureConfig", kind: "message", T: () => MatchFeatureConfig },
            { no: 33, name: "clubId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<MatchConf>): MatchConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.rule = "";
        message.matchType = 0;
        message.state = 0;
        message.lastUpdate = 0;
        message.operator = "";
        message.reviewState = 0;
        message.prizeType = 0;
        message.matchOwner = 0;
        message.showPriority = 0;
        message.tag = "";
        message.autoSendPrize = 0;
        message.endPoint = "";
        message.cancelMatchTime = 0;
        message.reportToNews = 0;
        message.matchListType = 0;
        message.subMatchConfs = [];
        message.clubId = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchConf): MatchConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string rule */ 3:
                    message.rule = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.match.common.v1.BaseConf baseConf */ 5:
                    message.baseConf = BaseConf.internalBinaryRead(reader, reader.uint32(), options, message.baseConf);
                    break;
                case /* bpt.match.common.v1.StageConf stageConf */ 6:
                    message.stageConf = StageConf.internalBinaryRead(reader, reader.uint32(), options, message.stageConf);
                    break;
                case /* bpt.match.common.v1.ParticipateConf participateConf */ 7:
                    message.participateConf = ParticipateConf.internalBinaryRead(reader, reader.uint32(), options, message.participateConf);
                    break;
                case /* bpt.match.common.v1.ScheduleConfig schedule */ 8:
                    message.schedule = ScheduleConfig.internalBinaryRead(reader, reader.uint32(), options, message.schedule);
                    break;
                case /* bpt.match.common.v1.EntryConf entryConf */ 9:
                    message.entryConf = EntryConf.internalBinaryRead(reader, reader.uint32(), options, message.entryConf);
                    break;
                case /* bpt.match.common.v1.DynamicPrizeConf dynamicPrizeConf */ 10:
                    message.dynamicPrizeConf = DynamicPrizeConf.internalBinaryRead(reader, reader.uint32(), options, message.dynamicPrizeConf);
                    break;
                case /* bpt.match.common.v1.State state */ 11:
                    message.state = reader.int32();
                    break;
                case /* int64 lastUpdate */ 12:
                    message.lastUpdate = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.FixedPrizeConf fixedPrizeConf */ 13:
                    message.fixedPrizeConf = FixedPrizeConf.internalBinaryRead(reader, reader.uint32(), options, message.fixedPrizeConf);
                    break;
                case /* string operator */ 14:
                    message.operator = reader.string();
                    break;
                case /* bpt.match.common.v1.SpinAndGoPrizeConf spinAndGoPrizeConf */ 15:
                    message.spinAndGoPrizeConf = SpinAndGoPrizeConf.internalBinaryRead(reader, reader.uint32(), options, message.spinAndGoPrizeConf);
                    break;
                case /* bpt.match.common.v1.CashConf cashConf */ 16:
                    message.cashConf = CashConf.internalBinaryRead(reader, reader.uint32(), options, message.cashConf);
                    break;
                case /* bpt.match.common.v1.ReviewState reviewState */ 17:
                    message.reviewState = reader.int32();
                    break;
                case /* bpt.match.common.v1.PrizeType prizeType */ 18:
                    message.prizeType = reader.int32();
                    break;
                case /* int64 matchOwner */ 19:
                    message.matchOwner = reader.int64().toNumber();
                    break;
                case /* int32 showPriority */ 20:
                    message.showPriority = reader.int32();
                    break;
                case /* string tag */ 21:
                    message.tag = reader.string();
                    break;
                case /* bpt.common.SwitchState autoSendPrize */ 22:
                    message.autoSendPrize = reader.int32();
                    break;
                case /* bpt.common.I18n i18n = 23 [json_name = "i18n"];*/ 23:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* bpt.match.common.v1.ParallelPrizeConf parallelPrizeConf */ 24:
                    message.parallelPrizeConf = ParallelPrizeConf.internalBinaryRead(reader, reader.uint32(), options, message.parallelPrizeConf);
                    break;
                case /* string endPoint */ 25:
                    message.endPoint = reader.string();
                    break;
                case /* int64 cancelMatchTime */ 26:
                    message.cancelMatchTime = reader.int64().toNumber();
                    break;
                case /* bpt.common.SwitchState reportToNews */ 27:
                    message.reportToNews = reader.int32();
                    break;
                case /* bpt.match.common.v1.ServiceConf serviceConf */ 28:
                    message.serviceConf = ServiceConf.internalBinaryRead(reader, reader.uint32(), options, message.serviceConf);
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 29:
                    message.matchListType = reader.int32();
                    break;
                case /* bpt.match.common.v1.EarlyBird earlyBird */ 30:
                    message.earlyBird = EarlyBird.internalBinaryRead(reader, reader.uint32(), options, message.earlyBird);
                    break;
                case /* repeated bpt.match.common.v1.SubMatchConf subMatchConfs */ 31:
                    message.subMatchConfs.push(SubMatchConf.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig */ 32:
                    message.matchFeatureConfig = MatchFeatureConfig.internalBinaryRead(reader, reader.uint32(), options, message.matchFeatureConfig);
                    break;
                case /* uint32 clubId */ 33:
                    message.clubId = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string rule = 3; */
        if (message.rule !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rule);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* bpt.match.common.v1.BaseConf baseConf = 5; */
        if (message.baseConf)
            BaseConf.internalBinaryWrite(message.baseConf, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.StageConf stageConf = 6; */
        if (message.stageConf)
            StageConf.internalBinaryWrite(message.stageConf, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ParticipateConf participateConf = 7; */
        if (message.participateConf)
            ParticipateConf.internalBinaryWrite(message.participateConf, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ScheduleConfig schedule = 8; */
        if (message.schedule)
            ScheduleConfig.internalBinaryWrite(message.schedule, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.EntryConf entryConf = 9; */
        if (message.entryConf)
            EntryConf.internalBinaryWrite(message.entryConf, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.DynamicPrizeConf dynamicPrizeConf = 10; */
        if (message.dynamicPrizeConf)
            DynamicPrizeConf.internalBinaryWrite(message.dynamicPrizeConf, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.State state = 11; */
        if (message.state !== 0)
            writer.tag(11, WireType.Varint).int32(message.state);
        /* int64 lastUpdate = 12; */
        if (message.lastUpdate !== 0)
            writer.tag(12, WireType.Varint).int64(message.lastUpdate);
        /* bpt.match.common.v1.FixedPrizeConf fixedPrizeConf = 13; */
        if (message.fixedPrizeConf)
            FixedPrizeConf.internalBinaryWrite(message.fixedPrizeConf, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* string operator = 14; */
        if (message.operator !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.operator);
        /* bpt.match.common.v1.SpinAndGoPrizeConf spinAndGoPrizeConf = 15; */
        if (message.spinAndGoPrizeConf)
            SpinAndGoPrizeConf.internalBinaryWrite(message.spinAndGoPrizeConf, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.CashConf cashConf = 16; */
        if (message.cashConf)
            CashConf.internalBinaryWrite(message.cashConf, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ReviewState reviewState = 17; */
        if (message.reviewState !== 0)
            writer.tag(17, WireType.Varint).int32(message.reviewState);
        /* bpt.match.common.v1.PrizeType prizeType = 18; */
        if (message.prizeType !== 0)
            writer.tag(18, WireType.Varint).int32(message.prizeType);
        /* int64 matchOwner = 19; */
        if (message.matchOwner !== 0)
            writer.tag(19, WireType.Varint).int64(message.matchOwner);
        /* int32 showPriority = 20; */
        if (message.showPriority !== 0)
            writer.tag(20, WireType.Varint).int32(message.showPriority);
        /* string tag = 21; */
        if (message.tag !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.tag);
        /* bpt.common.SwitchState autoSendPrize = 22; */
        if (message.autoSendPrize !== 0)
            writer.tag(22, WireType.Varint).int32(message.autoSendPrize);
        /* bpt.common.I18n i18n = 23 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ParallelPrizeConf parallelPrizeConf = 24; */
        if (message.parallelPrizeConf)
            ParallelPrizeConf.internalBinaryWrite(message.parallelPrizeConf, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* string endPoint = 25; */
        if (message.endPoint !== "")
            writer.tag(25, WireType.LengthDelimited).string(message.endPoint);
        /* int64 cancelMatchTime = 26; */
        if (message.cancelMatchTime !== 0)
            writer.tag(26, WireType.Varint).int64(message.cancelMatchTime);
        /* bpt.common.SwitchState reportToNews = 27; */
        if (message.reportToNews !== 0)
            writer.tag(27, WireType.Varint).int32(message.reportToNews);
        /* bpt.match.common.v1.ServiceConf serviceConf = 28; */
        if (message.serviceConf)
            ServiceConf.internalBinaryWrite(message.serviceConf, writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchListType matchListType = 29; */
        if (message.matchListType !== 0)
            writer.tag(29, WireType.Varint).int32(message.matchListType);
        /* bpt.match.common.v1.EarlyBird earlyBird = 30; */
        if (message.earlyBird)
            EarlyBird.internalBinaryWrite(message.earlyBird, writer.tag(30, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.SubMatchConf subMatchConfs = 31; */
        for (let i = 0; i < message.subMatchConfs.length; i++)
            SubMatchConf.internalBinaryWrite(message.subMatchConfs[i], writer.tag(31, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig = 32; */
        if (message.matchFeatureConfig)
            MatchFeatureConfig.internalBinaryWrite(message.matchFeatureConfig, writer.tag(32, WireType.LengthDelimited).fork(), options).join();
        /* uint32 clubId = 33; */
        if (message.clubId !== 0)
            writer.tag(33, WireType.Varint).uint32(message.clubId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.MatchConf
 */
export const MatchConf = new MatchConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PasswordConf$Type extends MessageType<PasswordConf> {
    constructor() {
        super("bpt.match.common.v1.PasswordConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "password", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PasswordConf>): PasswordConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.password = "";
        if (value !== undefined)
            reflectionMergePartial<PasswordConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PasswordConf): PasswordConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* string password */ 2:
                    message.password = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PasswordConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* string password = 2; */
        if (message.password !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.password);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.PasswordConf
 */
export const PasswordConf = new PasswordConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnterReviewConf$Type extends MessageType<EnterReviewConf> {
    constructor() {
        super("bpt.match.common.v1.EnterReviewConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<EnterReviewConf>): EnterReviewConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        if (value !== undefined)
            reflectionMergePartial<EnterReviewConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnterReviewConf): EnterReviewConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnterReviewConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.EnterReviewConf
 */
export const EnterReviewConf = new EnterReviewConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeSlot$Type extends MessageType<TimeSlot> {
    constructor() {
        super("bpt.match.common.v1.TimeSlot", [
            { no: 1, name: "showTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchStartTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TimeSlot>): TimeSlot {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.showTime = 0;
        message.matchStartTime = 0;
        if (value !== undefined)
            reflectionMergePartial<TimeSlot>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeSlot): TimeSlot {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 showTime */ 1:
                    message.showTime = reader.int64().toNumber();
                    break;
                case /* int64 matchStartTime */ 2:
                    message.matchStartTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeSlot, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 showTime = 1; */
        if (message.showTime !== 0)
            writer.tag(1, WireType.Varint).int64(message.showTime);
        /* int64 matchStartTime = 2; */
        if (message.matchStartTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.matchStartTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.TimeSlot
 */
export const TimeSlot = new TimeSlot$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BaseConf$Type extends MessageType<BaseConf> {
    constructor() {
        super("bpt.match.common.v1.BaseConf", [
            { no: 1, name: "betWay", kind: "enum", T: () => ["bpt.match.common.v1.BetWay", BetWay] },
            { no: 2, name: "startingStack", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "minPlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "maxPlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "playerPerTable", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "showImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "showImgMobile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "mobileShowImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "mobileShowImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "showImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "showImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BaseConf>): BaseConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.betWay = 0;
        message.startingStack = 0;
        message.minPlayerNum = 0;
        message.maxPlayerNum = 0;
        message.playerPerTable = 0;
        message.showImg = "";
        message.showImgMobile = "";
        message.mobileShowImgList = "";
        message.mobileShowImgDetail = "";
        message.showImgList = "";
        message.showImgDetail = "";
        if (value !== undefined)
            reflectionMergePartial<BaseConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BaseConf): BaseConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.BetWay betWay */ 1:
                    message.betWay = reader.int32();
                    break;
                case /* uint64 startingStack */ 2:
                    message.startingStack = reader.uint64().toNumber();
                    break;
                case /* uint32 minPlayerNum */ 3:
                    message.minPlayerNum = reader.uint32();
                    break;
                case /* uint32 maxPlayerNum */ 4:
                    message.maxPlayerNum = reader.uint32();
                    break;
                case /* uint32 playerPerTable */ 5:
                    message.playerPerTable = reader.uint32();
                    break;
                case /* string showImg */ 6:
                    message.showImg = reader.string();
                    break;
                case /* string showImgMobile */ 7:
                    message.showImgMobile = reader.string();
                    break;
                case /* string mobileShowImgList */ 8:
                    message.mobileShowImgList = reader.string();
                    break;
                case /* string mobileShowImgDetail */ 9:
                    message.mobileShowImgDetail = reader.string();
                    break;
                case /* string showImgList */ 10:
                    message.showImgList = reader.string();
                    break;
                case /* string showImgDetail */ 11:
                    message.showImgDetail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BaseConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.BetWay betWay = 1; */
        if (message.betWay !== 0)
            writer.tag(1, WireType.Varint).int32(message.betWay);
        /* uint64 startingStack = 2; */
        if (message.startingStack !== 0)
            writer.tag(2, WireType.Varint).uint64(message.startingStack);
        /* uint32 minPlayerNum = 3; */
        if (message.minPlayerNum !== 0)
            writer.tag(3, WireType.Varint).uint32(message.minPlayerNum);
        /* uint32 maxPlayerNum = 4; */
        if (message.maxPlayerNum !== 0)
            writer.tag(4, WireType.Varint).uint32(message.maxPlayerNum);
        /* uint32 playerPerTable = 5; */
        if (message.playerPerTable !== 0)
            writer.tag(5, WireType.Varint).uint32(message.playerPerTable);
        /* string showImg = 6; */
        if (message.showImg !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.showImg);
        /* string showImgMobile = 7; */
        if (message.showImgMobile !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.showImgMobile);
        /* string mobileShowImgList = 8; */
        if (message.mobileShowImgList !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.mobileShowImgList);
        /* string mobileShowImgDetail = 9; */
        if (message.mobileShowImgDetail !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.mobileShowImgDetail);
        /* string showImgList = 10; */
        if (message.showImgList !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.showImgList);
        /* string showImgDetail = 11; */
        if (message.showImgDetail !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.showImgDetail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.BaseConf
 */
export const BaseConf = new BaseConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceConf$Type extends MessageType<ServiceConf> {
    constructor() {
        super("bpt.match.common.v1.ServiceConf", [
            { no: 1, name: "matchVersion", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tableVersion", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceConf>): ServiceConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchVersion = 0;
        message.tableVersion = 0;
        if (value !== undefined)
            reflectionMergePartial<ServiceConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceConf): ServiceConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchVersion */ 1:
                    message.matchVersion = reader.int64().toNumber();
                    break;
                case /* int64 tableVersion */ 2:
                    message.tableVersion = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchVersion = 1; */
        if (message.matchVersion !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchVersion);
        /* int64 tableVersion = 2; */
        if (message.tableVersion !== 0)
            writer.tag(2, WireType.Varint).int64(message.tableVersion);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ServiceConf
 */
export const ServiceConf = new ServiceConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarlyBird$Type extends MessageType<EarlyBird> {
    constructor() {
        super("bpt.match.common.v1.EarlyBird", [
            { no: 1, name: "isOpen", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "isBroadcast", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 3, name: "getDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "award", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EarlyBirdAward },
            { no: 5, name: "avgAward", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EarlyBirdAvgAward }
        ]);
    }
    create(value?: PartialMessage<EarlyBird>): EarlyBird {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isOpen = 0;
        message.isBroadcast = 0;
        message.getDuration = 0;
        message.award = [];
        message.avgAward = [];
        if (value !== undefined)
            reflectionMergePartial<EarlyBird>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarlyBird): EarlyBird {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState isOpen */ 1:
                    message.isOpen = reader.int32();
                    break;
                case /* bpt.common.SwitchState isBroadcast */ 2:
                    message.isBroadcast = reader.int32();
                    break;
                case /* int64 getDuration */ 3:
                    message.getDuration = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.common.v1.EarlyBirdAward award */ 4:
                    message.award.push(EarlyBirdAward.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.common.v1.EarlyBirdAvgAward avgAward */ 5:
                    message.avgAward.push(EarlyBirdAvgAward.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EarlyBird, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState isOpen = 1; */
        if (message.isOpen !== 0)
            writer.tag(1, WireType.Varint).int32(message.isOpen);
        /* bpt.common.SwitchState isBroadcast = 2; */
        if (message.isBroadcast !== 0)
            writer.tag(2, WireType.Varint).int32(message.isBroadcast);
        /* int64 getDuration = 3; */
        if (message.getDuration !== 0)
            writer.tag(3, WireType.Varint).int64(message.getDuration);
        /* repeated bpt.match.common.v1.EarlyBirdAward award = 4; */
        for (let i = 0; i < message.award.length; i++)
            EarlyBirdAward.internalBinaryWrite(message.award[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.EarlyBirdAvgAward avgAward = 5; */
        for (let i = 0; i < message.avgAward.length; i++)
            EarlyBirdAvgAward.internalBinaryWrite(message.avgAward[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.EarlyBird
 */
export const EarlyBird = new EarlyBird$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarlyBirdAward$Type extends MessageType<EarlyBirdAward> {
    constructor() {
        super("bpt.match.common.v1.EarlyBirdAward", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "award", kind: "message", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<EarlyBirdAward>): EarlyBirdAward {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<EarlyBirdAward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarlyBirdAward): EarlyBirdAward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                case /* bpt.common.AssetItem award */ 3:
                    message.award = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EarlyBirdAward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        /* bpt.common.AssetItem award = 3; */
        if (message.award)
            AssetItem.internalBinaryWrite(message.award, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.EarlyBirdAward
 */
export const EarlyBirdAward = new EarlyBirdAward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarlyBirdAvgAward$Type extends MessageType<EarlyBirdAvgAward> {
    constructor() {
        super("bpt.match.common.v1.EarlyBirdAvgAward", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "upLimit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "award", kind: "message", T: () => AssetItem },
            { no: 4, name: "upLimitAssetItem", kind: "message", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<EarlyBirdAvgAward>): EarlyBirdAvgAward {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        message.upLimit = 0;
        if (value !== undefined)
            reflectionMergePartial<EarlyBirdAvgAward>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarlyBirdAvgAward): EarlyBirdAvgAward {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                case /* int32 upLimit */ 2:
                    message.upLimit = reader.int32();
                    break;
                case /* bpt.common.AssetItem award */ 3:
                    message.award = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                case /* bpt.common.AssetItem upLimitAssetItem */ 4:
                    message.upLimitAssetItem = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.upLimitAssetItem);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EarlyBirdAvgAward, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        /* int32 upLimit = 2; */
        if (message.upLimit !== 0)
            writer.tag(2, WireType.Varint).int32(message.upLimit);
        /* bpt.common.AssetItem award = 3; */
        if (message.award)
            AssetItem.internalBinaryWrite(message.award, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem upLimitAssetItem = 4; */
        if (message.upLimitAssetItem)
            AssetItem.internalBinaryWrite(message.upLimitAssetItem, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.EarlyBirdAvgAward
 */
export const EarlyBirdAvgAward = new EarlyBirdAvgAward$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StageConf$Type extends MessageType<StageConf> {
    constructor() {
        super("bpt.match.common.v1.StageConf", [
            { no: 1, name: "blindStructure", kind: "message", T: () => BlindStructure },
            { no: 2, name: "reBuy", kind: "message", T: () => RebuyConf },
            { no: 3, name: "addOn", kind: "message", T: () => AddOnOption },
            { no: 4, name: "reEntry", kind: "message", T: () => ReentryConf },
            { no: 5, name: "pauseConf", kind: "message", T: () => PauseConf },
            { no: 6, name: "bubbleConf", kind: "message", T: () => BubbleConf },
            { no: 7, name: "specTableConf", kind: "message", T: () => SpecTableConf },
            { no: 8, name: "timeCardConf", kind: "message", T: () => TimeCardConf }
        ]);
    }
    create(value?: PartialMessage<StageConf>): StageConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<StageConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StageConf): StageConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.BlindStructure blindStructure */ 1:
                    message.blindStructure = BlindStructure.internalBinaryRead(reader, reader.uint32(), options, message.blindStructure);
                    break;
                case /* bpt.match.common.v1.RebuyConf reBuy */ 2:
                    message.reBuy = RebuyConf.internalBinaryRead(reader, reader.uint32(), options, message.reBuy);
                    break;
                case /* bpt.match.common.v1.AddOnOption addOn */ 3:
                    message.addOn = AddOnOption.internalBinaryRead(reader, reader.uint32(), options, message.addOn);
                    break;
                case /* bpt.match.common.v1.ReentryConf reEntry */ 4:
                    message.reEntry = ReentryConf.internalBinaryRead(reader, reader.uint32(), options, message.reEntry);
                    break;
                case /* bpt.match.common.v1.PauseConf pauseConf */ 5:
                    message.pauseConf = PauseConf.internalBinaryRead(reader, reader.uint32(), options, message.pauseConf);
                    break;
                case /* bpt.match.common.v1.BubbleConf bubbleConf */ 6:
                    message.bubbleConf = BubbleConf.internalBinaryRead(reader, reader.uint32(), options, message.bubbleConf);
                    break;
                case /* bpt.match.common.v1.SpecTableConf specTableConf */ 7:
                    message.specTableConf = SpecTableConf.internalBinaryRead(reader, reader.uint32(), options, message.specTableConf);
                    break;
                case /* bpt.match.common.v1.TimeCardConf timeCardConf */ 8:
                    message.timeCardConf = TimeCardConf.internalBinaryRead(reader, reader.uint32(), options, message.timeCardConf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StageConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.BlindStructure blindStructure = 1; */
        if (message.blindStructure)
            BlindStructure.internalBinaryWrite(message.blindStructure, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.RebuyConf reBuy = 2; */
        if (message.reBuy)
            RebuyConf.internalBinaryWrite(message.reBuy, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.AddOnOption addOn = 3; */
        if (message.addOn)
            AddOnOption.internalBinaryWrite(message.addOn, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ReentryConf reEntry = 4; */
        if (message.reEntry)
            ReentryConf.internalBinaryWrite(message.reEntry, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.PauseConf pauseConf = 5; */
        if (message.pauseConf)
            PauseConf.internalBinaryWrite(message.pauseConf, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.BubbleConf bubbleConf = 6; */
        if (message.bubbleConf)
            BubbleConf.internalBinaryWrite(message.bubbleConf, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.SpecTableConf specTableConf = 7; */
        if (message.specTableConf)
            SpecTableConf.internalBinaryWrite(message.specTableConf, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.TimeCardConf timeCardConf = 8; */
        if (message.timeCardConf)
            TimeCardConf.internalBinaryWrite(message.timeCardConf, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.StageConf
 */
export const StageConf = new StageConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlindStructure$Type extends MessageType<BlindStructure> {
    constructor() {
        super("bpt.match.common.v1.BlindStructure", [
            { no: 1, name: "levels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BlindLevel },
            { no: 2, name: "anteType", kind: "enum", T: () => ["bpt.common.AnteType", AnteType] }
        ]);
    }
    create(value?: PartialMessage<BlindStructure>): BlindStructure {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.levels = [];
        message.anteType = 0;
        if (value !== undefined)
            reflectionMergePartial<BlindStructure>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlindStructure): BlindStructure {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.BlindLevel levels */ 1:
                    message.levels.push(BlindLevel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.AnteType anteType */ 2:
                    message.anteType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlindStructure, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.BlindLevel levels = 1; */
        for (let i = 0; i < message.levels.length; i++)
            BlindLevel.internalBinaryWrite(message.levels[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AnteType anteType = 2; */
        if (message.anteType !== 0)
            writer.tag(2, WireType.Varint).int32(message.anteType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.BlindStructure
 */
export const BlindStructure = new BlindStructure$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubMatchConf$Type extends MessageType<SubMatchConf> {
    constructor() {
        super("bpt.match.common.v1.SubMatchConf", [
            { no: 1, name: "subMatchConfID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "stageConf", kind: "message", T: () => StageConf },
            { no: 4, name: "sameWithDayOneA", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "startingStack", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "delayPlay", kind: "message", T: () => DelayPlay },
            { no: 7, name: "subMatchBuyIn", kind: "message", T: () => SimpleAsset }
        ]);
    }
    create(value?: PartialMessage<SubMatchConf>): SubMatchConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subMatchConfID = 0;
        message.sameWithDayOneA = 0;
        message.startingStack = 0;
        if (value !== undefined)
            reflectionMergePartial<SubMatchConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubMatchConf): SubMatchConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 subMatchConfID */ 1:
                    message.subMatchConfID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.StageConf stageConf */ 2:
                    message.stageConf = StageConf.internalBinaryRead(reader, reader.uint32(), options, message.stageConf);
                    break;
                case /* bpt.common.SwitchState sameWithDayOneA */ 4:
                    message.sameWithDayOneA = reader.int32();
                    break;
                case /* uint64 startingStack */ 5:
                    message.startingStack = reader.uint64().toNumber();
                    break;
                case /* bpt.match.common.v1.DelayPlay delayPlay */ 6:
                    message.delayPlay = DelayPlay.internalBinaryRead(reader, reader.uint32(), options, message.delayPlay);
                    break;
                case /* bpt.common.SimpleAsset subMatchBuyIn */ 7:
                    message.subMatchBuyIn = SimpleAsset.internalBinaryRead(reader, reader.uint32(), options, message.subMatchBuyIn);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubMatchConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 subMatchConfID = 1; */
        if (message.subMatchConfID !== 0)
            writer.tag(1, WireType.Varint).int64(message.subMatchConfID);
        /* bpt.match.common.v1.StageConf stageConf = 2; */
        if (message.stageConf)
            StageConf.internalBinaryWrite(message.stageConf, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState sameWithDayOneA = 4; */
        if (message.sameWithDayOneA !== 0)
            writer.tag(4, WireType.Varint).int32(message.sameWithDayOneA);
        /* uint64 startingStack = 5; */
        if (message.startingStack !== 0)
            writer.tag(5, WireType.Varint).uint64(message.startingStack);
        /* bpt.match.common.v1.DelayPlay delayPlay = 6; */
        if (message.delayPlay)
            DelayPlay.internalBinaryWrite(message.delayPlay, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SimpleAsset subMatchBuyIn = 7; */
        if (message.subMatchBuyIn)
            SimpleAsset.internalBinaryWrite(message.subMatchBuyIn, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.SubMatchConf
 */
export const SubMatchConf = new SubMatchConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlindLevel$Type extends MessageType<BlindLevel> {
    constructor() {
        super("bpt.match.common.v1.BlindLevel", [
            { no: 1, name: "level", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "ante", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "smallBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "bigBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "roundLength", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "minChipParticle", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "timeCardConfig", kind: "message", T: () => BlindLevelTimeCardConfig },
            { no: 8, name: "blindLevelTags", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.common.v1.BlindLevelTagType", BlindLevelTagType] }
        ]);
    }
    create(value?: PartialMessage<BlindLevel>): BlindLevel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.level = 0;
        message.ante = 0;
        message.smallBlind = 0;
        message.bigBlind = 0;
        message.roundLength = 0;
        message.minChipParticle = 0;
        message.blindLevelTags = [];
        if (value !== undefined)
            reflectionMergePartial<BlindLevel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlindLevel): BlindLevel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 level */ 1:
                    message.level = reader.uint32();
                    break;
                case /* uint64 ante */ 2:
                    message.ante = reader.uint64().toNumber();
                    break;
                case /* uint64 smallBlind */ 3:
                    message.smallBlind = reader.uint64().toNumber();
                    break;
                case /* uint64 bigBlind */ 4:
                    message.bigBlind = reader.uint64().toNumber();
                    break;
                case /* uint32 roundLength */ 5:
                    message.roundLength = reader.uint32();
                    break;
                case /* uint64 minChipParticle */ 6:
                    message.minChipParticle = reader.uint64().toNumber();
                    break;
                case /* bpt.match.common.v1.BlindLevelTimeCardConfig timeCardConfig */ 7:
                    message.timeCardConfig = BlindLevelTimeCardConfig.internalBinaryRead(reader, reader.uint32(), options, message.timeCardConfig);
                    break;
                case /* repeated bpt.match.common.v1.BlindLevelTagType blindLevelTags */ 8:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.blindLevelTags.push(reader.int32());
                    else
                        message.blindLevelTags.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlindLevel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).uint32(message.level);
        /* uint64 ante = 2; */
        if (message.ante !== 0)
            writer.tag(2, WireType.Varint).uint64(message.ante);
        /* uint64 smallBlind = 3; */
        if (message.smallBlind !== 0)
            writer.tag(3, WireType.Varint).uint64(message.smallBlind);
        /* uint64 bigBlind = 4; */
        if (message.bigBlind !== 0)
            writer.tag(4, WireType.Varint).uint64(message.bigBlind);
        /* uint32 roundLength = 5; */
        if (message.roundLength !== 0)
            writer.tag(5, WireType.Varint).uint32(message.roundLength);
        /* uint64 minChipParticle = 6; */
        if (message.minChipParticle !== 0)
            writer.tag(6, WireType.Varint).uint64(message.minChipParticle);
        /* bpt.match.common.v1.BlindLevelTimeCardConfig timeCardConfig = 7; */
        if (message.timeCardConfig)
            BlindLevelTimeCardConfig.internalBinaryWrite(message.timeCardConfig, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.BlindLevelTagType blindLevelTags = 8; */
        if (message.blindLevelTags.length) {
            writer.tag(8, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.blindLevelTags.length; i++)
                writer.int32(message.blindLevelTags[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.BlindLevel
 */
export const BlindLevel = new BlindLevel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BlindLevelTimeCardConfig$Type extends MessageType<BlindLevelTimeCardConfig> {
    constructor() {
        super("bpt.match.common.v1.BlindLevelTimeCardConfig", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "durationInSecond", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<BlindLevelTimeCardConfig>): BlindLevelTimeCardConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.durationInSecond = 0;
        message.timeCardNum = 0;
        if (value !== undefined)
            reflectionMergePartial<BlindLevelTimeCardConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BlindLevelTimeCardConfig): BlindLevelTimeCardConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* int64 durationInSecond */ 2:
                    message.durationInSecond = reader.int64().toNumber();
                    break;
                case /* int32 timeCardNum */ 3:
                    message.timeCardNum = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BlindLevelTimeCardConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* int64 durationInSecond = 2; */
        if (message.durationInSecond !== 0)
            writer.tag(2, WireType.Varint).int64(message.durationInSecond);
        /* int32 timeCardNum = 3; */
        if (message.timeCardNum !== 0)
            writer.tag(3, WireType.Varint).int32(message.timeCardNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.BlindLevelTimeCardConfig
 */
export const BlindLevelTimeCardConfig = new BlindLevelTimeCardConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RebuyConf$Type extends MessageType<RebuyConf> {
    constructor() {
        super("bpt.match.common.v1.RebuyConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "maxRebuy", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "exchange", kind: "message", T: () => Exchange },
            { no: 5, name: "timeout", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RebuyConf>): RebuyConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.blindLevel = 0;
        message.maxRebuy = 0;
        message.timeout = 0;
        if (value !== undefined)
            reflectionMergePartial<RebuyConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RebuyConf): RebuyConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* uint32 blindLevel */ 2:
                    message.blindLevel = reader.uint32();
                    break;
                case /* uint32 maxRebuy */ 3:
                    message.maxRebuy = reader.uint32();
                    break;
                case /* bpt.match.common.v1.Exchange exchange */ 4:
                    message.exchange = Exchange.internalBinaryRead(reader, reader.uint32(), options, message.exchange);
                    break;
                case /* int64 timeout */ 5:
                    message.timeout = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RebuyConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* uint32 blindLevel = 2; */
        if (message.blindLevel !== 0)
            writer.tag(2, WireType.Varint).uint32(message.blindLevel);
        /* uint32 maxRebuy = 3; */
        if (message.maxRebuy !== 0)
            writer.tag(3, WireType.Varint).uint32(message.maxRebuy);
        /* bpt.match.common.v1.Exchange exchange = 4; */
        if (message.exchange)
            Exchange.internalBinaryWrite(message.exchange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int64 timeout = 5; */
        if (message.timeout !== 0)
            writer.tag(5, WireType.Varint).int64(message.timeout);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.RebuyConf
 */
export const RebuyConf = new RebuyConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Exchange$Type extends MessageType<Exchange> {
    constructor() {
        super("bpt.match.common.v1.Exchange", [
            { no: 1, name: "pay", kind: "message", T: () => AssetItem },
            { no: 2, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Exchange>): Exchange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<Exchange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Exchange): Exchange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.AssetItem pay */ 1:
                    message.pay = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.pay);
                    break;
                case /* uint64 chips */ 2:
                    message.chips = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Exchange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.AssetItem pay = 1; */
        if (message.pay)
            AssetItem.internalBinaryWrite(message.pay, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint64 chips = 2; */
        if (message.chips !== 0)
            writer.tag(2, WireType.Varint).uint64(message.chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.Exchange
 */
export const Exchange = new Exchange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOnOption$Type extends MessageType<AddOnOption> {
    constructor() {
        super("bpt.match.common.v1.AddOnOption", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "exchange", kind: "message", T: () => Exchange },
            { no: 4, name: "durSecond", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "timeout", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "countMax", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AddOnOption>): AddOnOption {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.blindLevel = 0;
        message.durSecond = 0;
        message.timeout = 0;
        message.countMax = 0;
        if (value !== undefined)
            reflectionMergePartial<AddOnOption>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOnOption): AddOnOption {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* uint32 blindLevel */ 2:
                    message.blindLevel = reader.uint32();
                    break;
                case /* bpt.match.common.v1.Exchange exchange */ 3:
                    message.exchange = Exchange.internalBinaryRead(reader, reader.uint32(), options, message.exchange);
                    break;
                case /* int64 durSecond */ 4:
                    message.durSecond = reader.int64().toNumber();
                    break;
                case /* int64 timeout */ 5:
                    message.timeout = reader.int64().toNumber();
                    break;
                case /* int64 countMax */ 6:
                    message.countMax = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOnOption, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* uint32 blindLevel = 2; */
        if (message.blindLevel !== 0)
            writer.tag(2, WireType.Varint).uint32(message.blindLevel);
        /* bpt.match.common.v1.Exchange exchange = 3; */
        if (message.exchange)
            Exchange.internalBinaryWrite(message.exchange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 durSecond = 4; */
        if (message.durSecond !== 0)
            writer.tag(4, WireType.Varint).int64(message.durSecond);
        /* int64 timeout = 5; */
        if (message.timeout !== 0)
            writer.tag(5, WireType.Varint).int64(message.timeout);
        /* int64 countMax = 6; */
        if (message.countMax !== 0)
            writer.tag(6, WireType.Varint).int64(message.countMax);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.AddOnOption
 */
export const AddOnOption = new AddOnOption$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReentryConf$Type extends MessageType<ReentryConf> {
    constructor() {
        super("bpt.match.common.v1.ReentryConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "maxReentry", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "exchange", kind: "message", T: () => Exchange },
            { no: 4, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ReentryConf>): ReentryConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.maxReentry = 0;
        message.blindLevel = 0;
        if (value !== undefined)
            reflectionMergePartial<ReentryConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReentryConf): ReentryConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* uint32 maxReentry */ 2:
                    message.maxReentry = reader.uint32();
                    break;
                case /* bpt.match.common.v1.Exchange exchange */ 3:
                    message.exchange = Exchange.internalBinaryRead(reader, reader.uint32(), options, message.exchange);
                    break;
                case /* uint32 blindLevel */ 4:
                    message.blindLevel = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReentryConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* uint32 maxReentry = 2; */
        if (message.maxReentry !== 0)
            writer.tag(2, WireType.Varint).uint32(message.maxReentry);
        /* bpt.match.common.v1.Exchange exchange = 3; */
        if (message.exchange)
            Exchange.internalBinaryWrite(message.exchange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint32 blindLevel = 4; */
        if (message.blindLevel !== 0)
            writer.tag(4, WireType.Varint).uint32(message.blindLevel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ReentryConf
 */
export const ReentryConf = new ReentryConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseConf$Type extends MessageType<PauseConf> {
    constructor() {
        super("bpt.match.common.v1.PauseConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "pauseBlind", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PauseBlind }
        ]);
    }
    create(value?: PartialMessage<PauseConf>): PauseConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.pauseBlind = [];
        if (value !== undefined)
            reflectionMergePartial<PauseConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseConf): PauseConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.PauseBlind pauseBlind */ 4:
                    message.pauseBlind.push(PauseBlind.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* repeated bpt.match.common.v1.PauseBlind pauseBlind = 4; */
        for (let i = 0; i < message.pauseBlind.length; i++)
            PauseBlind.internalBinaryWrite(message.pauseBlind[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.PauseConf
 */
export const PauseConf = new PauseConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseBlind$Type extends MessageType<PauseBlind> {
    constructor() {
        super("bpt.match.common.v1.PauseBlind", [
            { no: 1, name: "level", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "break", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<PauseBlind>): PauseBlind {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.level = 0;
        message.break = 0;
        if (value !== undefined)
            reflectionMergePartial<PauseBlind>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseBlind): PauseBlind {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 level */ 1:
                    message.level = reader.uint32();
                    break;
                case /* uint32 break */ 2:
                    message.break = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseBlind, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).uint32(message.level);
        /* uint32 break = 2; */
        if (message.break !== 0)
            writer.tag(2, WireType.Varint).uint32(message.break);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.PauseBlind
 */
export const PauseBlind = new PauseBlind$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BubbleConf$Type extends MessageType<BubbleConf> {
    constructor() {
        super("bpt.match.common.v1.BubbleConf", [
            { no: 1, name: "isParallel", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<BubbleConf>): BubbleConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isParallel = 0;
        if (value !== undefined)
            reflectionMergePartial<BubbleConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BubbleConf): BubbleConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState isParallel */ 1:
                    message.isParallel = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BubbleConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState isParallel = 1; */
        if (message.isParallel !== 0)
            writer.tag(1, WireType.Varint).int32(message.isParallel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.BubbleConf
 */
export const BubbleConf = new BubbleConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpecTableConf$Type extends MessageType<SpecTableConf> {
    constructor() {
        super("bpt.match.common.v1.SpecTableConf", [
            { no: 8, name: "minAwardUserNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "finalTableUserNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SpecTableConf>): SpecTableConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.minAwardUserNum = 0;
        message.finalTableUserNum = 0;
        if (value !== undefined)
            reflectionMergePartial<SpecTableConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpecTableConf): SpecTableConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 minAwardUserNum */ 8:
                    message.minAwardUserNum = reader.uint32();
                    break;
                case /* uint32 finalTableUserNum */ 9:
                    message.finalTableUserNum = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpecTableConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 minAwardUserNum = 8; */
        if (message.minAwardUserNum !== 0)
            writer.tag(8, WireType.Varint).uint32(message.minAwardUserNum);
        /* uint32 finalTableUserNum = 9; */
        if (message.finalTableUserNum !== 0)
            writer.tag(9, WireType.Varint).uint32(message.finalTableUserNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.SpecTableConf
 */
export const SpecTableConf = new SpecTableConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeCardLevelGrant$Type extends MessageType<TimeCardLevelGrant> {
    constructor() {
        super("bpt.match.common.v1.TimeCardLevelGrant", [
            { no: 1, name: "level", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "timeCardNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<TimeCardLevelGrant>): TimeCardLevelGrant {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.level = 0;
        message.timeCardNum = 0;
        if (value !== undefined)
            reflectionMergePartial<TimeCardLevelGrant>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeCardLevelGrant): TimeCardLevelGrant {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 level */ 1:
                    message.level = reader.uint32();
                    break;
                case /* int32 timeCardNum */ 2:
                    message.timeCardNum = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeCardLevelGrant, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 level = 1; */
        if (message.level !== 0)
            writer.tag(1, WireType.Varint).uint32(message.level);
        /* int32 timeCardNum = 2; */
        if (message.timeCardNum !== 0)
            writer.tag(2, WireType.Varint).int32(message.timeCardNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.TimeCardLevelGrant
 */
export const TimeCardLevelGrant = new TimeCardLevelGrant$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeCardConf$Type extends MessageType<TimeCardConf> {
    constructor() {
        super("bpt.match.common.v1.TimeCardConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "durationInSecond", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "timeCardLevelGrants", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeCardLevelGrant }
        ]);
    }
    create(value?: PartialMessage<TimeCardConf>): TimeCardConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.durationInSecond = 0;
        message.timeCardLevelGrants = [];
        if (value !== undefined)
            reflectionMergePartial<TimeCardConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeCardConf): TimeCardConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* int64 durationInSecond */ 2:
                    message.durationInSecond = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.common.v1.TimeCardLevelGrant timeCardLevelGrants */ 3:
                    message.timeCardLevelGrants.push(TimeCardLevelGrant.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeCardConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* int64 durationInSecond = 2; */
        if (message.durationInSecond !== 0)
            writer.tag(2, WireType.Varint).int64(message.durationInSecond);
        /* repeated bpt.match.common.v1.TimeCardLevelGrant timeCardLevelGrants = 3; */
        for (let i = 0; i < message.timeCardLevelGrants.length; i++)
            TimeCardLevelGrant.internalBinaryWrite(message.timeCardLevelGrants[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.TimeCardConf
 */
export const TimeCardConf = new TimeCardConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipateConf$Type extends MessageType<ParticipateConf> {
    constructor() {
        super("bpt.match.common.v1.ParticipateConf", [
            { no: 1, name: "observerConf", kind: "message", T: () => ObserverConf },
            { no: 2, name: "robotConf", kind: "message", T: () => RobotConf },
            { no: 3, name: "delayPlay", kind: "message", T: () => DelayPlay },
            { no: 16, name: "passwordConf", kind: "message", T: () => PasswordConf },
            { no: 17, name: "enterReviewConf", kind: "message", T: () => EnterReviewConf }
        ]);
    }
    create(value?: PartialMessage<ParticipateConf>): ParticipateConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ParticipateConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParticipateConf): ParticipateConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.ObserverConf observerConf */ 1:
                    message.observerConf = ObserverConf.internalBinaryRead(reader, reader.uint32(), options, message.observerConf);
                    break;
                case /* bpt.match.common.v1.RobotConf robotConf */ 2:
                    message.robotConf = RobotConf.internalBinaryRead(reader, reader.uint32(), options, message.robotConf);
                    break;
                case /* bpt.match.common.v1.DelayPlay delayPlay */ 3:
                    message.delayPlay = DelayPlay.internalBinaryRead(reader, reader.uint32(), options, message.delayPlay);
                    break;
                case /* bpt.match.common.v1.PasswordConf passwordConf */ 16:
                    message.passwordConf = PasswordConf.internalBinaryRead(reader, reader.uint32(), options, message.passwordConf);
                    break;
                case /* bpt.match.common.v1.EnterReviewConf enterReviewConf */ 17:
                    message.enterReviewConf = EnterReviewConf.internalBinaryRead(reader, reader.uint32(), options, message.enterReviewConf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParticipateConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.ObserverConf observerConf = 1; */
        if (message.observerConf)
            ObserverConf.internalBinaryWrite(message.observerConf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.RobotConf robotConf = 2; */
        if (message.robotConf)
            RobotConf.internalBinaryWrite(message.robotConf, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.DelayPlay delayPlay = 3; */
        if (message.delayPlay)
            DelayPlay.internalBinaryWrite(message.delayPlay, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.PasswordConf passwordConf = 16; */
        if (message.passwordConf)
            PasswordConf.internalBinaryWrite(message.passwordConf, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.EnterReviewConf enterReviewConf = 17; */
        if (message.enterReviewConf)
            EnterReviewConf.internalBinaryWrite(message.enterReviewConf, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ParticipateConf
 */
export const ParticipateConf = new ParticipateConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObserverConf$Type extends MessageType<ObserverConf> {
    constructor() {
        super("bpt.match.common.v1.ObserverConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "observeMode", kind: "enum", T: () => ["bpt.match.common.v1.ObserveMode", ObserveMode] },
            { no: 3, name: "viewMode", kind: "enum", T: () => ["bpt.match.common.v1.ObserveView", ObserveView] },
            { no: 4, name: "isVisible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ObserverConf>): ObserverConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.observeMode = 0;
        message.viewMode = 0;
        message.isVisible = false;
        if (value !== undefined)
            reflectionMergePartial<ObserverConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObserverConf): ObserverConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* bpt.match.common.v1.ObserveMode observeMode */ 2:
                    message.observeMode = reader.int32();
                    break;
                case /* bpt.match.common.v1.ObserveView viewMode */ 3:
                    message.viewMode = reader.int32();
                    break;
                case /* bool isVisible */ 4:
                    message.isVisible = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ObserverConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* bpt.match.common.v1.ObserveMode observeMode = 2; */
        if (message.observeMode !== 0)
            writer.tag(2, WireType.Varint).int32(message.observeMode);
        /* bpt.match.common.v1.ObserveView viewMode = 3; */
        if (message.viewMode !== 0)
            writer.tag(3, WireType.Varint).int32(message.viewMode);
        /* bool isVisible = 4; */
        if (message.isVisible !== false)
            writer.tag(4, WireType.Varint).bool(message.isVisible);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ObserverConf
 */
export const ObserverConf = new ObserverConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RobotConf$Type extends MessageType<RobotConf> {
    constructor() {
        super("bpt.match.common.v1.RobotConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "mode", kind: "enum", T: () => ["bpt.match.common.v1.RobotEnterMode", RobotEnterMode] },
            { no: 3, name: "maxNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "enterWait", kind: "message", T: () => SectionRange },
            { no: 5, name: "addInterval", kind: "message", T: () => SectionRange },
            { no: 6, name: "addNum", kind: "message", T: () => SectionRange }
        ]);
    }
    create(value?: PartialMessage<RobotConf>): RobotConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.mode = 0;
        message.maxNum = 0;
        if (value !== undefined)
            reflectionMergePartial<RobotConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RobotConf): RobotConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* bpt.match.common.v1.RobotEnterMode mode */ 2:
                    message.mode = reader.int32();
                    break;
                case /* int64 maxNum */ 3:
                    message.maxNum = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.SectionRange enterWait */ 4:
                    message.enterWait = SectionRange.internalBinaryRead(reader, reader.uint32(), options, message.enterWait);
                    break;
                case /* bpt.match.common.v1.SectionRange addInterval */ 5:
                    message.addInterval = SectionRange.internalBinaryRead(reader, reader.uint32(), options, message.addInterval);
                    break;
                case /* bpt.match.common.v1.SectionRange addNum */ 6:
                    message.addNum = SectionRange.internalBinaryRead(reader, reader.uint32(), options, message.addNum);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RobotConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* bpt.match.common.v1.RobotEnterMode mode = 2; */
        if (message.mode !== 0)
            writer.tag(2, WireType.Varint).int32(message.mode);
        /* int64 maxNum = 3; */
        if (message.maxNum !== 0)
            writer.tag(3, WireType.Varint).int64(message.maxNum);
        /* bpt.match.common.v1.SectionRange enterWait = 4; */
        if (message.enterWait)
            SectionRange.internalBinaryWrite(message.enterWait, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.SectionRange addInterval = 5; */
        if (message.addInterval)
            SectionRange.internalBinaryWrite(message.addInterval, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.SectionRange addNum = 6; */
        if (message.addNum)
            SectionRange.internalBinaryWrite(message.addNum, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.RobotConf
 */
export const RobotConf = new RobotConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SectionRange$Type extends MessageType<SectionRange> {
    constructor() {
        super("bpt.match.common.v1.SectionRange", [
            { no: 1, name: "start", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "end", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SectionRange>): SectionRange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.start = 0;
        message.end = 0;
        if (value !== undefined)
            reflectionMergePartial<SectionRange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SectionRange): SectionRange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 start */ 1:
                    message.start = reader.uint64().toNumber();
                    break;
                case /* uint64 end */ 2:
                    message.end = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SectionRange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 start = 1; */
        if (message.start !== 0)
            writer.tag(1, WireType.Varint).uint64(message.start);
        /* uint64 end = 2; */
        if (message.end !== 0)
            writer.tag(2, WireType.Varint).uint64(message.end);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.SectionRange
 */
export const SectionRange = new SectionRange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelayPlay$Type extends MessageType<DelayPlay> {
    constructor() {
        super("bpt.match.common.v1.DelayPlay", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 3, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "delayDurationInSec", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DelayPlay>): DelayPlay {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.blindLevel = 0;
        message.delayDurationInSec = 0;
        if (value !== undefined)
            reflectionMergePartial<DelayPlay>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelayPlay): DelayPlay {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* uint32 blindLevel */ 3:
                    message.blindLevel = reader.uint32();
                    break;
                case /* int64 delayDurationInSec */ 5:
                    message.delayDurationInSec = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelayPlay, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* uint32 blindLevel = 3; */
        if (message.blindLevel !== 0)
            writer.tag(3, WireType.Varint).uint32(message.blindLevel);
        /* int64 delayDurationInSec = 5; */
        if (message.delayDurationInSec !== 0)
            writer.tag(5, WireType.Varint).int64(message.delayDurationInSec);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.DelayPlay
 */
export const DelayPlay = new DelayPlay$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ScheduleConfig$Type extends MessageType<ScheduleConfig> {
    constructor() {
        super("bpt.match.common.v1.ScheduleConfig", [
            { no: 1, name: "dateSpan", kind: "message", T: () => DateSpan },
            { no: 2, name: "timeSpans", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TimeSpan },
            { no: 3, name: "firstDisplayTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "type", kind: "enum", T: () => ["bpt.match.common.v1.SchedulerType", SchedulerType] },
            { no: 5, name: "matchStart", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "periodType", kind: "enum", T: () => ["bpt.match.common.v1.PeriodType", PeriodType] },
            { no: 7, name: "phasedConfig", kind: "message", T: () => PhasedConfig },
            { no: 8, name: "registrationDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ScheduleConfig>): ScheduleConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.timeSpans = [];
        message.firstDisplayTime = "";
        message.type = 0;
        message.matchStart = "";
        message.periodType = 0;
        message.registrationDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<ScheduleConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ScheduleConfig): ScheduleConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.DateSpan dateSpan */ 1:
                    message.dateSpan = DateSpan.internalBinaryRead(reader, reader.uint32(), options, message.dateSpan);
                    break;
                case /* repeated bpt.match.common.v1.TimeSpan timeSpans */ 2:
                    message.timeSpans.push(TimeSpan.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string firstDisplayTime */ 3:
                    message.firstDisplayTime = reader.string();
                    break;
                case /* bpt.match.common.v1.SchedulerType type */ 4:
                    message.type = reader.int32();
                    break;
                case /* string matchStart */ 5:
                    message.matchStart = reader.string();
                    break;
                case /* bpt.match.common.v1.PeriodType periodType */ 6:
                    message.periodType = reader.int32();
                    break;
                case /* bpt.match.common.v1.PhasedConfig phasedConfig */ 7:
                    message.phasedConfig = PhasedConfig.internalBinaryRead(reader, reader.uint32(), options, message.phasedConfig);
                    break;
                case /* int64 registrationDuration */ 8:
                    message.registrationDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ScheduleConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.DateSpan dateSpan = 1; */
        if (message.dateSpan)
            DateSpan.internalBinaryWrite(message.dateSpan, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.TimeSpan timeSpans = 2; */
        for (let i = 0; i < message.timeSpans.length; i++)
            TimeSpan.internalBinaryWrite(message.timeSpans[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string firstDisplayTime = 3; */
        if (message.firstDisplayTime !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.firstDisplayTime);
        /* bpt.match.common.v1.SchedulerType type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        /* string matchStart = 5; */
        if (message.matchStart !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchStart);
        /* bpt.match.common.v1.PeriodType periodType = 6; */
        if (message.periodType !== 0)
            writer.tag(6, WireType.Varint).int32(message.periodType);
        /* bpt.match.common.v1.PhasedConfig phasedConfig = 7; */
        if (message.phasedConfig)
            PhasedConfig.internalBinaryWrite(message.phasedConfig, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int64 registrationDuration = 8; */
        if (message.registrationDuration !== 0)
            writer.tag(8, WireType.Varint).int64(message.registrationDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ScheduleConfig
 */
export const ScheduleConfig = new ScheduleConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DateSpan$Type extends MessageType<DateSpan> {
    constructor() {
        super("bpt.match.common.v1.DateSpan", [
            { no: 1, name: "start", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "end", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "intervalDay", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DateSpan>): DateSpan {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.start = "";
        message.end = "";
        message.intervalDay = 0;
        if (value !== undefined)
            reflectionMergePartial<DateSpan>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DateSpan): DateSpan {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string start */ 1:
                    message.start = reader.string();
                    break;
                case /* string end */ 2:
                    message.end = reader.string();
                    break;
                case /* int32 intervalDay */ 3:
                    message.intervalDay = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DateSpan, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string start = 1; */
        if (message.start !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.start);
        /* string end = 2; */
        if (message.end !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.end);
        /* int32 intervalDay = 3; */
        if (message.intervalDay !== 0)
            writer.tag(3, WireType.Varint).int32(message.intervalDay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.DateSpan
 */
export const DateSpan = new DateSpan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeSpan$Type extends MessageType<TimeSpan> {
    constructor() {
        super("bpt.match.common.v1.TimeSpan", [
            { no: 1, name: "start", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "end", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "intervalDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TimeSpan>): TimeSpan {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.start = "";
        message.end = "";
        message.intervalDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<TimeSpan>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeSpan): TimeSpan {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string start */ 1:
                    message.start = reader.string();
                    break;
                case /* string end */ 2:
                    message.end = reader.string();
                    break;
                case /* int64 intervalDuration */ 3:
                    message.intervalDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeSpan, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string start = 1; */
        if (message.start !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.start);
        /* string end = 2; */
        if (message.end !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.end);
        /* int64 intervalDuration = 3; */
        if (message.intervalDuration !== 0)
            writer.tag(3, WireType.Varint).int64(message.intervalDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.TimeSpan
 */
export const TimeSpan = new TimeSpan$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhaseEntry$Type extends MessageType<PhaseEntry> {
    constructor() {
        super("bpt.match.common.v1.PhaseEntry", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "phase", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "multiEntry", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "gameOverType", kind: "enum", T: () => ["bpt.match.common.v1.GameOverType", GameOverType] },
            { no: 6, name: "gameOverBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "minPlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "maxPlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "gameOverPercentage", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "minAdvancePlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 13, name: "maxAdvancePlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "inheritStack", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<PhaseEntry>): PhaseEntry {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.phase = 0;
        message.multiEntry = 0;
        message.startAt = 0;
        message.gameOverType = 0;
        message.gameOverBlindLevel = 0;
        message.minPlayerNum = 0;
        message.maxPlayerNum = 0;
        message.name = "";
        message.gameOverPercentage = 0;
        message.minAdvancePlayerNum = 0;
        message.maxAdvancePlayerNum = 0;
        message.inheritStack = 0;
        if (value !== undefined)
            reflectionMergePartial<PhaseEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhaseEntry): PhaseEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* int32 phase */ 2:
                    message.phase = reader.int32();
                    break;
                case /* bpt.common.SwitchState multiEntry */ 3:
                    message.multiEntry = reader.int32();
                    break;
                case /* int64 startAt */ 4:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.GameOverType gameOverType */ 5:
                    message.gameOverType = reader.int32();
                    break;
                case /* uint32 gameOverBlindLevel */ 6:
                    message.gameOverBlindLevel = reader.uint32();
                    break;
                case /* uint32 minPlayerNum */ 8:
                    message.minPlayerNum = reader.uint32();
                    break;
                case /* uint32 maxPlayerNum */ 9:
                    message.maxPlayerNum = reader.uint32();
                    break;
                case /* string name */ 10:
                    message.name = reader.string();
                    break;
                case /* uint32 gameOverPercentage */ 11:
                    message.gameOverPercentage = reader.uint32();
                    break;
                case /* uint32 minAdvancePlayerNum */ 12:
                    message.minAdvancePlayerNum = reader.uint32();
                    break;
                case /* uint32 maxAdvancePlayerNum */ 13:
                    message.maxAdvancePlayerNum = reader.uint32();
                    break;
                case /* bpt.common.SwitchState inheritStack */ 14:
                    message.inheritStack = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhaseEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int32 phase = 2; */
        if (message.phase !== 0)
            writer.tag(2, WireType.Varint).int32(message.phase);
        /* bpt.common.SwitchState multiEntry = 3; */
        if (message.multiEntry !== 0)
            writer.tag(3, WireType.Varint).int32(message.multiEntry);
        /* int64 startAt = 4; */
        if (message.startAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.startAt);
        /* bpt.match.common.v1.GameOverType gameOverType = 5; */
        if (message.gameOverType !== 0)
            writer.tag(5, WireType.Varint).int32(message.gameOverType);
        /* uint32 gameOverBlindLevel = 6; */
        if (message.gameOverBlindLevel !== 0)
            writer.tag(6, WireType.Varint).uint32(message.gameOverBlindLevel);
        /* uint32 minPlayerNum = 8; */
        if (message.minPlayerNum !== 0)
            writer.tag(8, WireType.Varint).uint32(message.minPlayerNum);
        /* uint32 maxPlayerNum = 9; */
        if (message.maxPlayerNum !== 0)
            writer.tag(9, WireType.Varint).uint32(message.maxPlayerNum);
        /* string name = 10; */
        if (message.name !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.name);
        /* uint32 gameOverPercentage = 11; */
        if (message.gameOverPercentage !== 0)
            writer.tag(11, WireType.Varint).uint32(message.gameOverPercentage);
        /* uint32 minAdvancePlayerNum = 12; */
        if (message.minAdvancePlayerNum !== 0)
            writer.tag(12, WireType.Varint).uint32(message.minAdvancePlayerNum);
        /* uint32 maxAdvancePlayerNum = 13; */
        if (message.maxAdvancePlayerNum !== 0)
            writer.tag(13, WireType.Varint).uint32(message.maxAdvancePlayerNum);
        /* bpt.common.SwitchState inheritStack = 14; */
        if (message.inheritStack !== 0)
            writer.tag(14, WireType.Varint).int32(message.inheritStack);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.PhaseEntry
 */
export const PhaseEntry = new PhaseEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhaseRule$Type extends MessageType<PhaseRule> {
    constructor() {
        super("bpt.match.common.v1.PhaseRule", [
            { no: 1, name: "phase", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "ruleDesc", kind: "message", T: () => I18nField }
        ]);
    }
    create(value?: PartialMessage<PhaseRule>): PhaseRule {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.phase = 0;
        if (value !== undefined)
            reflectionMergePartial<PhaseRule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhaseRule): PhaseRule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 phase */ 1:
                    message.phase = reader.int32();
                    break;
                case /* bpt.common.I18nField ruleDesc */ 2:
                    message.ruleDesc = I18nField.internalBinaryRead(reader, reader.uint32(), options, message.ruleDesc);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhaseRule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 phase = 1; */
        if (message.phase !== 0)
            writer.tag(1, WireType.Varint).int32(message.phase);
        /* bpt.common.I18nField ruleDesc = 2; */
        if (message.ruleDesc)
            I18nField.internalBinaryWrite(message.ruleDesc, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.PhaseRule
 */
export const PhaseRule = new PhaseRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhasedConfig$Type extends MessageType<PhasedConfig> {
    constructor() {
        super("bpt.match.common.v1.PhasedConfig", [
            { no: 1, name: "phases", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PhaseEntry },
            { no: 2, name: "gameOverPercentage", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "phaseRules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PhaseRule }
        ]);
    }
    create(value?: PartialMessage<PhasedConfig>): PhasedConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.phases = [];
        message.gameOverPercentage = 0;
        message.phaseRules = [];
        if (value !== undefined)
            reflectionMergePartial<PhasedConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhasedConfig): PhasedConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.PhaseEntry phases */ 1:
                    message.phases.push(PhaseEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 gameOverPercentage */ 2:
                    message.gameOverPercentage = reader.uint32();
                    break;
                case /* repeated bpt.match.common.v1.PhaseRule phaseRules */ 3:
                    message.phaseRules.push(PhaseRule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhasedConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.PhaseEntry phases = 1; */
        for (let i = 0; i < message.phases.length; i++)
            PhaseEntry.internalBinaryWrite(message.phases[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint32 gameOverPercentage = 2; */
        if (message.gameOverPercentage !== 0)
            writer.tag(2, WireType.Varint).uint32(message.gameOverPercentage);
        /* repeated bpt.match.common.v1.PhaseRule phaseRules = 3; */
        for (let i = 0; i < message.phaseRules.length; i++)
            PhaseRule.internalBinaryWrite(message.phaseRules[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.PhasedConfig
 */
export const PhasedConfig = new PhasedConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Ticket$Type extends MessageType<Ticket> {
    constructor() {
        super("bpt.match.common.v1.Ticket", [
            { no: 1, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tokenID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Ticket>): Ticket {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.contractAddress = "";
        message.tokenID = "";
        message.rawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<Ticket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Ticket): Ticket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string contractAddress */ 1:
                    message.contractAddress = reader.string();
                    break;
                case /* string tokenID */ 2:
                    message.tokenID = reader.string();
                    break;
                case /* string rawAmount */ 3:
                    message.rawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Ticket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string contractAddress = 1; */
        if (message.contractAddress !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.contractAddress);
        /* string tokenID = 2; */
        if (message.tokenID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tokenID);
        /* string rawAmount = 3; */
        if (message.rawAmount !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.Ticket
 */
export const Ticket = new Ticket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntryConf$Type extends MessageType<EntryConf> {
    constructor() {
        super("bpt.match.common.v1.EntryConf", [
            { no: 2, name: "node", kind: "message", T: () => EntryNodeConfig },
            { no: 3, name: "chainConf", kind: "message", T: () => ChainConf },
            { no: 4, name: "ticketsConf", kind: "message", T: () => TicketsConf },
            { no: 5, name: "clubIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<EntryConf>): EntryConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.clubIds = [];
        if (value !== undefined)
            reflectionMergePartial<EntryConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntryConf): EntryConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.EntryNodeConfig node */ 2:
                    message.node = EntryNodeConfig.internalBinaryRead(reader, reader.uint32(), options, message.node);
                    break;
                case /* bpt.match.common.v1.ChainConf chainConf */ 3:
                    message.chainConf = ChainConf.internalBinaryRead(reader, reader.uint32(), options, message.chainConf);
                    break;
                case /* bpt.match.common.v1.TicketsConf ticketsConf */ 4:
                    message.ticketsConf = TicketsConf.internalBinaryRead(reader, reader.uint32(), options, message.ticketsConf);
                    break;
                case /* repeated uint32 clubIds */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.clubIds.push(reader.uint32());
                    else
                        message.clubIds.push(reader.uint32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntryConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.EntryNodeConfig node = 2; */
        if (message.node)
            EntryNodeConfig.internalBinaryWrite(message.node, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ChainConf chainConf = 3; */
        if (message.chainConf)
            ChainConf.internalBinaryWrite(message.chainConf, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.TicketsConf ticketsConf = 4; */
        if (message.ticketsConf)
            TicketsConf.internalBinaryWrite(message.ticketsConf, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated uint32 clubIds = 5; */
        if (message.clubIds.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.clubIds.length; i++)
                writer.uint32(message.clubIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.EntryConf
 */
export const EntryConf = new EntryConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChainConf$Type extends MessageType<ChainConf> {
    constructor() {
        super("bpt.match.common.v1.ChainConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 3, name: "waitTimeForChain", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "autoApply", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "dispatchContract", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChainConf>): ChainConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.chainID = 0;
        message.waitTimeForChain = 0;
        message.autoApply = 0;
        message.dispatchContract = "";
        if (value !== undefined)
            reflectionMergePartial<ChainConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChainConf): ChainConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* gaw.base.ChainID chainID */ 2:
                    message.chainID = reader.int32();
                    break;
                case /* int64 waitTimeForChain */ 3:
                    message.waitTimeForChain = reader.int64().toNumber();
                    break;
                case /* bpt.common.SwitchState autoApply */ 4:
                    message.autoApply = reader.int32();
                    break;
                case /* string dispatchContract */ 5:
                    message.dispatchContract = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChainConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* gaw.base.ChainID chainID = 2; */
        if (message.chainID !== 0)
            writer.tag(2, WireType.Varint).int32(message.chainID);
        /* int64 waitTimeForChain = 3; */
        if (message.waitTimeForChain !== 0)
            writer.tag(3, WireType.Varint).int64(message.waitTimeForChain);
        /* bpt.common.SwitchState autoApply = 4; */
        if (message.autoApply !== 0)
            writer.tag(4, WireType.Varint).int32(message.autoApply);
        /* string dispatchContract = 5; */
        if (message.dispatchContract !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.dispatchContract);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ChainConf
 */
export const ChainConf = new ChainConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntryNodeConfig$Type extends MessageType<EntryNodeConfig> {
    constructor() {
        super("bpt.match.common.v1.EntryNodeConfig", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.match.common.v1.EntryNodeType", EntryNodeType] },
            { no: 3, name: "nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EntryNodeConfig },
            { no: 4, name: "buyIn", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 11, name: "threshold", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "sign", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EntryNodeConfig>): EntryNodeConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.type = 0;
        message.nodes = [];
        message.buyIn = [];
        message.threshold = 0;
        message.sign = "";
        if (value !== undefined)
            reflectionMergePartial<EntryNodeConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntryNodeConfig): EntryNodeConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.EntryNodeType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.EntryNodeConfig nodes */ 3:
                    message.nodes.push(EntryNodeConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem buyIn */ 4:
                    message.buyIn.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 threshold */ 11:
                    message.threshold = reader.int64().toNumber();
                    break;
                case /* string sign */ 12:
                    message.sign = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntryNodeConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* bpt.match.common.v1.EntryNodeType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* repeated bpt.match.common.v1.EntryNodeConfig nodes = 3; */
        for (let i = 0; i < message.nodes.length; i++)
            EntryNodeConfig.internalBinaryWrite(message.nodes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem buyIn = 4; */
        for (let i = 0; i < message.buyIn.length; i++)
            AssetItem.internalBinaryWrite(message.buyIn[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int64 threshold = 11; */
        if (message.threshold !== 0)
            writer.tag(11, WireType.Varint).int64(message.threshold);
        /* string sign = 12; */
        if (message.sign !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.sign);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.EntryNodeConfig
 */
export const EntryNodeConfig = new EntryNodeConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TicketsConf$Type extends MessageType<TicketsConf> {
    constructor() {
        super("bpt.match.common.v1.TicketsConf", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "registerTicketCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "reentryTicketsConf", kind: "message", T: () => ReentryTicketsConf },
            { no: 5, name: "exchangePropID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "exchangePropRawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TicketsConf>): TicketsConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.propId = 0;
        message.registerTicketCnt = 0;
        message.exchangePropID = 0;
        message.exchangePropRawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<TicketsConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TicketsConf): TicketsConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* int64 propId */ 2:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* int32 registerTicketCnt */ 3:
                    message.registerTicketCnt = reader.int32();
                    break;
                case /* bpt.match.common.v1.ReentryTicketsConf reentryTicketsConf */ 4:
                    message.reentryTicketsConf = ReentryTicketsConf.internalBinaryRead(reader, reader.uint32(), options, message.reentryTicketsConf);
                    break;
                case /* int64 exchangePropID */ 5:
                    message.exchangePropID = reader.int64().toNumber();
                    break;
                case /* string exchangePropRawAmount */ 6:
                    message.exchangePropRawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TicketsConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* int64 propId = 2; */
        if (message.propId !== 0)
            writer.tag(2, WireType.Varint).int64(message.propId);
        /* int32 registerTicketCnt = 3; */
        if (message.registerTicketCnt !== 0)
            writer.tag(3, WireType.Varint).int32(message.registerTicketCnt);
        /* bpt.match.common.v1.ReentryTicketsConf reentryTicketsConf = 4; */
        if (message.reentryTicketsConf)
            ReentryTicketsConf.internalBinaryWrite(message.reentryTicketsConf, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int64 exchangePropID = 5; */
        if (message.exchangePropID !== 0)
            writer.tag(5, WireType.Varint).int64(message.exchangePropID);
        /* string exchangePropRawAmount = 6; */
        if (message.exchangePropRawAmount !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.exchangePropRawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.TicketsConf
 */
export const TicketsConf = new TicketsConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReentryTicketsConf$Type extends MessageType<ReentryTicketsConf> {
    constructor() {
        super("bpt.match.common.v1.ReentryTicketsConf", [
            { no: 4, name: "reentrySwitch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "reentryTicketCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ReentryTicketsConf>): ReentryTicketsConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.reentrySwitch = 0;
        message.reentryTicketCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<ReentryTicketsConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReentryTicketsConf): ReentryTicketsConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState reentrySwitch */ 4:
                    message.reentrySwitch = reader.int32();
                    break;
                case /* int32 reentryTicketCnt */ 5:
                    message.reentryTicketCnt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReentryTicketsConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState reentrySwitch = 4; */
        if (message.reentrySwitch !== 0)
            writer.tag(4, WireType.Varint).int32(message.reentrySwitch);
        /* int32 reentryTicketCnt = 5; */
        if (message.reentryTicketCnt !== 0)
            writer.tag(5, WireType.Varint).int32(message.reentryTicketCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ReentryTicketsConf
 */
export const ReentryTicketsConf = new ReentryTicketsConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DynamicPrizeConf$Type extends MessageType<DynamicPrizeConf> {
    constructor() {
        super("bpt.match.common.v1.DynamicPrizeConf", [
            { no: 1, name: "basePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 4, name: "tierPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TierPrize },
            { no: 5, name: "prizePercentage", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<DynamicPrizeConf>): DynamicPrizeConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.basePool = [];
        message.tierPrizes = [];
        message.prizePercentage = 0;
        if (value !== undefined)
            reflectionMergePartial<DynamicPrizeConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DynamicPrizeConf): DynamicPrizeConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem basePool */ 1:
                    message.basePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.common.v1.TierPrize tierPrizes */ 4:
                    message.tierPrizes.push(TierPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 prizePercentage */ 5:
                    message.prizePercentage = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DynamicPrizeConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem basePool = 1; */
        for (let i = 0; i < message.basePool.length; i++)
            AssetItem.internalBinaryWrite(message.basePool[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.TierPrize tierPrizes = 4; */
        for (let i = 0; i < message.tierPrizes.length; i++)
            TierPrize.internalBinaryWrite(message.tierPrizes[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint32 prizePercentage = 5; */
        if (message.prizePercentage !== 0)
            writer.tag(5, WireType.Varint).uint32(message.prizePercentage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.DynamicPrizeConf
 */
export const DynamicPrizeConf = new DynamicPrizeConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExtraPlaces$Type extends MessageType<ExtraPlaces> {
    constructor() {
        super("bpt.match.common.v1.ExtraPlaces", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "rawAmountPerPlace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExtraPlaces>): ExtraPlaces {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.rawAmountPerPlace = "";
        if (value !== undefined)
            reflectionMergePartial<ExtraPlaces>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExtraPlaces): ExtraPlaces {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* string rawAmountPerPlace */ 2:
                    message.rawAmountPerPlace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExtraPlaces, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* string rawAmountPerPlace = 2; */
        if (message.rawAmountPerPlace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rawAmountPerPlace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ExtraPlaces
 */
export const ExtraPlaces = new ExtraPlaces$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParallelPrizeConf$Type extends MessageType<ParallelPrizeConf> {
    constructor() {
        super("bpt.match.common.v1.ParallelPrizeConf", [
            { no: 1, name: "minAwardUserNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "rewardAsset", kind: "message", T: () => AssetItem },
            { no: 3, name: "extraPlace", kind: "message", T: () => ExtraPlaces }
        ]);
    }
    create(value?: PartialMessage<ParallelPrizeConf>): ParallelPrizeConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.minAwardUserNum = 0;
        if (value !== undefined)
            reflectionMergePartial<ParallelPrizeConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ParallelPrizeConf): ParallelPrizeConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 minAwardUserNum */ 1:
                    message.minAwardUserNum = reader.uint32();
                    break;
                case /* bpt.common.AssetItem rewardAsset */ 2:
                    message.rewardAsset = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.rewardAsset);
                    break;
                case /* bpt.match.common.v1.ExtraPlaces extraPlace */ 3:
                    message.extraPlace = ExtraPlaces.internalBinaryRead(reader, reader.uint32(), options, message.extraPlace);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ParallelPrizeConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 minAwardUserNum = 1; */
        if (message.minAwardUserNum !== 0)
            writer.tag(1, WireType.Varint).uint32(message.minAwardUserNum);
        /* bpt.common.AssetItem rewardAsset = 2; */
        if (message.rewardAsset)
            AssetItem.internalBinaryWrite(message.rewardAsset, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ExtraPlaces extraPlace = 3; */
        if (message.extraPlace)
            ExtraPlaces.internalBinaryWrite(message.extraPlace, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ParallelPrizeConf
 */
export const ParallelPrizeConf = new ParallelPrizeConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TierPrize$Type extends MessageType<TierPrize> {
    constructor() {
        super("bpt.match.common.v1.TierPrize", [
            { no: 1, name: "start", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "end", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "rankPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RankPrize }
        ]);
    }
    create(value?: PartialMessage<TierPrize>): TierPrize {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.start = 0;
        message.end = 0;
        message.rankPrizes = [];
        if (value !== undefined)
            reflectionMergePartial<TierPrize>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TierPrize): TierPrize {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 start */ 1:
                    message.start = reader.uint32();
                    break;
                case /* uint32 end */ 2:
                    message.end = reader.uint32();
                    break;
                case /* repeated bpt.match.common.v1.RankPrize rankPrizes */ 3:
                    message.rankPrizes.push(RankPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TierPrize, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 start = 1; */
        if (message.start !== 0)
            writer.tag(1, WireType.Varint).uint32(message.start);
        /* uint32 end = 2; */
        if (message.end !== 0)
            writer.tag(2, WireType.Varint).uint32(message.end);
        /* repeated bpt.match.common.v1.RankPrize rankPrizes = 3; */
        for (let i = 0; i < message.rankPrizes.length; i++)
            RankPrize.internalBinaryWrite(message.rankPrizes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.TierPrize
 */
export const TierPrize = new TierPrize$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RankPrize$Type extends MessageType<RankPrize> {
    constructor() {
        super("bpt.match.common.v1.RankPrize", [
            { no: 4, name: "rankRange", kind: "message", T: () => SectionRange },
            { no: 5, name: "tenThousandShare", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<RankPrize>): RankPrize {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tenThousandShare = 0;
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<RankPrize>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RankPrize): RankPrize {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.SectionRange rankRange */ 4:
                    message.rankRange = SectionRange.internalBinaryRead(reader, reader.uint32(), options, message.rankRange);
                    break;
                case /* uint32 tenThousandShare */ 5:
                    message.tenThousandShare = reader.uint32();
                    break;
                case /* repeated bpt.common.AssetItem assets */ 6:
                    message.assets.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RankPrize, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.SectionRange rankRange = 4; */
        if (message.rankRange)
            SectionRange.internalBinaryWrite(message.rankRange, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint32 tenThousandShare = 5; */
        if (message.tenThousandShare !== 0)
            writer.tag(5, WireType.Varint).uint32(message.tenThousandShare);
        /* repeated bpt.common.AssetItem assets = 6; */
        for (let i = 0; i < message.assets.length; i++)
            AssetItem.internalBinaryWrite(message.assets[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.RankPrize
 */
export const RankPrize = new RankPrize$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfInfoExt$Type extends MessageType<ConfInfoExt> {
    constructor() {
        super("bpt.match.common.v1.ConfInfoExt", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "showImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "showMobileImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "mobileShowImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "mobileShowImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "showImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "showImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "playerPerTable", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "entryConf", kind: "message", T: () => EntryConf },
            { no: 7, name: "cashConf", kind: "message", T: () => CashConf },
            { no: 8, name: "stageConf", kind: "message", T: () => StageConf },
            { no: 9, name: "playerNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n }
        ]);
    }
    create(value?: PartialMessage<ConfInfoExt>): ConfInfoExt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.showImg = "";
        message.showMobileImg = "";
        message.mobileShowImgList = "";
        message.mobileShowImgDetail = "";
        message.showImgList = "";
        message.showImgDetail = "";
        message.playerPerTable = 0;
        message.playerNum = 0;
        if (value !== undefined)
            reflectionMergePartial<ConfInfoExt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfInfoExt): ConfInfoExt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string showImg */ 3:
                    message.showImg = reader.string();
                    break;
                case /* string showMobileImg */ 4:
                    message.showMobileImg = reader.string();
                    break;
                case /* string mobileShowImgList */ 11:
                    message.mobileShowImgList = reader.string();
                    break;
                case /* string mobileShowImgDetail */ 12:
                    message.mobileShowImgDetail = reader.string();
                    break;
                case /* string showImgList */ 13:
                    message.showImgList = reader.string();
                    break;
                case /* string showImgDetail */ 14:
                    message.showImgDetail = reader.string();
                    break;
                case /* uint32 playerPerTable */ 5:
                    message.playerPerTable = reader.uint32();
                    break;
                case /* bpt.match.common.v1.EntryConf entryConf */ 6:
                    message.entryConf = EntryConf.internalBinaryRead(reader, reader.uint32(), options, message.entryConf);
                    break;
                case /* bpt.match.common.v1.CashConf cashConf */ 7:
                    message.cashConf = CashConf.internalBinaryRead(reader, reader.uint32(), options, message.cashConf);
                    break;
                case /* bpt.match.common.v1.StageConf stageConf */ 8:
                    message.stageConf = StageConf.internalBinaryRead(reader, reader.uint32(), options, message.stageConf);
                    break;
                case /* int64 playerNum */ 9:
                    message.playerNum = reader.int64().toNumber();
                    break;
                case /* bpt.common.I18n i18n = 10 [json_name = "i18n"];*/ 10:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfInfoExt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string showImg = 3; */
        if (message.showImg !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.showImg);
        /* string showMobileImg = 4; */
        if (message.showMobileImg !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.showMobileImg);
        /* string mobileShowImgList = 11; */
        if (message.mobileShowImgList !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.mobileShowImgList);
        /* string mobileShowImgDetail = 12; */
        if (message.mobileShowImgDetail !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.mobileShowImgDetail);
        /* string showImgList = 13; */
        if (message.showImgList !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.showImgList);
        /* string showImgDetail = 14; */
        if (message.showImgDetail !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.showImgDetail);
        /* uint32 playerPerTable = 5; */
        if (message.playerPerTable !== 0)
            writer.tag(5, WireType.Varint).uint32(message.playerPerTable);
        /* bpt.match.common.v1.EntryConf entryConf = 6; */
        if (message.entryConf)
            EntryConf.internalBinaryWrite(message.entryConf, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.CashConf cashConf = 7; */
        if (message.cashConf)
            CashConf.internalBinaryWrite(message.cashConf, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.StageConf stageConf = 8; */
        if (message.stageConf)
            StageConf.internalBinaryWrite(message.stageConf, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int64 playerNum = 9; */
        if (message.playerNum !== 0)
            writer.tag(9, WireType.Varint).int64(message.playerNum);
        /* bpt.common.I18n i18n = 10 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ConfInfoExt
 */
export const ConfInfoExt = new ConfInfoExt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FixedPrizeConf$Type extends MessageType<FixedPrizeConf> {
    constructor() {
        super("bpt.match.common.v1.FixedPrizeConf", [
            { no: 1, name: "rangePrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RangePrize }
        ]);
    }
    create(value?: PartialMessage<FixedPrizeConf>): FixedPrizeConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rangePrizes = [];
        if (value !== undefined)
            reflectionMergePartial<FixedPrizeConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FixedPrizeConf): FixedPrizeConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.RangePrize rangePrizes */ 1:
                    message.rangePrizes.push(RangePrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FixedPrizeConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.RangePrize rangePrizes = 1; */
        for (let i = 0; i < message.rangePrizes.length; i++)
            RangePrize.internalBinaryWrite(message.rangePrizes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.FixedPrizeConf
 */
export const FixedPrizeConf = new FixedPrizeConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RangePrize$Type extends MessageType<RangePrize> {
    constructor() {
        super("bpt.match.common.v1.RangePrize", [
            { no: 1, name: "startRank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "endRank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<RangePrize>): RangePrize {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startRank = 0;
        message.endRank = 0;
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<RangePrize>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RangePrize): RangePrize {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 startRank */ 1:
                    message.startRank = reader.uint32();
                    break;
                case /* uint32 endRank */ 2:
                    message.endRank = reader.uint32();
                    break;
                case /* repeated bpt.common.AssetItem assets */ 3:
                    message.assets.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RangePrize, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 startRank = 1; */
        if (message.startRank !== 0)
            writer.tag(1, WireType.Varint).uint32(message.startRank);
        /* uint32 endRank = 2; */
        if (message.endRank !== 0)
            writer.tag(2, WireType.Varint).uint32(message.endRank);
        /* repeated bpt.common.AssetItem assets = 3; */
        for (let i = 0; i < message.assets.length; i++)
            AssetItem.internalBinaryWrite(message.assets[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.RangePrize
 */
export const RangePrize = new RangePrize$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SpinAndGoPrizeConf$Type extends MessageType<SpinAndGoPrizeConf> {
    constructor() {
        super("bpt.match.common.v1.SpinAndGoPrizeConf", [
            { no: 1, name: "rewardAsset", kind: "message", T: () => AssetItem },
            { no: 2, name: "probabilitySheet", kind: "message", T: () => ProbabilitySheet }
        ]);
    }
    create(value?: PartialMessage<SpinAndGoPrizeConf>): SpinAndGoPrizeConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SpinAndGoPrizeConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SpinAndGoPrizeConf): SpinAndGoPrizeConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.AssetItem rewardAsset */ 1:
                    message.rewardAsset = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.rewardAsset);
                    break;
                case /* bpt.match.common.v1.ProbabilitySheet probabilitySheet */ 2:
                    message.probabilitySheet = ProbabilitySheet.internalBinaryRead(reader, reader.uint32(), options, message.probabilitySheet);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SpinAndGoPrizeConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.AssetItem rewardAsset = 1; */
        if (message.rewardAsset)
            AssetItem.internalBinaryWrite(message.rewardAsset, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ProbabilitySheet probabilitySheet = 2; */
        if (message.probabilitySheet)
            ProbabilitySheet.internalBinaryWrite(message.probabilitySheet, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.SpinAndGoPrizeConf
 */
export const SpinAndGoPrizeConf = new SpinAndGoPrizeConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProbabilitySheet$Type extends MessageType<ProbabilitySheet> {
    constructor() {
        super("bpt.match.common.v1.ProbabilitySheet", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProbabilityItem }
        ]);
    }
    create(value?: PartialMessage<ProbabilitySheet>): ProbabilitySheet {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ProbabilitySheet>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProbabilitySheet): ProbabilitySheet {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.ProbabilityItem items */ 1:
                    message.items.push(ProbabilityItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProbabilitySheet, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.ProbabilityItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            ProbabilityItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ProbabilitySheet
 */
export const ProbabilitySheet = new ProbabilitySheet$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProbabilityItem$Type extends MessageType<ProbabilityItem> {
    constructor() {
        super("bpt.match.common.v1.ProbabilityItem", [
            { no: 1, name: "probability", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "multipler", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ProbabilityItem>): ProbabilityItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.probability = 0;
        message.multipler = 0;
        if (value !== undefined)
            reflectionMergePartial<ProbabilityItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProbabilityItem): ProbabilityItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 probability */ 1:
                    message.probability = reader.uint32();
                    break;
                case /* uint32 multipler */ 2:
                    message.multipler = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProbabilityItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 probability = 1; */
        if (message.probability !== 0)
            writer.tag(1, WireType.Varint).uint32(message.probability);
        /* uint32 multipler = 2; */
        if (message.multipler !== 0)
            writer.tag(2, WireType.Varint).uint32(message.multipler);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.ProbabilityItem
 */
export const ProbabilityItem = new ProbabilityItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashConf$Type extends MessageType<CashConf> {
    constructor() {
        super("bpt.match.common.v1.CashConf", [
            { no: 1, name: "LowerLimitChips", kind: "scalar", localName: "LowerLimitChips", jsonName: "LowerLimitChips", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "UpperLimitChips", kind: "scalar", localName: "UpperLimitChips", jsonName: "UpperLimitChips", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "subMatchType", kind: "enum", T: () => ["bpt.match.common.v1.SubMatchType", SubMatchType] },
            { no: 4, name: "token", kind: "message", T: () => AssetItem },
            { no: 5, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "tableNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "minTableNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "createTableMode", kind: "enum", T: () => ["bpt.match.common.v1.CreateTableMode", CreateTableMode] },
            { no: 9, name: "reserveTableNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "entryFee", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CashConf>): CashConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.LowerLimitChips = 0;
        message.UpperLimitChips = 0;
        message.subMatchType = 0;
        message.chips = 0;
        message.tableNum = 0;
        message.minTableNum = 0;
        message.createTableMode = 0;
        message.reserveTableNum = 0;
        message.entryFee = 0;
        if (value !== undefined)
            reflectionMergePartial<CashConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashConf): CashConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 LowerLimitChips = 1 [json_name = "LowerLimitChips"];*/ 1:
                    message.LowerLimitChips = reader.uint64().toNumber();
                    break;
                case /* uint64 UpperLimitChips = 2 [json_name = "UpperLimitChips"];*/ 2:
                    message.UpperLimitChips = reader.uint64().toNumber();
                    break;
                case /* bpt.match.common.v1.SubMatchType subMatchType */ 3:
                    message.subMatchType = reader.int32();
                    break;
                case /* bpt.common.AssetItem token */ 4:
                    message.token = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.token);
                    break;
                case /* uint64 chips */ 5:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* uint32 tableNum */ 6:
                    message.tableNum = reader.uint32();
                    break;
                case /* uint32 minTableNum */ 7:
                    message.minTableNum = reader.uint32();
                    break;
                case /* bpt.match.common.v1.CreateTableMode createTableMode */ 8:
                    message.createTableMode = reader.int32();
                    break;
                case /* uint32 reserveTableNum */ 9:
                    message.reserveTableNum = reader.uint32();
                    break;
                case /* uint64 entryFee */ 10:
                    message.entryFee = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 LowerLimitChips = 1 [json_name = "LowerLimitChips"]; */
        if (message.LowerLimitChips !== 0)
            writer.tag(1, WireType.Varint).uint64(message.LowerLimitChips);
        /* uint64 UpperLimitChips = 2 [json_name = "UpperLimitChips"]; */
        if (message.UpperLimitChips !== 0)
            writer.tag(2, WireType.Varint).uint64(message.UpperLimitChips);
        /* bpt.match.common.v1.SubMatchType subMatchType = 3; */
        if (message.subMatchType !== 0)
            writer.tag(3, WireType.Varint).int32(message.subMatchType);
        /* bpt.common.AssetItem token = 4; */
        if (message.token)
            AssetItem.internalBinaryWrite(message.token, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* uint64 chips = 5; */
        if (message.chips !== 0)
            writer.tag(5, WireType.Varint).uint64(message.chips);
        /* uint32 tableNum = 6; */
        if (message.tableNum !== 0)
            writer.tag(6, WireType.Varint).uint32(message.tableNum);
        /* uint32 minTableNum = 7; */
        if (message.minTableNum !== 0)
            writer.tag(7, WireType.Varint).uint32(message.minTableNum);
        /* bpt.match.common.v1.CreateTableMode createTableMode = 8; */
        if (message.createTableMode !== 0)
            writer.tag(8, WireType.Varint).int32(message.createTableMode);
        /* uint32 reserveTableNum = 9; */
        if (message.reserveTableNum !== 0)
            writer.tag(9, WireType.Varint).uint32(message.reserveTableNum);
        /* uint64 entryFee = 10; */
        if (message.entryFee !== 0)
            writer.tag(10, WireType.Varint).uint64(message.entryFee);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.CashConf
 */
export const CashConf = new CashConf$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashMatchConf$Type extends MessageType<CashMatchConf> {
    constructor() {
        super("bpt.match.common.v1.CashMatchConf", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 3, name: "rule", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "playerPerTable", kind: "scalar", T: 13 /*ScalarType.UINT32*/, options: { "validate.rules": { uint32: { lte: 9, gte: 2 } } } },
            { no: 5, name: "level", kind: "message", T: () => BlindLevel },
            { no: 6, name: "anteType", kind: "enum", T: () => ["bpt.common.AnteType", AnteType] },
            { no: 7, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.State", State] },
            { no: 8, name: "lastUpdate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "cashConf", kind: "message", T: () => CashConf },
            { no: 11, name: "showPriority", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 12, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1" } } } },
            { no: 13, name: "serviceConf", kind: "message", T: () => ServiceConf },
            { no: 14, name: "matchOwner", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CashMatchConf>): CashMatchConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.name = "";
        message.rule = "";
        message.playerPerTable = 0;
        message.anteType = 0;
        message.state = 0;
        message.lastUpdate = 0;
        message.operator = "";
        message.showPriority = 0;
        message.tag = "";
        message.matchOwner = 0;
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<CashMatchConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashMatchConf): CashMatchConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string rule */ 3:
                    message.rule = reader.string();
                    break;
                case /* uint32 playerPerTable */ 4:
                    message.playerPerTable = reader.uint32();
                    break;
                case /* bpt.match.common.v1.BlindLevel level */ 5:
                    message.level = BlindLevel.internalBinaryRead(reader, reader.uint32(), options, message.level);
                    break;
                case /* bpt.common.AnteType anteType */ 6:
                    message.anteType = reader.int32();
                    break;
                case /* bpt.match.common.v1.State state */ 7:
                    message.state = reader.int32();
                    break;
                case /* int64 lastUpdate */ 8:
                    message.lastUpdate = reader.int64().toNumber();
                    break;
                case /* string operator */ 9:
                    message.operator = reader.string();
                    break;
                case /* bpt.match.common.v1.CashConf cashConf */ 10:
                    message.cashConf = CashConf.internalBinaryRead(reader, reader.uint32(), options, message.cashConf);
                    break;
                case /* int32 showPriority */ 11:
                    message.showPriority = reader.int32();
                    break;
                case /* string tag */ 12:
                    message.tag = reader.string();
                    break;
                case /* bpt.match.common.v1.ServiceConf serviceConf */ 13:
                    message.serviceConf = ServiceConf.internalBinaryRead(reader, reader.uint32(), options, message.serviceConf);
                    break;
                case /* int64 matchOwner */ 14:
                    message.matchOwner = reader.int64().toNumber();
                    break;
                case /* int64 id */ 15:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashMatchConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string rule = 3; */
        if (message.rule !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rule);
        /* uint32 playerPerTable = 4; */
        if (message.playerPerTable !== 0)
            writer.tag(4, WireType.Varint).uint32(message.playerPerTable);
        /* bpt.match.common.v1.BlindLevel level = 5; */
        if (message.level)
            BlindLevel.internalBinaryWrite(message.level, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AnteType anteType = 6; */
        if (message.anteType !== 0)
            writer.tag(6, WireType.Varint).int32(message.anteType);
        /* bpt.match.common.v1.State state = 7; */
        if (message.state !== 0)
            writer.tag(7, WireType.Varint).int32(message.state);
        /* int64 lastUpdate = 8; */
        if (message.lastUpdate !== 0)
            writer.tag(8, WireType.Varint).int64(message.lastUpdate);
        /* string operator = 9; */
        if (message.operator !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.operator);
        /* bpt.match.common.v1.CashConf cashConf = 10; */
        if (message.cashConf)
            CashConf.internalBinaryWrite(message.cashConf, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int32 showPriority = 11; */
        if (message.showPriority !== 0)
            writer.tag(11, WireType.Varint).int32(message.showPriority);
        /* string tag = 12; */
        if (message.tag !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.tag);
        /* bpt.match.common.v1.ServiceConf serviceConf = 13; */
        if (message.serviceConf)
            ServiceConf.internalBinaryWrite(message.serviceConf, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* int64 matchOwner = 14; */
        if (message.matchOwner !== 0)
            writer.tag(14, WireType.Varint).int64(message.matchOwner);
        /* int64 id = 15; */
        if (message.id !== 0)
            writer.tag(15, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.common.v1.CashMatchConf
 */
export const CashMatchConf = new CashMatchConf$Type();
