import { AssetItem } from '@/grpc/client/bpt/common/common_define';
import { Prop } from '@/grpc/client/bpt/hall/prop/v1/prop';
import { User } from '@/grpc/client/bpt/hall/userinfo/v1/userinfo';

import { MatchListType } from '@/grpc/client/bpt/match/common/v1/common';
import {
  GetIndexRankingResp,
  PrizeUser,
} from '@/grpc/client/bpt/match/matchhub/v1/matchhub';
import { TokenType } from '@/grpc/client/gaw/asset/wallet/v1/token';
import {
  batchGetProps,
  batchGetUser,
  listUserPrizeRanks,
} from '@/services/api';
import { formatNumWithComma } from '@/utils/string';
import { uniq } from 'lodash-es';
import { useEffect, useState } from 'react';
import { formatUnits } from 'viem';

export enum MatchPropType {
  ERC20 = 1,
  NFT_TICKET = 2,
}
const RankingModel = () => {
  const [matchListType, setMatchListType] = useState<
    MatchListType.MatchListTypeDaily | MatchListType.MatchListTypeWeekly
  >(MatchListType.MatchListTypeWeekly);

  const [userInfoMap, setUserInfoMap] = useState<Map<number, User>>(new Map());
  const [propsMap, setPropsMap] = useState<Map<number, Prop>>(new Map());

  const [dailyPrizeRank, setDailyPrizeRank] = useState<GetIndexRankingResp>({
    prizeUsers: [],
    matchKey: '',
  });
  const [weeklyPrizeRank, setWeeklyPrizeRank] = useState<GetIndexRankingResp>({
    prizeUsers: [],
    matchKey: '',
  });

  function getMatchPrize(rawAmount: string, decimals: number, isFormat = true) {
    if (!rawAmount) return 0;
    if (!decimals) return 0;

    const result = formatUnits(BigInt(rawAmount), decimals);
    const value = Number(result);
    if (isFormat) {
      return formatNumWithComma(value, 4);
    }
    return value;
  }

  const transformProps = (prop: Prop, rawAmount: string) => {
    let data: any = {};
    if (prop?.erc20info) {
      data = {
        rawAmount: rawAmount,
        source: prop.erc20info,
        symbol: prop.erc20info.symbol,
        logo: prop.erc20info.logo,
        decimals: prop.erc20info.decimals,
        tokenType: !prop?.erc20info?.contractAddress
          ? TokenType.TokenTypeNative
          : TokenType.TokenTypeERC20,
      };
    }
    if (prop?.nftInfo) {
      data = {
        rawAmount: rawAmount,
        source: prop.nftInfo,
        propType: MatchPropType.NFT_TICKET,
        logo: prop.nftInfo.logo,
        i18n: prop.base?.i18nName,
      };
    }
    data.displayAmount = getMatchPrize(
      data.rawAmount,
      data.source.decimals,
      data.source.formatAmount,
    );

    return data;
  };

  useEffect(() => {
    fetchUserPrizeRanksList(MatchListType.MatchListTypeDaily);
    fetchUserPrizeRanksList(MatchListType.MatchListTypeWeekly);
  }, [matchListType]);

  const fetchUserPrizeRanksList = async (matchListType: MatchListType) => {
    const resp = await listUserPrizeRanks({ matchListType });
    const prizeUsers = resp?.prizeUsers || [];
    const propIds: number[] = [];

    prizeUsers.forEach((user: PrizeUser) =>
      user.prizes.forEach((pr: AssetItem) => {
        propIds.push(pr.id);
      }),
    );

    const props = await batchGetProps(uniq(propIds));

    props.forEach((prop) => {
      setPropsMap(propsMap.set(prop.id, prop));
    });

    const uidList = prizeUsers.map((r: PrizeUser) => r.Uid);
    await loadUsersList(uidList);

    if (matchListType === MatchListType.MatchListTypeDaily) {
      setDailyPrizeRank({
        matchKey: resp?.matchKey || '',
        prizeUsers,
        i18n: resp?.i18n,
      });
    } else if (matchListType === MatchListType.MatchListTypeWeekly) {
      setWeeklyPrizeRank({
        matchKey: resp?.matchKey || '',
        prizeUsers,
        i18n: resp?.i18n,
      });
    }
  };

  const loadUsersList = async (uidList: number[]) => {
    // 去重后的 用户uid 集合
    const uniqueUidList = [...new Set(uidList)];
    const needQueryIds = uniqueUidList.filter((uid) => !userInfoMap.has(uid));
    if (needQueryIds.length <= 0) return;
    // 去重后的 用户uid 集合，最多200个
    const resultList: User[] = (await batchGetUser(needQueryIds)) as User[];
    resultList.map((user: User) => {
      if (user) {
        setUserInfoMap(userInfoMap.set(user.uid, user));
      }
    });
  };

  return {
    setMatchListType,
    dailyPrizeRank,
    weeklyPrizeRank,
    userInfoMap,
    transformProps,
    propsMap,
  };
};

export default RankingModel;
