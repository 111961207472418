import {
  GetIndexRankingReq,
  GetIndexRankingResp,
} from '@/grpc/client/bpt/match/matchhub/v1/matchhub';
import { MatchHubServiceClient } from '@/grpc/client/bpt/match/matchhub/v1/matchhub.client';
import { getApiPrefix } from '@/utils/api-prefix';
import { GrpcWebFetchTransport } from '@mtt/grpc-core';

export const createNoAuthTransport = () => {
  return new GrpcWebFetchTransport({
    format: 'binary',
    baseUrl: `${getApiPrefix()}/matchhub_noauth/`,
  });
};

class MatchHubMgr {
  private noAuthClient: MatchHubServiceClient;

  constructor() {
    this.noAuthClient = new MatchHubServiceClient(createNoAuthTransport());
  }

  /** 首页排行榜 */
  getIndexRanking(req: GetIndexRankingReq): Promise<GetIndexRankingResp> {
    return this.noAuthClient.getIndexRanking(GetIndexRankingReq.create(req))
      .response;
  }
}

export const matchHubMgr = new MatchHubMgr();
