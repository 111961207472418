// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/match/mttmatch/v1/mttmatch.proto" (package "bpt.match.mttmatch.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { TableStateChangeResp } from "../../../common/common";
import { MsgTableStateChange } from "../../../common/common";
import { TableOpResp } from "../../../common/common";
import { TableOpReq } from "../../../common/common";
import { TableInfoResp } from "../../../common/common";
import { TableInfoReq } from "../../../common/common";
import { RoundEndResp } from "../../../common/common";
import { RoundEndReq } from "../../../common/common";
import { RoundResultResp } from "../../../common/common";
import { RoundResultReq } from "../../../common/common";
import { PingMatchResp } from "../../../common/common";
import { PingMatchReq } from "../../../common/common";
import { Void } from "../../../base/base";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ChargeEvent } from "../../common/v1/common";
import { AssetItem } from "../../../common/common_define";
import { Desk } from "../../common/v1/common";
import { StageType } from "../../common/v1/common";
import { GameOverType } from "../../common/v1/common";
import { Ticket } from "../../common/v1/common";
import { RegisterWay } from "../../common/v1/common";
import { RegisterUser } from "../../common/v1/common";
import { MatchContext } from "../../common/v1/common";
import { DeskStatus } from "../../common/v1/common";
import { User } from "../../common/v1/common";
import { UserStatus } from "../../common/v1/common";
import { MyTable } from "../../common/v1/common";
import { MatchStatus } from "../../common/v1/common";
import { MatchConf } from "../../common/v1/common";
import { Match } from "../../common/v1/common";
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.TxInfo
 */
export interface TxInfo {
    /**
     * succeed true代表走上链流程
     *
     * @generated from protobuf field: bool succeed = 1;
     */
    succeed: boolean;
    /**
     * 唤起支付的凭据 (生成二维码给 GAW 或传给托管钱包付款)
     *
     * @generated from protobuf field: bytes credential = 2;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 3;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.RestoreMatchResp
 */
export interface RestoreMatchResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 1;
     */
    match?: Match;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf cfg = 2;
     */
    cfg?: MatchConf;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetMatchReq
 */
export interface GetMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetMatchResp
 */
export interface GetMatchResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 1;
     */
    match?: Match;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MatchStateInfo
 */
export interface MatchStateInfo {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus state = 3;
     */
    state: MatchStatus;
    /**
     * @generated from protobuf field: int64 confID = 5;
     */
    confID: number;
    /**
     * @generated from protobuf field: bool isRegister = 6;
     */
    isRegister: boolean; // 当前用户是否已报名
    /**
     * @generated from protobuf field: int64 startedAt = 9;
     */
    startedAt: number; // 比赛开始时间戳，单位秒
    /**
     * @generated from protobuf field: int64 endAt = 10;
     */
    endAt: number;
    /**
     * @generated from protobuf field: uint32 blindLevel = 11;
     */
    blindLevel: number; // deprecated 使用blindinfo的level
    /**
     * @generated from protobuf field: bool isBubbleTime = 12;
     */
    isBubbleTime: boolean;
    /**
     * @generated from protobuf field: bool isDelayRegister = 13;
     */
    isDelayRegister: boolean; // 是否能够延迟报名
    /**
     * @generated from protobuf field: int64 registerDeadline = 26;
     */
    registerDeadline: number; // 延迟报名时间
    /**
     * @generated from protobuf field: int64 waitDelayRegisterDeadline = 42;
     */
    waitDelayRegisterDeadline: number; // 剩下1人时，等待延时用户进场时间
    /**
     * @generated from protobuf field: string matchKey = 15;
     */
    matchKey: string; // 全局唯一标记
    /**
     * @generated from protobuf field: int64 finalTableID = 16;
     */
    finalTableID: number;
    /**
     * @generated from protobuf field: bool isBreakTime = 18;
     */
    isBreakTime: boolean; // deprecated 是否在休息时间,使用blindInfo的hasbreaktime 和时间范围判断
    /**
     * @generated from protobuf field: int64 userCnt = 21;
     */
    userCnt: number; // 报名用户
    /**
     * @generated from protobuf field: int64 srvID = 22;
     */
    srvID: number; // 服务id
    /**
     * @generated from protobuf field: uint32 myRank = 23;
     */
    myRank: number; // 我的排名
    /**
     * @generated from protobuf field: uint32 avgChip = 24;
     */
    avgChip: number; // 平均筹码
    /**
     * @generated from protobuf field: uint32 smallestStack = 50;
     */
    smallestStack: number; // 最小选手分数
    /**
     * @generated from protobuf field: uint32 largestStack = 51;
     */
    largestStack: number; // 最小选手分数
    /**
     * @generated from protobuf field: uint32 playingNum = 25;
     */
    playingNum: number; // 当前在玩用户（未淘汰）
    /**
     * @generated from protobuf field: bool isPrizeRound = 27;
     */
    isPrizeRound: boolean; // 是否钱圈
    /**
     * @generated from protobuf field: bool isAbleEnter = 28;
     */
    isAbleEnter: boolean; // 是否能报名，（钱圈，泡沫圈，过了延时报名时间,finaltable,开桌只有一桌，人满 都不能进入）
    /**
     * @generated from protobuf field: bpt.match.common.v1.MyTable myTable = 29;
     */
    myTable?: MyTable; // 我的桌子
    /**
     * @generated from protobuf field: int64 breakTimeStopAt = 31;
     */
    breakTimeStopAt: number;
    /**
     * @generated from protobuf field: uint32 rollBackBlindLevel = 32;
     */
    rollBackBlindLevel: number; // 发生回滚时的盲注等级，不发生值为0
    /**
     * @generated from protobuf field: uint32 startBlindLevel = 40;
     */
    startBlindLevel: number; // 开始盲注界别，对于多阶段比赛，开始盲注级别可能不为1
    /**
     * @generated from protobuf field: int64 registrationAt = 41;
     */
    registrationAt: number; // 比赛报名时间戳，单位秒
    /**
     * @generated from protobuf field: int64 pauseEndAt = 44;
     */
    pauseEndAt: number; // 当值为0时，未暂停，>0时，为暂停的截止时间
    /**
     * @generated from protobuf field: int64 pauseBeginAt = 45;
     */
    pauseBeginAt: number; // 暂停开始的时间
    /**
     * 当前已经暂停的总时长（单位：秒）不包括当前暂停（从pauseEndAt到now()）
     *
     * @generated from protobuf field: int32 elapsedPauseSeconds = 46;
     */
    elapsedPauseSeconds: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetMatchStateInfoReq
 */
export interface GetMatchStateInfoReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetMatchStateInfoResp
 */
export interface GetMatchStateInfoResp {
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.MatchStateInfo stateInfo = 1;
     */
    stateInfo?: MatchStateInfo;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.JoinMatchReq
 */
export interface JoinMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.JoinMatchResp
 */
export interface JoinMatchResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 1;
     */
    match?: Match;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.RestoreMatchReq
 */
export interface RestoreMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ExceptionTable
 */
export interface ExceptionTable {
    /**
     * @generated from protobuf field: int64 srvID = 1;
     */
    srvID: number; // 指定恢复到某个服务器，不指定的话由服务器指定
    /**
     * @generated from protobuf field: string tableKey = 2;
     */
    tableKey: string; // 指定恢复的tableKey
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.RestoreMatchTablesReq
 */
export interface RestoreMatchTablesReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.ExceptionTable tables = 2;
     */
    tables: ExceptionTable[]; // 指定桌子恢复
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.RestoreMode mode = 3;
     */
    mode: RestoreMode;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.RestoreFailedTable
 */
export interface RestoreFailedTable {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string; // tableKey
    /**
     * @generated from protobuf field: string failedMsg = 2;
     */
    failedMsg: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.RestoreMatchTablesResp
 */
export interface RestoreMatchTablesResp {
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.RestoreFailedTable failedTables = 1;
     */
    failedTables: RestoreFailedTable[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.RestoreByServerIDReq
 */
export interface RestoreByServerIDReq {
    /**
     * @generated from protobuf field: int64 srvID = 1;
     */
    srvID: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MatchOverViewReq
 */
export interface MatchOverViewReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MatchOverViewResp
 */
export interface MatchOverViewResp {
    /**
     * @generated from protobuf field: int64 addOnCnt = 1;
     */
    addOnCnt: number; // 总手数
    /**
     * @generated from protobuf field: int64 addOnPlayerCnt = 2;
     */
    addOnPlayerCnt: number; // 总人次
    /**
     * @generated from protobuf field: int64 reBuyCnt = 3;
     */
    reBuyCnt: number; // reBuy次数
    /**
     * @generated from protobuf field: int64 reEntryCnt = 4;
     */
    reEntryCnt: number; // reEntry次数
    /**
     * @generated from protobuf field: int64 remainPlayer = 5;
     */
    remainPlayer: number; // 剩余玩家
    /**
     * @generated from protobuf field: int64 totalPlayer = 6;
     */
    totalPlayer: number; // 总玩家
    /**
     * @generated from protobuf field: int64 avgScore = 7;
     */
    avgScore: number; // 剩余选手平均分
    /**
     * @generated from protobuf field: int32 placePaid = 8;
     */
    placePaid: number; // 获奖人数（如果是动态奖池根据报名人数动态变化）
    /**
     * @generated from protobuf field: int64 reBuyPlayerCnt = 9;
     */
    reBuyPlayerCnt: number; // reBuy人数
    /**
     * @generated from protobuf field: int64 reEntryPlayerCnt = 10;
     */
    reEntryPlayerCnt: number; // reEntry人数
    /**
     * 是否有奖圈，如在多日赛中，day1存在多场的情况下，需要多场打完才有排名，这种情况没有奖圈
     *
     * @generated from protobuf field: bool hasPrizeRound = 11;
     */
    hasPrizeRound: boolean;
    /**
     * @generated from protobuf field: int64 smallestStack = 12;
     */
    smallestStack: number; // 最小选手分数
    /**
     * @generated from protobuf field: int64 largestStack = 13;
     */
    largestStack: number; // 最小选手分数
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ListRankReq
 */
export interface ListRankReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.UserRankInfo
 */
export interface UserRankInfo {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint32 rank = 2;
     */
    rank: number;
    /**
     * @generated from protobuf field: uint64 chips = 3;
     */
    chips: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.UserStatus status = 4;
     */
    status: UserStatus;
    /**
     * @generated from protobuf field: string PrizeRawAmount = 5 [json_name = "PrizeRawAmount"];
     */
    PrizeRawAmount: string;
    /**
     * rebuy 总次数
     *
     * @generated from protobuf field: uint32 reBuyCnt = 6;
     */
    reBuyCnt: number;
    /**
     * addon 总次数
     *
     * @generated from protobuf field: uint32 addOnCnt = 7;
     */
    addOnCnt: number;
    /**
     * reentry 总次数
     *
     * @generated from protobuf field: uint32 reEntryCnt = 8;
     */
    reEntryCnt: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ListRankResp
 */
export interface ListRankResp {
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.UserRankInfo rankInfos = 1;
     */
    rankInfos: UserRankInfo[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ListDesksReq
 */
export interface ListDesksReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.SeatInfo
 */
export interface SeatInfo {
    /**
     * @generated from protobuf field: uint32 seatNo = 1;
     */
    seatNo: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint64 chips = 3;
     */
    chips: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.DeskInfo
 */
export interface DeskInfo {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 srvID = 2;
     */
    srvID: number;
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.SeatInfo seatInfos = 3;
     */
    seatInfos: SeatInfo[];
    /**
     * @generated from protobuf field: uint32 dealerSeat = 4;
     */
    dealerSeat: number;
    /**
     * @generated from protobuf field: int64 tableID = 5;
     */
    tableID: number;
    /**
     * @generated from protobuf field: int64 smStack = 6;
     */
    smStack: number;
    /**
     * @generated from protobuf field: int64 lgStack = 7;
     */
    lgStack: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ListDesksResp
 */
export interface ListDesksResp {
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.DeskInfo deskInfos = 1;
     */
    deskInfos: DeskInfo[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.DumpReq
 */
export interface DumpReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetNewestMatchReq
 */
export interface GetNewestMatchReq {
    /**
     * @generated from protobuf field: int64 confID = 1;
     */
    confID: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetNewestMatchResp
 */
export interface GetNewestMatchResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 1;
     */
    match?: Match;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetMatchReq
 */
export interface BatchGetMatchReq {
    /**
     * @generated from protobuf field: repeated string matchKeys = 2;
     */
    matchKeys: string[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetMatchResp
 */
export interface BatchGetMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Match matches = 1;
     */
    matches: Match[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetUserReq
 */
export interface BatchGetUserReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetUserResp
 */
export interface BatchGetUserResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.User users = 1;
     */
    users: User[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ObserveTableReq
 */
export interface ObserveTableReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string; // 必填
    /**
     * 选填，首选tablekey，如果首选tablekey未销毁，返回该tablekey,首选tablekey的桌子已销毁或不指定，后端返回可用的
     * 调用时，可能比赛已结束,返回CodeMatchFinish
     *
     * @generated from protobuf field: string preferredTableKey = 2;
     */
    preferredTableKey: string; // seleceMode =0
    /**
     * SelectModeForward 找preferredTableKey的下一张桌子，当仅剩一张桌子时，没有变化
     * SelectModeForward 找preferredTableKey的前一张桌子，当仅剩一张桌子时，没有变化
     *
     * @generated from protobuf field: bpt.match.mttmatch.v1.SelectMode mode = 3;
     */
    mode: SelectMode;
    /**
     * @generated from protobuf field: string currentTableKey = 4;
     */
    currentTableKey: string; // seleceMode =1或2时传入
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ObserveTableResp
 */
export interface ObserveTableResp {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string; // 优先返回首选tablekey,首选tablekey被销毁情况，返回一个
    /**
     * @generated from protobuf field: int64 tableSrvID = 2;
     */
    tableSrvID: number;
    /**
     * @generated from protobuf field: int32 remainTableCnt = 3;
     */
    remainTableCnt: number;
}
/**
 * 在泡沫时候围观
 * 泡沫围观只会在在打roundresult/roundend之前的状态（翻前/翻转河）的桌子里面返回
 * 不包括自己的桌子
 *
 * @generated from protobuf message bpt.match.mttmatch.v1.ObserveTableOnBubbleReq
 */
export interface ObserveTableOnBubbleReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string; // 必填
    /**
     * SelectModeForward 找preferredTableKey的下一张桌子，当仅剩一张桌子时，没有变化
     * SelectModeForward 找preferredTableKey的前一张桌子，当仅剩一张桌子时，没有变化
     *
     * @generated from protobuf field: bpt.match.mttmatch.v1.SelectMode mode = 3;
     */
    mode: SelectMode; // mode =0  时，随机一张在打桌子
    /**
     * @generated from protobuf field: string currentTableKey = 4;
     */
    currentTableKey: string; // seleceMode =1或2时必填
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ObserveTableOnBubbleResp
 */
export interface ObserveTableOnBubbleResp {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string; // 优先返回首选tablekey,首选tablekey被销毁情况，返回一个
    /**
     * @generated from protobuf field: int64 tableSrvID = 2;
     */
    tableSrvID: number;
    /**
     * @generated from protobuf field: int32 remainTableCnt = 3;
     */
    remainTableCnt: number; // 在打桌子数量，不包括自己的桌子
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetMyTableReq
 */
export interface GetMyTableReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.TableInfo
 */
export interface TableInfo {
    /**
     * @generated from protobuf field: int64 srvID = 1;
     */
    srvID: number;
    /**
     * @generated from protobuf field: string tableKey = 2;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.DeskStatus deskStatus = 3;
     */
    deskStatus: DeskStatus;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetMyTableResp
 */
export interface GetMyTableResp {
    /**
     * 玩家在打的桌子信息，如果玩家淘汰了，返回nil
     *
     * @generated from protobuf field: bpt.match.mttmatch.v1.TableInfo playingTable = 1;
     */
    playingTable?: TableInfo;
    /**
     * 玩家围观的桌子信息，如果没有围观任何桌子，返回nil
     *
     * @generated from protobuf field: bpt.match.mttmatch.v1.TableInfo observingTable = 2;
     */
    observingTable?: TableInfo;
    /**
     * 可围观的桌子数量
     * 如果在打= 未销毁桌子减1
     * 围观中= 所有未销毁桌子
     *
     * @generated from protobuf field: int32 observableTableCnt = 3;
     */
    observableTableCnt: number;
    /**
     * 泡沫可围观的桌子数（roundResult之前 即比牌前的桌子数）
     *
     * @generated from protobuf field: int32 bubbleObservableTableCnt = 4;
     */
    bubbleObservableTableCnt: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetMatchAndUserStatusReq
 */
export interface BatchGetMatchAndUserStatusReq {
    /**
     * @generated from protobuf field: repeated string matchKeys = 1;
     */
    matchKeys: string[];
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MatchAndUserStatus
 */
export interface MatchAndUserStatus {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus matchStatus = 1;
     */
    matchStatus: MatchStatus;
    /**
     * @generated from protobuf field: bpt.match.common.v1.UserStatus userStatus = 2;
     */
    userStatus: UserStatus;
    /**
     * @generated from protobuf field: bool isRegister = 3;
     */
    isRegister: boolean;
    /**
     * 其他比赛状态为false
     *
     * @generated from protobuf field: bool canEnter = 4;
     */
    canEnter: boolean;
    /**
     * @generated from protobuf field: string matchKey = 5;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 pauseBeginAt = 7;
     */
    pauseBeginAt: number;
    /**
     * @generated from protobuf field: int64 pauseEndAt = 8;
     */
    pauseEndAt: number;
    /**
     * @generated from protobuf field: int64 userCnt = 9;
     */
    userCnt: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetMatchAndUserStatusResp
 */
export interface BatchGetMatchAndUserStatusResp {
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.MatchAndUserStatus matchAndUserStatus = 1;
     */
    matchAndUserStatus: MatchAndUserStatus[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.UseShuffleCardReq
 */
export interface UseShuffleCardReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string text = 2;
     */
    text: string;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.PauseMatchReq
 */
export interface PauseMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 pauseDeadline = 2;
     */
    pauseDeadline: number; // deprecated
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ResumeMatchReq
 */
export interface ResumeMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.UpdateMatchReq
 */
export interface UpdateMatchReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 1;
     */
    match?: Match;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.DeleteMatchReq
 */
export interface DeleteMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CancelMatchReq
 */
export interface CancelMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.StopMatchReq
 */
export interface StopMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CancelMatchResp
 */
export interface CancelMatchResp {
    /**
     * @generated from protobuf field: repeated string successTS = 6;
     */
    successTS: string[]; // 赛中处理成功的交易
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 7;
     */
    match?: Match;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchContext matchPoolContext = 8;
     */
    matchPoolContext?: MatchContext;
    /**
     * @generated from protobuf field: bool isMatchOver = 9;
     */
    isMatchOver: boolean;
    /**
     * @generated from protobuf field: int64 finalTableUserNum = 10;
     */
    finalTableUserNum: number; // 进入FT用户
    /**
     * @generated from protobuf field: bool isHeadUp = 12;
     */
    isHeadUp: boolean; // 是否触发二人对决
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.DelayRegisterReq
 */
export interface DelayRegisterReq {
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.RegisterUser user = 5;
     */
    user?: RegisterUser;
    /**
     * @generated from protobuf field: bpt.match.common.v1.RegisterWay registerWay = 6;
     */
    registerWay: RegisterWay; // 报名方式
    /**
     * @generated from protobuf field: bpt.match.common.v1.Ticket ticket = 7;
     */
    ticket?: Ticket; // 当门票报名时传入，rawAmount 汇总即可，无需tokenid
    /**
     * @generated from protobuf field: int64 totalDeduplicatedEnterCnt = 8;
     */
    totalDeduplicatedEnterCnt: number; // 去重后的报名总人数
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ReBuyReq
 */
export interface ReBuyReq {
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ReBuyResp
 */
export interface ReBuyResp {
    /**
     * @generated from protobuf field: uint32 remainTimes = 1;
     */
    remainTimes: number;
    /**
     * @generated from protobuf field: uint64 chips = 2;
     */
    chips: number;
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.TxInfo txInfo = 3;
     */
    txInfo?: TxInfo;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ReEntryReq
 */
export interface ReEntryReq {
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.RegisterWay registerWay = 3;
     */
    registerWay: RegisterWay;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Ticket tickets = 4;
     */
    tickets: Ticket[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ReEntryResp
 */
export interface ReEntryResp {
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.TxInfo txInfo = 3;
     */
    txInfo?: TxInfo;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CancelReBuyReq
 */
export interface CancelReBuyReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CancelReBuyResp
 */
export interface CancelReBuyResp {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CancelAddOnReq
 */
export interface CancelAddOnReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CancelAddOnResp
 */
export interface CancelAddOnResp {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.AddOnReq
 */
export interface AddOnReq {
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 count = 4;
     */
    count: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.AddOnResp
 */
export interface AddOnResp {
    /**
     * 获得筹码
     *
     * @generated from protobuf field: uint64 chips = 1;
     */
    chips: number;
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.TxInfo txInfo = 2;
     */
    txInfo?: TxInfo;
}
/**
 *
 * ErrorCodeExceedLimit  手数上限
 * ErrorCodeCanceled 用户已结束比赛
 * ErrorCodeNotInValidTimeRange 未在时间内
 * ErrorCodeMatchInFinal FT阶段
 * ErrorCodeBubbleTime 泡沫时间
 * ErrorCodeEnterPrize 奖圈时间
 * ErrorCodeTxProcessed tx id 重复
 * ErrorCodeTxInvalid tx id 无效
 * ErrorCodeMatchOver 比赛已结束
 *
 * @generated from protobuf message bpt.match.mttmatch.v1.CheckAddOnReq
 */
export interface CheckAddOnReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
    /**
     * @generated from protobuf field: int64 count = 4;
     */
    count: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CheckAddOnResp
 */
export interface CheckAddOnResp {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.FinishAddOnReq
 */
export interface FinishAddOnReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
    /**
     * @generated from protobuf field: int64 count = 3;
     */
    count: number;
    /**
     * @generated from protobuf field: string txID = 4;
     */
    txID: string; // int64 prizeNum = 5;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.FinishAddOnResp
 */
export interface FinishAddOnResp {
}
// 
// ErrorCodeNotInValidTimeRange 未在时间内
// ErrorCodeCanceled 用户已结束比赛
// ErrorCodeReachMaxRebuy 重购次数达到上限
// ErrorCodeMatchInFinal FT阶段
// ErrorCodeBubbleTime 泡沫时间
// ErrorCodeEnterPrize 奖圈时间
// ErrorCodeTxProcessed tx id 重复
// ErrorCodeTxInvalid tx id 无效 
// ErrorCodeMatchOver 比赛已结束

/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CheckReBuyReq
 */
export interface CheckReBuyReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CheckReBuyResp
 */
export interface CheckReBuyResp {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.FinishReBuyReq
 */
export interface FinishReBuyReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
    /**
     * @generated from protobuf field: string txID = 4;
     */
    txID: string; // int64 prizeNum = 5;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.FinishReBuyResp
 */
export interface FinishReBuyResp {
}
// 
// ErrorCodeNotInValidTimeRange 未在时间内
// ErrorCodeExceedLimit 次数达到上限
// ErrorCodeMatchInFinal FT阶段
// ErrorCodeBubbleTime 泡沫时间
// ErrorCodeEnterPrize 奖圈时间

/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CheckReEntryReq
 */
export interface CheckReEntryReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.CheckReEntryResp
 */
export interface CheckReEntryResp {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.FinishReEntryReq
 */
export interface FinishReEntryReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
    /**
     * @generated from protobuf field: string txID = 3;
     */
    txID: string; // int64 prizeNum = 4;
    /**
     * 用于兼容旧的奖池，奖池挪走后，这里就可以废弃了
     *
     * @generated from protobuf field: bpt.match.common.v1.RegisterWay registerWay = 5;
     */
    registerWay: RegisterWay;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.FinishReEntryResp
 */
export interface FinishReEntryResp {
}
/**
 * 比赛结束条件参数
 *
 * @generated from protobuf message bpt.match.mttmatch.v1.GameOverParams
 */
export interface GameOverParams {
    /**
     * @generated from protobuf field: bpt.match.common.v1.GameOverType gameOverType = 1;
     */
    gameOverType: GameOverType;
    /**
     * @generated from protobuf field: uint32 gameOverBlindLevel = 2;
     */
    gameOverBlindLevel: number; // 结束的盲注级别
    /**
     * GameOverType为GameOverTypePercentage时必填
     *
     * @generated from protobuf field: uint32 gameOverPercentage = 3;
     */
    gameOverPercentage: number;
    /**
     * 最大晋级人数，0为不限制
     *
     * @generated from protobuf field: uint32 maxAdvancePlayerNum = 4;
     */
    maxAdvancePlayerNum: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.PauseParams
 */
export interface PauseParams {
    /**
     * 开启整点暂停
     *
     * @generated from protobuf field: bool pauseOnHour = 1;
     */
    pauseOnHour: boolean;
    /**
     * 持续时间(分) 如果是0 默认5分钟
     *
     * @generated from protobuf field: int32 pauseMinute = 2;
     */
    pauseMinute: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.StartMatchReq
 */
export interface StartMatchReq {
    /**
     * @generated from protobuf field: int64 matchID = 8;
     */
    matchID: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchContext ctx = 9;
     */
    ctx?: MatchContext;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 3;
     */
    conf?: MatchConf;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.RegisterUser users = 6;
     */
    users: RegisterUser[];
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.RegisterUser notJoinedUsers = 24;
     */
    notJoinedUsers: RegisterUser[];
    /**
     * @generated from protobuf field: uint32 startBlindLevel = 10;
     */
    startBlindLevel: number; // 指定某个盲注等级开始
    /**
     * @generated from protobuf field: uint32 rollBackBlindLevel = 20;
     */
    rollBackBlindLevel: number; // 回滚到的盲注级别,0为不需要回滚
    /**
     * @generated from protobuf field: bpt.match.common.v1.GameOverType gameOverType = 11;
     */
    gameOverType: GameOverType; // deprecated
    /**
     * @generated from protobuf field: uint32 gameOverBlindLevel = 12;
     */
    gameOverBlindLevel: number; // 结束的盲注级别 deprecated
    /**
     * @generated from protobuf field: bool isDisableFinalTable = 16;
     */
    isDisableFinalTable: boolean; // 默认为false,表示开启finaltable功能，开启后，有finaltable动画，新桌重新走庄，停止rebuy,addon,reentry,延时报名
    /**
     * @generated from protobuf field: bool isDisableBubble = 17;
     */
    isDisableBubble: boolean; // 默认为false,表示开启泡沫功能，开启后，会泡沫排名发奖，泡沫同时打牌,停止rebuy,addon,reentry,延时报名
    /**
     * @generated from protobuf field: bool isDisablePrizeRound = 18;
     */
    isDisablePrizeRound: boolean; // 默认为false,表示开启奖圈功能，开启后，会有钱圈动画，停止rebuy,addon,reentry,延时报名
    /**
     * @generated from protobuf field: bool isDisableDuel = 21;
     */
    isDisableDuel: boolean; // 默认为false,表示开启二人对决，二人对决会有二人对决动画
    /**
     * @generated from protobuf field: bpt.match.common.v1.StageType stageType = 23;
     */
    stageType: StageType;
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.GameOverParams gameOverParams = 25;
     */
    gameOverParams?: GameOverParams;
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.PauseParams pauseParams = 26;
     */
    pauseParams?: PauseParams;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.StartMatchResp
 */
export interface StartMatchResp {
    /**
     * 返回本服务(mttmatch)的id
     *
     * @generated from protobuf field: int64 srvID = 1;
     */
    srvID: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetCanReEnterReq
 */
export interface BatchGetCanReEnterReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated string matchKeys = 2;
     */
    matchKeys: string[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetCanReEnterResp
 */
export interface BatchGetCanReEnterResp {
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.UserCanEnter canEnters = 1;
     */
    canEnters: UserCanEnter[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetUserCanEnterReq
 */
export interface BatchGetUserCanEnterReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated string matchKeys = 2;
     */
    matchKeys: string[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.BatchGetUserCanEnterResp
 */
export interface BatchGetUserCanEnterResp {
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.UserCanEnter canEnters = 1;
     */
    canEnters: UserCanEnter[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.AnimationFinishReq
 */
export interface AnimationFinishReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.AnimationFinishType type = 2;
     */
    type: AnimationFinishType; // 类型 1最后一桌
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.AnimationFinishResp
 */
export interface AnimationFinishResp {
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.UserCanEnter
 */
export interface UserCanEnter {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bool canEnter = 2;
     */
    canEnter: boolean;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.DelayRegisterResp
 */
export interface DelayRegisterResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 1;
     */
    match?: Match;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.JoinDeskMsg
 */
export interface JoinDeskMsg {
    /**
     * @generated from protobuf field: int64 deskID = 2;
     */
    deskID: number;
    /**
     * 进入桌子的用户
     *
     * @generated from protobuf field: bpt.match.common.v1.User user = 3;
     */
    user?: User;
    /**
     * @generated from protobuf field: int64 confID = 4;
     */
    confID: number;
    /**
     * @generated from protobuf field: int64 serverID = 5;
     */
    serverID: number;
    /**
     * 加入的桌子是否finaltable,true表示加入桌子为finaltable
     *
     * @generated from protobuf field: bool isFinalTable = 6;
     */
    isFinalTable: boolean;
    /**
     * @generated from protobuf field: string tableKey = 7;
     */
    tableKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.LeaveDeskMsg
 */
export interface LeaveDeskMsg {
    /**
     * @generated from protobuf field: int64 deskID = 1;
     */
    deskID: number;
    /**
     * 只是离开的用户
     *
     * @generated from protobuf field: bpt.match.common.v1.User user = 2;
     */
    user?: User;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgMatchStart
 */
export interface MsgMatchStart {
    /**
     * @generated from protobuf field: int64 startedAt = 1;
     */
    startedAt: number;
    /**
     * @generated from protobuf field: bool isSuccess = 2;
     */
    isSuccess: boolean;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.PrizeAndBubbleUser
 */
export interface PrizeAndBubbleUser {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint32 rank = 2;
     */
    rank: number;
    /**
     * @generated from protobuf field: string PrizeRawAmount = 3 [json_name = "PrizeRawAmount"];
     */
    PrizeRawAmount: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgMatchOver
 */
export interface MsgMatchOver {
    /**
     * @generated from protobuf field: int64 endAt = 1;
     */
    endAt: number;
    /**
     * 这里返回获奖的+泡沫
     *
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.PrizeAndBubbleUser prizeAndBubbleUsers = 2;
     */
    prizeAndBubbleUsers: PrizeAndBubbleUser[];
    /**
     * @generated from protobuf field: string game = 3;
     */
    game: string; // 比赛名字
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgDesksStart
 */
export interface MsgDesksStart {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Desk desks = 1;
     */
    desks: Desk[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgDesksWait
 */
export interface MsgDesksWait {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgUserChipAvg
 */
export interface MsgUserChipAvg {
    /**
     * @generated from protobuf field: int64 avg = 1;
     */
    avg: number; // 平均值
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgDesksDestroy
 */
export interface MsgDesksDestroy {
    /**
     * @generated from protobuf field: repeated int64 deskIDs = 1;
     */
    deskIDs: number[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgRank
 */
export interface MsgRank {
    /**
     * 所有用户的排名
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.User users = 1;
     */
    users: User[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgRankChange
 */
export interface MsgRankChange {
    /**
     * @generated from protobuf field: int64 myRank = 1;
     */
    myRank: number; // 我当前排名
    /**
     * @generated from protobuf field: int64 totalUser = 2;
     */
    totalUser: number; // 剩余人数
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GoingDesk
 */
export interface GoingDesk {
    /**
     * @generated from protobuf field: int64 deskId = 1;
     */
    deskId: number; // 桌子id
    /**
     * @generated from protobuf field: bool isFinalTable = 2;
     */
    isFinalTable: boolean; // 是不是最后一桌
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgUserOut
 */
export interface MsgUserOut {
    /**
     * @generated from protobuf field: bpt.match.common.v1.User user = 1;
     */
    user?: User;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgBlindLevelUp
 */
export interface MsgBlindLevelUp {
    /**
     * @generated from protobuf field: uint32 currentLevel = 1;
     */
    currentLevel: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgUserResult
 */
export interface MsgUserResult {
    /**
     * 用户信息
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 筹码
     *
     * @generated from protobuf field: uint64 chips = 2;
     */
    chips: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgUserSettlement
 */
export interface MsgUserSettlement {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 结算时间戳（单位秒）
     *
     * @generated from protobuf field: int64 settleAt = 2;
     */
    settleAt: number;
    /**
     * 奖品信息
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem prize = 3;
     */
    prize: AssetItem[];
    /**
     * 最终排名
     *
     * @generated from protobuf field: uint32 rank = 4;
     */
    rank: number;
    /**
     * 本次结算是否需要询问reentry，仅在没有rebuy,和不在泡沫圈才能触发
     *
     * @generated from protobuf field: bool isReEntry = 5;
     */
    isReEntry: boolean;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgBubbleTime
 */
export interface MsgBubbleTime {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgBubbleTimeWait
 */
export interface MsgBubbleTimeWait {
    /**
     * @generated from protobuf field: int64 tableInPlay = 1;
     */
    tableInPlay: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgExitBubbleTime
 */
export interface MsgExitBubbleTime {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgEnterPrizeRound
 */
export interface MsgEnterPrizeRound {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgFinalTable
 */
export interface MsgFinalTable {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Desk desk = 1;
     */
    desk?: Desk;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.DuelUser
 */
export interface DuelUser {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint64 chips = 2;
     */
    chips: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgDuel
 */
export interface MsgDuel {
    /**
     * @generated from protobuf field: repeated bpt.match.mttmatch.v1.DuelUser duelUsers = 1;
     */
    duelUsers: DuelUser[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.RecyclingMatchReq
 */
export interface RecyclingMatchReq {
    /**
     * 定时任务id，唯一
     *
     * @generated from protobuf field: string jobId = 2;
     */
    jobId: string;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
// ===========================

/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgUserEnter
 */
export interface MsgUserEnter {
    /**
     * @generated from protobuf field: bpt.match.common.v1.User user = 1;
     */
    user?: User;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgUserCancelEnter
 */
export interface MsgUserCancelEnter {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 当前最新报名人数
     *
     * @generated from protobuf field: int64 registeredNum = 3;
     */
    registeredNum: number;
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgMatchAboutToStart
 */
export interface MsgMatchAboutToStart {
    /**
     * 比赛开始预估剩余时长(单位：秒)
     *
     * @generated from protobuf field: int64 leftDuration = 1;
     */
    leftDuration: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgCountDown
 */
export interface MsgCountDown {
    /**
     * 比赛开始倒计时，（单位：秒）
     *
     * @generated from protobuf field: int64 leftDuration = 1;
     */
    leftDuration: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgRoundStart
 */
export interface MsgRoundStart {
    /**
     * @generated from protobuf field: repeated int64 deskIDs = 1;
     */
    deskIDs: number[];
    /**
     * @generated from protobuf field: uint32 blindLevel = 2;
     */
    blindLevel: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgRoundEnd
 */
export interface MsgRoundEnd {
    /**
     * @generated from protobuf field: repeated int64 deskIDs = 1;
     */
    deskIDs: number[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgBreakTimeStart
 */
export interface MsgBreakTimeStart {
    /**
     * @generated from protobuf field: int64 stopTimeAt = 1;
     */
    stopTimeAt: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgBreakTimeStop
 */
export interface MsgBreakTimeStop {
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgPoolChange
 */
export interface MsgPoolChange {
    /**
     * 总奖池
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 1;
     */
    prizePool: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgReEntry
 */
export interface MsgReEntry {
    /**
     * 复活思考时间(单位：秒)
     *
     * @generated from protobuf field: int64 leftDuration = 1;
     */
    leftDuration: number;
    /**
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[];
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgReBuy
 */
export interface MsgReBuy {
    /**
     *  int64 leftDuration = 1;
     *
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[];
    /**
     * rebuy操作截止时间
     *
     * @generated from protobuf field: int64 deadLine = 3;
     */
    deadLine: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgAddOn
 */
export interface MsgAddOn {
    /**
     *  int64 leftDuration = 1;
     *
     * @generated from protobuf field: repeated int64 uids = 2;
     */
    uids: number[];
    /**
     * addon操作截止时间
     *
     * @generated from protobuf field: int64 deadLine = 3;
     */
    deadLine: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgCharge
 */
export interface MsgCharge {
    /**
     * @generated from protobuf field: bpt.match.common.v1.ChargeEvent event = 1;
     */
    event: ChargeEvent;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * 当前用户所有筹码(购买后)
     *
     * @generated from protobuf field: uint64 chips = 3;
     */
    chips: number;
    /**
     * 次数,rebuy,reentry为1,addon为购买的手数
     *
     * @generated from protobuf field: uint32 cnt = 4;
     */
    cnt: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgCancelMatch
 */
export interface MsgCancelMatch {
    /**
     * @generated from protobuf field: string game = 1;
     */
    game: string; // 比赛名字
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgUserObserve
 */
export interface MsgUserObserve {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Desk desk = 1;
     */
    desk?: Desk;
    /**
     * @generated from protobuf field: bpt.match.common.v1.User user = 2;
     */
    user?: User;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.MsgLastChance
 */
export interface MsgLastChance {
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.LastChanceScene scene = 1;
     */
    scene: LastChanceScene;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.ChipInfo
 */
export interface ChipInfo {
    /**
     * @generated from protobuf field: int64 avgChip = 1;
     */
    avgChip: number;
    /**
     * @generated from protobuf field: int64 smallestChip = 2;
     */
    smallestChip: number;
    /**
     * @generated from protobuf field: int64 largestChip = 3;
     */
    largestChip: number;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetChipInfoReq
 */
export interface GetChipInfoReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.mttmatch.v1.GetChipInfoResp
 */
export interface GetChipInfoResp {
    /**
     * @generated from protobuf field: bpt.match.mttmatch.v1.ChipInfo chipInfo = 2;
     */
    chipInfo?: ChipInfo;
}
/**
 * @generated from protobuf enum bpt.match.mttmatch.v1.RestoreMode
 */
export enum RestoreMode {
    /**
     * 自动模式 自动恢复全部桌子，服务器自己选择服务器
     *
     * @generated from protobuf enum value: RestoreModeAuto = 0;
     */
    RestoreModeAuto = 0,
    /**
     * @generated from protobuf enum value: RestoreModeSpecified = 1;
     */
    RestoreModeSpecified = 1
}
/**
 * @generated from protobuf enum bpt.match.mttmatch.v1.SnapShotState
 */
export enum SnapShotState {
    /**
     * 已经结束的可以回放比赛
     *
     * @generated from protobuf enum value: AbleReplay = 0;
     */
    AbleReplay = 0,
    /**
     * 正在回放的比赛，需要清理
     *
     * @generated from protobuf enum value: OnReplay = 1;
     */
    OnReplay = 1
}
/**
 * @generated from protobuf enum bpt.match.mttmatch.v1.SelectMode
 */
export enum SelectMode {
    /**
     * @generated from protobuf enum value: SelectModeRandom = 0;
     */
    SelectModeRandom = 0,
    /**
     * @generated from protobuf enum value: SelectModeForward = 1;
     */
    SelectModeForward = 1,
    /**
     * @generated from protobuf enum value: SelectModeBackward = 2;
     */
    SelectModeBackward = 2
}
/**
 * @generated from protobuf enum bpt.match.mttmatch.v1.AnimationFinishType
 */
export enum AnimationFinishType {
    /**
     * 未知
     *
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * 最后一桌
     *
     * @generated from protobuf enum value: FINALLY_TABLE = 1;
     */
    FINALLY_TABLE = 1,
    /**
     * 将要去某个桌子 播放完毕
     *
     * @generated from protobuf enum value: LEAVE_TABLE = 2;
     */
    LEAVE_TABLE = 2,
    /**
     * 加入桌子
     *
     * @generated from protobuf enum value: JOIN_TABLE = 3;
     */
    JOIN_TABLE = 3,
    /**
     * 二人对决
     *
     * @generated from protobuf enum value: DUEL = 4;
     */
    DUEL = 4
}
/**
 * @generated from protobuf enum bpt.match.mttmatch.v1.LastChanceScene
 */
export enum LastChanceScene {
    /**
     * @generated from protobuf enum value: SCENE_UNSET = 0;
     */
    SCENE_UNSET = 0,
    /**
     * @generated from protobuf enum value: SCENE_BUBBLE = 1;
     */
    SCENE_BUBBLE = 1,
    /**
     * @generated from protobuf enum value: SCENE_FINAL_TABLE = 2;
     */
    SCENE_FINAL_TABLE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class TxInfo$Type extends MessageType<TxInfo> {
    constructor() {
        super("bpt.match.mttmatch.v1.TxInfo", [
            { no: 1, name: "succeed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TxInfo>): TxInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.succeed = false;
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<TxInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TxInfo): TxInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool succeed */ 1:
                    message.succeed = reader.bool();
                    break;
                case /* bytes credential */ 2:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 3:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TxInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool succeed = 1; */
        if (message.succeed !== false)
            writer.tag(1, WireType.Varint).bool(message.succeed);
        /* bytes credential = 2; */
        if (message.credential.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 3; */
        if (message.tsId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.TxInfo
 */
export const TxInfo = new TxInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestoreMatchResp$Type extends MessageType<RestoreMatchResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.RestoreMatchResp", [
            { no: 1, name: "match", kind: "message", T: () => Match },
            { no: 2, name: "cfg", kind: "message", T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<RestoreMatchResp>): RestoreMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RestoreMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestoreMatchResp): RestoreMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Match match */ 1:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                case /* bpt.match.common.v1.MatchConf cfg */ 2:
                    message.cfg = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.cfg);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestoreMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Match match = 1; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchConf cfg = 2; */
        if (message.cfg)
            MatchConf.internalBinaryWrite(message.cfg, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.RestoreMatchResp
 */
export const RestoreMatchResp = new RestoreMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchReq$Type extends MessageType<GetMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchReq>): GetMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchReq): GetMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetMatchReq
 */
export const GetMatchReq = new GetMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchResp$Type extends MessageType<GetMatchResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetMatchResp", [
            { no: 1, name: "match", kind: "message", T: () => Match }
        ]);
    }
    create(value?: PartialMessage<GetMatchResp>): GetMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchResp): GetMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Match match */ 1:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Match match = 1; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetMatchResp
 */
export const GetMatchResp = new GetMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchStateInfo$Type extends MessageType<MatchStateInfo> {
    constructor() {
        super("bpt.match.mttmatch.v1.MatchStateInfo", [
            { no: 3, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 5, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "isBubbleTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "isDelayRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 42, name: "waitDelayRegisterDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "finalTableID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "isBreakTime", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "userCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 22, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 23, name: "myRank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 24, name: "avgChip", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 50, name: "smallestStack", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 51, name: "largestStack", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 25, name: "playingNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 27, name: "isPrizeRound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 28, name: "isAbleEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 29, name: "myTable", kind: "message", T: () => MyTable },
            { no: 31, name: "breakTimeStopAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 32, name: "rollBackBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 40, name: "startBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 41, name: "registrationAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 44, name: "pauseEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 45, name: "pauseBeginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 46, name: "elapsedPauseSeconds", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MatchStateInfo>): MatchStateInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        message.confID = 0;
        message.isRegister = false;
        message.startedAt = 0;
        message.endAt = 0;
        message.blindLevel = 0;
        message.isBubbleTime = false;
        message.isDelayRegister = false;
        message.registerDeadline = 0;
        message.waitDelayRegisterDeadline = 0;
        message.matchKey = "";
        message.finalTableID = 0;
        message.isBreakTime = false;
        message.userCnt = 0;
        message.srvID = 0;
        message.myRank = 0;
        message.avgChip = 0;
        message.smallestStack = 0;
        message.largestStack = 0;
        message.playingNum = 0;
        message.isPrizeRound = false;
        message.isAbleEnter = false;
        message.breakTimeStopAt = 0;
        message.rollBackBlindLevel = 0;
        message.startBlindLevel = 0;
        message.registrationAt = 0;
        message.pauseEndAt = 0;
        message.pauseBeginAt = 0;
        message.elapsedPauseSeconds = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchStateInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchStateInfo): MatchStateInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchStatus state */ 3:
                    message.state = reader.int32();
                    break;
                case /* int64 confID */ 5:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* bool isRegister */ 6:
                    message.isRegister = reader.bool();
                    break;
                case /* int64 startedAt */ 9:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 10:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* uint32 blindLevel */ 11:
                    message.blindLevel = reader.uint32();
                    break;
                case /* bool isBubbleTime */ 12:
                    message.isBubbleTime = reader.bool();
                    break;
                case /* bool isDelayRegister */ 13:
                    message.isDelayRegister = reader.bool();
                    break;
                case /* int64 registerDeadline */ 26:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* int64 waitDelayRegisterDeadline */ 42:
                    message.waitDelayRegisterDeadline = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 15:
                    message.matchKey = reader.string();
                    break;
                case /* int64 finalTableID */ 16:
                    message.finalTableID = reader.int64().toNumber();
                    break;
                case /* bool isBreakTime */ 18:
                    message.isBreakTime = reader.bool();
                    break;
                case /* int64 userCnt */ 21:
                    message.userCnt = reader.int64().toNumber();
                    break;
                case /* int64 srvID */ 22:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* uint32 myRank */ 23:
                    message.myRank = reader.uint32();
                    break;
                case /* uint32 avgChip */ 24:
                    message.avgChip = reader.uint32();
                    break;
                case /* uint32 smallestStack */ 50:
                    message.smallestStack = reader.uint32();
                    break;
                case /* uint32 largestStack */ 51:
                    message.largestStack = reader.uint32();
                    break;
                case /* uint32 playingNum */ 25:
                    message.playingNum = reader.uint32();
                    break;
                case /* bool isPrizeRound */ 27:
                    message.isPrizeRound = reader.bool();
                    break;
                case /* bool isAbleEnter */ 28:
                    message.isAbleEnter = reader.bool();
                    break;
                case /* bpt.match.common.v1.MyTable myTable */ 29:
                    message.myTable = MyTable.internalBinaryRead(reader, reader.uint32(), options, message.myTable);
                    break;
                case /* int64 breakTimeStopAt */ 31:
                    message.breakTimeStopAt = reader.int64().toNumber();
                    break;
                case /* uint32 rollBackBlindLevel */ 32:
                    message.rollBackBlindLevel = reader.uint32();
                    break;
                case /* uint32 startBlindLevel */ 40:
                    message.startBlindLevel = reader.uint32();
                    break;
                case /* int64 registrationAt */ 41:
                    message.registrationAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseEndAt */ 44:
                    message.pauseEndAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseBeginAt */ 45:
                    message.pauseBeginAt = reader.int64().toNumber();
                    break;
                case /* int32 elapsedPauseSeconds */ 46:
                    message.elapsedPauseSeconds = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchStateInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchStatus state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        /* int64 confID = 5; */
        if (message.confID !== 0)
            writer.tag(5, WireType.Varint).int64(message.confID);
        /* bool isRegister = 6; */
        if (message.isRegister !== false)
            writer.tag(6, WireType.Varint).bool(message.isRegister);
        /* int64 startedAt = 9; */
        if (message.startedAt !== 0)
            writer.tag(9, WireType.Varint).int64(message.startedAt);
        /* int64 endAt = 10; */
        if (message.endAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.endAt);
        /* uint32 blindLevel = 11; */
        if (message.blindLevel !== 0)
            writer.tag(11, WireType.Varint).uint32(message.blindLevel);
        /* bool isBubbleTime = 12; */
        if (message.isBubbleTime !== false)
            writer.tag(12, WireType.Varint).bool(message.isBubbleTime);
        /* bool isDelayRegister = 13; */
        if (message.isDelayRegister !== false)
            writer.tag(13, WireType.Varint).bool(message.isDelayRegister);
        /* int64 registerDeadline = 26; */
        if (message.registerDeadline !== 0)
            writer.tag(26, WireType.Varint).int64(message.registerDeadline);
        /* int64 waitDelayRegisterDeadline = 42; */
        if (message.waitDelayRegisterDeadline !== 0)
            writer.tag(42, WireType.Varint).int64(message.waitDelayRegisterDeadline);
        /* string matchKey = 15; */
        if (message.matchKey !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.matchKey);
        /* int64 finalTableID = 16; */
        if (message.finalTableID !== 0)
            writer.tag(16, WireType.Varint).int64(message.finalTableID);
        /* bool isBreakTime = 18; */
        if (message.isBreakTime !== false)
            writer.tag(18, WireType.Varint).bool(message.isBreakTime);
        /* int64 userCnt = 21; */
        if (message.userCnt !== 0)
            writer.tag(21, WireType.Varint).int64(message.userCnt);
        /* int64 srvID = 22; */
        if (message.srvID !== 0)
            writer.tag(22, WireType.Varint).int64(message.srvID);
        /* uint32 myRank = 23; */
        if (message.myRank !== 0)
            writer.tag(23, WireType.Varint).uint32(message.myRank);
        /* uint32 avgChip = 24; */
        if (message.avgChip !== 0)
            writer.tag(24, WireType.Varint).uint32(message.avgChip);
        /* uint32 smallestStack = 50; */
        if (message.smallestStack !== 0)
            writer.tag(50, WireType.Varint).uint32(message.smallestStack);
        /* uint32 largestStack = 51; */
        if (message.largestStack !== 0)
            writer.tag(51, WireType.Varint).uint32(message.largestStack);
        /* uint32 playingNum = 25; */
        if (message.playingNum !== 0)
            writer.tag(25, WireType.Varint).uint32(message.playingNum);
        /* bool isPrizeRound = 27; */
        if (message.isPrizeRound !== false)
            writer.tag(27, WireType.Varint).bool(message.isPrizeRound);
        /* bool isAbleEnter = 28; */
        if (message.isAbleEnter !== false)
            writer.tag(28, WireType.Varint).bool(message.isAbleEnter);
        /* bpt.match.common.v1.MyTable myTable = 29; */
        if (message.myTable)
            MyTable.internalBinaryWrite(message.myTable, writer.tag(29, WireType.LengthDelimited).fork(), options).join();
        /* int64 breakTimeStopAt = 31; */
        if (message.breakTimeStopAt !== 0)
            writer.tag(31, WireType.Varint).int64(message.breakTimeStopAt);
        /* uint32 rollBackBlindLevel = 32; */
        if (message.rollBackBlindLevel !== 0)
            writer.tag(32, WireType.Varint).uint32(message.rollBackBlindLevel);
        /* uint32 startBlindLevel = 40; */
        if (message.startBlindLevel !== 0)
            writer.tag(40, WireType.Varint).uint32(message.startBlindLevel);
        /* int64 registrationAt = 41; */
        if (message.registrationAt !== 0)
            writer.tag(41, WireType.Varint).int64(message.registrationAt);
        /* int64 pauseEndAt = 44; */
        if (message.pauseEndAt !== 0)
            writer.tag(44, WireType.Varint).int64(message.pauseEndAt);
        /* int64 pauseBeginAt = 45; */
        if (message.pauseBeginAt !== 0)
            writer.tag(45, WireType.Varint).int64(message.pauseBeginAt);
        /* int32 elapsedPauseSeconds = 46; */
        if (message.elapsedPauseSeconds !== 0)
            writer.tag(46, WireType.Varint).int32(message.elapsedPauseSeconds);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MatchStateInfo
 */
export const MatchStateInfo = new MatchStateInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchStateInfoReq$Type extends MessageType<GetMatchStateInfoReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetMatchStateInfoReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchStateInfoReq>): GetMatchStateInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchStateInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchStateInfoReq): GetMatchStateInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchStateInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetMatchStateInfoReq
 */
export const GetMatchStateInfoReq = new GetMatchStateInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchStateInfoResp$Type extends MessageType<GetMatchStateInfoResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetMatchStateInfoResp", [
            { no: 1, name: "stateInfo", kind: "message", T: () => MatchStateInfo }
        ]);
    }
    create(value?: PartialMessage<GetMatchStateInfoResp>): GetMatchStateInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchStateInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchStateInfoResp): GetMatchStateInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.mttmatch.v1.MatchStateInfo stateInfo */ 1:
                    message.stateInfo = MatchStateInfo.internalBinaryRead(reader, reader.uint32(), options, message.stateInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchStateInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.mttmatch.v1.MatchStateInfo stateInfo = 1; */
        if (message.stateInfo)
            MatchStateInfo.internalBinaryWrite(message.stateInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetMatchStateInfoResp
 */
export const GetMatchStateInfoResp = new GetMatchStateInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinMatchReq$Type extends MessageType<JoinMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.JoinMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<JoinMatchReq>): JoinMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<JoinMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinMatchReq): JoinMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.JoinMatchReq
 */
export const JoinMatchReq = new JoinMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinMatchResp$Type extends MessageType<JoinMatchResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.JoinMatchResp", [
            { no: 1, name: "match", kind: "message", T: () => Match }
        ]);
    }
    create(value?: PartialMessage<JoinMatchResp>): JoinMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<JoinMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinMatchResp): JoinMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Match match */ 1:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Match match = 1; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.JoinMatchResp
 */
export const JoinMatchResp = new JoinMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestoreMatchReq$Type extends MessageType<RestoreMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.RestoreMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RestoreMatchReq>): RestoreMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<RestoreMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestoreMatchReq): RestoreMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestoreMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.RestoreMatchReq
 */
export const RestoreMatchReq = new RestoreMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExceptionTable$Type extends MessageType<ExceptionTable> {
    constructor() {
        super("bpt.match.mttmatch.v1.ExceptionTable", [
            { no: 1, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ExceptionTable>): ExceptionTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.srvID = 0;
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<ExceptionTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExceptionTable): ExceptionTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 srvID */ 1:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* string tableKey */ 2:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExceptionTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 srvID = 1; */
        if (message.srvID !== 0)
            writer.tag(1, WireType.Varint).int64(message.srvID);
        /* string tableKey = 2; */
        if (message.tableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ExceptionTable
 */
export const ExceptionTable = new ExceptionTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestoreMatchTablesReq$Type extends MessageType<RestoreMatchTablesReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.RestoreMatchTablesReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tables", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ExceptionTable },
            { no: 3, name: "mode", kind: "enum", T: () => ["bpt.match.mttmatch.v1.RestoreMode", RestoreMode] }
        ]);
    }
    create(value?: PartialMessage<RestoreMatchTablesReq>): RestoreMatchTablesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.tables = [];
        message.mode = 0;
        if (value !== undefined)
            reflectionMergePartial<RestoreMatchTablesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestoreMatchTablesReq): RestoreMatchTablesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.match.mttmatch.v1.ExceptionTable tables */ 2:
                    message.tables.push(ExceptionTable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.mttmatch.v1.RestoreMode mode */ 3:
                    message.mode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestoreMatchTablesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.match.mttmatch.v1.ExceptionTable tables = 2; */
        for (let i = 0; i < message.tables.length; i++)
            ExceptionTable.internalBinaryWrite(message.tables[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.mttmatch.v1.RestoreMode mode = 3; */
        if (message.mode !== 0)
            writer.tag(3, WireType.Varint).int32(message.mode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.RestoreMatchTablesReq
 */
export const RestoreMatchTablesReq = new RestoreMatchTablesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestoreFailedTable$Type extends MessageType<RestoreFailedTable> {
    constructor() {
        super("bpt.match.mttmatch.v1.RestoreFailedTable", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "failedMsg", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RestoreFailedTable>): RestoreFailedTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.failedMsg = "";
        if (value !== undefined)
            reflectionMergePartial<RestoreFailedTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestoreFailedTable): RestoreFailedTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* string failedMsg */ 2:
                    message.failedMsg = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestoreFailedTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* string failedMsg = 2; */
        if (message.failedMsg !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.failedMsg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.RestoreFailedTable
 */
export const RestoreFailedTable = new RestoreFailedTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestoreMatchTablesResp$Type extends MessageType<RestoreMatchTablesResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.RestoreMatchTablesResp", [
            { no: 1, name: "failedTables", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RestoreFailedTable }
        ]);
    }
    create(value?: PartialMessage<RestoreMatchTablesResp>): RestoreMatchTablesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.failedTables = [];
        if (value !== undefined)
            reflectionMergePartial<RestoreMatchTablesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestoreMatchTablesResp): RestoreMatchTablesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.mttmatch.v1.RestoreFailedTable failedTables */ 1:
                    message.failedTables.push(RestoreFailedTable.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestoreMatchTablesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.mttmatch.v1.RestoreFailedTable failedTables = 1; */
        for (let i = 0; i < message.failedTables.length; i++)
            RestoreFailedTable.internalBinaryWrite(message.failedTables[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.RestoreMatchTablesResp
 */
export const RestoreMatchTablesResp = new RestoreMatchTablesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RestoreByServerIDReq$Type extends MessageType<RestoreByServerIDReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.RestoreByServerIDReq", [
            { no: 1, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RestoreByServerIDReq>): RestoreByServerIDReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.srvID = 0;
        if (value !== undefined)
            reflectionMergePartial<RestoreByServerIDReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RestoreByServerIDReq): RestoreByServerIDReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 srvID */ 1:
                    message.srvID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RestoreByServerIDReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 srvID = 1; */
        if (message.srvID !== 0)
            writer.tag(1, WireType.Varint).int64(message.srvID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.RestoreByServerIDReq
 */
export const RestoreByServerIDReq = new RestoreByServerIDReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchOverViewReq$Type extends MessageType<MatchOverViewReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.MatchOverViewReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchOverViewReq>): MatchOverViewReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MatchOverViewReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchOverViewReq): MatchOverViewReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchOverViewReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MatchOverViewReq
 */
export const MatchOverViewReq = new MatchOverViewReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchOverViewResp$Type extends MessageType<MatchOverViewResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.MatchOverViewResp", [
            { no: 1, name: "addOnCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "addOnPlayerCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "reBuyCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "reEntryCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "remainPlayer", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "totalPlayer", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "avgScore", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "placePaid", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "reBuyPlayerCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "reEntryPlayerCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "hasPrizeRound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "smallestStack", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "largestStack", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MatchOverViewResp>): MatchOverViewResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.addOnCnt = 0;
        message.addOnPlayerCnt = 0;
        message.reBuyCnt = 0;
        message.reEntryCnt = 0;
        message.remainPlayer = 0;
        message.totalPlayer = 0;
        message.avgScore = 0;
        message.placePaid = 0;
        message.reBuyPlayerCnt = 0;
        message.reEntryPlayerCnt = 0;
        message.hasPrizeRound = false;
        message.smallestStack = 0;
        message.largestStack = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchOverViewResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchOverViewResp): MatchOverViewResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 addOnCnt */ 1:
                    message.addOnCnt = reader.int64().toNumber();
                    break;
                case /* int64 addOnPlayerCnt */ 2:
                    message.addOnPlayerCnt = reader.int64().toNumber();
                    break;
                case /* int64 reBuyCnt */ 3:
                    message.reBuyCnt = reader.int64().toNumber();
                    break;
                case /* int64 reEntryCnt */ 4:
                    message.reEntryCnt = reader.int64().toNumber();
                    break;
                case /* int64 remainPlayer */ 5:
                    message.remainPlayer = reader.int64().toNumber();
                    break;
                case /* int64 totalPlayer */ 6:
                    message.totalPlayer = reader.int64().toNumber();
                    break;
                case /* int64 avgScore */ 7:
                    message.avgScore = reader.int64().toNumber();
                    break;
                case /* int32 placePaid */ 8:
                    message.placePaid = reader.int32();
                    break;
                case /* int64 reBuyPlayerCnt */ 9:
                    message.reBuyPlayerCnt = reader.int64().toNumber();
                    break;
                case /* int64 reEntryPlayerCnt */ 10:
                    message.reEntryPlayerCnt = reader.int64().toNumber();
                    break;
                case /* bool hasPrizeRound */ 11:
                    message.hasPrizeRound = reader.bool();
                    break;
                case /* int64 smallestStack */ 12:
                    message.smallestStack = reader.int64().toNumber();
                    break;
                case /* int64 largestStack */ 13:
                    message.largestStack = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchOverViewResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 addOnCnt = 1; */
        if (message.addOnCnt !== 0)
            writer.tag(1, WireType.Varint).int64(message.addOnCnt);
        /* int64 addOnPlayerCnt = 2; */
        if (message.addOnPlayerCnt !== 0)
            writer.tag(2, WireType.Varint).int64(message.addOnPlayerCnt);
        /* int64 reBuyCnt = 3; */
        if (message.reBuyCnt !== 0)
            writer.tag(3, WireType.Varint).int64(message.reBuyCnt);
        /* int64 reEntryCnt = 4; */
        if (message.reEntryCnt !== 0)
            writer.tag(4, WireType.Varint).int64(message.reEntryCnt);
        /* int64 remainPlayer = 5; */
        if (message.remainPlayer !== 0)
            writer.tag(5, WireType.Varint).int64(message.remainPlayer);
        /* int64 totalPlayer = 6; */
        if (message.totalPlayer !== 0)
            writer.tag(6, WireType.Varint).int64(message.totalPlayer);
        /* int64 avgScore = 7; */
        if (message.avgScore !== 0)
            writer.tag(7, WireType.Varint).int64(message.avgScore);
        /* int32 placePaid = 8; */
        if (message.placePaid !== 0)
            writer.tag(8, WireType.Varint).int32(message.placePaid);
        /* int64 reBuyPlayerCnt = 9; */
        if (message.reBuyPlayerCnt !== 0)
            writer.tag(9, WireType.Varint).int64(message.reBuyPlayerCnt);
        /* int64 reEntryPlayerCnt = 10; */
        if (message.reEntryPlayerCnt !== 0)
            writer.tag(10, WireType.Varint).int64(message.reEntryPlayerCnt);
        /* bool hasPrizeRound = 11; */
        if (message.hasPrizeRound !== false)
            writer.tag(11, WireType.Varint).bool(message.hasPrizeRound);
        /* int64 smallestStack = 12; */
        if (message.smallestStack !== 0)
            writer.tag(12, WireType.Varint).int64(message.smallestStack);
        /* int64 largestStack = 13; */
        if (message.largestStack !== 0)
            writer.tag(13, WireType.Varint).int64(message.largestStack);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MatchOverViewResp
 */
export const MatchOverViewResp = new MatchOverViewResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRankReq$Type extends MessageType<ListRankReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.ListRankReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListRankReq>): ListRankReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListRankReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRankReq): ListRankReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRankReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ListRankReq
 */
export const ListRankReq = new ListRankReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRankInfo$Type extends MessageType<UserRankInfo> {
    constructor() {
        super("bpt.match.mttmatch.v1.UserRankInfo", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "status", kind: "enum", T: () => ["bpt.match.common.v1.UserStatus", UserStatus] },
            { no: 5, name: "PrizeRawAmount", kind: "scalar", localName: "PrizeRawAmount", jsonName: "PrizeRawAmount", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "reBuyCnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "addOnCnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "reEntryCnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<UserRankInfo>): UserRankInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.rank = 0;
        message.chips = 0;
        message.status = 0;
        message.PrizeRawAmount = "";
        message.reBuyCnt = 0;
        message.addOnCnt = 0;
        message.reEntryCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<UserRankInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRankInfo): UserRankInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint32 rank */ 2:
                    message.rank = reader.uint32();
                    break;
                case /* uint64 chips */ 3:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* bpt.match.common.v1.UserStatus status */ 4:
                    message.status = reader.int32();
                    break;
                case /* string PrizeRawAmount = 5 [json_name = "PrizeRawAmount"];*/ 5:
                    message.PrizeRawAmount = reader.string();
                    break;
                case /* uint32 reBuyCnt */ 6:
                    message.reBuyCnt = reader.uint32();
                    break;
                case /* uint32 addOnCnt */ 7:
                    message.addOnCnt = reader.uint32();
                    break;
                case /* uint32 reEntryCnt */ 8:
                    message.reEntryCnt = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRankInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).uint32(message.rank);
        /* uint64 chips = 3; */
        if (message.chips !== 0)
            writer.tag(3, WireType.Varint).uint64(message.chips);
        /* bpt.match.common.v1.UserStatus status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).int32(message.status);
        /* string PrizeRawAmount = 5 [json_name = "PrizeRawAmount"]; */
        if (message.PrizeRawAmount !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.PrizeRawAmount);
        /* uint32 reBuyCnt = 6; */
        if (message.reBuyCnt !== 0)
            writer.tag(6, WireType.Varint).uint32(message.reBuyCnt);
        /* uint32 addOnCnt = 7; */
        if (message.addOnCnt !== 0)
            writer.tag(7, WireType.Varint).uint32(message.addOnCnt);
        /* uint32 reEntryCnt = 8; */
        if (message.reEntryCnt !== 0)
            writer.tag(8, WireType.Varint).uint32(message.reEntryCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.UserRankInfo
 */
export const UserRankInfo = new UserRankInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRankResp$Type extends MessageType<ListRankResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.ListRankResp", [
            { no: 1, name: "rankInfos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserRankInfo },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListRankResp>): ListRankResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rankInfos = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListRankResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRankResp): ListRankResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.mttmatch.v1.UserRankInfo rankInfos */ 1:
                    message.rankInfos.push(UserRankInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRankResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.mttmatch.v1.UserRankInfo rankInfos = 1; */
        for (let i = 0; i < message.rankInfos.length; i++)
            UserRankInfo.internalBinaryWrite(message.rankInfos[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ListRankResp
 */
export const ListRankResp = new ListRankResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDesksReq$Type extends MessageType<ListDesksReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.ListDesksReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListDesksReq>): ListDesksReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListDesksReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDesksReq): ListDesksReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDesksReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ListDesksReq
 */
export const ListDesksReq = new ListDesksReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeatInfo$Type extends MessageType<SeatInfo> {
    constructor() {
        super("bpt.match.mttmatch.v1.SeatInfo", [
            { no: 1, name: "seatNo", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SeatInfo>): SeatInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.seatNo = 0;
        message.uid = 0;
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<SeatInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeatInfo): SeatInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 seatNo */ 1:
                    message.seatNo = reader.uint32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 chips */ 3:
                    message.chips = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeatInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 seatNo = 1; */
        if (message.seatNo !== 0)
            writer.tag(1, WireType.Varint).uint32(message.seatNo);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* uint64 chips = 3; */
        if (message.chips !== 0)
            writer.tag(3, WireType.Varint).uint64(message.chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.SeatInfo
 */
export const SeatInfo = new SeatInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeskInfo$Type extends MessageType<DeskInfo> {
    constructor() {
        super("bpt.match.mttmatch.v1.DeskInfo", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "seatInfos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SeatInfo },
            { no: 4, name: "dealerSeat", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "tableID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "smStack", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "lgStack", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeskInfo>): DeskInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.srvID = 0;
        message.seatInfos = [];
        message.dealerSeat = 0;
        message.tableID = 0;
        message.smStack = 0;
        message.lgStack = 0;
        if (value !== undefined)
            reflectionMergePartial<DeskInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeskInfo): DeskInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* int64 srvID */ 2:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.mttmatch.v1.SeatInfo seatInfos */ 3:
                    message.seatInfos.push(SeatInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 dealerSeat */ 4:
                    message.dealerSeat = reader.uint32();
                    break;
                case /* int64 tableID */ 5:
                    message.tableID = reader.int64().toNumber();
                    break;
                case /* int64 smStack */ 6:
                    message.smStack = reader.int64().toNumber();
                    break;
                case /* int64 lgStack */ 7:
                    message.lgStack = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeskInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* int64 srvID = 2; */
        if (message.srvID !== 0)
            writer.tag(2, WireType.Varint).int64(message.srvID);
        /* repeated bpt.match.mttmatch.v1.SeatInfo seatInfos = 3; */
        for (let i = 0; i < message.seatInfos.length; i++)
            SeatInfo.internalBinaryWrite(message.seatInfos[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint32 dealerSeat = 4; */
        if (message.dealerSeat !== 0)
            writer.tag(4, WireType.Varint).uint32(message.dealerSeat);
        /* int64 tableID = 5; */
        if (message.tableID !== 0)
            writer.tag(5, WireType.Varint).int64(message.tableID);
        /* int64 smStack = 6; */
        if (message.smStack !== 0)
            writer.tag(6, WireType.Varint).int64(message.smStack);
        /* int64 lgStack = 7; */
        if (message.lgStack !== 0)
            writer.tag(7, WireType.Varint).int64(message.lgStack);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.DeskInfo
 */
export const DeskInfo = new DeskInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDesksResp$Type extends MessageType<ListDesksResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.ListDesksResp", [
            { no: 1, name: "deskInfos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DeskInfo },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListDesksResp>): ListDesksResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskInfos = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListDesksResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDesksResp): ListDesksResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.mttmatch.v1.DeskInfo deskInfos */ 1:
                    message.deskInfos.push(DeskInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDesksResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.mttmatch.v1.DeskInfo deskInfos = 1; */
        for (let i = 0; i < message.deskInfos.length; i++)
            DeskInfo.internalBinaryWrite(message.deskInfos[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ListDesksResp
 */
export const ListDesksResp = new ListDesksResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DumpReq$Type extends MessageType<DumpReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.DumpReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DumpReq>): DumpReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<DumpReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DumpReq): DumpReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DumpReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.DumpReq
 */
export const DumpReq = new DumpReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewestMatchReq$Type extends MessageType<GetNewestMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetNewestMatchReq", [
            { no: 1, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewestMatchReq>): GetNewestMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.confID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetNewestMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewestMatchReq): GetNewestMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 confID */ 1:
                    message.confID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewestMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 confID = 1; */
        if (message.confID !== 0)
            writer.tag(1, WireType.Varint).int64(message.confID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetNewestMatchReq
 */
export const GetNewestMatchReq = new GetNewestMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewestMatchResp$Type extends MessageType<GetNewestMatchResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetNewestMatchResp", [
            { no: 1, name: "match", kind: "message", T: () => Match }
        ]);
    }
    create(value?: PartialMessage<GetNewestMatchResp>): GetNewestMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNewestMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewestMatchResp): GetNewestMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Match match */ 1:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewestMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Match match = 1; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetNewestMatchResp
 */
export const GetNewestMatchResp = new GetNewestMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchReq$Type extends MessageType<BatchGetMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetMatchReq", [
            { no: 2, name: "matchKeys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchReq>): BatchGetMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKeys = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchReq): BatchGetMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string matchKeys */ 2:
                    message.matchKeys.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string matchKeys = 2; */
        for (let i = 0; i < message.matchKeys.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.matchKeys[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetMatchReq
 */
export const BatchGetMatchReq = new BatchGetMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchResp$Type extends MessageType<BatchGetMatchResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetMatchResp", [
            { no: 1, name: "matches", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Match }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchResp>): BatchGetMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matches = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchResp): BatchGetMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.Match matches */ 1:
                    message.matches.push(Match.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.Match matches = 1; */
        for (let i = 0; i < message.matches.length; i++)
            Match.internalBinaryWrite(message.matches[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetMatchResp
 */
export const BatchGetMatchResp = new BatchGetMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserReq$Type extends MessageType<BatchGetUserReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetUserReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserReq>): BatchGetUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserReq): BatchGetUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetUserReq
 */
export const BatchGetUserReq = new BatchGetUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserResp$Type extends MessageType<BatchGetUserResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetUserResp", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserResp>): BatchGetUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserResp): BatchGetUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetUserResp
 */
export const BatchGetUserResp = new BatchGetUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObserveTableReq$Type extends MessageType<ObserveTableReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.ObserveTableReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "preferredTableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "mode", kind: "enum", T: () => ["bpt.match.mttmatch.v1.SelectMode", SelectMode] },
            { no: 4, name: "currentTableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ObserveTableReq>): ObserveTableReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.preferredTableKey = "";
        message.mode = 0;
        message.currentTableKey = "";
        if (value !== undefined)
            reflectionMergePartial<ObserveTableReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObserveTableReq): ObserveTableReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string preferredTableKey */ 2:
                    message.preferredTableKey = reader.string();
                    break;
                case /* bpt.match.mttmatch.v1.SelectMode mode */ 3:
                    message.mode = reader.int32();
                    break;
                case /* string currentTableKey */ 4:
                    message.currentTableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ObserveTableReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string preferredTableKey = 2; */
        if (message.preferredTableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.preferredTableKey);
        /* bpt.match.mttmatch.v1.SelectMode mode = 3; */
        if (message.mode !== 0)
            writer.tag(3, WireType.Varint).int32(message.mode);
        /* string currentTableKey = 4; */
        if (message.currentTableKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.currentTableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ObserveTableReq
 */
export const ObserveTableReq = new ObserveTableReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObserveTableResp$Type extends MessageType<ObserveTableResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.ObserveTableResp", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableSrvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "remainTableCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ObserveTableResp>): ObserveTableResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.tableSrvID = 0;
        message.remainTableCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<ObserveTableResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObserveTableResp): ObserveTableResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* int64 tableSrvID */ 2:
                    message.tableSrvID = reader.int64().toNumber();
                    break;
                case /* int32 remainTableCnt */ 3:
                    message.remainTableCnt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ObserveTableResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* int64 tableSrvID = 2; */
        if (message.tableSrvID !== 0)
            writer.tag(2, WireType.Varint).int64(message.tableSrvID);
        /* int32 remainTableCnt = 3; */
        if (message.remainTableCnt !== 0)
            writer.tag(3, WireType.Varint).int32(message.remainTableCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ObserveTableResp
 */
export const ObserveTableResp = new ObserveTableResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObserveTableOnBubbleReq$Type extends MessageType<ObserveTableOnBubbleReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.ObserveTableOnBubbleReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "mode", kind: "enum", T: () => ["bpt.match.mttmatch.v1.SelectMode", SelectMode] },
            { no: 4, name: "currentTableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ObserveTableOnBubbleReq>): ObserveTableOnBubbleReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.mode = 0;
        message.currentTableKey = "";
        if (value !== undefined)
            reflectionMergePartial<ObserveTableOnBubbleReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObserveTableOnBubbleReq): ObserveTableOnBubbleReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.mttmatch.v1.SelectMode mode */ 3:
                    message.mode = reader.int32();
                    break;
                case /* string currentTableKey */ 4:
                    message.currentTableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ObserveTableOnBubbleReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.mttmatch.v1.SelectMode mode = 3; */
        if (message.mode !== 0)
            writer.tag(3, WireType.Varint).int32(message.mode);
        /* string currentTableKey = 4; */
        if (message.currentTableKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.currentTableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ObserveTableOnBubbleReq
 */
export const ObserveTableOnBubbleReq = new ObserveTableOnBubbleReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ObserveTableOnBubbleResp$Type extends MessageType<ObserveTableOnBubbleResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.ObserveTableOnBubbleResp", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableSrvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "remainTableCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ObserveTableOnBubbleResp>): ObserveTableOnBubbleResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.tableSrvID = 0;
        message.remainTableCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<ObserveTableOnBubbleResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ObserveTableOnBubbleResp): ObserveTableOnBubbleResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* int64 tableSrvID */ 2:
                    message.tableSrvID = reader.int64().toNumber();
                    break;
                case /* int32 remainTableCnt */ 3:
                    message.remainTableCnt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ObserveTableOnBubbleResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* int64 tableSrvID = 2; */
        if (message.tableSrvID !== 0)
            writer.tag(2, WireType.Varint).int64(message.tableSrvID);
        /* int32 remainTableCnt = 3; */
        if (message.remainTableCnt !== 0)
            writer.tag(3, WireType.Varint).int32(message.remainTableCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ObserveTableOnBubbleResp
 */
export const ObserveTableOnBubbleResp = new ObserveTableOnBubbleResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyTableReq$Type extends MessageType<GetMyTableReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetMyTableReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMyTableReq>): GetMyTableReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMyTableReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMyTableReq): GetMyTableReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMyTableReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetMyTableReq
 */
export const GetMyTableReq = new GetMyTableReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TableInfo$Type extends MessageType<TableInfo> {
    constructor() {
        super("bpt.match.mttmatch.v1.TableInfo", [
            { no: 1, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "deskStatus", kind: "enum", T: () => ["bpt.match.common.v1.DeskStatus", DeskStatus] }
        ]);
    }
    create(value?: PartialMessage<TableInfo>): TableInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.srvID = 0;
        message.tableKey = "";
        message.deskStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<TableInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TableInfo): TableInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 srvID */ 1:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* string tableKey */ 2:
                    message.tableKey = reader.string();
                    break;
                case /* bpt.match.common.v1.DeskStatus deskStatus */ 3:
                    message.deskStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TableInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 srvID = 1; */
        if (message.srvID !== 0)
            writer.tag(1, WireType.Varint).int64(message.srvID);
        /* string tableKey = 2; */
        if (message.tableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tableKey);
        /* bpt.match.common.v1.DeskStatus deskStatus = 3; */
        if (message.deskStatus !== 0)
            writer.tag(3, WireType.Varint).int32(message.deskStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.TableInfo
 */
export const TableInfo = new TableInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyTableResp$Type extends MessageType<GetMyTableResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetMyTableResp", [
            { no: 1, name: "playingTable", kind: "message", T: () => TableInfo },
            { no: 2, name: "observingTable", kind: "message", T: () => TableInfo },
            { no: 3, name: "observableTableCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "bubbleObservableTableCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetMyTableResp>): GetMyTableResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.observableTableCnt = 0;
        message.bubbleObservableTableCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMyTableResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMyTableResp): GetMyTableResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.mttmatch.v1.TableInfo playingTable */ 1:
                    message.playingTable = TableInfo.internalBinaryRead(reader, reader.uint32(), options, message.playingTable);
                    break;
                case /* bpt.match.mttmatch.v1.TableInfo observingTable */ 2:
                    message.observingTable = TableInfo.internalBinaryRead(reader, reader.uint32(), options, message.observingTable);
                    break;
                case /* int32 observableTableCnt */ 3:
                    message.observableTableCnt = reader.int32();
                    break;
                case /* int32 bubbleObservableTableCnt */ 4:
                    message.bubbleObservableTableCnt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMyTableResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.mttmatch.v1.TableInfo playingTable = 1; */
        if (message.playingTable)
            TableInfo.internalBinaryWrite(message.playingTable, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.mttmatch.v1.TableInfo observingTable = 2; */
        if (message.observingTable)
            TableInfo.internalBinaryWrite(message.observingTable, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 observableTableCnt = 3; */
        if (message.observableTableCnt !== 0)
            writer.tag(3, WireType.Varint).int32(message.observableTableCnt);
        /* int32 bubbleObservableTableCnt = 4; */
        if (message.bubbleObservableTableCnt !== 0)
            writer.tag(4, WireType.Varint).int32(message.bubbleObservableTableCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetMyTableResp
 */
export const GetMyTableResp = new GetMyTableResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchAndUserStatusReq$Type extends MessageType<BatchGetMatchAndUserStatusReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetMatchAndUserStatusReq", [
            { no: 1, name: "matchKeys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchAndUserStatusReq>): BatchGetMatchAndUserStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKeys = [];
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchAndUserStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchAndUserStatusReq): BatchGetMatchAndUserStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string matchKeys */ 1:
                    message.matchKeys.push(reader.string());
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchAndUserStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string matchKeys = 1; */
        for (let i = 0; i < message.matchKeys.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.matchKeys[i]);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetMatchAndUserStatusReq
 */
export const BatchGetMatchAndUserStatusReq = new BatchGetMatchAndUserStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchAndUserStatus$Type extends MessageType<MatchAndUserStatus> {
    constructor() {
        super("bpt.match.mttmatch.v1.MatchAndUserStatus", [
            { no: 1, name: "matchStatus", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 2, name: "userStatus", kind: "enum", T: () => ["bpt.match.common.v1.UserStatus", UserStatus] },
            { no: 3, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "canEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "pauseBeginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "pauseEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "userCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MatchAndUserStatus>): MatchAndUserStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchStatus = 0;
        message.userStatus = 0;
        message.isRegister = false;
        message.canEnter = false;
        message.matchKey = "";
        message.pauseBeginAt = 0;
        message.pauseEndAt = 0;
        message.userCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchAndUserStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchAndUserStatus): MatchAndUserStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchStatus matchStatus */ 1:
                    message.matchStatus = reader.int32();
                    break;
                case /* bpt.match.common.v1.UserStatus userStatus */ 2:
                    message.userStatus = reader.int32();
                    break;
                case /* bool isRegister */ 3:
                    message.isRegister = reader.bool();
                    break;
                case /* bool canEnter */ 4:
                    message.canEnter = reader.bool();
                    break;
                case /* string matchKey */ 5:
                    message.matchKey = reader.string();
                    break;
                case /* int64 pauseBeginAt */ 7:
                    message.pauseBeginAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseEndAt */ 8:
                    message.pauseEndAt = reader.int64().toNumber();
                    break;
                case /* int64 userCnt */ 9:
                    message.userCnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchAndUserStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchStatus matchStatus = 1; */
        if (message.matchStatus !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchStatus);
        /* bpt.match.common.v1.UserStatus userStatus = 2; */
        if (message.userStatus !== 0)
            writer.tag(2, WireType.Varint).int32(message.userStatus);
        /* bool isRegister = 3; */
        if (message.isRegister !== false)
            writer.tag(3, WireType.Varint).bool(message.isRegister);
        /* bool canEnter = 4; */
        if (message.canEnter !== false)
            writer.tag(4, WireType.Varint).bool(message.canEnter);
        /* string matchKey = 5; */
        if (message.matchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchKey);
        /* int64 pauseBeginAt = 7; */
        if (message.pauseBeginAt !== 0)
            writer.tag(7, WireType.Varint).int64(message.pauseBeginAt);
        /* int64 pauseEndAt = 8; */
        if (message.pauseEndAt !== 0)
            writer.tag(8, WireType.Varint).int64(message.pauseEndAt);
        /* int64 userCnt = 9; */
        if (message.userCnt !== 0)
            writer.tag(9, WireType.Varint).int64(message.userCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MatchAndUserStatus
 */
export const MatchAndUserStatus = new MatchAndUserStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchAndUserStatusResp$Type extends MessageType<BatchGetMatchAndUserStatusResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetMatchAndUserStatusResp", [
            { no: 1, name: "matchAndUserStatus", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchAndUserStatus }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchAndUserStatusResp>): BatchGetMatchAndUserStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchAndUserStatus = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchAndUserStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchAndUserStatusResp): BatchGetMatchAndUserStatusResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.mttmatch.v1.MatchAndUserStatus matchAndUserStatus */ 1:
                    message.matchAndUserStatus.push(MatchAndUserStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchAndUserStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.mttmatch.v1.MatchAndUserStatus matchAndUserStatus = 1; */
        for (let i = 0; i < message.matchAndUserStatus.length; i++)
            MatchAndUserStatus.internalBinaryWrite(message.matchAndUserStatus[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetMatchAndUserStatusResp
 */
export const BatchGetMatchAndUserStatusResp = new BatchGetMatchAndUserStatusResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UseShuffleCardReq$Type extends MessageType<UseShuffleCardReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.UseShuffleCardReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UseShuffleCardReq>): UseShuffleCardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.text = "";
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<UseShuffleCardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UseShuffleCardReq): UseShuffleCardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string text */ 2:
                    message.text = reader.string();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UseShuffleCardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string text = 2; */
        if (message.text !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.text);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.UseShuffleCardReq
 */
export const UseShuffleCardReq = new UseShuffleCardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseMatchReq$Type extends MessageType<PauseMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.PauseMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pauseDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PauseMatchReq>): PauseMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.pauseDeadline = 0;
        if (value !== undefined)
            reflectionMergePartial<PauseMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseMatchReq): PauseMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 pauseDeadline */ 2:
                    message.pauseDeadline = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 pauseDeadline = 2; */
        if (message.pauseDeadline !== 0)
            writer.tag(2, WireType.Varint).int64(message.pauseDeadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.PauseMatchReq
 */
export const PauseMatchReq = new PauseMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResumeMatchReq$Type extends MessageType<ResumeMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.ResumeMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResumeMatchReq>): ResumeMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ResumeMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResumeMatchReq): ResumeMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResumeMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ResumeMatchReq
 */
export const ResumeMatchReq = new ResumeMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMatchReq$Type extends MessageType<UpdateMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.UpdateMatchReq", [
            { no: 1, name: "match", kind: "message", T: () => Match }
        ]);
    }
    create(value?: PartialMessage<UpdateMatchReq>): UpdateMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMatchReq): UpdateMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Match match */ 1:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Match match = 1; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.UpdateMatchReq
 */
export const UpdateMatchReq = new UpdateMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchReq$Type extends MessageType<DeleteMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.DeleteMatchReq", [
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchReq>): DeleteMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchReq): DeleteMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.DeleteMatchReq
 */
export const DeleteMatchReq = new DeleteMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelMatchReq$Type extends MessageType<CancelMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.CancelMatchReq", [
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelMatchReq>): CancelMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<CancelMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelMatchReq): CancelMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CancelMatchReq
 */
export const CancelMatchReq = new CancelMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StopMatchReq$Type extends MessageType<StopMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.StopMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<StopMatchReq>): StopMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<StopMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StopMatchReq): StopMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StopMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.StopMatchReq
 */
export const StopMatchReq = new StopMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelMatchResp$Type extends MessageType<CancelMatchResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.CancelMatchResp", [
            { no: 6, name: "successTS", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "match", kind: "message", T: () => Match },
            { no: 8, name: "matchPoolContext", kind: "message", T: () => MatchContext },
            { no: 9, name: "isMatchOver", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "finalTableUserNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "isHeadUp", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CancelMatchResp>): CancelMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.successTS = [];
        message.isMatchOver = false;
        message.finalTableUserNum = 0;
        message.isHeadUp = false;
        if (value !== undefined)
            reflectionMergePartial<CancelMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelMatchResp): CancelMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string successTS */ 6:
                    message.successTS.push(reader.string());
                    break;
                case /* bpt.match.common.v1.Match match */ 7:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                case /* bpt.match.common.v1.MatchContext matchPoolContext */ 8:
                    message.matchPoolContext = MatchContext.internalBinaryRead(reader, reader.uint32(), options, message.matchPoolContext);
                    break;
                case /* bool isMatchOver */ 9:
                    message.isMatchOver = reader.bool();
                    break;
                case /* int64 finalTableUserNum */ 10:
                    message.finalTableUserNum = reader.int64().toNumber();
                    break;
                case /* bool isHeadUp */ 12:
                    message.isHeadUp = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string successTS = 6; */
        for (let i = 0; i < message.successTS.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.successTS[i]);
        /* bpt.match.common.v1.Match match = 7; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchContext matchPoolContext = 8; */
        if (message.matchPoolContext)
            MatchContext.internalBinaryWrite(message.matchPoolContext, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bool isMatchOver = 9; */
        if (message.isMatchOver !== false)
            writer.tag(9, WireType.Varint).bool(message.isMatchOver);
        /* int64 finalTableUserNum = 10; */
        if (message.finalTableUserNum !== 0)
            writer.tag(10, WireType.Varint).int64(message.finalTableUserNum);
        /* bool isHeadUp = 12; */
        if (message.isHeadUp !== false)
            writer.tag(12, WireType.Varint).bool(message.isHeadUp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CancelMatchResp
 */
export const CancelMatchResp = new CancelMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelayRegisterReq$Type extends MessageType<DelayRegisterReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.DelayRegisterReq", [
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "user", kind: "message", T: () => RegisterUser },
            { no: 6, name: "registerWay", kind: "enum", T: () => ["bpt.match.common.v1.RegisterWay", RegisterWay] },
            { no: 7, name: "ticket", kind: "message", T: () => Ticket },
            { no: 8, name: "totalDeduplicatedEnterCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DelayRegisterReq>): DelayRegisterReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.registerWay = 0;
        message.totalDeduplicatedEnterCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<DelayRegisterReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelayRegisterReq): DelayRegisterReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.RegisterUser user */ 5:
                    message.user = RegisterUser.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* bpt.match.common.v1.RegisterWay registerWay */ 6:
                    message.registerWay = reader.int32();
                    break;
                case /* bpt.match.common.v1.Ticket ticket */ 7:
                    message.ticket = Ticket.internalBinaryRead(reader, reader.uint32(), options, message.ticket);
                    break;
                case /* int64 totalDeduplicatedEnterCnt */ 8:
                    message.totalDeduplicatedEnterCnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelayRegisterReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.RegisterUser user = 5; */
        if (message.user)
            RegisterUser.internalBinaryWrite(message.user, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.RegisterWay registerWay = 6; */
        if (message.registerWay !== 0)
            writer.tag(6, WireType.Varint).int32(message.registerWay);
        /* bpt.match.common.v1.Ticket ticket = 7; */
        if (message.ticket)
            Ticket.internalBinaryWrite(message.ticket, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalDeduplicatedEnterCnt = 8; */
        if (message.totalDeduplicatedEnterCnt !== 0)
            writer.tag(8, WireType.Varint).int64(message.totalDeduplicatedEnterCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.DelayRegisterReq
 */
export const DelayRegisterReq = new DelayRegisterReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReBuyReq$Type extends MessageType<ReBuyReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.ReBuyReq", [
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReBuyReq>): ReBuyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ReBuyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReBuyReq): ReBuyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReBuyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ReBuyReq
 */
export const ReBuyReq = new ReBuyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReBuyResp$Type extends MessageType<ReBuyResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.ReBuyResp", [
            { no: 1, name: "remainTimes", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "txInfo", kind: "message", T: () => TxInfo }
        ]);
    }
    create(value?: PartialMessage<ReBuyResp>): ReBuyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.remainTimes = 0;
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<ReBuyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReBuyResp): ReBuyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 remainTimes */ 1:
                    message.remainTimes = reader.uint32();
                    break;
                case /* uint64 chips */ 2:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* bpt.match.mttmatch.v1.TxInfo txInfo */ 3:
                    message.txInfo = TxInfo.internalBinaryRead(reader, reader.uint32(), options, message.txInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReBuyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 remainTimes = 1; */
        if (message.remainTimes !== 0)
            writer.tag(1, WireType.Varint).uint32(message.remainTimes);
        /* uint64 chips = 2; */
        if (message.chips !== 0)
            writer.tag(2, WireType.Varint).uint64(message.chips);
        /* bpt.match.mttmatch.v1.TxInfo txInfo = 3; */
        if (message.txInfo)
            TxInfo.internalBinaryWrite(message.txInfo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ReBuyResp
 */
export const ReBuyResp = new ReBuyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReEntryReq$Type extends MessageType<ReEntryReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.ReEntryReq", [
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "registerWay", kind: "enum", T: () => ["bpt.match.common.v1.RegisterWay", RegisterWay] },
            { no: 4, name: "tickets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Ticket }
        ]);
    }
    create(value?: PartialMessage<ReEntryReq>): ReEntryReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.registerWay = 0;
        message.tickets = [];
        if (value !== undefined)
            reflectionMergePartial<ReEntryReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReEntryReq): ReEntryReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.RegisterWay registerWay */ 3:
                    message.registerWay = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.Ticket tickets */ 4:
                    message.tickets.push(Ticket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReEntryReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.RegisterWay registerWay = 3; */
        if (message.registerWay !== 0)
            writer.tag(3, WireType.Varint).int32(message.registerWay);
        /* repeated bpt.match.common.v1.Ticket tickets = 4; */
        for (let i = 0; i < message.tickets.length; i++)
            Ticket.internalBinaryWrite(message.tickets[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ReEntryReq
 */
export const ReEntryReq = new ReEntryReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReEntryResp$Type extends MessageType<ReEntryResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.ReEntryResp", [
            { no: 3, name: "txInfo", kind: "message", T: () => TxInfo }
        ]);
    }
    create(value?: PartialMessage<ReEntryResp>): ReEntryResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReEntryResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReEntryResp): ReEntryResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.mttmatch.v1.TxInfo txInfo */ 3:
                    message.txInfo = TxInfo.internalBinaryRead(reader, reader.uint32(), options, message.txInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReEntryResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.mttmatch.v1.TxInfo txInfo = 3; */
        if (message.txInfo)
            TxInfo.internalBinaryWrite(message.txInfo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ReEntryResp
 */
export const ReEntryResp = new ReEntryResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelReBuyReq$Type extends MessageType<CancelReBuyReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.CancelReBuyReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelReBuyReq>): CancelReBuyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<CancelReBuyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelReBuyReq): CancelReBuyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelReBuyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CancelReBuyReq
 */
export const CancelReBuyReq = new CancelReBuyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelReBuyResp$Type extends MessageType<CancelReBuyResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.CancelReBuyResp", []);
    }
    create(value?: PartialMessage<CancelReBuyResp>): CancelReBuyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CancelReBuyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelReBuyResp): CancelReBuyResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CancelReBuyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CancelReBuyResp
 */
export const CancelReBuyResp = new CancelReBuyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelAddOnReq$Type extends MessageType<CancelAddOnReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.CancelAddOnReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelAddOnReq>): CancelAddOnReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<CancelAddOnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelAddOnReq): CancelAddOnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelAddOnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CancelAddOnReq
 */
export const CancelAddOnReq = new CancelAddOnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelAddOnResp$Type extends MessageType<CancelAddOnResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.CancelAddOnResp", []);
    }
    create(value?: PartialMessage<CancelAddOnResp>): CancelAddOnResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CancelAddOnResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelAddOnResp): CancelAddOnResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CancelAddOnResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CancelAddOnResp
 */
export const CancelAddOnResp = new CancelAddOnResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOnReq$Type extends MessageType<AddOnReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.AddOnReq", [
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AddOnReq>): AddOnReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<AddOnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOnReq): AddOnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* int64 count */ 4:
                    message.count = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* int64 count = 4; */
        if (message.count !== 0)
            writer.tag(4, WireType.Varint).int64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.AddOnReq
 */
export const AddOnReq = new AddOnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOnResp$Type extends MessageType<AddOnResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.AddOnResp", [
            { no: 1, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "txInfo", kind: "message", T: () => TxInfo }
        ]);
    }
    create(value?: PartialMessage<AddOnResp>): AddOnResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<AddOnResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOnResp): AddOnResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 chips */ 1:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* bpt.match.mttmatch.v1.TxInfo txInfo */ 2:
                    message.txInfo = TxInfo.internalBinaryRead(reader, reader.uint32(), options, message.txInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOnResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 chips = 1; */
        if (message.chips !== 0)
            writer.tag(1, WireType.Varint).uint64(message.chips);
        /* bpt.match.mttmatch.v1.TxInfo txInfo = 2; */
        if (message.txInfo)
            TxInfo.internalBinaryWrite(message.txInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.AddOnResp
 */
export const AddOnResp = new AddOnResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAddOnReq$Type extends MessageType<CheckAddOnReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.CheckAddOnReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CheckAddOnReq>): CheckAddOnReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.userID = 0;
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckAddOnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAddOnReq): CheckAddOnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* int64 count */ 4:
                    message.count = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckAddOnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        /* int64 count = 4; */
        if (message.count !== 0)
            writer.tag(4, WireType.Varint).int64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CheckAddOnReq
 */
export const CheckAddOnReq = new CheckAddOnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckAddOnResp$Type extends MessageType<CheckAddOnResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.CheckAddOnResp", []);
    }
    create(value?: PartialMessage<CheckAddOnResp>): CheckAddOnResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CheckAddOnResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckAddOnResp): CheckAddOnResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CheckAddOnResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CheckAddOnResp
 */
export const CheckAddOnResp = new CheckAddOnResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinishAddOnReq$Type extends MessageType<FinishAddOnReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.FinishAddOnReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "txID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FinishAddOnReq>): FinishAddOnReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.userID = 0;
        message.count = 0;
        message.txID = "";
        if (value !== undefined)
            reflectionMergePartial<FinishAddOnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinishAddOnReq): FinishAddOnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* int64 count */ 3:
                    message.count = reader.int64().toNumber();
                    break;
                case /* string txID */ 4:
                    message.txID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinishAddOnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        /* int64 count = 3; */
        if (message.count !== 0)
            writer.tag(3, WireType.Varint).int64(message.count);
        /* string txID = 4; */
        if (message.txID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.txID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.FinishAddOnReq
 */
export const FinishAddOnReq = new FinishAddOnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinishAddOnResp$Type extends MessageType<FinishAddOnResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.FinishAddOnResp", []);
    }
    create(value?: PartialMessage<FinishAddOnResp>): FinishAddOnResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<FinishAddOnResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinishAddOnResp): FinishAddOnResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FinishAddOnResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.FinishAddOnResp
 */
export const FinishAddOnResp = new FinishAddOnResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckReBuyReq$Type extends MessageType<CheckReBuyReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.CheckReBuyReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CheckReBuyReq>): CheckReBuyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckReBuyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckReBuyReq): CheckReBuyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckReBuyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CheckReBuyReq
 */
export const CheckReBuyReq = new CheckReBuyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckReBuyResp$Type extends MessageType<CheckReBuyResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.CheckReBuyResp", []);
    }
    create(value?: PartialMessage<CheckReBuyResp>): CheckReBuyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CheckReBuyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckReBuyResp): CheckReBuyResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CheckReBuyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CheckReBuyResp
 */
export const CheckReBuyResp = new CheckReBuyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinishReBuyReq$Type extends MessageType<FinishReBuyReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.FinishReBuyReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "txID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FinishReBuyReq>): FinishReBuyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.userID = 0;
        message.txID = "";
        if (value !== undefined)
            reflectionMergePartial<FinishReBuyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinishReBuyReq): FinishReBuyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* string txID */ 4:
                    message.txID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinishReBuyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        /* string txID = 4; */
        if (message.txID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.txID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.FinishReBuyReq
 */
export const FinishReBuyReq = new FinishReBuyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinishReBuyResp$Type extends MessageType<FinishReBuyResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.FinishReBuyResp", []);
    }
    create(value?: PartialMessage<FinishReBuyResp>): FinishReBuyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<FinishReBuyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinishReBuyResp): FinishReBuyResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FinishReBuyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.FinishReBuyResp
 */
export const FinishReBuyResp = new FinishReBuyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckReEntryReq$Type extends MessageType<CheckReEntryReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.CheckReEntryReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CheckReEntryReq>): CheckReEntryReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckReEntryReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckReEntryReq): CheckReEntryReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckReEntryReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CheckReEntryReq
 */
export const CheckReEntryReq = new CheckReEntryReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckReEntryResp$Type extends MessageType<CheckReEntryResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.CheckReEntryResp", []);
    }
    create(value?: PartialMessage<CheckReEntryResp>): CheckReEntryResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CheckReEntryResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckReEntryResp): CheckReEntryResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CheckReEntryResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.CheckReEntryResp
 */
export const CheckReEntryResp = new CheckReEntryResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinishReEntryReq$Type extends MessageType<FinishReEntryReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.FinishReEntryReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "txID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "registerWay", kind: "enum", T: () => ["bpt.match.common.v1.RegisterWay", RegisterWay] }
        ]);
    }
    create(value?: PartialMessage<FinishReEntryReq>): FinishReEntryReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.userID = 0;
        message.txID = "";
        message.registerWay = 0;
        if (value !== undefined)
            reflectionMergePartial<FinishReEntryReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinishReEntryReq): FinishReEntryReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* string txID */ 3:
                    message.txID = reader.string();
                    break;
                case /* bpt.match.common.v1.RegisterWay registerWay */ 5:
                    message.registerWay = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FinishReEntryReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        /* string txID = 3; */
        if (message.txID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.txID);
        /* bpt.match.common.v1.RegisterWay registerWay = 5; */
        if (message.registerWay !== 0)
            writer.tag(5, WireType.Varint).int32(message.registerWay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.FinishReEntryReq
 */
export const FinishReEntryReq = new FinishReEntryReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FinishReEntryResp$Type extends MessageType<FinishReEntryResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.FinishReEntryResp", []);
    }
    create(value?: PartialMessage<FinishReEntryResp>): FinishReEntryResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<FinishReEntryResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FinishReEntryResp): FinishReEntryResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: FinishReEntryResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.FinishReEntryResp
 */
export const FinishReEntryResp = new FinishReEntryResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GameOverParams$Type extends MessageType<GameOverParams> {
    constructor() {
        super("bpt.match.mttmatch.v1.GameOverParams", [
            { no: 1, name: "gameOverType", kind: "enum", T: () => ["bpt.match.common.v1.GameOverType", GameOverType] },
            { no: 2, name: "gameOverBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "gameOverPercentage", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "maxAdvancePlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<GameOverParams>): GameOverParams {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.gameOverType = 0;
        message.gameOverBlindLevel = 0;
        message.gameOverPercentage = 0;
        message.maxAdvancePlayerNum = 0;
        if (value !== undefined)
            reflectionMergePartial<GameOverParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GameOverParams): GameOverParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.GameOverType gameOverType */ 1:
                    message.gameOverType = reader.int32();
                    break;
                case /* uint32 gameOverBlindLevel */ 2:
                    message.gameOverBlindLevel = reader.uint32();
                    break;
                case /* uint32 gameOverPercentage */ 3:
                    message.gameOverPercentage = reader.uint32();
                    break;
                case /* uint32 maxAdvancePlayerNum */ 4:
                    message.maxAdvancePlayerNum = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GameOverParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.GameOverType gameOverType = 1; */
        if (message.gameOverType !== 0)
            writer.tag(1, WireType.Varint).int32(message.gameOverType);
        /* uint32 gameOverBlindLevel = 2; */
        if (message.gameOverBlindLevel !== 0)
            writer.tag(2, WireType.Varint).uint32(message.gameOverBlindLevel);
        /* uint32 gameOverPercentage = 3; */
        if (message.gameOverPercentage !== 0)
            writer.tag(3, WireType.Varint).uint32(message.gameOverPercentage);
        /* uint32 maxAdvancePlayerNum = 4; */
        if (message.maxAdvancePlayerNum !== 0)
            writer.tag(4, WireType.Varint).uint32(message.maxAdvancePlayerNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GameOverParams
 */
export const GameOverParams = new GameOverParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseParams$Type extends MessageType<PauseParams> {
    constructor() {
        super("bpt.match.mttmatch.v1.PauseParams", [
            { no: 1, name: "pauseOnHour", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "pauseMinute", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PauseParams>): PauseParams {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pauseOnHour = false;
        message.pauseMinute = 0;
        if (value !== undefined)
            reflectionMergePartial<PauseParams>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseParams): PauseParams {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool pauseOnHour */ 1:
                    message.pauseOnHour = reader.bool();
                    break;
                case /* int32 pauseMinute */ 2:
                    message.pauseMinute = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseParams, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool pauseOnHour = 1; */
        if (message.pauseOnHour !== false)
            writer.tag(1, WireType.Varint).bool(message.pauseOnHour);
        /* int32 pauseMinute = 2; */
        if (message.pauseMinute !== 0)
            writer.tag(2, WireType.Varint).int32(message.pauseMinute);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.PauseParams
 */
export const PauseParams = new PauseParams$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartMatchReq$Type extends MessageType<StartMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.StartMatchReq", [
            { no: 8, name: "matchID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "ctx", kind: "message", T: () => MatchContext },
            { no: 3, name: "conf", kind: "message", T: () => MatchConf },
            { no: 6, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RegisterUser },
            { no: 24, name: "notJoinedUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RegisterUser },
            { no: 10, name: "startBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 20, name: "rollBackBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "gameOverType", kind: "enum", T: () => ["bpt.match.common.v1.GameOverType", GameOverType] },
            { no: 12, name: "gameOverBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 16, name: "isDisableFinalTable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "isDisableBubble", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "isDisablePrizeRound", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "isDisableDuel", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 23, name: "stageType", kind: "enum", T: () => ["bpt.match.common.v1.StageType", StageType] },
            { no: 25, name: "gameOverParams", kind: "message", T: () => GameOverParams },
            { no: 26, name: "pauseParams", kind: "message", T: () => PauseParams }
        ]);
    }
    create(value?: PartialMessage<StartMatchReq>): StartMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchID = 0;
        message.matchKey = "";
        message.users = [];
        message.notJoinedUsers = [];
        message.startBlindLevel = 0;
        message.rollBackBlindLevel = 0;
        message.gameOverType = 0;
        message.gameOverBlindLevel = 0;
        message.isDisableFinalTable = false;
        message.isDisableBubble = false;
        message.isDisablePrizeRound = false;
        message.isDisableDuel = false;
        message.stageType = 0;
        if (value !== undefined)
            reflectionMergePartial<StartMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartMatchReq): StartMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchID */ 8:
                    message.matchID = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchContext ctx */ 9:
                    message.ctx = MatchContext.internalBinaryRead(reader, reader.uint32(), options, message.ctx);
                    break;
                case /* bpt.match.common.v1.MatchConf conf */ 3:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                case /* repeated bpt.match.common.v1.RegisterUser users */ 6:
                    message.users.push(RegisterUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.common.v1.RegisterUser notJoinedUsers */ 24:
                    message.notJoinedUsers.push(RegisterUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 startBlindLevel */ 10:
                    message.startBlindLevel = reader.uint32();
                    break;
                case /* uint32 rollBackBlindLevel */ 20:
                    message.rollBackBlindLevel = reader.uint32();
                    break;
                case /* bpt.match.common.v1.GameOverType gameOverType */ 11:
                    message.gameOverType = reader.int32();
                    break;
                case /* uint32 gameOverBlindLevel */ 12:
                    message.gameOverBlindLevel = reader.uint32();
                    break;
                case /* bool isDisableFinalTable */ 16:
                    message.isDisableFinalTable = reader.bool();
                    break;
                case /* bool isDisableBubble */ 17:
                    message.isDisableBubble = reader.bool();
                    break;
                case /* bool isDisablePrizeRound */ 18:
                    message.isDisablePrizeRound = reader.bool();
                    break;
                case /* bool isDisableDuel */ 21:
                    message.isDisableDuel = reader.bool();
                    break;
                case /* bpt.match.common.v1.StageType stageType */ 23:
                    message.stageType = reader.int32();
                    break;
                case /* bpt.match.mttmatch.v1.GameOverParams gameOverParams */ 25:
                    message.gameOverParams = GameOverParams.internalBinaryRead(reader, reader.uint32(), options, message.gameOverParams);
                    break;
                case /* bpt.match.mttmatch.v1.PauseParams pauseParams */ 26:
                    message.pauseParams = PauseParams.internalBinaryRead(reader, reader.uint32(), options, message.pauseParams);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchID = 8; */
        if (message.matchID !== 0)
            writer.tag(8, WireType.Varint).int64(message.matchID);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchContext ctx = 9; */
        if (message.ctx)
            MatchContext.internalBinaryWrite(message.ctx, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchConf conf = 3; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.RegisterUser users = 6; */
        for (let i = 0; i < message.users.length; i++)
            RegisterUser.internalBinaryWrite(message.users[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.RegisterUser notJoinedUsers = 24; */
        for (let i = 0; i < message.notJoinedUsers.length; i++)
            RegisterUser.internalBinaryWrite(message.notJoinedUsers[i], writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* uint32 startBlindLevel = 10; */
        if (message.startBlindLevel !== 0)
            writer.tag(10, WireType.Varint).uint32(message.startBlindLevel);
        /* uint32 rollBackBlindLevel = 20; */
        if (message.rollBackBlindLevel !== 0)
            writer.tag(20, WireType.Varint).uint32(message.rollBackBlindLevel);
        /* bpt.match.common.v1.GameOverType gameOverType = 11; */
        if (message.gameOverType !== 0)
            writer.tag(11, WireType.Varint).int32(message.gameOverType);
        /* uint32 gameOverBlindLevel = 12; */
        if (message.gameOverBlindLevel !== 0)
            writer.tag(12, WireType.Varint).uint32(message.gameOverBlindLevel);
        /* bool isDisableFinalTable = 16; */
        if (message.isDisableFinalTable !== false)
            writer.tag(16, WireType.Varint).bool(message.isDisableFinalTable);
        /* bool isDisableBubble = 17; */
        if (message.isDisableBubble !== false)
            writer.tag(17, WireType.Varint).bool(message.isDisableBubble);
        /* bool isDisablePrizeRound = 18; */
        if (message.isDisablePrizeRound !== false)
            writer.tag(18, WireType.Varint).bool(message.isDisablePrizeRound);
        /* bool isDisableDuel = 21; */
        if (message.isDisableDuel !== false)
            writer.tag(21, WireType.Varint).bool(message.isDisableDuel);
        /* bpt.match.common.v1.StageType stageType = 23; */
        if (message.stageType !== 0)
            writer.tag(23, WireType.Varint).int32(message.stageType);
        /* bpt.match.mttmatch.v1.GameOverParams gameOverParams = 25; */
        if (message.gameOverParams)
            GameOverParams.internalBinaryWrite(message.gameOverParams, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.mttmatch.v1.PauseParams pauseParams = 26; */
        if (message.pauseParams)
            PauseParams.internalBinaryWrite(message.pauseParams, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.StartMatchReq
 */
export const StartMatchReq = new StartMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartMatchResp$Type extends MessageType<StartMatchResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.StartMatchResp", [
            { no: 1, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<StartMatchResp>): StartMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.srvID = 0;
        if (value !== undefined)
            reflectionMergePartial<StartMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartMatchResp): StartMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 srvID */ 1:
                    message.srvID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 srvID = 1; */
        if (message.srvID !== 0)
            writer.tag(1, WireType.Varint).int64(message.srvID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.StartMatchResp
 */
export const StartMatchResp = new StartMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetCanReEnterReq$Type extends MessageType<BatchGetCanReEnterReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetCanReEnterReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKeys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetCanReEnterReq>): BatchGetCanReEnterReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKeys = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetCanReEnterReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetCanReEnterReq): BatchGetCanReEnterReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated string matchKeys */ 2:
                    message.matchKeys.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetCanReEnterReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated string matchKeys = 2; */
        for (let i = 0; i < message.matchKeys.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.matchKeys[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetCanReEnterReq
 */
export const BatchGetCanReEnterReq = new BatchGetCanReEnterReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetCanReEnterResp$Type extends MessageType<BatchGetCanReEnterResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetCanReEnterResp", [
            { no: 1, name: "canEnters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserCanEnter }
        ]);
    }
    create(value?: PartialMessage<BatchGetCanReEnterResp>): BatchGetCanReEnterResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.canEnters = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetCanReEnterResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetCanReEnterResp): BatchGetCanReEnterResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.mttmatch.v1.UserCanEnter canEnters */ 1:
                    message.canEnters.push(UserCanEnter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetCanReEnterResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.mttmatch.v1.UserCanEnter canEnters = 1; */
        for (let i = 0; i < message.canEnters.length; i++)
            UserCanEnter.internalBinaryWrite(message.canEnters[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetCanReEnterResp
 */
export const BatchGetCanReEnterResp = new BatchGetCanReEnterResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserCanEnterReq$Type extends MessageType<BatchGetUserCanEnterReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetUserCanEnterReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKeys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserCanEnterReq>): BatchGetUserCanEnterReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKeys = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserCanEnterReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserCanEnterReq): BatchGetUserCanEnterReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated string matchKeys */ 2:
                    message.matchKeys.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserCanEnterReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated string matchKeys = 2; */
        for (let i = 0; i < message.matchKeys.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.matchKeys[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetUserCanEnterReq
 */
export const BatchGetUserCanEnterReq = new BatchGetUserCanEnterReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserCanEnterResp$Type extends MessageType<BatchGetUserCanEnterResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.BatchGetUserCanEnterResp", [
            { no: 1, name: "canEnters", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserCanEnter }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserCanEnterResp>): BatchGetUserCanEnterResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.canEnters = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserCanEnterResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserCanEnterResp): BatchGetUserCanEnterResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.mttmatch.v1.UserCanEnter canEnters */ 1:
                    message.canEnters.push(UserCanEnter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserCanEnterResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.mttmatch.v1.UserCanEnter canEnters = 1; */
        for (let i = 0; i < message.canEnters.length; i++)
            UserCanEnter.internalBinaryWrite(message.canEnters[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.BatchGetUserCanEnterResp
 */
export const BatchGetUserCanEnterResp = new BatchGetUserCanEnterResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnimationFinishReq$Type extends MessageType<AnimationFinishReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.AnimationFinishReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.match.mttmatch.v1.AnimationFinishType", AnimationFinishType] }
        ]);
    }
    create(value?: PartialMessage<AnimationFinishReq>): AnimationFinishReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<AnimationFinishReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnimationFinishReq): AnimationFinishReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.mttmatch.v1.AnimationFinishType type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnimationFinishReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.mttmatch.v1.AnimationFinishType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.AnimationFinishReq
 */
export const AnimationFinishReq = new AnimationFinishReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnimationFinishResp$Type extends MessageType<AnimationFinishResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.AnimationFinishResp", [
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AnimationFinishResp>): AnimationFinishResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<AnimationFinishResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnimationFinishResp): AnimationFinishResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnimationFinishResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.AnimationFinishResp
 */
export const AnimationFinishResp = new AnimationFinishResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserCanEnter$Type extends MessageType<UserCanEnter> {
    constructor() {
        super("bpt.match.mttmatch.v1.UserCanEnter", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "canEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UserCanEnter>): UserCanEnter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.canEnter = false;
        if (value !== undefined)
            reflectionMergePartial<UserCanEnter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserCanEnter): UserCanEnter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bool canEnter */ 2:
                    message.canEnter = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserCanEnter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bool canEnter = 2; */
        if (message.canEnter !== false)
            writer.tag(2, WireType.Varint).bool(message.canEnter);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.UserCanEnter
 */
export const UserCanEnter = new UserCanEnter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelayRegisterResp$Type extends MessageType<DelayRegisterResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.DelayRegisterResp", [
            { no: 1, name: "match", kind: "message", T: () => Match }
        ]);
    }
    create(value?: PartialMessage<DelayRegisterResp>): DelayRegisterResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DelayRegisterResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelayRegisterResp): DelayRegisterResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Match match */ 1:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelayRegisterResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Match match = 1; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.DelayRegisterResp
 */
export const DelayRegisterResp = new DelayRegisterResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinDeskMsg$Type extends MessageType<JoinDeskMsg> {
    constructor() {
        super("bpt.match.mttmatch.v1.JoinDeskMsg", [
            { no: 2, name: "deskID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "user", kind: "message", T: () => User },
            { no: 4, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "serverID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "isFinalTable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<JoinDeskMsg>): JoinDeskMsg {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskID = 0;
        message.confID = 0;
        message.serverID = 0;
        message.isFinalTable = false;
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<JoinDeskMsg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinDeskMsg): JoinDeskMsg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 deskID */ 2:
                    message.deskID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.User user */ 3:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* int64 confID */ 4:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* int64 serverID */ 5:
                    message.serverID = reader.int64().toNumber();
                    break;
                case /* bool isFinalTable */ 6:
                    message.isFinalTable = reader.bool();
                    break;
                case /* string tableKey */ 7:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinDeskMsg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 deskID = 2; */
        if (message.deskID !== 0)
            writer.tag(2, WireType.Varint).int64(message.deskID);
        /* bpt.match.common.v1.User user = 3; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 confID = 4; */
        if (message.confID !== 0)
            writer.tag(4, WireType.Varint).int64(message.confID);
        /* int64 serverID = 5; */
        if (message.serverID !== 0)
            writer.tag(5, WireType.Varint).int64(message.serverID);
        /* bool isFinalTable = 6; */
        if (message.isFinalTable !== false)
            writer.tag(6, WireType.Varint).bool(message.isFinalTable);
        /* string tableKey = 7; */
        if (message.tableKey !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.JoinDeskMsg
 */
export const JoinDeskMsg = new JoinDeskMsg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveDeskMsg$Type extends MessageType<LeaveDeskMsg> {
    constructor() {
        super("bpt.match.mttmatch.v1.LeaveDeskMsg", [
            { no: 1, name: "deskID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<LeaveDeskMsg>): LeaveDeskMsg {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskID = 0;
        if (value !== undefined)
            reflectionMergePartial<LeaveDeskMsg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LeaveDeskMsg): LeaveDeskMsg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 deskID */ 1:
                    message.deskID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.User user */ 2:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LeaveDeskMsg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 deskID = 1; */
        if (message.deskID !== 0)
            writer.tag(1, WireType.Varint).int64(message.deskID);
        /* bpt.match.common.v1.User user = 2; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.LeaveDeskMsg
 */
export const LeaveDeskMsg = new LeaveDeskMsg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchStart$Type extends MessageType<MsgMatchStart> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgMatchStart", [
            { no: 1, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "isSuccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchStart>): MsgMatchStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startedAt = 0;
        message.isSuccess = false;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchStart): MsgMatchStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 startedAt */ 1:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bool isSuccess */ 2:
                    message.isSuccess = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 startedAt = 1; */
        if (message.startedAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.startedAt);
        /* bool isSuccess = 2; */
        if (message.isSuccess !== false)
            writer.tag(2, WireType.Varint).bool(message.isSuccess);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgMatchStart
 */
export const MsgMatchStart = new MsgMatchStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrizeAndBubbleUser$Type extends MessageType<PrizeAndBubbleUser> {
    constructor() {
        super("bpt.match.mttmatch.v1.PrizeAndBubbleUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "PrizeRawAmount", kind: "scalar", localName: "PrizeRawAmount", jsonName: "PrizeRawAmount", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PrizeAndBubbleUser>): PrizeAndBubbleUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.rank = 0;
        message.PrizeRawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<PrizeAndBubbleUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrizeAndBubbleUser): PrizeAndBubbleUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint32 rank */ 2:
                    message.rank = reader.uint32();
                    break;
                case /* string PrizeRawAmount = 3 [json_name = "PrizeRawAmount"];*/ 3:
                    message.PrizeRawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrizeAndBubbleUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).uint32(message.rank);
        /* string PrizeRawAmount = 3 [json_name = "PrizeRawAmount"]; */
        if (message.PrizeRawAmount !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.PrizeRawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.PrizeAndBubbleUser
 */
export const PrizeAndBubbleUser = new PrizeAndBubbleUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchOver$Type extends MessageType<MsgMatchOver> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgMatchOver", [
            { no: 1, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "prizeAndBubbleUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrizeAndBubbleUser },
            { no: 3, name: "game", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchOver>): MsgMatchOver {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.endAt = 0;
        message.prizeAndBubbleUsers = [];
        message.game = "";
        if (value !== undefined)
            reflectionMergePartial<MsgMatchOver>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchOver): MsgMatchOver {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 endAt */ 1:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.mttmatch.v1.PrizeAndBubbleUser prizeAndBubbleUsers */ 2:
                    message.prizeAndBubbleUsers.push(PrizeAndBubbleUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string game */ 3:
                    message.game = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchOver, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 endAt = 1; */
        if (message.endAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.endAt);
        /* repeated bpt.match.mttmatch.v1.PrizeAndBubbleUser prizeAndBubbleUsers = 2; */
        for (let i = 0; i < message.prizeAndBubbleUsers.length; i++)
            PrizeAndBubbleUser.internalBinaryWrite(message.prizeAndBubbleUsers[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string game = 3; */
        if (message.game !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.game);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgMatchOver
 */
export const MsgMatchOver = new MsgMatchOver$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgDesksStart$Type extends MessageType<MsgDesksStart> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgDesksStart", [
            { no: 1, name: "desks", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Desk }
        ]);
    }
    create(value?: PartialMessage<MsgDesksStart>): MsgDesksStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.desks = [];
        if (value !== undefined)
            reflectionMergePartial<MsgDesksStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgDesksStart): MsgDesksStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.Desk desks */ 1:
                    message.desks.push(Desk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgDesksStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.Desk desks = 1; */
        for (let i = 0; i < message.desks.length; i++)
            Desk.internalBinaryWrite(message.desks[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgDesksStart
 */
export const MsgDesksStart = new MsgDesksStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgDesksWait$Type extends MessageType<MsgDesksWait> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgDesksWait", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgDesksWait>): MsgDesksWait {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgDesksWait>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgDesksWait): MsgDesksWait {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgDesksWait, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgDesksWait
 */
export const MsgDesksWait = new MsgDesksWait$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserChipAvg$Type extends MessageType<MsgUserChipAvg> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgUserChipAvg", [
            { no: 1, name: "avg", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserChipAvg>): MsgUserChipAvg {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.avg = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserChipAvg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserChipAvg): MsgUserChipAvg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 avg */ 1:
                    message.avg = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserChipAvg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 avg = 1; */
        if (message.avg !== 0)
            writer.tag(1, WireType.Varint).int64(message.avg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgUserChipAvg
 */
export const MsgUserChipAvg = new MsgUserChipAvg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgDesksDestroy$Type extends MessageType<MsgDesksDestroy> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgDesksDestroy", [
            { no: 1, name: "deskIDs", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgDesksDestroy>): MsgDesksDestroy {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskIDs = [];
        if (value !== undefined)
            reflectionMergePartial<MsgDesksDestroy>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgDesksDestroy): MsgDesksDestroy {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 deskIDs */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.deskIDs.push(reader.int64().toNumber());
                    else
                        message.deskIDs.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgDesksDestroy, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 deskIDs = 1; */
        if (message.deskIDs.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.deskIDs.length; i++)
                writer.int64(message.deskIDs[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgDesksDestroy
 */
export const MsgDesksDestroy = new MsgDesksDestroy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgRank$Type extends MessageType<MsgRank> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgRank", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<MsgRank>): MsgRank {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.users = [];
        if (value !== undefined)
            reflectionMergePartial<MsgRank>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgRank): MsgRank {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgRank, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgRank
 */
export const MsgRank = new MsgRank$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgRankChange$Type extends MessageType<MsgRankChange> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgRankChange", [
            { no: 1, name: "myRank", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "totalUser", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgRankChange>): MsgRankChange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.myRank = 0;
        message.totalUser = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgRankChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgRankChange): MsgRankChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 myRank */ 1:
                    message.myRank = reader.int64().toNumber();
                    break;
                case /* int64 totalUser */ 2:
                    message.totalUser = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgRankChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 myRank = 1; */
        if (message.myRank !== 0)
            writer.tag(1, WireType.Varint).int64(message.myRank);
        /* int64 totalUser = 2; */
        if (message.totalUser !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalUser);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgRankChange
 */
export const MsgRankChange = new MsgRankChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GoingDesk$Type extends MessageType<GoingDesk> {
    constructor() {
        super("bpt.match.mttmatch.v1.GoingDesk", [
            { no: 1, name: "deskId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "isFinalTable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GoingDesk>): GoingDesk {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskId = 0;
        message.isFinalTable = false;
        if (value !== undefined)
            reflectionMergePartial<GoingDesk>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GoingDesk): GoingDesk {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 deskId */ 1:
                    message.deskId = reader.int64().toNumber();
                    break;
                case /* bool isFinalTable */ 2:
                    message.isFinalTable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GoingDesk, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 deskId = 1; */
        if (message.deskId !== 0)
            writer.tag(1, WireType.Varint).int64(message.deskId);
        /* bool isFinalTable = 2; */
        if (message.isFinalTable !== false)
            writer.tag(2, WireType.Varint).bool(message.isFinalTable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GoingDesk
 */
export const GoingDesk = new GoingDesk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserOut$Type extends MessageType<MsgUserOut> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgUserOut", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<MsgUserOut>): MsgUserOut {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgUserOut>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserOut): MsgUserOut {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserOut, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgUserOut
 */
export const MsgUserOut = new MsgUserOut$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgBlindLevelUp$Type extends MessageType<MsgBlindLevelUp> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgBlindLevelUp", [
            { no: 1, name: "currentLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<MsgBlindLevelUp>): MsgBlindLevelUp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.currentLevel = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgBlindLevelUp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgBlindLevelUp): MsgBlindLevelUp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 currentLevel */ 1:
                    message.currentLevel = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgBlindLevelUp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 currentLevel = 1; */
        if (message.currentLevel !== 0)
            writer.tag(1, WireType.Varint).uint32(message.currentLevel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgBlindLevelUp
 */
export const MsgBlindLevelUp = new MsgBlindLevelUp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserResult$Type extends MessageType<MsgUserResult> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgUserResult", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserResult>): MsgUserResult {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserResult): MsgUserResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 chips */ 2:
                    message.chips = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint64 chips = 2; */
        if (message.chips !== 0)
            writer.tag(2, WireType.Varint).uint64(message.chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgUserResult
 */
export const MsgUserResult = new MsgUserResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserSettlement$Type extends MessageType<MsgUserSettlement> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgUserSettlement", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "settleAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "prize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 4, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "isReEntry", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserSettlement>): MsgUserSettlement {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.settleAt = 0;
        message.prize = [];
        message.rank = 0;
        message.isReEntry = false;
        if (value !== undefined)
            reflectionMergePartial<MsgUserSettlement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserSettlement): MsgUserSettlement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 settleAt */ 2:
                    message.settleAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prize */ 3:
                    message.prize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 rank */ 4:
                    message.rank = reader.uint32();
                    break;
                case /* bool isReEntry */ 5:
                    message.isReEntry = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserSettlement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 settleAt = 2; */
        if (message.settleAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.settleAt);
        /* repeated bpt.common.AssetItem prize = 3; */
        for (let i = 0; i < message.prize.length; i++)
            AssetItem.internalBinaryWrite(message.prize[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint32 rank = 4; */
        if (message.rank !== 0)
            writer.tag(4, WireType.Varint).uint32(message.rank);
        /* bool isReEntry = 5; */
        if (message.isReEntry !== false)
            writer.tag(5, WireType.Varint).bool(message.isReEntry);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgUserSettlement
 */
export const MsgUserSettlement = new MsgUserSettlement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgBubbleTime$Type extends MessageType<MsgBubbleTime> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgBubbleTime", []);
    }
    create(value?: PartialMessage<MsgBubbleTime>): MsgBubbleTime {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgBubbleTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgBubbleTime): MsgBubbleTime {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MsgBubbleTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgBubbleTime
 */
export const MsgBubbleTime = new MsgBubbleTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgBubbleTimeWait$Type extends MessageType<MsgBubbleTimeWait> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgBubbleTimeWait", [
            { no: 1, name: "tableInPlay", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgBubbleTimeWait>): MsgBubbleTimeWait {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableInPlay = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgBubbleTimeWait>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgBubbleTimeWait): MsgBubbleTimeWait {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 tableInPlay */ 1:
                    message.tableInPlay = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgBubbleTimeWait, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 tableInPlay = 1; */
        if (message.tableInPlay !== 0)
            writer.tag(1, WireType.Varint).int64(message.tableInPlay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgBubbleTimeWait
 */
export const MsgBubbleTimeWait = new MsgBubbleTimeWait$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgExitBubbleTime$Type extends MessageType<MsgExitBubbleTime> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgExitBubbleTime", []);
    }
    create(value?: PartialMessage<MsgExitBubbleTime>): MsgExitBubbleTime {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgExitBubbleTime>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgExitBubbleTime): MsgExitBubbleTime {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MsgExitBubbleTime, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgExitBubbleTime
 */
export const MsgExitBubbleTime = new MsgExitBubbleTime$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgEnterPrizeRound$Type extends MessageType<MsgEnterPrizeRound> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgEnterPrizeRound", []);
    }
    create(value?: PartialMessage<MsgEnterPrizeRound>): MsgEnterPrizeRound {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgEnterPrizeRound>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgEnterPrizeRound): MsgEnterPrizeRound {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MsgEnterPrizeRound, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgEnterPrizeRound
 */
export const MsgEnterPrizeRound = new MsgEnterPrizeRound$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgFinalTable$Type extends MessageType<MsgFinalTable> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgFinalTable", [
            { no: 1, name: "desk", kind: "message", T: () => Desk }
        ]);
    }
    create(value?: PartialMessage<MsgFinalTable>): MsgFinalTable {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgFinalTable>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgFinalTable): MsgFinalTable {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Desk desk */ 1:
                    message.desk = Desk.internalBinaryRead(reader, reader.uint32(), options, message.desk);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgFinalTable, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Desk desk = 1; */
        if (message.desk)
            Desk.internalBinaryWrite(message.desk, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgFinalTable
 */
export const MsgFinalTable = new MsgFinalTable$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuelUser$Type extends MessageType<DuelUser> {
    constructor() {
        super("bpt.match.mttmatch.v1.DuelUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DuelUser>): DuelUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<DuelUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuelUser): DuelUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 chips */ 2:
                    message.chips = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuelUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint64 chips = 2; */
        if (message.chips !== 0)
            writer.tag(2, WireType.Varint).uint64(message.chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.DuelUser
 */
export const DuelUser = new DuelUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgDuel$Type extends MessageType<MsgDuel> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgDuel", [
            { no: 1, name: "duelUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DuelUser }
        ]);
    }
    create(value?: PartialMessage<MsgDuel>): MsgDuel {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.duelUsers = [];
        if (value !== undefined)
            reflectionMergePartial<MsgDuel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgDuel): MsgDuel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.mttmatch.v1.DuelUser duelUsers */ 1:
                    message.duelUsers.push(DuelUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgDuel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.mttmatch.v1.DuelUser duelUsers = 1; */
        for (let i = 0; i < message.duelUsers.length; i++)
            DuelUser.internalBinaryWrite(message.duelUsers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgDuel
 */
export const MsgDuel = new MsgDuel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RecyclingMatchReq$Type extends MessageType<RecyclingMatchReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.RecyclingMatchReq", [
            { no: 2, name: "jobId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RecyclingMatchReq>): RecyclingMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.jobId = "";
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<RecyclingMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RecyclingMatchReq): RecyclingMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string jobId */ 2:
                    message.jobId = reader.string();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RecyclingMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string jobId = 2; */
        if (message.jobId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.jobId);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.RecyclingMatchReq
 */
export const RecyclingMatchReq = new RecyclingMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserEnter$Type extends MessageType<MsgUserEnter> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgUserEnter", [
            { no: 1, name: "user", kind: "message", T: () => User },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserEnter>): MsgUserEnter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgUserEnter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserEnter): MsgUserEnter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserEnter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgUserEnter
 */
export const MsgUserEnter = new MsgUserEnter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserCancelEnter$Type extends MessageType<MsgUserCancelEnter> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgUserCancelEnter", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "registeredNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserCancelEnter>): MsgUserCancelEnter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.registeredNum = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MsgUserCancelEnter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserCancelEnter): MsgUserCancelEnter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 registeredNum */ 3:
                    message.registeredNum = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserCancelEnter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 registeredNum = 3; */
        if (message.registeredNum !== 0)
            writer.tag(3, WireType.Varint).int64(message.registeredNum);
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgUserCancelEnter
 */
export const MsgUserCancelEnter = new MsgUserCancelEnter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchAboutToStart$Type extends MessageType<MsgMatchAboutToStart> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgMatchAboutToStart", [
            { no: 1, name: "leftDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchAboutToStart>): MsgMatchAboutToStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.leftDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchAboutToStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchAboutToStart): MsgMatchAboutToStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 leftDuration */ 1:
                    message.leftDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchAboutToStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 leftDuration = 1; */
        if (message.leftDuration !== 0)
            writer.tag(1, WireType.Varint).int64(message.leftDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgMatchAboutToStart
 */
export const MsgMatchAboutToStart = new MsgMatchAboutToStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgCountDown$Type extends MessageType<MsgCountDown> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgCountDown", [
            { no: 1, name: "leftDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgCountDown>): MsgCountDown {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.leftDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgCountDown>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgCountDown): MsgCountDown {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 leftDuration */ 1:
                    message.leftDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgCountDown, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 leftDuration = 1; */
        if (message.leftDuration !== 0)
            writer.tag(1, WireType.Varint).int64(message.leftDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgCountDown
 */
export const MsgCountDown = new MsgCountDown$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgRoundStart$Type extends MessageType<MsgRoundStart> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgRoundStart", [
            { no: 1, name: "deskIDs", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "blindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<MsgRoundStart>): MsgRoundStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskIDs = [];
        message.blindLevel = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgRoundStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgRoundStart): MsgRoundStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 deskIDs */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.deskIDs.push(reader.int64().toNumber());
                    else
                        message.deskIDs.push(reader.int64().toNumber());
                    break;
                case /* uint32 blindLevel */ 2:
                    message.blindLevel = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgRoundStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 deskIDs = 1; */
        if (message.deskIDs.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.deskIDs.length; i++)
                writer.int64(message.deskIDs[i]);
            writer.join();
        }
        /* uint32 blindLevel = 2; */
        if (message.blindLevel !== 0)
            writer.tag(2, WireType.Varint).uint32(message.blindLevel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgRoundStart
 */
export const MsgRoundStart = new MsgRoundStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgRoundEnd$Type extends MessageType<MsgRoundEnd> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgRoundEnd", [
            { no: 1, name: "deskIDs", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgRoundEnd>): MsgRoundEnd {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.deskIDs = [];
        if (value !== undefined)
            reflectionMergePartial<MsgRoundEnd>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgRoundEnd): MsgRoundEnd {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 deskIDs */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.deskIDs.push(reader.int64().toNumber());
                    else
                        message.deskIDs.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgRoundEnd, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 deskIDs = 1; */
        if (message.deskIDs.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.deskIDs.length; i++)
                writer.int64(message.deskIDs[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgRoundEnd
 */
export const MsgRoundEnd = new MsgRoundEnd$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgBreakTimeStart$Type extends MessageType<MsgBreakTimeStart> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgBreakTimeStart", [
            { no: 1, name: "stopTimeAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgBreakTimeStart>): MsgBreakTimeStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.stopTimeAt = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgBreakTimeStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgBreakTimeStart): MsgBreakTimeStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 stopTimeAt */ 1:
                    message.stopTimeAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgBreakTimeStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 stopTimeAt = 1; */
        if (message.stopTimeAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.stopTimeAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgBreakTimeStart
 */
export const MsgBreakTimeStart = new MsgBreakTimeStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgBreakTimeStop$Type extends MessageType<MsgBreakTimeStop> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgBreakTimeStop", []);
    }
    create(value?: PartialMessage<MsgBreakTimeStop>): MsgBreakTimeStop {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgBreakTimeStop>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgBreakTimeStop): MsgBreakTimeStop {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MsgBreakTimeStop, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgBreakTimeStop
 */
export const MsgBreakTimeStop = new MsgBreakTimeStop$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgPoolChange$Type extends MessageType<MsgPoolChange> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgPoolChange", [
            { no: 1, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<MsgPoolChange>): MsgPoolChange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizePool = [];
        if (value !== undefined)
            reflectionMergePartial<MsgPoolChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgPoolChange): MsgPoolChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem prizePool */ 1:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgPoolChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem prizePool = 1; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgPoolChange
 */
export const MsgPoolChange = new MsgPoolChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgReEntry$Type extends MessageType<MsgReEntry> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgReEntry", [
            { no: 1, name: "leftDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgReEntry>): MsgReEntry {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.leftDuration = 0;
        message.uids = [];
        if (value !== undefined)
            reflectionMergePartial<MsgReEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgReEntry): MsgReEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 leftDuration */ 1:
                    message.leftDuration = reader.int64().toNumber();
                    break;
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgReEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 leftDuration = 1; */
        if (message.leftDuration !== 0)
            writer.tag(1, WireType.Varint).int64(message.leftDuration);
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgReEntry
 */
export const MsgReEntry = new MsgReEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgReBuy$Type extends MessageType<MsgReBuy> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgReBuy", [
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "deadLine", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgReBuy>): MsgReBuy {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.deadLine = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgReBuy>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgReBuy): MsgReBuy {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* int64 deadLine */ 3:
                    message.deadLine = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgReBuy, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* int64 deadLine = 3; */
        if (message.deadLine !== 0)
            writer.tag(3, WireType.Varint).int64(message.deadLine);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgReBuy
 */
export const MsgReBuy = new MsgReBuy$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgAddOn$Type extends MessageType<MsgAddOn> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgAddOn", [
            { no: 2, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "deadLine", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgAddOn>): MsgAddOn {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.deadLine = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgAddOn>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgAddOn): MsgAddOn {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* int64 deadLine */ 3:
                    message.deadLine = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgAddOn, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 2; */
        if (message.uids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* int64 deadLine = 3; */
        if (message.deadLine !== 0)
            writer.tag(3, WireType.Varint).int64(message.deadLine);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgAddOn
 */
export const MsgAddOn = new MsgAddOn$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgCharge$Type extends MessageType<MsgCharge> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgCharge", [
            { no: 1, name: "event", kind: "enum", T: () => ["bpt.match.common.v1.ChargeEvent", ChargeEvent] },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "chips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "cnt", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<MsgCharge>): MsgCharge {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = 0;
        message.uid = 0;
        message.chips = 0;
        message.cnt = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgCharge>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgCharge): MsgCharge {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.ChargeEvent event */ 1:
                    message.event = reader.int32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint64 chips */ 3:
                    message.chips = reader.uint64().toNumber();
                    break;
                case /* uint32 cnt */ 4:
                    message.cnt = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgCharge, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.ChargeEvent event = 1; */
        if (message.event !== 0)
            writer.tag(1, WireType.Varint).int32(message.event);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* uint64 chips = 3; */
        if (message.chips !== 0)
            writer.tag(3, WireType.Varint).uint64(message.chips);
        /* uint32 cnt = 4; */
        if (message.cnt !== 0)
            writer.tag(4, WireType.Varint).uint32(message.cnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgCharge
 */
export const MsgCharge = new MsgCharge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgCancelMatch$Type extends MessageType<MsgCancelMatch> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgCancelMatch", [
            { no: 1, name: "game", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MsgCancelMatch>): MsgCancelMatch {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.game = "";
        if (value !== undefined)
            reflectionMergePartial<MsgCancelMatch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgCancelMatch): MsgCancelMatch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string game */ 1:
                    message.game = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgCancelMatch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string game = 1; */
        if (message.game !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.game);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgCancelMatch
 */
export const MsgCancelMatch = new MsgCancelMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserObserve$Type extends MessageType<MsgUserObserve> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgUserObserve", [
            { no: 1, name: "desk", kind: "message", T: () => Desk },
            { no: 2, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<MsgUserObserve>): MsgUserObserve {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MsgUserObserve>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserObserve): MsgUserObserve {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Desk desk */ 1:
                    message.desk = Desk.internalBinaryRead(reader, reader.uint32(), options, message.desk);
                    break;
                case /* bpt.match.common.v1.User user */ 2:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserObserve, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Desk desk = 1; */
        if (message.desk)
            Desk.internalBinaryWrite(message.desk, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.User user = 2; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgUserObserve
 */
export const MsgUserObserve = new MsgUserObserve$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgLastChance$Type extends MessageType<MsgLastChance> {
    constructor() {
        super("bpt.match.mttmatch.v1.MsgLastChance", [
            { no: 1, name: "scene", kind: "enum", T: () => ["bpt.match.mttmatch.v1.LastChanceScene", LastChanceScene] }
        ]);
    }
    create(value?: PartialMessage<MsgLastChance>): MsgLastChance {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.scene = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgLastChance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgLastChance): MsgLastChance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.mttmatch.v1.LastChanceScene scene */ 1:
                    message.scene = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgLastChance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.mttmatch.v1.LastChanceScene scene = 1; */
        if (message.scene !== 0)
            writer.tag(1, WireType.Varint).int32(message.scene);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.MsgLastChance
 */
export const MsgLastChance = new MsgLastChance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChipInfo$Type extends MessageType<ChipInfo> {
    constructor() {
        super("bpt.match.mttmatch.v1.ChipInfo", [
            { no: 1, name: "avgChip", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "smallestChip", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "largestChip", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ChipInfo>): ChipInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.avgChip = 0;
        message.smallestChip = 0;
        message.largestChip = 0;
        if (value !== undefined)
            reflectionMergePartial<ChipInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChipInfo): ChipInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 avgChip */ 1:
                    message.avgChip = reader.int64().toNumber();
                    break;
                case /* int64 smallestChip */ 2:
                    message.smallestChip = reader.int64().toNumber();
                    break;
                case /* int64 largestChip */ 3:
                    message.largestChip = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChipInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 avgChip = 1; */
        if (message.avgChip !== 0)
            writer.tag(1, WireType.Varint).int64(message.avgChip);
        /* int64 smallestChip = 2; */
        if (message.smallestChip !== 0)
            writer.tag(2, WireType.Varint).int64(message.smallestChip);
        /* int64 largestChip = 3; */
        if (message.largestChip !== 0)
            writer.tag(3, WireType.Varint).int64(message.largestChip);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.ChipInfo
 */
export const ChipInfo = new ChipInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChipInfoReq$Type extends MessageType<GetChipInfoReq> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetChipInfoReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetChipInfoReq>): GetChipInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetChipInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChipInfoReq): GetChipInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChipInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetChipInfoReq
 */
export const GetChipInfoReq = new GetChipInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChipInfoResp$Type extends MessageType<GetChipInfoResp> {
    constructor() {
        super("bpt.match.mttmatch.v1.GetChipInfoResp", [
            { no: 2, name: "chipInfo", kind: "message", T: () => ChipInfo }
        ]);
    }
    create(value?: PartialMessage<GetChipInfoResp>): GetChipInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetChipInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChipInfoResp): GetChipInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.mttmatch.v1.ChipInfo chipInfo */ 2:
                    message.chipInfo = ChipInfo.internalBinaryRead(reader, reader.uint32(), options, message.chipInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChipInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.mttmatch.v1.ChipInfo chipInfo = 2; */
        if (message.chipInfo)
            ChipInfo.internalBinaryWrite(message.chipInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.mttmatch.v1.GetChipInfoResp
 */
export const GetChipInfoResp = new GetChipInfoResp$Type();
/**
 * @generated ServiceType for protobuf service bpt.match.mttmatch.v1.matchService
 */
export const matchService = new ServiceType("bpt.match.mttmatch.v1.matchService", [
    { name: "Ping", options: { "blocker.exportclient": 0 }, I: Void, O: Void },
    { name: "PingMatch", options: { "blocker.exportclient": 0 }, I: PingMatchReq, O: PingMatchResp },
    { name: "GetMatch", options: { "blocker.exportclient": 0 }, I: GetMatchReq, O: GetMatchResp },
    { name: "GetMatchStateInfo", options: { "blocker.exportclient": 0 }, I: GetMatchStateInfoReq, O: GetMatchStateInfoResp },
    { name: "BatchGetMatch", options: { "blocker.exportclient": 0 }, I: BatchGetMatchReq, O: BatchGetMatchResp },
    { name: "JoinMatch", options: { "blocker.exportclient": 0 }, I: JoinMatchReq, O: JoinMatchResp },
    { name: "BatchGetUser", options: { "blocker.exportclient": 0 }, I: BatchGetUserReq, O: BatchGetUserResp },
    { name: "UpdateMatch", options: { "blocker.exportclient": 0 }, I: UpdateMatchReq, O: Void },
    { name: "DeleteMatch", options: { "blocker.exportclient": 0 }, I: DeleteMatchReq, O: Void },
    { name: "CancelMatch", options: { "blocker.exportclient": 0 }, I: CancelMatchReq, O: CancelMatchResp },
    { name: "StopMatch", options: { "blocker.exportclient": 0 }, I: StopMatchReq, O: Void },
    { name: "DelayRegister", options: { "blocker.exportclient": 0 }, I: DelayRegisterReq, O: DelayRegisterResp },
    { name: "CancelReBuy", options: { "blocker.exportclient": 0 }, I: CancelReBuyReq, O: CancelReBuyResp },
    { name: "CancelAddOn", options: { "blocker.exportclient": 0 }, I: CancelAddOnReq, O: CancelAddOnResp },
    { name: "CheckAddOn", options: { "blocker.exportclient": 0 }, I: CheckAddOnReq, O: CheckAddOnResp },
    { name: "FinishAddOn", options: { "blocker.exportclient": 0 }, I: FinishAddOnReq, O: FinishAddOnResp },
    { name: "CheckReBuy", options: { "blocker.exportclient": 0 }, I: CheckReBuyReq, O: CheckReBuyResp },
    { name: "FinishReBuy", options: { "blocker.exportclient": 0 }, I: FinishReBuyReq, O: FinishReBuyResp },
    { name: "CheckReEntry", options: { "blocker.exportclient": 0 }, I: CheckReEntryReq, O: CheckReEntryResp },
    { name: "FinishReEntry", options: { "blocker.exportclient": 0 }, I: FinishReEntryReq, O: FinishReEntryResp },
    { name: "StartMatch", options: { "blocker.exportclient": 0 }, I: StartMatchReq, O: StartMatchResp },
    { name: "BatchGetCanReEnter", options: { "blocker.exportclient": 0 }, I: BatchGetCanReEnterReq, O: BatchGetCanReEnterResp },
    { name: "BatchGetUserCanEnter", options: { "blocker.exportclient": 0 }, I: BatchGetUserCanEnterReq, O: BatchGetUserCanEnterResp },
    { name: "AnimationFinish", options: { "blocker.exportclient": 0 }, I: AnimationFinishReq, O: AnimationFinishResp },
    { name: "RoundResult", options: { "blocker.exportclient": 0 }, I: RoundResultReq, O: RoundResultResp },
    { name: "RoundEnd", options: { "blocker.exportclient": 0 }, I: RoundEndReq, O: RoundEndResp },
    { name: "TableInfo", options: { "blocker.exportclient": 0 }, I: TableInfoReq, O: TableInfoResp },
    { name: "TableOp", options: { "blocker.exportclient": 0 }, I: TableOpReq, O: TableOpResp },
    { name: "TableStateChange", options: { "blocker.exportclient": 0 }, I: MsgTableStateChange, O: TableStateChangeResp },
    { name: "RestoreMatch", options: { "blocker.exportclient": 0 }, I: RestoreMatchReq, O: Void },
    { name: "RestoreMatchTables", options: { "blocker.exportclient": 0 }, I: RestoreMatchTablesReq, O: RestoreMatchTablesResp },
    { name: "Dump", options: { "blocker.exportclient": 0 }, I: DumpReq, O: Void },
    { name: "RestoreByServerID", options: { "blocker.exportclient": 0 }, I: RestoreByServerIDReq, O: Void },
    { name: "MatchOverView", options: { "blocker.exportclient": 0 }, I: MatchOverViewReq, O: MatchOverViewResp },
    { name: "ListRank", options: { "blocker.exportclient": 0 }, I: ListRankReq, O: ListRankResp },
    { name: "ListDesks", options: { "blocker.exportclient": 0 }, I: ListDesksReq, O: ListDesksResp },
    { name: "ObserveTable", options: { "blocker.exportclient": 0 }, I: ObserveTableReq, O: ObserveTableResp },
    { name: "ObserveTableOnBubble", options: { "blocker.exportclient": 0 }, I: ObserveTableOnBubbleReq, O: ObserveTableOnBubbleResp },
    { name: "GetMyTable", options: {}, I: GetMyTableReq, O: GetMyTableResp },
    { name: "BatchGetMatchAndUserStatus", options: { "blocker.exportclient": 0 }, I: BatchGetMatchAndUserStatusReq, O: BatchGetMatchAndUserStatusResp },
    { name: "UseShuffleCard", options: { "blocker.exportclient": 0 }, I: UseShuffleCardReq, O: Void },
    { name: "PauseMatch", options: { "blocker.exportclient": 0 }, I: PauseMatchReq, O: Void },
    { name: "ResumeMatch", options: { "blocker.exportclient": 0 }, I: ResumeMatchReq, O: Void },
    { name: "GetChipInfo", options: { "blocker.exportclient": 0 }, I: GetChipInfoReq, O: GetChipInfoResp }
]);
