// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "gaw/asset/wallet/v1/wallet.proto" (package "gaw.asset.wallet.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { RelateType } from "../../../../bpt/social/relation/v1/relation";
import { SessionResponseBody } from "./wallet_session";
import { EventArguments } from "./wallet_session";
import { BoolValue } from "../../../../google/protobuf/wrappers";
import { StringValue } from "../../../../google/protobuf/wrappers";
import { Token } from "./token";
import { ChainID } from "../../../base/base";
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CheckWalletAddressesReq
 */
export interface CheckWalletAddressesReq {
    /**
     * @generated from protobuf field: string md5 = 1;
     */
    md5: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CheckWalletAddressesResp
 */
export interface CheckWalletAddressesResp {
    /**
     * @generated from protobuf field: bool needUpload = 1;
     */
    needUpload: boolean;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.WalletAddress
 */
export interface WalletAddress {
    /**
     * @generated from protobuf field: string network = 1;
     */
    network: string;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 3;
     */
    chainID: ChainID;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.UploadWalletAddressesReq
 */
export interface UploadWalletAddressesReq {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.WalletAddress addresses = 1;
     */
    addresses: WalletAddress[];
    /**
     * @generated from protobuf field: string md5 = 2;
     */
    md5: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.UploadWalletAddressesResp
 */
export interface UploadWalletAddressesResp {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ClearWalletAddressReq
 */
export interface ClearWalletAddressReq {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ClearWalletAddressResp
 */
export interface ClearWalletAddressResp {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetUserWalletAddressesReq
 */
export interface GetUserWalletAddressesReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetUserWalletAddressesResp
 */
export interface GetUserWalletAddressesResp {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.WalletAddress addresses = 1;
     */
    addresses: WalletAddress[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetUserAddressReq
 */
export interface GetUserAddressReq {
    /**
     * @generated from protobuf field: int64 userID = 1;
     */
    userID: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetUserAddressResp
 */
export interface GetUserAddressResp {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.BatchGetUserAddressReq
 */
export interface BatchGetUserAddressReq {
    /**
     * @generated from protobuf field: repeated int64 userIDs = 1;
     */
    userIDs: number[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.BatchGetUserAddressResp
 */
export interface BatchGetUserAddressResp {
    /**
     * @generated from protobuf field: map<string, string> addresses = 1;
     */
    addresses: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetWalletAddressesByUserIDReq
 */
export interface GetWalletAddressesByUserIDReq {
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetWalletAddressesByUserIDResp
 */
export interface GetWalletAddressesByUserIDResp {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.WalletAddress addresses = 1;
     */
    addresses: WalletAddress[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ERC20Info
 */
export interface ERC20Info {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string contractAddress = 2;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: string symbol = 4;
     */
    symbol: string;
    /**
     * @generated from protobuf field: uint32 decimals = 5;
     */
    decimals: number;
    /**
     * @generated from protobuf field: string logo = 6;
     */
    logo: string;
    /**
     * @generated from protobuf field: string chainLogo = 7;
     */
    chainLogo: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListERC20Req
 */
export interface ListERC20Req {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 3;
     */
    chainID: ChainID;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListERC20Resp
 */
export interface ListERC20Resp {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.ERC20Info erc20infos = 1 [json_name = "erc20infos"];
     */
    erc20infos: ERC20Info[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.NFTInfo
 */
export interface NFTInfo {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string contractAddress = 2;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: string tokenID = 3;
     */
    tokenID: string;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string logo = 5;
     */
    logo: string;
    /**
     * @generated from protobuf field: uint64 createTime = 6;
     */
    createTime: number;
    /**
     * @generated from protobuf field: uint64 expiredTime = 7;
     */
    expiredTime: number;
    /**
     * @generated from protobuf field: string mttPriceRawAmount = 8;
     */
    mttPriceRawAmount: string;
    /**
     * @generated from protobuf field: map<string, string> desc = 9;
     */
    desc: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: uint64 deadline = 10;
     */
    deadline: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetTokenInfoReq
 */
export interface GetTokenInfoReq {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string contractAddress = 2;
     */
    contractAddress: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetTokenInfoResp
 */
export interface GetTokenInfoResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Token token = 1;
     */
    token?: Token;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.SaveTokenInfoReq
 */
export interface SaveTokenInfoReq {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Token token = 2;
     */
    token?: Token;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.SaveTokenInfoResp
 */
export interface SaveTokenInfoResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Token token = 1;
     */
    token?: Token;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListTokenInfoReq
 */
export interface ListTokenInfoReq {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListTokenInfoResp
 */
export interface ListTokenInfoResp {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.Token tokens = 1;
     */
    tokens: Token[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.TokenExtra
 */
export interface TokenExtra {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ChainToken
 */
export interface ChainToken {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string contractAddress = 2;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: int64 createdAt = 3;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: bytes extra = 4;
     */
    extra: Uint8Array;
    /**
     * @generated from protobuf field: bool isActive = 5;
     */
    isActive: boolean;
    /**
     * @generated from protobuf field: int32 usageFrequency = 6;
     */
    usageFrequency: number;
    /**
     * @generated from protobuf field: string balance = 7;
     */
    balance: string;
}
/**
 * TODO: 差一个Source
 *
 * @generated from protobuf message gaw.asset.wallet.v1.WalletAccount
 */
export interface WalletAccount {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.ChainToken tokens = 1;
     */
    tokens: ChainToken[];
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string address = 3;
     */
    address: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.WalletType walletType = 4;
     */
    walletType: WalletType;
    /**
     * @generated from protobuf field: int64 createdAt = 5;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 updateAt = 6;
     */
    updateAt: number;
    /**
     * @generated from protobuf field: bytes extra = 7;
     */
    extra: Uint8Array;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.WalletStatus status = 8;
     */
    status: WalletStatus;
    /**
     * @generated from protobuf field: bool isDefaultPayment = 9;
     */
    isDefaultPayment: boolean;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Derivative derivative = 10;
     */
    derivative?: Derivative;
    /**
     * @generated from protobuf field: bool display = 11;
     */
    display: boolean;
    /**
     * @generated from protobuf field: bool isBackup = 12;
     */
    isBackup: boolean;
    /**
     * @generated from protobuf field: int32 seq = 13;
     */
    seq: number;
    /**
     * @generated from protobuf field: int64 authChangeAt = 14;
     */
    authChangeAt: number;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.WhiteListControl whiteListStatus = 15;
     */
    whiteListStatus: WhiteListControl;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.TransferMode transferMode = 16;
     */
    transferMode: TransferMode;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Derivative
 */
export interface Derivative {
    /**
     * @generated from protobuf field: string parentAddress = 1;
     */
    parentAddress: string;
    /**
     * @generated from protobuf field: int32 index = 2;
     */
    index: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetWalletReq
 */
export interface GetWalletReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetWalletResp
 */
export interface GetWalletResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.WalletAccount walletAccount = 1;
     */
    walletAccount?: WalletAccount;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListWalletReq
 */
export interface ListWalletReq {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListWalletResp
 */
export interface ListWalletResp {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.WalletAccount walletAccounts = 1;
     */
    walletAccounts: WalletAccount[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetActiveWalletReq
 */
export interface GetActiveWalletReq {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetCustodialWalletReq
 */
export interface GetCustodialWalletReq {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.UpdateWalletReq
 */
export interface UpdateWalletReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
    /**
     * @generated from protobuf field: google.protobuf.StringValue name = 2;
     */
    name?: StringValue;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.WalletStatus status = 3;
     */
    status: WalletStatus;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isDefaultPayment = 4;
     */
    isDefaultPayment?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue display = 5;
     */
    display?: BoolValue;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue isBackup = 6;
     */
    isBackup?: BoolValue;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.UpdateWalletResp
 */
export interface UpdateWalletResp {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CreateWalletReq
 */
export interface CreateWalletReq {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.WalletType walletType = 3;
     */
    walletType: WalletType;
    /**
     * @generated from protobuf field: bytes extra = 4;
     */
    extra: Uint8Array;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.WalletStatus status = 5;
     */
    status: WalletStatus;
    /**
     * @generated from protobuf field: bool isDefaultPayment = 6;
     */
    isDefaultPayment: boolean;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Derivative derivative = 7;
     */
    derivative?: Derivative;
    /**
     * @generated from protobuf field: bool isBackup = 8;
     */
    isBackup: boolean;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.PullAddressSecretReq
 */
export interface PullAddressSecretReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
    /**
     * @generated from protobuf field: string negotiateKey = 2;
     */
    negotiateKey: string; //    bool isTest = 3;
    //    string testSecret = 4;
    //    string testCipherText = 5;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.PullAddressSecretResp
 */
export interface PullAddressSecretResp {
    /**
     * @generated from protobuf field: string encryptedSecret = 1;
     */
    encryptedSecret: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.InitCustodialWalletReq
 */
export interface InitCustodialWalletReq {
    /**
     * @deprecated
     * @generated from protobuf field: string name = 1 [deprecated = true];
     */
    name: string;
    /**
     * @deprecated
     * @generated from protobuf field: string address = 2 [deprecated = true];
     */
    address: string;
    /**
     * @generated from protobuf field: int32 appID = 3;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 userID = 4;
     */
    userID: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.DeleteWalletReq
 */
export interface DeleteWalletReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.DeleteWalletResp
 */
export interface DeleteWalletResp {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetDefaultPaymentWalletReq
 */
export interface GetDefaultPaymentWalletReq {
    /**
     * @generated from protobuf field: int64 userID = 1;
     */
    userID: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CreateChainTokenReq
 */
export interface CreateChainTokenReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 2;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string contractAddress = 3;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: bytes extra = 4;
     */
    extra: Uint8Array;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CreateChainTokenResp
 */
export interface CreateChainTokenResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.ChainToken token = 1;
     */
    token?: ChainToken;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.UpdateChainTokenReq
 */
export interface UpdateChainTokenReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 2;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string contractAddress = 3;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: bool incrUsageFrequency = 5;
     */
    incrUsageFrequency: boolean;
    /**
     * @generated from protobuf field: google.protobuf.BoolValue active = 6;
     */
    active?: BoolValue;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.UpdateChainTokenResp
 */
export interface UpdateChainTokenResp {
}
/**
 * Transaction 对应 IntentTypeTransaction 的 data
 *
 * @generated from protobuf message gaw.asset.wallet.v1.IntentTransaction
 */
export interface IntentTransaction {
    /**
     * chain id , 默认是 eip155 的 chain id  https://chainid.network
     *
     * @generated from protobuf field: gaw.base.ChainID chain_id = 1;
     */
    chain_id: ChainID;
    /**
     * transaction 操作
     *
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.IntentTransaction.Tx txs = 2;
     */
    txs: IntentTransaction_Tx[];
    /**
     * 预期的发送者, 假如不是空的话，就检查现在用的钱包是不是这个地址，如果不是就报错
     *
     * @generated from protobuf field: string sender = 3;
     */
    sender: string;
}
/**
 * check_approve 的时候用到
 *
 * @generated from protobuf message gaw.asset.wallet.v1.IntentTransaction.Input
 */
export interface IntentTransaction_Input {
    /**
     * @generated from protobuf field: string spender = 1;
     */
    spender: string;
    /**
     * @generated from protobuf field: string amount = 2;
     */
    amount: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.IntentTransaction.Tx
 */
export interface IntentTransaction_Tx {
    /**
     * check_approve | send_tx
     * check_approve :
     *     用 erc20 的 abi 调用 allowance 看给 inpput.spender 的额度够不够，
     *     不够的话就调 approve 给 inpput.spender 授权等于 inpput.amount 的额度然后 check_approve 就执行完成了
     *     如果 allowance 足够就直接完成
     * send_tx :
     *     发送交易
     *
     * @generated from protobuf field: string type = 1;
     */
    type: string;
    /**
     * string from = 2;
     *
     * @generated from protobuf field: string to = 3;
     */
    to: string;
    /**
     * @generated from protobuf field: string value = 4;
     */
    value: string;
    /**
     * data hex
     *
     * @generated from protobuf field: string data = 6;
     */
    data: string;
    /**
     * check_approve 的时候用到
     *
     * @generated from protobuf field: gaw.asset.wallet.v1.IntentTransaction.Input input = 5;
     */
    input?: IntentTransaction_Input;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Intent
 */
export interface Intent {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.IntentType type = 2;
     */
    type: IntentType;
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "transaction";
        /**
         * type == IntentTypeTransaction
         *
         * @generated from protobuf field: gaw.asset.wallet.v1.IntentTransaction transaction = 3;
         */
        transaction: IntentTransaction;
    } | {
        oneofKind: undefined;
    };
    /**
     * bytes intentLink = 4;
     *
     * @generated from protobuf field: int64 deadline = 15;
     */
    deadline: number; // 失效时间, unix 时间戳
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CreateIntentReq
 */
export interface CreateIntentReq {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "transaction";
        /**
         * type == IntentTypeTransaction
         *
         * @generated from protobuf field: gaw.asset.wallet.v1.IntentTransaction transaction = 3;
         */
        transaction: IntentTransaction;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: int64 ttl = 15;
     */
    ttl: number; // 有效时间，单位：秒 0则不限制
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CreateIntentResp
 */
export interface CreateIntentResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Intent intent = 1;
     */
    intent?: Intent;
    /**
     * 直接把 intentLink 给前端
     *
     * @generated from protobuf field: bytes intentLink = 2;
     */
    intentLink: Uint8Array;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetIntentReq
 */
export interface GetIntentReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetIntentResp
 */
export interface GetIntentResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Intent intent = 1;
     */
    intent?: Intent;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.InvalidateIntentReq
 */
export interface InvalidateIntentReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.InvalidateIntentResp
 */
export interface InvalidateIntentResp {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetIntentByLinkReq
 */
export interface GetIntentByLinkReq {
    /**
     * @generated from protobuf field: bytes intentLink = 1;
     */
    intentLink: Uint8Array;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetIntentByLinkResp
 */
export interface GetIntentByLinkResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Intent intent = 1;
     */
    intent?: Intent;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.RedPacketInfo
 */
export interface RedPacketInfo {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * 红包类型
     *
     * @generated from protobuf field: gaw.asset.wallet.v1.RedPacketType type = 2;
     */
    type: RedPacketType;
    /**
     * 红包状态
     *
     * @generated from protobuf field: gaw.asset.wallet.v1.RedPacketStatus status = 3;
     */
    status: RedPacketStatus;
    /**
     * 群id
     *
     * @generated from protobuf field: int64 groupID = 4;
     */
    groupID: number;
    /**
     * 发红包的用户 ID
     *
     * @generated from protobuf field: int64 userID = 5;
     */
    userID: number;
    /**
     * 链ID
     *
     * @generated from protobuf field: gaw.base.ChainID chainID = 6;
     */
    chainID: ChainID;
    /**
     * 发红包的用户地址
     *
     * @generated from protobuf field: string userAddr = 7;
     */
    userAddr: string;
    /**
     * 红包合约地址
     *
     * @generated from protobuf field: string contractAddr = 8;
     */
    contractAddr: string;
    /**
     * 代币合约地址 本币为零地址
     *
     * @generated from protobuf field: string tokenContract = 9;
     */
    tokenContract: string;
    /**
     * 红包总金额
     *
     * @generated from protobuf field: string totalAmount = 10;
     */
    totalAmount: string;
    /**
     * 红包剩余金额
     *
     * @generated from protobuf field: string remainAmount = 11;
     */
    remainAmount: string;
    /**
     * 红包总个数
     *
     * @generated from protobuf field: int32 totalCount = 12;
     */
    totalCount: number;
    /**
     * 红包剩余个数
     *
     * @generated from protobuf field: int32 remainCount = 13;
     */
    remainCount: number;
    /**
     * // 红包最小数额
     * string minAmount = 14;
     * // 红包信息
     * string message = 15;
     * 红包创建的交易 hash (链上执行完后才有)
     *
     * @generated from protobuf field: string txHash = 16;
     */
    txHash: string;
    /**
     * 红包过期时间
     *
     * @generated from protobuf field: int64 expiredAt = 17;
     */
    expiredAt: number;
    /**
     * 红包创建时间
     *
     * @generated from protobuf field: int64 createdAt = 18;
     */
    createdAt: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CreateRedPacketReq
 */
export interface CreateRedPacketReq {
    /**
     * 红包类型
     *
     * @generated from protobuf field: gaw.asset.wallet.v1.RedPacketType type = 2;
     */
    type: RedPacketType;
    /**
     * 群id
     *
     * @generated from protobuf field: int64 groupID = 3;
     */
    groupID: number; // 链ID
    // gaw.base.ChainID chainID = 5;
    // 代币合约地址 本币为零地址
    // string tokenContract = 8;
    // // 红包总金额
    // string totalAmount = 9;
    // // 红包总个数
    // int32 total_count = 11;
    // // 红包最小数额
    // string minAmount = 13;
    // 红包信息 ?
    // string message = 14;
    // 红包有效时长 （秒）
    // int64 ttl = 15;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.CreateRedPacketResp
 */
export interface CreateRedPacketResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.RedPacketInfo redPacketInfo = 1;
     */
    redPacketInfo?: RedPacketInfo;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetRedPacketInfoReq
 */
export interface GetRedPacketInfoReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetRedPacketInfoResp
 */
export interface GetRedPacketInfoResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.RedPacketInfo redPacketInfo = 1;
     */
    redPacketInfo?: RedPacketInfo;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetClaimRedPacketSignatureReq
 */
export interface GetClaimRedPacketSignatureReq {
    /**
     * @generated from protobuf field: string redPacketID = 1;
     */
    redPacketID: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetClaimRedPacketSignatureResp
 */
export interface GetClaimRedPacketSignatureResp {
    /**
     * @generated from protobuf field: string signature = 1;
     */
    signature: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.RedPacketClaimRecord
 */
export interface RedPacketClaimRecord {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * 红包id
     *
     * @generated from protobuf field: string redPacketID = 2;
     */
    redPacketID: string;
    /**
     * 领取用户 ID
     *
     * @generated from protobuf field: int64 userID = 5;
     */
    userID: number;
    /**
     * 领取用户地址
     *
     * @generated from protobuf field: string userAddr = 6;
     */
    userAddr: string;
    /**
     * 领取金额
     *
     * @generated from protobuf field: string amount = 7;
     */
    amount: string;
    /**
     * 领取时间
     *
     * @generated from protobuf field: int64 claimedAt = 8;
     */
    claimedAt: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListRedPacketClaimRecordsReq
 */
export interface ListRedPacketClaimRecordsReq {
    /**
     * @generated from protobuf field: string redPacketID = 1;
     */
    redPacketID: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListRedPacketClaimRecordsResp
 */
export interface ListRedPacketClaimRecordsResp {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.RedPacketClaimRecord records = 1;
     */
    records: RedPacketClaimRecord[];
}
/**
 * ** SendSessionEvent **
 *
 * @generated from protobuf message gaw.asset.wallet.v1.SendSessionEventReq
 */
export interface SendSessionEventReq {
    /**
     * int32 appID = 1;
     *  int64 userID = 2;
     *
     * @generated from protobuf field: gaw.asset.wallet.v1.EventArguments eventArgs = 3;
     */
    eventArgs?: EventArguments;
    /**
     * callback 内容  web3.common.walletsession.Response
     *
     * @generated from protobuf field: string callbackURL = 4;
     */
    callbackURL: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.SendSessionEventResp
 */
export interface SendSessionEventResp {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.MsgSessionEvent
 */
export interface MsgSessionEvent {
    /**
     * @generated from protobuf field: int64 id = 2;
     */
    id: number;
}
/**
 * **   ResponseSessionEvent  **
 *
 * @generated from protobuf message gaw.asset.wallet.v1.ResponseSessionEventReq
 */
export interface ResponseSessionEventReq {
    /**
     * @generated from protobuf field: string jsonResponse = 1;
     */
    jsonResponse: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ResponseSessionEventResp
 */
export interface ResponseSessionEventResp {
}
/**
 * **   ListPendingSessionEvents   **
 *
 * @generated from protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsReq
 */
export interface ListPendingSessionEventsReq {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsResp
 */
export interface ListPendingSessionEventsResp {
    /**
     * @generated from protobuf field: repeated string jsonEventArguments = 1;
     */
    jsonEventArguments: string[];
}
/**
 * **   ListPendingSessionEventsByTopic   **
 *
 * @generated from protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsByTopicReq
 */
export interface ListPendingSessionEventsByTopicReq {
    /**
     * @generated from protobuf field: string topic = 1;
     */
    topic: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsByTopicResp
 */
export interface ListPendingSessionEventsByTopicResp {
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.EventArguments eventArguments = 1;
     */
    eventArguments: EventArguments[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ResetSeqReq
 */
export interface ResetSeqReq {
    /**
     * @generated from protobuf field: int32 appID = 1;
     */
    appID: number;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ResetSeqResp
 */
export interface ResetSeqResp {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetPayRouteReq
 */
export interface GetPayRouteReq {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetPayRouteResp
 */
export interface GetPayRouteResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.PayChannel channel = 1;
     */
    channel: PayChannel;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.FactorAuth
 */
export interface FactorAuth {
    /**
     * @generated from protobuf field: string pwdAuthToken = 1;
     */
    pwdAuthToken: string;
    /**
     * @generated from protobuf field: string factorAuthToken = 2;
     */
    factorAuthToken: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.PostTxSignatureReq
 */
export interface PostTxSignatureReq {
    /**
     * @generated from protobuf field: string from = 1;
     */
    from: string;
    /**
     * @generated from protobuf field: string to = 2;
     */
    to: string;
    /**
     * @generated from protobuf field: string value = 3;
     */
    value: string;
    /**
     * @generated from protobuf field: string data = 4;
     */
    data: string;
    /**
     * @generated from protobuf field: uint64 nonce = 5;
     */
    nonce: number;
    /**
     * @generated from protobuf field: int64 chain_id = 6;
     */
    chain_id: number;
    /**
     * @generated from protobuf field: string token = 7;
     */
    token: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.FactorAuth factorAuth = 8;
     */
    factorAuth?: FactorAuth;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.PostTxSignatureResp
 */
export interface PostTxSignatureResp {
    /**
     * @generated from protobuf field: string raw_tx = 1;
     */
    raw_tx: string;
    /**
     * @generated from protobuf field: string signature = 2;
     */
    signature: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Eip712Domain
 */
export interface Eip712Domain {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * @generated from protobuf field: int64 chainId = 3;
     */
    chainId: number;
    /**
     * @generated from protobuf field: string verifyingContract = 4;
     */
    verifyingContract: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.Eip2612Permit
 */
export interface Eip2612Permit {
    /**
     * @generated from protobuf field: string owner = 1;
     */
    owner: string;
    /**
     * @generated from protobuf field: string spender = 2;
     */
    spender: string;
    /**
     * @generated from protobuf field: string value = 3;
     */
    value: string;
    /**
     * @generated from protobuf field: int64 nonce = 4;
     */
    nonce: number;
    /**
     * @generated from protobuf field: int64 deadline = 5;
     */
    deadline: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ApplyPermitRep
 */
export interface ApplyPermitRep {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Eip712Domain domain = 1;
     */
    domain?: Eip712Domain;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Eip2612Permit permit = 2;
     */
    permit?: Eip2612Permit;
    /**
     * @generated from protobuf field: string applyAddress = 3;
     */
    applyAddress: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ApplyPermitResp
 */
export interface ApplyPermitResp {
    /**
     * @generated from protobuf field: string signature = 1;
     */
    signature: string;
    /**
     * @generated from protobuf field: string r = 2;
     */
    r: string;
    /**
     * @generated from protobuf field: string s = 3;
     */
    s: string;
    /**
     * @generated from protobuf field: string v = 4;
     */
    v: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetSessionMessageReq
 */
export interface GetSessionMessageReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetSessionMessageResp
 */
export interface GetSessionMessageResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.EventArguments eventArgs = 1;
     */
    eventArgs?: EventArguments;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.SessionResponseBody eventRes = 2;
     */
    eventRes?: SessionResponseBody;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetAddressDetailReq
 */
export interface GetAddressDetailReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetAddressDetailResp
 */
export interface GetAddressDetailResp {
    /**
     * @generated from protobuf field: int64 gawUserID = 1;
     */
    gawUserID: number;
    /**
     * @generated from protobuf field: int64 mttUserID = 2;
     */
    mttUserID: number;
    /**
     * @generated from protobuf field: string address = 3;
     */
    address: string;
    /**
     * @generated from protobuf field: int32 transTimes = 4;
     */
    transTimes: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetBalanceByUidReq
 */
export interface GetBalanceByUidReq {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: int64 userID = 2;
     */
    userID: number;
    /**
     * @generated from protobuf field: string contractAddress = 3;
     */
    contractAddress: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetBalanceByUidResp
 */
export interface GetBalanceByUidResp {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.GetBalanceByUidResp.Item Result = 1 [json_name = "Result"];
     */
    Result?: GetBalanceByUidResp_Item;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetBalanceByUidResp.Item
 */
export interface GetBalanceByUidResp_Item {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: string contractAddress = 3;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: string balance = 4;
     */
    balance: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetTssWalletReq
 */
export interface GetTssWalletReq {
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetTssWalletResp
 */
export interface GetTssWalletResp {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.PostTssSignatureReq
 */
export interface PostTssSignatureReq {
    /**
     * @generated from protobuf field: string from = 1;
     */
    from: string;
    /**
     * @generated from protobuf field: string to = 2;
     */
    to: string;
    /**
     * @generated from protobuf field: string value = 3;
     */
    value: string;
    /**
     * @generated from protobuf field: string data = 4;
     */
    data: string;
    /**
     * @generated from protobuf field: int64 chain_id = 5;
     */
    chain_id: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.PostTssSignatureResp
 */
export interface PostTssSignatureResp {
    /**
     * @generated from protobuf field: string rawTx = 1;
     */
    rawTx: string;
    /**
     * @generated from protobuf field: string signature = 2;
     */
    signature: string;
}
// --------------------------------白名单 Start--------------------------------

/**
 * 开启白名单-请求
 *
 * @generated from protobuf message gaw.asset.wallet.v1.OnWhiteListReq
 */
export interface OnWhiteListReq {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * 开启白名单-响应
 *
 * @generated from protobuf message gaw.asset.wallet.v1.OnWhiteListResp
 */
export interface OnWhiteListResp {
}
/**
 * 关闭白名单-请求
 *
 * @generated from protobuf message gaw.asset.wallet.v1.OffWhiteListReq
 */
export interface OffWhiteListReq {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * 关闭白名单-响应
 *
 * @generated from protobuf message gaw.asset.wallet.v1.OffWhiteListResp
 */
export interface OffWhiteListResp {
}
/**
 * 设置转账模式-请求
 *
 * @generated from protobuf message gaw.asset.wallet.v1.SetTransferModeReq
 */
export interface SetTransferModeReq {
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.TransferMode mode = 1;
     */
    mode: TransferMode; // 转账模式
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
}
/**
 * 设置转账模式-响应
 *
 * @generated from protobuf message gaw.asset.wallet.v1.SetTransferModeResp
 */
export interface SetTransferModeResp {
}
/**
 * 创建白名单-请求
 *
 * @generated from protobuf message gaw.asset.wallet.v1.CreateWhiteListReq
 */
export interface CreateWhiteListReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string; // 地址
    /**
     * @generated from protobuf field: string notes = 2;
     */
    notes: string; // 备注
    /**
     * @generated from protobuf field: string token = 3;
     */
    token: string;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 4;
     */
    chainID: ChainID;
}
/**
 * 创建白名单-响应
 *
 * @generated from protobuf message gaw.asset.wallet.v1.CreateWhiteListResp
 */
export interface CreateWhiteListResp {
}
/**
 * 获取白名单列表-请求
 *
 * @generated from protobuf message gaw.asset.wallet.v1.ListWhiteListReq
 */
export interface ListWhiteListReq {
}
/**
 * 获取白名单列表-响应
 *
 * @generated from protobuf message gaw.asset.wallet.v1.ListWhiteListResp
 */
export interface ListWhiteListResp {
    /**
     * @generated from protobuf field: int64 count = 1;
     */
    count: number; // 白名单数量
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.WhiteList white_list = 2;
     */
    white_list: WhiteList[]; // 白名单列表
}
/**
 * 根据地址获取转账次数
 *
 * @generated from protobuf message gaw.asset.wallet.v1.ListTransTimesReq
 */
export interface ListTransTimesReq {
    /**
     * @generated from protobuf field: repeated string address = 1;
     */
    address: string[];
    /**
     * @generated from protobuf field: int64 userId = 2;
     */
    userId: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListTransTimesResp
 */
export interface ListTransTimesResp {
    /**
     * @generated from protobuf field: map<string, int32> transTimes = 1;
     */
    transTimes: {
        [key: string]: number;
    };
}
/**
 * 根据地址获取转账次数
 *
 * @generated from protobuf message gaw.asset.wallet.v1.ListTransTimesAddressReq
 */
export interface ListTransTimesAddressReq {
    /**
     * @generated from protobuf field: repeated string address = 1;
     */
    address: string[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.ListTransTimesAddressResp
 */
export interface ListTransTimesAddressResp {
    /**
     * @generated from protobuf field: map<string, int32> transTimes = 1;
     */
    transTimes: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.TransTimesList
 */
export interface TransTimesList {
    /**
     * @generated from protobuf field: int64 userId = 1;
     */
    userId: number;
    /**
     * @generated from protobuf field: int32 transTimes = 2;
     */
    transTimes: number;
    /**
     * @generated from protobuf field: bool isWhite = 3;
     */
    isWhite: boolean;
    /**
     * @generated from protobuf field: bpt.social.relation.v1.RelateType relate = 4;
     */
    relate: RelateType;
    /**
     * @generated from protobuf field: string address = 5;
     */
    address: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetRecentlyTransReq
 */
export interface GetRecentlyTransReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.GetRecentlyTransResp
 */
export interface GetRecentlyTransResp {
    /**
     * @generated from protobuf field: int64 count = 1;
     */
    count: number; // 数量
    /**
     * @generated from protobuf field: repeated gaw.asset.wallet.v1.TransTimes transTimes = 2;
     */
    transTimes: TransTimes[];
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.TransTimes
 */
export interface TransTimes {
    /**
     * @generated from protobuf field: int64 to_user_id = 1;
     */
    to_user_id: number;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
    /**
     * @generated from protobuf field: int32 trans_times = 3;
     */
    trans_times: number;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 4;
     */
    chainID: ChainID;
}
/**
 * 回调增加转账次数
 *
 * @generated from protobuf message gaw.asset.wallet.v1.IncrTransTimesReq
 */
export interface IncrTransTimesReq {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
}
/**
 * @generated from protobuf message gaw.asset.wallet.v1.IncrTransTimesResp
 */
export interface IncrTransTimesResp {
}
/**
 * 白名单
 *
 * @generated from protobuf message gaw.asset.wallet.v1.WhiteList
 */
export interface WhiteList {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string; // 地址
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Role role = 2;
     */
    role: Role; // 角色
    /**
     * @generated from protobuf field: string notes = 3;
     */
    notes: string; // 备注
    /**
     * @generated from protobuf field: int64 to_user_id = 4;
     */
    to_user_id: number; // 用户id
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 5;
     */
    chainID: ChainID;
}
/**
 * 删除用户白名单信息-请求
 *
 * @generated from protobuf message gaw.asset.wallet.v1.RemoveWhiteListReq
 */
export interface RemoveWhiteListReq {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string; // 地址
    /**
     * @generated from protobuf field: string token = 2;
     */
    token: string;
}
/**
 * 删除用户白名单信息-响应
 *
 * @generated from protobuf message gaw.asset.wallet.v1.RemoveWhiteListResp
 */
export interface RemoveWhiteListResp {
}
/**
 * 转账签名-请求
 *
 * @generated from protobuf message gaw.asset.wallet.v1.TransferTxSignatureReq
 */
export interface TransferTxSignatureReq {
    /**
     * @generated from protobuf field: string from = 1;
     */
    from: string; // 发送者
    /**
     * @generated from protobuf field: string to = 2;
     */
    to: string; // 接收者
    /**
     * @generated from protobuf field: string value = 3;
     */
    value: string; // 金额
    /**
     * @generated from protobuf field: string data = 4;
     */
    data: string;
    /**
     * @generated from protobuf field: uint64 nonce = 5;
     */
    nonce: number;
    /**
     * @generated from protobuf field: int64 chain_id = 6;
     */
    chain_id: number; // 链id
    /**
     * @generated from protobuf field: string token = 7;
     */
    token: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.FactorAuth factorAuth = 8;
     */
    factorAuth?: FactorAuth;
}
/**
 * 转账签名-响应
 *
 * @generated from protobuf message gaw.asset.wallet.v1.TransferTxSignatureResp
 */
export interface TransferTxSignatureResp {
    /**
     * @generated from protobuf field: string raw_tx = 1;
     */
    raw_tx: string;
    /**
     * @generated from protobuf field: string signature = 2;
     */
    signature: string;
}
/**
 * ----------- start -----------
 *  WalletAccount
 *
 * @generated from protobuf enum gaw.asset.wallet.v1.WalletType
 */
export enum WalletType {
    /**
     * @generated from protobuf enum value: WalletTypeUnknown = 0;
     */
    WalletTypeUnknown = 0,
    /**
     * 助记词钱包
     *
     * @generated from protobuf enum value: MnemonicWallet = 1;
     */
    MnemonicWallet = 1,
    /**
     * 私钥钱包
     *
     * @generated from protobuf enum value: PrivateWallet = 2;
     */
    PrivateWallet = 2,
    /**
     * MPC钱包
     *
     * @generated from protobuf enum value: MPCWallet = 3;
     */
    MPCWallet = 3,
    /**
     * 合约钱包 (AA)
     *
     * @generated from protobuf enum value: ContractWallet = 4;
     */
    ContractWallet = 4,
    /**
     * 派生钱包
     *
     * @generated from protobuf enum value: DerivativeWallet = 5;
     */
    DerivativeWallet = 5,
    /**
     * 托管钱包
     *
     * @generated from protobuf enum value: CustodialWallet = 6;
     */
    CustodialWallet = 6
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.WalletStatus
 */
export enum WalletStatus {
    /**
     * @generated from protobuf enum value: WalletStatusUnknown = 0;
     */
    WalletStatusUnknown = 0,
    /**
     * 当前激活状态
     *
     * @generated from protobuf enum value: Active = 1;
     */
    Active = 1,
    /**
     * 正常状态
     *
     * @generated from protobuf enum value: Normal = 2;
     */
    Normal = 2
}
// ------------ end ------------

/**
 * @generated from protobuf enum gaw.asset.wallet.v1.IntentType
 */
export enum IntentType {
    /**
     * @generated from protobuf enum value: IntentTypeUnknown = 0;
     */
    IntentTypeUnknown = 0,
    /**
     * transaction
     *
     * @generated from protobuf enum value: IntentTypeTransaction = 1;
     */
    IntentTypeTransaction = 1
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.RedPacketType
 */
export enum RedPacketType {
    /**
     * @generated from protobuf enum value: RedPacketTypeUnknown = 0;
     */
    RedPacketTypeUnknown = 0,
    /**
     * 群红包
     *
     * @generated from protobuf enum value: RedPacketTypeGroup = 1;
     */
    RedPacketTypeGroup = 1
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.RedPacketStatus
 */
export enum RedPacketStatus {
    /**
     * 未知，没创建好
     *
     * @generated from protobuf enum value: RedPacketStatusUnknown = 0;
     */
    RedPacketStatusUnknown = 0,
    /**
     * 已初始化
     *
     * @generated from protobuf enum value: RedPacketStatusInited = 1;
     */
    RedPacketStatusInited = 1,
    /**
     * 已创建成功
     *
     * @generated from protobuf enum value: RedPacketStatusCreated = 2;
     */
    RedPacketStatusCreated = 2,
    /**
     * 已领完
     *
     * @generated from protobuf enum value: RedPacketStatusFinished = 5;
     */
    RedPacketStatusFinished = 5,
    /**
     * 已过期
     *
     * @generated from protobuf enum value: RedPacketStatusExpired = 9;
     */
    RedPacketStatusExpired = 9
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.PayChannel
 */
export enum PayChannel {
    /**
     * @generated from protobuf enum value: PayChannelUnknown = 0;
     */
    PayChannelUnknown = 0,
    /**
     * @generated from protobuf enum value: PayChannelWeb = 1;
     */
    PayChannelWeb = 1,
    /**
     * @generated from protobuf enum value: PayChannelMobile = 2;
     */
    PayChannelMobile = 2
}
/**
 * 转账模式
 *
 * @generated from protobuf enum gaw.asset.wallet.v1.TransferMode
 */
export enum TransferMode {
    /**
     * 未知
     *
     * @generated from protobuf enum value: TransferModeUnknown = 0;
     */
    TransferModeUnknown = 0,
    /**
     * 互相关注
     *
     * @generated from protobuf enum value: Interrelation = 1;
     */
    Interrelation = 1,
    /**
     * 我关注的
     *
     * @generated from protobuf enum value: Follow = 2;
     */
    Follow = 2,
    /**
     * 所有人
     *
     * @generated from protobuf enum value: EveryOne = 3;
     */
    EveryOne = 3
}
/**
 * @generated from protobuf enum gaw.asset.wallet.v1.Role
 */
export enum Role {
    /**
     * 未知
     *
     * @generated from protobuf enum value: RoleUnknown = 0;
     */
    RoleUnknown = 0,
    /**
     * 站内用户
     *
     * @generated from protobuf enum value: InStation = 1;
     */
    InStation = 1,
    /**
     * 链上用户
     *
     * @generated from protobuf enum value: InChain = 2;
     */
    InChain = 2
}
/**
 * 白名单控制
 *
 * @generated from protobuf enum gaw.asset.wallet.v1.WhiteListControl
 */
export enum WhiteListControl {
    /**
     * 未知
     *
     * @generated from protobuf enum value: WhiteListControlUnknown = 0;
     */
    WhiteListControlUnknown = 0,
    /**
     * 开启
     *
     * @generated from protobuf enum value: On = 1;
     */
    On = 1,
    /**
     * 关闭
     *
     * @generated from protobuf enum value: Off = 2;
     */
    Off = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class CheckWalletAddressesReq$Type extends MessageType<CheckWalletAddressesReq> {
    constructor() {
        super("gaw.asset.wallet.v1.CheckWalletAddressesReq", [
            { no: 1, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckWalletAddressesReq>): CheckWalletAddressesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.md5 = "";
        if (value !== undefined)
            reflectionMergePartial<CheckWalletAddressesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckWalletAddressesReq): CheckWalletAddressesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string md5 */ 1:
                    message.md5 = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckWalletAddressesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string md5 = 1; */
        if (message.md5 !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.md5);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CheckWalletAddressesReq
 */
export const CheckWalletAddressesReq = new CheckWalletAddressesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckWalletAddressesResp$Type extends MessageType<CheckWalletAddressesResp> {
    constructor() {
        super("gaw.asset.wallet.v1.CheckWalletAddressesResp", [
            { no: 1, name: "needUpload", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckWalletAddressesResp>): CheckWalletAddressesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.needUpload = false;
        if (value !== undefined)
            reflectionMergePartial<CheckWalletAddressesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckWalletAddressesResp): CheckWalletAddressesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool needUpload */ 1:
                    message.needUpload = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckWalletAddressesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool needUpload = 1; */
        if (message.needUpload !== false)
            writer.tag(1, WireType.Varint).bool(message.needUpload);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CheckWalletAddressesResp
 */
export const CheckWalletAddressesResp = new CheckWalletAddressesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WalletAddress$Type extends MessageType<WalletAddress> {
    constructor() {
        super("gaw.asset.wallet.v1.WalletAddress", [
            { no: 1, name: "network", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] }
        ]);
    }
    create(value?: PartialMessage<WalletAddress>): WalletAddress {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.network = "";
        message.address = "";
        message.chainID = 0;
        if (value !== undefined)
            reflectionMergePartial<WalletAddress>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WalletAddress): WalletAddress {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string network */ 1:
                    message.network = reader.string();
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 3:
                    message.chainID = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WalletAddress, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string network = 1; */
        if (message.network !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.network);
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* gaw.base.ChainID chainID = 3; */
        if (message.chainID !== 0)
            writer.tag(3, WireType.Varint).int32(message.chainID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.WalletAddress
 */
export const WalletAddress = new WalletAddress$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadWalletAddressesReq$Type extends MessageType<UploadWalletAddressesReq> {
    constructor() {
        super("gaw.asset.wallet.v1.UploadWalletAddressesReq", [
            { no: 1, name: "addresses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WalletAddress },
            { no: 2, name: "md5", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UploadWalletAddressesReq>): UploadWalletAddressesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.addresses = [];
        message.md5 = "";
        if (value !== undefined)
            reflectionMergePartial<UploadWalletAddressesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadWalletAddressesReq): UploadWalletAddressesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.WalletAddress addresses */ 1:
                    message.addresses.push(WalletAddress.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string md5 */ 2:
                    message.md5 = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadWalletAddressesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.WalletAddress addresses = 1; */
        for (let i = 0; i < message.addresses.length; i++)
            WalletAddress.internalBinaryWrite(message.addresses[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string md5 = 2; */
        if (message.md5 !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.md5);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.UploadWalletAddressesReq
 */
export const UploadWalletAddressesReq = new UploadWalletAddressesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadWalletAddressesResp$Type extends MessageType<UploadWalletAddressesResp> {
    constructor() {
        super("gaw.asset.wallet.v1.UploadWalletAddressesResp", []);
    }
    create(value?: PartialMessage<UploadWalletAddressesResp>): UploadWalletAddressesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UploadWalletAddressesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadWalletAddressesResp): UploadWalletAddressesResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UploadWalletAddressesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.UploadWalletAddressesResp
 */
export const UploadWalletAddressesResp = new UploadWalletAddressesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClearWalletAddressReq$Type extends MessageType<ClearWalletAddressReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ClearWalletAddressReq", []);
    }
    create(value?: PartialMessage<ClearWalletAddressReq>): ClearWalletAddressReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ClearWalletAddressReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClearWalletAddressReq): ClearWalletAddressReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ClearWalletAddressReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ClearWalletAddressReq
 */
export const ClearWalletAddressReq = new ClearWalletAddressReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClearWalletAddressResp$Type extends MessageType<ClearWalletAddressResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ClearWalletAddressResp", []);
    }
    create(value?: PartialMessage<ClearWalletAddressResp>): ClearWalletAddressResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ClearWalletAddressResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClearWalletAddressResp): ClearWalletAddressResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ClearWalletAddressResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ClearWalletAddressResp
 */
export const ClearWalletAddressResp = new ClearWalletAddressResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserWalletAddressesReq$Type extends MessageType<GetUserWalletAddressesReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetUserWalletAddressesReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserWalletAddressesReq>): GetUserWalletAddressesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserWalletAddressesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserWalletAddressesReq): GetUserWalletAddressesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserWalletAddressesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetUserWalletAddressesReq
 */
export const GetUserWalletAddressesReq = new GetUserWalletAddressesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserWalletAddressesResp$Type extends MessageType<GetUserWalletAddressesResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetUserWalletAddressesResp", [
            { no: 1, name: "addresses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WalletAddress }
        ]);
    }
    create(value?: PartialMessage<GetUserWalletAddressesResp>): GetUserWalletAddressesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.addresses = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserWalletAddressesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserWalletAddressesResp): GetUserWalletAddressesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.WalletAddress addresses */ 1:
                    message.addresses.push(WalletAddress.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserWalletAddressesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.WalletAddress addresses = 1; */
        for (let i = 0; i < message.addresses.length; i++)
            WalletAddress.internalBinaryWrite(message.addresses[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetUserWalletAddressesResp
 */
export const GetUserWalletAddressesResp = new GetUserWalletAddressesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserAddressReq$Type extends MessageType<GetUserAddressReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetUserAddressReq", [
            { no: 1, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserAddressReq>): GetUserAddressReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserAddressReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserAddressReq): GetUserAddressReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 userID */ 1:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserAddressReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 userID = 1; */
        if (message.userID !== 0)
            writer.tag(1, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetUserAddressReq
 */
export const GetUserAddressReq = new GetUserAddressReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserAddressResp$Type extends MessageType<GetUserAddressResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetUserAddressResp", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserAddressResp>): GetUserAddressResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserAddressResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserAddressResp): GetUserAddressResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserAddressResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetUserAddressResp
 */
export const GetUserAddressResp = new GetUserAddressResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserAddressReq$Type extends MessageType<BatchGetUserAddressReq> {
    constructor() {
        super("gaw.asset.wallet.v1.BatchGetUserAddressReq", [
            { no: 1, name: "userIDs", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserAddressReq>): BatchGetUserAddressReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userIDs = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserAddressReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserAddressReq): BatchGetUserAddressReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 userIDs */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.userIDs.push(reader.int64().toNumber());
                    else
                        message.userIDs.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserAddressReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 userIDs = 1; */
        if (message.userIDs.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.userIDs.length; i++)
                writer.int64(message.userIDs[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.BatchGetUserAddressReq
 */
export const BatchGetUserAddressReq = new BatchGetUserAddressReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserAddressResp$Type extends MessageType<BatchGetUserAddressResp> {
    constructor() {
        super("gaw.asset.wallet.v1.BatchGetUserAddressResp", [
            { no: 1, name: "addresses", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserAddressResp>): BatchGetUserAddressResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.addresses = {};
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserAddressResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserAddressResp): BatchGetUserAddressResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> addresses */ 1:
                    this.binaryReadMap1(message.addresses, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: BatchGetUserAddressResp["addresses"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BatchGetUserAddressResp["addresses"] | undefined, val: BatchGetUserAddressResp["addresses"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field gaw.asset.wallet.v1.BatchGetUserAddressResp.addresses");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: BatchGetUserAddressResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> addresses = 1; */
        for (let k of globalThis.Object.keys(message.addresses))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.addresses[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.BatchGetUserAddressResp
 */
export const BatchGetUserAddressResp = new BatchGetUserAddressResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWalletAddressesByUserIDReq$Type extends MessageType<GetWalletAddressesByUserIDReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetWalletAddressesByUserIDReq", [
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetWalletAddressesByUserIDReq>): GetWalletAddressesByUserIDReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetWalletAddressesByUserIDReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWalletAddressesByUserIDReq): GetWalletAddressesByUserIDReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWalletAddressesByUserIDReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetWalletAddressesByUserIDReq
 */
export const GetWalletAddressesByUserIDReq = new GetWalletAddressesByUserIDReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWalletAddressesByUserIDResp$Type extends MessageType<GetWalletAddressesByUserIDResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetWalletAddressesByUserIDResp", [
            { no: 1, name: "addresses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WalletAddress }
        ]);
    }
    create(value?: PartialMessage<GetWalletAddressesByUserIDResp>): GetWalletAddressesByUserIDResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.addresses = [];
        if (value !== undefined)
            reflectionMergePartial<GetWalletAddressesByUserIDResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWalletAddressesByUserIDResp): GetWalletAddressesByUserIDResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.WalletAddress addresses */ 1:
                    message.addresses.push(WalletAddress.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWalletAddressesByUserIDResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.WalletAddress addresses = 1; */
        for (let i = 0; i < message.addresses.length; i++)
            WalletAddress.internalBinaryWrite(message.addresses[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetWalletAddressesByUserIDResp
 */
export const GetWalletAddressesByUserIDResp = new GetWalletAddressesByUserIDResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ERC20Info$Type extends MessageType<ERC20Info> {
    constructor() {
        super("gaw.asset.wallet.v1.ERC20Info", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "decimals", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "logo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "chainLogo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ERC20Info>): ERC20Info {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.contractAddress = "";
        message.name = "";
        message.symbol = "";
        message.decimals = 0;
        message.logo = "";
        message.chainLogo = "";
        if (value !== undefined)
            reflectionMergePartial<ERC20Info>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ERC20Info): ERC20Info {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 2:
                    message.contractAddress = reader.string();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* string symbol */ 4:
                    message.symbol = reader.string();
                    break;
                case /* uint32 decimals */ 5:
                    message.decimals = reader.uint32();
                    break;
                case /* string logo */ 6:
                    message.logo = reader.string();
                    break;
                case /* string chainLogo */ 7:
                    message.chainLogo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ERC20Info, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 2; */
        if (message.contractAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contractAddress);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* string symbol = 4; */
        if (message.symbol !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.symbol);
        /* uint32 decimals = 5; */
        if (message.decimals !== 0)
            writer.tag(5, WireType.Varint).uint32(message.decimals);
        /* string logo = 6; */
        if (message.logo !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.logo);
        /* string chainLogo = 7; */
        if (message.chainLogo !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.chainLogo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ERC20Info
 */
export const ERC20Info = new ERC20Info$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListERC20Req$Type extends MessageType<ListERC20Req> {
    constructor() {
        super("gaw.asset.wallet.v1.ListERC20Req", [
            { no: 3, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] }
        ]);
    }
    create(value?: PartialMessage<ListERC20Req>): ListERC20Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListERC20Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListERC20Req): ListERC20Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 3:
                    message.chainID = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListERC20Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 3; */
        if (message.chainID !== 0)
            writer.tag(3, WireType.Varint).int32(message.chainID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListERC20Req
 */
export const ListERC20Req = new ListERC20Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListERC20Resp$Type extends MessageType<ListERC20Resp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListERC20Resp", [
            { no: 1, name: "erc20infos", kind: "message", localName: "erc20infos", jsonName: "erc20infos", repeat: 1 /*RepeatType.PACKED*/, T: () => ERC20Info }
        ]);
    }
    create(value?: PartialMessage<ListERC20Resp>): ListERC20Resp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.erc20infos = [];
        if (value !== undefined)
            reflectionMergePartial<ListERC20Resp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListERC20Resp): ListERC20Resp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.ERC20Info erc20infos = 1 [json_name = "erc20infos"];*/ 1:
                    message.erc20infos.push(ERC20Info.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListERC20Resp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.ERC20Info erc20infos = 1 [json_name = "erc20infos"]; */
        for (let i = 0; i < message.erc20infos.length; i++)
            ERC20Info.internalBinaryWrite(message.erc20infos[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListERC20Resp
 */
export const ListERC20Resp = new ListERC20Resp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NFTInfo$Type extends MessageType<NFTInfo> {
    constructor() {
        super("gaw.asset.wallet.v1.NFTInfo", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tokenID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "logo", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "createTime", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "expiredTime", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "mttPriceRawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "desc", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 10, name: "deadline", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<NFTInfo>): NFTInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.contractAddress = "";
        message.tokenID = "";
        message.name = "";
        message.logo = "";
        message.createTime = 0;
        message.expiredTime = 0;
        message.mttPriceRawAmount = "";
        message.desc = {};
        message.deadline = 0;
        if (value !== undefined)
            reflectionMergePartial<NFTInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NFTInfo): NFTInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 2:
                    message.contractAddress = reader.string();
                    break;
                case /* string tokenID */ 3:
                    message.tokenID = reader.string();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string logo */ 5:
                    message.logo = reader.string();
                    break;
                case /* uint64 createTime */ 6:
                    message.createTime = reader.uint64().toNumber();
                    break;
                case /* uint64 expiredTime */ 7:
                    message.expiredTime = reader.uint64().toNumber();
                    break;
                case /* string mttPriceRawAmount */ 8:
                    message.mttPriceRawAmount = reader.string();
                    break;
                case /* map<string, string> desc */ 9:
                    this.binaryReadMap9(message.desc, reader, options);
                    break;
                case /* uint64 deadline */ 10:
                    message.deadline = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap9(map: NFTInfo["desc"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof NFTInfo["desc"] | undefined, val: NFTInfo["desc"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field gaw.asset.wallet.v1.NFTInfo.desc");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: NFTInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 2; */
        if (message.contractAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contractAddress);
        /* string tokenID = 3; */
        if (message.tokenID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tokenID);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string logo = 5; */
        if (message.logo !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.logo);
        /* uint64 createTime = 6; */
        if (message.createTime !== 0)
            writer.tag(6, WireType.Varint).uint64(message.createTime);
        /* uint64 expiredTime = 7; */
        if (message.expiredTime !== 0)
            writer.tag(7, WireType.Varint).uint64(message.expiredTime);
        /* string mttPriceRawAmount = 8; */
        if (message.mttPriceRawAmount !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.mttPriceRawAmount);
        /* map<string, string> desc = 9; */
        for (let k of globalThis.Object.keys(message.desc))
            writer.tag(9, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.desc[k]).join();
        /* uint64 deadline = 10; */
        if (message.deadline !== 0)
            writer.tag(10, WireType.Varint).uint64(message.deadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.NFTInfo
 */
export const NFTInfo = new NFTInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTokenInfoReq$Type extends MessageType<GetTokenInfoReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetTokenInfoReq", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTokenInfoReq>): GetTokenInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.contractAddress = "";
        if (value !== undefined)
            reflectionMergePartial<GetTokenInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTokenInfoReq): GetTokenInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 2:
                    message.contractAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTokenInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 2; */
        if (message.contractAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contractAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetTokenInfoReq
 */
export const GetTokenInfoReq = new GetTokenInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTokenInfoResp$Type extends MessageType<GetTokenInfoResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetTokenInfoResp", [
            { no: 1, name: "token", kind: "message", T: () => Token }
        ]);
    }
    create(value?: PartialMessage<GetTokenInfoResp>): GetTokenInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetTokenInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTokenInfoResp): GetTokenInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Token token */ 1:
                    message.token = Token.internalBinaryRead(reader, reader.uint32(), options, message.token);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTokenInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Token token = 1; */
        if (message.token)
            Token.internalBinaryWrite(message.token, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetTokenInfoResp
 */
export const GetTokenInfoResp = new GetTokenInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTokenInfoReq$Type extends MessageType<SaveTokenInfoReq> {
    constructor() {
        super("gaw.asset.wallet.v1.SaveTokenInfoReq", [
            { no: 2, name: "token", kind: "message", T: () => Token }
        ]);
    }
    create(value?: PartialMessage<SaveTokenInfoReq>): SaveTokenInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveTokenInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTokenInfoReq): SaveTokenInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Token token */ 2:
                    message.token = Token.internalBinaryRead(reader, reader.uint32(), options, message.token);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTokenInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Token token = 2; */
        if (message.token)
            Token.internalBinaryWrite(message.token, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SaveTokenInfoReq
 */
export const SaveTokenInfoReq = new SaveTokenInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTokenInfoResp$Type extends MessageType<SaveTokenInfoResp> {
    constructor() {
        super("gaw.asset.wallet.v1.SaveTokenInfoResp", [
            { no: 1, name: "token", kind: "message", T: () => Token }
        ]);
    }
    create(value?: PartialMessage<SaveTokenInfoResp>): SaveTokenInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveTokenInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTokenInfoResp): SaveTokenInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Token token */ 1:
                    message.token = Token.internalBinaryRead(reader, reader.uint32(), options, message.token);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTokenInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Token token = 1; */
        if (message.token)
            Token.internalBinaryWrite(message.token, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SaveTokenInfoResp
 */
export const SaveTokenInfoResp = new SaveTokenInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTokenInfoReq$Type extends MessageType<ListTokenInfoReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListTokenInfoReq", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] }
        ]);
    }
    create(value?: PartialMessage<ListTokenInfoReq>): ListTokenInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListTokenInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTokenInfoReq): ListTokenInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTokenInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListTokenInfoReq
 */
export const ListTokenInfoReq = new ListTokenInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTokenInfoResp$Type extends MessageType<ListTokenInfoResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListTokenInfoResp", [
            { no: 1, name: "tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token }
        ]);
    }
    create(value?: PartialMessage<ListTokenInfoResp>): ListTokenInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokens = [];
        if (value !== undefined)
            reflectionMergePartial<ListTokenInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTokenInfoResp): ListTokenInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.Token tokens */ 1:
                    message.tokens.push(Token.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTokenInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.Token tokens = 1; */
        for (let i = 0; i < message.tokens.length; i++)
            Token.internalBinaryWrite(message.tokens[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListTokenInfoResp
 */
export const ListTokenInfoResp = new ListTokenInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TokenExtra$Type extends MessageType<TokenExtra> {
    constructor() {
        super("gaw.asset.wallet.v1.TokenExtra", []);
    }
    create(value?: PartialMessage<TokenExtra>): TokenExtra {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<TokenExtra>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TokenExtra): TokenExtra {
        return target ?? this.create();
    }
    internalBinaryWrite(message: TokenExtra, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.TokenExtra
 */
export const TokenExtra = new TokenExtra$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChainToken$Type extends MessageType<ChainToken> {
    constructor() {
        super("gaw.asset.wallet.v1.ChainToken", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 5, name: "isActive", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "usageFrequency", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "balance", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChainToken>): ChainToken {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.contractAddress = "";
        message.createdAt = 0;
        message.extra = new Uint8Array(0);
        message.isActive = false;
        message.usageFrequency = 0;
        message.balance = "";
        if (value !== undefined)
            reflectionMergePartial<ChainToken>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChainToken): ChainToken {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 2:
                    message.contractAddress = reader.string();
                    break;
                case /* int64 createdAt */ 3:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 4:
                    message.extra = reader.bytes();
                    break;
                case /* bool isActive */ 5:
                    message.isActive = reader.bool();
                    break;
                case /* int32 usageFrequency */ 6:
                    message.usageFrequency = reader.int32();
                    break;
                case /* string balance */ 7:
                    message.balance = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChainToken, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 2; */
        if (message.contractAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contractAddress);
        /* int64 createdAt = 3; */
        if (message.createdAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.createdAt);
        /* bytes extra = 4; */
        if (message.extra.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.extra);
        /* bool isActive = 5; */
        if (message.isActive !== false)
            writer.tag(5, WireType.Varint).bool(message.isActive);
        /* int32 usageFrequency = 6; */
        if (message.usageFrequency !== 0)
            writer.tag(6, WireType.Varint).int32(message.usageFrequency);
        /* string balance = 7; */
        if (message.balance !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.balance);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ChainToken
 */
export const ChainToken = new ChainToken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WalletAccount$Type extends MessageType<WalletAccount> {
    constructor() {
        super("gaw.asset.wallet.v1.WalletAccount", [
            { no: 1, name: "tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChainToken },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "walletType", kind: "enum", T: () => ["gaw.asset.wallet.v1.WalletType", WalletType] },
            { no: 5, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 8, name: "status", kind: "enum", T: () => ["gaw.asset.wallet.v1.WalletStatus", WalletStatus] },
            { no: 9, name: "isDefaultPayment", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 10, name: "derivative", kind: "message", T: () => Derivative },
            { no: 11, name: "display", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "isBackup", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "seq", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 14, name: "authChangeAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "whiteListStatus", kind: "enum", T: () => ["gaw.asset.wallet.v1.WhiteListControl", WhiteListControl] },
            { no: 16, name: "transferMode", kind: "enum", T: () => ["gaw.asset.wallet.v1.TransferMode", TransferMode] }
        ]);
    }
    create(value?: PartialMessage<WalletAccount>): WalletAccount {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokens = [];
        message.name = "";
        message.address = "";
        message.walletType = 0;
        message.createdAt = 0;
        message.updateAt = 0;
        message.extra = new Uint8Array(0);
        message.status = 0;
        message.isDefaultPayment = false;
        message.display = false;
        message.isBackup = false;
        message.seq = 0;
        message.authChangeAt = 0;
        message.whiteListStatus = 0;
        message.transferMode = 0;
        if (value !== undefined)
            reflectionMergePartial<WalletAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WalletAccount): WalletAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.ChainToken tokens */ 1:
                    message.tokens.push(ChainToken.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string address */ 3:
                    message.address = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.WalletType walletType */ 4:
                    message.walletType = reader.int32();
                    break;
                case /* int64 createdAt */ 5:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 updateAt */ 6:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 7:
                    message.extra = reader.bytes();
                    break;
                case /* gaw.asset.wallet.v1.WalletStatus status */ 8:
                    message.status = reader.int32();
                    break;
                case /* bool isDefaultPayment */ 9:
                    message.isDefaultPayment = reader.bool();
                    break;
                case /* gaw.asset.wallet.v1.Derivative derivative */ 10:
                    message.derivative = Derivative.internalBinaryRead(reader, reader.uint32(), options, message.derivative);
                    break;
                case /* bool display */ 11:
                    message.display = reader.bool();
                    break;
                case /* bool isBackup */ 12:
                    message.isBackup = reader.bool();
                    break;
                case /* int32 seq */ 13:
                    message.seq = reader.int32();
                    break;
                case /* int64 authChangeAt */ 14:
                    message.authChangeAt = reader.int64().toNumber();
                    break;
                case /* gaw.asset.wallet.v1.WhiteListControl whiteListStatus */ 15:
                    message.whiteListStatus = reader.int32();
                    break;
                case /* gaw.asset.wallet.v1.TransferMode transferMode */ 16:
                    message.transferMode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WalletAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.ChainToken tokens = 1; */
        for (let i = 0; i < message.tokens.length; i++)
            ChainToken.internalBinaryWrite(message.tokens[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string address = 3; */
        if (message.address !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.address);
        /* gaw.asset.wallet.v1.WalletType walletType = 4; */
        if (message.walletType !== 0)
            writer.tag(4, WireType.Varint).int32(message.walletType);
        /* int64 createdAt = 5; */
        if (message.createdAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.createdAt);
        /* int64 updateAt = 6; */
        if (message.updateAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.updateAt);
        /* bytes extra = 7; */
        if (message.extra.length)
            writer.tag(7, WireType.LengthDelimited).bytes(message.extra);
        /* gaw.asset.wallet.v1.WalletStatus status = 8; */
        if (message.status !== 0)
            writer.tag(8, WireType.Varint).int32(message.status);
        /* bool isDefaultPayment = 9; */
        if (message.isDefaultPayment !== false)
            writer.tag(9, WireType.Varint).bool(message.isDefaultPayment);
        /* gaw.asset.wallet.v1.Derivative derivative = 10; */
        if (message.derivative)
            Derivative.internalBinaryWrite(message.derivative, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* bool display = 11; */
        if (message.display !== false)
            writer.tag(11, WireType.Varint).bool(message.display);
        /* bool isBackup = 12; */
        if (message.isBackup !== false)
            writer.tag(12, WireType.Varint).bool(message.isBackup);
        /* int32 seq = 13; */
        if (message.seq !== 0)
            writer.tag(13, WireType.Varint).int32(message.seq);
        /* int64 authChangeAt = 14; */
        if (message.authChangeAt !== 0)
            writer.tag(14, WireType.Varint).int64(message.authChangeAt);
        /* gaw.asset.wallet.v1.WhiteListControl whiteListStatus = 15; */
        if (message.whiteListStatus !== 0)
            writer.tag(15, WireType.Varint).int32(message.whiteListStatus);
        /* gaw.asset.wallet.v1.TransferMode transferMode = 16; */
        if (message.transferMode !== 0)
            writer.tag(16, WireType.Varint).int32(message.transferMode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.WalletAccount
 */
export const WalletAccount = new WalletAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Derivative$Type extends MessageType<Derivative> {
    constructor() {
        super("gaw.asset.wallet.v1.Derivative", [
            { no: 1, name: "parentAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<Derivative>): Derivative {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.parentAddress = "";
        message.index = 0;
        if (value !== undefined)
            reflectionMergePartial<Derivative>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Derivative): Derivative {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parentAddress */ 1:
                    message.parentAddress = reader.string();
                    break;
                case /* int32 index */ 2:
                    message.index = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Derivative, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parentAddress = 1; */
        if (message.parentAddress !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.parentAddress);
        /* int32 index = 2; */
        if (message.index !== 0)
            writer.tag(2, WireType.Varint).int32(message.index);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Derivative
 */
export const Derivative = new Derivative$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWalletReq$Type extends MessageType<GetWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetWalletReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetWalletReq>): GetWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<GetWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWalletReq): GetWalletReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetWalletReq
 */
export const GetWalletReq = new GetWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetWalletResp$Type extends MessageType<GetWalletResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetWalletResp", [
            { no: 1, name: "walletAccount", kind: "message", T: () => WalletAccount }
        ]);
    }
    create(value?: PartialMessage<GetWalletResp>): GetWalletResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetWalletResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetWalletResp): GetWalletResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.WalletAccount walletAccount */ 1:
                    message.walletAccount = WalletAccount.internalBinaryRead(reader, reader.uint32(), options, message.walletAccount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetWalletResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.WalletAccount walletAccount = 1; */
        if (message.walletAccount)
            WalletAccount.internalBinaryWrite(message.walletAccount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetWalletResp
 */
export const GetWalletResp = new GetWalletResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWalletReq$Type extends MessageType<ListWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListWalletReq", []);
    }
    create(value?: PartialMessage<ListWalletReq>): ListWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListWalletReq): ListWalletReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListWalletReq
 */
export const ListWalletReq = new ListWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWalletResp$Type extends MessageType<ListWalletResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListWalletResp", [
            { no: 1, name: "walletAccounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WalletAccount }
        ]);
    }
    create(value?: PartialMessage<ListWalletResp>): ListWalletResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.walletAccounts = [];
        if (value !== undefined)
            reflectionMergePartial<ListWalletResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListWalletResp): ListWalletResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.WalletAccount walletAccounts */ 1:
                    message.walletAccounts.push(WalletAccount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListWalletResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.WalletAccount walletAccounts = 1; */
        for (let i = 0; i < message.walletAccounts.length; i++)
            WalletAccount.internalBinaryWrite(message.walletAccounts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListWalletResp
 */
export const ListWalletResp = new ListWalletResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetActiveWalletReq$Type extends MessageType<GetActiveWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetActiveWalletReq", []);
    }
    create(value?: PartialMessage<GetActiveWalletReq>): GetActiveWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetActiveWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetActiveWalletReq): GetActiveWalletReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetActiveWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetActiveWalletReq
 */
export const GetActiveWalletReq = new GetActiveWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustodialWalletReq$Type extends MessageType<GetCustodialWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetCustodialWalletReq", []);
    }
    create(value?: PartialMessage<GetCustodialWalletReq>): GetCustodialWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetCustodialWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustodialWalletReq): GetCustodialWalletReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetCustodialWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetCustodialWalletReq
 */
export const GetCustodialWalletReq = new GetCustodialWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateWalletReq$Type extends MessageType<UpdateWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.UpdateWalletReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "message", T: () => StringValue },
            { no: 3, name: "status", kind: "enum", T: () => ["gaw.asset.wallet.v1.WalletStatus", WalletStatus] },
            { no: 4, name: "isDefaultPayment", kind: "message", T: () => BoolValue },
            { no: 5, name: "display", kind: "message", T: () => BoolValue },
            { no: 6, name: "isBackup", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<UpdateWalletReq>): UpdateWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateWalletReq): UpdateWalletReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* google.protobuf.StringValue name */ 2:
                    message.name = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.name);
                    break;
                case /* gaw.asset.wallet.v1.WalletStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* google.protobuf.BoolValue isDefaultPayment */ 4:
                    message.isDefaultPayment = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isDefaultPayment);
                    break;
                case /* google.protobuf.BoolValue display */ 5:
                    message.display = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.display);
                    break;
                case /* google.protobuf.BoolValue isBackup */ 6:
                    message.isBackup = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.isBackup);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* google.protobuf.StringValue name = 2; */
        if (message.name)
            StringValue.internalBinaryWrite(message.name, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.WalletStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* google.protobuf.BoolValue isDefaultPayment = 4; */
        if (message.isDefaultPayment)
            BoolValue.internalBinaryWrite(message.isDefaultPayment, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue display = 5; */
        if (message.display)
            BoolValue.internalBinaryWrite(message.display, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.BoolValue isBackup = 6; */
        if (message.isBackup)
            BoolValue.internalBinaryWrite(message.isBackup, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.UpdateWalletReq
 */
export const UpdateWalletReq = new UpdateWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateWalletResp$Type extends MessageType<UpdateWalletResp> {
    constructor() {
        super("gaw.asset.wallet.v1.UpdateWalletResp", []);
    }
    create(value?: PartialMessage<UpdateWalletResp>): UpdateWalletResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateWalletResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateWalletResp): UpdateWalletResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateWalletResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.UpdateWalletResp
 */
export const UpdateWalletResp = new UpdateWalletResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWalletReq$Type extends MessageType<CreateWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateWalletReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "walletType", kind: "enum", T: () => ["gaw.asset.wallet.v1.WalletType", WalletType] },
            { no: 4, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 5, name: "status", kind: "enum", T: () => ["gaw.asset.wallet.v1.WalletStatus", WalletStatus] },
            { no: 6, name: "isDefaultPayment", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "derivative", kind: "message", T: () => Derivative },
            { no: 8, name: "isBackup", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateWalletReq>): CreateWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.address = "";
        message.walletType = 0;
        message.extra = new Uint8Array(0);
        message.status = 0;
        message.isDefaultPayment = false;
        message.isBackup = false;
        if (value !== undefined)
            reflectionMergePartial<CreateWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWalletReq): CreateWalletReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.WalletType walletType */ 3:
                    message.walletType = reader.int32();
                    break;
                case /* bytes extra */ 4:
                    message.extra = reader.bytes();
                    break;
                case /* gaw.asset.wallet.v1.WalletStatus status */ 5:
                    message.status = reader.int32();
                    break;
                case /* bool isDefaultPayment */ 6:
                    message.isDefaultPayment = reader.bool();
                    break;
                case /* gaw.asset.wallet.v1.Derivative derivative */ 7:
                    message.derivative = Derivative.internalBinaryRead(reader, reader.uint32(), options, message.derivative);
                    break;
                case /* bool isBackup */ 8:
                    message.isBackup = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* gaw.asset.wallet.v1.WalletType walletType = 3; */
        if (message.walletType !== 0)
            writer.tag(3, WireType.Varint).int32(message.walletType);
        /* bytes extra = 4; */
        if (message.extra.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.extra);
        /* gaw.asset.wallet.v1.WalletStatus status = 5; */
        if (message.status !== 0)
            writer.tag(5, WireType.Varint).int32(message.status);
        /* bool isDefaultPayment = 6; */
        if (message.isDefaultPayment !== false)
            writer.tag(6, WireType.Varint).bool(message.isDefaultPayment);
        /* gaw.asset.wallet.v1.Derivative derivative = 7; */
        if (message.derivative)
            Derivative.internalBinaryWrite(message.derivative, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bool isBackup = 8; */
        if (message.isBackup !== false)
            writer.tag(8, WireType.Varint).bool(message.isBackup);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateWalletReq
 */
export const CreateWalletReq = new CreateWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PullAddressSecretReq$Type extends MessageType<PullAddressSecretReq> {
    constructor() {
        super("gaw.asset.wallet.v1.PullAddressSecretReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "negotiateKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PullAddressSecretReq>): PullAddressSecretReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        message.negotiateKey = "";
        if (value !== undefined)
            reflectionMergePartial<PullAddressSecretReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PullAddressSecretReq): PullAddressSecretReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* string negotiateKey */ 2:
                    message.negotiateKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PullAddressSecretReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* string negotiateKey = 2; */
        if (message.negotiateKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.negotiateKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.PullAddressSecretReq
 */
export const PullAddressSecretReq = new PullAddressSecretReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PullAddressSecretResp$Type extends MessageType<PullAddressSecretResp> {
    constructor() {
        super("gaw.asset.wallet.v1.PullAddressSecretResp", [
            { no: 1, name: "encryptedSecret", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PullAddressSecretResp>): PullAddressSecretResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.encryptedSecret = "";
        if (value !== undefined)
            reflectionMergePartial<PullAddressSecretResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PullAddressSecretResp): PullAddressSecretResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string encryptedSecret */ 1:
                    message.encryptedSecret = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PullAddressSecretResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string encryptedSecret = 1; */
        if (message.encryptedSecret !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.encryptedSecret);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.PullAddressSecretResp
 */
export const PullAddressSecretResp = new PullAddressSecretResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitCustodialWalletReq$Type extends MessageType<InitCustodialWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.InitCustodialWalletReq", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<InitCustodialWalletReq>): InitCustodialWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.address = "";
        message.appID = 0;
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<InitCustodialWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitCustodialWalletReq): InitCustodialWalletReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name = 1 [deprecated = true];*/ 1:
                    message.name = reader.string();
                    break;
                case /* string address = 2 [deprecated = true];*/ 2:
                    message.address = reader.string();
                    break;
                case /* int32 appID */ 3:
                    message.appID = reader.int32();
                    break;
                case /* int64 userID */ 4:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitCustodialWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1 [deprecated = true]; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string address = 2 [deprecated = true]; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* int32 appID = 3; */
        if (message.appID !== 0)
            writer.tag(3, WireType.Varint).int32(message.appID);
        /* int64 userID = 4; */
        if (message.userID !== 0)
            writer.tag(4, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.InitCustodialWalletReq
 */
export const InitCustodialWalletReq = new InitCustodialWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteWalletReq$Type extends MessageType<DeleteWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.DeleteWalletReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteWalletReq>): DeleteWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteWalletReq): DeleteWalletReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.DeleteWalletReq
 */
export const DeleteWalletReq = new DeleteWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteWalletResp$Type extends MessageType<DeleteWalletResp> {
    constructor() {
        super("gaw.asset.wallet.v1.DeleteWalletResp", []);
    }
    create(value?: PartialMessage<DeleteWalletResp>): DeleteWalletResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteWalletResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteWalletResp): DeleteWalletResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteWalletResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.DeleteWalletResp
 */
export const DeleteWalletResp = new DeleteWalletResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDefaultPaymentWalletReq$Type extends MessageType<GetDefaultPaymentWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetDefaultPaymentWalletReq", [
            { no: 1, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetDefaultPaymentWalletReq>): GetDefaultPaymentWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<GetDefaultPaymentWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDefaultPaymentWalletReq): GetDefaultPaymentWalletReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 userID */ 1:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDefaultPaymentWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 userID = 1; */
        if (message.userID !== 0)
            writer.tag(1, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetDefaultPaymentWalletReq
 */
export const GetDefaultPaymentWalletReq = new GetDefaultPaymentWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateChainTokenReq$Type extends MessageType<CreateChainTokenReq> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateChainTokenReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 3, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<CreateChainTokenReq>): CreateChainTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        message.chainID = 0;
        message.contractAddress = "";
        message.extra = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<CreateChainTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateChainTokenReq): CreateChainTokenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 2:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 3:
                    message.contractAddress = reader.string();
                    break;
                case /* bytes extra */ 4:
                    message.extra = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateChainTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* gaw.base.ChainID chainID = 2; */
        if (message.chainID !== 0)
            writer.tag(2, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 3; */
        if (message.contractAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contractAddress);
        /* bytes extra = 4; */
        if (message.extra.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.extra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateChainTokenReq
 */
export const CreateChainTokenReq = new CreateChainTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateChainTokenResp$Type extends MessageType<CreateChainTokenResp> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateChainTokenResp", [
            { no: 1, name: "token", kind: "message", T: () => ChainToken }
        ]);
    }
    create(value?: PartialMessage<CreateChainTokenResp>): CreateChainTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateChainTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateChainTokenResp): CreateChainTokenResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.ChainToken token */ 1:
                    message.token = ChainToken.internalBinaryRead(reader, reader.uint32(), options, message.token);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateChainTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.ChainToken token = 1; */
        if (message.token)
            ChainToken.internalBinaryWrite(message.token, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateChainTokenResp
 */
export const CreateChainTokenResp = new CreateChainTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateChainTokenReq$Type extends MessageType<UpdateChainTokenReq> {
    constructor() {
        super("gaw.asset.wallet.v1.UpdateChainTokenReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 3, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "incrUsageFrequency", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "active", kind: "message", T: () => BoolValue }
        ]);
    }
    create(value?: PartialMessage<UpdateChainTokenReq>): UpdateChainTokenReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        message.chainID = 0;
        message.contractAddress = "";
        message.incrUsageFrequency = false;
        if (value !== undefined)
            reflectionMergePartial<UpdateChainTokenReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateChainTokenReq): UpdateChainTokenReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 2:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 3:
                    message.contractAddress = reader.string();
                    break;
                case /* bool incrUsageFrequency */ 5:
                    message.incrUsageFrequency = reader.bool();
                    break;
                case /* google.protobuf.BoolValue active */ 6:
                    message.active = BoolValue.internalBinaryRead(reader, reader.uint32(), options, message.active);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateChainTokenReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* gaw.base.ChainID chainID = 2; */
        if (message.chainID !== 0)
            writer.tag(2, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 3; */
        if (message.contractAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contractAddress);
        /* bool incrUsageFrequency = 5; */
        if (message.incrUsageFrequency !== false)
            writer.tag(5, WireType.Varint).bool(message.incrUsageFrequency);
        /* google.protobuf.BoolValue active = 6; */
        if (message.active)
            BoolValue.internalBinaryWrite(message.active, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.UpdateChainTokenReq
 */
export const UpdateChainTokenReq = new UpdateChainTokenReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateChainTokenResp$Type extends MessageType<UpdateChainTokenResp> {
    constructor() {
        super("gaw.asset.wallet.v1.UpdateChainTokenResp", []);
    }
    create(value?: PartialMessage<UpdateChainTokenResp>): UpdateChainTokenResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateChainTokenResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateChainTokenResp): UpdateChainTokenResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateChainTokenResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.UpdateChainTokenResp
 */
export const UpdateChainTokenResp = new UpdateChainTokenResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntentTransaction$Type extends MessageType<IntentTransaction> {
    constructor() {
        super("gaw.asset.wallet.v1.IntentTransaction", [
            { no: 1, name: "chain_id", kind: "enum", localName: "chain_id", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "txs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => IntentTransaction_Tx },
            { no: 3, name: "sender", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IntentTransaction>): IntentTransaction {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chain_id = 0;
        message.txs = [];
        message.sender = "";
        if (value !== undefined)
            reflectionMergePartial<IntentTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IntentTransaction): IntentTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chain_id */ 1:
                    message.chain_id = reader.int32();
                    break;
                case /* repeated gaw.asset.wallet.v1.IntentTransaction.Tx txs */ 2:
                    message.txs.push(IntentTransaction_Tx.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string sender */ 3:
                    message.sender = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IntentTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chain_id = 1; */
        if (message.chain_id !== 0)
            writer.tag(1, WireType.Varint).int32(message.chain_id);
        /* repeated gaw.asset.wallet.v1.IntentTransaction.Tx txs = 2; */
        for (let i = 0; i < message.txs.length; i++)
            IntentTransaction_Tx.internalBinaryWrite(message.txs[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string sender = 3; */
        if (message.sender !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.sender);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.IntentTransaction
 */
export const IntentTransaction = new IntentTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntentTransaction_Input$Type extends MessageType<IntentTransaction_Input> {
    constructor() {
        super("gaw.asset.wallet.v1.IntentTransaction.Input", [
            { no: 1, name: "spender", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IntentTransaction_Input>): IntentTransaction_Input {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.spender = "";
        message.amount = "";
        if (value !== undefined)
            reflectionMergePartial<IntentTransaction_Input>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IntentTransaction_Input): IntentTransaction_Input {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string spender */ 1:
                    message.spender = reader.string();
                    break;
                case /* string amount */ 2:
                    message.amount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IntentTransaction_Input, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string spender = 1; */
        if (message.spender !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.spender);
        /* string amount = 2; */
        if (message.amount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.amount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.IntentTransaction.Input
 */
export const IntentTransaction_Input = new IntentTransaction_Input$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntentTransaction_Tx$Type extends MessageType<IntentTransaction_Tx> {
    constructor() {
        super("gaw.asset.wallet.v1.IntentTransaction.Tx", [
            { no: 1, name: "type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "input", kind: "message", T: () => IntentTransaction_Input }
        ]);
    }
    create(value?: PartialMessage<IntentTransaction_Tx>): IntentTransaction_Tx {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = "";
        message.to = "";
        message.value = "";
        message.data = "";
        if (value !== undefined)
            reflectionMergePartial<IntentTransaction_Tx>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IntentTransaction_Tx): IntentTransaction_Tx {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string type */ 1:
                    message.type = reader.string();
                    break;
                case /* string to */ 3:
                    message.to = reader.string();
                    break;
                case /* string value */ 4:
                    message.value = reader.string();
                    break;
                case /* string data */ 6:
                    message.data = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.IntentTransaction.Input input */ 5:
                    message.input = IntentTransaction_Input.internalBinaryRead(reader, reader.uint32(), options, message.input);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IntentTransaction_Tx, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string type = 1; */
        if (message.type !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.type);
        /* string to = 3; */
        if (message.to !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.to);
        /* string value = 4; */
        if (message.value !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.value);
        /* string data = 6; */
        if (message.data !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.data);
        /* gaw.asset.wallet.v1.IntentTransaction.Input input = 5; */
        if (message.input)
            IntentTransaction_Input.internalBinaryWrite(message.input, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.IntentTransaction.Tx
 */
export const IntentTransaction_Tx = new IntentTransaction_Tx$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Intent$Type extends MessageType<Intent> {
    constructor() {
        super("gaw.asset.wallet.v1.Intent", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["gaw.asset.wallet.v1.IntentType", IntentType] },
            { no: 3, name: "transaction", kind: "message", oneof: "data", T: () => IntentTransaction },
            { no: 15, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Intent>): Intent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.type = 0;
        message.data = { oneofKind: undefined };
        message.deadline = 0;
        if (value !== undefined)
            reflectionMergePartial<Intent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Intent): Intent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.IntentType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* gaw.asset.wallet.v1.IntentTransaction transaction */ 3:
                    message.data = {
                        oneofKind: "transaction",
                        transaction: IntentTransaction.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).transaction)
                    };
                    break;
                case /* int64 deadline */ 15:
                    message.deadline = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Intent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* gaw.asset.wallet.v1.IntentType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* gaw.asset.wallet.v1.IntentTransaction transaction = 3; */
        if (message.data.oneofKind === "transaction")
            IntentTransaction.internalBinaryWrite(message.data.transaction, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 deadline = 15; */
        if (message.deadline !== 0)
            writer.tag(15, WireType.Varint).int64(message.deadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Intent
 */
export const Intent = new Intent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateIntentReq$Type extends MessageType<CreateIntentReq> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateIntentReq", [
            { no: 3, name: "transaction", kind: "message", oneof: "data", T: () => IntentTransaction },
            { no: 15, name: "ttl", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CreateIntentReq>): CreateIntentReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.data = { oneofKind: undefined };
        message.ttl = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateIntentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateIntentReq): CreateIntentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.IntentTransaction transaction */ 3:
                    message.data = {
                        oneofKind: "transaction",
                        transaction: IntentTransaction.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).transaction)
                    };
                    break;
                case /* int64 ttl */ 15:
                    message.ttl = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateIntentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.IntentTransaction transaction = 3; */
        if (message.data.oneofKind === "transaction")
            IntentTransaction.internalBinaryWrite(message.data.transaction, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 ttl = 15; */
        if (message.ttl !== 0)
            writer.tag(15, WireType.Varint).int64(message.ttl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateIntentReq
 */
export const CreateIntentReq = new CreateIntentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateIntentResp$Type extends MessageType<CreateIntentResp> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateIntentResp", [
            { no: 1, name: "intent", kind: "message", T: () => Intent },
            { no: 2, name: "intentLink", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<CreateIntentResp>): CreateIntentResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.intentLink = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<CreateIntentResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateIntentResp): CreateIntentResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Intent intent */ 1:
                    message.intent = Intent.internalBinaryRead(reader, reader.uint32(), options, message.intent);
                    break;
                case /* bytes intentLink */ 2:
                    message.intentLink = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateIntentResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Intent intent = 1; */
        if (message.intent)
            Intent.internalBinaryWrite(message.intent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bytes intentLink = 2; */
        if (message.intentLink.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.intentLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateIntentResp
 */
export const CreateIntentResp = new CreateIntentResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIntentReq$Type extends MessageType<GetIntentReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetIntentReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetIntentReq>): GetIntentReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<GetIntentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIntentReq): GetIntentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIntentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetIntentReq
 */
export const GetIntentReq = new GetIntentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIntentResp$Type extends MessageType<GetIntentResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetIntentResp", [
            { no: 1, name: "intent", kind: "message", T: () => Intent }
        ]);
    }
    create(value?: PartialMessage<GetIntentResp>): GetIntentResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetIntentResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIntentResp): GetIntentResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Intent intent */ 1:
                    message.intent = Intent.internalBinaryRead(reader, reader.uint32(), options, message.intent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIntentResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Intent intent = 1; */
        if (message.intent)
            Intent.internalBinaryWrite(message.intent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetIntentResp
 */
export const GetIntentResp = new GetIntentResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvalidateIntentReq$Type extends MessageType<InvalidateIntentReq> {
    constructor() {
        super("gaw.asset.wallet.v1.InvalidateIntentReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InvalidateIntentReq>): InvalidateIntentReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<InvalidateIntentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvalidateIntentReq): InvalidateIntentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InvalidateIntentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.InvalidateIntentReq
 */
export const InvalidateIntentReq = new InvalidateIntentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InvalidateIntentResp$Type extends MessageType<InvalidateIntentResp> {
    constructor() {
        super("gaw.asset.wallet.v1.InvalidateIntentResp", []);
    }
    create(value?: PartialMessage<InvalidateIntentResp>): InvalidateIntentResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<InvalidateIntentResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InvalidateIntentResp): InvalidateIntentResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: InvalidateIntentResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.InvalidateIntentResp
 */
export const InvalidateIntentResp = new InvalidateIntentResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIntentByLinkReq$Type extends MessageType<GetIntentByLinkReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetIntentByLinkReq", [
            { no: 1, name: "intentLink", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetIntentByLinkReq>): GetIntentByLinkReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.intentLink = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<GetIntentByLinkReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIntentByLinkReq): GetIntentByLinkReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes intentLink */ 1:
                    message.intentLink = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIntentByLinkReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes intentLink = 1; */
        if (message.intentLink.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.intentLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetIntentByLinkReq
 */
export const GetIntentByLinkReq = new GetIntentByLinkReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIntentByLinkResp$Type extends MessageType<GetIntentByLinkResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetIntentByLinkResp", [
            { no: 1, name: "intent", kind: "message", T: () => Intent }
        ]);
    }
    create(value?: PartialMessage<GetIntentByLinkResp>): GetIntentByLinkResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetIntentByLinkResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIntentByLinkResp): GetIntentByLinkResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Intent intent */ 1:
                    message.intent = Intent.internalBinaryRead(reader, reader.uint32(), options, message.intent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIntentByLinkResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Intent intent = 1; */
        if (message.intent)
            Intent.internalBinaryWrite(message.intent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetIntentByLinkResp
 */
export const GetIntentByLinkResp = new GetIntentByLinkResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedPacketInfo$Type extends MessageType<RedPacketInfo> {
    constructor() {
        super("gaw.asset.wallet.v1.RedPacketInfo", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["gaw.asset.wallet.v1.RedPacketType", RedPacketType] },
            { no: 3, name: "status", kind: "enum", T: () => ["gaw.asset.wallet.v1.RedPacketStatus", RedPacketStatus] },
            { no: 4, name: "groupID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 7, name: "userAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "contractAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "tokenContract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "totalAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "remainAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "totalCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "remainCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 16, name: "txHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RedPacketInfo>): RedPacketInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.type = 0;
        message.status = 0;
        message.groupID = 0;
        message.userID = 0;
        message.chainID = 0;
        message.userAddr = "";
        message.contractAddr = "";
        message.tokenContract = "";
        message.totalAmount = "";
        message.remainAmount = "";
        message.totalCount = 0;
        message.remainCount = 0;
        message.txHash = "";
        message.expiredAt = 0;
        message.createdAt = 0;
        if (value !== undefined)
            reflectionMergePartial<RedPacketInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedPacketInfo): RedPacketInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.RedPacketType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* gaw.asset.wallet.v1.RedPacketStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* int64 groupID */ 4:
                    message.groupID = reader.int64().toNumber();
                    break;
                case /* int64 userID */ 5:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* gaw.base.ChainID chainID */ 6:
                    message.chainID = reader.int32();
                    break;
                case /* string userAddr */ 7:
                    message.userAddr = reader.string();
                    break;
                case /* string contractAddr */ 8:
                    message.contractAddr = reader.string();
                    break;
                case /* string tokenContract */ 9:
                    message.tokenContract = reader.string();
                    break;
                case /* string totalAmount */ 10:
                    message.totalAmount = reader.string();
                    break;
                case /* string remainAmount */ 11:
                    message.remainAmount = reader.string();
                    break;
                case /* int32 totalCount */ 12:
                    message.totalCount = reader.int32();
                    break;
                case /* int32 remainCount */ 13:
                    message.remainCount = reader.int32();
                    break;
                case /* string txHash */ 16:
                    message.txHash = reader.string();
                    break;
                case /* int64 expiredAt */ 17:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                case /* int64 createdAt */ 18:
                    message.createdAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedPacketInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* gaw.asset.wallet.v1.RedPacketType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* gaw.asset.wallet.v1.RedPacketStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* int64 groupID = 4; */
        if (message.groupID !== 0)
            writer.tag(4, WireType.Varint).int64(message.groupID);
        /* int64 userID = 5; */
        if (message.userID !== 0)
            writer.tag(5, WireType.Varint).int64(message.userID);
        /* gaw.base.ChainID chainID = 6; */
        if (message.chainID !== 0)
            writer.tag(6, WireType.Varint).int32(message.chainID);
        /* string userAddr = 7; */
        if (message.userAddr !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.userAddr);
        /* string contractAddr = 8; */
        if (message.contractAddr !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.contractAddr);
        /* string tokenContract = 9; */
        if (message.tokenContract !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.tokenContract);
        /* string totalAmount = 10; */
        if (message.totalAmount !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.totalAmount);
        /* string remainAmount = 11; */
        if (message.remainAmount !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.remainAmount);
        /* int32 totalCount = 12; */
        if (message.totalCount !== 0)
            writer.tag(12, WireType.Varint).int32(message.totalCount);
        /* int32 remainCount = 13; */
        if (message.remainCount !== 0)
            writer.tag(13, WireType.Varint).int32(message.remainCount);
        /* string txHash = 16; */
        if (message.txHash !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.txHash);
        /* int64 expiredAt = 17; */
        if (message.expiredAt !== 0)
            writer.tag(17, WireType.Varint).int64(message.expiredAt);
        /* int64 createdAt = 18; */
        if (message.createdAt !== 0)
            writer.tag(18, WireType.Varint).int64(message.createdAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.RedPacketInfo
 */
export const RedPacketInfo = new RedPacketInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRedPacketReq$Type extends MessageType<CreateRedPacketReq> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateRedPacketReq", [
            { no: 2, name: "type", kind: "enum", T: () => ["gaw.asset.wallet.v1.RedPacketType", RedPacketType] },
            { no: 3, name: "groupID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CreateRedPacketReq>): CreateRedPacketReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.groupID = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateRedPacketReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRedPacketReq): CreateRedPacketReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.RedPacketType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* int64 groupID */ 3:
                    message.groupID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRedPacketReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.RedPacketType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* int64 groupID = 3; */
        if (message.groupID !== 0)
            writer.tag(3, WireType.Varint).int64(message.groupID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateRedPacketReq
 */
export const CreateRedPacketReq = new CreateRedPacketReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateRedPacketResp$Type extends MessageType<CreateRedPacketResp> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateRedPacketResp", [
            { no: 1, name: "redPacketInfo", kind: "message", T: () => RedPacketInfo }
        ]);
    }
    create(value?: PartialMessage<CreateRedPacketResp>): CreateRedPacketResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateRedPacketResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateRedPacketResp): CreateRedPacketResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.RedPacketInfo redPacketInfo */ 1:
                    message.redPacketInfo = RedPacketInfo.internalBinaryRead(reader, reader.uint32(), options, message.redPacketInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateRedPacketResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.RedPacketInfo redPacketInfo = 1; */
        if (message.redPacketInfo)
            RedPacketInfo.internalBinaryWrite(message.redPacketInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateRedPacketResp
 */
export const CreateRedPacketResp = new CreateRedPacketResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRedPacketInfoReq$Type extends MessageType<GetRedPacketInfoReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetRedPacketInfoReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRedPacketInfoReq>): GetRedPacketInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<GetRedPacketInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRedPacketInfoReq): GetRedPacketInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRedPacketInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetRedPacketInfoReq
 */
export const GetRedPacketInfoReq = new GetRedPacketInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRedPacketInfoResp$Type extends MessageType<GetRedPacketInfoResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetRedPacketInfoResp", [
            { no: 1, name: "redPacketInfo", kind: "message", T: () => RedPacketInfo }
        ]);
    }
    create(value?: PartialMessage<GetRedPacketInfoResp>): GetRedPacketInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetRedPacketInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRedPacketInfoResp): GetRedPacketInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.RedPacketInfo redPacketInfo */ 1:
                    message.redPacketInfo = RedPacketInfo.internalBinaryRead(reader, reader.uint32(), options, message.redPacketInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRedPacketInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.RedPacketInfo redPacketInfo = 1; */
        if (message.redPacketInfo)
            RedPacketInfo.internalBinaryWrite(message.redPacketInfo, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetRedPacketInfoResp
 */
export const GetRedPacketInfoResp = new GetRedPacketInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClaimRedPacketSignatureReq$Type extends MessageType<GetClaimRedPacketSignatureReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetClaimRedPacketSignatureReq", [
            { no: 1, name: "redPacketID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetClaimRedPacketSignatureReq>): GetClaimRedPacketSignatureReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.redPacketID = "";
        if (value !== undefined)
            reflectionMergePartial<GetClaimRedPacketSignatureReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetClaimRedPacketSignatureReq): GetClaimRedPacketSignatureReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redPacketID */ 1:
                    message.redPacketID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetClaimRedPacketSignatureReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redPacketID = 1; */
        if (message.redPacketID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redPacketID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetClaimRedPacketSignatureReq
 */
export const GetClaimRedPacketSignatureReq = new GetClaimRedPacketSignatureReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetClaimRedPacketSignatureResp$Type extends MessageType<GetClaimRedPacketSignatureResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetClaimRedPacketSignatureResp", [
            { no: 1, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetClaimRedPacketSignatureResp>): GetClaimRedPacketSignatureResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.signature = "";
        if (value !== undefined)
            reflectionMergePartial<GetClaimRedPacketSignatureResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetClaimRedPacketSignatureResp): GetClaimRedPacketSignatureResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string signature */ 1:
                    message.signature = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetClaimRedPacketSignatureResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string signature = 1; */
        if (message.signature !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.signature);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetClaimRedPacketSignatureResp
 */
export const GetClaimRedPacketSignatureResp = new GetClaimRedPacketSignatureResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedPacketClaimRecord$Type extends MessageType<RedPacketClaimRecord> {
    constructor() {
        super("gaw.asset.wallet.v1.RedPacketClaimRecord", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "redPacketID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "userAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "claimedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RedPacketClaimRecord>): RedPacketClaimRecord {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.redPacketID = "";
        message.userID = 0;
        message.userAddr = "";
        message.amount = "";
        message.claimedAt = 0;
        if (value !== undefined)
            reflectionMergePartial<RedPacketClaimRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedPacketClaimRecord): RedPacketClaimRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string redPacketID */ 2:
                    message.redPacketID = reader.string();
                    break;
                case /* int64 userID */ 5:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* string userAddr */ 6:
                    message.userAddr = reader.string();
                    break;
                case /* string amount */ 7:
                    message.amount = reader.string();
                    break;
                case /* int64 claimedAt */ 8:
                    message.claimedAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedPacketClaimRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string redPacketID = 2; */
        if (message.redPacketID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.redPacketID);
        /* int64 userID = 5; */
        if (message.userID !== 0)
            writer.tag(5, WireType.Varint).int64(message.userID);
        /* string userAddr = 6; */
        if (message.userAddr !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.userAddr);
        /* string amount = 7; */
        if (message.amount !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.amount);
        /* int64 claimedAt = 8; */
        if (message.claimedAt !== 0)
            writer.tag(8, WireType.Varint).int64(message.claimedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.RedPacketClaimRecord
 */
export const RedPacketClaimRecord = new RedPacketClaimRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRedPacketClaimRecordsReq$Type extends MessageType<ListRedPacketClaimRecordsReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListRedPacketClaimRecordsReq", [
            { no: 1, name: "redPacketID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListRedPacketClaimRecordsReq>): ListRedPacketClaimRecordsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.redPacketID = "";
        if (value !== undefined)
            reflectionMergePartial<ListRedPacketClaimRecordsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRedPacketClaimRecordsReq): ListRedPacketClaimRecordsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string redPacketID */ 1:
                    message.redPacketID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRedPacketClaimRecordsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string redPacketID = 1; */
        if (message.redPacketID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.redPacketID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListRedPacketClaimRecordsReq
 */
export const ListRedPacketClaimRecordsReq = new ListRedPacketClaimRecordsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRedPacketClaimRecordsResp$Type extends MessageType<ListRedPacketClaimRecordsResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListRedPacketClaimRecordsResp", [
            { no: 1, name: "records", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RedPacketClaimRecord }
        ]);
    }
    create(value?: PartialMessage<ListRedPacketClaimRecordsResp>): ListRedPacketClaimRecordsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.records = [];
        if (value !== undefined)
            reflectionMergePartial<ListRedPacketClaimRecordsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRedPacketClaimRecordsResp): ListRedPacketClaimRecordsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.RedPacketClaimRecord records */ 1:
                    message.records.push(RedPacketClaimRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRedPacketClaimRecordsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.RedPacketClaimRecord records = 1; */
        for (let i = 0; i < message.records.length; i++)
            RedPacketClaimRecord.internalBinaryWrite(message.records[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListRedPacketClaimRecordsResp
 */
export const ListRedPacketClaimRecordsResp = new ListRedPacketClaimRecordsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendSessionEventReq$Type extends MessageType<SendSessionEventReq> {
    constructor() {
        super("gaw.asset.wallet.v1.SendSessionEventReq", [
            { no: 3, name: "eventArgs", kind: "message", T: () => EventArguments },
            { no: 4, name: "callbackURL", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendSessionEventReq>): SendSessionEventReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.callbackURL = "";
        if (value !== undefined)
            reflectionMergePartial<SendSessionEventReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendSessionEventReq): SendSessionEventReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.EventArguments eventArgs */ 3:
                    message.eventArgs = EventArguments.internalBinaryRead(reader, reader.uint32(), options, message.eventArgs);
                    break;
                case /* string callbackURL */ 4:
                    message.callbackURL = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendSessionEventReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.EventArguments eventArgs = 3; */
        if (message.eventArgs)
            EventArguments.internalBinaryWrite(message.eventArgs, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string callbackURL = 4; */
        if (message.callbackURL !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.callbackURL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SendSessionEventReq
 */
export const SendSessionEventReq = new SendSessionEventReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendSessionEventResp$Type extends MessageType<SendSessionEventResp> {
    constructor() {
        super("gaw.asset.wallet.v1.SendSessionEventResp", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SendSessionEventResp>): SendSessionEventResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<SendSessionEventResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendSessionEventResp): SendSessionEventResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendSessionEventResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SendSessionEventResp
 */
export const SendSessionEventResp = new SendSessionEventResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgSessionEvent$Type extends MessageType<MsgSessionEvent> {
    constructor() {
        super("gaw.asset.wallet.v1.MsgSessionEvent", [
            { no: 2, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgSessionEvent>): MsgSessionEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgSessionEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgSessionEvent): MsgSessionEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 2:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgSessionEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 2; */
        if (message.id !== 0)
            writer.tag(2, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.MsgSessionEvent
 */
export const MsgSessionEvent = new MsgSessionEvent$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResponseSessionEventReq$Type extends MessageType<ResponseSessionEventReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ResponseSessionEventReq", [
            { no: 1, name: "jsonResponse", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResponseSessionEventReq>): ResponseSessionEventReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.jsonResponse = "";
        if (value !== undefined)
            reflectionMergePartial<ResponseSessionEventReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResponseSessionEventReq): ResponseSessionEventReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string jsonResponse */ 1:
                    message.jsonResponse = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResponseSessionEventReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string jsonResponse = 1; */
        if (message.jsonResponse !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.jsonResponse);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ResponseSessionEventReq
 */
export const ResponseSessionEventReq = new ResponseSessionEventReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResponseSessionEventResp$Type extends MessageType<ResponseSessionEventResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ResponseSessionEventResp", []);
    }
    create(value?: PartialMessage<ResponseSessionEventResp>): ResponseSessionEventResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ResponseSessionEventResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResponseSessionEventResp): ResponseSessionEventResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResponseSessionEventResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ResponseSessionEventResp
 */
export const ResponseSessionEventResp = new ResponseSessionEventResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPendingSessionEventsReq$Type extends MessageType<ListPendingSessionEventsReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListPendingSessionEventsReq", []);
    }
    create(value?: PartialMessage<ListPendingSessionEventsReq>): ListPendingSessionEventsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListPendingSessionEventsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPendingSessionEventsReq): ListPendingSessionEventsReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListPendingSessionEventsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsReq
 */
export const ListPendingSessionEventsReq = new ListPendingSessionEventsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPendingSessionEventsResp$Type extends MessageType<ListPendingSessionEventsResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListPendingSessionEventsResp", [
            { no: 1, name: "jsonEventArguments", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListPendingSessionEventsResp>): ListPendingSessionEventsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.jsonEventArguments = [];
        if (value !== undefined)
            reflectionMergePartial<ListPendingSessionEventsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPendingSessionEventsResp): ListPendingSessionEventsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string jsonEventArguments */ 1:
                    message.jsonEventArguments.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPendingSessionEventsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string jsonEventArguments = 1; */
        for (let i = 0; i < message.jsonEventArguments.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.jsonEventArguments[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsResp
 */
export const ListPendingSessionEventsResp = new ListPendingSessionEventsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPendingSessionEventsByTopicReq$Type extends MessageType<ListPendingSessionEventsByTopicReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListPendingSessionEventsByTopicReq", [
            { no: 1, name: "topic", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListPendingSessionEventsByTopicReq>): ListPendingSessionEventsByTopicReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.topic = "";
        if (value !== undefined)
            reflectionMergePartial<ListPendingSessionEventsByTopicReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPendingSessionEventsByTopicReq): ListPendingSessionEventsByTopicReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string topic */ 1:
                    message.topic = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPendingSessionEventsByTopicReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string topic = 1; */
        if (message.topic !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.topic);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsByTopicReq
 */
export const ListPendingSessionEventsByTopicReq = new ListPendingSessionEventsByTopicReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPendingSessionEventsByTopicResp$Type extends MessageType<ListPendingSessionEventsByTopicResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListPendingSessionEventsByTopicResp", [
            { no: 1, name: "eventArguments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventArguments }
        ]);
    }
    create(value?: PartialMessage<ListPendingSessionEventsByTopicResp>): ListPendingSessionEventsByTopicResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventArguments = [];
        if (value !== undefined)
            reflectionMergePartial<ListPendingSessionEventsByTopicResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPendingSessionEventsByTopicResp): ListPendingSessionEventsByTopicResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated gaw.asset.wallet.v1.EventArguments eventArguments */ 1:
                    message.eventArguments.push(EventArguments.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPendingSessionEventsByTopicResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated gaw.asset.wallet.v1.EventArguments eventArguments = 1; */
        for (let i = 0; i < message.eventArguments.length; i++)
            EventArguments.internalBinaryWrite(message.eventArguments[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListPendingSessionEventsByTopicResp
 */
export const ListPendingSessionEventsByTopicResp = new ListPendingSessionEventsByTopicResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetSeqReq$Type extends MessageType<ResetSeqReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ResetSeqReq", [
            { no: 1, name: "appID", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ResetSeqReq>): ResetSeqReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.appID = 0;
        message.userID = 0;
        if (value !== undefined)
            reflectionMergePartial<ResetSeqReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetSeqReq): ResetSeqReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 appID */ 1:
                    message.appID = reader.int32();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetSeqReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 appID = 1; */
        if (message.appID !== 0)
            writer.tag(1, WireType.Varint).int32(message.appID);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ResetSeqReq
 */
export const ResetSeqReq = new ResetSeqReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetSeqResp$Type extends MessageType<ResetSeqResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ResetSeqResp", []);
    }
    create(value?: PartialMessage<ResetSeqResp>): ResetSeqResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ResetSeqResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetSeqResp): ResetSeqResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ResetSeqResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ResetSeqResp
 */
export const ResetSeqResp = new ResetSeqResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPayRouteReq$Type extends MessageType<GetPayRouteReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetPayRouteReq", []);
    }
    create(value?: PartialMessage<GetPayRouteReq>): GetPayRouteReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetPayRouteReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPayRouteReq): GetPayRouteReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPayRouteReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetPayRouteReq
 */
export const GetPayRouteReq = new GetPayRouteReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPayRouteResp$Type extends MessageType<GetPayRouteResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetPayRouteResp", [
            { no: 1, name: "channel", kind: "enum", T: () => ["gaw.asset.wallet.v1.PayChannel", PayChannel] }
        ]);
    }
    create(value?: PartialMessage<GetPayRouteResp>): GetPayRouteResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.channel = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPayRouteResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPayRouteResp): GetPayRouteResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.PayChannel channel */ 1:
                    message.channel = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPayRouteResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.PayChannel channel = 1; */
        if (message.channel !== 0)
            writer.tag(1, WireType.Varint).int32(message.channel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetPayRouteResp
 */
export const GetPayRouteResp = new GetPayRouteResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FactorAuth$Type extends MessageType<FactorAuth> {
    constructor() {
        super("gaw.asset.wallet.v1.FactorAuth", [
            { no: 1, name: "pwdAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "factorAuthToken", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FactorAuth>): FactorAuth {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.pwdAuthToken = "";
        message.factorAuthToken = "";
        if (value !== undefined)
            reflectionMergePartial<FactorAuth>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FactorAuth): FactorAuth {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string pwdAuthToken */ 1:
                    message.pwdAuthToken = reader.string();
                    break;
                case /* string factorAuthToken */ 2:
                    message.factorAuthToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FactorAuth, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string pwdAuthToken = 1; */
        if (message.pwdAuthToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.pwdAuthToken);
        /* string factorAuthToken = 2; */
        if (message.factorAuthToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.factorAuthToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.FactorAuth
 */
export const FactorAuth = new FactorAuth$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostTxSignatureReq$Type extends MessageType<PostTxSignatureReq> {
    constructor() {
        super("gaw.asset.wallet.v1.PostTxSignatureReq", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1", ignoreEmpty: true } } } },
            { no: 4, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "10", prefix: "0x", ignoreEmpty: true } } } },
            { no: 5, name: "nonce", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { uint64: { gte: "0", ignoreEmpty: true } } } },
            { no: 6, name: "chain_id", kind: "scalar", localName: "chain_id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { int64: { gt: "0" } } } },
            { no: 7, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "factorAuth", kind: "message", T: () => FactorAuth }
        ]);
    }
    create(value?: PartialMessage<PostTxSignatureReq>): PostTxSignatureReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.from = "";
        message.to = "";
        message.value = "";
        message.data = "";
        message.nonce = 0;
        message.chain_id = 0;
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<PostTxSignatureReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostTxSignatureReq): PostTxSignatureReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* string data */ 4:
                    message.data = reader.string();
                    break;
                case /* uint64 nonce */ 5:
                    message.nonce = reader.uint64().toNumber();
                    break;
                case /* int64 chain_id */ 6:
                    message.chain_id = reader.int64().toNumber();
                    break;
                case /* string token */ 7:
                    message.token = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.FactorAuth factorAuth */ 8:
                    message.factorAuth = FactorAuth.internalBinaryRead(reader, reader.uint32(), options, message.factorAuth);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostTxSignatureReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* string data = 4; */
        if (message.data !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.data);
        /* uint64 nonce = 5; */
        if (message.nonce !== 0)
            writer.tag(5, WireType.Varint).uint64(message.nonce);
        /* int64 chain_id = 6; */
        if (message.chain_id !== 0)
            writer.tag(6, WireType.Varint).int64(message.chain_id);
        /* string token = 7; */
        if (message.token !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.token);
        /* gaw.asset.wallet.v1.FactorAuth factorAuth = 8; */
        if (message.factorAuth)
            FactorAuth.internalBinaryWrite(message.factorAuth, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.PostTxSignatureReq
 */
export const PostTxSignatureReq = new PostTxSignatureReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostTxSignatureResp$Type extends MessageType<PostTxSignatureResp> {
    constructor() {
        super("gaw.asset.wallet.v1.PostTxSignatureResp", [
            { no: 1, name: "raw_tx", kind: "scalar", localName: "raw_tx", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostTxSignatureResp>): PostTxSignatureResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.raw_tx = "";
        message.signature = "";
        if (value !== undefined)
            reflectionMergePartial<PostTxSignatureResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostTxSignatureResp): PostTxSignatureResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string raw_tx */ 1:
                    message.raw_tx = reader.string();
                    break;
                case /* string signature */ 2:
                    message.signature = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostTxSignatureResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string raw_tx = 1; */
        if (message.raw_tx !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.raw_tx);
        /* string signature = 2; */
        if (message.signature !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.signature);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.PostTxSignatureResp
 */
export const PostTxSignatureResp = new PostTxSignatureResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Eip712Domain$Type extends MessageType<Eip712Domain> {
    constructor() {
        super("gaw.asset.wallet.v1.Eip712Domain", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chainId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { int64: { gt: "0" } } } },
            { no: 4, name: "verifyingContract", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } }
        ]);
    }
    create(value?: PartialMessage<Eip712Domain>): Eip712Domain {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.version = "";
        message.chainId = 0;
        message.verifyingContract = "";
        if (value !== undefined)
            reflectionMergePartial<Eip712Domain>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Eip712Domain): Eip712Domain {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* int64 chainId */ 3:
                    message.chainId = reader.int64().toNumber();
                    break;
                case /* string verifyingContract */ 4:
                    message.verifyingContract = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Eip712Domain, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* int64 chainId = 3; */
        if (message.chainId !== 0)
            writer.tag(3, WireType.Varint).int64(message.chainId);
        /* string verifyingContract = 4; */
        if (message.verifyingContract !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.verifyingContract);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Eip712Domain
 */
export const Eip712Domain = new Eip712Domain$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Eip2612Permit$Type extends MessageType<Eip2612Permit> {
    constructor() {
        super("gaw.asset.wallet.v1.Eip2612Permit", [
            { no: 1, name: "owner", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 2, name: "spender", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0|[1-9]\\d*)$" } } } },
            { no: 4, name: "nonce", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { int64: { gte: "0" } } } },
            { no: 5, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { int64: { gt: "0" } } } }
        ]);
    }
    create(value?: PartialMessage<Eip2612Permit>): Eip2612Permit {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.owner = "";
        message.spender = "";
        message.value = "";
        message.nonce = 0;
        message.deadline = 0;
        if (value !== undefined)
            reflectionMergePartial<Eip2612Permit>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Eip2612Permit): Eip2612Permit {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string owner */ 1:
                    message.owner = reader.string();
                    break;
                case /* string spender */ 2:
                    message.spender = reader.string();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* int64 nonce */ 4:
                    message.nonce = reader.int64().toNumber();
                    break;
                case /* int64 deadline */ 5:
                    message.deadline = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Eip2612Permit, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string owner = 1; */
        if (message.owner !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.owner);
        /* string spender = 2; */
        if (message.spender !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.spender);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* int64 nonce = 4; */
        if (message.nonce !== 0)
            writer.tag(4, WireType.Varint).int64(message.nonce);
        /* int64 deadline = 5; */
        if (message.deadline !== 0)
            writer.tag(5, WireType.Varint).int64(message.deadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.Eip2612Permit
 */
export const Eip2612Permit = new Eip2612Permit$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyPermitRep$Type extends MessageType<ApplyPermitRep> {
    constructor() {
        super("gaw.asset.wallet.v1.ApplyPermitRep", [
            { no: 1, name: "domain", kind: "message", T: () => Eip712Domain },
            { no: 2, name: "permit", kind: "message", T: () => Eip2612Permit },
            { no: 3, name: "applyAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } }
        ]);
    }
    create(value?: PartialMessage<ApplyPermitRep>): ApplyPermitRep {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.applyAddress = "";
        if (value !== undefined)
            reflectionMergePartial<ApplyPermitRep>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyPermitRep): ApplyPermitRep {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.Eip712Domain domain */ 1:
                    message.domain = Eip712Domain.internalBinaryRead(reader, reader.uint32(), options, message.domain);
                    break;
                case /* gaw.asset.wallet.v1.Eip2612Permit permit */ 2:
                    message.permit = Eip2612Permit.internalBinaryRead(reader, reader.uint32(), options, message.permit);
                    break;
                case /* string applyAddress */ 3:
                    message.applyAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyPermitRep, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.Eip712Domain domain = 1; */
        if (message.domain)
            Eip712Domain.internalBinaryWrite(message.domain, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.Eip2612Permit permit = 2; */
        if (message.permit)
            Eip2612Permit.internalBinaryWrite(message.permit, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string applyAddress = 3; */
        if (message.applyAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.applyAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ApplyPermitRep
 */
export const ApplyPermitRep = new ApplyPermitRep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyPermitResp$Type extends MessageType<ApplyPermitResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ApplyPermitResp", [
            { no: 1, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "r", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "s", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "v", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplyPermitResp>): ApplyPermitResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.signature = "";
        message.r = "";
        message.s = "";
        message.v = "";
        if (value !== undefined)
            reflectionMergePartial<ApplyPermitResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyPermitResp): ApplyPermitResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string signature */ 1:
                    message.signature = reader.string();
                    break;
                case /* string r */ 2:
                    message.r = reader.string();
                    break;
                case /* string s */ 3:
                    message.s = reader.string();
                    break;
                case /* string v */ 4:
                    message.v = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyPermitResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string signature = 1; */
        if (message.signature !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.signature);
        /* string r = 2; */
        if (message.r !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.r);
        /* string s = 3; */
        if (message.s !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.s);
        /* string v = 4; */
        if (message.v !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.v);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ApplyPermitResp
 */
export const ApplyPermitResp = new ApplyPermitResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSessionMessageReq$Type extends MessageType<GetSessionMessageReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetSessionMessageReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetSessionMessageReq>): GetSessionMessageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetSessionMessageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSessionMessageReq): GetSessionMessageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSessionMessageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetSessionMessageReq
 */
export const GetSessionMessageReq = new GetSessionMessageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSessionMessageResp$Type extends MessageType<GetSessionMessageResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetSessionMessageResp", [
            { no: 1, name: "eventArgs", kind: "message", T: () => EventArguments },
            { no: 2, name: "eventRes", kind: "message", T: () => SessionResponseBody }
        ]);
    }
    create(value?: PartialMessage<GetSessionMessageResp>): GetSessionMessageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetSessionMessageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSessionMessageResp): GetSessionMessageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.EventArguments eventArgs */ 1:
                    message.eventArgs = EventArguments.internalBinaryRead(reader, reader.uint32(), options, message.eventArgs);
                    break;
                case /* gaw.asset.wallet.v1.SessionResponseBody eventRes */ 2:
                    message.eventRes = SessionResponseBody.internalBinaryRead(reader, reader.uint32(), options, message.eventRes);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSessionMessageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.EventArguments eventArgs = 1; */
        if (message.eventArgs)
            EventArguments.internalBinaryWrite(message.eventArgs, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.SessionResponseBody eventRes = 2; */
        if (message.eventRes)
            SessionResponseBody.internalBinaryWrite(message.eventRes, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetSessionMessageResp
 */
export const GetSessionMessageResp = new GetSessionMessageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAddressDetailReq$Type extends MessageType<GetAddressDetailReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetAddressDetailReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAddressDetailReq>): GetAddressDetailReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<GetAddressDetailReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAddressDetailReq): GetAddressDetailReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAddressDetailReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetAddressDetailReq
 */
export const GetAddressDetailReq = new GetAddressDetailReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAddressDetailResp$Type extends MessageType<GetAddressDetailResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetAddressDetailResp", [
            { no: 1, name: "gawUserID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "mttUserID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "transTimes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetAddressDetailResp>): GetAddressDetailResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.gawUserID = 0;
        message.mttUserID = 0;
        message.address = "";
        message.transTimes = 0;
        if (value !== undefined)
            reflectionMergePartial<GetAddressDetailResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAddressDetailResp): GetAddressDetailResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 gawUserID */ 1:
                    message.gawUserID = reader.int64().toNumber();
                    break;
                case /* int64 mttUserID */ 2:
                    message.mttUserID = reader.int64().toNumber();
                    break;
                case /* string address */ 3:
                    message.address = reader.string();
                    break;
                case /* int32 transTimes */ 4:
                    message.transTimes = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAddressDetailResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 gawUserID = 1; */
        if (message.gawUserID !== 0)
            writer.tag(1, WireType.Varint).int64(message.gawUserID);
        /* int64 mttUserID = 2; */
        if (message.mttUserID !== 0)
            writer.tag(2, WireType.Varint).int64(message.mttUserID);
        /* string address = 3; */
        if (message.address !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.address);
        /* int32 transTimes = 4; */
        if (message.transTimes !== 0)
            writer.tag(4, WireType.Varint).int32(message.transTimes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetAddressDetailResp
 */
export const GetAddressDetailResp = new GetAddressDetailResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceByUidReq$Type extends MessageType<GetBalanceByUidReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetBalanceByUidReq", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "userID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBalanceByUidReq>): GetBalanceByUidReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.userID = 0;
        message.contractAddress = "";
        if (value !== undefined)
            reflectionMergePartial<GetBalanceByUidReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceByUidReq): GetBalanceByUidReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* int64 userID */ 2:
                    message.userID = reader.int64().toNumber();
                    break;
                case /* string contractAddress */ 3:
                    message.contractAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceByUidReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* int64 userID = 2; */
        if (message.userID !== 0)
            writer.tag(2, WireType.Varint).int64(message.userID);
        /* string contractAddress = 3; */
        if (message.contractAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contractAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetBalanceByUidReq
 */
export const GetBalanceByUidReq = new GetBalanceByUidReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceByUidResp$Type extends MessageType<GetBalanceByUidResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetBalanceByUidResp", [
            { no: 1, name: "Result", kind: "message", localName: "Result", jsonName: "Result", T: () => GetBalanceByUidResp_Item }
        ]);
    }
    create(value?: PartialMessage<GetBalanceByUidResp>): GetBalanceByUidResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetBalanceByUidResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceByUidResp): GetBalanceByUidResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.GetBalanceByUidResp.Item Result = 1 [json_name = "Result"];*/ 1:
                    message.Result = GetBalanceByUidResp_Item.internalBinaryRead(reader, reader.uint32(), options, message.Result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceByUidResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.GetBalanceByUidResp.Item Result = 1 [json_name = "Result"]; */
        if (message.Result)
            GetBalanceByUidResp_Item.internalBinaryWrite(message.Result, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetBalanceByUidResp
 */
export const GetBalanceByUidResp = new GetBalanceByUidResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceByUidResp_Item$Type extends MessageType<GetBalanceByUidResp_Item> {
    constructor() {
        super("gaw.asset.wallet.v1.GetBalanceByUidResp.Item", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "balance", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBalanceByUidResp_Item>): GetBalanceByUidResp_Item {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.address = "";
        message.contractAddress = "";
        message.balance = "";
        if (value !== undefined)
            reflectionMergePartial<GetBalanceByUidResp_Item>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceByUidResp_Item): GetBalanceByUidResp_Item {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                case /* string contractAddress */ 3:
                    message.contractAddress = reader.string();
                    break;
                case /* string balance */ 4:
                    message.balance = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceByUidResp_Item, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* string contractAddress = 3; */
        if (message.contractAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.contractAddress);
        /* string balance = 4; */
        if (message.balance !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.balance);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetBalanceByUidResp.Item
 */
export const GetBalanceByUidResp_Item = new GetBalanceByUidResp_Item$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTssWalletReq$Type extends MessageType<GetTssWalletReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetTssWalletReq", []);
    }
    create(value?: PartialMessage<GetTssWalletReq>): GetTssWalletReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetTssWalletReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTssWalletReq): GetTssWalletReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetTssWalletReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetTssWalletReq
 */
export const GetTssWalletReq = new GetTssWalletReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTssWalletResp$Type extends MessageType<GetTssWalletResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetTssWalletResp", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTssWalletResp>): GetTssWalletResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<GetTssWalletResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTssWalletResp): GetTssWalletResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTssWalletResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetTssWalletResp
 */
export const GetTssWalletResp = new GetTssWalletResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostTssSignatureReq$Type extends MessageType<PostTssSignatureReq> {
    constructor() {
        super("gaw.asset.wallet.v1.PostTssSignatureReq", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1", ignoreEmpty: true } } } },
            { no: 4, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "10", prefix: "0x", ignoreEmpty: true } } } },
            { no: 5, name: "chain_id", kind: "scalar", localName: "chain_id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { int64: { gt: "0" } } } }
        ]);
    }
    create(value?: PartialMessage<PostTssSignatureReq>): PostTssSignatureReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.from = "";
        message.to = "";
        message.value = "";
        message.data = "";
        message.chain_id = 0;
        if (value !== undefined)
            reflectionMergePartial<PostTssSignatureReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostTssSignatureReq): PostTssSignatureReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* string data */ 4:
                    message.data = reader.string();
                    break;
                case /* int64 chain_id */ 5:
                    message.chain_id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostTssSignatureReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* string data = 4; */
        if (message.data !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.data);
        /* int64 chain_id = 5; */
        if (message.chain_id !== 0)
            writer.tag(5, WireType.Varint).int64(message.chain_id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.PostTssSignatureReq
 */
export const PostTssSignatureReq = new PostTssSignatureReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostTssSignatureResp$Type extends MessageType<PostTssSignatureResp> {
    constructor() {
        super("gaw.asset.wallet.v1.PostTssSignatureResp", [
            { no: 1, name: "rawTx", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostTssSignatureResp>): PostTssSignatureResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rawTx = "";
        message.signature = "";
        if (value !== undefined)
            reflectionMergePartial<PostTssSignatureResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostTssSignatureResp): PostTssSignatureResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string rawTx */ 1:
                    message.rawTx = reader.string();
                    break;
                case /* string signature */ 2:
                    message.signature = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostTssSignatureResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string rawTx = 1; */
        if (message.rawTx !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.rawTx);
        /* string signature = 2; */
        if (message.signature !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.signature);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.PostTssSignatureResp
 */
export const PostTssSignatureResp = new PostTssSignatureResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnWhiteListReq$Type extends MessageType<OnWhiteListReq> {
    constructor() {
        super("gaw.asset.wallet.v1.OnWhiteListReq", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OnWhiteListReq>): OnWhiteListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<OnWhiteListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnWhiteListReq): OnWhiteListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnWhiteListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.OnWhiteListReq
 */
export const OnWhiteListReq = new OnWhiteListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnWhiteListResp$Type extends MessageType<OnWhiteListResp> {
    constructor() {
        super("gaw.asset.wallet.v1.OnWhiteListResp", []);
    }
    create(value?: PartialMessage<OnWhiteListResp>): OnWhiteListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<OnWhiteListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnWhiteListResp): OnWhiteListResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OnWhiteListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.OnWhiteListResp
 */
export const OnWhiteListResp = new OnWhiteListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OffWhiteListReq$Type extends MessageType<OffWhiteListReq> {
    constructor() {
        super("gaw.asset.wallet.v1.OffWhiteListReq", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OffWhiteListReq>): OffWhiteListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<OffWhiteListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OffWhiteListReq): OffWhiteListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OffWhiteListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.OffWhiteListReq
 */
export const OffWhiteListReq = new OffWhiteListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OffWhiteListResp$Type extends MessageType<OffWhiteListResp> {
    constructor() {
        super("gaw.asset.wallet.v1.OffWhiteListResp", []);
    }
    create(value?: PartialMessage<OffWhiteListResp>): OffWhiteListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<OffWhiteListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OffWhiteListResp): OffWhiteListResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OffWhiteListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.OffWhiteListResp
 */
export const OffWhiteListResp = new OffWhiteListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTransferModeReq$Type extends MessageType<SetTransferModeReq> {
    constructor() {
        super("gaw.asset.wallet.v1.SetTransferModeReq", [
            { no: 1, name: "mode", kind: "enum", T: () => ["gaw.asset.wallet.v1.TransferMode", TransferMode], options: { "validate.rules": { enum: { in: [1, 2, 3] } } } },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetTransferModeReq>): SetTransferModeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mode = 0;
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<SetTransferModeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetTransferModeReq): SetTransferModeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.asset.wallet.v1.TransferMode mode */ 1:
                    message.mode = reader.int32();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetTransferModeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.asset.wallet.v1.TransferMode mode = 1; */
        if (message.mode !== 0)
            writer.tag(1, WireType.Varint).int32(message.mode);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SetTransferModeReq
 */
export const SetTransferModeReq = new SetTransferModeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTransferModeResp$Type extends MessageType<SetTransferModeResp> {
    constructor() {
        super("gaw.asset.wallet.v1.SetTransferModeResp", []);
    }
    create(value?: PartialMessage<SetTransferModeResp>): SetTransferModeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetTransferModeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetTransferModeResp): SetTransferModeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetTransferModeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.SetTransferModeResp
 */
export const SetTransferModeResp = new SetTransferModeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWhiteListReq$Type extends MessageType<CreateWhiteListReq> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateWhiteListReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 2, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] }
        ]);
    }
    create(value?: PartialMessage<CreateWhiteListReq>): CreateWhiteListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        message.notes = "";
        message.token = "";
        message.chainID = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateWhiteListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWhiteListReq): CreateWhiteListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* string notes */ 2:
                    message.notes = reader.string();
                    break;
                case /* string token */ 3:
                    message.token = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 4:
                    message.chainID = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateWhiteListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* string notes = 2; */
        if (message.notes !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.notes);
        /* string token = 3; */
        if (message.token !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.token);
        /* gaw.base.ChainID chainID = 4; */
        if (message.chainID !== 0)
            writer.tag(4, WireType.Varint).int32(message.chainID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateWhiteListReq
 */
export const CreateWhiteListReq = new CreateWhiteListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateWhiteListResp$Type extends MessageType<CreateWhiteListResp> {
    constructor() {
        super("gaw.asset.wallet.v1.CreateWhiteListResp", []);
    }
    create(value?: PartialMessage<CreateWhiteListResp>): CreateWhiteListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateWhiteListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateWhiteListResp): CreateWhiteListResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateWhiteListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.CreateWhiteListResp
 */
export const CreateWhiteListResp = new CreateWhiteListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWhiteListReq$Type extends MessageType<ListWhiteListReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListWhiteListReq", []);
    }
    create(value?: PartialMessage<ListWhiteListReq>): ListWhiteListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListWhiteListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListWhiteListReq): ListWhiteListReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListWhiteListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListWhiteListReq
 */
export const ListWhiteListReq = new ListWhiteListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListWhiteListResp$Type extends MessageType<ListWhiteListResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListWhiteListResp", [
            { no: 1, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "white_list", kind: "message", localName: "white_list", repeat: 1 /*RepeatType.PACKED*/, T: () => WhiteList }
        ]);
    }
    create(value?: PartialMessage<ListWhiteListResp>): ListWhiteListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        message.white_list = [];
        if (value !== undefined)
            reflectionMergePartial<ListWhiteListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListWhiteListResp): ListWhiteListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 count */ 1:
                    message.count = reader.int64().toNumber();
                    break;
                case /* repeated gaw.asset.wallet.v1.WhiteList white_list */ 2:
                    message.white_list.push(WhiteList.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListWhiteListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int64(message.count);
        /* repeated gaw.asset.wallet.v1.WhiteList white_list = 2; */
        for (let i = 0; i < message.white_list.length; i++)
            WhiteList.internalBinaryWrite(message.white_list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListWhiteListResp
 */
export const ListWhiteListResp = new ListWhiteListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTransTimesReq$Type extends MessageType<ListTransTimesReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListTransTimesReq", [
            { no: 1, name: "address", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListTransTimesReq>): ListTransTimesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = [];
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<ListTransTimesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTransTimesReq): ListTransTimesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string address */ 1:
                    message.address.push(reader.string());
                    break;
                case /* int64 userId */ 2:
                    message.userId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTransTimesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string address = 1; */
        for (let i = 0; i < message.address.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.address[i]);
        /* int64 userId = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int64(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListTransTimesReq
 */
export const ListTransTimesReq = new ListTransTimesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTransTimesResp$Type extends MessageType<ListTransTimesResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListTransTimesResp", [
            { no: 1, name: "transTimes", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<ListTransTimesResp>): ListTransTimesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.transTimes = {};
        if (value !== undefined)
            reflectionMergePartial<ListTransTimesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTransTimesResp): ListTransTimesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, int32> transTimes */ 1:
                    this.binaryReadMap1(message.transTimes, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ListTransTimesResp["transTimes"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListTransTimesResp["transTimes"] | undefined, val: ListTransTimesResp["transTimes"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field gaw.asset.wallet.v1.ListTransTimesResp.transTimes");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: ListTransTimesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, int32> transTimes = 1; */
        for (let k of globalThis.Object.keys(message.transTimes))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.transTimes[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListTransTimesResp
 */
export const ListTransTimesResp = new ListTransTimesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTransTimesAddressReq$Type extends MessageType<ListTransTimesAddressReq> {
    constructor() {
        super("gaw.asset.wallet.v1.ListTransTimesAddressReq", [
            { no: 1, name: "address", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListTransTimesAddressReq>): ListTransTimesAddressReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = [];
        if (value !== undefined)
            reflectionMergePartial<ListTransTimesAddressReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTransTimesAddressReq): ListTransTimesAddressReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string address */ 1:
                    message.address.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTransTimesAddressReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string address = 1; */
        for (let i = 0; i < message.address.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.address[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListTransTimesAddressReq
 */
export const ListTransTimesAddressReq = new ListTransTimesAddressReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTransTimesAddressResp$Type extends MessageType<ListTransTimesAddressResp> {
    constructor() {
        super("gaw.asset.wallet.v1.ListTransTimesAddressResp", [
            { no: 1, name: "transTimes", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<ListTransTimesAddressResp>): ListTransTimesAddressResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.transTimes = {};
        if (value !== undefined)
            reflectionMergePartial<ListTransTimesAddressResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTransTimesAddressResp): ListTransTimesAddressResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, int32> transTimes */ 1:
                    this.binaryReadMap1(message.transTimes, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ListTransTimesAddressResp["transTimes"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListTransTimesAddressResp["transTimes"] | undefined, val: ListTransTimesAddressResp["transTimes"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field gaw.asset.wallet.v1.ListTransTimesAddressResp.transTimes");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: ListTransTimesAddressResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, int32> transTimes = 1; */
        for (let k of globalThis.Object.keys(message.transTimes))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.transTimes[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.ListTransTimesAddressResp
 */
export const ListTransTimesAddressResp = new ListTransTimesAddressResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransTimesList$Type extends MessageType<TransTimesList> {
    constructor() {
        super("gaw.asset.wallet.v1.TransTimesList", [
            { no: 1, name: "userId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "transTimes", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "isWhite", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "relate", kind: "enum", T: () => ["bpt.social.relation.v1.RelateType", RelateType] },
            { no: 5, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TransTimesList>): TransTimesList {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userId = 0;
        message.transTimes = 0;
        message.isWhite = false;
        message.relate = 0;
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<TransTimesList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransTimesList): TransTimesList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 userId */ 1:
                    message.userId = reader.int64().toNumber();
                    break;
                case /* int32 transTimes */ 2:
                    message.transTimes = reader.int32();
                    break;
                case /* bool isWhite */ 3:
                    message.isWhite = reader.bool();
                    break;
                case /* bpt.social.relation.v1.RelateType relate */ 4:
                    message.relate = reader.int32();
                    break;
                case /* string address */ 5:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransTimesList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 userId = 1; */
        if (message.userId !== 0)
            writer.tag(1, WireType.Varint).int64(message.userId);
        /* int32 transTimes = 2; */
        if (message.transTimes !== 0)
            writer.tag(2, WireType.Varint).int32(message.transTimes);
        /* bool isWhite = 3; */
        if (message.isWhite !== false)
            writer.tag(3, WireType.Varint).bool(message.isWhite);
        /* bpt.social.relation.v1.RelateType relate = 4; */
        if (message.relate !== 0)
            writer.tag(4, WireType.Varint).int32(message.relate);
        /* string address = 5; */
        if (message.address !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.TransTimesList
 */
export const TransTimesList = new TransTimesList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentlyTransReq$Type extends MessageType<GetRecentlyTransReq> {
    constructor() {
        super("gaw.asset.wallet.v1.GetRecentlyTransReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetRecentlyTransReq>): GetRecentlyTransReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRecentlyTransReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecentlyTransReq): GetRecentlyTransReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecentlyTransReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetRecentlyTransReq
 */
export const GetRecentlyTransReq = new GetRecentlyTransReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecentlyTransResp$Type extends MessageType<GetRecentlyTransResp> {
    constructor() {
        super("gaw.asset.wallet.v1.GetRecentlyTransResp", [
            { no: 1, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "transTimes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransTimes }
        ]);
    }
    create(value?: PartialMessage<GetRecentlyTransResp>): GetRecentlyTransResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        message.transTimes = [];
        if (value !== undefined)
            reflectionMergePartial<GetRecentlyTransResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecentlyTransResp): GetRecentlyTransResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 count */ 1:
                    message.count = reader.int64().toNumber();
                    break;
                case /* repeated gaw.asset.wallet.v1.TransTimes transTimes */ 2:
                    message.transTimes.push(TransTimes.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecentlyTransResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int64(message.count);
        /* repeated gaw.asset.wallet.v1.TransTimes transTimes = 2; */
        for (let i = 0; i < message.transTimes.length; i++)
            TransTimes.internalBinaryWrite(message.transTimes[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.GetRecentlyTransResp
 */
export const GetRecentlyTransResp = new GetRecentlyTransResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransTimes$Type extends MessageType<TransTimes> {
    constructor() {
        super("gaw.asset.wallet.v1.TransTimes", [
            { no: 1, name: "to_user_id", kind: "scalar", localName: "to_user_id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "trans_times", kind: "scalar", localName: "trans_times", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] }
        ]);
    }
    create(value?: PartialMessage<TransTimes>): TransTimes {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.to_user_id = 0;
        message.address = "";
        message.trans_times = 0;
        message.chainID = 0;
        if (value !== undefined)
            reflectionMergePartial<TransTimes>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransTimes): TransTimes {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 to_user_id */ 1:
                    message.to_user_id = reader.int64().toNumber();
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                case /* int32 trans_times */ 3:
                    message.trans_times = reader.int32();
                    break;
                case /* gaw.base.ChainID chainID */ 4:
                    message.chainID = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransTimes, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 to_user_id = 1; */
        if (message.to_user_id !== 0)
            writer.tag(1, WireType.Varint).int64(message.to_user_id);
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        /* int32 trans_times = 3; */
        if (message.trans_times !== 0)
            writer.tag(3, WireType.Varint).int32(message.trans_times);
        /* gaw.base.ChainID chainID = 4; */
        if (message.chainID !== 0)
            writer.tag(4, WireType.Varint).int32(message.chainID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.TransTimes
 */
export const TransTimes = new TransTimes$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncrTransTimesReq$Type extends MessageType<IncrTransTimesReq> {
    constructor() {
        super("gaw.asset.wallet.v1.IncrTransTimesReq", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IncrTransTimesReq>): IncrTransTimesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<IncrTransTimesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncrTransTimesReq): IncrTransTimesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncrTransTimesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.IncrTransTimesReq
 */
export const IncrTransTimesReq = new IncrTransTimesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncrTransTimesResp$Type extends MessageType<IncrTransTimesResp> {
    constructor() {
        super("gaw.asset.wallet.v1.IncrTransTimesResp", []);
    }
    create(value?: PartialMessage<IncrTransTimesResp>): IncrTransTimesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<IncrTransTimesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncrTransTimesResp): IncrTransTimesResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: IncrTransTimesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.IncrTransTimesResp
 */
export const IncrTransTimesResp = new IncrTransTimesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WhiteList$Type extends MessageType<WhiteList> {
    constructor() {
        super("gaw.asset.wallet.v1.WhiteList", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "role", kind: "enum", T: () => ["gaw.asset.wallet.v1.Role", Role] },
            { no: 3, name: "notes", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "to_user_id", kind: "scalar", localName: "to_user_id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] }
        ]);
    }
    create(value?: PartialMessage<WhiteList>): WhiteList {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        message.role = 0;
        message.notes = "";
        message.to_user_id = 0;
        message.chainID = 0;
        if (value !== undefined)
            reflectionMergePartial<WhiteList>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WhiteList): WhiteList {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.Role role */ 2:
                    message.role = reader.int32();
                    break;
                case /* string notes */ 3:
                    message.notes = reader.string();
                    break;
                case /* int64 to_user_id */ 4:
                    message.to_user_id = reader.int64().toNumber();
                    break;
                case /* gaw.base.ChainID chainID */ 5:
                    message.chainID = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WhiteList, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* gaw.asset.wallet.v1.Role role = 2; */
        if (message.role !== 0)
            writer.tag(2, WireType.Varint).int32(message.role);
        /* string notes = 3; */
        if (message.notes !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.notes);
        /* int64 to_user_id = 4; */
        if (message.to_user_id !== 0)
            writer.tag(4, WireType.Varint).int64(message.to_user_id);
        /* gaw.base.ChainID chainID = 5; */
        if (message.chainID !== 0)
            writer.tag(5, WireType.Varint).int32(message.chainID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.WhiteList
 */
export const WhiteList = new WhiteList$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveWhiteListReq$Type extends MessageType<RemoveWhiteListReq> {
    constructor() {
        super("gaw.asset.wallet.v1.RemoveWhiteListReq", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 2, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveWhiteListReq>): RemoveWhiteListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<RemoveWhiteListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveWhiteListReq): RemoveWhiteListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* string token */ 2:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveWhiteListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* string token = 2; */
        if (message.token !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.RemoveWhiteListReq
 */
export const RemoveWhiteListReq = new RemoveWhiteListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveWhiteListResp$Type extends MessageType<RemoveWhiteListResp> {
    constructor() {
        super("gaw.asset.wallet.v1.RemoveWhiteListResp", []);
    }
    create(value?: PartialMessage<RemoveWhiteListResp>): RemoveWhiteListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RemoveWhiteListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveWhiteListResp): RemoveWhiteListResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RemoveWhiteListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.RemoveWhiteListResp
 */
export const RemoveWhiteListResp = new RemoveWhiteListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferTxSignatureReq$Type extends MessageType<TransferTxSignatureReq> {
    constructor() {
        super("gaw.asset.wallet.v1.TransferTxSignatureReq", [
            { no: 1, name: "from", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 2, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { pattern: "^(0x)[0-9a-fA-F]{40}$" } } } },
            { no: 3, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "1", ignoreEmpty: true } } } },
            { no: 4, name: "data", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "validate.rules": { string: { minLen: "10", prefix: "0x", ignoreEmpty: true } } } },
            { no: 5, name: "nonce", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { uint64: { gte: "0", ignoreEmpty: true } } } },
            { no: 6, name: "chain_id", kind: "scalar", localName: "chain_id", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/, options: { "validate.rules": { int64: { gt: "0" } } } },
            { no: 7, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "factorAuth", kind: "message", T: () => FactorAuth }
        ]);
    }
    create(value?: PartialMessage<TransferTxSignatureReq>): TransferTxSignatureReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.from = "";
        message.to = "";
        message.value = "";
        message.data = "";
        message.nonce = 0;
        message.chain_id = 0;
        message.token = "";
        if (value !== undefined)
            reflectionMergePartial<TransferTxSignatureReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransferTxSignatureReq): TransferTxSignatureReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string from */ 1:
                    message.from = reader.string();
                    break;
                case /* string to */ 2:
                    message.to = reader.string();
                    break;
                case /* string value */ 3:
                    message.value = reader.string();
                    break;
                case /* string data */ 4:
                    message.data = reader.string();
                    break;
                case /* uint64 nonce */ 5:
                    message.nonce = reader.uint64().toNumber();
                    break;
                case /* int64 chain_id */ 6:
                    message.chain_id = reader.int64().toNumber();
                    break;
                case /* string token */ 7:
                    message.token = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.FactorAuth factorAuth */ 8:
                    message.factorAuth = FactorAuth.internalBinaryRead(reader, reader.uint32(), options, message.factorAuth);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransferTxSignatureReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string from = 1; */
        if (message.from !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.from);
        /* string to = 2; */
        if (message.to !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.to);
        /* string value = 3; */
        if (message.value !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.value);
        /* string data = 4; */
        if (message.data !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.data);
        /* uint64 nonce = 5; */
        if (message.nonce !== 0)
            writer.tag(5, WireType.Varint).uint64(message.nonce);
        /* int64 chain_id = 6; */
        if (message.chain_id !== 0)
            writer.tag(6, WireType.Varint).int64(message.chain_id);
        /* string token = 7; */
        if (message.token !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.token);
        /* gaw.asset.wallet.v1.FactorAuth factorAuth = 8; */
        if (message.factorAuth)
            FactorAuth.internalBinaryWrite(message.factorAuth, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.TransferTxSignatureReq
 */
export const TransferTxSignatureReq = new TransferTxSignatureReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransferTxSignatureResp$Type extends MessageType<TransferTxSignatureResp> {
    constructor() {
        super("gaw.asset.wallet.v1.TransferTxSignatureResp", [
            { no: 1, name: "raw_tx", kind: "scalar", localName: "raw_tx", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "signature", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TransferTxSignatureResp>): TransferTxSignatureResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.raw_tx = "";
        message.signature = "";
        if (value !== undefined)
            reflectionMergePartial<TransferTxSignatureResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransferTxSignatureResp): TransferTxSignatureResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string raw_tx */ 1:
                    message.raw_tx = reader.string();
                    break;
                case /* string signature */ 2:
                    message.signature = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransferTxSignatureResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string raw_tx = 1; */
        if (message.raw_tx !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.raw_tx);
        /* string signature = 2; */
        if (message.signature !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.signature);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message gaw.asset.wallet.v1.TransferTxSignatureResp
 */
export const TransferTxSignatureResp = new TransferTxSignatureResp$Type();
/**
 * @generated ServiceType for protobuf service gaw.asset.wallet.v1.WalletService
 */
export const WalletService = new ServiceType("gaw.asset.wallet.v1.WalletService", [
    { name: "CheckWalletAddresses", options: {}, I: CheckWalletAddressesReq, O: CheckWalletAddressesResp },
    { name: "UploadWalletAddresses", options: {}, I: UploadWalletAddressesReq, O: UploadWalletAddressesResp },
    { name: "ClearWalletAddress", options: {}, I: ClearWalletAddressReq, O: ClearWalletAddressResp },
    { name: "GetWalletAddressesByUserID", options: {}, I: GetWalletAddressesByUserIDReq, O: GetWalletAddressesByUserIDResp },
    { name: "GetUserWalletAddresses", options: {}, I: GetUserWalletAddressesReq, O: GetUserWalletAddressesResp },
    { name: "ListERC20", options: {}, I: ListERC20Req, O: ListERC20Resp },
    { name: "GetTokenInfo", options: {}, I: GetTokenInfoReq, O: GetTokenInfoResp },
    { name: "SaveTokenInfo", options: { "blocker.exportclient": 0 }, I: SaveTokenInfoReq, O: SaveTokenInfoResp },
    { name: "ListTokenInfo", options: {}, I: ListTokenInfoReq, O: ListTokenInfoResp },
    { name: "CreateIntent", options: {}, I: CreateIntentReq, O: CreateIntentResp },
    { name: "GetIntent", options: {}, I: GetIntentReq, O: GetIntentResp },
    { name: "InvalidateIntent", options: {}, I: GetIntentReq, O: GetIntentResp },
    { name: "GetIntentByLink", options: {}, I: GetIntentByLinkReq, O: GetIntentByLinkResp },
    { name: "CreateWallet", options: {}, I: CreateWalletReq, O: GetWalletResp },
    { name: "InitCustodialWallet", options: { "blocker.exportclient": 0 }, I: InitCustodialWalletReq, O: GetWalletResp },
    { name: "PullAddressSecret", options: {}, I: PullAddressSecretReq, O: PullAddressSecretResp },
    { name: "UpdateWallet", options: {}, I: UpdateWalletReq, O: UpdateWalletResp },
    { name: "GetWallet", options: {}, I: GetWalletReq, O: GetWalletResp },
    { name: "GetActiveWallet", options: {}, I: GetActiveWalletReq, O: GetWalletResp },
    { name: "GetCustodialWallet", options: {}, I: GetCustodialWalletReq, O: GetWalletResp },
    { name: "DeleteWallet", options: {}, I: DeleteWalletReq, O: DeleteWalletResp },
    { name: "ListWallet", options: {}, I: ListWalletReq, O: ListWalletResp },
    { name: "GetDefaultPaymentWallet", options: {}, I: GetDefaultPaymentWalletReq, O: GetWalletResp },
    { name: "CreateChainToken", options: {}, I: CreateChainTokenReq, O: CreateChainTokenResp },
    { name: "UpdateChainToken", options: {}, I: UpdateChainTokenReq, O: UpdateChainTokenResp },
    { name: "CreateRedPacket", options: {}, I: CreateRedPacketReq, O: CreateRedPacketResp },
    { name: "GetRedPacketInfo", options: {}, I: GetRedPacketInfoReq, O: GetRedPacketInfoResp },
    { name: "GetClaimRedPacketSignature", options: {}, I: GetClaimRedPacketSignatureReq, O: GetClaimRedPacketSignatureResp },
    { name: "ListRedPacketClaimRecords", options: {}, I: ListRedPacketClaimRecordsReq, O: ListRedPacketClaimRecordsResp },
    { name: "SendSessionEvent", options: {}, I: SendSessionEventReq, O: SendSessionEventResp },
    { name: "ResponseSessionEvent", options: {}, I: ResponseSessionEventReq, O: ResponseSessionEventResp },
    { name: "ListPendingSessionEvents", options: {}, I: ListPendingSessionEventsReq, O: ListPendingSessionEventsResp },
    { name: "ListPendingSessionEventsByTopic", options: {}, I: ListPendingSessionEventsByTopicReq, O: ListPendingSessionEventsByTopicResp },
    { name: "ResetSeq", options: {}, I: ResetSeqReq, O: ResetSeqResp },
    { name: "GetPayRoute", options: {}, I: GetPayRouteReq, O: GetPayRouteResp },
    { name: "PostTxSignature", options: {}, I: PostTxSignatureReq, O: PostTxSignatureResp },
    { name: "ApplyPermit", options: {}, I: ApplyPermitRep, O: ApplyPermitResp },
    { name: "GetSessionMessage", options: {}, I: GetSessionMessageReq, O: GetSessionMessageResp },
    { name: "OnWhitelist", options: {}, I: OnWhiteListReq, O: OnWhiteListResp },
    { name: "OffWhitelist", options: {}, I: OffWhiteListReq, O: OffWhiteListResp },
    { name: "SetTransferMode", options: {}, I: SetTransferModeReq, O: SetTransferModeResp },
    { name: "CreateWhiteList", options: {}, I: CreateWhiteListReq, O: CreateWhiteListResp },
    { name: "ListWhiteList", options: {}, I: ListWhiteListReq, O: ListWhiteListResp },
    { name: "RemoveWhiteList", options: {}, I: RemoveWhiteListReq, O: RemoveWhiteListResp },
    { name: "IncrTransTimes", options: {}, I: IncrTransTimesReq, O: IncrTransTimesResp },
    { name: "ListTransTimes", options: {}, I: ListTransTimesReq, O: ListTransTimesResp },
    { name: "ListTransTimesAddress", options: {}, I: ListTransTimesAddressReq, O: ListTransTimesAddressResp },
    { name: "GetRecentlyTrans", options: {}, I: GetRecentlyTransReq, O: GetRecentlyTransResp },
    { name: "TransferTxSignature", options: {}, I: TransferTxSignatureReq, O: TransferTxSignatureResp },
    { name: "GetAddressDetail", options: {}, I: GetAddressDetailReq, O: GetAddressDetailResp },
    { name: "GetBalanceByUid", options: { "blocker.exportclient": 0 }, I: GetBalanceByUidReq, O: GetBalanceByUidResp },
    { name: "GetUserAddress", options: { "blocker.exportclient": 0 }, I: GetUserAddressReq, O: GetUserAddressResp },
    { name: "BatchGetUserAddress", options: { "blocker.exportclient": 0 }, I: BatchGetUserAddressReq, O: BatchGetUserAddressResp },
    { name: "GetTransTimes", options: { "blocker.exportclient": 0 }, I: ListTransTimesReq, O: ListTransTimesResp },
    { name: "GetTssWallet", options: { "blocker.exportclient": 0 }, I: GetTssWalletReq, O: GetTssWalletResp },
    { name: "PostTssSignature", options: { "blocker.exportclient": 0 }, I: PostTssSignatureReq, O: PostTssSignatureResp }
]);
