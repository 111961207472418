// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/base/base.proto" (package "bpt.base", syntax proto3)
// tslint:disable
// @ts-nocheck
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.base.Void
 */
export interface Void {
}
/**
 * ! Label数据内容
 *
 * @generated from protobuf message bpt.base.Label
 */
export interface Label {
    /**
     * @generated from protobuf field: string Key = 1 [json_name = "Key"];
     */
    Key: string; // ! key
    /**
     * @generated from protobuf field: string Value = 2 [json_name = "Value"];
     */
    Value: string; // ! value
}
/**
 * 公共异常协议
 *
 * @generated from protobuf message bpt.base.ExceptionResp
 */
export interface ExceptionResp {
    /**
     * @generated from protobuf field: int32 code = 1;
     */
    code: number; // 业务定义异常状态码
}
/**
 * 字段掩码
 *
 * @generated from protobuf message bpt.base.FieldMask
 */
export interface FieldMask {
    /**
     * @generated from protobuf field: repeated string paths = 1;
     */
    paths: string[];
}
/**
 * 依赖资源描述
 *
 * @generated from protobuf message bpt.base.SyncDependence
 */
export interface SyncDependence {
    /**
     * 目标服务名称
     *
     * @generated from protobuf field: string serviceName = 1;
     */
    serviceName: string;
    /**
     * 目标资源的update方法
     *
     * @generated from protobuf field: string updateMethodName = 2;
     */
    updateMethodName: string;
    /**
     * int32           updateId    = 2 ;
     * int32           getId       = 3 ;
     * 目标资源的get方法
     *
     * @generated from protobuf field: string getMethodName = 3;
     */
    getMethodName: string;
    /**
     * 目标资源的数字ID信息
     *
     * @generated from protobuf field: repeated int64 int64Ids = 4;
     */
    int64Ids: number[];
    /**
     * 目标资源的字符串ID信息
     *
     * @generated from protobuf field: repeated string stringIds = 5;
     */
    stringIds: string[];
}
/**
 * @generated from protobuf message bpt.base.BatchGetResourceReq
 */
export interface BatchGetResourceReq {
    /**
     * 资源数字ID
     *
     * @generated from protobuf field: repeated int64 int64Ids = 1;
     */
    int64Ids: number[];
    /**
     * 资源字符串ID
     *
     * @generated from protobuf field: repeated string stringIds = 2;
     */
    stringIds: string[];
    /**
     * 本次查询操作人
     *
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.base.BatchGetResourceResp
 */
export interface BatchGetResourceResp {
    /**
     * 返回的资源对象
     *
     * @generated from protobuf field: repeated bpt.base.Resource resources = 1;
     */
    resources: Resource[];
}
/**
 * @generated from protobuf message bpt.base.Resource
 */
export interface Resource {
    /**
     * 资源数字ID
     *
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * 资源upsert 请求使用的bytes数据
     *
     * @generated from protobuf field: bytes updateData = 2;
     */
    updateData: Uint8Array;
    /**
     * 资源校验和
     *
     * @generated from protobuf field: string checksum = 3;
     */
    checksum: string;
    /**
     * 资源依赖其它资源的归属情况
     *
     * @generated from protobuf field: repeated bpt.base.SyncDependence dependencies = 4;
     */
    dependencies: SyncDependence[];
    /**
     * 资源描述
     *
     * @generated from protobuf field: string desc = 5;
     */
    desc: string;
    /**
     * 资源字符ID
     *
     * @generated from protobuf field: string stringID = 6;
     */
    stringID: string;
}
/**
 * @generated from protobuf message bpt.base.ResourceDesc
 */
export interface ResourceDesc {
    /**
     * @generated from protobuf field: string serviceName = 1;
     */
    serviceName: string;
    /**
     * @generated from protobuf field: string getMethodName = 2;
     */
    getMethodName: string;
    /**
     * @generated from protobuf field: string updateMethodName = 3;
     */
    updateMethodName: string;
    /**
     * int32      getID       = 2 ;
     * int32      updateID    = 3 ;
     *
     * @generated from protobuf field: repeated int64 ids = 4;
     */
    ids: number[];
}
// @generated message type with reflection information, may provide speed optimized methods
class Void$Type extends MessageType<Void> {
    constructor() {
        super("bpt.base.Void", []);
    }
    create(value?: PartialMessage<Void>): Void {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<Void>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Void): Void {
        return target ?? this.create();
    }
    internalBinaryWrite(message: Void, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.Void
 */
export const Void = new Void$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Label$Type extends MessageType<Label> {
    constructor() {
        super("bpt.base.Label", [
            { no: 1, name: "Key", kind: "scalar", localName: "Key", jsonName: "Key", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "Value", kind: "scalar", localName: "Value", jsonName: "Value", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Label>): Label {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Key = "";
        message.Value = "";
        if (value !== undefined)
            reflectionMergePartial<Label>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Label): Label {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Key = 1 [json_name = "Key"];*/ 1:
                    message.Key = reader.string();
                    break;
                case /* string Value = 2 [json_name = "Value"];*/ 2:
                    message.Value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Label, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Key = 1 [json_name = "Key"]; */
        if (message.Key !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.Key);
        /* string Value = 2 [json_name = "Value"]; */
        if (message.Value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.Label
 */
export const Label = new Label$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExceptionResp$Type extends MessageType<ExceptionResp> {
    constructor() {
        super("bpt.base.ExceptionResp", [
            { no: 1, name: "code", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ExceptionResp>): ExceptionResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<ExceptionResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExceptionResp): ExceptionResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 code */ 1:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExceptionResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.ExceptionResp
 */
export const ExceptionResp = new ExceptionResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FieldMask$Type extends MessageType<FieldMask> {
    constructor() {
        super("bpt.base.FieldMask", [
            { no: 1, name: "paths", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FieldMask>): FieldMask {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.paths = [];
        if (value !== undefined)
            reflectionMergePartial<FieldMask>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FieldMask): FieldMask {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string paths */ 1:
                    message.paths.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FieldMask, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string paths = 1; */
        for (let i = 0; i < message.paths.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.paths[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.FieldMask
 */
export const FieldMask = new FieldMask$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SyncDependence$Type extends MessageType<SyncDependence> {
    constructor() {
        super("bpt.base.SyncDependence", [
            { no: 1, name: "serviceName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "updateMethodName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "getMethodName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "int64Ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "stringIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SyncDependence>): SyncDependence {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.serviceName = "";
        message.updateMethodName = "";
        message.getMethodName = "";
        message.int64Ids = [];
        message.stringIds = [];
        if (value !== undefined)
            reflectionMergePartial<SyncDependence>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SyncDependence): SyncDependence {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string serviceName */ 1:
                    message.serviceName = reader.string();
                    break;
                case /* string updateMethodName */ 2:
                    message.updateMethodName = reader.string();
                    break;
                case /* string getMethodName */ 3:
                    message.getMethodName = reader.string();
                    break;
                case /* repeated int64 int64Ids */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.int64Ids.push(reader.int64().toNumber());
                    else
                        message.int64Ids.push(reader.int64().toNumber());
                    break;
                case /* repeated string stringIds */ 5:
                    message.stringIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SyncDependence, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string serviceName = 1; */
        if (message.serviceName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.serviceName);
        /* string updateMethodName = 2; */
        if (message.updateMethodName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.updateMethodName);
        /* string getMethodName = 3; */
        if (message.getMethodName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.getMethodName);
        /* repeated int64 int64Ids = 4; */
        if (message.int64Ids.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.int64Ids.length; i++)
                writer.int64(message.int64Ids[i]);
            writer.join();
        }
        /* repeated string stringIds = 5; */
        for (let i = 0; i < message.stringIds.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.stringIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.SyncDependence
 */
export const SyncDependence = new SyncDependence$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetResourceReq$Type extends MessageType<BatchGetResourceReq> {
    constructor() {
        super("bpt.base.BatchGetResourceReq", [
            { no: 1, name: "int64Ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "stringIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetResourceReq>): BatchGetResourceReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.int64Ids = [];
        message.stringIds = [];
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<BatchGetResourceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetResourceReq): BatchGetResourceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 int64Ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.int64Ids.push(reader.int64().toNumber());
                    else
                        message.int64Ids.push(reader.int64().toNumber());
                    break;
                case /* repeated string stringIds */ 2:
                    message.stringIds.push(reader.string());
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetResourceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 int64Ids = 1; */
        if (message.int64Ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.int64Ids.length; i++)
                writer.int64(message.int64Ids[i]);
            writer.join();
        }
        /* repeated string stringIds = 2; */
        for (let i = 0; i < message.stringIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.stringIds[i]);
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.BatchGetResourceReq
 */
export const BatchGetResourceReq = new BatchGetResourceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetResourceResp$Type extends MessageType<BatchGetResourceResp> {
    constructor() {
        super("bpt.base.BatchGetResourceResp", [
            { no: 1, name: "resources", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Resource }
        ]);
    }
    create(value?: PartialMessage<BatchGetResourceResp>): BatchGetResourceResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.resources = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetResourceResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetResourceResp): BatchGetResourceResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.base.Resource resources */ 1:
                    message.resources.push(Resource.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetResourceResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.base.Resource resources = 1; */
        for (let i = 0; i < message.resources.length; i++)
            Resource.internalBinaryWrite(message.resources[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.BatchGetResourceResp
 */
export const BatchGetResourceResp = new BatchGetResourceResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Resource$Type extends MessageType<Resource> {
    constructor() {
        super("bpt.base.Resource", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "updateData", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "checksum", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "dependencies", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SyncDependence },
            { no: 5, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "stringID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Resource>): Resource {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.updateData = new Uint8Array(0);
        message.checksum = "";
        message.dependencies = [];
        message.desc = "";
        message.stringID = "";
        if (value !== undefined)
            reflectionMergePartial<Resource>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Resource): Resource {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* bytes updateData */ 2:
                    message.updateData = reader.bytes();
                    break;
                case /* string checksum */ 3:
                    message.checksum = reader.string();
                    break;
                case /* repeated bpt.base.SyncDependence dependencies */ 4:
                    message.dependencies.push(SyncDependence.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string desc */ 5:
                    message.desc = reader.string();
                    break;
                case /* string stringID */ 6:
                    message.stringID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Resource, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* bytes updateData = 2; */
        if (message.updateData.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.updateData);
        /* string checksum = 3; */
        if (message.checksum !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.checksum);
        /* repeated bpt.base.SyncDependence dependencies = 4; */
        for (let i = 0; i < message.dependencies.length; i++)
            SyncDependence.internalBinaryWrite(message.dependencies[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string desc = 5; */
        if (message.desc !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.desc);
        /* string stringID = 6; */
        if (message.stringID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.stringID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.Resource
 */
export const Resource = new Resource$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResourceDesc$Type extends MessageType<ResourceDesc> {
    constructor() {
        super("bpt.base.ResourceDesc", [
            { no: 1, name: "serviceName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "getMethodName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "updateMethodName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ResourceDesc>): ResourceDesc {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.serviceName = "";
        message.getMethodName = "";
        message.updateMethodName = "";
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<ResourceDesc>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResourceDesc): ResourceDesc {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string serviceName */ 1:
                    message.serviceName = reader.string();
                    break;
                case /* string getMethodName */ 2:
                    message.getMethodName = reader.string();
                    break;
                case /* string updateMethodName */ 3:
                    message.updateMethodName = reader.string();
                    break;
                case /* repeated int64 ids */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int64().toNumber());
                    else
                        message.ids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResourceDesc, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string serviceName = 1; */
        if (message.serviceName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.serviceName);
        /* string getMethodName = 2; */
        if (message.getMethodName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.getMethodName);
        /* string updateMethodName = 3; */
        if (message.updateMethodName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.updateMethodName);
        /* repeated int64 ids = 4; */
        if (message.ids.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int64(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.base.ResourceDesc
 */
export const ResourceDesc = new ResourceDesc$Type();
