import { useState } from 'react';

export default function Header() {
  const [visible, setVisible] = useState(false);

  const showHeader = () => setVisible(true);

  const hideHeader = () => setVisible(false);

  return { showHeader, hideHeader, headerVisible: visible };
}
