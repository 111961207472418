// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/match/matchlink/v1/matchlink.proto" (package "bpt.match.matchlink.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { PrizeType } from "../../common/v1/common";
import { Unit } from "../../../../gaw/asset/wallet/v1/token";
import { TokenType } from "../../../common/common_web3";
import { ChainID } from "../../../../gaw/base/base";
/**
 * @generated from protobuf message bpt.match.matchlink.v1.MatchChainInfo
 */
export interface MatchChainInfo {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainId = 1;
     */
    chainId: ChainID;
    /**
     * string  contract = 2;
     *
     * @generated from protobuf field: string dispatch_contract = 3;
     */
    dispatch_contract: string; // 比赛合约
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.Token
 */
export interface Token {
    /**
     * @generated from protobuf field: bpt.common.TokenType type = 1;
     */
    type: TokenType;
    /**
     * 代币合约地址  如果是 ERC20 则需要填写
     *
     * @generated from protobuf field: string contactAddr = 2;
     */
    contactAddr: string;
    /**
     * ERC721, ERC1155 时需要填写
     *
     * @generated from protobuf field: string tokenID = 3;
     */
    tokenID: string;
    /**
     * 数额, 最小为 1  (代币都是有小数位的，为 uint256 类型，在 proto 中不好表示)
     * 所以这里需要配合下面的 unit 进行配置，最终的值是 amount * unit 所代表的值
     *
     * @generated from protobuf field: int64 amount = 4;
     */
    amount: number;
    /**
     * 单位
     *
     * @generated from protobuf field: gaw.asset.wallet.v1.Unit unit = 5;
     */
    unit: Unit;
    /**
     * @generated from protobuf field: string rawAmount = 6;
     */
    rawAmount: string;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.Callback
 */
export interface Callback {
    /**
     * 支付结果回调 url 目前支持 RPC 回调 例如：rpc://tss.hall.pay/callbackmethod
     *
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.TimeInfo
 */
export interface TimeInfo {
    /**
     * @generated from protobuf field: int64 createdAt = 1;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 updatedAt = 2;
     */
    updatedAt: number;
    /**
     * @generated from protobuf field: int64 finishedAt = 3;
     */
    finishedAt: number; // int64 canceledAt = 4;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.Transaction
 */
export interface Transaction {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * 类型
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.TransactionType type = 2;
     */
    type: TransactionType;
    /**
     * 状态
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.TransactionStatus status = 3;
     */
    status: TransactionStatus;
    /**
     * 比赛链相关信息
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.MatchChainInfo chainInfo = 4;
     */
    chainInfo?: MatchChainInfo;
    /**
     * 比赛 ID
     *
     * @generated from protobuf field: int64 matchId = 6;
     */
    matchId: number;
    /**
     * 用户ID
     *
     * @generated from protobuf field: int64 uid = 8;
     */
    uid: number;
    /**
     * 退费类型 (如果是退费的话才有)
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.MatchWithdrawType withdrawType = 9;
     */
    withdrawType: MatchWithdrawType;
    /**
     * 退费相关的交易ID
     *
     * @generated from protobuf field: repeated string withdrawIDs = 10;
     */
    withdrawIDs: string[];
    /**
     * 参与者的钱包地址 (回调时候才会有)
     *
     * @generated from protobuf field: string walletAddr = 11;
     */
    walletAddr: string;
    /**
     * 透传给前端调起钱包
     *
     * @generated from protobuf field: bytes credential = 12;
     */
    credential: Uint8Array;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14;
     */
    callback?: Callback;
    /**
     * 时间相关信息
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.TimeInfo timeinfo = 15;
     */
    timeinfo?: TimeInfo;
    /**
     * 链上交易的 hash (回调时候才会有)
     *
     * @generated from protobuf field: string chainTxHash = 16;
     */
    chainTxHash: string;
    /**
     * 有计算数量的交易时有值，比如可以买多手的addon操作
     *
     * @generated from protobuf field: int64 count = 17;
     */
    count: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.MatchConfig
 */
export interface MatchConfig {
    /**
     * 开始时间 Unix 时间戳
     *
     * @generated from protobuf field: int64 startTime = 1;
     */
    startTime: number;
    /**
     * 是否是固定奖池 （若是开启动态奖池，则报名费代币需要和奖池代币一致）
     *
     * @deprecated
     * @generated from protobuf field: bool isFixedPool = 2 [deprecated = true];
     */
    isFixedPool: boolean;
    /**
     * 是否是私人比赛，需要密码（签名）
     *
     * @deprecated
     * @generated from protobuf field: bool isPrivate = 3 [deprecated = true];
     */
    isPrivate: boolean;
    /**
     * 奖池
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Token prizeToken = 4 [deprecated = true];
     */
    prizeToken?: Token;
    /**
     * 多币种奖池
     *
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.Token prizeTokens = 22;
     */
    prizeTokens: Token[];
    /**
     * 合约需要授权的金额
     *
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.Token approveTokens = 23;
     */
    approveTokens: Token[];
    /**
     * 最大使用门票数量
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token maxTicket = 24;
     */
    maxTicket?: Token;
    /**
     * 抽奖奖池
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token raffleToken = 27;
     */
    raffleToken?: Token;
    /**
     * 报名费
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token registerToken = 5;
     */
    registerToken?: Token;
    /**
     * 重入费 (代币要与报名费的代币一致)
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token reEntryToken = 6;
     */
    reEntryToken?: Token;
    /**
     * rebuy费 (代币要与报名费的代币一致)
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token reBuyToken = 13;
     */
    reBuyToken?: Token;
    /**
     * AddOn费 (代币要与报名费的代币一致)
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token addOnToken = 14;
     */
    addOnToken?: Token;
    /**
     * 门票报名
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token registerTicket = 25;
     */
    registerTicket?: Token;
    /**
     * 门票重入
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token reEntryTicket = 26;
     */
    reEntryTicket?: Token;
    /**
     * 固定参赛人数 (0为不限制)
     *
     * @generated from protobuf field: uint32 fixedParticipants = 7;
     */
    fixedParticipants: number;
    /**
     * 最小参赛人数
     *
     * @generated from protobuf field: uint32 minParticipants = 8;
     */
    minParticipants: number;
    /**
     * 最大参赛人数
     *
     * @generated from protobuf field: uint32 maxParticipants = 9;
     */
    maxParticipants: number;
    /**
     * rebuy次数
     *
     * @generated from protobuf field: uint32 MatchRebuyCount = 10 [json_name = "MatchRebuyCount"];
     */
    MatchRebuyCount: number;
    /**
     * addon次数
     *
     * @generated from protobuf field: uint32 MatchAddonCount = 11 [json_name = "MatchAddonCount"];
     */
    MatchAddonCount: number;
    /**
     * reentry次数
     *
     * @generated from protobuf field: uint32 MatchReentryCount = 12 [json_name = "MatchReentryCount"];
     */
    MatchReentryCount: number;
    /**
     * nft奖励相关
     * 冠军
     *
     * @generated from protobuf field: bool CanMintChampion = 15 [json_name = "CanMintChampion"];
     */
    CanMintChampion: boolean;
    /**
     * 奖圈
     *
     * @generated from protobuf field: bool CanMintAward = 16 [json_name = "CanMintAward"];
     */
    CanMintAward: boolean;
    /**
     * FinalTable
     *
     * @generated from protobuf field: bool CanMintFinalTable = 17 [json_name = "CanMintFinalTable"];
     */
    CanMintFinalTable: boolean;
    /**
     * 泡沫
     *
     * @generated from protobuf field: bool CanMintBubble = 18 [json_name = "CanMintBubble"];
     */
    CanMintBubble: boolean;
    /**
     * 是否自动发奖
     *
     * @generated from protobuf field: bool isAutoAward = 19;
     */
    isAutoAward: boolean;
    /**
     * @generated from protobuf field: bpt.match.common.v1.PrizeType PrizeType = 20 [json_name = "PrizeType"];
     */
    PrizeType: PrizeType; // 赛事奖励类型
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.DrawMatchConfig DrawMatchInfo = 21 [json_name = "DrawMatchInfo"];
     */
    DrawMatchInfo?: DrawMatchConfig; // 并列赛配置
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.DrawMatchConfig
 */
export interface DrawMatchConfig {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Token ThresholdAmount = 1 [json_name = "ThresholdAmount"];
     */
    ThresholdAmount?: Token; // 并列赛  奖池每增加X token，则奖圈人数+1  token的RawAmount
    /**
     * @generated from protobuf field: uint32 InitialWinnerCount = 2 [json_name = "InitialWinnerCount"];
     */
    InitialWinnerCount: number; // 并列赛的初始人数
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.MatchCustomConfig
 */
export interface MatchCustomConfig {
    /**
     * @generated from protobuf field: int64 startTime = 1;
     */
    startTime: number;
    /**
     * @generated from protobuf field: uint32 minParticipants = 2;
     */
    minParticipants: number;
    /**
     * @generated from protobuf field: uint32 maxParticipants = 3;
     */
    maxParticipants: number;
    /**
     * 报名费
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token registryToken = 4;
     */
    registryToken?: Token;
    /**
     * 重入费
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token reEntryToken = 5;
     */
    reEntryToken?: Token;
    /**
     * rebuy费
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token reBuyToken = 6;
     */
    reBuyToken?: Token;
    /**
     * AddOn费
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token addOnToken = 7;
     */
    addOnToken?: Token;
    /**
     * rebuy次数
     *
     * @generated from protobuf field: uint32 MatchRebuyCount = 8 [json_name = "MatchRebuyCount"];
     */
    MatchRebuyCount: number;
    /**
     * addon次数
     *
     * @generated from protobuf field: uint32 MatchAddonCount = 9 [json_name = "MatchAddonCount"];
     */
    MatchAddonCount: number;
    /**
     * reentry次数
     *
     * @generated from protobuf field: uint32 MatchReentryCount = 10 [json_name = "MatchReentryCount"];
     */
    MatchReentryCount: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.CreateMatchReq
 */
export interface CreateMatchReq {
    /**
     * uint32 appID = 1;
     *
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number; // wallet addr
    /**
     * ChainInfo
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.MatchChainInfo chainInfo = 3;
     */
    chainInfo?: MatchChainInfo;
    /**
     * 比赛ID
     *
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 比赛名称
     *
     * @generated from protobuf field: string title = 5;
     */
    title: string;
    /**
     * 比赛描述
     *
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * 比赛配置
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.MatchConfig matchConfig = 12;
     */
    matchConfig?: MatchConfig;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
    /**
     * 多赛事ID
     *
     * @generated from protobuf field: repeated int64 subMatchIds = 16;
     */
    subMatchIds: number[];
    /**
     * 子比赛差异配置
     *
     * @generated from protobuf field: map<int64, bpt.match.matchlink.v1.MatchCustomConfig> subMatchConfig = 17;
     */
    subMatchConfig: {
        [key: string]: MatchCustomConfig;
    };
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.CreateMatchResp
 */
export interface CreateMatchResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.UserPrize
 */
export interface UserPrize {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * 奖圈比例，总和为 10000 份(动态奖池必传)
     *
     * @generated from protobuf field: uint32 prizeRatio = 5;
     */
    prizeRatio: number;
    /**
     * 固定奖池数量
     *
     * @generated from protobuf field: string rawAmount = 6;
     */
    rawAmount: string;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.MultiPrize
 */
export interface MultiPrize {
    /**
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.UserPrize userPrizes = 1;
     */
    userPrizes: UserPrize[];
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.EndMatchReq
 */
export interface EndMatchReq {
    /**
     * @generated from protobuf field: int64 matchId = 1;
     */
    matchId: number;
    /**
     * 链上结算时间
     *
     * @deprecated
     * @generated from protobuf field: int64 endTime = 2 [deprecated = true];
     */
    endTime: number;
    /**
     * 奖圈比例，其中 prizeRatio 总和为 10000 份
     *
     * @deprecated
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.UserPrize userPrizes = 3 [deprecated = true];
     */
    userPrizes: UserPrize[];
    /**
     * @deprecated
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.MultiPrize multiPrizes = 7 [deprecated = true];
     */
    multiPrizes: MultiPrize[];
    /**
     * @deprecated
     * @generated from protobuf field: int64 finalTableCount = 5 [deprecated = true];
     */
    finalTableCount: number;
    /**
     * @deprecated
     * @generated from protobuf field: int64 uid = 6 [deprecated = true];
     */
    uid: number; // 操作人
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
    /**
     * 是不是自动发奖（用户不需要再扫码，直接自动发放到地址， 当这个为true  需要构建uid=>用户地址 丢给合约
     *
     * @deprecated
     * @generated from protobuf field: bool matchForSendPrize = 16 [deprecated = true];
     */
    matchForSendPrize: boolean;
    /**
     * @deprecated
     * @generated from protobuf field: repeated string successTs = 17 [deprecated = true];
     */
    successTs: string[];
    /**
     * @deprecated
     * @generated from protobuf field: int64 regNum = 18 [deprecated = true];
     */
    regNum: number; // 报名人数 包括延迟报名的人数
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.EndMatchResp
 */
export interface EndMatchResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.CancelMatchReq
 */
export interface CancelMatchReq {
    /**
     * @generated from protobuf field: int64 matchId = 1;
     */
    matchId: number;
    /**
     * 链上取消比赛时间 Unix = 2
     *
     * @deprecated
     * @generated from protobuf field: int64 cancelTime = 2 [deprecated = true];
     */
    cancelTime: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number; // 操作人
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
    /**
     * @generated from protobuf field: bool isNotEnoughUser = 16;
     */
    isNotEnoughUser: boolean; // 是不是人数不足
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.CancelMatchResp
 */
export interface CancelMatchResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ForceCancelMatchReq
 */
export interface ForceCancelMatchReq {
    /**
     * @generated from protobuf field: int64 matchId = 1;
     */
    matchId: number;
    /**
     * 链上取消比赛时间 Unix = 2
     *
     * @deprecated
     * @generated from protobuf field: int64 cancelTime = 2 [deprecated = true];
     */
    cancelTime: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ForceCancelMatchResp
 */
export interface ForceCancelMatchResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * 自动链上报名
 *
 * @generated from protobuf message bpt.match.matchlink.v1.RegisterAutoReq
 */
export interface RegisterAutoReq {
    /**
     * @generated from protobuf field: repeated int64 uidArr = 1;
     */
    uidArr: number[];
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * @generated from protobuf field: string matchKey = 5;
     */
    matchKey: string;
    /**
     * @deprecated
     * @generated from protobuf field: bool isMultiDays = 15 [deprecated = true];
     */
    isMultiDays: boolean;
    /**
     * @deprecated
     * @generated from protobuf field: bool isDelayRegister = 16 [deprecated = true];
     */
    isDelayRegister: boolean;
    /**
     * @deprecated
     * @generated from protobuf field: int64 deadline = 17 [deprecated = true];
     */
    deadline: number;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.RegisterAutoResp
 */
export interface RegisterAutoResp {
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.RegisterReq
 */
export interface RegisterReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 私密比赛的签名
     *
     * @deprecated
     * @generated from protobuf field: bytes signature = 5 [deprecated = true];
     */
    signature: Uint8Array;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
    /**
     * 门票数量
     *
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.Token tokens = 17;
     */
    tokens: Token[];
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.RegisterResp
 */
export interface RegisterResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.DelayRegisterReq
 */
export interface DelayRegisterReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 私密比赛的签名
     *
     * @deprecated
     * @generated from protobuf field: bytes signature = 5 [deprecated = true];
     */
    signature: Uint8Array;
    /**
     * 过期时间
     *
     * @deprecated
     * @generated from protobuf field: int64 deadline = 6 [deprecated = true];
     */
    deadline: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
    /**
     * 门票数量
     *
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.Token tokens = 17;
     */
    tokens: Token[];
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.DelayRegisterResp
 */
export interface DelayRegisterResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ReEntryReq
 */
export interface ReEntryReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 过期时间
     *
     * @deprecated
     * @generated from protobuf field: int64 deadline = 6 [deprecated = true];
     */
    deadline: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
    /**
     * 门票数量
     *
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.Token tokens = 17;
     */
    tokens: Token[];
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ReEntryResp
 */
export interface ReEntryResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ReEntryAutoReq
 */
export interface ReEntryAutoReq {
    /**
     * @generated from protobuf field: int64 matchId = 1;
     */
    matchId: number;
    /**
     * @generated from protobuf field: repeated int64 uidArr = 2;
     */
    uidArr: number[];
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ReEntryAutoResp
 */
export interface ReEntryAutoResp {
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ReBuyReq
 */
export interface ReBuyReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 过期时间
     *
     * @deprecated
     * @generated from protobuf field: int64 deadline = 6 [deprecated = true];
     */
    deadline: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ReBuyResp
 */
export interface ReBuyResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.AddOnReq
 */
export interface AddOnReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 过期时间
     *
     * @deprecated
     * @generated from protobuf field: int64 deadline = 6 [deprecated = true];
     */
    deadline: number;
    /**
     * 买入手数
     *
     * @generated from protobuf field: int64 count = 7;
     */
    count: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.AddOnResp
 */
export interface AddOnResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.PrizeInfo
 */
export interface PrizeInfo {
    /**
     * @generated from protobuf field: bpt.common.TokenType tokenType = 1;
     */
    tokenType: TokenType;
    /**
     * @generated from protobuf field: string tokenAddress = 2;
     */
    tokenAddress: string;
    /**
     * @generated from protobuf field: string tokenAmount = 3;
     */
    tokenAmount: string;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.AwardPrizeInfo
 */
export interface AwardPrizeInfo {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.PrizeInfo prizes = 2;
     */
    prizes: PrizeInfo[];
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ConfirmPrizeReq
 */
export interface ConfirmPrizeReq {
    /**
     * @generated from protobuf field: int64 matchId = 1;
     */
    matchId: number;
    /**
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.AwardPrizeInfo awardees = 2;
     */
    awardees: AwardPrizeInfo[];
    /**
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 3 [deprecated = true];
     */
    callback?: Callback;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ConfirmPrizeResp
 */
export interface ConfirmPrizeResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.SendPrizeReq
 */
export interface SendPrizeReq {
    /**
     * @generated from protobuf field: int64 matchId = 1;
     */
    matchId: number;
    /**
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.AwardPrizeInfo awardees = 2;
     */
    awardees: AwardPrizeInfo[];
    /**
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 3 [deprecated = true];
     */
    callback?: Callback;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.SendPrizeResp
 */
export interface SendPrizeResp {
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.MatchWithdrawReq
 */
export interface MatchWithdrawReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 过期时间
     *
     * @deprecated
     * @generated from protobuf field: int64 deadline = 6 [deprecated = true];
     */
    deadline: number;
    /**
     * 类型
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.MatchWithdrawType type = 7;
     */
    type: MatchWithdrawType;
    /**
     * @generated from protobuf field: string transactionId = 8;
     */
    transactionId: string;
    // 退费相应的交易ID，用于防止重复提交, 如果有处理过的或者和记录的不一致，都会返回错误
    // repeated string transactionIDs = 9;

    // // 次数 (最终退的数量是 对应配置的 token * count)
    // uint64 count = 10;

    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.MatchWithdrawResp
 */
export interface MatchWithdrawResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.BatchWithdrawReq
 */
export interface BatchWithdrawReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
    /**
     * @deprecated
     * @generated from protobuf field: repeated string transactionIDs = 2 [deprecated = true];
     */
    transactionIDs: string[];
    /**
     * @generated from protobuf field: int64 matchId = 3;
     */
    matchId: number;
    /**
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 4 [deprecated = true];
     */
    callback?: Callback;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.BatchWithdrawResp
 */
export interface BatchWithdrawResp {
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.LeaveMatchReq
 */
export interface LeaveMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 链上触发的退出比赛时间
     *
     * @deprecated
     * @generated from protobuf field: int64 leaveTime = 5 [deprecated = true];
     */
    leaveTime: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @deprecated
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @deprecated
     * @generated from protobuf field: int64 credentailTTL = 15 [deprecated = true];
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.LeaveMatchResp
 */
export interface LeaveMatchResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ClaimPrizeReq
 */
export interface ClaimPrizeReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 排名
     *
     * @generated from protobuf field: int32 rank = 3;
     */
    rank: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 13;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 14;
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @generated from protobuf field: int64 credentailTTL = 15;
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.ClaimPrizeResp
 */
export interface ClaimPrizeResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.MatchCashConfig
 */
export interface MatchCashConfig {
    /**
     * 比例: 1:100（rate）（比率算整数个，不包含小数）
     *
     * @generated from protobuf field: uint64 cashRate = 1;
     */
    cashRate: number;
    /**
     * 最小买入
     *
     * @generated from protobuf field: uint64 minAmount = 2;
     */
    minAmount: number;
    /**
     * 最大买入
     *
     * @generated from protobuf field: uint64 maxAmount = 3;
     */
    maxAmount: number;
    /**
     * 现金桌需要的地址和货币类型
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Token cashToken = 5;
     */
    cashToken?: Token;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.CreateCashMatchReq
 */
export interface CreateCashMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * ChainInfo
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.MatchChainInfo chainInfo = 2;
     */
    chainInfo?: MatchChainInfo;
    /**
     * 比赛ID
     *
     * @generated from protobuf field: int64 matchId = 3;
     */
    matchId: number;
    /**
     * 比赛名称
     *
     * @generated from protobuf field: string title = 4;
     */
    title: string;
    /**
     * 比赛描述
     *
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * 比赛配置
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.MatchCashConfig matchConfig = 6;
     */
    matchConfig?: MatchCashConfig;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 7;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 8;
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @generated from protobuf field: int64 credentailTTL = 9;
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.CreateCashMatchResp
 */
export interface CreateCashMatchResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.BuyCashReq
 */
export interface BuyCashReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 2;
     */
    matchId: number;
    /**
     * 私密比赛的签名
     *
     * @generated from protobuf field: bytes signature = 3;
     */
    signature: Uint8Array;
    /**
     * @generated from protobuf field: uint64 chipAmount = 4;
     */
    chipAmount: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 5;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 6;
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @generated from protobuf field: int64 credentailTTL = 7;
     */
    credentailTTL: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.BuyCashResp
 */
export interface BuyCashResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.WithDrawCashReq
 */
export interface WithDrawCashReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 matchId = 2;
     */
    matchId: number;
    /**
     * 私密比赛的签名
     *
     * @generated from protobuf field: bytes signature = 3;
     */
    signature: Uint8Array;
    /**
     * @generated from protobuf field: uint64 chipAmount = 4;
     */
    chipAmount: number;
    /**
     * 回调透传数据
     *
     * @generated from protobuf field: bytes extra = 5;
     */
    extra: Uint8Array;
    /**
     * 回调配置
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.Callback callback = 6;
     */
    callback?: Callback;
    /**
     * Credentail 有效期 (单位秒，默认七天), 0 为永久有效
     *
     * @generated from protobuf field: int64 credentailTTL = 7;
     */
    credentailTTL: number;
    /**
     * @generated from protobuf field: int64 timestamp = 8;
     */
    timestamp: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.WithDrawCashResp
 */
export interface WithDrawCashResp {
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction?: Transaction;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.GetTransactionReq
 */
export interface GetTransactionReq {
    /**
     * @generated from protobuf field: int64 matchId = 1;
     */
    matchId: number;
    /**
     * @generated from protobuf field: int64 userId = 2;
     */
    userId: number;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.GetTransactionResp
 */
export interface GetTransactionResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchlink.v1.Transaction transaction = 1;
     */
    transaction: Transaction[];
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.SetTransactionStatusReq
 */
export interface SetTransactionStatusReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * 状态
     *
     * @generated from protobuf field: bpt.match.matchlink.v1.TransactionStatus status = 2;
     */
    status: TransactionStatus;
}
/**
 * @generated from protobuf message bpt.match.matchlink.v1.SetTransactionStatusResp
 */
export interface SetTransactionStatusResp {
}
/**
 * @generated from protobuf enum bpt.match.matchlink.v1.MatchWithdrawType
 */
export enum MatchWithdrawType {
    /**
     * @generated from protobuf enum value: MatchWithdrawTypeUnknown = 0;
     */
    MatchWithdrawTypeUnknown = 0,
    /**
     * ReEntry
     *
     * @generated from protobuf enum value: MatchWithdrawTypeReEntry = 1;
     */
    MatchWithdrawTypeReEntry = 1,
    /**
     * ReBuy
     *
     * @generated from protobuf enum value: MatchWithdrawTypeReBuy = 2;
     */
    MatchWithdrawTypeReBuy = 2,
    /**
     * AddOn
     *
     * @generated from protobuf enum value: MatchWithdrawTypeAddOn = 3;
     */
    MatchWithdrawTypeAddOn = 3,
    /**
     * Register
     *
     * @generated from protobuf enum value: MatchWithdrawTypeRegister = 4;
     */
    MatchWithdrawTypeRegister = 4,
    /**
     * BatchWithdraw
     *
     * @generated from protobuf enum value: MatchWithdrawTypeBatch = 5;
     */
    MatchWithdrawTypeBatch = 5
}
/**
 * @generated from protobuf enum bpt.match.matchlink.v1.TransactionType
 */
export enum TransactionType {
    /**
     * @generated from protobuf enum value: TransactionTypeUnknown = 0;
     */
    TransactionTypeUnknown = 0,
    /**
     * 创建比赛
     *
     * @generated from protobuf enum value: TransactionTypeCreateMatch = 1;
     */
    TransactionTypeCreateMatch = 1,
    /**
     * 注册
     *
     * @generated from protobuf enum value: TransactionTypeRegister = 3;
     */
    TransactionTypeRegister = 3,
    /**
     * 离开比赛
     *
     * @generated from protobuf enum value: TransactionTypeLeaveMatch = 5;
     */
    TransactionTypeLeaveMatch = 5,
    /**
     * 结束比赛
     *
     * @generated from protobuf enum value: TransactionTypeEndMatch = 7;
     */
    TransactionTypeEndMatch = 7,
    /**
     * 取消比赛
     *
     * @generated from protobuf enum value: TransactionTypeCancelMatch = 9;
     */
    TransactionTypeCancelMatch = 9,
    /**
     * 人数不足
     *
     * @generated from protobuf enum value: TransactionTypeCancelMatchNotEnoughUser = 10;
     */
    TransactionTypeCancelMatchNotEnoughUser = 10,
    /**
     * 领取奖励
     *
     * @generated from protobuf enum value: TransactionTypeClaimPrize = 11;
     */
    TransactionTypeClaimPrize = 11,
    /**
     * 发放奖励
     *
     * @generated from protobuf enum value: TransactionTypeSendPrize = 12;
     */
    TransactionTypeSendPrize = 12,
    /**
     * 确认奖励
     *
     * @generated from protobuf enum value: TransactionTypeConfirmPrize = 18;
     */
    TransactionTypeConfirmPrize = 18,
    /**
     * 自动报名
     *
     * @generated from protobuf enum value: TransactionTypeAutoRegisterMatch = 16;
     */
    TransactionTypeAutoRegisterMatch = 16,
    /**
     * 自动重入
     *
     * @generated from protobuf enum value: TransactionTypeAutoReEntry = 25;
     */
    TransactionTypeAutoReEntry = 25,
    /**
     * 延迟报名
     *
     * @generated from protobuf enum value: TransactionTypeDelayRegisterMatch = 13;
     */
    TransactionTypeDelayRegisterMatch = 13,
    /**
     * ReEntry
     *
     * @generated from protobuf enum value: TransactionTypeReEntry = 15;
     */
    TransactionTypeReEntry = 15,
    /**
     * ReBuy
     *
     * @generated from protobuf enum value: TransactionTypeReBuy = 17;
     */
    TransactionTypeReBuy = 17,
    /**
     * AddOn
     *
     * @generated from protobuf enum value: TransactionTypeAddOn = 19;
     */
    TransactionTypeAddOn = 19,
    /**
     * MatchWithDraw
     *
     * @generated from protobuf enum value: TransactionTypeMatchWithDraw = 20;
     */
    TransactionTypeMatchWithDraw = 20,
    /**
     * 强制取消比赛
     *
     * @generated from protobuf enum value: TransactionTypeForceCancelMatch = 21;
     */
    TransactionTypeForceCancelMatch = 21,
    /**
     * BatchWitdraw
     *
     * @generated from protobuf enum value: TransactionTypeBatchWithdraw = 23;
     */
    TransactionTypeBatchWithdraw = 23,
    /**
     * 创建现金桌
     *
     * @generated from protobuf enum value: TransactionTypeCreateCashMatch = 30;
     */
    TransactionTypeCreateCashMatch = 30,
    /**
     * 现金桌买筹码
     *
     * @generated from protobuf enum value: TransactionTypeCashBuyCash = 31;
     */
    TransactionTypeCashBuyCash = 31,
    /**
     * 现金桌卖筹码
     *
     * @generated from protobuf enum value: TransactionTypeWithdrawCash = 32;
     */
    TransactionTypeWithdrawCash = 32
}
/**
 * @generated from protobuf enum bpt.match.matchlink.v1.TransactionStatus
 */
export enum TransactionStatus {
    /**
     * @generated from protobuf enum value: TransactionStatusUnknown = 0;
     */
    TransactionStatusUnknown = 0,
    /**
     * 只是在这里初始化，并未提交
     *
     * @generated from protobuf enum value: TransactionStatusInit = 1;
     */
    TransactionStatusInit = 1,
    /**
     * TODO 等待状态，暂时没有这个状态
     * TransactionStatusPendding = 2;
     * 执行成功
     *
     * @generated from protobuf enum value: TransactionStatusSuccess = 3;
     */
    TransactionStatusSuccess = 3,
    /**
     * 执行失败
     *
     * @generated from protobuf enum value: TransactionStatusFailed = 4;
     */
    TransactionStatusFailed = 4,
    /**
     * 被取消
     *
     * @generated from protobuf enum value: TransactionStatusCanceled = 5;
     */
    TransactionStatusCanceled = 5,
    /**
     * 退费
     *
     * @generated from protobuf enum value: TransactionStatusWithdraw = 6;
     */
    TransactionStatusWithdraw = 6
}
// @generated message type with reflection information, may provide speed optimized methods
class MatchChainInfo$Type extends MessageType<MatchChainInfo> {
    constructor() {
        super("bpt.match.matchlink.v1.MatchChainInfo", [
            { no: 1, name: "chainId", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 3, name: "dispatch_contract", kind: "scalar", localName: "dispatch_contract", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchChainInfo>): MatchChainInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainId = 0;
        message.dispatch_contract = "";
        if (value !== undefined)
            reflectionMergePartial<MatchChainInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchChainInfo): MatchChainInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainId */ 1:
                    message.chainId = reader.int32();
                    break;
                case /* string dispatch_contract */ 3:
                    message.dispatch_contract = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchChainInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainId = 1; */
        if (message.chainId !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainId);
        /* string dispatch_contract = 3; */
        if (message.dispatch_contract !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dispatch_contract);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.MatchChainInfo
 */
export const MatchChainInfo = new MatchChainInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Token$Type extends MessageType<Token> {
    constructor() {
        super("bpt.match.matchlink.v1.Token", [
            { no: 1, name: "type", kind: "enum", T: () => ["bpt.common.TokenType", TokenType] },
            { no: 2, name: "contactAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tokenID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "unit", kind: "enum", T: () => ["gaw.asset.wallet.v1.Unit", Unit] },
            { no: 6, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Token>): Token {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.contactAddr = "";
        message.tokenID = "";
        message.amount = 0;
        message.unit = 0;
        message.rawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<Token>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Token): Token {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.TokenType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string contactAddr */ 2:
                    message.contactAddr = reader.string();
                    break;
                case /* string tokenID */ 3:
                    message.tokenID = reader.string();
                    break;
                case /* int64 amount */ 4:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* gaw.asset.wallet.v1.Unit unit */ 5:
                    message.unit = reader.int32();
                    break;
                case /* string rawAmount */ 6:
                    message.rawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Token, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.TokenType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string contactAddr = 2; */
        if (message.contactAddr !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contactAddr);
        /* string tokenID = 3; */
        if (message.tokenID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tokenID);
        /* int64 amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Varint).int64(message.amount);
        /* gaw.asset.wallet.v1.Unit unit = 5; */
        if (message.unit !== 0)
            writer.tag(5, WireType.Varint).int32(message.unit);
        /* string rawAmount = 6; */
        if (message.rawAmount !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.rawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.Token
 */
export const Token = new Token$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Callback$Type extends MessageType<Callback> {
    constructor() {
        super("bpt.match.matchlink.v1.Callback", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Callback>): Callback {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<Callback>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Callback): Callback {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Callback, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.Callback
 */
export const Callback = new Callback$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TimeInfo$Type extends MessageType<TimeInfo> {
    constructor() {
        super("bpt.match.matchlink.v1.TimeInfo", [
            { no: 1, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "updatedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "finishedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<TimeInfo>): TimeInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.createdAt = 0;
        message.updatedAt = 0;
        message.finishedAt = 0;
        if (value !== undefined)
            reflectionMergePartial<TimeInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TimeInfo): TimeInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 createdAt */ 1:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 updatedAt */ 2:
                    message.updatedAt = reader.int64().toNumber();
                    break;
                case /* int64 finishedAt */ 3:
                    message.finishedAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TimeInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 createdAt = 1; */
        if (message.createdAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.createdAt);
        /* int64 updatedAt = 2; */
        if (message.updatedAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.updatedAt);
        /* int64 finishedAt = 3; */
        if (message.finishedAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.finishedAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.TimeInfo
 */
export const TimeInfo = new TimeInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Transaction$Type extends MessageType<Transaction> {
    constructor() {
        super("bpt.match.matchlink.v1.Transaction", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.match.matchlink.v1.TransactionType", TransactionType] },
            { no: 3, name: "status", kind: "enum", T: () => ["bpt.match.matchlink.v1.TransactionStatus", TransactionStatus] },
            { no: 4, name: "chainInfo", kind: "message", T: () => MatchChainInfo },
            { no: 6, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "withdrawType", kind: "enum", T: () => ["bpt.match.matchlink.v1.MatchWithdrawType", MatchWithdrawType] },
            { no: 10, name: "withdrawIDs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "walletAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "timeinfo", kind: "message", T: () => TimeInfo },
            { no: 16, name: "chainTxHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Transaction>): Transaction {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.type = 0;
        message.status = 0;
        message.matchId = 0;
        message.uid = 0;
        message.withdrawType = 0;
        message.withdrawIDs = [];
        message.walletAddr = "";
        message.credential = new Uint8Array(0);
        message.extra = new Uint8Array(0);
        message.chainTxHash = "";
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<Transaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Transaction): Transaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bpt.match.matchlink.v1.TransactionType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* bpt.match.matchlink.v1.TransactionStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* bpt.match.matchlink.v1.MatchChainInfo chainInfo */ 4:
                    message.chainInfo = MatchChainInfo.internalBinaryRead(reader, reader.uint32(), options, message.chainInfo);
                    break;
                case /* int64 matchId */ 6:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 8:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchlink.v1.MatchWithdrawType withdrawType */ 9:
                    message.withdrawType = reader.int32();
                    break;
                case /* repeated string withdrawIDs */ 10:
                    message.withdrawIDs.push(reader.string());
                    break;
                case /* string walletAddr */ 11:
                    message.walletAddr = reader.string();
                    break;
                case /* bytes credential */ 12:
                    message.credential = reader.bytes();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback */ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* bpt.match.matchlink.v1.TimeInfo timeinfo */ 15:
                    message.timeinfo = TimeInfo.internalBinaryRead(reader, reader.uint32(), options, message.timeinfo);
                    break;
                case /* string chainTxHash */ 16:
                    message.chainTxHash = reader.string();
                    break;
                case /* int64 count */ 17:
                    message.count = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Transaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bpt.match.matchlink.v1.TransactionType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* bpt.match.matchlink.v1.TransactionStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* bpt.match.matchlink.v1.MatchChainInfo chainInfo = 4; */
        if (message.chainInfo)
            MatchChainInfo.internalBinaryWrite(message.chainInfo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int64 matchId = 6; */
        if (message.matchId !== 0)
            writer.tag(6, WireType.Varint).int64(message.matchId);
        /* int64 uid = 8; */
        if (message.uid !== 0)
            writer.tag(8, WireType.Varint).int64(message.uid);
        /* bpt.match.matchlink.v1.MatchWithdrawType withdrawType = 9; */
        if (message.withdrawType !== 0)
            writer.tag(9, WireType.Varint).int32(message.withdrawType);
        /* repeated string withdrawIDs = 10; */
        for (let i = 0; i < message.withdrawIDs.length; i++)
            writer.tag(10, WireType.LengthDelimited).string(message.withdrawIDs[i]);
        /* string walletAddr = 11; */
        if (message.walletAddr !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.walletAddr);
        /* bytes credential = 12; */
        if (message.credential.length)
            writer.tag(12, WireType.LengthDelimited).bytes(message.credential);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.TimeInfo timeinfo = 15; */
        if (message.timeinfo)
            TimeInfo.internalBinaryWrite(message.timeinfo, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string chainTxHash = 16; */
        if (message.chainTxHash !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.chainTxHash);
        /* int64 count = 17; */
        if (message.count !== 0)
            writer.tag(17, WireType.Varint).int64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.Transaction
 */
export const Transaction = new Transaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchConfig$Type extends MessageType<MatchConfig> {
    constructor() {
        super("bpt.match.matchlink.v1.MatchConfig", [
            { no: 1, name: "startTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "isFixedPool", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "isPrivate", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "prizeToken", kind: "message", T: () => Token },
            { no: 22, name: "prizeTokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token },
            { no: 23, name: "approveTokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token },
            { no: 24, name: "maxTicket", kind: "message", T: () => Token },
            { no: 27, name: "raffleToken", kind: "message", T: () => Token },
            { no: 5, name: "registerToken", kind: "message", T: () => Token },
            { no: 6, name: "reEntryToken", kind: "message", T: () => Token },
            { no: 13, name: "reBuyToken", kind: "message", T: () => Token },
            { no: 14, name: "addOnToken", kind: "message", T: () => Token },
            { no: 25, name: "registerTicket", kind: "message", T: () => Token },
            { no: 26, name: "reEntryTicket", kind: "message", T: () => Token },
            { no: 7, name: "fixedParticipants", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "minParticipants", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "maxParticipants", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "MatchRebuyCount", kind: "scalar", localName: "MatchRebuyCount", jsonName: "MatchRebuyCount", T: 13 /*ScalarType.UINT32*/ },
            { no: 11, name: "MatchAddonCount", kind: "scalar", localName: "MatchAddonCount", jsonName: "MatchAddonCount", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "MatchReentryCount", kind: "scalar", localName: "MatchReentryCount", jsonName: "MatchReentryCount", T: 13 /*ScalarType.UINT32*/ },
            { no: 15, name: "CanMintChampion", kind: "scalar", localName: "CanMintChampion", jsonName: "CanMintChampion", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "CanMintAward", kind: "scalar", localName: "CanMintAward", jsonName: "CanMintAward", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "CanMintFinalTable", kind: "scalar", localName: "CanMintFinalTable", jsonName: "CanMintFinalTable", T: 8 /*ScalarType.BOOL*/ },
            { no: 18, name: "CanMintBubble", kind: "scalar", localName: "CanMintBubble", jsonName: "CanMintBubble", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "isAutoAward", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "PrizeType", kind: "enum", localName: "PrizeType", jsonName: "PrizeType", T: () => ["bpt.match.common.v1.PrizeType", PrizeType] },
            { no: 21, name: "DrawMatchInfo", kind: "message", localName: "DrawMatchInfo", jsonName: "DrawMatchInfo", T: () => DrawMatchConfig }
        ]);
    }
    create(value?: PartialMessage<MatchConfig>): MatchConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startTime = 0;
        message.isFixedPool = false;
        message.isPrivate = false;
        message.prizeTokens = [];
        message.approveTokens = [];
        message.fixedParticipants = 0;
        message.minParticipants = 0;
        message.maxParticipants = 0;
        message.MatchRebuyCount = 0;
        message.MatchAddonCount = 0;
        message.MatchReentryCount = 0;
        message.CanMintChampion = false;
        message.CanMintAward = false;
        message.CanMintFinalTable = false;
        message.CanMintBubble = false;
        message.isAutoAward = false;
        message.PrizeType = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchConfig): MatchConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 startTime */ 1:
                    message.startTime = reader.int64().toNumber();
                    break;
                case /* bool isFixedPool = 2 [deprecated = true];*/ 2:
                    message.isFixedPool = reader.bool();
                    break;
                case /* bool isPrivate = 3 [deprecated = true];*/ 3:
                    message.isPrivate = reader.bool();
                    break;
                case /* bpt.match.matchlink.v1.Token prizeToken = 4 [deprecated = true];*/ 4:
                    message.prizeToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.prizeToken);
                    break;
                case /* repeated bpt.match.matchlink.v1.Token prizeTokens */ 22:
                    message.prizeTokens.push(Token.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchlink.v1.Token approveTokens */ 23:
                    message.approveTokens.push(Token.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchlink.v1.Token maxTicket */ 24:
                    message.maxTicket = Token.internalBinaryRead(reader, reader.uint32(), options, message.maxTicket);
                    break;
                case /* bpt.match.matchlink.v1.Token raffleToken */ 27:
                    message.raffleToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.raffleToken);
                    break;
                case /* bpt.match.matchlink.v1.Token registerToken */ 5:
                    message.registerToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.registerToken);
                    break;
                case /* bpt.match.matchlink.v1.Token reEntryToken */ 6:
                    message.reEntryToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.reEntryToken);
                    break;
                case /* bpt.match.matchlink.v1.Token reBuyToken */ 13:
                    message.reBuyToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.reBuyToken);
                    break;
                case /* bpt.match.matchlink.v1.Token addOnToken */ 14:
                    message.addOnToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.addOnToken);
                    break;
                case /* bpt.match.matchlink.v1.Token registerTicket */ 25:
                    message.registerTicket = Token.internalBinaryRead(reader, reader.uint32(), options, message.registerTicket);
                    break;
                case /* bpt.match.matchlink.v1.Token reEntryTicket */ 26:
                    message.reEntryTicket = Token.internalBinaryRead(reader, reader.uint32(), options, message.reEntryTicket);
                    break;
                case /* uint32 fixedParticipants */ 7:
                    message.fixedParticipants = reader.uint32();
                    break;
                case /* uint32 minParticipants */ 8:
                    message.minParticipants = reader.uint32();
                    break;
                case /* uint32 maxParticipants */ 9:
                    message.maxParticipants = reader.uint32();
                    break;
                case /* uint32 MatchRebuyCount = 10 [json_name = "MatchRebuyCount"];*/ 10:
                    message.MatchRebuyCount = reader.uint32();
                    break;
                case /* uint32 MatchAddonCount = 11 [json_name = "MatchAddonCount"];*/ 11:
                    message.MatchAddonCount = reader.uint32();
                    break;
                case /* uint32 MatchReentryCount = 12 [json_name = "MatchReentryCount"];*/ 12:
                    message.MatchReentryCount = reader.uint32();
                    break;
                case /* bool CanMintChampion = 15 [json_name = "CanMintChampion"];*/ 15:
                    message.CanMintChampion = reader.bool();
                    break;
                case /* bool CanMintAward = 16 [json_name = "CanMintAward"];*/ 16:
                    message.CanMintAward = reader.bool();
                    break;
                case /* bool CanMintFinalTable = 17 [json_name = "CanMintFinalTable"];*/ 17:
                    message.CanMintFinalTable = reader.bool();
                    break;
                case /* bool CanMintBubble = 18 [json_name = "CanMintBubble"];*/ 18:
                    message.CanMintBubble = reader.bool();
                    break;
                case /* bool isAutoAward */ 19:
                    message.isAutoAward = reader.bool();
                    break;
                case /* bpt.match.common.v1.PrizeType PrizeType = 20 [json_name = "PrizeType"];*/ 20:
                    message.PrizeType = reader.int32();
                    break;
                case /* bpt.match.matchlink.v1.DrawMatchConfig DrawMatchInfo = 21 [json_name = "DrawMatchInfo"];*/ 21:
                    message.DrawMatchInfo = DrawMatchConfig.internalBinaryRead(reader, reader.uint32(), options, message.DrawMatchInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 startTime = 1; */
        if (message.startTime !== 0)
            writer.tag(1, WireType.Varint).int64(message.startTime);
        /* bool isFixedPool = 2 [deprecated = true]; */
        if (message.isFixedPool !== false)
            writer.tag(2, WireType.Varint).bool(message.isFixedPool);
        /* bool isPrivate = 3 [deprecated = true]; */
        if (message.isPrivate !== false)
            writer.tag(3, WireType.Varint).bool(message.isPrivate);
        /* bpt.match.matchlink.v1.Token prizeToken = 4 [deprecated = true]; */
        if (message.prizeToken)
            Token.internalBinaryWrite(message.prizeToken, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchlink.v1.Token prizeTokens = 22; */
        for (let i = 0; i < message.prizeTokens.length; i++)
            Token.internalBinaryWrite(message.prizeTokens[i], writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchlink.v1.Token approveTokens = 23; */
        for (let i = 0; i < message.approveTokens.length; i++)
            Token.internalBinaryWrite(message.approveTokens[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token maxTicket = 24; */
        if (message.maxTicket)
            Token.internalBinaryWrite(message.maxTicket, writer.tag(24, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token raffleToken = 27; */
        if (message.raffleToken)
            Token.internalBinaryWrite(message.raffleToken, writer.tag(27, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token registerToken = 5; */
        if (message.registerToken)
            Token.internalBinaryWrite(message.registerToken, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token reEntryToken = 6; */
        if (message.reEntryToken)
            Token.internalBinaryWrite(message.reEntryToken, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token reBuyToken = 13; */
        if (message.reBuyToken)
            Token.internalBinaryWrite(message.reBuyToken, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token addOnToken = 14; */
        if (message.addOnToken)
            Token.internalBinaryWrite(message.addOnToken, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token registerTicket = 25; */
        if (message.registerTicket)
            Token.internalBinaryWrite(message.registerTicket, writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token reEntryTicket = 26; */
        if (message.reEntryTicket)
            Token.internalBinaryWrite(message.reEntryTicket, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* uint32 fixedParticipants = 7; */
        if (message.fixedParticipants !== 0)
            writer.tag(7, WireType.Varint).uint32(message.fixedParticipants);
        /* uint32 minParticipants = 8; */
        if (message.minParticipants !== 0)
            writer.tag(8, WireType.Varint).uint32(message.minParticipants);
        /* uint32 maxParticipants = 9; */
        if (message.maxParticipants !== 0)
            writer.tag(9, WireType.Varint).uint32(message.maxParticipants);
        /* uint32 MatchRebuyCount = 10 [json_name = "MatchRebuyCount"]; */
        if (message.MatchRebuyCount !== 0)
            writer.tag(10, WireType.Varint).uint32(message.MatchRebuyCount);
        /* uint32 MatchAddonCount = 11 [json_name = "MatchAddonCount"]; */
        if (message.MatchAddonCount !== 0)
            writer.tag(11, WireType.Varint).uint32(message.MatchAddonCount);
        /* uint32 MatchReentryCount = 12 [json_name = "MatchReentryCount"]; */
        if (message.MatchReentryCount !== 0)
            writer.tag(12, WireType.Varint).uint32(message.MatchReentryCount);
        /* bool CanMintChampion = 15 [json_name = "CanMintChampion"]; */
        if (message.CanMintChampion !== false)
            writer.tag(15, WireType.Varint).bool(message.CanMintChampion);
        /* bool CanMintAward = 16 [json_name = "CanMintAward"]; */
        if (message.CanMintAward !== false)
            writer.tag(16, WireType.Varint).bool(message.CanMintAward);
        /* bool CanMintFinalTable = 17 [json_name = "CanMintFinalTable"]; */
        if (message.CanMintFinalTable !== false)
            writer.tag(17, WireType.Varint).bool(message.CanMintFinalTable);
        /* bool CanMintBubble = 18 [json_name = "CanMintBubble"]; */
        if (message.CanMintBubble !== false)
            writer.tag(18, WireType.Varint).bool(message.CanMintBubble);
        /* bool isAutoAward = 19; */
        if (message.isAutoAward !== false)
            writer.tag(19, WireType.Varint).bool(message.isAutoAward);
        /* bpt.match.common.v1.PrizeType PrizeType = 20 [json_name = "PrizeType"]; */
        if (message.PrizeType !== 0)
            writer.tag(20, WireType.Varint).int32(message.PrizeType);
        /* bpt.match.matchlink.v1.DrawMatchConfig DrawMatchInfo = 21 [json_name = "DrawMatchInfo"]; */
        if (message.DrawMatchInfo)
            DrawMatchConfig.internalBinaryWrite(message.DrawMatchInfo, writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.MatchConfig
 */
export const MatchConfig = new MatchConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DrawMatchConfig$Type extends MessageType<DrawMatchConfig> {
    constructor() {
        super("bpt.match.matchlink.v1.DrawMatchConfig", [
            { no: 1, name: "ThresholdAmount", kind: "message", localName: "ThresholdAmount", jsonName: "ThresholdAmount", T: () => Token },
            { no: 2, name: "InitialWinnerCount", kind: "scalar", localName: "InitialWinnerCount", jsonName: "InitialWinnerCount", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<DrawMatchConfig>): DrawMatchConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.InitialWinnerCount = 0;
        if (value !== undefined)
            reflectionMergePartial<DrawMatchConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DrawMatchConfig): DrawMatchConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Token ThresholdAmount = 1 [json_name = "ThresholdAmount"];*/ 1:
                    message.ThresholdAmount = Token.internalBinaryRead(reader, reader.uint32(), options, message.ThresholdAmount);
                    break;
                case /* uint32 InitialWinnerCount = 2 [json_name = "InitialWinnerCount"];*/ 2:
                    message.InitialWinnerCount = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DrawMatchConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Token ThresholdAmount = 1 [json_name = "ThresholdAmount"]; */
        if (message.ThresholdAmount)
            Token.internalBinaryWrite(message.ThresholdAmount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* uint32 InitialWinnerCount = 2 [json_name = "InitialWinnerCount"]; */
        if (message.InitialWinnerCount !== 0)
            writer.tag(2, WireType.Varint).uint32(message.InitialWinnerCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.DrawMatchConfig
 */
export const DrawMatchConfig = new DrawMatchConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchCustomConfig$Type extends MessageType<MatchCustomConfig> {
    constructor() {
        super("bpt.match.matchlink.v1.MatchCustomConfig", [
            { no: 1, name: "startTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "minParticipants", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "maxParticipants", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "registryToken", kind: "message", T: () => Token },
            { no: 5, name: "reEntryToken", kind: "message", T: () => Token },
            { no: 6, name: "reBuyToken", kind: "message", T: () => Token },
            { no: 7, name: "addOnToken", kind: "message", T: () => Token },
            { no: 8, name: "MatchRebuyCount", kind: "scalar", localName: "MatchRebuyCount", jsonName: "MatchRebuyCount", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "MatchAddonCount", kind: "scalar", localName: "MatchAddonCount", jsonName: "MatchAddonCount", T: 13 /*ScalarType.UINT32*/ },
            { no: 10, name: "MatchReentryCount", kind: "scalar", localName: "MatchReentryCount", jsonName: "MatchReentryCount", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<MatchCustomConfig>): MatchCustomConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startTime = 0;
        message.minParticipants = 0;
        message.maxParticipants = 0;
        message.MatchRebuyCount = 0;
        message.MatchAddonCount = 0;
        message.MatchReentryCount = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchCustomConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchCustomConfig): MatchCustomConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 startTime */ 1:
                    message.startTime = reader.int64().toNumber();
                    break;
                case /* uint32 minParticipants */ 2:
                    message.minParticipants = reader.uint32();
                    break;
                case /* uint32 maxParticipants */ 3:
                    message.maxParticipants = reader.uint32();
                    break;
                case /* bpt.match.matchlink.v1.Token registryToken */ 4:
                    message.registryToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.registryToken);
                    break;
                case /* bpt.match.matchlink.v1.Token reEntryToken */ 5:
                    message.reEntryToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.reEntryToken);
                    break;
                case /* bpt.match.matchlink.v1.Token reBuyToken */ 6:
                    message.reBuyToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.reBuyToken);
                    break;
                case /* bpt.match.matchlink.v1.Token addOnToken */ 7:
                    message.addOnToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.addOnToken);
                    break;
                case /* uint32 MatchRebuyCount = 8 [json_name = "MatchRebuyCount"];*/ 8:
                    message.MatchRebuyCount = reader.uint32();
                    break;
                case /* uint32 MatchAddonCount = 9 [json_name = "MatchAddonCount"];*/ 9:
                    message.MatchAddonCount = reader.uint32();
                    break;
                case /* uint32 MatchReentryCount = 10 [json_name = "MatchReentryCount"];*/ 10:
                    message.MatchReentryCount = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchCustomConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 startTime = 1; */
        if (message.startTime !== 0)
            writer.tag(1, WireType.Varint).int64(message.startTime);
        /* uint32 minParticipants = 2; */
        if (message.minParticipants !== 0)
            writer.tag(2, WireType.Varint).uint32(message.minParticipants);
        /* uint32 maxParticipants = 3; */
        if (message.maxParticipants !== 0)
            writer.tag(3, WireType.Varint).uint32(message.maxParticipants);
        /* bpt.match.matchlink.v1.Token registryToken = 4; */
        if (message.registryToken)
            Token.internalBinaryWrite(message.registryToken, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token reEntryToken = 5; */
        if (message.reEntryToken)
            Token.internalBinaryWrite(message.reEntryToken, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token reBuyToken = 6; */
        if (message.reBuyToken)
            Token.internalBinaryWrite(message.reBuyToken, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Token addOnToken = 7; */
        if (message.addOnToken)
            Token.internalBinaryWrite(message.addOnToken, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* uint32 MatchRebuyCount = 8 [json_name = "MatchRebuyCount"]; */
        if (message.MatchRebuyCount !== 0)
            writer.tag(8, WireType.Varint).uint32(message.MatchRebuyCount);
        /* uint32 MatchAddonCount = 9 [json_name = "MatchAddonCount"]; */
        if (message.MatchAddonCount !== 0)
            writer.tag(9, WireType.Varint).uint32(message.MatchAddonCount);
        /* uint32 MatchReentryCount = 10 [json_name = "MatchReentryCount"]; */
        if (message.MatchReentryCount !== 0)
            writer.tag(10, WireType.Varint).uint32(message.MatchReentryCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.MatchCustomConfig
 */
export const MatchCustomConfig = new MatchCustomConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMatchReq$Type extends MessageType<CreateMatchReq> {
    constructor() {
        super("bpt.match.matchlink.v1.CreateMatchReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "chainInfo", kind: "message", T: () => MatchChainInfo },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "matchConfig", kind: "message", T: () => MatchConfig },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "subMatchIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "subMatchConfig", kind: "map", K: 3 /*ScalarType.INT64*/, V: { kind: "message", T: () => MatchCustomConfig } }
        ]);
    }
    create(value?: PartialMessage<CreateMatchReq>): CreateMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.title = "";
        message.description = "";
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        message.subMatchIds = [];
        message.subMatchConfig = {};
        if (value !== undefined)
            reflectionMergePartial<CreateMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMatchReq): CreateMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchlink.v1.MatchChainInfo chainInfo */ 3:
                    message.chainInfo = MatchChainInfo.internalBinaryRead(reader, reader.uint32(), options, message.chainInfo);
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* string title */ 5:
                    message.title = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* bpt.match.matchlink.v1.MatchConfig matchConfig */ 12:
                    message.matchConfig = MatchConfig.internalBinaryRead(reader, reader.uint32(), options, message.matchConfig);
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                case /* repeated int64 subMatchIds */ 16:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.subMatchIds.push(reader.int64().toNumber());
                    else
                        message.subMatchIds.push(reader.int64().toNumber());
                    break;
                case /* map<int64, bpt.match.matchlink.v1.MatchCustomConfig> subMatchConfig */ 17:
                    this.binaryReadMap17(message.subMatchConfig, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap17(map: CreateMatchReq["subMatchConfig"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateMatchReq["subMatchConfig"] | undefined, val: CreateMatchReq["subMatchConfig"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int64().toString();
                    break;
                case 2:
                    val = MatchCustomConfig.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.match.matchlink.v1.CreateMatchReq.subMatchConfig");
            }
        }
        map[key ?? "0"] = val ?? MatchCustomConfig.create();
    }
    internalBinaryWrite(message: CreateMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* bpt.match.matchlink.v1.MatchChainInfo chainInfo = 3; */
        if (message.chainInfo)
            MatchChainInfo.internalBinaryWrite(message.chainInfo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* string title = 5; */
        if (message.title !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.title);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* bpt.match.matchlink.v1.MatchConfig matchConfig = 12; */
        if (message.matchConfig)
            MatchConfig.internalBinaryWrite(message.matchConfig, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        /* repeated int64 subMatchIds = 16; */
        if (message.subMatchIds.length) {
            writer.tag(16, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.subMatchIds.length; i++)
                writer.int64(message.subMatchIds[i]);
            writer.join();
        }
        /* map<int64, bpt.match.matchlink.v1.MatchCustomConfig> subMatchConfig = 17; */
        for (let k of globalThis.Object.keys(message.subMatchConfig)) {
            writer.tag(17, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            MatchCustomConfig.internalBinaryWrite(message.subMatchConfig[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.CreateMatchReq
 */
export const CreateMatchReq = new CreateMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMatchResp$Type extends MessageType<CreateMatchResp> {
    constructor() {
        super("bpt.match.matchlink.v1.CreateMatchResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<CreateMatchResp>): CreateMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMatchResp): CreateMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.CreateMatchResp
 */
export const CreateMatchResp = new CreateMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPrize$Type extends MessageType<UserPrize> {
    constructor() {
        super("bpt.match.matchlink.v1.UserPrize", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "prizeRatio", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserPrize>): UserPrize {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.prizeRatio = 0;
        message.rawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<UserPrize>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPrize): UserPrize {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint32 prizeRatio */ 5:
                    message.prizeRatio = reader.uint32();
                    break;
                case /* string rawAmount */ 6:
                    message.rawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPrize, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* uint32 prizeRatio = 5; */
        if (message.prizeRatio !== 0)
            writer.tag(5, WireType.Varint).uint32(message.prizeRatio);
        /* string rawAmount = 6; */
        if (message.rawAmount !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.rawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.UserPrize
 */
export const UserPrize = new UserPrize$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultiPrize$Type extends MessageType<MultiPrize> {
    constructor() {
        super("bpt.match.matchlink.v1.MultiPrize", [
            { no: 1, name: "userPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPrize }
        ]);
    }
    create(value?: PartialMessage<MultiPrize>): MultiPrize {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userPrizes = [];
        if (value !== undefined)
            reflectionMergePartial<MultiPrize>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultiPrize): MultiPrize {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchlink.v1.UserPrize userPrizes */ 1:
                    message.userPrizes.push(UserPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultiPrize, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchlink.v1.UserPrize userPrizes = 1; */
        for (let i = 0; i < message.userPrizes.length; i++)
            UserPrize.internalBinaryWrite(message.userPrizes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.MultiPrize
 */
export const MultiPrize = new MultiPrize$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndMatchReq$Type extends MessageType<EndMatchReq> {
    constructor() {
        super("bpt.match.matchlink.v1.EndMatchReq", [
            { no: 1, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "endTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "userPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPrize },
            { no: 7, name: "multiPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MultiPrize },
            { no: 5, name: "finalTableCount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "matchForSendPrize", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "successTs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "regNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<EndMatchReq>): EndMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchId = 0;
        message.endTime = 0;
        message.userPrizes = [];
        message.multiPrizes = [];
        message.finalTableCount = 0;
        message.uid = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        message.matchForSendPrize = false;
        message.successTs = [];
        message.regNum = 0;
        if (value !== undefined)
            reflectionMergePartial<EndMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EndMatchReq): EndMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchId */ 1:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 endTime = 2 [deprecated = true];*/ 2:
                    message.endTime = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchlink.v1.UserPrize userPrizes = 3 [deprecated = true];*/ 3:
                    message.userPrizes.push(UserPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchlink.v1.MultiPrize multiPrizes = 7 [deprecated = true];*/ 7:
                    message.multiPrizes.push(MultiPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 finalTableCount = 5 [deprecated = true];*/ 5:
                    message.finalTableCount = reader.int64().toNumber();
                    break;
                case /* int64 uid = 6 [deprecated = true];*/ 6:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                case /* bool matchForSendPrize = 16 [deprecated = true];*/ 16:
                    message.matchForSendPrize = reader.bool();
                    break;
                case /* repeated string successTs = 17 [deprecated = true];*/ 17:
                    message.successTs.push(reader.string());
                    break;
                case /* int64 regNum = 18 [deprecated = true];*/ 18:
                    message.regNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EndMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchId = 1; */
        if (message.matchId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchId);
        /* int64 endTime = 2 [deprecated = true]; */
        if (message.endTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.endTime);
        /* repeated bpt.match.matchlink.v1.UserPrize userPrizes = 3 [deprecated = true]; */
        for (let i = 0; i < message.userPrizes.length; i++)
            UserPrize.internalBinaryWrite(message.userPrizes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchlink.v1.MultiPrize multiPrizes = 7 [deprecated = true]; */
        for (let i = 0; i < message.multiPrizes.length; i++)
            MultiPrize.internalBinaryWrite(message.multiPrizes[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int64 finalTableCount = 5 [deprecated = true]; */
        if (message.finalTableCount !== 0)
            writer.tag(5, WireType.Varint).int64(message.finalTableCount);
        /* int64 uid = 6 [deprecated = true]; */
        if (message.uid !== 0)
            writer.tag(6, WireType.Varint).int64(message.uid);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        /* bool matchForSendPrize = 16 [deprecated = true]; */
        if (message.matchForSendPrize !== false)
            writer.tag(16, WireType.Varint).bool(message.matchForSendPrize);
        /* repeated string successTs = 17 [deprecated = true]; */
        for (let i = 0; i < message.successTs.length; i++)
            writer.tag(17, WireType.LengthDelimited).string(message.successTs[i]);
        /* int64 regNum = 18 [deprecated = true]; */
        if (message.regNum !== 0)
            writer.tag(18, WireType.Varint).int64(message.regNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.EndMatchReq
 */
export const EndMatchReq = new EndMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EndMatchResp$Type extends MessageType<EndMatchResp> {
    constructor() {
        super("bpt.match.matchlink.v1.EndMatchResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<EndMatchResp>): EndMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<EndMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EndMatchResp): EndMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EndMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.EndMatchResp
 */
export const EndMatchResp = new EndMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelMatchReq$Type extends MessageType<CancelMatchReq> {
    constructor() {
        super("bpt.match.matchlink.v1.CancelMatchReq", [
            { no: 1, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "cancelTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "isNotEnoughUser", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CancelMatchReq>): CancelMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchId = 0;
        message.cancelTime = 0;
        message.uid = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        message.isNotEnoughUser = false;
        if (value !== undefined)
            reflectionMergePartial<CancelMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelMatchReq): CancelMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchId */ 1:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 cancelTime = 2 [deprecated = true];*/ 2:
                    message.cancelTime = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                case /* bool isNotEnoughUser */ 16:
                    message.isNotEnoughUser = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchId = 1; */
        if (message.matchId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchId);
        /* int64 cancelTime = 2 [deprecated = true]; */
        if (message.cancelTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.cancelTime);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        /* bool isNotEnoughUser = 16; */
        if (message.isNotEnoughUser !== false)
            writer.tag(16, WireType.Varint).bool(message.isNotEnoughUser);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.CancelMatchReq
 */
export const CancelMatchReq = new CancelMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelMatchResp$Type extends MessageType<CancelMatchResp> {
    constructor() {
        super("bpt.match.matchlink.v1.CancelMatchResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<CancelMatchResp>): CancelMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CancelMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelMatchResp): CancelMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.CancelMatchResp
 */
export const CancelMatchResp = new CancelMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ForceCancelMatchReq$Type extends MessageType<ForceCancelMatchReq> {
    constructor() {
        super("bpt.match.matchlink.v1.ForceCancelMatchReq", [
            { no: 1, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "cancelTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ForceCancelMatchReq>): ForceCancelMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchId = 0;
        message.cancelTime = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<ForceCancelMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ForceCancelMatchReq): ForceCancelMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchId */ 1:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 cancelTime = 2 [deprecated = true];*/ 2:
                    message.cancelTime = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ForceCancelMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchId = 1; */
        if (message.matchId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchId);
        /* int64 cancelTime = 2 [deprecated = true]; */
        if (message.cancelTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.cancelTime);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ForceCancelMatchReq
 */
export const ForceCancelMatchReq = new ForceCancelMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ForceCancelMatchResp$Type extends MessageType<ForceCancelMatchResp> {
    constructor() {
        super("bpt.match.matchlink.v1.ForceCancelMatchResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<ForceCancelMatchResp>): ForceCancelMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ForceCancelMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ForceCancelMatchResp): ForceCancelMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ForceCancelMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ForceCancelMatchResp
 */
export const ForceCancelMatchResp = new ForceCancelMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterAutoReq$Type extends MessageType<RegisterAutoReq> {
    constructor() {
        super("bpt.match.matchlink.v1.RegisterAutoReq", [
            { no: 1, name: "uidArr", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "isMultiDays", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "isDelayRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback }
        ]);
    }
    create(value?: PartialMessage<RegisterAutoReq>): RegisterAutoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uidArr = [];
        message.matchId = 0;
        message.matchKey = "";
        message.isMultiDays = false;
        message.isDelayRegister = false;
        message.deadline = 0;
        if (value !== undefined)
            reflectionMergePartial<RegisterAutoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterAutoReq): RegisterAutoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uidArr */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uidArr.push(reader.int64().toNumber());
                    else
                        message.uidArr.push(reader.int64().toNumber());
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 5:
                    message.matchKey = reader.string();
                    break;
                case /* bool isMultiDays = 15 [deprecated = true];*/ 15:
                    message.isMultiDays = reader.bool();
                    break;
                case /* bool isDelayRegister = 16 [deprecated = true];*/ 16:
                    message.isDelayRegister = reader.bool();
                    break;
                case /* int64 deadline = 17 [deprecated = true];*/ 17:
                    message.deadline = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterAutoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uidArr = 1; */
        if (message.uidArr.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uidArr.length; i++)
                writer.int64(message.uidArr[i]);
            writer.join();
        }
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* string matchKey = 5; */
        if (message.matchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchKey);
        /* bool isMultiDays = 15 [deprecated = true]; */
        if (message.isMultiDays !== false)
            writer.tag(15, WireType.Varint).bool(message.isMultiDays);
        /* bool isDelayRegister = 16 [deprecated = true]; */
        if (message.isDelayRegister !== false)
            writer.tag(16, WireType.Varint).bool(message.isDelayRegister);
        /* int64 deadline = 17 [deprecated = true]; */
        if (message.deadline !== 0)
            writer.tag(17, WireType.Varint).int64(message.deadline);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.RegisterAutoReq
 */
export const RegisterAutoReq = new RegisterAutoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterAutoResp$Type extends MessageType<RegisterAutoResp> {
    constructor() {
        super("bpt.match.matchlink.v1.RegisterAutoResp", []);
    }
    create(value?: PartialMessage<RegisterAutoResp>): RegisterAutoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RegisterAutoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterAutoResp): RegisterAutoResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RegisterAutoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.RegisterAutoResp
 */
export const RegisterAutoResp = new RegisterAutoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterReq$Type extends MessageType<RegisterReq> {
    constructor() {
        super("bpt.match.matchlink.v1.RegisterReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "signature", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token }
        ]);
    }
    create(value?: PartialMessage<RegisterReq>): RegisterReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.signature = new Uint8Array(0);
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        message.tokens = [];
        if (value !== undefined)
            reflectionMergePartial<RegisterReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterReq): RegisterReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* bytes signature = 5 [deprecated = true];*/ 5:
                    message.signature = reader.bytes();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchlink.v1.Token tokens */ 17:
                    message.tokens.push(Token.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* bytes signature = 5 [deprecated = true]; */
        if (message.signature.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.signature);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        /* repeated bpt.match.matchlink.v1.Token tokens = 17; */
        for (let i = 0; i < message.tokens.length; i++)
            Token.internalBinaryWrite(message.tokens[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.RegisterReq
 */
export const RegisterReq = new RegisterReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterResp$Type extends MessageType<RegisterResp> {
    constructor() {
        super("bpt.match.matchlink.v1.RegisterResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<RegisterResp>): RegisterResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RegisterResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterResp): RegisterResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.RegisterResp
 */
export const RegisterResp = new RegisterResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelayRegisterReq$Type extends MessageType<DelayRegisterReq> {
    constructor() {
        super("bpt.match.matchlink.v1.DelayRegisterReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "signature", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token }
        ]);
    }
    create(value?: PartialMessage<DelayRegisterReq>): DelayRegisterReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.signature = new Uint8Array(0);
        message.deadline = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        message.tokens = [];
        if (value !== undefined)
            reflectionMergePartial<DelayRegisterReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelayRegisterReq): DelayRegisterReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* bytes signature = 5 [deprecated = true];*/ 5:
                    message.signature = reader.bytes();
                    break;
                case /* int64 deadline = 6 [deprecated = true];*/ 6:
                    message.deadline = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchlink.v1.Token tokens */ 17:
                    message.tokens.push(Token.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelayRegisterReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* bytes signature = 5 [deprecated = true]; */
        if (message.signature.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.signature);
        /* int64 deadline = 6 [deprecated = true]; */
        if (message.deadline !== 0)
            writer.tag(6, WireType.Varint).int64(message.deadline);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        /* repeated bpt.match.matchlink.v1.Token tokens = 17; */
        for (let i = 0; i < message.tokens.length; i++)
            Token.internalBinaryWrite(message.tokens[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.DelayRegisterReq
 */
export const DelayRegisterReq = new DelayRegisterReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DelayRegisterResp$Type extends MessageType<DelayRegisterResp> {
    constructor() {
        super("bpt.match.matchlink.v1.DelayRegisterResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<DelayRegisterResp>): DelayRegisterResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DelayRegisterResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DelayRegisterResp): DelayRegisterResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DelayRegisterResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.DelayRegisterResp
 */
export const DelayRegisterResp = new DelayRegisterResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReEntryReq$Type extends MessageType<ReEntryReq> {
    constructor() {
        super("bpt.match.matchlink.v1.ReEntryReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "tokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Token }
        ]);
    }
    create(value?: PartialMessage<ReEntryReq>): ReEntryReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.deadline = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        message.tokens = [];
        if (value !== undefined)
            reflectionMergePartial<ReEntryReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReEntryReq): ReEntryReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 deadline = 6 [deprecated = true];*/ 6:
                    message.deadline = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchlink.v1.Token tokens */ 17:
                    message.tokens.push(Token.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReEntryReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* int64 deadline = 6 [deprecated = true]; */
        if (message.deadline !== 0)
            writer.tag(6, WireType.Varint).int64(message.deadline);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        /* repeated bpt.match.matchlink.v1.Token tokens = 17; */
        for (let i = 0; i < message.tokens.length; i++)
            Token.internalBinaryWrite(message.tokens[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ReEntryReq
 */
export const ReEntryReq = new ReEntryReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReEntryResp$Type extends MessageType<ReEntryResp> {
    constructor() {
        super("bpt.match.matchlink.v1.ReEntryResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<ReEntryResp>): ReEntryResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReEntryResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReEntryResp): ReEntryResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReEntryResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ReEntryResp
 */
export const ReEntryResp = new ReEntryResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReEntryAutoReq$Type extends MessageType<ReEntryAutoReq> {
    constructor() {
        super("bpt.match.matchlink.v1.ReEntryAutoReq", [
            { no: 1, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "uidArr", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ReEntryAutoReq>): ReEntryAutoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchId = 0;
        message.uidArr = [];
        if (value !== undefined)
            reflectionMergePartial<ReEntryAutoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReEntryAutoReq): ReEntryAutoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchId */ 1:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* repeated int64 uidArr */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uidArr.push(reader.int64().toNumber());
                    else
                        message.uidArr.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReEntryAutoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchId = 1; */
        if (message.matchId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchId);
        /* repeated int64 uidArr = 2; */
        if (message.uidArr.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uidArr.length; i++)
                writer.int64(message.uidArr[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ReEntryAutoReq
 */
export const ReEntryAutoReq = new ReEntryAutoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReEntryAutoResp$Type extends MessageType<ReEntryAutoResp> {
    constructor() {
        super("bpt.match.matchlink.v1.ReEntryAutoResp", []);
    }
    create(value?: PartialMessage<ReEntryAutoResp>): ReEntryAutoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReEntryAutoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReEntryAutoResp): ReEntryAutoResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReEntryAutoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ReEntryAutoResp
 */
export const ReEntryAutoResp = new ReEntryAutoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReBuyReq$Type extends MessageType<ReBuyReq> {
    constructor() {
        super("bpt.match.matchlink.v1.ReBuyReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ReBuyReq>): ReBuyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.deadline = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<ReBuyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReBuyReq): ReBuyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 deadline = 6 [deprecated = true];*/ 6:
                    message.deadline = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReBuyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* int64 deadline = 6 [deprecated = true]; */
        if (message.deadline !== 0)
            writer.tag(6, WireType.Varint).int64(message.deadline);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ReBuyReq
 */
export const ReBuyReq = new ReBuyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReBuyResp$Type extends MessageType<ReBuyResp> {
    constructor() {
        super("bpt.match.matchlink.v1.ReBuyResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<ReBuyResp>): ReBuyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReBuyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReBuyResp): ReBuyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReBuyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ReBuyResp
 */
export const ReBuyResp = new ReBuyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOnReq$Type extends MessageType<AddOnReq> {
    constructor() {
        super("bpt.match.matchlink.v1.AddOnReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AddOnReq>): AddOnReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.deadline = 0;
        message.count = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<AddOnReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOnReq): AddOnReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 deadline = 6 [deprecated = true];*/ 6:
                    message.deadline = reader.int64().toNumber();
                    break;
                case /* int64 count */ 7:
                    message.count = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOnReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* int64 deadline = 6 [deprecated = true]; */
        if (message.deadline !== 0)
            writer.tag(6, WireType.Varint).int64(message.deadline);
        /* int64 count = 7; */
        if (message.count !== 0)
            writer.tag(7, WireType.Varint).int64(message.count);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.AddOnReq
 */
export const AddOnReq = new AddOnReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddOnResp$Type extends MessageType<AddOnResp> {
    constructor() {
        super("bpt.match.matchlink.v1.AddOnResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<AddOnResp>): AddOnResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AddOnResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddOnResp): AddOnResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddOnResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.AddOnResp
 */
export const AddOnResp = new AddOnResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrizeInfo$Type extends MessageType<PrizeInfo> {
    constructor() {
        super("bpt.match.matchlink.v1.PrizeInfo", [
            { no: 1, name: "tokenType", kind: "enum", T: () => ["bpt.common.TokenType", TokenType] },
            { no: 2, name: "tokenAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tokenAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PrizeInfo>): PrizeInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenType = 0;
        message.tokenAddress = "";
        message.tokenAmount = "";
        if (value !== undefined)
            reflectionMergePartial<PrizeInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrizeInfo): PrizeInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.TokenType tokenType */ 1:
                    message.tokenType = reader.int32();
                    break;
                case /* string tokenAddress */ 2:
                    message.tokenAddress = reader.string();
                    break;
                case /* string tokenAmount */ 3:
                    message.tokenAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrizeInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.TokenType tokenType = 1; */
        if (message.tokenType !== 0)
            writer.tag(1, WireType.Varint).int32(message.tokenType);
        /* string tokenAddress = 2; */
        if (message.tokenAddress !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tokenAddress);
        /* string tokenAmount = 3; */
        if (message.tokenAmount !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tokenAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.PrizeInfo
 */
export const PrizeInfo = new PrizeInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AwardPrizeInfo$Type extends MessageType<AwardPrizeInfo> {
    constructor() {
        super("bpt.match.matchlink.v1.AwardPrizeInfo", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrizeInfo }
        ]);
    }
    create(value?: PartialMessage<AwardPrizeInfo>): AwardPrizeInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.prizes = [];
        if (value !== undefined)
            reflectionMergePartial<AwardPrizeInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AwardPrizeInfo): AwardPrizeInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchlink.v1.PrizeInfo prizes */ 2:
                    message.prizes.push(PrizeInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AwardPrizeInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.match.matchlink.v1.PrizeInfo prizes = 2; */
        for (let i = 0; i < message.prizes.length; i++)
            PrizeInfo.internalBinaryWrite(message.prizes[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.AwardPrizeInfo
 */
export const AwardPrizeInfo = new AwardPrizeInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmPrizeReq$Type extends MessageType<ConfirmPrizeReq> {
    constructor() {
        super("bpt.match.matchlink.v1.ConfirmPrizeReq", [
            { no: 1, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "awardees", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AwardPrizeInfo },
            { no: 3, name: "callback", kind: "message", T: () => Callback }
        ]);
    }
    create(value?: PartialMessage<ConfirmPrizeReq>): ConfirmPrizeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchId = 0;
        message.awardees = [];
        if (value !== undefined)
            reflectionMergePartial<ConfirmPrizeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfirmPrizeReq): ConfirmPrizeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchId */ 1:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchlink.v1.AwardPrizeInfo awardees */ 2:
                    message.awardees.push(AwardPrizeInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 3 [deprecated = true];*/ 3:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfirmPrizeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchId = 1; */
        if (message.matchId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchId);
        /* repeated bpt.match.matchlink.v1.AwardPrizeInfo awardees = 2; */
        for (let i = 0; i < message.awardees.length; i++)
            AwardPrizeInfo.internalBinaryWrite(message.awardees[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Callback callback = 3 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ConfirmPrizeReq
 */
export const ConfirmPrizeReq = new ConfirmPrizeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ConfirmPrizeResp$Type extends MessageType<ConfirmPrizeResp> {
    constructor() {
        super("bpt.match.matchlink.v1.ConfirmPrizeResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<ConfirmPrizeResp>): ConfirmPrizeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ConfirmPrizeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ConfirmPrizeResp): ConfirmPrizeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ConfirmPrizeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ConfirmPrizeResp
 */
export const ConfirmPrizeResp = new ConfirmPrizeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendPrizeReq$Type extends MessageType<SendPrizeReq> {
    constructor() {
        super("bpt.match.matchlink.v1.SendPrizeReq", [
            { no: 1, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "awardees", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AwardPrizeInfo },
            { no: 3, name: "callback", kind: "message", T: () => Callback }
        ]);
    }
    create(value?: PartialMessage<SendPrizeReq>): SendPrizeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchId = 0;
        message.awardees = [];
        if (value !== undefined)
            reflectionMergePartial<SendPrizeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendPrizeReq): SendPrizeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchId */ 1:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchlink.v1.AwardPrizeInfo awardees */ 2:
                    message.awardees.push(AwardPrizeInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 3 [deprecated = true];*/ 3:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendPrizeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchId = 1; */
        if (message.matchId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchId);
        /* repeated bpt.match.matchlink.v1.AwardPrizeInfo awardees = 2; */
        for (let i = 0; i < message.awardees.length; i++)
            AwardPrizeInfo.internalBinaryWrite(message.awardees[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchlink.v1.Callback callback = 3 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.SendPrizeReq
 */
export const SendPrizeReq = new SendPrizeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendPrizeResp$Type extends MessageType<SendPrizeResp> {
    constructor() {
        super("bpt.match.matchlink.v1.SendPrizeResp", []);
    }
    create(value?: PartialMessage<SendPrizeResp>): SendPrizeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SendPrizeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendPrizeResp): SendPrizeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SendPrizeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.SendPrizeResp
 */
export const SendPrizeResp = new SendPrizeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchWithdrawReq$Type extends MessageType<MatchWithdrawReq> {
    constructor() {
        super("bpt.match.matchlink.v1.MatchWithdrawReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "deadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "type", kind: "enum", T: () => ["bpt.match.matchlink.v1.MatchWithdrawType", MatchWithdrawType] },
            { no: 8, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MatchWithdrawReq>): MatchWithdrawReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.deadline = 0;
        message.type = 0;
        message.transactionId = "";
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchWithdrawReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchWithdrawReq): MatchWithdrawReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 deadline = 6 [deprecated = true];*/ 6:
                    message.deadline = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchlink.v1.MatchWithdrawType type */ 7:
                    message.type = reader.int32();
                    break;
                case /* string transactionId */ 8:
                    message.transactionId = reader.string();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchWithdrawReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* int64 deadline = 6 [deprecated = true]; */
        if (message.deadline !== 0)
            writer.tag(6, WireType.Varint).int64(message.deadline);
        /* bpt.match.matchlink.v1.MatchWithdrawType type = 7; */
        if (message.type !== 0)
            writer.tag(7, WireType.Varint).int32(message.type);
        /* string transactionId = 8; */
        if (message.transactionId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.transactionId);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.MatchWithdrawReq
 */
export const MatchWithdrawReq = new MatchWithdrawReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchWithdrawResp$Type extends MessageType<MatchWithdrawResp> {
    constructor() {
        super("bpt.match.matchlink.v1.MatchWithdrawResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<MatchWithdrawResp>): MatchWithdrawResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MatchWithdrawResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchWithdrawResp): MatchWithdrawResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchWithdrawResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.MatchWithdrawResp
 */
export const MatchWithdrawResp = new MatchWithdrawResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchWithdrawReq$Type extends MessageType<BatchWithdrawReq> {
    constructor() {
        super("bpt.match.matchlink.v1.BatchWithdrawReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "transactionIDs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "callback", kind: "message", T: () => Callback }
        ]);
    }
    create(value?: PartialMessage<BatchWithdrawReq>): BatchWithdrawReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.transactionIDs = [];
        message.matchId = 0;
        if (value !== undefined)
            reflectionMergePartial<BatchWithdrawReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchWithdrawReq): BatchWithdrawReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* repeated string transactionIDs = 2 [deprecated = true];*/ 2:
                    message.transactionIDs.push(reader.string());
                    break;
                case /* int64 matchId */ 3:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 4 [deprecated = true];*/ 4:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchWithdrawReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* repeated string transactionIDs = 2 [deprecated = true]; */
        for (let i = 0; i < message.transactionIDs.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.transactionIDs[i]);
        /* int64 matchId = 3; */
        if (message.matchId !== 0)
            writer.tag(3, WireType.Varint).int64(message.matchId);
        /* bpt.match.matchlink.v1.Callback callback = 4 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.BatchWithdrawReq
 */
export const BatchWithdrawReq = new BatchWithdrawReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchWithdrawResp$Type extends MessageType<BatchWithdrawResp> {
    constructor() {
        super("bpt.match.matchlink.v1.BatchWithdrawResp", []);
    }
    create(value?: PartialMessage<BatchWithdrawResp>): BatchWithdrawResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<BatchWithdrawResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchWithdrawResp): BatchWithdrawResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BatchWithdrawResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.BatchWithdrawResp
 */
export const BatchWithdrawResp = new BatchWithdrawResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveMatchReq$Type extends MessageType<LeaveMatchReq> {
    constructor() {
        super("bpt.match.matchlink.v1.LeaveMatchReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "leaveTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<LeaveMatchReq>): LeaveMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.leaveTime = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<LeaveMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LeaveMatchReq): LeaveMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 leaveTime = 5 [deprecated = true];*/ 5:
                    message.leaveTime = reader.int64().toNumber();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true];*/ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL = 15 [deprecated = true];*/ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LeaveMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* int64 leaveTime = 5 [deprecated = true]; */
        if (message.leaveTime !== 0)
            writer.tag(5, WireType.Varint).int64(message.leaveTime);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14 [deprecated = true]; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15 [deprecated = true]; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.LeaveMatchReq
 */
export const LeaveMatchReq = new LeaveMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LeaveMatchResp$Type extends MessageType<LeaveMatchResp> {
    constructor() {
        super("bpt.match.matchlink.v1.LeaveMatchResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<LeaveMatchResp>): LeaveMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<LeaveMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LeaveMatchResp): LeaveMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LeaveMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.LeaveMatchResp
 */
export const LeaveMatchResp = new LeaveMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimPrizeReq$Type extends MessageType<ClaimPrizeReq> {
    constructor() {
        super("bpt.match.matchlink.v1.ClaimPrizeReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 13, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 14, name: "callback", kind: "message", T: () => Callback },
            { no: 15, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ClaimPrizeReq>): ClaimPrizeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.rank = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<ClaimPrizeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimPrizeReq): ClaimPrizeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int32 rank */ 3:
                    message.rank = reader.int32();
                    break;
                case /* bytes extra */ 13:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback */ 14:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL */ 15:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimPrizeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* int32 rank = 3; */
        if (message.rank !== 0)
            writer.tag(3, WireType.Varint).int32(message.rank);
        /* bytes extra = 13; */
        if (message.extra.length)
            writer.tag(13, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 14; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 15; */
        if (message.credentailTTL !== 0)
            writer.tag(15, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ClaimPrizeReq
 */
export const ClaimPrizeReq = new ClaimPrizeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimPrizeResp$Type extends MessageType<ClaimPrizeResp> {
    constructor() {
        super("bpt.match.matchlink.v1.ClaimPrizeResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<ClaimPrizeResp>): ClaimPrizeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ClaimPrizeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimPrizeResp): ClaimPrizeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimPrizeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.ClaimPrizeResp
 */
export const ClaimPrizeResp = new ClaimPrizeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchCashConfig$Type extends MessageType<MatchCashConfig> {
    constructor() {
        super("bpt.match.matchlink.v1.MatchCashConfig", [
            { no: 1, name: "cashRate", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "minAmount", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "maxAmount", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "cashToken", kind: "message", T: () => Token }
        ]);
    }
    create(value?: PartialMessage<MatchCashConfig>): MatchCashConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cashRate = 0;
        message.minAmount = 0;
        message.maxAmount = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchCashConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchCashConfig): MatchCashConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 cashRate */ 1:
                    message.cashRate = reader.uint64().toNumber();
                    break;
                case /* uint64 minAmount */ 2:
                    message.minAmount = reader.uint64().toNumber();
                    break;
                case /* uint64 maxAmount */ 3:
                    message.maxAmount = reader.uint64().toNumber();
                    break;
                case /* bpt.match.matchlink.v1.Token cashToken */ 5:
                    message.cashToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.cashToken);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchCashConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 cashRate = 1; */
        if (message.cashRate !== 0)
            writer.tag(1, WireType.Varint).uint64(message.cashRate);
        /* uint64 minAmount = 2; */
        if (message.minAmount !== 0)
            writer.tag(2, WireType.Varint).uint64(message.minAmount);
        /* uint64 maxAmount = 3; */
        if (message.maxAmount !== 0)
            writer.tag(3, WireType.Varint).uint64(message.maxAmount);
        /* bpt.match.matchlink.v1.Token cashToken = 5; */
        if (message.cashToken)
            Token.internalBinaryWrite(message.cashToken, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.MatchCashConfig
 */
export const MatchCashConfig = new MatchCashConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCashMatchReq$Type extends MessageType<CreateCashMatchReq> {
    constructor() {
        super("bpt.match.matchlink.v1.CreateCashMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "chainInfo", kind: "message", T: () => MatchChainInfo },
            { no: 3, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "matchConfig", kind: "message", T: () => MatchCashConfig },
            { no: 7, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 8, name: "callback", kind: "message", T: () => Callback },
            { no: 9, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CreateCashMatchReq>): CreateCashMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.title = "";
        message.description = "";
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateCashMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCashMatchReq): CreateCashMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchlink.v1.MatchChainInfo chainInfo */ 2:
                    message.chainInfo = MatchChainInfo.internalBinaryRead(reader, reader.uint32(), options, message.chainInfo);
                    break;
                case /* int64 matchId */ 3:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* bpt.match.matchlink.v1.MatchCashConfig matchConfig */ 6:
                    message.matchConfig = MatchCashConfig.internalBinaryRead(reader, reader.uint32(), options, message.matchConfig);
                    break;
                case /* bytes extra */ 7:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback */ 8:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL */ 9:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCashMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.match.matchlink.v1.MatchChainInfo chainInfo = 2; */
        if (message.chainInfo)
            MatchChainInfo.internalBinaryWrite(message.chainInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 matchId = 3; */
        if (message.matchId !== 0)
            writer.tag(3, WireType.Varint).int64(message.matchId);
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* bpt.match.matchlink.v1.MatchCashConfig matchConfig = 6; */
        if (message.matchConfig)
            MatchCashConfig.internalBinaryWrite(message.matchConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bytes extra = 7; */
        if (message.extra.length)
            writer.tag(7, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 8; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 9; */
        if (message.credentailTTL !== 0)
            writer.tag(9, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.CreateCashMatchReq
 */
export const CreateCashMatchReq = new CreateCashMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCashMatchResp$Type extends MessageType<CreateCashMatchResp> {
    constructor() {
        super("bpt.match.matchlink.v1.CreateCashMatchResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<CreateCashMatchResp>): CreateCashMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CreateCashMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCashMatchResp): CreateCashMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCashMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.CreateCashMatchResp
 */
export const CreateCashMatchResp = new CreateCashMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuyCashReq$Type extends MessageType<BuyCashReq> {
    constructor() {
        super("bpt.match.matchlink.v1.BuyCashReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "signature", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "chipAmount", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "callback", kind: "message", T: () => Callback },
            { no: 7, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BuyCashReq>): BuyCashReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.signature = new Uint8Array(0);
        message.chipAmount = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        if (value !== undefined)
            reflectionMergePartial<BuyCashReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuyCashReq): BuyCashReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 2:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* bytes signature */ 3:
                    message.signature = reader.bytes();
                    break;
                case /* uint64 chipAmount */ 4:
                    message.chipAmount = reader.uint64().toNumber();
                    break;
                case /* bytes extra */ 5:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback */ 6:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL */ 7:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuyCashReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 matchId = 2; */
        if (message.matchId !== 0)
            writer.tag(2, WireType.Varint).int64(message.matchId);
        /* bytes signature = 3; */
        if (message.signature.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.signature);
        /* uint64 chipAmount = 4; */
        if (message.chipAmount !== 0)
            writer.tag(4, WireType.Varint).uint64(message.chipAmount);
        /* bytes extra = 5; */
        if (message.extra.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 6; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 7; */
        if (message.credentailTTL !== 0)
            writer.tag(7, WireType.Varint).int64(message.credentailTTL);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.BuyCashReq
 */
export const BuyCashReq = new BuyCashReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuyCashResp$Type extends MessageType<BuyCashResp> {
    constructor() {
        super("bpt.match.matchlink.v1.BuyCashResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<BuyCashResp>): BuyCashResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<BuyCashResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuyCashResp): BuyCashResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuyCashResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.BuyCashResp
 */
export const BuyCashResp = new BuyCashResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WithDrawCashReq$Type extends MessageType<WithDrawCashReq> {
    constructor() {
        super("bpt.match.matchlink.v1.WithDrawCashReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "signature", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "chipAmount", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "extra", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "callback", kind: "message", T: () => Callback },
            { no: 7, name: "credentailTTL", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "timestamp", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<WithDrawCashReq>): WithDrawCashReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchId = 0;
        message.signature = new Uint8Array(0);
        message.chipAmount = 0;
        message.extra = new Uint8Array(0);
        message.credentailTTL = 0;
        message.timestamp = 0;
        if (value !== undefined)
            reflectionMergePartial<WithDrawCashReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WithDrawCashReq): WithDrawCashReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 matchId */ 2:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* bytes signature */ 3:
                    message.signature = reader.bytes();
                    break;
                case /* uint64 chipAmount */ 4:
                    message.chipAmount = reader.uint64().toNumber();
                    break;
                case /* bytes extra */ 5:
                    message.extra = reader.bytes();
                    break;
                case /* bpt.match.matchlink.v1.Callback callback */ 6:
                    message.callback = Callback.internalBinaryRead(reader, reader.uint32(), options, message.callback);
                    break;
                case /* int64 credentailTTL */ 7:
                    message.credentailTTL = reader.int64().toNumber();
                    break;
                case /* int64 timestamp */ 8:
                    message.timestamp = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WithDrawCashReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 matchId = 2; */
        if (message.matchId !== 0)
            writer.tag(2, WireType.Varint).int64(message.matchId);
        /* bytes signature = 3; */
        if (message.signature.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.signature);
        /* uint64 chipAmount = 4; */
        if (message.chipAmount !== 0)
            writer.tag(4, WireType.Varint).uint64(message.chipAmount);
        /* bytes extra = 5; */
        if (message.extra.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.extra);
        /* bpt.match.matchlink.v1.Callback callback = 6; */
        if (message.callback)
            Callback.internalBinaryWrite(message.callback, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int64 credentailTTL = 7; */
        if (message.credentailTTL !== 0)
            writer.tag(7, WireType.Varint).int64(message.credentailTTL);
        /* int64 timestamp = 8; */
        if (message.timestamp !== 0)
            writer.tag(8, WireType.Varint).int64(message.timestamp);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.WithDrawCashReq
 */
export const WithDrawCashReq = new WithDrawCashReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WithDrawCashResp$Type extends MessageType<WithDrawCashResp> {
    constructor() {
        super("bpt.match.matchlink.v1.WithDrawCashResp", [
            { no: 1, name: "transaction", kind: "message", T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<WithDrawCashResp>): WithDrawCashResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<WithDrawCashResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WithDrawCashResp): WithDrawCashResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction = Transaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WithDrawCashResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchlink.v1.Transaction transaction = 1; */
        if (message.transaction)
            Transaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.WithDrawCashResp
 */
export const WithDrawCashResp = new WithDrawCashResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionReq$Type extends MessageType<GetTransactionReq> {
    constructor() {
        super("bpt.match.matchlink.v1.GetTransactionReq", [
            { no: 1, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "userId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetTransactionReq>): GetTransactionReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchId = 0;
        message.userId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetTransactionReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionReq): GetTransactionReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchId */ 1:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* int64 userId */ 2:
                    message.userId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchId = 1; */
        if (message.matchId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchId);
        /* int64 userId = 2; */
        if (message.userId !== 0)
            writer.tag(2, WireType.Varint).int64(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.GetTransactionReq
 */
export const GetTransactionReq = new GetTransactionReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionResp$Type extends MessageType<GetTransactionResp> {
    constructor() {
        super("bpt.match.matchlink.v1.GetTransactionResp", [
            { no: 1, name: "transaction", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Transaction }
        ]);
    }
    create(value?: PartialMessage<GetTransactionResp>): GetTransactionResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.transaction = [];
        if (value !== undefined)
            reflectionMergePartial<GetTransactionResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionResp): GetTransactionResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchlink.v1.Transaction transaction */ 1:
                    message.transaction.push(Transaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchlink.v1.Transaction transaction = 1; */
        for (let i = 0; i < message.transaction.length; i++)
            Transaction.internalBinaryWrite(message.transaction[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.GetTransactionResp
 */
export const GetTransactionResp = new GetTransactionResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTransactionStatusReq$Type extends MessageType<SetTransactionStatusReq> {
    constructor() {
        super("bpt.match.matchlink.v1.SetTransactionStatusReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "enum", T: () => ["bpt.match.matchlink.v1.TransactionStatus", TransactionStatus] }
        ]);
    }
    create(value?: PartialMessage<SetTransactionStatusReq>): SetTransactionStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.status = 0;
        if (value !== undefined)
            reflectionMergePartial<SetTransactionStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetTransactionStatusReq): SetTransactionStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bpt.match.matchlink.v1.TransactionStatus status */ 2:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetTransactionStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bpt.match.matchlink.v1.TransactionStatus status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.SetTransactionStatusReq
 */
export const SetTransactionStatusReq = new SetTransactionStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetTransactionStatusResp$Type extends MessageType<SetTransactionStatusResp> {
    constructor() {
        super("bpt.match.matchlink.v1.SetTransactionStatusResp", []);
    }
    create(value?: PartialMessage<SetTransactionStatusResp>): SetTransactionStatusResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SetTransactionStatusResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetTransactionStatusResp): SetTransactionStatusResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetTransactionStatusResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchlink.v1.SetTransactionStatusResp
 */
export const SetTransactionStatusResp = new SetTransactionStatusResp$Type();
/**
 * @generated ServiceType for protobuf service bpt.match.matchlink.v1.matchlinkService
 */
export const matchlinkService = new ServiceType("bpt.match.matchlink.v1.matchlinkService", [
    { name: "CreateMatch", options: { "blocker.exportclient": 0 }, I: CreateMatchReq, O: CreateMatchResp },
    { name: "EndMatch", options: { "blocker.exportclient": 0 }, I: EndMatchReq, O: EndMatchResp },
    { name: "CancelMatch", options: { "blocker.exportclient": 0 }, I: CancelMatchReq, O: CancelMatchResp },
    { name: "ForceCancelMatch", options: { "blocker.exportclient": 0 }, I: ForceCancelMatchReq, O: ForceCancelMatchResp },
    { name: "Register", options: { "blocker.exportclient": 0 }, I: RegisterReq, O: RegisterResp },
    { name: "DelayRegister", options: { "blocker.exportclient": 0 }, I: DelayRegisterReq, O: DelayRegisterResp },
    { name: "RegisterAuto", options: { "blocker.exportclient": 0 }, I: RegisterAutoReq, O: RegisterAutoResp },
    { name: "ReEntry", options: { "blocker.exportclient": 0 }, I: ReEntryReq, O: ReEntryResp },
    { name: "ReEntryAuto", options: { "blocker.exportclient": 0 }, I: ReEntryAutoReq, O: ReEntryAutoResp },
    { name: "ReBuy", options: { "blocker.exportclient": 0 }, I: ReBuyReq, O: ReBuyResp },
    { name: "AddOn", options: { "blocker.exportclient": 0 }, I: AddOnReq, O: AddOnResp },
    { name: "ConfirmPrize", options: { "blocker.exportclient": 0 }, I: ConfirmPrizeReq, O: ConfirmPrizeResp },
    { name: "SendPrize", options: { "blocker.exportclient": 0 }, I: SendPrizeReq, O: SendPrizeResp },
    { name: "MatchWithdraw", options: { "blocker.exportclient": 0 }, I: MatchWithdrawReq, O: MatchWithdrawResp },
    { name: "BatchWithdraw", options: { "blocker.exportclient": 0 }, I: BatchWithdrawReq, O: BatchWithdrawResp },
    { name: "LeaveMatch", options: { "blocker.exportclient": 0 }, I: LeaveMatchReq, O: LeaveMatchResp },
    { name: "ClaimPrize", options: { "blocker.exportclient": 0 }, I: ClaimPrizeReq, O: ClaimPrizeResp },
    { name: "CreateCashMatch", options: { "blocker.exportclient": 0 }, I: CreateCashMatchReq, O: CreateCashMatchResp },
    { name: "BuyCash", options: { "blocker.exportclient": 0 }, I: BuyCashReq, O: BuyCashResp },
    { name: "WithdrawCash", options: { "blocker.exportclient": 0 }, I: WithDrawCashReq, O: WithDrawCashResp },
    { name: "GetTransaction", options: {}, I: GetTransactionReq, O: GetTransactionResp },
    { name: "SetTransactionStatus", options: {}, I: SetTransactionStatusReq, O: SetTransactionStatusResp }
]);
