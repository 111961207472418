// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/prop/v1/prop.proto" (package "bpt.hall.prop.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { BatchGetResourceResp } from "../../../base/base";
import { BatchGetResourceReq } from "../../../base/base";
import { FillAssetsResp } from "../../common/assets";
import { FillAssetsReq } from "../../common/assets";
import { DecUserAssetsResp } from "../../common/assets";
import { DecUserAssetsReq } from "../../common/assets";
import { IncUserAssetsResp } from "../../common/assets";
import { IncUserAssetsReq } from "../../common/assets";
import { Void } from "../../../base/base";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TxOnChainDetail } from "../../../common/common_web3";
import { GrantType as GrantType$ } from "../../../common/common_define";
import { TxOnChainStatus } from "../../../common/common_web3";
import { TxOnChainType } from "../../../common/common_web3";
import { UserRobotFlag } from "../../../common/common_define";
import { Sign } from "../../../common/common_define";
import { ErrorCode } from "../../../common/code/code";
import { DynamicAssetItems } from "../../common/assets";
import { Way } from "../../../common/common_define";
import { ExpireData } from "../../../common/common_define";
import { ChainID } from "../../../../gaw/base/base";
import { Unit } from "../../../../gaw/asset/wallet/v1/token";
import { TokenType } from "../../../common/common_web3";
import { BuffExtraConf } from "../../common/buff";
import { I18n } from "../../../common/common_lang";
import { PropCategory } from "../../../common/common_define";
import { NFTInfo } from "../../../../gaw/asset/wallet/v1/wallet";
import { ERC20Info } from "../../../../gaw/asset/wallet/v1/wallet";
import { PropType } from "../../../common/common_define";
import { AssetItem } from "../../../common/common_define";
/**
 * @generated from protobuf message bpt.hall.prop.v1.NotifyGiftOpenResultResp
 */
export interface NotifyGiftOpenResultResp {
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem results = 1;
     */
    results: AssetItem[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserPropBadgeReq
 */
export interface CleanUpUserPropBadgeReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 propId = 2;
     */
    propId: number;
    /**
     * @generated from protobuf field: bpt.common.PropType type = 3;
     */
    type: PropType;
    /**
     * @generated from protobuf field: int64 expireAt = 4;
     */
    expireAt: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CheckUserExpiredPropCallbackReq
 */
export interface CheckUserExpiredPropCallbackReq {
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 resId = 3;
     */
    resId: number;
    /**
     * @generated from protobuf field: int64 expireAt = 4;
     */
    expireAt: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.ResType resType = 5;
     */
    resType: ResType;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.VisualEffects
 */
export interface VisualEffects {
    /**
     * 预览效果
     *
     * @generated from protobuf field: string preview = 1;
     */
    preview: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SoundEffects
 */
export interface SoundEffects {
    /**
     * 预览音效
     *
     * @generated from protobuf field: string preview = 1;
     */
    preview: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.AnimationEffects
 */
export interface AnimationEffects {
    /**
     * 动效资源类型
     *
     * @generated from protobuf field: bpt.hall.prop.v1.AnimationEffects.ResourceType resourceType = 1;
     */
    resourceType: AnimationEffects_ResourceType;
}
/**
 * 动效资源类型枚举
 *
 * @generated from protobuf enum bpt.hall.prop.v1.AnimationEffects.ResourceType
 */
export enum AnimationEffects_ResourceType {
    /**
     * @generated from protobuf enum value: ResourceType_Unknown = 0;
     */
    ResourceType_Unknown = 0,
    /**
     * 炸弹
     *
     * @generated from protobuf enum value: ResourceType_Bomb = 1;
     */
    ResourceType_Bomb = 1,
    /**
     * 雞蛋
     *
     * @generated from protobuf enum value: ResourceType_Eggs = 2;
     */
    ResourceType_Eggs = 2,
    /**
     * 鈔票機
     *
     * @generated from protobuf enum value: ResourceType_CashMachine = 3;
     */
    ResourceType_CashMachine = 3,
    /**
     * 熱氣球
     *
     * @generated from protobuf enum value: ResourceType_HotAirBalloon = 4;
     */
    ResourceType_HotAirBalloon = 4,
    /**
     * 握手
     *
     * @generated from protobuf enum value: ResourceType_Handshake = 5;
     */
    ResourceType_Handshake = 5,
    /**
     * 拖鞋
     *
     * @generated from protobuf enum value: ResourceType_Slippers = 6;
     */
    ResourceType_Slippers = 6,
    /**
     * 啤酒
     *
     * @generated from protobuf enum value: ResourceType_Beer = 7;
     */
    ResourceType_Beer = 7,
    /**
     * 浪漫玫瑰
     *
     * @generated from protobuf enum value: ResourceType_Rose = 8;
     */
    ResourceType_Rose = 8,
    /**
     * 飛吻
     *
     * @generated from protobuf enum value: ResourceType_BlowAKiss = 9;
     */
    ResourceType_BlowAKiss = 9,
    /**
     * 水球
     *
     * @generated from protobuf enum value: ResourceType_WaterPolo = 10;
     */
    ResourceType_WaterPolo = 10,
    /**
     * 火箭
     *
     * @generated from protobuf enum value: ResourceType_Rocket = 11;
     */
    ResourceType_Rocket = 11,
    /**
     * 一束玫瑰發
     *
     * @generated from protobuf enum value: ResourceType_RoseFlower = 12;
     */
    ResourceType_RoseFlower = 12,
    /**
     * 跑车
     *
     * @generated from protobuf enum value: ResourceType_SportsCar = 13;
     */
    ResourceType_SportsCar = 13,
    /**
     * 烟花
     *
     * @generated from protobuf enum value: ResourceType_Fireworks = 14;
     */
    ResourceType_Fireworks = 14,
    /**
     * 皇冠
     *
     * @generated from protobuf enum value: ResourceType_Crown = 15;
     */
    ResourceType_Crown = 15
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.VoicePack
 */
export interface VoicePack {
    /**
     * @generated from protobuf field: string allIn = 1;
     */
    allIn: string;
    /**
     * @generated from protobuf field: string raise = 2;
     */
    raise: string;
    /**
     * @generated from protobuf field: string bet = 3;
     */
    bet: string;
    /**
     * @generated from protobuf field: string call = 4;
     */
    call: string;
    /**
     * @generated from protobuf field: string fold = 5;
     */
    fold: string;
    /**
     * @generated from protobuf field: string check = 6;
     */
    check: string;
    /**
     * @generated from protobuf field: string trial = 7;
     */
    trial: string;
}
/**
 * 道具定义
 *
 * @generated from protobuf message bpt.hall.prop.v1.Prop
 */
export interface Prop {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * 基本信息 syncproto.independence 单独计算checksum
     *
     * @generated from protobuf field: bpt.hall.prop.v1.BaseProp base = 2;
     */
    base?: BaseProp;
    /**
     * deprecated: buff 信息
     *
     * @generated from protobuf field: bpt.hall.prop.v1.BuffProp buff = 3;
     */
    buff?: BuffProp;
    /**
     * deprecated:优惠券信息
     *
     * @deprecated
     * @generated from protobuf field: bpt.hall.prop.v1.CouponProp coupon = 5 [deprecated = true];
     */
    coupon?: CouponProp;
    /**
     * deprecated:亲密卡信息
     *
     * @deprecated
     * @generated from protobuf field: bpt.hall.prop.v1.IntimacyProp intimacy = 6 [deprecated = true];
     */
    intimacy?: IntimacyProp;
    /**
     * deprecated:人气值道具
     *
     * @deprecated
     * @generated from protobuf field: bpt.hall.prop.v1.PopularityProp popularity = 7 [deprecated = true];
     */
    popularity?: PopularityProp;
    /**
     * GAW货币道具
     * deprecated: use erc20info
     *
     * @generated from protobuf field: bpt.hall.prop.v1.GAWCoinProp gawCoinProp = 8;
     */
    gawCoinProp?: GAWCoinProp;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.ERC20Info erc20info = 9 [json_name = "erc20info"];
     */
    erc20info?: ERC20Info;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.NFTInfo nftInfo = 10;
     */
    nftInfo?: NFTInfo;
    /**
     * @generated from protobuf field: int64 nftInfoLinkedErc20PropId = 11;
     */
    nftInfoLinkedErc20PropId: number; // 关联的erc20道具id
    /**
     * 语音包信息
     *
     * @generated from protobuf field: bpt.hall.prop.v1.VoicePack voicePack = 12;
     */
    voicePack?: VoicePack;
    /**
     * 当前Prop的价值
     *
     * @generated from protobuf field: bpt.hall.prop.v1.PriceInfo propPrice = 13;
     */
    propPrice?: PriceInfo;
    /**
     * 当前Prop的回收价值(只有礼物类型有)
     *
     * @generated from protobuf field: bpt.hall.prop.v1.PriceInfo recyclePrice = 14;
     */
    recyclePrice?: PriceInfo;
    /**
     * 互动道具、礼物的视觉效果
     *
     * @generated from protobuf field: bpt.hall.prop.v1.VisualEffects visualEffects = 15;
     */
    visualEffects?: VisualEffects;
    /**
     * 互动道具、礼物的音效
     *
     * @generated from protobuf field: bpt.hall.prop.v1.SoundEffects soundEffects = 16;
     */
    soundEffects?: SoundEffects;
    /**
     * 互动道具、礼物的动效信息
     *
     * @generated from protobuf field: bpt.hall.prop.v1.AnimationEffects animationEffects = 17;
     */
    animationEffects?: AnimationEffects;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.PriceInfo
 */
export interface PriceInfo {
    /**
     * token最小单位的数量
     *
     * @generated from protobuf field: string rawAmount = 1;
     */
    rawAmount: string;
    /**
     * 关联的erc20道具id
     *
     * @generated from protobuf field: int64 linkedErc20PropId = 2;
     */
    linkedErc20PropId: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SimplyProp
 */
export interface SimplyProp {
    /**
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * @generated from protobuf field: bpt.common.PropCategory category = 2;
     */
    category: PropCategory; // 道具品类分类
    /**
     * @generated from protobuf field: bpt.common.PropType type = 3;
     */
    type: PropType; // 道具功能类型
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string; // 名称
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.ERC20Info erc20info = 5 [json_name = "erc20info"];
     */
    erc20info?: ERC20Info; // erc20info
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.NFTInfo nftInfo = 6;
     */
    nftInfo?: NFTInfo;
}
// message PremiumCard {
//    //尊享卡类型
//    tss.hall.common.PremiumCardType Type = 1 ;
//    //续期单位时间量(小时)
//    int64 unitTime = 2 ;
// }

/**
 * 亲密卡道具
 *
 * @generated from protobuf message bpt.hall.prop.v1.IntimacyProp
 */
export interface IntimacyProp {
    /**
     * @generated from protobuf field: int64 intimacy = 7;
     */
    intimacy: number;
}
/**
 * 道具基础信息
 *
 * @generated from protobuf message bpt.hall.prop.v1.BaseProp
 */
export interface BaseProp {
    /**
     * 道具类型编号
     *
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * 道具品类分类
     *
     * @generated from protobuf field: bpt.common.PropCategory category = 2;
     */
    category: PropCategory;
    /**
     * 道具功能类型
     *
     * @generated from protobuf field: bpt.common.PropType type = 3;
     */
    type: PropType;
    /**
     * 名称
     *
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * 描述
     *
     * @generated from protobuf field: string desc = 5;
     */
    desc: string;
    /**
     * 道具底图
     *
     * @generated from protobuf field: string img = 6;
     */
    img: string;
    /**
     * 道具icon
     *
     * @generated from protobuf field: string icon = 7;
     */
    icon: string;
    /**
     * deprecated 价值
     *
     * @generated from protobuf field: int32 price = 8;
     */
    price: number;
    /**
     * 道具标签
     *
     * @generated from protobuf field: repeated string tags = 9;
     */
    tags: string[];
    /**
     * 道具状态
     *
     * @generated from protobuf field: bpt.hall.prop.v1.State state = 10;
     */
    state: State;
    /**
     * deprecated 是否堆叠
     *
     * @deprecated
     * @generated from protobuf field: bool isStack = 11 [deprecated = true];
     */
    isStack: boolean;
    /**
     * 创建时间
     *
     * @generated from protobuf field: int64 createAt = 12;
     */
    createAt: number;
    /**
     * 修改时间
     *
     * @generated from protobuf field: int64 updateAt = 13;
     */
    updateAt: number;
    /**
     * 操作人
     *
     * @generated from protobuf field: string operator = 14;
     */
    operator: string;
    /**
     * deprecated 使用方式
     *
     * @deprecated
     * @generated from protobuf field: bpt.hall.prop.v1.Usage usage = 15 [deprecated = true];
     */
    usage?: Usage;
    /**
     * deprecated 参考钻石
     *
     * @deprecated
     * @generated from protobuf field: int32 referDiamond = 16 [deprecated = true];
     */
    referDiamond: number;
    /**
     * deprecated 参考价格
     *
     * @deprecated
     * @generated from protobuf field: int32 referPrice = 17 [deprecated = true];
     */
    referPrice: number;
    /**
     * deprecated 是否可赠送
     *
     * @deprecated
     * @generated from protobuf field: bool canSend = 18 [deprecated = true];
     */
    canSend: boolean;
    /**
     * deprecated 丢弃时金币补偿
     *
     * @deprecated
     * @generated from protobuf field: int32 discardCompensation = 19 [deprecated = true];
     */
    discardCompensation: number;
    /**
     * deprecated 最低使用等级
     *
     * @deprecated
     * @generated from protobuf field: int32 usableLevel = 20 [deprecated = true];
     */
    usableLevel: number;
    /**
     * deprecated 仅限尊享卡用户使用
     *
     * @deprecated
     * @generated from protobuf field: bool usablePremium = 21 [deprecated = true];
     */
    usablePremium: boolean;
    /**
     * deprecated 参考奖券(回收补偿)
     *
     * @deprecated
     * @generated from protobuf field: int32 referMung = 22 [deprecated = true];
     */
    referMung: number;
    /**
     * deprecated 是否可回收
     *
     * @deprecated
     * @generated from protobuf field: bool recycleAble = 23 [deprecated = true];
     */
    recycleAble: boolean;
    /**
     * 对用户隐藏
     *
     * @generated from protobuf field: bool isInvisibleForUser = 24;
     */
    isInvisibleForUser: boolean;
    /**
     * 对cms隐藏
     *
     * @generated from protobuf field: bool isInvisibleForCMS = 25;
     */
    isInvisibleForCMS: boolean;
    /**
     * 多语言名称
     *
     * @generated from protobuf field: bpt.common.I18n i18nName = 26 [json_name = "i18nName"];
     */
    i18nName?: I18n;
    /**
     * 是否免费使用(现在只有-互动道具 才能有设置). 默认false不免费
     *
     * @generated from protobuf field: bool canBeUsedForFree = 27;
     */
    canBeUsedForFree: boolean;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BuffProp
 */
export interface BuffProp {
    /**
     * 可使用时长
     *
     * @generated from protobuf field: int64 duration = 1;
     */
    duration: number;
    /**
     * 可使用次数
     *
     * @generated from protobuf field: int32 count = 2;
     */
    count: number;
    /**
     * 加成比例
     *
     * @generated from protobuf field: int64 markupRate = 3;
     */
    markupRate: number;
    /**
     * buff条件配置
     *
     * @generated from protobuf field: bpt.hall.common.BuffExtraConf buffExtraConf = 4;
     */
    buffExtraConf?: BuffExtraConf;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CouponProp
 */
export interface CouponProp {
    /**
     * 满足优惠的价格
     *
     * @generated from protobuf field: int64 moneyAmount = 1;
     */
    moneyAmount: number;
    /**
     * 折扣比例
     *
     * @generated from protobuf field: int64 discount = 2;
     */
    discount: number;
    /**
     * 减免
     *
     * @generated from protobuf field: int64 deduction = 3;
     */
    deduction: number;
    /**
     * 最高减免
     *
     * @generated from protobuf field: int64 maxDeduction = 4;
     */
    maxDeduction: number;
    /**
     * 优惠券开始时间
     *
     * @generated from protobuf field: int64 startAt = 5;
     */
    startAt: number;
    /**
     * 优惠券结束时间
     *
     * @generated from protobuf field: int64 endAt = 6;
     */
    endAt: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.PopularityProp
 */
export interface PopularityProp {
    /**
     * 是否开启提现
     *
     * @generated from protobuf field: bool IsOpenWithdraw = 1 [json_name = "IsOpenWithdraw"];
     */
    IsOpenWithdraw: boolean;
    /**
     * 增加的人气值
     *
     * @generated from protobuf field: int64 popularity = 2;
     */
    popularity: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GAWCoinProp
 */
export interface GAWCoinProp {
    /**
     * @generated from protobuf field: bpt.common.TokenType type = 1;
     */
    type: TokenType;
    /**
     * 不填则为 默认配置的 ERC20 代币
     *
     * @generated from protobuf field: string contactAddr = 2;
     */
    contactAddr: string;
    /**
     * // ERC721 时需要填写
     * string tokenID = 3;
     * 数额, 最小为 1
     *
     * @generated from protobuf field: int64 amount = 4;
     */
    amount: number;
    /**
     * 小数位数, 默认 0,则 amount = 1 为 1 个 token
     *
     * @generated from protobuf field: int32 decimals = 5;
     */
    decimals: number;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.Unit unit = 6;
     */
    unit: Unit;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 7;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string symbol = 8;
     */
    symbol: string;
    /**
     * @generated from protobuf field: string logo = 9;
     */
    logo: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.Usage
 */
export interface Usage {
    /**
     * 使用方式
     *
     * @generated from protobuf field: bpt.hall.prop.v1.UsageType Type = 1 [json_name = "Type"];
     */
    Type: UsageType;
    /**
     * 描述 跳转描述
     *
     * @generated from protobuf field: string Desc = 2 [json_name = "Desc"];
     */
    Desc: string;
    /**
     * 使用跳转
     *
     * @generated from protobuf field: string url = 3;
     */
    url: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SavePropReq
 */
export interface SavePropReq {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Prop prop = 1;
     */
    prop?: Prop;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SavePropResp
 */
export interface SavePropResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Prop prop = 1;
     */
    prop?: Prop;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetPropReq
 */
export interface BatchGetPropReq {
    /**
     * @generated from protobuf field: repeated int64 ids = 1;
     */
    ids: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetPropResp
 */
export interface BatchGetPropResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.Prop props = 1;
     */
    props: Prop[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListPropReq
 */
export interface ListPropReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: repeated bpt.common.PropType types = 3;
     */
    types: PropType[];
    /**
     * @generated from protobuf field: string keyword = 4;
     */
    keyword: string;
    /**
     * @generated from protobuf field: bpt.common.PropCategory category = 5;
     */
    category: PropCategory;
    /**
     * @generated from protobuf field: bool invisible = 6;
     */
    invisible: boolean;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListPropResp
 */
export interface ListPropResp {
    /**
     * @generated from protobuf field: int64 totalSize = 1;
     */
    totalSize: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.Prop props = 2;
     */
    props: Prop[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListSimplyPropReq
 */
export interface ListSimplyPropReq {
    /**
     * @generated from protobuf field: bpt.common.PropCategory category = 1;
     */
    category: PropCategory;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListSimplyPropResp
 */
export interface ListSimplyPropResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.SimplyProp props = 1;
     */
    props: SimplyProp[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetPropReq
 */
export interface GetPropReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetPropResp
 */
export interface GetPropResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Prop prop = 1;
     */
    prop?: Prop;
}
/**
 * 背包领域 *************************
 *
 * @generated from protobuf message bpt.hall.prop.v1.UserProp
 */
export interface UserProp {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 道具id
     *
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number;
    /**
     * 数量
     *
     * @generated from protobuf field: int64 num = 3;
     */
    num: number;
    /**
     * 过期时间
     *
     * @generated from protobuf field: int64 expireAt = 4;
     */
    expireAt: number;
    /**
     * 道具分类
     *
     * @generated from protobuf field: bpt.common.PropCategory category = 5;
     */
    category: PropCategory;
    /**
     * 道具功能类型
     *
     * @generated from protobuf field: bpt.common.PropType type = 6;
     */
    type: PropType;
    /**
     * 道具名称
     *
     * @generated from protobuf field: string name = 7;
     */
    name: string;
    /**
     * 道具描述
     *
     * @generated from protobuf field: string desc = 8;
     */
    desc: string;
    /**
     * 道具图片地址
     *
     * @generated from protobuf field: string img = 9;
     */
    img: string;
    /**
     * 道具图片地址
     *
     * @generated from protobuf field: string icon = 10;
     */
    icon: string;
    /**
     * 发放时间
     *
     * @generated from protobuf field: int64 grantedAt = 11;
     */
    grantedAt: number;
    /**
     * 使用方式
     *
     * @generated from protobuf field: bpt.hall.prop.v1.Usage usage = 12;
     */
    usage?: Usage;
    /**
     * 道具显示tag
     *
     * @generated from protobuf field: string showTag = 13;
     */
    showTag: string;
    /**
     * 红点数量
     *
     * @generated from protobuf field: int64 badgeNum = 14;
     */
    badgeNum: number;
    /**
     * 道具状态，是否禁用
     *
     * @generated from protobuf field: bpt.hall.prop.v1.State state = 15;
     */
    state: State;
    /**
     * 唯一id： "uid:propID:expireAt"
     *
     * @generated from protobuf field: string objectID = 16;
     */
    objectID: string;
    /**
     * 扩展信息
     *
     * @generated from protobuf field: bpt.hall.prop.v1.BuffProp buff = 41;
     */
    buff?: BuffProp;
    /**
     * 优惠券信息
     *
     * @generated from protobuf field: bpt.hall.prop.v1.CouponProp coupon = 42;
     */
    coupon?: CouponProp;
    /**
     * 礼包包含的道具信息
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem gitfItems = 43;
     */
    gitfItems: AssetItem[];
    /**
     * 是否可赠送
     *
     * @generated from protobuf field: bool canSend = 44;
     */
    canSend: boolean;
    /**
     * 丢弃时金币补偿
     *
     * @generated from protobuf field: int32 discardCompensation = 45;
     */
    discardCompensation: number;
    /**
     * 是否为概要信息
     *
     * @generated from protobuf field: bool shortcut = 46;
     */
    shortcut: boolean;
    /**
     * 概要中的道具条目数量
     *
     * @generated from protobuf field: int64 numInShortcut = 47;
     */
    numInShortcut: number;
    /**
     * 参考奖券(回收补偿)
     *
     * @generated from protobuf field: int32 referMung = 48;
     */
    referMung: number;
    /**
     * 是否可回收
     *
     * @generated from protobuf field: bool recycleAble = 49;
     */
    recycleAble: boolean;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserChange
 */
export interface UserChange {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserChangeItem items = 2;
     */
    items: UserChangeItem[];
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UserPropChangeStamp stamp = 3;
     */
    stamp?: UserPropChangeStamp; // 变更戳信息
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserChangeItem
 */
export interface UserChangeItem {
    /**
     * 资源ID
     *
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * 过期时间点
     * int64 expireAt            = 2 ;
     *
     * @generated from protobuf field: bpt.common.ExpireData expire = 2;
     */
    expire?: ExpireData;
    /**
     * 都是正数，不能小于0
     *
     * @generated from protobuf field: int64 num = 3;
     */
    num: number;
    /**
     * @generated from protobuf field: string extra = 4;
     */
    extra: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserPropChangeStamp
 */
export interface UserPropChangeStamp {
    /**
     * @generated from protobuf field: bpt.common.Way way = 1;
     */
    way: Way;
    /**
     * @generated from protobuf field: string operator = 2;
     */
    operator: string;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.GrantType grantType = 3;
     */
    grantType: GrantType;
    /**
     * 变动场景
     *
     * @generated from protobuf field: map<string, string> scene = 4;
     */
    scene: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserPropReq
 */
export interface IncUserPropReq {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UserChange change = 1;
     */
    change?: UserChange;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserPropResp
 */
export interface IncUserPropResp {
    /**
     * 本次操作的批次id
     *
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string;
    /**
     * 增加的道具
     *
     * @generated from protobuf field: repeated bpt.hall.common.DynamicAssetItems assets = 2;
     */
    assets: DynamicAssetItems[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.DecUserPropReq
 */
export interface DecUserPropReq {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UserChange change = 1;
     */
    change?: UserChange;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.DecUserPropResp
 */
export interface DecUserPropResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * 本次操作的批次id
     *
     * @generated from protobuf field: string batchID = 2;
     */
    batchID: string;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.RefundUserPropReq
 */
export interface RefundUserPropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 批次id
     *
     * @generated from protobuf field: string batchID = 2;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.RefundUserPropResp
 */
export interface RefundUserPropResp {
    /**
     * 本次操作的批次id
     *
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.RenameReq
 */
export interface RenameReq {
    /**
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * @generated from protobuf field: int64 expireAt = 2;
     */
    expireAt: number;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: int64 uid = 4;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.RenameResp
 */
export interface RenameResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateVipReq
 */
export interface ActivateVipReq {
    /**
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * @generated from protobuf field: int64 expireAt = 2;
     */
    expireAt: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateVipResp
 */
export interface ActivateVipResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * @generated from protobuf field: int64 expiredAt = 2;
     */
    expiredAt: number;
    /**
     * @generated from protobuf field: int64 hour = 3;
     */
    hour: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivatePremiumCardReq
 */
export interface ActivatePremiumCardReq {
    /**
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * @generated from protobuf field: int64 expireAt = 2;
     */
    expireAt: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivatePremiumCardResp
 */
export interface ActivatePremiumCardResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * @generated from protobuf field: int64 expiredAt = 2;
     */
    expiredAt: number;
    /**
     * @generated from protobuf field: int64 hour = 3;
     */
    hour: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateSteakWinningProtectCardReq
 */
export interface ActivateSteakWinningProtectCardReq {
    /**
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * @generated from protobuf field: int64 expireAt = 2;
     */
    expireAt: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * @generated from protobuf field: string gameId = 4;
     */
    gameId: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateSteakWinningProtectCardResp
 */
export interface ActivateSteakWinningProtectCardResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.OpenGiftReq
 */
export interface OpenGiftReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int64 expireAt = 2;
     */
    expireAt: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.OpenGiftResp
 */
export interface OpenGiftResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * repeated UserProp userProps = 2;
     * int64    mungNum   = 3;
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem items = 4;
     */
    items: AssetItem[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateUserBuffReq
 */
export interface ActivateUserBuffReq {
    /**
     * 道具ID
     *
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * 过期时间
     *
     * @generated from protobuf field: int64 expireAt = 2;
     */
    expireAt: number;
    /**
     * 用户ID
     *
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * 激活对应类型buff
     *
     * @generated from protobuf field: bpt.common.PropType pType = 4;
     */
    pType: PropType;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateUserBuffResp
 */
export interface ActivateUserBuffResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * @generated from protobuf field: int64 markupRate = 2;
     */
    markupRate: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserIntimacyReq
 */
export interface IncUserIntimacyReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number; // 道具id
    /**
     * @generated from protobuf field: int64 propNum = 3;
     */
    propNum: number; // 道具数量
    /**
     * @generated from protobuf field: int64 withUid = 4;
     */
    withUid: number; // 和谁增加亲密值
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserIntimacyResp
 */
export interface IncUserIntimacyResp {
    /**
     * @generated from protobuf field: int64 incIntimacy = 1;
     */
    incIntimacy: number; // 增加的亲密值
    /**
     * @generated from protobuf field: int64 curIntimacy = 2;
     */
    curIntimacy: number; // 当前的亲密值
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropGawBalanceListReq
 */
export interface GetUserPropGawBalanceListReq {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: bool forceRefresh = 2;
     */
    forceRefresh: boolean;
    /**
     * @generated from protobuf field: string userAddress = 3;
     */
    userAddress: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropGawBalanceListResp
 */
export interface GetUserPropGawBalanceListResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserPropGawBalanceInfo list = 1;
     */
    list: UserPropGawBalanceInfo[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserPropGawBalanceInfo
 */
export interface UserPropGawBalanceInfo {
    /**
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.ERC20Info erc20info = 2 [json_name = "erc20info"];
     */
    erc20info?: ERC20Info;
    /**
     * @generated from protobuf field: string balance = 3;
     */
    balance: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserPropReq
 */
export interface BatchGetUserPropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated int64 ids = 2;
     */
    ids: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserGiftReq
 */
export interface BatchGetUserGiftReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated int64 ids = 2;
     */
    ids: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserPropResp
 */
export interface BatchGetUserPropResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp props = 1;
     */
    props: UserProp[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserGiftResp
 */
export interface BatchGetUserGiftResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp gifts = 1;
     */
    gifts: UserProp[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.Block
 */
export interface Block {
    /**
     * @generated from protobuf field: repeated bpt.common.PropType propTypes = 1;
     */
    propTypes: PropType[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserResReq
 */
export interface ListUserResReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * repeated tss.common.PropCategory categories   = 4; // 按照分类，
     * 按照功能类型， nil：跳过此索引 int64         expireAtStart = 6 ;   //
     * 开始时间 nil：跳过此索引 int64         expireAtEnd   = 7 ;   // 过期时间
     * nil：跳过此索引。长度为1~2；
     * 当len==1，表示指定expireAt；当len==2，表示查询expireAt范围为
     * expireRange[0]~expireRange[1] UserPropState state         = 8
     * [(gogoproto.nullable) = false];   // UserPropStateUnknown 跳过此索引
     * Block block         = 9 [(gogoproto.nullable) = false]; // 屏蔽项 bool
     * needTotalSize = 10 ;  // 是否需要总大小 string        orderBy       = 11
     * ;
     * // 排序方式
     * 是否可赠送
     *
     * @generated from protobuf field: bool canSend = 4;
     */
    canSend: boolean;
    /**
     * @generated from protobuf field: repeated bpt.common.PropType types = 5;
     */
    types: PropType[];
    /**
     * 查询选项
     *
     * @generated from protobuf field: bpt.hall.prop.v1.QueryTab queryTab = 6;
     */
    queryTab: QueryTab;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserResV2Req
 */
export interface ListUserResV2Req {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * 是否可赠送
     *
     * @generated from protobuf field: bool canSend = 4;
     */
    canSend: boolean;
    /**
     * @generated from protobuf field: repeated bpt.common.PropType types = 5;
     */
    types: PropType[];
    /**
     * 查询选项
     *
     * @generated from protobuf field: bpt.hall.prop.v1.QueryTab queryTab = 6;
     */
    queryTab: QueryTab;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ExpandPropDetailsReq
 */
export interface ExpandPropDetailsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 id = 2;
     */
    id: number;
    /**
     * @generated from protobuf field: int64 page = 3;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 4;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.common.PropType propType = 5;
     */
    propType: PropType;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CMSListUserResReq
 */
export interface CMSListUserResReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserResResp
 */
export interface ListUserResResp {
    /**
     * 普通道具和礼包
     *
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp props = 1;
     */
    props: UserProp[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
    /**
     * 兑换道具
     *
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp vouchers = 3;
     */
    vouchers: UserProp[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserResV2Resp
 */
export interface ListUserResV2Resp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp props = 1;
     */
    props: UserProp[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ExpandPropDetailsResp
 */
export interface ExpandPropDetailsResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp props = 1;
     */
    props: UserProp[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CMSListUserResResp
 */
export interface CMSListUserResResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp props = 1;
     */
    props: UserProp[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserMergedPropReq
 */
export interface ListUserMergedPropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated bpt.common.PropType types = 2;
     */
    types: PropType[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserMergedPropResp
 */
export interface ListUserMergedPropResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp props = 1;
     */
    props: UserProp[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserMergedPropReq
 */
export interface BatchGetUserMergedPropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated int64 propIDs = 2;
     */
    propIDs: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserMergedPropResp
 */
export interface BatchGetUserMergedPropResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserProp props = 1;
     */
    props: UserProp[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CountUserPropReq
 */
export interface CountUserPropReq {
    /**
     * 用户
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 道具id
     *
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number;
    /**
     * 按照分类， nil：跳过此索引,
     *
     * @generated from protobuf field: repeated bpt.common.PropCategory categories = 3;
     */
    categories: PropCategory[];
    /**
     * 按照功能类型， nil：跳过此索引
     *
     * @generated from protobuf field: repeated bpt.common.PropType types = 4;
     */
    types: PropType[];
    /**
     * 按照背包tab id， nil：跳过此索引
     *
     * @generated from protobuf field: int64 expireAtStart = 5;
     */
    expireAtStart: number;
    /**
     * 过期时间 0：跳过此索引
     *
     * @generated from protobuf field: int64 expireAtEnd = 6;
     */
    expireAtEnd: number;
    /**
     * deprecated
     * 屏蔽项
     *
     * @generated from protobuf field: bpt.hall.prop.v1.Block block = 7;
     */
    block?: Block;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CountUserPropResp
 */
export interface CountUserPropResp {
    /**
     * @generated from protobuf field: int64 totalSize = 1;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CountCurrentUserPropByPropTypeReq
 */
export interface CountCurrentUserPropByPropTypeReq {
    /**
     * 用户
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 功能类型
     *
     * @generated from protobuf field: repeated bpt.common.PropType types = 2;
     */
    types: PropType[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CountCurrentUserPropByPropTypeResp
 */
export interface CountCurrentUserPropByPropTypeResp {
    /**
     * key 为tss.common.PropType枚举，value为totalSize数量
     *
     * @generated from protobuf field: map<int32, int64> cntsMap = 1;
     */
    cntsMap: {
        [key: number]: number;
    };
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CountCurrentUserPropByCategoryReq
 */
export interface CountCurrentUserPropByCategoryReq {
    /**
     * 用户
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 道具类别
     *
     * @generated from protobuf field: repeated bpt.common.PropCategory categories = 2;
     */
    categories: PropCategory[];
    /**
     * 道具类型
     *
     * @generated from protobuf field: repeated bpt.common.PropType types = 3;
     */
    types: PropType[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CategoryItemCnt
 */
export interface CategoryItemCnt {
    /**
     * @generated from protobuf field: bpt.common.PropCategory category = 1;
     */
    category: PropCategory;
    /**
     * 类型
     *
     * @generated from protobuf field: bpt.common.PropType type = 2;
     */
    type: PropType;
    /**
     * 数量
     *
     * @generated from protobuf field: int64 cnt = 3;
     */
    cnt: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CountCurrentUserPropByCategoryResp
 */
export interface CountCurrentUserPropByCategoryResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.CategoryItemCnt itemCnts = 1;
     */
    itemCnts: CategoryItemCnt[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserPropReq
 */
export interface CleanUpUserPropReq {
    /**
     * 用户
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 道具id
     *
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number;
    /**
     * 按照分类， nil：跳过此索引,
     *
     * @generated from protobuf field: repeated bpt.common.PropCategory categories = 3;
     */
    categories: PropCategory[];
    /**
     * 按照功能类型， nil：跳过此索引
     *
     * @generated from protobuf field: repeated bpt.common.PropType types = 4;
     */
    types: PropType[];
    /**
     * @generated from protobuf field: int64 expireAt = 5;
     */
    expireAt: number;
    /**
     * string                   objectID    = 6 ;  // 对象唯一id
     * 方式
     *
     * @generated from protobuf field: bpt.hall.prop.v1.CleanUpOption option = 6;
     */
    option: CleanUpOption;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserPropV2Req
 */
export interface CleanUpUserPropV2Req {
    /**
     * 用户
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 道具id
     *
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number;
    /**
     * 按照分类， nil：跳过此索引,
     *
     * @generated from protobuf field: repeated bpt.common.PropCategory categories = 3;
     */
    categories: PropCategory[];
    /**
     * 按照功能类型， nil：跳过此索引
     *
     * @generated from protobuf field: repeated bpt.common.PropType types = 4;
     */
    types: PropType[];
    /**
     * @generated from protobuf field: int64 expireAt = 5;
     */
    expireAt: number;
    /**
     * 方式
     *
     * @generated from protobuf field: bpt.hall.prop.v1.CleanUpOption option = 6;
     */
    option: CleanUpOption;
    /**
     * 件数(0为全部
     *
     * @generated from protobuf field: int64 num = 7;
     */
    num: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserPropV2Resp
 */
export interface CleanUpUserPropV2Resp {
    /**
     * 补偿
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem compensate = 1;
     */
    compensate: AssetItem[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserPropResp
 */
export interface CleanUpUserPropResp {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserPropLog
 */
export interface UserPropLog {
    /**
     * 用户id
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 道具id
     *
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number;
    /**
     * 余量
     *
     * @generated from protobuf field: int64 balance = 3;
     */
    balance: number;
    /**
     * 变更数量
     *
     * @generated from protobuf field: int64 num = 4;
     */
    num: number;
    /**
     * 批次号
     *
     * @generated from protobuf field: string batchId = 5;
     */
    batchId: string;
    /**
     * 流向
     *
     * @generated from protobuf field: bpt.common.Sign sign = 6;
     */
    sign: Sign;
    /**
     * 创建点
     *
     * @generated from protobuf field: int64 createAt = 7;
     */
    createAt: number;
    /**
     * 变更方式
     *
     * @generated from protobuf field: bpt.common.Way way = 8;
     */
    way: Way;
    /**
     * 发放方式
     *
     * @generated from protobuf field: bpt.hall.prop.v1.GrantType grantType = 9;
     */
    grantType: GrantType;
    /**
     * 道具过期时间
     *
     * @generated from protobuf field: int64 expireAt = 10;
     */
    expireAt: number;
    /**
     * 扩展信息
     *
     * @generated from protobuf field: string extra = 11;
     */
    extra: string;
    /**
     * 操作人
     *
     * @generated from protobuf field: string operator = 12;
     */
    operator: string;
    /**
     * 道具名称
     *
     * @generated from protobuf field: string propName = 13;
     */
    propName: string;
    /**
     * 用户机器人标识
     *
     * @generated from protobuf field: bpt.common.UserRobotFlag urf = 14;
     */
    urf: UserRobotFlag;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserGiftLog
 */
export interface UserGiftLog {
    /**
     * 用户id
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 道具id
     *
     * @generated from protobuf field: int64 giftID = 2;
     */
    giftID: number;
    /**
     * 余量
     *
     * @generated from protobuf field: int64 balance = 3;
     */
    balance: number;
    /**
     * 变更数量
     *
     * @generated from protobuf field: int64 num = 4;
     */
    num: number;
    /**
     * 批次号
     *
     * @generated from protobuf field: string batchId = 5;
     */
    batchId: string;
    /**
     * 流向
     *
     * @generated from protobuf field: bpt.common.Sign sign = 6;
     */
    sign: Sign;
    /**
     * 创建点
     *
     * @generated from protobuf field: int64 createAt = 7;
     */
    createAt: number;
    /**
     * 变更方式
     *
     * @generated from protobuf field: bpt.common.Way way = 8;
     */
    way: Way;
    /**
     * 发放方式
     *
     * @generated from protobuf field: bpt.hall.prop.v1.GrantType grantType = 9;
     */
    grantType: GrantType;
    /**
     * 道具过期时间
     *
     * @generated from protobuf field: int64 expireAt = 10;
     */
    expireAt: number;
    /**
     * 扩展信息
     *
     * @generated from protobuf field: string extra = 11;
     */
    extra: string;
    /**
     * 操作人
     *
     * @generated from protobuf field: string operator = 12;
     */
    operator: string;
    /**
     * 道具名称
     *
     * @generated from protobuf field: string giftName = 13;
     */
    giftName: string;
    /**
     * 打开礼包获的物品
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem items = 14;
     */
    items: AssetItem[];
    /**
     * 用户机器人标识
     *
     * @generated from protobuf field: bpt.common.UserRobotFlag urf = 15;
     */
    urf: UserRobotFlag;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropLogReq
 */
export interface ListUserPropLogReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 beginAt = 4;
     */
    beginAt: number;
    /**
     * @generated from protobuf field: int64 endAt = 5;
     */
    endAt: number;
    /**
     * @generated from protobuf field: int64 propID = 6;
     */
    propID: number;
    /**
     * @generated from protobuf field: bpt.common.Way way = 7;
     */
    way: Way;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropLogResp
 */
export interface ListUserPropLogResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserPropLog logs = 1;
     */
    logs: UserPropLog[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogReq
 */
export interface ListUserTxOnChainLogReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 beginAt = 3;
     */
    beginAt: number;
    /**
     * @generated from protobuf field: int64 endAt = 4;
     */
    endAt: number;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainType type = 5;
     */
    type: TxOnChainType;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainStatus status = 6;
     */
    status: TxOnChainStatus;
    /**
     * 模糊搜索erc20的货币名
     *
     * @generated from protobuf field: string erc20SymbolFuzzy = 7;
     */
    erc20SymbolFuzzy: string;
    /**
     * 链ID
     *
     * @generated from protobuf field: gaw.base.ChainID chainID = 8;
     */
    chainID: ChainID;
    /**
     * 合约地址
     *
     * @generated from protobuf field: string contractAddress = 9;
     */
    contractAddress: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogResp
 */
export interface ListUserTxOnChainLogResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserTxOnChainLog list = 1;
     */
    list: UserTxOnChainLog[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogInternalReq
 */
export interface ListUserTxOnChainLogInternalReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 beginAt = 3;
     */
    beginAt: number;
    /**
     * @generated from protobuf field: int64 endAt = 4;
     */
    endAt: number;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainType type = 5;
     */
    type: TxOnChainType;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainStatus status = 6;
     */
    status: TxOnChainStatus;
    /**
     * 模糊搜索erc20的货币名
     *
     * @generated from protobuf field: string erc20SymbolFuzzy = 7;
     */
    erc20SymbolFuzzy: string;
    /**
     * 链ID
     *
     * @generated from protobuf field: gaw.base.ChainID chainID = 8;
     */
    chainID: ChainID;
    /**
     * 合约地址
     *
     * @generated from protobuf field: string contractAddress = 9;
     */
    contractAddress: string;
    /**
     * @generated from protobuf field: int64 uid = 10;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogInternalResp
 */
export interface ListUserTxOnChainLogInternalResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserTxOnChainLog list = 1;
     */
    list: UserTxOnChainLog[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserTxOnChainLog
 */
export interface UserTxOnChainLog {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.ERC20Info erc20info = 2 [json_name = "erc20info"];
     */
    erc20info?: ERC20Info;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainType type = 3;
     */
    type: TxOnChainType;
    /**
     * @generated from protobuf field: bpt.common.TxOnChainStatus status = 4;
     */
    status: TxOnChainStatus;
    /**
     * @generated from protobuf field: string amount = 5;
     */
    amount: string;
    /**
     * @generated from protobuf field: uint64 createTime = 6;
     */
    createTime: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.TxOnChainLogInfo txInfo = 7;
     */
    txInfo?: TxOnChainLogInfo;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.NFTInfo nftInfo = 8;
     */
    nftInfo?: NFTInfo;
    /**
     * @generated from protobuf field: bpt.common.Way way = 9;
     */
    way: Way; // 只用到7和104
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.TxOnChainLogInfo
 */
export interface TxOnChainLogInfo {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string txHash = 2;
     */
    txHash: string;
    /**
     * @generated from protobuf field: uint32 taskId = 3;
     */
    taskId: number;
    /**
     * @generated from protobuf field: string productId = 4;
     */
    productId: string;
    /**
     * @generated from protobuf field: int64 propId = 5;
     */
    propId: number;
    /**
     * @generated from protobuf field: string currentBalance = 6;
     */
    currentBalance: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UpdateUserTxOnChainLogReq
 */
export interface UpdateUserTxOnChainLogReq {
    /**
     * 用户ID(必填)
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 交易流水ID(必填) )
     *
     * @generated from protobuf field: string tradeID = 2;
     */
    tradeID: string;
    /**
     * Deprecated: use UpdateUserTxOnChainLogDetail.matchKey
     * 比赛唯一标识(非必填)
     *
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * 交易类型(必填)
     *
     * @generated from protobuf field: bpt.common.TxOnChainType txType = 4;
     */
    txType: TxOnChainType;
    /**
     * 交易详情信息字段
     *
     * @generated from protobuf field: bpt.hall.prop.v1.UpdateUserTxOnChainLogDetail additional = 5;
     */
    additional?: UpdateUserTxOnChainLogDetail;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UpdateUserTxOnChainLogDetail
 */
export interface UpdateUserTxOnChainLogDetail {
    /**
     * 比赛唯一标识(非必填)
     *
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * 商品唯一标识(非必填)
     *
     * @generated from protobuf field: string productId = 2;
     */
    productId: string;
    /**
     * 道具唯一标识(非必填)
     *
     * @generated from protobuf field: int64 propId = 3;
     */
    propId: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UpdateUserTxOnChainLogResp
 */
export interface UpdateUserTxOnChainLogResp {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropNFTReq
 */
export interface ListUserPropNFTReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: repeated int64 propIds = 3;
     */
    propIds: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropNFT
 */
export interface ListUserPropNFT {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.NFTInfo nftInfo = 2;
     */
    nftInfo?: NFTInfo;
    /**
     * @generated from protobuf field: bool new = 3;
     */
    new: boolean;
    /**
     * @generated from protobuf field: string amount = 4;
     */
    amount: string;
    /**
     * 关联的erc20道具id
     *
     * @generated from protobuf field: int64 nftInfoLinkedErc20PropId = 5;
     */
    nftInfoLinkedErc20PropId: number;
    /**
     * 多语言名称
     *
     * @generated from protobuf field: bpt.common.I18n i18nName = 6 [json_name = "i18nName"];
     */
    i18nName?: I18n;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropNFTResp
 */
export interface ListUserPropNFTResp {
    /**
     * @generated from protobuf field: int64 totalSize = 1;
     */
    totalSize: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.ListUserPropNFT list = 2;
     */
    list: ListUserPropNFT[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropNftInternalReq
 */
export interface ListUserPropNftInternalReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 propId = 2;
     */
    propId: number;
    /**
     * @generated from protobuf field: repeated string tokenIds = 3;
     */
    tokenIds: string[];
    /**
     * @generated from protobuf field: int64 page = 4;
     */
    page: number; // 0默认不分页
    /**
     * @generated from protobuf field: int64 pageSize = 5;
     */
    pageSize: number; // 0默认不分页
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropNftInternalResp
 */
export interface ListUserPropNftInternalResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.ListUserPropNFT list = 1;
     */
    list: ListUserPropNFT[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number; // 不分页 默认0
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetStatsReq
 */
export interface GetStatsReq {
    /**
     * 资源ID
     *
     * @generated from protobuf field: int64 ResID = 1 [json_name = "ResID"];
     */
    ResID: number;
    /**
     * 资源类型
     *
     * @generated from protobuf field: bpt.hall.prop.v1.ResType type = 2;
     */
    type: ResType;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetStatsResp
 */
export interface GetStatsResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Stats stats = 1;
     */
    stats?: Stats;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetPropGawResp
 */
export interface GetPropGawResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.Prop info = 1;
     */
    info: Prop[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.Stats
 */
export interface Stats {
    /**
     * 资源ID
     *
     * @generated from protobuf field: int64 ResID = 1 [json_name = "ResID"];
     */
    ResID: number;
    /**
     * 接受数量
     *
     * @generated from protobuf field: int64 acceptNum = 2;
     */
    acceptNum: number;
    /**
     * 过期数量
     *
     * @generated from protobuf field: int64 expiredNum = 3;
     */
    expiredNum: number;
    /**
     * 使用数量
     *
     * @generated from protobuf field: int64 usedNum = 4;
     */
    usedNum: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.Badge
 */
export interface Badge {
    /**
     * @generated from protobuf field: int64 amount = 1;
     */
    amount: number;
    /**
     * @generated from protobuf field: string key = 2;
     */
    key: string;
}
// 
// enum BadgeType {
// BadgeTypeUnknown = 0;
// BadgeTypeVoucher = 1;
// BadgeTypeTicket  = 2;
// BadgeTypeProp    = 3;
// }

/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserBadgeReq
 */
export interface GetUserBadgeReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserBadgeReq
 */
export interface ListUserBadgeReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserBadgeResp
 */
export interface GetUserBadgeResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Badge badge = 1;
     */
    badge?: Badge;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserBadgeResp
 */
export interface ListUserBadgeResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.Badge badges = 1;
     */
    badges: Badge[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserBadgeReq
 */
export interface CleanUpUserBadgeReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string badgeKey = 2;
     */
    badgeKey: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserBadgeResp
 */
export interface CleanUpUserBadgeResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Badge badge = 1;
     */
    badge?: Badge;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CheckUserPropExpiredReq
 */
export interface CheckUserPropExpiredReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated bpt.common.PropCategory categories = 2;
     */
    categories: PropCategory[]; // 按照分类
    /**
     * @generated from protobuf field: repeated bpt.common.PropType types = 3;
     */
    types: PropType[]; // 按照功能类型
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Block block = 4;
     */
    block?: Block;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CheckUserPropExpiredResp
 */
export interface CheckUserPropExpiredResp {
    /**
     * @generated from protobuf field: bool hasExpired = 1;
     */
    hasExpired: boolean; // 是否有过期的道具
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListGiftReq
 */
export interface ListGiftReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.State state = 3;
     */
    state: State;
    /**
     * @generated from protobuf field: string keyword = 4;
     */
    keyword: string;
    /**
     * @generated from protobuf field: repeated int64 ids = 5;
     */
    ids: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetGiftReq
 */
export interface GetGiftReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserGiftReq
 */
export interface IncUserGiftReq {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UserChange change = 1;
     */
    change?: UserChange;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserGiftResp
 */
export interface IncUserGiftResp {
    /**
     * 增加的道具
     *
     * @generated from protobuf field: repeated bpt.hall.common.DynamicAssetItems assets = 1;
     */
    assets: DynamicAssetItems[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.DecUserGiftReq
 */
export interface DecUserGiftReq {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UserChange change = 1;
     */
    change?: UserChange;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.DecUserGiftResp
 */
export interface DecUserGiftResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * 本次操作的批次id
     *
     * @generated from protobuf field: string batchID = 2;
     */
    batchID: string;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.RefundUserGiftReq
 */
export interface RefundUserGiftReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 批次id
     *
     * @generated from protobuf field: string batchID = 2;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.RefundUserGiftResp
 */
export interface RefundUserGiftResp {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListPropCategoryResp
 */
export interface ListPropCategoryResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.CategoryItem categories = 1;
     */
    categories: CategoryItem[]; // 道具分类
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CategoryItem
 */
export interface CategoryItem {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number; // 分类id
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // 分类名称
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListPropTypeReq
 */
export interface ListPropTypeReq {
    /**
     * @generated from protobuf field: int64 categoryID = 1;
     */
    categoryID: number; // 道具功能类型大类ID
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListPropTypeResp
 */
export interface ListPropTypeResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.PropTypeItem types = 1;
     */
    types: PropTypeItem[]; // 道具功能类型
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.PropTypeItem
 */
export interface PropTypeItem {
    /**
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number; // 道具分类
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // 道具功能类型
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserGiftLogReq
 */
export interface ListUserGiftLogReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * @generated from protobuf field: int64 beginAt = 4;
     */
    beginAt: number;
    /**
     * @generated from protobuf field: int64 endAt = 5;
     */
    endAt: number;
    /**
     * @generated from protobuf field: int64 giftID = 6;
     */
    giftID: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserGiftLogResp
 */
export interface ListUserGiftLogResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserGiftLog logs = 1;
     */
    logs: UserGiftLog[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.HasExpiredResReq
 */
export interface HasExpiredResReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // repeated int64         categoryIDs   = 2; // 按照分类 repeated int64
    // typeIDs       = 3;                                  // 按照功能类型 Block
    // block         = 4;                                  // 屏蔽项
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.HasExpiredResResp
 */
export interface HasExpiredResResp {
    /**
     * @generated from protobuf field: bool hasPropExpired = 1;
     */
    hasPropExpired: boolean; // true: 存在过期道具， false：不存在
    /**
     * @generated from protobuf field: bool hasGiftExpired = 2;
     */
    hasGiftExpired: boolean;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserGiftReq
 */
export interface CleanUpUserGiftReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // 用户
    /**
     * @generated from protobuf field: int64 giftId = 2;
     */
    giftId: number; // id
    /**
     * @generated from protobuf field: int64 expireAt = 5;
     */
    expireAt: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.CleanUpUserGiftResp
 */
export interface CleanUpUserGiftResp {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetGiftReq
 */
export interface BatchGetGiftReq {
    /**
     * @generated from protobuf field: repeated int64 ids = 1;
     */
    ids: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateUserPropReq
 */
export interface ActivateUserPropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.common.PropType type = 2;
     */
    type: PropType;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ActivateUserPropResp
 */
export interface ActivateUserPropResp {
    /**
     * @generated from protobuf field: int64 num = 1;
     */
    num: number; // 剩余个数(暂时用不上)
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SendServiceBroadcastReq
 */
export interface SendServiceBroadcastReq {
    /**
     * @generated from protobuf field: int64 chatId = 1;
     */
    chatId: number;
    /**
     * @generated from protobuf field: string content = 2;
     */
    content: string;
    /**
     * @generated from protobuf field: int64 propID = 3;
     */
    propID: number;
    /**
     * @generated from protobuf field: int64 expireAt = 4;
     */
    expireAt: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SendServiceBroadcastResp
 */
export interface SendServiceBroadcastResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserCoinReq
 */
export interface GetUserCoinReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserCoinResp
 */
export interface GetUserCoinResp {
    /**
     * @generated from protobuf field: bpt.common.AssetItem UserCoin = 1 [json_name = "UserCoin"];
     */
    UserCoin?: AssetItem;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.DailyTrainCoinConfig
 */
export interface DailyTrainCoinConfig {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: int64 amount = 2;
     */
    amount: number;
    /**
     * 领取间隔(s)
     *
     * @generated from protobuf field: int64 receiveInterval = 3;
     */
    receiveInterval: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetDailyTrainingCoinConfigResp
 */
export interface GetDailyTrainingCoinConfigResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.DailyTrainCoinConfig conf = 1;
     */
    conf?: DailyTrainCoinConfig;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SaveDailyTrainingCoinConfigReq
 */
export interface SaveDailyTrainingCoinConfigReq {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.DailyTrainCoinConfig conf = 1;
     */
    conf?: DailyTrainCoinConfig;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetDailyTrainingCoinReq
 */
export interface GetDailyTrainingCoinReq {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetDailyTrainingCoinResp
 */
export interface GetDailyTrainingCoinResp {
    /**
     * @generated from protobuf field: bpt.common.AssetItem trainCoin = 1;
     */
    trainCoin?: AssetItem;
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 2;
     */
    code: ErrorCode;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoReq
 */
export interface GetUserDailyTrainingCoinInfoReq {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoResp
 */
export interface GetUserDailyTrainingCoinInfoResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.DailyTrainCoinConfig conf = 1;
     */
    conf?: DailyTrainCoinConfig;
    /**
     * @generated from protobuf field: int64 lastReceiveTime = 2;
     */
    lastReceiveTime: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.OnChainAssetsChangeItem
 */
export interface OnChainAssetsChangeItem {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: string amount = 2;
     */
    amount: string;
    /**
     * Deprecated: use itemExtra
     *
     * @generated from protobuf field: string extra = 4;
     */
    extra: string; // 扩展json信息
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.OnChainAssetsChangeItemExtra itemExtra = 5;
     */
    itemExtra?: OnChainAssetsChangeItemExtra;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.OnChainAssetsChangeItemExtra
 */
export interface OnChainAssetsChangeItemExtra {
    /**
     * @generated from protobuf field: string tokenId = 1;
     */
    tokenId: string;
    /**
     * @generated from protobuf field: int64 expriedTime = 2;
     */
    expriedTime: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp
 */
export interface OnChainAssetsAssetsChangeStamp {
    /**
     * @generated from protobuf field: bpt.common.Way way = 1;
     */
    way: Way;
    /**
     * @generated from protobuf field: string operator = 2;
     */
    operator: string; // 变更人
    /**
     * @generated from protobuf field: bpt.common.GrantType grantType = 3;
     */
    grantType: GrantType$; // 发放方式
    /**
     * @generated from protobuf field: map<string, string> scene = 4;
     */
    scene: {
        [key: string]: string;
    }; // 变动场景
    /**
     * @generated from protobuf field: string tradeID = 5;
     */
    tradeID: string; // 交易ID
    /**
     * @generated from protobuf field: bpt.common.TxOnChainDetail tradeInfo = 6;
     */
    tradeInfo?: TxOnChainDetail; // 交易信息
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserOnChainAssetsReq
 */
export interface IncUserOnChainAssetsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.OnChainAssetsChangeItem item = 2;
     */
    item?: OnChainAssetsChangeItem;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp = 3;
     */
    stamp?: OnChainAssetsAssetsChangeStamp;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserOnChainAssetsResp
 */
export interface IncUserOnChainAssetsResp {
    /**
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string; // 操作的批次id
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.DecUserOnChainAssetsReq
 */
export interface DecUserOnChainAssetsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.OnChainAssetsChangeItem item = 2;
     */
    item?: OnChainAssetsChangeItem;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp = 3;
     */
    stamp?: OnChainAssetsAssetsChangeStamp;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.DecUserOnChainAssetsResp
 */
export interface DecUserOnChainAssetsResp {
    /**
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string; // 操作的批次id
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogReq
 */
export interface IncUserSendOnChainAssetsTxLogReq {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: string amount = 2;
     */
    amount: string;
    /**
     * @generated from protobuf field: string txHash = 3;
     */
    txHash: string;
    /**
     * @generated from protobuf field: gaw.asset.wallet.v1.ERC20Info erc20info = 5 [json_name = "erc20info"];
     */
    erc20info?: ERC20Info;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogResp
 */
export interface IncUserSendOnChainAssetsTxLogResp {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserPropBagAssetsReq
 */
export interface IncUserPropBagAssetsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.OnChainAssetsChangeItem item = 2;
     */
    item?: OnChainAssetsChangeItem;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp = 3;
     */
    stamp?: OnChainAssetsAssetsChangeStamp;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserPropBagAssetsResp
 */
export interface IncUserPropBagAssetsResp {
    /**
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string; // 操作的批次id
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserAssetsByMallBuyReq
 */
export interface IncUserAssetsByMallBuyReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // 用户UID
    /**
     * @generated from protobuf field: int64 propId = 2;
     */
    propId: number; // 商品道具ID
    /**
     * @generated from protobuf field: int64 num = 3;
     */
    num: number; // 数量
    /**
     * @generated from protobuf field: string tradeID = 4;
     */
    tradeID: string; // 交易ID
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.IncUserAssetsByMallBuyTradeInfo tradeInfo = 5;
     */
    tradeInfo?: IncUserAssetsByMallBuyTradeInfo; // 交易信息
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserAssetsByMallBuyTradeInfo
 */
export interface IncUserAssetsByMallBuyTradeInfo {
    /**
     * @generated from protobuf field: string txHash = 1;
     */
    txHash: string; // 链上交易哈希
    /**
     * @generated from protobuf field: string contractAddr = 2;
     */
    contractAddr: string; // 合约地址
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 3;
     */
    chainID: ChainID; // 链ID
    /**
     * @generated from protobuf field: uint64 timestamp = 4;
     */
    timestamp: number; // 时间戳
    /**
     * @generated from protobuf field: string productId = 5;
     */
    productId: string; // 商品ID
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.PriceInfo txOnChainPrice = 6;
     */
    txOnChainPrice?: PriceInfo; // 链上交易货币金额
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.IncUserAssetsByMallBuyResp
 */
export interface IncUserAssetsByMallBuyResp {
    /**
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string; // 操作的批次id
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropBagReq
 */
export interface GetUserPropBagReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropBagResp
 */
export interface GetUserPropBagResp {
    /**
     * @generated from protobuf field: int64 totalSize = 1;
     */
    totalSize: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserPropBagInfo list = 2;
     */
    list: UserPropBagInfo[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserPropBagInfo
 */
export interface UserPropBagInfo {
    /**
     * 道具id
     *
     * @generated from protobuf field: int64 propID = 1;
     */
    propID: number;
    /**
     * 数量-Category=(PropCategoryNormal) 使用，其他类型不
     *
     * @generated from protobuf field: int64 num = 2;
     */
    num: number;
    /**
     * 过期时间
     *
     * @generated from protobuf field: int64 expireAt = 3;
     */
    expireAt: number;
    /**
     * 道具分类
     *
     * @generated from protobuf field: bpt.common.PropCategory category = 4;
     */
    category: PropCategory;
    /**
     * 道具功能类型
     *
     * @generated from protobuf field: bpt.common.PropType type = 5;
     */
    type: PropType;
    /**
     * 金额数量-Category=(PropCategoryVirtualCoin,PropCategoryNFT) 使用，其他类型不
     *
     * @generated from protobuf field: string amount = 6;
     */
    amount: string;
    /**
     * 是否免费
     *
     * @generated from protobuf field: bool isFree = 7;
     */
    isFree: boolean;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropOnTableReq
 */
export interface GetUserPropOnTableReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string tableKey = 3;
     */
    tableKey: string;
    /**
     * 互动-PropTypeInteractive(不传默认互动) 或者 礼物-PropTypeGift
     *
     * @generated from protobuf field: bpt.common.PropType propType = 4;
     */
    propType: PropType;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropOnTableResp
 */
export interface GetUserPropOnTableResp {
    /**
     * @generated from protobuf field: int64 totalSize = 1;
     */
    totalSize: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserPropBagInfo list = 2;
     */
    list: UserPropBagInfo[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableReq
 */
export interface GetUserSelfUsablePropsOnTableReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * 不为空(推送到当前Table消息)
     *
     * @generated from protobuf field: string tableKey = 3;
     */
    tableKey: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableResp
 */
export interface GetUserSelfUsablePropsOnTableResp {
    /**
     * @generated from protobuf field: int64 totalSize = 1;
     */
    totalSize: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserPropBagInfo list = 2;
     */
    list: UserPropBagInfo[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UseInteractivePropReq
 */
export interface UseInteractivePropReq {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number; // 道具ID
    /**
     * @generated from protobuf field: int64 targetUid = 3;
     */
    targetUid: number;
    /**
     * @generated from protobuf field: int32 quantity = 4;
     */
    quantity: number; // 数量(聚合用户多次点击时，数量累加)
    /**
     * @generated from protobuf field: string matchKey = 5;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UsePropPlayerTableSeatInfo playerTableSeatInfo = 6;
     */
    playerTableSeatInfo?: UsePropPlayerTableSeatInfo; // 使用道具相关用户的桌子座位信息
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UsePropPlayerTableSeatInfo
 */
export interface UsePropPlayerTableSeatInfo {
    /**
     * 使用人的位置(前端推送使用)
     *
     * @generated from protobuf field: int32 playerSeatId = 1;
     */
    playerSeatId: number;
    /**
     * 目标用户的位置ID(前端推送使用)
     *
     * @generated from protobuf field: int32 targetPlayerSeatId = 2;
     */
    targetPlayerSeatId: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UseInteractivePropResp
 */
export interface UseInteractivePropResp {
    /**
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SendGiftReq
 */
export interface SendGiftReq {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number; // 道具ID
    /**
     * @generated from protobuf field: int64 receiverUid = 3;
     */
    receiverUid: number; // 接收者ID
    /**
     * @generated from protobuf field: int32 quantity = 4;
     */
    quantity: number; // 礼物数量
    /**
     * @generated from protobuf field: string matchKey = 5;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UsePropPlayerTableSeatInfo playerTableSeatInfo = 6;
     */
    playerTableSeatInfo?: UsePropPlayerTableSeatInfo; // 使用道具相关用户的桌子座位信息
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SendGiftResp
 */
export interface SendGiftResp {
    /**
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UsePropOnSelfReq
 */
export interface UsePropOnSelfReq {
    /**
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number;
    /**
     * @generated from protobuf field: string tableKey = 3;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int32 quantity = 5;
     */
    quantity: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UsePropAdditionalInfo additional = 6;
     */
    additional?: UsePropAdditionalInfo;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UsePropAdditionalInfo
 */
export interface UsePropAdditionalInfo {
    /**
     * RNG洗牌使用信息
     *
     * @generated from protobuf field: bpt.hall.prop.v1.UsePropRNGShuffleCardParam rngshuffleCard = 1;
     */
    rngshuffleCard?: UsePropRNGShuffleCardParam;
}
/**
 * RNGShuffleCard使用参数
 *
 * @generated from protobuf message bpt.hall.prop.v1.UsePropRNGShuffleCardParam
 */
export interface UsePropRNGShuffleCardParam {
    /**
     * @generated from protobuf field: string text = 1;
     */
    text: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UsePropOnSelfResp
 */
export interface UsePropOnSelfResp {
    /**
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropActivityLogReq
 */
export interface ListUserPropActivityLogReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 beginAt = 3;
     */
    beginAt: number;
    /**
     * @generated from protobuf field: int64 endAt = 4;
     */
    endAt: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UserPropActivityType activityType = 5;
     */
    activityType: UserPropActivityType;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.ListUserPropActivityLogResp
 */
export interface ListUserPropActivityLogResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.prop.v1.UserPropActivityLog list = 1;
     */
    list: UserPropActivityLog[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropNumReq
 */
export interface GetUserPropNumReq {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetUserPropNumResp
 */
export interface GetUserPropNumResp {
    /**
     * @generated from protobuf field: int64 num = 1;
     */
    num: number;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserPropNumReq
 */
export interface BatchGetUserPropNumReq {
    /**
     * @generated from protobuf field: repeated int64 propIdList = 1;
     */
    propIdList: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetUserPropNumResp
 */
export interface BatchGetUserPropNumResp {
    /**
     * @generated from protobuf field: map<int64, int64> propIdToNum = 1;
     */
    propIdToNum: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UserPropActivityLog
 */
export interface UserPropActivityLog {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.UserPropActivityType activityType = 2;
     */
    activityType: UserPropActivityType;
    /**
     * @generated from protobuf field: int64 quantity = 3;
     */
    quantity: number;
    /**
     * @generated from protobuf field: bpt.common.Sign sign = 4;
     */
    sign: Sign;
    /**
     * @generated from protobuf field: uint64 createTime = 5;
     */
    createTime: number;
    /**
     * 目标用户ID（如果适用，比如送礼、收礼）
     *
     * @generated from protobuf field: int64 targetUid = 8;
     */
    targetUid: number;
    /**
     * 链上交易价值
     *
     * @generated from protobuf field: bpt.hall.prop.v1.PriceInfo txOnChainPrice = 9;
     */
    txOnChainPrice?: PriceInfo;
    /**
     * 比赛KEY
     *
     * @generated from protobuf field: string matchKey = 10;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.FreezePropReq
 */
export interface FreezePropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // 用户id
    /**
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number; // 道具id
    /**
     * @generated from protobuf field: int64 num = 3;
     */
    num: number; // 数量
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.UnFreezePropReq
 */
export interface UnFreezePropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // 用户id
    /**
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number; // 道具id
    /**
     * @generated from protobuf field: int64 num = 3;
     */
    num: number; // 数量
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.SellDelPropReq
 */
export interface SellDelPropReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number; // 用户id
    /**
     * @generated from protobuf field: int64 propID = 2;
     */
    propID: number; // 道具id
    /**
     * @generated from protobuf field: int64 num = 3;
     */
    num: number; // 数量
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BaseResp
 */
export interface BaseResp {
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetPropInternalReq
 */
export interface GetPropInternalReq {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.GetPropInternalReq.FilterSource filterSource = 2;
     */
    filterSource: GetPropInternalReq_FilterSource;
}
/**
 * 过滤来源，不为None将检查对应来源包含的类型，请求ID非对应类型返回错误
 *
 * @generated from protobuf enum bpt.hall.prop.v1.GetPropInternalReq.FilterSource
 */
export enum GetPropInternalReq_FilterSource {
    /**
     * 默认0不检查
     *
     * @generated from protobuf enum value: None = 0;
     */
    None = 0,
    /**
     * 商城
     *
     * @generated from protobuf enum value: Mall = 1;
     */
    Mall = 1
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.GetPropInternalResp
 */
export interface GetPropInternalResp {
    /**
     * @generated from protobuf field: bpt.hall.prop.v1.Prop prop = 1;
     */
    prop?: Prop;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.AdminSendPropReq
 */
export interface AdminSendPropReq {
    /**
     * 发放到用户的UID
     *
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 发放的道具ID(只支PropCategoryNormal)
     *
     * @generated from protobuf field: int64 propId = 2;
     */
    propId: number;
    /**
     * 发放数量
     *
     * @generated from protobuf field: int64 num = 3;
     */
    num: number;
    /**
     * 发放原因
     *
     * @generated from protobuf field: string reason = 4;
     */
    reason: string;
    /**
     * 操作用户
     *
     * @generated from protobuf field: string operator = 5;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.AdminSendPropResp
 */
export interface AdminSendPropResp {
    /**
     * @generated from protobuf field: string batchId = 1;
     */
    batchId: string;
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetPropPriceInternalReq
 */
export interface BatchGetPropPriceInternalReq {
    /**
     * @generated from protobuf field: repeated int64 propIdList = 1;
     */
    propIdList: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetPropPriceInternalResp
 */
export interface BatchGetPropPriceInternalResp {
    /**
     * @generated from protobuf field: map<int64, bpt.hall.prop.v1.PriceInfo> propIdToPriceInfo = 1;
     */
    propIdToPriceInfo: {
        [key: string]: PriceInfo;
    };
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetPropMTTPriceInternalReq
 */
export interface BatchGetPropMTTPriceInternalReq {
    /**
     * @generated from protobuf field: repeated int64 propIdList = 1;
     */
    propIdList: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.BatchGetPropMTTPriceInternalResp
 */
export interface BatchGetPropMTTPriceInternalResp {
    /**
     * 道具ID对应的MTT价值
     *
     * @generated from protobuf field: map<int64, bpt.hall.prop.v1.MTTPriceInfo> propIdToMTTPriceInfo = 1;
     */
    propIdToMTTPriceInfo: {
        [key: string]: MTTPriceInfo;
    };
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.FilterOffChainPropReq
 */
export interface FilterOffChainPropReq {
    /**
     * @generated from protobuf field: repeated int64 propIdList = 1;
     */
    propIdList: number[];
}
/**
 * @generated from protobuf message bpt.hall.prop.v1.FilterOffChainPropResp
 */
export interface FilterOffChainPropResp {
    /**
     * 道具ID对应的MTT价值
     *
     * @generated from protobuf field: map<int64, bpt.hall.prop.v1.Prop> offChainProp = 1;
     */
    offChainProp: {
        [key: string]: Prop;
    };
}
/**
 * 对应的MTT价值
 *
 * @generated from protobuf message bpt.hall.prop.v1.MTTPriceInfo
 */
export interface MTTPriceInfo {
    /**
     * 对应MTT价值的RawAmount
     *
     * @generated from protobuf field: string mttRawAmount = 1;
     */
    mttRawAmount: string;
    /**
     * 对应MTT价值的MTT Decimals
     *
     * @generated from protobuf field: uint32 mttDecimals = 2;
     */
    mttDecimals: number;
    /**
     * 是否NFT
     *
     * @generated from protobuf field: bool isNFT = 3;
     */
    isNFT: boolean;
    /**
     * 当前Prop的Decimals
     *
     * @generated from protobuf field: uint32 propDecimals = 4;
     */
    propDecimals: number;
    /**
     * 当前Prop是否就是MTT
     *
     * @generated from protobuf field: bool isMTT = 5;
     */
    isMTT: boolean;
}
/**
 * @generated from protobuf enum bpt.hall.prop.v1.State
 */
export enum State {
    /**
     * @generated from protobuf enum value: PropStateUnknown = 0;
     */
    PropStateUnknown = 0,
    /**
     * @generated from protobuf enum value: PropStateEnabled = 1;
     */
    PropStateEnabled = 1,
    /**
     * @generated from protobuf enum value: PropStateDisabled = 2;
     */
    PropStateDisabled = 2
}
/**
 * 使用方式
 *
 * @generated from protobuf enum bpt.hall.prop.v1.UsageType
 */
export enum UsageType {
    /**
     * @generated from protobuf enum value: UsageTypeUnknown = 0;
     */
    UsageTypeUnknown = 0,
    /**
     * 直接使用
     *
     * @generated from protobuf enum value: UsageTypeDirectly = 1;
     */
    UsageTypeDirectly = 1,
    /**
     * 跳转使用
     *
     * @generated from protobuf enum value: UsageTypeJump = 2;
     */
    UsageTypeJump = 2,
    /**
     * 不能直接使用也不能跳转
     *
     * @generated from protobuf enum value: UsageTypeNoneAction = 3;
     */
    UsageTypeNoneAction = 3,
    /**
     * 跳转兑换
     *
     * @generated from protobuf enum value: UsageTypeJumpExchange = 4;
     */
    UsageTypeJumpExchange = 4
}
/**
 * 发放道具的方式
 * deprecated:use bpt.common.GrantType
 *
 * @generated from protobuf enum bpt.hall.prop.v1.GrantType
 */
export enum GrantType {
    /**
     * 直接发放
     *
     * @generated from protobuf enum value: GrantTypeDirectly = 0;
     */
    GrantTypeDirectly = 0,
    /**
     * 通过邮件发放
     *
     * @generated from protobuf enum value: GrantTypeByMail = 1;
     */
    GrantTypeByMail = 1,
    /**
     * 通过商城购买
     *
     * @generated from protobuf enum value: GrantTypeByMallBuy = 2;
     */
    GrantTypeByMallBuy = 2
}
/**
 * @generated from protobuf enum bpt.hall.prop.v1.GiftOpenMethodType
 */
export enum GiftOpenMethodType {
    /**
     * @generated from protobuf enum value: GiftOpenMethodTypeUnknown = 0;
     */
    GiftOpenMethodTypeUnknown = 0,
    /**
     * 自动打开
     *
     * @generated from protobuf enum value: GiftOpenMethodTypeAuto = 1;
     */
    GiftOpenMethodTypeAuto = 1,
    /**
     * 手动打开
     *
     * @generated from protobuf enum value: GiftOpenMethodTypeManual = 2;
     */
    GiftOpenMethodTypeManual = 2
}
/**
 * @generated from protobuf enum bpt.hall.prop.v1.QueryTab
 */
export enum QueryTab {
    /**
     * @generated from protobuf enum value: TabUnknown = 0;
     */
    TabUnknown = 0,
    /**
     * @generated from protobuf enum value: TabRegular = 1;
     */
    TabRegular = 1,
    /**
     * @generated from protobuf enum value: TabVoucher = 2;
     */
    TabVoucher = 2
}
/**
 * @generated from protobuf enum bpt.hall.prop.v1.UserPropState
 */
export enum UserPropState {
    /**
     * @generated from protobuf enum value: UserPropStateUnknown = 0;
     */
    UserPropStateUnknown = 0,
    /**
     * @generated from protobuf enum value: UserPropStateNormal = 1;
     */
    UserPropStateNormal = 1,
    /**
     * @generated from protobuf enum value: UserPropStateDeleted = 2;
     */
    UserPropStateDeleted = 2
}
/**
 * @generated from protobuf enum bpt.hall.prop.v1.CleanUpOption
 */
export enum CleanUpOption {
    /**
     * 丢弃
     *
     * @generated from protobuf enum value: CleanUpOptDiscard = 0;
     */
    CleanUpOptDiscard = 0,
    /**
     * 回收
     *
     * @generated from protobuf enum value: CleanUpOptRecycle = 1;
     */
    CleanUpOptRecycle = 1
}
/**
 * @generated from protobuf enum bpt.hall.prop.v1.ResType
 */
export enum ResType {
    /**
     * @generated from protobuf enum value: ResTypeUnknown = 0;
     */
    ResTypeUnknown = 0,
    /**
     * 道具资源
     *
     * @generated from protobuf enum value: ResTypeProp = 1;
     */
    ResTypeProp = 1,
    /**
     * 礼包资源
     *
     * @generated from protobuf enum value: ResTypeGift = 2;
     */
    ResTypeGift = 2
}
/**
 * @generated from protobuf enum bpt.hall.prop.v1.UserPropActivityType
 */
export enum UserPropActivityType {
    /**
     * @generated from protobuf enum value: ActivityType_None = 0;
     */
    ActivityType_None = 0,
    /**
     * 使用 -- matchKey
     *
     * @generated from protobuf enum value: ActivityType_Use = 1;
     */
    ActivityType_Use = 1,
    /**
     * 购买 -- onChainPrice
     *
     * @generated from protobuf enum value: ActivityType_Buy = 2;
     */
    ActivityType_Buy = 2,
    /**
     * 出售 -- onChainPrice
     *
     * @generated from protobuf enum value: ActivityType_Sell = 3;
     */
    ActivityType_Sell = 3,
    /**
     * 收礼 -- targetUid
     *
     * @generated from protobuf enum value: ActivityType_Receive = 4;
     */
    ActivityType_Receive = 4,
    /**
     * 送礼 -- targetUid
     *
     * @generated from protobuf enum value: ActivityType_Send = 5;
     */
    ActivityType_Send = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class NotifyGiftOpenResultResp$Type extends MessageType<NotifyGiftOpenResultResp> {
    constructor() {
        super("bpt.hall.prop.v1.NotifyGiftOpenResultResp", [
            { no: 1, name: "results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<NotifyGiftOpenResultResp>): NotifyGiftOpenResultResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.results = [];
        if (value !== undefined)
            reflectionMergePartial<NotifyGiftOpenResultResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotifyGiftOpenResultResp): NotifyGiftOpenResultResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem results */ 1:
                    message.results.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotifyGiftOpenResultResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem results = 1; */
        for (let i = 0; i < message.results.length; i++)
            AssetItem.internalBinaryWrite(message.results[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.NotifyGiftOpenResultResp
 */
export const NotifyGiftOpenResultResp = new NotifyGiftOpenResultResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserPropBadgeReq$Type extends MessageType<CleanUpUserPropBadgeReq> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserPropBadgeReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] },
            { no: 4, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CleanUpUserPropBadgeReq>): CleanUpUserPropBadgeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propId = 0;
        message.type = 0;
        message.expireAt = 0;
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserPropBadgeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserPropBadgeReq): CleanUpUserPropBadgeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propId */ 2:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* int64 expireAt */ 4:
                    message.expireAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CleanUpUserPropBadgeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propId = 2; */
        if (message.propId !== 0)
            writer.tag(2, WireType.Varint).int64(message.propId);
        /* bpt.common.PropType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* int64 expireAt = 4; */
        if (message.expireAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.expireAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserPropBadgeReq
 */
export const CleanUpUserPropBadgeReq = new CleanUpUserPropBadgeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckUserExpiredPropCallbackReq$Type extends MessageType<CheckUserExpiredPropCallbackReq> {
    constructor() {
        super("bpt.hall.prop.v1.CheckUserExpiredPropCallbackReq", [
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "resId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "resType", kind: "enum", T: () => ["bpt.hall.prop.v1.ResType", ResType] }
        ]);
    }
    create(value?: PartialMessage<CheckUserExpiredPropCallbackReq>): CheckUserExpiredPropCallbackReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.resId = 0;
        message.expireAt = 0;
        message.resType = 0;
        if (value !== undefined)
            reflectionMergePartial<CheckUserExpiredPropCallbackReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckUserExpiredPropCallbackReq): CheckUserExpiredPropCallbackReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 resId */ 3:
                    message.resId = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 4:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.ResType resType */ 5:
                    message.resType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckUserExpiredPropCallbackReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* int64 resId = 3; */
        if (message.resId !== 0)
            writer.tag(3, WireType.Varint).int64(message.resId);
        /* int64 expireAt = 4; */
        if (message.expireAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.expireAt);
        /* bpt.hall.prop.v1.ResType resType = 5; */
        if (message.resType !== 0)
            writer.tag(5, WireType.Varint).int32(message.resType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CheckUserExpiredPropCallbackReq
 */
export const CheckUserExpiredPropCallbackReq = new CheckUserExpiredPropCallbackReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VisualEffects$Type extends MessageType<VisualEffects> {
    constructor() {
        super("bpt.hall.prop.v1.VisualEffects", [
            { no: 1, name: "preview", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VisualEffects>): VisualEffects {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.preview = "";
        if (value !== undefined)
            reflectionMergePartial<VisualEffects>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VisualEffects): VisualEffects {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string preview */ 1:
                    message.preview = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VisualEffects, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string preview = 1; */
        if (message.preview !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.preview);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.VisualEffects
 */
export const VisualEffects = new VisualEffects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SoundEffects$Type extends MessageType<SoundEffects> {
    constructor() {
        super("bpt.hall.prop.v1.SoundEffects", [
            { no: 1, name: "preview", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SoundEffects>): SoundEffects {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.preview = "";
        if (value !== undefined)
            reflectionMergePartial<SoundEffects>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SoundEffects): SoundEffects {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string preview */ 1:
                    message.preview = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SoundEffects, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string preview = 1; */
        if (message.preview !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.preview);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SoundEffects
 */
export const SoundEffects = new SoundEffects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AnimationEffects$Type extends MessageType<AnimationEffects> {
    constructor() {
        super("bpt.hall.prop.v1.AnimationEffects", [
            { no: 1, name: "resourceType", kind: "enum", T: () => ["bpt.hall.prop.v1.AnimationEffects.ResourceType", AnimationEffects_ResourceType] }
        ]);
    }
    create(value?: PartialMessage<AnimationEffects>): AnimationEffects {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.resourceType = 0;
        if (value !== undefined)
            reflectionMergePartial<AnimationEffects>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AnimationEffects): AnimationEffects {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.AnimationEffects.ResourceType resourceType */ 1:
                    message.resourceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AnimationEffects, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.AnimationEffects.ResourceType resourceType = 1; */
        if (message.resourceType !== 0)
            writer.tag(1, WireType.Varint).int32(message.resourceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.AnimationEffects
 */
export const AnimationEffects = new AnimationEffects$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VoicePack$Type extends MessageType<VoicePack> {
    constructor() {
        super("bpt.hall.prop.v1.VoicePack", [
            { no: 1, name: "allIn", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "raise", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bet", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "call", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "fold", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "check", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "trial", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<VoicePack>): VoicePack {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.allIn = "";
        message.raise = "";
        message.bet = "";
        message.call = "";
        message.fold = "";
        message.check = "";
        message.trial = "";
        if (value !== undefined)
            reflectionMergePartial<VoicePack>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: VoicePack): VoicePack {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string allIn */ 1:
                    message.allIn = reader.string();
                    break;
                case /* string raise */ 2:
                    message.raise = reader.string();
                    break;
                case /* string bet */ 3:
                    message.bet = reader.string();
                    break;
                case /* string call */ 4:
                    message.call = reader.string();
                    break;
                case /* string fold */ 5:
                    message.fold = reader.string();
                    break;
                case /* string check */ 6:
                    message.check = reader.string();
                    break;
                case /* string trial */ 7:
                    message.trial = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: VoicePack, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string allIn = 1; */
        if (message.allIn !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.allIn);
        /* string raise = 2; */
        if (message.raise !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.raise);
        /* string bet = 3; */
        if (message.bet !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.bet);
        /* string call = 4; */
        if (message.call !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.call);
        /* string fold = 5; */
        if (message.fold !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.fold);
        /* string check = 6; */
        if (message.check !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.check);
        /* string trial = 7; */
        if (message.trial !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.trial);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.VoicePack
 */
export const VoicePack = new VoicePack$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Prop$Type extends MessageType<Prop> {
    constructor() {
        super("bpt.hall.prop.v1.Prop", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "base", kind: "message", T: () => BaseProp },
            { no: 3, name: "buff", kind: "message", T: () => BuffProp },
            { no: 5, name: "coupon", kind: "message", T: () => CouponProp },
            { no: 6, name: "intimacy", kind: "message", T: () => IntimacyProp },
            { no: 7, name: "popularity", kind: "message", T: () => PopularityProp },
            { no: 8, name: "gawCoinProp", kind: "message", T: () => GAWCoinProp },
            { no: 9, name: "erc20info", kind: "message", localName: "erc20info", jsonName: "erc20info", T: () => ERC20Info },
            { no: 10, name: "nftInfo", kind: "message", T: () => NFTInfo },
            { no: 11, name: "nftInfoLinkedErc20PropId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "voicePack", kind: "message", T: () => VoicePack },
            { no: 13, name: "propPrice", kind: "message", T: () => PriceInfo },
            { no: 14, name: "recyclePrice", kind: "message", T: () => PriceInfo },
            { no: 15, name: "visualEffects", kind: "message", T: () => VisualEffects },
            { no: 16, name: "soundEffects", kind: "message", T: () => SoundEffects },
            { no: 17, name: "animationEffects", kind: "message", T: () => AnimationEffects }
        ]);
    }
    create(value?: PartialMessage<Prop>): Prop {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.nftInfoLinkedErc20PropId = 0;
        if (value !== undefined)
            reflectionMergePartial<Prop>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Prop): Prop {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.BaseProp base */ 2:
                    message.base = BaseProp.internalBinaryRead(reader, reader.uint32(), options, message.base);
                    break;
                case /* bpt.hall.prop.v1.BuffProp buff */ 3:
                    message.buff = BuffProp.internalBinaryRead(reader, reader.uint32(), options, message.buff);
                    break;
                case /* bpt.hall.prop.v1.CouponProp coupon = 5 [deprecated = true];*/ 5:
                    message.coupon = CouponProp.internalBinaryRead(reader, reader.uint32(), options, message.coupon);
                    break;
                case /* bpt.hall.prop.v1.IntimacyProp intimacy = 6 [deprecated = true];*/ 6:
                    message.intimacy = IntimacyProp.internalBinaryRead(reader, reader.uint32(), options, message.intimacy);
                    break;
                case /* bpt.hall.prop.v1.PopularityProp popularity = 7 [deprecated = true];*/ 7:
                    message.popularity = PopularityProp.internalBinaryRead(reader, reader.uint32(), options, message.popularity);
                    break;
                case /* bpt.hall.prop.v1.GAWCoinProp gawCoinProp */ 8:
                    message.gawCoinProp = GAWCoinProp.internalBinaryRead(reader, reader.uint32(), options, message.gawCoinProp);
                    break;
                case /* gaw.asset.wallet.v1.ERC20Info erc20info = 9 [json_name = "erc20info"];*/ 9:
                    message.erc20info = ERC20Info.internalBinaryRead(reader, reader.uint32(), options, message.erc20info);
                    break;
                case /* gaw.asset.wallet.v1.NFTInfo nftInfo */ 10:
                    message.nftInfo = NFTInfo.internalBinaryRead(reader, reader.uint32(), options, message.nftInfo);
                    break;
                case /* int64 nftInfoLinkedErc20PropId */ 11:
                    message.nftInfoLinkedErc20PropId = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.VoicePack voicePack */ 12:
                    message.voicePack = VoicePack.internalBinaryRead(reader, reader.uint32(), options, message.voicePack);
                    break;
                case /* bpt.hall.prop.v1.PriceInfo propPrice */ 13:
                    message.propPrice = PriceInfo.internalBinaryRead(reader, reader.uint32(), options, message.propPrice);
                    break;
                case /* bpt.hall.prop.v1.PriceInfo recyclePrice */ 14:
                    message.recyclePrice = PriceInfo.internalBinaryRead(reader, reader.uint32(), options, message.recyclePrice);
                    break;
                case /* bpt.hall.prop.v1.VisualEffects visualEffects */ 15:
                    message.visualEffects = VisualEffects.internalBinaryRead(reader, reader.uint32(), options, message.visualEffects);
                    break;
                case /* bpt.hall.prop.v1.SoundEffects soundEffects */ 16:
                    message.soundEffects = SoundEffects.internalBinaryRead(reader, reader.uint32(), options, message.soundEffects);
                    break;
                case /* bpt.hall.prop.v1.AnimationEffects animationEffects */ 17:
                    message.animationEffects = AnimationEffects.internalBinaryRead(reader, reader.uint32(), options, message.animationEffects);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Prop, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* bpt.hall.prop.v1.BaseProp base = 2; */
        if (message.base)
            BaseProp.internalBinaryWrite(message.base, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.BuffProp buff = 3; */
        if (message.buff)
            BuffProp.internalBinaryWrite(message.buff, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.CouponProp coupon = 5 [deprecated = true]; */
        if (message.coupon)
            CouponProp.internalBinaryWrite(message.coupon, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.IntimacyProp intimacy = 6 [deprecated = true]; */
        if (message.intimacy)
            IntimacyProp.internalBinaryWrite(message.intimacy, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.PopularityProp popularity = 7 [deprecated = true]; */
        if (message.popularity)
            PopularityProp.internalBinaryWrite(message.popularity, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.GAWCoinProp gawCoinProp = 8; */
        if (message.gawCoinProp)
            GAWCoinProp.internalBinaryWrite(message.gawCoinProp, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.ERC20Info erc20info = 9 [json_name = "erc20info"]; */
        if (message.erc20info)
            ERC20Info.internalBinaryWrite(message.erc20info, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.NFTInfo nftInfo = 10; */
        if (message.nftInfo)
            NFTInfo.internalBinaryWrite(message.nftInfo, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int64 nftInfoLinkedErc20PropId = 11; */
        if (message.nftInfoLinkedErc20PropId !== 0)
            writer.tag(11, WireType.Varint).int64(message.nftInfoLinkedErc20PropId);
        /* bpt.hall.prop.v1.VoicePack voicePack = 12; */
        if (message.voicePack)
            VoicePack.internalBinaryWrite(message.voicePack, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.PriceInfo propPrice = 13; */
        if (message.propPrice)
            PriceInfo.internalBinaryWrite(message.propPrice, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.PriceInfo recyclePrice = 14; */
        if (message.recyclePrice)
            PriceInfo.internalBinaryWrite(message.recyclePrice, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.VisualEffects visualEffects = 15; */
        if (message.visualEffects)
            VisualEffects.internalBinaryWrite(message.visualEffects, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.SoundEffects soundEffects = 16; */
        if (message.soundEffects)
            SoundEffects.internalBinaryWrite(message.soundEffects, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.AnimationEffects animationEffects = 17; */
        if (message.animationEffects)
            AnimationEffects.internalBinaryWrite(message.animationEffects, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.Prop
 */
export const Prop = new Prop$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceInfo$Type extends MessageType<PriceInfo> {
    constructor() {
        super("bpt.hall.prop.v1.PriceInfo", [
            { no: 1, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "linkedErc20PropId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PriceInfo>): PriceInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rawAmount = "";
        message.linkedErc20PropId = 0;
        if (value !== undefined)
            reflectionMergePartial<PriceInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceInfo): PriceInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string rawAmount */ 1:
                    message.rawAmount = reader.string();
                    break;
                case /* int64 linkedErc20PropId */ 2:
                    message.linkedErc20PropId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PriceInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string rawAmount = 1; */
        if (message.rawAmount !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.rawAmount);
        /* int64 linkedErc20PropId = 2; */
        if (message.linkedErc20PropId !== 0)
            writer.tag(2, WireType.Varint).int64(message.linkedErc20PropId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.PriceInfo
 */
export const PriceInfo = new PriceInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SimplyProp$Type extends MessageType<SimplyProp> {
    constructor() {
        super("bpt.hall.prop.v1.SimplyProp", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "category", kind: "enum", T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "erc20info", kind: "message", localName: "erc20info", jsonName: "erc20info", T: () => ERC20Info },
            { no: 6, name: "nftInfo", kind: "message", T: () => NFTInfo }
        ]);
    }
    create(value?: PartialMessage<SimplyProp>): SimplyProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.category = 0;
        message.type = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<SimplyProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SimplyProp): SimplyProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropCategory category */ 2:
                    message.category = reader.int32();
                    break;
                case /* bpt.common.PropType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.ERC20Info erc20info = 5 [json_name = "erc20info"];*/ 5:
                    message.erc20info = ERC20Info.internalBinaryRead(reader, reader.uint32(), options, message.erc20info);
                    break;
                case /* gaw.asset.wallet.v1.NFTInfo nftInfo */ 6:
                    message.nftInfo = NFTInfo.internalBinaryRead(reader, reader.uint32(), options, message.nftInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SimplyProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* bpt.common.PropCategory category = 2; */
        if (message.category !== 0)
            writer.tag(2, WireType.Varint).int32(message.category);
        /* bpt.common.PropType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* gaw.asset.wallet.v1.ERC20Info erc20info = 5 [json_name = "erc20info"]; */
        if (message.erc20info)
            ERC20Info.internalBinaryWrite(message.erc20info, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.NFTInfo nftInfo = 6; */
        if (message.nftInfo)
            NFTInfo.internalBinaryWrite(message.nftInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SimplyProp
 */
export const SimplyProp = new SimplyProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IntimacyProp$Type extends MessageType<IntimacyProp> {
    constructor() {
        super("bpt.hall.prop.v1.IntimacyProp", [
            { no: 7, name: "intimacy", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<IntimacyProp>): IntimacyProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.intimacy = 0;
        if (value !== undefined)
            reflectionMergePartial<IntimacyProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IntimacyProp): IntimacyProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 intimacy */ 7:
                    message.intimacy = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IntimacyProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 intimacy = 7; */
        if (message.intimacy !== 0)
            writer.tag(7, WireType.Varint).int64(message.intimacy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IntimacyProp
 */
export const IntimacyProp = new IntimacyProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BaseProp$Type extends MessageType<BaseProp> {
    constructor() {
        super("bpt.hall.prop.v1.BaseProp", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "category", kind: "enum", T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "img", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "price", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "state", kind: "enum", T: () => ["bpt.hall.prop.v1.State", State] },
            { no: 11, name: "isStack", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "createAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "usage", kind: "message", T: () => Usage },
            { no: 16, name: "referDiamond", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "referPrice", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 18, name: "canSend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 19, name: "discardCompensation", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 20, name: "usableLevel", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "usablePremium", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "referMung", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "recycleAble", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 24, name: "isInvisibleForUser", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 25, name: "isInvisibleForCMS", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 26, name: "i18nName", kind: "message", localName: "i18nName", jsonName: "i18nName", T: () => I18n },
            { no: 27, name: "canBeUsedForFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<BaseProp>): BaseProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.category = 0;
        message.type = 0;
        message.name = "";
        message.desc = "";
        message.img = "";
        message.icon = "";
        message.price = 0;
        message.tags = [];
        message.state = 0;
        message.isStack = false;
        message.createAt = 0;
        message.updateAt = 0;
        message.operator = "";
        message.referDiamond = 0;
        message.referPrice = 0;
        message.canSend = false;
        message.discardCompensation = 0;
        message.usableLevel = 0;
        message.usablePremium = false;
        message.referMung = 0;
        message.recycleAble = false;
        message.isInvisibleForUser = false;
        message.isInvisibleForCMS = false;
        message.canBeUsedForFree = false;
        if (value !== undefined)
            reflectionMergePartial<BaseProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BaseProp): BaseProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropCategory category */ 2:
                    message.category = reader.int32();
                    break;
                case /* bpt.common.PropType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string desc */ 5:
                    message.desc = reader.string();
                    break;
                case /* string img */ 6:
                    message.img = reader.string();
                    break;
                case /* string icon */ 7:
                    message.icon = reader.string();
                    break;
                case /* int32 price */ 8:
                    message.price = reader.int32();
                    break;
                case /* repeated string tags */ 9:
                    message.tags.push(reader.string());
                    break;
                case /* bpt.hall.prop.v1.State state */ 10:
                    message.state = reader.int32();
                    break;
                case /* bool isStack = 11 [deprecated = true];*/ 11:
                    message.isStack = reader.bool();
                    break;
                case /* int64 createAt */ 12:
                    message.createAt = reader.int64().toNumber();
                    break;
                case /* int64 updateAt */ 13:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* string operator */ 14:
                    message.operator = reader.string();
                    break;
                case /* bpt.hall.prop.v1.Usage usage = 15 [deprecated = true];*/ 15:
                    message.usage = Usage.internalBinaryRead(reader, reader.uint32(), options, message.usage);
                    break;
                case /* int32 referDiamond = 16 [deprecated = true];*/ 16:
                    message.referDiamond = reader.int32();
                    break;
                case /* int32 referPrice = 17 [deprecated = true];*/ 17:
                    message.referPrice = reader.int32();
                    break;
                case /* bool canSend = 18 [deprecated = true];*/ 18:
                    message.canSend = reader.bool();
                    break;
                case /* int32 discardCompensation = 19 [deprecated = true];*/ 19:
                    message.discardCompensation = reader.int32();
                    break;
                case /* int32 usableLevel = 20 [deprecated = true];*/ 20:
                    message.usableLevel = reader.int32();
                    break;
                case /* bool usablePremium = 21 [deprecated = true];*/ 21:
                    message.usablePremium = reader.bool();
                    break;
                case /* int32 referMung = 22 [deprecated = true];*/ 22:
                    message.referMung = reader.int32();
                    break;
                case /* bool recycleAble = 23 [deprecated = true];*/ 23:
                    message.recycleAble = reader.bool();
                    break;
                case /* bool isInvisibleForUser */ 24:
                    message.isInvisibleForUser = reader.bool();
                    break;
                case /* bool isInvisibleForCMS */ 25:
                    message.isInvisibleForCMS = reader.bool();
                    break;
                case /* bpt.common.I18n i18nName = 26 [json_name = "i18nName"];*/ 26:
                    message.i18nName = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18nName);
                    break;
                case /* bool canBeUsedForFree */ 27:
                    message.canBeUsedForFree = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BaseProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* bpt.common.PropCategory category = 2; */
        if (message.category !== 0)
            writer.tag(2, WireType.Varint).int32(message.category);
        /* bpt.common.PropType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string desc = 5; */
        if (message.desc !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.desc);
        /* string img = 6; */
        if (message.img !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.img);
        /* string icon = 7; */
        if (message.icon !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.icon);
        /* int32 price = 8; */
        if (message.price !== 0)
            writer.tag(8, WireType.Varint).int32(message.price);
        /* repeated string tags = 9; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.tags[i]);
        /* bpt.hall.prop.v1.State state = 10; */
        if (message.state !== 0)
            writer.tag(10, WireType.Varint).int32(message.state);
        /* bool isStack = 11 [deprecated = true]; */
        if (message.isStack !== false)
            writer.tag(11, WireType.Varint).bool(message.isStack);
        /* int64 createAt = 12; */
        if (message.createAt !== 0)
            writer.tag(12, WireType.Varint).int64(message.createAt);
        /* int64 updateAt = 13; */
        if (message.updateAt !== 0)
            writer.tag(13, WireType.Varint).int64(message.updateAt);
        /* string operator = 14; */
        if (message.operator !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.operator);
        /* bpt.hall.prop.v1.Usage usage = 15 [deprecated = true]; */
        if (message.usage)
            Usage.internalBinaryWrite(message.usage, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* int32 referDiamond = 16 [deprecated = true]; */
        if (message.referDiamond !== 0)
            writer.tag(16, WireType.Varint).int32(message.referDiamond);
        /* int32 referPrice = 17 [deprecated = true]; */
        if (message.referPrice !== 0)
            writer.tag(17, WireType.Varint).int32(message.referPrice);
        /* bool canSend = 18 [deprecated = true]; */
        if (message.canSend !== false)
            writer.tag(18, WireType.Varint).bool(message.canSend);
        /* int32 discardCompensation = 19 [deprecated = true]; */
        if (message.discardCompensation !== 0)
            writer.tag(19, WireType.Varint).int32(message.discardCompensation);
        /* int32 usableLevel = 20 [deprecated = true]; */
        if (message.usableLevel !== 0)
            writer.tag(20, WireType.Varint).int32(message.usableLevel);
        /* bool usablePremium = 21 [deprecated = true]; */
        if (message.usablePremium !== false)
            writer.tag(21, WireType.Varint).bool(message.usablePremium);
        /* int32 referMung = 22 [deprecated = true]; */
        if (message.referMung !== 0)
            writer.tag(22, WireType.Varint).int32(message.referMung);
        /* bool recycleAble = 23 [deprecated = true]; */
        if (message.recycleAble !== false)
            writer.tag(23, WireType.Varint).bool(message.recycleAble);
        /* bool isInvisibleForUser = 24; */
        if (message.isInvisibleForUser !== false)
            writer.tag(24, WireType.Varint).bool(message.isInvisibleForUser);
        /* bool isInvisibleForCMS = 25; */
        if (message.isInvisibleForCMS !== false)
            writer.tag(25, WireType.Varint).bool(message.isInvisibleForCMS);
        /* bpt.common.I18n i18nName = 26 [json_name = "i18nName"]; */
        if (message.i18nName)
            I18n.internalBinaryWrite(message.i18nName, writer.tag(26, WireType.LengthDelimited).fork(), options).join();
        /* bool canBeUsedForFree = 27; */
        if (message.canBeUsedForFree !== false)
            writer.tag(27, WireType.Varint).bool(message.canBeUsedForFree);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BaseProp
 */
export const BaseProp = new BaseProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuffProp$Type extends MessageType<BuffProp> {
    constructor() {
        super("bpt.hall.prop.v1.BuffProp", [
            { no: 1, name: "duration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "markupRate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "buffExtraConf", kind: "message", T: () => BuffExtraConf }
        ]);
    }
    create(value?: PartialMessage<BuffProp>): BuffProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.duration = 0;
        message.count = 0;
        message.markupRate = 0;
        if (value !== undefined)
            reflectionMergePartial<BuffProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuffProp): BuffProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 duration */ 1:
                    message.duration = reader.int64().toNumber();
                    break;
                case /* int32 count */ 2:
                    message.count = reader.int32();
                    break;
                case /* int64 markupRate */ 3:
                    message.markupRate = reader.int64().toNumber();
                    break;
                case /* bpt.hall.common.BuffExtraConf buffExtraConf */ 4:
                    message.buffExtraConf = BuffExtraConf.internalBinaryRead(reader, reader.uint32(), options, message.buffExtraConf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuffProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 duration = 1; */
        if (message.duration !== 0)
            writer.tag(1, WireType.Varint).int64(message.duration);
        /* int32 count = 2; */
        if (message.count !== 0)
            writer.tag(2, WireType.Varint).int32(message.count);
        /* int64 markupRate = 3; */
        if (message.markupRate !== 0)
            writer.tag(3, WireType.Varint).int64(message.markupRate);
        /* bpt.hall.common.BuffExtraConf buffExtraConf = 4; */
        if (message.buffExtraConf)
            BuffExtraConf.internalBinaryWrite(message.buffExtraConf, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BuffProp
 */
export const BuffProp = new BuffProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CouponProp$Type extends MessageType<CouponProp> {
    constructor() {
        super("bpt.hall.prop.v1.CouponProp", [
            { no: 1, name: "moneyAmount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "discount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "deduction", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "maxDeduction", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CouponProp>): CouponProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.moneyAmount = 0;
        message.discount = 0;
        message.deduction = 0;
        message.maxDeduction = 0;
        message.startAt = 0;
        message.endAt = 0;
        if (value !== undefined)
            reflectionMergePartial<CouponProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CouponProp): CouponProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 moneyAmount */ 1:
                    message.moneyAmount = reader.int64().toNumber();
                    break;
                case /* int64 discount */ 2:
                    message.discount = reader.int64().toNumber();
                    break;
                case /* int64 deduction */ 3:
                    message.deduction = reader.int64().toNumber();
                    break;
                case /* int64 maxDeduction */ 4:
                    message.maxDeduction = reader.int64().toNumber();
                    break;
                case /* int64 startAt */ 5:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 6:
                    message.endAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CouponProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 moneyAmount = 1; */
        if (message.moneyAmount !== 0)
            writer.tag(1, WireType.Varint).int64(message.moneyAmount);
        /* int64 discount = 2; */
        if (message.discount !== 0)
            writer.tag(2, WireType.Varint).int64(message.discount);
        /* int64 deduction = 3; */
        if (message.deduction !== 0)
            writer.tag(3, WireType.Varint).int64(message.deduction);
        /* int64 maxDeduction = 4; */
        if (message.maxDeduction !== 0)
            writer.tag(4, WireType.Varint).int64(message.maxDeduction);
        /* int64 startAt = 5; */
        if (message.startAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.startAt);
        /* int64 endAt = 6; */
        if (message.endAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.endAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CouponProp
 */
export const CouponProp = new CouponProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PopularityProp$Type extends MessageType<PopularityProp> {
    constructor() {
        super("bpt.hall.prop.v1.PopularityProp", [
            { no: 1, name: "IsOpenWithdraw", kind: "scalar", localName: "IsOpenWithdraw", jsonName: "IsOpenWithdraw", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "popularity", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PopularityProp>): PopularityProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.IsOpenWithdraw = false;
        message.popularity = 0;
        if (value !== undefined)
            reflectionMergePartial<PopularityProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PopularityProp): PopularityProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool IsOpenWithdraw = 1 [json_name = "IsOpenWithdraw"];*/ 1:
                    message.IsOpenWithdraw = reader.bool();
                    break;
                case /* int64 popularity */ 2:
                    message.popularity = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PopularityProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool IsOpenWithdraw = 1 [json_name = "IsOpenWithdraw"]; */
        if (message.IsOpenWithdraw !== false)
            writer.tag(1, WireType.Varint).bool(message.IsOpenWithdraw);
        /* int64 popularity = 2; */
        if (message.popularity !== 0)
            writer.tag(2, WireType.Varint).int64(message.popularity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.PopularityProp
 */
export const PopularityProp = new PopularityProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GAWCoinProp$Type extends MessageType<GAWCoinProp> {
    constructor() {
        super("bpt.hall.prop.v1.GAWCoinProp", [
            { no: 1, name: "type", kind: "enum", T: () => ["bpt.common.TokenType", TokenType] },
            { no: 2, name: "contactAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "decimals", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "unit", kind: "enum", T: () => ["gaw.asset.wallet.v1.Unit", Unit] },
            { no: 7, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 8, name: "symbol", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "logo", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GAWCoinProp>): GAWCoinProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        message.contactAddr = "";
        message.amount = 0;
        message.decimals = 0;
        message.unit = 0;
        message.chainID = 0;
        message.symbol = "";
        message.logo = "";
        if (value !== undefined)
            reflectionMergePartial<GAWCoinProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GAWCoinProp): GAWCoinProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.TokenType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string contactAddr */ 2:
                    message.contactAddr = reader.string();
                    break;
                case /* int64 amount */ 4:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* int32 decimals */ 5:
                    message.decimals = reader.int32();
                    break;
                case /* gaw.asset.wallet.v1.Unit unit */ 6:
                    message.unit = reader.int32();
                    break;
                case /* gaw.base.ChainID chainID */ 7:
                    message.chainID = reader.int32();
                    break;
                case /* string symbol */ 8:
                    message.symbol = reader.string();
                    break;
                case /* string logo */ 9:
                    message.logo = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GAWCoinProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.TokenType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string contactAddr = 2; */
        if (message.contactAddr !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contactAddr);
        /* int64 amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Varint).int64(message.amount);
        /* int32 decimals = 5; */
        if (message.decimals !== 0)
            writer.tag(5, WireType.Varint).int32(message.decimals);
        /* gaw.asset.wallet.v1.Unit unit = 6; */
        if (message.unit !== 0)
            writer.tag(6, WireType.Varint).int32(message.unit);
        /* gaw.base.ChainID chainID = 7; */
        if (message.chainID !== 0)
            writer.tag(7, WireType.Varint).int32(message.chainID);
        /* string symbol = 8; */
        if (message.symbol !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.symbol);
        /* string logo = 9; */
        if (message.logo !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.logo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GAWCoinProp
 */
export const GAWCoinProp = new GAWCoinProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Usage$Type extends MessageType<Usage> {
    constructor() {
        super("bpt.hall.prop.v1.Usage", [
            { no: 1, name: "Type", kind: "enum", localName: "Type", jsonName: "Type", T: () => ["bpt.hall.prop.v1.UsageType", UsageType] },
            { no: 2, name: "Desc", kind: "scalar", localName: "Desc", jsonName: "Desc", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Usage>): Usage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.Type = 0;
        message.Desc = "";
        message.url = "";
        if (value !== undefined)
            reflectionMergePartial<Usage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Usage): Usage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.UsageType Type = 1 [json_name = "Type"];*/ 1:
                    message.Type = reader.int32();
                    break;
                case /* string Desc = 2 [json_name = "Desc"];*/ 2:
                    message.Desc = reader.string();
                    break;
                case /* string url */ 3:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Usage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.UsageType Type = 1 [json_name = "Type"]; */
        if (message.Type !== 0)
            writer.tag(1, WireType.Varint).int32(message.Type);
        /* string Desc = 2 [json_name = "Desc"]; */
        if (message.Desc !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.Desc);
        /* string url = 3; */
        if (message.url !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.Usage
 */
export const Usage = new Usage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SavePropReq$Type extends MessageType<SavePropReq> {
    constructor() {
        super("bpt.hall.prop.v1.SavePropReq", [
            { no: 1, name: "prop", kind: "message", T: () => Prop }
        ]);
    }
    create(value?: PartialMessage<SavePropReq>): SavePropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SavePropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SavePropReq): SavePropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.Prop prop */ 1:
                    message.prop = Prop.internalBinaryRead(reader, reader.uint32(), options, message.prop);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SavePropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.Prop prop = 1; */
        if (message.prop)
            Prop.internalBinaryWrite(message.prop, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SavePropReq
 */
export const SavePropReq = new SavePropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SavePropResp$Type extends MessageType<SavePropResp> {
    constructor() {
        super("bpt.hall.prop.v1.SavePropResp", [
            { no: 1, name: "prop", kind: "message", T: () => Prop }
        ]);
    }
    create(value?: PartialMessage<SavePropResp>): SavePropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SavePropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SavePropResp): SavePropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.Prop prop */ 1:
                    message.prop = Prop.internalBinaryRead(reader, reader.uint32(), options, message.prop);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SavePropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.Prop prop = 1; */
        if (message.prop)
            Prop.internalBinaryWrite(message.prop, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SavePropResp
 */
export const SavePropResp = new SavePropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetPropReq$Type extends MessageType<BatchGetPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetPropReq", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetPropReq>): BatchGetPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetPropReq): BatchGetPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int64().toNumber());
                    else
                        message.ids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int64(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetPropReq
 */
export const BatchGetPropReq = new BatchGetPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetPropResp$Type extends MessageType<BatchGetPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetPropResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Prop }
        ]);
    }
    create(value?: PartialMessage<BatchGetPropResp>): BatchGetPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetPropResp): BatchGetPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.Prop props */ 1:
                    message.props.push(Prop.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.Prop props = 1; */
        for (let i = 0; i < message.props.length; i++)
            Prop.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetPropResp
 */
export const BatchGetPropResp = new BatchGetPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPropReq$Type extends MessageType<ListPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListPropReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] },
            { no: 4, name: "keyword", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "category", kind: "enum", T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 6, name: "invisible", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListPropReq>): ListPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.types = [];
        message.keyword = "";
        message.category = 0;
        message.invisible = false;
        if (value !== undefined)
            reflectionMergePartial<ListPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPropReq): ListPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropType types */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* string keyword */ 4:
                    message.keyword = reader.string();
                    break;
                case /* bpt.common.PropCategory category */ 5:
                    message.category = reader.int32();
                    break;
                case /* bool invisible */ 6:
                    message.invisible = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* repeated bpt.common.PropType types = 3; */
        if (message.types.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* string keyword = 4; */
        if (message.keyword !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.keyword);
        /* bpt.common.PropCategory category = 5; */
        if (message.category !== 0)
            writer.tag(5, WireType.Varint).int32(message.category);
        /* bool invisible = 6; */
        if (message.invisible !== false)
            writer.tag(6, WireType.Varint).bool(message.invisible);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListPropReq
 */
export const ListPropReq = new ListPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPropResp$Type extends MessageType<ListPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListPropResp", [
            { no: 1, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Prop }
        ]);
    }
    create(value?: PartialMessage<ListPropResp>): ListPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalSize = 0;
        message.props = [];
        if (value !== undefined)
            reflectionMergePartial<ListPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPropResp): ListPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalSize */ 1:
                    message.totalSize = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.prop.v1.Prop props */ 2:
                    message.props.push(Prop.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalSize = 1; */
        if (message.totalSize !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalSize);
        /* repeated bpt.hall.prop.v1.Prop props = 2; */
        for (let i = 0; i < message.props.length; i++)
            Prop.internalBinaryWrite(message.props[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListPropResp
 */
export const ListPropResp = new ListPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSimplyPropReq$Type extends MessageType<ListSimplyPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListSimplyPropReq", [
            { no: 1, name: "category", kind: "enum", T: () => ["bpt.common.PropCategory", PropCategory] }
        ]);
    }
    create(value?: PartialMessage<ListSimplyPropReq>): ListSimplyPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.category = 0;
        if (value !== undefined)
            reflectionMergePartial<ListSimplyPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSimplyPropReq): ListSimplyPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.PropCategory category */ 1:
                    message.category = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSimplyPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.PropCategory category = 1; */
        if (message.category !== 0)
            writer.tag(1, WireType.Varint).int32(message.category);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListSimplyPropReq
 */
export const ListSimplyPropReq = new ListSimplyPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSimplyPropResp$Type extends MessageType<ListSimplyPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListSimplyPropResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimplyProp }
        ]);
    }
    create(value?: PartialMessage<ListSimplyPropResp>): ListSimplyPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        if (value !== undefined)
            reflectionMergePartial<ListSimplyPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSimplyPropResp): ListSimplyPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.SimplyProp props */ 1:
                    message.props.push(SimplyProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSimplyPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.SimplyProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            SimplyProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListSimplyPropResp
 */
export const ListSimplyPropResp = new ListSimplyPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPropReq$Type extends MessageType<GetPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetPropReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetPropReq>): GetPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPropReq): GetPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetPropReq
 */
export const GetPropReq = new GetPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPropResp$Type extends MessageType<GetPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetPropResp", [
            { no: 1, name: "prop", kind: "message", T: () => Prop }
        ]);
    }
    create(value?: PartialMessage<GetPropResp>): GetPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPropResp): GetPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.Prop prop */ 1:
                    message.prop = Prop.internalBinaryRead(reader, reader.uint32(), options, message.prop);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.Prop prop = 1; */
        if (message.prop)
            Prop.internalBinaryWrite(message.prop, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetPropResp
 */
export const GetPropResp = new GetPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserProp$Type extends MessageType<UserProp> {
    constructor() {
        super("bpt.hall.prop.v1.UserProp", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "category", kind: "enum", T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 6, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] },
            { no: 7, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "img", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "grantedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "usage", kind: "message", T: () => Usage },
            { no: 13, name: "showTag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "badgeNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "state", kind: "enum", T: () => ["bpt.hall.prop.v1.State", State] },
            { no: 16, name: "objectID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 41, name: "buff", kind: "message", T: () => BuffProp },
            { no: 42, name: "coupon", kind: "message", T: () => CouponProp },
            { no: 43, name: "gitfItems", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 44, name: "canSend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 45, name: "discardCompensation", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 46, name: "shortcut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 47, name: "numInShortcut", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 48, name: "referMung", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 49, name: "recycleAble", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UserProp>): UserProp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.num = 0;
        message.expireAt = 0;
        message.category = 0;
        message.type = 0;
        message.name = "";
        message.desc = "";
        message.img = "";
        message.icon = "";
        message.grantedAt = 0;
        message.showTag = "";
        message.badgeNum = 0;
        message.state = 0;
        message.objectID = "";
        message.gitfItems = [];
        message.canSend = false;
        message.discardCompensation = 0;
        message.shortcut = false;
        message.numInShortcut = 0;
        message.referMung = 0;
        message.recycleAble = false;
        if (value !== undefined)
            reflectionMergePartial<UserProp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserProp): UserProp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 4:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropCategory category */ 5:
                    message.category = reader.int32();
                    break;
                case /* bpt.common.PropType type */ 6:
                    message.type = reader.int32();
                    break;
                case /* string name */ 7:
                    message.name = reader.string();
                    break;
                case /* string desc */ 8:
                    message.desc = reader.string();
                    break;
                case /* string img */ 9:
                    message.img = reader.string();
                    break;
                case /* string icon */ 10:
                    message.icon = reader.string();
                    break;
                case /* int64 grantedAt */ 11:
                    message.grantedAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.Usage usage */ 12:
                    message.usage = Usage.internalBinaryRead(reader, reader.uint32(), options, message.usage);
                    break;
                case /* string showTag */ 13:
                    message.showTag = reader.string();
                    break;
                case /* int64 badgeNum */ 14:
                    message.badgeNum = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.State state */ 15:
                    message.state = reader.int32();
                    break;
                case /* string objectID */ 16:
                    message.objectID = reader.string();
                    break;
                case /* bpt.hall.prop.v1.BuffProp buff */ 41:
                    message.buff = BuffProp.internalBinaryRead(reader, reader.uint32(), options, message.buff);
                    break;
                case /* bpt.hall.prop.v1.CouponProp coupon */ 42:
                    message.coupon = CouponProp.internalBinaryRead(reader, reader.uint32(), options, message.coupon);
                    break;
                case /* repeated bpt.common.AssetItem gitfItems */ 43:
                    message.gitfItems.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bool canSend */ 44:
                    message.canSend = reader.bool();
                    break;
                case /* int32 discardCompensation */ 45:
                    message.discardCompensation = reader.int32();
                    break;
                case /* bool shortcut */ 46:
                    message.shortcut = reader.bool();
                    break;
                case /* int64 numInShortcut */ 47:
                    message.numInShortcut = reader.int64().toNumber();
                    break;
                case /* int32 referMung */ 48:
                    message.referMung = reader.int32();
                    break;
                case /* bool recycleAble */ 49:
                    message.recycleAble = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserProp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        /* int64 expireAt = 4; */
        if (message.expireAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.expireAt);
        /* bpt.common.PropCategory category = 5; */
        if (message.category !== 0)
            writer.tag(5, WireType.Varint).int32(message.category);
        /* bpt.common.PropType type = 6; */
        if (message.type !== 0)
            writer.tag(6, WireType.Varint).int32(message.type);
        /* string name = 7; */
        if (message.name !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.name);
        /* string desc = 8; */
        if (message.desc !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.desc);
        /* string img = 9; */
        if (message.img !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.img);
        /* string icon = 10; */
        if (message.icon !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.icon);
        /* int64 grantedAt = 11; */
        if (message.grantedAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.grantedAt);
        /* bpt.hall.prop.v1.Usage usage = 12; */
        if (message.usage)
            Usage.internalBinaryWrite(message.usage, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string showTag = 13; */
        if (message.showTag !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.showTag);
        /* int64 badgeNum = 14; */
        if (message.badgeNum !== 0)
            writer.tag(14, WireType.Varint).int64(message.badgeNum);
        /* bpt.hall.prop.v1.State state = 15; */
        if (message.state !== 0)
            writer.tag(15, WireType.Varint).int32(message.state);
        /* string objectID = 16; */
        if (message.objectID !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.objectID);
        /* bpt.hall.prop.v1.BuffProp buff = 41; */
        if (message.buff)
            BuffProp.internalBinaryWrite(message.buff, writer.tag(41, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.CouponProp coupon = 42; */
        if (message.coupon)
            CouponProp.internalBinaryWrite(message.coupon, writer.tag(42, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem gitfItems = 43; */
        for (let i = 0; i < message.gitfItems.length; i++)
            AssetItem.internalBinaryWrite(message.gitfItems[i], writer.tag(43, WireType.LengthDelimited).fork(), options).join();
        /* bool canSend = 44; */
        if (message.canSend !== false)
            writer.tag(44, WireType.Varint).bool(message.canSend);
        /* int32 discardCompensation = 45; */
        if (message.discardCompensation !== 0)
            writer.tag(45, WireType.Varint).int32(message.discardCompensation);
        /* bool shortcut = 46; */
        if (message.shortcut !== false)
            writer.tag(46, WireType.Varint).bool(message.shortcut);
        /* int64 numInShortcut = 47; */
        if (message.numInShortcut !== 0)
            writer.tag(47, WireType.Varint).int64(message.numInShortcut);
        /* int32 referMung = 48; */
        if (message.referMung !== 0)
            writer.tag(48, WireType.Varint).int32(message.referMung);
        /* bool recycleAble = 49; */
        if (message.recycleAble !== false)
            writer.tag(49, WireType.Varint).bool(message.recycleAble);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserProp
 */
export const UserProp = new UserProp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserChange$Type extends MessageType<UserChange> {
    constructor() {
        super("bpt.hall.prop.v1.UserChange", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserChangeItem },
            { no: 3, name: "stamp", kind: "message", T: () => UserPropChangeStamp }
        ]);
    }
    create(value?: PartialMessage<UserChange>): UserChange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<UserChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserChange): UserChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.prop.v1.UserChangeItem items */ 2:
                    message.items.push(UserChangeItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.hall.prop.v1.UserPropChangeStamp stamp */ 3:
                    message.stamp = UserPropChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.hall.prop.v1.UserChangeItem items = 2; */
        for (let i = 0; i < message.items.length; i++)
            UserChangeItem.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.UserPropChangeStamp stamp = 3; */
        if (message.stamp)
            UserPropChangeStamp.internalBinaryWrite(message.stamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserChange
 */
export const UserChange = new UserChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserChangeItem$Type extends MessageType<UserChangeItem> {
    constructor() {
        super("bpt.hall.prop.v1.UserChangeItem", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expire", kind: "message", T: () => ExpireData },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "extra", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserChangeItem>): UserChangeItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.num = 0;
        message.extra = "";
        if (value !== undefined)
            reflectionMergePartial<UserChangeItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserChangeItem): UserChangeItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* bpt.common.ExpireData expire */ 2:
                    message.expire = ExpireData.internalBinaryRead(reader, reader.uint32(), options, message.expire);
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                case /* string extra */ 4:
                    message.extra = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserChangeItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* bpt.common.ExpireData expire = 2; */
        if (message.expire)
            ExpireData.internalBinaryWrite(message.expire, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        /* string extra = 4; */
        if (message.extra !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.extra);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserChangeItem
 */
export const UserChangeItem = new UserChangeItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPropChangeStamp$Type extends MessageType<UserPropChangeStamp> {
    constructor() {
        super("bpt.hall.prop.v1.UserPropChangeStamp", [
            { no: 1, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] },
            { no: 2, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "grantType", kind: "enum", T: () => ["bpt.hall.prop.v1.GrantType", GrantType] },
            { no: 4, name: "scene", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<UserPropChangeStamp>): UserPropChangeStamp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.way = 0;
        message.operator = "";
        message.grantType = 0;
        message.scene = {};
        if (value !== undefined)
            reflectionMergePartial<UserPropChangeStamp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPropChangeStamp): UserPropChangeStamp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.Way way */ 1:
                    message.way = reader.int32();
                    break;
                case /* string operator */ 2:
                    message.operator = reader.string();
                    break;
                case /* bpt.hall.prop.v1.GrantType grantType */ 3:
                    message.grantType = reader.int32();
                    break;
                case /* map<string, string> scene */ 4:
                    this.binaryReadMap4(message.scene, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: UserPropChangeStamp["scene"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof UserPropChangeStamp["scene"] | undefined, val: UserPropChangeStamp["scene"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.prop.v1.UserPropChangeStamp.scene");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: UserPropChangeStamp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.Way way = 1; */
        if (message.way !== 0)
            writer.tag(1, WireType.Varint).int32(message.way);
        /* string operator = 2; */
        if (message.operator !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.operator);
        /* bpt.hall.prop.v1.GrantType grantType = 3; */
        if (message.grantType !== 0)
            writer.tag(3, WireType.Varint).int32(message.grantType);
        /* map<string, string> scene = 4; */
        for (let k of globalThis.Object.keys(message.scene))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.scene[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserPropChangeStamp
 */
export const UserPropChangeStamp = new UserPropChangeStamp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserPropReq$Type extends MessageType<IncUserPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserPropReq", [
            { no: 1, name: "change", kind: "message", T: () => UserChange }
        ]);
    }
    create(value?: PartialMessage<IncUserPropReq>): IncUserPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<IncUserPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserPropReq): IncUserPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.UserChange change */ 1:
                    message.change = UserChange.internalBinaryRead(reader, reader.uint32(), options, message.change);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.UserChange change = 1; */
        if (message.change)
            UserChange.internalBinaryWrite(message.change, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserPropReq
 */
export const IncUserPropReq = new IncUserPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserPropResp$Type extends MessageType<IncUserPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserPropResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DynamicAssetItems }
        ]);
    }
    create(value?: PartialMessage<IncUserPropResp>): IncUserPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<IncUserPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserPropResp): IncUserPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                case /* repeated bpt.hall.common.DynamicAssetItems assets */ 2:
                    message.assets.push(DynamicAssetItems.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        /* repeated bpt.hall.common.DynamicAssetItems assets = 2; */
        for (let i = 0; i < message.assets.length; i++)
            DynamicAssetItems.internalBinaryWrite(message.assets[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserPropResp
 */
export const IncUserPropResp = new IncUserPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserPropReq$Type extends MessageType<DecUserPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.DecUserPropReq", [
            { no: 1, name: "change", kind: "message", T: () => UserChange }
        ]);
    }
    create(value?: PartialMessage<DecUserPropReq>): DecUserPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DecUserPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserPropReq): DecUserPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.UserChange change */ 1:
                    message.change = UserChange.internalBinaryRead(reader, reader.uint32(), options, message.change);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.UserChange change = 1; */
        if (message.change)
            UserChange.internalBinaryWrite(message.change, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.DecUserPropReq
 */
export const DecUserPropReq = new DecUserPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserPropResp$Type extends MessageType<DecUserPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.DecUserPropResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 2, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DecUserPropResp>): DecUserPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.batchID = "";
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<DecUserPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserPropResp): DecUserPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* string batchID */ 2:
                    message.batchID = reader.string();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* string batchID = 2; */
        if (message.batchID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.batchID);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.DecUserPropResp
 */
export const DecUserPropResp = new DecUserPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundUserPropReq$Type extends MessageType<RefundUserPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.RefundUserPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefundUserPropReq>): RefundUserPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<RefundUserPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundUserPropReq): RefundUserPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string batchID */ 2:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefundUserPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string batchID = 2; */
        if (message.batchID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.RefundUserPropReq
 */
export const RefundUserPropReq = new RefundUserPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundUserPropResp$Type extends MessageType<RefundUserPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.RefundUserPropResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefundUserPropResp>): RefundUserPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<RefundUserPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundUserPropResp): RefundUserPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefundUserPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.RefundUserPropResp
 */
export const RefundUserPropResp = new RefundUserPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RenameReq$Type extends MessageType<RenameReq> {
    constructor() {
        super("bpt.hall.prop.v1.RenameReq", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RenameReq>): RenameReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.expireAt = 0;
        message.name = "";
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<RenameReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RenameReq): RenameReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 2:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* int64 uid */ 4:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RenameReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* int64 expireAt = 2; */
        if (message.expireAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expireAt);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* int64 uid = 4; */
        if (message.uid !== 0)
            writer.tag(4, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.RenameReq
 */
export const RenameReq = new RenameReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RenameResp$Type extends MessageType<RenameResp> {
    constructor() {
        super("bpt.hall.prop.v1.RenameResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] }
        ]);
    }
    create(value?: PartialMessage<RenameResp>): RenameResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<RenameResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RenameResp): RenameResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RenameResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.RenameResp
 */
export const RenameResp = new RenameResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateVipReq$Type extends MessageType<ActivateVipReq> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateVipReq", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateVipReq>): ActivateVipReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.expireAt = 0;
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivateVipReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateVipReq): ActivateVipReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 2:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateVipReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* int64 expireAt = 2; */
        if (message.expireAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expireAt);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateVipReq
 */
export const ActivateVipReq = new ActivateVipReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateVipResp$Type extends MessageType<ActivateVipResp> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateVipResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 2, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "hour", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateVipResp>): ActivateVipResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.expiredAt = 0;
        message.hour = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivateVipResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateVipResp): ActivateVipResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* int64 expiredAt */ 2:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                case /* int64 hour */ 3:
                    message.hour = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateVipResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* int64 expiredAt = 2; */
        if (message.expiredAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredAt);
        /* int64 hour = 3; */
        if (message.hour !== 0)
            writer.tag(3, WireType.Varint).int64(message.hour);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateVipResp
 */
export const ActivateVipResp = new ActivateVipResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivatePremiumCardReq$Type extends MessageType<ActivatePremiumCardReq> {
    constructor() {
        super("bpt.hall.prop.v1.ActivatePremiumCardReq", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ActivatePremiumCardReq>): ActivatePremiumCardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.expireAt = 0;
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivatePremiumCardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivatePremiumCardReq): ActivatePremiumCardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 2:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivatePremiumCardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* int64 expireAt = 2; */
        if (message.expireAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expireAt);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivatePremiumCardReq
 */
export const ActivatePremiumCardReq = new ActivatePremiumCardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivatePremiumCardResp$Type extends MessageType<ActivatePremiumCardResp> {
    constructor() {
        super("bpt.hall.prop.v1.ActivatePremiumCardResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 2, name: "expiredAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "hour", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ActivatePremiumCardResp>): ActivatePremiumCardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.expiredAt = 0;
        message.hour = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivatePremiumCardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivatePremiumCardResp): ActivatePremiumCardResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* int64 expiredAt */ 2:
                    message.expiredAt = reader.int64().toNumber();
                    break;
                case /* int64 hour */ 3:
                    message.hour = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivatePremiumCardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* int64 expiredAt = 2; */
        if (message.expiredAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expiredAt);
        /* int64 hour = 3; */
        if (message.hour !== 0)
            writer.tag(3, WireType.Varint).int64(message.hour);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivatePremiumCardResp
 */
export const ActivatePremiumCardResp = new ActivatePremiumCardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateSteakWinningProtectCardReq$Type extends MessageType<ActivateSteakWinningProtectCardReq> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateSteakWinningProtectCardReq", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "gameId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateSteakWinningProtectCardReq>): ActivateSteakWinningProtectCardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.expireAt = 0;
        message.uid = 0;
        message.gameId = "";
        if (value !== undefined)
            reflectionMergePartial<ActivateSteakWinningProtectCardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateSteakWinningProtectCardReq): ActivateSteakWinningProtectCardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 2:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string gameId */ 4:
                    message.gameId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateSteakWinningProtectCardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* int64 expireAt = 2; */
        if (message.expireAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expireAt);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* string gameId = 4; */
        if (message.gameId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.gameId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateSteakWinningProtectCardReq
 */
export const ActivateSteakWinningProtectCardReq = new ActivateSteakWinningProtectCardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateSteakWinningProtectCardResp$Type extends MessageType<ActivateSteakWinningProtectCardResp> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateSteakWinningProtectCardResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] }
        ]);
    }
    create(value?: PartialMessage<ActivateSteakWinningProtectCardResp>): ActivateSteakWinningProtectCardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivateSteakWinningProtectCardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateSteakWinningProtectCardResp): ActivateSteakWinningProtectCardResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateSteakWinningProtectCardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateSteakWinningProtectCardResp
 */
export const ActivateSteakWinningProtectCardResp = new ActivateSteakWinningProtectCardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenGiftReq$Type extends MessageType<OpenGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.OpenGiftReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<OpenGiftReq>): OpenGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.expireAt = 0;
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<OpenGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenGiftReq): OpenGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 2:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 expireAt = 2; */
        if (message.expireAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expireAt);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.OpenGiftReq
 */
export const OpenGiftReq = new OpenGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenGiftResp$Type extends MessageType<OpenGiftResp> {
    constructor() {
        super("bpt.hall.prop.v1.OpenGiftResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 4, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<OpenGiftResp>): OpenGiftResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<OpenGiftResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenGiftResp): OpenGiftResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem items */ 4:
                    message.items.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenGiftResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* repeated bpt.common.AssetItem items = 4; */
        for (let i = 0; i < message.items.length; i++)
            AssetItem.internalBinaryWrite(message.items[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.OpenGiftResp
 */
export const OpenGiftResp = new OpenGiftResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateUserBuffReq$Type extends MessageType<ActivateUserBuffReq> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateUserBuffReq", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "pType", kind: "enum", T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<ActivateUserBuffReq>): ActivateUserBuffReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.expireAt = 0;
        message.uid = 0;
        message.pType = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivateUserBuffReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateUserBuffReq): ActivateUserBuffReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 2:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropType pType */ 4:
                    message.pType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateUserBuffReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* int64 expireAt = 2; */
        if (message.expireAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.expireAt);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* bpt.common.PropType pType = 4; */
        if (message.pType !== 0)
            writer.tag(4, WireType.Varint).int32(message.pType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateUserBuffReq
 */
export const ActivateUserBuffReq = new ActivateUserBuffReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateUserBuffResp$Type extends MessageType<ActivateUserBuffResp> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateUserBuffResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 2, name: "markupRate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateUserBuffResp>): ActivateUserBuffResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.markupRate = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivateUserBuffResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateUserBuffResp): ActivateUserBuffResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* int64 markupRate */ 2:
                    message.markupRate = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateUserBuffResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* int64 markupRate = 2; */
        if (message.markupRate !== 0)
            writer.tag(2, WireType.Varint).int64(message.markupRate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateUserBuffResp
 */
export const ActivateUserBuffResp = new ActivateUserBuffResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserIntimacyReq$Type extends MessageType<IncUserIntimacyReq> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserIntimacyReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "propNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "withUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<IncUserIntimacyReq>): IncUserIntimacyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.propNum = 0;
        message.withUid = 0;
        if (value !== undefined)
            reflectionMergePartial<IncUserIntimacyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserIntimacyReq): IncUserIntimacyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 propNum */ 3:
                    message.propNum = reader.int64().toNumber();
                    break;
                case /* int64 withUid */ 4:
                    message.withUid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserIntimacyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 propNum = 3; */
        if (message.propNum !== 0)
            writer.tag(3, WireType.Varint).int64(message.propNum);
        /* int64 withUid = 4; */
        if (message.withUid !== 0)
            writer.tag(4, WireType.Varint).int64(message.withUid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserIntimacyReq
 */
export const IncUserIntimacyReq = new IncUserIntimacyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserIntimacyResp$Type extends MessageType<IncUserIntimacyResp> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserIntimacyResp", [
            { no: 1, name: "incIntimacy", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "curIntimacy", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<IncUserIntimacyResp>): IncUserIntimacyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.incIntimacy = 0;
        message.curIntimacy = 0;
        if (value !== undefined)
            reflectionMergePartial<IncUserIntimacyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserIntimacyResp): IncUserIntimacyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 incIntimacy */ 1:
                    message.incIntimacy = reader.int64().toNumber();
                    break;
                case /* int64 curIntimacy */ 2:
                    message.curIntimacy = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserIntimacyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 incIntimacy = 1; */
        if (message.incIntimacy !== 0)
            writer.tag(1, WireType.Varint).int64(message.incIntimacy);
        /* int64 curIntimacy = 2; */
        if (message.curIntimacy !== 0)
            writer.tag(2, WireType.Varint).int64(message.curIntimacy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserIntimacyResp
 */
export const IncUserIntimacyResp = new IncUserIntimacyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropGawBalanceListReq$Type extends MessageType<GetUserPropGawBalanceListReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropGawBalanceListReq", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "forceRefresh", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "userAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserPropGawBalanceListReq>): GetUserPropGawBalanceListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.forceRefresh = false;
        message.userAddress = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserPropGawBalanceListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropGawBalanceListReq): GetUserPropGawBalanceListReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* bool forceRefresh */ 2:
                    message.forceRefresh = reader.bool();
                    break;
                case /* string userAddress */ 3:
                    message.userAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropGawBalanceListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* bool forceRefresh = 2; */
        if (message.forceRefresh !== false)
            writer.tag(2, WireType.Varint).bool(message.forceRefresh);
        /* string userAddress = 3; */
        if (message.userAddress !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropGawBalanceListReq
 */
export const GetUserPropGawBalanceListReq = new GetUserPropGawBalanceListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropGawBalanceListResp$Type extends MessageType<GetUserPropGawBalanceListResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropGawBalanceListResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPropGawBalanceInfo }
        ]);
    }
    create(value?: PartialMessage<GetUserPropGawBalanceListResp>): GetUserPropGawBalanceListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserPropGawBalanceListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropGawBalanceListResp): GetUserPropGawBalanceListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserPropGawBalanceInfo list */ 1:
                    message.list.push(UserPropGawBalanceInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropGawBalanceListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserPropGawBalanceInfo list = 1; */
        for (let i = 0; i < message.list.length; i++)
            UserPropGawBalanceInfo.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropGawBalanceListResp
 */
export const GetUserPropGawBalanceListResp = new GetUserPropGawBalanceListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPropGawBalanceInfo$Type extends MessageType<UserPropGawBalanceInfo> {
    constructor() {
        super("bpt.hall.prop.v1.UserPropGawBalanceInfo", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "erc20info", kind: "message", localName: "erc20info", jsonName: "erc20info", T: () => ERC20Info },
            { no: 3, name: "balance", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserPropGawBalanceInfo>): UserPropGawBalanceInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.balance = "";
        if (value !== undefined)
            reflectionMergePartial<UserPropGawBalanceInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPropGawBalanceInfo): UserPropGawBalanceInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* gaw.asset.wallet.v1.ERC20Info erc20info = 2 [json_name = "erc20info"];*/ 2:
                    message.erc20info = ERC20Info.internalBinaryRead(reader, reader.uint32(), options, message.erc20info);
                    break;
                case /* string balance */ 3:
                    message.balance = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPropGawBalanceInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* gaw.asset.wallet.v1.ERC20Info erc20info = 2 [json_name = "erc20info"]; */
        if (message.erc20info)
            ERC20Info.internalBinaryWrite(message.erc20info, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string balance = 3; */
        if (message.balance !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.balance);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserPropGawBalanceInfo
 */
export const UserPropGawBalanceInfo = new UserPropGawBalanceInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserPropReq$Type extends MessageType<BatchGetUserPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserPropReq>): BatchGetUserPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserPropReq): BatchGetUserPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated int64 ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int64().toNumber());
                    else
                        message.ids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated int64 ids = 2; */
        if (message.ids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int64(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserPropReq
 */
export const BatchGetUserPropReq = new BatchGetUserPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserGiftReq$Type extends MessageType<BatchGetUserGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserGiftReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserGiftReq>): BatchGetUserGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserGiftReq): BatchGetUserGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated int64 ids */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int64().toNumber());
                    else
                        message.ids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated int64 ids = 2; */
        if (message.ids.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int64(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserGiftReq
 */
export const BatchGetUserGiftReq = new BatchGetUserGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserPropResp$Type extends MessageType<BatchGetUserPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserPropResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserPropResp>): BatchGetUserPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserPropResp): BatchGetUserPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp props */ 1:
                    message.props.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            UserProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserPropResp
 */
export const BatchGetUserPropResp = new BatchGetUserPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserGiftResp$Type extends MessageType<BatchGetUserGiftResp> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserGiftResp", [
            { no: 1, name: "gifts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserGiftResp>): BatchGetUserGiftResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.gifts = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserGiftResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserGiftResp): BatchGetUserGiftResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp gifts */ 1:
                    message.gifts.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserGiftResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp gifts = 1; */
        for (let i = 0; i < message.gifts.length; i++)
            UserProp.internalBinaryWrite(message.gifts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserGiftResp
 */
export const BatchGetUserGiftResp = new BatchGetUserGiftResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Block$Type extends MessageType<Block> {
    constructor() {
        super("bpt.hall.prop.v1.Block", [
            { no: 1, name: "propTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<Block>): Block {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propTypes = [];
        if (value !== undefined)
            reflectionMergePartial<Block>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Block): Block {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.PropType propTypes */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propTypes.push(reader.int32());
                    else
                        message.propTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Block, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.PropType propTypes = 1; */
        if (message.propTypes.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propTypes.length; i++)
                writer.int32(message.propTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.Block
 */
export const Block = new Block$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserResReq$Type extends MessageType<ListUserResReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserResReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "canSend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] },
            { no: 6, name: "queryTab", kind: "enum", T: () => ["bpt.hall.prop.v1.QueryTab", QueryTab] }
        ]);
    }
    create(value?: PartialMessage<ListUserResReq>): ListUserResReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.uid = 0;
        message.canSend = false;
        message.types = [];
        message.queryTab = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserResReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserResReq): ListUserResReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bool canSend */ 4:
                    message.canSend = reader.bool();
                    break;
                case /* repeated bpt.common.PropType types */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* bpt.hall.prop.v1.QueryTab queryTab */ 6:
                    message.queryTab = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserResReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* bool canSend = 4; */
        if (message.canSend !== false)
            writer.tag(4, WireType.Varint).bool(message.canSend);
        /* repeated bpt.common.PropType types = 5; */
        if (message.types.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* bpt.hall.prop.v1.QueryTab queryTab = 6; */
        if (message.queryTab !== 0)
            writer.tag(6, WireType.Varint).int32(message.queryTab);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserResReq
 */
export const ListUserResReq = new ListUserResReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserResV2Req$Type extends MessageType<ListUserResV2Req> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserResV2Req", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "canSend", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] },
            { no: 6, name: "queryTab", kind: "enum", T: () => ["bpt.hall.prop.v1.QueryTab", QueryTab] }
        ]);
    }
    create(value?: PartialMessage<ListUserResV2Req>): ListUserResV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.uid = 0;
        message.canSend = false;
        message.types = [];
        message.queryTab = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserResV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserResV2Req): ListUserResV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bool canSend */ 4:
                    message.canSend = reader.bool();
                    break;
                case /* repeated bpt.common.PropType types */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* bpt.hall.prop.v1.QueryTab queryTab */ 6:
                    message.queryTab = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserResV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* bool canSend = 4; */
        if (message.canSend !== false)
            writer.tag(4, WireType.Varint).bool(message.canSend);
        /* repeated bpt.common.PropType types = 5; */
        if (message.types.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* bpt.hall.prop.v1.QueryTab queryTab = 6; */
        if (message.queryTab !== 0)
            writer.tag(6, WireType.Varint).int32(message.queryTab);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserResV2Req
 */
export const ListUserResV2Req = new ListUserResV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpandPropDetailsReq$Type extends MessageType<ExpandPropDetailsReq> {
    constructor() {
        super("bpt.hall.prop.v1.ExpandPropDetailsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "propType", kind: "enum", T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<ExpandPropDetailsReq>): ExpandPropDetailsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.id = 0;
        message.page = 0;
        message.pageSize = 0;
        message.propType = 0;
        if (value !== undefined)
            reflectionMergePartial<ExpandPropDetailsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExpandPropDetailsReq): ExpandPropDetailsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 id */ 2:
                    message.id = reader.int64().toNumber();
                    break;
                case /* int64 page */ 3:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 4:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropType propType */ 5:
                    message.propType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExpandPropDetailsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 id = 2; */
        if (message.id !== 0)
            writer.tag(2, WireType.Varint).int64(message.id);
        /* int64 page = 3; */
        if (message.page !== 0)
            writer.tag(3, WireType.Varint).int64(message.page);
        /* int64 pageSize = 4; */
        if (message.pageSize !== 0)
            writer.tag(4, WireType.Varint).int64(message.pageSize);
        /* bpt.common.PropType propType = 5; */
        if (message.propType !== 0)
            writer.tag(5, WireType.Varint).int32(message.propType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ExpandPropDetailsReq
 */
export const ExpandPropDetailsReq = new ExpandPropDetailsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CMSListUserResReq$Type extends MessageType<CMSListUserResReq> {
    constructor() {
        super("bpt.hall.prop.v1.CMSListUserResReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CMSListUserResReq>): CMSListUserResReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<CMSListUserResReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CMSListUserResReq): CMSListUserResReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CMSListUserResReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CMSListUserResReq
 */
export const CMSListUserResReq = new CMSListUserResReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserResResp$Type extends MessageType<ListUserResResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserResResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "vouchers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp }
        ]);
    }
    create(value?: PartialMessage<ListUserResResp>): ListUserResResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        message.totalSize = 0;
        message.vouchers = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserResResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserResResp): ListUserResResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp props */ 1:
                    message.props.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.prop.v1.UserProp vouchers */ 3:
                    message.vouchers.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserResResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            UserProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        /* repeated bpt.hall.prop.v1.UserProp vouchers = 3; */
        for (let i = 0; i < message.vouchers.length; i++)
            UserProp.internalBinaryWrite(message.vouchers[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserResResp
 */
export const ListUserResResp = new ListUserResResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserResV2Resp$Type extends MessageType<ListUserResV2Resp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserResV2Resp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserResV2Resp>): ListUserResV2Resp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserResV2Resp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserResV2Resp): ListUserResV2Resp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp props */ 1:
                    message.props.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserResV2Resp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            UserProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserResV2Resp
 */
export const ListUserResV2Resp = new ListUserResV2Resp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExpandPropDetailsResp$Type extends MessageType<ExpandPropDetailsResp> {
    constructor() {
        super("bpt.hall.prop.v1.ExpandPropDetailsResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ExpandPropDetailsResp>): ExpandPropDetailsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ExpandPropDetailsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExpandPropDetailsResp): ExpandPropDetailsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp props */ 1:
                    message.props.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExpandPropDetailsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            UserProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ExpandPropDetailsResp
 */
export const ExpandPropDetailsResp = new ExpandPropDetailsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CMSListUserResResp$Type extends MessageType<CMSListUserResResp> {
    constructor() {
        super("bpt.hall.prop.v1.CMSListUserResResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CMSListUserResResp>): CMSListUserResResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<CMSListUserResResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CMSListUserResResp): CMSListUserResResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp props */ 1:
                    message.props.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CMSListUserResResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            UserProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CMSListUserResResp
 */
export const CMSListUserResResp = new CMSListUserResResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserMergedPropReq$Type extends MessageType<ListUserMergedPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserMergedPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<ListUserMergedPropReq>): ListUserMergedPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.types = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserMergedPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserMergedPropReq): ListUserMergedPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropType types */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserMergedPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.common.PropType types = 2; */
        if (message.types.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserMergedPropReq
 */
export const ListUserMergedPropReq = new ListUserMergedPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserMergedPropResp$Type extends MessageType<ListUserMergedPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserMergedPropResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp }
        ]);
    }
    create(value?: PartialMessage<ListUserMergedPropResp>): ListUserMergedPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserMergedPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserMergedPropResp): ListUserMergedPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp props */ 1:
                    message.props.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserMergedPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            UserProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserMergedPropResp
 */
export const ListUserMergedPropResp = new ListUserMergedPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserMergedPropReq$Type extends MessageType<BatchGetUserMergedPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserMergedPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propIDs", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserMergedPropReq>): BatchGetUserMergedPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propIDs = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserMergedPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserMergedPropReq): BatchGetUserMergedPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated int64 propIDs */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propIDs.push(reader.int64().toNumber());
                    else
                        message.propIDs.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserMergedPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated int64 propIDs = 2; */
        if (message.propIDs.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propIDs.length; i++)
                writer.int64(message.propIDs[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserMergedPropReq
 */
export const BatchGetUserMergedPropReq = new BatchGetUserMergedPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserMergedPropResp$Type extends MessageType<BatchGetUserMergedPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserMergedPropResp", [
            { no: 1, name: "props", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserProp }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserMergedPropResp>): BatchGetUserMergedPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.props = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserMergedPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserMergedPropResp): BatchGetUserMergedPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserProp props */ 1:
                    message.props.push(UserProp.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserMergedPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserProp props = 1; */
        for (let i = 0; i < message.props.length; i++)
            UserProp.internalBinaryWrite(message.props[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserMergedPropResp
 */
export const BatchGetUserMergedPropResp = new BatchGetUserMergedPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountUserPropReq$Type extends MessageType<CountUserPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.CountUserPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "categories", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 4, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] },
            { no: 5, name: "expireAtStart", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "expireAtEnd", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "block", kind: "message", T: () => Block }
        ]);
    }
    create(value?: PartialMessage<CountUserPropReq>): CountUserPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.categories = [];
        message.types = [];
        message.expireAtStart = 0;
        message.expireAtEnd = 0;
        if (value !== undefined)
            reflectionMergePartial<CountUserPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountUserPropReq): CountUserPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropCategory categories */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.categories.push(reader.int32());
                    else
                        message.categories.push(reader.int32());
                    break;
                case /* repeated bpt.common.PropType types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* int64 expireAtStart */ 5:
                    message.expireAtStart = reader.int64().toNumber();
                    break;
                case /* int64 expireAtEnd */ 6:
                    message.expireAtEnd = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.Block block */ 7:
                    message.block = Block.internalBinaryRead(reader, reader.uint32(), options, message.block);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountUserPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* repeated bpt.common.PropCategory categories = 3; */
        if (message.categories.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.categories.length; i++)
                writer.int32(message.categories[i]);
            writer.join();
        }
        /* repeated bpt.common.PropType types = 4; */
        if (message.types.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* int64 expireAtStart = 5; */
        if (message.expireAtStart !== 0)
            writer.tag(5, WireType.Varint).int64(message.expireAtStart);
        /* int64 expireAtEnd = 6; */
        if (message.expireAtEnd !== 0)
            writer.tag(6, WireType.Varint).int64(message.expireAtEnd);
        /* bpt.hall.prop.v1.Block block = 7; */
        if (message.block)
            Block.internalBinaryWrite(message.block, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CountUserPropReq
 */
export const CountUserPropReq = new CountUserPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountUserPropResp$Type extends MessageType<CountUserPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.CountUserPropResp", [
            { no: 1, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CountUserPropResp>): CountUserPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<CountUserPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountUserPropResp): CountUserPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalSize */ 1:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountUserPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalSize = 1; */
        if (message.totalSize !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CountUserPropResp
 */
export const CountUserPropResp = new CountUserPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountCurrentUserPropByPropTypeReq$Type extends MessageType<CountCurrentUserPropByPropTypeReq> {
    constructor() {
        super("bpt.hall.prop.v1.CountCurrentUserPropByPropTypeReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<CountCurrentUserPropByPropTypeReq>): CountCurrentUserPropByPropTypeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.types = [];
        if (value !== undefined)
            reflectionMergePartial<CountCurrentUserPropByPropTypeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountCurrentUserPropByPropTypeReq): CountCurrentUserPropByPropTypeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropType types */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountCurrentUserPropByPropTypeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.common.PropType types = 2; */
        if (message.types.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CountCurrentUserPropByPropTypeReq
 */
export const CountCurrentUserPropByPropTypeReq = new CountCurrentUserPropByPropTypeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountCurrentUserPropByPropTypeResp$Type extends MessageType<CountCurrentUserPropByPropTypeResp> {
    constructor() {
        super("bpt.hall.prop.v1.CountCurrentUserPropByPropTypeResp", [
            { no: 1, name: "cntsMap", kind: "map", K: 5 /*ScalarType.INT32*/, V: { kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ } }
        ]);
    }
    create(value?: PartialMessage<CountCurrentUserPropByPropTypeResp>): CountCurrentUserPropByPropTypeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cntsMap = {};
        if (value !== undefined)
            reflectionMergePartial<CountCurrentUserPropByPropTypeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountCurrentUserPropByPropTypeResp): CountCurrentUserPropByPropTypeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int32, int64> cntsMap */ 1:
                    this.binaryReadMap1(message.cntsMap, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: CountCurrentUserPropByPropTypeResp["cntsMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CountCurrentUserPropByPropTypeResp["cntsMap"] | undefined, val: CountCurrentUserPropByPropTypeResp["cntsMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int32();
                    break;
                case 2:
                    val = reader.int64().toNumber();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.prop.v1.CountCurrentUserPropByPropTypeResp.cntsMap");
            }
        }
        map[key ?? 0] = val ?? 0;
    }
    internalBinaryWrite(message: CountCurrentUserPropByPropTypeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int32, int64> cntsMap = 1; */
        for (let k of globalThis.Object.keys(message.cntsMap))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int32(parseInt(k)).tag(2, WireType.Varint).int64(message.cntsMap[k as any]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CountCurrentUserPropByPropTypeResp
 */
export const CountCurrentUserPropByPropTypeResp = new CountCurrentUserPropByPropTypeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountCurrentUserPropByCategoryReq$Type extends MessageType<CountCurrentUserPropByCategoryReq> {
    constructor() {
        super("bpt.hall.prop.v1.CountCurrentUserPropByCategoryReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "categories", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 3, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<CountCurrentUserPropByCategoryReq>): CountCurrentUserPropByCategoryReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.categories = [];
        message.types = [];
        if (value !== undefined)
            reflectionMergePartial<CountCurrentUserPropByCategoryReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountCurrentUserPropByCategoryReq): CountCurrentUserPropByCategoryReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropCategory categories */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.categories.push(reader.int32());
                    else
                        message.categories.push(reader.int32());
                    break;
                case /* repeated bpt.common.PropType types */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountCurrentUserPropByCategoryReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.common.PropCategory categories = 2; */
        if (message.categories.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.categories.length; i++)
                writer.int32(message.categories[i]);
            writer.join();
        }
        /* repeated bpt.common.PropType types = 3; */
        if (message.types.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CountCurrentUserPropByCategoryReq
 */
export const CountCurrentUserPropByCategoryReq = new CountCurrentUserPropByCategoryReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CategoryItemCnt$Type extends MessageType<CategoryItemCnt> {
    constructor() {
        super("bpt.hall.prop.v1.CategoryItemCnt", [
            { no: 1, name: "category", kind: "enum", T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] },
            { no: 3, name: "cnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CategoryItemCnt>): CategoryItemCnt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.category = 0;
        message.type = 0;
        message.cnt = 0;
        if (value !== undefined)
            reflectionMergePartial<CategoryItemCnt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CategoryItemCnt): CategoryItemCnt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.PropCategory category */ 1:
                    message.category = reader.int32();
                    break;
                case /* bpt.common.PropType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* int64 cnt */ 3:
                    message.cnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CategoryItemCnt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.PropCategory category = 1; */
        if (message.category !== 0)
            writer.tag(1, WireType.Varint).int32(message.category);
        /* bpt.common.PropType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* int64 cnt = 3; */
        if (message.cnt !== 0)
            writer.tag(3, WireType.Varint).int64(message.cnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CategoryItemCnt
 */
export const CategoryItemCnt = new CategoryItemCnt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CountCurrentUserPropByCategoryResp$Type extends MessageType<CountCurrentUserPropByCategoryResp> {
    constructor() {
        super("bpt.hall.prop.v1.CountCurrentUserPropByCategoryResp", [
            { no: 1, name: "itemCnts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CategoryItemCnt }
        ]);
    }
    create(value?: PartialMessage<CountCurrentUserPropByCategoryResp>): CountCurrentUserPropByCategoryResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.itemCnts = [];
        if (value !== undefined)
            reflectionMergePartial<CountCurrentUserPropByCategoryResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CountCurrentUserPropByCategoryResp): CountCurrentUserPropByCategoryResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.CategoryItemCnt itemCnts */ 1:
                    message.itemCnts.push(CategoryItemCnt.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CountCurrentUserPropByCategoryResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.CategoryItemCnt itemCnts = 1; */
        for (let i = 0; i < message.itemCnts.length; i++)
            CategoryItemCnt.internalBinaryWrite(message.itemCnts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CountCurrentUserPropByCategoryResp
 */
export const CountCurrentUserPropByCategoryResp = new CountCurrentUserPropByCategoryResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserPropReq$Type extends MessageType<CleanUpUserPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "categories", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 4, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] },
            { no: 5, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "option", kind: "enum", T: () => ["bpt.hall.prop.v1.CleanUpOption", CleanUpOption] }
        ]);
    }
    create(value?: PartialMessage<CleanUpUserPropReq>): CleanUpUserPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.categories = [];
        message.types = [];
        message.expireAt = 0;
        message.option = 0;
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserPropReq): CleanUpUserPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropCategory categories */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.categories.push(reader.int32());
                    else
                        message.categories.push(reader.int32());
                    break;
                case /* repeated bpt.common.PropType types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* int64 expireAt */ 5:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.CleanUpOption option */ 6:
                    message.option = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CleanUpUserPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* repeated bpt.common.PropCategory categories = 3; */
        if (message.categories.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.categories.length; i++)
                writer.int32(message.categories[i]);
            writer.join();
        }
        /* repeated bpt.common.PropType types = 4; */
        if (message.types.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* int64 expireAt = 5; */
        if (message.expireAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.expireAt);
        /* bpt.hall.prop.v1.CleanUpOption option = 6; */
        if (message.option !== 0)
            writer.tag(6, WireType.Varint).int32(message.option);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserPropReq
 */
export const CleanUpUserPropReq = new CleanUpUserPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserPropV2Req$Type extends MessageType<CleanUpUserPropV2Req> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserPropV2Req", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "categories", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 4, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] },
            { no: 5, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "option", kind: "enum", T: () => ["bpt.hall.prop.v1.CleanUpOption", CleanUpOption] },
            { no: 7, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CleanUpUserPropV2Req>): CleanUpUserPropV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.categories = [];
        message.types = [];
        message.expireAt = 0;
        message.option = 0;
        message.num = 0;
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserPropV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserPropV2Req): CleanUpUserPropV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropCategory categories */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.categories.push(reader.int32());
                    else
                        message.categories.push(reader.int32());
                    break;
                case /* repeated bpt.common.PropType types */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* int64 expireAt */ 5:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.CleanUpOption option */ 6:
                    message.option = reader.int32();
                    break;
                case /* int64 num */ 7:
                    message.num = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CleanUpUserPropV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* repeated bpt.common.PropCategory categories = 3; */
        if (message.categories.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.categories.length; i++)
                writer.int32(message.categories[i]);
            writer.join();
        }
        /* repeated bpt.common.PropType types = 4; */
        if (message.types.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* int64 expireAt = 5; */
        if (message.expireAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.expireAt);
        /* bpt.hall.prop.v1.CleanUpOption option = 6; */
        if (message.option !== 0)
            writer.tag(6, WireType.Varint).int32(message.option);
        /* int64 num = 7; */
        if (message.num !== 0)
            writer.tag(7, WireType.Varint).int64(message.num);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserPropV2Req
 */
export const CleanUpUserPropV2Req = new CleanUpUserPropV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserPropV2Resp$Type extends MessageType<CleanUpUserPropV2Resp> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserPropV2Resp", [
            { no: 1, name: "compensate", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<CleanUpUserPropV2Resp>): CleanUpUserPropV2Resp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.compensate = [];
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserPropV2Resp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserPropV2Resp): CleanUpUserPropV2Resp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem compensate */ 1:
                    message.compensate.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CleanUpUserPropV2Resp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem compensate = 1; */
        for (let i = 0; i < message.compensate.length; i++)
            AssetItem.internalBinaryWrite(message.compensate[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserPropV2Resp
 */
export const CleanUpUserPropV2Resp = new CleanUpUserPropV2Resp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserPropResp$Type extends MessageType<CleanUpUserPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserPropResp", []);
    }
    create(value?: PartialMessage<CleanUpUserPropResp>): CleanUpUserPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserPropResp): CleanUpUserPropResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CleanUpUserPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserPropResp
 */
export const CleanUpUserPropResp = new CleanUpUserPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPropLog$Type extends MessageType<UserPropLog> {
    constructor() {
        super("bpt.hall.prop.v1.UserPropLog", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "balance", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "batchId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sign", kind: "enum", T: () => ["bpt.common.Sign", Sign] },
            { no: 7, name: "createAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] },
            { no: 9, name: "grantType", kind: "enum", T: () => ["bpt.hall.prop.v1.GrantType", GrantType] },
            { no: 10, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "extra", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "propName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "urf", kind: "enum", T: () => ["bpt.common.UserRobotFlag", UserRobotFlag] }
        ]);
    }
    create(value?: PartialMessage<UserPropLog>): UserPropLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.balance = 0;
        message.num = 0;
        message.batchId = "";
        message.sign = 0;
        message.createAt = 0;
        message.way = 0;
        message.grantType = 0;
        message.expireAt = 0;
        message.extra = "";
        message.operator = "";
        message.propName = "";
        message.urf = 0;
        if (value !== undefined)
            reflectionMergePartial<UserPropLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPropLog): UserPropLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 balance */ 3:
                    message.balance = reader.int64().toNumber();
                    break;
                case /* int64 num */ 4:
                    message.num = reader.int64().toNumber();
                    break;
                case /* string batchId */ 5:
                    message.batchId = reader.string();
                    break;
                case /* bpt.common.Sign sign */ 6:
                    message.sign = reader.int32();
                    break;
                case /* int64 createAt */ 7:
                    message.createAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.Way way */ 8:
                    message.way = reader.int32();
                    break;
                case /* bpt.hall.prop.v1.GrantType grantType */ 9:
                    message.grantType = reader.int32();
                    break;
                case /* int64 expireAt */ 10:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* string extra */ 11:
                    message.extra = reader.string();
                    break;
                case /* string operator */ 12:
                    message.operator = reader.string();
                    break;
                case /* string propName */ 13:
                    message.propName = reader.string();
                    break;
                case /* bpt.common.UserRobotFlag urf */ 14:
                    message.urf = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPropLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 balance = 3; */
        if (message.balance !== 0)
            writer.tag(3, WireType.Varint).int64(message.balance);
        /* int64 num = 4; */
        if (message.num !== 0)
            writer.tag(4, WireType.Varint).int64(message.num);
        /* string batchId = 5; */
        if (message.batchId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.batchId);
        /* bpt.common.Sign sign = 6; */
        if (message.sign !== 0)
            writer.tag(6, WireType.Varint).int32(message.sign);
        /* int64 createAt = 7; */
        if (message.createAt !== 0)
            writer.tag(7, WireType.Varint).int64(message.createAt);
        /* bpt.common.Way way = 8; */
        if (message.way !== 0)
            writer.tag(8, WireType.Varint).int32(message.way);
        /* bpt.hall.prop.v1.GrantType grantType = 9; */
        if (message.grantType !== 0)
            writer.tag(9, WireType.Varint).int32(message.grantType);
        /* int64 expireAt = 10; */
        if (message.expireAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.expireAt);
        /* string extra = 11; */
        if (message.extra !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.extra);
        /* string operator = 12; */
        if (message.operator !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.operator);
        /* string propName = 13; */
        if (message.propName !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.propName);
        /* bpt.common.UserRobotFlag urf = 14; */
        if (message.urf !== 0)
            writer.tag(14, WireType.Varint).int32(message.urf);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserPropLog
 */
export const UserPropLog = new UserPropLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserGiftLog$Type extends MessageType<UserGiftLog> {
    constructor() {
        super("bpt.hall.prop.v1.UserGiftLog", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "giftID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "balance", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "batchId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sign", kind: "enum", T: () => ["bpt.common.Sign", Sign] },
            { no: 7, name: "createAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] },
            { no: 9, name: "grantType", kind: "enum", T: () => ["bpt.hall.prop.v1.GrantType", GrantType] },
            { no: 10, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "extra", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "giftName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 15, name: "urf", kind: "enum", T: () => ["bpt.common.UserRobotFlag", UserRobotFlag] }
        ]);
    }
    create(value?: PartialMessage<UserGiftLog>): UserGiftLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.giftID = 0;
        message.balance = 0;
        message.num = 0;
        message.batchId = "";
        message.sign = 0;
        message.createAt = 0;
        message.way = 0;
        message.grantType = 0;
        message.expireAt = 0;
        message.extra = "";
        message.operator = "";
        message.giftName = "";
        message.items = [];
        message.urf = 0;
        if (value !== undefined)
            reflectionMergePartial<UserGiftLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserGiftLog): UserGiftLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 giftID */ 2:
                    message.giftID = reader.int64().toNumber();
                    break;
                case /* int64 balance */ 3:
                    message.balance = reader.int64().toNumber();
                    break;
                case /* int64 num */ 4:
                    message.num = reader.int64().toNumber();
                    break;
                case /* string batchId */ 5:
                    message.batchId = reader.string();
                    break;
                case /* bpt.common.Sign sign */ 6:
                    message.sign = reader.int32();
                    break;
                case /* int64 createAt */ 7:
                    message.createAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.Way way */ 8:
                    message.way = reader.int32();
                    break;
                case /* bpt.hall.prop.v1.GrantType grantType */ 9:
                    message.grantType = reader.int32();
                    break;
                case /* int64 expireAt */ 10:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* string extra */ 11:
                    message.extra = reader.string();
                    break;
                case /* string operator */ 12:
                    message.operator = reader.string();
                    break;
                case /* string giftName */ 13:
                    message.giftName = reader.string();
                    break;
                case /* repeated bpt.common.AssetItem items */ 14:
                    message.items.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.UserRobotFlag urf */ 15:
                    message.urf = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserGiftLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 giftID = 2; */
        if (message.giftID !== 0)
            writer.tag(2, WireType.Varint).int64(message.giftID);
        /* int64 balance = 3; */
        if (message.balance !== 0)
            writer.tag(3, WireType.Varint).int64(message.balance);
        /* int64 num = 4; */
        if (message.num !== 0)
            writer.tag(4, WireType.Varint).int64(message.num);
        /* string batchId = 5; */
        if (message.batchId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.batchId);
        /* bpt.common.Sign sign = 6; */
        if (message.sign !== 0)
            writer.tag(6, WireType.Varint).int32(message.sign);
        /* int64 createAt = 7; */
        if (message.createAt !== 0)
            writer.tag(7, WireType.Varint).int64(message.createAt);
        /* bpt.common.Way way = 8; */
        if (message.way !== 0)
            writer.tag(8, WireType.Varint).int32(message.way);
        /* bpt.hall.prop.v1.GrantType grantType = 9; */
        if (message.grantType !== 0)
            writer.tag(9, WireType.Varint).int32(message.grantType);
        /* int64 expireAt = 10; */
        if (message.expireAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.expireAt);
        /* string extra = 11; */
        if (message.extra !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.extra);
        /* string operator = 12; */
        if (message.operator !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.operator);
        /* string giftName = 13; */
        if (message.giftName !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.giftName);
        /* repeated bpt.common.AssetItem items = 14; */
        for (let i = 0; i < message.items.length; i++)
            AssetItem.internalBinaryWrite(message.items[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.UserRobotFlag urf = 15; */
        if (message.urf !== 0)
            writer.tag(15, WireType.Varint).int32(message.urf);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserGiftLog
 */
export const UserGiftLog = new UserGiftLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropLogReq$Type extends MessageType<ListUserPropLogReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropLogReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "beginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] }
        ]);
    }
    create(value?: PartialMessage<ListUserPropLogReq>): ListUserPropLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.uid = 0;
        message.beginAt = 0;
        message.endAt = 0;
        message.propID = 0;
        message.way = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPropLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropLogReq): ListUserPropLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 beginAt */ 4:
                    message.beginAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 5:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 6:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* bpt.common.Way way */ 7:
                    message.way = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* int64 beginAt = 4; */
        if (message.beginAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.beginAt);
        /* int64 endAt = 5; */
        if (message.endAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.endAt);
        /* int64 propID = 6; */
        if (message.propID !== 0)
            writer.tag(6, WireType.Varint).int64(message.propID);
        /* bpt.common.Way way = 7; */
        if (message.way !== 0)
            writer.tag(7, WireType.Varint).int32(message.way);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropLogReq
 */
export const ListUserPropLogReq = new ListUserPropLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropLogResp$Type extends MessageType<ListUserPropLogResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropLogResp", [
            { no: 1, name: "logs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPropLog },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserPropLogResp>): ListUserPropLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.logs = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPropLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropLogResp): ListUserPropLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserPropLog logs */ 1:
                    message.logs.push(UserPropLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserPropLog logs = 1; */
        for (let i = 0; i < message.logs.length; i++)
            UserPropLog.internalBinaryWrite(message.logs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropLogResp
 */
export const ListUserPropLogResp = new ListUserPropLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserTxOnChainLogReq$Type extends MessageType<ListUserTxOnChainLogReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserTxOnChainLogReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "beginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["bpt.common.TxOnChainType", TxOnChainType] },
            { no: 6, name: "status", kind: "enum", T: () => ["bpt.common.TxOnChainStatus", TxOnChainStatus] },
            { no: 7, name: "erc20SymbolFuzzy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 9, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserTxOnChainLogReq>): ListUserTxOnChainLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.beginAt = 0;
        message.endAt = 0;
        message.type = 0;
        message.status = 0;
        message.erc20SymbolFuzzy = "";
        message.chainID = 0;
        message.contractAddress = "";
        if (value !== undefined)
            reflectionMergePartial<ListUserTxOnChainLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserTxOnChainLogReq): ListUserTxOnChainLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 beginAt */ 3:
                    message.beginAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 4:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.TxOnChainType type */ 5:
                    message.type = reader.int32();
                    break;
                case /* bpt.common.TxOnChainStatus status */ 6:
                    message.status = reader.int32();
                    break;
                case /* string erc20SymbolFuzzy */ 7:
                    message.erc20SymbolFuzzy = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 8:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 9:
                    message.contractAddress = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserTxOnChainLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 beginAt = 3; */
        if (message.beginAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.beginAt);
        /* int64 endAt = 4; */
        if (message.endAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.endAt);
        /* bpt.common.TxOnChainType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* bpt.common.TxOnChainStatus status = 6; */
        if (message.status !== 0)
            writer.tag(6, WireType.Varint).int32(message.status);
        /* string erc20SymbolFuzzy = 7; */
        if (message.erc20SymbolFuzzy !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.erc20SymbolFuzzy);
        /* gaw.base.ChainID chainID = 8; */
        if (message.chainID !== 0)
            writer.tag(8, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 9; */
        if (message.contractAddress !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.contractAddress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogReq
 */
export const ListUserTxOnChainLogReq = new ListUserTxOnChainLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserTxOnChainLogResp$Type extends MessageType<ListUserTxOnChainLogResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserTxOnChainLogResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserTxOnChainLog },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserTxOnChainLogResp>): ListUserTxOnChainLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserTxOnChainLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserTxOnChainLogResp): ListUserTxOnChainLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserTxOnChainLog list */ 1:
                    message.list.push(UserTxOnChainLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserTxOnChainLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserTxOnChainLog list = 1; */
        for (let i = 0; i < message.list.length; i++)
            UserTxOnChainLog.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogResp
 */
export const ListUserTxOnChainLogResp = new ListUserTxOnChainLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserTxOnChainLogInternalReq$Type extends MessageType<ListUserTxOnChainLogInternalReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserTxOnChainLogInternalReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "beginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["bpt.common.TxOnChainType", TxOnChainType] },
            { no: 6, name: "status", kind: "enum", T: () => ["bpt.common.TxOnChainStatus", TxOnChainStatus] },
            { no: 7, name: "erc20SymbolFuzzy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 9, name: "contractAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserTxOnChainLogInternalReq>): ListUserTxOnChainLogInternalReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.beginAt = 0;
        message.endAt = 0;
        message.type = 0;
        message.status = 0;
        message.erc20SymbolFuzzy = "";
        message.chainID = 0;
        message.contractAddress = "";
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserTxOnChainLogInternalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserTxOnChainLogInternalReq): ListUserTxOnChainLogInternalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 beginAt */ 3:
                    message.beginAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 4:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.TxOnChainType type */ 5:
                    message.type = reader.int32();
                    break;
                case /* bpt.common.TxOnChainStatus status */ 6:
                    message.status = reader.int32();
                    break;
                case /* string erc20SymbolFuzzy */ 7:
                    message.erc20SymbolFuzzy = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 8:
                    message.chainID = reader.int32();
                    break;
                case /* string contractAddress */ 9:
                    message.contractAddress = reader.string();
                    break;
                case /* int64 uid */ 10:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserTxOnChainLogInternalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 beginAt = 3; */
        if (message.beginAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.beginAt);
        /* int64 endAt = 4; */
        if (message.endAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.endAt);
        /* bpt.common.TxOnChainType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* bpt.common.TxOnChainStatus status = 6; */
        if (message.status !== 0)
            writer.tag(6, WireType.Varint).int32(message.status);
        /* string erc20SymbolFuzzy = 7; */
        if (message.erc20SymbolFuzzy !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.erc20SymbolFuzzy);
        /* gaw.base.ChainID chainID = 8; */
        if (message.chainID !== 0)
            writer.tag(8, WireType.Varint).int32(message.chainID);
        /* string contractAddress = 9; */
        if (message.contractAddress !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.contractAddress);
        /* int64 uid = 10; */
        if (message.uid !== 0)
            writer.tag(10, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogInternalReq
 */
export const ListUserTxOnChainLogInternalReq = new ListUserTxOnChainLogInternalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserTxOnChainLogInternalResp$Type extends MessageType<ListUserTxOnChainLogInternalResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserTxOnChainLogInternalResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserTxOnChainLog },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserTxOnChainLogInternalResp>): ListUserTxOnChainLogInternalResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserTxOnChainLogInternalResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserTxOnChainLogInternalResp): ListUserTxOnChainLogInternalResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserTxOnChainLog list */ 1:
                    message.list.push(UserTxOnChainLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserTxOnChainLogInternalResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserTxOnChainLog list = 1; */
        for (let i = 0; i < message.list.length; i++)
            UserTxOnChainLog.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserTxOnChainLogInternalResp
 */
export const ListUserTxOnChainLogInternalResp = new ListUserTxOnChainLogInternalResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserTxOnChainLog$Type extends MessageType<UserTxOnChainLog> {
    constructor() {
        super("bpt.hall.prop.v1.UserTxOnChainLog", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "erc20info", kind: "message", localName: "erc20info", jsonName: "erc20info", T: () => ERC20Info },
            { no: 3, name: "type", kind: "enum", T: () => ["bpt.common.TxOnChainType", TxOnChainType] },
            { no: 4, name: "status", kind: "enum", T: () => ["bpt.common.TxOnChainStatus", TxOnChainStatus] },
            { no: 5, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "createTime", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "txInfo", kind: "message", T: () => TxOnChainLogInfo },
            { no: 8, name: "nftInfo", kind: "message", T: () => NFTInfo },
            { no: 9, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] }
        ]);
    }
    create(value?: PartialMessage<UserTxOnChainLog>): UserTxOnChainLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.type = 0;
        message.status = 0;
        message.amount = "";
        message.createTime = 0;
        message.way = 0;
        if (value !== undefined)
            reflectionMergePartial<UserTxOnChainLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserTxOnChainLog): UserTxOnChainLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* gaw.asset.wallet.v1.ERC20Info erc20info = 2 [json_name = "erc20info"];*/ 2:
                    message.erc20info = ERC20Info.internalBinaryRead(reader, reader.uint32(), options, message.erc20info);
                    break;
                case /* bpt.common.TxOnChainType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* bpt.common.TxOnChainStatus status */ 4:
                    message.status = reader.int32();
                    break;
                case /* string amount */ 5:
                    message.amount = reader.string();
                    break;
                case /* uint64 createTime */ 6:
                    message.createTime = reader.uint64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.TxOnChainLogInfo txInfo */ 7:
                    message.txInfo = TxOnChainLogInfo.internalBinaryRead(reader, reader.uint32(), options, message.txInfo);
                    break;
                case /* gaw.asset.wallet.v1.NFTInfo nftInfo */ 8:
                    message.nftInfo = NFTInfo.internalBinaryRead(reader, reader.uint32(), options, message.nftInfo);
                    break;
                case /* bpt.common.Way way */ 9:
                    message.way = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserTxOnChainLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* gaw.asset.wallet.v1.ERC20Info erc20info = 2 [json_name = "erc20info"]; */
        if (message.erc20info)
            ERC20Info.internalBinaryWrite(message.erc20info, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.TxOnChainType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* bpt.common.TxOnChainStatus status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).int32(message.status);
        /* string amount = 5; */
        if (message.amount !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.amount);
        /* uint64 createTime = 6; */
        if (message.createTime !== 0)
            writer.tag(6, WireType.Varint).uint64(message.createTime);
        /* bpt.hall.prop.v1.TxOnChainLogInfo txInfo = 7; */
        if (message.txInfo)
            TxOnChainLogInfo.internalBinaryWrite(message.txInfo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* gaw.asset.wallet.v1.NFTInfo nftInfo = 8; */
        if (message.nftInfo)
            NFTInfo.internalBinaryWrite(message.nftInfo, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.Way way = 9; */
        if (message.way !== 0)
            writer.tag(9, WireType.Varint).int32(message.way);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserTxOnChainLog
 */
export const UserTxOnChainLog = new UserTxOnChainLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TxOnChainLogInfo$Type extends MessageType<TxOnChainLogInfo> {
    constructor() {
        super("bpt.hall.prop.v1.TxOnChainLogInfo", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "txHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "taskId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "productId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "currentBalance", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TxOnChainLogInfo>): TxOnChainLogInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.txHash = "";
        message.taskId = 0;
        message.productId = "";
        message.propId = 0;
        message.currentBalance = "";
        if (value !== undefined)
            reflectionMergePartial<TxOnChainLogInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TxOnChainLogInfo): TxOnChainLogInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string txHash */ 2:
                    message.txHash = reader.string();
                    break;
                case /* uint32 taskId */ 3:
                    message.taskId = reader.uint32();
                    break;
                case /* string productId */ 4:
                    message.productId = reader.string();
                    break;
                case /* int64 propId */ 5:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* string currentBalance */ 6:
                    message.currentBalance = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TxOnChainLogInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string txHash = 2; */
        if (message.txHash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.txHash);
        /* uint32 taskId = 3; */
        if (message.taskId !== 0)
            writer.tag(3, WireType.Varint).uint32(message.taskId);
        /* string productId = 4; */
        if (message.productId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.productId);
        /* int64 propId = 5; */
        if (message.propId !== 0)
            writer.tag(5, WireType.Varint).int64(message.propId);
        /* string currentBalance = 6; */
        if (message.currentBalance !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.currentBalance);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.TxOnChainLogInfo
 */
export const TxOnChainLogInfo = new TxOnChainLogInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserTxOnChainLogReq$Type extends MessageType<UpdateUserTxOnChainLogReq> {
    constructor() {
        super("bpt.hall.prop.v1.UpdateUserTxOnChainLogReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "tradeID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "txType", kind: "enum", T: () => ["bpt.common.TxOnChainType", TxOnChainType] },
            { no: 5, name: "additional", kind: "message", T: () => UpdateUserTxOnChainLogDetail }
        ]);
    }
    create(value?: PartialMessage<UpdateUserTxOnChainLogReq>): UpdateUserTxOnChainLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.tradeID = "";
        message.matchKey = "";
        message.txType = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateUserTxOnChainLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserTxOnChainLogReq): UpdateUserTxOnChainLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string tradeID */ 2:
                    message.tradeID = reader.string();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.common.TxOnChainType txType */ 4:
                    message.txType = reader.int32();
                    break;
                case /* bpt.hall.prop.v1.UpdateUserTxOnChainLogDetail additional */ 5:
                    message.additional = UpdateUserTxOnChainLogDetail.internalBinaryRead(reader, reader.uint32(), options, message.additional);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserTxOnChainLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string tradeID = 2; */
        if (message.tradeID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tradeID);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.common.TxOnChainType txType = 4; */
        if (message.txType !== 0)
            writer.tag(4, WireType.Varint).int32(message.txType);
        /* bpt.hall.prop.v1.UpdateUserTxOnChainLogDetail additional = 5; */
        if (message.additional)
            UpdateUserTxOnChainLogDetail.internalBinaryWrite(message.additional, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UpdateUserTxOnChainLogReq
 */
export const UpdateUserTxOnChainLogReq = new UpdateUserTxOnChainLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserTxOnChainLogDetail$Type extends MessageType<UpdateUserTxOnChainLogDetail> {
    constructor() {
        super("bpt.hall.prop.v1.UpdateUserTxOnChainLogDetail", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "productId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateUserTxOnChainLogDetail>): UpdateUserTxOnChainLogDetail {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.productId = "";
        message.propId = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateUserTxOnChainLogDetail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserTxOnChainLogDetail): UpdateUserTxOnChainLogDetail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string productId */ 2:
                    message.productId = reader.string();
                    break;
                case /* int64 propId */ 3:
                    message.propId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserTxOnChainLogDetail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string productId = 2; */
        if (message.productId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.productId);
        /* int64 propId = 3; */
        if (message.propId !== 0)
            writer.tag(3, WireType.Varint).int64(message.propId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UpdateUserTxOnChainLogDetail
 */
export const UpdateUserTxOnChainLogDetail = new UpdateUserTxOnChainLogDetail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserTxOnChainLogResp$Type extends MessageType<UpdateUserTxOnChainLogResp> {
    constructor() {
        super("bpt.hall.prop.v1.UpdateUserTxOnChainLogResp", []);
    }
    create(value?: PartialMessage<UpdateUserTxOnChainLogResp>): UpdateUserTxOnChainLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdateUserTxOnChainLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserTxOnChainLogResp): UpdateUserTxOnChainLogResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateUserTxOnChainLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UpdateUserTxOnChainLogResp
 */
export const UpdateUserTxOnChainLogResp = new UpdateUserTxOnChainLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropNFTReq$Type extends MessageType<ListUserPropNFTReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropNFTReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "propIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserPropNFTReq>): ListUserPropNFTReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.propIds = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserPropNFTReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropNFTReq): ListUserPropNFTReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* repeated int64 propIds */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propIds.push(reader.int64().toNumber());
                    else
                        message.propIds.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropNFTReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* repeated int64 propIds = 3; */
        if (message.propIds.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propIds.length; i++)
                writer.int64(message.propIds[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropNFTReq
 */
export const ListUserPropNFTReq = new ListUserPropNFTReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropNFT$Type extends MessageType<ListUserPropNFT> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropNFT", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "nftInfo", kind: "message", T: () => NFTInfo },
            { no: 3, name: "new", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "nftInfoLinkedErc20PropId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "i18nName", kind: "message", localName: "i18nName", jsonName: "i18nName", T: () => I18n }
        ]);
    }
    create(value?: PartialMessage<ListUserPropNFT>): ListUserPropNFT {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.new = false;
        message.amount = "";
        message.nftInfoLinkedErc20PropId = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPropNFT>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropNFT): ListUserPropNFT {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* gaw.asset.wallet.v1.NFTInfo nftInfo */ 2:
                    message.nftInfo = NFTInfo.internalBinaryRead(reader, reader.uint32(), options, message.nftInfo);
                    break;
                case /* bool new */ 3:
                    message.new = reader.bool();
                    break;
                case /* string amount */ 4:
                    message.amount = reader.string();
                    break;
                case /* int64 nftInfoLinkedErc20PropId */ 5:
                    message.nftInfoLinkedErc20PropId = reader.int64().toNumber();
                    break;
                case /* bpt.common.I18n i18nName = 6 [json_name = "i18nName"];*/ 6:
                    message.i18nName = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18nName);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropNFT, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* gaw.asset.wallet.v1.NFTInfo nftInfo = 2; */
        if (message.nftInfo)
            NFTInfo.internalBinaryWrite(message.nftInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bool new = 3; */
        if (message.new !== false)
            writer.tag(3, WireType.Varint).bool(message.new);
        /* string amount = 4; */
        if (message.amount !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.amount);
        /* int64 nftInfoLinkedErc20PropId = 5; */
        if (message.nftInfoLinkedErc20PropId !== 0)
            writer.tag(5, WireType.Varint).int64(message.nftInfoLinkedErc20PropId);
        /* bpt.common.I18n i18nName = 6 [json_name = "i18nName"]; */
        if (message.i18nName)
            I18n.internalBinaryWrite(message.i18nName, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropNFT
 */
export const ListUserPropNFT = new ListUserPropNFT$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropNFTResp$Type extends MessageType<ListUserPropNFTResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropNFTResp", [
            { no: 1, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListUserPropNFT }
        ]);
    }
    create(value?: PartialMessage<ListUserPropNFTResp>): ListUserPropNFTResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalSize = 0;
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserPropNFTResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropNFTResp): ListUserPropNFTResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalSize */ 1:
                    message.totalSize = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.prop.v1.ListUserPropNFT list */ 2:
                    message.list.push(ListUserPropNFT.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropNFTResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalSize = 1; */
        if (message.totalSize !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalSize);
        /* repeated bpt.hall.prop.v1.ListUserPropNFT list = 2; */
        for (let i = 0; i < message.list.length; i++)
            ListUserPropNFT.internalBinaryWrite(message.list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropNFTResp
 */
export const ListUserPropNFTResp = new ListUserPropNFTResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropNftInternalReq$Type extends MessageType<ListUserPropNftInternalReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropNftInternalReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "tokenIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserPropNftInternalReq>): ListUserPropNftInternalReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propId = 0;
        message.tokenIds = [];
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPropNftInternalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropNftInternalReq): ListUserPropNftInternalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propId */ 2:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* repeated string tokenIds */ 3:
                    message.tokenIds.push(reader.string());
                    break;
                case /* int64 page */ 4:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 5:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropNftInternalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propId = 2; */
        if (message.propId !== 0)
            writer.tag(2, WireType.Varint).int64(message.propId);
        /* repeated string tokenIds = 3; */
        for (let i = 0; i < message.tokenIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tokenIds[i]);
        /* int64 page = 4; */
        if (message.page !== 0)
            writer.tag(4, WireType.Varint).int64(message.page);
        /* int64 pageSize = 5; */
        if (message.pageSize !== 0)
            writer.tag(5, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropNftInternalReq
 */
export const ListUserPropNftInternalReq = new ListUserPropNftInternalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropNftInternalResp$Type extends MessageType<ListUserPropNftInternalResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropNftInternalResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListUserPropNFT },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserPropNftInternalResp>): ListUserPropNftInternalResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPropNftInternalResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropNftInternalResp): ListUserPropNftInternalResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.ListUserPropNFT list */ 1:
                    message.list.push(ListUserPropNFT.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropNftInternalResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.ListUserPropNFT list = 1; */
        for (let i = 0; i < message.list.length; i++)
            ListUserPropNFT.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropNftInternalResp
 */
export const ListUserPropNftInternalResp = new ListUserPropNftInternalResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStatsReq$Type extends MessageType<GetStatsReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetStatsReq", [
            { no: 1, name: "ResID", kind: "scalar", localName: "ResID", jsonName: "ResID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.hall.prop.v1.ResType", ResType] }
        ]);
    }
    create(value?: PartialMessage<GetStatsReq>): GetStatsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ResID = 0;
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<GetStatsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStatsReq): GetStatsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ResID = 1 [json_name = "ResID"];*/ 1:
                    message.ResID = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.ResType type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStatsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ResID = 1 [json_name = "ResID"]; */
        if (message.ResID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ResID);
        /* bpt.hall.prop.v1.ResType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetStatsReq
 */
export const GetStatsReq = new GetStatsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStatsResp$Type extends MessageType<GetStatsResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetStatsResp", [
            { no: 1, name: "stats", kind: "message", T: () => Stats }
        ]);
    }
    create(value?: PartialMessage<GetStatsResp>): GetStatsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetStatsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStatsResp): GetStatsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.Stats stats */ 1:
                    message.stats = Stats.internalBinaryRead(reader, reader.uint32(), options, message.stats);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStatsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.Stats stats = 1; */
        if (message.stats)
            Stats.internalBinaryWrite(message.stats, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetStatsResp
 */
export const GetStatsResp = new GetStatsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPropGawResp$Type extends MessageType<GetPropGawResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetPropGawResp", [
            { no: 1, name: "info", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Prop }
        ]);
    }
    create(value?: PartialMessage<GetPropGawResp>): GetPropGawResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.info = [];
        if (value !== undefined)
            reflectionMergePartial<GetPropGawResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPropGawResp): GetPropGawResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.Prop info */ 1:
                    message.info.push(Prop.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPropGawResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.Prop info = 1; */
        for (let i = 0; i < message.info.length; i++)
            Prop.internalBinaryWrite(message.info[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetPropGawResp
 */
export const GetPropGawResp = new GetPropGawResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Stats$Type extends MessageType<Stats> {
    constructor() {
        super("bpt.hall.prop.v1.Stats", [
            { no: 1, name: "ResID", kind: "scalar", localName: "ResID", jsonName: "ResID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "acceptNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "expiredNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "usedNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Stats>): Stats {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ResID = 0;
        message.acceptNum = 0;
        message.expiredNum = 0;
        message.usedNum = 0;
        if (value !== undefined)
            reflectionMergePartial<Stats>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Stats): Stats {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ResID = 1 [json_name = "ResID"];*/ 1:
                    message.ResID = reader.int64().toNumber();
                    break;
                case /* int64 acceptNum */ 2:
                    message.acceptNum = reader.int64().toNumber();
                    break;
                case /* int64 expiredNum */ 3:
                    message.expiredNum = reader.int64().toNumber();
                    break;
                case /* int64 usedNum */ 4:
                    message.usedNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Stats, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ResID = 1 [json_name = "ResID"]; */
        if (message.ResID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ResID);
        /* int64 acceptNum = 2; */
        if (message.acceptNum !== 0)
            writer.tag(2, WireType.Varint).int64(message.acceptNum);
        /* int64 expiredNum = 3; */
        if (message.expiredNum !== 0)
            writer.tag(3, WireType.Varint).int64(message.expiredNum);
        /* int64 usedNum = 4; */
        if (message.usedNum !== 0)
            writer.tag(4, WireType.Varint).int64(message.usedNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.Stats
 */
export const Stats = new Stats$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Badge$Type extends MessageType<Badge> {
    constructor() {
        super("bpt.hall.prop.v1.Badge", [
            { no: 1, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "key", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Badge>): Badge {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.amount = 0;
        message.key = "";
        if (value !== undefined)
            reflectionMergePartial<Badge>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Badge): Badge {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 amount */ 1:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* string key */ 2:
                    message.key = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Badge, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 amount = 1; */
        if (message.amount !== 0)
            writer.tag(1, WireType.Varint).int64(message.amount);
        /* string key = 2; */
        if (message.key !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.key);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.Badge
 */
export const Badge = new Badge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserBadgeReq$Type extends MessageType<GetUserBadgeReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserBadgeReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserBadgeReq>): GetUserBadgeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserBadgeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserBadgeReq): GetUserBadgeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserBadgeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserBadgeReq
 */
export const GetUserBadgeReq = new GetUserBadgeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBadgeReq$Type extends MessageType<ListUserBadgeReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserBadgeReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserBadgeReq>): ListUserBadgeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserBadgeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserBadgeReq): ListUserBadgeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserBadgeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserBadgeReq
 */
export const ListUserBadgeReq = new ListUserBadgeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserBadgeResp$Type extends MessageType<GetUserBadgeResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserBadgeResp", [
            { no: 1, name: "badge", kind: "message", T: () => Badge }
        ]);
    }
    create(value?: PartialMessage<GetUserBadgeResp>): GetUserBadgeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserBadgeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserBadgeResp): GetUserBadgeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.Badge badge */ 1:
                    message.badge = Badge.internalBinaryRead(reader, reader.uint32(), options, message.badge);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserBadgeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.Badge badge = 1; */
        if (message.badge)
            Badge.internalBinaryWrite(message.badge, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserBadgeResp
 */
export const GetUserBadgeResp = new GetUserBadgeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserBadgeResp$Type extends MessageType<ListUserBadgeResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserBadgeResp", [
            { no: 1, name: "badges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Badge }
        ]);
    }
    create(value?: PartialMessage<ListUserBadgeResp>): ListUserBadgeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.badges = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserBadgeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserBadgeResp): ListUserBadgeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.Badge badges */ 1:
                    message.badges.push(Badge.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserBadgeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.Badge badges = 1; */
        for (let i = 0; i < message.badges.length; i++)
            Badge.internalBinaryWrite(message.badges[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserBadgeResp
 */
export const ListUserBadgeResp = new ListUserBadgeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserBadgeReq$Type extends MessageType<CleanUpUserBadgeReq> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserBadgeReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "badgeKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CleanUpUserBadgeReq>): CleanUpUserBadgeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.badgeKey = "";
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserBadgeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserBadgeReq): CleanUpUserBadgeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string badgeKey */ 2:
                    message.badgeKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CleanUpUserBadgeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string badgeKey = 2; */
        if (message.badgeKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.badgeKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserBadgeReq
 */
export const CleanUpUserBadgeReq = new CleanUpUserBadgeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserBadgeResp$Type extends MessageType<CleanUpUserBadgeResp> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserBadgeResp", [
            { no: 1, name: "badge", kind: "message", T: () => Badge }
        ]);
    }
    create(value?: PartialMessage<CleanUpUserBadgeResp>): CleanUpUserBadgeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserBadgeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserBadgeResp): CleanUpUserBadgeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.Badge badge */ 1:
                    message.badge = Badge.internalBinaryRead(reader, reader.uint32(), options, message.badge);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CleanUpUserBadgeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.Badge badge = 1; */
        if (message.badge)
            Badge.internalBinaryWrite(message.badge, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserBadgeResp
 */
export const CleanUpUserBadgeResp = new CleanUpUserBadgeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckUserPropExpiredReq$Type extends MessageType<CheckUserPropExpiredReq> {
    constructor() {
        super("bpt.hall.prop.v1.CheckUserPropExpiredReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "categories", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 3, name: "types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.common.PropType", PropType] },
            { no: 4, name: "block", kind: "message", T: () => Block }
        ]);
    }
    create(value?: PartialMessage<CheckUserPropExpiredReq>): CheckUserPropExpiredReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.categories = [];
        message.types = [];
        if (value !== undefined)
            reflectionMergePartial<CheckUserPropExpiredReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckUserPropExpiredReq): CheckUserPropExpiredReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.PropCategory categories */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.categories.push(reader.int32());
                    else
                        message.categories.push(reader.int32());
                    break;
                case /* repeated bpt.common.PropType types */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.types.push(reader.int32());
                    else
                        message.types.push(reader.int32());
                    break;
                case /* bpt.hall.prop.v1.Block block */ 4:
                    message.block = Block.internalBinaryRead(reader, reader.uint32(), options, message.block);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckUserPropExpiredReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.common.PropCategory categories = 2; */
        if (message.categories.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.categories.length; i++)
                writer.int32(message.categories[i]);
            writer.join();
        }
        /* repeated bpt.common.PropType types = 3; */
        if (message.types.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.types.length; i++)
                writer.int32(message.types[i]);
            writer.join();
        }
        /* bpt.hall.prop.v1.Block block = 4; */
        if (message.block)
            Block.internalBinaryWrite(message.block, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CheckUserPropExpiredReq
 */
export const CheckUserPropExpiredReq = new CheckUserPropExpiredReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckUserPropExpiredResp$Type extends MessageType<CheckUserPropExpiredResp> {
    constructor() {
        super("bpt.hall.prop.v1.CheckUserPropExpiredResp", [
            { no: 1, name: "hasExpired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckUserPropExpiredResp>): CheckUserPropExpiredResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hasExpired = false;
        if (value !== undefined)
            reflectionMergePartial<CheckUserPropExpiredResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckUserPropExpiredResp): CheckUserPropExpiredResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool hasExpired */ 1:
                    message.hasExpired = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckUserPropExpiredResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool hasExpired = 1; */
        if (message.hasExpired !== false)
            writer.tag(1, WireType.Varint).bool(message.hasExpired);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CheckUserPropExpiredResp
 */
export const CheckUserPropExpiredResp = new CheckUserPropExpiredResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListGiftReq$Type extends MessageType<ListGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListGiftReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["bpt.hall.prop.v1.State", State] },
            { no: 4, name: "keyword", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListGiftReq>): ListGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.state = 0;
        message.keyword = "";
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<ListGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListGiftReq): ListGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.State state */ 3:
                    message.state = reader.int32();
                    break;
                case /* string keyword */ 4:
                    message.keyword = reader.string();
                    break;
                case /* repeated int64 ids */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int64().toNumber());
                    else
                        message.ids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bpt.hall.prop.v1.State state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        /* string keyword = 4; */
        if (message.keyword !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.keyword);
        /* repeated int64 ids = 5; */
        if (message.ids.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int64(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListGiftReq
 */
export const ListGiftReq = new ListGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetGiftReq$Type extends MessageType<GetGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetGiftReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetGiftReq>): GetGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetGiftReq): GetGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetGiftReq
 */
export const GetGiftReq = new GetGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserGiftReq$Type extends MessageType<IncUserGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserGiftReq", [
            { no: 1, name: "change", kind: "message", T: () => UserChange }
        ]);
    }
    create(value?: PartialMessage<IncUserGiftReq>): IncUserGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<IncUserGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserGiftReq): IncUserGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.UserChange change */ 1:
                    message.change = UserChange.internalBinaryRead(reader, reader.uint32(), options, message.change);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.UserChange change = 1; */
        if (message.change)
            UserChange.internalBinaryWrite(message.change, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserGiftReq
 */
export const IncUserGiftReq = new IncUserGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserGiftResp$Type extends MessageType<IncUserGiftResp> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserGiftResp", [
            { no: 1, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DynamicAssetItems }
        ]);
    }
    create(value?: PartialMessage<IncUserGiftResp>): IncUserGiftResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<IncUserGiftResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserGiftResp): IncUserGiftResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.common.DynamicAssetItems assets */ 1:
                    message.assets.push(DynamicAssetItems.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserGiftResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.common.DynamicAssetItems assets = 1; */
        for (let i = 0; i < message.assets.length; i++)
            DynamicAssetItems.internalBinaryWrite(message.assets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserGiftResp
 */
export const IncUserGiftResp = new IncUserGiftResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserGiftReq$Type extends MessageType<DecUserGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.DecUserGiftReq", [
            { no: 1, name: "change", kind: "message", T: () => UserChange }
        ]);
    }
    create(value?: PartialMessage<DecUserGiftReq>): DecUserGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DecUserGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserGiftReq): DecUserGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.UserChange change */ 1:
                    message.change = UserChange.internalBinaryRead(reader, reader.uint32(), options, message.change);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.UserChange change = 1; */
        if (message.change)
            UserChange.internalBinaryWrite(message.change, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.DecUserGiftReq
 */
export const DecUserGiftReq = new DecUserGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserGiftResp$Type extends MessageType<DecUserGiftResp> {
    constructor() {
        super("bpt.hall.prop.v1.DecUserGiftResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 2, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DecUserGiftResp>): DecUserGiftResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.batchID = "";
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<DecUserGiftResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserGiftResp): DecUserGiftResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* string batchID */ 2:
                    message.batchID = reader.string();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserGiftResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* string batchID = 2; */
        if (message.batchID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.batchID);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.DecUserGiftResp
 */
export const DecUserGiftResp = new DecUserGiftResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundUserGiftReq$Type extends MessageType<RefundUserGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.RefundUserGiftReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefundUserGiftReq>): RefundUserGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<RefundUserGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundUserGiftReq): RefundUserGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string batchID */ 2:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefundUserGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string batchID = 2; */
        if (message.batchID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.RefundUserGiftReq
 */
export const RefundUserGiftReq = new RefundUserGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefundUserGiftResp$Type extends MessageType<RefundUserGiftResp> {
    constructor() {
        super("bpt.hall.prop.v1.RefundUserGiftResp", []);
    }
    create(value?: PartialMessage<RefundUserGiftResp>): RefundUserGiftResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<RefundUserGiftResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefundUserGiftResp): RefundUserGiftResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RefundUserGiftResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.RefundUserGiftResp
 */
export const RefundUserGiftResp = new RefundUserGiftResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPropCategoryResp$Type extends MessageType<ListPropCategoryResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListPropCategoryResp", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CategoryItem }
        ]);
    }
    create(value?: PartialMessage<ListPropCategoryResp>): ListPropCategoryResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categories = [];
        if (value !== undefined)
            reflectionMergePartial<ListPropCategoryResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPropCategoryResp): ListPropCategoryResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.CategoryItem categories */ 1:
                    message.categories.push(CategoryItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPropCategoryResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.CategoryItem categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            CategoryItem.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListPropCategoryResp
 */
export const ListPropCategoryResp = new ListPropCategoryResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CategoryItem$Type extends MessageType<CategoryItem> {
    constructor() {
        super("bpt.hall.prop.v1.CategoryItem", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CategoryItem>): CategoryItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<CategoryItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CategoryItem): CategoryItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CategoryItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CategoryItem
 */
export const CategoryItem = new CategoryItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPropTypeReq$Type extends MessageType<ListPropTypeReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListPropTypeReq", [
            { no: 1, name: "categoryID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListPropTypeReq>): ListPropTypeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.categoryID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListPropTypeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPropTypeReq): ListPropTypeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 categoryID */ 1:
                    message.categoryID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPropTypeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 categoryID = 1; */
        if (message.categoryID !== 0)
            writer.tag(1, WireType.Varint).int64(message.categoryID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListPropTypeReq
 */
export const ListPropTypeReq = new ListPropTypeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPropTypeResp$Type extends MessageType<ListPropTypeResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListPropTypeResp", [
            { no: 1, name: "types", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PropTypeItem }
        ]);
    }
    create(value?: PartialMessage<ListPropTypeResp>): ListPropTypeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.types = [];
        if (value !== undefined)
            reflectionMergePartial<ListPropTypeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPropTypeResp): ListPropTypeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.PropTypeItem types */ 1:
                    message.types.push(PropTypeItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPropTypeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.PropTypeItem types = 1; */
        for (let i = 0; i < message.types.length; i++)
            PropTypeItem.internalBinaryWrite(message.types[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListPropTypeResp
 */
export const ListPropTypeResp = new ListPropTypeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PropTypeItem$Type extends MessageType<PropTypeItem> {
    constructor() {
        super("bpt.hall.prop.v1.PropTypeItem", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PropTypeItem>): PropTypeItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<PropTypeItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PropTypeItem): PropTypeItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PropTypeItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.PropTypeItem
 */
export const PropTypeItem = new PropTypeItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserGiftLogReq$Type extends MessageType<ListUserGiftLogReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserGiftLogReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "beginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "giftID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserGiftLogReq>): ListUserGiftLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.uid = 0;
        message.beginAt = 0;
        message.endAt = 0;
        message.giftID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserGiftLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserGiftLogReq): ListUserGiftLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 beginAt */ 4:
                    message.beginAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 5:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* int64 giftID */ 6:
                    message.giftID = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserGiftLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* int64 beginAt = 4; */
        if (message.beginAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.beginAt);
        /* int64 endAt = 5; */
        if (message.endAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.endAt);
        /* int64 giftID = 6; */
        if (message.giftID !== 0)
            writer.tag(6, WireType.Varint).int64(message.giftID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserGiftLogReq
 */
export const ListUserGiftLogReq = new ListUserGiftLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserGiftLogResp$Type extends MessageType<ListUserGiftLogResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserGiftLogResp", [
            { no: 1, name: "logs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserGiftLog },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserGiftLogResp>): ListUserGiftLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.logs = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserGiftLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserGiftLogResp): ListUserGiftLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserGiftLog logs */ 1:
                    message.logs.push(UserGiftLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserGiftLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserGiftLog logs = 1; */
        for (let i = 0; i < message.logs.length; i++)
            UserGiftLog.internalBinaryWrite(message.logs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserGiftLogResp
 */
export const ListUserGiftLogResp = new ListUserGiftLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HasExpiredResReq$Type extends MessageType<HasExpiredResReq> {
    constructor() {
        super("bpt.hall.prop.v1.HasExpiredResReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<HasExpiredResReq>): HasExpiredResReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<HasExpiredResReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HasExpiredResReq): HasExpiredResReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HasExpiredResReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.HasExpiredResReq
 */
export const HasExpiredResReq = new HasExpiredResReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HasExpiredResResp$Type extends MessageType<HasExpiredResResp> {
    constructor() {
        super("bpt.hall.prop.v1.HasExpiredResResp", [
            { no: 1, name: "hasPropExpired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "hasGiftExpired", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<HasExpiredResResp>): HasExpiredResResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.hasPropExpired = false;
        message.hasGiftExpired = false;
        if (value !== undefined)
            reflectionMergePartial<HasExpiredResResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HasExpiredResResp): HasExpiredResResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool hasPropExpired */ 1:
                    message.hasPropExpired = reader.bool();
                    break;
                case /* bool hasGiftExpired */ 2:
                    message.hasGiftExpired = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HasExpiredResResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool hasPropExpired = 1; */
        if (message.hasPropExpired !== false)
            writer.tag(1, WireType.Varint).bool(message.hasPropExpired);
        /* bool hasGiftExpired = 2; */
        if (message.hasGiftExpired !== false)
            writer.tag(2, WireType.Varint).bool(message.hasGiftExpired);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.HasExpiredResResp
 */
export const HasExpiredResResp = new HasExpiredResResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserGiftReq$Type extends MessageType<CleanUpUserGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserGiftReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "giftId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CleanUpUserGiftReq>): CleanUpUserGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.giftId = 0;
        message.expireAt = 0;
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserGiftReq): CleanUpUserGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 giftId */ 2:
                    message.giftId = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 5:
                    message.expireAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CleanUpUserGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 giftId = 2; */
        if (message.giftId !== 0)
            writer.tag(2, WireType.Varint).int64(message.giftId);
        /* int64 expireAt = 5; */
        if (message.expireAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.expireAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserGiftReq
 */
export const CleanUpUserGiftReq = new CleanUpUserGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CleanUpUserGiftResp$Type extends MessageType<CleanUpUserGiftResp> {
    constructor() {
        super("bpt.hall.prop.v1.CleanUpUserGiftResp", []);
    }
    create(value?: PartialMessage<CleanUpUserGiftResp>): CleanUpUserGiftResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<CleanUpUserGiftResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CleanUpUserGiftResp): CleanUpUserGiftResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CleanUpUserGiftResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.CleanUpUserGiftResp
 */
export const CleanUpUserGiftResp = new CleanUpUserGiftResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetGiftReq$Type extends MessageType<BatchGetGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetGiftReq", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetGiftReq>): BatchGetGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetGiftReq): BatchGetGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int64().toNumber());
                    else
                        message.ids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int64(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetGiftReq
 */
export const BatchGetGiftReq = new BatchGetGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateUserPropReq$Type extends MessageType<ActivateUserPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateUserPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<ActivateUserPropReq>): ActivateUserPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivateUserPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateUserPropReq): ActivateUserPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropType type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateUserPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.common.PropType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateUserPropReq
 */
export const ActivateUserPropReq = new ActivateUserPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ActivateUserPropResp$Type extends MessageType<ActivateUserPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.ActivateUserPropResp", [
            { no: 1, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ActivateUserPropResp>): ActivateUserPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.num = 0;
        if (value !== undefined)
            reflectionMergePartial<ActivateUserPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ActivateUserPropResp): ActivateUserPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 num */ 1:
                    message.num = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ActivateUserPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 num = 1; */
        if (message.num !== 0)
            writer.tag(1, WireType.Varint).int64(message.num);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ActivateUserPropResp
 */
export const ActivateUserPropResp = new ActivateUserPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendServiceBroadcastReq$Type extends MessageType<SendServiceBroadcastReq> {
    constructor() {
        super("bpt.hall.prop.v1.SendServiceBroadcastReq", [
            { no: 1, name: "chatId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "content", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SendServiceBroadcastReq>): SendServiceBroadcastReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chatId = 0;
        message.content = "";
        message.propID = 0;
        message.expireAt = 0;
        if (value !== undefined)
            reflectionMergePartial<SendServiceBroadcastReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendServiceBroadcastReq): SendServiceBroadcastReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 chatId */ 1:
                    message.chatId = reader.int64().toNumber();
                    break;
                case /* string content */ 2:
                    message.content = reader.string();
                    break;
                case /* int64 propID */ 3:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 4:
                    message.expireAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendServiceBroadcastReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 chatId = 1; */
        if (message.chatId !== 0)
            writer.tag(1, WireType.Varint).int64(message.chatId);
        /* string content = 2; */
        if (message.content !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.content);
        /* int64 propID = 3; */
        if (message.propID !== 0)
            writer.tag(3, WireType.Varint).int64(message.propID);
        /* int64 expireAt = 4; */
        if (message.expireAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.expireAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SendServiceBroadcastReq
 */
export const SendServiceBroadcastReq = new SendServiceBroadcastReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendServiceBroadcastResp$Type extends MessageType<SendServiceBroadcastResp> {
    constructor() {
        super("bpt.hall.prop.v1.SendServiceBroadcastResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] }
        ]);
    }
    create(value?: PartialMessage<SendServiceBroadcastResp>): SendServiceBroadcastResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<SendServiceBroadcastResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendServiceBroadcastResp): SendServiceBroadcastResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendServiceBroadcastResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SendServiceBroadcastResp
 */
export const SendServiceBroadcastResp = new SendServiceBroadcastResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserCoinReq$Type extends MessageType<GetUserCoinReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserCoinReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserCoinReq>): GetUserCoinReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserCoinReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserCoinReq): GetUserCoinReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserCoinReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserCoinReq
 */
export const GetUserCoinReq = new GetUserCoinReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserCoinResp$Type extends MessageType<GetUserCoinResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserCoinResp", [
            { no: 1, name: "UserCoin", kind: "message", localName: "UserCoin", jsonName: "UserCoin", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<GetUserCoinResp>): GetUserCoinResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserCoinResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserCoinResp): GetUserCoinResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.AssetItem UserCoin = 1 [json_name = "UserCoin"];*/ 1:
                    message.UserCoin = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.UserCoin);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserCoinResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.AssetItem UserCoin = 1 [json_name = "UserCoin"]; */
        if (message.UserCoin)
            AssetItem.internalBinaryWrite(message.UserCoin, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserCoinResp
 */
export const GetUserCoinResp = new GetUserCoinResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DailyTrainCoinConfig$Type extends MessageType<DailyTrainCoinConfig> {
    constructor() {
        super("bpt.hall.prop.v1.DailyTrainCoinConfig", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "receiveInterval", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DailyTrainCoinConfig>): DailyTrainCoinConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.amount = 0;
        message.receiveInterval = 0;
        if (value !== undefined)
            reflectionMergePartial<DailyTrainCoinConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DailyTrainCoinConfig): DailyTrainCoinConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* int64 amount */ 2:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* int64 receiveInterval */ 3:
                    message.receiveInterval = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DailyTrainCoinConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* int64 amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Varint).int64(message.amount);
        /* int64 receiveInterval = 3; */
        if (message.receiveInterval !== 0)
            writer.tag(3, WireType.Varint).int64(message.receiveInterval);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.DailyTrainCoinConfig
 */
export const DailyTrainCoinConfig = new DailyTrainCoinConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDailyTrainingCoinConfigResp$Type extends MessageType<GetDailyTrainingCoinConfigResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetDailyTrainingCoinConfigResp", [
            { no: 1, name: "conf", kind: "message", T: () => DailyTrainCoinConfig }
        ]);
    }
    create(value?: PartialMessage<GetDailyTrainingCoinConfigResp>): GetDailyTrainingCoinConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetDailyTrainingCoinConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDailyTrainingCoinConfigResp): GetDailyTrainingCoinConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.DailyTrainCoinConfig conf */ 1:
                    message.conf = DailyTrainCoinConfig.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDailyTrainingCoinConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.DailyTrainCoinConfig conf = 1; */
        if (message.conf)
            DailyTrainCoinConfig.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetDailyTrainingCoinConfigResp
 */
export const GetDailyTrainingCoinConfigResp = new GetDailyTrainingCoinConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveDailyTrainingCoinConfigReq$Type extends MessageType<SaveDailyTrainingCoinConfigReq> {
    constructor() {
        super("bpt.hall.prop.v1.SaveDailyTrainingCoinConfigReq", [
            { no: 1, name: "conf", kind: "message", T: () => DailyTrainCoinConfig }
        ]);
    }
    create(value?: PartialMessage<SaveDailyTrainingCoinConfigReq>): SaveDailyTrainingCoinConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveDailyTrainingCoinConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveDailyTrainingCoinConfigReq): SaveDailyTrainingCoinConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.DailyTrainCoinConfig conf */ 1:
                    message.conf = DailyTrainCoinConfig.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveDailyTrainingCoinConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.DailyTrainCoinConfig conf = 1; */
        if (message.conf)
            DailyTrainCoinConfig.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SaveDailyTrainingCoinConfigReq
 */
export const SaveDailyTrainingCoinConfigReq = new SaveDailyTrainingCoinConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDailyTrainingCoinReq$Type extends MessageType<GetDailyTrainingCoinReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetDailyTrainingCoinReq", []);
    }
    create(value?: PartialMessage<GetDailyTrainingCoinReq>): GetDailyTrainingCoinReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetDailyTrainingCoinReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDailyTrainingCoinReq): GetDailyTrainingCoinReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetDailyTrainingCoinReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetDailyTrainingCoinReq
 */
export const GetDailyTrainingCoinReq = new GetDailyTrainingCoinReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDailyTrainingCoinResp$Type extends MessageType<GetDailyTrainingCoinResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetDailyTrainingCoinResp", [
            { no: 1, name: "trainCoin", kind: "message", T: () => AssetItem },
            { no: 2, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] }
        ]);
    }
    create(value?: PartialMessage<GetDailyTrainingCoinResp>): GetDailyTrainingCoinResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        if (value !== undefined)
            reflectionMergePartial<GetDailyTrainingCoinResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDailyTrainingCoinResp): GetDailyTrainingCoinResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.AssetItem trainCoin */ 1:
                    message.trainCoin = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.trainCoin);
                    break;
                case /* bpt.common.code.ErrorCode code */ 2:
                    message.code = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDailyTrainingCoinResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.AssetItem trainCoin = 1; */
        if (message.trainCoin)
            AssetItem.internalBinaryWrite(message.trainCoin, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.code.ErrorCode code = 2; */
        if (message.code !== 0)
            writer.tag(2, WireType.Varint).int32(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetDailyTrainingCoinResp
 */
export const GetDailyTrainingCoinResp = new GetDailyTrainingCoinResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserDailyTrainingCoinInfoReq$Type extends MessageType<GetUserDailyTrainingCoinInfoReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoReq", []);
    }
    create(value?: PartialMessage<GetUserDailyTrainingCoinInfoReq>): GetUserDailyTrainingCoinInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserDailyTrainingCoinInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserDailyTrainingCoinInfoReq): GetUserDailyTrainingCoinInfoReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetUserDailyTrainingCoinInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoReq
 */
export const GetUserDailyTrainingCoinInfoReq = new GetUserDailyTrainingCoinInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserDailyTrainingCoinInfoResp$Type extends MessageType<GetUserDailyTrainingCoinInfoResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoResp", [
            { no: 1, name: "conf", kind: "message", T: () => DailyTrainCoinConfig },
            { no: 2, name: "lastReceiveTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserDailyTrainingCoinInfoResp>): GetUserDailyTrainingCoinInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.lastReceiveTime = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserDailyTrainingCoinInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserDailyTrainingCoinInfoResp): GetUserDailyTrainingCoinInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.DailyTrainCoinConfig conf */ 1:
                    message.conf = DailyTrainCoinConfig.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                case /* int64 lastReceiveTime */ 2:
                    message.lastReceiveTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserDailyTrainingCoinInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.DailyTrainCoinConfig conf = 1; */
        if (message.conf)
            DailyTrainCoinConfig.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 lastReceiveTime = 2; */
        if (message.lastReceiveTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.lastReceiveTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserDailyTrainingCoinInfoResp
 */
export const GetUserDailyTrainingCoinInfoResp = new GetUserDailyTrainingCoinInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnChainAssetsChangeItem$Type extends MessageType<OnChainAssetsChangeItem> {
    constructor() {
        super("bpt.hall.prop.v1.OnChainAssetsChangeItem", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "extra", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "itemExtra", kind: "message", T: () => OnChainAssetsChangeItemExtra }
        ]);
    }
    create(value?: PartialMessage<OnChainAssetsChangeItem>): OnChainAssetsChangeItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.amount = "";
        message.extra = "";
        if (value !== undefined)
            reflectionMergePartial<OnChainAssetsChangeItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnChainAssetsChangeItem): OnChainAssetsChangeItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* string amount */ 2:
                    message.amount = reader.string();
                    break;
                case /* string extra */ 4:
                    message.extra = reader.string();
                    break;
                case /* bpt.hall.prop.v1.OnChainAssetsChangeItemExtra itemExtra */ 5:
                    message.itemExtra = OnChainAssetsChangeItemExtra.internalBinaryRead(reader, reader.uint32(), options, message.itemExtra);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnChainAssetsChangeItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* string amount = 2; */
        if (message.amount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.amount);
        /* string extra = 4; */
        if (message.extra !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.extra);
        /* bpt.hall.prop.v1.OnChainAssetsChangeItemExtra itemExtra = 5; */
        if (message.itemExtra)
            OnChainAssetsChangeItemExtra.internalBinaryWrite(message.itemExtra, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.OnChainAssetsChangeItem
 */
export const OnChainAssetsChangeItem = new OnChainAssetsChangeItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnChainAssetsChangeItemExtra$Type extends MessageType<OnChainAssetsChangeItemExtra> {
    constructor() {
        super("bpt.hall.prop.v1.OnChainAssetsChangeItemExtra", [
            { no: 1, name: "tokenId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "expriedTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<OnChainAssetsChangeItemExtra>): OnChainAssetsChangeItemExtra {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tokenId = "";
        message.expriedTime = 0;
        if (value !== undefined)
            reflectionMergePartial<OnChainAssetsChangeItemExtra>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnChainAssetsChangeItemExtra): OnChainAssetsChangeItemExtra {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tokenId */ 1:
                    message.tokenId = reader.string();
                    break;
                case /* int64 expriedTime */ 2:
                    message.expriedTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnChainAssetsChangeItemExtra, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tokenId = 1; */
        if (message.tokenId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tokenId);
        /* int64 expriedTime = 2; */
        if (message.expriedTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.expriedTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.OnChainAssetsChangeItemExtra
 */
export const OnChainAssetsChangeItemExtra = new OnChainAssetsChangeItemExtra$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnChainAssetsAssetsChangeStamp$Type extends MessageType<OnChainAssetsAssetsChangeStamp> {
    constructor() {
        super("bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp", [
            { no: 1, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] },
            { no: 2, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "grantType", kind: "enum", T: () => ["bpt.common.GrantType", GrantType$] },
            { no: 4, name: "scene", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "tradeID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tradeInfo", kind: "message", T: () => TxOnChainDetail }
        ]);
    }
    create(value?: PartialMessage<OnChainAssetsAssetsChangeStamp>): OnChainAssetsAssetsChangeStamp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.way = 0;
        message.operator = "";
        message.grantType = 0;
        message.scene = {};
        message.tradeID = "";
        if (value !== undefined)
            reflectionMergePartial<OnChainAssetsAssetsChangeStamp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnChainAssetsAssetsChangeStamp): OnChainAssetsAssetsChangeStamp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.Way way */ 1:
                    message.way = reader.int32();
                    break;
                case /* string operator */ 2:
                    message.operator = reader.string();
                    break;
                case /* bpt.common.GrantType grantType */ 3:
                    message.grantType = reader.int32();
                    break;
                case /* map<string, string> scene */ 4:
                    this.binaryReadMap4(message.scene, reader, options);
                    break;
                case /* string tradeID */ 5:
                    message.tradeID = reader.string();
                    break;
                case /* bpt.common.TxOnChainDetail tradeInfo */ 6:
                    message.tradeInfo = TxOnChainDetail.internalBinaryRead(reader, reader.uint32(), options, message.tradeInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: OnChainAssetsAssetsChangeStamp["scene"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof OnChainAssetsAssetsChangeStamp["scene"] | undefined, val: OnChainAssetsAssetsChangeStamp["scene"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp.scene");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: OnChainAssetsAssetsChangeStamp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.Way way = 1; */
        if (message.way !== 0)
            writer.tag(1, WireType.Varint).int32(message.way);
        /* string operator = 2; */
        if (message.operator !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.operator);
        /* bpt.common.GrantType grantType = 3; */
        if (message.grantType !== 0)
            writer.tag(3, WireType.Varint).int32(message.grantType);
        /* map<string, string> scene = 4; */
        for (let k of globalThis.Object.keys(message.scene))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.scene[k]).join();
        /* string tradeID = 5; */
        if (message.tradeID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tradeID);
        /* bpt.common.TxOnChainDetail tradeInfo = 6; */
        if (message.tradeInfo)
            TxOnChainDetail.internalBinaryWrite(message.tradeInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp
 */
export const OnChainAssetsAssetsChangeStamp = new OnChainAssetsAssetsChangeStamp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserOnChainAssetsReq$Type extends MessageType<IncUserOnChainAssetsReq> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserOnChainAssetsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "item", kind: "message", T: () => OnChainAssetsChangeItem },
            { no: 3, name: "stamp", kind: "message", T: () => OnChainAssetsAssetsChangeStamp }
        ]);
    }
    create(value?: PartialMessage<IncUserOnChainAssetsReq>): IncUserOnChainAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<IncUserOnChainAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserOnChainAssetsReq): IncUserOnChainAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.OnChainAssetsChangeItem item */ 2:
                    message.item = OnChainAssetsChangeItem.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp */ 3:
                    message.stamp = OnChainAssetsAssetsChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserOnChainAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.hall.prop.v1.OnChainAssetsChangeItem item = 2; */
        if (message.item)
            OnChainAssetsChangeItem.internalBinaryWrite(message.item, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp = 3; */
        if (message.stamp)
            OnChainAssetsAssetsChangeStamp.internalBinaryWrite(message.stamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserOnChainAssetsReq
 */
export const IncUserOnChainAssetsReq = new IncUserOnChainAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserOnChainAssetsResp$Type extends MessageType<IncUserOnChainAssetsResp> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserOnChainAssetsResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IncUserOnChainAssetsResp>): IncUserOnChainAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<IncUserOnChainAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserOnChainAssetsResp): IncUserOnChainAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserOnChainAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserOnChainAssetsResp
 */
export const IncUserOnChainAssetsResp = new IncUserOnChainAssetsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserOnChainAssetsReq$Type extends MessageType<DecUserOnChainAssetsReq> {
    constructor() {
        super("bpt.hall.prop.v1.DecUserOnChainAssetsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "item", kind: "message", T: () => OnChainAssetsChangeItem },
            { no: 3, name: "stamp", kind: "message", T: () => OnChainAssetsAssetsChangeStamp }
        ]);
    }
    create(value?: PartialMessage<DecUserOnChainAssetsReq>): DecUserOnChainAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<DecUserOnChainAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserOnChainAssetsReq): DecUserOnChainAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.OnChainAssetsChangeItem item */ 2:
                    message.item = OnChainAssetsChangeItem.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp */ 3:
                    message.stamp = OnChainAssetsAssetsChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserOnChainAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.hall.prop.v1.OnChainAssetsChangeItem item = 2; */
        if (message.item)
            OnChainAssetsChangeItem.internalBinaryWrite(message.item, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp = 3; */
        if (message.stamp)
            OnChainAssetsAssetsChangeStamp.internalBinaryWrite(message.stamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.DecUserOnChainAssetsReq
 */
export const DecUserOnChainAssetsReq = new DecUserOnChainAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserOnChainAssetsResp$Type extends MessageType<DecUserOnChainAssetsResp> {
    constructor() {
        super("bpt.hall.prop.v1.DecUserOnChainAssetsResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DecUserOnChainAssetsResp>): DecUserOnChainAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<DecUserOnChainAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserOnChainAssetsResp): DecUserOnChainAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserOnChainAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.DecUserOnChainAssetsResp
 */
export const DecUserOnChainAssetsResp = new DecUserOnChainAssetsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserSendOnChainAssetsTxLogReq$Type extends MessageType<IncUserSendOnChainAssetsTxLogReq> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogReq", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "txHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "erc20info", kind: "message", localName: "erc20info", jsonName: "erc20info", T: () => ERC20Info }
        ]);
    }
    create(value?: PartialMessage<IncUserSendOnChainAssetsTxLogReq>): IncUserSendOnChainAssetsTxLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.amount = "";
        message.txHash = "";
        if (value !== undefined)
            reflectionMergePartial<IncUserSendOnChainAssetsTxLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserSendOnChainAssetsTxLogReq): IncUserSendOnChainAssetsTxLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* string amount */ 2:
                    message.amount = reader.string();
                    break;
                case /* string txHash */ 3:
                    message.txHash = reader.string();
                    break;
                case /* gaw.asset.wallet.v1.ERC20Info erc20info = 5 [json_name = "erc20info"];*/ 5:
                    message.erc20info = ERC20Info.internalBinaryRead(reader, reader.uint32(), options, message.erc20info);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserSendOnChainAssetsTxLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* string amount = 2; */
        if (message.amount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.amount);
        /* string txHash = 3; */
        if (message.txHash !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.txHash);
        /* gaw.asset.wallet.v1.ERC20Info erc20info = 5 [json_name = "erc20info"]; */
        if (message.erc20info)
            ERC20Info.internalBinaryWrite(message.erc20info, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogReq
 */
export const IncUserSendOnChainAssetsTxLogReq = new IncUserSendOnChainAssetsTxLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserSendOnChainAssetsTxLogResp$Type extends MessageType<IncUserSendOnChainAssetsTxLogResp> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogResp", []);
    }
    create(value?: PartialMessage<IncUserSendOnChainAssetsTxLogResp>): IncUserSendOnChainAssetsTxLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<IncUserSendOnChainAssetsTxLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserSendOnChainAssetsTxLogResp): IncUserSendOnChainAssetsTxLogResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: IncUserSendOnChainAssetsTxLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserSendOnChainAssetsTxLogResp
 */
export const IncUserSendOnChainAssetsTxLogResp = new IncUserSendOnChainAssetsTxLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserPropBagAssetsReq$Type extends MessageType<IncUserPropBagAssetsReq> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserPropBagAssetsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "item", kind: "message", T: () => OnChainAssetsChangeItem },
            { no: 3, name: "stamp", kind: "message", T: () => OnChainAssetsAssetsChangeStamp }
        ]);
    }
    create(value?: PartialMessage<IncUserPropBagAssetsReq>): IncUserPropBagAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<IncUserPropBagAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserPropBagAssetsReq): IncUserPropBagAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.OnChainAssetsChangeItem item */ 2:
                    message.item = OnChainAssetsChangeItem.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp */ 3:
                    message.stamp = OnChainAssetsAssetsChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserPropBagAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.hall.prop.v1.OnChainAssetsChangeItem item = 2; */
        if (message.item)
            OnChainAssetsChangeItem.internalBinaryWrite(message.item, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.prop.v1.OnChainAssetsAssetsChangeStamp stamp = 3; */
        if (message.stamp)
            OnChainAssetsAssetsChangeStamp.internalBinaryWrite(message.stamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserPropBagAssetsReq
 */
export const IncUserPropBagAssetsReq = new IncUserPropBagAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserPropBagAssetsResp$Type extends MessageType<IncUserPropBagAssetsResp> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserPropBagAssetsResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IncUserPropBagAssetsResp>): IncUserPropBagAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<IncUserPropBagAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserPropBagAssetsResp): IncUserPropBagAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserPropBagAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserPropBagAssetsResp
 */
export const IncUserPropBagAssetsResp = new IncUserPropBagAssetsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserAssetsByMallBuyReq$Type extends MessageType<IncUserAssetsByMallBuyReq> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserAssetsByMallBuyReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "tradeID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "tradeInfo", kind: "message", T: () => IncUserAssetsByMallBuyTradeInfo }
        ]);
    }
    create(value?: PartialMessage<IncUserAssetsByMallBuyReq>): IncUserAssetsByMallBuyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propId = 0;
        message.num = 0;
        message.tradeID = "";
        if (value !== undefined)
            reflectionMergePartial<IncUserAssetsByMallBuyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserAssetsByMallBuyReq): IncUserAssetsByMallBuyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propId */ 2:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                case /* string tradeID */ 4:
                    message.tradeID = reader.string();
                    break;
                case /* bpt.hall.prop.v1.IncUserAssetsByMallBuyTradeInfo tradeInfo */ 5:
                    message.tradeInfo = IncUserAssetsByMallBuyTradeInfo.internalBinaryRead(reader, reader.uint32(), options, message.tradeInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserAssetsByMallBuyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propId = 2; */
        if (message.propId !== 0)
            writer.tag(2, WireType.Varint).int64(message.propId);
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        /* string tradeID = 4; */
        if (message.tradeID !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tradeID);
        /* bpt.hall.prop.v1.IncUserAssetsByMallBuyTradeInfo tradeInfo = 5; */
        if (message.tradeInfo)
            IncUserAssetsByMallBuyTradeInfo.internalBinaryWrite(message.tradeInfo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserAssetsByMallBuyReq
 */
export const IncUserAssetsByMallBuyReq = new IncUserAssetsByMallBuyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserAssetsByMallBuyTradeInfo$Type extends MessageType<IncUserAssetsByMallBuyTradeInfo> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserAssetsByMallBuyTradeInfo", [
            { no: 1, name: "txHash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "contractAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 4, name: "timestamp", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "productId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "txOnChainPrice", kind: "message", T: () => PriceInfo }
        ]);
    }
    create(value?: PartialMessage<IncUserAssetsByMallBuyTradeInfo>): IncUserAssetsByMallBuyTradeInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.txHash = "";
        message.contractAddr = "";
        message.chainID = 0;
        message.timestamp = 0;
        message.productId = "";
        if (value !== undefined)
            reflectionMergePartial<IncUserAssetsByMallBuyTradeInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserAssetsByMallBuyTradeInfo): IncUserAssetsByMallBuyTradeInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string txHash */ 1:
                    message.txHash = reader.string();
                    break;
                case /* string contractAddr */ 2:
                    message.contractAddr = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 3:
                    message.chainID = reader.int32();
                    break;
                case /* uint64 timestamp */ 4:
                    message.timestamp = reader.uint64().toNumber();
                    break;
                case /* string productId */ 5:
                    message.productId = reader.string();
                    break;
                case /* bpt.hall.prop.v1.PriceInfo txOnChainPrice */ 6:
                    message.txOnChainPrice = PriceInfo.internalBinaryRead(reader, reader.uint32(), options, message.txOnChainPrice);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserAssetsByMallBuyTradeInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string txHash = 1; */
        if (message.txHash !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.txHash);
        /* string contractAddr = 2; */
        if (message.contractAddr !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.contractAddr);
        /* gaw.base.ChainID chainID = 3; */
        if (message.chainID !== 0)
            writer.tag(3, WireType.Varint).int32(message.chainID);
        /* uint64 timestamp = 4; */
        if (message.timestamp !== 0)
            writer.tag(4, WireType.Varint).uint64(message.timestamp);
        /* string productId = 5; */
        if (message.productId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.productId);
        /* bpt.hall.prop.v1.PriceInfo txOnChainPrice = 6; */
        if (message.txOnChainPrice)
            PriceInfo.internalBinaryWrite(message.txOnChainPrice, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserAssetsByMallBuyTradeInfo
 */
export const IncUserAssetsByMallBuyTradeInfo = new IncUserAssetsByMallBuyTradeInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserAssetsByMallBuyResp$Type extends MessageType<IncUserAssetsByMallBuyResp> {
    constructor() {
        super("bpt.hall.prop.v1.IncUserAssetsByMallBuyResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IncUserAssetsByMallBuyResp>): IncUserAssetsByMallBuyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<IncUserAssetsByMallBuyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserAssetsByMallBuyResp): IncUserAssetsByMallBuyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserAssetsByMallBuyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.IncUserAssetsByMallBuyResp
 */
export const IncUserAssetsByMallBuyResp = new IncUserAssetsByMallBuyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropBagReq$Type extends MessageType<GetUserPropBagReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropBagReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserPropBagReq>): GetUserPropBagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserPropBagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropBagReq): GetUserPropBagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropBagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropBagReq
 */
export const GetUserPropBagReq = new GetUserPropBagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropBagResp$Type extends MessageType<GetUserPropBagResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropBagResp", [
            { no: 1, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPropBagInfo }
        ]);
    }
    create(value?: PartialMessage<GetUserPropBagResp>): GetUserPropBagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalSize = 0;
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserPropBagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropBagResp): GetUserPropBagResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalSize */ 1:
                    message.totalSize = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.prop.v1.UserPropBagInfo list */ 2:
                    message.list.push(UserPropBagInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropBagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalSize = 1; */
        if (message.totalSize !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalSize);
        /* repeated bpt.hall.prop.v1.UserPropBagInfo list = 2; */
        for (let i = 0; i < message.list.length; i++)
            UserPropBagInfo.internalBinaryWrite(message.list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropBagResp
 */
export const GetUserPropBagResp = new GetUserPropBagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPropBagInfo$Type extends MessageType<UserPropBagInfo> {
    constructor() {
        super("bpt.hall.prop.v1.UserPropBagInfo", [
            { no: 1, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "expireAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "category", kind: "enum", T: () => ["bpt.common.PropCategory", PropCategory] },
            { no: 5, name: "type", kind: "enum", T: () => ["bpt.common.PropType", PropType] },
            { no: 6, name: "amount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "isFree", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UserPropBagInfo>): UserPropBagInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.num = 0;
        message.expireAt = 0;
        message.category = 0;
        message.type = 0;
        message.amount = "";
        message.isFree = false;
        if (value !== undefined)
            reflectionMergePartial<UserPropBagInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPropBagInfo): UserPropBagInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 1:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 num */ 2:
                    message.num = reader.int64().toNumber();
                    break;
                case /* int64 expireAt */ 3:
                    message.expireAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.PropCategory category */ 4:
                    message.category = reader.int32();
                    break;
                case /* bpt.common.PropType type */ 5:
                    message.type = reader.int32();
                    break;
                case /* string amount */ 6:
                    message.amount = reader.string();
                    break;
                case /* bool isFree */ 7:
                    message.isFree = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPropBagInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 1; */
        if (message.propID !== 0)
            writer.tag(1, WireType.Varint).int64(message.propID);
        /* int64 num = 2; */
        if (message.num !== 0)
            writer.tag(2, WireType.Varint).int64(message.num);
        /* int64 expireAt = 3; */
        if (message.expireAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.expireAt);
        /* bpt.common.PropCategory category = 4; */
        if (message.category !== 0)
            writer.tag(4, WireType.Varint).int32(message.category);
        /* bpt.common.PropType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        /* string amount = 6; */
        if (message.amount !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.amount);
        /* bool isFree = 7; */
        if (message.isFree !== false)
            writer.tag(7, WireType.Varint).bool(message.isFree);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserPropBagInfo
 */
export const UserPropBagInfo = new UserPropBagInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropOnTableReq$Type extends MessageType<GetUserPropOnTableReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropOnTableReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "propType", kind: "enum", T: () => ["bpt.common.PropType", PropType] }
        ]);
    }
    create(value?: PartialMessage<GetUserPropOnTableReq>): GetUserPropOnTableReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.tableKey = "";
        message.propType = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserPropOnTableReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropOnTableReq): GetUserPropOnTableReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string tableKey */ 3:
                    message.tableKey = reader.string();
                    break;
                case /* bpt.common.PropType propType */ 4:
                    message.propType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropOnTableReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string tableKey = 3; */
        if (message.tableKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tableKey);
        /* bpt.common.PropType propType = 4; */
        if (message.propType !== 0)
            writer.tag(4, WireType.Varint).int32(message.propType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropOnTableReq
 */
export const GetUserPropOnTableReq = new GetUserPropOnTableReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropOnTableResp$Type extends MessageType<GetUserPropOnTableResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropOnTableResp", [
            { no: 1, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPropBagInfo }
        ]);
    }
    create(value?: PartialMessage<GetUserPropOnTableResp>): GetUserPropOnTableResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalSize = 0;
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserPropOnTableResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropOnTableResp): GetUserPropOnTableResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalSize */ 1:
                    message.totalSize = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.prop.v1.UserPropBagInfo list */ 2:
                    message.list.push(UserPropBagInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropOnTableResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalSize = 1; */
        if (message.totalSize !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalSize);
        /* repeated bpt.hall.prop.v1.UserPropBagInfo list = 2; */
        for (let i = 0; i < message.list.length; i++)
            UserPropBagInfo.internalBinaryWrite(message.list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropOnTableResp
 */
export const GetUserPropOnTableResp = new GetUserPropOnTableResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserSelfUsablePropsOnTableReq$Type extends MessageType<GetUserSelfUsablePropsOnTableReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserSelfUsablePropsOnTableReq>): GetUserSelfUsablePropsOnTableReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserSelfUsablePropsOnTableReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserSelfUsablePropsOnTableReq): GetUserSelfUsablePropsOnTableReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string tableKey */ 3:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserSelfUsablePropsOnTableReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string tableKey = 3; */
        if (message.tableKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableReq
 */
export const GetUserSelfUsablePropsOnTableReq = new GetUserSelfUsablePropsOnTableReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserSelfUsablePropsOnTableResp$Type extends MessageType<GetUserSelfUsablePropsOnTableResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableResp", [
            { no: 1, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPropBagInfo }
        ]);
    }
    create(value?: PartialMessage<GetUserSelfUsablePropsOnTableResp>): GetUserSelfUsablePropsOnTableResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalSize = 0;
        message.list = [];
        if (value !== undefined)
            reflectionMergePartial<GetUserSelfUsablePropsOnTableResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserSelfUsablePropsOnTableResp): GetUserSelfUsablePropsOnTableResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalSize */ 1:
                    message.totalSize = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.prop.v1.UserPropBagInfo list */ 2:
                    message.list.push(UserPropBagInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserSelfUsablePropsOnTableResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalSize = 1; */
        if (message.totalSize !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalSize);
        /* repeated bpt.hall.prop.v1.UserPropBagInfo list = 2; */
        for (let i = 0; i < message.list.length; i++)
            UserPropBagInfo.internalBinaryWrite(message.list[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserSelfUsablePropsOnTableResp
 */
export const GetUserSelfUsablePropsOnTableResp = new GetUserSelfUsablePropsOnTableResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UseInteractivePropReq$Type extends MessageType<UseInteractivePropReq> {
    constructor() {
        super("bpt.hall.prop.v1.UseInteractivePropReq", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "targetUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "playerTableSeatInfo", kind: "message", T: () => UsePropPlayerTableSeatInfo }
        ]);
    }
    create(value?: PartialMessage<UseInteractivePropReq>): UseInteractivePropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.propID = 0;
        message.targetUid = 0;
        message.quantity = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<UseInteractivePropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UseInteractivePropReq): UseInteractivePropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 targetUid */ 3:
                    message.targetUid = reader.int64().toNumber();
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* string matchKey */ 5:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.hall.prop.v1.UsePropPlayerTableSeatInfo playerTableSeatInfo */ 6:
                    message.playerTableSeatInfo = UsePropPlayerTableSeatInfo.internalBinaryRead(reader, reader.uint32(), options, message.playerTableSeatInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UseInteractivePropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 targetUid = 3; */
        if (message.targetUid !== 0)
            writer.tag(3, WireType.Varint).int64(message.targetUid);
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* string matchKey = 5; */
        if (message.matchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.hall.prop.v1.UsePropPlayerTableSeatInfo playerTableSeatInfo = 6; */
        if (message.playerTableSeatInfo)
            UsePropPlayerTableSeatInfo.internalBinaryWrite(message.playerTableSeatInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UseInteractivePropReq
 */
export const UseInteractivePropReq = new UseInteractivePropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropPlayerTableSeatInfo$Type extends MessageType<UsePropPlayerTableSeatInfo> {
    constructor() {
        super("bpt.hall.prop.v1.UsePropPlayerTableSeatInfo", [
            { no: 1, name: "playerSeatId", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "targetPlayerSeatId", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<UsePropPlayerTableSeatInfo>): UsePropPlayerTableSeatInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.playerSeatId = 0;
        message.targetPlayerSeatId = 0;
        if (value !== undefined)
            reflectionMergePartial<UsePropPlayerTableSeatInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropPlayerTableSeatInfo): UsePropPlayerTableSeatInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 playerSeatId */ 1:
                    message.playerSeatId = reader.int32();
                    break;
                case /* int32 targetPlayerSeatId */ 2:
                    message.targetPlayerSeatId = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsePropPlayerTableSeatInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 playerSeatId = 1; */
        if (message.playerSeatId !== 0)
            writer.tag(1, WireType.Varint).int32(message.playerSeatId);
        /* int32 targetPlayerSeatId = 2; */
        if (message.targetPlayerSeatId !== 0)
            writer.tag(2, WireType.Varint).int32(message.targetPlayerSeatId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UsePropPlayerTableSeatInfo
 */
export const UsePropPlayerTableSeatInfo = new UsePropPlayerTableSeatInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UseInteractivePropResp$Type extends MessageType<UseInteractivePropResp> {
    constructor() {
        super("bpt.hall.prop.v1.UseInteractivePropResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UseInteractivePropResp>): UseInteractivePropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<UseInteractivePropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UseInteractivePropResp): UseInteractivePropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UseInteractivePropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UseInteractivePropResp
 */
export const UseInteractivePropResp = new UseInteractivePropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendGiftReq$Type extends MessageType<SendGiftReq> {
    constructor() {
        super("bpt.hall.prop.v1.SendGiftReq", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "receiverUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "playerTableSeatInfo", kind: "message", T: () => UsePropPlayerTableSeatInfo }
        ]);
    }
    create(value?: PartialMessage<SendGiftReq>): SendGiftReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.propID = 0;
        message.receiverUid = 0;
        message.quantity = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<SendGiftReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendGiftReq): SendGiftReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 receiverUid */ 3:
                    message.receiverUid = reader.int64().toNumber();
                    break;
                case /* int32 quantity */ 4:
                    message.quantity = reader.int32();
                    break;
                case /* string matchKey */ 5:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.hall.prop.v1.UsePropPlayerTableSeatInfo playerTableSeatInfo */ 6:
                    message.playerTableSeatInfo = UsePropPlayerTableSeatInfo.internalBinaryRead(reader, reader.uint32(), options, message.playerTableSeatInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendGiftReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 receiverUid = 3; */
        if (message.receiverUid !== 0)
            writer.tag(3, WireType.Varint).int64(message.receiverUid);
        /* int32 quantity = 4; */
        if (message.quantity !== 0)
            writer.tag(4, WireType.Varint).int32(message.quantity);
        /* string matchKey = 5; */
        if (message.matchKey !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.hall.prop.v1.UsePropPlayerTableSeatInfo playerTableSeatInfo = 6; */
        if (message.playerTableSeatInfo)
            UsePropPlayerTableSeatInfo.internalBinaryWrite(message.playerTableSeatInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SendGiftReq
 */
export const SendGiftReq = new SendGiftReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendGiftResp$Type extends MessageType<SendGiftResp> {
    constructor() {
        super("bpt.hall.prop.v1.SendGiftResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendGiftResp>): SendGiftResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<SendGiftResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendGiftResp): SendGiftResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendGiftResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SendGiftResp
 */
export const SendGiftResp = new SendGiftResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropOnSelfReq$Type extends MessageType<UsePropOnSelfReq> {
    constructor() {
        super("bpt.hall.prop.v1.UsePropOnSelfReq", [
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "quantity", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "additional", kind: "message", T: () => UsePropAdditionalInfo }
        ]);
    }
    create(value?: PartialMessage<UsePropOnSelfReq>): UsePropOnSelfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propID = 0;
        message.tableKey = "";
        message.matchKey = "";
        message.quantity = 0;
        if (value !== undefined)
            reflectionMergePartial<UsePropOnSelfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropOnSelfReq): UsePropOnSelfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* string tableKey */ 3:
                    message.tableKey = reader.string();
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                case /* int32 quantity */ 5:
                    message.quantity = reader.int32();
                    break;
                case /* bpt.hall.prop.v1.UsePropAdditionalInfo additional */ 6:
                    message.additional = UsePropAdditionalInfo.internalBinaryRead(reader, reader.uint32(), options, message.additional);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsePropOnSelfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* string tableKey = 3; */
        if (message.tableKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tableKey);
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        /* int32 quantity = 5; */
        if (message.quantity !== 0)
            writer.tag(5, WireType.Varint).int32(message.quantity);
        /* bpt.hall.prop.v1.UsePropAdditionalInfo additional = 6; */
        if (message.additional)
            UsePropAdditionalInfo.internalBinaryWrite(message.additional, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UsePropOnSelfReq
 */
export const UsePropOnSelfReq = new UsePropOnSelfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropAdditionalInfo$Type extends MessageType<UsePropAdditionalInfo> {
    constructor() {
        super("bpt.hall.prop.v1.UsePropAdditionalInfo", [
            { no: 1, name: "rngshuffleCard", kind: "message", T: () => UsePropRNGShuffleCardParam }
        ]);
    }
    create(value?: PartialMessage<UsePropAdditionalInfo>): UsePropAdditionalInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UsePropAdditionalInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropAdditionalInfo): UsePropAdditionalInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.UsePropRNGShuffleCardParam rngshuffleCard */ 1:
                    message.rngshuffleCard = UsePropRNGShuffleCardParam.internalBinaryRead(reader, reader.uint32(), options, message.rngshuffleCard);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsePropAdditionalInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.UsePropRNGShuffleCardParam rngshuffleCard = 1; */
        if (message.rngshuffleCard)
            UsePropRNGShuffleCardParam.internalBinaryWrite(message.rngshuffleCard, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UsePropAdditionalInfo
 */
export const UsePropAdditionalInfo = new UsePropAdditionalInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropRNGShuffleCardParam$Type extends MessageType<UsePropRNGShuffleCardParam> {
    constructor() {
        super("bpt.hall.prop.v1.UsePropRNGShuffleCardParam", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UsePropRNGShuffleCardParam>): UsePropRNGShuffleCardParam {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.text = "";
        if (value !== undefined)
            reflectionMergePartial<UsePropRNGShuffleCardParam>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropRNGShuffleCardParam): UsePropRNGShuffleCardParam {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsePropRNGShuffleCardParam, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UsePropRNGShuffleCardParam
 */
export const UsePropRNGShuffleCardParam = new UsePropRNGShuffleCardParam$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropOnSelfResp$Type extends MessageType<UsePropOnSelfResp> {
    constructor() {
        super("bpt.hall.prop.v1.UsePropOnSelfResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UsePropOnSelfResp>): UsePropOnSelfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<UsePropOnSelfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropOnSelfResp): UsePropOnSelfResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsePropOnSelfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UsePropOnSelfResp
 */
export const UsePropOnSelfResp = new UsePropOnSelfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropActivityLogReq$Type extends MessageType<ListUserPropActivityLogReq> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropActivityLogReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "beginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "activityType", kind: "enum", T: () => ["bpt.hall.prop.v1.UserPropActivityType", UserPropActivityType] }
        ]);
    }
    create(value?: PartialMessage<ListUserPropActivityLogReq>): ListUserPropActivityLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.beginAt = 0;
        message.endAt = 0;
        message.activityType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPropActivityLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropActivityLogReq): ListUserPropActivityLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 beginAt */ 3:
                    message.beginAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 4:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.UserPropActivityType activityType */ 5:
                    message.activityType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropActivityLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 beginAt = 3; */
        if (message.beginAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.beginAt);
        /* int64 endAt = 4; */
        if (message.endAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.endAt);
        /* bpt.hall.prop.v1.UserPropActivityType activityType = 5; */
        if (message.activityType !== 0)
            writer.tag(5, WireType.Varint).int32(message.activityType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropActivityLogReq
 */
export const ListUserPropActivityLogReq = new ListUserPropActivityLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserPropActivityLogResp$Type extends MessageType<ListUserPropActivityLogResp> {
    constructor() {
        super("bpt.hall.prop.v1.ListUserPropActivityLogResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPropActivityLog },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserPropActivityLogResp>): ListUserPropActivityLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserPropActivityLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserPropActivityLogResp): ListUserPropActivityLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.prop.v1.UserPropActivityLog list */ 1:
                    message.list.push(UserPropActivityLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserPropActivityLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.prop.v1.UserPropActivityLog list = 1; */
        for (let i = 0; i < message.list.length; i++)
            UserPropActivityLog.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.ListUserPropActivityLogResp
 */
export const ListUserPropActivityLogResp = new ListUserPropActivityLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropNumReq$Type extends MessageType<GetUserPropNumReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropNumReq", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserPropNumReq>): GetUserPropNumReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserPropNumReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropNumReq): GetUserPropNumReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropNumReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropNumReq
 */
export const GetUserPropNumReq = new GetUserPropNumReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserPropNumResp$Type extends MessageType<GetUserPropNumResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetUserPropNumResp", [
            { no: 1, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserPropNumResp>): GetUserPropNumResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.num = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserPropNumResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserPropNumResp): GetUserPropNumResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 num */ 1:
                    message.num = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserPropNumResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 num = 1; */
        if (message.num !== 0)
            writer.tag(1, WireType.Varint).int64(message.num);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetUserPropNumResp
 */
export const GetUserPropNumResp = new GetUserPropNumResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserPropNumReq$Type extends MessageType<BatchGetUserPropNumReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserPropNumReq", [
            { no: 1, name: "propIdList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserPropNumReq>): BatchGetUserPropNumReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propIdList = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserPropNumReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserPropNumReq): BatchGetUserPropNumReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 propIdList */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propIdList.push(reader.int64().toNumber());
                    else
                        message.propIdList.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserPropNumReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 propIdList = 1; */
        if (message.propIdList.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propIdList.length; i++)
                writer.int64(message.propIdList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserPropNumReq
 */
export const BatchGetUserPropNumReq = new BatchGetUserPropNumReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserPropNumResp$Type extends MessageType<BatchGetUserPropNumResp> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetUserPropNumResp", [
            { no: 1, name: "propIdToNum", kind: "map", K: 3 /*ScalarType.INT64*/, V: { kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ } }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserPropNumResp>): BatchGetUserPropNumResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propIdToNum = {};
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserPropNumResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserPropNumResp): BatchGetUserPropNumResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int64, int64> propIdToNum */ 1:
                    this.binaryReadMap1(message.propIdToNum, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: BatchGetUserPropNumResp["propIdToNum"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BatchGetUserPropNumResp["propIdToNum"] | undefined, val: BatchGetUserPropNumResp["propIdToNum"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int64().toString();
                    break;
                case 2:
                    val = reader.int64().toNumber();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.prop.v1.BatchGetUserPropNumResp.propIdToNum");
            }
        }
        map[key ?? "0"] = val ?? 0;
    }
    internalBinaryWrite(message: BatchGetUserPropNumResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int64, int64> propIdToNum = 1; */
        for (let k of globalThis.Object.keys(message.propIdToNum))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int64(k).tag(2, WireType.Varint).int64(message.propIdToNum[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetUserPropNumResp
 */
export const BatchGetUserPropNumResp = new BatchGetUserPropNumResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPropActivityLog$Type extends MessageType<UserPropActivityLog> {
    constructor() {
        super("bpt.hall.prop.v1.UserPropActivityLog", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "activityType", kind: "enum", T: () => ["bpt.hall.prop.v1.UserPropActivityType", UserPropActivityType] },
            { no: 3, name: "quantity", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "sign", kind: "enum", T: () => ["bpt.common.Sign", Sign] },
            { no: 5, name: "createTime", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "targetUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "txOnChainPrice", kind: "message", T: () => PriceInfo },
            { no: 10, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserPropActivityLog>): UserPropActivityLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.activityType = 0;
        message.quantity = 0;
        message.sign = 0;
        message.createTime = 0;
        message.targetUid = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<UserPropActivityLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPropActivityLog): UserPropActivityLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.UserPropActivityType activityType */ 2:
                    message.activityType = reader.int32();
                    break;
                case /* int64 quantity */ 3:
                    message.quantity = reader.int64().toNumber();
                    break;
                case /* bpt.common.Sign sign */ 4:
                    message.sign = reader.int32();
                    break;
                case /* uint64 createTime */ 5:
                    message.createTime = reader.uint64().toNumber();
                    break;
                case /* int64 targetUid */ 8:
                    message.targetUid = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.PriceInfo txOnChainPrice */ 9:
                    message.txOnChainPrice = PriceInfo.internalBinaryRead(reader, reader.uint32(), options, message.txOnChainPrice);
                    break;
                case /* string matchKey */ 10:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPropActivityLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* bpt.hall.prop.v1.UserPropActivityType activityType = 2; */
        if (message.activityType !== 0)
            writer.tag(2, WireType.Varint).int32(message.activityType);
        /* int64 quantity = 3; */
        if (message.quantity !== 0)
            writer.tag(3, WireType.Varint).int64(message.quantity);
        /* bpt.common.Sign sign = 4; */
        if (message.sign !== 0)
            writer.tag(4, WireType.Varint).int32(message.sign);
        /* uint64 createTime = 5; */
        if (message.createTime !== 0)
            writer.tag(5, WireType.Varint).uint64(message.createTime);
        /* int64 targetUid = 8; */
        if (message.targetUid !== 0)
            writer.tag(8, WireType.Varint).int64(message.targetUid);
        /* bpt.hall.prop.v1.PriceInfo txOnChainPrice = 9; */
        if (message.txOnChainPrice)
            PriceInfo.internalBinaryWrite(message.txOnChainPrice, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 10; */
        if (message.matchKey !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UserPropActivityLog
 */
export const UserPropActivityLog = new UserPropActivityLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreezePropReq$Type extends MessageType<FreezePropReq> {
    constructor() {
        super("bpt.hall.prop.v1.FreezePropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<FreezePropReq>): FreezePropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.num = 0;
        if (value !== undefined)
            reflectionMergePartial<FreezePropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreezePropReq): FreezePropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreezePropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.FreezePropReq
 */
export const FreezePropReq = new FreezePropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnFreezePropReq$Type extends MessageType<UnFreezePropReq> {
    constructor() {
        super("bpt.hall.prop.v1.UnFreezePropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UnFreezePropReq>): UnFreezePropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.num = 0;
        if (value !== undefined)
            reflectionMergePartial<UnFreezePropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnFreezePropReq): UnFreezePropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnFreezePropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.UnFreezePropReq
 */
export const UnFreezePropReq = new UnFreezePropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SellDelPropReq$Type extends MessageType<SellDelPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.SellDelPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SellDelPropReq>): SellDelPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propID = 0;
        message.num = 0;
        if (value !== undefined)
            reflectionMergePartial<SellDelPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SellDelPropReq): SellDelPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propID */ 2:
                    message.propID = reader.int64().toNumber();
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SellDelPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propID = 2; */
        if (message.propID !== 0)
            writer.tag(2, WireType.Varint).int64(message.propID);
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.SellDelPropReq
 */
export const SellDelPropReq = new SellDelPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BaseResp$Type extends MessageType<BaseResp> {
    constructor() {
        super("bpt.hall.prop.v1.BaseResp", []);
    }
    create(value?: PartialMessage<BaseResp>): BaseResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<BaseResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BaseResp): BaseResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BaseResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BaseResp
 */
export const BaseResp = new BaseResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPropInternalReq$Type extends MessageType<GetPropInternalReq> {
    constructor() {
        super("bpt.hall.prop.v1.GetPropInternalReq", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "filterSource", kind: "enum", T: () => ["bpt.hall.prop.v1.GetPropInternalReq.FilterSource", GetPropInternalReq_FilterSource] }
        ]);
    }
    create(value?: PartialMessage<GetPropInternalReq>): GetPropInternalReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.filterSource = 0;
        if (value !== undefined)
            reflectionMergePartial<GetPropInternalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPropInternalReq): GetPropInternalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* bpt.hall.prop.v1.GetPropInternalReq.FilterSource filterSource */ 2:
                    message.filterSource = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPropInternalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* bpt.hall.prop.v1.GetPropInternalReq.FilterSource filterSource = 2; */
        if (message.filterSource !== 0)
            writer.tag(2, WireType.Varint).int32(message.filterSource);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetPropInternalReq
 */
export const GetPropInternalReq = new GetPropInternalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPropInternalResp$Type extends MessageType<GetPropInternalResp> {
    constructor() {
        super("bpt.hall.prop.v1.GetPropInternalResp", [
            { no: 1, name: "prop", kind: "message", T: () => Prop }
        ]);
    }
    create(value?: PartialMessage<GetPropInternalResp>): GetPropInternalResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetPropInternalResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPropInternalResp): GetPropInternalResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.prop.v1.Prop prop */ 1:
                    message.prop = Prop.internalBinaryRead(reader, reader.uint32(), options, message.prop);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPropInternalResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.prop.v1.Prop prop = 1; */
        if (message.prop)
            Prop.internalBinaryWrite(message.prop, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.GetPropInternalResp
 */
export const GetPropInternalResp = new GetPropInternalResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminSendPropReq$Type extends MessageType<AdminSendPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.AdminSendPropReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AdminSendPropReq>): AdminSendPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.propId = 0;
        message.num = 0;
        message.reason = "";
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<AdminSendPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminSendPropReq): AdminSendPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* int64 propId */ 2:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                case /* string reason */ 4:
                    message.reason = reader.string();
                    break;
                case /* string operator */ 5:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminSendPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* int64 propId = 2; */
        if (message.propId !== 0)
            writer.tag(2, WireType.Varint).int64(message.propId);
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        /* string reason = 4; */
        if (message.reason !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.reason);
        /* string operator = 5; */
        if (message.operator !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.AdminSendPropReq
 */
export const AdminSendPropReq = new AdminSendPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminSendPropResp$Type extends MessageType<AdminSendPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.AdminSendPropResp", [
            { no: 1, name: "batchId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AdminSendPropResp>): AdminSendPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchId = "";
        if (value !== undefined)
            reflectionMergePartial<AdminSendPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminSendPropResp): AdminSendPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchId */ 1:
                    message.batchId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminSendPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchId = 1; */
        if (message.batchId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.AdminSendPropResp
 */
export const AdminSendPropResp = new AdminSendPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetPropPriceInternalReq$Type extends MessageType<BatchGetPropPriceInternalReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetPropPriceInternalReq", [
            { no: 1, name: "propIdList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetPropPriceInternalReq>): BatchGetPropPriceInternalReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propIdList = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetPropPriceInternalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetPropPriceInternalReq): BatchGetPropPriceInternalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 propIdList */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propIdList.push(reader.int64().toNumber());
                    else
                        message.propIdList.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetPropPriceInternalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 propIdList = 1; */
        if (message.propIdList.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propIdList.length; i++)
                writer.int64(message.propIdList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetPropPriceInternalReq
 */
export const BatchGetPropPriceInternalReq = new BatchGetPropPriceInternalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetPropPriceInternalResp$Type extends MessageType<BatchGetPropPriceInternalResp> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetPropPriceInternalResp", [
            { no: 1, name: "propIdToPriceInfo", kind: "map", K: 3 /*ScalarType.INT64*/, V: { kind: "message", T: () => PriceInfo } }
        ]);
    }
    create(value?: PartialMessage<BatchGetPropPriceInternalResp>): BatchGetPropPriceInternalResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propIdToPriceInfo = {};
        if (value !== undefined)
            reflectionMergePartial<BatchGetPropPriceInternalResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetPropPriceInternalResp): BatchGetPropPriceInternalResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int64, bpt.hall.prop.v1.PriceInfo> propIdToPriceInfo */ 1:
                    this.binaryReadMap1(message.propIdToPriceInfo, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: BatchGetPropPriceInternalResp["propIdToPriceInfo"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BatchGetPropPriceInternalResp["propIdToPriceInfo"] | undefined, val: BatchGetPropPriceInternalResp["propIdToPriceInfo"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int64().toString();
                    break;
                case 2:
                    val = PriceInfo.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.prop.v1.BatchGetPropPriceInternalResp.propIdToPriceInfo");
            }
        }
        map[key ?? "0"] = val ?? PriceInfo.create();
    }
    internalBinaryWrite(message: BatchGetPropPriceInternalResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int64, bpt.hall.prop.v1.PriceInfo> propIdToPriceInfo = 1; */
        for (let k of globalThis.Object.keys(message.propIdToPriceInfo)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            PriceInfo.internalBinaryWrite(message.propIdToPriceInfo[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetPropPriceInternalResp
 */
export const BatchGetPropPriceInternalResp = new BatchGetPropPriceInternalResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetPropMTTPriceInternalReq$Type extends MessageType<BatchGetPropMTTPriceInternalReq> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetPropMTTPriceInternalReq", [
            { no: 1, name: "propIdList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetPropMTTPriceInternalReq>): BatchGetPropMTTPriceInternalReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propIdList = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetPropMTTPriceInternalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetPropMTTPriceInternalReq): BatchGetPropMTTPriceInternalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 propIdList */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propIdList.push(reader.int64().toNumber());
                    else
                        message.propIdList.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetPropMTTPriceInternalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 propIdList = 1; */
        if (message.propIdList.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propIdList.length; i++)
                writer.int64(message.propIdList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetPropMTTPriceInternalReq
 */
export const BatchGetPropMTTPriceInternalReq = new BatchGetPropMTTPriceInternalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetPropMTTPriceInternalResp$Type extends MessageType<BatchGetPropMTTPriceInternalResp> {
    constructor() {
        super("bpt.hall.prop.v1.BatchGetPropMTTPriceInternalResp", [
            { no: 1, name: "propIdToMTTPriceInfo", kind: "map", K: 3 /*ScalarType.INT64*/, V: { kind: "message", T: () => MTTPriceInfo } }
        ]);
    }
    create(value?: PartialMessage<BatchGetPropMTTPriceInternalResp>): BatchGetPropMTTPriceInternalResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propIdToMTTPriceInfo = {};
        if (value !== undefined)
            reflectionMergePartial<BatchGetPropMTTPriceInternalResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetPropMTTPriceInternalResp): BatchGetPropMTTPriceInternalResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int64, bpt.hall.prop.v1.MTTPriceInfo> propIdToMTTPriceInfo */ 1:
                    this.binaryReadMap1(message.propIdToMTTPriceInfo, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: BatchGetPropMTTPriceInternalResp["propIdToMTTPriceInfo"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BatchGetPropMTTPriceInternalResp["propIdToMTTPriceInfo"] | undefined, val: BatchGetPropMTTPriceInternalResp["propIdToMTTPriceInfo"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int64().toString();
                    break;
                case 2:
                    val = MTTPriceInfo.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.prop.v1.BatchGetPropMTTPriceInternalResp.propIdToMTTPriceInfo");
            }
        }
        map[key ?? "0"] = val ?? MTTPriceInfo.create();
    }
    internalBinaryWrite(message: BatchGetPropMTTPriceInternalResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int64, bpt.hall.prop.v1.MTTPriceInfo> propIdToMTTPriceInfo = 1; */
        for (let k of globalThis.Object.keys(message.propIdToMTTPriceInfo)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            MTTPriceInfo.internalBinaryWrite(message.propIdToMTTPriceInfo[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.BatchGetPropMTTPriceInternalResp
 */
export const BatchGetPropMTTPriceInternalResp = new BatchGetPropMTTPriceInternalResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterOffChainPropReq$Type extends MessageType<FilterOffChainPropReq> {
    constructor() {
        super("bpt.hall.prop.v1.FilterOffChainPropReq", [
            { no: 1, name: "propIdList", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<FilterOffChainPropReq>): FilterOffChainPropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propIdList = [];
        if (value !== undefined)
            reflectionMergePartial<FilterOffChainPropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterOffChainPropReq): FilterOffChainPropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 propIdList */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.propIdList.push(reader.int64().toNumber());
                    else
                        message.propIdList.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FilterOffChainPropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 propIdList = 1; */
        if (message.propIdList.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.propIdList.length; i++)
                writer.int64(message.propIdList[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.FilterOffChainPropReq
 */
export const FilterOffChainPropReq = new FilterOffChainPropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FilterOffChainPropResp$Type extends MessageType<FilterOffChainPropResp> {
    constructor() {
        super("bpt.hall.prop.v1.FilterOffChainPropResp", [
            { no: 1, name: "offChainProp", kind: "map", K: 3 /*ScalarType.INT64*/, V: { kind: "message", T: () => Prop } }
        ]);
    }
    create(value?: PartialMessage<FilterOffChainPropResp>): FilterOffChainPropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.offChainProp = {};
        if (value !== undefined)
            reflectionMergePartial<FilterOffChainPropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FilterOffChainPropResp): FilterOffChainPropResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<int64, bpt.hall.prop.v1.Prop> offChainProp */ 1:
                    this.binaryReadMap1(message.offChainProp, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: FilterOffChainPropResp["offChainProp"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof FilterOffChainPropResp["offChainProp"] | undefined, val: FilterOffChainPropResp["offChainProp"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int64().toString();
                    break;
                case 2:
                    val = Prop.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.prop.v1.FilterOffChainPropResp.offChainProp");
            }
        }
        map[key ?? "0"] = val ?? Prop.create();
    }
    internalBinaryWrite(message: FilterOffChainPropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<int64, bpt.hall.prop.v1.Prop> offChainProp = 1; */
        for (let k of globalThis.Object.keys(message.offChainProp)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int64(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Prop.internalBinaryWrite(message.offChainProp[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.FilterOffChainPropResp
 */
export const FilterOffChainPropResp = new FilterOffChainPropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MTTPriceInfo$Type extends MessageType<MTTPriceInfo> {
    constructor() {
        super("bpt.hall.prop.v1.MTTPriceInfo", [
            { no: 1, name: "mttRawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "mttDecimals", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "isNFT", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "propDecimals", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "isMTT", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MTTPriceInfo>): MTTPriceInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mttRawAmount = "";
        message.mttDecimals = 0;
        message.isNFT = false;
        message.propDecimals = 0;
        message.isMTT = false;
        if (value !== undefined)
            reflectionMergePartial<MTTPriceInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MTTPriceInfo): MTTPriceInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mttRawAmount */ 1:
                    message.mttRawAmount = reader.string();
                    break;
                case /* uint32 mttDecimals */ 2:
                    message.mttDecimals = reader.uint32();
                    break;
                case /* bool isNFT */ 3:
                    message.isNFT = reader.bool();
                    break;
                case /* uint32 propDecimals */ 4:
                    message.propDecimals = reader.uint32();
                    break;
                case /* bool isMTT */ 5:
                    message.isMTT = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MTTPriceInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mttRawAmount = 1; */
        if (message.mttRawAmount !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.mttRawAmount);
        /* uint32 mttDecimals = 2; */
        if (message.mttDecimals !== 0)
            writer.tag(2, WireType.Varint).uint32(message.mttDecimals);
        /* bool isNFT = 3; */
        if (message.isNFT !== false)
            writer.tag(3, WireType.Varint).bool(message.isNFT);
        /* uint32 propDecimals = 4; */
        if (message.propDecimals !== 0)
            writer.tag(4, WireType.Varint).uint32(message.propDecimals);
        /* bool isMTT = 5; */
        if (message.isMTT !== false)
            writer.tag(5, WireType.Varint).bool(message.isMTT);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.prop.v1.MTTPriceInfo
 */
export const MTTPriceInfo = new MTTPriceInfo$Type();
/**
 * @generated ServiceType for protobuf service bpt.hall.prop.v1.PropService
 */
export const PropService = new ServiceType("bpt.hall.prop.v1.PropService", [
    { name: "GetProp", options: {}, I: GetPropReq, O: GetPropResp },
    { name: "BatchGetProp", options: {}, I: BatchGetPropReq, O: BatchGetPropResp },
    { name: "ListProp", options: {}, I: ListPropReq, O: ListPropResp },
    { name: "GetPropGaw", options: {}, I: Void, O: GetPropGawResp },
    { name: "GetUserPropGawBalanceList", options: {}, I: GetUserPropGawBalanceListReq, O: GetUserPropGawBalanceListResp },
    { name: "BatchGetUserProp", options: {}, I: BatchGetUserPropReq, O: BatchGetUserPropResp },
    { name: "GetUserCoin", options: {}, I: GetUserCoinReq, O: GetUserCoinResp },
    { name: "GetDailyTrainingCoin", options: {}, I: GetDailyTrainingCoinReq, O: GetDailyTrainingCoinResp },
    { name: "GetUserDailyTrainingCoinInfo", options: {}, I: GetUserDailyTrainingCoinInfoReq, O: GetUserDailyTrainingCoinInfoResp },
    { name: "ListUserTxOnChainLog", options: {}, I: ListUserTxOnChainLogReq, O: ListUserTxOnChainLogResp },
    { name: "ListUserPropNFT", options: {}, I: ListUserPropNFTReq, O: ListUserPropNFTResp },
    { name: "GetUserPropBag", options: {}, I: GetUserPropBagReq, O: GetUserPropBagResp },
    { name: "GetUserPropOnTable", options: {}, I: GetUserPropOnTableReq, O: GetUserPropOnTableResp },
    { name: "GetUserSelfUsablePropsOnTable", options: {}, I: GetUserSelfUsablePropsOnTableReq, O: GetUserSelfUsablePropsOnTableResp },
    { name: "UseInteractiveProp", options: {}, I: UseInteractivePropReq, O: UseInteractivePropResp },
    { name: "SendGift", options: {}, I: SendGiftReq, O: SendGiftResp },
    { name: "ListUserPropActivityLog", options: {}, I: ListUserPropActivityLogReq, O: ListUserPropActivityLogResp },
    { name: "GetUserPropNum", options: {}, I: GetUserPropNumReq, O: GetUserPropNumResp },
    { name: "BatchGetUserPropNum", options: {}, I: BatchGetUserPropNumReq, O: BatchGetUserPropNumResp },
    { name: "UsePropOnSelf", options: {}, I: UsePropOnSelfReq, O: UsePropOnSelfResp },
    { name: "IncUserSendOnChainAssetsTxLog", options: {}, I: IncUserSendOnChainAssetsTxLogReq, O: IncUserSendOnChainAssetsTxLogResp },
    { name: "SaveProp", options: { "blocker.exportclient": 0 }, I: SavePropReq, O: SavePropResp },
    { name: "GetStats", options: { "blocker.exportclient": 0 }, I: GetStatsReq, O: GetStatsResp },
    { name: "IncUserAssets", options: { "blocker.exportclient": 0 }, I: IncUserAssetsReq, O: IncUserAssetsResp },
    { name: "DecUserAssets", options: { "blocker.exportclient": 0 }, I: DecUserAssetsReq, O: DecUserAssetsResp },
    { name: "IncUserOnChainAssets", options: { "blocker.exportclient": 0 }, I: IncUserOnChainAssetsReq, O: IncUserOnChainAssetsResp },
    { name: "DecUserOnChainAssets", options: { "blocker.exportclient": 0 }, I: DecUserOnChainAssetsReq, O: DecUserOnChainAssetsResp },
    { name: "IncUserAssetsByMallBuy", options: { "blocker.exportclient": 0 }, I: IncUserAssetsByMallBuyReq, O: IncUserAssetsByMallBuyResp },
    { name: "FillAssets", options: { "blocker.exportclient": 0 }, I: FillAssetsReq, O: FillAssetsResp },
    { name: "RefundUserProp", options: { "blocker.exportclient": 0 }, I: RefundUserPropReq, O: RefundUserPropResp },
    { name: "CMSListUserRes", options: { "blocker.exportclient": 0 }, I: CMSListUserResReq, O: CMSListUserResResp },
    { name: "ListUserPropLog", options: { "blocker.exportclient": 0 }, I: ListUserPropLogReq, O: ListUserPropLogResp },
    { name: "CheckUserExpiredPropCallback", options: { "blocker.exportclient": 0 }, I: CheckUserExpiredPropCallbackReq, O: Void },
    { name: "BatchGetPropForSync", options: { "blocker.exportclient": 0 }, I: BatchGetResourceReq, O: BatchGetResourceResp },
    { name: "ListSimplyProp", options: { "blocker.exportclient": 0 }, I: ListSimplyPropReq, O: ListSimplyPropResp },
    { name: "FreezeProp", options: { "blocker.exportclient": 0 }, I: FreezePropReq, O: BaseResp },
    { name: "UnFreezeProp", options: { "blocker.exportclient": 0 }, I: UnFreezePropReq, O: BaseResp },
    { name: "SellDelProp", options: { "blocker.exportclient": 0 }, I: SellDelPropReq, O: BaseResp },
    { name: "ListPropCategory", options: { "blocker.exportclient": 0 }, I: Void, O: ListPropCategoryResp },
    { name: "ListPropType", options: { "blocker.exportclient": 0 }, I: ListPropTypeReq, O: ListPropTypeResp },
    { name: "GetDailyTrainingCoinConfig", options: { "blocker.exportclient": 0 }, I: Void, O: GetDailyTrainingCoinConfigResp },
    { name: "SaveDailyTrainingCoinConfig", options: { "blocker.exportclient": 0 }, I: SaveDailyTrainingCoinConfigReq, O: Void },
    { name: "UpdateUserTxOnChainLog", options: { "blocker.exportclient": 0 }, I: UpdateUserTxOnChainLogReq, O: UpdateUserTxOnChainLogResp },
    { name: "ListUserPropNftInternal", options: { "blocker.exportclient": 0 }, I: ListUserPropNftInternalReq, O: ListUserPropNftInternalResp },
    { name: "GetPropInternal", options: { "blocker.exportclient": 0 }, I: GetPropInternalReq, O: GetPropInternalResp },
    { name: "AdminSendProp", options: { "blocker.exportclient": 0 }, I: AdminSendPropReq, O: AdminSendPropResp },
    { name: "BatchGetPropPriceInternal", options: { "blocker.exportclient": 0 }, I: BatchGetPropPriceInternalReq, O: BatchGetPropPriceInternalResp },
    { name: "BatchGetPropMTTPriceInternal", options: { "blocker.exportclient": 0 }, I: BatchGetPropMTTPriceInternalReq, O: BatchGetPropMTTPriceInternalResp },
    { name: "FilterOffChainProp", options: {}, I: FilterOffChainPropReq, O: FilterOffChainPropResp },
    { name: "ListUserTxOnChainLogInternal", options: { "blocker.exportclient": 0 }, I: ListUserTxOnChainLogInternalReq, O: ListUserTxOnChainLogInternalResp }
]);
