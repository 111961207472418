// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/match/matchhub/v1/matchhub.proto" (package "bpt.match.matchhub.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { MsgMatchOver } from "../../../broker/match/match";
import { MsgMatchUserSettlement } from "../../../broker/match/match";
import { MsgMatchRegisterDeadline } from "../../../broker/match/match";
import { RestoreMatchTablesResp } from "../../mttmatch/v1/mttmatch";
import { RestoreMatchTablesReq } from "../../mttmatch/v1/mttmatch";
import { GetMyTableResp } from "../../mttmatch/v1/mttmatch";
import { GetMyTableReq } from "../../mttmatch/v1/mttmatch";
import { ObserveTableOnBubbleResp } from "../../mttmatch/v1/mttmatch";
import { ObserveTableOnBubbleReq } from "../../mttmatch/v1/mttmatch";
import { ObserveTableResp } from "../../mttmatch/v1/mttmatch";
import { ObserveTableReq } from "../../mttmatch/v1/mttmatch";
import { BatchGetUserResp } from "../../mttmatch/v1/mttmatch";
import { BatchGetUserReq } from "../../mttmatch/v1/mttmatch";
import { ListDesksResp } from "../../mttmatch/v1/mttmatch";
import { ListDesksReq } from "../../mttmatch/v1/mttmatch";
import { ListRankResp } from "../../mttmatch/v1/mttmatch";
import { ListRankReq } from "../../mttmatch/v1/mttmatch";
import { MatchOverViewResp } from "../../mttmatch/v1/mttmatch";
import { MatchOverViewReq } from "../../mttmatch/v1/mttmatch";
import { BatchGetUserCanEnterResp } from "../../mttmatch/v1/mttmatch";
import { BatchGetUserCanEnterReq } from "../../mttmatch/v1/mttmatch";
import { BatchGetCanReEnterResp } from "../../mttmatch/v1/mttmatch";
import { BatchGetCanReEnterReq } from "../../mttmatch/v1/mttmatch";
import { AddOnResp } from "../../mttmatch/v1/mttmatch";
import { AddOnReq } from "../../mttmatch/v1/mttmatch";
import { CancelAddOnResp } from "../../mttmatch/v1/mttmatch";
import { CancelAddOnReq } from "../../mttmatch/v1/mttmatch";
import { CancelReBuyResp } from "../../mttmatch/v1/mttmatch";
import { CancelReBuyReq } from "../../mttmatch/v1/mttmatch";
import { ReEntryResp } from "../../mttmatch/v1/mttmatch";
import { ReEntryReq } from "../../mttmatch/v1/mttmatch";
import { ReBuyResp } from "../../mttmatch/v1/mttmatch";
import { ReBuyReq } from "../../mttmatch/v1/mttmatch";
import { AnimationFinishResp } from "../../mttmatch/v1/mttmatch";
import { AnimationFinishReq } from "../../mttmatch/v1/mttmatch";
import { Transaction } from "../../matchlink/v1/matchlink";
import { Void } from "../../../base/base";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { MatchFeatureConfig } from "../../common/v1/common";
import { BetWay } from "../../common/v1/common";
import { ServiceConf } from "../../common/v1/common";
import { ScheduleConfig } from "../../common/v1/common";
import { RankPrize } from "../../common/v1/common";
import { DynamicPrizeConf } from "../../common/v1/common";
import { DelayPlay } from "../../common/v1/common";
import { PauseConf } from "../../common/v1/common";
import { ReentryConf } from "../../common/v1/common";
import { AddOnOption } from "../../common/v1/common";
import { RebuyConf } from "../../common/v1/common";
import { ChainID } from "../../../../gaw/base/base";
import { TierPrize } from "../../common/v1/common";
import { SectionRange } from "../../common/v1/common";
import { UserStatus as UserStatus$ } from "../../common/v1/common";
import { TableViewChangeReason } from "../../../common/common";
import { TableView } from "../../../common/common";
import { StageUser as StageUser$ } from "../../common/v1/common";
import { GameOverType } from "../../common/v1/common";
import { I18nField } from "../../../common/common";
import { SchedulerType } from "../../common/v1/common";
import { PrizeType } from "../../common/v1/common";
import { StageUser } from "../../../broker/match/match";
import { SwitchState } from "../../../common/common_define";
import { MainMatchInfo } from "../../common/v1/common";
import { MainMatchType } from "../../common/v1/common";
import { MatchStatus } from "../../common/v1/common";
import { MatchListType } from "../../common/v1/common";
import { SubMatchType } from "../../common/v1/common";
import { TransactionMeta } from "../../../common/common_web3";
import { MatchFront } from "../../common/v1/common";
import { Match } from "../../common/v1/common";
import { Gender } from "../../../common/common_define";
import { Desk } from "../../common/v1/common";
import { User } from "../../common/v1/common";
import { ErrorCode } from "../../../common/code/code";
import { I18n } from "../../../common/common_lang";
import { SimpleAsset as SimpleAsset$ } from "../../../common/common_define";
import { Phone } from "../../../../mp/user/account/v1/account";
import { Ticket } from "../../common/v1/common";
import { RegisterWay } from "../../common/v1/common";
import { ReviewState } from "../../common/v1/common";
import { ConfInfoExt } from "../../common/v1/common";
import { State } from "../../common/v1/common";
import { MatchConf } from "../../common/v1/common";
import { JoinStatus } from "../../common/v1/common";
import { Token } from "../../matchlink/v1/matchlink";
import { MatchType } from "../../common/v1/common";
import { AssetItem } from "../../../common/common_define";
import { ParallelPrizeConf } from "../../common/v1/common";
import { BlindStructure } from "../../common/v1/common";
import { BlindLevel } from "../../common/v1/common";
import { RangePrize } from "../../common/v1/common";
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchConfTmpl
 */
export interface MatchConfTmpl {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
    /**
     * 配置模板类型
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmplType type = 2;
     */
    type: MatchConfTmplType;
    /**
     * 静态奖池配置
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.RangePrize rangePrizes = 3;
     */
    rangePrizes: RangePrize[];
    /**
     * 盲注结构配置 Level必须从1开始
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.BlindLevel levels = 4;
     */
    levels: BlindLevel[]; // deprecated
    /**
     * @generated from protobuf field: bpt.match.common.v1.BlindStructure blindStructure = 11;
     */
    blindStructure?: BlindStructure; // 盲注结构，旧的levels抛弃，上线保证数据迁移
    /**
     * 操作人
     *
     * @generated from protobuf field: string operator = 5;
     */
    operator: string;
    /**
     * 创建时间
     *
     * @generated from protobuf field: int64 createAt = 6;
     */
    createAt: number;
    /**
     * 更新时间
     *
     * @generated from protobuf field: int64 updateAt = 7;
     */
    updateAt: number;
    /**
     * 名称
     *
     * @generated from protobuf field: string name = 8;
     */
    name: string;
    /**
     * 所有者
     *
     * @generated from protobuf field: int64 owner = 9;
     */
    owner: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.ParallelPrizeConf parallelConf = 10;
     */
    parallelConf?: ParallelPrizeConf;
}
/**
 * ===================matchlink api 上链相关=======================
 *
 *
 * @generated from protobuf message bpt.match.matchhub.v1.MsgTransactionBack
 */
export interface MsgTransactionBack {
    /**
     * @generated from protobuf field: string tsId = 1;
     */
    tsId: string;
    /**
     * @generated from protobuf field: map<string, string> info = 2;
     */
    info: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgPoolChange
 */
export interface MsgPoolChange {
    /**
     * 总奖池
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 1;
     */
    prizePool: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgMatchMultipler
 */
export interface MsgMatchMultipler {
    /**
     * 倍数
     *
     * @generated from protobuf field: uint32 multipler = 1;
     */
    multipler: number;
    /**
     * 还剩多少秒开赛，预留时间播放动画
     *
     * @generated from protobuf field: int64 leftDuration = 2;
     */
    leftDuration: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchRecord
 */
export interface MatchRecord {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
    /**
     * 比赛配置id
     *
     * @generated from protobuf field: int64 MatchConfId = 2 [json_name = "MatchConfId"];
     */
    MatchConfId: number;
    /**
     * 比赛名称
     *
     * @generated from protobuf field: string Name = 3 [json_name = "Name"];
     */
    Name: string;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRecordState state = 4;
     */
    state: MatchRecordState;
    /**
     * 用户排名和奖励
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserPrize userPrizes = 5;
     */
    userPrizes: UserPrize[];
    /**
     * 上链交易但处理失败的条目
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserTsFail userTsFails = 6;
     */
    userTsFails: UserTsFail[];
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 7;
     */
    matchType: MatchType;
    /**
     * 创建时间
     *
     * @generated from protobuf field: int64 createAt = 12;
     */
    createAt: number;
    /**
     * 更新时间
     *
     * @generated from protobuf field: int64 updateAt = 13;
     */
    updateAt: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRecordTsInfo tsInfo = 14;
     */
    tsInfo?: MatchRecordTsInfo;
    /**
     * @generated from protobuf field: int64 StartAt = 15 [json_name = "StartAt"];
     */
    StartAt: number;
    /**
     * @generated from protobuf field: string tag = 16;
     */
    tag: string;
    /**
     * @generated from protobuf field: int64 srvID = 17;
     */
    srvID: number;
    /**
     * @generated from protobuf field: int64 pauseDeadline = 18;
     */
    pauseDeadline: number; // 暂停截止时间
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchRecordTsInfo
 */
export interface MatchRecordTsInfo {
    /**
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserPrize
 */
export interface UserPrize {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint32 rank = 2;
     */
    rank: number;
    /**
     * 赢得的奖池比例，10000为100%
     *
     * @generated from protobuf field: uint32 prizeRatio = 3;
     */
    prizeRatio: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.PrizeToken prizeToken = 4;
     */
    prizeToken?: PrizeToken;
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.PrizeToken prizeTokens = 5;
     */
    prizeTokens: PrizeToken[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserTsFail
 */
export interface UserTsFail {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.UserTsFailType type = 2;
     */
    type: UserTsFailType;
    /**
     * @generated from protobuf field: string tsId = 3;
     */
    tsId: string; //    //额外信息
    //    // prop_id：道具id, raw_amount:数量字符串
    //    map<string, string> meta = 4;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.PrizeToken
 */
export interface PrizeToken {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: bpt.match.matchlink.v1.Token prizeToken = 2;
     */
    prizeToken?: Token;
    /**
     * @generated from protobuf field: int64 propId = 3;
     */
    propId: number;
}
/**
 * ===================用户比赛申请管理=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.UserManage
 */
export interface UserManage {
    /**
     * 申请id
     *
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
    /**
     * 用户id
     *
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * 用户昵称
     *
     * @generated from protobuf field: string userNickname = 3;
     */
    userNickname: string;
    /**
     * matchId
     *
     * @generated from protobuf field: int64 matchId = 4;
     */
    matchId: number;
    /**
     * 比赛名称
     *
     * @generated from protobuf field: string matchName = 5;
     */
    matchName: string;
    /**
     * matchKey
     *
     * @generated from protobuf field: string matchKey = 6;
     */
    matchKey: string;
    /**
     * 报名状态
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.ParticipateStatus participateStatus = 9;
     */
    participateStatus: ParticipateStatus;
    /**
     * 审核状态
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.AuditStatus auditStatus = 10;
     */
    auditStatus: AuditStatus;
    /**
     * 操作人
     *
     * @generated from protobuf field: string operator = 11;
     */
    operator: string;
    /**
     * 创建时间
     *
     * @generated from protobuf field: int64 createAt = 12;
     */
    createAt: number;
    /**
     * 更新时间
     *
     * @generated from protobuf field: int64 updateAt = 13;
     */
    updateAt: number;
    /**
     * 排名
     *
     * @generated from protobuf field: int64 rank = 14;
     */
    rank: number;
    /**
     * 分数
     *
     * @generated from protobuf field: int64 score = 15;
     */
    score: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.AwardStatus awardStatus = 16;
     */
    awardStatus: AwardStatus;
    /**
     * 参数
     *
     * @generated from protobuf field: bpt.match.common.v1.JoinStatus joinStatus = 17;
     */
    joinStatus: JoinStatus;
}
/**
 * /////////////////////// req && resp//////////////////////////
 * ===================比赛配置管理=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfReq
 */
export interface SaveMatchConfReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 1;
     */
    conf?: MatchConf;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfResp
 */
export interface SaveMatchConfResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 1;
     */
    conf?: MatchConf;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ReuseMatchConfReq
 */
export interface ReuseMatchConfReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 1;
     */
    conf?: MatchConf;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ReuseMatchConfResp
 */
export interface ReuseMatchConfResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 1;
     */
    conf?: MatchConf;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfReq
 */
export interface DeleteMatchConfReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string operator = 2;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfResp
 */
export interface DeleteMatchConfResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateMatchConfStateReq
 */
export interface UpdateMatchConfStateReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.State state = 1;
     */
    state: State;
    /**
     * @generated from protobuf field: int64 id = 2;
     */
    id: number;
    /**
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateMatchConfStateResp
 */
export interface UpdateMatchConfStateResp {
    /**
     * 透传给前端调起钱包
     *
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfReq
 */
export interface GetMatchConfReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfResp
 */
export interface GetMatchConfResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 1;
     */
    conf?: MatchConf;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetMatchConfReq
 */
export interface BatchGetMatchConfReq {
    /**
     * @generated from protobuf field: repeated int64 id = 1;
     */
    id: number[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetMatchConfResp
 */
export interface BatchGetMatchConfResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.MatchConf conf = 1;
     */
    conf: MatchConf[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfByKeyReq
 */
export interface ListMatchConfByKeyReq {
    /**
     * @generated from protobuf field: repeated string matchKeys = 1;
     */
    matchKeys: string[]; // 数组最多传10个
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfByKeyResp
 */
export interface ListMatchConfByKeyResp {
    /**
     * @generated from protobuf field: map<string, bpt.match.common.v1.MatchConf> matchConfs = 1;
     */
    matchConfs: {
        [key: string]: MatchConf;
    };
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetConfByIdsReq
 */
export interface GetConfByIdsReq {
    /**
     * @generated from protobuf field: repeated int64 ids = 1;
     */
    ids: number[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetConfByIdsResp
 */
export interface GetConfByIdsResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.ConfInfoExt info = 1;
     */
    info: ConfInfoExt[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfReq
 */
export interface ListMatchConfReq {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 id = 3;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.State state = 5;
     */
    state: State;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 6;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: bpt.match.common.v1.ReviewState reviewState = 7;
     */
    reviewState: ReviewState;
    /**
     * @generated from protobuf field: string tag = 8;
     */
    tag: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfResp
 */
export interface ListMatchConfResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.MatchConf confs = 1;
     */
    confs: MatchConf[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfTmplReq
 */
export interface SaveMatchConfTmplReq {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1;
     */
    tmpl?: MatchConfTmpl;
    /**
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfTmplResp
 */
export interface SaveMatchConfTmplResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1;
     */
    tmpl?: MatchConfTmpl;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfTmplReq
 */
export interface GetMatchConfTmplReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfTmplResp
 */
export interface GetMatchConfTmplResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1;
     */
    tmpl?: MatchConfTmpl;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfTmplReq
 */
export interface DeleteMatchConfTmplReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfTmplReq
 */
export interface ListMatchConfTmplReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmplType matchType = 3;
     */
    matchType: MatchConfTmplType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfTmplResp
 */
export interface ListMatchConfTmplResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchConfTmpl tmpls = 1;
     */
    tmpls: MatchConfTmpl[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * ===================配置下的比赛列表信息=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchRecordReq
 */
export interface ListMatchRecordReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 confId = 3;
     */
    confId: number;
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchRecordState states = 5;
     */
    states: MatchRecordState[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchRecordResp
 */
export interface ListMatchRecordResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchRecord matchRecords = 1;
     */
    matchRecords: MatchRecord[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetRunningMatchReq
 */
export interface BatchGetRunningMatchReq {
    /**
     * @generated from protobuf field: int64 latestTime = 1;
     */
    latestTime: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetRunningMatchResp
 */
export interface BatchGetRunningMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchRecord matchRecords = 1;
     */
    matchRecords: MatchRecord[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchRecordReq
 */
export interface GetMatchRecordReq {
    /**
     * @generated from protobuf field: string recordId = 1;
     */
    recordId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchRecordResp
 */
export interface GetMatchRecordResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRecord matchRecord = 1;
     */
    matchRecord?: MatchRecord;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateMatchRecordStateReq
 */
export interface UpdateMatchRecordStateReq {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRecordState state = 1;
     */
    state: MatchRecordState;
    /**
     * @generated from protobuf field: int64 id = 2;
     */
    id: number;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateMatchRecordStateResp
 */
export interface UpdateMatchRecordStateResp {
    /**
     * 透传给前端调起钱包
     *
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateMatchRecordConfReq
 */
export interface UpdateMatchRecordConfReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 2;
     */
    conf?: MatchConf;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ForceCancelMatchReq
 */
export interface ForceCancelMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ForceCancelMatchResp
 */
export interface ForceCancelMatchResp {
    /**
     * 透传给前端调起钱包
     *
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.CancelMatchByUserReq
 */
export interface CancelMatchByUserReq {
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * 当前操作的邮件id（邮件领取的场景）
     *
     * @generated from protobuf field: string mailId = 4;
     */
    mailId: string;
    /**
     *  // 取消比赛原因
     *  bpt.common.I18n reason = 5;
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.Withdrawal withdrawal = 6;
     */
    withdrawal: Withdrawal;
    /**
     * @generated from protobuf field: string reasonI18nKey = 7 [json_name = "reasonI18nKey"];
     */
    reasonI18nKey: string; // 比赛取消原因多语言key,需要从多语言获取对应显示内容
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.CancelMatchByUserResp
 */
export interface CancelMatchByUserResp {
    /**
     * 透传给前端调起钱包
     *
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
}
/**
 * ===================用户比赛申请管理=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.UserApplyMatchReq
 */
export interface UserApplyMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 matchId = 3;
     */
    matchId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserApplyMatchResp
 */
export interface UserApplyMatchResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserRegisterMatchReq
 */
export interface UserRegisterMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.RegisterWay registerWay = 6;
     */
    registerWay: RegisterWay;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Ticket tickets = 7;
     */
    tickets: Ticket[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserRegisterMatchResp
 */
export interface UserRegisterMatchResp {
    /**
     * @generated from protobuf field: bool isOnChain = 1;
     */
    isOnChain: boolean; // 比赛是否上链，如返回true，需要使用credential走付款流程
    /**
     * 唤起支付的凭据 (生成二维码给 GAW 或传给托管钱包付款)
     *
     * @generated from protobuf field: bytes credential = 2;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 3;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserUnRegisterMatchReq
 */
export interface UserUnRegisterMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * 钱包地址
     *
     * @generated from protobuf field: string walletAddr = 4;
     */
    walletAddr: string;
    /**
     * @generated from protobuf field: bytes signature = 5;
     */
    signature: Uint8Array;
    /**
     * 当前操作的邮件id（邮件领取的场景）
     *
     * @generated from protobuf field: string mailId = 6;
     */
    mailId: string;
    /**
     * 邮件senceMeta中的tx_id（邮件领取的场景）
     *
     * @generated from protobuf field: string txId = 7;
     */
    txId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserUnRegisterMatchResp
 */
export interface UserUnRegisterMatchResp {
    /**
     * @generated from protobuf field: bool isOnChain = 1;
     */
    isOnChain: boolean; // 比赛是否上链的
    /**
     * 唤起支付的凭据 (生成二维码给 GAW 或传给托管钱包付款)
     *
     * @generated from protobuf field: bytes credential = 2;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 3;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AuditUserManageReq
 */
export interface AuditUserManageReq {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.AuditStatus auditStatus = 1;
     */
    auditStatus: AuditStatus;
    /**
     * @generated from protobuf field: string userManageId = 2;
     */
    userManageId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AuditUserManageResp
 */
export interface AuditUserManageResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListUserManageReq
 */
export interface ListUserManageReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.AuditStatus auditStatus = 3;
     */
    auditStatus: AuditStatus;
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListUserManageResp
 */
export interface ListUserManageResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserManage userManages = 1;
     */
    userManages: UserManage[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetUserManageReq
 */
export interface BatchGetUserManageReq {
    /**
     * @generated from protobuf field: repeated int64 uids = 1;
     */
    uids: number[];
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetUserManageResp
 */
export interface BatchGetUserManageResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserManage userManages = 1;
     */
    userManages: UserManage[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateUserManageStatusReq
 */
export interface UpdateUserManageStatusReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.JoinStatus joinStatus = 2;
     */
    joinStatus: JoinStatus;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AdminRegisterReq
 */
export interface AdminRegisterReq {
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.RegisterWay registerWay = 6;
     */
    registerWay: RegisterWay;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Ticket tickets = 7;
     */
    tickets: Ticket[];
    /**
     * @generated from protobuf field: repeated string uidStr = 8;
     */
    uidStr: string[]; // js不支持大的int64,改传string
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SubmitAwardClaimFormReq
 */
export interface SubmitAwardClaimFormReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string trueName = 3;
     */
    trueName: string; // 真实名字
    /**
     * @generated from protobuf field: string homeAddress = 4;
     */
    homeAddress: string; // 用户联系地址（非钱包地址）
    /**
     * @generated from protobuf field: string videoUrl = 5;
     */
    videoUrl: string; // 视频验证时的ossurl,获奖感言
    /**
     * @generated from protobuf field: map<int64, string> addressMap = 6;
     */
    addressMap: {
        [key: string]: string;
    }; // key 是不上链的代币的propID,value是用户接收的地址
    /**
     *  string phone  =7;// 电话号码
     *
     * @generated from protobuf field: string email = 8;
     */
    email: string; // 邮箱
    /**
     * @generated from protobuf field: mp.user.account.v1.Phone phone = 9;
     */
    phone?: Phone; // 电话号码
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.OffChainPrize
 */
export interface OffChainPrize {
    /**
     * @generated from protobuf field: bpt.common.SimpleAsset prizeItem = 1;
     */
    prizeItem?: SimpleAsset$;
    /**
     * @generated from protobuf field: string address = 2;
     */
    address: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AwardClaimForm
 */
export interface AwardClaimForm {
    /**
     * @generated from protobuf field: string ID = 13 [json_name = "ID"];
     */
    ID: string;
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.common.I18n matchName = 8;
     */
    matchName?: I18n; // 比赛名称
    /**
     * @generated from protobuf field: string trueName = 3;
     */
    trueName: string; // 真实名字
    /**
     * @generated from protobuf field: int64 uid = 11;
     */
    uid: number; // 用户mttid
    /**
     * @generated from protobuf field: string homeAddress = 4;
     */
    homeAddress: string; // 用户联系地址（非钱包地址）
    /**
     * @generated from protobuf field: string videoUrl = 5;
     */
    videoUrl: string; // 视频验证时的ossurl,获奖感言
    /**
     * @generated from protobuf field: map<int64, string> addressMap = 6;
     */
    addressMap: {
        [key: string]: string;
    }; // key 是不上链的代币的propID,value是用户接收的地址
    /**
     * @generated from protobuf field: string email = 14;
     */
    email: string; // 邮箱
    /**
     * @generated from protobuf field: mp.user.account.v1.Phone phone = 15;
     */
    phone?: Phone; // 电话号码
    /**
     * @generated from protobuf field: string remark = 10;
     */
    remark: string; // 审核发奖时提交的备注
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.AuditStatus auditStatus = 12;
     */
    auditStatus: AuditStatus; // 审核状态
    /**
     * @generated from protobuf field: string operator = 16;
     */
    operator: string; // 发奖的操作人员
    /**
     * @generated from protobuf field: int64 submitAt = 17;
     */
    submitAt: number; // 提交时间
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.OffChainPrize offChainPrizes = 18;
     */
    offChainPrizes: OffChainPrize[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListAwardClaimFormReq
 */
export interface ListAwardClaimFormReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string; // 按照matchkey搜索
    /**
     * @generated from protobuf field: int64 uid = 4;
     */
    uid: number; // 按照用户搜索
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.AuditStatus auditStatus = 5;
     */
    auditStatus: AuditStatus; // 按照审核状态查询
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListAwardClaimFormResp
 */
export interface ListAwardClaimFormResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.AwardClaimForm forms = 1;
     */
    forms: AwardClaimForm[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AuditAwardClaimFormReq
 */
export interface AuditAwardClaimFormReq {
    /**
     * @generated from protobuf field: string ID = 1 [json_name = "ID"];
     */
    ID: string;
    /**
     * @generated from protobuf field: string remark = 2;
     */
    remark: string; // 审核发奖时提交的备注
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.AuditStatus auditStatus = 3;
     */
    auditStatus: AuditStatus; // 必填，通过或拒绝
    /**
     * @generated from protobuf field: string operator = 4;
     */
    operator: string; // 操作人员
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.QuickStartReq
 */
export interface QuickStartReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 1;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: string tag = 2;
     */
    tag: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.QuickStartResp
 */
export interface QuickStartResp {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string tableKey = 2;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 3;
     */
    code: ErrorCode;
    /**
     * @generated from protobuf field: uint64 lowerLimitChips = 4;
     */
    lowerLimitChips: number;
    /**
     * @generated from protobuf field: int32 seatNo = 5;
     */
    seatNo: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ChangeDeskReq
 */
export interface ChangeDeskReq {
    /**
     * 旧桌子上的信息,避免换到同一张桌子上
     *
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string tableKey = 2;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 3;
     */
    matchType: MatchType;
    /**
     * 当前桌子的准入门槛
     *
     * @generated from protobuf field: uint64 lowerLimitChips = 4;
     */
    lowerLimitChips: number;
    /**
     * 包括退出当前牌桌后,用户的资产数目
     *
     * @generated from protobuf field: int64 userAssetNum = 5;
     */
    userAssetNum: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ChangeDeskResp
 */
export interface ChangeDeskResp {
    /**
     * 新桌子上的信息
     *
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string tableKey = 2;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: uint32 seatNo = 3;
     */
    seatNo: number;
    /**
     * 新桌子的准入门槛
     *
     * @generated from protobuf field: uint64 lowerLimitChips = 4;
     */
    lowerLimitChips: number;
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode statusCode = 6;
     */
    statusCode: ErrorCode;
}
/**
 * ===================赛前==========================
 * //=================赛前推送=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.MsgCountDown
 */
export interface MsgCountDown {
    /**
     * 比赛开始倒计时（单位：秒）
     *
     * @generated from protobuf field: int64 leftDuration = 1;
     */
    leftDuration: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgCancelMatch
 */
export interface MsgCancelMatch {
    /**
     * @generated from protobuf field: string game = 1;
     */
    game: string; // 比赛名字
    /**
     * @generated from protobuf field: int64 serverTS = 2;
     */
    serverTS: number; // 服务器时间戳，秒级
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgMatchStart
 */
export interface MsgMatchStart {
    /**
     * 比赛开始时间
     *
     * @generated from protobuf field: int64 startedAt = 1;
     */
    startedAt: number;
    /**
     * 比赛开始是否成功
     *
     * @generated from protobuf field: bool isSuccess = 2;
     */
    isSuccess: boolean;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgUserRegister
 */
export interface MsgUserRegister {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.User user = 2;
     */
    user: User[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgUserUnRegister
 */
export interface MsgUserUnRegister {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated int64 uid = 2;
     */
    uid: number[];
    /**
     * 当前最新报名人数
     *
     * @generated from protobuf field: int64 registeredNum = 3;
     */
    registeredNum: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgUserRegisterNum
 */
export interface MsgUserRegisterNum {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 registeredNum = 2;
     */
    registeredNum: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgUserJoinedNum
 */
export interface MsgUserJoinedNum {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 joinedNum = 2;
     */
    joinedNum: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MsgMatchAboutToStart
 */
export interface MsgMatchAboutToStart {
    /**
     * 比赛开始预估剩余时长(单位：秒)
     *
     * @generated from protobuf field: int64 leftDuration = 1;
     */
    leftDuration: number;
}
/**
 * //================面板、赛前接口=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchTablesReq
 */
export interface ListMatchTablesReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchTablesResp
 */
export interface ListMatchTablesResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Desk user = 1;
     */
    user: Desk[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMultiMatchTablesReq
 */
export interface ListMultiMatchTablesReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * 有值时，表示查询某个比赛的桌子
     *
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * 盲注级别
     *
     * @generated from protobuf field: bpt.match.common.v1.BlindLevel blindLevel = 5;
     */
    blindLevel?: BlindLevel;
    /**
     * @generated from protobuf field: string tag = 6;
     */
    tag: string;
    /**
     * 是否仅限有人的桌子
     *
     * @generated from protobuf field: bool notEmptyTable = 7;
     */
    notEmptyTable: boolean;
    /**
     * 是否仅限有空座位的桌子
     *
     * @generated from protobuf field: bool seatingAvaliable = 8;
     */
    seatingAvaliable: boolean;
    /**
     * 盲注级别(多选)
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.BlindLevel blindLevels = 9;
     */
    blindLevels: BlindLevel[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMultiMatchTablesResp
 */
export interface ListMultiMatchTablesResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.Desk user = 1;
     */
    user: Desk[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchUsersReq
 */
export interface ListMatchUsersReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchUsersResp
 */
export interface ListMatchUsersResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.User user = 1;
     */
    user: User[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListRegisterUsersReq
 */
export interface ListRegisterUsersReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListJoinedUsersReq
 */
export interface ListJoinedUsersReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListRegisterUsersResp
 */
export interface ListRegisterUsersResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.RegisterUser user = 1;
     */
    user: RegisterUser[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListJoinedUsersResp
 */
export interface ListJoinedUsersResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.RegisterUser user = 1;
     */
    user: RegisterUser[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.RegisterUser
 */
export interface RegisterUser {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string nickname = 2;
     */
    nickname: string;
    /**
     * @generated from protobuf field: bpt.common.Gender gender = 3;
     */
    gender: Gender;
    /**
     * @generated from protobuf field: string avatar = 4;
     */
    avatar: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetUserReq
 */
export interface GetUserReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetUserResp
 */
export interface GetUserResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.User user = 1;
     */
    user?: User;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchReq
 */
export interface GetMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchResp
 */
export interface GetMatchResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.Match match = 1;
     */
    match?: Match;
    /**
     * 用户比赛相关信息，UID>0 时有效
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.UserMatchInfo userMatchInfo = 2;
     */
    userMatchInfo?: UserMatchInfo;
}
/**
 * 用户的比赛信息
 *
 * @generated from protobuf message bpt.match.matchhub.v1.UserMatchInfo
 */
export interface UserMatchInfo {
    /**
     * 当前用户是否已报名
     *
     * @generated from protobuf field: bool isRegister = 1;
     */
    isRegister: boolean;
    /**
     * 报名方式
     *
     * @generated from protobuf field: bpt.match.common.v1.RegisterWay registerWay = 2;
     */
    registerWay: RegisterWay;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.JoinMatchReq
 */
export interface JoinMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.JoinMatchResp
 */
export interface JoinMatchResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchFront match = 1;
     */
    match?: MatchFront;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMyMatchReq
 */
export interface ListMyMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMyMatchResp
 */
export interface ListMyMatchResp {
    /**
     * @generated from protobuf field: repeated string matchKey = 1;
     */
    matchKey: string[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateReq
 */
export interface BatchGetMatchStateAndUserStateReq {
    /**
     * @generated from protobuf field: repeated string matchKey = 1;
     */
    matchKey: string[]; // 最多传5个matchKey
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateResp
 */
export interface BatchGetMatchStateAndUserStateResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserMatchState
 */
export interface UserMatchState {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRecordState state = 1;
     */
    state: MatchRecordState;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GenPayCredentialReq
 */
export interface GenPayCredentialReq {
    /**
     * @generated from protobuf field: string mailId = 1;
     */
    mailId: string;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.GenPayCredentialReq.OperateType operate = 3;
     */
    operate: GenPayCredentialReq_OperateType;
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.GenPayCredentialReq.OperateType
 */
export enum GenPayCredentialReq_OperateType {
    /**
     * 默认空
     *
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * 获取退款
     *
     * @generated from protobuf enum value: REFUNDED = 1;
     */
    REFUNDED = 1,
    /**
     * 获取奖励
     *
     * @generated from protobuf enum value: PRIZE = 2;
     */
    PRIZE = 2
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GenPayCredentialResp
 */
export interface GenPayCredentialResp {
    /**
     * 唤起支付的凭据 (生成二维码给 GAW 或传给托管钱包付款)
     *
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
    /**
     * @generated from protobuf field: bpt.common.TransactionMeta meta = 3;
     */
    meta?: TransactionMeta;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.FreeAddChipsReq
 */
export interface FreeAddChipsReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.FreeAddChipsReq.Type type = 2;
     */
    type: FreeAddChipsReq_Type;
    /**
     * @generated from protobuf field: int64 count = 3;
     */
    count: number;
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.FreeAddChipsReq.Type
 */
export enum FreeAddChipsReq_Type {
    /**
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: ReEnery = 1;
     */
    ReEnery = 1,
    /**
     * @generated from protobuf enum value: Addon = 2;
     */
    Addon = 2
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.FreeAddChipsResp
 */
export interface FreeAddChipsResp {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
// ===================房间等级分类==========================

/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchTag
 */
export interface MatchTag {
    /**
     * 新建时不传
     *
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: string tag = 3;
     */
    tag: string;
    /**
     * 排序优先级
     *
     * @generated from protobuf field: int64 sort = 4;
     */
    sort: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchTagReq
 */
export interface ListMatchTagReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 1;
     */
    matchType: MatchType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchTagResp
 */
export interface ListMatchTagResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchTag matchTags = 1;
     */
    matchTags: MatchTag[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchTagReq
 */
export interface SaveMatchTagReq {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchTag tagConf = 1;
     */
    tagConf?: MatchTag;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchTagReq
 */
export interface DeleteMatchTagReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchBlindOptionsReq
 */
export interface ListMatchBlindOptionsReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 1;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: string tag = 2;
     */
    tag: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchBlindOptionsResp
 */
export interface ListMatchBlindOptionsResp {
    /**
     * 排好序了的
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.BlindLevel blindOptions = 1;
     */
    blindOptions: BlindLevel[];
}
/**
 * ===================Match List Start=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.CreateMatchReq
 */
export interface CreateMatchReq {
    /**
     * @generated from protobuf field: int64 confID = 1;
     */
    confID: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: int64 matchID = 3;
     */
    matchID: number;
    /**
     * 报名截止时间，mtt超过报名截止时间的不展示
     * 没有延时开赛，比赛开始时间就是报名截止时间，
     * 配置延时开赛，取延时报名截止时间
     *
     * @generated from protobuf field: int64 registerDeadline = 5;
     */
    registerDeadline: number;
    /**
     * @generated from protobuf field: string matchKey = 6;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchConf conf = 7;
     */
    conf?: MatchConf;
    /**
     * @generated from protobuf field: int64 startAt = 8;
     */
    startAt: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 9;
     */
    prizePool: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.RemoveMatchReq
 */
export interface RemoveMatchReq {
    /**
     * @generated from protobuf field: int64 confID = 1;
     */
    confID: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: int64 matchID = 3;
     */
    matchID: number;
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.RemoveMatchByConfIdReq
 */
export interface RemoveMatchByConfIdReq {
    /**
     * @generated from protobuf field: int64 confID = 1;
     */
    confID: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.RemoveMatchByMatchKeyReq
 */
export interface RemoveMatchByMatchKeyReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateListStartReq
 */
export interface UpdateListStartReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: int64 srvId = 3;
     */
    srvId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdateListUsersReq
 */
export interface UpdateListUsersReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: repeated int64 registerUsers = 3;
     */
    registerUsers: number[];
    /**
     * @generated from protobuf field: bool isFull = 4;
     */
    isFull: boolean;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchReq
 */
export interface ListMatchReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: bpt.match.common.v1.SubMatchType subMatchType = 5;
     */
    subMatchType: SubMatchType;
    /**
     * @generated from protobuf field: string tag = 6;
     */
    tag: string;
    /**
     * 是否返回子比赛，某些赛制如多日赛，主比赛不能报名，子比赛可以报名，
     * 需要从子比赛报名，比赛晋级
     *
     * @generated from protobuf field: bool includeSubMatch = 7;
     */
    includeSubMatch: boolean;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 8;
     */
    matchListType: MatchListType; // 周赛日赛月赛等
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchResp
 */
export interface ListMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.CashMatchItem items = 1;
     */
    items: CashMatchItem[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMttMatchReq
 */
export interface ListMttMatchReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * 是否返回子比赛，某些赛制如多日赛，主比赛不能报名，子比赛可以报名，
     * 需要从子比赛报名，比赛晋级
     *
     * @generated from protobuf field: bool includeSubMatch = 3;
     */
    includeSubMatch: boolean;
    /**
     * @generated from protobuf field: bool onlyShowMy = 4;
     */
    onlyShowMy: boolean; // 只展示我创建的，登录后才能设置为true
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 5;
     */
    matchListType: MatchListType; // 周赛日赛月赛
    /**
     * @generated from protobuf field: bool includeTestMatch = 6;
     */
    includeTestMatch: boolean; // 默认不返回，true返回测试赛
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMttMatchResp
 */
export interface ListMttMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MttMatchItem items = 1;
     */
    items: MttMatchItem[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchSidebarItem
 */
export interface MatchSidebarItem {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.common.I18n matchName = 2;
     */
    matchName?: I18n; // 多语言，主要是name的
    /**
     * @generated from protobuf field: int64 startedAt = 3;
     */
    startedAt: number; // 开赛时间
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.UserStatus userStatus = 4;
     */
    userStatus?: UserStatus;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus matchStatus = 5;
     */
    matchStatus: MatchStatus;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListSidebarMatchReq
 */
export interface ListSidebarMatchReq {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListSidebarMatchResp
 */
export interface ListSidebarMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchSidebarItem items = 1;
     */
    items: MatchSidebarItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchImg
 */
export interface MatchImg {
    /**
     * @generated from protobuf field: string normal = 1;
     */
    normal: string;
    /**
     * @generated from protobuf field: string summary = 2;
     */
    summary: string; // 移动端缩略图icon(小图标)，仅在DeviceTypeMobile返回
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchItem
 */
export interface MatchItem {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * 基础信息
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchImg matchImg = 2;
     */
    matchImg?: MatchImg;
    /**
     * @generated from protobuf field: bpt.common.I18n matchName = 3;
     */
    matchName?: I18n; // 多语言，主要是name的
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 4;
     */
    matchListType: MatchListType;
    /**
     * @generated from protobuf field: int64 registerDeadline = 5;
     */
    registerDeadline: number; // 报名截止时间
    /**
     * @generated from protobuf field: int64 startedAt = 6;
     */
    startedAt: number; // 开赛时间
    /**
     * 道具相关
     * 奖池
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SimpleAsset prizePoolAssetList = 7;
     */
    prizePoolAssetList: SimpleAsset[];
    /**
     * 报名
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SimpleAsset registerAssetList = 8;
     */
    registerAssetList: SimpleAsset[];
    /**
     * 用户状态
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.UserStatus userStatus = 9;
     */
    userStatus?: UserStatus;
    /**
     * 比赛状态
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus matchStatus = 10;
     */
    matchStatus: MatchStatus;
    /**
     * 允许报名
     *
     * @generated from protobuf field: bool isAllowRegister = 11;
     */
    isAllowRegister: boolean;
    /**
     * 报名用户数量
     *
     * @generated from protobuf field: int64 registerUserCnt = 12;
     */
    registerUserCnt: number;
    /**
     *  // 当前阶段
     *  int32 currentPhase = 13;
     * 最早接受报名时间
     *
     * @generated from protobuf field: int64 registrationAt = 14;
     */
    registrationAt: number;
    /**
     * @generated from protobuf field: string phaseName = 15;
     */
    phaseName: string; // 阶段赛名字如Day 1 A/Day 1/Day 2/FINAL DAY //deprecated
    /**
     * @generated from protobuf field: int32 phase = 16;
     */
    phase: number; // deprecated
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchType mainMatchType = 17;
     */
    mainMatchType: MainMatchType; // 赛制类型
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.PhasedTournamentInfo phasedTournamentInfo = 18;
     */
    phasedTournamentInfo?: PhasedTournamentInfo; // 多日赛赛制有的信息
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.PhasedTournamentInfo
 */
export interface PhasedTournamentInfo {
    /**
     * @generated from protobuf field: string phaseName = 1;
     */
    phaseName: string; // 阶段赛名字如Day 1 A/Day 1/Day 2/FINAL DAY //deprecated
    /**
     * @generated from protobuf field: int32 phase = 2;
     */
    phase: number; // deprecated
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchItemReq
 */
export interface ListMatchItemReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 3;
     */
    matchListType: MatchListType; // deprecated 不使用,使用matchListTypes
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.ListMatchItemReq.DeviceType deviceType = 4;
     */
    deviceType: ListMatchItemReq_DeviceType;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.ListMatchItemReq.ViewType viewType = 5;
     */
    viewType: ListMatchItemReq_ViewType;
    /**
     * @generated from protobuf field: string lang = 6;
     */
    lang: string;
    /**
     * 周赛日赛月赛
     * 传空不限制，返回全部
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.MatchListType matchListTypes = 7;
     */
    matchListTypes: MatchListType[];
    /**
     * @generated from protobuf field: bool includeTestMatch = 8;
     */
    includeTestMatch: boolean; // true 会包含测试赛，默认不包括
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.ListMatchItemReq.MatchProgress matchProgress = 9;
     */
    matchProgress: ListMatchItemReq_MatchProgress; // 比赛进度
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.ListMatchItemReq.DeviceType
 */
export enum ListMatchItemReq_DeviceType {
    /**
     * @generated from protobuf enum value: DeviceTypePC = 0;
     */
    DeviceTypePC = 0,
    /**
     * @generated from protobuf enum value: DeviceTypeMobile = 1;
     */
    DeviceTypeMobile = 1
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.ListMatchItemReq.ViewType
 */
export enum ListMatchItemReq_ViewType {
    /**
     * @generated from protobuf enum value: ViewTypeAll = 0;
     */
    ViewTypeAll = 0,
    /**
     * @generated from protobuf enum value: ViewTypeMy = 1;
     */
    ViewTypeMy = 1
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.ListMatchItemReq.MatchProgress
 */
export enum ListMatchItemReq_MatchProgress {
    /**
     * 默认，要带上之前老板定的完赛后显示规则（日赛保留1小时，周赛保留1天）
     *
     * @generated from protobuf enum value: MatchProgressNormal = 0;
     */
    MatchProgressNormal = 0,
    /**
     * 还可以报名的
     *
     * @generated from protobuf enum value: MatchProgressLatest = 1;
     */
    MatchProgressLatest = 1,
    /**
     * 正在进行
     *
     * @generated from protobuf enum value: MatchProgressOngoing = 2;
     */
    MatchProgressOngoing = 2,
    /**
     * 即将开赛的比赛,挖矿赛返回15分钟内开赛的，日赛发挥1小时内开赛的
     *
     * @generated from protobuf enum value: MatchProgressUpcoming = 3;
     */
    MatchProgressUpcoming = 3,
    /**
     * 已完赛
     *
     * @generated from protobuf enum value: MatchProgressFinished = 4;
     */
    MatchProgressFinished = 4,
    /**
     * 未开赛的比赛，MatchProgressUpcoming的比赛是这个状态比赛的子集
     *
     * @generated from protobuf enum value: MatchProgressNotStart = 5;
     */
    MatchProgressNotStart = 5
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchItemResp
 */
export interface ListMatchItemResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchItem items = 1;
     */
    items: MatchItem[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListClubMatchItemReq
 */
export interface ListClubMatchItemReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * 俱乐部ID
     *
     * @generated from protobuf field: uint32 clubId = 3;
     */
    clubId: number;
    /**
     * 默认DeviceTypeMobile
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.ListMatchItemReq.DeviceType deviceType = 4;
     */
    deviceType: ListMatchItemReq_DeviceType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListClubMatchItemResp
 */
export interface ListClubMatchItemResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchItem items = 1;
     */
    items: MatchItem[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.HistoryMatch
 */
export interface HistoryMatch {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * 开始时间
     *
     * @generated from protobuf field: int64 startedAt = 6;
     */
    startedAt: number;
    /**
     * 比赛状态
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus matchStatus = 7;
     */
    matchStatus: MatchStatus;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 13 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言，主要是name的
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SimpleAsset buyIns = 14;
     */
    buyIns: SimpleAsset[];
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SimpleAsset tickets = 15;
     */
    tickets: SimpleAsset[]; // entry的门票配置，不是reentry!!
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 16;
     */
    matchListType: MatchListType;
    /**
     * @generated from protobuf field: string showImg = 2;
     */
    showImg: string; // 展示图片
    /**
     * @generated from protobuf field: string showMobileImg = 3;
     */
    showMobileImg: string; // 手机版本的展示图片
    /**
     * 移动端规格列表图片
     *
     * @generated from protobuf field: string mobileShowImgList = 17;
     */
    mobileShowImgList: string;
    /**
     * 移动端规格详情图片
     *
     * @generated from protobuf field: string mobileShowImgDetail = 18;
     */
    mobileShowImgDetail: string;
    /**
     * pc端规格列表图片
     *
     * @generated from protobuf field: string showImgList = 19;
     */
    showImgList: string; // 列表
    /**
     * pc端规格详情图片
     *
     * @generated from protobuf field: string showImgDetail = 20;
     */
    showImgDetail: string; // 详情
    /**
     * 比赛参赛人数
     *
     * @generated from protobuf field: int64 matchPlayerNum = 21;
     */
    matchPlayerNum: number;
    /**
     * 总奖池
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 22;
     */
    prizePool: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListHistoryMatchReq
 */
export interface ListHistoryMatchReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * 查询用户的比赛记录，如果不传入，查询的是官方所有举办的比赛，按开赛时间倒序排序
     * 如果传入queryUid,则查询的是该用户参加的比赛
     *
     * @generated from protobuf field: int64 queryUid = 3;
     */
    queryUid: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListHistoryMatchResp
 */
export interface ListHistoryMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.HistoryMatch historyMatches = 1;
     */
    historyMatches: HistoryMatch[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListHistoryClubMatchReq
 */
export interface ListHistoryClubMatchReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * 查询用户的比赛记录，如果不传入，查询的是官方所有举办的比赛，按开赛时间倒序排序
     * 如果传入queryUid,则查询的是该用户参加的比赛
     *
     * @generated from protobuf field: int64 queryUid = 3;
     */
    queryUid: number;
    /**
     * 属于的clubId,为0不属于任何club
     *
     * @generated from protobuf field: uint32 clubId = 4;
     */
    clubId: number;
    /**
     * @generated from protobuf field: int64 matchStartAtBegin = 5;
     */
    matchStartAtBegin: number; // 开始时间
    /**
     * @generated from protobuf field: int64 matchStartAtEnd = 6;
     */
    matchStartAtEnd: number; // 开始时间
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListHistoryClubMatchResp
 */
export interface ListHistoryClubMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.HistoryMatch historyMatches = 1;
     */
    historyMatches: HistoryMatch[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListLatestMatchReq
 */
export interface ListLatestMatchReq {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListLatestMatchResp
 */
export interface ListLatestMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.HistoryMatch latestMatches = 1;
     */
    latestMatches: HistoryMatch[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetMttMatchDynamicDataReq
 */
export interface BatchGetMttMatchDynamicDataReq {
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 11;
     */
    prizePool: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetListMyMatchReq
 */
export interface GetListMyMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetListMyMatchResp
 */
export interface GetListMyMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchItem items = 1;
     */
    items: MatchItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetListMyMttMatchResp
 */
export interface GetListMyMttMatchResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MttMatchItem items = 1;
     */
    items: MttMatchItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetHomeMyMatchListReq
 */
export interface GetHomeMyMatchListReq {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.HomeMyMatchItem
 */
export interface HomeMyMatchItem {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 1;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: int64 startedAt = 2;
     */
    startedAt: number; // 开始时间
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus matchStatus = 3;
     */
    matchStatus: MatchStatus; // 比赛状态
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string matchName = 5;
     */
    matchName: string; // 比赛名
    /**
     * @generated from protobuf field: int64 registerDeadline = 6;
     */
    registerDeadline: number; // 延迟报名时间
    /**
     * @generated from protobuf field: bool isShowMyMatch = 7;
     */
    isShowMyMatch: boolean;
    /**
     * @generated from protobuf field: bool isOut = 8;
     */
    isOut: boolean; // 是否淘汰
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 9 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchInfo mainMatchInfo = 10;
     */
    mainMatchInfo?: MainMatchInfo; // 主比赛信息
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetHomeMyMatchListResp
 */
export interface GetHomeMyMatchListResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.HomeMyMatchItem items = 1;
     */
    items: HomeMyMatchItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetUserObservableMatchReq
 */
export interface GetUserObservableMatchReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetUserObservableMatchResp
 */
export interface GetUserObservableMatchResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 1;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string; // 返回空表示没有可围观的比赛
    /**
     * @generated from protobuf field: string tableKey = 3;
     */
    tableKey: string; // 现金桌才会赋值，目前不会实现
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMyMatchRecordReq
 */
export interface GetMyMatchRecordReq {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: int64 matchStartAtBegin = 3;
     */
    matchStartAtBegin: number; // 开始时间
    /**
     * @generated from protobuf field: int64 matchStartAtEnd = 4;
     */
    matchStartAtEnd: number; // 开始时间
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState state = 5;
     */
    state: UserMatchRecord_UserMatchRecordState; // 用户记录状态
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserMatchRecord
 */
export interface UserMatchRecord {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 matchId = 2;
     */
    matchId: number;
    /**
     * @generated from protobuf field: string matchName = 3;
     */
    matchName: string; // 比赛名
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState state = 4;
     */
    state: UserMatchRecord_UserMatchRecordState; // 用户记录状态
    /**
     * @generated from protobuf field: int64 matchStartAt = 5;
     */
    matchStartAt: number; // 比赛开始时间
    /**
     * @generated from protobuf field: uint32 rank = 6;
     */
    rank: number; // 排名
    /**
     * @generated from protobuf field: bpt.common.AssetItem matchBuyIn = 7;
     */
    matchBuyIn?: AssetItem; // 比赛報名費
    /**
     * Deprecated: use matchPrizeList;
     *
     * @generated from protobuf field: bpt.common.AssetItem matchPrize = 8;
     */
    matchPrize?: AssetItem; // 比赛獎勵
    /**
     * @generated from protobuf field: int64 matchDuration = 9;
     */
    matchDuration: number; // 比赛持续时间
    /**
     * @generated from protobuf field: int64 matchPlayerNum = 10;
     */
    matchPlayerNum: number; // 比赛参赛人数
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 11 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem matchPrizeList = 12;
     */
    matchPrizeList: AssetItem[]; // 比赛獎勵列表
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchInfo mainMatchInfo = 13;
     */
    mainMatchInfo?: MainMatchInfo; // 主比赛信息
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.ClaimFormStatus formStatus = 14;
     */
    formStatus: ClaimFormStatus;
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState
 */
export enum UserMatchRecord_UserMatchRecordState {
    /**
     * 默认空
     *
     * @generated from protobuf enum value: NONE = 0;
     */
    NONE = 0,
    /**
     * 已完赛
     *
     * @generated from protobuf enum value: MATCH_COMPLETED = 1;
     */
    MATCH_COMPLETED = 1,
    /**
     * 待领奖
     *
     * @generated from protobuf enum value: PRIZE_PENDING = 2;
     */
    PRIZE_PENDING = 2,
    /**
     * 部分领奖
     *
     * @generated from protobuf enum value: PRIZE_PARTIALLY_CLAIMED = 8;
     */
    PRIZE_PARTIALLY_CLAIMED = 8,
    /**
     * 已领奖
     *
     * @generated from protobuf enum value: PRIZE_CLAIMED = 3;
     */
    PRIZE_CLAIMED = 3,
    /**
     * 去退款
     *
     * @generated from protobuf enum value: REFUND_PENDING = 4;
     */
    REFUND_PENDING = 4,
    /**
     * 已退款
     *
     * @generated from protobuf enum value: REFUNDED = 5;
     */
    REFUNDED = 5,
    /**
     * 已取消
     *
     * @generated from protobuf enum value: CANCEL = 6;
     */
    CANCEL = 6,
    /**
     * 未参赛
     *
     * @generated from protobuf enum value: NOT_JOINED = 7;
     */
    NOT_JOINED = 7
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMyMatchRecordResp
 */
export interface GetMyMatchRecordResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserMatchRecord userMatchRecords = 1;
     */
    userMatchRecords: UserMatchRecord[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchReportDetailReq
 */
export interface GetMatchReportDetailReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchReportDetailResp
 */
export interface GetMatchReportDetailResp {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string matchName = 2;
     */
    matchName: string;
    /**
     * @generated from protobuf field: int64 startTime = 3;
     */
    startTime: number;
    /**
     * @generated from protobuf field: int64 matchDuration = 24;
     */
    matchDuration: number; // 比赛持续时间
    /**
     * @generated from protobuf field: string bannerPath = 4;
     */
    bannerPath: string; // 图片地址
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 5;
     */
    prizePool: AssetItem[]; // 总奖池
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem dynamicPrizePool = 6;
     */
    dynamicPrizePool: AssetItem[]; // addon,rebuy,reentry奖池
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem basicPrizePool = 20;
     */
    basicPrizePool: AssetItem[]; // 基础奖池
    /**
     * @generated from protobuf field: int32 totalPlayer = 7;
     */
    totalPlayer: number; // 参与总人数
    /**
     * @generated from protobuf field: int32 reentry = 8;
     */
    reentry: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem reentryPrize = 21;
     */
    reentryPrize: AssetItem[];
    /**
     * @generated from protobuf field: bpt.common.SwitchState reentrySwitch = 29;
     */
    reentrySwitch: SwitchState;
    /**
     * @generated from protobuf field: int32 addOn = 9;
     */
    addOn: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem addOnPrize = 22;
     */
    addOnPrize: AssetItem[];
    /**
     * @generated from protobuf field: bpt.common.SwitchState addOnSwitch = 30;
     */
    addOnSwitch: SwitchState;
    /**
     * @generated from protobuf field: int32 reBuy = 10;
     */
    reBuy: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem reBuyPrize = 23;
     */
    reBuyPrize: AssetItem[];
    /**
     * @generated from protobuf field: bpt.common.SwitchState reBuySwitch = 31;
     */
    reBuySwitch: SwitchState;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.userPerformance userPerformance = 11;
     */
    userPerformance?: userPerformance; // 用户表现
    /**
     * @generated from protobuf field: int64 bubbleUser = 13;
     */
    bubbleUser: number;
    /**
     * @generated from protobuf field: repeated bpt.broker.match.StageUser finalTableUser = 14;
     */
    finalTableUser: StageUser[];
    /**
     * @generated from protobuf field: int64 thirdPlaceUser = 15;
     */
    thirdPlaceUser: number;
    /**
     * @generated from protobuf field: repeated bpt.broker.match.StageUser headsUpUser = 16;
     */
    headsUpUser: StageUser[];
    /**
     * @generated from protobuf field: int64 championUser = 17;
     */
    championUser: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem championPrize = 25;
     */
    championPrize: AssetItem[];
    /**
     * @generated from protobuf field: repeated uint32 championCards = 26;
     */
    championCards: number[]; // 冠军手牌
    /**
     * @generated from protobuf field: repeated uint32 publicCards = 27;
     */
    publicCards: number[]; // 公共牌
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 18 [json_name = "i18n"];
     */
    i18n?: I18n;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 19;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem buyIn = 28;
     */
    buyIn: AssetItem[];
    /**
     * @generated from protobuf field: bpt.match.common.v1.PrizeType prizeType = 32;
     */
    prizeType: PrizeType; // 赛事奖励类型
    /**
     * @generated from protobuf field: bpt.match.common.v1.SchedulerType schedulerType = 33;
     */
    schedulerType: SchedulerType; // 周期类型
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchSchedule schedules = 34;
     */
    schedules: MatchSchedule[]; // 赛程
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchInfo mainMatchInfo = 35;
     */
    mainMatchInfo?: MainMatchInfo; // 主比赛信息
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 36;
     */
    matchListType: MatchListType;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.SimpleAsset ticket = 37;
     */
    ticket?: SimpleAsset; // 如果比赛支持门票报名，值不为空有值就展示
    /**
     * @generated from protobuf field: int32 advanceNum = 39;
     */
    advanceNum: number; // 本场比赛晋级人数
    /**
     * @generated from protobuf field: bpt.common.I18nField ruleDesc = 40;
     */
    ruleDesc?: I18nField; // 对应阶段规则说明
    /**
     * PrizeAdvance prizeAdvance = 41;
     *
     * @generated from protobuf field: bool isEarlyEnd = 42;
     */
    isEarlyEnd: boolean; // 是否在本场子比赛结束整体比赛
    /**
     * 目标席位赛目标比赛Key
     *
     * @generated from protobuf field: string seatMatchTargetMatchKey = 43;
     */
    seatMatchTargetMatchKey: string;
    /**
     * @generated from protobuf field: bpt.common.AssetItem prizePoolThreshold = 44;
     */
    prizePoolThreshold?: AssetItem; // 奖池破保线 
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.userPerformance
 */
export interface userPerformance {
    /**
     * @generated from protobuf field: repeated uint32 bestHand = 1;
     */
    bestHand: number[];
    /**
     * @generated from protobuf field: int32 rank = 2;
     */
    rank: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem win = 3;
     */
    win: AssetItem[];
    /**
     * @generated from protobuf field: uint32 reentry = 4;
     */
    reentry: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem reentryCost = 7;
     */
    reentryCost: AssetItem[];
    /**
     * @generated from protobuf field: uint32 addOn = 5;
     */
    addOn: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem addOnCost = 8;
     */
    addOnCost: AssetItem[];
    /**
     * @generated from protobuf field: uint32 reBuy = 6;
     */
    reBuy: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem reBuyCost = 9;
     */
    reBuyCost: AssetItem[];
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.PrizeShowType prizeShowType = 10;
     */
    prizeShowType: PrizeShowType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchPrizeUsersReq
 */
export interface GetMatchPrizeUsersReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetIndexRankingReq
 */
export interface GetIndexRankingReq {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 1;
     */
    matchListType: MatchListType;
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchPrizeUsersResp
 */
export interface GetMatchPrizeUsersResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.PrizeUser prizeUsers = 1;
     */
    prizeUsers: PrizeUser[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.SchedulerType schedulerType = 3;
     */
    schedulerType: SchedulerType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetIndexRankingResp
 */
export interface GetIndexRankingResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.PrizeUser prizeUsers = 1;
     */
    prizeUsers: PrizeUser[];
    /**
     * @generated from protobuf field: string matchKey = 4;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 5 [json_name = "i18n"];
     */
    i18n?: I18n;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchSchedulesReq
 */
export interface GetMatchSchedulesReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchSchedulesResp
 */
export interface GetMatchSchedulesResp {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchSchedule schedules = 2;
     */
    schedules: MatchSchedule[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.PrizeUser
 */
export interface PrizeUser {
    /**
     * @generated from protobuf field: uint32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 Uid = 2 [json_name = "Uid"];
     */
    Uid: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizes = 3;
     */
    prizes: AssetItem[];
    /**
     * @generated from protobuf field: int64 Chips = 4 [json_name = "Chips"];
     */
    Chips: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.PrizeShowType prizeShowType = 5;
     */
    prizeShowType: PrizeShowType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchSchedule
 */
export interface MatchSchedule {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus state = 2;
     */
    state: MatchStatus;
    /**
     * @generated from protobuf field: int64 StartAt = 3 [json_name = "StartAt"];
     */
    StartAt: number;
    /**
     * @generated from protobuf field: uint32 BlindLevel = 4 [json_name = "BlindLevel"];
     */
    BlindLevel: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.GameOverType gameOverType = 5;
     */
    gameOverType: GameOverType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchMoneyCircleUsersReq
 */
export interface GetMatchMoneyCircleUsersReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchMoneyCircleUsersResp
 */
export interface GetMatchMoneyCircleUsersResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.StageUser stageUsers = 1;
     */
    stageUsers: StageUser$[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMttMatchFinalRankingReq
 */
export interface GetMttMatchFinalRankingReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMttMatchFinalRankingResp
 */
export interface GetMttMatchFinalRankingResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.RankUser rankUsers = 1;
     */
    rankUsers: RankUser[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.RankUser
 */
export interface RankUser {
    /**
     * @generated from protobuf field: uint32 rank = 1;
     */
    rank: number;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizes = 3;
     */
    prizes: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ChipInfo
 */
export interface ChipInfo {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 avgChip = 2;
     */
    avgChip: number;
    /**
     * @generated from protobuf field: int64 smallestChip = 3;
     */
    smallestChip: number;
    /**
     * @generated from protobuf field: int64 largestChip = 4;
     */
    largestChip: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetChipInfoReq
 */
export interface GetChipInfoReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetChipInfoResp
 */
export interface GetChipInfoResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.ChipInfo chipInfo = 2;
     */
    chipInfo?: ChipInfo;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMyMatchNumReq
 */
export interface GetMyMatchNumReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMyMatchNumResp
 */
export interface GetMyMatchNumResp {
    /**
     * @generated from protobuf field: int64 totalSize = 1;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdatePoolReq
 */
export interface UpdatePoolReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 2;
     */
    prizePool: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UpdatePoolResp
 */
export interface UpdatePoolResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.CashMatchItem
 */
export interface CashMatchItem {
    /**
     * 服务ID
     *
     * @generated from protobuf field: int64 srvID = 1;
     */
    srvID: number;
    /**
     * @generated from protobuf field: int64 matchID = 2;
     */
    matchID: number;
    /**
     * @generated from protobuf field: int64 confID = 3;
     */
    confID: number;
    /**
     * @generated from protobuf field: repeated int64 entryUsers = 4;
     */
    entryUsers: number[];
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.UserInfo userInfo = 5;
     */
    userInfo?: UserInfo;
    /**
     * @generated from protobuf field: int64 confLastUpdate = 6;
     */
    confLastUpdate: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 7;
     */
    matchType: MatchType;
    /**
     * 开始时间
     *
     * @generated from protobuf field: int64 startedAt = 8;
     */
    startedAt: number;
    /**
     * 比赛状态
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus matchStatus = 9;
     */
    matchStatus: MatchStatus;
    /**
     * @generated from protobuf field: bool isDelayRegister = 10;
     */
    isDelayRegister: boolean;
    /**
     * 总奖池(动态，包含重入和重购后增加的奖池)
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 11;
     */
    prizePool: AssetItem[];
    /**
     * @generated from protobuf field: string matchKey = 12;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 finalTableID = 13;
     */
    finalTableID: number;
    /**
     * @generated from protobuf field: bool isBubble = 14;
     */
    isBubble: boolean;
    /**
     * 所属TAG(beginner/advanced/expert)
     *
     * @generated from protobuf field: string tag = 15;
     */
    tag: string;
    /**
     * 其他扩展配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ConfInfoExt ext = 16;
     */
    ext?: ConfInfoExt;
    /**
     * @generated from protobuf field: int64 userCnt = 17;
     */
    userCnt: number; // 报名用户
    /**
     * @generated from protobuf field: int64 registerDeadline = 18;
     */
    registerDeadline: number; // 延迟报名时间
    /**
     * 过了延时报名时间，钱圈，泡沫圈，finaltable, 人满，开桌仅一桌时，isAbleEnter为false,否则为true
     *
     * @generated from protobuf field: bool isAbleEnter = 19;
     */
    isAbleEnter: boolean;
    /**
     * 获取我的比赛的时候有值，用户未淘汰可以回到我的比赛，返回true
     * 用户淘汰后，再根据用户isAbleEnter判断，如果能进入，返回true, 不能进入返回false
     *
     * @generated from protobuf field: bool isShowMyMatch = 20;
     */
    isShowMyMatch: boolean;
    /**
     * @generated from protobuf field: int32 showPriority = 21;
     */
    showPriority: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 22;
     */
    matchListType: MatchListType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserInfo
 */
export interface UserInfo {
    /**
     * @generated from protobuf field: bool isRegister = 1;
     */
    isRegister: boolean;
    /**
     * @generated from protobuf field: bool isOut = 2;
     */
    isOut: boolean;
    /**
     * @generated from protobuf field: bool isAbleReEntry = 3;
     */
    isAbleReEntry: boolean;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MttMatchItem
 */
export interface MttMatchItem {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string showImg = 2;
     */
    showImg: string; // 展示图片
    /**
     * @generated from protobuf field: string showMobileImg = 3;
     */
    showMobileImg: string; // 手机版本的展示图片
    /**
     * 移动端规格列表图片
     *
     * @generated from protobuf field: string mobileShowImgList = 18;
     */
    mobileShowImgList: string;
    /**
     * 移动端规格详情图片
     *
     * @generated from protobuf field: string mobileShowImgDetail = 19;
     */
    mobileShowImgDetail: string;
    /**
     * pc端规格列表图片
     *
     * @generated from protobuf field: string showImgList = 20;
     */
    showImgList: string; // 列表
    /**
     * pc端规格详情图片
     *
     * @generated from protobuf field: string showImgDetail = 21;
     */
    showImgDetail: string; // 详情
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SimpleAsset prizePool = 4;
     */
    prizePool: SimpleAsset[];
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchUserStatus matchUserStatus = 5;
     */
    matchUserStatus?: MatchUserStatus;
    /**
     * 开始时间
     *
     * @generated from protobuf field: int64 startedAt = 6;
     */
    startedAt: number;
    /**
     * 比赛状态
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus matchStatus = 7;
     */
    matchStatus: MatchStatus;
    /**
     * @generated from protobuf field: bool isDelayRegister = 8;
     */
    isDelayRegister: boolean;
    /**
     * @generated from protobuf field: int64 userCnt = 9;
     */
    userCnt: number; // 报名用户
    /**
     * @generated from protobuf field: int64 registerDeadline = 10;
     */
    registerDeadline: number; // 延迟报名时间
    /**
     * 过了延时报名时间，钱圈，泡沫圈，finaltable, 人满，开桌仅一桌时，isAbleEnter为false,否则为true
     *
     * @generated from protobuf field: bool isAbleEnter = 11;
     */
    isAbleEnter: boolean;
    /**
     * 获取我的比赛的时候有值，用户未淘汰可以回到我的比赛，返回true
     * 用户淘汰后，再根据用户isAbleEnter判断，如果能进入，返回true, 不能进入返回false
     *
     * @generated from protobuf field: bool isShowMyMatch = 12;
     */
    isShowMyMatch: boolean;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 13 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言，主要是name的
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SimpleAsset buyIns = 14;
     */
    buyIns: SimpleAsset[];
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SimpleAsset tickets = 15;
     */
    tickets: SimpleAsset[]; // entry的门票配置，不是reentry!!
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchListType = 16;
     */
    matchListType: MatchListType;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MainMatchInfo mainMatchInfo = 17;
     */
    mainMatchInfo?: MainMatchInfo; // 主比赛信息
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MttMatchItem subMatchItem = 22;
     */
    subMatchItem?: MttMatchItem;
}
/**
 * deprecated: 不够通用，请使用bpt/common/common_define.proto下的SimpleAsset
 *
 * @generated from protobuf message bpt.match.matchhub.v1.SimpleAsset
 */
export interface SimpleAsset {
    /**
     * @generated from protobuf field: int64 propId = 1;
     */
    propId: number;
    /**
     * @generated from protobuf field: string rawAmount = 2;
     */
    rawAmount: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchUserStatus
 */
export interface MatchUserStatus {
    /**
     * @generated from protobuf field: bool isRegister = 1;
     */
    isRegister: boolean;
    /**
     * @generated from protobuf field: bool isAllowReEntry = 3;
     */
    isAllowReEntry: boolean;
    /**
     * @generated from protobuf field: bool isOut = 4;
     */
    isOut: boolean;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserStatus
 */
export interface UserStatus {
    /**
     * @generated from protobuf field: bool isRegister = 1;
     */
    isRegister: boolean;
    /**
     * @generated from protobuf field: bool isOut = 2;
     */
    isOut: boolean;
    /**
     * @generated from protobuf field: bool isJoin = 3;
     */
    isJoin: boolean;
}
// ===================Match List End=======================

/**
 * matchType必传
 * 锦标赛matchKey必传、练习场tableKey必传
 *
 * @generated from protobuf message bpt.match.matchhub.v1.ListTableViewsResp
 */
export interface ListTableViewsResp {
    /**
     * @generated from protobuf field: repeated bpt.common.TableView tableViews = 1;
     */
    tableViews: TableView[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AddTableViewReq
 */
export interface AddTableViewReq {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 uid = 3;
     */
    uid: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * 是否强制替换
     *
     * @generated from protobuf field: bool forceReplace = 5;
     */
    forceReplace: boolean;
    /**
     * 替换位置(从0数)
     *
     * @generated from protobuf field: int32 replaceIndex = 6;
     */
    replaceIndex: number;
    /**
     * @generated from protobuf field: bpt.common.TableViewChangeReason reason = 7;
     */
    reason: TableViewChangeReason;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchAddTableViewReq
 */
export interface BatchAddTableViewReq {
    /**
     * @generated from protobuf field: string MatchKey = 1 [json_name = "MatchKey"];
     */
    MatchKey: string;
    /**
     * @generated from protobuf field: string TableKey = 2 [json_name = "TableKey"];
     */
    TableKey: string;
    /**
     * @generated from protobuf field: repeated int64 uids = 3;
     */
    uids: number[];
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: bpt.common.TableViewChangeReason reason = 7;
     */
    reason: TableViewChangeReason;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.RemoveTableViewReq
 */
export interface RemoveTableViewReq {
    /**
     * @generated from protobuf field: string tableKey = 1;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 3;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 4;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: bpt.common.TableViewChangeReason reason = 7;
     */
    reason: TableViewChangeReason;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchBriefReq
 */
export interface GetMatchBriefReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string; // 可以传入列表获取的matchKey,也可传入子比赛的matchkey
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchBriefResp
 */
export interface GetMatchBriefResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchFront match = 1;
     */
    match?: MatchFront;
    /**
     * 用户比赛相关信息，UID>0 时有效
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.UserMatchInfo userMatchInfo = 2;
     */
    userMatchInfo?: UserMatchInfo;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListSubMatchesReq
 */
export interface ListSubMatchesReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string; // 可以传入列表获取的matchKey,也可传入子比赛的matchkey
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SubMatch
 */
export interface SubMatch {
    /**
     * @generated from protobuf field: string matchName = 1;
     */
    matchName: string; // 比赛名称
    /**
     * @generated from protobuf field: int64 startedAt = 2;
     */
    startedAt: number; // 比赛开始时间
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchStatus state = 3;
     */
    state: MatchStatus; // 比赛状态
    /**
     * @generated from protobuf field: bpt.match.common.v1.UserStatus userStatus = 4;
     */
    userStatus: UserStatus$; // 用户状态
    /**
     * @generated from protobuf field: bool isRegister = 5;
     */
    isRegister: boolean; // 用户是否报名
    /**
     * @generated from protobuf field: bool isAdvanced = 6;
     */
    isAdvanced: boolean; // 用户是否晋级
    /**
     * @generated from protobuf field: string matchKey = 7;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bool isDelayRegister = 8;
     */
    isDelayRegister: boolean; // 是否能够延迟报名
    /**
     * @generated from protobuf field: bpt.match.common.v1.GameOverType gameOverType = 9;
     */
    gameOverType: GameOverType;
    /**
     * 阶段：如 1,2,3 表示day1,day2,day3,可报名阶段仅包括day1
     *
     * @generated from protobuf field: int32 phase = 10;
     */
    phase: number;
    /**
     * 用户晋级带的筹码,isAdvanced为true时才有意义
     *
     * @generated from protobuf field: int64 chips = 11;
     */
    chips: number;
    /**
     * 是否还可进入比赛
     * 比赛中用户不能延时报名，addon,rebuy,reentry,为false
     * 其他比赛状态为false
     *
     * @generated from protobuf field: bool canEnter = 12;
     */
    canEnter: boolean;
    /**
     * @generated from protobuf field: uint32 gameOverBlindLevel = 13;
     */
    gameOverBlindLevel: number; // 结束的盲注级别
    /**
     * @generated from protobuf field: int64 durationInSeconds = 14;
     */
    durationInSeconds: number; // 该盲注级别持续的时间
    /**
     * 结束比赛的剩余人数，当配置GameOverType=GameOverTypeBlindLevelOrReachFinalTable等有人数结束条件的时候有值
     *
     * @generated from protobuf field: int64 remainingNumber = 15;
     */
    remainingNumber: number;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 16 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言
    /**
     * @generated from protobuf field: int64 enterNumber = 17;
     */
    enterNumber: number; // 比赛人数
    /**
     * @generated from protobuf field: int64 matchNumber = 18;
     */
    matchNumber: number; // 晋级人数
    /**
     * @generated from protobuf field: bool isLastEntry = 19;
     */
    isLastEntry: boolean; // 多天比赛是否是最后一场
    /**
     * @generated from protobuf field: int64 matchDuration = 20;
     */
    matchDuration: number; // 比赛时长
    /**
     * @generated from protobuf field: bool isEarlyEnd = 21;
     */
    isEarlyEnd: boolean; // 是否在当前提前结束整体比赛
    /**
     * @generated from protobuf field: int64 registerDeadline = 22;
     */
    registerDeadline: number; // 暂停截止时间
    /**
     * @generated from protobuf field: int64 pauseBeginAt = 23;
     */
    pauseBeginAt: number; // 暂停开始时间
    /**
     * @generated from protobuf field: int64 pauseEndAt = 24;
     */
    pauseEndAt: number; // 暂停开始时间
    /**
     * @generated from protobuf field: int64 registrationAt = 25;
     */
    registrationAt: number; // 最早接受报名时间
    /**
     * @generated from protobuf field: bool isInheritStack = 26;
     */
    isInheritStack: boolean; // 是否继承筹码
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListSubMatchesResp
 */
export interface ListSubMatchesResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SubMatch subMatches = 1;
     */
    subMatches: SubMatch[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetDayOneMaxChipsReq
 */
export interface GetDayOneMaxChipsReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetDayOneMaxChipsResp
 */
export interface GetDayOneMaxChipsResp {
    /**
     * @generated from protobuf field: int64 chips = 1;
     */
    chips: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchNameAndStartAtReq
 */
export interface GetMatchNameAndStartAtReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchNameAndStartAtResp
 */
export interface GetMatchNameAndStartAtResp {
    /**
     * @generated from protobuf field: string matchName = 1;
     */
    matchName: string; // deprecated
    /**
     * @generated from protobuf field: int64 startAt = 2;
     */
    startAt: number;
    /**
     * @generated from protobuf field: bpt.common.I18n i18name = 4 [json_name = "i18name"];
     */
    i18name?: I18n;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtReq
 */
export interface BatchGetMatchNameAndStartAtReq {
    /**
     * @generated from protobuf field: repeated string matchKeys = 1;
     */
    matchKeys: string[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchNameAndStartAt
 */
export interface MatchNameAndStartAt {
    /**
     * @generated from protobuf field: int64 startAt = 1;
     */
    startAt: number;
    /**
     * @generated from protobuf field: bpt.common.I18n i18name = 2 [json_name = "i18name"];
     */
    i18name?: I18n;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtResp
 */
export interface BatchGetMatchNameAndStartAtResp {
    /**
     * @generated from protobuf field: map<string, bpt.match.matchhub.v1.MatchNameAndStartAt> matchNameAndStartAt = 1;
     */
    matchNameAndStartAt: {
        [key: string]: MatchNameAndStartAt;
    };
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListPrizePoolReq
 */
export interface ListPrizePoolReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.PrizePoolEntry
 */
export interface PrizePoolEntry {
    /**
     * @generated from protobuf field: bpt.common.AssetItem prize = 1;
     */
    prize?: AssetItem;
    /**
     * @generated from protobuf field: bpt.match.common.v1.SectionRange rankRange = 3;
     */
    rankRange?: SectionRange;
    /**
     * 万分数，保留百分位小数点后两位
     *
     * @generated from protobuf field: uint32 tenThousandShare = 4;
     */
    tenThousandShare: number;
    /**
     * 总合并奖池
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem prizes = 5;
     */
    prizes: AssetItem[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListPrizePoolResp
 */
export interface ListPrizePoolResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.PrizeType prizeType = 1;
     */
    prizeType: PrizeType;
    /**
     * @generated from protobuf field: int64 addOnCnt = 2;
     */
    addOnCnt: number; // 总手数
    /**
     * @generated from protobuf field: int64 reBuyCnt = 3;
     */
    reBuyCnt: number; // reBuy次数
    /**
     * @generated from protobuf field: int64 reEntryCnt = 4;
     */
    reEntryCnt: number; // reEntry次数
    /**
     * @generated from protobuf field: int64 totalPlayer = 5;
     */
    totalPlayer: number; // 总玩家,多日赛包括前几阶段报名人数
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.PrizePoolEntry entries = 6;
     */
    entries: PrizePoolEntry[];
    /**
     * @generated from protobuf field: int64 addOnPlayerCnt = 7;
     */
    addOnPlayerCnt: number; // 总人次
    /**
     * @generated from protobuf field: int64 reBuyPlayerCnt = 8;
     */
    reBuyPlayerCnt: number; // reBuy人数
    /**
     * @generated from protobuf field: int64 reEntryPlayerCnt = 9;
     */
    reEntryPlayerCnt: number; // reEntry人数
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 10;
     */
    prizePool: AssetItem[]; // 总奖池
    /**
     * @generated from protobuf field: int64 prizeNum = 11;
     */
    prizeNum: number; // 奖圈人数
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem reentryPrize = 12;
     */
    reentryPrize: AssetItem[]; // reentry奖池
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem addonPrize = 13;
     */
    addonPrize: AssetItem[]; // addon奖池
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem rebuyPrize = 14;
     */
    rebuyPrize: AssetItem[]; // rebuy奖池
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem dynamicPrizePool = 15;
     */
    dynamicPrizePool: AssetItem[]; // addon,rebuy,reentry,报名费奖池
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem basicPrizePool = 16;
     */
    basicPrizePool: AssetItem[]; // 基础奖池
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.PrizeAdvance prizeAdvance = 17;
     */
    prizeAdvance?: PrizeAdvance; // 是否展示晋级信息
    /**
     * @generated from protobuf field: bpt.common.AssetItem prizePoolThreshold = 18;
     */
    prizePoolThreshold?: AssetItem; // 奖池破保线
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.PrizeAdvance
 */
export interface PrizeAdvance {
    /**
     * 两天赛day1单场为false, 多场为true,其他情况均为false
     * TODO 其他赛制要补充文档
     *
     * @generated from protobuf field: bool show = 1;
     */
    show: boolean; // true 表示要展示晋级座位，false要展示奖圈（第几名什么奖）
    /**
     * @generated from protobuf field: int32 advanceNum = 2;
     */
    advanceNum: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserReq
 */
export interface ListMatchPrizeAndBubbleUserReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * 后台excel导入做校验和转换
 *
 * @generated from protobuf message bpt.match.matchhub.v1.FormatAndValidateTierPrizeReq
 */
export interface FormatAndValidateTierPrizeReq {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.TierPrize tierPrizes = 1;
     */
    tierPrizes: TierPrize[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.FormatAndValidateTierPrizeResp
 */
export interface FormatAndValidateTierPrizeResp {
    /**
     * @generated from protobuf field: repeated bpt.match.common.v1.TierPrize tierPrizes = 1;
     */
    tierPrizes: TierPrize[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListDispatchContractReq
 */
export interface ListDispatchContractReq {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetRegisterInfoReq
 */
export interface GetRegisterInfoReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetReEntryInfoReq
 */
export interface GetReEntryInfoReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetRegisterInfoResp
 */
export interface GetRegisterInfoResp {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bool free = 2;
     */
    free: boolean; // 报名是否免费 
    /**
     * Deprecated: use allowConfig
     * 报名条件-允许VIP等级，为空不限制
     *
     * @generated from protobuf field: repeated int32 allowVipLevels = 3;
     */
    allowVipLevels: number[];
    /**
     * Deprecated: use allowConfig
     * 报名条件-允许身份认证等级，为空不限制
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel allowIdentityLevels = 4;
     */
    allowIdentityLevels: EntryConfig_IdentityLevel[];
    /**
     * 免费报名条件配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.RegisterInfoFreeConfig freeConfig = 5;
     */
    freeConfig?: RegisterInfoFreeConfig;
    /**
     * 运行报名条件配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.RegisterInfoAllowConfig allowConfig = 6;
     */
    allowConfig?: RegisterInfoAllowConfig;
}
/**
 * 免报名费配置
 *
 * @generated from protobuf message bpt.match.matchhub.v1.RegisterInfoFreeConfig
 */
export interface RegisterInfoFreeConfig {
    /**
     * Deprecated: use IdentityType
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 1;
     */
    identityLevels: EntryConfig_IdentityLevel[];
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 2;
     */
    identityTypes: EntryConfig_IdentityType[]; // 身份认证类型
}
/**
 * 允许报名配置
 *
 * @generated from protobuf message bpt.match.matchhub.v1.RegisterInfoAllowConfig
 */
export interface RegisterInfoAllowConfig {
    /**
     * Deprecated: use IdentityType
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 1;
     */
    identityLevels: EntryConfig_IdentityLevel[];
    /**
     * @generated from protobuf field: repeated int32 vipLevels = 2;
     */
    vipLevels: number[];
    /**
     * @generated from protobuf field: repeated int32 clubIds = 3;
     */
    clubIds: number[];
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 4;
     */
    identityTypes: EntryConfig_IdentityType[]; // 身份认证类型
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetReEntryInfoResp
 */
export interface GetReEntryInfoResp {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: uint32 freeCount = 2;
     */
    freeCount: number; // 免费次数
    /**
     * @generated from protobuf field: uint32 freeRemain = 3;
     */
    freeRemain: number; // 剩余免费次数
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DispatchContract
 */
export interface DispatchContract {
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string; // 合约名称
    /**
     * @generated from protobuf field: string address = 3;
     */
    address: string; // 合约地址
    /**
     * @generated from protobuf field: string desc = 4;
     */
    desc: string; // 补充说明，如应用场景，注意事项等
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListDispatchContractResp
 */
export interface ListDispatchContractResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.DispatchContract dispatchContracts = 1;
     */
    dispatchContracts: DispatchContract[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListUserRecordByCmsReq
 */
export interface ListUserRecordByCmsReq {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.AwardStatus awardStatus = 1;
     */
    awardStatus: AwardStatus;
    /**
     * 筛选包含奖励的记录，用户的奖励中包含prizePropIDs里面的任何一项即返回
     *
     * @generated from protobuf field: repeated int64 prizePropIDs = 2;
     */
    prizePropIDs: number[];
    /**
     * @generated from protobuf field: int64 matchStartAtBegin = 3;
     */
    matchStartAtBegin: number; // 开始时间
    /**
     * @generated from protobuf field: int64 matchStartAtEnd = 4;
     */
    matchStartAtEnd: number; // 开始时间
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UserRecordByCms
 */
export interface UserRecordByCms {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 用户昵称
     *
     * @generated from protobuf field: string userNickname = 2;
     */
    userNickname: string;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.UserMatchRecord matchRecord = 3;
     */
    matchRecord?: UserMatchRecord;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListUserRecordByCmsResp
 */
export interface ListUserRecordByCmsResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserRecordByCms userMatchRecords = 1;
     */
    userMatchRecords: UserRecordByCms[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MarkAwardReq
 */
export interface MarkAwardReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 uid = 2;
     */
    uid: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MarkAwardResp
 */
export interface MarkAwardResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.PauseMatchReq
 */
export interface PauseMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * 暂停时间（秒级时间戳），多次请求以最后一次为准
     *  int64 pauseEndAt = 2;
     *
     * @generated from protobuf field: int32 pauseMinutes = 3;
     */
    pauseMinutes: number; // 暂停多少分钟，多次调用会累加
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ResumeMatchReq
 */
export interface ResumeMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UsePropReq
 */
export interface UsePropReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: int64 propId = 2;
     */
    propId: number; // 商品道具ID
    /**
     * @generated from protobuf field: int64 num = 3;
     */
    num: number; // 数量
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UsePropResp
 */
export interface UsePropResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UsePropShuffleCardCallBackReq
 */
export interface UsePropShuffleCardCallBackReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string tableKey = 3;
     */
    tableKey: string;
    /**
     * @generated from protobuf field: string text = 4;
     */
    text: string;
    /**
     * @generated from protobuf field: string batchId = 5;
     */
    batchId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.UsePropShuffleCardCallBackResp
 */
export interface UsePropShuffleCardCallBackResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MarkReadCompletedAwardMatchReq
 */
export interface MarkReadCompletedAwardMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.PrizeAndBubbleUser
 */
export interface PrizeAndBubbleUser {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * @generated from protobuf field: uint32 rank = 2;
     */
    rank: number;
    /**
     * @generated from protobuf field: string PrizeRawAmount = 3 [json_name = "PrizeRawAmount"];
     */
    PrizeRawAmount: string; // deprecated 多奖品后不再适用
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem userPrizePool = 4;
     */
    userPrizePool: AssetItem[]; // 用户总奖池 deprecated
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset userPrizeSimple = 5;
     */
    userPrizeSimple: SimpleAsset$[]; // 奖池
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.PrizeShowType prizeShowType = 6;
     */
    prizeShowType: PrizeShowType; // 展示类型
}
/**
 * 获取之前阶段奖圈和泡沫用户
 *
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserResp
 */
export interface ListMatchPrizeAndBubbleUserResp {
    /**
     * @generated from protobuf field: int64 startAt = 5;
     */
    startAt: number; // 开始时间
    /**
     * @generated from protobuf field: int64 endAt = 1;
     */
    endAt: number; // 结束时间
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.PrizeAndBubbleUser prizeAndBubbleUsers = 2;
     */
    prizeAndBubbleUsers: PrizeAndBubbleUser[];
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 3 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言
    /**
     * @generated from protobuf field: int64 totalEnterCnt = 4;
     */
    totalEnterCnt: number; // 总报名人数
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem prizePool = 6;
     */
    prizePool: AssetItem[]; // 总奖池
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetSimpleMatchReq
 */
export interface GetSimpleMatchReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetSimpleMatchResp
 */
export interface GetSimpleMatchResp {
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 1 [json_name = "i18n"];
     */
    i18n?: I18n; // 多语言
    /**
     * @generated from protobuf field: int64 startAt = 2;
     */
    startAt: number; // 开赛时间
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetSeatMatchLiteInfoReq
 */
export interface GetSeatMatchLiteInfoReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetSeatMatchLiteInfoResp
 */
export interface GetSeatMatchLiteInfoResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SubMatch seatMatchLiteInfoList = 1;
     */
    seatMatchLiteInfoList: SubMatch[];
}
/**
 * ===================新后台比赛配置相关接口参数 Start=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.OnlineMatchConfigReq
 */
export interface OnlineMatchConfigReq {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * 网络
     *
     * @generated from protobuf field: gaw.base.ChainID chainID = 2;
     */
    chainID: ChainID;
    /**
     * 赛事合约
     *
     * @generated from protobuf field: string dispatchContract = 3;
     */
    dispatchContract: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.OnlineMatchConfigResp
 */
export interface OnlineMatchConfigResp {
    /**
     * 透传给前端调起钱包
     *
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.OfflineMatchConfigReq
 */
export interface OfflineMatchConfigReq {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.OfflineMatchConfigResp
 */
export interface OfflineMatchConfigResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AdminSendRewardReq
 */
export interface AdminSendRewardReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AdminSendRewardResp
 */
export interface AdminSendRewardResp {
    /**
     * 透传给前端调起钱包
     *
     * @generated from protobuf field: bytes credential = 1;
     */
    credential: Uint8Array;
    /**
     * @generated from protobuf field: string tsId = 2;
     */
    tsId: string;
    /**
     * @generated from protobuf field: gaw.base.ChainID chainID = 3;
     */
    chainID: ChainID;
    /**
     * @generated from protobuf field: string address = 4;
     */
    address: string; // 合约地址
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AdminFetchUserPrizeRewardReq
 */
export interface AdminFetchUserPrizeRewardReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.AdminFetchUserPrizeRewardResp
 */
export interface AdminFetchUserPrizeRewardResp {
    /**
     * 用户排名和奖励
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserPrize userPrizes = 1;
     */
    userPrizes: UserPrize[];
    /**
     * MatchOwner的剩余金额
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.UserPrize matchOwnerRemaiPrize = 2;
     */
    matchOwnerRemaiPrize: UserPrize[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigReq
 */
export interface ListMatchConfigReq {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.match.common.v1.State state = 3;
     */
    state: State;
    /**
     * 比赛名称(en)
     *
     * @generated from protobuf field: string matchName = 4;
     */
    matchName: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigResp
 */
export interface ListMatchConfigResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.ListMatchConfigInfo configList = 1;
     */
    configList: ListMatchConfigInfo[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigInfo
 */
export interface ListMatchConfigInfo {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: bpt.common.I18n i18n = 3 [json_name = "i18n"];
     */
    i18n?: I18n;
    /**
     * @generated from protobuf field: string matchStartTime = 4;
     */
    matchStartTime: string;
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 5;
     */
    matchType: MatchType;
    /**
     * @generated from protobuf field: bpt.match.common.v1.State matchState = 6;
     */
    matchState: State;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.ListMatchConfigRecordInfo matchConfigRecord = 7;
     */
    matchConfigRecord?: ListMatchConfigRecordInfo;
    /**
     * 显示状态
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchListShowStatus matchListShowStatus = 8;
     */
    matchListShowStatus: MatchListShowStatus;
    /**
     * 网络
     *
     * @generated from protobuf field: gaw.base.ChainID chainID = 9;
     */
    chainID: ChainID;
    /**
     * 赛事合约
     *
     * @generated from protobuf field: string dispatchContract = 10;
     */
    dispatchContract: string;
}
/**
 * 列表中的赛事信息
 *
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigRecordInfo
 */
export interface ListMatchConfigRecordInfo {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRecordState state = 2;
     */
    state: MatchRecordState;
    /**
     * 子比赛
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.ListMatchConfigRecordInfo subMatchConfigList = 3;
     */
    subMatchConfigList: ListMatchConfigRecordInfo[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetEarlyBirdConfigInternalReq
 */
export interface GetEarlyBirdConfigInternalReq {
    /**
     * 配置ID(非必填)
     *
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * 赛事key(非必填)
     *
     * @generated from protobuf field: string matchKey = 2;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetEarlyBirdConfigInternalResp
 */
export interface GetEarlyBirdConfigInternalResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.EarlyBirdConfig earlyBirdConfig = 1;
     */
    earlyBirdConfig?: EarlyBirdConfig;
    /**
     * 第一次比赛开始时间
     *
     * @generated from protobuf field: int64 firstMatchStartTime = 2;
     */
    firstMatchStartTime: number;
    /**
     * @generated from protobuf field: bool OnChain = 3 [json_name = "OnChain"];
     */
    OnChain: boolean; // 是否上链
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfigReq
 */
export interface DeleteMatchConfigReq {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfigResp
 */
export interface DeleteMatchConfigResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DuplicateMatchConfigReq
 */
export interface DuplicateMatchConfigReq {
    /**
     * @generated from protobuf field: int64 duplicateConfigId = 1;
     */
    duplicateConfigId: number;
    /**
     * 是否需要返回配置信息，默认false
     *
     * @generated from protobuf field: bool returnConfigInfo = 2;
     */
    returnConfigInfo: boolean;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DuplicateMatchConfigResp
 */
export interface DuplicateMatchConfigResp {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * returnConfigInfo=true返回
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2;
     */
    baseConfig?: MatchBaseConfig;
    /**
     * returnConfigInfo=true返回
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3;
     */
    blindInfoConfig?: MatchBlindInfoConfig;
    /**
     * returnConfigInfo=true返回
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4;
     */
    rewardInfoConfig?: MatchRewardInfoConfig;
    /**
     * 子比赛配置(暂时只有盲注)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5;
     */
    subMatchConfigs: SubMatchConfig[];
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6;
     */
    newRewardInfoConfig?: NewMatchRewardInfoConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfigReq
 */
export interface GetMatchConfigReq {
    /**
     * 赛配置ID
     *
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfigResp
 */
export interface GetMatchConfigResp {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2;
     */
    baseConfig?: MatchBaseConfig;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3;
     */
    blindInfoConfig?: MatchBlindInfoConfig;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4;
     */
    rewardInfoConfig?: MatchRewardInfoConfig;
    /**
     * 子比赛配置(暂时只有盲注)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5;
     */
    subMatchConfigs: SubMatchConfig[];
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6;
     */
    newRewardInfoConfig?: NewMatchRewardInfoConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigBlindReq
 */
export interface SaveMatchConfigBlindReq {
    /**
     * 赛配置ID
     *
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * 盲注结构信息
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 2;
     */
    blindInfoConfig?: MatchBlindInfoConfig;
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 3;
     */
    subMatchConfigs: SubMatchConfig[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchBlindInfoConfig
 */
export interface MatchBlindInfoConfig {
    /**
     * 盲注结构
     *
     * @generated from protobuf field: bpt.match.common.v1.BlindStructure blindStructure = 2;
     */
    blindStructure?: BlindStructure;
    /**
     * 重购
     *
     * @generated from protobuf field: bpt.match.common.v1.RebuyConf reBuy = 3;
     */
    reBuy?: RebuyConf;
    /**
     * 加注
     *
     * @generated from protobuf field: bpt.match.common.v1.AddOnOption addOn = 4;
     */
    addOn?: AddOnOption;
    /**
     * 重入
     *
     * @generated from protobuf field: bpt.match.common.v1.ReentryConf reEntry = 5;
     */
    reEntry?: ReentryConf;
    /**
     * 暂停休息时间
     *
     * @generated from protobuf field: bpt.match.common.v1.PauseConf pauseConf = 6;
     */
    pauseConf?: PauseConf;
    // Deprecated: 没用！！！
    // bpt.match.common.v1.BubbleConf bubbleConf = 7 ; 

    // Deprecated: 没用！！！
    // bpt.match.common.v1.SpecTableConf specTableConf = 8;

    // Deprecated: 放在BlindStructure里面
    // bpt.match.common.v1.TimeCardConf timeCardConf = 9;

    /**
     * 初始筹码
     *
     * @generated from protobuf field: uint64 startingStack = 10;
     */
    startingStack: number;
    /**
     * 玩家行动时间
     *
     * @generated from protobuf field: int64 playCardMainTime = 11;
     */
    playCardMainTime: number;
    /**
     * 延迟入场配置
     *
     * @generated from protobuf field: bpt.match.common.v1.DelayPlay delayPlay = 12;
     */
    delayPlay?: DelayPlay;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SubMatchConfig
 */
export interface SubMatchConfig {
    /**
     * 对应ScheduleConfig下的PhaseEntry.ID
     *
     * @generated from protobuf field: int64 subMatchConfigID = 1;
     */
    subMatchConfigID: number;
    /**
     * 子比赛盲注配置，不传默认使用主配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 2;
     */
    blindInfoConfig?: MatchBlindInfoConfig;
    /**
     * 多语言
     *
     * @generated from protobuf field: bpt.common.I18n i18n = 3 [json_name = "i18n"];
     */
    i18n?: I18n;
    /**
     * 是否和day1A保持一致
     *
     * @generated from protobuf field: bpt.common.SwitchState sameWithDayOneA = 4;
     */
    sameWithDayOneA: SwitchState;
    /**
     * 报名条件
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.EntryConfig entryConfig = 5;
     */
    entryConfig?: EntryConfig;
    /**
     * 子比赛时间配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.SubMatchScheduleConfig subMatchScheduleConfig = 7;
     */
    subMatchScheduleConfig?: SubMatchScheduleConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SubMatchScheduleConfig
 */
export interface SubMatchScheduleConfig {
    /**
     * @generated from protobuf field: int64 registrationDuration = 8;
     */
    registrationDuration: number; // 距离开赛多久可以报名
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigBlindResp
 */
export interface SaveMatchConfigBlindResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigRewardReq
 */
export interface SaveMatchConfigRewardReq {
    /**
     * 赛配置ID
     *
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 2;
     */
    rewardInfoConfig?: MatchRewardInfoConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchRewardInfoConfig
 */
export interface MatchRewardInfoConfig {
    /**
     * 动态或静态奖池，Mtt动态奖池去prizeconf,固定奖池取sngprizeconf
     *
     * @generated from protobuf field: bpt.match.common.v1.PrizeType prizeType = 1;
     */
    prizeType: PrizeType;
    /**
     * PrizeConfig
     *
     * @generated from protobuf field: bpt.match.common.v1.ParallelPrizeConf parallelPrizeConf = 2;
     */
    parallelPrizeConf?: ParallelPrizeConf;
    /**
     * 动态奖励配置
     *
     * @generated from protobuf field: bpt.match.common.v1.DynamicPrizeConf dynamicPrizeConf = 3;
     */
    dynamicPrizeConf?: DynamicPrizeConf;
    /**
     * 是否自动发奖
     *
     * @generated from protobuf field: bpt.common.SwitchState autoSendPrize = 4;
     */
    autoSendPrize: SwitchState;
    /**
     * 奖池破保线
     *
     * @generated from protobuf field: bpt.common.AssetItem prizePoolThreshold = 5;
     */
    prizePoolThreshold?: AssetItem;
    /**
     * 最低奖池计算人数(动态奖池生效)
     *
     * @generated from protobuf field: int32 minPrizePoolCount = 6;
     */
    minPrizePoolCount: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.NewMatchRewardInfoConfig
 */
export interface NewMatchRewardInfoConfig {
    /**
     * 是否自动发奖
     *
     * @generated from protobuf field: bpt.common.SwitchState autoSendPrize = 1;
     */
    autoSendPrize: SwitchState;
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchRewardTierConfig matchRewardTierConfig = 2;
     */
    matchRewardTierConfig: MatchRewardTierConfig[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchRewardTierConfig
 */
export interface MatchRewardTierConfig {
    /**
     * 参赛人数范围开始
     *
     * @generated from protobuf field: uint32 start = 1;
     */
    start: number;
    /**
     * 参赛人数范围结束
     *
     * @generated from protobuf field: uint32 end = 2;
     */
    end: number;
    /**
     * 基础奖池
     *
     * @generated from protobuf field: repeated bpt.common.AssetItem basePool = 3;
     */
    basePool: AssetItem[];
    /**
     * 最低奖池计算人数
     *
     * @generated from protobuf field: int32 minPrizePoolCount = 4;
     */
    minPrizePoolCount: number;
    /**
     * 奖池破保线
     *
     * @generated from protobuf field: bpt.common.AssetItem prizePoolThreshold = 5;
     */
    prizePoolThreshold?: AssetItem;
    /**
     * 奖圈比例人数,取本阶梯奖池的奖圈比例, 默认值为100%
     *
     * @generated from protobuf field: uint32 prizePercentage = 6;
     */
    prizePercentage: number;
    /**
     * 奖圈奖励
     *
     * @generated from protobuf field: repeated bpt.match.common.v1.RankPrize rankPrizes = 7;
     */
    rankPrizes: RankPrize[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigRewardResp
 */
export interface SaveMatchConfigRewardResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigAllReq
 */
export interface SaveMatchConfigAllReq {
    /**
     * 赛配置ID
     *
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2;
     */
    baseConfig?: MatchBaseConfig;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3;
     */
    blindInfoConfig?: MatchBlindInfoConfig;
    /**
     * Deprecated: use NewMatchRewardInfoConfig
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4;
     */
    rewardInfoConfig?: MatchRewardInfoConfig;
    /**
     * 子比赛配置(暂时只有盲注)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5;
     */
    subMatchConfigs: SubMatchConfig[];
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6;
     */
    newRewardInfoConfig?: NewMatchRewardInfoConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigAllResp
 */
export interface SaveMatchConfigAllResp {
    /**
     * 赛配置ID
     *
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2;
     */
    baseConfig?: MatchBaseConfig;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3;
     */
    blindInfoConfig?: MatchBlindInfoConfig;
    /**
     * Deprecated: use NewMatchRewardInfoConfig
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4;
     */
    rewardInfoConfig?: MatchRewardInfoConfig;
    /**
     * 子比赛配置(暂时只有盲注)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5;
     */
    subMatchConfigs: SubMatchConfig[];
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6;
     */
    newRewardInfoConfig?: NewMatchRewardInfoConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigBaseReq
 */
export interface SaveMatchConfigBaseReq {
    /**
     * 赛配置ID
     *
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2;
     */
    baseConfig?: MatchBaseConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchGameOverConfig
 */
export interface MatchGameOverConfig {
    /**
     * @generated from protobuf field: bpt.match.common.v1.GameOverType gameOverType = 1;
     */
    gameOverType: GameOverType;
    /**
     * @generated from protobuf field: uint32 gameOverBlindLevel = 2;
     */
    gameOverBlindLevel: number;
    /**
     * @generated from protobuf field: uint32 gameOverPercentage = 3;
     */
    gameOverPercentage: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchBaseConfig
 */
export interface MatchBaseConfig {
    /**
     * 基础配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfigBase baseInfo = 2;
     */
    baseInfo?: MatchConfigBase;
    /**
     * 时间配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ScheduleConfig scheduleConfig = 3;
     */
    scheduleConfig?: ScheduleConfig;
    /**
     * 报名条件
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.EntryConfig entryConfig = 4;
     */
    entryConfig?: EntryConfig;
    /**
     * 赛事封面配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchCoverConfig coverConfig = 5;
     */
    coverConfig?: MatchCoverConfig;
    /**
     * 早鸟奖励配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.EarlyBirdConfig earlyBird = 6;
     */
    earlyBird?: EarlyBirdConfig;
    /**
     * 版本配置
     *
     * @generated from protobuf field: bpt.match.common.v1.ServiceConf serviceConf = 7;
     */
    serviceConf?: ServiceConf;
    /**
     * 多语言
     *
     * @generated from protobuf field: bpt.common.I18n i18n = 8 [json_name = "i18n"];
     */
    i18n?: I18n;
    /**
     * 赛报是否上报到资讯
     *
     * @generated from protobuf field: bpt.common.SwitchState reportToNews = 9;
     */
    reportToNews: SwitchState;
    /**
     * 比赛置顶排序(值越大排越靠前)
     *
     * @generated from protobuf field: int32 showPriority = 10;
     */
    showPriority: number;
    /**
     * 属于的clubId,为0不属于任何club
     *
     * @generated from protobuf field: uint32 clubId = 11;
     */
    clubId: number;
    /**
     * 标签列表
     *
     * @generated from protobuf field: repeated string tags = 12;
     */
    tags: string[];
    /**
     * 比赛结束条件
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchGameOverConfig matchGameOverConfig = 13;
     */
    matchGameOverConfig?: MatchGameOverConfig;
    /**
     * 席位赛配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.SeatMatchConfig seatMatchConfig = 14;
     */
    seatMatchConfig?: SeatMatchConfig;
    /**
     * 比赛是否整点休息开关
     *
     * @generated from protobuf field: bpt.common.SwitchState enableHourlyRest = 15;
     */
    enableHourlyRest: SwitchState;
}
/**
 * 席位赛配置
 *
 * @generated from protobuf message bpt.match.matchhub.v1.SeatMatchConfig
 */
export interface SeatMatchConfig {
    /**
     * 是否开启席位赛
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 目标赛事Key
     *
     * @generated from protobuf field: string targetMatchKey = 2;
     */
    targetMatchKey: string;
    /**
     * 最大晋级人数
     *
     * @generated from protobuf field: uint32 maxAdvancePlayerNum = 3;
     */
    maxAdvancePlayerNum: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigBaseResp
 */
export interface SaveMatchConfigBaseResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.CreateMatchConfigReq
 */
export interface CreateMatchConfigReq {
    /**
     * 网络
     *
     * @generated from protobuf field: gaw.base.ChainID chainID = 1;
     */
    chainID: ChainID;
    /**
     * 赛事合约
     *
     * @generated from protobuf field: string dispatchContract = 2;
     */
    dispatchContract: string;
    /**
     * 赛事类型
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 3;
     */
    matchType: MatchType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.CreateMatchConfigResp
 */
export interface CreateMatchConfigResp {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchConfigBase
 */
export interface MatchConfigBase {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * 赛事类型
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchType matchType = 2;
     */
    matchType: MatchType;
    /**
     * 比赛名称
     *
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * 游戏类型
     *
     * @generated from protobuf field: bpt.match.common.v1.BetWay gameType = 4;
     */
    gameType: BetWay;
    /**
     * 赛事分类
     *
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchCategory = 5;
     */
    matchCategory: MatchListType;
    /**
     * 赛事简介规则
     *
     * @generated from protobuf field: string rule = 6;
     */
    rule: string;
    /**
     * 比赛最低人数
     *
     * @generated from protobuf field: int32 minPlayerNum = 7;
     */
    minPlayerNum: number;
    /**
     * 比赛最高人数
     *
     * @generated from protobuf field: int32 maxPlayerNum = 8;
     */
    maxPlayerNum: number;
    /**
     * 每桌人数
     *
     * @generated from protobuf field: int32 playerPerTable = 9;
     */
    playerPerTable: number;
    /**
     * 决赛桌人数
     *
     * @generated from protobuf field: int32 playerFinalTable = 10;
     */
    playerFinalTable: number;
}
/**
 * 报名条件配置
 *
 * @generated from protobuf message bpt.match.matchhub.v1.EntryConfig
 */
export interface EntryConfig {
    /**
     * 买入配置
     *
     * @generated from protobuf field: bpt.common.AssetItem buyIn = 1;
     */
    buyIn?: AssetItem;
    /**
     * 门票配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.EntryTicketsConfig ticketsConfig = 2;
     */
    ticketsConfig?: EntryTicketsConfig;
    /**
     * 是否免Gas(打开 业务自动代报名)
     *
     * @generated from protobuf field: bpt.common.SwitchState autoApply = 3;
     */
    autoApply: SwitchState;
    /**
     * 是否开启上链(默认关闭)
     *
     * @generated from protobuf field: bpt.common.SwitchState switchOnChain = 4;
     */
    switchOnChain: SwitchState;
    /**
     * 限制Club(为0表示不限)
     *
     * @generated from protobuf field: repeated uint32 clubIds = 5;
     */
    clubIds: number[];
    /**
     * 限制VIP等级(为0表示不限)[1、2、3]
     *
     * @generated from protobuf field: repeated uint32 vipLevels = 6;
     */
    vipLevels: number[];
    /**
     * Deprecated: use IdentityType
     * 身份认证等级(为0表示不限)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 7;
     */
    identityLevels: EntryConfig_IdentityLevel[];
    /**
     * 免费报名配置
     *
     * @generated from protobuf field: bpt.match.matchhub.v1.FreeEntryConfig freeEntryConfig = 8;
     */
    freeEntryConfig?: FreeEntryConfig;
    /**
     * 身份认证类型(为空数组表示不限)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 9;
     */
    identityTypes: EntryConfig_IdentityType[];
}
/**
 * Deprecated: use IdentityType
 *
 * @generated from protobuf enum bpt.match.matchhub.v1.EntryConfig.IdentityLevel
 */
export enum EntryConfig_IdentityLevel {
    /**
     * 0 无认证（不使用)
     *
     * @generated from protobuf enum value: IdentityLevelUnVerify = 0;
     */
    IdentityLevelUnVerify = 0,
    /**
     * Blue 通过Twitter的社交验证
     *
     * @generated from protobuf enum value: IdentityLevelBlue = 1;
     */
    IdentityLevelBlue = 1,
    /**
     * Green 通过人脸的活体验证
     *
     * @generated from protobuf enum value: IdentityLevelGreen = 2;
     */
    IdentityLevelGreen = 2,
    /**
     * Golden 通过Twitter 和 人脸活体认证
     *
     * @generated from protobuf enum value: IdentityLevelGolden = 3;
     */
    IdentityLevelGolden = 3
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.EntryConfig.IdentityType
 */
export enum EntryConfig_IdentityType {
    /**
     * 0 无认证（不使用)
     *
     * @generated from protobuf enum value: IdentityTypeUnVerify = 0;
     */
    IdentityTypeUnVerify = 0,
    /**
     * 1 Twitter
     *
     * @generated from protobuf enum value: IdentityTypeTwitter = 1;
     */
    IdentityTypeTwitter = 1,
    /**
     * 2 人脸
     *
     * @generated from protobuf enum value: IdentityTypeFace = 2;
     */
    IdentityTypeFace = 2
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.FreeEntryConfig
 */
export interface FreeEntryConfig {
    /**
     * 是否开启
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * Deprecated: use IdentityType
     * 身份认证等级(不能为0)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 2;
     */
    identityLevels: EntryConfig_IdentityLevel[];
    /**
     * 身份认证类型(不能为0)
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 3;
     */
    identityTypes: EntryConfig_IdentityType[];
}
/**
 * 报名门票配置
 *
 * @generated from protobuf message bpt.match.matchhub.v1.EntryTicketsConfig
 */
export interface EntryTicketsConfig {
    /**
     * 是否启用门票报名，默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState switch = 1;
     */
    switch: SwitchState;
    /**
     * 门票物品id
     *
     * @generated from protobuf field: int64 propId = 2;
     */
    propId: number;
    /**
     * 报名所需门票数
     *
     * @generated from protobuf field: int32 registerTicketCnt = 3;
     */
    registerTicketCnt: number;
}
/**
 * 赛事封面配置
 *
 * @generated from protobuf message bpt.match.matchhub.v1.MatchCoverConfig
 */
export interface MatchCoverConfig {
    /**
     * 展示图片
     *
     * @generated from protobuf field: string showImg = 1;
     */
    showImg: string;
    /**
     * 手机版本的展示图片
     *
     * @generated from protobuf field: string showImgMobile = 2;
     */
    showImgMobile: string;
    /**
     * 移动端规格列表图片
     *
     * @generated from protobuf field: string mobileShowImgList = 3;
     */
    mobileShowImgList: string;
    /**
     * 移动端规格详情图片
     *
     * @generated from protobuf field: string mobileShowImgDetail = 4;
     */
    mobileShowImgDetail: string;
    /**
     * pc端规格列表图片(列表)
     *
     * @generated from protobuf field: string showImgList = 5;
     */
    showImgList: string;
    /**
     * pc端规格详情图片(详情)
     *
     * @generated from protobuf field: string showImgDetail = 6;
     */
    showImgDetail: string;
}
/**
 * 早鸟配置
 *
 * @generated from protobuf message bpt.match.matchhub.v1.EarlyBirdConfig
 */
export interface EarlyBirdConfig {
    /**
     * 默认关闭
     *
     * @generated from protobuf field: bpt.common.SwitchState isOpen = 1;
     */
    isOpen: SwitchState;
    /**
     * 是否广播大奖
     *
     * @generated from protobuf field: bpt.common.SwitchState isBroadcast = 2;
     */
    isBroadcast: SwitchState;
    /**
     * 早鸟资格(距离开赛前秒数)
     *
     * @generated from protobuf field: int64 getDuration = 3;
     */
    getDuration: number;
    /**
     * 前3个奖项
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EarlyBirdAwardConfig award = 4;
     */
    award: EarlyBirdAwardConfig[];
    /**
     * 第四个，按人数均分
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.EarlyBirdAvgAwardConfig avgAward = 5;
     */
    avgAward: EarlyBirdAvgAwardConfig[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.EarlyBirdAwardConfig
 */
export interface EarlyBirdAwardConfig {
    /**
     * 个数
     *
     * @generated from protobuf field: int32 count = 1;
     */
    count: number;
    /**
     * 奖项
     *
     * @generated from protobuf field: bpt.common.AssetItem award = 3;
     */
    award?: AssetItem;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.EarlyBirdAvgAwardConfig
 */
export interface EarlyBirdAvgAwardConfig {
    /**
     * 个数
     *
     * @generated from protobuf field: int32 count = 1;
     */
    count: number;
    /**
     * Deprecated: use upLimitAssetItem
     *
     * @generated from protobuf field: int32 upLimit = 2;
     */
    upLimit: number;
    /**
     * 奖项
     *
     * @generated from protobuf field: bpt.common.AssetItem award = 3;
     */
    award?: AssetItem;
    /**
     * 个人上限
     *
     * @generated from protobuf field: bpt.common.AssetItem upLimitAssetItem = 4;
     */
    upLimitAssetItem?: AssetItem;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateReq
 */
export interface SaveMatchConfigTemplateReq {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1;
     */
    tmpl?: MatchConfTmpl;
    /**
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateResp
 */
export interface SaveMatchConfigTemplateResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1;
     */
    tmpl?: MatchConfTmpl;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateReq
 */
export interface GetMatchConfigTemplateReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateResp
 */
export interface GetMatchConfigTemplateResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1;
     */
    tmpl?: MatchConfTmpl;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateReq
 */
export interface ListMatchConfigTemplateReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmplType matchType = 3;
     */
    matchType: MatchConfTmplType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateResp
 */
export interface ListMatchConfigTemplateResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchConfTmpl tmplList = 1;
     */
    tmplList: MatchConfTmpl[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfigTemplateReq
 */
export interface DeleteMatchConfigTemplateReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfigTemplateResp
 */
export interface DeleteMatchConfigTemplateResp {
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigOperationLogReq
 */
export interface ListMatchConfigOperationLogReq {
    /**
     * @generated from protobuf field: int64 matchConfigId = 1;
     */
    matchConfigId: number;
    /**
     * @generated from protobuf field: int32 page = 2;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 3;
     */
    pageSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigOperationLogResp
 */
export interface ListMatchConfigOperationLogResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.ListMatchConfigOperationLog logList = 1;
     */
    logList: ListMatchConfigOperationLog[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigOperationLog
 */
export interface ListMatchConfigOperationLog {
    /**
     * 操作用户Uid
     *
     * @generated from protobuf field: int64 operatorUid = 1;
     */
    operatorUid: number;
    /**
     * 操作时间
     *
     * @generated from protobuf field: int64 operateTime = 2;
     */
    operateTime: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.ListMatchConfigOperationLog.OperateType operateType = 3;
     */
    operateType: ListMatchConfigOperationLog_OperateType;
    /**
     * 操作日志差异列表
     *
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchConfigOperationLogDifference differenceList = 6;
     */
    differenceList: MatchConfigOperationLogDifference[];
}
/**
 * 操作类型
 *
 * @generated from protobuf enum bpt.match.matchhub.v1.ListMatchConfigOperationLog.OperateType
 */
export enum ListMatchConfigOperationLog_OperateType {
    /**
     * 未知
     *
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * 新增
     *
     * @generated from protobuf enum value: Add = 1;
     */
    Add = 1,
    /**
     * 修改
     *
     * @generated from protobuf enum value: Modify = 2;
     */
    Modify = 2,
    /**
     * 发布
     *
     * @generated from protobuf enum value: Publish = 3;
     */
    Publish = 3,
    /**
     * 取消
     *
     * @generated from protobuf enum value: Cancel = 4;
     */
    Cancel = 4,
    /**
     * 暂停
     *
     * @generated from protobuf enum value: Pause = 5;
     */
    Pause = 5,
    /**
     * 恢复
     *
     * @generated from protobuf enum value: Resume = 6;
     */
    Resume = 6,
    /**
     * 完成支付
     *
     * @generated from protobuf enum value: CompletePayment = 7;
     */
    CompletePayment = 7,
    /**
     * 完成发奖
     *
     * @generated from protobuf enum value: CompleteAward = 8;
     */
    CompleteAward = 8
}
/**
 * 操作日志差异
 *
 * @generated from protobuf message bpt.match.matchhub.v1.MatchConfigOperationLogDifference
 */
export interface MatchConfigOperationLogDifference {
    /**
     * 字段路径
     *
     * @generated from protobuf field: string fieldPath = 1;
     */
    fieldPath: string;
    /**
     * 操作前的值
     *
     * @generated from protobuf field: string beforeValue = 2;
     */
    beforeValue: string;
    /**
     * 操作后的值
     *
     * @generated from protobuf field: string afterValue = 3;
     */
    afterValue: string;
}
/**
 * 字段取自SaveMatchConfigAllReq
 *
 * @generated from protobuf message bpt.match.matchhub.v1.MatchConfTmplV2
 */
export interface MatchConfTmplV2 {
    /**
     * 模板信息,id后端赋值
     *
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
    /**
     * @generated from protobuf field: int64 createTime = 4;
     */
    createTime: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 5;
     */
    blindInfoConfig?: MatchBlindInfoConfig;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 6;
     */
    rewardInfoConfig?: MatchRewardInfoConfig;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.TmplPartType part = 7;
     */
    part: TmplPartType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Req
 */
export interface SaveMatchConfigTemplateV2Req {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmplV2 tmpl = 1;
     */
    tmpl?: MatchConfTmplV2;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Resp
 */
export interface SaveMatchConfigTemplateV2Resp {
    /**
     * @generated from protobuf field: int64 newTmplId = 1;
     */
    newTmplId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateV2Req
 */
export interface GetMatchConfigTemplateV2Req {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateV2Resp
 */
export interface GetMatchConfigTemplateV2Resp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchConfTmplV2 tmpl = 1;
     */
    tmpl?: MatchConfTmplV2;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateV2Req
 */
export interface ListMatchConfigTemplateV2Req {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string name = 3;
     */
    name: string;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.TmplPartType part = 4;
     */
    part: TmplPartType;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateV2Resp
 */
export interface ListMatchConfigTemplateV2Resp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchConfTmplV2 tmplList = 1;
     */
    tmplList: MatchConfTmplV2[];
    /**
     * @generated from protobuf field: int64 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchConfigTemplateV2Req
 */
export interface DeleteMatchConfigTemplateV2Req {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * ===================MTT Match 调用接口 Start=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchPrizeNumReq
 */
export interface GetMatchPrizeNumReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchPrizeNumResp
 */
export interface GetMatchPrizeNumResp {
    /**
     * @generated from protobuf field: int64 prizeNum = 1;
     */
    prizeNum: number;
}
/**
 * ===================赛前预告 Start=======================
 *
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchNoticeReq
 */
export interface GetMatchNoticeReq {
    /**
     * @generated from protobuf field: string lang = 1;
     */
    lang: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchNoticeResp
 */
export interface GetMatchNoticeResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchNotice notice = 1;
     */
    notice?: MatchNotice;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.I18nNotice
 */
export interface I18nNotice {
    /**
     * k:lang, v:matchNotice
     *
     * @generated from protobuf field: map<string, bpt.match.matchhub.v1.MatchNotice> notice = 1;
     */
    notice: {
        [key: string]: MatchNotice;
    };
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchNotice
 */
export interface MatchNotice {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: repeated string pcImgs = 2;
     */
    pcImgs: string[];
    /**
     * @generated from protobuf field: repeated string mobileImgs = 3;
     */
    mobileImgs: string[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchNoticeConfig
 */
export interface MatchNoticeConfig {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: int64 displayStartAt = 2;
     */
    displayStartAt: number;
    /**
     * @generated from protobuf field: int64 displayEndAt = 3;
     */
    displayEndAt: number;
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.I18nNotice i18n = 5 [json_name = "i18n"];
     */
    i18n?: I18nNotice;
    /**
     * @generated from protobuf field: bpt.common.SwitchState state = 6;
     */
    state: SwitchState;
    /**
     * @generated from protobuf field: string name = 8;
     */
    name: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchNoticeConfigReq
 */
export interface GetMatchNoticeConfigReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchNoticeConfigResp
 */
export interface GetMatchNoticeConfigResp {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchNoticeConfig config = 1;
     */
    config?: MatchNoticeConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchNoticeConfigReq
 */
export interface SaveMatchNoticeConfigReq {
    /**
     * @generated from protobuf field: bpt.match.matchhub.v1.MatchNoticeConfig config = 1;
     */
    config?: MatchNoticeConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.SaveMatchNoticeConfigResp
 */
export interface SaveMatchNoticeConfigResp {
    /**
     * @generated from protobuf field: int64 newConfId = 1;
     */
    newConfId: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchNoticeConfigReq
 */
export interface ListMatchNoticeConfigReq {
    /**
     * @generated from protobuf field: int32 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int32 pageSize = 2;
     */
    pageSize: number;
    /**
     * 查找的时间区间:[displayStartAt, displayEndAt)
     *
     * @generated from protobuf field: int64 displayStartAt = 3;
     */
    displayStartAt: number;
    /**
     * @generated from protobuf field: int64 displayEndAt = 4;
     */
    displayEndAt: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchNoticeConfigResp
 */
export interface ListMatchNoticeConfigResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchNoticeConfig list = 1;
     */
    list: MatchNoticeConfig[];
    /**
     * @generated from protobuf field: int32 totalSize = 2;
     */
    totalSize: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.DeleteMatchNoticeConfigReq
 */
export interface DeleteMatchNoticeConfigReq {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.MatchListTypeFeatureConfig
 */
export interface MatchListTypeFeatureConfig {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchListType matchCategory = 1;
     */
    matchCategory: MatchListType;
    /**
     * @generated from protobuf field: bool isEnable = 2;
     */
    isEnable: boolean; // 是否启用
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig = 3;
     */
    matchFeatureConfig?: MatchFeatureConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.ListMatchFeaturesResp
 */
export interface ListMatchFeaturesResp {
    /**
     * @generated from protobuf field: repeated bpt.match.matchhub.v1.MatchListTypeFeatureConfig matchListTypeConfigs = 1;
     */
    matchListTypeConfigs: MatchListTypeFeatureConfig[];
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchFeaturesReq
 */
export interface GetMatchFeaturesReq {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetMatchFeaturesResp
 */
export interface GetMatchFeaturesResp {
    /**
     * @generated from protobuf field: bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig = 1;
     */
    matchFeatureConfig?: MatchFeatureConfig;
}
/**
 * @generated from protobuf message bpt.match.matchhub.v1.GetRecommendMatchResp
 */
export interface GetRecommendMatchResp {
    /**
     * @generated from protobuf field: string matchKey = 1;
     */
    matchKey: string;
    /**
     * @generated from protobuf field: string matchName = 2;
     */
    matchName: string; // 需要在header传入语言，没有传入语言返回英语
    /**
     * @generated from protobuf field: uint64 smallBlind = 3;
     */
    smallBlind: number;
    /**
     * @generated from protobuf field: uint64 bigBlind = 4;
     */
    bigBlind: number;
    /**
     * @generated from protobuf field: repeated bpt.common.SimpleAsset buyIn = 5;
     */
    buyIn: SimpleAsset$[];
    /**
     * @generated from protobuf field: int64 remainPlayerCnt = 6;
     */
    remainPlayerCnt: number; // 剩余玩家数量
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.EventType
 */
export enum EventType {
    /**
     * @generated from protobuf enum value: EventTypeUnknown = 0;
     */
    EventTypeUnknown = 0,
    /**
     * 比赛配置上线
     *
     * @generated from protobuf enum value: EventTypeOnlineMatchConf = 1;
     */
    EventTypeOnlineMatchConf = 1,
    /**
     * 比赛配置下线
     *
     * @generated from protobuf enum value: EventTypeOfflineMatchConf = 2;
     */
    EventTypeOfflineMatchConf = 2,
    /**
     * 删除比赛配置
     *
     * @generated from protobuf enum value: EventTypeDeleteMatchConf = 3;
     */
    EventTypeDeleteMatchConf = 3
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.MatchConfTmplType
 */
export enum MatchConfTmplType {
    /**
     * @generated from protobuf enum value: MatchConfTmplTypeUnknown = 0;
     */
    MatchConfTmplTypeUnknown = 0,
    /**
     * 静态奖池配置模板
     *
     * @generated from protobuf enum value: MatchConfTmplTypeRangePrizes = 1;
     */
    MatchConfTmplTypeRangePrizes = 1,
    /**
     * 盲注结构配置模板
     *
     * @generated from protobuf enum value: MatchConfTmplTypeBlindStructure = 2;
     */
    MatchConfTmplTypeBlindStructure = 2,
    /**
     * 平行奖池配置模板
     *
     * @generated from protobuf enum value: MatchConfTmplTypeParallelPrizes = 3;
     */
    MatchConfTmplTypeParallelPrizes = 3
}
// ===================配置下的比赛列表信息=======================

/**
 * @generated from protobuf enum bpt.match.matchhub.v1.MatchRecordState
 */
export enum MatchRecordState {
    /**
     * @generated from protobuf enum value: MatchRecordStateUnknown = 0;
     */
    MatchRecordStateUnknown = 0,
    /**
     * 比赛中
     *
     * @generated from protobuf enum value: MatchRecordStateRunning = 1;
     */
    MatchRecordStateRunning = 1,
    /**
     * 审核拒绝
     *    MatchRecordStateRejected = 2;
     * 已取消
     *
     * @generated from protobuf enum value: MatchRecordStateCancel = 3;
     */
    MatchRecordStateCancel = 3,
    /**
     * 已结束
     *
     * @generated from protobuf enum value: MatchRecordStateStop = 4;
     */
    MatchRecordStateStop = 4,
    /**
     * 开赛失败
     *
     * @generated from protobuf enum value: MatchRecordStateStartFailed = 5;
     */
    MatchRecordStateStartFailed = 5,
    /**
     * 创建中
     *
     * @generated from protobuf enum value: MatchRecordStateCreating = 6;
     */
    MatchRecordStateCreating = 6,
    /**
     * 已结算
     *
     * @generated from protobuf enum value: MatchRecordStateIssued = 7;
     */
    MatchRecordStateIssued = 7,
    /**
     * 已创建，但是不一定在列表展示，
     * 在列表展示如果还没到首次展示时间，不展示
     * 未到首次展示期间报名是非法请求，返回业务错误 CodeNotInValidTimeRange
     * 到了首次展示时间，在列表展示
     *
     * @generated from protobuf enum value: MatchRecordStateCreated = 8;
     */
    MatchRecordStateCreated = 8,
    /**
     * 异常终结，可以恢复比赛会取消比赛
     *
     * @generated from protobuf enum value: MatchRecordStateException = 10;
     */
    MatchRecordStateException = 10,
    /**
     * 暂停比赛
     *
     * @generated from protobuf enum value: MatchRecordStatePause = 11;
     */
    MatchRecordStatePause = 11,
    /**
     * 等待合约确认stop,确认后，更改为stop
     *
     * @generated from protobuf enum value: MatchRecordStateConfirmingStop = 12;
     */
    MatchRecordStateConfirmingStop = 12,
    /**
     * 开始比赛准备瞬间状态
     *
     * @generated from protobuf enum value: MatchRecordStateStarting = 13;
     */
    MatchRecordStateStarting = 13
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.UserTsFailType
 */
export enum UserTsFailType {
    /**
     * @generated from protobuf enum value: UserTsFailTypeUnknown = 0;
     */
    UserTsFailTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: UserTsFailTypeRegister = 1;
     */
    UserTsFailTypeRegister = 1,
    /**
     * @generated from protobuf enum value: UserTsFailTypeReBuy = 2;
     */
    UserTsFailTypeReBuy = 2,
    /**
     * @generated from protobuf enum value: UserTsFailTypeReEntry = 3;
     */
    UserTsFailTypeReEntry = 3,
    /**
     * @generated from protobuf enum value: UserTsFailTypeAddon = 4;
     */
    UserTsFailTypeAddon = 4,
    /**
     * @generated from protobuf enum value: UserTsFailTypeDelayRegister = 5;
     */
    UserTsFailTypeDelayRegister = 5
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.ParticipateStatus
 */
export enum ParticipateStatus {
    /**
     * @generated from protobuf enum value: ParticipateStatusUnknown = 0;
     */
    ParticipateStatusUnknown = 0,
    /**
     * 报名中
     *
     * @generated from protobuf enum value: ParticipateStatusJoining = 1;
     */
    ParticipateStatusJoining = 1,
    /**
     * 已报名
     *
     * @generated from protobuf enum value: ParticipateStatusJoined = 2;
     */
    ParticipateStatusJoined = 2,
    /**
     * 报名失败
     *
     * 可再次报名, 状态可转为ParticipateStatusJoining
     *
     * @generated from protobuf enum value: ParticipateStatusFailed = 3;
     */
    ParticipateStatusFailed = 3,
    /**
     * 比赛取消不能重新报名，终局状态，该状态后不可再变
     *
     * @generated from protobuf enum value: ParticipateStatusCanceled = 4;
     */
    ParticipateStatusCanceled = 4
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.AuditStatus
 */
export enum AuditStatus {
    /**
     * @generated from protobuf enum value: AuditStatusUnknown = 0;
     */
    AuditStatusUnknown = 0,
    /**
     * 审核通过
     *
     * @generated from protobuf enum value: AuditStatusPassed = 1;
     */
    AuditStatusPassed = 1,
    /**
     * 审核拒绝
     *
     * @generated from protobuf enum value: AuditStatusRejected = 2;
     */
    AuditStatusRejected = 2
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.AwardStatus
 */
export enum AwardStatus {
    /**
     * @generated from protobuf enum value: AwardStatusUnknown = 0;
     */
    AwardStatusUnknown = 0,
    /**
     * 已发放
     *
     * @generated from protobuf enum value: AwardStatusIssued = 1;
     */
    AwardStatusIssued = 1,
    /**
     * 已全部领取（链+非链均领取）
     *
     * @generated from protobuf enum value: AwardStatusClaimed = 2;
     */
    AwardStatusClaimed = 2,
    /**
     * 已领取链上奖品，未领取非链奖品
     *
     * @generated from protobuf enum value: AwardStatusClaimedChain = 3;
     */
    AwardStatusClaimedChain = 3,
    /**
     * 已领取非链上奖品，未领取链奖品
     *
     * @generated from protobuf enum value: AwardStatusClaimedNonChain = 4;
     */
    AwardStatusClaimedNonChain = 4
}
/**
 * 退出方式
 *
 * @generated from protobuf enum bpt.match.matchhub.v1.Withdrawal
 */
export enum Withdrawal {
    /**
     * 取消比赛退款
     *
     * @generated from protobuf enum value: WithdrawalRefund = 0;
     */
    WithdrawalRefund = 0,
    /**
     * 取消比赛发奖
     *
     * @generated from protobuf enum value: WithdrawalReward = 1;
     */
    WithdrawalReward = 1
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.ClaimFormStatus
 */
export enum ClaimFormStatus {
    /**
     * 没有奖励，不用填表单
     *
     * @generated from protobuf enum value: ClaimFormStatusNone = 0;
     */
    ClaimFormStatusNone = 0,
    /**
     * 未填写表单
     *
     * @generated from protobuf enum value: ClaimFormStatusWaitSubmit = 1;
     */
    ClaimFormStatusWaitSubmit = 1,
    /**
     * 已提交表单
     *
     * @generated from protobuf enum value: ClaimFormStatusSubmitted = 2;
     */
    ClaimFormStatusSubmitted = 2
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.PrizeShowType
 */
export enum PrizeShowType {
    /**
     * 显示奖金
     *
     * @generated from protobuf enum value: PrizeShowTypePrize = 0;
     */
    PrizeShowTypePrize = 0,
    /**
     * 显示Seat
     *
     * @generated from protobuf enum value: PrizeShowTypeSeat = 1;
     */
    PrizeShowTypeSeat = 1,
    /**
     * 没获奖，不展示
     *
     * @generated from protobuf enum value: PrizeShowTypeNone = 2;
     */
    PrizeShowTypeNone = 2,
    /**
     * 同时获奖和晋级
     *
     * @generated from protobuf enum value: PrizeShowTypePrizeAndSeat = 3;
     */
    PrizeShowTypePrizeAndSeat = 3
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.MatchListShowStatus
 */
export enum MatchListShowStatus {
    /**
     * 未知
     *
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * 草稿：点击保存之后的草稿状态
     *
     * @generated from protobuf enum value: Draft = 1;
     */
    Draft = 1,
    /**
     * 未发布：赛事已经创建完成，但是还没有到发布时间
     *
     * @generated from protobuf enum value: Unpublished = 2;
     */
    Unpublished = 2,
    /**
     * 待支付：点击发布之后，信息校验正确，调起钱包支付中的过渡状态
     *
     * @generated from protobuf enum value: PendingPayment = 3;
     */
    PendingPayment = 3,
    /**
     * 已发布：赛事已经发布，已经展示在前端的赛事列表中
     *
     * @generated from protobuf enum value: Published = 4;
     */
    Published = 4,
    /**
     * 进行中：赛事已经到达开赛时间，并且满足开赛条件，正常开赛
     *
     * @generated from protobuf enum value: InProgress = 5;
     */
    InProgress = 5,
    /**
     * 取消中：上链的赛事点击取消，调起钱包支付的状态
     *
     * @generated from protobuf enum value: Cancelling = 6;
     */
    Cancelling = 6,
    /**
     * 已暂停：比赛中的比赛已暂停，但是对于用户来说是中间插入休息时间
     *
     * @generated from protobuf enum value: Paused = 7;
     */
    Paused = 7,
    /**
     * 已取消：比赛已经取消，退还玩家报名费、重入、重购和加码费用
     *
     * @generated from protobuf enum value: Cancelled = 8;
     */
    Cancelled = 8,
    /**
     * 待发奖：比赛结束完成，比赛事结束，等待发奖
     *
     * @generated from protobuf enum value: AwaitingAward = 9;
     */
    AwaitingAward = 9,
    /**
     * 已发奖：发奖完成
     *
     * @generated from protobuf enum value: Awarded = 10;
     */
    Awarded = 10,
    /**
     * 开赛失败(比赛开赛失败，显示领取退款)
     *
     * @generated from protobuf enum value: StartFailed = 11;
     */
    StartFailed = 11
}
/**
 * @generated from protobuf enum bpt.match.matchhub.v1.TmplPartType
 */
export enum TmplPartType {
    /**
     * @generated from protobuf enum value: PartUnknown = 0;
     */
    PartUnknown = 0,
    /**
     * 盲注配置
     *
     * @generated from protobuf enum value: PartBlindInfo = 2;
     */
    PartBlindInfo = 2,
    /**
     * 奖励配置
     *
     * @generated from protobuf enum value: PartRewardInfo = 3;
     */
    PartRewardInfo = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class MatchConfTmpl$Type extends MessageType<MatchConfTmpl> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchConfTmpl", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchConfTmplType", MatchConfTmplType] },
            { no: 3, name: "rangePrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RangePrize },
            { no: 4, name: "levels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BlindLevel },
            { no: 11, name: "blindStructure", kind: "message", T: () => BlindStructure },
            { no: 5, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "createAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "owner", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "parallelConf", kind: "message", T: () => ParallelPrizeConf }
        ]);
    }
    create(value?: PartialMessage<MatchConfTmpl>): MatchConfTmpl {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.type = 0;
        message.rangePrizes = [];
        message.levels = [];
        message.operator = "";
        message.createAt = 0;
        message.updateAt = 0;
        message.name = "";
        message.owner = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchConfTmpl>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchConfTmpl): MatchConfTmpl {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.MatchConfTmplType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.RangePrize rangePrizes */ 3:
                    message.rangePrizes.push(RangePrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.common.v1.BlindLevel levels */ 4:
                    message.levels.push(BlindLevel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.BlindStructure blindStructure */ 11:
                    message.blindStructure = BlindStructure.internalBinaryRead(reader, reader.uint32(), options, message.blindStructure);
                    break;
                case /* string operator */ 5:
                    message.operator = reader.string();
                    break;
                case /* int64 createAt */ 6:
                    message.createAt = reader.int64().toNumber();
                    break;
                case /* int64 updateAt */ 7:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* string name */ 8:
                    message.name = reader.string();
                    break;
                case /* int64 owner */ 9:
                    message.owner = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.ParallelPrizeConf parallelConf */ 10:
                    message.parallelConf = ParallelPrizeConf.internalBinaryRead(reader, reader.uint32(), options, message.parallelConf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchConfTmpl, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        /* bpt.match.matchhub.v1.MatchConfTmplType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* repeated bpt.match.common.v1.RangePrize rangePrizes = 3; */
        for (let i = 0; i < message.rangePrizes.length; i++)
            RangePrize.internalBinaryWrite(message.rangePrizes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.common.v1.BlindLevel levels = 4; */
        for (let i = 0; i < message.levels.length; i++)
            BlindLevel.internalBinaryWrite(message.levels[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.BlindStructure blindStructure = 11; */
        if (message.blindStructure)
            BlindStructure.internalBinaryWrite(message.blindStructure, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string operator = 5; */
        if (message.operator !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.operator);
        /* int64 createAt = 6; */
        if (message.createAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.createAt);
        /* int64 updateAt = 7; */
        if (message.updateAt !== 0)
            writer.tag(7, WireType.Varint).int64(message.updateAt);
        /* string name = 8; */
        if (message.name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.name);
        /* int64 owner = 9; */
        if (message.owner !== 0)
            writer.tag(9, WireType.Varint).int64(message.owner);
        /* bpt.match.common.v1.ParallelPrizeConf parallelConf = 10; */
        if (message.parallelConf)
            ParallelPrizeConf.internalBinaryWrite(message.parallelConf, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchConfTmpl
 */
export const MatchConfTmpl = new MatchConfTmpl$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgTransactionBack$Type extends MessageType<MsgTransactionBack> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgTransactionBack", [
            { no: 1, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "info", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<MsgTransactionBack>): MsgTransactionBack {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tsId = "";
        message.info = {};
        if (value !== undefined)
            reflectionMergePartial<MsgTransactionBack>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgTransactionBack): MsgTransactionBack {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tsId */ 1:
                    message.tsId = reader.string();
                    break;
                case /* map<string, string> info */ 2:
                    this.binaryReadMap2(message.info, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap2(map: MsgTransactionBack["info"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof MsgTransactionBack["info"] | undefined, val: MsgTransactionBack["info"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.match.matchhub.v1.MsgTransactionBack.info");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: MsgTransactionBack, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tsId = 1; */
        if (message.tsId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tsId);
        /* map<string, string> info = 2; */
        for (let k of globalThis.Object.keys(message.info))
            writer.tag(2, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.info[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgTransactionBack
 */
export const MsgTransactionBack = new MsgTransactionBack$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgPoolChange$Type extends MessageType<MsgPoolChange> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgPoolChange", [
            { no: 1, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<MsgPoolChange>): MsgPoolChange {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizePool = [];
        if (value !== undefined)
            reflectionMergePartial<MsgPoolChange>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgPoolChange): MsgPoolChange {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem prizePool */ 1:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgPoolChange, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem prizePool = 1; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgPoolChange
 */
export const MsgPoolChange = new MsgPoolChange$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchMultipler$Type extends MessageType<MsgMatchMultipler> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgMatchMultipler", [
            { no: 1, name: "multipler", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "leftDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchMultipler>): MsgMatchMultipler {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.multipler = 0;
        message.leftDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchMultipler>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchMultipler): MsgMatchMultipler {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 multipler */ 1:
                    message.multipler = reader.uint32();
                    break;
                case /* int64 leftDuration */ 2:
                    message.leftDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchMultipler, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 multipler = 1; */
        if (message.multipler !== 0)
            writer.tag(1, WireType.Varint).uint32(message.multipler);
        /* int64 leftDuration = 2; */
        if (message.leftDuration !== 0)
            writer.tag(2, WireType.Varint).int64(message.leftDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgMatchMultipler
 */
export const MsgMatchMultipler = new MsgMatchMultipler$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchRecord$Type extends MessageType<MatchRecord> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchRecord", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "MatchConfId", kind: "scalar", localName: "MatchConfId", jsonName: "MatchConfId", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "Name", kind: "scalar", localName: "Name", jsonName: "Name", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchRecordState", MatchRecordState] },
            { no: 5, name: "userPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPrize },
            { no: 6, name: "userTsFails", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserTsFail },
            { no: 7, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 12, name: "createAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "tsInfo", kind: "message", T: () => MatchRecordTsInfo },
            { no: 15, name: "StartAt", kind: "scalar", localName: "StartAt", jsonName: "StartAt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "pauseDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MatchRecord>): MatchRecord {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.MatchConfId = 0;
        message.Name = "";
        message.state = 0;
        message.userPrizes = [];
        message.userTsFails = [];
        message.matchType = 0;
        message.createAt = 0;
        message.updateAt = 0;
        message.StartAt = 0;
        message.tag = "";
        message.srvID = 0;
        message.pauseDeadline = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchRecord): MatchRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                case /* int64 MatchConfId = 2 [json_name = "MatchConfId"];*/ 2:
                    message.MatchConfId = reader.int64().toNumber();
                    break;
                case /* string Name = 3 [json_name = "Name"];*/ 3:
                    message.Name = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.MatchRecordState state */ 4:
                    message.state = reader.int32();
                    break;
                case /* repeated bpt.match.matchhub.v1.UserPrize userPrizes */ 5:
                    message.userPrizes.push(UserPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchhub.v1.UserTsFail userTsFails */ 6:
                    message.userTsFails.push(UserTsFail.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 7:
                    message.matchType = reader.int32();
                    break;
                case /* int64 createAt */ 12:
                    message.createAt = reader.int64().toNumber();
                    break;
                case /* int64 updateAt */ 13:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchRecordTsInfo tsInfo */ 14:
                    message.tsInfo = MatchRecordTsInfo.internalBinaryRead(reader, reader.uint32(), options, message.tsInfo);
                    break;
                case /* int64 StartAt = 15 [json_name = "StartAt"];*/ 15:
                    message.StartAt = reader.int64().toNumber();
                    break;
                case /* string tag */ 16:
                    message.tag = reader.string();
                    break;
                case /* int64 srvID */ 17:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* int64 pauseDeadline */ 18:
                    message.pauseDeadline = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        /* int64 MatchConfId = 2 [json_name = "MatchConfId"]; */
        if (message.MatchConfId !== 0)
            writer.tag(2, WireType.Varint).int64(message.MatchConfId);
        /* string Name = 3 [json_name = "Name"]; */
        if (message.Name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.Name);
        /* bpt.match.matchhub.v1.MatchRecordState state = 4; */
        if (message.state !== 0)
            writer.tag(4, WireType.Varint).int32(message.state);
        /* repeated bpt.match.matchhub.v1.UserPrize userPrizes = 5; */
        for (let i = 0; i < message.userPrizes.length; i++)
            UserPrize.internalBinaryWrite(message.userPrizes[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.UserTsFail userTsFails = 6; */
        for (let i = 0; i < message.userTsFails.length; i++)
            UserTsFail.internalBinaryWrite(message.userTsFails[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchType matchType = 7; */
        if (message.matchType !== 0)
            writer.tag(7, WireType.Varint).int32(message.matchType);
        /* int64 createAt = 12; */
        if (message.createAt !== 0)
            writer.tag(12, WireType.Varint).int64(message.createAt);
        /* int64 updateAt = 13; */
        if (message.updateAt !== 0)
            writer.tag(13, WireType.Varint).int64(message.updateAt);
        /* bpt.match.matchhub.v1.MatchRecordTsInfo tsInfo = 14; */
        if (message.tsInfo)
            MatchRecordTsInfo.internalBinaryWrite(message.tsInfo, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 StartAt = 15 [json_name = "StartAt"]; */
        if (message.StartAt !== 0)
            writer.tag(15, WireType.Varint).int64(message.StartAt);
        /* string tag = 16; */
        if (message.tag !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.tag);
        /* int64 srvID = 17; */
        if (message.srvID !== 0)
            writer.tag(17, WireType.Varint).int64(message.srvID);
        /* int64 pauseDeadline = 18; */
        if (message.pauseDeadline !== 0)
            writer.tag(18, WireType.Varint).int64(message.pauseDeadline);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchRecord
 */
export const MatchRecord = new MatchRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchRecordTsInfo$Type extends MessageType<MatchRecordTsInfo> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchRecordTsInfo", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchRecordTsInfo>): MatchRecordTsInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<MatchRecordTsInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchRecordTsInfo): MatchRecordTsInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchRecordTsInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchRecordTsInfo
 */
export const MatchRecordTsInfo = new MatchRecordTsInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserPrize$Type extends MessageType<UserPrize> {
    constructor() {
        super("bpt.match.matchhub.v1.UserPrize", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "prizeRatio", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "prizeToken", kind: "message", T: () => PrizeToken },
            { no: 5, name: "prizeTokens", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrizeToken }
        ]);
    }
    create(value?: PartialMessage<UserPrize>): UserPrize {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.rank = 0;
        message.prizeRatio = 0;
        message.prizeTokens = [];
        if (value !== undefined)
            reflectionMergePartial<UserPrize>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserPrize): UserPrize {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint32 rank */ 2:
                    message.rank = reader.uint32();
                    break;
                case /* uint32 prizeRatio */ 3:
                    message.prizeRatio = reader.uint32();
                    break;
                case /* bpt.match.matchhub.v1.PrizeToken prizeToken */ 4:
                    message.prizeToken = PrizeToken.internalBinaryRead(reader, reader.uint32(), options, message.prizeToken);
                    break;
                case /* repeated bpt.match.matchhub.v1.PrizeToken prizeTokens */ 5:
                    message.prizeTokens.push(PrizeToken.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserPrize, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).uint32(message.rank);
        /* uint32 prizeRatio = 3; */
        if (message.prizeRatio !== 0)
            writer.tag(3, WireType.Varint).uint32(message.prizeRatio);
        /* bpt.match.matchhub.v1.PrizeToken prizeToken = 4; */
        if (message.prizeToken)
            PrizeToken.internalBinaryWrite(message.prizeToken, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.PrizeToken prizeTokens = 5; */
        for (let i = 0; i < message.prizeTokens.length; i++)
            PrizeToken.internalBinaryWrite(message.prizeTokens[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserPrize
 */
export const UserPrize = new UserPrize$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserTsFail$Type extends MessageType<UserTsFail> {
    constructor() {
        super("bpt.match.matchhub.v1.UserTsFail", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.match.matchhub.v1.UserTsFailType", UserTsFailType] },
            { no: 3, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserTsFail>): UserTsFail {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.type = 0;
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<UserTsFail>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserTsFail): UserTsFail {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.UserTsFailType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* string tsId */ 3:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserTsFail, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* bpt.match.matchhub.v1.UserTsFailType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* string tsId = 3; */
        if (message.tsId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserTsFail
 */
export const UserTsFail = new UserTsFail$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrizeToken$Type extends MessageType<PrizeToken> {
    constructor() {
        super("bpt.match.matchhub.v1.PrizeToken", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prizeToken", kind: "message", T: () => Token },
            { no: 3, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<PrizeToken>): PrizeToken {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.propId = 0;
        if (value !== undefined)
            reflectionMergePartial<PrizeToken>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrizeToken): PrizeToken {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* bpt.match.matchlink.v1.Token prizeToken */ 2:
                    message.prizeToken = Token.internalBinaryRead(reader, reader.uint32(), options, message.prizeToken);
                    break;
                case /* int64 propId */ 3:
                    message.propId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrizeToken, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* bpt.match.matchlink.v1.Token prizeToken = 2; */
        if (message.prizeToken)
            Token.internalBinaryWrite(message.prizeToken, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 propId = 3; */
        if (message.propId !== 0)
            writer.tag(3, WireType.Varint).int64(message.propId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.PrizeToken
 */
export const PrizeToken = new PrizeToken$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserManage$Type extends MessageType<UserManage> {
    constructor() {
        super("bpt.match.matchhub.v1.UserManage", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "userNickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "participateStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.ParticipateStatus", ParticipateStatus] },
            { no: 10, name: "auditStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AuditStatus", AuditStatus] },
            { no: 11, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "createAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 13, name: "updateAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "rank", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "score", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "awardStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AwardStatus", AwardStatus] },
            { no: 17, name: "joinStatus", kind: "enum", T: () => ["bpt.match.common.v1.JoinStatus", JoinStatus] }
        ]);
    }
    create(value?: PartialMessage<UserManage>): UserManage {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.uid = 0;
        message.userNickname = "";
        message.matchId = 0;
        message.matchName = "";
        message.matchKey = "";
        message.participateStatus = 0;
        message.auditStatus = 0;
        message.operator = "";
        message.createAt = 0;
        message.updateAt = 0;
        message.rank = 0;
        message.score = 0;
        message.awardStatus = 0;
        message.joinStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<UserManage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserManage): UserManage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string userNickname */ 3:
                    message.userNickname = reader.string();
                    break;
                case /* int64 matchId */ 4:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* string matchName */ 5:
                    message.matchName = reader.string();
                    break;
                case /* string matchKey */ 6:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.ParticipateStatus participateStatus */ 9:
                    message.participateStatus = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.AuditStatus auditStatus */ 10:
                    message.auditStatus = reader.int32();
                    break;
                case /* string operator */ 11:
                    message.operator = reader.string();
                    break;
                case /* int64 createAt */ 12:
                    message.createAt = reader.int64().toNumber();
                    break;
                case /* int64 updateAt */ 13:
                    message.updateAt = reader.int64().toNumber();
                    break;
                case /* int64 rank */ 14:
                    message.rank = reader.int64().toNumber();
                    break;
                case /* int64 score */ 15:
                    message.score = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.AwardStatus awardStatus */ 16:
                    message.awardStatus = reader.int32();
                    break;
                case /* bpt.match.common.v1.JoinStatus joinStatus */ 17:
                    message.joinStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserManage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* string userNickname = 3; */
        if (message.userNickname !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userNickname);
        /* int64 matchId = 4; */
        if (message.matchId !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchId);
        /* string matchName = 5; */
        if (message.matchName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchName);
        /* string matchKey = 6; */
        if (message.matchKey !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.matchhub.v1.ParticipateStatus participateStatus = 9; */
        if (message.participateStatus !== 0)
            writer.tag(9, WireType.Varint).int32(message.participateStatus);
        /* bpt.match.matchhub.v1.AuditStatus auditStatus = 10; */
        if (message.auditStatus !== 0)
            writer.tag(10, WireType.Varint).int32(message.auditStatus);
        /* string operator = 11; */
        if (message.operator !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.operator);
        /* int64 createAt = 12; */
        if (message.createAt !== 0)
            writer.tag(12, WireType.Varint).int64(message.createAt);
        /* int64 updateAt = 13; */
        if (message.updateAt !== 0)
            writer.tag(13, WireType.Varint).int64(message.updateAt);
        /* int64 rank = 14; */
        if (message.rank !== 0)
            writer.tag(14, WireType.Varint).int64(message.rank);
        /* int64 score = 15; */
        if (message.score !== 0)
            writer.tag(15, WireType.Varint).int64(message.score);
        /* bpt.match.matchhub.v1.AwardStatus awardStatus = 16; */
        if (message.awardStatus !== 0)
            writer.tag(16, WireType.Varint).int32(message.awardStatus);
        /* bpt.match.common.v1.JoinStatus joinStatus = 17; */
        if (message.joinStatus !== 0)
            writer.tag(17, WireType.Varint).int32(message.joinStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserManage
 */
export const UserManage = new UserManage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfReq$Type extends MessageType<SaveMatchConfReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfReq", [
            { no: 1, name: "conf", kind: "message", T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfReq>): SaveMatchConfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfReq): SaveMatchConfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchConf conf */ 1:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchConf conf = 1; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfReq
 */
export const SaveMatchConfReq = new SaveMatchConfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfResp$Type extends MessageType<SaveMatchConfResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfResp", [
            { no: 1, name: "conf", kind: "message", T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfResp>): SaveMatchConfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfResp): SaveMatchConfResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchConf conf */ 1:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchConf conf = 1; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfResp
 */
export const SaveMatchConfResp = new SaveMatchConfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReuseMatchConfReq$Type extends MessageType<ReuseMatchConfReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ReuseMatchConfReq", [
            { no: 1, name: "conf", kind: "message", T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<ReuseMatchConfReq>): ReuseMatchConfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReuseMatchConfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReuseMatchConfReq): ReuseMatchConfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchConf conf */ 1:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReuseMatchConfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchConf conf = 1; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ReuseMatchConfReq
 */
export const ReuseMatchConfReq = new ReuseMatchConfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReuseMatchConfResp$Type extends MessageType<ReuseMatchConfResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ReuseMatchConfResp", [
            { no: 1, name: "conf", kind: "message", T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<ReuseMatchConfResp>): ReuseMatchConfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ReuseMatchConfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReuseMatchConfResp): ReuseMatchConfResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchConf conf */ 1:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReuseMatchConfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchConf conf = 1; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ReuseMatchConfResp
 */
export const ReuseMatchConfResp = new ReuseMatchConfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfReq$Type extends MessageType<DeleteMatchConfReq> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchConfReq>): DeleteMatchConfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfReq): DeleteMatchConfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string operator */ 2:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchConfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string operator = 2; */
        if (message.operator !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfReq
 */
export const DeleteMatchConfReq = new DeleteMatchConfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfResp$Type extends MessageType<DeleteMatchConfResp> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfResp", []);
    }
    create(value?: PartialMessage<DeleteMatchConfResp>): DeleteMatchConfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfResp): DeleteMatchConfResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteMatchConfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfResp
 */
export const DeleteMatchConfResp = new DeleteMatchConfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMatchConfStateReq$Type extends MessageType<UpdateMatchConfStateReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateMatchConfStateReq", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.State", State] },
            { no: 2, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMatchConfStateReq>): UpdateMatchConfStateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        message.id = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateMatchConfStateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMatchConfStateReq): UpdateMatchConfStateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.State state */ 1:
                    message.state = reader.int32();
                    break;
                case /* int64 id */ 2:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMatchConfStateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.State state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* int64 id = 2; */
        if (message.id !== 0)
            writer.tag(2, WireType.Varint).int64(message.id);
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateMatchConfStateReq
 */
export const UpdateMatchConfStateReq = new UpdateMatchConfStateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMatchConfStateResp$Type extends MessageType<UpdateMatchConfStateResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateMatchConfStateResp", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMatchConfStateResp>): UpdateMatchConfStateResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateMatchConfStateResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMatchConfStateResp): UpdateMatchConfStateResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMatchConfStateResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateMatchConfStateResp
 */
export const UpdateMatchConfStateResp = new UpdateMatchConfStateResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfReq$Type extends MessageType<GetMatchConfReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfReq>): GetMatchConfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfReq): GetMatchConfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfReq
 */
export const GetMatchConfReq = new GetMatchConfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfResp$Type extends MessageType<GetMatchConfResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfResp", [
            { no: 1, name: "conf", kind: "message", T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfResp>): GetMatchConfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfResp): GetMatchConfResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchConf conf */ 1:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchConf conf = 1; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfResp
 */
export const GetMatchConfResp = new GetMatchConfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchConfReq$Type extends MessageType<BatchGetMatchConfReq> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetMatchConfReq", [
            { no: 1, name: "id", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchConfReq>): BatchGetMatchConfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchConfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchConfReq): BatchGetMatchConfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 id */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.id.push(reader.int64().toNumber());
                    else
                        message.id.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchConfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 id = 1; */
        if (message.id.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.id.length; i++)
                writer.int64(message.id[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetMatchConfReq
 */
export const BatchGetMatchConfReq = new BatchGetMatchConfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchConfResp$Type extends MessageType<BatchGetMatchConfResp> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetMatchConfResp", [
            { no: 1, name: "conf", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchConfResp>): BatchGetMatchConfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.conf = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchConfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchConfResp): BatchGetMatchConfResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.MatchConf conf */ 1:
                    message.conf.push(MatchConf.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchConfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.MatchConf conf = 1; */
        for (let i = 0; i < message.conf.length; i++)
            MatchConf.internalBinaryWrite(message.conf[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetMatchConfResp
 */
export const BatchGetMatchConfResp = new BatchGetMatchConfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfByKeyReq$Type extends MessageType<ListMatchConfByKeyReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfByKeyReq", [
            { no: 1, name: "matchKeys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfByKeyReq>): ListMatchConfByKeyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKeys = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfByKeyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfByKeyReq): ListMatchConfByKeyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string matchKeys */ 1:
                    message.matchKeys.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfByKeyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string matchKeys = 1; */
        for (let i = 0; i < message.matchKeys.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.matchKeys[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfByKeyReq
 */
export const ListMatchConfByKeyReq = new ListMatchConfByKeyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfByKeyResp$Type extends MessageType<ListMatchConfByKeyResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfByKeyResp", [
            { no: 1, name: "matchConfs", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => MatchConf } }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfByKeyResp>): ListMatchConfByKeyResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfs = {};
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfByKeyResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfByKeyResp): ListMatchConfByKeyResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, bpt.match.common.v1.MatchConf> matchConfs */ 1:
                    this.binaryReadMap1(message.matchConfs, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: ListMatchConfByKeyResp["matchConfs"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ListMatchConfByKeyResp["matchConfs"] | undefined, val: ListMatchConfByKeyResp["matchConfs"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = MatchConf.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.match.matchhub.v1.ListMatchConfByKeyResp.matchConfs");
            }
        }
        map[key ?? ""] = val ?? MatchConf.create();
    }
    internalBinaryWrite(message: ListMatchConfByKeyResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, bpt.match.common.v1.MatchConf> matchConfs = 1; */
        for (let k of globalThis.Object.keys(message.matchConfs)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            MatchConf.internalBinaryWrite(message.matchConfs[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfByKeyResp
 */
export const ListMatchConfByKeyResp = new ListMatchConfByKeyResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConfByIdsReq$Type extends MessageType<GetConfByIdsReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetConfByIdsReq", [
            { no: 1, name: "ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetConfByIdsReq>): GetConfByIdsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ids = [];
        if (value !== undefined)
            reflectionMergePartial<GetConfByIdsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConfByIdsReq): GetConfByIdsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 ids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.ids.push(reader.int64().toNumber());
                    else
                        message.ids.push(reader.int64().toNumber());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConfByIdsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 ids = 1; */
        if (message.ids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.ids.length; i++)
                writer.int64(message.ids[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetConfByIdsReq
 */
export const GetConfByIdsReq = new GetConfByIdsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetConfByIdsResp$Type extends MessageType<GetConfByIdsResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetConfByIdsResp", [
            { no: 1, name: "info", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ConfInfoExt }
        ]);
    }
    create(value?: PartialMessage<GetConfByIdsResp>): GetConfByIdsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.info = [];
        if (value !== undefined)
            reflectionMergePartial<GetConfByIdsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetConfByIdsResp): GetConfByIdsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.ConfInfoExt info */ 1:
                    message.info.push(ConfInfoExt.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetConfByIdsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.ConfInfoExt info = 1; */
        for (let i = 0; i < message.info.length; i++)
            ConfInfoExt.internalBinaryWrite(message.info[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetConfByIdsResp
 */
export const GetConfByIdsResp = new GetConfByIdsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfReq$Type extends MessageType<ListMatchConfReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfReq", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.State", State] },
            { no: 6, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 7, name: "reviewState", kind: "enum", T: () => ["bpt.match.common.v1.ReviewState", ReviewState] },
            { no: 8, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfReq>): ListMatchConfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.id = 0;
        message.name = "";
        message.state = 0;
        message.matchType = 0;
        message.reviewState = 0;
        message.tag = "";
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfReq): ListMatchConfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* int64 id */ 3:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* bpt.match.common.v1.State state */ 5:
                    message.state = reader.int32();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 6:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.match.common.v1.ReviewState reviewState */ 7:
                    message.reviewState = reader.int32();
                    break;
                case /* string tag */ 8:
                    message.tag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* int64 id = 3; */
        if (message.id !== 0)
            writer.tag(3, WireType.Varint).int64(message.id);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* bpt.match.common.v1.State state = 5; */
        if (message.state !== 0)
            writer.tag(5, WireType.Varint).int32(message.state);
        /* bpt.match.common.v1.MatchType matchType = 6; */
        if (message.matchType !== 0)
            writer.tag(6, WireType.Varint).int32(message.matchType);
        /* bpt.match.common.v1.ReviewState reviewState = 7; */
        if (message.reviewState !== 0)
            writer.tag(7, WireType.Varint).int32(message.reviewState);
        /* string tag = 8; */
        if (message.tag !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.tag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfReq
 */
export const ListMatchConfReq = new ListMatchConfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfResp$Type extends MessageType<ListMatchConfResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfResp", [
            { no: 1, name: "confs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchConf },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfResp>): ListMatchConfResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.confs = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfResp): ListMatchConfResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.MatchConf confs */ 1:
                    message.confs.push(MatchConf.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.MatchConf confs = 1; */
        for (let i = 0; i < message.confs.length; i++)
            MatchConf.internalBinaryWrite(message.confs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfResp
 */
export const ListMatchConfResp = new ListMatchConfResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfTmplReq$Type extends MessageType<SaveMatchConfTmplReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfTmplReq", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmpl },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfTmplReq>): SaveMatchConfTmplReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfTmplReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfTmplReq): SaveMatchConfTmplReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmpl tmpl */ 1:
                    message.tmpl = MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfTmplReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1; */
        if (message.tmpl)
            MatchConfTmpl.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfTmplReq
 */
export const SaveMatchConfTmplReq = new SaveMatchConfTmplReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfTmplResp$Type extends MessageType<SaveMatchConfTmplResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfTmplResp", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmpl }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfTmplResp>): SaveMatchConfTmplResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfTmplResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfTmplResp): SaveMatchConfTmplResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmpl tmpl */ 1:
                    message.tmpl = MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfTmplResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1; */
        if (message.tmpl)
            MatchConfTmpl.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfTmplResp
 */
export const SaveMatchConfTmplResp = new SaveMatchConfTmplResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfTmplReq$Type extends MessageType<GetMatchConfTmplReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfTmplReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfTmplReq>): GetMatchConfTmplReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfTmplReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfTmplReq): GetMatchConfTmplReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfTmplReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfTmplReq
 */
export const GetMatchConfTmplReq = new GetMatchConfTmplReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfTmplResp$Type extends MessageType<GetMatchConfTmplResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfTmplResp", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmpl }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfTmplResp>): GetMatchConfTmplResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfTmplResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfTmplResp): GetMatchConfTmplResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmpl tmpl */ 1:
                    message.tmpl = MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfTmplResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1; */
        if (message.tmpl)
            MatchConfTmpl.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfTmplResp
 */
export const GetMatchConfTmplResp = new GetMatchConfTmplResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfTmplReq$Type extends MessageType<DeleteMatchConfTmplReq> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfTmplReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchConfTmplReq>): DeleteMatchConfTmplReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfTmplReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfTmplReq): DeleteMatchConfTmplReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchConfTmplReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfTmplReq
 */
export const DeleteMatchConfTmplReq = new DeleteMatchConfTmplReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfTmplReq$Type extends MessageType<ListMatchConfTmplReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfTmplReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchType", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchConfTmplType", MatchConfTmplType] }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfTmplReq>): ListMatchConfTmplReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfTmplReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfTmplReq): ListMatchConfTmplReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchConfTmplType matchType */ 3:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfTmplReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bpt.match.matchhub.v1.MatchConfTmplType matchType = 3; */
        if (message.matchType !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfTmplReq
 */
export const ListMatchConfTmplReq = new ListMatchConfTmplReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfTmplResp$Type extends MessageType<ListMatchConfTmplResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfTmplResp", [
            { no: 1, name: "tmpls", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchConfTmpl },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfTmplResp>): ListMatchConfTmplResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tmpls = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfTmplResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfTmplResp): ListMatchConfTmplResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchConfTmpl tmpls */ 1:
                    message.tmpls.push(MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfTmplResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchConfTmpl tmpls = 1; */
        for (let i = 0; i < message.tmpls.length; i++)
            MatchConfTmpl.internalBinaryWrite(message.tmpls[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfTmplResp
 */
export const ListMatchConfTmplResp = new ListMatchConfTmplResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchRecordReq$Type extends MessageType<ListMatchRecordReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchRecordReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "confId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "states", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.MatchRecordState", MatchRecordState] }
        ]);
    }
    create(value?: PartialMessage<ListMatchRecordReq>): ListMatchRecordReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.confId = 0;
        message.states = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchRecordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchRecordReq): ListMatchRecordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 confId */ 3:
                    message.confId = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchhub.v1.MatchRecordState states */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.states.push(reader.int32());
                    else
                        message.states.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchRecordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 confId = 3; */
        if (message.confId !== 0)
            writer.tag(3, WireType.Varint).int64(message.confId);
        /* repeated bpt.match.matchhub.v1.MatchRecordState states = 5; */
        if (message.states.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.states.length; i++)
                writer.int32(message.states[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchRecordReq
 */
export const ListMatchRecordReq = new ListMatchRecordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchRecordResp$Type extends MessageType<ListMatchRecordResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchRecordResp", [
            { no: 1, name: "matchRecords", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchRecord },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchRecordResp>): ListMatchRecordResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchRecords = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchRecordResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchRecordResp): ListMatchRecordResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchRecord matchRecords */ 1:
                    message.matchRecords.push(MatchRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchRecordResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchRecord matchRecords = 1; */
        for (let i = 0; i < message.matchRecords.length; i++)
            MatchRecord.internalBinaryWrite(message.matchRecords[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchRecordResp
 */
export const ListMatchRecordResp = new ListMatchRecordResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetRunningMatchReq$Type extends MessageType<BatchGetRunningMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetRunningMatchReq", [
            { no: 1, name: "latestTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetRunningMatchReq>): BatchGetRunningMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.latestTime = 0;
        if (value !== undefined)
            reflectionMergePartial<BatchGetRunningMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetRunningMatchReq): BatchGetRunningMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 latestTime */ 1:
                    message.latestTime = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetRunningMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 latestTime = 1; */
        if (message.latestTime !== 0)
            writer.tag(1, WireType.Varint).int64(message.latestTime);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetRunningMatchReq
 */
export const BatchGetRunningMatchReq = new BatchGetRunningMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetRunningMatchResp$Type extends MessageType<BatchGetRunningMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetRunningMatchResp", [
            { no: 1, name: "matchRecords", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchRecord }
        ]);
    }
    create(value?: PartialMessage<BatchGetRunningMatchResp>): BatchGetRunningMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchRecords = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetRunningMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetRunningMatchResp): BatchGetRunningMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchRecord matchRecords */ 1:
                    message.matchRecords.push(MatchRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetRunningMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchRecord matchRecords = 1; */
        for (let i = 0; i < message.matchRecords.length; i++)
            MatchRecord.internalBinaryWrite(message.matchRecords[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetRunningMatchResp
 */
export const BatchGetRunningMatchResp = new BatchGetRunningMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchRecordReq$Type extends MessageType<GetMatchRecordReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchRecordReq", [
            { no: 1, name: "recordId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchRecordReq>): GetMatchRecordReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.recordId = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchRecordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchRecordReq): GetMatchRecordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string recordId */ 1:
                    message.recordId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchRecordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string recordId = 1; */
        if (message.recordId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.recordId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchRecordReq
 */
export const GetMatchRecordReq = new GetMatchRecordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchRecordResp$Type extends MessageType<GetMatchRecordResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchRecordResp", [
            { no: 1, name: "matchRecord", kind: "message", T: () => MatchRecord }
        ]);
    }
    create(value?: PartialMessage<GetMatchRecordResp>): GetMatchRecordResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchRecordResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchRecordResp): GetMatchRecordResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchRecord matchRecord */ 1:
                    message.matchRecord = MatchRecord.internalBinaryRead(reader, reader.uint32(), options, message.matchRecord);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchRecordResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchRecord matchRecord = 1; */
        if (message.matchRecord)
            MatchRecord.internalBinaryWrite(message.matchRecord, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchRecordResp
 */
export const GetMatchRecordResp = new GetMatchRecordResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMatchRecordStateReq$Type extends MessageType<UpdateMatchRecordStateReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateMatchRecordStateReq", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchRecordState", MatchRecordState] },
            { no: 2, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMatchRecordStateReq>): UpdateMatchRecordStateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        message.id = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateMatchRecordStateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMatchRecordStateReq): UpdateMatchRecordStateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchRecordState state */ 1:
                    message.state = reader.int32();
                    break;
                case /* int64 id */ 2:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMatchRecordStateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchRecordState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* int64 id = 2; */
        if (message.id !== 0)
            writer.tag(2, WireType.Varint).int64(message.id);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateMatchRecordStateReq
 */
export const UpdateMatchRecordStateReq = new UpdateMatchRecordStateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMatchRecordStateResp$Type extends MessageType<UpdateMatchRecordStateResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateMatchRecordStateResp", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateMatchRecordStateResp>): UpdateMatchRecordStateResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateMatchRecordStateResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMatchRecordStateResp): UpdateMatchRecordStateResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMatchRecordStateResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateMatchRecordStateResp
 */
export const UpdateMatchRecordStateResp = new UpdateMatchRecordStateResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMatchRecordConfReq$Type extends MessageType<UpdateMatchRecordConfReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateMatchRecordConfReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "conf", kind: "message", T: () => MatchConf }
        ]);
    }
    create(value?: PartialMessage<UpdateMatchRecordConfReq>): UpdateMatchRecordConfReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateMatchRecordConfReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMatchRecordConfReq): UpdateMatchRecordConfReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchConf conf */ 2:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMatchRecordConfReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchConf conf = 2; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateMatchRecordConfReq
 */
export const UpdateMatchRecordConfReq = new UpdateMatchRecordConfReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ForceCancelMatchReq$Type extends MessageType<ForceCancelMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ForceCancelMatchReq", [
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ForceCancelMatchReq>): ForceCancelMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ForceCancelMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ForceCancelMatchReq): ForceCancelMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ForceCancelMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ForceCancelMatchReq
 */
export const ForceCancelMatchReq = new ForceCancelMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ForceCancelMatchResp$Type extends MessageType<ForceCancelMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ForceCancelMatchResp", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ForceCancelMatchResp>): ForceCancelMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<ForceCancelMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ForceCancelMatchResp): ForceCancelMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ForceCancelMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ForceCancelMatchResp
 */
export const ForceCancelMatchResp = new ForceCancelMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelMatchByUserReq$Type extends MessageType<CancelMatchByUserReq> {
    constructor() {
        super("bpt.match.matchhub.v1.CancelMatchByUserReq", [
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "mailId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "withdrawal", kind: "enum", T: () => ["bpt.match.matchhub.v1.Withdrawal", Withdrawal] },
            { no: 7, name: "reasonI18nKey", kind: "scalar", localName: "reasonI18nKey", jsonName: "reasonI18nKey", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelMatchByUserReq>): CancelMatchByUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.mailId = "";
        message.withdrawal = 0;
        message.reasonI18nKey = "";
        if (value !== undefined)
            reflectionMergePartial<CancelMatchByUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelMatchByUserReq): CancelMatchByUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* string mailId */ 4:
                    message.mailId = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.Withdrawal withdrawal */ 6:
                    message.withdrawal = reader.int32();
                    break;
                case /* string reasonI18nKey = 7 [json_name = "reasonI18nKey"];*/ 7:
                    message.reasonI18nKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelMatchByUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* string mailId = 4; */
        if (message.mailId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.mailId);
        /* bpt.match.matchhub.v1.Withdrawal withdrawal = 6; */
        if (message.withdrawal !== 0)
            writer.tag(6, WireType.Varint).int32(message.withdrawal);
        /* string reasonI18nKey = 7 [json_name = "reasonI18nKey"]; */
        if (message.reasonI18nKey !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.reasonI18nKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.CancelMatchByUserReq
 */
export const CancelMatchByUserReq = new CancelMatchByUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CancelMatchByUserResp$Type extends MessageType<CancelMatchByUserResp> {
    constructor() {
        super("bpt.match.matchhub.v1.CancelMatchByUserResp", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CancelMatchByUserResp>): CancelMatchByUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<CancelMatchByUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CancelMatchByUserResp): CancelMatchByUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CancelMatchByUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.CancelMatchByUserResp
 */
export const CancelMatchByUserResp = new CancelMatchByUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserApplyMatchReq$Type extends MessageType<UserApplyMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UserApplyMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UserApplyMatchReq>): UserApplyMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.matchId = 0;
        if (value !== undefined)
            reflectionMergePartial<UserApplyMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserApplyMatchReq): UserApplyMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* int64 matchId */ 3:
                    message.matchId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserApplyMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* int64 matchId = 3; */
        if (message.matchId !== 0)
            writer.tag(3, WireType.Varint).int64(message.matchId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserApplyMatchReq
 */
export const UserApplyMatchReq = new UserApplyMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserApplyMatchResp$Type extends MessageType<UserApplyMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UserApplyMatchResp", []);
    }
    create(value?: PartialMessage<UserApplyMatchResp>): UserApplyMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UserApplyMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserApplyMatchResp): UserApplyMatchResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UserApplyMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserApplyMatchResp
 */
export const UserApplyMatchResp = new UserApplyMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRegisterMatchReq$Type extends MessageType<UserRegisterMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UserRegisterMatchReq", [
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "registerWay", kind: "enum", T: () => ["bpt.match.common.v1.RegisterWay", RegisterWay] },
            { no: 7, name: "tickets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Ticket }
        ]);
    }
    create(value?: PartialMessage<UserRegisterMatchReq>): UserRegisterMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.registerWay = 0;
        message.tickets = [];
        if (value !== undefined)
            reflectionMergePartial<UserRegisterMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRegisterMatchReq): UserRegisterMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.RegisterWay registerWay */ 6:
                    message.registerWay = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.Ticket tickets */ 7:
                    message.tickets.push(Ticket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRegisterMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.RegisterWay registerWay = 6; */
        if (message.registerWay !== 0)
            writer.tag(6, WireType.Varint).int32(message.registerWay);
        /* repeated bpt.match.common.v1.Ticket tickets = 7; */
        for (let i = 0; i < message.tickets.length; i++)
            Ticket.internalBinaryWrite(message.tickets[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserRegisterMatchReq
 */
export const UserRegisterMatchReq = new UserRegisterMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRegisterMatchResp$Type extends MessageType<UserRegisterMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UserRegisterMatchResp", [
            { no: 1, name: "isOnChain", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserRegisterMatchResp>): UserRegisterMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isOnChain = false;
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<UserRegisterMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRegisterMatchResp): UserRegisterMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isOnChain */ 1:
                    message.isOnChain = reader.bool();
                    break;
                case /* bytes credential */ 2:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 3:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRegisterMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isOnChain = 1; */
        if (message.isOnChain !== false)
            writer.tag(1, WireType.Varint).bool(message.isOnChain);
        /* bytes credential = 2; */
        if (message.credential.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 3; */
        if (message.tsId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserRegisterMatchResp
 */
export const UserRegisterMatchResp = new UserRegisterMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserUnRegisterMatchReq$Type extends MessageType<UserUnRegisterMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UserUnRegisterMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "walletAddr", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "signature", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 6, name: "mailId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "txId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserUnRegisterMatchReq>): UserUnRegisterMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.walletAddr = "";
        message.signature = new Uint8Array(0);
        message.mailId = "";
        message.txId = "";
        if (value !== undefined)
            reflectionMergePartial<UserUnRegisterMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserUnRegisterMatchReq): UserUnRegisterMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string walletAddr */ 4:
                    message.walletAddr = reader.string();
                    break;
                case /* bytes signature */ 5:
                    message.signature = reader.bytes();
                    break;
                case /* string mailId */ 6:
                    message.mailId = reader.string();
                    break;
                case /* string txId */ 7:
                    message.txId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserUnRegisterMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string walletAddr = 4; */
        if (message.walletAddr !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.walletAddr);
        /* bytes signature = 5; */
        if (message.signature.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.signature);
        /* string mailId = 6; */
        if (message.mailId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mailId);
        /* string txId = 7; */
        if (message.txId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.txId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserUnRegisterMatchReq
 */
export const UserUnRegisterMatchReq = new UserUnRegisterMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserUnRegisterMatchResp$Type extends MessageType<UserUnRegisterMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UserUnRegisterMatchResp", [
            { no: 1, name: "isOnChain", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 3, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserUnRegisterMatchResp>): UserUnRegisterMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isOnChain = false;
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<UserUnRegisterMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserUnRegisterMatchResp): UserUnRegisterMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isOnChain */ 1:
                    message.isOnChain = reader.bool();
                    break;
                case /* bytes credential */ 2:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 3:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserUnRegisterMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isOnChain = 1; */
        if (message.isOnChain !== false)
            writer.tag(1, WireType.Varint).bool(message.isOnChain);
        /* bytes credential = 2; */
        if (message.credential.length)
            writer.tag(2, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 3; */
        if (message.tsId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserUnRegisterMatchResp
 */
export const UserUnRegisterMatchResp = new UserUnRegisterMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuditUserManageReq$Type extends MessageType<AuditUserManageReq> {
    constructor() {
        super("bpt.match.matchhub.v1.AuditUserManageReq", [
            { no: 1, name: "auditStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AuditStatus", AuditStatus] },
            { no: 2, name: "userManageId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuditUserManageReq>): AuditUserManageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.auditStatus = 0;
        message.userManageId = "";
        if (value !== undefined)
            reflectionMergePartial<AuditUserManageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuditUserManageReq): AuditUserManageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.AuditStatus auditStatus */ 1:
                    message.auditStatus = reader.int32();
                    break;
                case /* string userManageId */ 2:
                    message.userManageId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuditUserManageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.AuditStatus auditStatus = 1; */
        if (message.auditStatus !== 0)
            writer.tag(1, WireType.Varint).int32(message.auditStatus);
        /* string userManageId = 2; */
        if (message.userManageId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userManageId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AuditUserManageReq
 */
export const AuditUserManageReq = new AuditUserManageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuditUserManageResp$Type extends MessageType<AuditUserManageResp> {
    constructor() {
        super("bpt.match.matchhub.v1.AuditUserManageResp", []);
    }
    create(value?: PartialMessage<AuditUserManageResp>): AuditUserManageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AuditUserManageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuditUserManageResp): AuditUserManageResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AuditUserManageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AuditUserManageResp
 */
export const AuditUserManageResp = new AuditUserManageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserManageReq$Type extends MessageType<ListUserManageReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListUserManageReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "auditStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AuditStatus", AuditStatus] },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserManageReq>): ListUserManageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.auditStatus = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ListUserManageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserManageReq): ListUserManageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.AuditStatus auditStatus */ 3:
                    message.auditStatus = reader.int32();
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserManageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bpt.match.matchhub.v1.AuditStatus auditStatus = 3; */
        if (message.auditStatus !== 0)
            writer.tag(3, WireType.Varint).int32(message.auditStatus);
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListUserManageReq
 */
export const ListUserManageReq = new ListUserManageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserManageResp$Type extends MessageType<ListUserManageResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListUserManageResp", [
            { no: 1, name: "userManages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserManage },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserManageResp>): ListUserManageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userManages = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserManageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserManageResp): ListUserManageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.UserManage userManages */ 1:
                    message.userManages.push(UserManage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserManageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.UserManage userManages = 1; */
        for (let i = 0; i < message.userManages.length; i++)
            UserManage.internalBinaryWrite(message.userManages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListUserManageResp
 */
export const ListUserManageResp = new ListUserManageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserManageReq$Type extends MessageType<BatchGetUserManageReq> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetUserManageReq", [
            { no: 1, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserManageReq>): BatchGetUserManageReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uids = [];
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserManageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserManageReq): BatchGetUserManageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated int64 uids */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserManageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated int64 uids = 1; */
        if (message.uids.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetUserManageReq
 */
export const BatchGetUserManageReq = new BatchGetUserManageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetUserManageResp$Type extends MessageType<BatchGetUserManageResp> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetUserManageResp", [
            { no: 1, name: "userManages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserManage }
        ]);
    }
    create(value?: PartialMessage<BatchGetUserManageResp>): BatchGetUserManageResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userManages = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetUserManageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetUserManageResp): BatchGetUserManageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.UserManage userManages */ 1:
                    message.userManages.push(UserManage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetUserManageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.UserManage userManages = 1; */
        for (let i = 0; i < message.userManages.length; i++)
            UserManage.internalBinaryWrite(message.userManages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetUserManageResp
 */
export const BatchGetUserManageResp = new BatchGetUserManageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateUserManageStatusReq$Type extends MessageType<UpdateUserManageStatusReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateUserManageStatusReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "joinStatus", kind: "enum", T: () => ["bpt.match.common.v1.JoinStatus", JoinStatus] }
        ]);
    }
    create(value?: PartialMessage<UpdateUserManageStatusReq>): UpdateUserManageStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.joinStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateUserManageStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateUserManageStatusReq): UpdateUserManageStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.JoinStatus joinStatus */ 2:
                    message.joinStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateUserManageStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.JoinStatus joinStatus = 2; */
        if (message.joinStatus !== 0)
            writer.tag(2, WireType.Varint).int32(message.joinStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateUserManageStatusReq
 */
export const UpdateUserManageStatusReq = new UpdateUserManageStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminRegisterReq$Type extends MessageType<AdminRegisterReq> {
    constructor() {
        super("bpt.match.matchhub.v1.AdminRegisterReq", [
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "registerWay", kind: "enum", T: () => ["bpt.match.common.v1.RegisterWay", RegisterWay] },
            { no: 7, name: "tickets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Ticket },
            { no: 8, name: "uidStr", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AdminRegisterReq>): AdminRegisterReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.registerWay = 0;
        message.tickets = [];
        message.uidStr = [];
        if (value !== undefined)
            reflectionMergePartial<AdminRegisterReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminRegisterReq): AdminRegisterReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.RegisterWay registerWay */ 6:
                    message.registerWay = reader.int32();
                    break;
                case /* repeated bpt.match.common.v1.Ticket tickets */ 7:
                    message.tickets.push(Ticket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string uidStr */ 8:
                    message.uidStr.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminRegisterReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.RegisterWay registerWay = 6; */
        if (message.registerWay !== 0)
            writer.tag(6, WireType.Varint).int32(message.registerWay);
        /* repeated bpt.match.common.v1.Ticket tickets = 7; */
        for (let i = 0; i < message.tickets.length; i++)
            Ticket.internalBinaryWrite(message.tickets[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated string uidStr = 8; */
        for (let i = 0; i < message.uidStr.length; i++)
            writer.tag(8, WireType.LengthDelimited).string(message.uidStr[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AdminRegisterReq
 */
export const AdminRegisterReq = new AdminRegisterReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubmitAwardClaimFormReq$Type extends MessageType<SubmitAwardClaimFormReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SubmitAwardClaimFormReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "trueName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "homeAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "videoUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "addressMap", kind: "map", K: 3 /*ScalarType.INT64*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 8, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "phone", kind: "message", T: () => Phone }
        ]);
    }
    create(value?: PartialMessage<SubmitAwardClaimFormReq>): SubmitAwardClaimFormReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.trueName = "";
        message.homeAddress = "";
        message.videoUrl = "";
        message.addressMap = {};
        message.email = "";
        if (value !== undefined)
            reflectionMergePartial<SubmitAwardClaimFormReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubmitAwardClaimFormReq): SubmitAwardClaimFormReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string trueName */ 3:
                    message.trueName = reader.string();
                    break;
                case /* string homeAddress */ 4:
                    message.homeAddress = reader.string();
                    break;
                case /* string videoUrl */ 5:
                    message.videoUrl = reader.string();
                    break;
                case /* map<int64, string> addressMap */ 6:
                    this.binaryReadMap6(message.addressMap, reader, options);
                    break;
                case /* string email */ 8:
                    message.email = reader.string();
                    break;
                case /* mp.user.account.v1.Phone phone */ 9:
                    message.phone = Phone.internalBinaryRead(reader, reader.uint32(), options, message.phone);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: SubmitAwardClaimFormReq["addressMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof SubmitAwardClaimFormReq["addressMap"] | undefined, val: SubmitAwardClaimFormReq["addressMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int64().toString();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.match.matchhub.v1.SubmitAwardClaimFormReq.addressMap");
            }
        }
        map[key ?? "0"] = val ?? "";
    }
    internalBinaryWrite(message: SubmitAwardClaimFormReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string trueName = 3; */
        if (message.trueName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.trueName);
        /* string homeAddress = 4; */
        if (message.homeAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.homeAddress);
        /* string videoUrl = 5; */
        if (message.videoUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.videoUrl);
        /* map<int64, string> addressMap = 6; */
        for (let k of globalThis.Object.keys(message.addressMap))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int64(k).tag(2, WireType.LengthDelimited).string(message.addressMap[k]).join();
        /* string email = 8; */
        if (message.email !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.email);
        /* mp.user.account.v1.Phone phone = 9; */
        if (message.phone)
            Phone.internalBinaryWrite(message.phone, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SubmitAwardClaimFormReq
 */
export const SubmitAwardClaimFormReq = new SubmitAwardClaimFormReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OffChainPrize$Type extends MessageType<OffChainPrize> {
    constructor() {
        super("bpt.match.matchhub.v1.OffChainPrize", [
            { no: 1, name: "prizeItem", kind: "message", T: () => SimpleAsset$ },
            { no: 2, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OffChainPrize>): OffChainPrize {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<OffChainPrize>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OffChainPrize): OffChainPrize {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SimpleAsset prizeItem */ 1:
                    message.prizeItem = SimpleAsset$.internalBinaryRead(reader, reader.uint32(), options, message.prizeItem);
                    break;
                case /* string address */ 2:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OffChainPrize, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SimpleAsset prizeItem = 1; */
        if (message.prizeItem)
            SimpleAsset$.internalBinaryWrite(message.prizeItem, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string address = 2; */
        if (message.address !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.OffChainPrize
 */
export const OffChainPrize = new OffChainPrize$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AwardClaimForm$Type extends MessageType<AwardClaimForm> {
    constructor() {
        super("bpt.match.matchhub.v1.AwardClaimForm", [
            { no: 13, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "matchName", kind: "message", T: () => I18n },
            { no: 3, name: "trueName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "homeAddress", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "videoUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "addressMap", kind: "map", K: 3 /*ScalarType.INT64*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 14, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "phone", kind: "message", T: () => Phone },
            { no: 10, name: "remark", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "auditStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AuditStatus", AuditStatus] },
            { no: 16, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "submitAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "offChainPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OffChainPrize }
        ]);
    }
    create(value?: PartialMessage<AwardClaimForm>): AwardClaimForm {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.matchKey = "";
        message.trueName = "";
        message.uid = 0;
        message.homeAddress = "";
        message.videoUrl = "";
        message.addressMap = {};
        message.email = "";
        message.remark = "";
        message.auditStatus = 0;
        message.operator = "";
        message.submitAt = 0;
        message.offChainPrizes = [];
        if (value !== undefined)
            reflectionMergePartial<AwardClaimForm>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AwardClaimForm): AwardClaimForm {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 13 [json_name = "ID"];*/ 13:
                    message.ID = reader.string();
                    break;
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.common.I18n matchName */ 8:
                    message.matchName = I18n.internalBinaryRead(reader, reader.uint32(), options, message.matchName);
                    break;
                case /* string trueName */ 3:
                    message.trueName = reader.string();
                    break;
                case /* int64 uid */ 11:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string homeAddress */ 4:
                    message.homeAddress = reader.string();
                    break;
                case /* string videoUrl */ 5:
                    message.videoUrl = reader.string();
                    break;
                case /* map<int64, string> addressMap */ 6:
                    this.binaryReadMap6(message.addressMap, reader, options);
                    break;
                case /* string email */ 14:
                    message.email = reader.string();
                    break;
                case /* mp.user.account.v1.Phone phone */ 15:
                    message.phone = Phone.internalBinaryRead(reader, reader.uint32(), options, message.phone);
                    break;
                case /* string remark */ 10:
                    message.remark = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.AuditStatus auditStatus */ 12:
                    message.auditStatus = reader.int32();
                    break;
                case /* string operator */ 16:
                    message.operator = reader.string();
                    break;
                case /* int64 submitAt */ 17:
                    message.submitAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchhub.v1.OffChainPrize offChainPrizes */ 18:
                    message.offChainPrizes.push(OffChainPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: AwardClaimForm["addressMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AwardClaimForm["addressMap"] | undefined, val: AwardClaimForm["addressMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.int64().toString();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.match.matchhub.v1.AwardClaimForm.addressMap");
            }
        }
        map[key ?? "0"] = val ?? "";
    }
    internalBinaryWrite(message: AwardClaimForm, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 13 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.ID);
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.common.I18n matchName = 8; */
        if (message.matchName)
            I18n.internalBinaryWrite(message.matchName, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string trueName = 3; */
        if (message.trueName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.trueName);
        /* int64 uid = 11; */
        if (message.uid !== 0)
            writer.tag(11, WireType.Varint).int64(message.uid);
        /* string homeAddress = 4; */
        if (message.homeAddress !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.homeAddress);
        /* string videoUrl = 5; */
        if (message.videoUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.videoUrl);
        /* map<int64, string> addressMap = 6; */
        for (let k of globalThis.Object.keys(message.addressMap))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.Varint).int64(k).tag(2, WireType.LengthDelimited).string(message.addressMap[k]).join();
        /* string email = 14; */
        if (message.email !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.email);
        /* mp.user.account.v1.Phone phone = 15; */
        if (message.phone)
            Phone.internalBinaryWrite(message.phone, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* string remark = 10; */
        if (message.remark !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.remark);
        /* bpt.match.matchhub.v1.AuditStatus auditStatus = 12; */
        if (message.auditStatus !== 0)
            writer.tag(12, WireType.Varint).int32(message.auditStatus);
        /* string operator = 16; */
        if (message.operator !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.operator);
        /* int64 submitAt = 17; */
        if (message.submitAt !== 0)
            writer.tag(17, WireType.Varint).int64(message.submitAt);
        /* repeated bpt.match.matchhub.v1.OffChainPrize offChainPrizes = 18; */
        for (let i = 0; i < message.offChainPrizes.length; i++)
            OffChainPrize.internalBinaryWrite(message.offChainPrizes[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AwardClaimForm
 */
export const AwardClaimForm = new AwardClaimForm$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAwardClaimFormReq$Type extends MessageType<ListAwardClaimFormReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListAwardClaimFormReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "auditStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AuditStatus", AuditStatus] }
        ]);
    }
    create(value?: PartialMessage<ListAwardClaimFormReq>): ListAwardClaimFormReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchKey = "";
        message.uid = 0;
        message.auditStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<ListAwardClaimFormReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAwardClaimFormReq): ListAwardClaimFormReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* int64 uid */ 4:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.AuditStatus auditStatus */ 5:
                    message.auditStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAwardClaimFormReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* int64 uid = 4; */
        if (message.uid !== 0)
            writer.tag(4, WireType.Varint).int64(message.uid);
        /* bpt.match.matchhub.v1.AuditStatus auditStatus = 5; */
        if (message.auditStatus !== 0)
            writer.tag(5, WireType.Varint).int32(message.auditStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListAwardClaimFormReq
 */
export const ListAwardClaimFormReq = new ListAwardClaimFormReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListAwardClaimFormResp$Type extends MessageType<ListAwardClaimFormResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListAwardClaimFormResp", [
            { no: 1, name: "forms", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AwardClaimForm },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListAwardClaimFormResp>): ListAwardClaimFormResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.forms = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<ListAwardClaimFormResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListAwardClaimFormResp): ListAwardClaimFormResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.AwardClaimForm forms */ 1:
                    message.forms.push(AwardClaimForm.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListAwardClaimFormResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.AwardClaimForm forms = 1; */
        for (let i = 0; i < message.forms.length; i++)
            AwardClaimForm.internalBinaryWrite(message.forms[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListAwardClaimFormResp
 */
export const ListAwardClaimFormResp = new ListAwardClaimFormResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AuditAwardClaimFormReq$Type extends MessageType<AuditAwardClaimFormReq> {
    constructor() {
        super("bpt.match.matchhub.v1.AuditAwardClaimFormReq", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "remark", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "auditStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AuditStatus", AuditStatus] },
            { no: 4, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AuditAwardClaimFormReq>): AuditAwardClaimFormReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = "";
        message.remark = "";
        message.auditStatus = 0;
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<AuditAwardClaimFormReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AuditAwardClaimFormReq): AuditAwardClaimFormReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.string();
                    break;
                case /* string remark */ 2:
                    message.remark = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.AuditStatus auditStatus */ 3:
                    message.auditStatus = reader.int32();
                    break;
                case /* string operator */ 4:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AuditAwardClaimFormReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ID = 1 [json_name = "ID"]; */
        if (message.ID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ID);
        /* string remark = 2; */
        if (message.remark !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.remark);
        /* bpt.match.matchhub.v1.AuditStatus auditStatus = 3; */
        if (message.auditStatus !== 0)
            writer.tag(3, WireType.Varint).int32(message.auditStatus);
        /* string operator = 4; */
        if (message.operator !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AuditAwardClaimFormReq
 */
export const AuditAwardClaimFormReq = new AuditAwardClaimFormReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuickStartReq$Type extends MessageType<QuickStartReq> {
    constructor() {
        super("bpt.match.matchhub.v1.QuickStartReq", [
            { no: 1, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 2, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<QuickStartReq>): QuickStartReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchType = 0;
        message.tag = "";
        if (value !== undefined)
            reflectionMergePartial<QuickStartReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuickStartReq): QuickStartReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchType matchType */ 1:
                    message.matchType = reader.int32();
                    break;
                case /* string tag */ 2:
                    message.tag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QuickStartReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchType matchType = 1; */
        if (message.matchType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchType);
        /* string tag = 2; */
        if (message.tag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.QuickStartReq
 */
export const QuickStartReq = new QuickStartReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class QuickStartResp$Type extends MessageType<QuickStartResp> {
    constructor() {
        super("bpt.match.matchhub.v1.QuickStartResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 4, name: "lowerLimitChips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "seatNo", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<QuickStartResp>): QuickStartResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.tableKey = "";
        message.code = 0;
        message.lowerLimitChips = 0;
        message.seatNo = 0;
        if (value !== undefined)
            reflectionMergePartial<QuickStartResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: QuickStartResp): QuickStartResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string tableKey */ 2:
                    message.tableKey = reader.string();
                    break;
                case /* bpt.common.code.ErrorCode code */ 3:
                    message.code = reader.int32();
                    break;
                case /* uint64 lowerLimitChips */ 4:
                    message.lowerLimitChips = reader.uint64().toNumber();
                    break;
                case /* int32 seatNo */ 5:
                    message.seatNo = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: QuickStartResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string tableKey = 2; */
        if (message.tableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tableKey);
        /* bpt.common.code.ErrorCode code = 3; */
        if (message.code !== 0)
            writer.tag(3, WireType.Varint).int32(message.code);
        /* uint64 lowerLimitChips = 4; */
        if (message.lowerLimitChips !== 0)
            writer.tag(4, WireType.Varint).uint64(message.lowerLimitChips);
        /* int32 seatNo = 5; */
        if (message.seatNo !== 0)
            writer.tag(5, WireType.Varint).int32(message.seatNo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.QuickStartResp
 */
export const QuickStartResp = new QuickStartResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeDeskReq$Type extends MessageType<ChangeDeskReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ChangeDeskReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 4, name: "lowerLimitChips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "userAssetNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ChangeDeskReq>): ChangeDeskReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.tableKey = "";
        message.matchType = 0;
        message.lowerLimitChips = 0;
        message.userAssetNum = 0;
        if (value !== undefined)
            reflectionMergePartial<ChangeDeskReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeDeskReq): ChangeDeskReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string tableKey */ 2:
                    message.tableKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 3:
                    message.matchType = reader.int32();
                    break;
                case /* uint64 lowerLimitChips */ 4:
                    message.lowerLimitChips = reader.uint64().toNumber();
                    break;
                case /* int64 userAssetNum */ 5:
                    message.userAssetNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChangeDeskReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string tableKey = 2; */
        if (message.tableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tableKey);
        /* bpt.match.common.v1.MatchType matchType = 3; */
        if (message.matchType !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchType);
        /* uint64 lowerLimitChips = 4; */
        if (message.lowerLimitChips !== 0)
            writer.tag(4, WireType.Varint).uint64(message.lowerLimitChips);
        /* int64 userAssetNum = 5; */
        if (message.userAssetNum !== 0)
            writer.tag(5, WireType.Varint).int64(message.userAssetNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ChangeDeskReq
 */
export const ChangeDeskReq = new ChangeDeskReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChangeDeskResp$Type extends MessageType<ChangeDeskResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ChangeDeskResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "seatNo", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "lowerLimitChips", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "statusCode", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] }
        ]);
    }
    create(value?: PartialMessage<ChangeDeskResp>): ChangeDeskResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.tableKey = "";
        message.seatNo = 0;
        message.lowerLimitChips = 0;
        message.statusCode = 0;
        if (value !== undefined)
            reflectionMergePartial<ChangeDeskResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChangeDeskResp): ChangeDeskResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string tableKey */ 2:
                    message.tableKey = reader.string();
                    break;
                case /* uint32 seatNo */ 3:
                    message.seatNo = reader.uint32();
                    break;
                case /* uint64 lowerLimitChips */ 4:
                    message.lowerLimitChips = reader.uint64().toNumber();
                    break;
                case /* bpt.common.code.ErrorCode statusCode */ 6:
                    message.statusCode = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChangeDeskResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string tableKey = 2; */
        if (message.tableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tableKey);
        /* uint32 seatNo = 3; */
        if (message.seatNo !== 0)
            writer.tag(3, WireType.Varint).uint32(message.seatNo);
        /* uint64 lowerLimitChips = 4; */
        if (message.lowerLimitChips !== 0)
            writer.tag(4, WireType.Varint).uint64(message.lowerLimitChips);
        /* bpt.common.code.ErrorCode statusCode = 6; */
        if (message.statusCode !== 0)
            writer.tag(6, WireType.Varint).int32(message.statusCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ChangeDeskResp
 */
export const ChangeDeskResp = new ChangeDeskResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgCountDown$Type extends MessageType<MsgCountDown> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgCountDown", [
            { no: 1, name: "leftDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgCountDown>): MsgCountDown {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.leftDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgCountDown>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgCountDown): MsgCountDown {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 leftDuration */ 1:
                    message.leftDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgCountDown, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 leftDuration = 1; */
        if (message.leftDuration !== 0)
            writer.tag(1, WireType.Varint).int64(message.leftDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgCountDown
 */
export const MsgCountDown = new MsgCountDown$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgCancelMatch$Type extends MessageType<MsgCancelMatch> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgCancelMatch", [
            { no: 1, name: "game", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "serverTS", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgCancelMatch>): MsgCancelMatch {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.game = "";
        message.serverTS = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgCancelMatch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgCancelMatch): MsgCancelMatch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string game */ 1:
                    message.game = reader.string();
                    break;
                case /* int64 serverTS */ 2:
                    message.serverTS = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgCancelMatch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string game = 1; */
        if (message.game !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.game);
        /* int64 serverTS = 2; */
        if (message.serverTS !== 0)
            writer.tag(2, WireType.Varint).int64(message.serverTS);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgCancelMatch
 */
export const MsgCancelMatch = new MsgCancelMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchStart$Type extends MessageType<MsgMatchStart> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgMatchStart", [
            { no: 1, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "isSuccess", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchStart>): MsgMatchStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startedAt = 0;
        message.isSuccess = false;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchStart): MsgMatchStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 startedAt */ 1:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bool isSuccess */ 2:
                    message.isSuccess = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 startedAt = 1; */
        if (message.startedAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.startedAt);
        /* bool isSuccess = 2; */
        if (message.isSuccess !== false)
            writer.tag(2, WireType.Varint).bool(message.isSuccess);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgMatchStart
 */
export const MsgMatchStart = new MsgMatchStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserRegister$Type extends MessageType<MsgUserRegister> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgUserRegister", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<MsgUserRegister>): MsgUserRegister {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.user = [];
        if (value !== undefined)
            reflectionMergePartial<MsgUserRegister>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserRegister): MsgUserRegister {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.match.common.v1.User user */ 2:
                    message.user.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserRegister, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.match.common.v1.User user = 2; */
        for (let i = 0; i < message.user.length; i++)
            User.internalBinaryWrite(message.user[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgUserRegister
 */
export const MsgUserRegister = new MsgUserRegister$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserUnRegister$Type extends MessageType<MsgUserUnRegister> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgUserUnRegister", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uid", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "registeredNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserUnRegister>): MsgUserUnRegister {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.uid = [];
        message.registeredNum = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserUnRegister>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserUnRegister): MsgUserUnRegister {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated int64 uid */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uid.push(reader.int64().toNumber());
                    else
                        message.uid.push(reader.int64().toNumber());
                    break;
                case /* int64 registeredNum */ 3:
                    message.registeredNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserUnRegister, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated int64 uid = 2; */
        if (message.uid.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uid.length; i++)
                writer.int64(message.uid[i]);
            writer.join();
        }
        /* int64 registeredNum = 3; */
        if (message.registeredNum !== 0)
            writer.tag(3, WireType.Varint).int64(message.registeredNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgUserUnRegister
 */
export const MsgUserUnRegister = new MsgUserUnRegister$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserRegisterNum$Type extends MessageType<MsgUserRegisterNum> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgUserRegisterNum", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "registeredNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserRegisterNum>): MsgUserRegisterNum {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.registeredNum = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserRegisterNum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserRegisterNum): MsgUserRegisterNum {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 registeredNum */ 2:
                    message.registeredNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserRegisterNum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 registeredNum = 2; */
        if (message.registeredNum !== 0)
            writer.tag(2, WireType.Varint).int64(message.registeredNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgUserRegisterNum
 */
export const MsgUserRegisterNum = new MsgUserRegisterNum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgUserJoinedNum$Type extends MessageType<MsgUserJoinedNum> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgUserJoinedNum", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "joinedNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgUserJoinedNum>): MsgUserJoinedNum {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.joinedNum = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgUserJoinedNum>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgUserJoinedNum): MsgUserJoinedNum {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 joinedNum */ 2:
                    message.joinedNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgUserJoinedNum, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 joinedNum = 2; */
        if (message.joinedNum !== 0)
            writer.tag(2, WireType.Varint).int64(message.joinedNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgUserJoinedNum
 */
export const MsgUserJoinedNum = new MsgUserJoinedNum$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MsgMatchAboutToStart$Type extends MessageType<MsgMatchAboutToStart> {
    constructor() {
        super("bpt.match.matchhub.v1.MsgMatchAboutToStart", [
            { no: 1, name: "leftDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MsgMatchAboutToStart>): MsgMatchAboutToStart {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.leftDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<MsgMatchAboutToStart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MsgMatchAboutToStart): MsgMatchAboutToStart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 leftDuration */ 1:
                    message.leftDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MsgMatchAboutToStart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 leftDuration = 1; */
        if (message.leftDuration !== 0)
            writer.tag(1, WireType.Varint).int64(message.leftDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MsgMatchAboutToStart
 */
export const MsgMatchAboutToStart = new MsgMatchAboutToStart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchTablesReq$Type extends MessageType<ListMatchTablesReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchTablesReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] }
        ]);
    }
    create(value?: PartialMessage<ListMatchTablesReq>): ListMatchTablesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchKey = "";
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchTablesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchTablesReq): ListMatchTablesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchTablesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchTablesReq
 */
export const ListMatchTablesReq = new ListMatchTablesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchTablesResp$Type extends MessageType<ListMatchTablesResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchTablesResp", [
            { no: 1, name: "user", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Desk }
        ]);
    }
    create(value?: PartialMessage<ListMatchTablesResp>): ListMatchTablesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.user = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchTablesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchTablesResp): ListMatchTablesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.Desk user */ 1:
                    message.user.push(Desk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchTablesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.Desk user = 1; */
        for (let i = 0; i < message.user.length; i++)
            Desk.internalBinaryWrite(message.user[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchTablesResp
 */
export const ListMatchTablesResp = new ListMatchTablesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMultiMatchTablesReq$Type extends MessageType<ListMultiMatchTablesReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMultiMatchTablesReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 5, name: "blindLevel", kind: "message", T: () => BlindLevel },
            { no: 6, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "notEmptyTable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "seatingAvaliable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "blindLevels", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BlindLevel }
        ]);
    }
    create(value?: PartialMessage<ListMultiMatchTablesReq>): ListMultiMatchTablesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchKey = "";
        message.matchType = 0;
        message.tag = "";
        message.notEmptyTable = false;
        message.seatingAvaliable = false;
        message.blindLevels = [];
        if (value !== undefined)
            reflectionMergePartial<ListMultiMatchTablesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMultiMatchTablesReq): ListMultiMatchTablesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.match.common.v1.BlindLevel blindLevel */ 5:
                    message.blindLevel = BlindLevel.internalBinaryRead(reader, reader.uint32(), options, message.blindLevel);
                    break;
                case /* string tag */ 6:
                    message.tag = reader.string();
                    break;
                case /* bool notEmptyTable */ 7:
                    message.notEmptyTable = reader.bool();
                    break;
                case /* bool seatingAvaliable */ 8:
                    message.seatingAvaliable = reader.bool();
                    break;
                case /* repeated bpt.match.common.v1.BlindLevel blindLevels */ 9:
                    message.blindLevels.push(BlindLevel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMultiMatchTablesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* bpt.match.common.v1.BlindLevel blindLevel = 5; */
        if (message.blindLevel)
            BlindLevel.internalBinaryWrite(message.blindLevel, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string tag = 6; */
        if (message.tag !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.tag);
        /* bool notEmptyTable = 7; */
        if (message.notEmptyTable !== false)
            writer.tag(7, WireType.Varint).bool(message.notEmptyTable);
        /* bool seatingAvaliable = 8; */
        if (message.seatingAvaliable !== false)
            writer.tag(8, WireType.Varint).bool(message.seatingAvaliable);
        /* repeated bpt.match.common.v1.BlindLevel blindLevels = 9; */
        for (let i = 0; i < message.blindLevels.length; i++)
            BlindLevel.internalBinaryWrite(message.blindLevels[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMultiMatchTablesReq
 */
export const ListMultiMatchTablesReq = new ListMultiMatchTablesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMultiMatchTablesResp$Type extends MessageType<ListMultiMatchTablesResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMultiMatchTablesResp", [
            { no: 1, name: "user", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Desk },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMultiMatchTablesResp>): ListMultiMatchTablesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.user = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMultiMatchTablesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMultiMatchTablesResp): ListMultiMatchTablesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.Desk user */ 1:
                    message.user.push(Desk.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMultiMatchTablesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.Desk user = 1; */
        for (let i = 0; i < message.user.length; i++)
            Desk.internalBinaryWrite(message.user[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMultiMatchTablesResp
 */
export const ListMultiMatchTablesResp = new ListMultiMatchTablesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchUsersReq$Type extends MessageType<ListMatchUsersReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchUsersReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] }
        ]);
    }
    create(value?: PartialMessage<ListMatchUsersReq>): ListMatchUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchKey = "";
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchUsersReq): ListMatchUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchUsersReq
 */
export const ListMatchUsersReq = new ListMatchUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchUsersResp$Type extends MessageType<ListMatchUsersResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchUsersResp", [
            { no: 1, name: "user", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchUsersResp>): ListMatchUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.user = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchUsersResp): ListMatchUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.User user */ 1:
                    message.user.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.User user = 1; */
        for (let i = 0; i < message.user.length; i++)
            User.internalBinaryWrite(message.user[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchUsersResp
 */
export const ListMatchUsersResp = new ListMatchUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRegisterUsersReq$Type extends MessageType<ListRegisterUsersReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListRegisterUsersReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListRegisterUsersReq>): ListRegisterUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ListRegisterUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRegisterUsersReq): ListRegisterUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRegisterUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListRegisterUsersReq
 */
export const ListRegisterUsersReq = new ListRegisterUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListJoinedUsersReq$Type extends MessageType<ListJoinedUsersReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListJoinedUsersReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListJoinedUsersReq>): ListJoinedUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ListJoinedUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListJoinedUsersReq): ListJoinedUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListJoinedUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListJoinedUsersReq
 */
export const ListJoinedUsersReq = new ListJoinedUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListRegisterUsersResp$Type extends MessageType<ListRegisterUsersResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListRegisterUsersResp", [
            { no: 1, name: "user", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RegisterUser },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListRegisterUsersResp>): ListRegisterUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.user = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListRegisterUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListRegisterUsersResp): ListRegisterUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.RegisterUser user */ 1:
                    message.user.push(RegisterUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListRegisterUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.RegisterUser user = 1; */
        for (let i = 0; i < message.user.length; i++)
            RegisterUser.internalBinaryWrite(message.user[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListRegisterUsersResp
 */
export const ListRegisterUsersResp = new ListRegisterUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListJoinedUsersResp$Type extends MessageType<ListJoinedUsersResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListJoinedUsersResp", [
            { no: 1, name: "user", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RegisterUser },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListJoinedUsersResp>): ListJoinedUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.user = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListJoinedUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListJoinedUsersResp): ListJoinedUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.RegisterUser user */ 1:
                    message.user.push(RegisterUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListJoinedUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.RegisterUser user = 1; */
        for (let i = 0; i < message.user.length; i++)
            RegisterUser.internalBinaryWrite(message.user[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListJoinedUsersResp
 */
export const ListJoinedUsersResp = new ListJoinedUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterUser$Type extends MessageType<RegisterUser> {
    constructor() {
        super("bpt.match.matchhub.v1.RegisterUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "nickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "gender", kind: "enum", T: () => ["bpt.common.Gender", Gender] },
            { no: 4, name: "avatar", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterUser>): RegisterUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.nickname = "";
        message.gender = 0;
        message.avatar = "";
        if (value !== undefined)
            reflectionMergePartial<RegisterUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterUser): RegisterUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string nickname */ 2:
                    message.nickname = reader.string();
                    break;
                case /* bpt.common.Gender gender */ 3:
                    message.gender = reader.int32();
                    break;
                case /* string avatar */ 4:
                    message.avatar = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string nickname = 2; */
        if (message.nickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nickname);
        /* bpt.common.Gender gender = 3; */
        if (message.gender !== 0)
            writer.tag(3, WireType.Varint).int32(message.gender);
        /* string avatar = 4; */
        if (message.avatar !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.avatar);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RegisterUser
 */
export const RegisterUser = new RegisterUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserReq$Type extends MessageType<GetUserReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetUserReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserReq>): GetUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserReq): GetUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetUserReq
 */
export const GetUserReq = new GetUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserResp$Type extends MessageType<GetUserResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetUserResp", [
            { no: 1, name: "user", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<GetUserResp>): GetUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserResp): GetUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.User user */ 1:
                    message.user = User.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.User user = 1; */
        if (message.user)
            User.internalBinaryWrite(message.user, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetUserResp
 */
export const GetUserResp = new GetUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchReq$Type extends MessageType<GetMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchReq>): GetMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchReq): GetMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchReq
 */
export const GetMatchReq = new GetMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchResp$Type extends MessageType<GetMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchResp", [
            { no: 1, name: "match", kind: "message", T: () => Match },
            { no: 2, name: "userMatchInfo", kind: "message", T: () => UserMatchInfo }
        ]);
    }
    create(value?: PartialMessage<GetMatchResp>): GetMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchResp): GetMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.Match match */ 1:
                    message.match = Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                case /* bpt.match.matchhub.v1.UserMatchInfo userMatchInfo */ 2:
                    message.userMatchInfo = UserMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.userMatchInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.Match match = 1; */
        if (message.match)
            Match.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.UserMatchInfo userMatchInfo = 2; */
        if (message.userMatchInfo)
            UserMatchInfo.internalBinaryWrite(message.userMatchInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchResp
 */
export const GetMatchResp = new GetMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserMatchInfo$Type extends MessageType<UserMatchInfo> {
    constructor() {
        super("bpt.match.matchhub.v1.UserMatchInfo", [
            { no: 1, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "registerWay", kind: "enum", T: () => ["bpt.match.common.v1.RegisterWay", RegisterWay] }
        ]);
    }
    create(value?: PartialMessage<UserMatchInfo>): UserMatchInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isRegister = false;
        message.registerWay = 0;
        if (value !== undefined)
            reflectionMergePartial<UserMatchInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserMatchInfo): UserMatchInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isRegister */ 1:
                    message.isRegister = reader.bool();
                    break;
                case /* bpt.match.common.v1.RegisterWay registerWay */ 2:
                    message.registerWay = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserMatchInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isRegister = 1; */
        if (message.isRegister !== false)
            writer.tag(1, WireType.Varint).bool(message.isRegister);
        /* bpt.match.common.v1.RegisterWay registerWay = 2; */
        if (message.registerWay !== 0)
            writer.tag(2, WireType.Varint).int32(message.registerWay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserMatchInfo
 */
export const UserMatchInfo = new UserMatchInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinMatchReq$Type extends MessageType<JoinMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.JoinMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<JoinMatchReq>): JoinMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<JoinMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinMatchReq): JoinMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.JoinMatchReq
 */
export const JoinMatchReq = new JoinMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinMatchResp$Type extends MessageType<JoinMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.JoinMatchResp", [
            { no: 1, name: "match", kind: "message", T: () => MatchFront }
        ]);
    }
    create(value?: PartialMessage<JoinMatchResp>): JoinMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<JoinMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinMatchResp): JoinMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchFront match */ 1:
                    message.match = MatchFront.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchFront match = 1; */
        if (message.match)
            MatchFront.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.JoinMatchResp
 */
export const JoinMatchResp = new JoinMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMyMatchReq$Type extends MessageType<ListMyMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMyMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMyMatchReq>): ListMyMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMyMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMyMatchReq): ListMyMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMyMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMyMatchReq
 */
export const ListMyMatchReq = new ListMyMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMyMatchResp$Type extends MessageType<ListMyMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMyMatchResp", [
            { no: 1, name: "matchKey", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMyMatchResp>): ListMyMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = [];
        if (value !== undefined)
            reflectionMergePartial<ListMyMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMyMatchResp): ListMyMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string matchKey */ 1:
                    message.matchKey.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMyMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string matchKey = 1; */
        for (let i = 0; i < message.matchKey.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMyMatchResp
 */
export const ListMyMatchResp = new ListMyMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchStateAndUserStateReq$Type extends MessageType<BatchGetMatchStateAndUserStateReq> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateReq", [
            { no: 1, name: "matchKey", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchStateAndUserStateReq>): BatchGetMatchStateAndUserStateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchStateAndUserStateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchStateAndUserStateReq): BatchGetMatchStateAndUserStateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string matchKey */ 1:
                    message.matchKey.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchStateAndUserStateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string matchKey = 1; */
        for (let i = 0; i < message.matchKey.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateReq
 */
export const BatchGetMatchStateAndUserStateReq = new BatchGetMatchStateAndUserStateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchStateAndUserStateResp$Type extends MessageType<BatchGetMatchStateAndUserStateResp> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateResp", []);
    }
    create(value?: PartialMessage<BatchGetMatchStateAndUserStateResp>): BatchGetMatchStateAndUserStateResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchStateAndUserStateResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchStateAndUserStateResp): BatchGetMatchStateAndUserStateResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BatchGetMatchStateAndUserStateResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetMatchStateAndUserStateResp
 */
export const BatchGetMatchStateAndUserStateResp = new BatchGetMatchStateAndUserStateResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserMatchState$Type extends MessageType<UserMatchState> {
    constructor() {
        super("bpt.match.matchhub.v1.UserMatchState", [
            { no: 1, name: "state", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchRecordState", MatchRecordState] }
        ]);
    }
    create(value?: PartialMessage<UserMatchState>): UserMatchState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<UserMatchState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserMatchState): UserMatchState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchRecordState state */ 1:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserMatchState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchRecordState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserMatchState
 */
export const UserMatchState = new UserMatchState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenPayCredentialReq$Type extends MessageType<GenPayCredentialReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GenPayCredentialReq", [
            { no: 1, name: "mailId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "operate", kind: "enum", T: () => ["bpt.match.matchhub.v1.GenPayCredentialReq.OperateType", GenPayCredentialReq_OperateType] }
        ]);
    }
    create(value?: PartialMessage<GenPayCredentialReq>): GenPayCredentialReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.mailId = "";
        message.matchKey = "";
        message.operate = 0;
        if (value !== undefined)
            reflectionMergePartial<GenPayCredentialReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenPayCredentialReq): GenPayCredentialReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string mailId */ 1:
                    message.mailId = reader.string();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.GenPayCredentialReq.OperateType operate */ 3:
                    message.operate = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenPayCredentialReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string mailId = 1; */
        if (message.mailId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.mailId);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.matchhub.v1.GenPayCredentialReq.OperateType operate = 3; */
        if (message.operate !== 0)
            writer.tag(3, WireType.Varint).int32(message.operate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GenPayCredentialReq
 */
export const GenPayCredentialReq = new GenPayCredentialReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GenPayCredentialResp$Type extends MessageType<GenPayCredentialResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GenPayCredentialResp", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "meta", kind: "message", T: () => TransactionMeta }
        ]);
    }
    create(value?: PartialMessage<GenPayCredentialResp>): GenPayCredentialResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<GenPayCredentialResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GenPayCredentialResp): GenPayCredentialResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                case /* bpt.common.TransactionMeta meta */ 3:
                    message.meta = TransactionMeta.internalBinaryRead(reader, reader.uint32(), options, message.meta);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GenPayCredentialResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        /* bpt.common.TransactionMeta meta = 3; */
        if (message.meta)
            TransactionMeta.internalBinaryWrite(message.meta, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GenPayCredentialResp
 */
export const GenPayCredentialResp = new GenPayCredentialResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreeAddChipsReq$Type extends MessageType<FreeAddChipsReq> {
    constructor() {
        super("bpt.match.matchhub.v1.FreeAddChipsReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["bpt.match.matchhub.v1.FreeAddChipsReq.Type", FreeAddChipsReq_Type] },
            { no: 3, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<FreeAddChipsReq>): FreeAddChipsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.type = 0;
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<FreeAddChipsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreeAddChipsReq): FreeAddChipsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.FreeAddChipsReq.Type type */ 2:
                    message.type = reader.int32();
                    break;
                case /* int64 count */ 3:
                    message.count = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreeAddChipsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.matchhub.v1.FreeAddChipsReq.Type type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* int64 count = 3; */
        if (message.count !== 0)
            writer.tag(3, WireType.Varint).int64(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.FreeAddChipsReq
 */
export const FreeAddChipsReq = new FreeAddChipsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreeAddChipsResp$Type extends MessageType<FreeAddChipsResp> {
    constructor() {
        super("bpt.match.matchhub.v1.FreeAddChipsResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FreeAddChipsResp>): FreeAddChipsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<FreeAddChipsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreeAddChipsResp): FreeAddChipsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreeAddChipsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.FreeAddChipsResp
 */
export const FreeAddChipsResp = new FreeAddChipsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchTag$Type extends MessageType<MatchTag> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchTag", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 3, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "sort", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MatchTag>): MatchTag {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.matchType = 0;
        message.tag = "";
        message.sort = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchTag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchTag): MatchTag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                case /* string tag */ 3:
                    message.tag = reader.string();
                    break;
                case /* int64 sort */ 4:
                    message.sort = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchTag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        /* string tag = 3; */
        if (message.tag !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tag);
        /* int64 sort = 4; */
        if (message.sort !== 0)
            writer.tag(4, WireType.Varint).int64(message.sort);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchTag
 */
export const MatchTag = new MatchTag$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchTagReq$Type extends MessageType<ListMatchTagReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchTagReq", [
            { no: 1, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] }
        ]);
    }
    create(value?: PartialMessage<ListMatchTagReq>): ListMatchTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchTagReq): ListMatchTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchType matchType */ 1:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchType matchType = 1; */
        if (message.matchType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchTagReq
 */
export const ListMatchTagReq = new ListMatchTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchTagResp$Type extends MessageType<ListMatchTagResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchTagResp", [
            { no: 1, name: "matchTags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchTag }
        ]);
    }
    create(value?: PartialMessage<ListMatchTagResp>): ListMatchTagResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchTags = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchTagResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchTagResp): ListMatchTagResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchTag matchTags */ 1:
                    message.matchTags.push(MatchTag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchTagResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchTag matchTags = 1; */
        for (let i = 0; i < message.matchTags.length; i++)
            MatchTag.internalBinaryWrite(message.matchTags[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchTagResp
 */
export const ListMatchTagResp = new ListMatchTagResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchTagReq$Type extends MessageType<SaveMatchTagReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchTagReq", [
            { no: 1, name: "tagConf", kind: "message", T: () => MatchTag }
        ]);
    }
    create(value?: PartialMessage<SaveMatchTagReq>): SaveMatchTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchTagReq): SaveMatchTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchTag tagConf */ 1:
                    message.tagConf = MatchTag.internalBinaryRead(reader, reader.uint32(), options, message.tagConf);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchTag tagConf = 1; */
        if (message.tagConf)
            MatchTag.internalBinaryWrite(message.tagConf, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchTagReq
 */
export const SaveMatchTagReq = new SaveMatchTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchTagReq$Type extends MessageType<DeleteMatchTagReq> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchTagReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchTagReq>): DeleteMatchTagReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchTagReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchTagReq): DeleteMatchTagReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchTagReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchTagReq
 */
export const DeleteMatchTagReq = new DeleteMatchTagReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchBlindOptionsReq$Type extends MessageType<ListMatchBlindOptionsReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchBlindOptionsReq", [
            { no: 1, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 2, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchBlindOptionsReq>): ListMatchBlindOptionsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchType = 0;
        message.tag = "";
        if (value !== undefined)
            reflectionMergePartial<ListMatchBlindOptionsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchBlindOptionsReq): ListMatchBlindOptionsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchType matchType */ 1:
                    message.matchType = reader.int32();
                    break;
                case /* string tag */ 2:
                    message.tag = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchBlindOptionsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchType matchType = 1; */
        if (message.matchType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchType);
        /* string tag = 2; */
        if (message.tag !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tag);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchBlindOptionsReq
 */
export const ListMatchBlindOptionsReq = new ListMatchBlindOptionsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchBlindOptionsResp$Type extends MessageType<ListMatchBlindOptionsResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchBlindOptionsResp", [
            { no: 1, name: "blindOptions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BlindLevel }
        ]);
    }
    create(value?: PartialMessage<ListMatchBlindOptionsResp>): ListMatchBlindOptionsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.blindOptions = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchBlindOptionsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchBlindOptionsResp): ListMatchBlindOptionsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.BlindLevel blindOptions */ 1:
                    message.blindOptions.push(BlindLevel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchBlindOptionsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.BlindLevel blindOptions = 1; */
        for (let i = 0; i < message.blindOptions.length; i++)
            BlindLevel.internalBinaryWrite(message.blindOptions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchBlindOptionsResp
 */
export const ListMatchBlindOptionsResp = new ListMatchBlindOptionsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMatchReq$Type extends MessageType<CreateMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.CreateMatchReq", [
            { no: 1, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 3, name: "matchID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "conf", kind: "message", T: () => MatchConf },
            { no: 8, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<CreateMatchReq>): CreateMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.confID = 0;
        message.matchType = 0;
        message.matchID = 0;
        message.registerDeadline = 0;
        message.matchKey = "";
        message.startAt = 0;
        message.prizePool = [];
        if (value !== undefined)
            reflectionMergePartial<CreateMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMatchReq): CreateMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 confID */ 1:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                case /* int64 matchID */ 3:
                    message.matchID = reader.int64().toNumber();
                    break;
                case /* int64 registerDeadline */ 5:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 6:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchConf conf */ 7:
                    message.conf = MatchConf.internalBinaryRead(reader, reader.uint32(), options, message.conf);
                    break;
                case /* int64 startAt */ 8:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 9:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 confID = 1; */
        if (message.confID !== 0)
            writer.tag(1, WireType.Varint).int64(message.confID);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        /* int64 matchID = 3; */
        if (message.matchID !== 0)
            writer.tag(3, WireType.Varint).int64(message.matchID);
        /* int64 registerDeadline = 5; */
        if (message.registerDeadline !== 0)
            writer.tag(5, WireType.Varint).int64(message.registerDeadline);
        /* string matchKey = 6; */
        if (message.matchKey !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchConf conf = 7; */
        if (message.conf)
            MatchConf.internalBinaryWrite(message.conf, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int64 startAt = 8; */
        if (message.startAt !== 0)
            writer.tag(8, WireType.Varint).int64(message.startAt);
        /* repeated bpt.common.AssetItem prizePool = 9; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.CreateMatchReq
 */
export const CreateMatchReq = new CreateMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveMatchReq$Type extends MessageType<RemoveMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.RemoveMatchReq", [
            { no: 1, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 3, name: "matchID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveMatchReq>): RemoveMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.confID = 0;
        message.matchType = 0;
        message.matchID = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<RemoveMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveMatchReq): RemoveMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 confID */ 1:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                case /* int64 matchID */ 3:
                    message.matchID = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 confID = 1; */
        if (message.confID !== 0)
            writer.tag(1, WireType.Varint).int64(message.confID);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        /* int64 matchID = 3; */
        if (message.matchID !== 0)
            writer.tag(3, WireType.Varint).int64(message.matchID);
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RemoveMatchReq
 */
export const RemoveMatchReq = new RemoveMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveMatchByConfIdReq$Type extends MessageType<RemoveMatchByConfIdReq> {
    constructor() {
        super("bpt.match.matchhub.v1.RemoveMatchByConfIdReq", [
            { no: 1, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] }
        ]);
    }
    create(value?: PartialMessage<RemoveMatchByConfIdReq>): RemoveMatchByConfIdReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.confID = 0;
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<RemoveMatchByConfIdReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveMatchByConfIdReq): RemoveMatchByConfIdReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 confID */ 1:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveMatchByConfIdReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 confID = 1; */
        if (message.confID !== 0)
            writer.tag(1, WireType.Varint).int64(message.confID);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RemoveMatchByConfIdReq
 */
export const RemoveMatchByConfIdReq = new RemoveMatchByConfIdReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveMatchByMatchKeyReq$Type extends MessageType<RemoveMatchByMatchKeyReq> {
    constructor() {
        super("bpt.match.matchhub.v1.RemoveMatchByMatchKeyReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] }
        ]);
    }
    create(value?: PartialMessage<RemoveMatchByMatchKeyReq>): RemoveMatchByMatchKeyReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<RemoveMatchByMatchKeyReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveMatchByMatchKeyReq): RemoveMatchByMatchKeyReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveMatchByMatchKeyReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RemoveMatchByMatchKeyReq
 */
export const RemoveMatchByMatchKeyReq = new RemoveMatchByMatchKeyReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateListStartReq$Type extends MessageType<UpdateListStartReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateListStartReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 3, name: "srvId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateListStartReq>): UpdateListStartReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchType = 0;
        message.srvId = 0;
        if (value !== undefined)
            reflectionMergePartial<UpdateListStartReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateListStartReq): UpdateListStartReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                case /* int64 srvId */ 3:
                    message.srvId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateListStartReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        /* int64 srvId = 3; */
        if (message.srvId !== 0)
            writer.tag(3, WireType.Varint).int64(message.srvId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateListStartReq
 */
export const UpdateListStartReq = new UpdateListStartReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateListUsersReq$Type extends MessageType<UpdateListUsersReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdateListUsersReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 3, name: "registerUsers", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "isFull", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateListUsersReq>): UpdateListUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchType = 0;
        message.registerUsers = [];
        message.isFull = false;
        if (value !== undefined)
            reflectionMergePartial<UpdateListUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateListUsersReq): UpdateListUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                case /* repeated int64 registerUsers */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.registerUsers.push(reader.int64().toNumber());
                    else
                        message.registerUsers.push(reader.int64().toNumber());
                    break;
                case /* bool isFull */ 4:
                    message.isFull = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateListUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        /* repeated int64 registerUsers = 3; */
        if (message.registerUsers.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.registerUsers.length; i++)
                writer.int64(message.registerUsers[i]);
            writer.join();
        }
        /* bool isFull = 4; */
        if (message.isFull !== false)
            writer.tag(4, WireType.Varint).bool(message.isFull);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdateListUsersReq
 */
export const UpdateListUsersReq = new UpdateListUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchReq$Type extends MessageType<ListMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 5, name: "subMatchType", kind: "enum", T: () => ["bpt.match.common.v1.SubMatchType", SubMatchType] },
            { no: 6, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "includeSubMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] }
        ]);
    }
    create(value?: PartialMessage<ListMatchReq>): ListMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.uid = 0;
        message.matchType = 0;
        message.subMatchType = 0;
        message.tag = "";
        message.includeSubMatch = false;
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchReq): ListMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.match.common.v1.SubMatchType subMatchType */ 5:
                    message.subMatchType = reader.int32();
                    break;
                case /* string tag */ 6:
                    message.tag = reader.string();
                    break;
                case /* bool includeSubMatch */ 7:
                    message.includeSubMatch = reader.bool();
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 8:
                    message.matchListType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* bpt.match.common.v1.SubMatchType subMatchType = 5; */
        if (message.subMatchType !== 0)
            writer.tag(5, WireType.Varint).int32(message.subMatchType);
        /* string tag = 6; */
        if (message.tag !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.tag);
        /* bool includeSubMatch = 7; */
        if (message.includeSubMatch !== false)
            writer.tag(7, WireType.Varint).bool(message.includeSubMatch);
        /* bpt.match.common.v1.MatchListType matchListType = 8; */
        if (message.matchListType !== 0)
            writer.tag(8, WireType.Varint).int32(message.matchListType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchReq
 */
export const ListMatchReq = new ListMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchResp$Type extends MessageType<ListMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CashMatchItem },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchResp>): ListMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchResp): ListMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.CashMatchItem items */ 1:
                    message.items.push(CashMatchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.CashMatchItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            CashMatchItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchResp
 */
export const ListMatchResp = new ListMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMttMatchReq$Type extends MessageType<ListMttMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMttMatchReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "includeSubMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "onlyShowMy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 6, name: "includeTestMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ListMttMatchReq>): ListMttMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.includeSubMatch = false;
        message.onlyShowMy = false;
        message.matchListType = 0;
        message.includeTestMatch = false;
        if (value !== undefined)
            reflectionMergePartial<ListMttMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMttMatchReq): ListMttMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bool includeSubMatch */ 3:
                    message.includeSubMatch = reader.bool();
                    break;
                case /* bool onlyShowMy */ 4:
                    message.onlyShowMy = reader.bool();
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 5:
                    message.matchListType = reader.int32();
                    break;
                case /* bool includeTestMatch */ 6:
                    message.includeTestMatch = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMttMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bool includeSubMatch = 3; */
        if (message.includeSubMatch !== false)
            writer.tag(3, WireType.Varint).bool(message.includeSubMatch);
        /* bool onlyShowMy = 4; */
        if (message.onlyShowMy !== false)
            writer.tag(4, WireType.Varint).bool(message.onlyShowMy);
        /* bpt.match.common.v1.MatchListType matchListType = 5; */
        if (message.matchListType !== 0)
            writer.tag(5, WireType.Varint).int32(message.matchListType);
        /* bool includeTestMatch = 6; */
        if (message.includeTestMatch !== false)
            writer.tag(6, WireType.Varint).bool(message.includeTestMatch);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMttMatchReq
 */
export const ListMttMatchReq = new ListMttMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMttMatchResp$Type extends MessageType<ListMttMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMttMatchResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MttMatchItem },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMttMatchResp>): ListMttMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMttMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMttMatchResp): ListMttMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MttMatchItem items */ 1:
                    message.items.push(MttMatchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMttMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MttMatchItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MttMatchItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMttMatchResp
 */
export const ListMttMatchResp = new ListMttMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchSidebarItem$Type extends MessageType<MatchSidebarItem> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchSidebarItem", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchName", kind: "message", T: () => I18n },
            { no: 3, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "userStatus", kind: "message", T: () => UserStatus },
            { no: 5, name: "matchStatus", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] }
        ]);
    }
    create(value?: PartialMessage<MatchSidebarItem>): MatchSidebarItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.startedAt = 0;
        message.matchStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchSidebarItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchSidebarItem): MatchSidebarItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.common.I18n matchName */ 2:
                    message.matchName = I18n.internalBinaryRead(reader, reader.uint32(), options, message.matchName);
                    break;
                case /* int64 startedAt */ 3:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.UserStatus userStatus */ 4:
                    message.userStatus = UserStatus.internalBinaryRead(reader, reader.uint32(), options, message.userStatus);
                    break;
                case /* bpt.match.common.v1.MatchStatus matchStatus */ 5:
                    message.matchStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchSidebarItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.common.I18n matchName = 2; */
        if (message.matchName)
            I18n.internalBinaryWrite(message.matchName, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 startedAt = 3; */
        if (message.startedAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.startedAt);
        /* bpt.match.matchhub.v1.UserStatus userStatus = 4; */
        if (message.userStatus)
            UserStatus.internalBinaryWrite(message.userStatus, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchStatus matchStatus = 5; */
        if (message.matchStatus !== 0)
            writer.tag(5, WireType.Varint).int32(message.matchStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchSidebarItem
 */
export const MatchSidebarItem = new MatchSidebarItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSidebarMatchReq$Type extends MessageType<ListSidebarMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListSidebarMatchReq", []);
    }
    create(value?: PartialMessage<ListSidebarMatchReq>): ListSidebarMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListSidebarMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSidebarMatchReq): ListSidebarMatchReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListSidebarMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListSidebarMatchReq
 */
export const ListSidebarMatchReq = new ListSidebarMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSidebarMatchResp$Type extends MessageType<ListSidebarMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListSidebarMatchResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchSidebarItem }
        ]);
    }
    create(value?: PartialMessage<ListSidebarMatchResp>): ListSidebarMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<ListSidebarMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSidebarMatchResp): ListSidebarMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchSidebarItem items */ 1:
                    message.items.push(MatchSidebarItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSidebarMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchSidebarItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MatchSidebarItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListSidebarMatchResp
 */
export const ListSidebarMatchResp = new ListSidebarMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchImg$Type extends MessageType<MatchImg> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchImg", [
            { no: 1, name: "normal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "summary", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchImg>): MatchImg {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.normal = "";
        message.summary = "";
        if (value !== undefined)
            reflectionMergePartial<MatchImg>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchImg): MatchImg {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string normal */ 1:
                    message.normal = reader.string();
                    break;
                case /* string summary */ 2:
                    message.summary = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchImg, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string normal = 1; */
        if (message.normal !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.normal);
        /* string summary = 2; */
        if (message.summary !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.summary);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchImg
 */
export const MatchImg = new MatchImg$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchItem$Type extends MessageType<MatchItem> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchItem", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchImg", kind: "message", T: () => MatchImg },
            { no: 3, name: "matchName", kind: "message", T: () => I18n },
            { no: 4, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 5, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "prizePoolAssetList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 8, name: "registerAssetList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 9, name: "userStatus", kind: "message", T: () => UserStatus },
            { no: 10, name: "matchStatus", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 11, name: "isAllowRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "registerUserCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "registrationAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "phaseName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "phase", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 17, name: "mainMatchType", kind: "enum", T: () => ["bpt.match.common.v1.MainMatchType", MainMatchType] },
            { no: 18, name: "phasedTournamentInfo", kind: "message", T: () => PhasedTournamentInfo }
        ]);
    }
    create(value?: PartialMessage<MatchItem>): MatchItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchListType = 0;
        message.registerDeadline = 0;
        message.startedAt = 0;
        message.prizePoolAssetList = [];
        message.registerAssetList = [];
        message.matchStatus = 0;
        message.isAllowRegister = false;
        message.registerUserCnt = 0;
        message.registrationAt = 0;
        message.phaseName = "";
        message.phase = 0;
        message.mainMatchType = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchItem): MatchItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.MatchImg matchImg */ 2:
                    message.matchImg = MatchImg.internalBinaryRead(reader, reader.uint32(), options, message.matchImg);
                    break;
                case /* bpt.common.I18n matchName */ 3:
                    message.matchName = I18n.internalBinaryRead(reader, reader.uint32(), options, message.matchName);
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 4:
                    message.matchListType = reader.int32();
                    break;
                case /* int64 registerDeadline */ 5:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* int64 startedAt */ 6:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchhub.v1.SimpleAsset prizePoolAssetList */ 7:
                    message.prizePoolAssetList.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchhub.v1.SimpleAsset registerAssetList */ 8:
                    message.registerAssetList.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.UserStatus userStatus */ 9:
                    message.userStatus = UserStatus.internalBinaryRead(reader, reader.uint32(), options, message.userStatus);
                    break;
                case /* bpt.match.common.v1.MatchStatus matchStatus */ 10:
                    message.matchStatus = reader.int32();
                    break;
                case /* bool isAllowRegister */ 11:
                    message.isAllowRegister = reader.bool();
                    break;
                case /* int64 registerUserCnt */ 12:
                    message.registerUserCnt = reader.int64().toNumber();
                    break;
                case /* int64 registrationAt */ 14:
                    message.registrationAt = reader.int64().toNumber();
                    break;
                case /* string phaseName */ 15:
                    message.phaseName = reader.string();
                    break;
                case /* int32 phase */ 16:
                    message.phase = reader.int32();
                    break;
                case /* bpt.match.common.v1.MainMatchType mainMatchType */ 17:
                    message.mainMatchType = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.PhasedTournamentInfo phasedTournamentInfo */ 18:
                    message.phasedTournamentInfo = PhasedTournamentInfo.internalBinaryRead(reader, reader.uint32(), options, message.phasedTournamentInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.matchhub.v1.MatchImg matchImg = 2; */
        if (message.matchImg)
            MatchImg.internalBinaryWrite(message.matchImg, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.I18n matchName = 3; */
        if (message.matchName)
            I18n.internalBinaryWrite(message.matchName, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchListType matchListType = 4; */
        if (message.matchListType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchListType);
        /* int64 registerDeadline = 5; */
        if (message.registerDeadline !== 0)
            writer.tag(5, WireType.Varint).int64(message.registerDeadline);
        /* int64 startedAt = 6; */
        if (message.startedAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.startedAt);
        /* repeated bpt.match.matchhub.v1.SimpleAsset prizePoolAssetList = 7; */
        for (let i = 0; i < message.prizePoolAssetList.length; i++)
            SimpleAsset.internalBinaryWrite(message.prizePoolAssetList[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SimpleAsset registerAssetList = 8; */
        for (let i = 0; i < message.registerAssetList.length; i++)
            SimpleAsset.internalBinaryWrite(message.registerAssetList[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.UserStatus userStatus = 9; */
        if (message.userStatus)
            UserStatus.internalBinaryWrite(message.userStatus, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchStatus matchStatus = 10; */
        if (message.matchStatus !== 0)
            writer.tag(10, WireType.Varint).int32(message.matchStatus);
        /* bool isAllowRegister = 11; */
        if (message.isAllowRegister !== false)
            writer.tag(11, WireType.Varint).bool(message.isAllowRegister);
        /* int64 registerUserCnt = 12; */
        if (message.registerUserCnt !== 0)
            writer.tag(12, WireType.Varint).int64(message.registerUserCnt);
        /* int64 registrationAt = 14; */
        if (message.registrationAt !== 0)
            writer.tag(14, WireType.Varint).int64(message.registrationAt);
        /* string phaseName = 15; */
        if (message.phaseName !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.phaseName);
        /* int32 phase = 16; */
        if (message.phase !== 0)
            writer.tag(16, WireType.Varint).int32(message.phase);
        /* bpt.match.common.v1.MainMatchType mainMatchType = 17; */
        if (message.mainMatchType !== 0)
            writer.tag(17, WireType.Varint).int32(message.mainMatchType);
        /* bpt.match.matchhub.v1.PhasedTournamentInfo phasedTournamentInfo = 18; */
        if (message.phasedTournamentInfo)
            PhasedTournamentInfo.internalBinaryWrite(message.phasedTournamentInfo, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchItem
 */
export const MatchItem = new MatchItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PhasedTournamentInfo$Type extends MessageType<PhasedTournamentInfo> {
    constructor() {
        super("bpt.match.matchhub.v1.PhasedTournamentInfo", [
            { no: 1, name: "phaseName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "phase", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PhasedTournamentInfo>): PhasedTournamentInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.phaseName = "";
        message.phase = 0;
        if (value !== undefined)
            reflectionMergePartial<PhasedTournamentInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PhasedTournamentInfo): PhasedTournamentInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string phaseName */ 1:
                    message.phaseName = reader.string();
                    break;
                case /* int32 phase */ 2:
                    message.phase = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PhasedTournamentInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string phaseName = 1; */
        if (message.phaseName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.phaseName);
        /* int32 phase = 2; */
        if (message.phase !== 0)
            writer.tag(2, WireType.Varint).int32(message.phase);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.PhasedTournamentInfo
 */
export const PhasedTournamentInfo = new PhasedTournamentInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchItemReq$Type extends MessageType<ListMatchItemReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchItemReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 4, name: "deviceType", kind: "enum", T: () => ["bpt.match.matchhub.v1.ListMatchItemReq.DeviceType", ListMatchItemReq_DeviceType] },
            { no: 5, name: "viewType", kind: "enum", T: () => ["bpt.match.matchhub.v1.ListMatchItemReq.ViewType", ListMatchItemReq_ViewType] },
            { no: 6, name: "lang", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "matchListTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 8, name: "includeTestMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "matchProgress", kind: "enum", T: () => ["bpt.match.matchhub.v1.ListMatchItemReq.MatchProgress", ListMatchItemReq_MatchProgress] }
        ]);
    }
    create(value?: PartialMessage<ListMatchItemReq>): ListMatchItemReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchListType = 0;
        message.deviceType = 0;
        message.viewType = 0;
        message.lang = "";
        message.matchListTypes = [];
        message.includeTestMatch = false;
        message.matchProgress = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchItemReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchItemReq): ListMatchItemReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 3:
                    message.matchListType = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.ListMatchItemReq.DeviceType deviceType */ 4:
                    message.deviceType = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.ListMatchItemReq.ViewType viewType */ 5:
                    message.viewType = reader.int32();
                    break;
                case /* string lang */ 6:
                    message.lang = reader.string();
                    break;
                case /* repeated bpt.match.common.v1.MatchListType matchListTypes */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.matchListTypes.push(reader.int32());
                    else
                        message.matchListTypes.push(reader.int32());
                    break;
                case /* bool includeTestMatch */ 8:
                    message.includeTestMatch = reader.bool();
                    break;
                case /* bpt.match.matchhub.v1.ListMatchItemReq.MatchProgress matchProgress */ 9:
                    message.matchProgress = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchItemReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bpt.match.common.v1.MatchListType matchListType = 3; */
        if (message.matchListType !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchListType);
        /* bpt.match.matchhub.v1.ListMatchItemReq.DeviceType deviceType = 4; */
        if (message.deviceType !== 0)
            writer.tag(4, WireType.Varint).int32(message.deviceType);
        /* bpt.match.matchhub.v1.ListMatchItemReq.ViewType viewType = 5; */
        if (message.viewType !== 0)
            writer.tag(5, WireType.Varint).int32(message.viewType);
        /* string lang = 6; */
        if (message.lang !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.lang);
        /* repeated bpt.match.common.v1.MatchListType matchListTypes = 7; */
        if (message.matchListTypes.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.matchListTypes.length; i++)
                writer.int32(message.matchListTypes[i]);
            writer.join();
        }
        /* bool includeTestMatch = 8; */
        if (message.includeTestMatch !== false)
            writer.tag(8, WireType.Varint).bool(message.includeTestMatch);
        /* bpt.match.matchhub.v1.ListMatchItemReq.MatchProgress matchProgress = 9; */
        if (message.matchProgress !== 0)
            writer.tag(9, WireType.Varint).int32(message.matchProgress);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchItemReq
 */
export const ListMatchItemReq = new ListMatchItemReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchItemResp$Type extends MessageType<ListMatchItemResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchItemResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchItem },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchItemResp>): ListMatchItemResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchItemResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchItemResp): ListMatchItemResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchItem items */ 1:
                    message.items.push(MatchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchItemResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MatchItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchItemResp
 */
export const ListMatchItemResp = new ListMatchItemResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClubMatchItemReq$Type extends MessageType<ListClubMatchItemReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListClubMatchItemReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "clubId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "deviceType", kind: "enum", T: () => ["bpt.match.matchhub.v1.ListMatchItemReq.DeviceType", ListMatchItemReq_DeviceType] }
        ]);
    }
    create(value?: PartialMessage<ListClubMatchItemReq>): ListClubMatchItemReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.clubId = 0;
        message.deviceType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListClubMatchItemReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClubMatchItemReq): ListClubMatchItemReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* uint32 clubId */ 3:
                    message.clubId = reader.uint32();
                    break;
                case /* bpt.match.matchhub.v1.ListMatchItemReq.DeviceType deviceType */ 4:
                    message.deviceType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClubMatchItemReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* uint32 clubId = 3; */
        if (message.clubId !== 0)
            writer.tag(3, WireType.Varint).uint32(message.clubId);
        /* bpt.match.matchhub.v1.ListMatchItemReq.DeviceType deviceType = 4; */
        if (message.deviceType !== 0)
            writer.tag(4, WireType.Varint).int32(message.deviceType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListClubMatchItemReq
 */
export const ListClubMatchItemReq = new ListClubMatchItemReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListClubMatchItemResp$Type extends MessageType<ListClubMatchItemResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListClubMatchItemResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchItem },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListClubMatchItemResp>): ListClubMatchItemResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListClubMatchItemResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListClubMatchItemResp): ListClubMatchItemResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchItem items */ 1:
                    message.items.push(MatchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListClubMatchItemResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MatchItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListClubMatchItemResp
 */
export const ListClubMatchItemResp = new ListClubMatchItemResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HistoryMatch$Type extends MessageType<HistoryMatch> {
    constructor() {
        super("bpt.match.matchhub.v1.HistoryMatch", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "matchStatus", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 13, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 14, name: "buyIns", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 15, name: "tickets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 16, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 2, name: "showImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "showMobileImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "mobileShowImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "mobileShowImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "showImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "showImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "matchPlayerNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 22, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<HistoryMatch>): HistoryMatch {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.startedAt = 0;
        message.matchStatus = 0;
        message.buyIns = [];
        message.tickets = [];
        message.matchListType = 0;
        message.showImg = "";
        message.showMobileImg = "";
        message.mobileShowImgList = "";
        message.mobileShowImgDetail = "";
        message.showImgList = "";
        message.showImgDetail = "";
        message.matchPlayerNum = 0;
        message.prizePool = [];
        if (value !== undefined)
            reflectionMergePartial<HistoryMatch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HistoryMatch): HistoryMatch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 startedAt */ 6:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchStatus matchStatus */ 7:
                    message.matchStatus = reader.int32();
                    break;
                case /* bpt.common.I18n i18n = 13 [json_name = "i18n"];*/ 13:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* repeated bpt.match.matchhub.v1.SimpleAsset buyIns */ 14:
                    message.buyIns.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchhub.v1.SimpleAsset tickets */ 15:
                    message.tickets.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 16:
                    message.matchListType = reader.int32();
                    break;
                case /* string showImg */ 2:
                    message.showImg = reader.string();
                    break;
                case /* string showMobileImg */ 3:
                    message.showMobileImg = reader.string();
                    break;
                case /* string mobileShowImgList */ 17:
                    message.mobileShowImgList = reader.string();
                    break;
                case /* string mobileShowImgDetail */ 18:
                    message.mobileShowImgDetail = reader.string();
                    break;
                case /* string showImgList */ 19:
                    message.showImgList = reader.string();
                    break;
                case /* string showImgDetail */ 20:
                    message.showImgDetail = reader.string();
                    break;
                case /* int64 matchPlayerNum */ 21:
                    message.matchPlayerNum = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 22:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HistoryMatch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 startedAt = 6; */
        if (message.startedAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.startedAt);
        /* bpt.match.common.v1.MatchStatus matchStatus = 7; */
        if (message.matchStatus !== 0)
            writer.tag(7, WireType.Varint).int32(message.matchStatus);
        /* bpt.common.I18n i18n = 13 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SimpleAsset buyIns = 14; */
        for (let i = 0; i < message.buyIns.length; i++)
            SimpleAsset.internalBinaryWrite(message.buyIns[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SimpleAsset tickets = 15; */
        for (let i = 0; i < message.tickets.length; i++)
            SimpleAsset.internalBinaryWrite(message.tickets[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchListType matchListType = 16; */
        if (message.matchListType !== 0)
            writer.tag(16, WireType.Varint).int32(message.matchListType);
        /* string showImg = 2; */
        if (message.showImg !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.showImg);
        /* string showMobileImg = 3; */
        if (message.showMobileImg !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.showMobileImg);
        /* string mobileShowImgList = 17; */
        if (message.mobileShowImgList !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.mobileShowImgList);
        /* string mobileShowImgDetail = 18; */
        if (message.mobileShowImgDetail !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.mobileShowImgDetail);
        /* string showImgList = 19; */
        if (message.showImgList !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.showImgList);
        /* string showImgDetail = 20; */
        if (message.showImgDetail !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.showImgDetail);
        /* int64 matchPlayerNum = 21; */
        if (message.matchPlayerNum !== 0)
            writer.tag(21, WireType.Varint).int64(message.matchPlayerNum);
        /* repeated bpt.common.AssetItem prizePool = 22; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.HistoryMatch
 */
export const HistoryMatch = new HistoryMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHistoryMatchReq$Type extends MessageType<ListHistoryMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListHistoryMatchReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "queryUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListHistoryMatchReq>): ListHistoryMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.queryUid = 0;
        if (value !== undefined)
            reflectionMergePartial<ListHistoryMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListHistoryMatchReq): ListHistoryMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 queryUid */ 3:
                    message.queryUid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListHistoryMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 queryUid = 3; */
        if (message.queryUid !== 0)
            writer.tag(3, WireType.Varint).int64(message.queryUid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListHistoryMatchReq
 */
export const ListHistoryMatchReq = new ListHistoryMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHistoryMatchResp$Type extends MessageType<ListHistoryMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListHistoryMatchResp", [
            { no: 1, name: "historyMatches", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryMatch },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListHistoryMatchResp>): ListHistoryMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.historyMatches = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListHistoryMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListHistoryMatchResp): ListHistoryMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.HistoryMatch historyMatches */ 1:
                    message.historyMatches.push(HistoryMatch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListHistoryMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.HistoryMatch historyMatches = 1; */
        for (let i = 0; i < message.historyMatches.length; i++)
            HistoryMatch.internalBinaryWrite(message.historyMatches[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListHistoryMatchResp
 */
export const ListHistoryMatchResp = new ListHistoryMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHistoryClubMatchReq$Type extends MessageType<ListHistoryClubMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListHistoryClubMatchReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "queryUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "clubId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "matchStartAtBegin", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "matchStartAtEnd", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListHistoryClubMatchReq>): ListHistoryClubMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.queryUid = 0;
        message.clubId = 0;
        message.matchStartAtBegin = 0;
        message.matchStartAtEnd = 0;
        if (value !== undefined)
            reflectionMergePartial<ListHistoryClubMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListHistoryClubMatchReq): ListHistoryClubMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* int64 queryUid */ 3:
                    message.queryUid = reader.int64().toNumber();
                    break;
                case /* uint32 clubId */ 4:
                    message.clubId = reader.uint32();
                    break;
                case /* int64 matchStartAtBegin */ 5:
                    message.matchStartAtBegin = reader.int64().toNumber();
                    break;
                case /* int64 matchStartAtEnd */ 6:
                    message.matchStartAtEnd = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListHistoryClubMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* int64 queryUid = 3; */
        if (message.queryUid !== 0)
            writer.tag(3, WireType.Varint).int64(message.queryUid);
        /* uint32 clubId = 4; */
        if (message.clubId !== 0)
            writer.tag(4, WireType.Varint).uint32(message.clubId);
        /* int64 matchStartAtBegin = 5; */
        if (message.matchStartAtBegin !== 0)
            writer.tag(5, WireType.Varint).int64(message.matchStartAtBegin);
        /* int64 matchStartAtEnd = 6; */
        if (message.matchStartAtEnd !== 0)
            writer.tag(6, WireType.Varint).int64(message.matchStartAtEnd);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListHistoryClubMatchReq
 */
export const ListHistoryClubMatchReq = new ListHistoryClubMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListHistoryClubMatchResp$Type extends MessageType<ListHistoryClubMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListHistoryClubMatchResp", [
            { no: 1, name: "historyMatches", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryMatch },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListHistoryClubMatchResp>): ListHistoryClubMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.historyMatches = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListHistoryClubMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListHistoryClubMatchResp): ListHistoryClubMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.HistoryMatch historyMatches */ 1:
                    message.historyMatches.push(HistoryMatch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListHistoryClubMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.HistoryMatch historyMatches = 1; */
        for (let i = 0; i < message.historyMatches.length; i++)
            HistoryMatch.internalBinaryWrite(message.historyMatches[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListHistoryClubMatchResp
 */
export const ListHistoryClubMatchResp = new ListHistoryClubMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLatestMatchReq$Type extends MessageType<ListLatestMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListLatestMatchReq", []);
    }
    create(value?: PartialMessage<ListLatestMatchReq>): ListLatestMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ListLatestMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLatestMatchReq): ListLatestMatchReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListLatestMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListLatestMatchReq
 */
export const ListLatestMatchReq = new ListLatestMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLatestMatchResp$Type extends MessageType<ListLatestMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListLatestMatchResp", [
            { no: 1, name: "latestMatches", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HistoryMatch }
        ]);
    }
    create(value?: PartialMessage<ListLatestMatchResp>): ListLatestMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.latestMatches = [];
        if (value !== undefined)
            reflectionMergePartial<ListLatestMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLatestMatchResp): ListLatestMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.HistoryMatch latestMatches */ 1:
                    message.latestMatches.push(HistoryMatch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListLatestMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.HistoryMatch latestMatches = 1; */
        for (let i = 0; i < message.latestMatches.length; i++)
            HistoryMatch.internalBinaryWrite(message.latestMatches[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListLatestMatchResp
 */
export const ListLatestMatchResp = new ListLatestMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMttMatchDynamicDataReq$Type extends MessageType<BatchGetMttMatchDynamicDataReq> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetMttMatchDynamicDataReq", [
            { no: 11, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<BatchGetMttMatchDynamicDataReq>): BatchGetMttMatchDynamicDataReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizePool = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMttMatchDynamicDataReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMttMatchDynamicDataReq): BatchGetMttMatchDynamicDataReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem prizePool */ 11:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMttMatchDynamicDataReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem prizePool = 11; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetMttMatchDynamicDataReq
 */
export const BatchGetMttMatchDynamicDataReq = new BatchGetMttMatchDynamicDataReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetListMyMatchReq$Type extends MessageType<GetListMyMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetListMyMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetListMyMatchReq>): GetListMyMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetListMyMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetListMyMatchReq): GetListMyMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetListMyMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetListMyMatchReq
 */
export const GetListMyMatchReq = new GetListMyMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetListMyMatchResp$Type extends MessageType<GetListMyMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetListMyMatchResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchItem }
        ]);
    }
    create(value?: PartialMessage<GetListMyMatchResp>): GetListMyMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<GetListMyMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetListMyMatchResp): GetListMyMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchItem items */ 1:
                    message.items.push(MatchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetListMyMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MatchItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetListMyMatchResp
 */
export const GetListMyMatchResp = new GetListMyMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetListMyMttMatchResp$Type extends MessageType<GetListMyMttMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetListMyMttMatchResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MttMatchItem }
        ]);
    }
    create(value?: PartialMessage<GetListMyMttMatchResp>): GetListMyMttMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<GetListMyMttMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetListMyMttMatchResp): GetListMyMttMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MttMatchItem items */ 1:
                    message.items.push(MttMatchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetListMyMttMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MttMatchItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MttMatchItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetListMyMttMatchResp
 */
export const GetListMyMttMatchResp = new GetListMyMttMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeMyMatchListReq$Type extends MessageType<GetHomeMyMatchListReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetHomeMyMatchListReq", []);
    }
    create(value?: PartialMessage<GetHomeMyMatchListReq>): GetHomeMyMatchListReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetHomeMyMatchListReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHomeMyMatchListReq): GetHomeMyMatchListReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetHomeMyMatchListReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetHomeMyMatchListReq
 */
export const GetHomeMyMatchListReq = new GetHomeMyMatchListReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HomeMyMatchItem$Type extends MessageType<HomeMyMatchItem> {
    constructor() {
        super("bpt.match.matchhub.v1.HomeMyMatchItem", [
            { no: 1, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 2, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchStatus", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "isShowMyMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "isOut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 10, name: "mainMatchInfo", kind: "message", T: () => MainMatchInfo }
        ]);
    }
    create(value?: PartialMessage<HomeMyMatchItem>): HomeMyMatchItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchType = 0;
        message.startedAt = 0;
        message.matchStatus = 0;
        message.matchKey = "";
        message.matchName = "";
        message.registerDeadline = 0;
        message.isShowMyMatch = false;
        message.isOut = false;
        if (value !== undefined)
            reflectionMergePartial<HomeMyMatchItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HomeMyMatchItem): HomeMyMatchItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchType matchType */ 1:
                    message.matchType = reader.int32();
                    break;
                case /* int64 startedAt */ 2:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchStatus matchStatus */ 3:
                    message.matchStatus = reader.int32();
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                case /* string matchName */ 5:
                    message.matchName = reader.string();
                    break;
                case /* int64 registerDeadline */ 6:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* bool isShowMyMatch */ 7:
                    message.isShowMyMatch = reader.bool();
                    break;
                case /* bool isOut */ 8:
                    message.isOut = reader.bool();
                    break;
                case /* bpt.common.I18n i18n = 9 [json_name = "i18n"];*/ 9:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* bpt.match.common.v1.MainMatchInfo mainMatchInfo */ 10:
                    message.mainMatchInfo = MainMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.mainMatchInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HomeMyMatchItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchType matchType = 1; */
        if (message.matchType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchType);
        /* int64 startedAt = 2; */
        if (message.startedAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.startedAt);
        /* bpt.match.common.v1.MatchStatus matchStatus = 3; */
        if (message.matchStatus !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchStatus);
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        /* string matchName = 5; */
        if (message.matchName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.matchName);
        /* int64 registerDeadline = 6; */
        if (message.registerDeadline !== 0)
            writer.tag(6, WireType.Varint).int64(message.registerDeadline);
        /* bool isShowMyMatch = 7; */
        if (message.isShowMyMatch !== false)
            writer.tag(7, WireType.Varint).bool(message.isShowMyMatch);
        /* bool isOut = 8; */
        if (message.isOut !== false)
            writer.tag(8, WireType.Varint).bool(message.isOut);
        /* bpt.common.I18n i18n = 9 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MainMatchInfo mainMatchInfo = 10; */
        if (message.mainMatchInfo)
            MainMatchInfo.internalBinaryWrite(message.mainMatchInfo, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.HomeMyMatchItem
 */
export const HomeMyMatchItem = new HomeMyMatchItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHomeMyMatchListResp$Type extends MessageType<GetHomeMyMatchListResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetHomeMyMatchListResp", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HomeMyMatchItem }
        ]);
    }
    create(value?: PartialMessage<GetHomeMyMatchListResp>): GetHomeMyMatchListResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<GetHomeMyMatchListResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHomeMyMatchListResp): GetHomeMyMatchListResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.HomeMyMatchItem items */ 1:
                    message.items.push(HomeMyMatchItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHomeMyMatchListResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.HomeMyMatchItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            HomeMyMatchItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetHomeMyMatchListResp
 */
export const GetHomeMyMatchListResp = new GetHomeMyMatchListResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserObservableMatchReq$Type extends MessageType<GetUserObservableMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetUserObservableMatchReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserObservableMatchReq>): GetUserObservableMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetUserObservableMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserObservableMatchReq): GetUserObservableMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserObservableMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetUserObservableMatchReq
 */
export const GetUserObservableMatchReq = new GetUserObservableMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetUserObservableMatchResp$Type extends MessageType<GetUserObservableMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetUserObservableMatchResp", [
            { no: 1, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetUserObservableMatchResp>): GetUserObservableMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchType = 0;
        message.matchKey = "";
        message.tableKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetUserObservableMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetUserObservableMatchResp): GetUserObservableMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchType matchType */ 1:
                    message.matchType = reader.int32();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string tableKey */ 3:
                    message.tableKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetUserObservableMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchType matchType = 1; */
        if (message.matchType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchType);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string tableKey = 3; */
        if (message.tableKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tableKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetUserObservableMatchResp
 */
export const GetUserObservableMatchResp = new GetUserObservableMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyMatchRecordReq$Type extends MessageType<GetMyMatchRecordReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMyMatchRecordReq", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "matchStartAtBegin", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchStartAtEnd", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "state", kind: "enum", T: () => ["bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState", UserMatchRecord_UserMatchRecordState] }
        ]);
    }
    create(value?: PartialMessage<GetMyMatchRecordReq>): GetMyMatchRecordReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchStartAtBegin = 0;
        message.matchStartAtEnd = 0;
        message.state = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMyMatchRecordReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMyMatchRecordReq): GetMyMatchRecordReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* int64 matchStartAtBegin */ 3:
                    message.matchStartAtBegin = reader.int64().toNumber();
                    break;
                case /* int64 matchStartAtEnd */ 4:
                    message.matchStartAtEnd = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState state */ 5:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMyMatchRecordReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* int64 matchStartAtBegin = 3; */
        if (message.matchStartAtBegin !== 0)
            writer.tag(3, WireType.Varint).int64(message.matchStartAtBegin);
        /* int64 matchStartAtEnd = 4; */
        if (message.matchStartAtEnd !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchStartAtEnd);
        /* bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState state = 5; */
        if (message.state !== 0)
            writer.tag(5, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMyMatchRecordReq
 */
export const GetMyMatchRecordReq = new GetMyMatchRecordReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserMatchRecord$Type extends MessageType<UserMatchRecord> {
    constructor() {
        super("bpt.match.matchhub.v1.UserMatchRecord", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "enum", T: () => ["bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState", UserMatchRecord_UserMatchRecordState] },
            { no: 5, name: "matchStartAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "matchBuyIn", kind: "message", T: () => AssetItem },
            { no: 8, name: "matchPrize", kind: "message", T: () => AssetItem },
            { no: 9, name: "matchDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "matchPlayerNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 12, name: "matchPrizeList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 13, name: "mainMatchInfo", kind: "message", T: () => MainMatchInfo },
            { no: 14, name: "formStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.ClaimFormStatus", ClaimFormStatus] }
        ]);
    }
    create(value?: PartialMessage<UserMatchRecord>): UserMatchRecord {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchId = 0;
        message.matchName = "";
        message.state = 0;
        message.matchStartAt = 0;
        message.rank = 0;
        message.matchDuration = 0;
        message.matchPlayerNum = 0;
        message.matchPrizeList = [];
        message.formStatus = 0;
        if (value !== undefined)
            reflectionMergePartial<UserMatchRecord>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserMatchRecord): UserMatchRecord {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 matchId */ 2:
                    message.matchId = reader.int64().toNumber();
                    break;
                case /* string matchName */ 3:
                    message.matchName = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState state */ 4:
                    message.state = reader.int32();
                    break;
                case /* int64 matchStartAt */ 5:
                    message.matchStartAt = reader.int64().toNumber();
                    break;
                case /* uint32 rank */ 6:
                    message.rank = reader.uint32();
                    break;
                case /* bpt.common.AssetItem matchBuyIn */ 7:
                    message.matchBuyIn = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.matchBuyIn);
                    break;
                case /* bpt.common.AssetItem matchPrize */ 8:
                    message.matchPrize = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.matchPrize);
                    break;
                case /* int64 matchDuration */ 9:
                    message.matchDuration = reader.int64().toNumber();
                    break;
                case /* int64 matchPlayerNum */ 10:
                    message.matchPlayerNum = reader.int64().toNumber();
                    break;
                case /* bpt.common.I18n i18n = 11 [json_name = "i18n"];*/ 11:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* repeated bpt.common.AssetItem matchPrizeList */ 12:
                    message.matchPrizeList.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.MainMatchInfo mainMatchInfo */ 13:
                    message.mainMatchInfo = MainMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.mainMatchInfo);
                    break;
                case /* bpt.match.matchhub.v1.ClaimFormStatus formStatus */ 14:
                    message.formStatus = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserMatchRecord, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 matchId = 2; */
        if (message.matchId !== 0)
            writer.tag(2, WireType.Varint).int64(message.matchId);
        /* string matchName = 3; */
        if (message.matchName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchName);
        /* bpt.match.matchhub.v1.UserMatchRecord.UserMatchRecordState state = 4; */
        if (message.state !== 0)
            writer.tag(4, WireType.Varint).int32(message.state);
        /* int64 matchStartAt = 5; */
        if (message.matchStartAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.matchStartAt);
        /* uint32 rank = 6; */
        if (message.rank !== 0)
            writer.tag(6, WireType.Varint).uint32(message.rank);
        /* bpt.common.AssetItem matchBuyIn = 7; */
        if (message.matchBuyIn)
            AssetItem.internalBinaryWrite(message.matchBuyIn, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem matchPrize = 8; */
        if (message.matchPrize)
            AssetItem.internalBinaryWrite(message.matchPrize, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* int64 matchDuration = 9; */
        if (message.matchDuration !== 0)
            writer.tag(9, WireType.Varint).int64(message.matchDuration);
        /* int64 matchPlayerNum = 10; */
        if (message.matchPlayerNum !== 0)
            writer.tag(10, WireType.Varint).int64(message.matchPlayerNum);
        /* bpt.common.I18n i18n = 11 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem matchPrizeList = 12; */
        for (let i = 0; i < message.matchPrizeList.length; i++)
            AssetItem.internalBinaryWrite(message.matchPrizeList[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MainMatchInfo mainMatchInfo = 13; */
        if (message.mainMatchInfo)
            MainMatchInfo.internalBinaryWrite(message.mainMatchInfo, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.ClaimFormStatus formStatus = 14; */
        if (message.formStatus !== 0)
            writer.tag(14, WireType.Varint).int32(message.formStatus);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserMatchRecord
 */
export const UserMatchRecord = new UserMatchRecord$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyMatchRecordResp$Type extends MessageType<GetMyMatchRecordResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMyMatchRecordResp", [
            { no: 1, name: "userMatchRecords", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserMatchRecord },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetMyMatchRecordResp>): GetMyMatchRecordResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userMatchRecords = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMyMatchRecordResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMyMatchRecordResp): GetMyMatchRecordResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.UserMatchRecord userMatchRecords */ 1:
                    message.userMatchRecords.push(UserMatchRecord.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMyMatchRecordResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.UserMatchRecord userMatchRecords = 1; */
        for (let i = 0; i < message.userMatchRecords.length; i++)
            UserMatchRecord.internalBinaryWrite(message.userMatchRecords[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMyMatchRecordResp
 */
export const GetMyMatchRecordResp = new GetMyMatchRecordResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchReportDetailReq$Type extends MessageType<GetMatchReportDetailReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchReportDetailReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchReportDetailReq>): GetMatchReportDetailReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchReportDetailReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchReportDetailReq): GetMatchReportDetailReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchReportDetailReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchReportDetailReq
 */
export const GetMatchReportDetailReq = new GetMatchReportDetailReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchReportDetailResp$Type extends MessageType<GetMatchReportDetailResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchReportDetailResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "startTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 24, name: "matchDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "bannerPath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 6, name: "dynamicPrizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 20, name: "basicPrizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 7, name: "totalPlayer", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "reentry", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 21, name: "reentryPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 29, name: "reentrySwitch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 9, name: "addOn", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "addOnPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 30, name: "addOnSwitch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 10, name: "reBuy", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 23, name: "reBuyPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 31, name: "reBuySwitch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 11, name: "userPerformance", kind: "message", T: () => userPerformance },
            { no: 13, name: "bubbleUser", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "finalTableUser", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StageUser },
            { no: 15, name: "thirdPlaceUser", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "headsUpUser", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StageUser },
            { no: 17, name: "championUser", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 25, name: "championPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 26, name: "championCards", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 27, name: "publicCards", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 18, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 19, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 28, name: "buyIn", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 32, name: "prizeType", kind: "enum", T: () => ["bpt.match.common.v1.PrizeType", PrizeType] },
            { no: 33, name: "schedulerType", kind: "enum", T: () => ["bpt.match.common.v1.SchedulerType", SchedulerType] },
            { no: 34, name: "schedules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchSchedule },
            { no: 35, name: "mainMatchInfo", kind: "message", T: () => MainMatchInfo },
            { no: 36, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 37, name: "ticket", kind: "message", T: () => SimpleAsset },
            { no: 39, name: "advanceNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 40, name: "ruleDesc", kind: "message", T: () => I18nField },
            { no: 42, name: "isEarlyEnd", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 43, name: "seatMatchTargetMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 44, name: "prizePoolThreshold", kind: "message", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<GetMatchReportDetailResp>): GetMatchReportDetailResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchName = "";
        message.startTime = 0;
        message.matchDuration = 0;
        message.bannerPath = "";
        message.prizePool = [];
        message.dynamicPrizePool = [];
        message.basicPrizePool = [];
        message.totalPlayer = 0;
        message.reentry = 0;
        message.reentryPrize = [];
        message.reentrySwitch = 0;
        message.addOn = 0;
        message.addOnPrize = [];
        message.addOnSwitch = 0;
        message.reBuy = 0;
        message.reBuyPrize = [];
        message.reBuySwitch = 0;
        message.bubbleUser = 0;
        message.finalTableUser = [];
        message.thirdPlaceUser = 0;
        message.headsUpUser = [];
        message.championUser = 0;
        message.championPrize = [];
        message.championCards = [];
        message.publicCards = [];
        message.matchType = 0;
        message.buyIn = [];
        message.prizeType = 0;
        message.schedulerType = 0;
        message.schedules = [];
        message.matchListType = 0;
        message.advanceNum = 0;
        message.isEarlyEnd = false;
        message.seatMatchTargetMatchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchReportDetailResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchReportDetailResp): GetMatchReportDetailResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string matchName */ 2:
                    message.matchName = reader.string();
                    break;
                case /* int64 startTime */ 3:
                    message.startTime = reader.int64().toNumber();
                    break;
                case /* int64 matchDuration */ 24:
                    message.matchDuration = reader.int64().toNumber();
                    break;
                case /* string bannerPath */ 4:
                    message.bannerPath = reader.string();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 5:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem dynamicPrizePool */ 6:
                    message.dynamicPrizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem basicPrizePool */ 20:
                    message.basicPrizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalPlayer */ 7:
                    message.totalPlayer = reader.int32();
                    break;
                case /* int32 reentry */ 8:
                    message.reentry = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem reentryPrize */ 21:
                    message.reentryPrize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.SwitchState reentrySwitch */ 29:
                    message.reentrySwitch = reader.int32();
                    break;
                case /* int32 addOn */ 9:
                    message.addOn = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem addOnPrize */ 22:
                    message.addOnPrize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.SwitchState addOnSwitch */ 30:
                    message.addOnSwitch = reader.int32();
                    break;
                case /* int32 reBuy */ 10:
                    message.reBuy = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem reBuyPrize */ 23:
                    message.reBuyPrize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.SwitchState reBuySwitch */ 31:
                    message.reBuySwitch = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.userPerformance userPerformance */ 11:
                    message.userPerformance = userPerformance.internalBinaryRead(reader, reader.uint32(), options, message.userPerformance);
                    break;
                case /* int64 bubbleUser */ 13:
                    message.bubbleUser = reader.int64().toNumber();
                    break;
                case /* repeated bpt.broker.match.StageUser finalTableUser */ 14:
                    message.finalTableUser.push(StageUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 thirdPlaceUser */ 15:
                    message.thirdPlaceUser = reader.int64().toNumber();
                    break;
                case /* repeated bpt.broker.match.StageUser headsUpUser */ 16:
                    message.headsUpUser.push(StageUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 championUser */ 17:
                    message.championUser = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem championPrize */ 25:
                    message.championPrize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated uint32 championCards */ 26:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.championCards.push(reader.uint32());
                    else
                        message.championCards.push(reader.uint32());
                    break;
                case /* repeated uint32 publicCards */ 27:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.publicCards.push(reader.uint32());
                    else
                        message.publicCards.push(reader.uint32());
                    break;
                case /* bpt.common.I18n i18n = 18 [json_name = "i18n"];*/ 18:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 19:
                    message.matchType = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem buyIn */ 28:
                    message.buyIn.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.PrizeType prizeType */ 32:
                    message.prizeType = reader.int32();
                    break;
                case /* bpt.match.common.v1.SchedulerType schedulerType */ 33:
                    message.schedulerType = reader.int32();
                    break;
                case /* repeated bpt.match.matchhub.v1.MatchSchedule schedules */ 34:
                    message.schedules.push(MatchSchedule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.MainMatchInfo mainMatchInfo */ 35:
                    message.mainMatchInfo = MainMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.mainMatchInfo);
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 36:
                    message.matchListType = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.SimpleAsset ticket */ 37:
                    message.ticket = SimpleAsset.internalBinaryRead(reader, reader.uint32(), options, message.ticket);
                    break;
                case /* int32 advanceNum */ 39:
                    message.advanceNum = reader.int32();
                    break;
                case /* bpt.common.I18nField ruleDesc */ 40:
                    message.ruleDesc = I18nField.internalBinaryRead(reader, reader.uint32(), options, message.ruleDesc);
                    break;
                case /* bool isEarlyEnd */ 42:
                    message.isEarlyEnd = reader.bool();
                    break;
                case /* string seatMatchTargetMatchKey */ 43:
                    message.seatMatchTargetMatchKey = reader.string();
                    break;
                case /* bpt.common.AssetItem prizePoolThreshold */ 44:
                    message.prizePoolThreshold = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.prizePoolThreshold);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchReportDetailResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string matchName = 2; */
        if (message.matchName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchName);
        /* int64 startTime = 3; */
        if (message.startTime !== 0)
            writer.tag(3, WireType.Varint).int64(message.startTime);
        /* int64 matchDuration = 24; */
        if (message.matchDuration !== 0)
            writer.tag(24, WireType.Varint).int64(message.matchDuration);
        /* string bannerPath = 4; */
        if (message.bannerPath !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.bannerPath);
        /* repeated bpt.common.AssetItem prizePool = 5; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem dynamicPrizePool = 6; */
        for (let i = 0; i < message.dynamicPrizePool.length; i++)
            AssetItem.internalBinaryWrite(message.dynamicPrizePool[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem basicPrizePool = 20; */
        for (let i = 0; i < message.basicPrizePool.length; i++)
            AssetItem.internalBinaryWrite(message.basicPrizePool[i], writer.tag(20, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalPlayer = 7; */
        if (message.totalPlayer !== 0)
            writer.tag(7, WireType.Varint).int32(message.totalPlayer);
        /* int32 reentry = 8; */
        if (message.reentry !== 0)
            writer.tag(8, WireType.Varint).int32(message.reentry);
        /* repeated bpt.common.AssetItem reentryPrize = 21; */
        for (let i = 0; i < message.reentryPrize.length; i++)
            AssetItem.internalBinaryWrite(message.reentryPrize[i], writer.tag(21, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState reentrySwitch = 29; */
        if (message.reentrySwitch !== 0)
            writer.tag(29, WireType.Varint).int32(message.reentrySwitch);
        /* int32 addOn = 9; */
        if (message.addOn !== 0)
            writer.tag(9, WireType.Varint).int32(message.addOn);
        /* repeated bpt.common.AssetItem addOnPrize = 22; */
        for (let i = 0; i < message.addOnPrize.length; i++)
            AssetItem.internalBinaryWrite(message.addOnPrize[i], writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState addOnSwitch = 30; */
        if (message.addOnSwitch !== 0)
            writer.tag(30, WireType.Varint).int32(message.addOnSwitch);
        /* int32 reBuy = 10; */
        if (message.reBuy !== 0)
            writer.tag(10, WireType.Varint).int32(message.reBuy);
        /* repeated bpt.common.AssetItem reBuyPrize = 23; */
        for (let i = 0; i < message.reBuyPrize.length; i++)
            AssetItem.internalBinaryWrite(message.reBuyPrize[i], writer.tag(23, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState reBuySwitch = 31; */
        if (message.reBuySwitch !== 0)
            writer.tag(31, WireType.Varint).int32(message.reBuySwitch);
        /* bpt.match.matchhub.v1.userPerformance userPerformance = 11; */
        if (message.userPerformance)
            userPerformance.internalBinaryWrite(message.userPerformance, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* int64 bubbleUser = 13; */
        if (message.bubbleUser !== 0)
            writer.tag(13, WireType.Varint).int64(message.bubbleUser);
        /* repeated bpt.broker.match.StageUser finalTableUser = 14; */
        for (let i = 0; i < message.finalTableUser.length; i++)
            StageUser.internalBinaryWrite(message.finalTableUser[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* int64 thirdPlaceUser = 15; */
        if (message.thirdPlaceUser !== 0)
            writer.tag(15, WireType.Varint).int64(message.thirdPlaceUser);
        /* repeated bpt.broker.match.StageUser headsUpUser = 16; */
        for (let i = 0; i < message.headsUpUser.length; i++)
            StageUser.internalBinaryWrite(message.headsUpUser[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* int64 championUser = 17; */
        if (message.championUser !== 0)
            writer.tag(17, WireType.Varint).int64(message.championUser);
        /* repeated bpt.common.AssetItem championPrize = 25; */
        for (let i = 0; i < message.championPrize.length; i++)
            AssetItem.internalBinaryWrite(message.championPrize[i], writer.tag(25, WireType.LengthDelimited).fork(), options).join();
        /* repeated uint32 championCards = 26; */
        if (message.championCards.length) {
            writer.tag(26, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.championCards.length; i++)
                writer.uint32(message.championCards[i]);
            writer.join();
        }
        /* repeated uint32 publicCards = 27; */
        if (message.publicCards.length) {
            writer.tag(27, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.publicCards.length; i++)
                writer.uint32(message.publicCards[i]);
            writer.join();
        }
        /* bpt.common.I18n i18n = 18 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchType matchType = 19; */
        if (message.matchType !== 0)
            writer.tag(19, WireType.Varint).int32(message.matchType);
        /* repeated bpt.common.AssetItem buyIn = 28; */
        for (let i = 0; i < message.buyIn.length; i++)
            AssetItem.internalBinaryWrite(message.buyIn[i], writer.tag(28, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.PrizeType prizeType = 32; */
        if (message.prizeType !== 0)
            writer.tag(32, WireType.Varint).int32(message.prizeType);
        /* bpt.match.common.v1.SchedulerType schedulerType = 33; */
        if (message.schedulerType !== 0)
            writer.tag(33, WireType.Varint).int32(message.schedulerType);
        /* repeated bpt.match.matchhub.v1.MatchSchedule schedules = 34; */
        for (let i = 0; i < message.schedules.length; i++)
            MatchSchedule.internalBinaryWrite(message.schedules[i], writer.tag(34, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MainMatchInfo mainMatchInfo = 35; */
        if (message.mainMatchInfo)
            MainMatchInfo.internalBinaryWrite(message.mainMatchInfo, writer.tag(35, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchListType matchListType = 36; */
        if (message.matchListType !== 0)
            writer.tag(36, WireType.Varint).int32(message.matchListType);
        /* bpt.match.matchhub.v1.SimpleAsset ticket = 37; */
        if (message.ticket)
            SimpleAsset.internalBinaryWrite(message.ticket, writer.tag(37, WireType.LengthDelimited).fork(), options).join();
        /* int32 advanceNum = 39; */
        if (message.advanceNum !== 0)
            writer.tag(39, WireType.Varint).int32(message.advanceNum);
        /* bpt.common.I18nField ruleDesc = 40; */
        if (message.ruleDesc)
            I18nField.internalBinaryWrite(message.ruleDesc, writer.tag(40, WireType.LengthDelimited).fork(), options).join();
        /* bool isEarlyEnd = 42; */
        if (message.isEarlyEnd !== false)
            writer.tag(42, WireType.Varint).bool(message.isEarlyEnd);
        /* string seatMatchTargetMatchKey = 43; */
        if (message.seatMatchTargetMatchKey !== "")
            writer.tag(43, WireType.LengthDelimited).string(message.seatMatchTargetMatchKey);
        /* bpt.common.AssetItem prizePoolThreshold = 44; */
        if (message.prizePoolThreshold)
            AssetItem.internalBinaryWrite(message.prizePoolThreshold, writer.tag(44, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchReportDetailResp
 */
export const GetMatchReportDetailResp = new GetMatchReportDetailResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class userPerformance$Type extends MessageType<userPerformance> {
    constructor() {
        super("bpt.match.matchhub.v1.userPerformance", [
            { no: 1, name: "bestHand", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "rank", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "win", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 4, name: "reentry", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "reentryCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 5, name: "addOn", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 8, name: "addOnCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 6, name: "reBuy", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 9, name: "reBuyCost", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 10, name: "prizeShowType", kind: "enum", T: () => ["bpt.match.matchhub.v1.PrizeShowType", PrizeShowType] }
        ]);
    }
    create(value?: PartialMessage<userPerformance>): userPerformance {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.bestHand = [];
        message.rank = 0;
        message.win = [];
        message.reentry = 0;
        message.reentryCost = [];
        message.addOn = 0;
        message.addOnCost = [];
        message.reBuy = 0;
        message.reBuyCost = [];
        message.prizeShowType = 0;
        if (value !== undefined)
            reflectionMergePartial<userPerformance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: userPerformance): userPerformance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated uint32 bestHand */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.bestHand.push(reader.uint32());
                    else
                        message.bestHand.push(reader.uint32());
                    break;
                case /* int32 rank */ 2:
                    message.rank = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem win */ 3:
                    message.win.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 reentry */ 4:
                    message.reentry = reader.uint32();
                    break;
                case /* repeated bpt.common.AssetItem reentryCost */ 7:
                    message.reentryCost.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 addOn */ 5:
                    message.addOn = reader.uint32();
                    break;
                case /* repeated bpt.common.AssetItem addOnCost */ 8:
                    message.addOnCost.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 reBuy */ 6:
                    message.reBuy = reader.uint32();
                    break;
                case /* repeated bpt.common.AssetItem reBuyCost */ 9:
                    message.reBuyCost.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.PrizeShowType prizeShowType */ 10:
                    message.prizeShowType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: userPerformance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated uint32 bestHand = 1; */
        if (message.bestHand.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.bestHand.length; i++)
                writer.uint32(message.bestHand[i]);
            writer.join();
        }
        /* int32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).int32(message.rank);
        /* repeated bpt.common.AssetItem win = 3; */
        for (let i = 0; i < message.win.length; i++)
            AssetItem.internalBinaryWrite(message.win[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint32 reentry = 4; */
        if (message.reentry !== 0)
            writer.tag(4, WireType.Varint).uint32(message.reentry);
        /* repeated bpt.common.AssetItem reentryCost = 7; */
        for (let i = 0; i < message.reentryCost.length; i++)
            AssetItem.internalBinaryWrite(message.reentryCost[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* uint32 addOn = 5; */
        if (message.addOn !== 0)
            writer.tag(5, WireType.Varint).uint32(message.addOn);
        /* repeated bpt.common.AssetItem addOnCost = 8; */
        for (let i = 0; i < message.addOnCost.length; i++)
            AssetItem.internalBinaryWrite(message.addOnCost[i], writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* uint32 reBuy = 6; */
        if (message.reBuy !== 0)
            writer.tag(6, WireType.Varint).uint32(message.reBuy);
        /* repeated bpt.common.AssetItem reBuyCost = 9; */
        for (let i = 0; i < message.reBuyCost.length; i++)
            AssetItem.internalBinaryWrite(message.reBuyCost[i], writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.PrizeShowType prizeShowType = 10; */
        if (message.prizeShowType !== 0)
            writer.tag(10, WireType.Varint).int32(message.prizeShowType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.userPerformance
 */
export const userPerformance = new userPerformance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchPrizeUsersReq$Type extends MessageType<GetMatchPrizeUsersReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchPrizeUsersReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchPrizeUsersReq>): GetMatchPrizeUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchPrizeUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchPrizeUsersReq): GetMatchPrizeUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchPrizeUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchPrizeUsersReq
 */
export const GetMatchPrizeUsersReq = new GetMatchPrizeUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndexRankingReq$Type extends MessageType<GetIndexRankingReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetIndexRankingReq", [
            { no: 1, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetIndexRankingReq>): GetIndexRankingReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchListType = 0;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetIndexRankingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIndexRankingReq): GetIndexRankingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchListType matchListType */ 1:
                    message.matchListType = reader.int32();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIndexRankingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchListType matchListType = 1; */
        if (message.matchListType !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchListType);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetIndexRankingReq
 */
export const GetIndexRankingReq = new GetIndexRankingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchPrizeUsersResp$Type extends MessageType<GetMatchPrizeUsersResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchPrizeUsersResp", [
            { no: 1, name: "prizeUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrizeUser },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "schedulerType", kind: "enum", T: () => ["bpt.match.common.v1.SchedulerType", SchedulerType] }
        ]);
    }
    create(value?: PartialMessage<GetMatchPrizeUsersResp>): GetMatchPrizeUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizeUsers = [];
        message.totalSize = 0;
        message.schedulerType = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchPrizeUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchPrizeUsersResp): GetMatchPrizeUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.PrizeUser prizeUsers */ 1:
                    message.prizeUsers.push(PrizeUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.SchedulerType schedulerType */ 3:
                    message.schedulerType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchPrizeUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.PrizeUser prizeUsers = 1; */
        for (let i = 0; i < message.prizeUsers.length; i++)
            PrizeUser.internalBinaryWrite(message.prizeUsers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        /* bpt.match.common.v1.SchedulerType schedulerType = 3; */
        if (message.schedulerType !== 0)
            writer.tag(3, WireType.Varint).int32(message.schedulerType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchPrizeUsersResp
 */
export const GetMatchPrizeUsersResp = new GetMatchPrizeUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndexRankingResp$Type extends MessageType<GetIndexRankingResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetIndexRankingResp", [
            { no: 1, name: "prizeUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrizeUser },
            { no: 4, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n }
        ]);
    }
    create(value?: PartialMessage<GetIndexRankingResp>): GetIndexRankingResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizeUsers = [];
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetIndexRankingResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIndexRankingResp): GetIndexRankingResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.PrizeUser prizeUsers */ 1:
                    message.prizeUsers.push(PrizeUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 4:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.common.I18n i18n = 5 [json_name = "i18n"];*/ 5:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIndexRankingResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.PrizeUser prizeUsers = 1; */
        for (let i = 0; i < message.prizeUsers.length; i++)
            PrizeUser.internalBinaryWrite(message.prizeUsers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 4; */
        if (message.matchKey !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.common.I18n i18n = 5 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetIndexRankingResp
 */
export const GetIndexRankingResp = new GetIndexRankingResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchSchedulesReq$Type extends MessageType<GetMatchSchedulesReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchSchedulesReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchSchedulesReq>): GetMatchSchedulesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchSchedulesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchSchedulesReq): GetMatchSchedulesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchSchedulesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchSchedulesReq
 */
export const GetMatchSchedulesReq = new GetMatchSchedulesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchSchedulesResp$Type extends MessageType<GetMatchSchedulesResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchSchedulesResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "schedules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchSchedule }
        ]);
    }
    create(value?: PartialMessage<GetMatchSchedulesResp>): GetMatchSchedulesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.schedules = [];
        if (value !== undefined)
            reflectionMergePartial<GetMatchSchedulesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchSchedulesResp): GetMatchSchedulesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.match.matchhub.v1.MatchSchedule schedules */ 2:
                    message.schedules.push(MatchSchedule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchSchedulesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.match.matchhub.v1.MatchSchedule schedules = 2; */
        for (let i = 0; i < message.schedules.length; i++)
            MatchSchedule.internalBinaryWrite(message.schedules[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchSchedulesResp
 */
export const GetMatchSchedulesResp = new GetMatchSchedulesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrizeUser$Type extends MessageType<PrizeUser> {
    constructor() {
        super("bpt.match.matchhub.v1.PrizeUser", [
            { no: 1, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "Uid", kind: "scalar", localName: "Uid", jsonName: "Uid", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 4, name: "Chips", kind: "scalar", localName: "Chips", jsonName: "Chips", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "prizeShowType", kind: "enum", T: () => ["bpt.match.matchhub.v1.PrizeShowType", PrizeShowType] }
        ]);
    }
    create(value?: PartialMessage<PrizeUser>): PrizeUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.Uid = 0;
        message.prizes = [];
        message.Chips = 0;
        message.prizeShowType = 0;
        if (value !== undefined)
            reflectionMergePartial<PrizeUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrizeUser): PrizeUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 rank */ 1:
                    message.rank = reader.uint32();
                    break;
                case /* int64 Uid = 2 [json_name = "Uid"];*/ 2:
                    message.Uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizes */ 3:
                    message.prizes.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 Chips = 4 [json_name = "Chips"];*/ 4:
                    message.Chips = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.PrizeShowType prizeShowType */ 5:
                    message.prizeShowType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrizeUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).uint32(message.rank);
        /* int64 Uid = 2 [json_name = "Uid"]; */
        if (message.Uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.Uid);
        /* repeated bpt.common.AssetItem prizes = 3; */
        for (let i = 0; i < message.prizes.length; i++)
            AssetItem.internalBinaryWrite(message.prizes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 Chips = 4 [json_name = "Chips"]; */
        if (message.Chips !== 0)
            writer.tag(4, WireType.Varint).int64(message.Chips);
        /* bpt.match.matchhub.v1.PrizeShowType prizeShowType = 5; */
        if (message.prizeShowType !== 0)
            writer.tag(5, WireType.Varint).int32(message.prizeShowType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.PrizeUser
 */
export const PrizeUser = new PrizeUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchSchedule$Type extends MessageType<MatchSchedule> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchSchedule", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 3, name: "StartAt", kind: "scalar", localName: "StartAt", jsonName: "StartAt", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "BlindLevel", kind: "scalar", localName: "BlindLevel", jsonName: "BlindLevel", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "gameOverType", kind: "enum", T: () => ["bpt.match.common.v1.GameOverType", GameOverType] }
        ]);
    }
    create(value?: PartialMessage<MatchSchedule>): MatchSchedule {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.state = 0;
        message.StartAt = 0;
        message.BlindLevel = 0;
        message.gameOverType = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchSchedule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchSchedule): MatchSchedule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchStatus state */ 2:
                    message.state = reader.int32();
                    break;
                case /* int64 StartAt = 3 [json_name = "StartAt"];*/ 3:
                    message.StartAt = reader.int64().toNumber();
                    break;
                case /* uint32 BlindLevel = 4 [json_name = "BlindLevel"];*/ 4:
                    message.BlindLevel = reader.uint32();
                    break;
                case /* bpt.match.common.v1.GameOverType gameOverType */ 5:
                    message.gameOverType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchSchedule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchStatus state = 2; */
        if (message.state !== 0)
            writer.tag(2, WireType.Varint).int32(message.state);
        /* int64 StartAt = 3 [json_name = "StartAt"]; */
        if (message.StartAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.StartAt);
        /* uint32 BlindLevel = 4 [json_name = "BlindLevel"]; */
        if (message.BlindLevel !== 0)
            writer.tag(4, WireType.Varint).uint32(message.BlindLevel);
        /* bpt.match.common.v1.GameOverType gameOverType = 5; */
        if (message.gameOverType !== 0)
            writer.tag(5, WireType.Varint).int32(message.gameOverType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchSchedule
 */
export const MatchSchedule = new MatchSchedule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchMoneyCircleUsersReq$Type extends MessageType<GetMatchMoneyCircleUsersReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchMoneyCircleUsersReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchMoneyCircleUsersReq>): GetMatchMoneyCircleUsersReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchMoneyCircleUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchMoneyCircleUsersReq): GetMatchMoneyCircleUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchMoneyCircleUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchMoneyCircleUsersReq
 */
export const GetMatchMoneyCircleUsersReq = new GetMatchMoneyCircleUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchMoneyCircleUsersResp$Type extends MessageType<GetMatchMoneyCircleUsersResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchMoneyCircleUsersResp", [
            { no: 1, name: "stageUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StageUser$ },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchMoneyCircleUsersResp>): GetMatchMoneyCircleUsersResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.stageUsers = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchMoneyCircleUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchMoneyCircleUsersResp): GetMatchMoneyCircleUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.StageUser stageUsers */ 1:
                    message.stageUsers.push(StageUser$.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchMoneyCircleUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.StageUser stageUsers = 1; */
        for (let i = 0; i < message.stageUsers.length; i++)
            StageUser$.internalBinaryWrite(message.stageUsers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchMoneyCircleUsersResp
 */
export const GetMatchMoneyCircleUsersResp = new GetMatchMoneyCircleUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMttMatchFinalRankingReq$Type extends MessageType<GetMttMatchFinalRankingReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMttMatchFinalRankingReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMttMatchFinalRankingReq>): GetMttMatchFinalRankingReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMttMatchFinalRankingReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMttMatchFinalRankingReq): GetMttMatchFinalRankingReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 page */ 2:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 3:
                    message.pageSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMttMatchFinalRankingReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int64(message.page);
        /* int64 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int64(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMttMatchFinalRankingReq
 */
export const GetMttMatchFinalRankingReq = new GetMttMatchFinalRankingReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMttMatchFinalRankingResp$Type extends MessageType<GetMttMatchFinalRankingResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMttMatchFinalRankingResp", [
            { no: 1, name: "rankUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RankUser },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMttMatchFinalRankingResp>): GetMttMatchFinalRankingResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rankUsers = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMttMatchFinalRankingResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMttMatchFinalRankingResp): GetMttMatchFinalRankingResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.RankUser rankUsers */ 1:
                    message.rankUsers.push(RankUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMttMatchFinalRankingResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.RankUser rankUsers = 1; */
        for (let i = 0; i < message.rankUsers.length; i++)
            RankUser.internalBinaryWrite(message.rankUsers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMttMatchFinalRankingResp
 */
export const GetMttMatchFinalRankingResp = new GetMttMatchFinalRankingResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RankUser$Type extends MessageType<RankUser> {
    constructor() {
        super("bpt.match.matchhub.v1.RankUser", [
            { no: 1, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<RankUser>): RankUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.rank = 0;
        message.uid = 0;
        message.prizes = [];
        if (value !== undefined)
            reflectionMergePartial<RankUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RankUser): RankUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 rank */ 1:
                    message.rank = reader.uint32();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizes */ 3:
                    message.prizes.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RankUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 rank = 1; */
        if (message.rank !== 0)
            writer.tag(1, WireType.Varint).uint32(message.rank);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* repeated bpt.common.AssetItem prizes = 3; */
        for (let i = 0; i < message.prizes.length; i++)
            AssetItem.internalBinaryWrite(message.prizes[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RankUser
 */
export const RankUser = new RankUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChipInfo$Type extends MessageType<ChipInfo> {
    constructor() {
        super("bpt.match.matchhub.v1.ChipInfo", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "avgChip", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "smallestChip", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "largestChip", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ChipInfo>): ChipInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.avgChip = 0;
        message.smallestChip = 0;
        message.largestChip = 0;
        if (value !== undefined)
            reflectionMergePartial<ChipInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChipInfo): ChipInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 avgChip */ 2:
                    message.avgChip = reader.int64().toNumber();
                    break;
                case /* int64 smallestChip */ 3:
                    message.smallestChip = reader.int64().toNumber();
                    break;
                case /* int64 largestChip */ 4:
                    message.largestChip = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChipInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 avgChip = 2; */
        if (message.avgChip !== 0)
            writer.tag(2, WireType.Varint).int64(message.avgChip);
        /* int64 smallestChip = 3; */
        if (message.smallestChip !== 0)
            writer.tag(3, WireType.Varint).int64(message.smallestChip);
        /* int64 largestChip = 4; */
        if (message.largestChip !== 0)
            writer.tag(4, WireType.Varint).int64(message.largestChip);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ChipInfo
 */
export const ChipInfo = new ChipInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChipInfoReq$Type extends MessageType<GetChipInfoReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetChipInfoReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetChipInfoReq>): GetChipInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetChipInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChipInfoReq): GetChipInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChipInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetChipInfoReq
 */
export const GetChipInfoReq = new GetChipInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChipInfoResp$Type extends MessageType<GetChipInfoResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetChipInfoResp", [
            { no: 2, name: "chipInfo", kind: "message", T: () => ChipInfo }
        ]);
    }
    create(value?: PartialMessage<GetChipInfoResp>): GetChipInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetChipInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChipInfoResp): GetChipInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.ChipInfo chipInfo */ 2:
                    message.chipInfo = ChipInfo.internalBinaryRead(reader, reader.uint32(), options, message.chipInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChipInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.ChipInfo chipInfo = 2; */
        if (message.chipInfo)
            ChipInfo.internalBinaryWrite(message.chipInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetChipInfoResp
 */
export const GetChipInfoResp = new GetChipInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyMatchNumReq$Type extends MessageType<GetMyMatchNumReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMyMatchNumReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMyMatchNumReq>): GetMyMatchNumReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMyMatchNumReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMyMatchNumReq): GetMyMatchNumReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMyMatchNumReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMyMatchNumReq
 */
export const GetMyMatchNumReq = new GetMyMatchNumReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMyMatchNumResp$Type extends MessageType<GetMyMatchNumResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMyMatchNumResp", [
            { no: 1, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMyMatchNumResp>): GetMyMatchNumResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMyMatchNumResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMyMatchNumResp): GetMyMatchNumResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 totalSize */ 1:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMyMatchNumResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 totalSize = 1; */
        if (message.totalSize !== 0)
            writer.tag(1, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMyMatchNumResp
 */
export const GetMyMatchNumResp = new GetMyMatchNumResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePoolReq$Type extends MessageType<UpdatePoolReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdatePoolReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<UpdatePoolReq>): UpdatePoolReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.prizePool = [];
        if (value !== undefined)
            reflectionMergePartial<UpdatePoolReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePoolReq): UpdatePoolReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 2:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePoolReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* repeated bpt.common.AssetItem prizePool = 2; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdatePoolReq
 */
export const UpdatePoolReq = new UpdatePoolReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePoolResp$Type extends MessageType<UpdatePoolResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UpdatePoolResp", []);
    }
    create(value?: PartialMessage<UpdatePoolResp>): UpdatePoolResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UpdatePoolResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePoolResp): UpdatePoolResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdatePoolResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UpdatePoolResp
 */
export const UpdatePoolResp = new UpdatePoolResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashMatchItem$Type extends MessageType<CashMatchItem> {
    constructor() {
        super("bpt.match.matchhub.v1.CashMatchItem", [
            { no: 1, name: "srvID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "confID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "entryUsers", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "userInfo", kind: "message", T: () => UserInfo },
            { no: 6, name: "confLastUpdate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 8, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "matchStatus", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 10, name: "isDelayRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 12, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "finalTableID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "isBubble", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "ext", kind: "message", T: () => ConfInfoExt },
            { no: 17, name: "userCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 19, name: "isAbleEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "isShowMyMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 21, name: "showPriority", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 22, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] }
        ]);
    }
    create(value?: PartialMessage<CashMatchItem>): CashMatchItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.srvID = 0;
        message.matchID = 0;
        message.confID = 0;
        message.entryUsers = [];
        message.confLastUpdate = 0;
        message.matchType = 0;
        message.startedAt = 0;
        message.matchStatus = 0;
        message.isDelayRegister = false;
        message.prizePool = [];
        message.matchKey = "";
        message.finalTableID = 0;
        message.isBubble = false;
        message.tag = "";
        message.userCnt = 0;
        message.registerDeadline = 0;
        message.isAbleEnter = false;
        message.isShowMyMatch = false;
        message.showPriority = 0;
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<CashMatchItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashMatchItem): CashMatchItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 srvID */ 1:
                    message.srvID = reader.int64().toNumber();
                    break;
                case /* int64 matchID */ 2:
                    message.matchID = reader.int64().toNumber();
                    break;
                case /* int64 confID */ 3:
                    message.confID = reader.int64().toNumber();
                    break;
                case /* repeated int64 entryUsers */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.entryUsers.push(reader.int64().toNumber());
                    else
                        message.entryUsers.push(reader.int64().toNumber());
                    break;
                case /* bpt.match.matchhub.v1.UserInfo userInfo */ 5:
                    message.userInfo = UserInfo.internalBinaryRead(reader, reader.uint32(), options, message.userInfo);
                    break;
                case /* int64 confLastUpdate */ 6:
                    message.confLastUpdate = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 7:
                    message.matchType = reader.int32();
                    break;
                case /* int64 startedAt */ 8:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchStatus matchStatus */ 9:
                    message.matchStatus = reader.int32();
                    break;
                case /* bool isDelayRegister */ 10:
                    message.isDelayRegister = reader.bool();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 11:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string matchKey */ 12:
                    message.matchKey = reader.string();
                    break;
                case /* int64 finalTableID */ 13:
                    message.finalTableID = reader.int64().toNumber();
                    break;
                case /* bool isBubble */ 14:
                    message.isBubble = reader.bool();
                    break;
                case /* string tag */ 15:
                    message.tag = reader.string();
                    break;
                case /* bpt.match.common.v1.ConfInfoExt ext */ 16:
                    message.ext = ConfInfoExt.internalBinaryRead(reader, reader.uint32(), options, message.ext);
                    break;
                case /* int64 userCnt */ 17:
                    message.userCnt = reader.int64().toNumber();
                    break;
                case /* int64 registerDeadline */ 18:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* bool isAbleEnter */ 19:
                    message.isAbleEnter = reader.bool();
                    break;
                case /* bool isShowMyMatch */ 20:
                    message.isShowMyMatch = reader.bool();
                    break;
                case /* int32 showPriority */ 21:
                    message.showPriority = reader.int32();
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 22:
                    message.matchListType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashMatchItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 srvID = 1; */
        if (message.srvID !== 0)
            writer.tag(1, WireType.Varint).int64(message.srvID);
        /* int64 matchID = 2; */
        if (message.matchID !== 0)
            writer.tag(2, WireType.Varint).int64(message.matchID);
        /* int64 confID = 3; */
        if (message.confID !== 0)
            writer.tag(3, WireType.Varint).int64(message.confID);
        /* repeated int64 entryUsers = 4; */
        if (message.entryUsers.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.entryUsers.length; i++)
                writer.int64(message.entryUsers[i]);
            writer.join();
        }
        /* bpt.match.matchhub.v1.UserInfo userInfo = 5; */
        if (message.userInfo)
            UserInfo.internalBinaryWrite(message.userInfo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int64 confLastUpdate = 6; */
        if (message.confLastUpdate !== 0)
            writer.tag(6, WireType.Varint).int64(message.confLastUpdate);
        /* bpt.match.common.v1.MatchType matchType = 7; */
        if (message.matchType !== 0)
            writer.tag(7, WireType.Varint).int32(message.matchType);
        /* int64 startedAt = 8; */
        if (message.startedAt !== 0)
            writer.tag(8, WireType.Varint).int64(message.startedAt);
        /* bpt.match.common.v1.MatchStatus matchStatus = 9; */
        if (message.matchStatus !== 0)
            writer.tag(9, WireType.Varint).int32(message.matchStatus);
        /* bool isDelayRegister = 10; */
        if (message.isDelayRegister !== false)
            writer.tag(10, WireType.Varint).bool(message.isDelayRegister);
        /* repeated bpt.common.AssetItem prizePool = 11; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* string matchKey = 12; */
        if (message.matchKey !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.matchKey);
        /* int64 finalTableID = 13; */
        if (message.finalTableID !== 0)
            writer.tag(13, WireType.Varint).int64(message.finalTableID);
        /* bool isBubble = 14; */
        if (message.isBubble !== false)
            writer.tag(14, WireType.Varint).bool(message.isBubble);
        /* string tag = 15; */
        if (message.tag !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.tag);
        /* bpt.match.common.v1.ConfInfoExt ext = 16; */
        if (message.ext)
            ConfInfoExt.internalBinaryWrite(message.ext, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* int64 userCnt = 17; */
        if (message.userCnt !== 0)
            writer.tag(17, WireType.Varint).int64(message.userCnt);
        /* int64 registerDeadline = 18; */
        if (message.registerDeadline !== 0)
            writer.tag(18, WireType.Varint).int64(message.registerDeadline);
        /* bool isAbleEnter = 19; */
        if (message.isAbleEnter !== false)
            writer.tag(19, WireType.Varint).bool(message.isAbleEnter);
        /* bool isShowMyMatch = 20; */
        if (message.isShowMyMatch !== false)
            writer.tag(20, WireType.Varint).bool(message.isShowMyMatch);
        /* int32 showPriority = 21; */
        if (message.showPriority !== 0)
            writer.tag(21, WireType.Varint).int32(message.showPriority);
        /* bpt.match.common.v1.MatchListType matchListType = 22; */
        if (message.matchListType !== 0)
            writer.tag(22, WireType.Varint).int32(message.matchListType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.CashMatchItem
 */
export const CashMatchItem = new CashMatchItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserInfo$Type extends MessageType<UserInfo> {
    constructor() {
        super("bpt.match.matchhub.v1.UserInfo", [
            { no: 1, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "isOut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "isAbleReEntry", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UserInfo>): UserInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isRegister = false;
        message.isOut = false;
        message.isAbleReEntry = false;
        if (value !== undefined)
            reflectionMergePartial<UserInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserInfo): UserInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isRegister */ 1:
                    message.isRegister = reader.bool();
                    break;
                case /* bool isOut */ 2:
                    message.isOut = reader.bool();
                    break;
                case /* bool isAbleReEntry */ 3:
                    message.isAbleReEntry = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isRegister = 1; */
        if (message.isRegister !== false)
            writer.tag(1, WireType.Varint).bool(message.isRegister);
        /* bool isOut = 2; */
        if (message.isOut !== false)
            writer.tag(2, WireType.Varint).bool(message.isOut);
        /* bool isAbleReEntry = 3; */
        if (message.isAbleReEntry !== false)
            writer.tag(3, WireType.Varint).bool(message.isAbleReEntry);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserInfo
 */
export const UserInfo = new UserInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MttMatchItem$Type extends MessageType<MttMatchItem> {
    constructor() {
        super("bpt.match.matchhub.v1.MttMatchItem", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "showImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "showMobileImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "mobileShowImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "mobileShowImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "showImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "showImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 5, name: "matchUserStatus", kind: "message", T: () => MatchUserStatus },
            { no: 6, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "matchStatus", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 8, name: "isDelayRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "userCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "isAbleEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 12, name: "isShowMyMatch", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 14, name: "buyIns", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 15, name: "tickets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset },
            { no: 16, name: "matchListType", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 17, name: "mainMatchInfo", kind: "message", T: () => MainMatchInfo },
            { no: 22, name: "subMatchItem", kind: "message", T: () => MttMatchItem }
        ]);
    }
    create(value?: PartialMessage<MttMatchItem>): MttMatchItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.showImg = "";
        message.showMobileImg = "";
        message.mobileShowImgList = "";
        message.mobileShowImgDetail = "";
        message.showImgList = "";
        message.showImgDetail = "";
        message.prizePool = [];
        message.startedAt = 0;
        message.matchStatus = 0;
        message.isDelayRegister = false;
        message.userCnt = 0;
        message.registerDeadline = 0;
        message.isAbleEnter = false;
        message.isShowMyMatch = false;
        message.buyIns = [];
        message.tickets = [];
        message.matchListType = 0;
        if (value !== undefined)
            reflectionMergePartial<MttMatchItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MttMatchItem): MttMatchItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string showImg */ 2:
                    message.showImg = reader.string();
                    break;
                case /* string showMobileImg */ 3:
                    message.showMobileImg = reader.string();
                    break;
                case /* string mobileShowImgList */ 18:
                    message.mobileShowImgList = reader.string();
                    break;
                case /* string mobileShowImgDetail */ 19:
                    message.mobileShowImgDetail = reader.string();
                    break;
                case /* string showImgList */ 20:
                    message.showImgList = reader.string();
                    break;
                case /* string showImgDetail */ 21:
                    message.showImgDetail = reader.string();
                    break;
                case /* repeated bpt.match.matchhub.v1.SimpleAsset prizePool */ 4:
                    message.prizePool.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.MatchUserStatus matchUserStatus */ 5:
                    message.matchUserStatus = MatchUserStatus.internalBinaryRead(reader, reader.uint32(), options, message.matchUserStatus);
                    break;
                case /* int64 startedAt */ 6:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchStatus matchStatus */ 7:
                    message.matchStatus = reader.int32();
                    break;
                case /* bool isDelayRegister */ 8:
                    message.isDelayRegister = reader.bool();
                    break;
                case /* int64 userCnt */ 9:
                    message.userCnt = reader.int64().toNumber();
                    break;
                case /* int64 registerDeadline */ 10:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* bool isAbleEnter */ 11:
                    message.isAbleEnter = reader.bool();
                    break;
                case /* bool isShowMyMatch */ 12:
                    message.isShowMyMatch = reader.bool();
                    break;
                case /* bpt.common.I18n i18n = 13 [json_name = "i18n"];*/ 13:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* repeated bpt.match.matchhub.v1.SimpleAsset buyIns */ 14:
                    message.buyIns.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchhub.v1.SimpleAsset tickets */ 15:
                    message.tickets.push(SimpleAsset.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.common.v1.MatchListType matchListType */ 16:
                    message.matchListType = reader.int32();
                    break;
                case /* bpt.match.common.v1.MainMatchInfo mainMatchInfo */ 17:
                    message.mainMatchInfo = MainMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.mainMatchInfo);
                    break;
                case /* bpt.match.matchhub.v1.MttMatchItem subMatchItem */ 22:
                    message.subMatchItem = MttMatchItem.internalBinaryRead(reader, reader.uint32(), options, message.subMatchItem);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MttMatchItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string showImg = 2; */
        if (message.showImg !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.showImg);
        /* string showMobileImg = 3; */
        if (message.showMobileImg !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.showMobileImg);
        /* string mobileShowImgList = 18; */
        if (message.mobileShowImgList !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.mobileShowImgList);
        /* string mobileShowImgDetail = 19; */
        if (message.mobileShowImgDetail !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.mobileShowImgDetail);
        /* string showImgList = 20; */
        if (message.showImgList !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.showImgList);
        /* string showImgDetail = 21; */
        if (message.showImgDetail !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.showImgDetail);
        /* repeated bpt.match.matchhub.v1.SimpleAsset prizePool = 4; */
        for (let i = 0; i < message.prizePool.length; i++)
            SimpleAsset.internalBinaryWrite(message.prizePool[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchUserStatus matchUserStatus = 5; */
        if (message.matchUserStatus)
            MatchUserStatus.internalBinaryWrite(message.matchUserStatus, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int64 startedAt = 6; */
        if (message.startedAt !== 0)
            writer.tag(6, WireType.Varint).int64(message.startedAt);
        /* bpt.match.common.v1.MatchStatus matchStatus = 7; */
        if (message.matchStatus !== 0)
            writer.tag(7, WireType.Varint).int32(message.matchStatus);
        /* bool isDelayRegister = 8; */
        if (message.isDelayRegister !== false)
            writer.tag(8, WireType.Varint).bool(message.isDelayRegister);
        /* int64 userCnt = 9; */
        if (message.userCnt !== 0)
            writer.tag(9, WireType.Varint).int64(message.userCnt);
        /* int64 registerDeadline = 10; */
        if (message.registerDeadline !== 0)
            writer.tag(10, WireType.Varint).int64(message.registerDeadline);
        /* bool isAbleEnter = 11; */
        if (message.isAbleEnter !== false)
            writer.tag(11, WireType.Varint).bool(message.isAbleEnter);
        /* bool isShowMyMatch = 12; */
        if (message.isShowMyMatch !== false)
            writer.tag(12, WireType.Varint).bool(message.isShowMyMatch);
        /* bpt.common.I18n i18n = 13 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SimpleAsset buyIns = 14; */
        for (let i = 0; i < message.buyIns.length; i++)
            SimpleAsset.internalBinaryWrite(message.buyIns[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SimpleAsset tickets = 15; */
        for (let i = 0; i < message.tickets.length; i++)
            SimpleAsset.internalBinaryWrite(message.tickets[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.MatchListType matchListType = 16; */
        if (message.matchListType !== 0)
            writer.tag(16, WireType.Varint).int32(message.matchListType);
        /* bpt.match.common.v1.MainMatchInfo mainMatchInfo = 17; */
        if (message.mainMatchInfo)
            MainMatchInfo.internalBinaryWrite(message.mainMatchInfo, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MttMatchItem subMatchItem = 22; */
        if (message.subMatchItem)
            MttMatchItem.internalBinaryWrite(message.subMatchItem, writer.tag(22, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MttMatchItem
 */
export const MttMatchItem = new MttMatchItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SimpleAsset$Type extends MessageType<SimpleAsset> {
    constructor() {
        super("bpt.match.matchhub.v1.SimpleAsset", [
            { no: 1, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rawAmount", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SimpleAsset>): SimpleAsset {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.propId = 0;
        message.rawAmount = "";
        if (value !== undefined)
            reflectionMergePartial<SimpleAsset>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SimpleAsset): SimpleAsset {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 propId */ 1:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* string rawAmount */ 2:
                    message.rawAmount = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SimpleAsset, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 propId = 1; */
        if (message.propId !== 0)
            writer.tag(1, WireType.Varint).int64(message.propId);
        /* string rawAmount = 2; */
        if (message.rawAmount !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rawAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SimpleAsset
 */
export const SimpleAsset = new SimpleAsset$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchUserStatus$Type extends MessageType<MatchUserStatus> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchUserStatus", [
            { no: 1, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "isAllowReEntry", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "isOut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<MatchUserStatus>): MatchUserStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isRegister = false;
        message.isAllowReEntry = false;
        message.isOut = false;
        if (value !== undefined)
            reflectionMergePartial<MatchUserStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchUserStatus): MatchUserStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isRegister */ 1:
                    message.isRegister = reader.bool();
                    break;
                case /* bool isAllowReEntry */ 3:
                    message.isAllowReEntry = reader.bool();
                    break;
                case /* bool isOut */ 4:
                    message.isOut = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchUserStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isRegister = 1; */
        if (message.isRegister !== false)
            writer.tag(1, WireType.Varint).bool(message.isRegister);
        /* bool isAllowReEntry = 3; */
        if (message.isAllowReEntry !== false)
            writer.tag(3, WireType.Varint).bool(message.isAllowReEntry);
        /* bool isOut = 4; */
        if (message.isOut !== false)
            writer.tag(4, WireType.Varint).bool(message.isOut);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchUserStatus
 */
export const MatchUserStatus = new MatchUserStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserStatus$Type extends MessageType<UserStatus> {
    constructor() {
        super("bpt.match.matchhub.v1.UserStatus", [
            { no: 1, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "isOut", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "isJoin", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UserStatus>): UserStatus {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isRegister = false;
        message.isOut = false;
        message.isJoin = false;
        if (value !== undefined)
            reflectionMergePartial<UserStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserStatus): UserStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool isRegister */ 1:
                    message.isRegister = reader.bool();
                    break;
                case /* bool isOut */ 2:
                    message.isOut = reader.bool();
                    break;
                case /* bool isJoin */ 3:
                    message.isJoin = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool isRegister = 1; */
        if (message.isRegister !== false)
            writer.tag(1, WireType.Varint).bool(message.isRegister);
        /* bool isOut = 2; */
        if (message.isOut !== false)
            writer.tag(2, WireType.Varint).bool(message.isOut);
        /* bool isJoin = 3; */
        if (message.isJoin !== false)
            writer.tag(3, WireType.Varint).bool(message.isJoin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserStatus
 */
export const UserStatus = new UserStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListTableViewsResp$Type extends MessageType<ListTableViewsResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListTableViewsResp", [
            { no: 1, name: "tableViews", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TableView }
        ]);
    }
    create(value?: PartialMessage<ListTableViewsResp>): ListTableViewsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableViews = [];
        if (value !== undefined)
            reflectionMergePartial<ListTableViewsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTableViewsResp): ListTableViewsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.TableView tableViews */ 1:
                    message.tableViews.push(TableView.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTableViewsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.TableView tableViews = 1; */
        for (let i = 0; i < message.tableViews.length; i++)
            TableView.internalBinaryWrite(message.tableViews[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListTableViewsResp
 */
export const ListTableViewsResp = new ListTableViewsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddTableViewReq$Type extends MessageType<AddTableViewReq> {
    constructor() {
        super("bpt.match.matchhub.v1.AddTableViewReq", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 5, name: "forceReplace", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "replaceIndex", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "reason", kind: "enum", T: () => ["bpt.common.TableViewChangeReason", TableViewChangeReason] }
        ]);
    }
    create(value?: PartialMessage<AddTableViewReq>): AddTableViewReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.matchKey = "";
        message.uid = 0;
        message.matchType = 0;
        message.forceReplace = false;
        message.replaceIndex = 0;
        message.reason = 0;
        if (value !== undefined)
            reflectionMergePartial<AddTableViewReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddTableViewReq): AddTableViewReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* int64 uid */ 3:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* bool forceReplace */ 5:
                    message.forceReplace = reader.bool();
                    break;
                case /* int32 replaceIndex */ 6:
                    message.replaceIndex = reader.int32();
                    break;
                case /* bpt.common.TableViewChangeReason reason */ 7:
                    message.reason = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddTableViewReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* int64 uid = 3; */
        if (message.uid !== 0)
            writer.tag(3, WireType.Varint).int64(message.uid);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* bool forceReplace = 5; */
        if (message.forceReplace !== false)
            writer.tag(5, WireType.Varint).bool(message.forceReplace);
        /* int32 replaceIndex = 6; */
        if (message.replaceIndex !== 0)
            writer.tag(6, WireType.Varint).int32(message.replaceIndex);
        /* bpt.common.TableViewChangeReason reason = 7; */
        if (message.reason !== 0)
            writer.tag(7, WireType.Varint).int32(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AddTableViewReq
 */
export const AddTableViewReq = new AddTableViewReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchAddTableViewReq$Type extends MessageType<BatchAddTableViewReq> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchAddTableViewReq", [
            { no: 1, name: "MatchKey", kind: "scalar", localName: "MatchKey", jsonName: "MatchKey", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "TableKey", kind: "scalar", localName: "TableKey", jsonName: "TableKey", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "uids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 7, name: "reason", kind: "enum", T: () => ["bpt.common.TableViewChangeReason", TableViewChangeReason] }
        ]);
    }
    create(value?: PartialMessage<BatchAddTableViewReq>): BatchAddTableViewReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.MatchKey = "";
        message.TableKey = "";
        message.uids = [];
        message.matchType = 0;
        message.reason = 0;
        if (value !== undefined)
            reflectionMergePartial<BatchAddTableViewReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchAddTableViewReq): BatchAddTableViewReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string MatchKey = 1 [json_name = "MatchKey"];*/ 1:
                    message.MatchKey = reader.string();
                    break;
                case /* string TableKey = 2 [json_name = "TableKey"];*/ 2:
                    message.TableKey = reader.string();
                    break;
                case /* repeated int64 uids */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.uids.push(reader.int64().toNumber());
                    else
                        message.uids.push(reader.int64().toNumber());
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.common.TableViewChangeReason reason */ 7:
                    message.reason = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchAddTableViewReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string MatchKey = 1 [json_name = "MatchKey"]; */
        if (message.MatchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.MatchKey);
        /* string TableKey = 2 [json_name = "TableKey"]; */
        if (message.TableKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.TableKey);
        /* repeated int64 uids = 3; */
        if (message.uids.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.uids.length; i++)
                writer.int64(message.uids[i]);
            writer.join();
        }
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* bpt.common.TableViewChangeReason reason = 7; */
        if (message.reason !== 0)
            writer.tag(7, WireType.Varint).int32(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchAddTableViewReq
 */
export const BatchAddTableViewReq = new BatchAddTableViewReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveTableViewReq$Type extends MessageType<RemoveTableViewReq> {
    constructor() {
        super("bpt.match.matchhub.v1.RemoveTableViewReq", [
            { no: 1, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 7, name: "reason", kind: "enum", T: () => ["bpt.common.TableViewChangeReason", TableViewChangeReason] }
        ]);
    }
    create(value?: PartialMessage<RemoveTableViewReq>): RemoveTableViewReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tableKey = "";
        message.uid = 0;
        message.matchKey = "";
        message.matchType = 0;
        message.reason = 0;
        if (value !== undefined)
            reflectionMergePartial<RemoveTableViewReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveTableViewReq): RemoveTableViewReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tableKey */ 1:
                    message.tableKey = reader.string();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 3:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 4:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.common.TableViewChangeReason reason */ 7:
                    message.reason = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveTableViewReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tableKey = 1; */
        if (message.tableKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tableKey);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        /* string matchKey = 3; */
        if (message.matchKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.common.v1.MatchType matchType = 4; */
        if (message.matchType !== 0)
            writer.tag(4, WireType.Varint).int32(message.matchType);
        /* bpt.common.TableViewChangeReason reason = 7; */
        if (message.reason !== 0)
            writer.tag(7, WireType.Varint).int32(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RemoveTableViewReq
 */
export const RemoveTableViewReq = new RemoveTableViewReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchBriefReq$Type extends MessageType<GetMatchBriefReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchBriefReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchBriefReq>): GetMatchBriefReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchBriefReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchBriefReq): GetMatchBriefReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchBriefReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchBriefReq
 */
export const GetMatchBriefReq = new GetMatchBriefReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchBriefResp$Type extends MessageType<GetMatchBriefResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchBriefResp", [
            { no: 1, name: "match", kind: "message", T: () => MatchFront },
            { no: 2, name: "userMatchInfo", kind: "message", T: () => UserMatchInfo }
        ]);
    }
    create(value?: PartialMessage<GetMatchBriefResp>): GetMatchBriefResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchBriefResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchBriefResp): GetMatchBriefResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchFront match */ 1:
                    message.match = MatchFront.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                case /* bpt.match.matchhub.v1.UserMatchInfo userMatchInfo */ 2:
                    message.userMatchInfo = UserMatchInfo.internalBinaryRead(reader, reader.uint32(), options, message.userMatchInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchBriefResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchFront match = 1; */
        if (message.match)
            MatchFront.internalBinaryWrite(message.match, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.UserMatchInfo userMatchInfo = 2; */
        if (message.userMatchInfo)
            UserMatchInfo.internalBinaryWrite(message.userMatchInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchBriefResp
 */
export const GetMatchBriefResp = new GetMatchBriefResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubMatchesReq$Type extends MessageType<ListSubMatchesReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListSubMatchesReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListSubMatchesReq>): ListSubMatchesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ListSubMatchesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSubMatchesReq): ListSubMatchesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSubMatchesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListSubMatchesReq
 */
export const ListSubMatchesReq = new ListSubMatchesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubMatch$Type extends MessageType<SubMatch> {
    constructor() {
        super("bpt.match.matchhub.v1.SubMatch", [
            { no: 1, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.MatchStatus", MatchStatus] },
            { no: 4, name: "userStatus", kind: "enum", T: () => ["bpt.match.common.v1.UserStatus", UserStatus$] },
            { no: 5, name: "isRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "isAdvanced", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "isDelayRegister", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 9, name: "gameOverType", kind: "enum", T: () => ["bpt.match.common.v1.GameOverType", GameOverType] },
            { no: 10, name: "phase", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "chips", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "canEnter", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "gameOverBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 14, name: "durationInSeconds", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "remainingNumber", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 17, name: "enterNumber", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 18, name: "matchNumber", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 19, name: "isLastEntry", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 20, name: "matchDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 21, name: "isEarlyEnd", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "registerDeadline", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 23, name: "pauseBeginAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 24, name: "pauseEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 25, name: "registrationAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 26, name: "isInheritStack", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SubMatch>): SubMatch {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchName = "";
        message.startedAt = 0;
        message.state = 0;
        message.userStatus = 0;
        message.isRegister = false;
        message.isAdvanced = false;
        message.matchKey = "";
        message.isDelayRegister = false;
        message.gameOverType = 0;
        message.phase = 0;
        message.chips = 0;
        message.canEnter = false;
        message.gameOverBlindLevel = 0;
        message.durationInSeconds = 0;
        message.remainingNumber = 0;
        message.enterNumber = 0;
        message.matchNumber = 0;
        message.isLastEntry = false;
        message.matchDuration = 0;
        message.isEarlyEnd = false;
        message.registerDeadline = 0;
        message.pauseBeginAt = 0;
        message.pauseEndAt = 0;
        message.registrationAt = 0;
        message.isInheritStack = false;
        if (value !== undefined)
            reflectionMergePartial<SubMatch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubMatch): SubMatch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchName */ 1:
                    message.matchName = reader.string();
                    break;
                case /* int64 startedAt */ 2:
                    message.startedAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchStatus state */ 3:
                    message.state = reader.int32();
                    break;
                case /* bpt.match.common.v1.UserStatus userStatus */ 4:
                    message.userStatus = reader.int32();
                    break;
                case /* bool isRegister */ 5:
                    message.isRegister = reader.bool();
                    break;
                case /* bool isAdvanced */ 6:
                    message.isAdvanced = reader.bool();
                    break;
                case /* string matchKey */ 7:
                    message.matchKey = reader.string();
                    break;
                case /* bool isDelayRegister */ 8:
                    message.isDelayRegister = reader.bool();
                    break;
                case /* bpt.match.common.v1.GameOverType gameOverType */ 9:
                    message.gameOverType = reader.int32();
                    break;
                case /* int32 phase */ 10:
                    message.phase = reader.int32();
                    break;
                case /* int64 chips */ 11:
                    message.chips = reader.int64().toNumber();
                    break;
                case /* bool canEnter */ 12:
                    message.canEnter = reader.bool();
                    break;
                case /* uint32 gameOverBlindLevel */ 13:
                    message.gameOverBlindLevel = reader.uint32();
                    break;
                case /* int64 durationInSeconds */ 14:
                    message.durationInSeconds = reader.int64().toNumber();
                    break;
                case /* int64 remainingNumber */ 15:
                    message.remainingNumber = reader.int64().toNumber();
                    break;
                case /* bpt.common.I18n i18n = 16 [json_name = "i18n"];*/ 16:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* int64 enterNumber */ 17:
                    message.enterNumber = reader.int64().toNumber();
                    break;
                case /* int64 matchNumber */ 18:
                    message.matchNumber = reader.int64().toNumber();
                    break;
                case /* bool isLastEntry */ 19:
                    message.isLastEntry = reader.bool();
                    break;
                case /* int64 matchDuration */ 20:
                    message.matchDuration = reader.int64().toNumber();
                    break;
                case /* bool isEarlyEnd */ 21:
                    message.isEarlyEnd = reader.bool();
                    break;
                case /* int64 registerDeadline */ 22:
                    message.registerDeadline = reader.int64().toNumber();
                    break;
                case /* int64 pauseBeginAt */ 23:
                    message.pauseBeginAt = reader.int64().toNumber();
                    break;
                case /* int64 pauseEndAt */ 24:
                    message.pauseEndAt = reader.int64().toNumber();
                    break;
                case /* int64 registrationAt */ 25:
                    message.registrationAt = reader.int64().toNumber();
                    break;
                case /* bool isInheritStack */ 26:
                    message.isInheritStack = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubMatch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchName = 1; */
        if (message.matchName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchName);
        /* int64 startedAt = 2; */
        if (message.startedAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.startedAt);
        /* bpt.match.common.v1.MatchStatus state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        /* bpt.match.common.v1.UserStatus userStatus = 4; */
        if (message.userStatus !== 0)
            writer.tag(4, WireType.Varint).int32(message.userStatus);
        /* bool isRegister = 5; */
        if (message.isRegister !== false)
            writer.tag(5, WireType.Varint).bool(message.isRegister);
        /* bool isAdvanced = 6; */
        if (message.isAdvanced !== false)
            writer.tag(6, WireType.Varint).bool(message.isAdvanced);
        /* string matchKey = 7; */
        if (message.matchKey !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.matchKey);
        /* bool isDelayRegister = 8; */
        if (message.isDelayRegister !== false)
            writer.tag(8, WireType.Varint).bool(message.isDelayRegister);
        /* bpt.match.common.v1.GameOverType gameOverType = 9; */
        if (message.gameOverType !== 0)
            writer.tag(9, WireType.Varint).int32(message.gameOverType);
        /* int32 phase = 10; */
        if (message.phase !== 0)
            writer.tag(10, WireType.Varint).int32(message.phase);
        /* int64 chips = 11; */
        if (message.chips !== 0)
            writer.tag(11, WireType.Varint).int64(message.chips);
        /* bool canEnter = 12; */
        if (message.canEnter !== false)
            writer.tag(12, WireType.Varint).bool(message.canEnter);
        /* uint32 gameOverBlindLevel = 13; */
        if (message.gameOverBlindLevel !== 0)
            writer.tag(13, WireType.Varint).uint32(message.gameOverBlindLevel);
        /* int64 durationInSeconds = 14; */
        if (message.durationInSeconds !== 0)
            writer.tag(14, WireType.Varint).int64(message.durationInSeconds);
        /* int64 remainingNumber = 15; */
        if (message.remainingNumber !== 0)
            writer.tag(15, WireType.Varint).int64(message.remainingNumber);
        /* bpt.common.I18n i18n = 16 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* int64 enterNumber = 17; */
        if (message.enterNumber !== 0)
            writer.tag(17, WireType.Varint).int64(message.enterNumber);
        /* int64 matchNumber = 18; */
        if (message.matchNumber !== 0)
            writer.tag(18, WireType.Varint).int64(message.matchNumber);
        /* bool isLastEntry = 19; */
        if (message.isLastEntry !== false)
            writer.tag(19, WireType.Varint).bool(message.isLastEntry);
        /* int64 matchDuration = 20; */
        if (message.matchDuration !== 0)
            writer.tag(20, WireType.Varint).int64(message.matchDuration);
        /* bool isEarlyEnd = 21; */
        if (message.isEarlyEnd !== false)
            writer.tag(21, WireType.Varint).bool(message.isEarlyEnd);
        /* int64 registerDeadline = 22; */
        if (message.registerDeadline !== 0)
            writer.tag(22, WireType.Varint).int64(message.registerDeadline);
        /* int64 pauseBeginAt = 23; */
        if (message.pauseBeginAt !== 0)
            writer.tag(23, WireType.Varint).int64(message.pauseBeginAt);
        /* int64 pauseEndAt = 24; */
        if (message.pauseEndAt !== 0)
            writer.tag(24, WireType.Varint).int64(message.pauseEndAt);
        /* int64 registrationAt = 25; */
        if (message.registrationAt !== 0)
            writer.tag(25, WireType.Varint).int64(message.registrationAt);
        /* bool isInheritStack = 26; */
        if (message.isInheritStack !== false)
            writer.tag(26, WireType.Varint).bool(message.isInheritStack);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SubMatch
 */
export const SubMatch = new SubMatch$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListSubMatchesResp$Type extends MessageType<ListSubMatchesResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListSubMatchesResp", [
            { no: 1, name: "subMatches", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatch }
        ]);
    }
    create(value?: PartialMessage<ListSubMatchesResp>): ListSubMatchesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subMatches = [];
        if (value !== undefined)
            reflectionMergePartial<ListSubMatchesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListSubMatchesResp): ListSubMatchesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.SubMatch subMatches */ 1:
                    message.subMatches.push(SubMatch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListSubMatchesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.SubMatch subMatches = 1; */
        for (let i = 0; i < message.subMatches.length; i++)
            SubMatch.internalBinaryWrite(message.subMatches[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListSubMatchesResp
 */
export const ListSubMatchesResp = new ListSubMatchesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDayOneMaxChipsReq$Type extends MessageType<GetDayOneMaxChipsReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetDayOneMaxChipsReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDayOneMaxChipsReq>): GetDayOneMaxChipsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetDayOneMaxChipsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDayOneMaxChipsReq): GetDayOneMaxChipsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDayOneMaxChipsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetDayOneMaxChipsReq
 */
export const GetDayOneMaxChipsReq = new GetDayOneMaxChipsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDayOneMaxChipsResp$Type extends MessageType<GetDayOneMaxChipsResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetDayOneMaxChipsResp", [
            { no: 1, name: "chips", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetDayOneMaxChipsResp>): GetDayOneMaxChipsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chips = 0;
        if (value !== undefined)
            reflectionMergePartial<GetDayOneMaxChipsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDayOneMaxChipsResp): GetDayOneMaxChipsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 chips */ 1:
                    message.chips = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDayOneMaxChipsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 chips = 1; */
        if (message.chips !== 0)
            writer.tag(1, WireType.Varint).int64(message.chips);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetDayOneMaxChipsResp
 */
export const GetDayOneMaxChipsResp = new GetDayOneMaxChipsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchNameAndStartAtReq$Type extends MessageType<GetMatchNameAndStartAtReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchNameAndStartAtReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchNameAndStartAtReq>): GetMatchNameAndStartAtReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchNameAndStartAtReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchNameAndStartAtReq): GetMatchNameAndStartAtReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchNameAndStartAtReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchNameAndStartAtReq
 */
export const GetMatchNameAndStartAtReq = new GetMatchNameAndStartAtReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchNameAndStartAtResp$Type extends MessageType<GetMatchNameAndStartAtResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchNameAndStartAtResp", [
            { no: 1, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "i18name", kind: "message", localName: "i18name", jsonName: "i18name", T: () => I18n }
        ]);
    }
    create(value?: PartialMessage<GetMatchNameAndStartAtResp>): GetMatchNameAndStartAtResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchName = "";
        message.startAt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchNameAndStartAtResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchNameAndStartAtResp): GetMatchNameAndStartAtResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchName */ 1:
                    message.matchName = reader.string();
                    break;
                case /* int64 startAt */ 2:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.I18n i18name = 4 [json_name = "i18name"];*/ 4:
                    message.i18name = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18name);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchNameAndStartAtResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchName = 1; */
        if (message.matchName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchName);
        /* int64 startAt = 2; */
        if (message.startAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.startAt);
        /* bpt.common.I18n i18name = 4 [json_name = "i18name"]; */
        if (message.i18name)
            I18n.internalBinaryWrite(message.i18name, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchNameAndStartAtResp
 */
export const GetMatchNameAndStartAtResp = new GetMatchNameAndStartAtResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchNameAndStartAtReq$Type extends MessageType<BatchGetMatchNameAndStartAtReq> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtReq", [
            { no: 1, name: "matchKeys", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchNameAndStartAtReq>): BatchGetMatchNameAndStartAtReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKeys = [];
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchNameAndStartAtReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchNameAndStartAtReq): BatchGetMatchNameAndStartAtReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string matchKeys */ 1:
                    message.matchKeys.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetMatchNameAndStartAtReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string matchKeys = 1; */
        for (let i = 0; i < message.matchKeys.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.matchKeys[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtReq
 */
export const BatchGetMatchNameAndStartAtReq = new BatchGetMatchNameAndStartAtReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchNameAndStartAt$Type extends MessageType<MatchNameAndStartAt> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchNameAndStartAt", [
            { no: 1, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "i18name", kind: "message", localName: "i18name", jsonName: "i18name", T: () => I18n }
        ]);
    }
    create(value?: PartialMessage<MatchNameAndStartAt>): MatchNameAndStartAt {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startAt = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchNameAndStartAt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchNameAndStartAt): MatchNameAndStartAt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 startAt */ 1:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* bpt.common.I18n i18name = 2 [json_name = "i18name"];*/ 2:
                    message.i18name = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18name);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchNameAndStartAt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 startAt = 1; */
        if (message.startAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.startAt);
        /* bpt.common.I18n i18name = 2 [json_name = "i18name"]; */
        if (message.i18name)
            I18n.internalBinaryWrite(message.i18name, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchNameAndStartAt
 */
export const MatchNameAndStartAt = new MatchNameAndStartAt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetMatchNameAndStartAtResp$Type extends MessageType<BatchGetMatchNameAndStartAtResp> {
    constructor() {
        super("bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtResp", [
            { no: 1, name: "matchNameAndStartAt", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => MatchNameAndStartAt } }
        ]);
    }
    create(value?: PartialMessage<BatchGetMatchNameAndStartAtResp>): BatchGetMatchNameAndStartAtResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchNameAndStartAt = {};
        if (value !== undefined)
            reflectionMergePartial<BatchGetMatchNameAndStartAtResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetMatchNameAndStartAtResp): BatchGetMatchNameAndStartAtResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, bpt.match.matchhub.v1.MatchNameAndStartAt> matchNameAndStartAt */ 1:
                    this.binaryReadMap1(message.matchNameAndStartAt, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: BatchGetMatchNameAndStartAtResp["matchNameAndStartAt"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BatchGetMatchNameAndStartAtResp["matchNameAndStartAt"] | undefined, val: BatchGetMatchNameAndStartAtResp["matchNameAndStartAt"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = MatchNameAndStartAt.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtResp.matchNameAndStartAt");
            }
        }
        map[key ?? ""] = val ?? MatchNameAndStartAt.create();
    }
    internalBinaryWrite(message: BatchGetMatchNameAndStartAtResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, bpt.match.matchhub.v1.MatchNameAndStartAt> matchNameAndStartAt = 1; */
        for (let k of globalThis.Object.keys(message.matchNameAndStartAt)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            MatchNameAndStartAt.internalBinaryWrite(message.matchNameAndStartAt[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.BatchGetMatchNameAndStartAtResp
 */
export const BatchGetMatchNameAndStartAtResp = new BatchGetMatchNameAndStartAtResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPrizePoolReq$Type extends MessageType<ListPrizePoolReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListPrizePoolReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListPrizePoolReq>): ListPrizePoolReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ListPrizePoolReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPrizePoolReq): ListPrizePoolReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPrizePoolReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListPrizePoolReq
 */
export const ListPrizePoolReq = new ListPrizePoolReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrizePoolEntry$Type extends MessageType<PrizePoolEntry> {
    constructor() {
        super("bpt.match.matchhub.v1.PrizePoolEntry", [
            { no: 1, name: "prize", kind: "message", T: () => AssetItem },
            { no: 3, name: "rankRange", kind: "message", T: () => SectionRange },
            { no: 4, name: "tenThousandShare", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "prizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<PrizePoolEntry>): PrizePoolEntry {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tenThousandShare = 0;
        message.prizes = [];
        if (value !== undefined)
            reflectionMergePartial<PrizePoolEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrizePoolEntry): PrizePoolEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.AssetItem prize */ 1:
                    message.prize = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.prize);
                    break;
                case /* bpt.match.common.v1.SectionRange rankRange */ 3:
                    message.rankRange = SectionRange.internalBinaryRead(reader, reader.uint32(), options, message.rankRange);
                    break;
                case /* uint32 tenThousandShare */ 4:
                    message.tenThousandShare = reader.uint32();
                    break;
                case /* repeated bpt.common.AssetItem prizes */ 5:
                    message.prizes.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrizePoolEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.AssetItem prize = 1; */
        if (message.prize)
            AssetItem.internalBinaryWrite(message.prize, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.SectionRange rankRange = 3; */
        if (message.rankRange)
            SectionRange.internalBinaryWrite(message.rankRange, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* uint32 tenThousandShare = 4; */
        if (message.tenThousandShare !== 0)
            writer.tag(4, WireType.Varint).uint32(message.tenThousandShare);
        /* repeated bpt.common.AssetItem prizes = 5; */
        for (let i = 0; i < message.prizes.length; i++)
            AssetItem.internalBinaryWrite(message.prizes[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.PrizePoolEntry
 */
export const PrizePoolEntry = new PrizePoolEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListPrizePoolResp$Type extends MessageType<ListPrizePoolResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListPrizePoolResp", [
            { no: 1, name: "prizeType", kind: "enum", T: () => ["bpt.match.common.v1.PrizeType", PrizeType] },
            { no: 2, name: "addOnCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "reBuyCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "reEntryCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "totalPlayer", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrizePoolEntry },
            { no: 7, name: "addOnPlayerCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "reBuyPlayerCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "reEntryPlayerCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 11, name: "prizeNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "reentryPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 13, name: "addonPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 14, name: "rebuyPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 15, name: "dynamicPrizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 16, name: "basicPrizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 17, name: "prizeAdvance", kind: "message", T: () => PrizeAdvance },
            { no: 18, name: "prizePoolThreshold", kind: "message", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<ListPrizePoolResp>): ListPrizePoolResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizeType = 0;
        message.addOnCnt = 0;
        message.reBuyCnt = 0;
        message.reEntryCnt = 0;
        message.totalPlayer = 0;
        message.entries = [];
        message.addOnPlayerCnt = 0;
        message.reBuyPlayerCnt = 0;
        message.reEntryPlayerCnt = 0;
        message.prizePool = [];
        message.prizeNum = 0;
        message.reentryPrize = [];
        message.addonPrize = [];
        message.rebuyPrize = [];
        message.dynamicPrizePool = [];
        message.basicPrizePool = [];
        if (value !== undefined)
            reflectionMergePartial<ListPrizePoolResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListPrizePoolResp): ListPrizePoolResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.PrizeType prizeType */ 1:
                    message.prizeType = reader.int32();
                    break;
                case /* int64 addOnCnt */ 2:
                    message.addOnCnt = reader.int64().toNumber();
                    break;
                case /* int64 reBuyCnt */ 3:
                    message.reBuyCnt = reader.int64().toNumber();
                    break;
                case /* int64 reEntryCnt */ 4:
                    message.reEntryCnt = reader.int64().toNumber();
                    break;
                case /* int64 totalPlayer */ 5:
                    message.totalPlayer = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchhub.v1.PrizePoolEntry entries */ 6:
                    message.entries.push(PrizePoolEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 addOnPlayerCnt */ 7:
                    message.addOnPlayerCnt = reader.int64().toNumber();
                    break;
                case /* int64 reBuyPlayerCnt */ 8:
                    message.reBuyPlayerCnt = reader.int64().toNumber();
                    break;
                case /* int64 reEntryPlayerCnt */ 9:
                    message.reEntryPlayerCnt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 10:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 prizeNum */ 11:
                    message.prizeNum = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem reentryPrize */ 12:
                    message.reentryPrize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem addonPrize */ 13:
                    message.addonPrize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem rebuyPrize */ 14:
                    message.rebuyPrize.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem dynamicPrizePool */ 15:
                    message.dynamicPrizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.AssetItem basicPrizePool */ 16:
                    message.basicPrizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.PrizeAdvance prizeAdvance */ 17:
                    message.prizeAdvance = PrizeAdvance.internalBinaryRead(reader, reader.uint32(), options, message.prizeAdvance);
                    break;
                case /* bpt.common.AssetItem prizePoolThreshold */ 18:
                    message.prizePoolThreshold = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.prizePoolThreshold);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListPrizePoolResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.PrizeType prizeType = 1; */
        if (message.prizeType !== 0)
            writer.tag(1, WireType.Varint).int32(message.prizeType);
        /* int64 addOnCnt = 2; */
        if (message.addOnCnt !== 0)
            writer.tag(2, WireType.Varint).int64(message.addOnCnt);
        /* int64 reBuyCnt = 3; */
        if (message.reBuyCnt !== 0)
            writer.tag(3, WireType.Varint).int64(message.reBuyCnt);
        /* int64 reEntryCnt = 4; */
        if (message.reEntryCnt !== 0)
            writer.tag(4, WireType.Varint).int64(message.reEntryCnt);
        /* int64 totalPlayer = 5; */
        if (message.totalPlayer !== 0)
            writer.tag(5, WireType.Varint).int64(message.totalPlayer);
        /* repeated bpt.match.matchhub.v1.PrizePoolEntry entries = 6; */
        for (let i = 0; i < message.entries.length; i++)
            PrizePoolEntry.internalBinaryWrite(message.entries[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* int64 addOnPlayerCnt = 7; */
        if (message.addOnPlayerCnt !== 0)
            writer.tag(7, WireType.Varint).int64(message.addOnPlayerCnt);
        /* int64 reBuyPlayerCnt = 8; */
        if (message.reBuyPlayerCnt !== 0)
            writer.tag(8, WireType.Varint).int64(message.reBuyPlayerCnt);
        /* int64 reEntryPlayerCnt = 9; */
        if (message.reEntryPlayerCnt !== 0)
            writer.tag(9, WireType.Varint).int64(message.reEntryPlayerCnt);
        /* repeated bpt.common.AssetItem prizePool = 10; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int64 prizeNum = 11; */
        if (message.prizeNum !== 0)
            writer.tag(11, WireType.Varint).int64(message.prizeNum);
        /* repeated bpt.common.AssetItem reentryPrize = 12; */
        for (let i = 0; i < message.reentryPrize.length; i++)
            AssetItem.internalBinaryWrite(message.reentryPrize[i], writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem addonPrize = 13; */
        for (let i = 0; i < message.addonPrize.length; i++)
            AssetItem.internalBinaryWrite(message.addonPrize[i], writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem rebuyPrize = 14; */
        for (let i = 0; i < message.rebuyPrize.length; i++)
            AssetItem.internalBinaryWrite(message.rebuyPrize[i], writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem dynamicPrizePool = 15; */
        for (let i = 0; i < message.dynamicPrizePool.length; i++)
            AssetItem.internalBinaryWrite(message.dynamicPrizePool[i], writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.AssetItem basicPrizePool = 16; */
        for (let i = 0; i < message.basicPrizePool.length; i++)
            AssetItem.internalBinaryWrite(message.basicPrizePool[i], writer.tag(16, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.PrizeAdvance prizeAdvance = 17; */
        if (message.prizeAdvance)
            PrizeAdvance.internalBinaryWrite(message.prizeAdvance, writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem prizePoolThreshold = 18; */
        if (message.prizePoolThreshold)
            AssetItem.internalBinaryWrite(message.prizePoolThreshold, writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListPrizePoolResp
 */
export const ListPrizePoolResp = new ListPrizePoolResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrizeAdvance$Type extends MessageType<PrizeAdvance> {
    constructor() {
        super("bpt.match.matchhub.v1.PrizeAdvance", [
            { no: 1, name: "show", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "advanceNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PrizeAdvance>): PrizeAdvance {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.show = false;
        message.advanceNum = 0;
        if (value !== undefined)
            reflectionMergePartial<PrizeAdvance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrizeAdvance): PrizeAdvance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool show */ 1:
                    message.show = reader.bool();
                    break;
                case /* int32 advanceNum */ 2:
                    message.advanceNum = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrizeAdvance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool show = 1; */
        if (message.show !== false)
            writer.tag(1, WireType.Varint).bool(message.show);
        /* int32 advanceNum = 2; */
        if (message.advanceNum !== 0)
            writer.tag(2, WireType.Varint).int32(message.advanceNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.PrizeAdvance
 */
export const PrizeAdvance = new PrizeAdvance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchPrizeAndBubbleUserReq$Type extends MessageType<ListMatchPrizeAndBubbleUserReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchPrizeAndBubbleUserReq>): ListMatchPrizeAndBubbleUserReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ListMatchPrizeAndBubbleUserReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchPrizeAndBubbleUserReq): ListMatchPrizeAndBubbleUserReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchPrizeAndBubbleUserReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserReq
 */
export const ListMatchPrizeAndBubbleUserReq = new ListMatchPrizeAndBubbleUserReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormatAndValidateTierPrizeReq$Type extends MessageType<FormatAndValidateTierPrizeReq> {
    constructor() {
        super("bpt.match.matchhub.v1.FormatAndValidateTierPrizeReq", [
            { no: 1, name: "tierPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TierPrize }
        ]);
    }
    create(value?: PartialMessage<FormatAndValidateTierPrizeReq>): FormatAndValidateTierPrizeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tierPrizes = [];
        if (value !== undefined)
            reflectionMergePartial<FormatAndValidateTierPrizeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormatAndValidateTierPrizeReq): FormatAndValidateTierPrizeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.TierPrize tierPrizes */ 1:
                    message.tierPrizes.push(TierPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FormatAndValidateTierPrizeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.TierPrize tierPrizes = 1; */
        for (let i = 0; i < message.tierPrizes.length; i++)
            TierPrize.internalBinaryWrite(message.tierPrizes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.FormatAndValidateTierPrizeReq
 */
export const FormatAndValidateTierPrizeReq = new FormatAndValidateTierPrizeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FormatAndValidateTierPrizeResp$Type extends MessageType<FormatAndValidateTierPrizeResp> {
    constructor() {
        super("bpt.match.matchhub.v1.FormatAndValidateTierPrizeResp", [
            { no: 1, name: "tierPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TierPrize }
        ]);
    }
    create(value?: PartialMessage<FormatAndValidateTierPrizeResp>): FormatAndValidateTierPrizeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tierPrizes = [];
        if (value !== undefined)
            reflectionMergePartial<FormatAndValidateTierPrizeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FormatAndValidateTierPrizeResp): FormatAndValidateTierPrizeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.common.v1.TierPrize tierPrizes */ 1:
                    message.tierPrizes.push(TierPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FormatAndValidateTierPrizeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.common.v1.TierPrize tierPrizes = 1; */
        for (let i = 0; i < message.tierPrizes.length; i++)
            TierPrize.internalBinaryWrite(message.tierPrizes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.FormatAndValidateTierPrizeResp
 */
export const FormatAndValidateTierPrizeResp = new FormatAndValidateTierPrizeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDispatchContractReq$Type extends MessageType<ListDispatchContractReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListDispatchContractReq", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] }
        ]);
    }
    create(value?: PartialMessage<ListDispatchContractReq>): ListDispatchContractReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        if (value !== undefined)
            reflectionMergePartial<ListDispatchContractReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDispatchContractReq): ListDispatchContractReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDispatchContractReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListDispatchContractReq
 */
export const ListDispatchContractReq = new ListDispatchContractReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRegisterInfoReq$Type extends MessageType<GetRegisterInfoReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetRegisterInfoReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRegisterInfoReq>): GetRegisterInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetRegisterInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRegisterInfoReq): GetRegisterInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRegisterInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetRegisterInfoReq
 */
export const GetRegisterInfoReq = new GetRegisterInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReEntryInfoReq$Type extends MessageType<GetReEntryInfoReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetReEntryInfoReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetReEntryInfoReq>): GetReEntryInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetReEntryInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReEntryInfoReq): GetReEntryInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReEntryInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetReEntryInfoReq
 */
export const GetReEntryInfoReq = new GetReEntryInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRegisterInfoResp$Type extends MessageType<GetRegisterInfoResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetRegisterInfoResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "free", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "allowVipLevels", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "allowIdentityLevels", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityLevel", EntryConfig_IdentityLevel] },
            { no: 5, name: "freeConfig", kind: "message", T: () => RegisterInfoFreeConfig },
            { no: 6, name: "allowConfig", kind: "message", T: () => RegisterInfoAllowConfig }
        ]);
    }
    create(value?: PartialMessage<GetRegisterInfoResp>): GetRegisterInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.free = false;
        message.allowVipLevels = [];
        message.allowIdentityLevels = [];
        if (value !== undefined)
            reflectionMergePartial<GetRegisterInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRegisterInfoResp): GetRegisterInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bool free */ 2:
                    message.free = reader.bool();
                    break;
                case /* repeated int32 allowVipLevels */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.allowVipLevels.push(reader.int32());
                    else
                        message.allowVipLevels.push(reader.int32());
                    break;
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel allowIdentityLevels */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.allowIdentityLevels.push(reader.int32());
                    else
                        message.allowIdentityLevels.push(reader.int32());
                    break;
                case /* bpt.match.matchhub.v1.RegisterInfoFreeConfig freeConfig */ 5:
                    message.freeConfig = RegisterInfoFreeConfig.internalBinaryRead(reader, reader.uint32(), options, message.freeConfig);
                    break;
                case /* bpt.match.matchhub.v1.RegisterInfoAllowConfig allowConfig */ 6:
                    message.allowConfig = RegisterInfoAllowConfig.internalBinaryRead(reader, reader.uint32(), options, message.allowConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRegisterInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bool free = 2; */
        if (message.free !== false)
            writer.tag(2, WireType.Varint).bool(message.free);
        /* repeated int32 allowVipLevels = 3; */
        if (message.allowVipLevels.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.allowVipLevels.length; i++)
                writer.int32(message.allowVipLevels[i]);
            writer.join();
        }
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel allowIdentityLevels = 4; */
        if (message.allowIdentityLevels.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.allowIdentityLevels.length; i++)
                writer.int32(message.allowIdentityLevels[i]);
            writer.join();
        }
        /* bpt.match.matchhub.v1.RegisterInfoFreeConfig freeConfig = 5; */
        if (message.freeConfig)
            RegisterInfoFreeConfig.internalBinaryWrite(message.freeConfig, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.RegisterInfoAllowConfig allowConfig = 6; */
        if (message.allowConfig)
            RegisterInfoAllowConfig.internalBinaryWrite(message.allowConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetRegisterInfoResp
 */
export const GetRegisterInfoResp = new GetRegisterInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterInfoFreeConfig$Type extends MessageType<RegisterInfoFreeConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.RegisterInfoFreeConfig", [
            { no: 1, name: "identityLevels", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityLevel", EntryConfig_IdentityLevel] },
            { no: 2, name: "identityTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityType", EntryConfig_IdentityType] }
        ]);
    }
    create(value?: PartialMessage<RegisterInfoFreeConfig>): RegisterInfoFreeConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.identityLevels = [];
        message.identityTypes = [];
        if (value !== undefined)
            reflectionMergePartial<RegisterInfoFreeConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterInfoFreeConfig): RegisterInfoFreeConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityLevels.push(reader.int32());
                    else
                        message.identityLevels.push(reader.int32());
                    break;
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityTypes.push(reader.int32());
                    else
                        message.identityTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterInfoFreeConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 1; */
        if (message.identityLevels.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityLevels.length; i++)
                writer.int32(message.identityLevels[i]);
            writer.join();
        }
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 2; */
        if (message.identityTypes.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityTypes.length; i++)
                writer.int32(message.identityTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RegisterInfoFreeConfig
 */
export const RegisterInfoFreeConfig = new RegisterInfoFreeConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterInfoAllowConfig$Type extends MessageType<RegisterInfoAllowConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.RegisterInfoAllowConfig", [
            { no: 1, name: "identityLevels", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityLevel", EntryConfig_IdentityLevel] },
            { no: 2, name: "vipLevels", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "clubIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "identityTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityType", EntryConfig_IdentityType] }
        ]);
    }
    create(value?: PartialMessage<RegisterInfoAllowConfig>): RegisterInfoAllowConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.identityLevels = [];
        message.vipLevels = [];
        message.clubIds = [];
        message.identityTypes = [];
        if (value !== undefined)
            reflectionMergePartial<RegisterInfoAllowConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterInfoAllowConfig): RegisterInfoAllowConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityLevels.push(reader.int32());
                    else
                        message.identityLevels.push(reader.int32());
                    break;
                case /* repeated int32 vipLevels */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.vipLevels.push(reader.int32());
                    else
                        message.vipLevels.push(reader.int32());
                    break;
                case /* repeated int32 clubIds */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.clubIds.push(reader.int32());
                    else
                        message.clubIds.push(reader.int32());
                    break;
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityTypes.push(reader.int32());
                    else
                        message.identityTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterInfoAllowConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 1; */
        if (message.identityLevels.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityLevels.length; i++)
                writer.int32(message.identityLevels[i]);
            writer.join();
        }
        /* repeated int32 vipLevels = 2; */
        if (message.vipLevels.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.vipLevels.length; i++)
                writer.int32(message.vipLevels[i]);
            writer.join();
        }
        /* repeated int32 clubIds = 3; */
        if (message.clubIds.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.clubIds.length; i++)
                writer.int32(message.clubIds[i]);
            writer.join();
        }
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 4; */
        if (message.identityTypes.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityTypes.length; i++)
                writer.int32(message.identityTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.RegisterInfoAllowConfig
 */
export const RegisterInfoAllowConfig = new RegisterInfoAllowConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReEntryInfoResp$Type extends MessageType<GetReEntryInfoResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetReEntryInfoResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "freeCount", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "freeRemain", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetReEntryInfoResp>): GetReEntryInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.freeCount = 0;
        message.freeRemain = 0;
        if (value !== undefined)
            reflectionMergePartial<GetReEntryInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReEntryInfoResp): GetReEntryInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* uint32 freeCount */ 2:
                    message.freeCount = reader.uint32();
                    break;
                case /* uint32 freeRemain */ 3:
                    message.freeRemain = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReEntryInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* uint32 freeCount = 2; */
        if (message.freeCount !== 0)
            writer.tag(2, WireType.Varint).uint32(message.freeCount);
        /* uint32 freeRemain = 3; */
        if (message.freeRemain !== 0)
            writer.tag(3, WireType.Varint).uint32(message.freeRemain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetReEntryInfoResp
 */
export const GetReEntryInfoResp = new GetReEntryInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DispatchContract$Type extends MessageType<DispatchContract> {
    constructor() {
        super("bpt.match.matchhub.v1.DispatchContract", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "desc", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DispatchContract>): DispatchContract {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.name = "";
        message.address = "";
        message.desc = "";
        if (value !== undefined)
            reflectionMergePartial<DispatchContract>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DispatchContract): DispatchContract {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string address */ 3:
                    message.address = reader.string();
                    break;
                case /* string desc */ 4:
                    message.desc = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DispatchContract, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string address = 3; */
        if (message.address !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.address);
        /* string desc = 4; */
        if (message.desc !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.desc);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DispatchContract
 */
export const DispatchContract = new DispatchContract$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListDispatchContractResp$Type extends MessageType<ListDispatchContractResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListDispatchContractResp", [
            { no: 1, name: "dispatchContracts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DispatchContract }
        ]);
    }
    create(value?: PartialMessage<ListDispatchContractResp>): ListDispatchContractResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dispatchContracts = [];
        if (value !== undefined)
            reflectionMergePartial<ListDispatchContractResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListDispatchContractResp): ListDispatchContractResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.DispatchContract dispatchContracts */ 1:
                    message.dispatchContracts.push(DispatchContract.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListDispatchContractResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.DispatchContract dispatchContracts = 1; */
        for (let i = 0; i < message.dispatchContracts.length; i++)
            DispatchContract.internalBinaryWrite(message.dispatchContracts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListDispatchContractResp
 */
export const ListDispatchContractResp = new ListDispatchContractResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserRecordByCmsReq$Type extends MessageType<ListUserRecordByCmsReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListUserRecordByCmsReq", [
            { no: 1, name: "awardStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.AwardStatus", AwardStatus] },
            { no: 2, name: "prizePropIDs", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchStartAtBegin", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "matchStartAtEnd", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListUserRecordByCmsReq>): ListUserRecordByCmsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.awardStatus = 0;
        message.prizePropIDs = [];
        message.matchStartAtBegin = 0;
        message.matchStartAtEnd = 0;
        if (value !== undefined)
            reflectionMergePartial<ListUserRecordByCmsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserRecordByCmsReq): ListUserRecordByCmsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.AwardStatus awardStatus */ 1:
                    message.awardStatus = reader.int32();
                    break;
                case /* repeated int64 prizePropIDs */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.prizePropIDs.push(reader.int64().toNumber());
                    else
                        message.prizePropIDs.push(reader.int64().toNumber());
                    break;
                case /* int64 matchStartAtBegin */ 3:
                    message.matchStartAtBegin = reader.int64().toNumber();
                    break;
                case /* int64 matchStartAtEnd */ 4:
                    message.matchStartAtEnd = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserRecordByCmsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.AwardStatus awardStatus = 1; */
        if (message.awardStatus !== 0)
            writer.tag(1, WireType.Varint).int32(message.awardStatus);
        /* repeated int64 prizePropIDs = 2; */
        if (message.prizePropIDs.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.prizePropIDs.length; i++)
                writer.int64(message.prizePropIDs[i]);
            writer.join();
        }
        /* int64 matchStartAtBegin = 3; */
        if (message.matchStartAtBegin !== 0)
            writer.tag(3, WireType.Varint).int64(message.matchStartAtBegin);
        /* int64 matchStartAtEnd = 4; */
        if (message.matchStartAtEnd !== 0)
            writer.tag(4, WireType.Varint).int64(message.matchStartAtEnd);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListUserRecordByCmsReq
 */
export const ListUserRecordByCmsReq = new ListUserRecordByCmsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserRecordByCms$Type extends MessageType<UserRecordByCms> {
    constructor() {
        super("bpt.match.matchhub.v1.UserRecordByCms", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "userNickname", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchRecord", kind: "message", T: () => UserMatchRecord }
        ]);
    }
    create(value?: PartialMessage<UserRecordByCms>): UserRecordByCms {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.userNickname = "";
        if (value !== undefined)
            reflectionMergePartial<UserRecordByCms>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserRecordByCms): UserRecordByCms {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string userNickname */ 2:
                    message.userNickname = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.UserMatchRecord matchRecord */ 3:
                    message.matchRecord = UserMatchRecord.internalBinaryRead(reader, reader.uint32(), options, message.matchRecord);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserRecordByCms, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string userNickname = 2; */
        if (message.userNickname !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userNickname);
        /* bpt.match.matchhub.v1.UserMatchRecord matchRecord = 3; */
        if (message.matchRecord)
            UserMatchRecord.internalBinaryWrite(message.matchRecord, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UserRecordByCms
 */
export const UserRecordByCms = new UserRecordByCms$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUserRecordByCmsResp$Type extends MessageType<ListUserRecordByCmsResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListUserRecordByCmsResp", [
            { no: 1, name: "userMatchRecords", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserRecordByCms }
        ]);
    }
    create(value?: PartialMessage<ListUserRecordByCmsResp>): ListUserRecordByCmsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userMatchRecords = [];
        if (value !== undefined)
            reflectionMergePartial<ListUserRecordByCmsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUserRecordByCmsResp): ListUserRecordByCmsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.UserRecordByCms userMatchRecords */ 1:
                    message.userMatchRecords.push(UserRecordByCms.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUserRecordByCmsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.UserRecordByCms userMatchRecords = 1; */
        for (let i = 0; i < message.userMatchRecords.length; i++)
            UserRecordByCms.internalBinaryWrite(message.userMatchRecords[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListUserRecordByCmsResp
 */
export const ListUserRecordByCmsResp = new ListUserRecordByCmsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkAwardReq$Type extends MessageType<MarkAwardReq> {
    constructor() {
        super("bpt.match.matchhub.v1.MarkAwardReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<MarkAwardReq>): MarkAwardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.uid = 0;
        if (value !== undefined)
            reflectionMergePartial<MarkAwardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkAwardReq): MarkAwardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 uid */ 2:
                    message.uid = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarkAwardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 uid = 2; */
        if (message.uid !== 0)
            writer.tag(2, WireType.Varint).int64(message.uid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MarkAwardReq
 */
export const MarkAwardReq = new MarkAwardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkAwardResp$Type extends MessageType<MarkAwardResp> {
    constructor() {
        super("bpt.match.matchhub.v1.MarkAwardResp", []);
    }
    create(value?: PartialMessage<MarkAwardResp>): MarkAwardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<MarkAwardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkAwardResp): MarkAwardResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MarkAwardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MarkAwardResp
 */
export const MarkAwardResp = new MarkAwardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseMatchReq$Type extends MessageType<PauseMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.PauseMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "pauseMinutes", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PauseMatchReq>): PauseMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.pauseMinutes = 0;
        if (value !== undefined)
            reflectionMergePartial<PauseMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseMatchReq): PauseMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int32 pauseMinutes */ 3:
                    message.pauseMinutes = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int32 pauseMinutes = 3; */
        if (message.pauseMinutes !== 0)
            writer.tag(3, WireType.Varint).int32(message.pauseMinutes);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.PauseMatchReq
 */
export const PauseMatchReq = new PauseMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResumeMatchReq$Type extends MessageType<ResumeMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ResumeMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResumeMatchReq>): ResumeMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<ResumeMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResumeMatchReq): ResumeMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResumeMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ResumeMatchReq
 */
export const ResumeMatchReq = new ResumeMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropReq$Type extends MessageType<UsePropReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UsePropReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<UsePropReq>): UsePropReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.propId = 0;
        message.num = 0;
        if (value !== undefined)
            reflectionMergePartial<UsePropReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropReq): UsePropReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* int64 propId */ 2:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsePropReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* int64 propId = 2; */
        if (message.propId !== 0)
            writer.tag(2, WireType.Varint).int64(message.propId);
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UsePropReq
 */
export const UsePropReq = new UsePropReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropResp$Type extends MessageType<UsePropResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UsePropResp", []);
    }
    create(value?: PartialMessage<UsePropResp>): UsePropResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UsePropResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropResp): UsePropResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UsePropResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UsePropResp
 */
export const UsePropResp = new UsePropResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropShuffleCardCallBackReq$Type extends MessageType<UsePropShuffleCardCallBackReq> {
    constructor() {
        super("bpt.match.matchhub.v1.UsePropShuffleCardCallBackReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tableKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "batchId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UsePropShuffleCardCallBackReq>): UsePropShuffleCardCallBackReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.matchKey = "";
        message.tableKey = "";
        message.text = "";
        message.batchId = "";
        if (value !== undefined)
            reflectionMergePartial<UsePropShuffleCardCallBackReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropShuffleCardCallBackReq): UsePropShuffleCardCallBackReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                case /* string tableKey */ 3:
                    message.tableKey = reader.string();
                    break;
                case /* string text */ 4:
                    message.text = reader.string();
                    break;
                case /* string batchId */ 5:
                    message.batchId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UsePropShuffleCardCallBackReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        /* string tableKey = 3; */
        if (message.tableKey !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tableKey);
        /* string text = 4; */
        if (message.text !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.text);
        /* string batchId = 5; */
        if (message.batchId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.batchId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UsePropShuffleCardCallBackReq
 */
export const UsePropShuffleCardCallBackReq = new UsePropShuffleCardCallBackReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UsePropShuffleCardCallBackResp$Type extends MessageType<UsePropShuffleCardCallBackResp> {
    constructor() {
        super("bpt.match.matchhub.v1.UsePropShuffleCardCallBackResp", []);
    }
    create(value?: PartialMessage<UsePropShuffleCardCallBackResp>): UsePropShuffleCardCallBackResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<UsePropShuffleCardCallBackResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UsePropShuffleCardCallBackResp): UsePropShuffleCardCallBackResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UsePropShuffleCardCallBackResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.UsePropShuffleCardCallBackResp
 */
export const UsePropShuffleCardCallBackResp = new UsePropShuffleCardCallBackResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkReadCompletedAwardMatchReq$Type extends MessageType<MarkReadCompletedAwardMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.MarkReadCompletedAwardMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MarkReadCompletedAwardMatchReq>): MarkReadCompletedAwardMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<MarkReadCompletedAwardMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkReadCompletedAwardMatchReq): MarkReadCompletedAwardMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarkReadCompletedAwardMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MarkReadCompletedAwardMatchReq
 */
export const MarkReadCompletedAwardMatchReq = new MarkReadCompletedAwardMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PrizeAndBubbleUser$Type extends MessageType<PrizeAndBubbleUser> {
    constructor() {
        super("bpt.match.matchhub.v1.PrizeAndBubbleUser", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rank", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "PrizeRawAmount", kind: "scalar", localName: "PrizeRawAmount", jsonName: "PrizeRawAmount", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "userPrizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 5, name: "userPrizeSimple", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset$ },
            { no: 6, name: "prizeShowType", kind: "enum", T: () => ["bpt.match.matchhub.v1.PrizeShowType", PrizeShowType] }
        ]);
    }
    create(value?: PartialMessage<PrizeAndBubbleUser>): PrizeAndBubbleUser {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.rank = 0;
        message.PrizeRawAmount = "";
        message.userPrizePool = [];
        message.userPrizeSimple = [];
        message.prizeShowType = 0;
        if (value !== undefined)
            reflectionMergePartial<PrizeAndBubbleUser>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PrizeAndBubbleUser): PrizeAndBubbleUser {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* uint32 rank */ 2:
                    message.rank = reader.uint32();
                    break;
                case /* string PrizeRawAmount = 3 [json_name = "PrizeRawAmount"];*/ 3:
                    message.PrizeRawAmount = reader.string();
                    break;
                case /* repeated bpt.common.AssetItem userPrizePool */ 4:
                    message.userPrizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.common.SimpleAsset userPrizeSimple */ 5:
                    message.userPrizeSimple.push(SimpleAsset$.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.PrizeShowType prizeShowType */ 6:
                    message.prizeShowType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PrizeAndBubbleUser, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* uint32 rank = 2; */
        if (message.rank !== 0)
            writer.tag(2, WireType.Varint).uint32(message.rank);
        /* string PrizeRawAmount = 3 [json_name = "PrizeRawAmount"]; */
        if (message.PrizeRawAmount !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.PrizeRawAmount);
        /* repeated bpt.common.AssetItem userPrizePool = 4; */
        for (let i = 0; i < message.userPrizePool.length; i++)
            AssetItem.internalBinaryWrite(message.userPrizePool[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.common.SimpleAsset userPrizeSimple = 5; */
        for (let i = 0; i < message.userPrizeSimple.length; i++)
            SimpleAsset$.internalBinaryWrite(message.userPrizeSimple[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.PrizeShowType prizeShowType = 6; */
        if (message.prizeShowType !== 0)
            writer.tag(6, WireType.Varint).int32(message.prizeShowType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.PrizeAndBubbleUser
 */
export const PrizeAndBubbleUser = new PrizeAndBubbleUser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchPrizeAndBubbleUserResp$Type extends MessageType<ListMatchPrizeAndBubbleUserResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserResp", [
            { no: 5, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 1, name: "endAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "prizeAndBubbleUsers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PrizeAndBubbleUser },
            { no: 3, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 4, name: "totalEnterCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "prizePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<ListMatchPrizeAndBubbleUserResp>): ListMatchPrizeAndBubbleUserResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startAt = 0;
        message.endAt = 0;
        message.prizeAndBubbleUsers = [];
        message.totalEnterCnt = 0;
        message.prizePool = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchPrizeAndBubbleUserResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchPrizeAndBubbleUserResp): ListMatchPrizeAndBubbleUserResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 startAt */ 5:
                    message.startAt = reader.int64().toNumber();
                    break;
                case /* int64 endAt */ 1:
                    message.endAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchhub.v1.PrizeAndBubbleUser prizeAndBubbleUsers */ 2:
                    message.prizeAndBubbleUsers.push(PrizeAndBubbleUser.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.common.I18n i18n = 3 [json_name = "i18n"];*/ 3:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* int64 totalEnterCnt */ 4:
                    message.totalEnterCnt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.common.AssetItem prizePool */ 6:
                    message.prizePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchPrizeAndBubbleUserResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 startAt = 5; */
        if (message.startAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.startAt);
        /* int64 endAt = 1; */
        if (message.endAt !== 0)
            writer.tag(1, WireType.Varint).int64(message.endAt);
        /* repeated bpt.match.matchhub.v1.PrizeAndBubbleUser prizeAndBubbleUsers = 2; */
        for (let i = 0; i < message.prizeAndBubbleUsers.length; i++)
            PrizeAndBubbleUser.internalBinaryWrite(message.prizeAndBubbleUsers[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.I18n i18n = 3 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalEnterCnt = 4; */
        if (message.totalEnterCnt !== 0)
            writer.tag(4, WireType.Varint).int64(message.totalEnterCnt);
        /* repeated bpt.common.AssetItem prizePool = 6; */
        for (let i = 0; i < message.prizePool.length; i++)
            AssetItem.internalBinaryWrite(message.prizePool[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchPrizeAndBubbleUserResp
 */
export const ListMatchPrizeAndBubbleUserResp = new ListMatchPrizeAndBubbleUserResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSimpleMatchReq$Type extends MessageType<GetSimpleMatchReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetSimpleMatchReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSimpleMatchReq>): GetSimpleMatchReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetSimpleMatchReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSimpleMatchReq): GetSimpleMatchReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSimpleMatchReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetSimpleMatchReq
 */
export const GetSimpleMatchReq = new GetSimpleMatchReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSimpleMatchResp$Type extends MessageType<GetSimpleMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetSimpleMatchResp", [
            { no: 1, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 2, name: "startAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetSimpleMatchResp>): GetSimpleMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startAt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetSimpleMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSimpleMatchResp): GetSimpleMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.I18n i18n = 1 [json_name = "i18n"];*/ 1:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* int64 startAt */ 2:
                    message.startAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSimpleMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.I18n i18n = 1 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 startAt = 2; */
        if (message.startAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.startAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetSimpleMatchResp
 */
export const GetSimpleMatchResp = new GetSimpleMatchResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSeatMatchLiteInfoReq$Type extends MessageType<GetSeatMatchLiteInfoReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetSeatMatchLiteInfoReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetSeatMatchLiteInfoReq>): GetSeatMatchLiteInfoReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetSeatMatchLiteInfoReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSeatMatchLiteInfoReq): GetSeatMatchLiteInfoReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSeatMatchLiteInfoReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetSeatMatchLiteInfoReq
 */
export const GetSeatMatchLiteInfoReq = new GetSeatMatchLiteInfoReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetSeatMatchLiteInfoResp$Type extends MessageType<GetSeatMatchLiteInfoResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetSeatMatchLiteInfoResp", [
            { no: 1, name: "seatMatchLiteInfoList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatch }
        ]);
    }
    create(value?: PartialMessage<GetSeatMatchLiteInfoResp>): GetSeatMatchLiteInfoResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.seatMatchLiteInfoList = [];
        if (value !== undefined)
            reflectionMergePartial<GetSeatMatchLiteInfoResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetSeatMatchLiteInfoResp): GetSeatMatchLiteInfoResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.SubMatch seatMatchLiteInfoList */ 1:
                    message.seatMatchLiteInfoList.push(SubMatch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetSeatMatchLiteInfoResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.SubMatch seatMatchLiteInfoList = 1; */
        for (let i = 0; i < message.seatMatchLiteInfoList.length; i++)
            SubMatch.internalBinaryWrite(message.seatMatchLiteInfoList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetSeatMatchLiteInfoResp
 */
export const GetSeatMatchLiteInfoResp = new GetSeatMatchLiteInfoResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnlineMatchConfigReq$Type extends MessageType<OnlineMatchConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.OnlineMatchConfigReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 3, name: "dispatchContract", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OnlineMatchConfigReq>): OnlineMatchConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.chainID = 0;
        message.dispatchContract = "";
        if (value !== undefined)
            reflectionMergePartial<OnlineMatchConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnlineMatchConfigReq): OnlineMatchConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* gaw.base.ChainID chainID */ 2:
                    message.chainID = reader.int32();
                    break;
                case /* string dispatchContract */ 3:
                    message.dispatchContract = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnlineMatchConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* gaw.base.ChainID chainID = 2; */
        if (message.chainID !== 0)
            writer.tag(2, WireType.Varint).int32(message.chainID);
        /* string dispatchContract = 3; */
        if (message.dispatchContract !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.dispatchContract);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.OnlineMatchConfigReq
 */
export const OnlineMatchConfigReq = new OnlineMatchConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnlineMatchConfigResp$Type extends MessageType<OnlineMatchConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.OnlineMatchConfigResp", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OnlineMatchConfigResp>): OnlineMatchConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        if (value !== undefined)
            reflectionMergePartial<OnlineMatchConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnlineMatchConfigResp): OnlineMatchConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnlineMatchConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.OnlineMatchConfigResp
 */
export const OnlineMatchConfigResp = new OnlineMatchConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OfflineMatchConfigReq$Type extends MessageType<OfflineMatchConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.OfflineMatchConfigReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<OfflineMatchConfigReq>): OfflineMatchConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        if (value !== undefined)
            reflectionMergePartial<OfflineMatchConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OfflineMatchConfigReq): OfflineMatchConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OfflineMatchConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.OfflineMatchConfigReq
 */
export const OfflineMatchConfigReq = new OfflineMatchConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OfflineMatchConfigResp$Type extends MessageType<OfflineMatchConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.OfflineMatchConfigResp", []);
    }
    create(value?: PartialMessage<OfflineMatchConfigResp>): OfflineMatchConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<OfflineMatchConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OfflineMatchConfigResp): OfflineMatchConfigResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OfflineMatchConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.OfflineMatchConfigResp
 */
export const OfflineMatchConfigResp = new OfflineMatchConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminSendRewardReq$Type extends MessageType<AdminSendRewardReq> {
    constructor() {
        super("bpt.match.matchhub.v1.AdminSendRewardReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AdminSendRewardReq>): AdminSendRewardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<AdminSendRewardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminSendRewardReq): AdminSendRewardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminSendRewardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AdminSendRewardReq
 */
export const AdminSendRewardReq = new AdminSendRewardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminSendRewardResp$Type extends MessageType<AdminSendRewardResp> {
    constructor() {
        super("bpt.match.matchhub.v1.AdminSendRewardResp", [
            { no: 1, name: "credential", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 2, name: "tsId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 4, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AdminSendRewardResp>): AdminSendRewardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.credential = new Uint8Array(0);
        message.tsId = "";
        message.chainID = 0;
        message.address = "";
        if (value !== undefined)
            reflectionMergePartial<AdminSendRewardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminSendRewardResp): AdminSendRewardResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bytes credential */ 1:
                    message.credential = reader.bytes();
                    break;
                case /* string tsId */ 2:
                    message.tsId = reader.string();
                    break;
                case /* gaw.base.ChainID chainID */ 3:
                    message.chainID = reader.int32();
                    break;
                case /* string address */ 4:
                    message.address = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminSendRewardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bytes credential = 1; */
        if (message.credential.length)
            writer.tag(1, WireType.LengthDelimited).bytes(message.credential);
        /* string tsId = 2; */
        if (message.tsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tsId);
        /* gaw.base.ChainID chainID = 3; */
        if (message.chainID !== 0)
            writer.tag(3, WireType.Varint).int32(message.chainID);
        /* string address = 4; */
        if (message.address !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.address);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AdminSendRewardResp
 */
export const AdminSendRewardResp = new AdminSendRewardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminFetchUserPrizeRewardReq$Type extends MessageType<AdminFetchUserPrizeRewardReq> {
    constructor() {
        super("bpt.match.matchhub.v1.AdminFetchUserPrizeRewardReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AdminFetchUserPrizeRewardReq>): AdminFetchUserPrizeRewardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<AdminFetchUserPrizeRewardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminFetchUserPrizeRewardReq): AdminFetchUserPrizeRewardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminFetchUserPrizeRewardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AdminFetchUserPrizeRewardReq
 */
export const AdminFetchUserPrizeRewardReq = new AdminFetchUserPrizeRewardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AdminFetchUserPrizeRewardResp$Type extends MessageType<AdminFetchUserPrizeRewardResp> {
    constructor() {
        super("bpt.match.matchhub.v1.AdminFetchUserPrizeRewardResp", [
            { no: 1, name: "userPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPrize },
            { no: 2, name: "matchOwnerRemaiPrize", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserPrize }
        ]);
    }
    create(value?: PartialMessage<AdminFetchUserPrizeRewardResp>): AdminFetchUserPrizeRewardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.userPrizes = [];
        message.matchOwnerRemaiPrize = [];
        if (value !== undefined)
            reflectionMergePartial<AdminFetchUserPrizeRewardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AdminFetchUserPrizeRewardResp): AdminFetchUserPrizeRewardResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.UserPrize userPrizes */ 1:
                    message.userPrizes.push(UserPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchhub.v1.UserPrize matchOwnerRemaiPrize */ 2:
                    message.matchOwnerRemaiPrize.push(UserPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AdminFetchUserPrizeRewardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.UserPrize userPrizes = 1; */
        for (let i = 0; i < message.userPrizes.length; i++)
            UserPrize.internalBinaryWrite(message.userPrizes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.UserPrize matchOwnerRemaiPrize = 2; */
        for (let i = 0; i < message.matchOwnerRemaiPrize.length; i++)
            UserPrize.internalBinaryWrite(message.matchOwnerRemaiPrize[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.AdminFetchUserPrizeRewardResp
 */
export const AdminFetchUserPrizeRewardResp = new AdminFetchUserPrizeRewardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigReq$Type extends MessageType<ListMatchConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigReq", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["bpt.match.common.v1.State", State] },
            { no: 4, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigReq>): ListMatchConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.state = 0;
        message.matchName = "";
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigReq): ListMatchConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* bpt.match.common.v1.State state */ 3:
                    message.state = reader.int32();
                    break;
                case /* string matchName */ 4:
                    message.matchName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* bpt.match.common.v1.State state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        /* string matchName = 4; */
        if (message.matchName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigReq
 */
export const ListMatchConfigReq = new ListMatchConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigResp$Type extends MessageType<ListMatchConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigResp", [
            { no: 1, name: "configList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListMatchConfigInfo },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigResp>): ListMatchConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.configList = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigResp): ListMatchConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.ListMatchConfigInfo configList */ 1:
                    message.configList.push(ListMatchConfigInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.ListMatchConfigInfo configList = 1; */
        for (let i = 0; i < message.configList.length; i++)
            ListMatchConfigInfo.internalBinaryWrite(message.configList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigResp
 */
export const ListMatchConfigResp = new ListMatchConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigInfo$Type extends MessageType<ListMatchConfigInfo> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigInfo", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 4, name: "matchStartTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 6, name: "matchState", kind: "enum", T: () => ["bpt.match.common.v1.State", State] },
            { no: 7, name: "matchConfigRecord", kind: "message", T: () => ListMatchConfigRecordInfo },
            { no: 8, name: "matchListShowStatus", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchListShowStatus", MatchListShowStatus] },
            { no: 9, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 10, name: "dispatchContract", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigInfo>): ListMatchConfigInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.name = "";
        message.matchStartTime = "";
        message.matchType = 0;
        message.matchState = 0;
        message.matchListShowStatus = 0;
        message.chainID = 0;
        message.dispatchContract = "";
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigInfo): ListMatchConfigInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* bpt.common.I18n i18n = 3 [json_name = "i18n"];*/ 3:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* string matchStartTime */ 4:
                    message.matchStartTime = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 5:
                    message.matchType = reader.int32();
                    break;
                case /* bpt.match.common.v1.State matchState */ 6:
                    message.matchState = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.ListMatchConfigRecordInfo matchConfigRecord */ 7:
                    message.matchConfigRecord = ListMatchConfigRecordInfo.internalBinaryRead(reader, reader.uint32(), options, message.matchConfigRecord);
                    break;
                case /* bpt.match.matchhub.v1.MatchListShowStatus matchListShowStatus */ 8:
                    message.matchListShowStatus = reader.int32();
                    break;
                case /* gaw.base.ChainID chainID */ 9:
                    message.chainID = reader.int32();
                    break;
                case /* string dispatchContract */ 10:
                    message.dispatchContract = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* bpt.common.I18n i18n = 3 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string matchStartTime = 4; */
        if (message.matchStartTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.matchStartTime);
        /* bpt.match.common.v1.MatchType matchType = 5; */
        if (message.matchType !== 0)
            writer.tag(5, WireType.Varint).int32(message.matchType);
        /* bpt.match.common.v1.State matchState = 6; */
        if (message.matchState !== 0)
            writer.tag(6, WireType.Varint).int32(message.matchState);
        /* bpt.match.matchhub.v1.ListMatchConfigRecordInfo matchConfigRecord = 7; */
        if (message.matchConfigRecord)
            ListMatchConfigRecordInfo.internalBinaryWrite(message.matchConfigRecord, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchListShowStatus matchListShowStatus = 8; */
        if (message.matchListShowStatus !== 0)
            writer.tag(8, WireType.Varint).int32(message.matchListShowStatus);
        /* gaw.base.ChainID chainID = 9; */
        if (message.chainID !== 0)
            writer.tag(9, WireType.Varint).int32(message.chainID);
        /* string dispatchContract = 10; */
        if (message.dispatchContract !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.dispatchContract);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigInfo
 */
export const ListMatchConfigInfo = new ListMatchConfigInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigRecordInfo$Type extends MessageType<ListMatchConfigRecordInfo> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigRecordInfo", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "state", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchRecordState", MatchRecordState] },
            { no: 3, name: "subMatchConfigList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListMatchConfigRecordInfo }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigRecordInfo>): ListMatchConfigRecordInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.state = 0;
        message.subMatchConfigList = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigRecordInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigRecordInfo): ListMatchConfigRecordInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.MatchRecordState state */ 2:
                    message.state = reader.int32();
                    break;
                case /* repeated bpt.match.matchhub.v1.ListMatchConfigRecordInfo subMatchConfigList */ 3:
                    message.subMatchConfigList.push(ListMatchConfigRecordInfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigRecordInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* bpt.match.matchhub.v1.MatchRecordState state = 2; */
        if (message.state !== 0)
            writer.tag(2, WireType.Varint).int32(message.state);
        /* repeated bpt.match.matchhub.v1.ListMatchConfigRecordInfo subMatchConfigList = 3; */
        for (let i = 0; i < message.subMatchConfigList.length; i++)
            ListMatchConfigRecordInfo.internalBinaryWrite(message.subMatchConfigList[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigRecordInfo
 */
export const ListMatchConfigRecordInfo = new ListMatchConfigRecordInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEarlyBirdConfigInternalReq$Type extends MessageType<GetEarlyBirdConfigInternalReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetEarlyBirdConfigInternalReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetEarlyBirdConfigInternalReq>): GetEarlyBirdConfigInternalReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetEarlyBirdConfigInternalReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEarlyBirdConfigInternalReq): GetEarlyBirdConfigInternalReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* string matchKey */ 2:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEarlyBirdConfigInternalReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* string matchKey = 2; */
        if (message.matchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetEarlyBirdConfigInternalReq
 */
export const GetEarlyBirdConfigInternalReq = new GetEarlyBirdConfigInternalReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEarlyBirdConfigInternalResp$Type extends MessageType<GetEarlyBirdConfigInternalResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetEarlyBirdConfigInternalResp", [
            { no: 1, name: "earlyBirdConfig", kind: "message", T: () => EarlyBirdConfig },
            { no: 2, name: "firstMatchStartTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "OnChain", kind: "scalar", localName: "OnChain", jsonName: "OnChain", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetEarlyBirdConfigInternalResp>): GetEarlyBirdConfigInternalResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.firstMatchStartTime = 0;
        message.OnChain = false;
        if (value !== undefined)
            reflectionMergePartial<GetEarlyBirdConfigInternalResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEarlyBirdConfigInternalResp): GetEarlyBirdConfigInternalResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.EarlyBirdConfig earlyBirdConfig */ 1:
                    message.earlyBirdConfig = EarlyBirdConfig.internalBinaryRead(reader, reader.uint32(), options, message.earlyBirdConfig);
                    break;
                case /* int64 firstMatchStartTime */ 2:
                    message.firstMatchStartTime = reader.int64().toNumber();
                    break;
                case /* bool OnChain = 3 [json_name = "OnChain"];*/ 3:
                    message.OnChain = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEarlyBirdConfigInternalResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.EarlyBirdConfig earlyBirdConfig = 1; */
        if (message.earlyBirdConfig)
            EarlyBirdConfig.internalBinaryWrite(message.earlyBirdConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 firstMatchStartTime = 2; */
        if (message.firstMatchStartTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.firstMatchStartTime);
        /* bool OnChain = 3 [json_name = "OnChain"]; */
        if (message.OnChain !== false)
            writer.tag(3, WireType.Varint).bool(message.OnChain);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetEarlyBirdConfigInternalResp
 */
export const GetEarlyBirdConfigInternalResp = new GetEarlyBirdConfigInternalResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfigReq$Type extends MessageType<DeleteMatchConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfigReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchConfigReq>): DeleteMatchConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfigReq): DeleteMatchConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfigReq
 */
export const DeleteMatchConfigReq = new DeleteMatchConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfigResp$Type extends MessageType<DeleteMatchConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfigResp", []);
    }
    create(value?: PartialMessage<DeleteMatchConfigResp>): DeleteMatchConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfigResp): DeleteMatchConfigResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteMatchConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfigResp
 */
export const DeleteMatchConfigResp = new DeleteMatchConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicateMatchConfigReq$Type extends MessageType<DuplicateMatchConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.DuplicateMatchConfigReq", [
            { no: 1, name: "duplicateConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "returnConfigInfo", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DuplicateMatchConfigReq>): DuplicateMatchConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.duplicateConfigId = 0;
        message.returnConfigInfo = false;
        if (value !== undefined)
            reflectionMergePartial<DuplicateMatchConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicateMatchConfigReq): DuplicateMatchConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 duplicateConfigId */ 1:
                    message.duplicateConfigId = reader.int64().toNumber();
                    break;
                case /* bool returnConfigInfo */ 2:
                    message.returnConfigInfo = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicateMatchConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 duplicateConfigId = 1; */
        if (message.duplicateConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.duplicateConfigId);
        /* bool returnConfigInfo = 2; */
        if (message.returnConfigInfo !== false)
            writer.tag(2, WireType.Varint).bool(message.returnConfigInfo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DuplicateMatchConfigReq
 */
export const DuplicateMatchConfigReq = new DuplicateMatchConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DuplicateMatchConfigResp$Type extends MessageType<DuplicateMatchConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.DuplicateMatchConfigResp", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "baseConfig", kind: "message", T: () => MatchBaseConfig },
            { no: 3, name: "blindInfoConfig", kind: "message", T: () => MatchBlindInfoConfig },
            { no: 4, name: "rewardInfoConfig", kind: "message", T: () => MatchRewardInfoConfig },
            { no: 5, name: "subMatchConfigs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatchConfig },
            { no: 6, name: "newRewardInfoConfig", kind: "message", T: () => NewMatchRewardInfoConfig }
        ]);
    }
    create(value?: PartialMessage<DuplicateMatchConfigResp>): DuplicateMatchConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.subMatchConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<DuplicateMatchConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DuplicateMatchConfigResp): DuplicateMatchConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig */ 2:
                    message.baseConfig = MatchBaseConfig.internalBinaryRead(reader, reader.uint32(), options, message.baseConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig */ 3:
                    message.blindInfoConfig = MatchBlindInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindInfoConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig */ 4:
                    message.rewardInfoConfig = MatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.rewardInfoConfig);
                    break;
                case /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs */ 5:
                    message.subMatchConfigs.push(SubMatchConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig */ 6:
                    message.newRewardInfoConfig = NewMatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.newRewardInfoConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DuplicateMatchConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2; */
        if (message.baseConfig)
            MatchBaseConfig.internalBinaryWrite(message.baseConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3; */
        if (message.blindInfoConfig)
            MatchBlindInfoConfig.internalBinaryWrite(message.blindInfoConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4; */
        if (message.rewardInfoConfig)
            MatchRewardInfoConfig.internalBinaryWrite(message.rewardInfoConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5; */
        for (let i = 0; i < message.subMatchConfigs.length; i++)
            SubMatchConfig.internalBinaryWrite(message.subMatchConfigs[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6; */
        if (message.newRewardInfoConfig)
            NewMatchRewardInfoConfig.internalBinaryWrite(message.newRewardInfoConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DuplicateMatchConfigResp
 */
export const DuplicateMatchConfigResp = new DuplicateMatchConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfigReq$Type extends MessageType<GetMatchConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfigReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfigReq>): GetMatchConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfigReq): GetMatchConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfigReq
 */
export const GetMatchConfigReq = new GetMatchConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfigResp$Type extends MessageType<GetMatchConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfigResp", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "baseConfig", kind: "message", T: () => MatchBaseConfig },
            { no: 3, name: "blindInfoConfig", kind: "message", T: () => MatchBlindInfoConfig },
            { no: 4, name: "rewardInfoConfig", kind: "message", T: () => MatchRewardInfoConfig },
            { no: 5, name: "subMatchConfigs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatchConfig },
            { no: 6, name: "newRewardInfoConfig", kind: "message", T: () => NewMatchRewardInfoConfig }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfigResp>): GetMatchConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.subMatchConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfigResp): GetMatchConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig */ 2:
                    message.baseConfig = MatchBaseConfig.internalBinaryRead(reader, reader.uint32(), options, message.baseConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig */ 3:
                    message.blindInfoConfig = MatchBlindInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindInfoConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig */ 4:
                    message.rewardInfoConfig = MatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.rewardInfoConfig);
                    break;
                case /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs */ 5:
                    message.subMatchConfigs.push(SubMatchConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig */ 6:
                    message.newRewardInfoConfig = NewMatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.newRewardInfoConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2; */
        if (message.baseConfig)
            MatchBaseConfig.internalBinaryWrite(message.baseConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3; */
        if (message.blindInfoConfig)
            MatchBlindInfoConfig.internalBinaryWrite(message.blindInfoConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4; */
        if (message.rewardInfoConfig)
            MatchRewardInfoConfig.internalBinaryWrite(message.rewardInfoConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5; */
        for (let i = 0; i < message.subMatchConfigs.length; i++)
            SubMatchConfig.internalBinaryWrite(message.subMatchConfigs[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6; */
        if (message.newRewardInfoConfig)
            NewMatchRewardInfoConfig.internalBinaryWrite(message.newRewardInfoConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfigResp
 */
export const GetMatchConfigResp = new GetMatchConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigBlindReq$Type extends MessageType<SaveMatchConfigBlindReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigBlindReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "blindInfoConfig", kind: "message", T: () => MatchBlindInfoConfig },
            { no: 3, name: "subMatchConfigs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatchConfig }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigBlindReq>): SaveMatchConfigBlindReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.subMatchConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigBlindReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigBlindReq): SaveMatchConfigBlindReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig */ 2:
                    message.blindInfoConfig = MatchBlindInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindInfoConfig);
                    break;
                case /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs */ 3:
                    message.subMatchConfigs.push(SubMatchConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigBlindReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 2; */
        if (message.blindInfoConfig)
            MatchBlindInfoConfig.internalBinaryWrite(message.blindInfoConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 3; */
        for (let i = 0; i < message.subMatchConfigs.length; i++)
            SubMatchConfig.internalBinaryWrite(message.subMatchConfigs[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigBlindReq
 */
export const SaveMatchConfigBlindReq = new SaveMatchConfigBlindReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchBlindInfoConfig$Type extends MessageType<MatchBlindInfoConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchBlindInfoConfig", [
            { no: 2, name: "blindStructure", kind: "message", T: () => BlindStructure },
            { no: 3, name: "reBuy", kind: "message", T: () => RebuyConf },
            { no: 4, name: "addOn", kind: "message", T: () => AddOnOption },
            { no: 5, name: "reEntry", kind: "message", T: () => ReentryConf },
            { no: 6, name: "pauseConf", kind: "message", T: () => PauseConf },
            { no: 10, name: "startingStack", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "playCardMainTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "delayPlay", kind: "message", T: () => DelayPlay }
        ]);
    }
    create(value?: PartialMessage<MatchBlindInfoConfig>): MatchBlindInfoConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.startingStack = 0;
        message.playCardMainTime = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchBlindInfoConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchBlindInfoConfig): MatchBlindInfoConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.BlindStructure blindStructure */ 2:
                    message.blindStructure = BlindStructure.internalBinaryRead(reader, reader.uint32(), options, message.blindStructure);
                    break;
                case /* bpt.match.common.v1.RebuyConf reBuy */ 3:
                    message.reBuy = RebuyConf.internalBinaryRead(reader, reader.uint32(), options, message.reBuy);
                    break;
                case /* bpt.match.common.v1.AddOnOption addOn */ 4:
                    message.addOn = AddOnOption.internalBinaryRead(reader, reader.uint32(), options, message.addOn);
                    break;
                case /* bpt.match.common.v1.ReentryConf reEntry */ 5:
                    message.reEntry = ReentryConf.internalBinaryRead(reader, reader.uint32(), options, message.reEntry);
                    break;
                case /* bpt.match.common.v1.PauseConf pauseConf */ 6:
                    message.pauseConf = PauseConf.internalBinaryRead(reader, reader.uint32(), options, message.pauseConf);
                    break;
                case /* uint64 startingStack */ 10:
                    message.startingStack = reader.uint64().toNumber();
                    break;
                case /* int64 playCardMainTime */ 11:
                    message.playCardMainTime = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.DelayPlay delayPlay */ 12:
                    message.delayPlay = DelayPlay.internalBinaryRead(reader, reader.uint32(), options, message.delayPlay);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchBlindInfoConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.BlindStructure blindStructure = 2; */
        if (message.blindStructure)
            BlindStructure.internalBinaryWrite(message.blindStructure, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.RebuyConf reBuy = 3; */
        if (message.reBuy)
            RebuyConf.internalBinaryWrite(message.reBuy, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.AddOnOption addOn = 4; */
        if (message.addOn)
            AddOnOption.internalBinaryWrite(message.addOn, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ReentryConf reEntry = 5; */
        if (message.reEntry)
            ReentryConf.internalBinaryWrite(message.reEntry, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.PauseConf pauseConf = 6; */
        if (message.pauseConf)
            PauseConf.internalBinaryWrite(message.pauseConf, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* uint64 startingStack = 10; */
        if (message.startingStack !== 0)
            writer.tag(10, WireType.Varint).uint64(message.startingStack);
        /* int64 playCardMainTime = 11; */
        if (message.playCardMainTime !== 0)
            writer.tag(11, WireType.Varint).int64(message.playCardMainTime);
        /* bpt.match.common.v1.DelayPlay delayPlay = 12; */
        if (message.delayPlay)
            DelayPlay.internalBinaryWrite(message.delayPlay, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchBlindInfoConfig
 */
export const MatchBlindInfoConfig = new MatchBlindInfoConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubMatchConfig$Type extends MessageType<SubMatchConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.SubMatchConfig", [
            { no: 1, name: "subMatchConfigID", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "blindInfoConfig", kind: "message", T: () => MatchBlindInfoConfig },
            { no: 3, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 4, name: "sameWithDayOneA", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "entryConfig", kind: "message", T: () => EntryConfig },
            { no: 7, name: "subMatchScheduleConfig", kind: "message", T: () => SubMatchScheduleConfig }
        ]);
    }
    create(value?: PartialMessage<SubMatchConfig>): SubMatchConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.subMatchConfigID = 0;
        message.sameWithDayOneA = 0;
        if (value !== undefined)
            reflectionMergePartial<SubMatchConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubMatchConfig): SubMatchConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 subMatchConfigID */ 1:
                    message.subMatchConfigID = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig */ 2:
                    message.blindInfoConfig = MatchBlindInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindInfoConfig);
                    break;
                case /* bpt.common.I18n i18n = 3 [json_name = "i18n"];*/ 3:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* bpt.common.SwitchState sameWithDayOneA */ 4:
                    message.sameWithDayOneA = reader.int32();
                    break;
                case /* bpt.match.matchhub.v1.EntryConfig entryConfig */ 5:
                    message.entryConfig = EntryConfig.internalBinaryRead(reader, reader.uint32(), options, message.entryConfig);
                    break;
                case /* bpt.match.matchhub.v1.SubMatchScheduleConfig subMatchScheduleConfig */ 7:
                    message.subMatchScheduleConfig = SubMatchScheduleConfig.internalBinaryRead(reader, reader.uint32(), options, message.subMatchScheduleConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubMatchConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 subMatchConfigID = 1; */
        if (message.subMatchConfigID !== 0)
            writer.tag(1, WireType.Varint).int64(message.subMatchConfigID);
        /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 2; */
        if (message.blindInfoConfig)
            MatchBlindInfoConfig.internalBinaryWrite(message.blindInfoConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.I18n i18n = 3 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState sameWithDayOneA = 4; */
        if (message.sameWithDayOneA !== 0)
            writer.tag(4, WireType.Varint).int32(message.sameWithDayOneA);
        /* bpt.match.matchhub.v1.EntryConfig entryConfig = 5; */
        if (message.entryConfig)
            EntryConfig.internalBinaryWrite(message.entryConfig, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.SubMatchScheduleConfig subMatchScheduleConfig = 7; */
        if (message.subMatchScheduleConfig)
            SubMatchScheduleConfig.internalBinaryWrite(message.subMatchScheduleConfig, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SubMatchConfig
 */
export const SubMatchConfig = new SubMatchConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SubMatchScheduleConfig$Type extends MessageType<SubMatchScheduleConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.SubMatchScheduleConfig", [
            { no: 8, name: "registrationDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SubMatchScheduleConfig>): SubMatchScheduleConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.registrationDuration = 0;
        if (value !== undefined)
            reflectionMergePartial<SubMatchScheduleConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SubMatchScheduleConfig): SubMatchScheduleConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 registrationDuration */ 8:
                    message.registrationDuration = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SubMatchScheduleConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 registrationDuration = 8; */
        if (message.registrationDuration !== 0)
            writer.tag(8, WireType.Varint).int64(message.registrationDuration);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SubMatchScheduleConfig
 */
export const SubMatchScheduleConfig = new SubMatchScheduleConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigBlindResp$Type extends MessageType<SaveMatchConfigBlindResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigBlindResp", []);
    }
    create(value?: PartialMessage<SaveMatchConfigBlindResp>): SaveMatchConfigBlindResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigBlindResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigBlindResp): SaveMatchConfigBlindResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveMatchConfigBlindResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigBlindResp
 */
export const SaveMatchConfigBlindResp = new SaveMatchConfigBlindResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigRewardReq$Type extends MessageType<SaveMatchConfigRewardReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigRewardReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "rewardInfoConfig", kind: "message", T: () => MatchRewardInfoConfig }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigRewardReq>): SaveMatchConfigRewardReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigRewardReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigRewardReq): SaveMatchConfigRewardReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig */ 2:
                    message.rewardInfoConfig = MatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.rewardInfoConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigRewardReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 2; */
        if (message.rewardInfoConfig)
            MatchRewardInfoConfig.internalBinaryWrite(message.rewardInfoConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigRewardReq
 */
export const SaveMatchConfigRewardReq = new SaveMatchConfigRewardReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchRewardInfoConfig$Type extends MessageType<MatchRewardInfoConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchRewardInfoConfig", [
            { no: 1, name: "prizeType", kind: "enum", T: () => ["bpt.match.common.v1.PrizeType", PrizeType] },
            { no: 2, name: "parallelPrizeConf", kind: "message", T: () => ParallelPrizeConf },
            { no: 3, name: "dynamicPrizeConf", kind: "message", T: () => DynamicPrizeConf },
            { no: 4, name: "autoSendPrize", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "prizePoolThreshold", kind: "message", T: () => AssetItem },
            { no: 6, name: "minPrizePoolCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MatchRewardInfoConfig>): MatchRewardInfoConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizeType = 0;
        message.autoSendPrize = 0;
        message.minPrizePoolCount = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchRewardInfoConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchRewardInfoConfig): MatchRewardInfoConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.PrizeType prizeType */ 1:
                    message.prizeType = reader.int32();
                    break;
                case /* bpt.match.common.v1.ParallelPrizeConf parallelPrizeConf */ 2:
                    message.parallelPrizeConf = ParallelPrizeConf.internalBinaryRead(reader, reader.uint32(), options, message.parallelPrizeConf);
                    break;
                case /* bpt.match.common.v1.DynamicPrizeConf dynamicPrizeConf */ 3:
                    message.dynamicPrizeConf = DynamicPrizeConf.internalBinaryRead(reader, reader.uint32(), options, message.dynamicPrizeConf);
                    break;
                case /* bpt.common.SwitchState autoSendPrize */ 4:
                    message.autoSendPrize = reader.int32();
                    break;
                case /* bpt.common.AssetItem prizePoolThreshold */ 5:
                    message.prizePoolThreshold = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.prizePoolThreshold);
                    break;
                case /* int32 minPrizePoolCount */ 6:
                    message.minPrizePoolCount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchRewardInfoConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.PrizeType prizeType = 1; */
        if (message.prizeType !== 0)
            writer.tag(1, WireType.Varint).int32(message.prizeType);
        /* bpt.match.common.v1.ParallelPrizeConf parallelPrizeConf = 2; */
        if (message.parallelPrizeConf)
            ParallelPrizeConf.internalBinaryWrite(message.parallelPrizeConf, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.DynamicPrizeConf dynamicPrizeConf = 3; */
        if (message.dynamicPrizeConf)
            DynamicPrizeConf.internalBinaryWrite(message.dynamicPrizeConf, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState autoSendPrize = 4; */
        if (message.autoSendPrize !== 0)
            writer.tag(4, WireType.Varint).int32(message.autoSendPrize);
        /* bpt.common.AssetItem prizePoolThreshold = 5; */
        if (message.prizePoolThreshold)
            AssetItem.internalBinaryWrite(message.prizePoolThreshold, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int32 minPrizePoolCount = 6; */
        if (message.minPrizePoolCount !== 0)
            writer.tag(6, WireType.Varint).int32(message.minPrizePoolCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchRewardInfoConfig
 */
export const MatchRewardInfoConfig = new MatchRewardInfoConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NewMatchRewardInfoConfig$Type extends MessageType<NewMatchRewardInfoConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.NewMatchRewardInfoConfig", [
            { no: 1, name: "autoSendPrize", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "matchRewardTierConfig", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchRewardTierConfig }
        ]);
    }
    create(value?: PartialMessage<NewMatchRewardInfoConfig>): NewMatchRewardInfoConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.autoSendPrize = 0;
        message.matchRewardTierConfig = [];
        if (value !== undefined)
            reflectionMergePartial<NewMatchRewardInfoConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NewMatchRewardInfoConfig): NewMatchRewardInfoConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState autoSendPrize */ 1:
                    message.autoSendPrize = reader.int32();
                    break;
                case /* repeated bpt.match.matchhub.v1.MatchRewardTierConfig matchRewardTierConfig */ 2:
                    message.matchRewardTierConfig.push(MatchRewardTierConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NewMatchRewardInfoConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState autoSendPrize = 1; */
        if (message.autoSendPrize !== 0)
            writer.tag(1, WireType.Varint).int32(message.autoSendPrize);
        /* repeated bpt.match.matchhub.v1.MatchRewardTierConfig matchRewardTierConfig = 2; */
        for (let i = 0; i < message.matchRewardTierConfig.length; i++)
            MatchRewardTierConfig.internalBinaryWrite(message.matchRewardTierConfig[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.NewMatchRewardInfoConfig
 */
export const NewMatchRewardInfoConfig = new NewMatchRewardInfoConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchRewardTierConfig$Type extends MessageType<MatchRewardTierConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchRewardTierConfig", [
            { no: 1, name: "start", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "end", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "basePool", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 4, name: "minPrizePoolCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "prizePoolThreshold", kind: "message", T: () => AssetItem },
            { no: 6, name: "prizePercentage", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "rankPrizes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RankPrize }
        ]);
    }
    create(value?: PartialMessage<MatchRewardTierConfig>): MatchRewardTierConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.start = 0;
        message.end = 0;
        message.basePool = [];
        message.minPrizePoolCount = 0;
        message.prizePercentage = 0;
        message.rankPrizes = [];
        if (value !== undefined)
            reflectionMergePartial<MatchRewardTierConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchRewardTierConfig): MatchRewardTierConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 start */ 1:
                    message.start = reader.uint32();
                    break;
                case /* uint32 end */ 2:
                    message.end = reader.uint32();
                    break;
                case /* repeated bpt.common.AssetItem basePool */ 3:
                    message.basePool.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 minPrizePoolCount */ 4:
                    message.minPrizePoolCount = reader.int32();
                    break;
                case /* bpt.common.AssetItem prizePoolThreshold */ 5:
                    message.prizePoolThreshold = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.prizePoolThreshold);
                    break;
                case /* uint32 prizePercentage */ 6:
                    message.prizePercentage = reader.uint32();
                    break;
                case /* repeated bpt.match.common.v1.RankPrize rankPrizes */ 7:
                    message.rankPrizes.push(RankPrize.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchRewardTierConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 start = 1; */
        if (message.start !== 0)
            writer.tag(1, WireType.Varint).uint32(message.start);
        /* uint32 end = 2; */
        if (message.end !== 0)
            writer.tag(2, WireType.Varint).uint32(message.end);
        /* repeated bpt.common.AssetItem basePool = 3; */
        for (let i = 0; i < message.basePool.length; i++)
            AssetItem.internalBinaryWrite(message.basePool[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 minPrizePoolCount = 4; */
        if (message.minPrizePoolCount !== 0)
            writer.tag(4, WireType.Varint).int32(message.minPrizePoolCount);
        /* bpt.common.AssetItem prizePoolThreshold = 5; */
        if (message.prizePoolThreshold)
            AssetItem.internalBinaryWrite(message.prizePoolThreshold, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint32 prizePercentage = 6; */
        if (message.prizePercentage !== 0)
            writer.tag(6, WireType.Varint).uint32(message.prizePercentage);
        /* repeated bpt.match.common.v1.RankPrize rankPrizes = 7; */
        for (let i = 0; i < message.rankPrizes.length; i++)
            RankPrize.internalBinaryWrite(message.rankPrizes[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchRewardTierConfig
 */
export const MatchRewardTierConfig = new MatchRewardTierConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigRewardResp$Type extends MessageType<SaveMatchConfigRewardResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigRewardResp", []);
    }
    create(value?: PartialMessage<SaveMatchConfigRewardResp>): SaveMatchConfigRewardResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigRewardResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigRewardResp): SaveMatchConfigRewardResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveMatchConfigRewardResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigRewardResp
 */
export const SaveMatchConfigRewardResp = new SaveMatchConfigRewardResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigAllReq$Type extends MessageType<SaveMatchConfigAllReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigAllReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "baseConfig", kind: "message", T: () => MatchBaseConfig },
            { no: 3, name: "blindInfoConfig", kind: "message", T: () => MatchBlindInfoConfig },
            { no: 4, name: "rewardInfoConfig", kind: "message", T: () => MatchRewardInfoConfig },
            { no: 5, name: "subMatchConfigs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatchConfig },
            { no: 6, name: "newRewardInfoConfig", kind: "message", T: () => NewMatchRewardInfoConfig }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigAllReq>): SaveMatchConfigAllReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.subMatchConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigAllReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigAllReq): SaveMatchConfigAllReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig */ 2:
                    message.baseConfig = MatchBaseConfig.internalBinaryRead(reader, reader.uint32(), options, message.baseConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig */ 3:
                    message.blindInfoConfig = MatchBlindInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindInfoConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig */ 4:
                    message.rewardInfoConfig = MatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.rewardInfoConfig);
                    break;
                case /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs */ 5:
                    message.subMatchConfigs.push(SubMatchConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig */ 6:
                    message.newRewardInfoConfig = NewMatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.newRewardInfoConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigAllReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2; */
        if (message.baseConfig)
            MatchBaseConfig.internalBinaryWrite(message.baseConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3; */
        if (message.blindInfoConfig)
            MatchBlindInfoConfig.internalBinaryWrite(message.blindInfoConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4; */
        if (message.rewardInfoConfig)
            MatchRewardInfoConfig.internalBinaryWrite(message.rewardInfoConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5; */
        for (let i = 0; i < message.subMatchConfigs.length; i++)
            SubMatchConfig.internalBinaryWrite(message.subMatchConfigs[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6; */
        if (message.newRewardInfoConfig)
            NewMatchRewardInfoConfig.internalBinaryWrite(message.newRewardInfoConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigAllReq
 */
export const SaveMatchConfigAllReq = new SaveMatchConfigAllReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigAllResp$Type extends MessageType<SaveMatchConfigAllResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigAllResp", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "baseConfig", kind: "message", T: () => MatchBaseConfig },
            { no: 3, name: "blindInfoConfig", kind: "message", T: () => MatchBlindInfoConfig },
            { no: 4, name: "rewardInfoConfig", kind: "message", T: () => MatchRewardInfoConfig },
            { no: 5, name: "subMatchConfigs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SubMatchConfig },
            { no: 6, name: "newRewardInfoConfig", kind: "message", T: () => NewMatchRewardInfoConfig }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigAllResp>): SaveMatchConfigAllResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.subMatchConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigAllResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigAllResp): SaveMatchConfigAllResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig */ 2:
                    message.baseConfig = MatchBaseConfig.internalBinaryRead(reader, reader.uint32(), options, message.baseConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig */ 3:
                    message.blindInfoConfig = MatchBlindInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindInfoConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig */ 4:
                    message.rewardInfoConfig = MatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.rewardInfoConfig);
                    break;
                case /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs */ 5:
                    message.subMatchConfigs.push(SubMatchConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig */ 6:
                    message.newRewardInfoConfig = NewMatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.newRewardInfoConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigAllResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2; */
        if (message.baseConfig)
            MatchBaseConfig.internalBinaryWrite(message.baseConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 3; */
        if (message.blindInfoConfig)
            MatchBlindInfoConfig.internalBinaryWrite(message.blindInfoConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 4; */
        if (message.rewardInfoConfig)
            MatchRewardInfoConfig.internalBinaryWrite(message.rewardInfoConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.SubMatchConfig subMatchConfigs = 5; */
        for (let i = 0; i < message.subMatchConfigs.length; i++)
            SubMatchConfig.internalBinaryWrite(message.subMatchConfigs[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.NewMatchRewardInfoConfig newRewardInfoConfig = 6; */
        if (message.newRewardInfoConfig)
            NewMatchRewardInfoConfig.internalBinaryWrite(message.newRewardInfoConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigAllResp
 */
export const SaveMatchConfigAllResp = new SaveMatchConfigAllResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigBaseReq$Type extends MessageType<SaveMatchConfigBaseReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigBaseReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "baseConfig", kind: "message", T: () => MatchBaseConfig }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigBaseReq>): SaveMatchConfigBaseReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigBaseReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigBaseReq): SaveMatchConfigBaseReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig */ 2:
                    message.baseConfig = MatchBaseConfig.internalBinaryRead(reader, reader.uint32(), options, message.baseConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigBaseReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* bpt.match.matchhub.v1.MatchBaseConfig baseConfig = 2; */
        if (message.baseConfig)
            MatchBaseConfig.internalBinaryWrite(message.baseConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigBaseReq
 */
export const SaveMatchConfigBaseReq = new SaveMatchConfigBaseReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchGameOverConfig$Type extends MessageType<MatchGameOverConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchGameOverConfig", [
            { no: 1, name: "gameOverType", kind: "enum", T: () => ["bpt.match.common.v1.GameOverType", GameOverType] },
            { no: 2, name: "gameOverBlindLevel", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "gameOverPercentage", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<MatchGameOverConfig>): MatchGameOverConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.gameOverType = 0;
        message.gameOverBlindLevel = 0;
        message.gameOverPercentage = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchGameOverConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchGameOverConfig): MatchGameOverConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.GameOverType gameOverType */ 1:
                    message.gameOverType = reader.int32();
                    break;
                case /* uint32 gameOverBlindLevel */ 2:
                    message.gameOverBlindLevel = reader.uint32();
                    break;
                case /* uint32 gameOverPercentage */ 3:
                    message.gameOverPercentage = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchGameOverConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.GameOverType gameOverType = 1; */
        if (message.gameOverType !== 0)
            writer.tag(1, WireType.Varint).int32(message.gameOverType);
        /* uint32 gameOverBlindLevel = 2; */
        if (message.gameOverBlindLevel !== 0)
            writer.tag(2, WireType.Varint).uint32(message.gameOverBlindLevel);
        /* uint32 gameOverPercentage = 3; */
        if (message.gameOverPercentage !== 0)
            writer.tag(3, WireType.Varint).uint32(message.gameOverPercentage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchGameOverConfig
 */
export const MatchGameOverConfig = new MatchGameOverConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchBaseConfig$Type extends MessageType<MatchBaseConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchBaseConfig", [
            { no: 2, name: "baseInfo", kind: "message", T: () => MatchConfigBase },
            { no: 3, name: "scheduleConfig", kind: "message", T: () => ScheduleConfig },
            { no: 4, name: "entryConfig", kind: "message", T: () => EntryConfig },
            { no: 5, name: "coverConfig", kind: "message", T: () => MatchCoverConfig },
            { no: 6, name: "earlyBird", kind: "message", T: () => EarlyBirdConfig },
            { no: 7, name: "serviceConf", kind: "message", T: () => ServiceConf },
            { no: 8, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18n },
            { no: 9, name: "reportToNews", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 10, name: "showPriority", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "clubId", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 12, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "matchGameOverConfig", kind: "message", T: () => MatchGameOverConfig },
            { no: 14, name: "seatMatchConfig", kind: "message", T: () => SeatMatchConfig },
            { no: 15, name: "enableHourlyRest", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] }
        ]);
    }
    create(value?: PartialMessage<MatchBaseConfig>): MatchBaseConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.reportToNews = 0;
        message.showPriority = 0;
        message.clubId = 0;
        message.tags = [];
        message.enableHourlyRest = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchBaseConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchBaseConfig): MatchBaseConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfigBase baseInfo */ 2:
                    message.baseInfo = MatchConfigBase.internalBinaryRead(reader, reader.uint32(), options, message.baseInfo);
                    break;
                case /* bpt.match.common.v1.ScheduleConfig scheduleConfig */ 3:
                    message.scheduleConfig = ScheduleConfig.internalBinaryRead(reader, reader.uint32(), options, message.scheduleConfig);
                    break;
                case /* bpt.match.matchhub.v1.EntryConfig entryConfig */ 4:
                    message.entryConfig = EntryConfig.internalBinaryRead(reader, reader.uint32(), options, message.entryConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchCoverConfig coverConfig */ 5:
                    message.coverConfig = MatchCoverConfig.internalBinaryRead(reader, reader.uint32(), options, message.coverConfig);
                    break;
                case /* bpt.match.matchhub.v1.EarlyBirdConfig earlyBird */ 6:
                    message.earlyBird = EarlyBirdConfig.internalBinaryRead(reader, reader.uint32(), options, message.earlyBird);
                    break;
                case /* bpt.match.common.v1.ServiceConf serviceConf */ 7:
                    message.serviceConf = ServiceConf.internalBinaryRead(reader, reader.uint32(), options, message.serviceConf);
                    break;
                case /* bpt.common.I18n i18n = 8 [json_name = "i18n"];*/ 8:
                    message.i18n = I18n.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* bpt.common.SwitchState reportToNews */ 9:
                    message.reportToNews = reader.int32();
                    break;
                case /* int32 showPriority */ 10:
                    message.showPriority = reader.int32();
                    break;
                case /* uint32 clubId */ 11:
                    message.clubId = reader.uint32();
                    break;
                case /* repeated string tags */ 12:
                    message.tags.push(reader.string());
                    break;
                case /* bpt.match.matchhub.v1.MatchGameOverConfig matchGameOverConfig */ 13:
                    message.matchGameOverConfig = MatchGameOverConfig.internalBinaryRead(reader, reader.uint32(), options, message.matchGameOverConfig);
                    break;
                case /* bpt.match.matchhub.v1.SeatMatchConfig seatMatchConfig */ 14:
                    message.seatMatchConfig = SeatMatchConfig.internalBinaryRead(reader, reader.uint32(), options, message.seatMatchConfig);
                    break;
                case /* bpt.common.SwitchState enableHourlyRest */ 15:
                    message.enableHourlyRest = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchBaseConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfigBase baseInfo = 2; */
        if (message.baseInfo)
            MatchConfigBase.internalBinaryWrite(message.baseInfo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ScheduleConfig scheduleConfig = 3; */
        if (message.scheduleConfig)
            ScheduleConfig.internalBinaryWrite(message.scheduleConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.EntryConfig entryConfig = 4; */
        if (message.entryConfig)
            EntryConfig.internalBinaryWrite(message.entryConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchCoverConfig coverConfig = 5; */
        if (message.coverConfig)
            MatchCoverConfig.internalBinaryWrite(message.coverConfig, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.EarlyBirdConfig earlyBird = 6; */
        if (message.earlyBird)
            EarlyBirdConfig.internalBinaryWrite(message.earlyBird, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.common.v1.ServiceConf serviceConf = 7; */
        if (message.serviceConf)
            ServiceConf.internalBinaryWrite(message.serviceConf, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.I18n i18n = 8 [json_name = "i18n"]; */
        if (message.i18n)
            I18n.internalBinaryWrite(message.i18n, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState reportToNews = 9; */
        if (message.reportToNews !== 0)
            writer.tag(9, WireType.Varint).int32(message.reportToNews);
        /* int32 showPriority = 10; */
        if (message.showPriority !== 0)
            writer.tag(10, WireType.Varint).int32(message.showPriority);
        /* uint32 clubId = 11; */
        if (message.clubId !== 0)
            writer.tag(11, WireType.Varint).uint32(message.clubId);
        /* repeated string tags = 12; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(12, WireType.LengthDelimited).string(message.tags[i]);
        /* bpt.match.matchhub.v1.MatchGameOverConfig matchGameOverConfig = 13; */
        if (message.matchGameOverConfig)
            MatchGameOverConfig.internalBinaryWrite(message.matchGameOverConfig, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.SeatMatchConfig seatMatchConfig = 14; */
        if (message.seatMatchConfig)
            SeatMatchConfig.internalBinaryWrite(message.seatMatchConfig, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState enableHourlyRest = 15; */
        if (message.enableHourlyRest !== 0)
            writer.tag(15, WireType.Varint).int32(message.enableHourlyRest);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchBaseConfig
 */
export const MatchBaseConfig = new MatchBaseConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SeatMatchConfig$Type extends MessageType<SeatMatchConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.SeatMatchConfig", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "targetMatchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "maxAdvancePlayerNum", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<SeatMatchConfig>): SeatMatchConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.targetMatchKey = "";
        message.maxAdvancePlayerNum = 0;
        if (value !== undefined)
            reflectionMergePartial<SeatMatchConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SeatMatchConfig): SeatMatchConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* string targetMatchKey */ 2:
                    message.targetMatchKey = reader.string();
                    break;
                case /* uint32 maxAdvancePlayerNum */ 3:
                    message.maxAdvancePlayerNum = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SeatMatchConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* string targetMatchKey = 2; */
        if (message.targetMatchKey !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.targetMatchKey);
        /* uint32 maxAdvancePlayerNum = 3; */
        if (message.maxAdvancePlayerNum !== 0)
            writer.tag(3, WireType.Varint).uint32(message.maxAdvancePlayerNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SeatMatchConfig
 */
export const SeatMatchConfig = new SeatMatchConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigBaseResp$Type extends MessageType<SaveMatchConfigBaseResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigBaseResp", []);
    }
    create(value?: PartialMessage<SaveMatchConfigBaseResp>): SaveMatchConfigBaseResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigBaseResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigBaseResp): SaveMatchConfigBaseResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveMatchConfigBaseResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigBaseResp
 */
export const SaveMatchConfigBaseResp = new SaveMatchConfigBaseResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMatchConfigReq$Type extends MessageType<CreateMatchConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.CreateMatchConfigReq", [
            { no: 1, name: "chainID", kind: "enum", T: () => ["gaw.base.ChainID", ChainID] },
            { no: 2, name: "dispatchContract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] }
        ]);
    }
    create(value?: PartialMessage<CreateMatchConfigReq>): CreateMatchConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.chainID = 0;
        message.dispatchContract = "";
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateMatchConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMatchConfigReq): CreateMatchConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* gaw.base.ChainID chainID */ 1:
                    message.chainID = reader.int32();
                    break;
                case /* string dispatchContract */ 2:
                    message.dispatchContract = reader.string();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 3:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMatchConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* gaw.base.ChainID chainID = 1; */
        if (message.chainID !== 0)
            writer.tag(1, WireType.Varint).int32(message.chainID);
        /* string dispatchContract = 2; */
        if (message.dispatchContract !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.dispatchContract);
        /* bpt.match.common.v1.MatchType matchType = 3; */
        if (message.matchType !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.CreateMatchConfigReq
 */
export const CreateMatchConfigReq = new CreateMatchConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateMatchConfigResp$Type extends MessageType<CreateMatchConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.CreateMatchConfigResp", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<CreateMatchConfigResp>): CreateMatchConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        if (value !== undefined)
            reflectionMergePartial<CreateMatchConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateMatchConfigResp): CreateMatchConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateMatchConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.CreateMatchConfigResp
 */
export const CreateMatchConfigResp = new CreateMatchConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchConfigBase$Type extends MessageType<MatchConfigBase> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchConfigBase", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "matchType", kind: "enum", T: () => ["bpt.match.common.v1.MatchType", MatchType] },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "gameType", kind: "enum", T: () => ["bpt.match.common.v1.BetWay", BetWay] },
            { no: 5, name: "matchCategory", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 6, name: "rule", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "minPlayerNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 8, name: "maxPlayerNum", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "playerPerTable", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "playerFinalTable", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<MatchConfigBase>): MatchConfigBase {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.matchType = 0;
        message.name = "";
        message.gameType = 0;
        message.matchCategory = 0;
        message.rule = "";
        message.minPlayerNum = 0;
        message.maxPlayerNum = 0;
        message.playerPerTable = 0;
        message.playerFinalTable = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchConfigBase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchConfigBase): MatchConfigBase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* bpt.match.common.v1.MatchType matchType */ 2:
                    message.matchType = reader.int32();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* bpt.match.common.v1.BetWay gameType */ 4:
                    message.gameType = reader.int32();
                    break;
                case /* bpt.match.common.v1.MatchListType matchCategory */ 5:
                    message.matchCategory = reader.int32();
                    break;
                case /* string rule */ 6:
                    message.rule = reader.string();
                    break;
                case /* int32 minPlayerNum */ 7:
                    message.minPlayerNum = reader.int32();
                    break;
                case /* int32 maxPlayerNum */ 8:
                    message.maxPlayerNum = reader.int32();
                    break;
                case /* int32 playerPerTable */ 9:
                    message.playerPerTable = reader.int32();
                    break;
                case /* int32 playerFinalTable */ 10:
                    message.playerFinalTable = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchConfigBase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* bpt.match.common.v1.MatchType matchType = 2; */
        if (message.matchType !== 0)
            writer.tag(2, WireType.Varint).int32(message.matchType);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* bpt.match.common.v1.BetWay gameType = 4; */
        if (message.gameType !== 0)
            writer.tag(4, WireType.Varint).int32(message.gameType);
        /* bpt.match.common.v1.MatchListType matchCategory = 5; */
        if (message.matchCategory !== 0)
            writer.tag(5, WireType.Varint).int32(message.matchCategory);
        /* string rule = 6; */
        if (message.rule !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.rule);
        /* int32 minPlayerNum = 7; */
        if (message.minPlayerNum !== 0)
            writer.tag(7, WireType.Varint).int32(message.minPlayerNum);
        /* int32 maxPlayerNum = 8; */
        if (message.maxPlayerNum !== 0)
            writer.tag(8, WireType.Varint).int32(message.maxPlayerNum);
        /* int32 playerPerTable = 9; */
        if (message.playerPerTable !== 0)
            writer.tag(9, WireType.Varint).int32(message.playerPerTable);
        /* int32 playerFinalTable = 10; */
        if (message.playerFinalTable !== 0)
            writer.tag(10, WireType.Varint).int32(message.playerFinalTable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchConfigBase
 */
export const MatchConfigBase = new MatchConfigBase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntryConfig$Type extends MessageType<EntryConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.EntryConfig", [
            { no: 1, name: "buyIn", kind: "message", T: () => AssetItem },
            { no: 2, name: "ticketsConfig", kind: "message", T: () => EntryTicketsConfig },
            { no: 3, name: "autoApply", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 4, name: "switchOnChain", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 5, name: "clubIds", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 6, name: "vipLevels", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 13 /*ScalarType.UINT32*/ },
            { no: 7, name: "identityLevels", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityLevel", EntryConfig_IdentityLevel] },
            { no: 8, name: "freeEntryConfig", kind: "message", T: () => FreeEntryConfig },
            { no: 9, name: "identityTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityType", EntryConfig_IdentityType] }
        ]);
    }
    create(value?: PartialMessage<EntryConfig>): EntryConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.autoApply = 0;
        message.switchOnChain = 0;
        message.clubIds = [];
        message.vipLevels = [];
        message.identityLevels = [];
        message.identityTypes = [];
        if (value !== undefined)
            reflectionMergePartial<EntryConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntryConfig): EntryConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.AssetItem buyIn */ 1:
                    message.buyIn = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.buyIn);
                    break;
                case /* bpt.match.matchhub.v1.EntryTicketsConfig ticketsConfig */ 2:
                    message.ticketsConfig = EntryTicketsConfig.internalBinaryRead(reader, reader.uint32(), options, message.ticketsConfig);
                    break;
                case /* bpt.common.SwitchState autoApply */ 3:
                    message.autoApply = reader.int32();
                    break;
                case /* bpt.common.SwitchState switchOnChain */ 4:
                    message.switchOnChain = reader.int32();
                    break;
                case /* repeated uint32 clubIds */ 5:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.clubIds.push(reader.uint32());
                    else
                        message.clubIds.push(reader.uint32());
                    break;
                case /* repeated uint32 vipLevels */ 6:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.vipLevels.push(reader.uint32());
                    else
                        message.vipLevels.push(reader.uint32());
                    break;
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels */ 7:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityLevels.push(reader.int32());
                    else
                        message.identityLevels.push(reader.int32());
                    break;
                case /* bpt.match.matchhub.v1.FreeEntryConfig freeEntryConfig */ 8:
                    message.freeEntryConfig = FreeEntryConfig.internalBinaryRead(reader, reader.uint32(), options, message.freeEntryConfig);
                    break;
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes */ 9:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityTypes.push(reader.int32());
                    else
                        message.identityTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntryConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.AssetItem buyIn = 1; */
        if (message.buyIn)
            AssetItem.internalBinaryWrite(message.buyIn, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.EntryTicketsConfig ticketsConfig = 2; */
        if (message.ticketsConfig)
            EntryTicketsConfig.internalBinaryWrite(message.ticketsConfig, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState autoApply = 3; */
        if (message.autoApply !== 0)
            writer.tag(3, WireType.Varint).int32(message.autoApply);
        /* bpt.common.SwitchState switchOnChain = 4; */
        if (message.switchOnChain !== 0)
            writer.tag(4, WireType.Varint).int32(message.switchOnChain);
        /* repeated uint32 clubIds = 5; */
        if (message.clubIds.length) {
            writer.tag(5, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.clubIds.length; i++)
                writer.uint32(message.clubIds[i]);
            writer.join();
        }
        /* repeated uint32 vipLevels = 6; */
        if (message.vipLevels.length) {
            writer.tag(6, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.vipLevels.length; i++)
                writer.uint32(message.vipLevels[i]);
            writer.join();
        }
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 7; */
        if (message.identityLevels.length) {
            writer.tag(7, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityLevels.length; i++)
                writer.int32(message.identityLevels[i]);
            writer.join();
        }
        /* bpt.match.matchhub.v1.FreeEntryConfig freeEntryConfig = 8; */
        if (message.freeEntryConfig)
            FreeEntryConfig.internalBinaryWrite(message.freeEntryConfig, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 9; */
        if (message.identityTypes.length) {
            writer.tag(9, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityTypes.length; i++)
                writer.int32(message.identityTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.EntryConfig
 */
export const EntryConfig = new EntryConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FreeEntryConfig$Type extends MessageType<FreeEntryConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.FreeEntryConfig", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "identityLevels", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityLevel", EntryConfig_IdentityLevel] },
            { no: 3, name: "identityTypes", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.match.matchhub.v1.EntryConfig.IdentityType", EntryConfig_IdentityType] }
        ]);
    }
    create(value?: PartialMessage<FreeEntryConfig>): FreeEntryConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.identityLevels = [];
        message.identityTypes = [];
        if (value !== undefined)
            reflectionMergePartial<FreeEntryConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FreeEntryConfig): FreeEntryConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityLevels.push(reader.int32());
                    else
                        message.identityLevels.push(reader.int32());
                    break;
                case /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes */ 3:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.identityTypes.push(reader.int32());
                    else
                        message.identityTypes.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FreeEntryConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityLevel identityLevels = 2; */
        if (message.identityLevels.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityLevels.length; i++)
                writer.int32(message.identityLevels[i]);
            writer.join();
        }
        /* repeated bpt.match.matchhub.v1.EntryConfig.IdentityType identityTypes = 3; */
        if (message.identityTypes.length) {
            writer.tag(3, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.identityTypes.length; i++)
                writer.int32(message.identityTypes[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.FreeEntryConfig
 */
export const FreeEntryConfig = new FreeEntryConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EntryTicketsConfig$Type extends MessageType<EntryTicketsConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.EntryTicketsConfig", [
            { no: 1, name: "switch", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "propId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "registerTicketCnt", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<EntryTicketsConfig>): EntryTicketsConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.switch = 0;
        message.propId = 0;
        message.registerTicketCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<EntryTicketsConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EntryTicketsConfig): EntryTicketsConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState switch */ 1:
                    message.switch = reader.int32();
                    break;
                case /* int64 propId */ 2:
                    message.propId = reader.int64().toNumber();
                    break;
                case /* int32 registerTicketCnt */ 3:
                    message.registerTicketCnt = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EntryTicketsConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState switch = 1; */
        if (message.switch !== 0)
            writer.tag(1, WireType.Varint).int32(message.switch);
        /* int64 propId = 2; */
        if (message.propId !== 0)
            writer.tag(2, WireType.Varint).int64(message.propId);
        /* int32 registerTicketCnt = 3; */
        if (message.registerTicketCnt !== 0)
            writer.tag(3, WireType.Varint).int32(message.registerTicketCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.EntryTicketsConfig
 */
export const EntryTicketsConfig = new EntryTicketsConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchCoverConfig$Type extends MessageType<MatchCoverConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchCoverConfig", [
            { no: 1, name: "showImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "showImgMobile", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "mobileShowImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "mobileShowImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "showImgList", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "showImgDetail", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchCoverConfig>): MatchCoverConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.showImg = "";
        message.showImgMobile = "";
        message.mobileShowImgList = "";
        message.mobileShowImgDetail = "";
        message.showImgList = "";
        message.showImgDetail = "";
        if (value !== undefined)
            reflectionMergePartial<MatchCoverConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchCoverConfig): MatchCoverConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string showImg */ 1:
                    message.showImg = reader.string();
                    break;
                case /* string showImgMobile */ 2:
                    message.showImgMobile = reader.string();
                    break;
                case /* string mobileShowImgList */ 3:
                    message.mobileShowImgList = reader.string();
                    break;
                case /* string mobileShowImgDetail */ 4:
                    message.mobileShowImgDetail = reader.string();
                    break;
                case /* string showImgList */ 5:
                    message.showImgList = reader.string();
                    break;
                case /* string showImgDetail */ 6:
                    message.showImgDetail = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchCoverConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string showImg = 1; */
        if (message.showImg !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.showImg);
        /* string showImgMobile = 2; */
        if (message.showImgMobile !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.showImgMobile);
        /* string mobileShowImgList = 3; */
        if (message.mobileShowImgList !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.mobileShowImgList);
        /* string mobileShowImgDetail = 4; */
        if (message.mobileShowImgDetail !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.mobileShowImgDetail);
        /* string showImgList = 5; */
        if (message.showImgList !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.showImgList);
        /* string showImgDetail = 6; */
        if (message.showImgDetail !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.showImgDetail);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchCoverConfig
 */
export const MatchCoverConfig = new MatchCoverConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarlyBirdConfig$Type extends MessageType<EarlyBirdConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.EarlyBirdConfig", [
            { no: 1, name: "isOpen", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 2, name: "isBroadcast", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 3, name: "getDuration", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "award", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EarlyBirdAwardConfig },
            { no: 5, name: "avgAward", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EarlyBirdAvgAwardConfig }
        ]);
    }
    create(value?: PartialMessage<EarlyBirdConfig>): EarlyBirdConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.isOpen = 0;
        message.isBroadcast = 0;
        message.getDuration = 0;
        message.award = [];
        message.avgAward = [];
        if (value !== undefined)
            reflectionMergePartial<EarlyBirdConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarlyBirdConfig): EarlyBirdConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.SwitchState isOpen */ 1:
                    message.isOpen = reader.int32();
                    break;
                case /* bpt.common.SwitchState isBroadcast */ 2:
                    message.isBroadcast = reader.int32();
                    break;
                case /* int64 getDuration */ 3:
                    message.getDuration = reader.int64().toNumber();
                    break;
                case /* repeated bpt.match.matchhub.v1.EarlyBirdAwardConfig award */ 4:
                    message.award.push(EarlyBirdAwardConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated bpt.match.matchhub.v1.EarlyBirdAvgAwardConfig avgAward */ 5:
                    message.avgAward.push(EarlyBirdAvgAwardConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EarlyBirdConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.SwitchState isOpen = 1; */
        if (message.isOpen !== 0)
            writer.tag(1, WireType.Varint).int32(message.isOpen);
        /* bpt.common.SwitchState isBroadcast = 2; */
        if (message.isBroadcast !== 0)
            writer.tag(2, WireType.Varint).int32(message.isBroadcast);
        /* int64 getDuration = 3; */
        if (message.getDuration !== 0)
            writer.tag(3, WireType.Varint).int64(message.getDuration);
        /* repeated bpt.match.matchhub.v1.EarlyBirdAwardConfig award = 4; */
        for (let i = 0; i < message.award.length; i++)
            EarlyBirdAwardConfig.internalBinaryWrite(message.award[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated bpt.match.matchhub.v1.EarlyBirdAvgAwardConfig avgAward = 5; */
        for (let i = 0; i < message.avgAward.length; i++)
            EarlyBirdAvgAwardConfig.internalBinaryWrite(message.avgAward[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.EarlyBirdConfig
 */
export const EarlyBirdConfig = new EarlyBirdConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarlyBirdAwardConfig$Type extends MessageType<EarlyBirdAwardConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.EarlyBirdAwardConfig", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "award", kind: "message", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<EarlyBirdAwardConfig>): EarlyBirdAwardConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        if (value !== undefined)
            reflectionMergePartial<EarlyBirdAwardConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarlyBirdAwardConfig): EarlyBirdAwardConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                case /* bpt.common.AssetItem award */ 3:
                    message.award = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EarlyBirdAwardConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        /* bpt.common.AssetItem award = 3; */
        if (message.award)
            AssetItem.internalBinaryWrite(message.award, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.EarlyBirdAwardConfig
 */
export const EarlyBirdAwardConfig = new EarlyBirdAwardConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EarlyBirdAvgAwardConfig$Type extends MessageType<EarlyBirdAvgAwardConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.EarlyBirdAvgAwardConfig", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "upLimit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "award", kind: "message", T: () => AssetItem },
            { no: 4, name: "upLimitAssetItem", kind: "message", T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<EarlyBirdAvgAwardConfig>): EarlyBirdAvgAwardConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        message.upLimit = 0;
        if (value !== undefined)
            reflectionMergePartial<EarlyBirdAvgAwardConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EarlyBirdAvgAwardConfig): EarlyBirdAvgAwardConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                case /* int32 upLimit */ 2:
                    message.upLimit = reader.int32();
                    break;
                case /* bpt.common.AssetItem award */ 3:
                    message.award = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                case /* bpt.common.AssetItem upLimitAssetItem */ 4:
                    message.upLimitAssetItem = AssetItem.internalBinaryRead(reader, reader.uint32(), options, message.upLimitAssetItem);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EarlyBirdAvgAwardConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        /* int32 upLimit = 2; */
        if (message.upLimit !== 0)
            writer.tag(2, WireType.Varint).int32(message.upLimit);
        /* bpt.common.AssetItem award = 3; */
        if (message.award)
            AssetItem.internalBinaryWrite(message.award, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.AssetItem upLimitAssetItem = 4; */
        if (message.upLimitAssetItem)
            AssetItem.internalBinaryWrite(message.upLimitAssetItem, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.EarlyBirdAvgAwardConfig
 */
export const EarlyBirdAvgAwardConfig = new EarlyBirdAvgAwardConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigTemplateReq$Type extends MessageType<SaveMatchConfigTemplateReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigTemplateReq", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmpl },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigTemplateReq>): SaveMatchConfigTemplateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.operator = "";
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigTemplateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigTemplateReq): SaveMatchConfigTemplateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmpl tmpl */ 1:
                    message.tmpl = MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigTemplateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1; */
        if (message.tmpl)
            MatchConfTmpl.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateReq
 */
export const SaveMatchConfigTemplateReq = new SaveMatchConfigTemplateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigTemplateResp$Type extends MessageType<SaveMatchConfigTemplateResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigTemplateResp", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmpl }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigTemplateResp>): SaveMatchConfigTemplateResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigTemplateResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigTemplateResp): SaveMatchConfigTemplateResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmpl tmpl */ 1:
                    message.tmpl = MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigTemplateResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1; */
        if (message.tmpl)
            MatchConfTmpl.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateResp
 */
export const SaveMatchConfigTemplateResp = new SaveMatchConfigTemplateResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfigTemplateReq$Type extends MessageType<GetMatchConfigTemplateReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfigTemplateReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfigTemplateReq>): GetMatchConfigTemplateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfigTemplateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfigTemplateReq): GetMatchConfigTemplateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfigTemplateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateReq
 */
export const GetMatchConfigTemplateReq = new GetMatchConfigTemplateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfigTemplateResp$Type extends MessageType<GetMatchConfigTemplateResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfigTemplateResp", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmpl }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfigTemplateResp>): GetMatchConfigTemplateResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfigTemplateResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfigTemplateResp): GetMatchConfigTemplateResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmpl tmpl */ 1:
                    message.tmpl = MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfigTemplateResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmpl tmpl = 1; */
        if (message.tmpl)
            MatchConfTmpl.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateResp
 */
export const GetMatchConfigTemplateResp = new GetMatchConfigTemplateResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigTemplateReq$Type extends MessageType<ListMatchConfigTemplateReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigTemplateReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "matchType", kind: "enum", T: () => ["bpt.match.matchhub.v1.MatchConfTmplType", MatchConfTmplType] }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigTemplateReq>): ListMatchConfigTemplateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.matchType = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigTemplateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigTemplateReq): ListMatchConfigTemplateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchConfTmplType matchType */ 3:
                    message.matchType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigTemplateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bpt.match.matchhub.v1.MatchConfTmplType matchType = 3; */
        if (message.matchType !== 0)
            writer.tag(3, WireType.Varint).int32(message.matchType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateReq
 */
export const ListMatchConfigTemplateReq = new ListMatchConfigTemplateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigTemplateResp$Type extends MessageType<ListMatchConfigTemplateResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigTemplateResp", [
            { no: 1, name: "tmplList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchConfTmpl },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigTemplateResp>): ListMatchConfigTemplateResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tmplList = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigTemplateResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigTemplateResp): ListMatchConfigTemplateResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchConfTmpl tmplList */ 1:
                    message.tmplList.push(MatchConfTmpl.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigTemplateResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchConfTmpl tmplList = 1; */
        for (let i = 0; i < message.tmplList.length; i++)
            MatchConfTmpl.internalBinaryWrite(message.tmplList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateResp
 */
export const ListMatchConfigTemplateResp = new ListMatchConfigTemplateResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfigTemplateReq$Type extends MessageType<DeleteMatchConfigTemplateReq> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfigTemplateReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchConfigTemplateReq>): DeleteMatchConfigTemplateReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfigTemplateReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfigTemplateReq): DeleteMatchConfigTemplateReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchConfigTemplateReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfigTemplateReq
 */
export const DeleteMatchConfigTemplateReq = new DeleteMatchConfigTemplateReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfigTemplateResp$Type extends MessageType<DeleteMatchConfigTemplateResp> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfigTemplateResp", []);
    }
    create(value?: PartialMessage<DeleteMatchConfigTemplateResp>): DeleteMatchConfigTemplateResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfigTemplateResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfigTemplateResp): DeleteMatchConfigTemplateResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteMatchConfigTemplateResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfigTemplateResp
 */
export const DeleteMatchConfigTemplateResp = new DeleteMatchConfigTemplateResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigOperationLogReq$Type extends MessageType<ListMatchConfigOperationLogReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigOperationLogReq", [
            { no: 1, name: "matchConfigId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigOperationLogReq>): ListMatchConfigOperationLogReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchConfigId = 0;
        message.page = 0;
        message.pageSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigOperationLogReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigOperationLogReq): ListMatchConfigOperationLogReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 matchConfigId */ 1:
                    message.matchConfigId = reader.int64().toNumber();
                    break;
                case /* int32 page */ 2:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 3:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigOperationLogReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 matchConfigId = 1; */
        if (message.matchConfigId !== 0)
            writer.tag(1, WireType.Varint).int64(message.matchConfigId);
        /* int32 page = 2; */
        if (message.page !== 0)
            writer.tag(2, WireType.Varint).int32(message.page);
        /* int32 pageSize = 3; */
        if (message.pageSize !== 0)
            writer.tag(3, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigOperationLogReq
 */
export const ListMatchConfigOperationLogReq = new ListMatchConfigOperationLogReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigOperationLogResp$Type extends MessageType<ListMatchConfigOperationLogResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigOperationLogResp", [
            { no: 1, name: "logList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ListMatchConfigOperationLog },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigOperationLogResp>): ListMatchConfigOperationLogResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.logList = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigOperationLogResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigOperationLogResp): ListMatchConfigOperationLogResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.ListMatchConfigOperationLog logList */ 1:
                    message.logList.push(ListMatchConfigOperationLog.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigOperationLogResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.ListMatchConfigOperationLog logList = 1; */
        for (let i = 0; i < message.logList.length; i++)
            ListMatchConfigOperationLog.internalBinaryWrite(message.logList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigOperationLogResp
 */
export const ListMatchConfigOperationLogResp = new ListMatchConfigOperationLogResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigOperationLog$Type extends MessageType<ListMatchConfigOperationLog> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigOperationLog", [
            { no: 1, name: "operatorUid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "operateTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "operateType", kind: "enum", T: () => ["bpt.match.matchhub.v1.ListMatchConfigOperationLog.OperateType", ListMatchConfigOperationLog_OperateType] },
            { no: 6, name: "differenceList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchConfigOperationLogDifference }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigOperationLog>): ListMatchConfigOperationLog {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.operatorUid = 0;
        message.operateTime = 0;
        message.operateType = 0;
        message.differenceList = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigOperationLog>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigOperationLog): ListMatchConfigOperationLog {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 operatorUid */ 1:
                    message.operatorUid = reader.int64().toNumber();
                    break;
                case /* int64 operateTime */ 2:
                    message.operateTime = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.ListMatchConfigOperationLog.OperateType operateType */ 3:
                    message.operateType = reader.int32();
                    break;
                case /* repeated bpt.match.matchhub.v1.MatchConfigOperationLogDifference differenceList */ 6:
                    message.differenceList.push(MatchConfigOperationLogDifference.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigOperationLog, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 operatorUid = 1; */
        if (message.operatorUid !== 0)
            writer.tag(1, WireType.Varint).int64(message.operatorUid);
        /* int64 operateTime = 2; */
        if (message.operateTime !== 0)
            writer.tag(2, WireType.Varint).int64(message.operateTime);
        /* bpt.match.matchhub.v1.ListMatchConfigOperationLog.OperateType operateType = 3; */
        if (message.operateType !== 0)
            writer.tag(3, WireType.Varint).int32(message.operateType);
        /* repeated bpt.match.matchhub.v1.MatchConfigOperationLogDifference differenceList = 6; */
        for (let i = 0; i < message.differenceList.length; i++)
            MatchConfigOperationLogDifference.internalBinaryWrite(message.differenceList[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigOperationLog
 */
export const ListMatchConfigOperationLog = new ListMatchConfigOperationLog$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchConfigOperationLogDifference$Type extends MessageType<MatchConfigOperationLogDifference> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchConfigOperationLogDifference", [
            { no: 1, name: "fieldPath", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "beforeValue", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "afterValue", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchConfigOperationLogDifference>): MatchConfigOperationLogDifference {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.fieldPath = "";
        message.beforeValue = "";
        message.afterValue = "";
        if (value !== undefined)
            reflectionMergePartial<MatchConfigOperationLogDifference>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchConfigOperationLogDifference): MatchConfigOperationLogDifference {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fieldPath */ 1:
                    message.fieldPath = reader.string();
                    break;
                case /* string beforeValue */ 2:
                    message.beforeValue = reader.string();
                    break;
                case /* string afterValue */ 3:
                    message.afterValue = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchConfigOperationLogDifference, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fieldPath = 1; */
        if (message.fieldPath !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fieldPath);
        /* string beforeValue = 2; */
        if (message.beforeValue !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.beforeValue);
        /* string afterValue = 3; */
        if (message.afterValue !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.afterValue);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchConfigOperationLogDifference
 */
export const MatchConfigOperationLogDifference = new MatchConfigOperationLogDifference$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchConfTmplV2$Type extends MessageType<MatchConfTmplV2> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchConfTmplV2", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "createTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "blindInfoConfig", kind: "message", T: () => MatchBlindInfoConfig },
            { no: 6, name: "rewardInfoConfig", kind: "message", T: () => MatchRewardInfoConfig },
            { no: 7, name: "part", kind: "enum", T: () => ["bpt.match.matchhub.v1.TmplPartType", TmplPartType] }
        ]);
    }
    create(value?: PartialMessage<MatchConfTmplV2>): MatchConfTmplV2 {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.name = "";
        message.operator = "";
        message.createTime = 0;
        message.part = 0;
        if (value !== undefined)
            reflectionMergePartial<MatchConfTmplV2>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchConfTmplV2): MatchConfTmplV2 {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                case /* int64 createTime */ 4:
                    message.createTime = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig */ 5:
                    message.blindInfoConfig = MatchBlindInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.blindInfoConfig);
                    break;
                case /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig */ 6:
                    message.rewardInfoConfig = MatchRewardInfoConfig.internalBinaryRead(reader, reader.uint32(), options, message.rewardInfoConfig);
                    break;
                case /* bpt.match.matchhub.v1.TmplPartType part */ 7:
                    message.part = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchConfTmplV2, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        /* int64 createTime = 4; */
        if (message.createTime !== 0)
            writer.tag(4, WireType.Varint).int64(message.createTime);
        /* bpt.match.matchhub.v1.MatchBlindInfoConfig blindInfoConfig = 5; */
        if (message.blindInfoConfig)
            MatchBlindInfoConfig.internalBinaryWrite(message.blindInfoConfig, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.MatchRewardInfoConfig rewardInfoConfig = 6; */
        if (message.rewardInfoConfig)
            MatchRewardInfoConfig.internalBinaryWrite(message.rewardInfoConfig, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bpt.match.matchhub.v1.TmplPartType part = 7; */
        if (message.part !== 0)
            writer.tag(7, WireType.Varint).int32(message.part);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchConfTmplV2
 */
export const MatchConfTmplV2 = new MatchConfTmplV2$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigTemplateV2Req$Type extends MessageType<SaveMatchConfigTemplateV2Req> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Req", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmplV2 }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigTemplateV2Req>): SaveMatchConfigTemplateV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigTemplateV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigTemplateV2Req): SaveMatchConfigTemplateV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmplV2 tmpl */ 1:
                    message.tmpl = MatchConfTmplV2.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigTemplateV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmplV2 tmpl = 1; */
        if (message.tmpl)
            MatchConfTmplV2.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Req
 */
export const SaveMatchConfigTemplateV2Req = new SaveMatchConfigTemplateV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchConfigTemplateV2Resp$Type extends MessageType<SaveMatchConfigTemplateV2Resp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Resp", [
            { no: 1, name: "newTmplId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SaveMatchConfigTemplateV2Resp>): SaveMatchConfigTemplateV2Resp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.newTmplId = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveMatchConfigTemplateV2Resp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchConfigTemplateV2Resp): SaveMatchConfigTemplateV2Resp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 newTmplId */ 1:
                    message.newTmplId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchConfigTemplateV2Resp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 newTmplId = 1; */
        if (message.newTmplId !== 0)
            writer.tag(1, WireType.Varint).int64(message.newTmplId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchConfigTemplateV2Resp
 */
export const SaveMatchConfigTemplateV2Resp = new SaveMatchConfigTemplateV2Resp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfigTemplateV2Req$Type extends MessageType<GetMatchConfigTemplateV2Req> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfigTemplateV2Req", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfigTemplateV2Req>): GetMatchConfigTemplateV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfigTemplateV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfigTemplateV2Req): GetMatchConfigTemplateV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfigTemplateV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateV2Req
 */
export const GetMatchConfigTemplateV2Req = new GetMatchConfigTemplateV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchConfigTemplateV2Resp$Type extends MessageType<GetMatchConfigTemplateV2Resp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchConfigTemplateV2Resp", [
            { no: 1, name: "tmpl", kind: "message", T: () => MatchConfTmplV2 }
        ]);
    }
    create(value?: PartialMessage<GetMatchConfigTemplateV2Resp>): GetMatchConfigTemplateV2Resp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchConfigTemplateV2Resp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchConfigTemplateV2Resp): GetMatchConfigTemplateV2Resp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchConfTmplV2 tmpl */ 1:
                    message.tmpl = MatchConfTmplV2.internalBinaryRead(reader, reader.uint32(), options, message.tmpl);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchConfigTemplateV2Resp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchConfTmplV2 tmpl = 1; */
        if (message.tmpl)
            MatchConfTmplV2.internalBinaryWrite(message.tmpl, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchConfigTemplateV2Resp
 */
export const GetMatchConfigTemplateV2Resp = new GetMatchConfigTemplateV2Resp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigTemplateV2Req$Type extends MessageType<ListMatchConfigTemplateV2Req> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigTemplateV2Req", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "part", kind: "enum", T: () => ["bpt.match.matchhub.v1.TmplPartType", TmplPartType] }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigTemplateV2Req>): ListMatchConfigTemplateV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.name = "";
        message.part = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigTemplateV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigTemplateV2Req): ListMatchConfigTemplateV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* string name */ 3:
                    message.name = reader.string();
                    break;
                case /* bpt.match.matchhub.v1.TmplPartType part */ 4:
                    message.part = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigTemplateV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* string name = 3; */
        if (message.name !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.name);
        /* bpt.match.matchhub.v1.TmplPartType part = 4; */
        if (message.part !== 0)
            writer.tag(4, WireType.Varint).int32(message.part);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateV2Req
 */
export const ListMatchConfigTemplateV2Req = new ListMatchConfigTemplateV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchConfigTemplateV2Resp$Type extends MessageType<ListMatchConfigTemplateV2Resp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchConfigTemplateV2Resp", [
            { no: 1, name: "tmplList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchConfTmplV2 },
            { no: 2, name: "totalSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchConfigTemplateV2Resp>): ListMatchConfigTemplateV2Resp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.tmplList = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchConfigTemplateV2Resp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchConfigTemplateV2Resp): ListMatchConfigTemplateV2Resp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchConfTmplV2 tmplList */ 1:
                    message.tmplList.push(MatchConfTmplV2.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 totalSize */ 2:
                    message.totalSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchConfigTemplateV2Resp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchConfTmplV2 tmplList = 1; */
        for (let i = 0; i < message.tmplList.length; i++)
            MatchConfTmplV2.internalBinaryWrite(message.tmplList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchConfigTemplateV2Resp
 */
export const ListMatchConfigTemplateV2Resp = new ListMatchConfigTemplateV2Resp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchConfigTemplateV2Req$Type extends MessageType<DeleteMatchConfigTemplateV2Req> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchConfigTemplateV2Req", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchConfigTemplateV2Req>): DeleteMatchConfigTemplateV2Req {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchConfigTemplateV2Req>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchConfigTemplateV2Req): DeleteMatchConfigTemplateV2Req {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchConfigTemplateV2Req, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchConfigTemplateV2Req
 */
export const DeleteMatchConfigTemplateV2Req = new DeleteMatchConfigTemplateV2Req$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchPrizeNumReq$Type extends MessageType<GetMatchPrizeNumReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchPrizeNumReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchPrizeNumReq>): GetMatchPrizeNumReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchPrizeNumReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchPrizeNumReq): GetMatchPrizeNumReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchPrizeNumReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchPrizeNumReq
 */
export const GetMatchPrizeNumReq = new GetMatchPrizeNumReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchPrizeNumResp$Type extends MessageType<GetMatchPrizeNumResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchPrizeNumResp", [
            { no: 1, name: "prizeNum", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchPrizeNumResp>): GetMatchPrizeNumResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.prizeNum = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchPrizeNumResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchPrizeNumResp): GetMatchPrizeNumResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 prizeNum */ 1:
                    message.prizeNum = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchPrizeNumResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 prizeNum = 1; */
        if (message.prizeNum !== 0)
            writer.tag(1, WireType.Varint).int64(message.prizeNum);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchPrizeNumResp
 */
export const GetMatchPrizeNumResp = new GetMatchPrizeNumResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchNoticeReq$Type extends MessageType<GetMatchNoticeReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchNoticeReq", [
            { no: 1, name: "lang", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchNoticeReq>): GetMatchNoticeReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.lang = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchNoticeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchNoticeReq): GetMatchNoticeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string lang */ 1:
                    message.lang = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchNoticeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string lang = 1; */
        if (message.lang !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.lang);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchNoticeReq
 */
export const GetMatchNoticeReq = new GetMatchNoticeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchNoticeResp$Type extends MessageType<GetMatchNoticeResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchNoticeResp", [
            { no: 1, name: "notice", kind: "message", T: () => MatchNotice }
        ]);
    }
    create(value?: PartialMessage<GetMatchNoticeResp>): GetMatchNoticeResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchNoticeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchNoticeResp): GetMatchNoticeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchNotice notice */ 1:
                    message.notice = MatchNotice.internalBinaryRead(reader, reader.uint32(), options, message.notice);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchNoticeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchNotice notice = 1; */
        if (message.notice)
            MatchNotice.internalBinaryWrite(message.notice, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchNoticeResp
 */
export const GetMatchNoticeResp = new GetMatchNoticeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class I18nNotice$Type extends MessageType<I18nNotice> {
    constructor() {
        super("bpt.match.matchhub.v1.I18nNotice", [
            { no: 1, name: "notice", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => MatchNotice } }
        ]);
    }
    create(value?: PartialMessage<I18nNotice>): I18nNotice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.notice = {};
        if (value !== undefined)
            reflectionMergePartial<I18nNotice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: I18nNotice): I18nNotice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, bpt.match.matchhub.v1.MatchNotice> notice */ 1:
                    this.binaryReadMap1(message.notice, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: I18nNotice["notice"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof I18nNotice["notice"] | undefined, val: I18nNotice["notice"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = MatchNotice.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.match.matchhub.v1.I18nNotice.notice");
            }
        }
        map[key ?? ""] = val ?? MatchNotice.create();
    }
    internalBinaryWrite(message: I18nNotice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, bpt.match.matchhub.v1.MatchNotice> notice = 1; */
        for (let k of globalThis.Object.keys(message.notice)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            MatchNotice.internalBinaryWrite(message.notice[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.I18nNotice
 */
export const I18nNotice = new I18nNotice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchNotice$Type extends MessageType<MatchNotice> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchNotice", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "pcImgs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "mobileImgs", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchNotice>): MatchNotice {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.pcImgs = [];
        message.mobileImgs = [];
        if (value !== undefined)
            reflectionMergePartial<MatchNotice>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchNotice): MatchNotice {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* repeated string pcImgs */ 2:
                    message.pcImgs.push(reader.string());
                    break;
                case /* repeated string mobileImgs */ 3:
                    message.mobileImgs.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchNotice, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* repeated string pcImgs = 2; */
        for (let i = 0; i < message.pcImgs.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.pcImgs[i]);
        /* repeated string mobileImgs = 3; */
        for (let i = 0; i < message.mobileImgs.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.mobileImgs[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchNotice
 */
export const MatchNotice = new MatchNotice$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchNoticeConfig$Type extends MessageType<MatchNoticeConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchNoticeConfig", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "displayStartAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "displayEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "i18n", kind: "message", localName: "i18n", jsonName: "i18n", T: () => I18nNotice },
            { no: 6, name: "state", kind: "enum", T: () => ["bpt.common.SwitchState", SwitchState] },
            { no: 8, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MatchNoticeConfig>): MatchNoticeConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        message.displayStartAt = 0;
        message.displayEndAt = 0;
        message.state = 0;
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<MatchNoticeConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchNoticeConfig): MatchNoticeConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* int64 displayStartAt */ 2:
                    message.displayStartAt = reader.int64().toNumber();
                    break;
                case /* int64 displayEndAt */ 3:
                    message.displayEndAt = reader.int64().toNumber();
                    break;
                case /* bpt.match.matchhub.v1.I18nNotice i18n = 5 [json_name = "i18n"];*/ 5:
                    message.i18n = I18nNotice.internalBinaryRead(reader, reader.uint32(), options, message.i18n);
                    break;
                case /* bpt.common.SwitchState state */ 6:
                    message.state = reader.int32();
                    break;
                case /* string name */ 8:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchNoticeConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* int64 displayStartAt = 2; */
        if (message.displayStartAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.displayStartAt);
        /* int64 displayEndAt = 3; */
        if (message.displayEndAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.displayEndAt);
        /* bpt.match.matchhub.v1.I18nNotice i18n = 5 [json_name = "i18n"]; */
        if (message.i18n)
            I18nNotice.internalBinaryWrite(message.i18n, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bpt.common.SwitchState state = 6; */
        if (message.state !== 0)
            writer.tag(6, WireType.Varint).int32(message.state);
        /* string name = 8; */
        if (message.name !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchNoticeConfig
 */
export const MatchNoticeConfig = new MatchNoticeConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchNoticeConfigReq$Type extends MessageType<GetMatchNoticeConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchNoticeConfigReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchNoticeConfigReq>): GetMatchNoticeConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<GetMatchNoticeConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchNoticeConfigReq): GetMatchNoticeConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchNoticeConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchNoticeConfigReq
 */
export const GetMatchNoticeConfigReq = new GetMatchNoticeConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchNoticeConfigResp$Type extends MessageType<GetMatchNoticeConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchNoticeConfigResp", [
            { no: 1, name: "config", kind: "message", T: () => MatchNoticeConfig }
        ]);
    }
    create(value?: PartialMessage<GetMatchNoticeConfigResp>): GetMatchNoticeConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchNoticeConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchNoticeConfigResp): GetMatchNoticeConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchNoticeConfig config */ 1:
                    message.config = MatchNoticeConfig.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchNoticeConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchNoticeConfig config = 1; */
        if (message.config)
            MatchNoticeConfig.internalBinaryWrite(message.config, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchNoticeConfigResp
 */
export const GetMatchNoticeConfigResp = new GetMatchNoticeConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchNoticeConfigReq$Type extends MessageType<SaveMatchNoticeConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchNoticeConfigReq", [
            { no: 1, name: "config", kind: "message", T: () => MatchNoticeConfig }
        ]);
    }
    create(value?: PartialMessage<SaveMatchNoticeConfigReq>): SaveMatchNoticeConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<SaveMatchNoticeConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchNoticeConfigReq): SaveMatchNoticeConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.matchhub.v1.MatchNoticeConfig config */ 1:
                    message.config = MatchNoticeConfig.internalBinaryRead(reader, reader.uint32(), options, message.config);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchNoticeConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.matchhub.v1.MatchNoticeConfig config = 1; */
        if (message.config)
            MatchNoticeConfig.internalBinaryWrite(message.config, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchNoticeConfigReq
 */
export const SaveMatchNoticeConfigReq = new SaveMatchNoticeConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveMatchNoticeConfigResp$Type extends MessageType<SaveMatchNoticeConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.SaveMatchNoticeConfigResp", [
            { no: 1, name: "newConfId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SaveMatchNoticeConfigResp>): SaveMatchNoticeConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.newConfId = 0;
        if (value !== undefined)
            reflectionMergePartial<SaveMatchNoticeConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveMatchNoticeConfigResp): SaveMatchNoticeConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 newConfId */ 1:
                    message.newConfId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveMatchNoticeConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 newConfId = 1; */
        if (message.newConfId !== 0)
            writer.tag(1, WireType.Varint).int64(message.newConfId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.SaveMatchNoticeConfigResp
 */
export const SaveMatchNoticeConfigResp = new SaveMatchNoticeConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchNoticeConfigReq$Type extends MessageType<ListMatchNoticeConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchNoticeConfigReq", [
            { no: 1, name: "page", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "displayStartAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "displayEndAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchNoticeConfigReq>): ListMatchNoticeConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.displayStartAt = 0;
        message.displayEndAt = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchNoticeConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchNoticeConfigReq): ListMatchNoticeConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page */ 1:
                    message.page = reader.int32();
                    break;
                case /* int32 pageSize */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* int64 displayStartAt */ 3:
                    message.displayStartAt = reader.int64().toNumber();
                    break;
                case /* int64 displayEndAt */ 4:
                    message.displayEndAt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchNoticeConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int32(message.page);
        /* int32 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* int64 displayStartAt = 3; */
        if (message.displayStartAt !== 0)
            writer.tag(3, WireType.Varint).int64(message.displayStartAt);
        /* int64 displayEndAt = 4; */
        if (message.displayEndAt !== 0)
            writer.tag(4, WireType.Varint).int64(message.displayEndAt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchNoticeConfigReq
 */
export const ListMatchNoticeConfigReq = new ListMatchNoticeConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchNoticeConfigResp$Type extends MessageType<ListMatchNoticeConfigResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchNoticeConfigResp", [
            { no: 1, name: "list", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchNoticeConfig },
            { no: 2, name: "totalSize", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ListMatchNoticeConfigResp>): ListMatchNoticeConfigResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.list = [];
        message.totalSize = 0;
        if (value !== undefined)
            reflectionMergePartial<ListMatchNoticeConfigResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchNoticeConfigResp): ListMatchNoticeConfigResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchNoticeConfig list */ 1:
                    message.list.push(MatchNoticeConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int32 totalSize */ 2:
                    message.totalSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchNoticeConfigResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchNoticeConfig list = 1; */
        for (let i = 0; i < message.list.length; i++)
            MatchNoticeConfig.internalBinaryWrite(message.list[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 totalSize = 2; */
        if (message.totalSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.totalSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchNoticeConfigResp
 */
export const ListMatchNoticeConfigResp = new ListMatchNoticeConfigResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMatchNoticeConfigReq$Type extends MessageType<DeleteMatchNoticeConfigReq> {
    constructor() {
        super("bpt.match.matchhub.v1.DeleteMatchNoticeConfigReq", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteMatchNoticeConfigReq>): DeleteMatchNoticeConfigReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = 0;
        if (value !== undefined)
            reflectionMergePartial<DeleteMatchNoticeConfigReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMatchNoticeConfigReq): DeleteMatchNoticeConfigReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteMatchNoticeConfigReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.DeleteMatchNoticeConfigReq
 */
export const DeleteMatchNoticeConfigReq = new DeleteMatchNoticeConfigReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MatchListTypeFeatureConfig$Type extends MessageType<MatchListTypeFeatureConfig> {
    constructor() {
        super("bpt.match.matchhub.v1.MatchListTypeFeatureConfig", [
            { no: 1, name: "matchCategory", kind: "enum", T: () => ["bpt.match.common.v1.MatchListType", MatchListType] },
            { no: 2, name: "isEnable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "matchFeatureConfig", kind: "message", T: () => MatchFeatureConfig }
        ]);
    }
    create(value?: PartialMessage<MatchListTypeFeatureConfig>): MatchListTypeFeatureConfig {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchCategory = 0;
        message.isEnable = false;
        if (value !== undefined)
            reflectionMergePartial<MatchListTypeFeatureConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MatchListTypeFeatureConfig): MatchListTypeFeatureConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchListType matchCategory */ 1:
                    message.matchCategory = reader.int32();
                    break;
                case /* bool isEnable */ 2:
                    message.isEnable = reader.bool();
                    break;
                case /* bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig */ 3:
                    message.matchFeatureConfig = MatchFeatureConfig.internalBinaryRead(reader, reader.uint32(), options, message.matchFeatureConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MatchListTypeFeatureConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchListType matchCategory = 1; */
        if (message.matchCategory !== 0)
            writer.tag(1, WireType.Varint).int32(message.matchCategory);
        /* bool isEnable = 2; */
        if (message.isEnable !== false)
            writer.tag(2, WireType.Varint).bool(message.isEnable);
        /* bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig = 3; */
        if (message.matchFeatureConfig)
            MatchFeatureConfig.internalBinaryWrite(message.matchFeatureConfig, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.MatchListTypeFeatureConfig
 */
export const MatchListTypeFeatureConfig = new MatchListTypeFeatureConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMatchFeaturesResp$Type extends MessageType<ListMatchFeaturesResp> {
    constructor() {
        super("bpt.match.matchhub.v1.ListMatchFeaturesResp", [
            { no: 1, name: "matchListTypeConfigs", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MatchListTypeFeatureConfig }
        ]);
    }
    create(value?: PartialMessage<ListMatchFeaturesResp>): ListMatchFeaturesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchListTypeConfigs = [];
        if (value !== undefined)
            reflectionMergePartial<ListMatchFeaturesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMatchFeaturesResp): ListMatchFeaturesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.match.matchhub.v1.MatchListTypeFeatureConfig matchListTypeConfigs */ 1:
                    message.matchListTypeConfigs.push(MatchListTypeFeatureConfig.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMatchFeaturesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.match.matchhub.v1.MatchListTypeFeatureConfig matchListTypeConfigs = 1; */
        for (let i = 0; i < message.matchListTypeConfigs.length; i++)
            MatchListTypeFeatureConfig.internalBinaryWrite(message.matchListTypeConfigs[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.ListMatchFeaturesResp
 */
export const ListMatchFeaturesResp = new ListMatchFeaturesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchFeaturesReq$Type extends MessageType<GetMatchFeaturesReq> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchFeaturesReq", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMatchFeaturesReq>): GetMatchFeaturesReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        if (value !== undefined)
            reflectionMergePartial<GetMatchFeaturesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchFeaturesReq): GetMatchFeaturesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchFeaturesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchFeaturesReq
 */
export const GetMatchFeaturesReq = new GetMatchFeaturesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMatchFeaturesResp$Type extends MessageType<GetMatchFeaturesResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetMatchFeaturesResp", [
            { no: 1, name: "matchFeatureConfig", kind: "message", T: () => MatchFeatureConfig }
        ]);
    }
    create(value?: PartialMessage<GetMatchFeaturesResp>): GetMatchFeaturesResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetMatchFeaturesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMatchFeaturesResp): GetMatchFeaturesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig */ 1:
                    message.matchFeatureConfig = MatchFeatureConfig.internalBinaryRead(reader, reader.uint32(), options, message.matchFeatureConfig);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMatchFeaturesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.match.common.v1.MatchFeatureConfig matchFeatureConfig = 1; */
        if (message.matchFeatureConfig)
            MatchFeatureConfig.internalBinaryWrite(message.matchFeatureConfig, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetMatchFeaturesResp
 */
export const GetMatchFeaturesResp = new GetMatchFeaturesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecommendMatchResp$Type extends MessageType<GetRecommendMatchResp> {
    constructor() {
        super("bpt.match.matchhub.v1.GetRecommendMatchResp", [
            { no: 1, name: "matchKey", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "matchName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "smallBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "bigBlind", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "buyIn", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleAsset$ },
            { no: 6, name: "remainPlayerCnt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetRecommendMatchResp>): GetRecommendMatchResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.matchKey = "";
        message.matchName = "";
        message.smallBlind = 0;
        message.bigBlind = 0;
        message.buyIn = [];
        message.remainPlayerCnt = 0;
        if (value !== undefined)
            reflectionMergePartial<GetRecommendMatchResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecommendMatchResp): GetRecommendMatchResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string matchKey */ 1:
                    message.matchKey = reader.string();
                    break;
                case /* string matchName */ 2:
                    message.matchName = reader.string();
                    break;
                case /* uint64 smallBlind */ 3:
                    message.smallBlind = reader.uint64().toNumber();
                    break;
                case /* uint64 bigBlind */ 4:
                    message.bigBlind = reader.uint64().toNumber();
                    break;
                case /* repeated bpt.common.SimpleAsset buyIn */ 5:
                    message.buyIn.push(SimpleAsset$.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 remainPlayerCnt */ 6:
                    message.remainPlayerCnt = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecommendMatchResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string matchKey = 1; */
        if (message.matchKey !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.matchKey);
        /* string matchName = 2; */
        if (message.matchName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.matchName);
        /* uint64 smallBlind = 3; */
        if (message.smallBlind !== 0)
            writer.tag(3, WireType.Varint).uint64(message.smallBlind);
        /* uint64 bigBlind = 4; */
        if (message.bigBlind !== 0)
            writer.tag(4, WireType.Varint).uint64(message.bigBlind);
        /* repeated bpt.common.SimpleAsset buyIn = 5; */
        for (let i = 0; i < message.buyIn.length; i++)
            SimpleAsset$.internalBinaryWrite(message.buyIn[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* int64 remainPlayerCnt = 6; */
        if (message.remainPlayerCnt !== 0)
            writer.tag(6, WireType.Varint).int64(message.remainPlayerCnt);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.match.matchhub.v1.GetRecommendMatchResp
 */
export const GetRecommendMatchResp = new GetRecommendMatchResp$Type();
/**
 * @generated ServiceType for protobuf service bpt.match.matchhub.v1.MatchHubService
 */
export const MatchHubService = new ServiceType("bpt.match.matchhub.v1.MatchHubService", [
    { name: "Ping", options: {}, I: Void, O: Void },
    { name: "SaveMatchConf", options: {}, I: SaveMatchConfReq, O: SaveMatchConfResp },
    { name: "ReuseMatchConf", options: {}, I: ReuseMatchConfReq, O: ReuseMatchConfResp },
    { name: "DeleteMatchConf", options: {}, I: DeleteMatchConfReq, O: DeleteMatchConfResp },
    { name: "UpdateMatchConfState", options: {}, I: UpdateMatchConfStateReq, O: UpdateMatchConfStateResp },
    { name: "GetMatchConf", options: {}, I: GetMatchConfReq, O: GetMatchConfResp },
    { name: "BatchGetMatchConf", options: {}, I: BatchGetMatchConfReq, O: BatchGetMatchConfResp },
    { name: "ListMatchConfByKey", options: {}, I: ListMatchConfByKeyReq, O: ListMatchConfByKeyResp },
    { name: "ListMatchConf", options: {}, I: ListMatchConfReq, O: ListMatchConfResp },
    { name: "GetConfByIds", options: {}, I: GetConfByIdsReq, O: GetConfByIdsResp },
    { name: "SaveMatchConfTmpl", options: {}, I: SaveMatchConfTmplReq, O: SaveMatchConfTmplResp },
    { name: "GetMatchConfTmpl", options: {}, I: GetMatchConfTmplReq, O: GetMatchConfTmplResp },
    { name: "ListMatchConfTmpl", options: {}, I: ListMatchConfTmplReq, O: ListMatchConfTmplResp },
    { name: "DeleteMatchConfTmpl", options: {}, I: DeleteMatchConfTmplReq, O: Void },
    { name: "GetSimpleMatch", options: {}, I: GetSimpleMatchReq, O: GetSimpleMatchResp },
    { name: "GetSeatMatchLiteInfo", options: {}, I: GetSeatMatchLiteInfoReq, O: GetSeatMatchLiteInfoResp },
    { name: "OnlineMatchConfig", options: {}, I: OnlineMatchConfigReq, O: OnlineMatchConfigResp },
    { name: "OfflineMatchConfig", options: {}, I: OfflineMatchConfigReq, O: OfflineMatchConfigResp },
    { name: "AdminSendReward", options: {}, I: AdminSendRewardReq, O: AdminSendRewardResp },
    { name: "AdminFetchUserPrizeReward", options: {}, I: AdminFetchUserPrizeRewardReq, O: AdminFetchUserPrizeRewardResp },
    { name: "PauseMatch", options: {}, I: PauseMatchReq, O: Void },
    { name: "ResumeMatch", options: {}, I: ResumeMatchReq, O: Void },
    { name: "CreateMatchConfig", options: {}, I: CreateMatchConfigReq, O: CreateMatchConfigResp },
    { name: "SaveMatchConfigBase", options: {}, I: SaveMatchConfigBaseReq, O: SaveMatchConfigBaseResp },
    { name: "SaveMatchConfigBlind", options: {}, I: SaveMatchConfigBlindReq, O: SaveMatchConfigBlindResp },
    { name: "SaveMatchConfigReward", options: {}, I: SaveMatchConfigRewardReq, O: SaveMatchConfigRewardResp },
    { name: "SaveMatchConfigAll", options: {}, I: SaveMatchConfigAllReq, O: SaveMatchConfigAllResp },
    { name: "GetMatchConfig", options: {}, I: GetMatchConfigReq, O: GetMatchConfigResp },
    { name: "DuplicateMatchConfig", options: {}, I: DuplicateMatchConfigReq, O: DuplicateMatchConfigResp },
    { name: "DeleteMatchConfig", options: {}, I: DeleteMatchConfigReq, O: DeleteMatchConfigResp },
    { name: "ListMatchConfig", options: {}, I: ListMatchConfigReq, O: ListMatchConfigResp },
    { name: "SaveMatchConfigTemplate", options: {}, I: SaveMatchConfigTemplateReq, O: SaveMatchConfigTemplateResp },
    { name: "GetMatchConfigTemplate", options: {}, I: GetMatchConfigTemplateReq, O: GetMatchConfigTemplateResp },
    { name: "ListMatchConfigTemplate", options: {}, I: ListMatchConfigTemplateReq, O: ListMatchConfigTemplateResp },
    { name: "DeleteMatchConfigTemplate", options: {}, I: DeleteMatchConfigTemplateReq, O: DeleteMatchConfigTemplateResp },
    { name: "ListMatchConfigOperationLog", options: {}, I: ListMatchConfigOperationLogReq, O: ListMatchConfigOperationLogResp },
    { name: "SaveMatchConfigTemplateV2", options: {}, I: SaveMatchConfigTemplateV2Req, O: SaveMatchConfigTemplateV2Resp },
    { name: "GetMatchConfigTemplateV2", options: {}, I: GetMatchConfigTemplateV2Req, O: GetMatchConfigTemplateV2Resp },
    { name: "ListMatchConfigTemplateV2", options: {}, I: ListMatchConfigTemplateV2Req, O: ListMatchConfigTemplateV2Resp },
    { name: "DeleteMatchConfigTemplateV2", options: {}, I: DeleteMatchConfigTemplateV2Req, O: Void },
    { name: "ListMatchRecord", options: {}, I: ListMatchRecordReq, O: ListMatchRecordResp },
    { name: "BatchGetRunningMatch", options: {}, I: BatchGetRunningMatchReq, O: BatchGetRunningMatchResp },
    { name: "GetMatchRecord", options: {}, I: GetMatchRecordReq, O: GetMatchRecordResp },
    { name: "UpdateMatchRecordState", options: {}, I: UpdateMatchRecordStateReq, O: UpdateMatchRecordStateResp },
    { name: "UpdateMatchRecordConf", options: {}, I: UpdateMatchRecordConfReq, O: Void },
    { name: "CancelMatchByUser", options: {}, I: CancelMatchByUserReq, O: CancelMatchByUserResp },
    { name: "ForceCancelMatch", options: {}, I: ForceCancelMatchReq, O: ForceCancelMatchResp },
    { name: "UserApplyMatch", options: {}, I: UserApplyMatchReq, O: UserApplyMatchResp },
    { name: "AuditUserManage", options: {}, I: AuditUserManageReq, O: AuditUserManageResp },
    { name: "ListUserManage", options: {}, I: ListUserManageReq, O: ListUserManageResp },
    { name: "UserRegisterMatch", options: {}, I: UserRegisterMatchReq, O: UserRegisterMatchResp },
    { name: "UserUnRegisterMatch", options: {}, I: UserUnRegisterMatchReq, O: UserUnRegisterMatchResp },
    { name: "BatchGetUserManage", options: {}, I: BatchGetUserManageReq, O: BatchGetUserManageResp },
    { name: "UpdateUserManageStatus", options: {}, I: UpdateUserManageStatusReq, O: Void },
    { name: "LinkTransactionCallBack", options: {}, I: Transaction, O: Void },
    { name: "ListMatchTables", options: {}, I: ListMatchTablesReq, O: ListMatchTablesResp },
    { name: "ListMatchUsers", options: {}, I: ListMatchUsersReq, O: ListMatchUsersResp },
    { name: "ListRegisterUsers", options: {}, I: ListRegisterUsersReq, O: ListRegisterUsersResp },
    { name: "ListMultiMatchTables", options: {}, I: ListMultiMatchTablesReq, O: ListMultiMatchTablesResp },
    { name: "ListMatchBlindOptions", options: {}, I: ListMatchBlindOptionsReq, O: ListMatchBlindOptionsResp },
    { name: "ListJoinedUsers", options: {}, I: ListJoinedUsersReq, O: ListJoinedUsersResp },
    { name: "GetUser", options: {}, I: GetUserReq, O: GetUserResp },
    { name: "GetMatch", options: {}, I: GetMatchReq, O: GetMatchResp },
    { name: "GetMatchBrief", options: {}, I: GetMatchBriefReq, O: GetMatchBriefResp },
    { name: "JoinMatch", options: {}, I: JoinMatchReq, O: JoinMatchResp },
    { name: "ListMyMatch", options: {}, I: ListMyMatchReq, O: ListMyMatchResp },
    { name: "BatchGetMatchStateAndUserState", options: {}, I: BatchGetMatchStateAndUserStateReq, O: BatchGetMatchStateAndUserStateResp },
    { name: "GenPayCredential", options: {}, I: GenPayCredentialReq, O: GenPayCredentialResp },
    { name: "FreeAddChips", options: {}, I: FreeAddChipsReq, O: FreeAddChipsResp },
    { name: "ListMatchTag", options: {}, I: ListMatchTagReq, O: ListMatchTagResp },
    { name: "SaveMatchTag", options: {}, I: SaveMatchTagReq, O: Void },
    { name: "DeleteMatchTag", options: {}, I: DeleteMatchTagReq, O: Void },
    { name: "QuickStart", options: {}, I: QuickStartReq, O: QuickStartResp },
    { name: "ChangeDesk", options: {}, I: ChangeDeskReq, O: ChangeDeskResp },
    { name: "CreateMatch", options: {}, I: CreateMatchReq, O: Void },
    { name: "RemoveMatch", options: {}, I: RemoveMatchReq, O: Void },
    { name: "RemoveMatchByConfId", options: {}, I: RemoveMatchByConfIdReq, O: Void },
    { name: "RemoveMatchByMatchKey", options: {}, I: RemoveMatchByMatchKeyReq, O: Void },
    { name: "ClearShowExpiredList", options: {}, I: Void, O: Void },
    { name: "UpdateListStart", options: {}, I: UpdateListStartReq, O: Void },
    { name: "UpdateListUsers", options: {}, I: UpdateListUsersReq, O: Void },
    { name: "ListMatch", options: {}, I: ListMatchReq, O: ListMatchResp },
    { name: "ListMttMatch", options: {}, I: ListMttMatchReq, O: ListMttMatchResp },
    { name: "ListSidebarMatch", options: {}, I: ListSidebarMatchReq, O: ListSidebarMatchResp },
    { name: "ListMatchItem", options: {}, I: ListMatchItemReq, O: ListMatchItemResp },
    { name: "ListClubMatchItem", options: {}, I: ListClubMatchItemReq, O: ListClubMatchItemResp },
    { name: "ListLatestMatch", options: {}, I: ListLatestMatchReq, O: ListLatestMatchResp },
    { name: "ListHistoryMatch", options: {}, I: ListHistoryMatchReq, O: ListHistoryMatchResp },
    { name: "ListHistoryClubMatch", options: {}, I: ListHistoryClubMatchReq, O: ListHistoryClubMatchResp },
    { name: "GetListMyMatch", options: {}, I: GetListMyMatchReq, O: GetListMyMatchResp },
    { name: "GetListMyMttMatch", options: {}, I: Void, O: GetListMyMttMatchResp },
    { name: "GetHomeMyMatchList", options: {}, I: GetHomeMyMatchListReq, O: GetHomeMyMatchListResp },
    { name: "GetUserObservableMatch", options: {}, I: GetUserObservableMatchReq, O: GetUserObservableMatchResp },
    { name: "GetMyMatchRecord", options: {}, I: GetMyMatchRecordReq, O: GetMyMatchRecordResp },
    { name: "GetMatchReportDetail", options: {}, I: GetMatchReportDetailReq, O: GetMatchReportDetailResp },
    { name: "GetMatchPrizeUsers", options: {}, I: GetMatchPrizeUsersReq, O: GetMatchPrizeUsersResp },
    { name: "GetIndexRanking", options: {}, I: GetIndexRankingReq, O: GetIndexRankingResp },
    { name: "GetMatchSchedules", options: {}, I: GetMatchSchedulesReq, O: GetMatchSchedulesResp },
    { name: "GetMatchMoneyCircleUsers", options: {}, I: GetMatchMoneyCircleUsersReq, O: GetMatchMoneyCircleUsersResp },
    { name: "GetMttMatchFinalRanking", options: {}, I: GetMttMatchFinalRankingReq, O: GetMttMatchFinalRankingResp },
    { name: "GetMyMatchNum", options: {}, I: GetMyMatchNumReq, O: GetMyMatchNumResp },
    { name: "GetChipInfo", options: {}, I: GetChipInfoReq, O: GetChipInfoResp },
    { name: "UpdatePool", options: {}, I: UpdatePoolReq, O: UpdatePoolResp },
    { name: "AnimationFinish", options: {}, I: AnimationFinishReq, O: AnimationFinishResp },
    { name: "ReBuy", options: {}, I: ReBuyReq, O: ReBuyResp },
    { name: "ReEntry", options: {}, I: ReEntryReq, O: ReEntryResp },
    { name: "CancelReBuy", options: {}, I: CancelReBuyReq, O: CancelReBuyResp },
    { name: "CancelAddOn", options: {}, I: CancelAddOnReq, O: CancelAddOnResp },
    { name: "AddOn", options: {}, I: AddOnReq, O: AddOnResp },
    { name: "BatchGetCanReEnter", options: {}, I: BatchGetCanReEnterReq, O: BatchGetCanReEnterResp },
    { name: "BatchGetUserCanEnter", options: {}, I: BatchGetUserCanEnterReq, O: BatchGetUserCanEnterResp },
    { name: "MatchOverView", options: {}, I: MatchOverViewReq, O: MatchOverViewResp },
    { name: "ListRank", options: {}, I: ListRankReq, O: ListRankResp },
    { name: "ListDesks", options: {}, I: ListDesksReq, O: ListDesksResp },
    { name: "BatchGetUser", options: {}, I: BatchGetUserReq, O: BatchGetUserResp },
    { name: "ListTableViews", options: {}, I: Void, O: ListTableViewsResp },
    { name: "AddTableView", options: {}, I: AddTableViewReq, O: Void },
    { name: "BatchAddTableView", options: {}, I: BatchAddTableViewReq, O: Void },
    { name: "RemoveTableView", options: {}, I: RemoveTableViewReq, O: Void },
    { name: "ObserveTable", options: {}, I: ObserveTableReq, O: ObserveTableResp },
    { name: "ObserveTableOnBubble", options: {}, I: ObserveTableOnBubbleReq, O: ObserveTableOnBubbleResp },
    { name: "GetMyTable", options: {}, I: GetMyTableReq, O: GetMyTableResp },
    { name: "ListSubMatches", options: {}, I: ListSubMatchesReq, O: ListSubMatchesResp },
    { name: "GetDayOneMaxChips", options: {}, I: GetDayOneMaxChipsReq, O: GetDayOneMaxChipsResp },
    { name: "GetMatchNameAndStartAt", options: {}, I: GetMatchNameAndStartAtReq, O: GetMatchNameAndStartAtResp },
    { name: "BatchGetMatchNameAndStartAt", options: {}, I: BatchGetMatchNameAndStartAtReq, O: BatchGetMatchNameAndStartAtResp },
    { name: "ListPrizePool", options: {}, I: ListPrizePoolReq, O: ListPrizePoolResp },
    { name: "ListMatchPrizeAndBubbleUser", options: {}, I: ListMatchPrizeAndBubbleUserReq, O: ListMatchPrizeAndBubbleUserResp },
    { name: "FormatAndValidateTierPrize", options: {}, I: FormatAndValidateTierPrizeReq, O: FormatAndValidateTierPrizeResp },
    { name: "ListDispatchContract", options: {}, I: ListDispatchContractReq, O: ListDispatchContractResp },
    { name: "ListUserRecordByCms", options: {}, I: ListUserRecordByCmsReq, O: ListUserRecordByCmsResp },
    { name: "MarkAward", options: {}, I: MarkAwardReq, O: MarkAwardResp },
    { name: "GetRegisterInfo", options: {}, I: GetRegisterInfoReq, O: GetRegisterInfoResp },
    { name: "GetReEntryInfo", options: {}, I: GetReEntryInfoReq, O: GetReEntryInfoResp },
    { name: "UseProp", options: {}, I: UsePropReq, O: UsePropResp },
    { name: "UsePropShuffleCardCallBack", options: { "blocker.exportclient": 0 }, I: UsePropShuffleCardCallBackReq, O: UsePropShuffleCardCallBackResp },
    { name: "MarkReadCompletedAwardMatch", options: {}, I: MarkReadCompletedAwardMatchReq, O: Void },
    { name: "AdminRegister", options: { "blocker.exportclient": 0 }, I: AdminRegisterReq, O: Void },
    { name: "RestoreMatchTables", options: { "blocker.exportclient": 0 }, I: RestoreMatchTablesReq, O: RestoreMatchTablesResp },
    { name: "SubmitAwardClaimForm", options: {}, I: SubmitAwardClaimFormReq, O: Void },
    { name: "ListAwardClaimForm", options: { "blocker.exportclient": 0 }, I: ListAwardClaimFormReq, O: ListAwardClaimFormResp },
    { name: "AuditAwardClaimForm", options: { "blocker.exportclient": 0 }, I: AuditAwardClaimFormReq, O: Void },
    { name: "GetEarlyBirdConfigInternal", options: {}, I: GetEarlyBirdConfigInternalReq, O: GetEarlyBirdConfigInternalResp },
    { name: "MttMatchRegisterDeadline", options: {}, I: MsgMatchRegisterDeadline, O: Void },
    { name: "MttMatchUserSettlement", options: {}, I: MsgMatchUserSettlement, O: Void },
    { name: "MttMatchOver", options: {}, I: MsgMatchOver, O: Void },
    { name: "GetMatchPrizeNum", options: {}, I: GetMatchPrizeNumReq, O: GetMatchPrizeNumResp },
    { name: "GetMatchNotice", options: {}, I: GetMatchNoticeReq, O: GetMatchNoticeResp },
    { name: "ListMatchNoticeConfig", options: { "blocker.exportclient": 0 }, I: ListMatchNoticeConfigReq, O: ListMatchNoticeConfigResp },
    { name: "GetMatchNoticeConfig", options: { "blocker.exportclient": 0 }, I: GetMatchNoticeConfigReq, O: GetMatchNoticeConfigResp },
    { name: "SaveMatchNoticeConfig", options: { "blocker.exportclient": 0 }, I: SaveMatchNoticeConfigReq, O: SaveMatchNoticeConfigResp },
    { name: "DeleteMatchNoticeConfig", options: { "blocker.exportclient": 0 }, I: DeleteMatchNoticeConfigReq, O: Void },
    { name: "ListMatchFeatures", options: { "blocker.exportclient": 0 }, I: Void, O: ListMatchFeaturesResp },
    { name: "GetMatchFeatures", options: { "blocker.exportclient": 0 }, I: GetMatchFeaturesReq, O: GetMatchFeaturesResp },
    { name: "GetRecommendMatch", options: {}, I: Void, O: GetRecommendMatchResp }
]);
