// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/common/common_lang.proto" (package "bpt.common", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.common.LanguageEntry
 */
export interface LanguageEntry {
    /**
     * @generated from protobuf field: map<string, string> entry = 1;
     */
    entry: {
        [key: string]: string;
    };
}
/**
 * 通用i18n
 *
 * @generated from protobuf message bpt.common.I18n
 */
export interface I18n {
    /**
     * 语言 key是语言，LanguageEntry 是该语言的翻译kv集合，如{"zh-cn":{"name":"盖茨"}}
     *
     * @generated from protobuf field: map<string, bpt.common.LanguageEntry> language = 1;
     */
    language: {
        [key: string]: LanguageEntry;
    };
}
// @generated message type with reflection information, may provide speed optimized methods
class LanguageEntry$Type extends MessageType<LanguageEntry> {
    constructor() {
        super("bpt.common.LanguageEntry", [
            { no: 1, name: "entry", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<LanguageEntry>): LanguageEntry {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.entry = {};
        if (value !== undefined)
            reflectionMergePartial<LanguageEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LanguageEntry): LanguageEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> entry */ 1:
                    this.binaryReadMap1(message.entry, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: LanguageEntry["entry"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof LanguageEntry["entry"] | undefined, val: LanguageEntry["entry"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.LanguageEntry.entry");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: LanguageEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> entry = 1; */
        for (let k of globalThis.Object.keys(message.entry))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.entry[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.LanguageEntry
 */
export const LanguageEntry = new LanguageEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class I18n$Type extends MessageType<I18n> {
    constructor() {
        super("bpt.common.I18n", [
            { no: 1, name: "language", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => LanguageEntry } }
        ]);
    }
    create(value?: PartialMessage<I18n>): I18n {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.language = {};
        if (value !== undefined)
            reflectionMergePartial<I18n>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: I18n): I18n {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, bpt.common.LanguageEntry> language */ 1:
                    this.binaryReadMap1(message.language, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: I18n["language"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof I18n["language"] | undefined, val: I18n["language"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = LanguageEntry.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.common.I18n.language");
            }
        }
        map[key ?? ""] = val ?? LanguageEntry.create();
    }
    internalBinaryWrite(message: I18n, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, bpt.common.LanguageEntry> language = 1; */
        for (let k of globalThis.Object.keys(message.language)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            LanguageEntry.internalBinaryWrite(message.language[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.common.I18n
 */
export const I18n = new I18n$Type();
