// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/common/assets.proto" (package "bpt.hall.common", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { ErrorCode } from "../../common/code/code";
import { AssetItem } from "../../common/common_define";
import { TxOnChainDetail } from "../../common/common_web3";
import { GrantType } from "../../common/common_define";
import { Way } from "../../common/common_define";
import { AssetType } from "../../common/common_define";
import { ExpireData } from "../../common/common_define";
/**
 * @generated from protobuf message bpt.hall.common.AssetsChangeItem
 */
export interface AssetsChangeItem {
    /**
     * 资源ID
     *
     * @generated from protobuf field: int64 ID = 1 [json_name = "ID"];
     */
    ID: number;
    /**
     * 过期时间点
     *
     * @generated from protobuf field: bpt.common.ExpireData expire = 2;
     */
    expire?: ExpireData;
    /**
     * 都是正数，不能小于0
     *
     * @generated from protobuf field: int64 num = 3;
     */
    num: number;
    /**
     * 扩展json信息
     *
     * @generated from protobuf field: string extra = 4;
     */
    extra: string;
    /**
     * 资产类型在钱包服中使用
     *
     * @generated from protobuf field: bpt.common.AssetType assetType = 5;
     */
    assetType: AssetType;
    /**
     * 钱包服的需要使用加成卡计算加成的数量
     *
     * @generated from protobuf field: int64 markupAmount = 6;
     */
    markupAmount: number;
}
/**
 * @generated from protobuf message bpt.hall.common.AssetsChangeStamp
 */
export interface AssetsChangeStamp {
    /**
     * @generated from protobuf field: bpt.common.Way way = 1;
     */
    way: Way;
    /**
     * 变更人
     *
     * @generated from protobuf field: string operator = 2;
     */
    operator: string;
    /**
     * 发放方式
     *
     * @generated from protobuf field: bpt.common.GrantType grantType = 3;
     */
    grantType: GrantType;
    /**
     * 变动场景
     *
     * @generated from protobuf field: map<string, string> scene = 4;
     */
    scene: {
        [key: string]: string;
    };
    /**
     * 交易ID
     *
     * @generated from protobuf field: string tradeID = 5;
     */
    tradeID: string;
    /**
     * 交易信息
     *
     * @generated from protobuf field: bpt.common.TxOnChainDetail tradeInfo = 6;
     */
    tradeInfo?: TxOnChainDetail;
}
/**
 * @generated from protobuf message bpt.hall.common.AssetsChangeInfo
 */
export interface AssetsChangeInfo {
    /**
     * @generated from protobuf field: bpt.hall.common.AssetsChangeItem item = 1;
     */
    item?: AssetsChangeItem;
    /**
     * @generated from protobuf field: bpt.hall.common.AssetsChangeStamp stamp = 2;
     */
    stamp?: AssetsChangeStamp;
}
/**
 * @generated from protobuf message bpt.hall.common.DynamicAssetItems
 */
export interface DynamicAssetItems {
    /**
     * @generated from protobuf field: bpt.hall.common.DynamicAssetType dtype = 1;
     */
    dtype: DynamicAssetType;
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem asset = 2;
     */
    asset: AssetItem[];
    /**
     * @generated from protobuf field: int64 markupRate = 3;
     */
    markupRate: number;
    /**
     * @generated from protobuf field: bpt.hall.common.AssetMarkupRateType markupRateType = 4;
     */
    markupRateType: AssetMarkupRateType;
    /**
     * @generated from protobuf field: bpt.common.Way way = 5;
     */
    way: Way;
    /**
     * 附加信息
     * prop_id: buff关联的道具id
     * vip_level :vip加成时的vip等级
     *
     * @generated from protobuf field: map<string, string> meta = 6;
     */
    meta: {
        [key: string]: string;
    };
    /**
     * 场景值map
     *
     * @generated from protobuf field: map<string, string> scene = 7;
     */
    scene: {
        [key: string]: string;
    };
    /**
     * 发放方式
     *
     * @generated from protobuf field: bpt.common.GrantType grantType = 8;
     */
    grantType: GrantType;
}
/**
 * @generated from protobuf message bpt.hall.common.IncUserAssetsReq
 */
export interface IncUserAssetsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 多个资产项
     *
     * @generated from protobuf field: repeated bpt.hall.common.AssetsChangeItem items = 2;
     */
    items: AssetsChangeItem[];
    /**
     * @generated from protobuf field: bpt.hall.common.AssetsChangeStamp stamp = 3;
     */
    stamp?: AssetsChangeStamp; //  string batchId = 4;
}
/**
 * @generated from protobuf message bpt.hall.common.IncUserAssetsResp
 */
export interface IncUserAssetsResp {
    /**
     * 本次操作的批次id
     *
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.common.CalcIncUserAssetsReq
 */
export interface CalcIncUserAssetsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 多个资产项
     *
     * @generated from protobuf field: repeated bpt.hall.common.AssetsChangeItem items = 2;
     */
    items: AssetsChangeItem[];
    /**
     * @generated from protobuf field: bpt.hall.common.AssetsChangeStamp stamp = 3;
     */
    stamp?: AssetsChangeStamp;
}
/**
 * @generated from protobuf message bpt.hall.common.CalcIncUserAssetsResp
 */
export interface CalcIncUserAssetsResp {
    /**
     * 增加的道具
     *
     * @generated from protobuf field: repeated bpt.hall.common.DynamicAssetItems assets = 2;
     */
    assets: DynamicAssetItems[];
}
/**
 * @generated from protobuf message bpt.hall.common.SaveIncUserAssetsReq
 */
export interface SaveIncUserAssetsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 增加的道具
     *
     * @generated from protobuf field: repeated bpt.hall.common.DynamicAssetItems assets = 2;
     */
    assets: DynamicAssetItems[];
}
/**
 * @generated from protobuf message bpt.hall.common.SaveIncUserAssetsResp
 */
export interface SaveIncUserAssetsResp {
    /**
     * 本次操作的批次id
     *
     * @generated from protobuf field: string batchID = 1;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.common.DecUserAssetsReq
 */
export interface DecUserAssetsReq {
    /**
     * @generated from protobuf field: int64 uid = 1;
     */
    uid: number;
    /**
     * 多个装扮项
     *
     * @generated from protobuf field: repeated bpt.hall.common.AssetsChangeItem items = 2;
     */
    items: AssetsChangeItem[];
    /**
     * @generated from protobuf field: bpt.hall.common.AssetsChangeStamp stamp = 3;
     */
    stamp?: AssetsChangeStamp;
}
/**
 * @generated from protobuf message bpt.hall.common.DecUserAssetsResp
 */
export interface DecUserAssetsResp {
    /**
     * @generated from protobuf field: bpt.common.code.ErrorCode code = 1;
     */
    code: ErrorCode;
    /**
     * 本次操作的批次id
     *
     * @generated from protobuf field: string batchID = 2;
     */
    batchID: string;
}
/**
 * @generated from protobuf message bpt.hall.common.FillAssetsReq
 */
export interface FillAssetsReq {
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem assets = 1;
     */
    assets: AssetItem[];
}
/**
 * @generated from protobuf message bpt.hall.common.FillAssetsResp
 */
export interface FillAssetsResp {
    /**
     * @generated from protobuf field: repeated bpt.common.AssetItem assets = 1;
     */
    assets: AssetItem[];
}
/**
 * @generated from protobuf enum bpt.hall.common.DynamicAssetType
 */
export enum DynamicAssetType {
    /**
     * @generated from protobuf enum value: DynamicAssetTypeUnknown = 0;
     */
    DynamicAssetTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: DynamicAssetTypeBase = 1;
     */
    DynamicAssetTypeBase = 1,
    /**
     * @generated from protobuf enum value: DynamicAssetTypeMarkup = 2;
     */
    DynamicAssetTypeMarkup = 2
}
/**
 * @generated from protobuf enum bpt.hall.common.AssetMarkupRateType
 */
export enum AssetMarkupRateType {
    /**
     * @generated from protobuf enum value: AssetMarkupRateTypeUnknown = 0;
     */
    AssetMarkupRateTypeUnknown = 0,
    /**
     * 加成卡加成部分
     *
     * @generated from protobuf enum value: AssetMarkupRateTypeBalanceAddCard = 1;
     */
    AssetMarkupRateTypeBalanceAddCard = 1,
    /**
     * 权益加成部分
     *
     * @generated from protobuf enum value: AssetMarkupRateTypeVip = 2;
     */
    AssetMarkupRateTypeVip = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class AssetsChangeItem$Type extends MessageType<AssetsChangeItem> {
    constructor() {
        super("bpt.hall.common.AssetsChangeItem", [
            { no: 1, name: "ID", kind: "scalar", localName: "ID", jsonName: "ID", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "expire", kind: "message", T: () => ExpireData },
            { no: 3, name: "num", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "extra", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "assetType", kind: "enum", T: () => ["bpt.common.AssetType", AssetType] },
            { no: 6, name: "markupAmount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<AssetsChangeItem>): AssetsChangeItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.ID = 0;
        message.num = 0;
        message.extra = "";
        message.assetType = 0;
        message.markupAmount = 0;
        if (value !== undefined)
            reflectionMergePartial<AssetsChangeItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetsChangeItem): AssetsChangeItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 ID = 1 [json_name = "ID"];*/ 1:
                    message.ID = reader.int64().toNumber();
                    break;
                case /* bpt.common.ExpireData expire */ 2:
                    message.expire = ExpireData.internalBinaryRead(reader, reader.uint32(), options, message.expire);
                    break;
                case /* int64 num */ 3:
                    message.num = reader.int64().toNumber();
                    break;
                case /* string extra */ 4:
                    message.extra = reader.string();
                    break;
                case /* bpt.common.AssetType assetType */ 5:
                    message.assetType = reader.int32();
                    break;
                case /* int64 markupAmount */ 6:
                    message.markupAmount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssetsChangeItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 ID = 1 [json_name = "ID"]; */
        if (message.ID !== 0)
            writer.tag(1, WireType.Varint).int64(message.ID);
        /* bpt.common.ExpireData expire = 2; */
        if (message.expire)
            ExpireData.internalBinaryWrite(message.expire, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 num = 3; */
        if (message.num !== 0)
            writer.tag(3, WireType.Varint).int64(message.num);
        /* string extra = 4; */
        if (message.extra !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.extra);
        /* bpt.common.AssetType assetType = 5; */
        if (message.assetType !== 0)
            writer.tag(5, WireType.Varint).int32(message.assetType);
        /* int64 markupAmount = 6; */
        if (message.markupAmount !== 0)
            writer.tag(6, WireType.Varint).int64(message.markupAmount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.AssetsChangeItem
 */
export const AssetsChangeItem = new AssetsChangeItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetsChangeStamp$Type extends MessageType<AssetsChangeStamp> {
    constructor() {
        super("bpt.hall.common.AssetsChangeStamp", [
            { no: 1, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] },
            { no: 2, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "grantType", kind: "enum", T: () => ["bpt.common.GrantType", GrantType] },
            { no: 4, name: "scene", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "tradeID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tradeInfo", kind: "message", T: () => TxOnChainDetail }
        ]);
    }
    create(value?: PartialMessage<AssetsChangeStamp>): AssetsChangeStamp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.way = 0;
        message.operator = "";
        message.grantType = 0;
        message.scene = {};
        message.tradeID = "";
        if (value !== undefined)
            reflectionMergePartial<AssetsChangeStamp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetsChangeStamp): AssetsChangeStamp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.Way way */ 1:
                    message.way = reader.int32();
                    break;
                case /* string operator */ 2:
                    message.operator = reader.string();
                    break;
                case /* bpt.common.GrantType grantType */ 3:
                    message.grantType = reader.int32();
                    break;
                case /* map<string, string> scene */ 4:
                    this.binaryReadMap4(message.scene, reader, options);
                    break;
                case /* string tradeID */ 5:
                    message.tradeID = reader.string();
                    break;
                case /* bpt.common.TxOnChainDetail tradeInfo */ 6:
                    message.tradeInfo = TxOnChainDetail.internalBinaryRead(reader, reader.uint32(), options, message.tradeInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: AssetsChangeStamp["scene"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof AssetsChangeStamp["scene"] | undefined, val: AssetsChangeStamp["scene"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.common.AssetsChangeStamp.scene");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: AssetsChangeStamp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.Way way = 1; */
        if (message.way !== 0)
            writer.tag(1, WireType.Varint).int32(message.way);
        /* string operator = 2; */
        if (message.operator !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.operator);
        /* bpt.common.GrantType grantType = 3; */
        if (message.grantType !== 0)
            writer.tag(3, WireType.Varint).int32(message.grantType);
        /* map<string, string> scene = 4; */
        for (let k of globalThis.Object.keys(message.scene))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.scene[k]).join();
        /* string tradeID = 5; */
        if (message.tradeID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.tradeID);
        /* bpt.common.TxOnChainDetail tradeInfo = 6; */
        if (message.tradeInfo)
            TxOnChainDetail.internalBinaryWrite(message.tradeInfo, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.AssetsChangeStamp
 */
export const AssetsChangeStamp = new AssetsChangeStamp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssetsChangeInfo$Type extends MessageType<AssetsChangeInfo> {
    constructor() {
        super("bpt.hall.common.AssetsChangeInfo", [
            { no: 1, name: "item", kind: "message", T: () => AssetsChangeItem },
            { no: 2, name: "stamp", kind: "message", T: () => AssetsChangeStamp }
        ]);
    }
    create(value?: PartialMessage<AssetsChangeInfo>): AssetsChangeInfo {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<AssetsChangeInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssetsChangeInfo): AssetsChangeInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.common.AssetsChangeItem item */ 1:
                    message.item = AssetsChangeItem.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* bpt.hall.common.AssetsChangeStamp stamp */ 2:
                    message.stamp = AssetsChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssetsChangeInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.common.AssetsChangeItem item = 1; */
        if (message.item)
            AssetsChangeItem.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.common.AssetsChangeStamp stamp = 2; */
        if (message.stamp)
            AssetsChangeStamp.internalBinaryWrite(message.stamp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.AssetsChangeInfo
 */
export const AssetsChangeInfo = new AssetsChangeInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DynamicAssetItems$Type extends MessageType<DynamicAssetItems> {
    constructor() {
        super("bpt.hall.common.DynamicAssetItems", [
            { no: 1, name: "dtype", kind: "enum", T: () => ["bpt.hall.common.DynamicAssetType", DynamicAssetType] },
            { no: 2, name: "asset", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem },
            { no: 3, name: "markupRate", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "markupRateType", kind: "enum", T: () => ["bpt.hall.common.AssetMarkupRateType", AssetMarkupRateType] },
            { no: 5, name: "way", kind: "enum", T: () => ["bpt.common.Way", Way] },
            { no: 6, name: "meta", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 7, name: "scene", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 8, name: "grantType", kind: "enum", T: () => ["bpt.common.GrantType", GrantType] }
        ]);
    }
    create(value?: PartialMessage<DynamicAssetItems>): DynamicAssetItems {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.dtype = 0;
        message.asset = [];
        message.markupRate = 0;
        message.markupRateType = 0;
        message.way = 0;
        message.meta = {};
        message.scene = {};
        message.grantType = 0;
        if (value !== undefined)
            reflectionMergePartial<DynamicAssetItems>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DynamicAssetItems): DynamicAssetItems {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.common.DynamicAssetType dtype */ 1:
                    message.dtype = reader.int32();
                    break;
                case /* repeated bpt.common.AssetItem asset */ 2:
                    message.asset.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 markupRate */ 3:
                    message.markupRate = reader.int64().toNumber();
                    break;
                case /* bpt.hall.common.AssetMarkupRateType markupRateType */ 4:
                    message.markupRateType = reader.int32();
                    break;
                case /* bpt.common.Way way */ 5:
                    message.way = reader.int32();
                    break;
                case /* map<string, string> meta */ 6:
                    this.binaryReadMap6(message.meta, reader, options);
                    break;
                case /* map<string, string> scene */ 7:
                    this.binaryReadMap7(message.scene, reader, options);
                    break;
                case /* bpt.common.GrantType grantType */ 8:
                    message.grantType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap6(map: DynamicAssetItems["meta"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DynamicAssetItems["meta"] | undefined, val: DynamicAssetItems["meta"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.common.DynamicAssetItems.meta");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap7(map: DynamicAssetItems["scene"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof DynamicAssetItems["scene"] | undefined, val: DynamicAssetItems["scene"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.common.DynamicAssetItems.scene");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: DynamicAssetItems, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.common.DynamicAssetType dtype = 1; */
        if (message.dtype !== 0)
            writer.tag(1, WireType.Varint).int32(message.dtype);
        /* repeated bpt.common.AssetItem asset = 2; */
        for (let i = 0; i < message.asset.length; i++)
            AssetItem.internalBinaryWrite(message.asset[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int64 markupRate = 3; */
        if (message.markupRate !== 0)
            writer.tag(3, WireType.Varint).int64(message.markupRate);
        /* bpt.hall.common.AssetMarkupRateType markupRateType = 4; */
        if (message.markupRateType !== 0)
            writer.tag(4, WireType.Varint).int32(message.markupRateType);
        /* bpt.common.Way way = 5; */
        if (message.way !== 0)
            writer.tag(5, WireType.Varint).int32(message.way);
        /* map<string, string> meta = 6; */
        for (let k of globalThis.Object.keys(message.meta))
            writer.tag(6, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.meta[k]).join();
        /* map<string, string> scene = 7; */
        for (let k of globalThis.Object.keys(message.scene))
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.scene[k]).join();
        /* bpt.common.GrantType grantType = 8; */
        if (message.grantType !== 0)
            writer.tag(8, WireType.Varint).int32(message.grantType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.DynamicAssetItems
 */
export const DynamicAssetItems = new DynamicAssetItems$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserAssetsReq$Type extends MessageType<IncUserAssetsReq> {
    constructor() {
        super("bpt.hall.common.IncUserAssetsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetsChangeItem },
            { no: 3, name: "stamp", kind: "message", T: () => AssetsChangeStamp }
        ]);
    }
    create(value?: PartialMessage<IncUserAssetsReq>): IncUserAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<IncUserAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserAssetsReq): IncUserAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.common.AssetsChangeItem items */ 2:
                    message.items.push(AssetsChangeItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.hall.common.AssetsChangeStamp stamp */ 3:
                    message.stamp = AssetsChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.hall.common.AssetsChangeItem items = 2; */
        for (let i = 0; i < message.items.length; i++)
            AssetsChangeItem.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.common.AssetsChangeStamp stamp = 3; */
        if (message.stamp)
            AssetsChangeStamp.internalBinaryWrite(message.stamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.IncUserAssetsReq
 */
export const IncUserAssetsReq = new IncUserAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class IncUserAssetsResp$Type extends MessageType<IncUserAssetsResp> {
    constructor() {
        super("bpt.hall.common.IncUserAssetsResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<IncUserAssetsResp>): IncUserAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<IncUserAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: IncUserAssetsResp): IncUserAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: IncUserAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.IncUserAssetsResp
 */
export const IncUserAssetsResp = new IncUserAssetsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalcIncUserAssetsReq$Type extends MessageType<CalcIncUserAssetsReq> {
    constructor() {
        super("bpt.hall.common.CalcIncUserAssetsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetsChangeItem },
            { no: 3, name: "stamp", kind: "message", T: () => AssetsChangeStamp }
        ]);
    }
    create(value?: PartialMessage<CalcIncUserAssetsReq>): CalcIncUserAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<CalcIncUserAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalcIncUserAssetsReq): CalcIncUserAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.common.AssetsChangeItem items */ 2:
                    message.items.push(AssetsChangeItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.hall.common.AssetsChangeStamp stamp */ 3:
                    message.stamp = AssetsChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalcIncUserAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.hall.common.AssetsChangeItem items = 2; */
        for (let i = 0; i < message.items.length; i++)
            AssetsChangeItem.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.common.AssetsChangeStamp stamp = 3; */
        if (message.stamp)
            AssetsChangeStamp.internalBinaryWrite(message.stamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.CalcIncUserAssetsReq
 */
export const CalcIncUserAssetsReq = new CalcIncUserAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CalcIncUserAssetsResp$Type extends MessageType<CalcIncUserAssetsResp> {
    constructor() {
        super("bpt.hall.common.CalcIncUserAssetsResp", [
            { no: 2, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DynamicAssetItems }
        ]);
    }
    create(value?: PartialMessage<CalcIncUserAssetsResp>): CalcIncUserAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<CalcIncUserAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CalcIncUserAssetsResp): CalcIncUserAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.common.DynamicAssetItems assets */ 2:
                    message.assets.push(DynamicAssetItems.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CalcIncUserAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.common.DynamicAssetItems assets = 2; */
        for (let i = 0; i < message.assets.length; i++)
            DynamicAssetItems.internalBinaryWrite(message.assets[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.CalcIncUserAssetsResp
 */
export const CalcIncUserAssetsResp = new CalcIncUserAssetsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveIncUserAssetsReq$Type extends MessageType<SaveIncUserAssetsReq> {
    constructor() {
        super("bpt.hall.common.SaveIncUserAssetsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DynamicAssetItems }
        ]);
    }
    create(value?: PartialMessage<SaveIncUserAssetsReq>): SaveIncUserAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<SaveIncUserAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveIncUserAssetsReq): SaveIncUserAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.common.DynamicAssetItems assets */ 2:
                    message.assets.push(DynamicAssetItems.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveIncUserAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.hall.common.DynamicAssetItems assets = 2; */
        for (let i = 0; i < message.assets.length; i++)
            DynamicAssetItems.internalBinaryWrite(message.assets[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.SaveIncUserAssetsReq
 */
export const SaveIncUserAssetsReq = new SaveIncUserAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveIncUserAssetsResp$Type extends MessageType<SaveIncUserAssetsResp> {
    constructor() {
        super("bpt.hall.common.SaveIncUserAssetsResp", [
            { no: 1, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveIncUserAssetsResp>): SaveIncUserAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<SaveIncUserAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveIncUserAssetsResp): SaveIncUserAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string batchID */ 1:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveIncUserAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string batchID = 1; */
        if (message.batchID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.SaveIncUserAssetsResp
 */
export const SaveIncUserAssetsResp = new SaveIncUserAssetsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserAssetsReq$Type extends MessageType<DecUserAssetsReq> {
    constructor() {
        super("bpt.hall.common.DecUserAssetsReq", [
            { no: 1, name: "uid", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetsChangeItem },
            { no: 3, name: "stamp", kind: "message", T: () => AssetsChangeStamp }
        ]);
    }
    create(value?: PartialMessage<DecUserAssetsReq>): DecUserAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uid = 0;
        message.items = [];
        if (value !== undefined)
            reflectionMergePartial<DecUserAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserAssetsReq): DecUserAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 uid */ 1:
                    message.uid = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.common.AssetsChangeItem items */ 2:
                    message.items.push(AssetsChangeItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* bpt.hall.common.AssetsChangeStamp stamp */ 3:
                    message.stamp = AssetsChangeStamp.internalBinaryRead(reader, reader.uint32(), options, message.stamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 uid = 1; */
        if (message.uid !== 0)
            writer.tag(1, WireType.Varint).int64(message.uid);
        /* repeated bpt.hall.common.AssetsChangeItem items = 2; */
        for (let i = 0; i < message.items.length; i++)
            AssetsChangeItem.internalBinaryWrite(message.items[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* bpt.hall.common.AssetsChangeStamp stamp = 3; */
        if (message.stamp)
            AssetsChangeStamp.internalBinaryWrite(message.stamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.DecUserAssetsReq
 */
export const DecUserAssetsReq = new DecUserAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DecUserAssetsResp$Type extends MessageType<DecUserAssetsResp> {
    constructor() {
        super("bpt.hall.common.DecUserAssetsResp", [
            { no: 1, name: "code", kind: "enum", T: () => ["bpt.common.code.ErrorCode", ErrorCode] },
            { no: 2, name: "batchID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DecUserAssetsResp>): DecUserAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.code = 0;
        message.batchID = "";
        if (value !== undefined)
            reflectionMergePartial<DecUserAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DecUserAssetsResp): DecUserAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.common.code.ErrorCode code */ 1:
                    message.code = reader.int32();
                    break;
                case /* string batchID */ 2:
                    message.batchID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DecUserAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.common.code.ErrorCode code = 1; */
        if (message.code !== 0)
            writer.tag(1, WireType.Varint).int32(message.code);
        /* string batchID = 2; */
        if (message.batchID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.batchID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.DecUserAssetsResp
 */
export const DecUserAssetsResp = new DecUserAssetsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FillAssetsReq$Type extends MessageType<FillAssetsReq> {
    constructor() {
        super("bpt.hall.common.FillAssetsReq", [
            { no: 1, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<FillAssetsReq>): FillAssetsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<FillAssetsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FillAssetsReq): FillAssetsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem assets */ 1:
                    message.assets.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FillAssetsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem assets = 1; */
        for (let i = 0; i < message.assets.length; i++)
            AssetItem.internalBinaryWrite(message.assets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.FillAssetsReq
 */
export const FillAssetsReq = new FillAssetsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FillAssetsResp$Type extends MessageType<FillAssetsResp> {
    constructor() {
        super("bpt.hall.common.FillAssetsResp", [
            { no: 1, name: "assets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssetItem }
        ]);
    }
    create(value?: PartialMessage<FillAssetsResp>): FillAssetsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.assets = [];
        if (value !== undefined)
            reflectionMergePartial<FillAssetsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FillAssetsResp): FillAssetsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.common.AssetItem assets */ 1:
                    message.assets.push(AssetItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FillAssetsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.common.AssetItem assets = 1; */
        for (let i = 0; i < message.assets.length; i++)
            AssetItem.internalBinaryWrite(message.assets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.FillAssetsResp
 */
export const FillAssetsResp = new FillAssetsResp$Type();
