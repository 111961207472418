import { ENV } from './env';

const BASE_API_MAP = {
  [ENV.DEV]: 'https://api-dev.mtt.xyz:31963',
  [ENV.TEST]: 'https://api-test.mtt.xyz',
  [ENV.PROD]: 'https://api.mtt.xyz',
};
export function getApiPrefix() {
  return BASE_API_MAP[ENV.PROD];
}
