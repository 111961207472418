import { getLanguage, initMttI18n } from '@mtt/i18n-core';
import { env } from './utils/env';
import { setupSentry } from './utils/sentry';

import { setGrpcEnv } from '@mtt/grpc-core';
import { setLocale } from '@umijs/max';

setGrpcEnv(env);
setupSentry();

export function render(oldRender: any) {
  initMttI18n(false).finally(() => {
    const lang = getLanguage();
    console.log(lang, '111111');
    setLocale(lang!);
    oldRender();
  });
}
