import { MatchListType } from '@/grpc/client/bpt/match/common/v1/common';
import { matchHubMgr } from '@/grpc/domain/match/matchHubMgr';
import { hallPropMgr } from '@/grpc/domain/props/propMgr';
import { hallUserInfoMgr } from '@/grpc/domain/user/userMgr';

export const listUserPrizeRanks = async ({
  matchListType,
}: {
  matchListType: MatchListType;
}) => {
  const res = await matchHubMgr
    .getIndexRanking({
      matchListType,
      page: 1,
      pageSize: 9,
    })
    .catch(() => undefined);

  return res;
};

// const loadUsersList = async (uidList: number[]) => {
//   // 去重后的 用户uid 集合
//   const uniqueUidList = [...new Set(uidList)];
//   const needQueryIds = uniqueUidList.filter(
//     (uid) => !this.userInfoMap.has(uid),
//   );
//   if (needQueryIds.length <= 0) return;
//   // 去重后的 用户uid 集合，最多200个
//   const resultList: IUser[] = (await batchUser.getUsers(
//     needQueryIds,
//   )) as IUser[];
//   resultList.map((user: IUser) => {
//     if (user) {
//       runInAction(() => {
//         this.userInfoMap.set(user.uid, user);
//       });
//     }
//   });
// };

export const batchGetProps = async (propsIds: number[]) => {
  const data = await hallPropMgr.batchGetProp(propsIds);
  return data.props;
};

export const batchGetUser = async (userIds: number[]) => {
  const data = await hallUserInfoMgr.batchGetUser(userIds);
  return data.users;
};

export const getUser = async (uid: number) => {
  const data = await hallUserInfoMgr.getUser(uid);
  return data.user;
};
