const ENV_KEY = 'MTT_ENV';

export enum ENV {
  DEV = 'development',
  TEST = 'test',
  PROD = 'production',
}

export function normalizeEnv(env: string | null) {
  switch (env) {
    case 'dev':
    case ENV.DEV: // 'development':
      return ENV.DEV;
    case ENV.TEST: //'test':
    case 'testing':
      return ENV.TEST;
    case 'prod':
    case ENV.PROD: //'production':
      return ENV.PROD;
    default:
      return undefined;
  }
}

/** 预设的 env 值 */
function getPersistEnv() {
  return normalizeEnv(localStorage.getItem(ENV_KEY));
}

/** 根据 url 的 hostname 判断环境变量
 * 以下规则：
 *   开发环境: hostname 中包含 localhost, ip, -dev
 *   测试环境: hostname 中包含 test
 *   生产环境: hostname 中不包含 dev, test
 *
 */
function judgeEnvViaHost() {
  const hostname = location.hostname;

  // 测试环境: hostname 中包含 test
  if (hostname.includes('test')) {
    return ENV.TEST;
  }

  // 开发环境: hostname 中包含 localhost, ip, -dev, .oa.com (只有内网能使用 - 默认非 Prod 环境)
  if (
    hostname.includes('nodeport.') ||
    hostname.includes('.oa.com') ||
    hostname.includes('localhost') ||
    hostname.includes('dev') ||
    /^\d+\.\d+\.\d+\.\d+/.test(hostname)
  ) {
    return ENV.DEV;
  }

  // 生产环境: hostname 中不包含 dev, test
  return ENV.PROD;
}

/**
 * 获取环境信息
 * 优先级: URL覆盖 > 预设环境 > SSR环境 > 主机名判断
 */
export const getEnv = (): ENV => {
  const fallbackEnv = judgeEnvViaHost();
  if (fallbackEnv === ENV.PROD) {
    return ENV.PROD;
  }

  const envGetters = [getPersistEnv, judgeEnvViaHost];

  for (const getter of envGetters) {
    const env = getter();
    if (env) {
      console.debug(`环境获取结果: ${env}，来源: ${getter.name}`);
      return env;
    }
  }

  // 理论上不会执行到这里，因为judgeEnvViaHost总是会返回一个值
  throw new Error('judge env failed');
};

export const env = getEnv();
