import * as Sentry from '@sentry/react';
import extend from 'extend';
import { env } from './env';

interface InitSentryOptions extends Sentry.BrowserOptions {
  environment?: string;
}

/**
 * 标准化捕捉的错误
 * 如果不是 Error 类型, 转为 Error 再上报
 *  当前兼容 GRPCError 和 ErrorCode
 *  直接抛出基本类型的地方 - 无法兼容 - 须避免抛出不带 stack 的异常
 *  */
function normalizeCaptureBody(
  body: Error | object | boolean | number | string,
): Error | object {
  if (
    typeof body === 'string' ||
    typeof body === 'number' ||
    typeof body === 'boolean'
  ) {
    const err = new Error(`throw: ${JSON.stringify(body)}`);
    return err;
  }
  if (!body || body instanceof Error) {
    return body;
  }
  // 处理 GRPCError 和 ErrorCode
  if ('name' in body || 'code' in body) {
    const { name, message, stack, code, msg, methodName, serviceName } =
      body as any;
    const resultError = new Error(message || msg || code);
    Object.assign(resultError, body);
    if (methodName || serviceName) {
      resultError.name = `GRPC_${serviceName || methodName}`;
    } else if (name) {
      resultError.name = `Capture_${name}`;
    } else {
      resultError.name = `Code_${code || ''}`;
    }
    resultError.stack = stack;
    return resultError;
  }
  return body;
}
interface CapturePrams {
  scope?: {
    title?: string;
    level?: Sentry.SeverityLevel;
    tag?: string;
    extra?: any;
  };
  body: any;
}

/**
 * 手动上报捕获异常
 * @param params
 */
export const captureSentryException = (params: CapturePrams) => {
  const defaultScope: CapturePrams['scope'] = {
    title: 'Custom exception',
    level: 'error',
  };
  const { scope, body } = params;
  const { title, level, tag, extra } = extend(true, defaultScope, scope);
  Sentry.withScope(function (scopeInterface) {
    scopeInterface.setLevel(level);
    //  避免不相关的错误聚合, 去除指纹设置-默认会根据 trace 聚合
    // scopeInterface.setFingerprint([title])
    scopeInterface.setTag('custom_title', title);
    if (tag) {
      scopeInterface.setTag('tag', tag);
    }
    if (extra) {
      scopeInterface.setExtra('extra', extra);
    }
    if (!(body instanceof Error)) {
      scopeInterface.setExtra('stringify', JSON.stringify(body));
    }
    Sentry.captureException(normalizeCaptureBody(body));
  });
};

/**
 * 屏蔽不上报规则
 * @returns
 */
const maskReport = (): boolean => {
  const hostnames = ['localhost', '127.0.0.1'];
  if (hostnames.includes(window.location.hostname)) {
    return true;
  }
  return false;
};

/**
 * 初始化 Sentry
 */
export const setupSentry = (options?: InitSentryOptions) => {
  if (!maskReport()) {
    const _options: Sentry.BrowserOptions = {
      environment: env,
      dsn: 'https://4d3143e4a205a4822fa517e5c73eff9e@sentry.mtt.xyz/4',
      release: `j_${process.env.BUILD_ID}`, // 使用 jenkins build id 作为版本
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.breadcrumbsIntegration({ console: false }),
        Sentry.replayIntegration({
          maskAllText: true, // 屏蔽文本
          blockAllMedia: true, // 屏蔽图片
        }),
        // TODO 适配 react
        // Sentry.reactRouterV6BrowserTracingIntegration({
        //   useEffect: React.useEffect,
        //   useLocation,
        //   useNavigationType,
        //   createRoutesFromChildren,
        //   matchRoutes,
        // }),
      ],
      // 指定环境地址进行上报
      denyUrls: ['localhost', '127.0.0.1'],
      // allowUrls: ['mtt.xyz'],
      tracesSampleRate: 1.0, // 100% 的采样率
      replaysSessionSampleRate: 0, // 非异常不采样重播
      replaysOnErrorSampleRate: 1.0, // 错误时的重播采样率
      ...options,
    };
    console.debug(`[sentry option]`, _options);
    Sentry.init(_options);
    window.addEventListener(
      'unhandledrejection',
      (event: PromiseRejectionEvent) => {
        if (
          event.reason instanceof Error &&
          event.reason.message?.startsWith('Unable to preload CSS')
        ) {
          // preload css 不上报
          return;
        }

        // 上报sentry
        // rpc error
        // console.debug('unhandledrejection', event, captureSentryException)
        // 抛出错误 - 处理错误
        captureSentryException({
          scope: {
            title: 'unhandledrejection',
            level: 'error',
          },
          body: event.reason,
        });
      },
    );
  }
};
