// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/common/buff.proto" (package "bpt.hall.common", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.hall.common.BuffExtraConf
 */
export interface BuffExtraConf {
    /**
     * buff有效范围
     *
     * @generated from protobuf field: repeated bpt.hall.common.BuffCondition buffCondition = 1;
     */
    buffCondition: BuffCondition[];
    /**
     * 加成比例类型
     *
     * @generated from protobuf field: bpt.hall.common.MarkupRateType markupRateType = 2;
     */
    markupRateType: MarkupRateType;
    /**
     * 附加信息
     * prop_id: buff关联的道具id
     *
     * @generated from protobuf field: map<string, string> meta = 3;
     */
    meta: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf enum bpt.hall.common.BuffCondition
 */
export enum BuffCondition {
    /**
     * @generated from protobuf enum value: BuffConditionUnknown = 0;
     */
    BuffConditionUnknown = 0,
    /**
     * 娱乐场场景
     *
     * @generated from protobuf enum value: BuffConditionMateMatch = 1;
     */
    BuffConditionMateMatch = 1,
    /**
     * 比赛场景
     *
     * @generated from protobuf enum value: BuffConditionMatch = 2;
     */
    BuffConditionMatch = 2
}
/**
 * @generated from protobuf enum bpt.hall.common.MarkupRateType
 */
export enum MarkupRateType {
    /**
     * @generated from protobuf enum value: MarkupRateUnknown = 0;
     */
    MarkupRateUnknown = 0,
    /**
     * 全部道具
     *
     * @generated from protobuf enum value: MarkupRateAllAsset = 1;
     */
    MarkupRateAllAsset = 1,
    /**
     * 货币
     *
     * @generated from protobuf enum value: MarkupRateWalletAsset = 2;
     */
    MarkupRateWalletAsset = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class BuffExtraConf$Type extends MessageType<BuffExtraConf> {
    constructor() {
        super("bpt.hall.common.BuffExtraConf", [
            { no: 1, name: "buffCondition", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["bpt.hall.common.BuffCondition", BuffCondition] },
            { no: 2, name: "markupRateType", kind: "enum", T: () => ["bpt.hall.common.MarkupRateType", MarkupRateType] },
            { no: 3, name: "meta", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<BuffExtraConf>): BuffExtraConf {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.buffCondition = [];
        message.markupRateType = 0;
        message.meta = {};
        if (value !== undefined)
            reflectionMergePartial<BuffExtraConf>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuffExtraConf): BuffExtraConf {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.common.BuffCondition buffCondition */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.buffCondition.push(reader.int32());
                    else
                        message.buffCondition.push(reader.int32());
                    break;
                case /* bpt.hall.common.MarkupRateType markupRateType */ 2:
                    message.markupRateType = reader.int32();
                    break;
                case /* map<string, string> meta */ 3:
                    this.binaryReadMap3(message.meta, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: BuffExtraConf["meta"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof BuffExtraConf["meta"] | undefined, val: BuffExtraConf["meta"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.common.BuffExtraConf.meta");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: BuffExtraConf, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.common.BuffCondition buffCondition = 1; */
        if (message.buffCondition.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.buffCondition.length; i++)
                writer.int32(message.buffCondition[i]);
            writer.join();
        }
        /* bpt.hall.common.MarkupRateType markupRateType = 2; */
        if (message.markupRateType !== 0)
            writer.tag(2, WireType.Varint).int32(message.markupRateType);
        /* map<string, string> meta = 3; */
        for (let k of globalThis.Object.keys(message.meta))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.meta[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.common.BuffExtraConf
 */
export const BuffExtraConf = new BuffExtraConf$Type();
